<template>
  <div>
    <div class="top mb-4">
      <div class="bg-white shadow-sm radius-top">
        <div class="bg-primary radius-top px-3 py-2 mb-0">
          <b-row class="justify-content-between">
            <b-col cols="12" lg="3" class="d-flex align-items-center mr-auto">
              <p class="mb-0">Form Rekanan</p>
            </b-col>
            <b-col cols="12" lg="2">
              <multiselect class="shadow-sm border-0 rounded" v-model="vselectJenisPendonor" :show-labels="false" :limit="3"
                @input="valJenisPendonor" label="name" placeholder="Ruang Transaksi" :options="comboJenisPendonor"
                :searchable="true">
              </multiselect>
            </b-col>
            <b-col cols="12" lg="1">
              <p class="mb-0 text-white">
                <b-button variant="secondary" class="w-100">Faktur</b-button>
              </p>
            </b-col>
          </b-row>
        </div>
        <div class="p-4">
          <b-row>
            <b-col cols="12" lg="2" class="mb-4">
              <div>
                <b-form-group label="Nama PBF" class="mb-0">
                  <div class="border radius">
                    <multiselect class="shadow-sm border-0 rounded" v-model="vselectJenisPendonor" :show-labels="false" :limit="3"
                      @input="valJenisPendonor" label="name" placeholder="Masukan Nama PBF" :options="comboJenisPendonor"
                      :searchable="true">
                    </multiselect>
                  </div>
                </b-form-group>
              </div>
            </b-col>
            <b-col cols="12" lg="2" class="mb-4">
              <div>
                <b-form-group label="Nomor SP" class="mb-0">
                  <b-form-input id="exampleInputText1" type="text" placeholder="Nomor SP"></b-form-input>
                </b-form-group>
              </div>
            </b-col>
            <b-col cols="12" lg="2" class="mb-4">
              <div>
                <b-form-group label="Nomor Faktur" class="mb-0">
                  <b-form-input id="exampleInputText1" type="text" placeholder="Nomor Faktur"></b-form-input>
                </b-form-group>
              </div>
            </b-col>
            <b-col cols="12" lg="2" class="mb-4">
              <div>
                <b-form-group label="Tanggal Faktur" class="mb-0">
                  <b-form-input id="exampleInputText1" type="date" placeholder="Tanggal Faktur"></b-form-input>
                </b-form-group>
              </div>
            </b-col>
            <b-col cols="12" lg="2">
              <div>
                <b-form-group label="Tanggal Transaksi" class="mb-0">
                  <b-form-input id="exampleInputText1" type="date" placeholder="Tanggal Transaksi"></b-form-input>
                </b-form-group>
              </div>
            </b-col>
            <b-col cols="12" lg="2">
              <div>
                <b-form-group label="Tanggal Jatuh Tempo" class="mb-0">
                  <b-form-input id="exampleInputText1" type="date" placeholder="Enter Name"></b-form-input>
                </b-form-group>
              </div>
            </b-col>
            <b-col cols="12" lg="2">
              <div>
                <b-form-group label="Keterangan" class="mb-0">
                  <b-form-input id="exampleInputText1" type="text" placeholder="Keterangan"></b-form-input>
                </b-form-group>
              </div>
            </b-col>
            <b-col cols="12" lg="2">
              <div>
                <b-form-group label="Keterangan" class="mb-0">
                  <div class="border radius">
                    <multiselect class="shadow-sm border-0 rounded" v-model="vselectJenisPendonor" :show-labels="false" :limit="3"
                      @input="valJenisPendonor" label="name" placeholder="Sumber Dana" :options="comboJenisPendonor"
                      :searchable="true">
                    </multiselect>
                  </div>
                </b-form-group>
              </div>
            </b-col>
            <b-col cols="12" lg="2">
              <div>
                <b-form-group label="Keterangan" class="mb-0">
                  <div class="border radius">
                    <multiselect class="shadow-sm border-0 rounded" v-model="vselectJenisPendonor" :show-labels="false" :limit="3"
                      @input="valJenisPendonor" label="name" placeholder="Sudah Termasuk" :options="comboJenisPendonor"
                      :searchable="true">
                    </multiselect>
                  </div>
                </b-form-group>
              </div>
            </b-col>
          </b-row>
        </div>
        <hr>
        <div class="p-4">
          <b-row>
            <b-col cols="12" lg="2" class="mb-4">
              <div>
                <b-form-group label="Kategori" class="mb-0">
                  <div class="border radius">
                    <multiselect class="shadow-sm border-0 rounded" v-model="vselectJenisPendonor" :show-labels="false" :limit="3"
                      @input="valJenisPendonor" label="name" placeholder="Kategori Barang" :options="comboJenisPendonor"
                      :searchable="true">
                    </multiselect>
                  </div>
                </b-form-group>
              </div>
            </b-col>
            <b-col cols="12" lg="2" class="mb-4">
              <div>
                <b-form-group label="Nama Barang" class="mb-0">
                  <div class="border radius">
                    <multiselect class="shadow-sm border-0 rounded" v-model="vselectJenisPendonor" :show-labels="false" :limit="3"
                      @input="valJenisPendonor" label="name" placeholder="Nama Barang" :options="comboJenisPendonor"
                      :searchable="true">
                    </multiselect>
                  </div>
                </b-form-group>
              </div>
            </b-col>
            <b-col cols="12" lg="2" class="mb-4">
              <div>
                <b-form-group label="Nomor Batch" class="mb-0">
                  <b-form-input id="exampleInputText1" type="text" placeholder="Nomor Batch"></b-form-input>
                </b-form-group>
              </div>
            </b-col>
            <b-col cols="12" lg="2" class="mb-4">
              <div>
                <b-form-group label="Exd" class="mb-0">
                  <b-form-input id="exampleInputText1" type="date" placeholder="Nomor Faktur"></b-form-input>
                </b-form-group>
              </div>
            </b-col>
            <b-col cols="12" lg="2" class="mb-4">
              <div>
                <b-form-group label="Jumlah Box" class="mb-0">
                  <b-form-input id="exampleInputText1" type="text" placeholder="Jumlah Box"></b-form-input>
                </b-form-group>
              </div>
            </b-col>
            <b-col cols="12" lg="2">
              <div>
                <b-form-group label="Isi Per Box" class="mb-0">
                  <b-form-input id="exampleInputText1" type="text" placeholder="Isi Per Box"></b-form-input>
                </b-form-group>
              </div>
            </b-col>
            <b-col cols="12" lg="2">
              <div>
                <b-form-group label="Total Jumlah" class="mb-0">
                  <b-form-input id="exampleInputText1" type="text" placeholder="Total Jumlah"></b-form-input>
                </b-form-group>
              </div>
            </b-col>
            <b-col cols="12" lg="2">
              <div>
                <b-form-group label="Harga Per Box" class="mb-0">
                  <b-form-input id="exampleInputText1" type="text" placeholder="Harga / Box"></b-form-input>
                </b-form-group>
              </div>
            </b-col>
            <b-col cols="12" lg="2">
              <div>
                <b-form-group label="Harga Terkecil" class="mb-0">
                  <b-form-input id="exampleInputText1" type="text" placeholder="Harga Terkecil"></b-form-input>
                </b-form-group>
              </div>
            </b-col>
            <b-col cols="12" lg="2">
              <div>
                <b-form-group label="Diskon %" class="mb-0">
                  <b-form-input id="exampleInputText1" type="text" placeholder="Diskon %"></b-form-input>
                </b-form-group>
              </div>
            </b-col>
            <b-col cols="12" lg="2">
              <div>
                <b-form-group label="Diskon Rp" class="mb-0">
                  <div class="border radius">
                    <multiselect class="shadow-sm border-0 rounded" v-model="vselectJenisPendonor" :show-labels="false" :limit="3"
                      @input="valJenisPendonor" label="name" placeholder="Sudah Termasuk" :options="comboJenisPendonor"
                      :searchable="true">
                    </multiselect>
                  </div>
                </b-form-group>
              </div>
            </b-col>
            <b-col cols="12" lg="2">
              <div>
                <b-form-group label="Jumlah Nilai" class="mb-0">
                  <div class="border radius">
                    <multiselect class="shadow-sm border-0 rounded" v-model="vselectJenisPendonor" :show-labels="false" :limit="3"
                      @input="valJenisPendonor" label="name" placeholder="Sudah Termasuk" :options="comboJenisPendonor"
                      :searchable="true">
                    </multiselect>
                  </div>
                </b-form-group>
              </div>
            </b-col>
            <b-col cols="12" lg="1" class="mt-3">
              <b-button variant="success" class="w-100">Tambah</b-button>
            </b-col>
          </b-row>
        </div>
      </div>
    </div>
    <div class="bottom mb-4">
      <div class="bg-white shadow-sm radius-top">
        <div class="bg-primary radius-top px-3 py-3 mb-0">
          <b-row class="justify-content-between">
            <b-col cols="12" lg="3" class="d-flex align-items-center mr-auto">
              <p class="mb-0">Detail</p>
            </b-col>
          </b-row>
        </div>
        <div>
          <div>
            <b-table class="bg-white" ref="table" stacked="md" responsive striped hover show-empty :items="items"></b-table>
          </div>
        </div>
      </div>
    </div>
    <div class="jumlah total-bg radius px-4 py-3 shadow-sm">
      <b-row class="justify-content-end">
        <b-col cols="12" lg="4">
          <div class="px-5">
            <i class="fas fa-receipt fa-8x text-white"></i>
          </div>
        </b-col>
        <b-col cols="12" lg="8">
          <div class="text-right">
            <b-row class="mb-2 justify-content-end">
              <b-col cols="1">
                <p class="text-white mb-0">Jumlah</p>
              </b-col>
              <b-col cols="1">
                <p class="text-white mb-0">:</p>
              </b-col>
              <b-col cols="2">
                <p class="text-white mb-0">0</p>
              </b-col>
            </b-row>
            <b-row class="mb-2 justify-content-end">
              <b-col cols="1">
                <p class="text-white mb-0">PPN</p>
              </b-col>
              <b-col cols="1">
                <p class="text-white mb-0">:</p>
              </b-col>
              <b-col cols="2">
                <p class="text-white mb-0">0</p>
              </b-col>
            </b-row>
            <b-row class="mb-2 justify-content-end">
              <b-col cols="1">
                <p class="text-white mb-0">Diskon</p>
              </b-col>
              <b-col cols="1">
                <p class="text-white mb-0">:</p>
              </b-col>
              <b-col cols="2">
                <p class="text-white mb-0">0</p>
              </b-col>
            </b-row>
            <b-row class="mb-0 justify-content-end">
              <b-col cols="1">
                <p class="text-white mb-0">Total</p>
              </b-col>
              <b-col cols="1">
                <p class="text-white mb-0">:</p>
              </b-col>
              <b-col cols="2">
                <p class="text-white mb-0">0</p>
              </b-col>
            </b-row>
          </div>
        </b-col>
      </b-row>
    </div>
    <div class="history text-right mt-3">
      <b-button variant="warning" class="shadow-sm border mr-2" @click="historyPermintaanBarang"><i class="fas fa-undo"></i>History</b-button>
    </div>
    <b-modal ref="modal-history" size="lg" title="Histori Permintaan Barang" ok-title="Tutup" :ok-only="true"
      :no-close-on-backdrop="true" :static="true" :hide-header-close="true" centered>
      <div>
        <div class="bg-primary radius-top px-3 py-3">
          <b-row>
            <b-col class="d-flex align-items-center">
              <p class="text-white mb-0">History</p>
            </b-col>
            <b-col>
              <div>
                <b-form-group class="bg-white radius mb-0">
                  <b-form-input id="exampleInputText1" type="search" placeholder="Cari Nomor Faktur"></b-form-input>
                </b-form-group>
              </div>
            </b-col>
          </b-row>
        </div>
        <div>
          <b-table class="bg-white shadow-sm border" ref="table" stacked="md" striped hover show-empty :items="items2"></b-table>
        </div>
      </div>
    </b-modal>
  </div>
</template>
<script>
import Multiselect from 'vue-multiselect'
export default {
  name: 'PenerimaanPBF',
  components: {
    Multiselect
  },
  data () {
    return {
      items: [
        { nama_barang: 1, nomor_batch: 'Barang 1', qty_besar: 'Kategori 1', qty_kecil: 'Merk 1', qty_jumlah: 'Penyedia 1', harga_satuan_besar: 'Stok 1', harga_satuan_kecil: 'Status 1', harga_satuan_jumlah: 'No. Seri 1', Exd: 'No. Seri 1', diskon_persen: 'No. Seri 1', diskon_rupiah: 'No. Seri 1', total_harga: 'No. Seri 1' }
      ],
      fields: [
        'kode',
        { key: 'awal', label: 'Awal' },
        { key: 'masuk', label: 'Masuk' },
        { key: 'keluar', label: 'Keluar' },
        { key: 'jumlah', label: 'Type 2C' }
      ],
      comboJenisPendonor: [
        { name: 'Langsung', id: 1 },
        { name: 'Sukarela', id: 2 },
        { name: 'Pengganti', id: 3 },
        { name: 'Aferesis', id: 4 }
      ],
      vselectJenisPendonor: []
    }
  },
  methods: {
    historyPermintaanBarang () {
      this.$refs['modal-history'].show()
    }
  }
}
</script>
<style>
.total-bg {
  background: rgb(100, 100, 100);
}
</style>
