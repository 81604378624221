<template>
  <div id="e-kinerja" class="mb-4">
    <div v-show="isLoading">
        <div id="show-overlay"></div>
      <Loader :style="{ opacity: .5 }" />
    </div>
    <b-container fluid>
      <div class="bg-white letter-spacing-custom border-radius-custom-ekinerja shadow-sm px-3 py-3">
        <div class="border py-2 px-2">
          <b-row class="justify-content-between">
            <b-col cols="12" lg="3" class="pr-1 mb-2 mb-lg-0">
              <p class="text-center text-lg-left font-weight-bold text-dark h5 m-0 p-0">E-KINERJA</p>
            </b-col>
            <b-col cols="12" lg="1" class="pl-lg-1">
              <div class="w-100">
                <b-button class="w-100" size="sm" variant="primary" @click="create" v-b-popover.hover.top="'Tambah Kegiatan'"><i class="fas fa-plus"></i> </b-button>
              </div>
            </b-col>
          </b-row>
        </div>
        <div class="table-header border py-2 px-2">
          <b-row class="align-items-center">
            <b-col cols="6" lg="2" class="pr-lg-1 mb-2 mb-lg-0">
              <div>
                <b-form-input v-model="preview.filterTag" type="search" class="e-kinerja-filter" @input="tableRefresh" placeholder="Tag..."></b-form-input>
              </div>
            </b-col>
            <b-col cols="6" lg="3" class="px-lg-1 mb-2 mb-lg-0">
              <div>
                <b-form-input v-model="preview.filterUraian" type="search" class="e-kinerja-filter" @input="tableRefresh" placeholder="Uraian..."></b-form-input>
              </div>
            </b-col>
            <b-col cols="6" lg="2" class="px-lg-1 mb-2 mb-lg-0">
              <div>
                <multiselect :options="options.status" v-model="selected.status" @input="valueStatus" :allow-empty="false" label="uraian" :show-labels="false" placeholder="Tipe" :searchable="true">
                </multiselect>
              </div>
            </b-col>
            <b-col cols="6" lg="2" class="px-lg-1 mb-2 mb-lg-0">
              <div>
                <multiselect :options="options.statusKinerja" v-model="selected.statusKinerja" @input="valueStatusKinerja" :allow-empty="false" label="deskripsi" :show-labels="false" placeholder="Status" :searchable="true">
                </multiselect>
              </div>
            </b-col>
            <b-col cols="12" lg="2" class="px-lg-1 mb-2 mb-lg-0">
              <div>
                <flat-pickr :config="config" v-model="preview.tanggal" value="" @on-change="tglEvent" placeholder="[ Range Tanggal ]" class="w-100 calender-small custom-flat-pickr m-0">
                </flat-pickr>
              </div>
            </b-col>
            <b-col cols="12" lg="1" class="pl-lg-1 mb-2 mb-lg-0 ml-auto">
              <div class="w-100">
                <b-button size="sm" class="w-100 " variant="warning" @click="resetFilter" v-b-popover.hover.top="'Reset Filter'"> <i class="fas fa-undo"></i></b-button>
              </div>
            </b-col>
            <!-- <b-col cols="12" lg="1" class="pl-lg-1 ml-auto">
              <div class="w-100">
                <b-button class="w-100" size="sm" variant="primary" @click="create" v-b-popover.hover.top="'Tambah Kegiatan'"><i class="fas fa-plus"></i> </b-button>
              </div>
            </b-col> -->
          </b-row>
        </div>
        <div class="table table-body m-0">
          <b-table ref="table" class="m-0" :sort-by.sync="table.sortBy" :sort-desc.sync="table.sortDesc"
            :filter="table.filter" @filtered="onFiltered" :busy="table.isBusy" :current-page="table.currentPage"
            :per-page="table.perPage" responsive stacked="md" bordered hover show-empty small :items="table.items"
            :fields="table.fields" :select-mode="table.selectMode" selectable>
            <template #table-busy>
              <div class="text-center my-2">
                <b-spinner class="align-middle text-primary mr-2"></b-spinner>
                <strong class="text-dark ">Loading...</strong>
              </div>
            </template>
            <template #cell(index)="data">
              {{ (table.currentPage*table.perPage)-table.perPage + data.index + 1 }}
            </template>
            <template #cell(te.mulai)="data">
              <span>{{ timeStamp(tglWithDots(data.item)) }}</span>
            </template>
            <template #cell(te.selesai)="data">
              <span :class="timeStamp(tglEndWithDots(data.item)) === '00-00-000 00:00' ? 'text-secondary' : 'text-dark'">{{ timeStamp(tglEndWithDots(data.item)) }}</span>
            </template>
            <template #cell(te.status)="data">
              <span v-if="statusWithDots(data.item) === 1">Selesai <i class="fas fa-check-circle text-success"></i></span>
              <span class="text-secondary" v-else-if="statusWithDots(data.item) === 0">Terjadwal <i class="fas fa-calendar-week"></i></span>
              <span class="text-secondary" v-else-if="statusWithDots(data.item) === 2">Onprogress <i class="fas fa-spinner"></i></span>
              <span class="text-secondary" v-else-if="statusWithDots(data.item) === 8">Pending <i class="fas fa-hourglass-half tl-color"></i></span>
              <span v-else-if="statusWithDots(data.item) === 9">Tindak lanjut <i class="fas fa-check-circle text-success"></i></span>
            </template>
            <template #cell(action)="data">
              <span class="text-lg-center text-left d-flex flex-row">
                <b-button :id="'btn-admin-' + data.index" size="sm" variant="danger" class="w-100 m-1" @click="action(data.item.id, data.item.muraian, data.item.uraian, statusWithDots(data.item), tagWithDots(data.item), data.item.aksi, '1')"><i class="fas fa-users-cog"></i></b-button>
                <b-button :id="'btn-lihat-' + data.index" size="sm" variant="warning" class="w-100 m-1" @click="show(data.item.id)"><i class="fas fa-eye"></i></b-button>
                <b-button :id="'btn-aksi-' + data.index" size="sm" variant="primary" class="w-100 m-1" @click="action(data.item.id, data.item.muraian, data.item.uraian, statusWithDots(data.item), tagWithDots(data.item), data.item.aksi, '2')"><i class="fas fa-edit"></i></b-button>
              </span>
            </template>
          </b-table>
        </div>
        <div class="table-footer border">
          <b-row id="pagination-trx-remun" class="align-items-center px-2">
            <b-col cols="12" lg="3" class="d-flex flex-row align-items-center justify-content-center justify-content-lg-start mr-auto">
              <div>
                <b-pagination
                  v-model="table.currentPage"
                  :total-rows="table.totalRows"
                  :per-page="table.perPage"
                  size="md"
                  class="my-2"
                ></b-pagination>
              </div>
              <div>
                <span class="hover" @click="tableRefresh">
                  <i class="fas fa-sync text-primary"></i>
                </span>
              </div>
            </b-col>
            <b-col cols="12" lg="6" v-if="messageModal === 0">
              <div :class="['px-2 shadow-sm text-right mb-3 mb-lg-0 d-flex flex-row justify-content-end', messageColor]">
                <div class="mr-3">
                  <p class="small text-uppercase text-white m-0">{{ message }}</p>
                </div>
                <div>
                  <p class="small text-uppercase text-white m-0" @click="clearMessage"><i class="fas fa-times-circle"></i></p>
                </div>
              </div>
            </b-col>
            <b-col cols="12" lg="3" class="d-flex flex-row align-items-center justify-content-center justify-content-lg-end mb-3 mb-lg-0">
              <div class="mr-2">
                <b-form-select
                  class="cstm-select-remun-dash"
                  id="per-page-select"
                  v-model="table.perPage"
                  :options="table.pageOptions"
                ></b-form-select>
              </div>
              <div>
                <p class="text-dark text-center text-lg-right mb-0">Menampilkan {{ table.perPage }} dari {{ table.totalRows }} baris</p>
              </div>
            </b-col>
          </b-row>
        </div>
      </div>
    </b-container>
    <b-modal id="modal-ekinerja-view" ref="modal-ekinerja-view" title="LIHAT" size="lg" centered :no-close-on-backdrop="true" :static="true" :no-close-on-esc="true" ok-title="Simpan" @ok="onPatchShow">
      <div class="letter-spacing-custom mb-2" v-if="showData.length !== 0">
        <b-row>
          <b-col lg="6" class="pr-1">
            <div class="mb-0">
              <div class="mb-2">
                <p class="text-secondary mb-0">Tag</p>
                <b-form-input v-model="showData.tag" type="text" class="text-dark e-kinerja-filter" placeholder="Tag..."></b-form-input>
              </div>
              <div class="mb-2">
                <p class="text-secondary mb-0">Mulai</p>
                <flat-pickr :config="configShow" v-model="preview.tanggalMulaiShow" @on-change="tglEventShow($event, '1')" value="" placeholder="[ Tanggal Mulai ]" class="w-100 calender-small custom-flat-pickr m-0">
                </flat-pickr>
              </div>
              <div class="mb-0">
                <p class="text-secondary mb-0">Selesai</p>
                <flat-pickr :config="configShow" v-model="preview.tanggalAkhirShow" @on-change="tglEventShow($event, '2')" value="" placeholder="[ Tanggal Selesai ]" class="w-100 calender-small custom-flat-pickr m-0">
                </flat-pickr>
              </div>
            </div>
          </b-col>
          <b-col lg="6" class="pl-1">
            <div class="mb-0">
              <div class="mb-2">
                <p class="text-secondary mb-0">Tipe</p>
                <multiselect :options="options.status" v-model="selected.statusShow" @input="valueStatusShow" :allow-empty="false" label="uraian" :show-labels="false" placeholder="Pilih Tipe" :searchable="true">
                </multiselect>
              </div>
              <div class="mb-2">
                <p class="text-secondary mb-0">Uraian</p>
                <b-form-input v-model="showData.uraian" type="text" class="text-dark e-kinerja-filter" placeholder="Uraian..."></b-form-input>
              </div>
              <div class="mb-0">
                <p class="text-secondary mb-0">Status</p>
                <multiselect disabled :options="options.statusKinerja" v-model="selected.statusKinerjaShow" :allow-empty="false" label="deskripsi" :show-labels="false" placeholder="Pilih Status" :searchable="true">
                </multiselect>
              </div>
            </div>
          </b-col>
          <b-col lg="12" class="mt-2">
            <div>
              <div>
                <p class="text-secondary mb-0">Keterangan</p>
                <b-form-textarea v-model="showData.keterangan" rows="5" class="text-dark border-radius-custom-ekinerja-textarea" placeholder="Keterangan..."></b-form-textarea>
              </div>
            </div>
          </b-col>
        </b-row>
        <div :class="['px-2 shadow-sm text-right d-flex flex-row justify-content-end mt-2', messageColor]" v-if="messageModal === 1">
          <div class="mr-3">
            <p class="small text-uppercase text-white m-0">{{ message }}</p>
          </div>
          <div>
            <p class="small text-uppercase text-white m-0" @click="clearMessage"><i class="fas fa-times-circle"></i></p>
          </div>
        </div>
      </div>
    </b-modal>
    <b-modal id="modal-ekinerja-add" ref="modal-ekinerja-add" title="TAMBAH" size="lg" centered :no-close-on-backdrop="true" :static="true" :no-close-on-esc="true" cancel-title="Tutup" ok-title="Simpan" @ok="onCreateEvent">
      <div class="letter-spacing-custom">
        <div class="mb-2">
          <b-row>
            <b-col>
              <div>
                <p class="text-dark m-0">Jenis Kegiatan</p>
              </div>
            </b-col>
            <b-col cols="12" lg="9">
              <div>
                <multiselect :options="options.status" v-model="selected.statusForm" @input="valueStatusForm" :allow-empty="false" label="uraian" :show-labels="false" placeholder="Status" :searchable="true">
                </multiselect>
              </div>
            </b-col>
          </b-row>
        </div>
        <div class="mb-2">
          <b-row>
            <b-col>
              <div>
                <p class="text-dark m-0">Tag</p>
              </div>
            </b-col>
            <b-col cols="12" lg="9">
              <div>
                <b-form-input v-model="createEvent.tag" type="text" class="text-dark e-kinerja-filter" placeholder="Tag..."></b-form-input>
              </div>
            </b-col>
          </b-row>
        </div>
        <div class="mb-2">
          <b-row>
            <b-col>
              <div>
                <p class="text-dark m-0">Uraian</p>
              </div>
            </b-col>
            <b-col cols="12" lg="9">
              <div>
                <b-form-input v-model="createEvent.uraian" type="text" class="text-dark e-kinerja-filter" placeholder="Uraian..."></b-form-input>
              </div>
            </b-col>
          </b-row>
        </div>
        <div class="mb-2">
          <b-row>
            <b-col>
              <div>
                <p class="text-dark m-0">Keterangan</p>
              </div>
            </b-col>
            <b-col cols="12" lg="9">
              <div>
                <b-form-textarea v-model="createEvent.keterangan" rows="5" class="text-dark border-radius-custom-ekinerja-textarea" placeholder="Keterangan..."></b-form-textarea>
              </div>
            </b-col>
          </b-row>
        </div>
        <!-- <div :class="['ml-auto px-2 shadow-sm text-right', messageColor]" v-if="messageModal === 1">
          <p class="small text-uppercase text-white m-0">{{ message }}</p>
        </div> -->
        <div :class="['px-2 shadow-sm text-right d-flex flex-row justify-content-end', messageColor]" v-if="messageModal === 1">
          <div class="mr-3">
            <p class="small text-uppercase text-white m-0">{{ message }}</p>
          </div>
          <div>
            <p class="small text-uppercase text-white m-0" @click="clearMessage"><i class="fas fa-times-circle"></i></p>
          </div>
        </div>
      </div>
    </b-modal>
    <b-modal id="modal-ekinerja-action" ref="modal-ekinerja-action" title="LOG" size="xl" centered :no-close-on-backdrop="true" :static="true" :no-close-on-esc="true" :ok-only="true" ok-variant="secondary" ok-title="Tutup">
      <div class="letter-spacing-custom">
        <b-row>
          <b-col xl="6">
            <div class="px-3 py-2">
              <b-row>
                <b-col cols="12" lg="6" class="mb-2 mt-2">
                  <div class="text-center text-lg-left border-bottom border-top pt-1 pb-1">
                    <p class="m-0 p-0">TAG</p>
                    <p class="text-dark m-0 p-0">{{ preview.tag }}</p>
                  </div>
                </b-col>
                <b-col cols="12" lg="6" class="mb-2 mt-2">
                  <div class="text-center text-lg-left border-bottom border-top pt-1 pb-1">
                    <p class="m-0 p-0">TIPE</p>
                    <p class="text-dark m-0 p-0">{{ preview.tipe }}</p>
                  </div>
                </b-col>
                <b-col cols="12" lg="6" class="mb-2">
                  <div class="text-center text-lg-left border-bottom pb-1">
                    <p class="m-0 p-0">STATUS</p>
                    <p class="text-dark m-0 p-0">
                      <span v-if="preview.status === 1">Selesai</span>
                      <span v-else-if="preview.status === 0">Terjadwal</span>
                      <span v-else-if="preview.status === 2">On Progress</span>
                      <span v-else-if="preview.status === 8">Pending</span>
                      <span v-else-if="preview.status === 9">Tindak Lanjut</span>
                    </p>
                  </div>
                </b-col>
                <b-col cols="12" lg="6">
                  <div class="text-center text-lg-left border-bottom pb-1">
                    <p class="m-0 p-0">URAIAN</p>
                    <p class="text-dark m-0 p-0">{{ preview.uraianLog }}</p>
                  </div>
                </b-col>
              </b-row>
            </div>
          </b-col>
          <b-col xl="6">
            <div class="py-2" v-if="preview.paramAction === '1'">
              <b-row class="align-items-center px-2">
                <b-col cols="6" xl="3" class="pr-1 mb-2 mb-xl-0">
                  <div>
                    <b-button size="sm" variant="primary" class="w-100" @click="actionB"><i class="fas fa-plus"></i> Tambah</b-button>
                  </div>
                </b-col>
                <b-col cols="6" xl="3" class="pr-xl-1 pl-1 mb-2 mb-xl-0">
                  <div>
                    <b-button size="sm" variant="success" class="w-100" @click="onUpdateEvent('1')"> Selesai</b-button>
                  </div>
                </b-col>
                <b-col cols="6" xl="3" class="pr-1 pl-xl-1 mb-2 mb-xl-0">
                  <div>
                    <b-button size="sm" variant="success" class="w-100" @click="onUpdateEvent('2')"> Bersambung</b-button>
                  </div>
                </b-col>
                <b-col cols="6" xl="3" class="pl-1 mb-2 mb-xl-0">
                  <div>
                    <b-button size="sm" variant="warning" class="w-100" @click="onUpdateEvent('3')"> Pending</b-button>
                  </div>
                </b-col>
              </b-row>
            </div>
            <div class="py-2" v-else-if="preview.paramAction === '2'">
              <b-row class="align-items-center px-2">
                <b-col cols="6" xl="3" class="pr-1 mb-2 mb-xl-0">
                  <div>
                    <b-button :disabled="preview.status === 1 ? true : false" size="sm" :variant="preview.status === 1 ? 'secondary' : 'primary'" class="w-100" @click="actionB"><i class="fas fa-plus"></i> Tambah</b-button>
                  </div>
                </b-col>
                <b-col cols="6" xl="3" class="pr-xl-1 pl-1 mb-2 mb-xl-0">
                  <div>
                    <b-button :disabled="preview.status === 1 || formLog.detail[0].id === '' ? true : false" size="sm" :variant="preview.status === 1 || formLog.detail[0].id === '' ? 'secondary' : 'success'" class="w-100" @click="onUpdateEvent('1')"> Selesai</b-button>
                  </div>
                </b-col>
                <b-col cols="6" xl="3" class="pr-1 pl-xl-1 mb-2 mb-xl-0">
                  <div>
                    <b-button :disabled="preview.status === 1 || formLog.detail[0].id === '' ? true : false" size="sm" :variant="preview.status === 1 || formLog.detail[0].id === '' ? 'secondary' : 'success'" class="w-100" @click="onUpdateEvent('2')"> Bersambung</b-button>
                  </div>
                </b-col>
                <b-col cols="6" xl="3" class="pl-1 mb-2 mb-xl-0">
                  <div>
                    <b-button :disabled="preview.status === 1 || formLog.detail[0].id === '' ? true : false" size="sm" :variant="preview.status === 1 || formLog.detail[0].id === '' ? 'secondary' : 'warning'" class="w-100" @click="onUpdateEvent('3')"> Pending</b-button>
                  </div>
                </b-col>
              </b-row>
            </div>
          </b-col>
        </b-row>
        <div class="table-responsive mb-0">
          <table class="table bg-white table-bordered m-0 p-0">
            <thead>
              <b-tr>
                <b-th variant="white" class="py-2 text-center text-uppercase">No</b-th>
                <b-th variant="white" class="py-2 text-center text-uppercase">Uraian</b-th>
              </b-tr>
            </thead>
            <tbody>
              <b-tr v-for="(p, index) in formLog.detail" v-bind:key="index">
                <b-td @click="issue(p.aksi, p.id, p.uraian, p.id_event)">
                  <div class="d-flex flex-row text-center justify-content-between hover py-2">
                    <div class="mr-1">
                      <p class="m-0 p-0 text-primary font-weight-bold">{{ p.no_urut }}</p>
                    </div>
                    <div>
                      <span v-if="p.no_urut">
                        <i :class="['fas m-0 p-0 text-primary font-weight-bold', p.aksi === 1 ? 'fa-edit' : 'fa-share']"></i>
                      </span>
                    </div>
                  </div>
                </b-td>
                <b-td class="w-100 p-0">
                  <div>
                    <b-form-textarea disabled v-model="formLog.detail[index].uraian" rows="3" class="text-dark font-italic font-weight-bold bg-white border-radius-custom-ekinerja-textarea rounded-0" placeholder="Keterangan..."></b-form-textarea>
                  </div>
                </b-td>
              </b-tr>
            </tbody>
          </table>
        </div>
        <!-- <div :class="['ml-auto px-2 shadow-sm text-right mb-2', messageColor]" v-if="messageModal === 1">
          <p class="small text-uppercase text-white m-0">{{ message }}</p>
        </div> -->
        <div :class="['px-2 shadow-sm text-right d-flex flex-row justify-content-end', messageColor]" v-if="messageModal === 1">
          <div class="mr-3">
            <p class="small text-uppercase text-white m-0">{{ message }}</p>
          </div>
          <div>
            <p class="small text-uppercase text-white m-0" @click="clearMessage"><i class="fas fa-times-circle"></i></p>
          </div>
        </div>
      </div>
    </b-modal>
    <b-modal id="modal-ekinerja-tambah-log" ref="modal-ekinerja-tambah-log" title="TAMBAH LOG" size="lg" centered :no-close-on-backdrop="true" :static="true" :no-close-on-esc="true" cancel-title="Tutup" ok-title="Simpan" @ok="onCreateEventLog">
      <div class="letter-spacing-custom">
        <div>
          <div>
            <b-form-textarea v-model="createEventLog.uraian" rows="8" class="text-dark text-area-radius" placeholder="Uraian..."></b-form-textarea>
          </div>
        </div>
        <!-- <div :class="['ml-auto px-2 shadow-sm text-right', messageColor]" v-if="messageModal === 2">
          <p class="small text-uppercase text-white m-0">{{ message }}</p>
        </div> -->
        <div :class="['px-2 shadow-sm text-right d-flex flex-row justify-content-end', messageColor]" v-if="messageModal === 2">
          <div class="mr-3">
            <p class="small text-uppercase text-white m-0">{{ message }}</p>
          </div>
          <div>
            <p class="small text-uppercase text-white m-0" @click="clearMessage"><i class="fas fa-times-circle"></i></p>
          </div>
        </div>
      </div>
    </b-modal>
    <b-modal id="modal-ekinerja-edit-log" ref="modal-ekinerja-edit-log" title="EDIT LOG" size="lg" centered :no-close-on-backdrop="true" :static="true" :no-close-on-esc="true" cancel-title="Tutup" ok-title="Simpan" @ok="onUpdatEventLog">
      <div class="letter-spacing-custom">
        <div>
          <div>
            <b-form-textarea v-model="editEventLog.uraian" rows="8" class="text-dark text-area-radius" placeholder="Uraian..."></b-form-textarea>
          </div>
        </div>
        <!-- <div :class="['ml-auto px-2 shadow-sm text-right', messageColor]" v-if="messageModal === 2">
          <p class="small text-uppercase text-white m-0">{{ message }}</p>
        </div> -->
        <div :class="['px-2 shadow-sm text-right d-flex flex-row justify-content-end', messageColor]" v-if="messageModal === 2">
          <div class="mr-3">
            <p class="small text-uppercase text-white m-0">{{ message }}</p>
          </div>
          <div>
            <p class="small text-uppercase text-white m-0" @click="clearMessage"><i class="fas fa-times-circle"></i></p>
          </div>
        </div>
      </div>
    </b-modal>
    <b-modal id="modal-ekinerja-create-issue" ref="modal-ekinerja-create-issue" header-class="text-uppercase" :title="'ISSUE : ' + this.preview.tipe + ', '  + this.preview.tag" size="xl" centered :no-close-on-backdrop="true" :static="true" :no-close-on-esc="true" cancel-title="Tutup" ok-title="Tutup" :ok-only="true" ok-variant="secondary">
      <div class="letter-spacing-custom">
        <b-row class="align-items-center justify-content-between">
          <b-col cols="12">
            <div class="text-center text-lg-left mb-2">
              <!-- <p class="m-0 p-0 text-uppercase">Uraian Log <span class="text-dark font-weight-bold">{{ preview.uraian }}</span></p> -->
              <b-form-textarea disabled v-model="preview.uraian" rows="4" class="bg-white text-dark font-italic font-weight-bold border-radius-custom-ekinerja-textarea rounded-0" placeholder="Keterangan..."></b-form-textarea>
            </div>
          </b-col>
          <b-col cols="12" lg="2">
            <div class="text-center text-lg-left">
              <b-button variant="primary" class="border-radius-custom-ekinerja shadow-sm w-100" size="sm" @click="modalCreateIssue"><i class="fas fa-plus"></i> Create Issue</b-button>
            </div>
          </b-col>
        </b-row>
        <div class="mb-0 mt-2">
          <table class="table bg-white table-bordered m-0 p-0">
            <thead>
              <b-tr>
                <b-th variant="white" class="py-2 text-center text-uppercase">Uraian</b-th>
                <b-th variant="white" class="py-2 text-center text-uppercase">Assign</b-th>
              </b-tr>
            </thead>
            <tbody>
              <b-tr v-for="(p, index) in formIssue.detail" v-bind:key="index">
                <b-td>
                  <div class="py-2" v-if="formIssue.detail[index].id !== ''">
                    <p class="mb-0">{{ p.uraian }}
                      <b-button :disabled="p.jml_assign === 0 ? true : false" :variant="p.jml_assign === 0 ? 'secondary' : 'primary'" class="border-radius-custom-ekinerja shadow-sm ml-2" size="sm" @click="loadPic(p.id, p.uraian)"><i class="fas fa-user"></i> {{ p.jml_assign }}</b-button>
                    </p>
                  </div>
                </b-td>
                <b-td class="col-2">
                  <div class="text-center py-2" v-if="formIssue.detail[index].id !== ''">
                    <b-button :disabled="p.jml_assign === 0 ? false : true" :variant="p.jml_assign === 0 ? 'primary' : 'secondary'" class="border-radius-custom-ekinerja shadow-sm" size="sm" @click="modalAssign(index, p.id, p.uraian)"><i class="fas fa-share"></i> Assign Issue</b-button>
                  </div>
                </b-td>
              </b-tr>
            </tbody>
          </table>
        </div>
        <div :class="['px-2 shadow-sm text-right d-flex flex-row justify-content-end mt-2', messageColor]" v-if="messageModal === 3">
          <div class="mr-3">
            <p class="small text-uppercase text-white m-0">{{ message }}</p>
          </div>
          <div>
            <p class="small text-uppercase text-white m-0" @click="clearMessage"><i class="fas fa-times-circle"></i></p>
          </div>
        </div>
      </div>
    </b-modal>
    <b-modal id="modal-ekinerja-create-issue-event" ref="modal-ekinerja-create-issue-event" header-class="text-uppercase" title="CREATE ISSUE" size="lg" centered :no-close-on-backdrop="true" :static="true" :no-close-on-esc="true" cancel-title="Tutup" ok-title="Simpan" @ok="onCreateIssue">
      <div class="letter-spacing-custom">
        <div>
          <b-form-textarea v-model="createIssue.uraian" rows="8" class="text-dark border-radius-custom-ekinerja-textarea rounded-0" placeholder="Uraian..."></b-form-textarea>
        </div>
        <div>
          <b-form-input v-model="createIssue.url_referensi" type="text" class="text-dark e-kinerja-filter rounded-0" placeholder="URL..."></b-form-input>
        </div>
        <div :class="['px-2 shadow-sm text-right d-flex flex-row justify-content-end mt-2', messageColor]" v-if="messageModal === 4">
          <div class="mr-3">
            <p class="small text-uppercase text-white m-0">{{ message }}</p>
          </div>
          <div>
            <p class="small text-uppercase text-white m-0" @click="clearMessage"><i class="fas fa-times-circle"></i></p>
          </div>
        </div>
      </div>
    </b-modal>
    <b-modal id="modal-assign-issue" ref="modal-assign-issue" header-class="text-uppercase" title="ASSIGN ISSUE" size="lg" centered :no-close-on-backdrop="true" :static="true" :no-close-on-esc="true" cancel-title="Tutup" ok-title="Simpan" @ok="onCreateAssign">
      <div class="letter-spacing-custom">
        <div>
          <p class="m-0 p-0 text-center text-lg-left text-dark">{{ preview.assignTitle }} <i class="far fa-check-circle text-primary"></i></p>
        </div>
        <hr>
        <div class="mb-3">
          <b-row>
            <b-col cols="12" lg="3">
              <b-button variant="primary"  class="border-radius-custom-ekinerja shadow-sm w-100" size="sm" @click="addRowAssign"> <i class="fas fa-plus"></i>Tambah &nbsp;  <i class="fas fa-user"></i>PIC </b-button>
            </b-col>
          </b-row>
        </div>
        <div v-for="(p, index) in formAssign.detail" :key="index">
          <b-row class="align-items-center">
            <b-col cols="12" lg="6" class="pr-lg-1 mb-2 mb-lg-0">
              <div>
                <multiselect :options="options.assign" v-model="selected.assign[index].detail" @input="valueAssign($event, index)" :allow-empty="false" label="nama" :show-labels="false" placeholder="PIC" :searchable="true">
                </multiselect>
              </div>
            </b-col>
            <b-col cols="12" lg="5" class="px-lg-1">
              <div>
                <multiselect :options="options.assignType" v-model="selected.assignType[index].detail" @input="valueAssignType($event, index)" :allow-empty="false" label="uraian" :show-labels="false" placeholder="Assign Type" :searchable="true">
                </multiselect>
              </div>
            </b-col>
            <b-col cols="12" lg="1" class="pl-lg-1 mt-2 mt-lg-0">
              <div class="text-center">
                <b-button :disabled="index === 0 ? true : false" :variant="index === 0 ? 'secondary' : 'danger'" class="w-100" size="sm" @click="deleteRowAssign(index)"><i class="fas fa-trash"></i></b-button>
              </div>
            </b-col>
          </b-row>
          <hr>
        </div>
        <div :class="['px-2 shadow-sm text-right d-flex flex-row justify-content-end mt-2', messageColor]" v-if="messageModal === 4">
          <div class="mr-3">
            <p class="small text-uppercase text-white m-0">{{ message }}</p>
          </div>
          <div>
            <p class="small text-uppercase text-white m-0" @click="clearMessage"><i class="fas fa-times-circle"></i></p>
          </div>
        </div>
      </div>
    </b-modal>
    <b-modal id="modal-ekinerja-pic-issue-event" ref="modal-ekinerja-pic-issue-event" header-class="text-uppercase" title="PIC LIST" size="md" centered :no-close-on-backdrop="true" :static="true" :no-close-on-esc="true" cancel-title="Tutup" ok-title="Tutup" ok-variant="secondary" :ok-only="true">
      <div class="letter-spacing-custom">
        <div>
          <p class="m-0 p-0 text-dark text-center text-lg-left">{{ preview.assignTitle }} <i class="far fa-check-circle text-primary"></i></p>
        </div>
        <hr>
        <div v-for="(p, index) in formPic" :key="index">
          <div :class="['d-flex flex-row flex-wrap align-items-center', index === formPic.length - 1 ? 'mb-1' : 'mb-3']">
            <div class="mr-2">
              <p class="text-dark m-0 p-0"><i class="fas fa-user"></i> {{ p.nama }} <i class="fas fa-long-arrow-alt-right"></i></p>
            </div>
            <div class="bg-primary px-2 shadow-sm border-radius-custom-ekinerja">
              <p class="text-white m-0 p-0">{{ p.uraian }}</p>
            </div>
          </div>
        </div>
        <div :class="['px-2 shadow-sm text-right d-flex flex-row justify-content-end mt-2', messageColor]" v-if="messageModal === 4">
          <div class="mr-3">
            <p class="small text-uppercase text-white m-0">{{ message }}</p>
          </div>
          <div>
            <p class="small text-uppercase text-white m-0" @click="clearMessage"><i class="fas fa-times-circle"></i></p>
          </div>
        </div>
      </div>
    </b-modal>
  </div>
</template>
<script>
import { xray } from '../../../config/pluginInit'
import newServices from '../../../services/fatmahost/pegawai/index.ts'
import flatPickr from 'vue-flatpickr-component'
import 'flatpickr/dist/flatpickr.css'
import Multiselect from 'vue-multiselect'

export default {
  name: 'E-Kinerja',
  components: {
    flatPickr,
    Multiselect
  },
  data () {
    return {
      isLoading: false,
      table: {
        isBusy: false,
        items: this.list,
        sortBy: '',
        sortDesc: false,
        fields: [
          { label: 'NO.', key: 'index', class: 'text-lg-center' },
          { label: 'ID', key: 'id', class: 'text-lg-center', sortable: true },
          { label: 'TGL. MULAI', key: 'te.mulai', sortable: true },
          { label: 'TGL. SELESAI', key: 'te.selesai', sortable: true },
          { label: 'TIPE', key: 'muraian', sortable: true },
          { label: 'TAG', key: 'te.tag', sortable: true },
          { label: 'URAIAN', key: 'uraian', sortable: true },
          { label: 'STATUS', key: 'te.status', sortable: true },
          { key: 'action', label: 'AKSI', class: 'text-center' }
        ],
        filter: null,
        currentPage: null,
        perPage: null,
        pageOptions: [10, 25, 50, 100],
        totalRows: null,
        selectMode: 'single',
        selected: []
      },
      createEvent: {
        object: 'tkinerja-event',
        id_mtype: '',
        tag: '',
        uraian: '',
        keterangan: '',
        created_by: xray.currentUser().id.toString()
      },
      formLog: {
        detail: [
          {
            id: '',
            id_event: '',
            muraian: '',
            no_urut: '',
            rowcount: '',
            statcode: '',
            temulai: '',
            teselesai: '',
            uraian: '',
            aksi: ''
          }
        ]
      },
      createEventLog: {
        object: 'tkinerja-event-log',
        id_event: '',
        uraian: '',
        created_by: xray.currentUser().id.toString()
      },
      editEventLog: {
        object: 'tkinerja-event-log',
        uraian: ''
      },
      createIssue: {
        object: 'tkinerja-issue',
        id_event_log: '',
        uraian: '',
        url_referensi: '',
        created_by: xray.currentUser().id.toString()
      },
      formIssue: {
        object: 'tkinerja-assign-issue',
        id_issue: '',
        created_by: xray.currentUser().id.toString(),
        detail: [
          {
            statcode: '',
            rowcount: '',
            id: '',
            id_eventlog: '',
            uraian: '',
            status: '',
            created_by: '',
            name: '',
            pic: '',
            assign_type: '',
            //
            jml_assign: ''
          }
        ]
      },
      formAssign: {
        object: 'tkinerja-assign-issue',
        id_issue: '',
        created_by: xray.currentUser().id.toString(),
        detail: [
          {
            pic: '',
            assign_type: ''
          }
        ]
      },
      formPic: [
        {
          nama: '',
          nip: '',
          uraian: ''
        }
      ],
      showData: {
        object: 'tkinerja-event-patch',
        id_mtype: '',
        id: '',
        tag: '',
        uraian: '',
        keterangan: '',
        mulai: '',
        selesai: '',
        //
        status: '',
        muraian: '',
        created_by: '',
        created_event: ''
      },
      options: {
        status: [],
        statusKinerja: [],
        assign: [],
        assignType: []
      },
      selected: {
        status: [],
        statusKinerja: [],
        statusShow: [],
        statusKinerjaShow: [],
        statusForm: [],
        assign: [
          {
            detail: []
          }
        ],
        assignType: [
          {
            detail: []
          }
        ]
      },
      preview: {
        id: '',
        idIssue: '',
        idLog: '',
        assignIndex: 0,
        assignTitle: '',
        paramAction: '2',
        //
        tanggal: '',
        tanggalAwal: '',
        tanggalAkhir: '',
        //
        tanggalMulaiShow: '',
        tanggalAkhirShow: '',
        //
        filterTag: '',
        filterUraian: '',
        filterStatus: '',
        filterStatusKinerja: '',
        //
        tag: '',
        status: '',
        tipe: '',
        //
        uraianLog: '',
        uraian: ''
      },
      config: {
        dateFormat: 'd-m-Y',
        disableMobile: true,
        mode: 'range'
      },
      configShow: {
        dateFormat: 'd-m-Y',
        disableMobile: true
      },
      message: '',
      messageColor: '',
      messageModal: null
    }
  },
  mounted () {
    xray.index()
    this.getStatus()
    this.getStatusKinerja()
    this.getAssign()
    this.getAssignType()
  },
  methods: {
    timeStamp (value) {
      if (value) {
        let currentDate = new Date(value)
        let day = currentDate.getDate()
        let month = (currentDate.getMonth() + 1).toString().padStart(2, 0)
        let year = currentDate.getFullYear()
        let hours = currentDate.getHours()
        let minutes = currentDate.getMinutes()
        if (day < 10) {
          day = '0' + day
        }
        if (hours < 10) {
          hours = '0' + hours
        }
        if (minutes < 10) {
          minutes = '0' + minutes
        }
        let final = day + '-' + month + '-' + year + ' ' + hours + ':' + minutes
        return final
      } else {
        return '00-00-000 00:00'
      }
    },
    tglEvent ($event) {
      if ($event[1] !== undefined) {
        let date = new Date($event[0])
        let day = date.getDate()
        let month = (date.getMonth() + 1).toString().padStart(2, 0)
        let year = date.getFullYear()
        if (day < 10) {
          day = '0' + day
        }
        //
        let date2 = new Date($event[1])
        let day2 = date2.getDate()
        let month2 = (date2.getMonth() + 1).toString().padStart(2, 0)
        let year2 = date2.getFullYear()
        if (day2 < 10) {
          day2 = '0' + day2
        }
        let currentDate = year + '-' + month + '-' + day
        let currentDate2 = year2 + '-' + month2 + '-' + day2
        this.preview.tanggalAwal = currentDate
        this.preview.tanggalAkhir = currentDate2
        this.$refs.table.refresh()
      }
    },
    tglEventShow ($event, value) {
      if ($event === null || $event === '') {
        if (value === '1') {
          this.showData.mulai = '0000-00-00'
        } else if (value === '2') {
          this.showData.selesai = '0000-00-00'
        }
      } else {
        let date = new Date($event)
        let year = date.getFullYear()
        let month = date.getMonth() + 1
        let day = date.getDate()
        if (month < 10) {
          month = '0' + month
        }
        if (day < 10) {
          day = '0' + day
        }
        let joinDate = year + '-' + month + '-' + day
        if (value === '1') {
          this.showData.mulai = joinDate
        } else if (value === '2') {
          this.showData.selesai = joinDate
        }
      }
    },
    async getStatus () {
      try {
        const response = await newServices.mworktype()
        if (response.data.statcode === 0) {
          this.options.status = response.data.data
        } else {
          this.options.status = []
        }
      } catch (error) {
        this.message = error.message
      }
    },
    async getStatusKinerja () {
      try {
        const response = await newServices.stskinerja()
        if (response.data.statcode === 0) {
          this.options.statusKinerja = response.data.data
        } else {
          this.options.statusKinerja = []
        }
      } catch (error) {
        this.message = error.message
      }
    },
    async getAssign () {
      try {
        const response = await newServices.assign()
        if (response.data.statcode === 0) {
          this.options.assign = response.data.data
        } else {
          this.options.assign = []
        }
      } catch (error) {
        this.message = error.message
      }
    },
    async getAssignType () {
      try {
        const response = await newServices.assignType()
        if (response.data.statcode === 0) {
          this.options.assignType = response.data.data
        } else {
          this.options.assignType = []
        }
      } catch (error) {
        this.message = error.message
      }
    },
    async list (ctx) {
      this.table.isBusy = true
      let sortfield = ''
      let sorting = ''
      let userid = xray.currentUser().id.toString()
      let tag = this.preview.filterTag
      let uraian = this.preview.filterUraian
      let status = this.preview.filterStatus
      let statusKin = this.preview.filterStatusKinerja
      let tglAwal = this.preview.tanggalAwal
      let tglAkhir = this.preview.tanggalAkhir
      if (ctx.sortBy) {
        sortfield = ctx.sortBy
      } else {
        sortfield = ''
      }
      if (ctx.sortDesc === true) {
        sorting = 'ASC'
      } else if (ctx.sortDesc === false) {
        sorting = 'DESC'
      } else {
        sorting = ''
      }
      if (ctx.filter === null) {
        ctx.filter = ''
      }
      try {
        let response = await newServices.findOrAllTrxKinerja(ctx.currentPage - 1, ctx.perPage, sortfield, sorting, tag, uraian, status, statusKin, userid, tglAwal, tglAkhir)
        if (response.status === 200) {
          this.table.isBusy = false
          this.table.totalRows = response.data.pagination.total
          this.table.perPage = response.data.pagination.perPage
          this.table.currentPage = response.data.pagination.current
          return response.data.data
        } else {
          this.table.isBusy = false
          return []
        }
      } catch (error) {
        this.table.isBusy = false
        this.message = error.message
        return []
      }
    },
    valueStatus (value) {
      this.preview.filterStatus = value.id.toString()
      this.$refs.table.refresh()
    },
    valueStatusShow (value) {
      this.showData.id_mtype = value.id.toString()
    },
    valueStatusKinerja (value) {
      this.preview.filterStatusKinerja = value.id.toString()
      this.$refs.table.refresh()
    },
    valueStatusForm (value) {
      this.createEvent.id_mtype = value.id.toString()
    },
    tglWithDots (item) {
      return item['te.mulai']
    },
    tglEndWithDots (item) {
      return item['te.selesai']
    },
    statusWithDots (item) {
      return item['te.status']
    },
    tagWithDots (item) {
      return item['te.tag']
    },
    onFiltered (filteredItems) {
      this.table.totalRows = filteredItems.length
    },
    // onFilteredB (filteredItems) {
    //   this.tableB.totalRows = filteredItems.length
    // },
    tableRefresh () {
      this.$refs.table.refresh()
    },
    async show (id) {
      this.preview.id = id.toString()
      this.isLoading = true
      try {
        let data = {
          param0: 'tkinerja-event'
        }
        let response = await newServices.showTrxKinerja(id.toString(), data)
        if (response.data.statcode === 0) {
          this.isLoading = false
          this.showData.id = response.data.data[0].id.toString()
          this.showData.tag = response.data.data[0].tag
          this.showData.status = response.data.data[0].status.toString()
          this.showData.uraian = response.data.data[0].uraian
          this.showData.muraian = response.data.data[0].muraian
          this.showData.id_mtype = response.data.data[0].id_mtype.toString()
          this.showData.created_by = response.data.data[0].created_by.toString()
          this.showData.keterangan = response.data.data[0].keterangan
          this.showData.created_event = response.data.data[0].created_event.toString()
          //
          this.selected.statusShow = { id: response.data.data[0].id_mtype, uraian: response.data.data[0].muraian }
          this.selected.statusKinerjaShow = { id: response.data.data[0].status, deskripsi: response.data.data[0].status === 1 ? 'Selesai' : 'On Progress' }
          //
          this.preview.tanggalMulaiShow = response.data.data[0].mulai !== null ? new Date(response.data.data[0].mulai) : ''
          this.preview.tanggalAkhirShow = response.data.data[0].selesai !== null ? new Date(response.data.data[0].selesai) : ''
          //
          this.$refs['modal-ekinerja-view'].show()
        } else {
          this.isLoading = false
          this.message = response.data.message
        }
      } catch (error) {
        this.isLoading = false
        this.message = error.message
      }
    },
    create () {
      this.clearMessage()
      this.clearCreateForm()
      this.createEventLog.uraian = ''
      this.$refs['modal-ekinerja-add'].show()
    },
    async onCreateEvent (bvModalEvent) {
      bvModalEvent.preventDefault()
      this.isLoading = true
      try {
        let response = await newServices.createTrxKinerja(this.createEvent)
        if (response.data.statcode === 0) {
          this.isLoading = false
          this.$refs['modal-ekinerja-add'].hide()
          this.messageModal = 0
          this.messageColor = 'bg-primary'
          this.message = response.data.message
          this.clearCreateForm()
          this.$refs.table.refresh()
        } else {
          this.isLoading = false
          this.messageModal = 1
          this.messageColor = 'bg-danger'
          this.message = response.data.message
        }
      } catch (error) {
        this.isLoading = false
        this.messageModal = 1
        this.messageColor = 'bg-danger'
        this.message = error.message
      }
    },
    async action (eventid, muraian, uraian, status, tag, aksi, param) {
      this.isLoading = true
      this.preview.id = eventid.toString()
      this.createEventLog.id_event = eventid.toString()
      this.preview.paramAction = param
      this.preview.tag = tag
      this.preview.status = status
      this.preview.tipe = muraian
      this.preview.uraianLog = uraian
      this.preview.aksi = aksi
      this.createEventLog.uraian = ''
      this.clearMessage()
      this.clearArr()
      try {
        let response = await newServices.findLogTrxKinerja(eventid.toString())
        if (response.data.data) {
          this.isLoading = false
          this.deleteRow(0)
          let data = response.data.data
          data.map((value, index) => {
            this.formLog.detail.push({
              id: value.id,
              id_event: value.id_event,
              muraian: value.muraian,
              no_urut: value.no_urut,
              rowcount: value.rowcount,
              statcode: value.statcode,
              temulai: '',
              teselesai: '',
              uraian: value.uraian,
              aksi: aksi
            })
          })
        } else {
          this.isLoading = false
          this.messageModal = 1
          this.messageColor = 'bg-danger'
          this.message = response.data.message
        }
      } catch (error) {
        this.isLoading = false
        this.messageModal = 1
        this.messageColor = 'bg-danger'
        this.message = error.message
      }
      this.$refs['modal-ekinerja-action'].show()
    },
    async actionReload () {
      this.isLoading = true
      try {
        let response = await newServices.findLogTrxKinerja(this.preview.id)
        if (response.data.data) {
          this.isLoading = false
          this.deleteRow(0)
          let data = response.data.data
          data.map((value, index) => {
            this.formLog.detail.push({
              id: value.id,
              id_event: value.id_event,
              muraian: value.muraian,
              no_urut: value.no_urut,
              rowcount: value.rowcount,
              statcode: value.statcode,
              temulai: '',
              teselesai: '',
              uraian: value.uraian,
              aksi: this.preview.aksi
            })
          })
        } else {
          this.isLoading = false
        }
      } catch (error) {
        this.isLoading = false
        this.message = error.message
      }
    },
    actionB () {
      this.clearMessage()
      this.createEventLog.uraian = ''
      this.$refs['modal-ekinerja-tambah-log'].show()
    },
    async onPatchShow (bvModalEvent) {
      bvModalEvent.preventDefault()
      if (this.showData.selesai === 'NaN-NaN-NaN') {
        this.showData.selesai = null
      }
      this.isLoading = true
      try {
        let response = await newServices.patchTrxKinerja(this.preview.id, this.showData)
        if (response.data.statcode === 0) {
          this.isLoading = false
          this.$refs['modal-ekinerja-view'].hide()
          this.messageModal = 0
          this.messageColor = 'bg-primary'
          this.message = response.data.message
          this.$refs.table.refresh()
        } else {
          this.isLoading = false
          this.messageModal = 1
          this.messageColor = 'bg-danger'
          this.message = response.data.message
        }
      } catch (error) {
        this.isLoading = false
        this.messageModal = 1
        this.messageColor = 'bg-danger'
        this.message = error.message
      }
    },
    async onCreateEventLog (bvModalEvent) {
      this.isLoading = true
      bvModalEvent.preventDefault()
      if (this.createEventLog.uraian !== '') {
        try {
          let response = await newServices.createTrxKinerja(this.createEventLog)
          if (response.data.statcode === 0) {
            this.isLoading = false
            this.$refs['modal-ekinerja-tambah-log'].hide()
            this.messageModal = 1
            this.messageColor = 'bg-primary'
            this.message = response.data.message
            this.createEventLog.uraian = ''
            this.clearArr()
            this.actionReload()
          } else {
            this.isLoading = false
            this.messageModal = 2
            this.messageColor = 'bg-danger'
            this.message = response.data.message
          }
        } catch (error) {
          this.isLoading = false
          this.messageModal = 2
          this.messageColor = 'bg-danger'
          this.message = error.message
        }
      } else {
        this.isLoading = false
        this.messageModal = 2
        this.messageColor = 'bg-danger'
        this.message = 'Uraian Tidak Boleh Kosong'
      }
    },
    async onUpdateEvent (value) {
      this.isLoading = true
      try {
        let data = {
          object: 'tkinerja-event',
          status: value
        }
        let response = await newServices.updateTrxKinerja(this.preview.id, data)
        if (response.data.statcode === 0) {
          this.isLoading = false
          this.$refs['modal-ekinerja-action'].hide()
          this.messageModal = 0
          this.messageColor = 'bg-primary'
          this.message = response.data.message
          this.$refs.table.refresh()
        } else {
          this.isLoading = false
          this.messageModal = 1
          this.messageColor = 'bg-danger'
          this.message = response.data.message
        }
      } catch (error) {
        this.isLoading = false
        this.messageModal = 1
        this.messageColor = 'bg-danger'
        this.message = error.message
      }
    },
    async issue (param, id, uraian, idEvent) {
      this.preview.idIssue = id
      this.preview.idLog = idEvent
      this.preview.uraian = uraian
      this.editEventLog.uraian = uraian
      // this.createIssue.uraian = uraian
      this.createIssue.id_event_log = id.toString()
      if (param === 1) {
        this.$refs['modal-ekinerja-edit-log'].show()
      } else if (param === 2) {
        this.loadIssue(id)
      }
    },
    async onUpdatEventLog (bvModalEvent) {
      bvModalEvent.preventDefault()
      this.isLoading = true
      if (this.editEventLog.uraian !== '') {
        try {
          const response = await newServices.updateTrxKinerja(this.preview.idIssue, this.editEventLog)
          if (response.data.statcode === 0) {
            this.isLoading = false
            this.$refs['modal-ekinerja-edit-log'].hide()
            this.messageModal = 1
            this.messageColor = 'bg-primary'
            this.message = response.data.message
            this.createEventLog.uraian = ''
            this.clearArr()
            this.actionReload()
          } else {
            this.isLoading = false
            this.messageModal = 1
            this.messageColor = 'bg-danger'
            this.message = response.data.message
          }
        } catch (error) {
          this.isLoading = false
          this.messageModal = 1
          this.messageColor = 'bg-danger'
          this.message = error.message
        }
      } else {
        this.isLoading = false
        this.messageModal = 2
        this.messageColor = 'bg-danger'
        this.message = 'Uraian Tidak Boleh Kosong'
      }
    },
    async onCreateIssue (bvModalEvent) {
      bvModalEvent.preventDefault()
      this.isLoading = true
      if (this.createIssue.uraian !== '') {
        try {
          let response = await newServices.createTrxKinerja(this.createIssue)
          if (response.data.statcode === 0) {
            this.isLoading = false
            this.$refs['modal-ekinerja-create-issue-event'].hide()
            this.messageModal = 3
            this.messageColor = 'bg-primary'
            this.message = response.data.message
            this.clearArrIssue()
            this.reloadIssue()
          } else {
            this.isLoading = false
            this.messageModal = 4
            this.messageColor = 'bg-danger'
            this.message = response.data.message
          }
        } catch (error) {
          this.isLoading = false
          this.messageModal = 4
          this.messageColor = 'bg-danger'
          this.message = error.message
        }
      } else {
        this.isLoading = false
        this.messageModal = 4
        this.messageColor = 'bg-danger'
        this.message = 'Uraian Tidak Boleh Kosong'
      }
    },
    modalCreateIssue () {
      this.createIssue.uraian = ''
      this.$refs['modal-ekinerja-create-issue-event'].show()
    },
    modalAssign (index, id, uraian) {
      this.preview.assignIndex = index
      this.preview.assignTitle = uraian
      this.formAssign.id_issue = id.toString()
      this.clearArrAssign()
      this.$refs['modal-assign-issue'].show()
    },
    async loadIssue (id) {
      this.isLoading = true
      this.clearArrIssue()
      try {
        let response = await newServices.findIssueTrxKinerja(this.preview.idIssue.toString())
        if (response.data.data) {
          this.isLoading = false
          this.formIssue.id_issue = id.toString()
          this.deleteRowIssue(0)
          let data = response.data.data
          data.map((value, index) => {
            this.formIssue.detail.push({
              statcode: value.statcode,
              rowcount: value.rowcount,
              id: value.id.toString(),
              id_eventlog: value.id_eventlog,
              uraian: value.uraian,
              status: value.status,
              created_by: xray.currentUser().id.toString(),
              name: value.name,
              pic: '',
              assign_type: '',
              jml_assign: value.jml_assign
            })
          })
        } else {
          this.isLoading = false
        }
      } catch (error) {
        this.isLoading = false
        this.message = error.message
      }
      this.$refs['modal-ekinerja-create-issue'].show()
    },
    async reloadIssue () {
      this.isLoading = true
      try {
        let response = await newServices.findIssueTrxKinerja(this.preview.idIssue.toString())
        if (response.data.data) {
          this.isLoading = false
          this.deleteRowIssue(0)
          let data = response.data.data
          data.map((value, index) => {
            this.formIssue.detail.push({
              statcode: value.statcode,
              rowcount: value.rowcount,
              id: value.id.toString(),
              id_eventlog: value.id_eventlog,
              uraian: value.uraian,
              status: value.status,
              created_by: xray.currentUser().id.toString(),
              name: value.name,
              pic: '',
              assign_type: '',
              jml_assign: value.jml_assign
            })
          })
        } else {
          this.isLoading = false
        }
      } catch (error) {
        this.isLoading = false
        this.message = error.message
      }
    },
    valueAssign ($event, index) {
      this.formAssign.detail[index].pic = $event.id.toString()
    },
    valueAssignType ($event, index) {
      this.formAssign.detail[index].assign_type = $event.id.toString()
    },
    async onCreateAssign (bvModalEvent) {
      bvModalEvent.preventDefault()
      this.isLoading = true
      try {
        let response = await newServices.createTrxKinerja(this.formAssign)
        if (response.data.statcode === 0) {
          this.isLoading = false
          this.$refs['modal-assign-issue'].hide()
          this.messageModal = 3
          this.messageColor = 'bg-primary'
          this.message = response.data.message
          this.clearArrAssign()
          this.clearArrIssue()
          this.reloadIssue()
        } else {
          this.isLoading = false
          this.messageModal = 4
          this.messageColor = 'bg-danger'
          this.message = response.data.message
        }
      } catch (error) {
        this.isLoading = false
        this.messageModal = 4
        this.messageColor = 'bg-danger'
        this.message = error.message
      }
    },
    async loadPic (id, uraian) {
      this.isLoading = true
      this.preview.assignTitle = uraian
      this.clearFormpic()
      try {
        let response = await newServices.findUserTrxKinerja(id.toString())
        if (!response.data.statcode) {
          this.isLoading = false
          this.deleteRowPic(0)
          let data = response.data.data
          data.map((value, index) => {
            this.formPic.push({
              nama: value.nama,
              nip: value.nip,
              uraian: value.uraian
            })
          })
        } else {
          this.isLoading = false
          this.messageModal = 4
          this.messageColor = 'bg-danger'
          this.message = response.data.message
        }
      } catch (error) {
        this.isLoading = false
        this.messageModal = 4
        this.messageColor = 'bg-danger'
        this.message = error.message
      }
      this.$refs['modal-ekinerja-pic-issue-event'].show()
    },
    addRowAssign () {
      this.formAssign.detail.push({
        pic: '',
        assign_type: ''
      })
      this.selected.assign.push(
        {
          detail: []
        }
      )
      this.selected.assignType.push(
        {
          detail: []
        }
      )
    },
    deleteRow (index) {
      this.formLog.detail.splice(index, 1)
    },
    deleteRowIssue (index) {
      this.formIssue.detail.splice(index, 1)
    },
    deleteRowAssign (index) {
      this.formAssign.detail.splice(index, 1)
      this.selected.assign.splice(index, 1)
      this.selected.assignType.splice(index, 1)
    },
    deleteRowPic (index) {
      this.formPic.splice(index, 1)
    },
    clearMessage () {
      this.messageModal = null
      this.message = ''
    },
    clearCreateForm () {
      this.createEvent.object = 'tkinerja-event'
      this.createEvent.id_mtype = ''
      this.createEvent.tag = ''
      this.createEvent.uraian = ''
      this.createEvent.keterangan = ''
      this.createEvent.created_by = xray.currentUser().id.toString()
      //
      this.selected.statusForm = []
    },
    clearArr () {
      this.formLog.detail = [
        {
          id: '',
          id_event: '',
          muraian: '',
          no_urut: '',
          rowcount: '',
          statcode: '',
          temulai: '',
          teselesai: '',
          uraian: '',
          aksi: ''
        }
      ]
    },
    clearArrIssue () {
      this.formIssue.detail = [
        {
          statcode: '',
          rowcount: '',
          id: '',
          id_eventlog: '',
          uraian: '',
          status: '',
          created_by: '',
          name: '',
          pic: '',
          assign_type: '',
          jml_assign: ''
        }
      ]
    },
    clearArrAssign () {
      this.formAssign.detail = [
        {
          pic: '',
          assign_type: ''
        }
      ]
      this.selected.assign = [
        {
          detail: []
        }
      ]
      this.selected.assignType = [
        {
          detail: []
        }
      ]
    },
    clearFormpic () {
      this.formPic = [
        {
          nama: '',
          nip: '',
          uraian: ''
        }
      ]
    },
    resetFilter () {
      this.preview.filterTag = ''
      this.preview.filterStatus = ''
      this.preview.filterStatusKinerja = ''
      this.preview.filterUraian = ''
      this.preview.tanggal = ''
      this.preview.tanggalAwal = ''
      this.preview.tanggalAkhir = ''
      //
      this.selected.status = []
      this.selected.statusKinerja = []
      //
      this.$refs.table.refresh()
    }
  }
}
</script>

<style>
#e-kinerja .hover {
  cursor: pointer;
}
#e-kinerja .border-radius-custom-ekinerja {
  border-radius: .5rem;
}
#e-kinerja .border-radius-custom-ekinerja-textarea {
  border-radius: .5rem;
  line-height: 25px
}
#e-kinerja .table td {
  font-size: .75rem;
  font-weight: 500;
  padding-top: 0px;
  padding-bottom: 0px;
}
#e-kinerja .table th {
  font-size: .8rem;
  font-weight: 400;
}
#e-kinerja .rounded-custom {
  border-radius: 5px;
}
#e-kinerja .page-link {
  border: none;
  color: gray!important;
  margin-right: 15px;
}
#e-kinerja .page-item.active .page-link {
  background-color: transparent;
  border-color: transparent;
  color: #089bab!important;
  font-weight: bold;
}
#e-kinerja .cstm-select-remun-dash {
  font-size: 13px;
  height: 33px!important;
  border-radius: 5px;
}
#e-kinerja .e-kinerja-filter {
  font-size: 13px;
  height: 33px!important;
  border-radius: 5px;
}
#e-kinerja .custom-flat-pickr {
    border: 1px solid rgb(233, 233, 233);
    height: 33px;
    padding-left: 10px;
    padding-top: 5px;
    padding-bottom: 5px;
    border: 1px solid rgb(209, 208, 208)!important;
    border-radius: 5px;
    font-size: 12px;
    color: black;
}
#e-kinerja .letter-spacing-custom {
  letter-spacing: .5px
}
#e-kinerja .multiselect__placeholder {
  font-weight: normal;
  font-size: 13px!important;
}
#e-kinerja .multiselect__option {
  font-weight: normal;
  font-size: 13px;
}
#e-kinerja .multiselect__tags {
  font-weight: normal;
  padding: 3px 40px 0 8px!important;
  height: 33px!important;
  min-height: 33px!important;
  border: 1px solid rgba(0, 0, 0, 0.212);
}
#e-kinerja .multiselect {
  height: 33px!important;
  min-height: 33px!important;
}
#e-kinerja .multiselect__select {
  height: 33px!important;
  min-height: 33px!important;
}
#modal-ekinerja-action .modal-dialog {
    min-width: 95%;
}
#modal-ekinerja-action .modal-body, #modal-ekinerja-edit-log .modal-body, #modal-ekinerja-create-issue-event .modal-body {
  padding: 0
}
#modal-ekinerja-action .bg-info-custom {
  background: rgb(0, 187, 230);
}
#modal-ekinerja-tambah-log .modal-body {
  padding: 0
}
#modal-ekinerja-create-issue .modal-body {
  padding: .5rem
}
#modal-ekinerja-action .btn-height-auto {
  height: -webkit-fill-available;
}
#e-kinerja .tl-color {
  color: rgb(255, 196, 0);
}
#modal-ekinerja-edit-log .text-area-radius, #modal-ekinerja-tambah-log .text-area-radius {
  border-radius: 0px;
  line-height: 25px
}
</style>
