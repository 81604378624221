<template>
  <div class="form-wizard-irj p-lg-4">
    <b-container fluid>
      <div v-show="isLoading">
        <div id="show-overlay"></div>
        <Loader :style="{ opacity: .5 }" />
      </div>
      <b-row class="justify-content-center">
        <b-col cols="12" md="6" lg="4" xl="3" class="mt-3">
          <div>
            <div class="wadah rounded p-lg-3 p-2 bg-review position-relative">
              <div :class="kuotaClass">
                <div class="position-absolute wadah-child py-4 d-flex flex-column justify-content-between align-items-center">
                <div>
                  <p class="h4 text-white text-center font-weight-bold txt-shdw-reservasi mb-0">{{viewTgl}}</p>
                </div>
                <div>
                  <div class="bg-white shadow-sm rounded mb-4" v-if="kuotaSeeIRJ.jumlah_on">
                    <div class="px-4 pt-3">
                      <p class="h6 text-black font-weight-bold mb-0">{{kuotaSeeIRJ.deskripsi}}</p>
                    </div>
                    <hr>
                    <div class="px-4 pb-3">
                      <p class="text-black mb-1">Tanggal : <span class="font-weight-bold text-dark">{{ viewTgl }}</span></p>
                      <p class="text-black mb-1">Total Kuota : <span class="font-weight-bold text-success">{{kuotaSeeIRJ.jumlah_on}}</span></p>
                      <p class="text-black mb-0">Kuota Tersedia : <span class="font-weight-bold text-danger">{{kuotaSeeIRJ.sisa}}</span></p>
                    </div>
                  </div>
                  <div v-else>
                    <p class="h6 text-white txt-shdw-reservasi mb-2">Kuota Tidak Tersedia</p>
                  </div>
                </div>
                <div>
                  <b-button variant="danger" class="font-weight-bold" @click="kuotaClose"><i class="fas fa-times-circle"></i> Tutup</b-button>
                </div>
              </div>
              </div>
              <div class="head-calendar shadow bg-white text-center rounded-custom p-3 mb-4">
                <p class="h6 text-dark mb-0"><i class="fas fa-calendar-check"></i> CEK KUOTA RAWAT JALAN</p>
              </div>
                <div class="mb-2 bg-white rounded-custom">
                  <b-form-group class="mb-0">
                    <multiselect :show-labels="false" v-model="selectedRuanganKuota" :options="comboRuanganKuota" @input="valRuanganpoliKuota"
                      :limit="3" label="deskripsi" placeholder="Pilih Ruangan" :searchable="true">
                    </multiselect>
                  </b-form-group>
                </div>
              <div class="body-calendar rounded-custom">
                <div class="d-flex justify-content-center rounded-custom shadow p-3 mb-4 bg-white calender-small">
                  <template>
                    <flat-pickr :config="config" @on-change="lihatkuota" value="" class="d-none" />
                  </template>
                </div>
              </div>
              <div class="bottom-calendar shadow bg-white rounded-custom text-center p-3">
                <p class="small text-dark mb-0">Klik Tanggal Untuk Melihat Kuota</p>
              </div>
            </div>
            <!-- <div class="bg-white rounded p-lg-3 p-2 bg-review position-relative mt-5">
              <pre>{{formData}}</pre>
            </div> -->
          </div>
        </b-col>
        <b-col cols="12" md="12" lg="8" xl="8">
          <form-wizard @onComplete="onComplete">
            <tab-content title="TUJUAN" :selected="true" class="px-1">
              <div class="tujuan-wizard py-5 px-2 px-lg-4 my-4">
                <b-row>
                  <b-col cols="12" lg="6" class="mb-4">
                    <b-form-group label="Tanggal Kedatangan :" label-for="tanggal" class="mb-0">
                      <b-input-group>
                        <b-form-input id="example-input" v-model="formattedtglreservasi" type="text" placeholder="DD-MM-YYYY"
                          :class="hasError('tanggal_reservasi') ? 'invalid-cstm' : ''">
                        </b-form-input>
                        <b-input-group-append>
                          <b-form-datepicker @context="onContextTglReservasi" id="example-datepicker2" v-model="formData.tanggal_reservasi" nav-button-variant="dark"
                            :date-disabled-fn="dateDisabled" button-only right locale="id"
                            aria-controls="example-input"></b-form-datepicker>
                        </b-input-group-append>
                      </b-input-group>
                    </b-form-group>
                  </b-col>
                  <b-col cols="12" lg="6" class="mb-4" v-if="formData.tanggal_reservasi !== ''">
                    <b-form-group label="Instalasi :" class="mb-0">
                      <multiselect :show-labels="false" v-model="selectedInstalasi" :options="comboInstalasi" @input="getunit" :limit="3" label="deskripsi"
                        placeholder="Pilih Instalasi" :searchable="true">
                      </multiselect>
                    </b-form-group>
                  </b-col>
                  <b-col cols="12" lg="6" class="mb-4" v-if="formData.tanggal_reservasi !== ''">
                    <b-form-group label="Unit :" class="mb-0">
                      <multiselect :show-labels="false" v-model="selectedUnit" :options="comboUnit" @input="getruangan" :limit="3" label="deskripsi"
                        placeholder="Pilih Unit" :searchable="true">
                      </multiselect>
                    </b-form-group>
                  </b-col>
                  <b-col cols="12" lg="6" class="mb-4" v-if="formData.tanggal_reservasi !== ''">
                    <b-form-group label="Ruangan :" class="mb-0">
                      <multiselect :show-labels="false" v-model="selectedRuangan" :options="comboRuangan" @input="valRuanganpoli" :limit="3" label="deskripsi"
                        placeholder="Pilih Ruangan" :searchable="true"
                        :class="hasError('ruangan') ? 'multiselect__tags-error' : ''">
                      </multiselect>
                      <small class="text-success font-weight-normal" v-if="respon.jumlah_on">Tersisa {{ respon.sisa }} kuota dari total {{ respon.jumlah_on }} kuota tersedia </small>
                      <small class="text-danger font-weight-normal" v-else>{{ respon }} </small>
                    </b-form-group>
                  </b-col>
                </b-row>
              </div>
            </tab-content>
            <tab-content title="DATA DIRI" class="px-3">
              <div class="datadiri-wizard mb-4 page-scroll pb-5">
                <div class="mb-4 mt-4">
                  <div class="text-center">
                    <b-form-group class="mt-3">
                      <b-form-radio-group id="radio-group-1" v-model="jenispasien" @change="onChangePasien" :options="jenisPasien"
                        name="radio-options"></b-form-radio-group>
                    </b-form-group>
                  </div>
                  <div v-if="jenispasien === 1">
                    <b-row class="justify-content-center">
                      <b-col cols="12" lg="8">
                        <div>
                          <b-form-group class="mb-0">
                            <multiselect v-model="selectedMember" :show-labels="false" :limit="3" @input="valMember"
                            label="nama" track-by="nama" :custom-label="nameWithNorm" placeholder="Pilih Member" :options="comboMember" :searchable="true">
                            </multiselect>
                          </b-form-group>
                        </div>
                      </b-col>
                    </b-row>
                  </div>
                </div>
                <div>
                  <b-row class="justify-content-center mt-3">
                    <b-col cols="12" lg="5">
                      <b-form-group label="Jenis Identitas :" class="mb-4">
                        <multiselect v-model="selectedJenisIdentitas" :show-labels="false" :limit="3" @input="valJenisIdentitas"
                          label="name" placeholder="Jenis Identitas" :options="comboJenisIdentitas" :searchable="true"
                          :class="hasError('jenis') ? 'multiselect__tags-error' : ''">
                        </multiselect>
                      </b-form-group>
                      <b-form-group label="Nomor Identitas :" class="mb-4">
                        <b-form-input id="nomor" type="text" v-model="formData.nomor" placeholder="Nomor NIK/SIM/Passport"
                          :class="hasError('nomor') ? 'is-invalid' : ''"></b-form-input>
                      </b-form-group>
                      <b-form-group label="Nama Lengkap :" class="mb-4">
                        <b-form-input id="nama" type="text" v-model="formData.nama" placeholder="Nama"
                          :class="hasError('nama') ? 'is-invalid' : ''"></b-form-input>
                      </b-form-group>
                      <b-form-group label="Jenis Kelamin :" class="mb-4">
                        <multiselect v-model="selectedJenisKelamin" :show-labels="false" :limit="3" @input="valJenisKelamin"
                          label="name" placeholder="Jenis Kelamin" :options="comboJenisKelamin" :searchable="true"
                          :class="hasError('jenis_kelamin') ? 'multiselect__tags-error' : ''">
                        </multiselect>
                      </b-form-group>
                      <b-form-group label="Tempat Lahir :" class="mb-4">
                        <multiselect v-model="selectedTempatLahir" :show-labels="false" :limit="3" @input="valTempatLahir"
                          label="deskripsi" placeholder="Tempat Lahir" :options="comboTempatLahir" :searchable="true"
                          :class="hasError('tempat_lahir') ? 'multiselect__tags-error' : ''">
                        </multiselect>
                      </b-form-group>
                      <!-- <b-form-group label="Tanggal Lahir:" label-for="tanggal" class="mb-4">
                        <b-input-group>
                          <b-form-input id="example-input" v-model="formData.tanggal_lahir" type="text" placeholder="DD-MM-YYYY" :class="hasError('tanggal_lahir') ? 'invalid-cstm' : ''">
                          </b-form-input>
                          <b-input-group-append>
                            <b-form-datepicker id="example-datepicker2" v-model="formData.tanggal_lahir" nav-button-variant="dark"
                              button-only right locale="id" aria-controls="example-input">
                            </b-form-datepicker>
                          </b-input-group-append>
                        </b-input-group>
                      </b-form-group> -->
                      <b-form-group label="Tanggal Lahir">
                        <template>
                          <flat-pickr v-model="tglLahir" @on-change="valTglLahir" :config="configTglLahir"
                            placeholder="Pilih Tanggal " class="w-100 custom-flatpicker calender-small"
                            :class="hasError('tanggal_lahir') ? 'flatpickr-input-error' : ''">
                          </flat-pickr>
                        </template>
                      </b-form-group>
                      <b-form-group label="Usia:" label-for="tanggal" class="mb-4">
                        <b-form-input readonly id="usia" type="text" v-model="formData.usia" placeholder="Usia Saat Ini"
                          :class="hasError('usia') ? 'is-invalid' : ''"></b-form-input>
                      </b-form-group>
                      <b-form-group label="Agama :" class="mb-4">
                        <multiselect v-model="selectedAgama" :show-labels="false" :limit="3" @input="valAgama" label="deskripsi"
                          placeholder="Agama" :options="comboAgama" :searchable="true"
                          :class="hasError('agama') ? 'multiselect__tags-error' : ''">
                        </multiselect>
                      </b-form-group>
                      <b-form-group label="Pendidikan :" class="mb-4">
                        <multiselect v-model="selectedPendidikan" :show-labels="false" :limit="3" @input="valPendidikan"
                          label="deskripsi" placeholder="Pendidikan" :options="comboPendidikan" :searchable="true"
                          :class="hasError('pendidikan') ? 'multiselect__tags-error' : ''">
                        </multiselect>
                      </b-form-group>
                      <b-form-group label="Pekerjaan:" label-for="tanggal" class="mb-4">
                        <multiselect v-model="selectedPekerjaan" :show-labels="false" :limit="3" @input="valPekerjaan"
                          label="deskripsi" placeholder="Pekerjaan" :options="comboPekerjaan" :searchable="true"
                          :class="hasError('pekerjaan') ? 'multiselect__tags-error' : ''">
                        </multiselect>
                      </b-form-group>
                    </b-col>
                    <b-col cols="12" lg="5">
                      <b-form-group label="Warga Negara:" label-for="tanggal" class="mb-4">
                        <multiselect v-model="selectedNegara" :show-labels="false" :limit="3" @input="valNegara" label="deskripsi"
                          placeholder="Kewarganegaraan (Country)" :options="comboNegara" :searchable="true"
                          :class="hasError('kewarganegaraan') ? 'multiselect__tags-error' : ''">
                        </multiselect>
                      </b-form-group>
                      <b-form-group label="Provinsi :" class="mb-4">
                        <multiselect v-model="selectedProvinsi" :show-labels="false" :limit="3" @input="getkabupaten"
                          label="deskripsi" placeholder="Provinsi" :options="comboProvinsi" :searchable="true"
                          :class="hasError('wilayah') ? 'multiselect__tags-error' : ''">
                        </multiselect>
                      </b-form-group>
                      <b-form-group label="Kota:" label-for="tanggal" class="mb-4">
                        <multiselect v-model="selectedKabupaten" :show-labels="false" :limit="3" @input="getkecamatan"
                          label="deskripsi" placeholder="Kabupaten/Kota" :options="comboKabupaten" :searchable="true"
                          :class="hasError('wilayah') ? 'multiselect__tags-error' : ''">
                        </multiselect>
                      </b-form-group>
                      <b-form-group label="Kecamatan :" class="mb-4">
                        <multiselect v-model="selectedKecamatan" :show-labels="false" :limit="3" @input="getkelurahan"
                          label="deskripsi" placeholder="Kecamatan" :options="comboKecamatan" :searchable="true"
                          :class="hasError('wilayah') ? 'multiselect__tags-error' : ''">
                        </multiselect>
                      </b-form-group>
                      <b-form-group label="Kelurahan:" label-for="tanggal" class="mb-4">
                        <multiselect v-model="selectedKelurahan" :show-labels="false" :limit="3" @input="valWilayah"
                          label="deskripsi" placeholder="Kabupaten/Kota" :options="comboKelurahan" :searchable="true"
                          :class="hasError('wilayah') ? 'multiselect__tags-error' : ''">
                        </multiselect>
                      </b-form-group>
                      <div class="mb-4">
                        <b-row>
                          <b-col cols="12" lg="4">
                            <b-form-group label="RT :" class="mb-4">
                              <b-form-input id="nama" type="text" v-model="formData.rt" placeholder="RT"
                                :class="hasError('rt') ? 'is-invalid' : ''"></b-form-input>
                            </b-form-group>
                          </b-col>
                          <b-col cols="12" lg="4">
                            <b-form-group label="RW :" class="mb-4">
                              <b-form-input id="nama" type="text" v-model="formData.rw" placeholder="RW"
                                :class="hasError('rw') ? 'is-invalid' : ''"></b-form-input>
                            </b-form-group>
                          </b-col>
                          <b-col cols="12" lg="4">
                            <b-form-group label="Kodepos :" class="mb-0">
                              <b-form-input id="nama" type="text" v-model="formData.kodepos" placeholder="Kodepos"
                                :class="hasError('kodepos') ? 'is-invalid' : ''"></b-form-input>
                            </b-form-group>
                          </b-col>
                        </b-row>
                      </div>
                      <b-form-group label="Alamat Tinggal :" class="mb-4">
                        <b-form-textarea id="textarea" v-model="formData.alamat" placeholder="Alamat" rows="3"
                          :class="hasError('alamat') ? 'is-invalid' : ''"></b-form-textarea>
                      </b-form-group>
                      <b-form-group label="No. Handpone :" class="mb-4">
                        <b-form-input id="handpone" v-model="formData.nomor_kontak" type="text" placeholder="Handpone"
                          :class="hasError('nomor_kontak') ? 'is-invalid' : ''"></b-form-input>
                      </b-form-group>
                      <b-form-group label="Email :" class="mb-4">
                        <b-form-input id="email" type="email" v-model="formData.email" placeholder="Email"
                          :class="hasError('email') ? 'is-invalid' : ''"></b-form-input>
                      </b-form-group>
                    </b-col>
                  </b-row>
                </div>
              </div>
            </tab-content>
            <tab-content title="CARA BAYAR" class="px-3">
              <div class="cara-bayar-wizard py-5 my-4">
                <b-row class="align-items-center justify-content-center">
                  <b-col cols="12" lg="6" class="mb-4">
                    <b-form-group class="mb-0">
                      <multiselect v-model="selectedCaraBayar" :show-labels="false" :limit="3" label="deskripsi"
                        placeholder="Cara Bayar" :options="comboCaraBayar" @input="valCaraBayar" :searchable="true"
                        :class="hasError('carabayar') ? 'multiselect__tags-error' : ''">
                      </multiselect>
                    </b-form-group>
                  </b-col>
                  <b-col cols="12" lg="6" class="mb-4" v-if="formData.carabayar === '2'">
                    <div>
                      <b-row class="align-items-center">
                        <b-col cols="12" lg="8">
                          <b-form-group class="mb-0">
                            <b-form-input id="nama" type="search" v-model="nomorBPJS" placeholder="Masukan Nomor Kartu BPJS"></b-form-input>
                          </b-form-group>
                        </b-col>
                        <b-col cols="12" lg="4">
                          <div>
                            <b-button variant="primary" class="w-100" @click="getnoBpjs">Validasi</b-button>
                          </div>
                        </b-col>
                      </b-row>
                    </div>
                  </b-col>
                </b-row>
              </div>
            </tab-content>
            <tab-content title="REVIEW" class="px-lg-3">
              <div class="bg-review rounded shadow-sm p-lg-4">
                <div class="d-none d-lg-block">
                  <div class="d-flex justify-content-center mb-3">
                  <div class="title bg-white rounded shadow-sm">
                    <p class="h6 text-dark px-4 py-2 mb-0">Review Data Pasien</p>
                  </div>
                </div>
                </div>
                <div class="bg-white rounded shadow-sm p-4" v-if="formData.nik !== '' && formData.nama !== ''">
                  <b-row>
                    <b-col cols="12" lg="6">
                      <b-row class="mb-2">
                        <b-col cols="3" lg="4">
                          <p class="small text-dark mb-0">Nomor Identitas</p>
                        </b-col>
                        <b-col cols="1" lg="1">
                          <p class="small text-dark mb-0">:</p>
                        </b-col>
                        <b-col>
                          <p class="small text-dark font-weight-bold mb-0">{{formData.nomor}}</p>
                        </b-col>
                      </b-row>
                      <b-row class="mb-2">
                        <b-col cols="3" lg="4">
                          <p class="small text-dark mb-0">Nama Lengkap</p>
                        </b-col>
                        <b-col cols="1" lg="1">
                          <p class="small text-dark mb-0">:</p>
                        </b-col>
                        <b-col>
                          <p class="small text-dark font-weight-bold mb-0">{{formData.nama}}</p>
                        </b-col>
                      </b-row>
                       <b-row class="mb-2">
                        <b-col cols="3" lg="4">
                          <p class="small text-dark mb-0">Jenis Kelamin</p>
                        </b-col>
                        <b-col cols="1" lg="1">
                          <p class="small text-dark mb-0">:</p>
                        </b-col>
                        <b-col>
                          <p class="small text-dark font-weight-bold mb-0">{{selectedJenisKelamin.name}}</p>
                        </b-col>
                      </b-row>
                      <b-row class="mb-2">
                        <b-col cols="3" lg="4">
                          <p class="small text-dark mb-0">Tanggal Lahir</p>
                        </b-col>
                        <b-col cols="1" lg="1">
                          <p class="small text-dark mb-0">:</p>
                        </b-col>
                        <b-col>
                          <p class="small text-dark font-weight-bold mb-0">{{tglLahirCard}}</p>
                        </b-col>
                      </b-row>
                      <b-row class="mb-2">
                        <b-col cols="3" lg="4">
                          <p class="small text-dark mb-0">Alamat Tinggal</p>
                        </b-col>
                        <b-col cols="1" lg="1">
                          <p class="small text-dark mb-0">:</p>
                        </b-col>
                        <b-col>
                          <p class="small text-dark font-weight-bold mb-0">{{formData.alamat}}</p>
                        </b-col>
                      </b-row>
                    </b-col>
                    <b-col cols="12" lg="6">
                      <b-row class="mb-2">
                        <b-col cols="3" lg="4">
                          <p class="small text-dark mb-0">Tempat Lahir</p>
                        </b-col>
                        <b-col cols="1" lg="1">
                          <p class="small text-dark mb-0">:</p>
                        </b-col>
                        <b-col>
                          <p class="small text-dark font-weight-bold mb-0">{{selectedTempatLahir.deskripsi}}</p>
                        </b-col>
                      </b-row>
                      <b-row class="mb-2">
                        <b-col cols="3" lg="4">
                          <p class="small text-dark mb-0">Nomor Handpone</p>
                        </b-col>
                        <b-col cols="1" lg="1">
                          <p class="small text-dark mb-0">:</p>
                        </b-col>
                        <b-col>
                          <p class="small text-dark font-weight-bold mb-0">{{formData.nomor_kontak}}</p>
                        </b-col>
                      </b-row>
                      <b-row class="mb-2">
                        <b-col cols="3" lg="4">
                          <p class="small text-dark mb-0">Email</p>
                        </b-col>
                        <b-col cols="1" lg="1">
                          <p class="small text-dark mb-0">:</p>
                        </b-col>
                        <b-col>
                          <p class="small text-dark font-weight-bold mb-0">{{formData.email}}</p>
                        </b-col>
                      </b-row>
                      <b-row class="mb-2">
                        <b-col cols="3" lg="4">
                          <p class="small text-dark mb-0">Ruangan</p>
                        </b-col>
                        <b-col cols="1" lg="1">
                          <p class="small text-dark mb-0">:</p>
                        </b-col>
                        <b-col>
                          <p class="small text-dark font-weight-bold mb-0">{{ selectedRuangan.deskripsi }}</p>
                        </b-col>
                      </b-row>
                      <b-row class="mb-2">
                        <b-col cols="3" lg="4">
                          <p class="small text-dark mb-0">Tanggal Reservasi</p>
                        </b-col>
                        <b-col cols="1" lg="1">
                          <p class="small text-dark mb-0">:</p>
                        </b-col>
                        <b-col>
                          <p class="small text-dark font-weight-bold mb-0">{{ new Date(formData.tanggal_reservasi) | formatDate }}</p>
                        </b-col>
                      </b-row>
                    </b-col>
                  </b-row>
                </div>
                <div v-else>
                  <p class="text-white-50 text-center mb-0">Data Belum Lengkap</p>
                </div>
              </div>
            </tab-content>
          </form-wizard>
        </b-col>
      </b-row>
    </b-container>
    <b-modal ref="modal-sukses-norm" size="md" title="Informasi" ok-title="Oke" :ok-only=true
      :hide-header=true centered>
      <div class="text-center px-lg-4 px-2">
        <lottie :options="defaultOptions" :autoPlay="true" :width="250" :height="250" />
        <p class="h6 text-success font-weight-bold text-uppercase mb-0">{{respon}}</p>
      </div>
    </b-modal>
    <b-modal ref="modal-sukses-qrcode" size="md" title="Informasi" ok-title="Oke" @ok="refreshpage" :no-close-on-backdrop="true" :static="true" :ok-only="true" :hide-header="true" centered>
      <div class="text-center px-2 py-5">
        <div class="mb-4">
          <p class="h3 text-success font-weight-bold mb-1"><i class="fas fa-check-circle"></i> Berhasil</p>
          <p class="small text-success text-capitalize mb-0"> {{respon}}</p>
        </div>
        <div class="mb-2">
          <p class="h3 text-dark font-weight-bold mb-0">Nomor Reservasi</p>
        </div>
        <div>
          <template>
            <qrcode-vue :value="qrcode" :size="size" level="H" />
          </template>
        </div>
        <div class="mt-2">
          <p class="h4 text-dark font-weight-bold mb-0">{{qrcode}}</p>
        </div>
         <div class="mt-2">
          <p class="h6 text-dark font-weight-normal mb-0">{{resKet}}</p>
        </div>
      </div>
    </b-modal>
    <b-modal ref="modal-gagal-norm" size="md" title="Informasi" ok-title="Saya Mengerti" :ok-only=true
      :hide-header=true centered>
      <div class="text-center">
        <lottie :options="defaultOptions2" :autoPlay="true" :width="250" :height="250" />
        <p class="h6 font-weight-bold text-capitalize text-danger">{{error}}</p>
      </div>
    </b-modal>
    <b-modal ref="modal-gagal-normValid" size="md" title="Informasi" ok-title="Saya Mengerti" :ok-only=true
      :hide-header=true centered>
      <div class="text-center px-4">
        <lottie :options="defaultOptions2" :autoPlay="true" :width="250" :height="250" />
        <p class="h6 font-weight-bold text-capitalize text-danger">{{error}}</p>
      </div>
    </b-modal>
    <b-modal ref="modal-gagal-kirim-2" size="md" title="Informasi" ok-title="Saya Mengerti" :ok-only=true
      :hide-header=true centered>
      <div class="text-center">
        <lottie :options="defaultOptions2" :autoPlay="true" :width="250" :height="250" />
        <p class="h6 text-danger">{{error}}</p>
      </div>
    </b-modal>
    <b-modal ref="modal-bpjs" size="lg" title="Riwayat Pasien BPJS" ok-title="Saya Mengerti" :ok-only=true
      :hide-header=true centered>
      <!-- <div>
        <pre>{{ formData }}</pre>
      </div> -->
      <div class="shadow-sm border mb-4">
        <div class="mb-4">
          <div class="bg-primary px-3 py-2">
            <p class="mb-0">Detail Peserta</p>
          </div>
          <div class="p-3">
            <b-row>
              <b-col>
                <div>
                  <b-row class="mb-2">
                    <b-col cols="4">
                      <p class="text-dark mb-0">Nama Peserta</p>
                    </b-col>
                    <b-col cols="1">
                      <p class="text-dark mb-0">:</p>
                    </b-col>
                    <b-col>
                      <p class="text-dark font-weight-bold mb-0">{{ pesertaBPJS.nama }}</p>
                    </b-col>
                  </b-row>
                  <b-row class="mb-2">
                    <b-col cols="4">
                      <p class="text-dark mb-0">Nomor Induk Kependudukan</p>
                    </b-col>
                    <b-col cols="1">
                      <p class="text-dark mb-0">:</p>
                    </b-col>
                    <b-col>
                      <p class="text-dark font-weight-bold mb-0">{{ pesertaBPJS.nik }}</p>
                    </b-col>
                  </b-row>
                  <b-row class="mb-0">
                    <b-col cols="4">
                      <p class="text-dark mb-0">Nomor Kartu</p>
                    </b-col>
                    <b-col cols="1">
                      <p class="text-dark mb-0">:</p>
                    </b-col>
                    <b-col>
                      <p class="text-dark font-weight-bold mb-0">{{ pesertaBPJS.noKartu }}</p>
                    </b-col>
                  </b-row>
                </div>
              </b-col>
            </b-row>
          </div>
        </div>
      </div>
      <div class="shadow-sm border">
        <div class="mb-4">
          <div class="bg-primary px-3 py-2">
            <p class="mb-0">Riwayat Rujukan</p>
          </div>
          <div v-for="(r, index) in rujukanBPJS.rujukan" :key="r.noKunjungan">
            <b-row>
              <b-col>
                <div class="p-3">
                  <b-row class="mb-2">
                    <b-col cols="4">
                      <p class="text-dark mb-0">Nomor Kunjungan</p>
                    </b-col>
                    <b-col cols="1">
                      <p class="text-dark mb-0">:</p>
                    </b-col>
                    <b-col>
                      <p class="text-dark font-weight-bold mb-0">{{ r.noKunjungan }}</p>
                    </b-col>
                  </b-row>
                  <b-row class="mb-2">
                    <b-col cols="4">
                      <p class="text-dark mb-0">Prov Perujuk</p>
                    </b-col>
                    <b-col cols="1">
                      <p class="text-dark mb-0">:</p>
                    </b-col>
                    <b-col>
                      <p class="text-dark font-weight-bold mb-0">{{ r.provPerujuk.nama }}</p>
                    </b-col>
                  </b-row>
                  <b-row class="mb-2">
                    <b-col cols="4">
                      <p class="text-dark mb-0">Pelayanan</p>
                    </b-col>
                    <b-col cols="1">
                      <p class="text-dark mb-0">:</p>
                    </b-col>
                    <b-col>
                      <p class="text-dark font-weight-bold mb-0">{{ r.pelayanan.nama }}</p>
                    </b-col>
                  </b-row>
                  <b-row class="mb-2">
                    <b-col cols="4">
                      <p class="text-dark mb-0">Poli Rujukan</p>
                    </b-col>
                    <b-col cols="1">
                      <p class="text-dark mb-0">:</p>
                    </b-col>
                    <b-col>
                      <p class="text-dark font-weight-bold mb-0">{{ r.poliRujukan.nama }}</p>
                    </b-col>
                  </b-row>
                  <b-row class="mb-2">
                    <b-col cols="4">
                      <p class="text-dark mb-0">Diagnosa</p>
                    </b-col>
                    <b-col cols="1">
                      <p class="text-dark mb-0">:</p>
                    </b-col>
                    <b-col>
                      <p class="text-dark font-weight-bold mb-0">{{ r.diagnosa.kode }}</p>
                    </b-col>
                  </b-row>
                  <div class="text-right">
                    <b-button variant="primary" @click="testbtn(index)">Pilih Rujukan</b-button>
                  </div>
                </div>
              </b-col>
            </b-row>
            <hr>
          </div>
        </div>
      </div>
    </b-modal>
  </div>
</template>
<script>
import { FormWizard, TabContent, ValidationHelper } from 'vue-step-wizard'
import 'vue-step-wizard/dist/vue-step-wizard.css'
import Loader from '../../components/xray/loader/Loader'
import Online from '../../services/reservasi_online'
import UTD from '../../services/utd.js'
import jwtdecode from 'jwt-decode'
import Multiselect from 'vue-multiselect'
import Lottie from 'vue-lottie'
import animationLogo from '../../assets/lottie/checked.json'
import animationLogo2 from '../../assets/lottie/failed.json'
import QrcodeVue from 'qrcode.vue'
import flatPickr from 'vue-flatpickr-component'
import 'flatpickr/dist/flatpickr.css'
import { required, email, numeric } from 'vuelidate/lib/validators'
import moment from 'moment'
// import Token from '../../services/token.js'

export default {
  name: 'ReservasiOnlineRawatJalan',
  components: {
    FormWizard,
    TabContent,
    Loader,
    Multiselect,
    QrcodeVue,
    Lottie,
    flatPickr
  },
  data () {
    return {
      formattedtglreservasi: '',
      isLoading: false,
      jenispasien: 1,
      viewTgl: '',
      formData: {
        jenis: '',
        nomor: '',
        norm: '',
        nama: '',
        tempat_lahir: null,
        tanggal_lahir: '',
        usia: '',
        jenis_kelamin: '',
        agama: null,
        nomor_kontak: '',
        email: '',
        alamat: '',
        rt: '',
        rw: '',
        kodepos: '',
        kewarganegaraan: null,
        pendidikan: null,
        pekerjaan: null,
        jenis_reservasi: 1,
        tanggal_reservasi: '',
        ruangan: null,
        carabayar: '',
        wilayah: null,
        peserta: {
          no_kartu: null,
          response: {
            nik: null,
            nama: null,
            pisa: null,
            sex: null,
            mr: {
              noMR: null,
              noTelepon: null
            },
            tglLahir: null,
            tglCetakKartu: null,
            tglTAT: null,
            tglTMT: null,
            statusPeserta: {
              kode: null,
              keterangan: null
            },
            provUmum: {
              kdProvider: null,
              nmProvider: null
            },
            jenisPeserta: {
              kode: null,
              keterangan: null
            },
            hakKelas: {
              kode: null,
              keterangan: null
            },
            umur: {
              umurSekarang: null,
              umurSaatPelayanan: null
            },
            informasi: {
              dinsos: null,
              prolanisPRB: null,
              noSKTM: null
            },
            cob: {
              noAsuransi: null,
              nmAsuransi: null,
              tglTMT: null,
              tglTAT: null
            }
          }
        },
        rujukan: {
          no_rujukan: '',
          response: {
            asalFaskes: null,
            rujukan: [
              {
                noKunjungan: null,
                tglKunjungan: null,
                provPerujuk: {
                  kode: null,
                  nama: null
                },
                peserta: {
                  noKartu: null,
                  nik: null,
                  nama: null,
                  pisa: null,
                  sex: null,
                  mr: {
                    noMR: null,
                    noTelepon: null
                  },
                  tglLahir: null,
                  tglCetakKartu: null,
                  tglTAT: null,
                  tglTMT: null,
                  statusPeserta: {
                    kode: null,
                    keterangan: null
                  },
                  provUmum: {
                    kdProvider: null,
                    nmProvider: null
                  },
                  jenisPeserta: {
                    kode: null,
                    keterangan: null
                  },
                  hakKelas: {
                    kode: null,
                    keterangan: null
                  },
                  umur: {
                    umurSekarang: null,
                    umurSaatPelayanan: null
                  },
                  informasi: {
                    dinsos: null,
                    prolanisPRB: null,
                    noSKTM: null
                  },
                  cob: {
                    noAsuransi: null,
                    nmAsuransi: null,
                    tglTMT: null,
                    tglTAT: null
                  }
                },
                diagnosa: {
                  kode: null,
                  nama: null
                },
                keluhan: null,
                poliRujukan: {
                  kode: null,
                  nama: null
                },
                pelayanan: {
                  kode: null,
                  nama: null
                }
              }
            ]
          }
        }
      },
      tglpelayanan: '',
      tglLahir: '',
      validationRules: [
        { tanggal_reservasi: { required }, ruangan: { required } },
        { jenis: { required }, nomor: { required, numeric }, nama: { required }, tempat_lahir: { required }, tanggal_lahir: { required }, wilayah: { required }, usia: { required }, agama: { required }, pekerjaan: { required }, kewarganegaraan: { required }, pendidikan: { required }, jenis_kelamin: { required }, alamat: { required }, rt: { required }, rw: { required }, kodepos: { required }, nomor_kontak: { required }, email: { required, email } },
        { carabayar: { required } }
      ],
      selectedTempatLahir: [],
      selectedAgama: [],
      selectedPendidikan: [],
      selectedPekerjaan: [],
      selectedNegara: [],
      selectedProvinsi: [],
      selectedKabupaten: [],
      selectedKecamatan: [],
      selectedKelurahan: [],
      selectedJenisKelamin: [],
      selectedJenisIdentitas: [],
      selectedInstalasiKuota: [],
      selectedUnitKuota: [],
      selectedRuanganKuota: [],
      selectedInstalasi: [],
      selectedUnit: [],
      selectedRuangan: [],
      selectedSkrining: [],
      selectedCaraBayar: [],
      selectedMember: [],
      comboTempatLahir: [],
      comboAgama: [],
      comboPendidikan: [],
      comboPekerjaan: [],
      comboNegara: [],
      comboProvinsi: [],
      comboKabupaten: [],
      comboKecamatan: [],
      comboKelurahan: [],
      comboUnitKuota: [],
      comboRuanganKuota: [],
      comboUnit: [],
      comboRuangan: [],
      comboCaraBayar: [],
      comboMember: [],
      comboInstalasi: [
        { deskripsi: 'Instalasi Rawat Jalan', id: 10101 }
      ],
      comboInstalasiKuota: [
        { deskripsi: 'Instalasi Rawat Jalan', id: 10101 }
      ],
      comboJenisKelamin: [
        { name: 'Laki - laki', id: 1 },
        { name: 'Perempuan', id: 2 }
      ],
      comboJenisIdentitas: [
        { name: 'KTP (NIK)', id: 1 },
        { name: 'SIM', id: 2 },
        { name: 'Passport', id: 4 }
      ],
      comboSkrining: [
        { name: 'Skrining 1', id: 1 },
        { name: 'Skrining 2', id: 2 },
        { name: 'Skrining 3', id: 3 },
        { name: 'Skrining 4', id: 4 },
        { name: 'Skrining 5', id: 5 }
      ],
      jenisPasien: [
        { text: 'Pasien Lama', value: 1 },
        { text: 'Pasien Baru', value: 2 }
      ],
      tabIndex: 1,
      norm: '',
      value: '',
      qrcode: '',
      resKet: '',
      size: 250,
      error: '',
      respon: '',
      classHide: 'd-none',
      defaultOptions: { animationData: animationLogo, loop: true },
      defaultOptions2: { animationData: animationLogo2, loop: true },
      config: {
        // disable: ['2022-10-01', '2022-10-20', '2022-11-17'],
        minDate: 'today',
        dateFormat: 'd-m-Y',
        inline: true
      },
      configTglLahir: {
        // disable: ['2022-10-01', '2022-10-20', '2022-11-17'],
        dateFormat: 'd-m-Y',
        disableMobile: true
      },
      kuotaSee: [],
      kuotaSeeIRJ: {},
      kuotaClass: 'd-none',
      selected: 'first',
      options: [
        { text: 'Toggle this custom radio', value: 'first' },
        { text: 'Or toggle this other custom radio', value: 'second' },
        { text: 'This one is Disabled', value: 'third', disabled: true },
        { text: 'This is the 4th radio', value: { fourth: 4 } }
      ],
      tglResponNorm: '',
      ruangan: null,
      caraBayar: null,
      nomorBPJS: '',
      pesertaBPJS: {},
      rujukanBPJS: [],
      validasiBPJS: false,
      tampungValueKuota: null,
      formLihatKuota: {
        ruangan: null
      }
    }
  },
  mounted () {
    // this.cekToken()
    this.wilayah()
    this.agama()
    this.pendidikan()
    this.pekerjaan()
    this.Negara()
    this.getprovinsi()
    this.getCaraBayar()
    this.getruanganKuota()
    this.member()
  },
  computed: {
    nameState () {
      return this.norm.length === 8
    },
    tglLahirCard () {
      var months = new Array(12)
      months[0] = 'January'
      months[1] = 'February'
      months[2] = 'March'
      months[3] = 'April'
      months[4] = 'May'
      months[5] = 'June'
      months[6] = 'July'
      months[7] = 'August'
      months[8] = 'September'
      months[9] = 'October'
      months[10] = 'November'
      months[11] = 'December'
      var date = new Date(this.formData.tanggal_lahir)
      var year = date.getFullYear()
      var month = date.getMonth()
      var day = date.getDate()
      var tglFinal
      tglFinal = day + ' ' + months[month] + ', ' + year
      return tglFinal
    }
  },
  mixins: [ValidationHelper],
  methods: {
    onContextTglReservasi (ctx) {
      this.formattedtglreservasi = ctx.selectedFormatted
      this.selectedInstalasi = []
      this.selectedUnit = []
      this.selectedRuangan = []
      this.formData.ruangan = null
      this.respon = ''
    },
    onChangePasien () {
      this.norm = ''
      this.formData.jenis = ''
      this.selectedJenisIdentitas = []
      this.formData.nomor = ''
      this.formData.norm = ''
      this.formData.nama = ''
      this.formData.tempat_lahir = null
      this.selectedTempatLahir = []
      this.formData.tanggal_lahir = ''
      this.tglLahir = ''
      this.formData.usia = ''
      this.formData.jenis_kelamin = ''
      this.selectedJenisKelamin = []
      this.formData.agama = null
      this.selectedAgama = []
      this.formData.nomor_kontak = ''
      this.formData.alamat = ''
      this.formData.rt = ''
      this.formData.rw = ''
      this.formData.kodepos = ''
      this.formData.kewarganegaraan = null
      this.selectedNegara = []
      this.formData.pendidikan = null
      this.selectedPendidikan = []
      this.formData.pekerjaan = null
      this.selectedPekerjaan = []
      this.formData.email = ''
      this.formData.wilayah = null
      this.selectedProvinsi = []
      this.selectedKabupaten = []
      this.selectedKecamatan = []
      this.selectedKelurahan = []
    },
    async getNorm (value) {
      const data = value
      if (data.length === 8) {
        this.isLoading = true
        await Online.showByRm(data).then(response => {
          if (response.status === 200) {
            this.formData.norm = response.data.data[0].NORM
            this.formData.jenis = response.data.data[0].JENIS_NIK
            this.selectedJenisIdentitas = response.data.data[0].JENIS_NIK === 1 ? { name: 'KTP (NIK)', id: response.data.data[0].JENIS_NIK } : response.data.data[0].JENIS_NIK === 2 ? { name: 'SIM', id: response.data.data[0].JENIS_NIK } : response.data.data[0].JENIS_NIK === 4 ? { name: 'Passport', id: response.data.data[0].JENIS_NIK } : []
            this.formData.nomor = response.data.data[0].JENIS_NIK === 1 ? response.data.data[0].NIK : response.data.data[0].JENIS_NIK === 2 ? response.data.data[0].SIM : response.data.data[0].PASSPORT
            this.formData.nama = response.data.data[0].NAMA
            this.formData.jenis_kelamin = response.data.data[0].JENIS_KELAMIN
            this.selectedJenisKelamin = response.data.data[0].JENIS_KELAMIN === null ? [] : { name: response.data.data[0].NAMA_JENIS_KELAMIN, id: response.data.data[0].JENIS_KELAMIN }
            this.formData.agama = response.data.data[0].AGAMA
            this.selectedAgama = response.data.data[0].AGAMA === null ? [] : { deskripsi: response.data.data[0].DESKRIPSI_AGAMA, id: response.data.data[0].AGAMA }
            this.formData.pendidikan = response.data.data[0].PENDIDIKAN
            this.selectedPendidikan = response.data.data[0].PENDIDIKAN === null ? [] : { deskripsi: response.data.data[0].DESKRIPSI_PENDIDIKAN, id: response.data.data[0].PENDIDIKAN }
            this.formData.pekerjaan = response.data.data[0].PEKERJAAN
            this.selectedPekerjaan = response.data.data[0].PEKERJAAN === null ? [] : { deskripsi: response.data.data[0].DESKRIPSI_PEKERJAAN, id: response.data.data[0].PEKERJAAN }
            this.formData.kewarganegaraan = response.data.data[0].KEWARGANEGARAAN
            this.selectedNegara = response.data.data[0].KEWARGANEGARAAN === null ? [] : { deskripsi: response.data.data[0].DESKRIPSI_KEWARGANEGARAAN, id: response.data.data[0].KEWARGANEGARAAN }
            this.formData.alamat = response.data.data[0].ALAMAT
            this.selectedProvinsi = response.data.data[0].KELURAHAN === null ? [] : { deskripsi: response.data.data[0].NAMA_PROVINSI, id: response.data.data[0].PROVINSI }
            this.selectedKabupaten = response.data.data[0].KELURAHAN === null ? [] : { deskripsi: response.data.data[0].NAMA_KABUPATEN, id: response.data.data[0].KABUPATEN }
            this.selectedKecamatan = response.data.data[0].KELURAHAN === null ? [] : { deskripsi: response.data.data[0].NAMA_KECAMATAN, id: response.data.data[0].KECAMATAN }
            this.selectedKelurahan = response.data.data[0].KELURAHAN === null ? [] : { deskripsi: response.data.data[0].NAMA_KELURAHAN, id: response.data.data[0].KELURAHAN }
            this.formData.wilayah = response.data.data[0].KELURAHAN === null ? '' : response.data.data[0].KELURAHAN
            this.formData.rt = response.data.data[0].RT
            this.formData.rw = response.data.data[0].RW
            this.formData.kodepos = response.data.data[0].KODEPOS
            this.formData.nomor_kontak = response.data.data[0].NOMOR
            this.tglResponNorm = response.data.data[0].TANGGAL_LAHIR
            this.formData.tempat_lahir = response.data.data[0].TEMPAT_LAHIR
            this.selectedTempatLahir = { deskripsi: response.data.data[0].nmTempatLahir, id: response.data.data[0].TEMPAT_LAHIR }
            this.classHide = 'd-block'
            this.classReviewHide = 'd-block'
            this.respon = response.data.message
            this.$refs['modal-sukses-norm'].show()
            const tglUmur = response.data.data[0].TANGGAL_LAHIR
            this.tglLahir = tglUmur
            this.getAge(tglUmur)
          }
          this.isLoading = false
        }).catch(error => {
          this.error = error.response
          this.isLoading = false
          this.formData.jenis = ''
          this.formData.nomor = ''
          this.formData.norm = ''
          this.formData.nama = ''
          this.formData.tempat_lahir = null
          this.selectedTempatLahir = []
          this.formData.tanggal_lahir = ''
          this.tglLahir = ''
          this.formData.usia = ''
          this.formData.jenis_kelamin = ''
          this.selectedJenisKelamin = []
          this.formData.agama = null
          this.selectedAgama = []
          this.formData.nomor_kontak = ''
          this.formData.alamat = ''
          this.formData.rt = ''
          this.formData.rw = ''
          this.formData.kodepos = ''
          this.formData.kewarganegaraan = null
          this.selectedNegara = []
          this.formData.pendidikan = null
          this.selectedPendidikan = []
          this.formData.pekerjaan = null
          this.selectedPekerjaan = []
          this.formData.email = ''
          this.formData.wilayah = null
          this.selectedProvinsi = []
          this.selectedKabupaten = []
          this.selectedKecamatan = []
          this.selectedKelurahan = []
          this.error = error.response.data.message
          this.$refs['modal-gagal-norm'].show()
        })
      } else {
        this.isLoading = false
        this.formData.jenis = ''
        this.formData.nomor = ''
        this.formData.norm = ''
        this.formData.nama = ''
        this.formData.tempat_lahir = null
        this.selectedTempatLahir = []
        this.formData.tanggal_lahir = ''
        this.tglLahir = ''
        this.formData.usia = ''
        this.formData.jenis_kelamin = ''
        this.selectedJenisKelamin = []
        this.formData.agama = null
        this.selectedAgama = []
        this.formData.nomor_kontak = ''
        this.formData.alamat = ''
        this.formData.rt = ''
        this.formData.rw = ''
        this.formData.kodepos = ''
        this.formData.kewarganegaraan = null
        this.selectedNegara = []
        this.formData.pendidikan = null
        this.selectedPendidikan = []
        this.formData.pekerjaan = null
        this.selectedPekerjaan = []
        this.formData.email = ''
        this.formData.wilayah = null
        this.selectedProvinsi = []
        this.selectedKabupaten = []
        this.selectedKecamatan = []
        this.selectedKelurahan = []
        this.error = 'nomor rekam medis harus 8 digit dan tidak mengandung huruf'
        this.$refs['modal-gagal-normValid'].show()
      }
    },
    valTglLahir () {
      const tglStr = this.tglLahir
      const tgl = tglStr.split('-').reverse().join('-')
      this.formData.tanggal_lahir = tgl
      this.getAge(tglStr)
    },
    async wilayah () {
      try {
        const res = await UTD.getWilayah()
        this.comboTempatLahir = res.data.tempatLahir
      } catch (err) {
        this.error = err
      }
    },
    async agama () {
      try {
        const res = await Online.agama()
        this.comboAgama = res.data.agama
      } catch (err) {
        this.error = err
      }
    },
    async pendidikan () {
      try {
        const res = await Online.pendidikan()
        this.comboPendidikan = res.data.pendidikan
      } catch (err) {
        this.error = err
      }
    },
    async pekerjaan () {
      try {
        const res = await Online.pekerjaan()
        this.comboPekerjaan = res.data.pekerjaan
      } catch (err) {
        this.error = err
      }
    },
    async Negara () {
      try {
        const res = await Online.kewarganegaraan()
        this.comboNegara = res.data.kewarganegaraan
      } catch (err) {
        this.error = err
      }
    },
    async getprovinsi () {
      await Online.provinsi().then(response => {
        if (response.status === 200) {
          this.comboProvinsi = response.data.provinsi
        }
      }).catch(error => {
        this.error = error.response
      })
    },
    async getkabupaten (value) {
      const data = {
        id: value.id
      }
      await Online.kabupaten(data).then(response => {
        if (response.status === 200) {
          this.comboKabupaten = response.data.kabupaten
        }
      }).catch(error => {
        this.error = error.response
      })
    },
    async getkecamatan (value) {
      const data = {
        id: value.id
      }
      await Online.kecamatan(data).then(response => {
        if (response.status === 200) {
          this.comboKecamatan = response.data.kecamatan
        }
      }).catch(error => {
        this.error = error.response
      })
    },
    async getkelurahan (value) {
      const data = {
        id: value.id
      }
      await Online.kelurahan(data).then(response => {
        if (response.status === 200) {
          this.comboKelurahan = response.data.desa
        }
      }).catch(error => {
        this.error = error.response
      })
    },
    async getCaraBayar () {
      await Online.caraBayar().then(response => {
        if (response.status === 200) {
          this.comboCaraBayar = response.data.carabayar
        }
      }).catch(error => {
        this.error = error.response
      })
    },
    async getunit (value) {
      const data = {
        instalasi: value.id
      }
      await Online.unit(data).then(response => {
        if (response.status === 200) {
          this.comboUnit = response.data.data
          this.comboUnitKuota = response.data.data
        }
      }).catch(error => {
        this.error = error.response
      })
    },
    async getruangan (value) {
      const data = {
        unit: value.id
      }
      await Online.ruangan(data).then(response => {
        if (response.status === 200) {
          this.comboRuangan = response.data.data
        }
      }).catch(error => {
        this.error = error.response
      })
    },
    async getruanganKuota () {
      const data = {
        unit: 1010101
      }
      await Online.ruangan(data).then(response => {
        if (response.status === 200) {
          this.comboRuanganKuota = response.data.data
        }
      }).catch(error => {
        this.error = error.response
      })
    },
    async member () {
      try {
        const res = await Online.getMember(jwtdecode(localStorage.getItem('patient')).user.id)
        this.comboMember = res.data.data
        this.respon = res.data.message
      } catch (err) {
        this.error = err.response.data.message
      }
    },
    async getnoBpjs () {
      this.isLoading = true
      if (this.nomorBPJS !== '') {
        const nokartu = this.nomorBPJS
        await Online.cekKBpjs(nokartu).then(response => {
          if (response.status === 200) {
            this.pesertaBPJS = response.data.peserta
            this.rujukanBPJS = response.data.rujukan
            this.$refs['modal-bpjs'].show()
            this.isLoading = false
          } else {
            this.error = response.data.message
            this.$refs['modal-gagal-kirim-2'].show()
            this.isLoading = false
            this.validasiBPJS = false
            this.clearBpjsForm()
          }
        }).catch(error => {
          this.error = error.response
          this.$refs['modal-gagal-kirim-2'].show()
          this.isLoading = false
          this.validasiBPJS = false
          this.clearBpjsForm()
        })
      } else {
        this.error = 'Nomor BPJS Harus Di Isi'
        this.$refs['modal-gagal-kirim-2'].show()
        this.isLoading = false
        this.validasiBPJS = false
        this.clearBpjsForm()
      }
    },
    testbtn (index) {
      let idx = index
      let dataPeserta = this.pesertaBPJS
      let dataRujukan = this.rujukanBPJS.rujukan
      this.formData.peserta.no_kartu = dataPeserta.noKartu
      this.formData.peserta.response.nik = dataPeserta.nik
      this.formData.peserta.response.nama = dataPeserta.nama
      this.formData.peserta.response.pisa = dataPeserta.pisa
      this.formData.peserta.response.sex = dataPeserta.sex
      this.formData.peserta.response.mr.noMR = dataPeserta.mr.noMR
      this.formData.peserta.response.mr.noTelepon = dataPeserta.mr.noTelepon
      this.formData.peserta.response.tglLahir = dataPeserta.tglLahir
      this.formData.peserta.response.tglCetakKartu = dataPeserta.tglCetakKartu
      this.formData.peserta.response.tglTAT = dataPeserta.tglTAT
      this.formData.peserta.response.tglTMT = dataPeserta.tglTMT
      this.formData.peserta.response.statusPeserta.kode = dataPeserta.statusPeserta.kode
      this.formData.peserta.response.statusPeserta.keterangan = dataPeserta.statusPeserta.keterangan
      this.formData.peserta.response.provUmum.kdProvider = dataPeserta.provUmum.kdProvider
      this.formData.peserta.response.provUmum.nmProvider = dataPeserta.provUmum.nmProvider
      this.formData.peserta.response.jenisPeserta.kode = dataPeserta.jenisPeserta.kode
      this.formData.peserta.response.jenisPeserta.keterangan = dataPeserta.jenisPeserta.keterangan
      this.formData.peserta.response.hakKelas.kode = dataPeserta.hakKelas.kode
      this.formData.peserta.response.hakKelas.keterangan = dataPeserta.hakKelas.keterangan
      this.formData.peserta.response.umur.umurSekarang = dataPeserta.umur.umurSekarang
      this.formData.peserta.response.umur.umurSaatPelayanan = dataPeserta.umur.umurSaatPelayanan
      this.formData.peserta.response.informasi.dinsos = dataPeserta.informasi.dinsos
      this.formData.peserta.response.informasi.prolanisPRB = dataPeserta.informasi.prolanisPRB
      this.formData.peserta.response.informasi.noSKTM = dataPeserta.informasi.noSKTM
      this.formData.peserta.response.cob.noAsuransi = dataPeserta.cob.noAsuransi
      this.formData.peserta.response.cob.nmAsuransi = dataPeserta.cob.nmAsuransi
      this.formData.peserta.response.cob.tglTMT = dataPeserta.cob.tglTMT
      this.formData.peserta.response.cob.noSKTM = dataPeserta.cob.noSKTM
      this.formData.rujukan.no_rujukan = this.rujukanBPJS.rujukan[idx].noKunjungan
      this.formData.rujukan.response.asalFaskes = this.rujukanBPJS.asalFaskes
      this.formData.rujukan.response.rujukan[0].noKunjungan = dataRujukan[idx].noKunjungan
      this.formData.rujukan.response.rujukan[0].tglKunjungan = dataRujukan[idx].tglKunjungan
      this.formData.rujukan.response.rujukan[0].provPerujuk.kode = dataRujukan[idx].provPerujuk.kode
      this.formData.rujukan.response.rujukan[0].provPerujuk.nama = dataRujukan[idx].provPerujuk.nama
      this.formData.rujukan.response.rujukan[0].peserta.noKartu = dataRujukan[idx].peserta.noKartu
      this.formData.rujukan.response.rujukan[0].peserta.nik = dataRujukan[idx].peserta.nik
      this.formData.rujukan.response.rujukan[0].peserta.nama = dataRujukan[idx].peserta.nama
      this.formData.rujukan.response.rujukan[0].peserta.pisa = dataRujukan[idx].peserta.pisa
      this.formData.rujukan.response.rujukan[0].peserta.sex = dataRujukan[idx].peserta.sex
      this.formData.rujukan.response.rujukan[0].peserta.mr.noMR = dataRujukan[idx].peserta.mr.noMR
      this.formData.rujukan.response.rujukan[0].peserta.mr.noTelepon = dataRujukan[idx].peserta.mr.noTelepon
      this.formData.rujukan.response.rujukan[0].peserta.tglLahir = dataRujukan[idx].peserta.tglLahir
      this.formData.rujukan.response.rujukan[0].peserta.tglCetakKartu = dataRujukan[idx].peserta.tglCetakKartu
      this.formData.rujukan.response.rujukan[0].peserta.tglTAT = dataRujukan[idx].peserta.tglTAT
      this.formData.rujukan.response.rujukan[0].peserta.tglTMT = dataRujukan[idx].peserta.tglTMT
      this.formData.rujukan.response.rujukan[0].peserta.statusPeserta.kode = dataRujukan[idx].peserta.statusPeserta.kode
      this.formData.rujukan.response.rujukan[0].peserta.statusPeserta.keterangan = dataRujukan[idx].peserta.statusPeserta.keterangan
      this.formData.rujukan.response.rujukan[0].peserta.provUmum.kdProvider = dataRujukan[idx].peserta.provUmum.kdProvider
      this.formData.rujukan.response.rujukan[0].peserta.provUmum.nmProvider = dataRujukan[idx].peserta.provUmum.nmProvider
      this.formData.rujukan.response.rujukan[0].peserta.jenisPeserta.kode = dataRujukan[idx].peserta.jenisPeserta.kode
      this.formData.rujukan.response.rujukan[0].peserta.jenisPeserta.keterangan = dataRujukan[idx].peserta.jenisPeserta.keterangan
      this.formData.rujukan.response.rujukan[0].peserta.hakKelas.kode = dataRujukan[idx].peserta.hakKelas.kode
      this.formData.rujukan.response.rujukan[0].peserta.hakKelas.keterangan = dataRujukan[idx].peserta.hakKelas.keterangan
      this.formData.rujukan.response.rujukan[0].peserta.umur.umurSekarang = dataRujukan[idx].peserta.umur.umurSekarang
      this.formData.rujukan.response.rujukan[0].peserta.umur.umurSaatPelayanan = dataRujukan[idx].peserta.umur.umurSaatPelayanan
      this.formData.rujukan.response.rujukan[0].peserta.informasi.dinsos = dataRujukan[idx].peserta.informasi.dinsos
      this.formData.rujukan.response.rujukan[0].peserta.informasi.prolanisPRB = dataRujukan[idx].peserta.informasi.prolanisPRB
      this.formData.rujukan.response.rujukan[0].peserta.informasi.noSKTM = dataRujukan[idx].peserta.informasi.noSKTM
      this.formData.rujukan.response.rujukan[0].peserta.cob.noAsuransi = dataRujukan[idx].peserta.cob.noAsuransi
      this.formData.rujukan.response.rujukan[0].peserta.cob.nmAsuransi = dataRujukan[idx].peserta.cob.nmAsuransi
      this.formData.rujukan.response.rujukan[0].peserta.cob.tglTMT = dataRujukan[idx].peserta.cob.tglTMT
      this.formData.rujukan.response.rujukan[0].peserta.cob.tglTAT = dataRujukan[idx].peserta.cob.tglTAT
      this.formData.rujukan.response.rujukan[0].diagnosa.kode = dataRujukan[idx].diagnosa.kode
      this.formData.rujukan.response.rujukan[0].diagnosa.nama = dataRujukan[idx].diagnosa.nama
      this.formData.rujukan.response.rujukan[0].keluhan = dataRujukan[idx].keluhan
      this.formData.rujukan.response.rujukan[0].poliRujukan.kode = dataRujukan[idx].poliRujukan.kode
      this.formData.rujukan.response.rujukan[0].poliRujukan.nama = dataRujukan[idx].poliRujukan.nama
      this.formData.rujukan.response.rujukan[0].pelayanan.kode = dataRujukan[idx].pelayanan.kode
      this.formData.rujukan.response.rujukan[0].pelayanan.nama = dataRujukan[idx].pelayanan.nama
      this.validasiBPJS = true
      this.$refs['modal-bpjs'].hide()
    },
    nameWithNorm ({ nama, norm, deskripsi }) {
      return `${nama} — ${norm.toString().padStart(8, '0')} — ${deskripsi}`
    },
    valMember (value) {
      this.normMember = value.norm
      this.valMemberAutoDigit()
    },
    valMemberAutoDigit () {
      const temp = this.normMember?.toString() || ''
      const generate = temp.padStart(8, '0')
      this.getNorm(generate)
    },
    valTempatLahir (value) {
      this.formData.tempat_lahir = value.id
    },
    valJenisKelamin (value) {
      this.formData.jenis_kelamin = value.id
    },
    valJenisIdentitas (value) {
      this.formData.jenis = value.id
    },
    valAgama (value) {
      this.formData.agama = value.id_referensi
    },
    valPendidikan (value) {
      this.formData.pendidikan = value.id_referensi
    },
    valPekerjaan (value) {
      this.formData.pekerjaan = value.id_referensi
    },
    valNegara (value) {
      this.formData.kewarganegaraan = value.id
    },
    valWilayah (value) {
      this.formData.wilayah = value.id
    },
    valCaraBayar (value) {
      this.formData.carabayar = value.id_referensi.toString()
      if (value.id_referensi !== 2) {
        this.clearBpjsForm()
      }
    },
    clearBpjsForm () {
      this.formData.peserta.no_kartu = null
      this.formData.peserta.response.nik = null
      this.formData.peserta.response.nama = null
      this.formData.peserta.response.pisa = null
      this.formData.peserta.response.sex = null
      this.formData.peserta.response.mr.noMR = null
      this.formData.peserta.response.mr.noTelepon = null
      this.formData.peserta.response.tglLahir = null
      this.formData.peserta.response.tglCetakKartu = null
      this.formData.peserta.response.tglTAT = null
      this.formData.peserta.response.tglTMT = null
      this.formData.peserta.response.statusPeserta.kode = null
      this.formData.peserta.response.statusPeserta.keterangan = null
      this.formData.peserta.response.provUmum.kdProvider = null
      this.formData.peserta.response.provUmum.nmProvider = null
      this.formData.peserta.response.jenisPeserta.kode = null
      this.formData.peserta.response.jenisPeserta.keterangan = null
      this.formData.peserta.response.hakKelas.kode = null
      this.formData.peserta.response.hakKelas.keterangan = null
      this.formData.peserta.response.umur.umurSekarang = null
      this.formData.peserta.response.umur.umurSaatPelayanan = null
      this.formData.peserta.response.informasi.dinsos = null
      this.formData.peserta.response.informasi.prolanisPRB = null
      this.formData.peserta.response.informasi.noSKTM = null
      this.formData.peserta.response.cob.noAsuransi = null
      this.formData.peserta.response.cob.nmAsuransi = null
      this.formData.peserta.response.cob.tglTMT = null
      this.formData.peserta.response.cob.noSKTM = null
      this.formData.rujukan.no_rujukan = null
      this.formData.rujukan.response.asalFaskes = null
      this.formData.rujukan.response.rujukan[0].noKunjungan = null
      this.formData.rujukan.response.rujukan[0].tglKunjungan = null
      this.formData.rujukan.response.rujukan[0].provPerujuk.kode = null
      this.formData.rujukan.response.rujukan[0].provPerujuk.nama = null
      this.formData.rujukan.response.rujukan[0].peserta.noKartu = null
      this.formData.rujukan.response.rujukan[0].peserta.nik = null
      this.formData.rujukan.response.rujukan[0].peserta.nama = null
      this.formData.rujukan.response.rujukan[0].peserta.pisa = null
      this.formData.rujukan.response.rujukan[0].peserta.sex = null
      this.formData.rujukan.response.rujukan[0].peserta.mr.noMR = null
      this.formData.rujukan.response.rujukan[0].peserta.mr.noTelepon = null
      this.formData.rujukan.response.rujukan[0].peserta.tglLahir = null
      this.formData.rujukan.response.rujukan[0].peserta.tglCetakKartu = null
      this.formData.rujukan.response.rujukan[0].peserta.tglTAT = null
      this.formData.rujukan.response.rujukan[0].peserta.tglTMT = null
      this.formData.rujukan.response.rujukan[0].peserta.statusPeserta.kode = null
      this.formData.rujukan.response.rujukan[0].peserta.statusPeserta.keterangan = null
      this.formData.rujukan.response.rujukan[0].peserta.provUmum.kdProvider = null
      this.formData.rujukan.response.rujukan[0].peserta.provUmum.nmProvider = null
      this.formData.rujukan.response.rujukan[0].peserta.jenisPeserta.kode = null
      this.formData.rujukan.response.rujukan[0].peserta.jenisPeserta.keterangan = null
      this.formData.rujukan.response.rujukan[0].peserta.hakKelas.kode = null
      this.formData.rujukan.response.rujukan[0].peserta.hakKelas.keterangan = null
      this.formData.rujukan.response.rujukan[0].peserta.umur.umurSekarang = null
      this.formData.rujukan.response.rujukan[0].peserta.umur.umurSaatPelayanan = null
      this.formData.rujukan.response.rujukan[0].peserta.informasi.dinsos = null
      this.formData.rujukan.response.rujukan[0].peserta.informasi.prolanisPRB = null
      this.formData.rujukan.response.rujukan[0].peserta.informasi.noSKTM = null
      this.formData.rujukan.response.rujukan[0].peserta.cob.noAsuransi = null
      this.formData.rujukan.response.rujukan[0].peserta.cob.nmAsuransi = null
      this.formData.rujukan.response.rujukan[0].peserta.cob.tglTMT = null
      this.formData.rujukan.response.rujukan[0].peserta.cob.tglTAT = null
      this.formData.rujukan.response.rujukan[0].diagnosa.kode = null
      this.formData.rujukan.response.rujukan[0].diagnosa.nama = null
      this.formData.rujukan.response.rujukan[0].keluhan = null
      this.formData.rujukan.response.rujukan[0].poliRujukan.kode = null
      this.formData.rujukan.response.rujukan[0].poliRujukan.nama = null
      this.formData.rujukan.response.rujukan[0].pelayanan.kode = null
      this.formData.rujukan.response.rujukan[0].pelayanan.nama = null
      this.validasiBPJS = false
    },
    valRuanganpoliKuota (value) {
      this.formLihatKuota.ruangan = value.id
    },
    valRuanganpoli (value) {
      this.tampungValueKuota = value.id
      const ruangan = this.tampungValueKuota
      const tgl = this.formData.tanggal_reservasi
      const jnsrsv = this.formData.jenis_reservasi
      const val = '2'
      if (tgl !== '' && ruangan !== null) {
        this.getKuotaIrj(ruangan, tgl, jnsrsv, val)
      } else {
        this.error = 'Tanggal kedatangan Atau Ruangan Tidak Boleh Kosong'
        this.$refs['modal-gagal-kirim-2'].show()
      }
      this.viewTgl = tgl.split('-').reverse().join('-')
    },
    async lihatkuota (selectedDates, dateStr, instance) {
      if (this.formLihatKuota.ruangan !== null) {
        const jnsrsv = this.formData.jenis_reservasi
        const ruangan = this.formLihatKuota.ruangan
        const tgl = dateStr.split('-').reverse().join('-')
        const val = '1'
        this.getKuotaIrj(ruangan, tgl, jnsrsv, val)
        this.viewTgl = dateStr
      } else {
        this.error = 'Pilih Instalasi, Unit, Dan Ruangan Terlebih Dahulu'
        this.$refs['modal-gagal-kirim-2'].show()
      }
    },
    async getKuotaIrj (ruangan, tgl, jnsrsv, val) {
      if (val === '1') {
        await Online.cekKuotaIrj(ruangan, tgl, jnsrsv).then(response => {
          if (response.status === 200 && response.data.data[0].jumlah_on) {
            this.kuotaSeeIRJ = response.data.data[0]
            this.kuotaClass = 'd-block'
          } else {
            this.kuotaSeeIRJ = {}
            this.kuotaClass = 'd-block'
          }
        }).catch(error => {
          this.error = error.response
          this.kuotaSeeIRJ = {}
          this.kuotaClass = 'd-block'
        })
      } else if (val === '2') {
        await Online.cekKuotaIrj(ruangan, tgl, jnsrsv).then(response => {
          if (response.status === 200 && response.data.data[0].jumlah_on) {
            this.respon = response.data.data[0]
            this.formData.ruangan = this.tampungValueKuota
          } else {
            this.respon = 'Kuota Tidak Tersedia'
            this.tampungValueKuota = null
            this.formData.ruangan = null
          }
        }).catch(error => {
          this.respon = 'Kuota Tidak Tersedia'
          this.error = error.response
          this.tampungValueKuota = null
          this.formData.ruangan = null
        })
      }
    },
    async onComplete () {
      this.isLoading = true
      const data = this.formData
      if (this.formData.carabayar === '2') {
        if (this.validasiBPJS === true) {
          await Online.createReservasiIRJ(data).then(response => {
            if (response.status === 200 || response.status === 201 || response.status === 202) {
              this.respon = response.data.message
              this.qrcode = response.data.data
              this.resKet = response.data.keterangan
              this.$refs['modal-sukses-qrcode'].show()
              this.isLoading = false
            }
          }).catch(error => {
            this.error = error.response.data.message
            this.$refs['modal-gagal-kirim-2'].show()
            this.isLoading = false
          })
        } else if (this.validasiBPJS === false) {
          this.error = 'Anda Belum Memlakukan Validasi BPJS'
          this.$refs['modal-gagal-kirim-2'].show()
          this.isLoading = false
        }
      } else {
        await Online.createReservasiIRJ(data).then(response => {
          if (response.status === 200 || response.status === 201 || response.status === 202) {
            this.respon = response.data.message
            this.qrcode = response.data.data
            this.resKet = response.data.keterangan
            this.$refs['modal-sukses-qrcode'].show()
            this.isLoading = false
          }
        }).catch(error => {
          this.error = error.response.data.message
          this.$refs['modal-gagal-kirim-2'].show()
          this.isLoading = false
        })
      }
    },
    kuotaClose () {
      this.kuotaClass = 'd-none'
    },
    dateDisabled (ymd, date) {
      const today = new Date()
      today.setDate(today.getDate())
      today.setHours(0, 0, 0, 0)
      return date < new Date(today.getTime() + 2 * 24 * 3600 * 1000)
    },
    refreshpage () {
      location.reload()
    },
    getAge (date) {
      if (date) {
        const tgl = date.split('-').reverse().join('-')
        const today = moment(new Date())
        const dob = moment(new Date(tgl))
        var duration = moment.duration(today.diff(dob))
        var age = duration.years().toString().padStart(2, '0') + ' Tahun ' + duration.months().toString().padStart(2, '0') + ' Bulan ' + duration.days().toString().padStart(2, '0') + ' Hari'
      }
      this.formData.usia = age
    }
  }
}
</script>
<style>
@import '../../assets/css/pages/public/reservasi-irj-griya.css';
</style>
