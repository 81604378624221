<template>
  <b-container fluid class="utdPendaftaran">
    <div v-show="isLoading">
        <div id="show-overlay"></div>
      <Loader :style="{ opacity: .5 }" />
    </div>
    <b-row class="mb-3">
      <b-col>
        <iq-card class="h-100">
          <template v-slot:headerTitle>
            <h4 class="card-title"><i class="fas fa-book-medical text-secondary"></i> Halaman Pendaftaran</h4>
          </template>
          <template v-slot:body>
            <div>
              <b-row class="mb-3">
                <b-col cols="12" lg="6">
                  <b-row>
                    <b-col>
                      <div>
                        <div class="mb-1">
                          <b-row>
                            <b-col cols="6" md="5" lg="4">
                              <p class="text-dark mb-0">Nama Pendonor</p>
                            </b-col>
                            <b-col cols="1">
                              <p class="text-dark mb-0">:</p>
                            </b-col>
                            <b-col>
                              <p class="text-dark mb-0"><span>{{ nama }}</span></p>
                            </b-col>
                          </b-row>
                        </div>
                        <div class="mb-1">
                          <b-row>
                            <b-col cols="6" md="5" lg="4">
                              <p class="text-dark mb-0">Umur Pendonor</p>
                            </b-col>
                            <b-col cols="1">
                              <p class="text-dark mb-0">:</p>
                            </b-col>
                            <b-col>
                              <p class="text-dark mb-0"><span>{{ umur }}</span></p>
                            </b-col>
                          </b-row>
                        </div>
                        <div class="mb-0">
                          <b-row>
                            <b-col cols="6" md="5" lg="4">
                              <p class="text-dark mb-0">Jenis Kelamin Pendonor</p>
                            </b-col>
                            <b-col cols="1">
                              <p class="text-dark mb-0">:</p>
                            </b-col>
                            <b-col>
                              <p class="text-dark mb-0">{{ jenis_kelamin }}</p>
                            </b-col>
                          </b-row>
                        </div>
                      </div>
                    </b-col>
                  </b-row>
                </b-col>
                <b-col cols="12" lg="6">
                  <div class="text-right">
                    <p class="h6 mb-0">Nomor Reservasi</p>
                    <p class="h3 text-primary font-weight-bold mb-0">{{ no_resv }}</p>
                  </div>
                </b-col>
              </b-row>
              <b-row class="mb-1">
                <b-col cols="12" md="4" lg="3" class="pr-1">
                  <div class="shadow-sm border p-3 mb-4">
                    <p class="h6 text-dark">Perubahan Tanggal Lahir :</p>
                    <b-form>
                      <div>
                        <p class="font-weight-normal mb-0">Pilih Tanggal Lahir :</p>
                      </div>
                      <flat-pickr @on-change="valTglLahir" v-model="tanggal_lahir" :config="config"
                        class="w-100 calender-small flatpickr-cstm-lab" placeholder="PILIH TANGGAL">
                      </flat-pickr>
                    </b-form>
                  </div>
                </b-col>
                <b-col cols="12" md="4" lg="3" class="pr-1 pl-1">
                  <div class="shadow-sm border p-3 mb-4">
                    <p class="h6 text-dark">Perubahan Jenis Layanan :</p>
                    <b-form>
                      <div>
                        <p class="font-weight-normal mb-0">Pilih Jenis Layanan :</p>
                      </div>
                      <b-form-group class="mb-0">
                        <b-form-select class="selected-cstm-utd" :options="optionsjenisLayanan"
                          v-model="result.jenis_layanan">
                        </b-form-select>
                      </b-form-group>
                    </b-form>
                  </div>
                </b-col>
                <b-col cols="12" md="7" lg="6" class="pl-1">
                  <div class="shadow-sm border p-3 mb-4">
                    <p class="h6 text-dark">Form Berat Badan :</p>
                    <b-form class="mt-2">
                      <div class="row">
                        <div class="col-12 col-lg-6 pr-1">
                          <div>
                            <p class="font-weight-normal mb-0">Masukan Berat Badan (Kilogram)</p>
                          </div>
                          <b-form-group class="mb-0">
                            <b-form-input id="exampleInputText1" type="number" v-model="result.berat_badan"
                              placeholder="Berat Badan Sekarang"></b-form-input>
                          </b-form-group>
                        </div>
                        <div class="col-12 col-lg-6 pl-1">
                          <div>
                            <p class="font-weight-normal mb-0">Status Lolos Berat Badan :</p>
                          </div>
                          <b-form-group class="mb-0">
                            <b-form-select class="selected-cstm-utd" :options="statusBeratBadan"
                              v-model="result.status_berat_badan" @input="stspendaftaran">
                            </b-form-select>
                          </b-form-group>
                        </div>
                      </div>
                    </b-form>
                  </div>
                </b-col>
              </b-row>
              <div class="btn-cancel" v-if="statusFinal !== 0">
                <b-button variant="warning" @click="resetFinal"><i class="fas fa-edit"></i> Perubahan Data</b-button>
              </div>
              <b-row v-if="result.berat_badan !== null && result.status_berat_badan !== null && statusFinal === 0">
                <b-col>
                  <div class="mb-1">
                    <small class="text-danger">Peringatan : Harap Teliti Saat Pengisian</small>
                  </div>
                  <button type="button" @click="onSubmit" class="btn btn-primary">
                    <i class="fas fa-window-restore"></i> Simpan Pendaftaran
                  </button>
                </b-col>
              </b-row>
            </div>
          </template>
        </iq-card>
      </b-col>
    </b-row>
    <b-row class="justify-content-center">
      <b-col md="2">
        <iq-card>
          <button type="button" @click="utdList" class="btn btn-primary btn-block">
            <i class="fas fa-undo"></i> Kembali
          </button>
        </iq-card>
      </b-col>
    </b-row>
    <b-modal ref="modal-peringatan" size="md" title="Informasi" ok-title="Saya Mengerti" :ok-only="true" @ok="move"
      :no-close-on-backdrop="true" :static="true" :hide-header-close="true" centered>
      <div class="text-center">
        <p class="h1 text-success font-weight-bold mb-3"><i class="fas fa-thumbs-up"></i></p>
        <p class="h6 text-success mb-0">{{ notif }}</p>
      </div>
    </b-modal>
    <b-modal ref="modal-gagal-kirim" class="shadow" hide-backdrop size="lg" title="PERINGATAN" ok-title="Selesai"
      :ok-only="true" @ok="move" :no-close-on-backdrop="true" :static="true" :hide-header-close="true" centered>
      <div class="text-center px-4 py-5">
        <p class="h1 text-danger font-weight-bold mb-3"><i class="fas fa-exclamation"></i></p>
        <p class="h6 text-danger mb-0">{{ error }}</p>
      </div>
    </b-modal>
  </b-container>
</template>
<script>
import { xray } from '../../../config/pluginInit'
import UTD from '../../../services/utd.js'
import FlatPickr from 'vue-flatpickr-component'
import 'flatpickr/dist/flatpickr.css'
import moment from 'moment'
export default {
  name: 'UtdPendaftaran',
  components: {
    FlatPickr
  },
  data () {
    return {
      isLoading: false,
      access: {
        isAdmin: false,
        bitcontrol2: 0
      },
      id: null,
      tgl: null,
      error: '',
      notif: '',
      pasien: '',
      utilityClass: 0,
      result: {
        id_dft_trx_reservasi_utd_detail: this.$route.params.id,
        tanggal_lahir: '',
        jenis_layanan: '',
        berat_badan: null,
        status_berat_badan: null,
        status_pendaftaran: null,
        petugas_pendaftaran: xray.currentUser().id
      },
      statusBeratBadan: [
        { value: null, text: 'Pilih Status', disabled: true },
        { value: 1, text: 'Lolos' },
        { value: 2, text: 'Tidak Lolos' }
      ],
      optionsjenisLayanan: [
        { value: null, text: 'Pilih Jenis layanan', disabled: true },
        { value: 1, text: 'Gedung' },
        { value: 2, text: 'Mobile Unit' }
      ],
      config: {
        dateFormat: 'd-m-Y',
        maxDate: new Date()
      },
      nama: '',
      umur: '',
      tanggal_lahir: '',
      jenis_kelamin: '',
      no_resv: '',
      statusFinal: 0
    }
  },
  mounted () {
    xray.index()
    this.utlity()
  },
  methods: {
    utlity () {
      const id = this.$route.params.id
      const nama = this.$route.query.nama
      const status = this.$route.query.status
      const umur = this.$route.query.umur
      const jk = this.$route.query.jk
      const noresrv = this.$route.query.noresrv
      const jl = this.$route.query.jl
      this.detailPasien(id)
      this.capNama(nama)
      this.capUmur(umur)
      this.capJK(jk)
      this.capResrv(noresrv)
      this.capJl(jl)
      this.statusFinal = status
      this.access.isAdmin = xray.isAdministrator()
      this.access.bitcontrol2 = xray.bitcontrol(this.$route.name).bitcontrol2.slice(0, 1)
      if (this.access.bitcontrol2 === '1' || this.access.isAdmin === 'true') {
        this.utilityClass = 1
      }
    },
    utdList () {
      this.$router.push({ name: 'utd.list' })
    },
    async detailPasien (id) {
      const data = {
        id: id
      }
      await UTD.viewPendaftaran(data).then(response => {
        if (response.status === 200) {
          if (response.data.data[0] !== undefined) {
            this.pasien = response.data.data[0]
            this.result.berat_badan = this.pasien.berat_badan
            this.result.status_berat_badan = this.pasien.status_berat_badan
          }
        }
      }).catch(error => {
        this.error = error.response || error.response.status
      })
    },
    async onSubmit (e) {
      this.isLoading = true
      const pasienUtd = this.result
      try {
        const pasienUtdResponse = await UTD.pendaftaran(pasienUtd)
        if (pasienUtdResponse.status === 200) {
          this.isLoading = false
          this.notif = pasienUtdResponse.data.message
          this.$refs['modal-peringatan'].show()
        }
      } catch (err) {
        this.isLoading = false
        this.error = err.response.data.message
        this.$refs['modal-gagal-kirim'].show()
      }
    },
    capNama (nama) {
      this.nama = nama
    },
    capJK (jk) {
      this.jenis_kelamin = jk
    },
    capJl (jl) {
      this.result.jenis_layanan = jl
    },
    capResrv (noresrv) {
      this.no_resv = noresrv
    },
    capUmur (umur) {
      this.result.tanggal_lahir = umur
      this.tanggal_lahir = umur.split('-').reverse().join('-')
      const date = moment(new Date(umur))
      const today = moment(new Date())
      const dob = moment(new Date(date))
      var duration = moment.duration(today.diff(dob))
      var age = duration.years().toString().padStart(2, '0') + ' Tahun ' + duration.months().toString().padStart(2, '0') + ' Bulan ' + duration.days().toString().padStart(2, '0') + ' Hari'
      this.umur = age
    },
    valTglLahir (val) {
      let date = new Date(val)
      let year = date.getFullYear()
      let month = date.getMonth() + 1
      let day = date.getDate()
      if (month < 10) {
        month = '0' + month
      }
      if (day < 10) {
        day = '0' + day
      }
      let joinDate = year + '-' + month + '-' + day
      this.result.tanggal_lahir = joinDate
    },
    resetFinal () {
      this.statusFinal = 0
    },
    move () {
      this.$router.push({ name: 'utd.list' })
    },
    stspendaftaran () {
      if (this.result.status_berat_badan === 1) {
        this.result.status_pendaftaran = 1
      } else {
        this.result.status_pendaftaran = 0
      }
    }
  }
}
</script>
<style>
.utdPendaftaran .form-control {
  color: black !important;
}

.utdPendaftaran .custom-select {
  color: black !important;
}

.utdPendaftaran .flatpickr-input {
    height: 45px;
    border-radius: 10px;
    border: 1px solid #d7dbda;
    padding-left: 10px;
    padding-right: 10px;
}
</style>
