import Api from './fatmahost/axiosInstance'

const END_POINT = 'reservasi/mcu'
export default {
  show (id) {
    return Api.laravel.get(`${END_POINT}/${id}`)
  },
  store (data) {
    return Api.laravel.post(END_POINT, data)
  },
  all (page, params) {
    if (params !== null) {
      return Api.laravel.get(`${END_POINT}/?params=${params}`)
    } else {
      return Api.laravel.get(`${END_POINT}/?page=${page}`)
    }
  },
  delete (id) {
    return Api.laravel.delete(`${END_POINT}/${id}`)
  }

}
