<template>
  <b-container fluid>
    <b-row>
      <b-col cols="4" class="pr-1">
        <iq-card>
          <template v-slot:headerTitle>
            <h6 class="card-title">Module Akses</h6>
          </template>
          <template v-slot:body>
            <div style="height: 400px; overflow-y: hidden; overflow-y: scroll;">
              <ul class="list-inline">
              <div v-for="item in modul" :key="item.id_ref">
                <li class="d-flex align-items-center justify-content-between mb-1" v-if="item.id_ref.length == 2">
                  <!-- <p>{{ item }}</p> -->
                  <b-form-checkbox v-model="memodul" :value="item.id" :disabled="item.id === 1">{{ item.name }}</b-form-checkbox>
                </li>
                <li class="d-flex align-items-center justify-content-between ml-4" v-if="item.id_ref.length == 4">
                  <b-form-checkbox v-model="memodul" :value="item.id"> {{ item.name }} </b-form-checkbox>
                </li>
              </div>
            </ul>
            </div>
          </template>
          <hr class="m-0">
          <div class="px-4 py-3 text-center">
            <b-button @click="createPengguna" class="btn btn-success shadow-sm">Simpan Module</b-button>
          </div>
        </iq-card>
      </b-col>
      <b-col cols="4" class="pl-1">
        <iq-card>
          <template v-slot:headerTitle>
            <h6 class="card-title">Fitur Tambahan</h6>
          </template>
          <template v-slot:body>
            <div style="height: 400px; overflow-y: hidden; overflow-y: scroll;">
              <ul class="list-inline">
              <div v-for="item in fitur" :key="item.id">
                <li class="d-flex align-items-center justify-content-between mb-1">
                  <!-- <p>{{ item }}</p> -->
                  <b-form-checkbox v-model="mefitur" :value="item.id">{{ item.description }}</b-form-checkbox>
                </li>
              </div>
            </ul>
            </div>
          </template>
          <hr class="m-0">
          <div class="px-4 py-3 text-center">
            <b-button @click="createFitur" class="btn btn-success shadow-sm">Simpan Fitur</b-button>
          </div>
        </iq-card>
      </b-col>
    </b-row>
    <div class="d-md-none mb-4">
      <button type="button" @click="listpegawai" class="btn btn-primary shadow"><i
          class="las la-arrow-left"></i>Kembali</button>
    </div>
    <!-- <b-row>
      <b-col lg="4">
        <iq-card body-class="iq-card-body pl-0 pr-0 pt-0">
          <template v-slot:body>
            <div class="docter-details-block">
              <div class="doc-profile-bg bg-primary" style="height:50px;">
              </div>
              <div class="docter-profile text-center mt-2">
                <b-img :src="pegawai.jenis_kelamin === 1 ? imgProfile.pria : imgProfile.wanita" alt="profile-img"
                  class="avatar-130 img-fluid" />
              </div>
              <div class="text-center mt-3 pl-3 pr-3">
                <h4><b>{{ pegawai.gelar_depan == null ? pegawai.gelar_depan : pegawai.gelar_depan +'.' }} {{
                    pegawai.nama }} {{ pegawai.gelar_belakang }}</b></h4>
                <p class="mb-0">{{ pegawai.nip }}</p>
                <p class="mb-0">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Delectus repudiandae eveniet harum.</p>
              </div>
              <ul class="doctoe-sedual d-flex align-items-center justify-content-between p-0 m-0">
                <li class="text-center">
                  <h3 class="counter">5</h3>
                  <span>Terlambat</span>
                </li>
                <li class="text-center">
                  <h3 class="counter">1</h3>
                  <span>Tidak Masuk</span>
                </li>
                <li class="text-center">
                  <h3 class="counter">62:24:50</h3>
                  <span>Jam Kerja</span>
                </li>
              </ul>
            </div>
          </template>
        </iq-card>
        <iq-card class="mb-4">
          <template v-slot:headerTitle>
            <h4 class="card-title">Personal Information</h4>
          </template>
          <template v-slot:body>
            <div class="iq-card-body">
              <div class="about-info m-0 p-0">
                <div class="row">
                  <div class="col-2 text-dark">NIP</div>
                  <div class="col-1 text-dark">:</div>
                  <div class="col-8 text-dark font-weight-bold">{{ pegawai.nip }}</div>
                  <div class="col-2 text-dark">Nama</div>
                  <div class="col-1 text-dark">:</div>
                  <div class="col-8 text-dark font-weight-bold">{{ pegawai.nama }}</div>
                  <div class="col-4">Umur:</div>
                      <div class="col-8">{{ new Date(pegawai.tanggal_lahir) | umur }}</div>
                  <div class="col-2 text-dark">Profesi</div>
                  <div class="col-1 text-dark">:</div>
                  <div class="col-8 text-dark font-weight-bold">{{ pegawai.nm_profesi }}</div>
                </div>
              </div>
            </div>
          </template>
        </iq-card>
      </b-col>
      <b-col lg="4">
        <iq-card>
          <template v-slot:headerTitle>
            <h4 class="card-title">Module</h4>
          </template>
          <template v-slot:body>
            <ul class="list-inline">
              <div v-for="item in modul" :key="item.id_ref">
                <li class="d-flex align-items-center justify-content-between mb-1" v-if="item.id_ref.length == 2">
                  <p>{{ item }}</p>
                  <b-form-checkbox v-model="memodul" :value="item.id" :disabled="item.id === 1">{{ item.name }}</b-form-checkbox>
                </li>
                <li class="d-flex align-items-center justify-content-between ml-4" v-if="item.id_ref.length == 4">
                  <b-form-checkbox v-model="memodul" :value="item.id"> {{ item.name }} </b-form-checkbox>
                </li>
              </div>
            </ul>
          </template>
          <hr class="m-0">
          <div class="px-4 py-3 text-center">
            <b-button @click="createPengguna" class="btn btn-success shadow-sm">Simpan Module</b-button>
          </div>
        </iq-card>
      </b-col>
      <b-col lg="4">
        <iq-card>
          <template v-slot:headerTitle>
            <h4 class="card-title">Fitur Tambahan</h4>
          </template>
          <template v-slot:body>
            <ul class="list-inline">
              <div v-for="item in fitur" :key="item.id">
                <li class="d-flex align-items-center justify-content-between mb-1">
                  <p>{{ item }}</p>
                  <b-form-checkbox v-model="mefitur" :value="item.id">{{ item.description }}</b-form-checkbox>
                </li>
              </div>
            </ul>
          </template>
          <hr class="m-0">
          <div class="px-4 py-3 text-center">
            <b-button @click="createFitur" class="btn btn-success shadow-sm">Simpan Fitur</b-button>
          </div>
        </iq-card>
      </b-col>
      <b-col lg="4">
        <lottie :options="defaultOptions" :autoPlay="true" :width="500" :height="500" />
      </b-col>
    </b-row> -->
    <div class="d-none d-md-block">
      <b-row class="mb-4">
        <b-col cols="2">
          <button type="button" @click="listpegawai" class="btn btn-primary btn-block shadow"><i
              class="las la-arrow-left"></i>Kembali
          </button>
        </b-col>
      </b-row>
    </div>
  </b-container>
</template>
<script>
import { xray } from '../../../config/pluginInit'
import animationLogo from '../../../assets/lottie/pengguna.json'
import Service from '../../../services/fatmahost/index'
import User from '../../../services/user'
// import Modules from '../../services/module'
import Vue from 'vue'
import VueToast from 'vue-toast-notification'
import 'vue-toast-notification/dist/theme-sugar.css'

Vue.use(VueToast)

export default {
  components: {
    // Lottie
  },
  mounted () {
    xray.index()
    this.viewPegawai(this.$route.params.id)
    this.viewUserAccess(this.$route.params.id)
    this.modules()
    this.Fitures()
  },
  data () {
    return {
      defaultOptions: { animationData: animationLogo, loop: true },
      id_user: null,
      memodul: [],
      useraccess: null,
      modul: [],
      fitur: [],
      mefitur: [],
      error: [],
      imgProfile: {
        pria: require('../../../assets/images/user/14.jpg'),
        wanita: require('../../../assets/images/user/15.jpg')
      },
      doctor: {
        profile: require('../../../assets/images/user/1.jpg')
      },
      pegawai: []
    }
  },
  methods: {
    async createPengguna () {
      const data = []
      this.memodul.map((value) => {
        data.push({
          user_id: this.id_user,
          module_id: value
        })
      })
      this.createAccess(data)
    },
    async createAccess (data) {
      await User.store(data)
      Vue.$toast.open({
        message: 'Data berhasil disimpan',
        type: 'success',
        position: 'top-right'
      })
    },
    async createFitur () {
      const data = {
        user_id: this.id_user,
        module: []
      }
      this.mefitur.map((value) => {
        data.module.push({
          module_entity_id: value
        })
      })
      this.createAccessFitur(data)
    },
    // async createAccessFitur (data) {
    //   await Modules.store(data)
    //   Vue.$toast.open({
    //     message: 'Data berhasil disimpan',
    //     type: 'success',
    //     position: 'top-right'
    //   })
    // },
    async viewPegawai (id) {
      await Service.pegawai.findId(id).then(response => {
        if (response.status === 200) {
          this.pegawai = response.data.pegawai[0]
        }
      }).catch(error => {
        this.error = error.response
      })
    },
    async viewUserAccess (id) {
      await User.findId(id).then(response => {
        if (response.status === 200) {
          this.id_user = response.data.data.original.user
          const data = response.data.data.original.user_access
          data.forEach((value, index) => {
            this.memodul.push(value.module_id)
          })
          if (this.id_user !== null) {
            this.viewFitureAccess()
          }
        }
      }).catch(error => {
        this.error = error.response
      })
    },
    // async viewFitureAccess () {
    //   const id = this.id_user
    //   await Modules.showFitur(id).then(response => {
    //     if (response.status === 200) {
    //       const data = response.data.data
    //       data.forEach((value) => {
    //         this.mefitur.push(value.module_entity_id)
    //       })
    //     }
    //   }).catch(error => {
    //     this.error = error.response
    //   })
    // },
    // async modules () {
    //   await Modules.fetchAll().then(response => {
    //     if (response.status === 200) {
    //       this.modul = response.data.data
    //     }
    //   }).catch(error => {
    //     this.error = error.response || error.response.status
    //   })
    // },
    // async Fitures () {
    //   await Modules.fetchAllFitur().then(response => {
    //     if (response.status === 200) {
    //       this.fitur = response.data.data
    //     }
    //   }).catch(error => {
    //     this.error = error.response
    //   })
    // },
    listpegawai () {
      this.$router.push({ name: 'pengguna.list' })
    }
  }
}
</script>
