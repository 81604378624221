<template>
  <div class="emr-konsul">
    <b-card no-body>
      <b-tabs pills card>
        <b-tab active>
          <template #title>
            <span>Konsul</span>
          </template>
          <div class="p-2">
            <Konsul />
          </div>
        </b-tab>
        <b-tab>
          <template #title>
            Jawab Konsul
          </template>
          <div class="p-2">
            <Konsul />
          </div>
        </b-tab>
      </b-tabs>
    </b-card>
    <!-- <b-row class="justify-content-between">
      <b-col cols="12">
        <div class="border w-100 h-100">
          <div class="arrow bg-primary p-2">
            <span class="h6"><i class="fas fa-prescription-bottle-alt"></i> &nbsp; Form Konsul</span>
          </div>
          <div class="custom-scroller" :style="{height: heightPercent}">
            <div class="table mb-0">
              <b-table class="mb-0"
              size="sm"
              selectable
              select-mode="single"
              @row-clicked="getDetailLab"
              ref="table"
              hover
              :items="items.table"
              :fields="fields.table">
              </b-table>
            </div>
          </div>
        </div>
      </b-col>
    </b-row> -->
  </div>
</template>
<script>
import { xray } from '../../../../config/pluginInit.js'
import Services from '../../../../services/fatmahost/index.js'
import Konsul from './konsul/index.vue'

export default {
  name: 'KonsulOrder',
  components: {
    Konsul
  },
  data () {
    return {
      windowHeight: 0,
      heightPercent: 0,
      formOrder: '8',
      formDetail: '4',
      message: '',
      selected: [],
      fields: {
        table: [
          { key: 'no_urut', label: 'No.', class: 'text-uppercase text-center' },
          { key: 'no_resep', label: 'Tanggal', class: 'text-uppercase' },
          { key: 'asal', label: 'Ruang Asal', class: 'text-uppercase' },
          { key: 'tujuan', label: 'Ruang Tujuan', class: 'text-uppercase' },
          { key: 'tanggal', label: 'Dokter Asal', class: 'text-uppercase' },
          { key: 'dpjp1', label: 'Dokter Tujuan', class: 'text-uppercase' },
          { key: 'dpjp', label: 'Keterangan', class: 'text-uppercase' }
        ],
        table2: [
          { key: 'jawab', label: 'Jawaban', class: 'text-uppercase text-center' }
        ]
      },
      items: {
        table: this.getTableLab,
        table2: []
      }
    }
  },
  mounted () {
    xray.index()
    this.getWindowHeight()
    window.addEventListener('resize', this.getWindowHeight)
  },
  destroyed () {
    window.removeEventListener('resize', this.getWindowHeight)
  },
  methods: {
    async getTableLab () {
      try {
        const param1 = ``
        const param2 = `{"object":"SIMRS_EMR_RORDERRAD","xparam0":"${this.$route.params.id}","xparam1":"","xparam2":"","xparam3":"","xparam4":"","xparam5":"","xparam6":"","xparam7":"","xparam8":"","xparam9":""}`
        const response = await Services.middleware.find(param1, param2)
        return response.data.data
      } catch (error) {
        this.message = error
      }
    },
    async getDetailLab (item, index, event) {
      try {
        const param1 = ``
        const param2 = `{"object":"SIMRS_EMR_RORDERRAD_RINC","xparam0":"${item.id}","xparam1":"","xparam2":"","xparam3":"","xparam4":"","xparam5":"","xparam6":"","xparam7":"","xparam8":"","xparam9":""}`
        const response = await Services.middleware.find(param1, param2)
        this.items.table2 = response.data.data
      } catch (error) {
        this.message = error
      }
    },
    getWindowHeight () {
      this.windowHeight = window.innerHeight
      var calc = (50 / 100) * this.windowHeight
      this.heightPercent = calc + 'px'
    },
    closeRightForm () {
      this.formOrder = '11'
      this.formDetail = '1'
    },
    openRightForm () {
      this.formOrder = '8'
      this.formDetail = '4'
    }
  }
}
</script>

<style>
.form-control-emr {
  height: 33px!important;
  line-height: 33px!important;
  border-radius: 5px!important;
  font-size: 12px!important;
}

.emr-konsul .custom-scroller {
  position: relative;
  overflow-y: auto;
}

.emr-konsul .radius-custom-emr {
  border-radius: .5rem;
}

.emr-konsul .radius-custom-emr-head {
  border-top-left-radius: .5rem;
  border-top-right-radius: .5rem;
}

.emr-konsul .radius-custom-form-head-eresep-daftar-order {
  border-top-right-radius: .5rem;
}

.emr-konsul .eresep-daftar-order-fc-black {
  color: black;
  font-weight: bold;
}

.emr-konsul .eresep-daftar-order-form-input {
  font-size: 13px;
  height: 33px!important;
  border-radius: 5px;
}

.emr-konsul .table td {
    font-size: 13px;
    padding-top: 0px;
    padding-bottom: 0px;
}
.emr-konsul .table th {
    font-size: 13px;
    font-weight: bold;
}

.emr-konsul .table td, .emr-konsul .table th {
    padding: 0.20rem!important;
}

.emr-konsul .hover {
  transition: all .3s;
  cursor: pointer;
  background-color: rgb(255, 255, 255);
  color: rgb(107, 107, 107);
}

.emr-konsul .arrow:hover .hover {
  background-color: rgba(0, 0, 0, 0.596);
  color: rgb(255, 255, 255);
}

.emr-konsul .multiselect__single {
  font-size: 13px !important;
}
.emr-konsul .multiselect__placeholder {
  font-weight: normal !important;
  font-size: 13px !important;
  padding-top: 0px!important;
}
.emr-konsul .multiselect__option {
  font-size: 13px;
}
.emr-konsul .multiselect__tags {
  padding: 5px 40px 0 8px !important;
  height: 33px !important;
  min-height: 33px !important;
  border: 1px solid rgb(214, 214, 214);
}
.emr-konsul .multiselect__content-wrapper {
  font-size: 12px;
  font-weight: normal !important;
}
</style>
