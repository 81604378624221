<template>
  <div class="meningitis-admin">
    <b-container fluid>
      <iq-card>
        <template v-slot:headerTitle>
          <h5 class="card-title">Reservasi Sentra Vaksin</h5>
        </template>
        <template v-slot:headerAction>
          <div class="d-flex flex-row align-items-center mb-0 py-1 px-1 bg-filter flex-wrap">
            <div cols="2" class="mr-1">
              <b-form-group class="mb-0">
                <template>
                  <flat-pickr :config="configTgl" @on-change="filterTglAwal" v-model="preview.tglAwal" placeholder="Tanggal Awal" class="w-100 custom-flatpicker calender-small" />
                </template>
              </b-form-group>
              <!-- <b-form-select class="cstm-slct-kuota" v-model="selected.filterRuang" @change="valFilterRuang" :options="combo.filterRuang"></b-form-select> -->
            </div>
            <div cols="2" class="mr-1">
              <b-form-group class="mb-0">
                <template>
                  <flat-pickr :config="configTgl" @on-change="filterTglAkhir" v-model="preview.tglAkhir" placeholder="Tanggal Akhir" class="w-100 custom-flatpicker calender-small" />
                </template>
              </b-form-group>
              <!-- <b-form-select class="cstm-slct-kuota" v-model="selected.filterType" :options="combo.filterType"></b-form-select> -->
            </div>
            <div class="mr-1">
              <div>
                <b-form-select v-model="selected.filterType" @change="valType" :options="combo.filterType"></b-form-select>
              </div>
            </div>
            <div class="mr-1">
              <div>
                <b-form-input type="search" v-model="table.filter" class="bg-white border border-dark custom-search-kuota" placeholder="Cari"></b-form-input>
              </div>
            </div>
            <div cols="2" class="mr-1">
              <div>
                <b-button variant="warning" class="text-center py-2" style="border-radius: 5px;" @click="resetTable"> Reset Filter</b-button>
              </div>
            </div>
          </div>
        </template>
        <template v-slot:body>
          <div class="table-list-pasien mb-0">
            <b-row>
              <b-col>
                <b-table class="mb-0" ref="table" :busy="table.isBusy" :items="table.items" :fields="table.fields"
                  :current-page="table.currentPage" :per-page="table.perPage" :filter="table.filter" @filtered="onFiltered" stacked="md"
                  bordered hover small striped show-empty>
                  <template #table-busy>
                    <div class="text-center my-2">
                      <b-spinner class="align-middle text-primary mr-2"></b-spinner>
                      <strong class="text-dark">Memuat Data...</strong>
                    </div>
                  </template>
                  <template #cell(index)="data">
                    <span>{{ (table.currentPage*table.perPage)-table.perPage + data.index + 1 }}</span>
                  </template>
                  <template #cell(nomor_reservasi)="data">
                    <span>{{ data.item.nomor_reservasi }}</span>
                  </template>
                  <template #cell(norm)="data">
                    <span>{{ data.item.norm }}</span>
                  </template>
                  <template #cell(nama)="data">
                    <span>{{ data.item.nama }}</span>
                  </template>
                  <template #cell(nohp)="data">
                    <span>{{ data.item.nohp }}</span>
                  </template>
                  <template #cell(ruangan)="data">
                    <span>{{ data.item.ruangan }}</span>
                  </template>
                  <template #cell(deskripsi_dokter)="data">
                    <span>{{ data.item.deskripsi_dokter }}</span>
                  </template>
                  <template #cell(tanggal_reservasi)="data">
                    <span>{{ new Date(data.item.tanggal_reservasi) | formatDate }}</span>
                  </template>
                  <template #cell(shift)="data">
                    <span>{{ data.item.shift }}</span>
                  </template>
                  <!-- <template #cell(jenis_reservasi)="data">
                    <span class="font-size-responsive text-success" v-if="data.item.jenis_reservasi === 1 || data.item.jenis_reservasi === '1'"><i class="fas fa-globe"></i> Online</span>
                    <span class="font-size-responsive text-warning" v-else><i class="fas fa-hospital-alt"></i> Offline</span>
                  </template> -->
                  <template #cell(status)="data">
                    <span class="text-secondary" v-if="data.item.status === 1 || data.item.status === '1'">Belum Daftar</span>
                    <span v-else-if="data.item.status === 2 || data.item.status === '2'">Sudah Daftar <i class="fas fa-check-circle text-success"></i></span>
                    <span v-else-if="data.item.status === 3 || data.item.status === '3'">Batal Daftar <i class="fas fa-times text-danger"></i></span>
                  </template>
                  <template v-slot:cell(action)="data">
                    <b-button variant="white" size="md" :disabled="data.item.status === 1 ? false : true" @click="batalModal(data.item.id, data.item.jenis_reservasi, data.item.ruangan, data.item.tanggal_reservasi, data.item.nomor_reservasi, data.item.nama, data.item.nama_ruangan)">
                      <i :class="data.item.status === 1 ? 'ri-close-circle-fill ri-lg text-danger' : 'ri-close-circle-fill ri-lg text-secondary'"></i>
                    </b-button>
                    <b-button variant="white" size="md" :disabled="data.item.status === 1 ? false : true" @click="daftarModal(data.item.nomor_reservasi)">
                      <i :class="data.item.status === 1 ? 'ri-hotel-line ri-lg text-primary' : 'ri-hotel-line ri-lg text-secondary'"></i>
                    </b-button>
                    <b-button variant="white" size="md" @click="data.toggleDetails">
                      <i class="ri-eye-fill ri-lg text-primary"></i>
                    </b-button>
                  </template>
                  <template #row-details="data">
                    <b-card>
                      <b-row>
                        <b-col cols="12" md="6" lg="5" xl="4">
                          <div>
                            <b-row class="mb-1">
                              <b-col cols="4">
                                <p class="mb-0">Jenis Reservasi</p>
                              </b-col>
                              <b-col cols="1">
                                <p class="mb-0">:</p>
                              </b-col>
                              <b-col>
                                <p class="font-size-responsive text-success font-weight-bold mb-0" v-if="data.item.jenis_reservasi === 1 || data.item.jenis_reservasi === '1'">ONLINE</p>
                                <p class="font-size-responsive text-warning font-weight-bold mb-0" v-else>OFFLINE</p>
                              </b-col>
                            </b-row>
                            <b-row class="mb-1">
                              <b-col cols="4">
                                <p class="mb-0">Jenis Pasien</p>
                              </b-col>
                              <b-col cols="1">
                                <p class="mb-0">:</p>
                              </b-col>
                              <b-col>
                                <p class="mb-0" v-if="data.item.jenis_pasien === 1"> Pasien Lama</p>
                                <p class="mb-0" v-else> Pasien Baru</p>
                              </b-col>
                            </b-row>
                            <b-row class="mb-1">
                              <b-col cols="4">
                                <p class="mb-0">Cara Bayar</p>
                              </b-col>
                              <b-col cols="1">
                                <p class="mb-0">:</p>
                              </b-col>
                              <b-col>
                                <p class="mb-0" v-if="data.item.nama_carabayar">{{ data.item.nama_carabayar }}</p>
                                <p class="mb-0" v-else>-</p>
                              </b-col>
                            </b-row>
                            <b-row class="mb-1">
                              <b-col cols="4">
                                <p class="mb-0">Jenis Kelamin</p>
                              </b-col>
                              <b-col cols="1">
                                <p class="mb-0">:</p>
                              </b-col>
                              <b-col>
                                <p class="mb-0" v-if="data.item.jenis_kelamin === 1"> Laki - Laki</p>
                                <p class="mb-0" v-else> Perempuan</p>
                              </b-col>
                            </b-row>
                            <b-row class="mb-1">
                              <b-col cols="4">
                                <p class="mb-0">Tanggal Lahir</p>
                              </b-col>
                              <b-col cols="1">
                                <p class="mb-0">:</p>
                              </b-col>
                              <b-col>
                                <p class="mb-0">{{ new Date(data.item.tanggal_lahir) | formatDate }}</p>
                              </b-col>
                            </b-row>
                            <b-row class="mb-0">
                              <b-col cols="4">
                                <p class="mb-0">Usia</p>
                              </b-col>
                              <b-col cols="1">
                                <p class="mb-0">:</p>
                              </b-col>
                              <b-col>
                                <p class="mb-0"> {{ data.item.usia }}</p>
                              </b-col>
                            </b-row>
                          </div>
                        </b-col>
                        <b-col cols="12" md="6" lg="5" xl="4">
                          <div>
                            <b-row class="mb-1">
                              <b-col cols="4">
                                <p class="mb-0">Nomor Identitas</p>
                              </b-col>
                              <b-col cols="1">
                                <p class="mb-0">:</p>
                              </b-col>
                              <b-col>
                                <p class="mb-0"> {{ data.item.nomor }}</p>
                              </b-col>
                            </b-row>
                            <b-row class="mb-1">
                              <b-col cols="4">
                                <p class="mb-0">Nomor Kontak</p>
                              </b-col>
                              <b-col cols="1">
                                <p class="mb-0">:</p>
                              </b-col>
                              <b-col>
                                <p class="mb-0"> {{ data.item.nomor_kontak }}</p>
                              </b-col>
                            </b-row>
                            <b-row class="mb-1">
                              <b-col cols="4">
                                <p class="mb-0">Email</p>
                              </b-col>
                              <b-col cols="1">
                                <p class="mb-0">:</p>
                              </b-col>
                              <b-col>
                                <p class="mb-0" v-if="data.item.email"> {{ data.item.email }}</p>
                                <p class="mb-0" v-else> -</p>
                              </b-col>
                            </b-row>
                            <b-row class="mb-1">
                              <b-col cols="4">
                                <p class="mb-0">Alamat</p>
                              </b-col>
                              <b-col cols="1">
                                <p class="mb-0">:</p>
                              </b-col>
                              <b-col>
                                <p class="mb-0"> {{ data.item.alamat }}</p>
                              </b-col>
                            </b-row>
                            <b-row class="mb-1">
                              <b-col cols="4">
                                <p class="mb-0">Kewarganegaraan</p>
                              </b-col>
                              <b-col cols="1">
                                <p class="mb-0">:</p>
                              </b-col>
                              <b-col>
                                <p class="mb-0" v-if="data.item.kewarganegaraan === 71"> Indonesia</p>
                                <p class="mb-0" v-else> Asing</p>
                              </b-col>
                            </b-row>
                            <b-row class="mb-0">
                              <b-col cols="4">
                                <p class="mb-0">Alasan Batal</p>
                              </b-col>
                              <b-col cols="1">
                                <p class="mb-0">:</p>
                              </b-col>
                              <b-col>
                                <p class="text-danger mb-0" v-if="data.item.alasan_batal"> {{ data.item.alasan_batal }}</p>
                                <p class="mb-0" v-else> -</p>
                              </b-col>
                            </b-row>
                          </div>
                        </b-col>
                      </b-row>
                    </b-card>
                  </template>
                </b-table>
              </b-col>
            </b-row>
          </div>
          <div class="bg-white shadow-sm pb-2 pt-2 pl-3 pr-3 border-bottom border-right border-left">
            <b-row class="align-items-center justify-content-between">
              <b-col cols="12" lg="3" class="mr-auto">
                <div class="d-flex align-items-center">
                  <div>
                    <b-pagination v-model="table.currentPage" :total-rows="table.totalRows" :per-page="table.perPage" size="sm" class="my-0">
                    </b-pagination>
                  </div>
                  <div class="border-left border-right px-3">
                    <span class="cursor-point" @click="tableRefresh">
                      <i class="fas fa-sync"></i>
                    </span>
                  </div>
                </div>
              </b-col>
              <!-- <b-col class="pr-1" cols="12" lg="2">
                <div class="d-flex flex-row justify-content-end">
                  <div>
                    <b-button variant="white" class="border py-2 rounded-0 text-right" @click="filterAdvancedModal"><i class="fas fa-filter"></i> Filter Tabel</b-button>
                  </div>
                </div>
              </b-col> -->
              <b-col cols="12" lg="4" class="d-flex justify-content-end align-items-center">
                <div class="mr-3">
                  <b-form-select id="per-page-select" v-model="table.perPage" :options="table.pageOptions" size="sm"
                  ></b-form-select>
                </div>
                <div>
                  <p class="font-weight-normal mb-0 text-dark" v-if="table.totalRows">Menampilkan 1 -  {{ table.perPage }} dari {{ table.totalRows }}</p>
                </div>
              </b-col>
            </b-row>
          </div>
          <!-- <pre>{{ action.pembatalan }}</pre> -->
        </template>
      </iq-card>
      <section id="modal-component">
        <b-modal ref="modal-daftar" size="md" title="Daftar Meningitis" ok-title="Daftarkan Reservasi" cancel-title="Tutup" @ok="daftarMeni" :ok-disabled="action.daftar.paspor ? false : true" :no-close-on-backdrop="true" :static="true" centered>
          <div class="px-1 py-1">
            <b-form-input size="sm" class="form-control rounded-0" v-model="action.daftar.paspor" placeholder="Masukan Passpor"></b-form-input>
          </div>
          <!-- <pre>{{ action.daftar }}</pre> -->
        </b-modal>
        <b-modal ref="modal-pembatalan" size="md" title="Pembatalan Reservasi" ok-title="Batalkan Reservasi" cancel-title="Tutup" @cancel="clearData" @ok="onBatal" :ok-disabled="action.okDisable" :no-close-on-backdrop="true" :static="true" centered>
          <div class="px-1 py-1">
            <b-row class="mb-2">
              <b-col cols="12" lg="4">
                <p class="text-dark mb-0">NOMOR RESERVASI</p>
              </b-col>
              <b-col cols="12" lg="1">
                <p class="text-dark mb-0">:</p>
              </b-col>
              <b-col>
                <p class="text-dark text-uppercase font-weight-bold mb-0">{{ action.pembatalan.nomor_reservasi }}</p>
              </b-col>
            </b-row>
            <b-row class="text-dark mb-2">
              <b-col cols="12" lg="4">
                <p class="text-dark mb-0">NAMA</p>
              </b-col>
              <b-col cols="12" lg="1">
                <p class="text-dark mb-0">:</p>
              </b-col>
              <b-col>
                <p class="text-dark text-uppercase font-weight-bold mb-0">{{ preview.pembatalan.nama }}</p>
              </b-col>
            </b-row>
            <b-row class="mb-2">
              <b-col cols="12" lg="4">
                <p class="text-dark mb-0">POLI</p>
              </b-col>
              <b-col cols="12" lg="1">
                <p class="text-dark mb-0">:</p>
              </b-col>
              <b-col>
                <p class="text-dark text-uppercase font-weight-bold mb-0">{{ preview.pembatalan.ruangan }}</p>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="12" lg="4">
                <p class="text-dark mb-0">Alasan</p>
              </b-col>
              <b-col cols="12" lg="1">
                <p class="text-dark mb-0">:</p>
              </b-col>
              <b-col>
                <div>
                  <b-form-textarea v-model="action.pembatalan.alasan_batal" @input="textVal" id="textarea" placeholder="Ketik Alasan..." rows="1" max-rows="3"></b-form-textarea>
                </div>
              </b-col>
            </b-row>
          </div>
          <!-- <pre>{{ action.pembatalan }}</pre> -->
        </b-modal>
        <b-modal ref="modal-cetak-ulang" size="lg" title="Cetak Bukti Reservasi" ok-title="Cetak" cancel-title="Tutup" @ok="print" :no-close-on-backdrop="true" :static="true" centered>
          <div class="px-lg-2 px-1 py-2">
            <b-row class="mb-1">
              <b-col cols="12" lg="4">
                <p class="text-dark mb-0">Nama</p>
              </b-col>
              <b-col cols="12" lg="1">
                <p class="text-dark mb-0">:</p>
              </b-col>
              <b-col cols="12" lg="7">
                <p class="text-dark font-weight-bold mb-0">{{ action.cetakUlang2.nama }}</p>
              </b-col>
            </b-row>
            <b-row class="mb-1">
              <b-col cols="12" lg="4">
                <p class="text-dark mb-0">No. Rekam Medis</p>
              </b-col>
              <b-col cols="12" lg="1">
                <p class="text-dark mb-0">:</p>
              </b-col>
              <b-col cols="12" lg="7">
                <p class="text-dark font-weight-bold mb-0">{{ action.cetakUlang2.norm }}</p>
              </b-col>
            </b-row>
            <b-row class="mb-1">
              <b-col cols="12" lg="4">
                <p class="text-dark mb-0">No. Reservasi</p>
              </b-col>
              <b-col cols="12" lg="1">
                <p class="text-dark mb-0">:</p>
              </b-col>
              <b-col cols="12" lg="7">
                <p class="text-dark font-weight-bold mb-0">{{ action.cetakUlang2.nomor_reservasi }}</p>
              </b-col>
            </b-row>
            <b-row class="mb-1">
              <b-col cols="12" lg="4">
                <p class="text-dark mb-0">No. Pendaftaran</p>
              </b-col>
              <b-col cols="12" lg="1">
                <p class="text-dark mb-0">:</p>
              </b-col>
              <b-col cols="12" lg="7">
                <p class="text-dark font-weight-bold mb-0" v-if="preview.status === 2">{{ action.cetakUlang2.nopen }}</p>
                <p class="text-dark font-weight-bold mb-0" v-else>-</p>
              </b-col>
            </b-row>
            <b-row class="mb-1">
              <b-col cols="12" lg="4">
                <p class="text-dark mb-0">Poli</p>
              </b-col>
              <b-col cols="12" lg="1">
                <p class="text-dark mb-0">:</p>
              </b-col>
              <b-col cols="12" lg="7">
                <p class="text-dark font-weight-bold mb-0">{{ action.cetakUlang2.ruangan }}</p>
              </b-col>
            </b-row>
            <b-row class="mb-1">
              <b-col cols="12" lg="4">
                <p class="text-dark mb-0">Dokter</p>
              </b-col>
              <b-col cols="12" lg="1">
                <p class="text-dark mb-0">:</p>
              </b-col>
              <b-col cols="12" lg="7">
                <p class="text-dark font-weight-bold mb-0">{{ action.cetakUlang2.dokter }}</p>
              </b-col>
            </b-row>
            <b-row class="mb-1">
              <b-col cols="12" lg="4">
                <p class="text-dark mb-0">Nomor Antrian</p>
              </b-col>
              <b-col cols="12" lg="1">
                <p class="text-dark mb-0">:</p>
              </b-col>
              <b-col cols="12" lg="7">
                <p class="text-dark font-weight-bold mb-0" v-if="action.cetakUlang2.nomor_antrian">{{ action.cetakUlang2.nomor_antrian }}</p>
                <p class="text-dark font-weight-bold mb-0" v-else>-</p>
              </b-col>
            </b-row>
            <b-row class="mb-1">
              <b-col cols="12" lg="4">
                <p class="text-dark mb-0">Tanggal Reservasi</p>
              </b-col>
              <b-col cols="12" lg="1">
                <p class="text-dark mb-0">:</p>
              </b-col>
              <b-col cols="12" lg="7">
                <p class="text-dark font-weight-bold mb-0">{{ new Date(action.cetakUlang2.tanggal_reservasi) | formatDate }}</p>
              </b-col>
            </b-row>
            <b-row class="mb-1">
              <b-col cols="12" lg="4">
                <p class="text-dark mb-0">Estimasi</p>
              </b-col>
              <b-col cols="12" lg="1">
                <p class="text-dark mb-0">:</p>
              </b-col>
              <b-col cols="12" lg="7">
                <p class="text-dark font-weight-bold mb-0">{{ action.cetakUlang2.estimasi }}</p>
              </b-col>
            </b-row>
            <b-row class="mb-1">
              <b-col cols="12" lg="4">
                <p class="text-dark mb-0">check In</p>
              </b-col>
              <b-col cols="12" lg="1">
                <p class="text-dark mb-0">:</p>
              </b-col>
              <b-col cols="12" lg="7">
                <p class="text-dark font-weight-bold mb-0" v-if="preview.status === 2">{{ action.cetakUlang2.check_in }}</p>
                <p class="text-dark font-weight-bold mb-0" v-else>-</p>
              </b-col>
            </b-row>
          </div>
        </b-modal>
        <b-modal ref="modal-sukses" size="md" title="Informasi" ok-title="Tutup" :ok-only="true" @ok="tableRefresh" :no-close-on-backdrop="true" :static="true" :hide-header="true" centered>
          <div class="text-center px-lg-4 px-2">
            <lottie :options="animate.defaultOptions" :autoPlay="true" :width="250" :height="250" />
            <p class="h6 font-weight-bold text-success mb-0">{{ message.success }}</p>
          </div>
        </b-modal>
        <b-modal ref="modal-gagal" size="md" title="Informasi" ok-title="Tutup" :ok-only="true" @ok="tableRefresh" :no-close-on-backdrop="true" :static="true" :hide-header="true" centered>
          <div class="text-center px-lg-4 px-2">
            <lottie :options="animate.defaultOptions2" :autoPlay="true" :width="250" :height="250" />
            <p class="h6 font-weight-bold text-danger mb-0">{{ message.error }}</p>
          </div>
        </b-modal>
      </section>
      <section id="print-area">
        <div id="printCoba" style="display: none;">
          <div class="page" style="border: none; width: 110mm; height: 20mm; margin: 0px;">
            <div class="page-area" style="font-family: Arial, Helvetica, sans-serif; border: none;">
              <table class="header font-arial" style="width: 100%; padding-bottom: 10px; border-bottom: 1px solid black;">
                <tbody>
                  <tr>
                    <!-- <td><img src="<?= base_url('/assets/img/logo2.png') ?>" alt="logo-fatmawati"></td> -->
                    <td style="width: 50%;">
                      <strong>
                        <span style="color: black; font-size: 1.3rem;">RSUP FATMAWATI</span><br>
                        <span style="font-size: 1rem; color: black;" v-if="action.cetakUlang2.tanggal_reservasi">Tanggal : {{ new Date(action.cetakUlang2.tanggal_reservasi) | formatDate }}</span><br>
                        <!-- <span style="font-size: 9pt; color: gray; font-weight: lighter;">TANGGAL CETAK : 22-12-2022</span> -->
                      </strong>
                    </td>
                    <td style="width: 50%; padding-left: 100px;">
                      <table>
                        <tbody>
                          <tr>
                            <td style="text-align: center; padding: 0; border-spacing: 0; margin: 0;">
                              <span style="color: rgb(53, 53, 53); font-size: 2rem; font-weight: bold;">{{ action.cetakUlang2.nomor_antrian }}</span>
                            </td>
                          </tr>
                          <tr>
                            <td style="text-align: center; padding: 0; border-spacing: 0; margin: 0;">
                              <span style="font-size: 1rem; color: rgb(53, 53, 53); font-weight: bold;">NO.ANTRIAN</span>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </td>
                  </tr>
                </tbody>
              </table>
              <table class="padding-md font-arial text-general" style="padding-top: 10px; padding-bottom: 20px; border-top: none; font-size: 3mm; width: 100%;">
                <tbody style="padding-top: 10px">
                  <tr>
                    <td width="20%"><span style="font-size: 1.1rem;">Nama</span></td>
                    <td width="1%"><span style="font-size: 1.1rem;">:</span></td>
                    <td width="29%">
                      <span style="font-size: 1.1rem; font-weight: bold; color: black;">{{ action.cetakUlang2.nama }}</span>
                    </td>
                  </tr>
                  <tr>
                    <td width="20%"><span style="font-size: 1.1rem;">No. Rekam Medis</span></td>
                    <td width="1%"><span style="font-size: 1.1rem;">:</span></td>
                    <td width="29%">
                      <span style="font-size: 1.1rem; font-weight: bold; color: black;">{{ action.cetakUlang2.norm }}</span>
                    </td>
                  </tr>
                  <tr>
                    <td width="20%"><span style="font-size: 1.1rem;">No. Reservasi</span></td>
                    <td width="1%"><span style="font-size: 1.1rem;">:</span></td>
                    <td width="29%">
                      <span style="font-size: 1.1rem; font-weight: bold; color: black;">{{ action.cetakUlang2.nomor_reservasi }}</span>
                    </td>
                  </tr>
                  <tr>
                    <td width="20%"><span style="font-size: 1.1rem;">Poli</span></td>
                    <td width="1%"><span style="font-size: 1.1rem;">:</span></td>
                    <td width="29%">
                      <span style="font-size: 1.1rem; font-weight: bold; color: black;">{{ action.cetakUlang2.ruangan }}</span>
                    </td>
                  </tr>
                  <tr>
                    <td width="20%"><span style="font-size: 1.1rem;">Dokter</span></td>
                    <td width="1%"><span style="font-size: 1.1rem;">:</span></td>
                    <td width="29%">
                      <span style="font-size: 1.1rem; font-weight: bold; color: black;">{{ action.cetakUlang2.dokter }}</span>
                    </td>
                  </tr>
                  <tr>
                    <td width="20%"><span style="font-size: 1.1rem;">Estimasi</span></td>
                    <td width="1%"><span style="font-size: 1.1rem;">:</span></td>
                    <td width="29%">
                      <span style="font-size: 1.1rem; font-weight: bold; color: black;">{{ action.cetakUlang2.estimasi }}</span>
                    </td>
                  </tr>
                  <tr>
                    <td width="20%"><span style="font-size: 1.1rem;">Check-In</span></td>
                    <td width="1%"><span style="font-size: 1.1rem;">:</span></td>
                    <td width="29%">
                      <span style="font-size: 1.1rem; font-weight: bold; color: black;" v-if="preview.status === 2">{{ action.cetakUlang2.check_in }}</span>
                      <span style="font-size: 1.1rem; font-weight: bold; color: black;" v-else>-</span>
                    </td>
                  </tr>
                  <!-- <tr>
                    <td width="20%"><span style="font-size: 1.1rem;">Nomor Antrian</span></td>
                    <td width="1%"><span style="font-size: 1.1rem;">:</span></td>
                    <td width="29%">
                      <span style="font-size: 1.1rem; font-weight: bold; color: black;">{{ action.cetakUlang2.nomor_antrian }}</span>
                    </td>
                  </tr>
                  <tr>
                    <td width="20%"><span style="font-size: 1.1rem;">Tanggal Reservasi</span></td>
                    <td width="1%"><span style="font-size: 1.1rem;">:</span></td>
                    <td width="29%">
                      <span style="font-size: 1.1rem; font-weight: bold; color: black;" v-if="action.cetakUlang2.tanggal_reservasi">{{ new Date(action.cetakUlang2.tanggal_reservasi) | formatDate }}</span>
                    </td>
                  </tr> -->
                </tbody>
              </table>
              <!-- <table class="font-arial" style="padding-top: 40px padding-bottom: 20px; width: 100%;">
                <tbody>
                  <tr>
                    <td style="width: 50%; font-size: 1.1rem; font-weight: bold; padding-bottom: 20px;">
                      <span style="border: 3px solid black; padding: 5px;">NOMOR ANTRIAN : {{ action.cetakUlang.nomor_antrian }}</span>
                    </td>
                  </tr>
                </tbody>
              </table> -->
              <table class="font-arial" style=" width: 100%;">
                <tbody>
                  <tr>
                    <td style="width: 50%; font-size: 1.1rem; padding-top: 10px;">
                      <!-- <span style="color: gray; font-size: 1.1rem; font-weight: bold;">Informasi Selengkapnya : </span><br> -->
                      <span style="font-size: 1rem; color: gray; font-weight: lighter; font-style: italic;">Nomor Registrasi :
                        <span style="font-weight: bold;" v-if="preview.status === 2">{{ action.cetakUlang2.nopen }}</span>
                        <span style="font-weight: bold;" v-else>-</span>
                      </span><br>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </section>
    </b-container>
  </div>
</template>
<script>
import { xray } from '../../../config/pluginInit'
import newservices from '../../../services/fatmahost/reservasi/index.ts'
import Lottie from 'vue-lottie'
import animationLogo from '../../../assets/lottie/checked.json'
import animationLogo2 from '../../../assets/lottie/failed.json'
import logo from '../../../assets/images/logo-rs-black.png'
import flatPickr from 'vue-flatpickr-component'
import { Indonesian as IndonesianLocale } from 'flatpickr/dist/l10n/id.js'
import Util from '../../../Utils/converter/index.js'
import 'flatpickr/dist/flatpickr.css'

export default {
  name: 'MeniList',
  components: {
    Lottie,
    flatPickr
  },
  data () {
    return {
      table: {
        isBusy: false,
        items: this.listPasien,
        fields: [
          { label: 'NO', key: 'index', class: 'text-center' },
          { label: 'NO.RESERVASI', key: 'nomor_reservasi' },
          { label: 'NORM', key: 'norm' },
          { label: 'NAMA', key: 'nama' },
          // { label: 'NO.HP', key: 'nomor_kontak' },
          // { label: 'JENIS KELAMIN', key: 'deskripsi_jenis_kelamin' },
          { label: 'RUANGAN', key: 'nama_ruangan' },
          // { label: 'DOKTER', key: 'deskripsi_dokter' },
          { label: 'TANGGAL RESERVASI', key: 'tanggal_reservasi' },
          // { label: 'JAM', key: 'shift' },
          // { label: 'JNS.RESERVASI', key: 'jenis_reservasi' },
          { label: 'STATUS', key: 'status' },
          { label: 'AKSI', key: 'action', class: 'text-center' }
        ],
        filter: null,
        filterOn: [],
        currentPage: null,
        perPage: 10,
        pageOptions: [10, 50, 100, 500],
        totalRows: 1
      },
      filter: {
        param: '1',
        tglAwal: ''
        // tglAkhir: ''
      },
      configTgl: {
        locale: IndonesianLocale,
        minDate: 'today',
        disableMobile: true,
        dateFormat: 'd-m-Y'
      },
      selected: {
        filterType: '1'
      },
      combo: {
        filterType: [
          { value: '1', text: 'No. Reservasi' },
          { value: '2', text: 'Nama Pasien' },
          // { value: '3', text: 'Nama Dokter' },
          { value: '4', text: 'No. Rekam Medis' },
          { value: '5', text: 'Nama Poli' }
        ]
      },
      action: {
        daftar: {
          object: 'meningitis',
          nomor_reservasi: '',
          paspor: '',
          updated_in: xray.currentUser().id.toString()
        },
        pembatalan: {
          object: 'poli',
          jenis_reservasi: '',
          nomor_reservasi: '',
          id_ruangan: '',
          tanggal_reservasi: '',
          alasan_batal: '',
          updated_in: xray.currentUser().id.toString()
        },
        cetakUlang: {
          nama: '',
          norm: '',
          nopen: '',
          ruangan: '',
          carabayar: '',
          nomor_antrian: ''
        },
        cetakUlang2: {},
        okDisable: true
      },
      message: {
        success: '',
        error: ''
      },
      preview: {
        pembatalan: {
          nomor: '',
          nama: '',
          ruangan: ''
        },
        tglAwal: '',
        tglAkhir: '',
        id: '',
        status: ''
      },
      animate: {
        defaultOptions: { animationData: animationLogo, loop: true },
        defaultOptions2: { animationData: animationLogo2, loop: true }
      },
      dateBoard: '',
      imageLogo: logo
    }
  },
  mounted () {
    xray.index()
    this.getDate()
  },
  methods: {
    tableRefresh () {
      this.$refs.table.refresh()
    },
    refresh () {
      location.reload()
    },
    async listPasien (ctx) {
      if (ctx.filter === '') {
        ctx.filter = null
      }
      let filter = ''
      let param = this.filter.param
      let tglAwal = this.filter.tglAwal ? this.filter.tglAwal : ''
      let tglAkhir = this.filter.tglAkhir ? this.filter.tglAkhir : ''
      if (tglAwal === 'NaN-NaN-NaN') {
        tglAwal = ''
      }
      if (tglAkhir === 'NaN-NaN-NaN') {
        tglAkhir = ''
      }
      if (ctx.filter === null) {
        filter = `{"xparam0":"","xparam1":"","xparam2":"","xparam3":"${tglAwal}","xparam4":"${tglAkhir}","xparam5":"","xparam6":"","xparam7":""}`
      } else {
        if (param === '1') {
          filter = `{"xparam0":"${ctx.filter}","xparam1":"","xparam2":"","xparam3":"${tglAwal}","xparam4":"${tglAkhir}","xparam5":"","xparam6":"","xparam7":""}`
        } else if (param === '2') {
          filter = `{"xparam0":"","xparam1":"${ctx.filter}","xparam2":"","xparam3":"${tglAwal}","xparam4":"${tglAkhir}","xparam5":"","xparam6":"","xparam7":""}`
        } else if (param === '3') {
          filter = `{"xparam0":"","xparam1":"","xparam2":"${ctx.filter}","xparam3":"${tglAwal}","xparam4":"${tglAkhir}","xparam5":"","xparam6":"","xparam7":""}`
        } else if (param === '4') {
          filter = `{"xparam0":"","xparam1":"","xparam2":"","xparam3":"${tglAwal}","xparam4":"${tglAkhir}","xparam5":"${ctx.filter}","xparam6":"","xparam7":""}`
        } else if (param === '5') {
          filter = `{"xparam0":"","xparam1":"","xparam2":"","xparam3":"${tglAwal}","xparam4":"${tglAkhir}","xparam5":"","xparam6":"${ctx.filter}","xparam7":""}`
        }
      }
      this.table.isBusy = true
      try {
        const response = await newservices.findAllMeningitis(ctx.currentPage - 1, ctx.perPage, filter)
        if (response.data.statcode === 20001) {
          this.table.isBusy = false
          return []
        }
        this.table.isBusy = false
        this.table.totalRows = response.data.pagination.total
        this.table.currentPage = response.data.pagination.current
        this.table.perPage = response.data.pagination.perPage
        return response.data.data
      } catch (error) {
        this.table.isBusy = false
        this.message.error = error.response.data.message
        return []
      }
    },
    onFiltered (filteredItems) {
      this.table.totalRows = filteredItems.length
      // this.table.currentPage = 1
    },
    filterAdvancedModal () {
      this.$refs['modal-filter'].show()
    },
    valType (param) {
      this.filter.param = param
    },
    resetTable () {
      this.filter.param = '1'
      this.selected.filterType = '1'
      this.filter.tglAwal = ''
      this.filter.tglAkhir = ''
      this.preview.tglAwal = ''
      this.preview.tglAkhir = ''
      this.table.filter = ''
      this.$refs.table.refresh()
    },
    filterTglAwal (val) {
      let date = new Date(val)
      let year = date.getFullYear()
      let month = date.getMonth() + 1
      let day = date.getDate()
      if (month < 10) {
        month = '0' + month
      }
      if (day < 10) {
        day = '0' + day
      }
      let joinDate = year + '-' + month + '-' + day
      this.filter.tglAwal = joinDate
      this.filter.tglAkhir = joinDate
      this.table.filter = ''
      this.$refs.table.refresh()
    },
    filterTglAkhir (val) {
      let date = new Date(val)
      let year = date.getFullYear()
      let month = date.getMonth() + 1
      let day = date.getDate()
      if (month < 10) {
        month = '0' + month
      }
      if (day < 10) {
        day = '0' + day
      }
      let joinDate = year + '-' + month + '-' + day
      this.filter.tglAkhir = joinDate
      this.table.filter = ''
      this.$refs.table.refresh()
    },
    batalModal (id, jenis, ruangan, tgl, norsrv, nama, nmruang) {
      this.preview.id = id.toString()
      this.preview.pembatalan.nama = nama
      this.preview.pembatalan.ruangan = nmruang
      this.action.pembatalan.jenis_reservasi = jenis.toString()
      this.action.pembatalan.id_ruangan = ruangan
      this.action.pembatalan.tanggal_reservasi = Util.convertToDMY(tgl).split('-').reverse().join('-')
      this.action.pembatalan.nomor_reservasi = norsrv
      this.$refs['modal-pembatalan'].show()
    },
    daftarModal (nomor) {
      this.action.daftar.nomor_reservasi = nomor
      this.$refs['modal-daftar'].show()
    },
    async daftarMeni () {
      const data = this.action.daftar
      try {
        const response = await newservices.daftarmeningitis(data)
        if (response.data.statcode === 0) {
          this.message.success = response.data.message
          this.$refs['modal-sukses'].show()
        } else {
          this.message.error = response.data.message
          this.$refs['modal-gagal'].show()
        }
      } catch (error) {
        this.message.error = error.message
        this.$refs['modal-gagal'].show()
      }
    },
    textVal () {
      if (this.action.pembatalan.alasan_batal !== '') {
        this.action.okDisable = false
      } else {
        this.action.okDisable = true
      }
    },
    async onBatal () {
      const id = this.preview.id
      const data = this.action.pembatalan
      await newservices.batal(id, data).then(response => {
        if (response.data[0].statcode === 0) {
          this.message.success = response.data[0].message
          this.$refs['modal-sukses'].show()
        } else {
          this.message.error = response.data[0].message
          this.$refs['modal-gagal'].show()
        }
        this.clearData()
      }).catch(error => {
        this.message.error = error.message
        this.$refs['modal-gagal'].show()
        this.clearData()
      })
    },
    clearData () {
      this.action.pembatalan.jenis_reservasi = ''
      this.action.pembatalan.id_dokter = ''
      this.action.pembatalan.id_ruangan = ''
      this.action.pembatalan.tanggal_reservasi = ''
      this.action.pembatalan.alasan_batal = ''
      this.action.pembatalan.shift = ''
      this.preview.pembatalan.nomor = ''
      this.preview.pembatalan.nama = ''
      this.preview.pembatalan.ruangan = ''
    },
    getDate () {
      let date = new Date()
      let y = date.getFullYear()
      let m = date.getMonth() + 1
      let d = date.getDate()
      let joinDate = d + '-' + m + '-' + y
      this.dateBoard = joinDate
    },
    isAlphabetKey (evt) {
      evt = (evt) || window.event
      var charCode = (evt.which) ? evt.which : evt.keyCode
      if ((charCode < 65 || charCode > 90) && (charCode < 97 || charCode > 123) && charCode !== 32) {
        evt.preventDefault()
      } else {
        return true
      }
    },
    print () {
      const prtHtml = document.getElementById('printCoba')
      const Winprint = window.open('', '', 'height=1000,width=1000')
      Winprint.document.write(`<!DOCTYPE html>
      <html lang="en">
      <head>
      </head>
      <body>
      ${prtHtml.innerHTML}
      </body>
      </html>`)
      Winprint.document.close()
      Winprint.focus()
      Winprint.print()
      Winprint.close()
      // setTimeout(() => {
      //   this.autofocus()
      // }, 1000)
    }
  }
}
</script>
<style>
.meningitis-admin .custom-flatpicker {
  height: 38px;
  border: 1px solid rgba(0, 0, 0, 0.13);
  border-radius: 5px;
  padding-left: 10px;
}
.meningitis-admin .custom-select {
  border: 1px solid rgba(0, 0, 0, 0.13);
  min-height: 38px;
  font-size: 13px!important;
}
.meningitis-admin .bg-filter {
  border: 1px solid rgba(0, 0, 0, 0.13);
  background-color: #089bab1e;
}
.meningitis-admin .custom-search-kuota {
  border-radius: 5px!important;
  font-size: 13px!important;
  height: 38px!important;
}
.meningitis-admin .table td {
    font-size: 12px;
    font-weight: 500;
    padding-top: 0px;
    padding-bottom: 0px;
}
.meningitis-admin .table th {
    font-size: 13px;
    font-weight: 400;
}
.meningitis-admin .page-link {
  background: transparent!important;
    border: none!important;
    color: gray!important;
    margin-right: 15px;
}
.meningitis-admin .page-item.active .page-link {
    background-color: transparent;
    border-color: transparent;
    color: #089bab!important;
    font-weight: bold;
}
.meningitis-admin .cursor-point {
    cursor: pointer;
}
.cstm-slct-irj-Admin {
  font-size: 13px!important;
  height: 38px!important;
  border-radius: 5px;
}
.cstm-cntrl-irj-Admin {
  color: black!important;
  border-radius: 5px!important;
  font-size: 12px!important;
  height: 4px!important;
}
.custom-fontsize {
  font-size: 13px;
}
</style>
