export default {
  SET_TOKEN (state, data) {
    state.token = data
    localStorage.setItem('token', data)
  },
  SET_USER (state, data) {
    state.user = data
  },
  SET_MODULE (state, data) {
    state.module = data
  },
  // SET_UTILITY (state, data) {
  //   state.utility = data
  // },
  SET_MODULE_LOCAL (state, data) {
    state.module_local = data
    localStorage.setItem('module', data)
  }
  // SET_UTILITY_LOCAL (state, data) {
  //   state.utility_local = data
  //   localStorage.setItem('utility', data)
  // }
}
