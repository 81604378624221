import Api from '../axiosInstance'
const version = '/v1'
export default {
  dash_rajal (param1, param2) {
    return Api.dashboard.get(`${version}/dashboard/practitioner/dashboardDirut/rajal`, { params: { param1: param1, param2: param2 } })
  },
  dash_ranap (param1, param2) {
    return Api.dashboard.get(`${version}/dashboard/practitioner/dashboardDirut/ranap`, { params: { param1: param1, param2: param2 } })
  },
  dash_sdm_strsip () {
    return Api.dashboard.get(`${version}/dashboard/practitioner/dashboardStrSip/rekap`, { params: { param0: 'str-rekap' } })
  },
  dash_sdm_detail_strsip (param1, param2) {
    return Api.dashboard.get(`${version}/dashboard/practitioner/dashboardStrSip/findAll`, { params: { param0: 'str-sip', param1: param1, param2: param2 } })
  },
  dash_home () {
    return Api.dashboard.get(`${version}/dashboard/practitioner/dashboardHome`)
  }
}
