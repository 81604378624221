<template>
  <b-container fluid>
    <b-row class="justify-content-center">
      <b-col lg="5">
        <iq-card body-class="iq-card-body pl-0 pr-0 pt-0">
          <template v-slot:body>
            <div class="docter-details-block">
              <div class="doc-profile-bg bg-primary" style="height:50px;">
              </div>
              <b-row class="align-items-center justify-content-center p-2">
                <b-col cols="3">
                  <div class="docter-profile">
                    <b-img :src="pegawai.JENIS_KELAMIN === 1 ? imgProfile.pria : imgProfile.wanita" alt="profile-img" width="120"
                      class="img-fluid" />
                  </div>
                </b-col>
              </b-row>
              <hr>
              <div class="px-4 py-2">
                <div class="about-info m-0 p-0">
                  <div class="row">
                    <div class="col-2 col-lg-3 text-dark">Nama</div>
                    <div class="col-1 col-lg-1 text-dark">:</div>
                    <div class="col-7 col-lg-8 text-dark font-weight-bold">{{ pegawai.NAMA }}</div>
                  </div>
                  <div class="row">
                    <div class="col-2 col-lg-3 text-dark">NIP</div>
                    <div class="col-1 col-lg-1 text-dark">:</div>
                    <div class="col-7 col-lg-8 text-dark font-weight-bold">{{ pegawai.NIP }}</div>
                  </div>
                  <div class="row">
                    <div class="col-2 col-lg-3 text-dark">No. Identitas</div>
                    <div class="col-1 col-lg-1 text-dark">:</div>
                    <div class="col-7 col-lg-8 text-dark font-weight-bold">{{ pegawai.NOMOR }}</div>
                  </div>
                  <div class="row">
                    <div class="col-2 col-lg-3 text-dark">Jenis Kelamin</div>
                    <div class="col-1 col-lg-1 text-dark">:</div>
                    <div class="col-7 col-lg-8 text-dark font-weight-bold">{{ pegawai.NAMA_JENKEL }}</div>
                  </div>
                  <div class="row">
                    <div class="col-2 col-lg-3 text-dark">Agama</div>
                    <div class="col-1 col-lg-1 text-dark">:</div>
                    <div class="col-7 col-lg-8 text-dark font-weight-bold">{{ pegawai.NAMA_AGAMA }}</div>
                  </div>
                  <div class="row">
                    <div class="col-2 col-lg-3 text-dark">Umur</div>
                    <div class="col-1 col-lg-1 text-dark">:</div>
                    <div class="col-7 col-lg-8 text-dark font-weight-bold">{{ getAge(pegawai.TANGGAL_LAHIR) }} Tahun</div>
                  </div>
                  <div class="row">
                    <div class="col-2 col-lg-3 text-dark">Profesi</div>
                    <div class="col-1 col-lg-1 text-dark">:</div>
                    <div class="col-7 col-lg-8 text-dark font-weight-bold">{{ pegawai.NAMA_PROFESI }}</div>
                  </div>
                </div>
              </div>
            </div>
          </template>
        </iq-card>
      </b-col>
    </b-row>
    <b-row class="justify-content-center mb-4">
      <b-col lg="2">
        <button type="button" @click="listpegawai" class="btn btn-primary btn-block"><i
                        class="las la-arrow-left"></i>Kembali</button>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { xray } from '../../../config/pluginInit'
import Service from '../../../services/fatmahost/index'
export default {
  name: 'view',
  components: {
  },
  mounted () {
    xray.index()
    this.id = this.$route.params.id
    this.viewPegawai(this.id)
  },
  data () {
    return {
      // defaultOptions: { animationData: animationLogo, loop: true },
      id: null,
      error: [],
      imgProfile: {
        pria: require('../../../assets/images/user/14.jpg'),
        wanita: require('../../../assets/images/user/15.jpg')
      },
      doctor: {
        profile: require('../../../assets/images/user/1.jpg')
      },
      pegawai: []
    }
  },
  methods: {
    async viewPegawai (id) {
      await Service.pegawai.findId(id).then(response => {
        if (response.status === 200) {
          this.pegawai = response.data.data[0]
        }
      }).catch(error => {
        this.error = error.response || error.response.status
      })
    },
    getAge (dateString) {
      var today = new Date()
      var birthDate = new Date(dateString)
      var age = today.getFullYear() - birthDate.getFullYear()
      var m = today.getMonth() - birthDate.getMonth()
      if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
        age--
      }
      return age
    },
    editPegawai () {
      this.$router.push({ path: '/pegawai/edit/' + this.id })
    },
    listpegawai () {
      this.$router.push({ name: 'pegawai.list' })
    }
  }
}
</script>
