import Api from '../axiosInstance'
const version = '/v1'
const newVersion = '/v2'
export default {
  findAll (currentPage, perPage, filter) {
    return Api.pegawai.get(`${version}/service/pasien?param0=pasien&param1=${currentPage}&param2=${perPage}&param3=${filter}`)
  },
  // findId (id) {
  //   return Api.pegawai.get(`${version}/service/pasien/${id}?param0=pasien`)
  // },
  create (data) {
    return Api.pegawai.post(`${version}/service/pasien`, data)
  },
  update (id, data) {
    return Api.pegawai.put(`${version}/service/pasien/${id}`, data)
  },
  delete (id) {
    return Api.pegawai.delete(`${version}/service/pasien/${id}?param0=pasien`)
  },
  //
  findAllNew (currentPage, perPage, norm, nama, tglLahir) {
    return Api.pasien.get(`${newVersion}/service/pasien?param0=pasien&param1=${currentPage}&param2=${perPage}&param3={"xparam0":"${norm}","xparam1":"${nama}","xparam2":"${tglLahir}"}&param4={"xparam0":"","xparam1":""}`)
  },
  show (norm) {
    return Api.pasien.get(`${newVersion}/service/pasien/${norm}?param0=pasien`)
  },
  //
  listPendaftaranByRm (rm, currentPage, perPage) {
    return Api.pasien.get(`${newVersion}/pendaftaran/${rm}?param0=list-pendaftaran-by-rm&param1=${currentPage}&param2=${perPage}&param3={"xparam0":"","xparam1":"","xparam2":"","xparam3":"","xparam4":""}&param4={"xparam0":"","xparam1":"DESC"}`)
  },
  listKunjunganByReg (reg, currentPage, perPage) {
    return Api.pasien.get(`${newVersion}/kunjungan/${reg}?param0=list-kunjungan-by-reg&param1=${currentPage}&param2=${perPage}&param3={"xparam0":"","xparam1":"","xparam2":"","xparam3":"","xparam4":""}&param4={"xparam0":"","xparam1":"DESC"}`)
  },
  findId (id) {
    return Api.pasien.get(`${newVersion}/pendaftaran/${id}`)
  }
}
