<template>
  <div class="iq-sidebar">
    <div class="iq-sidebar-logo d-flex justify-content-between">
      <router-link :to="homeURL">
        <!-- <img :src="logo" class="img-fluid" alt="logo"> -->
        <span class="mr-0 font-weight-bold">SIM</span>
        <span class=" ml-0 font-weight-bold">RSF</span>
        <!-- <span class=" ml-3 font-weight-bold"><i class="fas fa-power-off text-danger"></i></span> -->
      </router-link>
      <div class="iq-menu-bt-sidebar" v-if="toggleButton">
        <div class="iq-menu-bt align-self-center">
          <div class="wrapper-menu" @click="miniSidebar">
            <div class="main-circle"><i class="ri-more-fill"></i></div>
            <div class="hover-circle"><i class="ri-more-2-fill"></i></div>
          </div>
        </div>
      </div>
    </div>
    <!-- <div class="mt-3 py-1 my-cursor iq-sidebar-logo bg-danger shadow-sm d-flex align-items-center justify-content-center margin-remove" v-if="this.updateApp === true || this.updateApp === 'true'">
      <a @click="refresh">
        <img :src="logoUpdate" class="img-fluid" width="25rem" alt="logo">
        <span class="txt-update-size mb-0 pb-0 ml-0 font-weight-normal ml-2">UPDATE</span>
      </a>
    </div> -->
    <div id="sidebar-scrollbar">
      <nav class="iq-sidebar-menu" :class="horizontal ? 'd-xl-none' : ''">
        <List :items="items" :open="true" :horizontal="horizontal"/>
      </nav>
      <div class="p-3"></div>
    </div>
  </div>
  <!-- TOP Nav Bar -->
</template>

<script>
import { xray } from '../../../config/pluginInit'
import List from '../menus/ListStyle1'

export default {
  name: 'SideBarStyle1',
  props: {
    homeURL: { type: Object, default: () => ({ name: xray.rootHome() }) },
    items: { type: Array },
    logo: { type: String, default: require('../../../assets/images/logo.png') },
    logoUpdate: { type: String, default: require('../../../assets/images/update-logo.svg') },
    horizontal: { type: Boolean },
    toggleButton: { type: Boolean, default: true }
  },
  components: {
    List
  },
  mounted () {
    this.updateApp = localStorage.getItem('update') === 'true' ? localStorage.getItem('update') : false

    const element = document.querySelectorAll('.iq-sidebar-menu li a')
    Array.from(element, (elem) => {
      elem.addEventListener('click', (e) => {
        const prevMenu = window.$('.iq-sidebar-menu li').has('.iq-submenu').find('ul.show').attr('id')
        const prevMenuElem = document.getElementById(prevMenu)
        if (prevMenuElem !== null) {
          Array.from(prevMenuElem.closest('li').getElementsByClassName('not-collapsed'), (el) => {
            if (elem.closest('ul').getAttribute('id') !== prevMenu) {
              this.$root.$emit('bv::toggle::collapse', prevMenu)
              prevMenuElem.closest('li').classList.remove('active')
            }
            if (elem.getAttribute('aria-controls') === prevMenu) {
              this.$root.$emit('bv::toggle::collapse', prevMenu)
            }
          })
        }
        window.$(elem).closest('li').has('.iq-submenu').addClass('active')
      })
    })
  },
  methods: {
    refresh () {
      this.$forceUpdate()
      window.location.reload(true)
      localStorage.removeItem('update')
    },
    miniSidebar () {
      this.$emit('toggle')
    }
  },
  data () {
    return {
      updateApp: null,
      error: ''
    }
  }
}
</script>
<style>
  .mouse-cursor-cstm {
    cursor: pointer;
  }
  .txt-update-size {
    font-size: 16px!important;
  }
  .margin-remove {
    padding: 0px!important;
  }
  .my-cursor {
    cursor: pointer;
  }
</style>
