import Api from '../axiosInstance'
const version = '/v1'
export default {
  findAll (currentPage, perPage, filter) {
    return Api.master.get(`${version}/master/practitioner/msetting?param0=ruangan&param1=${currentPage}&param2=${perPage}&${filter}`)
  },
  findId (id) {
    return Api.master.get(`${version}/master/practitioner/msetting/${id}?param0=ruangan`)
  },
  create (data) {
    return Api.master.post(`${version}/master/practitioner/msetting`, data)
  },
  update (id, data) {
    return Api.master.put(`${version}/master/practitioner/msetting/${id}`, data)
  },
  delete (id) {
    return Api.master.delete(`${version}/master/practitioner/msetting/${id}?param0=ruangan`)
  }
}
