
<template>
    <div  id="sihepi">
    <b-container fluid>
    <div v-show="isLoading">
        <div id="show-overlay"></div>
      <Loader :style="{ opacity: .5 }" />
    </div>
      <form>
        <b-row>
          <b-col lg="12">
            <iq-card body-class="p-0">
              <template v-slot:body>
                <div class="iq-edit-list">
                  <tab-nav :pills="true" class="iq-edit-profile d-flex">
                    <tab-nav-items class="col-md-4 p-0" :active="true" href="#hasil-test" title="Hasil Test" />
                    <tab-nav-items class="col-md-4 p-0" :active="false" href="#dashboard" title="Dashboarad" />
                    <tab-nav-items class="col-md-4 p-0" :active="false" href="#laporan" title="Laporan" />
                  </tab-nav>
                </div>
              </template>
            </iq-card>
          </b-col>
          <b-col lg="12">
            <div class="iq-edit-list-data">
              <tab-content id="pills-tabContent-2">
                <tab-content-item :active="true" id="hasil-test" >
                    <div class="table-title bg-white px-3 py-2 d-flex flex-row align-items-center rounded">
                        <div class="d-flex flex-row align-items-center mr-auto">
                            <h5 class="card-title text-dark font-weight-bold m-0 p-0">SISTEM INFORMASI SIHEPI</h5>
                            <div class="ml-3">
                                <b-button variant="warning" class="rounded-1 mr-2" size="sm" @click="transfer()">
                                    <i class="fas fa-exchange-alt"></i> Transfer Data
                                </b-button>
                                <b-button variant="danger" class="rounded-1" size="sm" @click="kirim()">
                                    <i class="fas fa-send"></i> Kirim
                                </b-button>
                            </div>
                        </div>
                        <div class="mr-2">
                          <flat-pickr :config="config" v-model="search.tanggal_awal" value="" @on-change="convertDate($event, 'tanggalawal')" placeholder="[ Tanggal Mulai ]" class="w-100 calender-small custom-flat-pickr m-0">
                          </flat-pickr>
                        </div>
                        <div class="mr-2">
                          <flat-pickr :config="config" v-model="search.tanggal_akhir" value="" @on-change="convertDate($event, 'tanggalakhir')" placeholder="[ Tanggal Akhir ]" class="w-100 calender-small custom-flat-pickr m-0">
                          </flat-pickr>
                        </div>
                        <div class="mr-2">
                            <b-form-input class="form-control-custom" v-model="search.searchLike" placeholder="Nama / Nik" size="sm"></b-form-input>
                        </div>
                        <div class="mr-2">
                            <multiselect v-model="selected.searchFlag" @input="flag" :options="options.searchFlag" track-by="value" :allow-empty="false" label="name" :show-labels="false" placeholder="[ Status ]" :searchable="true">
                            </multiselect>
                        </div>
                        <div>
                            <b-button variant="primary" class="rounded-1" size="sm" @click="cari()">
                            <i class="fas fa-search"></i> Cari
                            </b-button>
                        </div>
                    </div>
                  <iq-card>
                    <template v-slot:body>
                        <div class="table bg-white mb-0">
                        <b-table class="mb-0" ref="table" :busy="table.isBusy" :items="table.items" :fields="table.fields" :select-mode="table.selectMode" :per-page="table.perPage"
                        :current-page="table.currentPage" stacked="md"
                            bordered hover small show-empty selectable>
                            <template #table-busy>
                                <div class="text-center my-2">
                                <b-spinner class="align-middle text-primary mr-2"></b-spinner>
                                <strong class="text-dark">Memuat Data...</strong>
                                </div>
                            </template>
                            <template #cell(index)="data">
                                <span>{{ (table.currentPage*table.perPage)-table.perPage + data.index + 1 }}</span>
                            </template>
                            <template #cell(nik_result)="data">
                                <span>{{ data.item.nik_result }}</span>
                            </template>
                            <template #cell(nama_result)="data">
                                <span>{{ data.item.nama_result }}</span>
                            </template>
                            <template #cell(kode_tes)="data">
                                <span>{{ data.item.kode_tes }}</span>
                            </template>
                            <template #cell(tanggal_tes)="data">
                                <span>{{ convertDate(data.item.tanggal_tes, 'tanggal_tes') }}</span>
                            </template>
                            <template #cell(hasil_tes)="data">
                                <span>{{ data.item.hasil_tes }}</span>
                            </template>
                            <template #cell(flag_result)="data">
                                <p class="badge badge-primary my-2 rounded-pill mr-2" v-if="data.item.flag_result == 1">Terkirim </p>
                                <p class="badge badge-warning rounded-pill mr-2" v-else-if="data.item.flag_result == 0">Belum Dikirim </p>
                                <p class="badge badge-danger rounded-pill mr-2" v-else-if="data.item.flag_result == 3">Gagal Kirim </p>
                                <p class="badge badge-info rounded-pill mr-2" v-else>Data Test</p>
                            </template>
                            <template v-slot:cell(aksi)="data">
                                <b-button variant="white" size="md" class="btn btn-primary my-2 rounded-pill mr-2" @click="openModal('registrasi', data.item.nik_result, data.item.nama_result, data.item.jk, data.item.tgl_lahir, data.item.status_nikah, data.item.pendidikan, data.item.pekerjaan, data.item.alamat, data.item.alamat_kel, data.item.alamat_kec, data.item.alamat_kota, data.item.alamat_prov, data.item.no_hp)">
                                    <i class="ri-account-box-fill pr-0"></i>
                                </b-button>
                                <b-button variant="white" size="md" class="btn btn-primary rounded-pill" disabled @click="openModal('faktor-resiko', data.item.nik_result, data.item.nama_result, data.item.jk, data.item.status_nikah, data.item.pendidikan, data.item.alamat, data.item.alamat_kel, data.item.alamat_kec, data.item.alamat_kota, data.item.alamat_prov, data.item.no_hp)">
                                    <i class="ri-question-answer-line pr-0"></i>
                                </b-button>
                            </template>
                            </b-table>
                        </div>
                        <div class="table-pagination bg-white px-3 py-2 d-flex flex-row align-items-center">
                            <div>
                                <b-pagination v-model="table.currentPage" :total-rows="table.totalRows" :per-page="table.perPage" size="sm" class="my-0"></b-pagination>
                            </div>
                            <div @click="refreshTable" class="my-cursor">
                                <i class="fas fa-sync"></i>
                            </div>
                            <div class="ml-auto">
                                <p class="m-0 p-0 text-secondary">Menampilkan {{ table.totalRows }} baris</p>
                            </div>
                        </div>
                    </template>
                  </iq-card>
                </tab-content-item>
                <tab-content-item :active="false" id="faktor-resiko">
                  <!-- <iq-card>
                    <template v-slot:headerTitle>
                      <h4 class="card-title">Faktor Resiko</h4>
                    </template>
                    <template v-slot:body>
                        <div class="form-group row align-items-center">
                          <label class="col-md-3" for="emailnotification">Email Notification:</label>
                          <div class="col-md-9 custom-control custom-switch">
                            <input type="checkbox" class="custom-control-input" id="emailnotification" checked="">
                            <label class="custom-control-label" for="emailnotification"></label>
                          </div>
                        </div>
                        <div class="form-group row align-items-center">
                          <label class="col-md-3" for="smsnotification">SMS Notification:</label>
                          <div class="col-md-9 custom-control custom-switch">
                            <input type="checkbox" class="custom-control-input" id="smsnotification" checked="">
                            <label class="custom-control-label" for="smsnotification"></label>
                          </div>
                        </div>
                        <div class="form-group row align-items-center">
                          <label class="col-md-3" for="npass">When To Email</label>
                          <div class="col-md-9">
                            <div class="custom-control custom-checkbox">
                              <input type="checkbox" class="custom-control-input" id="email01">
                              <label class="custom-control-label" for="email01">You have new notifications.</label>
                            </div>
                            <div class="custom-control custom-checkbox">
                              <input type="checkbox" class="custom-control-input" id="email02">
                              <label class="custom-control-label" for="email02">You're sent a direct message</label>
                            </div>
                            <div class="custom-control custom-checkbox">
                              <input type="checkbox" class="custom-control-input" id="email03" checked="">
                              <label class="custom-control-label" for="email03">Someone adds you as a connection</label>
                            </div>
                          </div>
                        </div>
                        <div class="form-group row align-items-center">
                          <label class="col-md-3" for="npass">When To Escalate Emails</label>
                          <div class="col-md-9">
                            <div class="custom-control custom-checkbox">
                              <input type="checkbox" class="custom-control-input" id="email04">
                              <label class="custom-control-label" for="email04"> Upon new order.</label>
                            </div>
                            <div class="custom-control custom-checkbox">
                              <input type="checkbox" class="custom-control-input" id="email05">
                              <label class="custom-control-label" for="email05"> New membership approval</label>
                            </div>
                            <div class="custom-control custom-checkbox">
                              <input type="checkbox" class="custom-control-input" id="email06" checked="">
                              <label class="custom-control-label" for="email06"> Member registration</label>
                            </div>
                          </div>
                        </div>
                        <b-button type="submit" variant="primary" class="mr-2">Submit</b-button>
                        <b-button type="reset" variant="none" class="iq-bg-danger">Cancel</b-button>
                    </template>
                  </iq-card> -->
                </tab-content-item>
                <tab-content-item :active="false" id="dashboard">
                    <div class="row">
                        <div class="col-md-6">
                            <div class="iq-card iq-card-block iq-card-stretch iq-card-height">
                                <div class="iq-card-header d-flex justify-content-between">
                                <div class="iq-header-title">
                                    <h4 class="card-title">Data Riil per maret 2024  </h4>
                                </div>
                                </div>
                                <div class="iq-card-body">
                                <div id="doc-chart-01" style="height: 415px;"></div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <iq-card class-name="iq-card-block iq-card-stretch iq-card-height">
                            <template v-slot:headerTitle>
                                <h4 class="card-title">Pasien Berhasil Terkirim Sihepi per Maret</h4>
                            </template>
                            <template v-slot:body>
                                <ApexChart element="home-chart-06" :chartOption="chart6" />
                            </template>
                            </iq-card>
                        </div>
                    </div>
                </tab-content-item>
                <tab-content-item :active="false" id="laporan">
                  <iq-card>
                    <template v-slot:headerTitle>
                      <h4 class="card-title">Laporan</h4>
                    </template>
                    <template v-slot:body>

                    </template>
                  </iq-card>
                </tab-content-item>
              </tab-content>
            </div>
          </b-col>
        </b-row>
      </form>
    </b-container>
    <b-modal id="modal-registrasi-sihepi" ref="modal-registrasi-sihepi" header-class="text-uppercase" title="Register Pasien" size="lg" centered scrollable :no-close-on-backdrop="true" :static="true" :no-close-on-esc="true" cancel-title="Tutup" ok-title="Simpan">
      <div>
        <div class="field-pasien shadow-sm radius-custom border mb-3">
          <div class="p-2">
            <p class="m-0 p-0 text-dark font-weight-bold text-uppercase">Pasien</p>
          </div>
          <hr class="p-0 my-1">
          <div class="p-2">
            <b-row class="align-items-center mb-1">
              <b-col cols="3">
                <div>
                  <p class="m-0 p-0 text-dark custom-fonts">NIK</p>
                </div>
              </b-col>
              <b-col>
                <b-form-group class="m-0 p-0">
                  <b-form-input class="form-control-custom" v-model="formRegistrasi.nik" placeholder="No. Rekam Medis..." size="sm"></b-form-input>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row class="align-items-center mb-1">
              <b-col cols="3">
                <div>
                  <p class="m-0 p-0 text-dark custom-fonts">NAMA</p>
                </div>
              </b-col>
              <b-col>
                <b-form-group class="m-0 p-0">
                  <b-form-input class="form-control-custom" v-model="formRegistrasi.nama" placeholder="No. Induk Kependudukan..." size="sm"></b-form-input>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row class="align-items-center mb-1">
              <b-col cols="3">
                <div>
                  <p class="m-0 p-0 text-dark custom-fonts">Jenis Kelamin</p>
                </div>
              </b-col>
              <b-col>
                <b-form-group class="m-0 p-0">
                  <multiselect v-model="selected.jeniskelamin" @input="jkValue" :options="options.jeniskelamin" track-by="value" :allow-empty="false" label="name" :show-labels="false" placeholder="[ Jenis Kelamin ]" :searchable="true">
                  </multiselect>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row class="align-items-center mb-1">
              <b-col cols="3">
                <div>
                  <p class="m-0 p-0 text-dark custom-fonts">Tanggal Lahir</p>
                </div>
              </b-col>
              <b-col>
                <b-form-group class="m-0 p-0">
                  <flat-pickr :config="config" v-model="formRegistrasi.tgl_lahir" @on-change="convertDate($event, 'tanggallahir')" placeholder="[ Tanggal Lahir ]" class="w-100 calender-small custom-flat-pickr m-0">
                  </flat-pickr>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row class="align-items-center mb-1">
              <b-col cols="3">
                <div>
                  <p class="m-0 p-0 text-dark custom-fonts">Status Nikah</p>
                </div>
              </b-col>
              <b-col>
                <b-form-group class="m-0 p-0">
                  <multiselect v-model="selected.stsNikah" @input="stsNikah" :options="options.stsNikah" track-by="value" :allow-empty="false" label="name" :show-labels="false" placeholder="[ Status Nikah ]" :searchable="true">
                  </multiselect>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row class="align-items-center mb-1">
              <b-col cols="3">
                <div>
                  <p class="m-0 p-0 text-dark custom-fonts">Pendidikan</p>
                </div>
              </b-col>
              <b-col>
                <b-form-group class="m-0 p-0">
                  <b-form-input class="form-control-custom" v-model="formRegistrasi.pendidikan" placeholder="pendidikan" size="sm"></b-form-input>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row class="align-items-center mb-1">
              <b-col cols="3">
                <div>
                  <p class="m-0 p-0 text-dark custom-fonts">ALAMAT</p>
                </div>
              </b-col>
              <b-col>
                <b-form-group class="m-0 p-0">
                    <b-form-input class="form-control-custom" v-model="formRegistrasi.alamat" placeholder="Alamat" size="sm"></b-form-input>
                </b-form-group>
              </b-col>
            </b-row>
          </div>
        </div>
      </div>
    </b-modal>
    </div>
</template>
<script>
import { xray } from '../../../config/pluginInit'
import Loader from '../../../components/xray/loader/Loader'
import Services from '../../../services/fatmahost/connected/index.js'
import Multiselect from 'vue-multiselect'
import flatPickr from 'vue-flatpickr-component'
import 'flatpickr/dist/flatpickr.css'

export default {
  name: 'Sihepi',
  components: {
    Loader,
    Multiselect,
    flatPickr
  },
  data () {
    return {
      isLoading: false,
      table: {
        isBusy: false,
        items: this.list,
        fields: [
          { label: 'NO.', key: 'index', class: 'text-lg-center' },
          { label: 'NIK', key: 'nik_result' },
          { label: 'NAMA', key: 'nama_result' },
          { label: 'KODE TES', key: 'kode_tes' },
          { label: 'TANGGAL TES', key: 'tanggal_tes' },
          { label: 'HASIL', key: 'hasil_tes' },
          { label: 'STATUS', key: 'flag_result', class: 'text-center' },
          { label: 'AKSI', key: 'aksi', class: 'text-center' }
        ],
        currentPage: 1,
        perPage: 10,
        totalRows: 0,
        selectMode: 'single',
        condition: ''
      },
      formRegistrasi: {
        nik: '',
        nama: '',
        jk: null,
        tgl_lahir: '',
        status_nikah: '',
        pendidikan: '',
        pekerjaan: '',
        alamat: '',
        alamat_kel: '',
        alamat_kec: '',
        alamat_prov: '',
        alamat_kota: '',
        no_hp: ''
      },
      view: {
        nik: '',
        nama: '',
        jk: null,
        tgl_lahir: '',
        status_nikah: '',
        pendidikan: '',
        pekerjaan: '',
        alamat: '',
        alamat_kel: '',
        alamat_kec: '',
        alamat_prov: '',
        alamat_kota: '',
        no_hp: ''
      },
      options: {
        jeniskelamin: [
          { name: 'Perempuan', value: 'p' },
          { name: 'Laki-laki', value: 'l' }
        ],
        stsNikah: [
          { name: 'Kawin', value: 'Kawin' },
          { name: 'Belum Kawin', value: 'Belum Kawin' },
          { name: 'Cerai Hidup', value: 'Cerai Hidup' },
          { name: 'Cerai Mati', value: 'Cerai Mati' }
        ],
        searchFlag: [
          { name: 'Terkirim', value: 1 },
          { name: 'Belum dikirim', value: 0 },
          { name: 'Gagal Kirim', value: 3 }
        ]
      },
      selected: {
        jeniskelamin: [],
        stsNikah: []
      },
      config: {
        dateFormat: 'Y-m-d',
        maxDate: 'today',
        disableMobile: true
      },
      search: {
        tanggal_awal: '',
        tanggal_akhir: '',
        searchLike: '',
        flag: ''
      },
      chart6: {
        chart: {
          height: 500,
          type: 'bar',
          sparkline: {
            show: false
          },
          toolbar: {
            show: false
          }
        },
        colors: ['#089bab', '#fc9f5b'],
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: '30%',
            endingShape: 'rounded'
          }
        },
        dataLabels: {
          enabled: false
        },
        stroke: {
          show: false,
          width: 5,
          colors: ['#ffffff']
        },
        series: [{
          name: 'antihcv',
          enabled: 'true',
          data: []
        }, {
          name: 'vl',
          data: []
        }],
        xaxis: {
          categories: ['03-2024', '04-2024', '05-2024', '06-2024', '07-2024', '08-2024', '09-2024', '10-2024', '11-2024']
        },
        fill: {
          opacity: 1
        },
        tooltip: {
          y: {
            formatter: function (val) {
              return val + ' pasien'
            }
          }
        }
      }

    }
  },
  mounted () {
    xray.index()
    this.list()
    this.dashboardTerkirim()
  },
  methods: {
    async list () {
      this.table.isBusy = true
      try {
        let queryParams = ''
        // Membuat parameter untuk tanggal awal jika ada
        queryParams += `page=${this.table.currentPage}&limit=${this.table.perPage}`
        if (this.search.tanggal_awal) {
          if (queryParams.length > 0) queryParams += '&'
          queryParams += `awal=${encodeURIComponent(this.search.tanggal_awal)}`
        }
        // Anda bisa menambahkan lebih banyak parameter dengan cara yang serupa
        // Contoh menambahkan tanggal akhir
        if (this.search.tanggal_akhir) {
          if (queryParams.length > 0) queryParams += '&'
          queryParams += `akhir=${encodeURIComponent(this.search.tanggal_akhir)}`
        }
        if (this.search.searchLike) {
          if (queryParams.length > 0) queryParams += '&'
          queryParams += `search=${encodeURIComponent(this.search.searchLike)}`
        }
        if (this.search.flag !== '') {
          if (queryParams.length > 0) queryParams += '&'
          queryParams += `flag=${encodeURIComponent(this.search.flag)}`
        }
        // Membuat query string lengkap
        if (queryParams.length > 0) queryParams = '?' + queryParams
        const response = await Services.getDataAllSihepi(queryParams)
        if (response.data.status === 200) {
          this.table.isBusy = false
          this.message = response.data.detail
          this.status = response.data.status
          this.table.totalRows = response.data.total
          return response.data.data[0]
        } else {
          this.table.isBusy = false
          return response.data.data
        }
      } catch (error) {
        this.table.isBusy = false
        this.status = 400
        this.message = error.message
      }
    },
    refreshTable () {
      this.$refs.table.refresh()
    },
    cari () {
      this.$refs.table.refresh()
    },
    async kirim () {
      this.isLoading = true
      try {
        await Services.kirimDataAllSihepi()
        this.isLoading = false
        this.$refs.table.refresh()
      } catch (error) {
        this.isLoading = false
        this.toast('bg-danger', 'gagal', '2')
      }
    },
    async transfer () {
      this.isLoading = true
      try {
        await Services.transferDataAllSihepi()
        this.isLoading = false
        this.$refs.table.refresh()
      } catch (error) {
        this.isLoading = false
        this.toast('bg-danger', error.response.data.detail || error.response.data.data.detail, '2')
      }
    },
    openModal (param, nik, nama, jk, tgllahir, statusnikah, pendidikan, pekerjaan, alamat, alamatkel, alamatkec, alamatprov, alamatkota, nohp) {
      this.formRegistrasi.nik = nik
      this.formRegistrasi.nama = nama
      this.formRegistrasi.tgl_lahir = tgllahir//   this.formRegistrasi.status_nikah = [{ value: statusnikah }]
      this.formRegistrasi.pendidikan = pendidikan
      this.formRegistrasi.pekerjaan = pekerjaan
      this.formRegistrasi.alamat = alamat
      this.formRegistrasi.alamat_kel = alamatkel
      this.formRegistrasi.alamat_kec = alamatkec
      this.formRegistrasi.alamat_prov = alamatprov
      this.formRegistrasi.alamat_kota = alamatkota
      this.formRegistrasi.no_hp = nohp
      this.selected.jeniskelamin = [{ value: jk, name: jk === 'l' ? 'Laki-laki' : 'Perempuan' }]
      this.selected.stsNikah = [{ value: statusnikah, name: statusnikah }]
      //

      this.$refs[`modal-${param}-sihepi`].show()
    },
    convertDate (value, param) {
      const date = new Date(value)
      const currentDate = new Date()
      const day = String(date.getDate()).padStart(2, '0')
      const month = String(date.getMonth() + 1).padStart(2, '0')
      const year = String(date.getFullYear())
      const hours = String(currentDate.getHours()).padStart(2, '0')
      const minutes = String(currentDate.getMinutes()).padStart(2, '0')
      const seconds = String(currentDate.getSeconds()).padStart(2, '0')
      if (param === 'tanggallahir') this.formRegistrasi.tgl_lahir = year + '-' + month + '-' + day
      if (param === 'tanggal') this.form.RUJUKAN.TANGGAL = year + '-' + month + '-' + day + ' ' + hours + ':' + minutes + ':' + seconds
      if (param === 'tanggal_tes') return year + '-' + month + '-' + day
    },
    jkValue (e) {
      this.formRegistrasi.jk = e.value
    },
    stsNikah (e) {
      this.formRegistrasi.status_nikah = e.value
    },
    flag (e) {
      this.search.flag = e.value
    },
    async dashboardTerkirim () {
      this.isLoading = true
      try {
        const response = await Services.dashboardTerkirim()
        const apiData = response.data
        const categories = apiData[0].map(item => item.bulan_tahun)
        const antihcvData = apiData[0].map(item => parseInt(item.antihcv))
        const vlData = apiData[0].map(item => parseInt(item.vl))
        this.chart6.series[0].data = antihcvData
        this.chart6.series[1].data = vlData
        this.chart6.xaxis.categories = categories
        this.isLoading = false
      } catch (error) {
        this.isLoading = false
        this.toast('bg-danger', 'gagal', '2')
      }
    }
  }
}
</script>
<style>
#sihepi .table td {
  font-size: 12px;
  font-weight: 500;
  padding-top: 0px;
  padding-bottom: 0px;
}

#sihepi .table th {
  font-size: 13px;
  font-weight: 400;
}

#sihepi .page-link {
  background: transparent !important;
  border: none !important;
  color: gray !important;
  margin-right: 15px;
}

#sihepi .page-item.active .page-link {
  background-color: transparent;
  border-color: transparent;
  color: #089bab !important;
  font-weight: bold;
}
#sihepi .my-cursor {
  cursor: pointer;
}
#sihepi .radius-custom {
  border-radius: .3rem;
}
#sihepi .form-control-custom {
  height: 33px!important;
  background-color: transparent;
  border-radius: .3rem!important;
  font-size: 13px;
  color: black;
}
#sihepi .multiselect__placeholder {
  font-weight: normal;
  font-size: 13px!important;
  color: #495057c9;
}
#sihepi .multiselect__option {
  font-weight: normal;
  font-size: 13px;
}
#sihepi .multiselect__tags {
  overflow: hidden;
  font-weight: normal;
  padding: 3px 40px 0 8px!important;
  height: 33px!important;
  min-height: 33px!important;
  border: 1px solid rgba(0, 0, 0, 0.212);
}
#sihepi .multiselect {
  height: 33px!important;
  min-height: 33px!important;
}
#sihepi .multiselect__select {
  height: 33px!important;
  min-height: 33px!important;
}
#sihepi .multiselect__single  {
  padding-left: 0px;
}
#sihepi .custom-fonts {
  font-size: 13px;
}
#sihepi .custom-flat-pickr {
    border: 1px solid rgb(233, 233, 233);
    height: 33px;
    padding-left: 10px;
    padding-top: 5px;
    padding-bottom: 5px;
    border: 1px solid rgb(192, 192, 192)!important;
    border-radius: 5px;
    font-size: 12px;
    color: black;
}
</style>
