<template>
  <div id="list-pegawai" class="list-pegawai">
    <b-container fluid>
      <b-row>
        <b-col md="12">
          <iq-card>
            <template v-slot:headerTitle>
              <h4 class="card-title">Data Pegawai</h4>
            </template>
            <template v-slot:headerAction>
              <b-button variant="primary" @click="tambah"><i class="fas fa-user-plus"></i> Tambah Pegawai</b-button>
            </template>
            <template v-slot:body>
              <div class="py-2">
                <div class="table mb-0">
                  <b-table class="mb-0"
                  ref="table"
                  bordered
                  hover
                  striped
                  :busy="isBusy"
                  :items="items"
                  :fields="fields"
                  :current-page="currentPage"
                  :per-page="perPage"
                  :filter="filter"
                  stacked="md"
                  show-empty
                  @filtered="onFiltered"
                >
                  <template #table-busy>
                    <div class="text-center text-danger my-2">
                      <b-spinner class="align-middle" variant="primary" type="grow" style="width: 3rem; height: 3rem;"></b-spinner>
                      <!-- <strong> Loading...</strong> -->
                    </div>
                  </template>
                  <template #cell(index)="data">
                    {{ (currentPage*perPage)-perPage + data.index + 1 }}
                  </template>
                  <template v-slot:cell(TANGGAL_LAHIR)="data">
                    <span>{{ new Date(data.item.TANGGAL_LAHIR) | formatDate }}</span>
                  </template>
                  <template v-slot:cell(JENIS_KELAMIN)="data">
                    <span>{{ data.item.JENIS_KELAMIN == 1 ? 'Laki - laki' : 'Perempuan' }}</span>
                  </template>
                  <template v-slot:cell(action)="data">
                    <!-- <b-button variant="white" class="my-1 mr-1" size="md" @click="modalFinger"><i class="fas fa-fingerprint"></i></b-button> -->
                    <b-button variant="white" class="my-1 mr-1" size="md" @click="info(data.item, data.index, $event.target)"><i class="fas fa-user-lock text-warning"></i></b-button>
                    <b-button variant="white" class="my-1 mr-1" size="md" @click="viewPegawai(data.item.ID)"><i class="fas fa-eye text-success"></i></b-button>
                    <b-button variant="white" class="my-1" size="md" @click="editPegawai(data.item.ID)"><i class="fas fa-edit text-primary"></i></b-button>
                  </template>
                  </b-table>
                </div>
                <div class="bg-white pb-2 pt-2 pl-3 pr-3 border-bottom border-right border-left">
                  <b-row class="align-items-center justify-content-between">
                    <b-col cols="12" lg="3" class="mr-auto">
                      <div class="d-flex align-items-center">
                        <div>
                          <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage" size="sm" class="my-0">
                          </b-pagination>
                        </div>
                        <div class="border-left border-right px-3">
                          <span class="cursor-point" @click="tableRefresh">
                            <i class="fas fa-sync"></i>
                          </span>
                        </div>
                      </div>
                    </b-col>
                    <b-col cols="12" lg="5">
                      <div class="d-flex flex-row">
                        <div class="mr-2 w-75">
                          <b-input-group>
                            <b-form-input class="cstm-slct-pegawai rounded-0" id="filter-input" v-model="filter" type="search" placeholder="[ Pencarian ]"></b-form-input>
                          </b-input-group>
                        </div>
                        <div>
                          <b-form-select class="cstm-slct-pegawai" v-model="selected.filterType" :options="combo.filterType" @change="filterchange"></b-form-select>
                        </div>
                      </div>
                    </b-col>
                    <b-col cols="12" lg="3">
                      <span class="text-right">
                        <p class="font-weight-normal mb-0">Menampilkan 1 - {{ perPage }} dari {{ totalRows }}</p>
                      </span>
                    </b-col>
                  </b-row>
                </div>
              </div>
            </template>
          </iq-card>
        </b-col>
      </b-row>
    </b-container>
    <b-modal centered @ok="handleOk" @show="resetModal" @hidden="resetModal" title="User Akses Pegawai" :id="infoModal.id">
      <form ref="form" @submit.stop.prevent="handleSubmit">
        <b-form-group class="mb-0">
          <b-form-input style="display: none;" id="pegawai-id-input" v-model="infoModal.data.pegawai_id" disabled></b-form-input>
        </b-form-group>
        <b-form-group class="mb-2" label-for="name-input" invalid-feedback="Nama tidak boleh kosong">
          <b-row>
            <b-col cols="2">
              <p class="mb-0 text-dark custom-fontsize">Nama</p>
            </b-col>
            <b-col>
              <b-form-input id="nama-input" class="cstm-cntrl-pegawai" v-model="infoModal.data.name" :value="infoModal.data.name" required></b-form-input>
            </b-col>
          </b-row>
        </b-form-group>
        <b-form-group class="mb-2" label-for="name-input" :state="usernameState" invalid-feedback="Username tidak boleh kosong">
          <b-row>
            <b-col cols="2">
              <p class="mb-0 text-dark custom-fontsize">Username</p>
            </b-col>
            <b-col>
              <b-form-input id="username-input" class="cstm-cntrl-pegawai" v-model="infoModal.data.username" :state="usernameState" required></b-form-input>
            </b-col>
          </b-row>
        </b-form-group>
        <b-form-group class="mb-0" label-for="name-input" :state="passwordState" invalid-feedback="Password tidak boleh kosong">
          <b-row>
            <b-col cols="2">
              <p class="mb-0 text-dark custom-fontsize">Password</p>
            </b-col>
            <b-col>
              <b-form-input id="password-input" class="cstm-cntrl-pegawai" v-model="infoModal.data.password" :state="passwordState" required></b-form-input>
            </b-col>
          </b-row>
        </b-form-group>
      </form>
    </b-modal>
    <b-modal id="modal-forgot" ref="modal-forgot" size="xl" title="Finger" ok-title="Oke" :ok-only=true :no-close-on-backdrop="true" :static="true" centered>
      <div>
        <b-embed
          id="myIframe"
          type="iframe"
          aspect="16by9"
          src="http://192.168.13.139:8081/"
          allowfullscreen
        ></b-embed>
      </div>
    </b-modal>
    <ModalMsgbox ref="MsgBox" />
  </div>
</template>
<script>
import { xray } from '../../../config/pluginInit'
import Service from '../../../services/fatmahost/index'
import ModalMsgbox from '../../../components/modal/ModalMsgBox'

export default {
  name: 'UiDataTable',
  components: {
    ModalMsgbox
  },
  data () {
    return {
      usernameState: null,
      passwordState: null,
      isBusy: false,
      fields: [
        { label: 'NO', key: 'index', class: 'text-center' },
        { label: 'NO. INDUK PEGAWAI', key: 'NIP', class: 'text-left' },
        { label: 'NO. IDENTITAS', key: 'NOMOR', class: 'text-left' },
        { label: 'PROFESI', key: 'NAMA_PROFESI', class: 'text-left' },
        { label: 'NAMA LENGKAP', key: 'NAMA', class: 'text-left' },
        { label: 'JENIS KELAMIN', key: 'JENIS_KELAMIN', class: 'text-left' },
        // { label: 'Tempat Lahir', key: 'tempat_lahir', class: 'text-left' },
        { label: 'TANGGAL LAHIR', key: 'TANGGAL_LAHIR', class: 'text-left' },
        { label: 'AKSI', key: 'action', class: 'text-center' }
      ],
      items: this.listPegawai,
      totalRows: null,
      currentPage: null,
      perPage: null,
      filterOn: [],
      filter: null,
      infoModal: {
        id: 'info-modal',
        data: {
          name: '',
          username: '',
          password: '',
          pegawai_id: ''
        }
      },
      selected: {
        filterType: '2'
      },
      combo: {
        filterType: [
          { value: '2', text: 'Nama' },
          { value: '1', text: 'NIP' },
          { value: '3', text: 'Tanggal Lahir' }
        ]
      }
    }
  },
  mounted () {
    xray.index()
  },
  methods: {
    tst () {
      // var iframe = document.getElementById('myIframe')
      // var iframeDocument = iframe.contentDocument || iframe.contentWindow.document
      // var dataToSend = '1'
      // iframeDocument.getElementById('dataElement').innerText = dataToSend
      var iframe = document.getElementById('myIframe')
      iframe.contentWindow.postMessage({ data: 'Data Anda' }, 'http://192.168.13.139:8081/')
    },
    default () {
      return {
        errors: [],
        id: this.items.length,
        nip: '',
        nama: '',
        tempat_lahir: '',
        tanggal_lahir: '',
        jenis_kelamin: '',
        alamat: ''
      }
    },
    async listPegawai (ctx) {
      let filterType = this.selected.filterType
      let filter = ''
      if (ctx.filter === '') {
        ctx.filter = null
        filter = `{"xparam0":"","xparam1":"","xparam2":""}`
      }
      if (ctx.filter === null) {
        filter = `{"xparam0":"","xparam1":"","xparam2":""}`
      } else {
        if (filterType === '1') {
          filter = `{"xparam0":"${ctx.filter}","xparam1":"","xparam2":""}`
        } else if (filterType === '2') {
          filter = `{"xparam0":"","xparam1":"${ctx.filter}","xparam2":""}`
        } else if (filterType === '3') {
          filter = `{"xparam0":"","xparam1":"","xparam2":"${ctx.filter}"}`
        }
      }
      this.isBusy = true
      try {
        const response = await Service.pegawai.findAll(ctx.currentPage - 1, ctx.perPage, filter)
        this.totalRows = response.data.pagination.total
        this.currentPage = response.data.pagination.current
        this.perPage = response.data.pagination.perPage
        this.isBusy = false
        return response.data.data
      } catch (error) {
        this.isBusy = false
        return []
      }
    },
    viewPegawai (id) {
      this.$router.push('view/' + id)
    },
    editPegawai (id) {
      this.$router.push('edit/' + id)
    },
    tambah () {
      this.$router.push({ name: 'pegawai.add' })
    },
    onFiltered (filteredItems) {
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
    handleOk (bvModalEvent) {
      bvModalEvent.preventDefault()
      this.handleSubmit()
    },
    resetModal () {
      this.usernameState = null
      this.passwordState = null
      this.infoModal.data.username = ''
      this.infoModal.data.password = ''
    },
    checkFormValidity () {
      const valid = this.$refs.form.checkValidity()
      this.usernameState = valid
      this.passwordState = valid
      return valid
    },
    handleSubmit () {
      if (!this.checkFormValidity()) {
        return
      }
      this.save(this.infoModal.data)
    },
    async save (value) {
      try {
        const response = await Service.users.register(value)
        if (response.status === 200) {
          this.$refs.table.refresh()
          this.$refs.MsgBox.show({
            type: 'success',
            msg: response.data.message
          })
        } else {
          this.$refs.table.refresh()
          this.$refs.MsgBox.show({
            type: 'error'
          })
        }
      } catch (error) {
        this.$refs.table.refresh()
        this.error = error.response.data.errors.message
        this.$refs.MsgBox.show({
          type: 'error',
          msg: error.response.data.errors.message
        })
      }
    },
    info (item, index, button) {
      this.infoModal.data.pegawai_id = item.ID
      this.infoModal.data.name = item.NAMA
      this.$root.$emit('bv::show::modal', this.infoModal.id, button)
    },
    tableRefresh () {
      this.$refs.table.refresh()
    },
    filterchange () {
      this.filter = ''
    },
    modalFinger () {
      this.$refs['modal-forgot'].show()
      this.tst()
    }
  }
}
</script>
<style>
#modal-forgot .modal-body {
  padding: 0;
  margin: 0;
}
#list-pegawai .table td {
    font-size: 12px;
    font-weight: 500;
    padding-top: 0px;
    padding-bottom: 0px;
}
#list-pegawai .table th {
    font-size: 13px;
    font-weight: 400;
}
#list-pegawai .page-link {
    border: none;
    color: gray!important;
    margin-right: 15px;
}
#list-pegawai .page-item.active .page-link {
    background-color: transparent;
    border-color: transparent;
    color: #089bab!important;
    font-weight: bold;
}
#list-pegawai .cursor-point {
    cursor: pointer;
}
.cstm-slct-pegawai {
  font-size: 13px!important;
  height: 38px!important;
  border-radius: 5px;
}
.cstm-cntrl-pegawai {
  color: black!important;
  font-weight: bold!important;
  border-radius: 5px!important;
  font-size: 12px!important;
  height: 38px!important;
}
.custom-fontsize {
  font-size: 12px;
}
</style>
