<template>
  <div>
    <tab-nav :pills="true" id="pills-tab" class="justify-content-center border-0 shadow-none mb-1">
      <tab-nav-items class="font-weight-bold text-uppercase" :active="true" id="permintaan-pengiriman-tab" href="#permintaan-pengiriman"
        ariaControls="permintaan-pengiriman" role="tab" :ariaSelected="true" title="Permintaan" />
      <tab-nav-items class="font-weight-bold text-uppercase" :active="false" id="permintaan-history-tab" href="#permintaan-history"
        ariaControls="permintaan-history" role="tab" :ariaSelected="false" title="Penerimaan Barang baru" />
    </tab-nav>
    <tab-content id="pills-tabContent-2">
      <tab-content-item :active="true" id="permintaan-pengiriman" aria-labelled-by="permintaan-pengiriman-tab">
        <PermintaanChild></PermintaanChild>
      </tab-content-item>
      <tab-content-item :active="false" id="permintaan-history" aria-labelled-by="permintaan-history-tab">
        <PenerimaanBarangBaru></PenerimaanBarangBaru>
      </tab-content-item>
    </tab-content>
  </div>
</template>
<script>
import PermintaanChild from './permintaanChild/PermintaanChild'
import PenerimaanBarangBaru from './permintaanChild/PenerimaanBarangBaru'
export default {
  name: 'Permintaan',
  components: {
    PermintaanChild,
    PenerimaanBarangBaru
  }
}
</script>
