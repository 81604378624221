<template>
  <div>
    <b-container fluid>
      <div class="p-4" style="background: #089bab;">
        <b-embed
          id="myframe"
          type="iframe"
          aspect="16by9"
          src="http://192.168.5.73/"
          allowfullscreen
        ></b-embed>
      </div>
    </b-container>
  </div>
</template>
<script>
import { xray } from '../../config/pluginInit'

export default {
  name: 'DashboardReal',
  mounted () {
    xray.index()
  },
  methods: {
    // test () {
    //   var a = document.getElementById('myframe')
    //   console.log(a)
    // }
  }
}
</script>
