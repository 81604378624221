import Vue from 'vue'
import VueRouter from 'vue-router'
import jwtdecode from 'jwt-decode'
/* Layouts */
import Layout1 from '../layouts/Layout1'
// import Default from '../layouts/BlankLayout'
import AuthLayout1 from '../layouts/AuthLayouts/AuthLayout1'
import AuthLayout1New from '../layouts/AuthLayouts/AuthLayout1New'
/* Dashboards View */
import Dashboard from '../views/Dashboards/Dashboard1'
/* LogFile View */
import LogFile from '../views/Log/index'
/* SDM View */
import Penugasan from '../views/SDM/Penugasan/index'
import DetailRemun from '../views/SDM/Remun/DetailRemun'
import DetailRemunRekap from '../views/SDM/Remun/DetailRemunRekap'
import IndexRemunerasi from '../views/SDM/Remun/index'
import DetailRemunV2 from '../views/SDM/RemunV2/DetailRemun'
import DetailRemunRekapV2 from '../views/SDM/RemunV2/DetailRemunRekap'
import IndexRemunerasiV2 from '../views/SDM/RemunV2/index'
import KsmPegawai from '../views/SDM/Ksm/KsmPegawai'
import KsmPegawaiGrup from '../views/SDM/Ksm/KsmPegawaiGrup'
import RemunTrans from '../views/SDM/RemunTrans/index'
import RemunTransV2 from '../views/SDM/RemunTransV2/index'
import Ekinerja from '../views/SDM/EKinerja/index'
import EkinerjaDashboard from '../views/SDM/EKinerjaDashboard/index'
/* Error View */
import ErrorPage from '../views/Pages/ErrorPage'
/* Authentic View */
import SignIn from '../views/Auth/SignIn'
import SignInNew from '../views/Auth/SignInNew'
/* Welcome View */
import Welcome from '../views/Public/WelcomePage'
/* Pegawai View */
import PegawaiView from '../views/Master/Pegawai/PegawaiView'
import PegawaiList from '../views/Master/Pegawai/PegawaiList'
import PegawaiAdd from '../views/Master/Pegawai/PegawaiAdd'
import PegawaiEdit from '../views/Master/Pegawai/PegawaiEdit'
/* pasien View */
import PasienView from '../views/Master/Pasien/PasienView'
import PasienList from '../views/Master/Pasien/PasienList'
import PasienAdd from '../views/Master/Pasien/PasienAdd'
import PasienEdit from '../views/Master/Pasien/PasienEdit'
/* pendaftaran View */
import PendaftaranView from '../views/Master/Pendaftaran/PendaftaranView'
import PendaftaranList from '../views/Master/Pendaftaran/PendaftaranList'
import PendaftaranAdd from '../views/Master/Pendaftaran/PendaftaranAdd'
import PendaftaranEdit from '../views/Master/Pendaftaran/PendaftaranEdit'
/* Pegawai View */
import JadwalView from '../views/Laboratorium/JadwalCathlab/View'
import JadwalList from '../views/Laboratorium/JadwalCathlab/Index'
import JadwalAdd from '../views/Laboratorium/JadwalCathlab/Add'
import JadwalDash from '../views/Laboratorium/JadwalCathlab/Dashboard'
/* Sakti View */
import Sakti from '../views/Keuangan/Sakti/Index'
/* Bios View */
import Bios from '../views/Keuangan/Bios/Index'
import BiosSaldo from '../views/Keuangan/Bios/Sub/BiosSaldo'
import BiosSaldoOperasional from '../views/Keuangan/Bios/Sub/BiosSaldoOperasional'
import BiosSaldoPengelolaanKas from '../views/Keuangan/Bios/Sub/BiosSaldoPengelolaanKas'
import BiosSaldoDanaKelolaan from '../views/Keuangan/Bios/Sub/BiosSaldoDanaKelolaan'
import BiosPengeluaran from '../views/Keuangan/Bios/Sub/BiosPengeluaran'
import BiosPenerimaan from '../views/Keuangan/Bios/Sub/BiosPenerimaan'
import BiosKepatuhanWaktuVisite from '../views/Keuangan/Bios/Sub/BiosIKTKepatuhanVisiteDokter'
import BiosIKTJumlahVisitePasien from '../views/Keuangan/Bios/Sub/BiosIKTJumlahVisitePasien'
import BiosIKTJumlahVisitePasien12 from '../views/Keuangan/Bios/Sub/BiosIKTJumlahVisitePasien12'
import BiosIKTJumlahDpjpTdkVisite from '../views/Keuangan/Bios/Sub/BiosIKTJumlahDpjpTdkVisite'
import BiosIKTRasioPobo from '../views/Keuangan/Bios/Sub/BiosIKTRasioPobo'
import BiosIKTJumlahVisitePasienPertama from '../views/Keuangan/Bios/Sub/BiosIKTJumlahVisitePasienPertama'
import BiosIKTKepuasanPasien from '../views/Keuangan/Bios/Sub/BiosIKTKepuasanPasien'
import BiosIKTWaktuTungguIRJ from '../views/Keuangan/Bios/Sub/BiosIKTWaktuTungguIRJ'
import BiosIKTPenyelenggaraanRme from '../views/Keuangan/Bios/Sub/BiosIKTPenyelenggaraanRme'
import BiosIKTKepatuhanPenggunaanApd from '../views/Keuangan/Bios/Sub/BiosIKTKepatuhanPenggunaanApd'
import BiosIKTPenundaanOperasi from '../views/Keuangan/Bios/Sub/BiosIKTPenundaanOperasi'
import BiosIKTKepatuhanTerhadapAlurKlinis from '../views/Keuangan/Bios/Sub/BiosIKTKepatuhanTerhadapAlurKlinis'
import BiosIKTKepatuhanPenggunaanFormularium from '../views/Keuangan/Bios/Sub/BiosIKTKepatuhanPenggunaanFormularium'
import BiosIKTKepatuhanWaktuVisiteDPJP from '../views/Keuangan/Bios/Sub/BiosIKTKepatuhanWaktuVisiteDPJP'
import BiosIKTKepatuhanPelaksanaanProtokolKesehatan from '../views/Keuangan/Bios/Sub/BiosIKTKepatuhanPelaksanaanProtokolKesehatan'
import BiosIKTPersentasePembelianAlkes from '../views/Keuangan/Bios/Sub/BiosIKTPersentasePembelianAlkes'
import BiosIKTKepatuhanIdentifikasiPasien from '../views/Keuangan/Bios/Sub/BiosIKTKepatuhanIdentifikasiPasien'
import BiosIKTKecepatanWaktuTanggapKomplain from '../views/Keuangan/Bios/Sub/BiosIKTKecepatanWaktuTanggapKomplain'
import BiosIKTPelaporanHasilKritisLab from '../views/Keuangan/Bios/Sub/BiosIKTPelaporanHasilKritisLab'
import BiosIKTWaktuTanggapOperasiSeksio from '../views/Keuangan/Bios/Sub/BiosIKTWaktuTanggapOperasiSeksio'
import BiosIKTKepatuhanResikoPasienJatuh from '../views/Keuangan/Bios/Sub/BiosIKTKepatuhanResikoPasienJatuh'
import BiosIKTPertumbuhanRealisasiPendapatanBlu from '../views/Keuangan/Bios/Sub/BiosIKTPertumbuhanRealisasiPendapatanBlu'
import BiosIKTJumlahVisite10 from '../views/Keuangan/Bios/Sub/BiosIKTJumlahVisite10'
import BiosIKTKepatuhanKebersihanTangan from '../views/Keuangan/Bios/Sub/BiosIKTKepatuhanKebersihanTangan'
import BiosLayananBor from '../views/Keuangan/Bios/Sub/BiosLayananBor'
import BiosLayananToi from '../views/Keuangan/Bios/Sub/BiosLayananToi'
import BiosLayananAlos from '../views/Keuangan/Bios/Sub/BiosLayananAlos'
import BiosLayananBto from '../views/Keuangan/Bios/Sub/BiosLayananBto'
// Bios master
import BiosMasterAkun from '../views/Keuangan/Bios/master/akun'
import BiosMasterBank from '../views/Keuangan/Bios/master/bank'
import BiosMasterRekening from '../views/Keuangan/Bios/master/rekening'
/* Mhas View */
import Mhas from '../views/Keuangan/Mhas/Index'
/* Eklaim View */
import EklaimList from '../views/Eklaim/EklaimList'
import EklaimAdd from '../views/Eklaim/EklaimAdd'
import EklaimMonitor from '../views/Eklaim/EklaimMonitoring'
import EklaimMonitorView from '../views/Eklaim/EklaimMonitoringView'
import ImportData from '../views/Eklaim/ImportData'
/* Pengguna View */
import PenggunaList from '../views/Master/Pengguna/PenggunaList'
import PenggunaView from '../views/Master/Pengguna/PenggunaView'
/* Module View */
import ModuleList from '../views/System/Modules/List'
import RoleList from '../views/System/Role/List'
import MenuModule from '../views/System/MenuModules/List'
import MenuList from '../views/System/Menu/List'
/* Module View */
import BarangList from '../views/Barang/Index'
/* Module Farmasi */
import FarmasiList from '../views/Farmasi/InventoriPinjam'
import FarmasiListPengembalian from '../views/Farmasi/InventoriPengembalian'
import StockOpnameList from '../views/Farmasi/StockOpname'
import StockOpnameView from '../views/Farmasi/StockOpnameView'
import StockOpnameSee from '../views/Farmasi/StockOpnameSee'
import PendingFarmasiList from '../views/Farmasi/PendingFarmasi'
import PendingFarmasiTambah from '../views/Farmasi/PendingFarmasiTambah'
import PenjualanRetur from '../views/Farmasi/PenjualanRetur'
import PenjualanList from '../views/Farmasi/PenjualanList'
//
import Penerimaan from '../views/RumahTangga/Penerimaan/index'
import PenerimaanDetail from '../views/RumahTangga/Penerimaan/detail'
import RumahTanggaKemasan from '../views/RumahTangga/Master/MRTKemasan/index'
import RumahTanggaKelompok from '../views/RumahTangga/Master/MRTKelompok/index'
import RumahTanggaRekanan from '../views/RumahTangga/Master/MRTRekanan/index'
import RumahTanggaJenisAnggaran from '../views/RumahTangga/Master/MRTJenisAnggaran/index'
import RumahTangga from '../views/RumahTangga/Master/MRTRumahTangga/index'
// import PendingFarmasiView from '../views/Farmasi/PendingFarmasiView'
/* Master Hari Libur Nasional */
import LiburNasionalIndex from '../views/Master/LiburNasional/index'
/* Master Hari Libur Dokter */
import LiburDokterIndex from '../views/Master/LiburDokter/index'
/* Master Hari Libur Executive */
import LiburExecutiveIndex from '../views/Master/LiburExecutive/index'
/* Master Hari Libur Irm */
import LiburIrmIndex from '../views/Master/LiburIrm/index'
/* Master Hari Libur exclude */
import LiburExcludeindex from '../views/Master/LiburNasionalExclude/index'
/* Module View */
import PersediaanList from '../views/Persediaan/Index'
/* Chat View */
import ChatIndex from '../views/Apps/Chat/Index'
/* Master Kuota */
import KuotaIndex from '../views/Master/Kuota/Index'
import KuotaDokterIndex from '../views/Master/KuotaDokter/Index'
import KuotaDokterIrj from '../views/Master/KuotaDokter/KuotaDokterIrj'
import KuotaDokterIgh from '../views/Master/KuotaDokter/KuotaDokterIgh'
import KuotaDokterIrm from '../views/Master/KuotaDokter/KuotaDokterIrm'
import KuotaMCU from '../views/Master/KuotaMCU'
import PenjadwalanOperasi from '../views/Master/PenjadwalanOperasi/index'
import KalenderOperasi from '../views/Master/PenjadwalanOperasi/kalender.vue'
/* Master Ruangan */
import masterRuangan from '../views/Master/Ruangan/index'
/* Master Tarif */
import TarifIndex from '../views/Master/SettingTarif/Index'
import TarifRemunIndex from '../views/Master/TarifRemun/Index'
/* BPJS View */
import BpjsKepesertaan from '../views/Public/BpjsKepesertaan'
import BpjsKepesertaanAdmin from '../views/Public/BpjsKepesertaanAdmin'
/* Dashboard SDM */
import DashboardSDM from '../views/Public/DashboardSDM'
/* Dashboard Dirut */
import DashboardDirut from '../views/Dashboards/Dashboard-Dirut'
/* Dashboard Antrian */
import DashboardAntrian from '../views/Public/Dashboard-Antrian'
/* Dashboard Klaim */
import DashboardKlaim from '../views/Public/DashboardKlaim'
/* Dashboard Realtime */
import DashboardReal from '../views/Public/DashboardReal'
/* Dashboard Realtime */
import DatabaseWrite from '../views/Monitoring/Database/Write'
import DatabaseRead from '../views/Monitoring/Database/Read'
/* Reservasi Meningitis */
import ReservasiMeningitisOnline from '../views/Public/ReservasiMeningitisOnline'
import ReservasiMeningitisOffline from '../views/Public/ReservasiMeningitisOffline'
import MeningitisList from '../views/Reservasi/InstalasiMeningitis/Index'
import MeningitisView from '../views/Reservasi/InstalasiMeningitis/View'
import McuList from '../views/Mcu/Index'
import McuView from '../views/Mcu/View'
/* Reservasi IRJ */
import IrjList from '../views/Reservasi/InstalasiRawatJalan/IrjList'
import ReservasiOnlineRawatJalan from '../views/Public/ReservasiOnlineRawatJalan'
/* Reservasi Griya */
import IghList from '../views/Reservasi/InstalasiGriyaHusada/IghList'
//
/* Reservasi Rehab Medik */
import IrmList from '../views/Reservasi/InstalasiRehabMedik/IrmList'
//
/* List Member */
import AkunList from '../views/Reservasi/Member/Akun'
import AnggotaList from '../views/Reservasi/Member/Anggota'
//
import ReservasiOnline from '../views/Public/ReservasiOnline'
import ReservasiOffline from '../views/Public/ReservasiOffline'
import ReservasiOfflineNew from '../views/Public/ReservasiOfflineNew'
/* APM */
import AnjunganPendaftaranMandiri from '../views/Public/AnjunganPendaftaranMandiri'
import AnjunganPendaftaranMandiriDtl from '../views/Public/AnjunganPendaftaranMandiriDetail'
import AnjunganPendaftaranMandiriDtlIGH from '../views/Public/AnjunganPendaftaranMandiriDetailIGH'
import ApmIrjSep from '../views/Public/APM-RJ-SEP'
import ApmIrjSepv2 from '../views/Public/ApmIrjV2.vue'
/* Unit Transfusi Darah */
import ReservasiUtd from '../views/Public/ReservasiUTD'
import UtdList from '../views/Reservasi/InstalasiUTD/UtdList'
import UtdPendaftaran from '../views/Reservasi/InstalasiUTD/UtdPendaftaran'
import UtdPemeriksaan from '../views/Reservasi/InstalasiUTD/UtdPemeriksaan'
import UtdDokter from '../views/Reservasi/InstalasiUTD/UtdDokter'
/* User View */
import Profile from '../views/User/Profile'
import ProfileEdit from '../views/User/ProfileEdit'
/* Katalog */
import KatalogList from '../views/Katalog/Index'
import KatalogView from '../views/Katalog/Sub/Katalog'
import KatalogPabrik from '../views/Katalog/Sub/Pabrik'
import KatalogKemasan from '../views/Katalog/Sub/Kemasan'
import KatalogPbf from '../views/Katalog/Sub/Pbf'
import KatalogGenerik from '../views/Katalog/Sub/Generik'
import KatalogKelompok from '../views/Katalog/Sub/Kelompok'
import KatalogBrand from '../views/Katalog/Sub/Brand'
import KatalogBuffer from '../views/Katalog/Sub/BufferGudang'
import KatalogJenisAnggaran from '../views/Katalog/Sub/JenisAnggaran'
import KatalogSubJenisAnggaran from '../views/Katalog/Sub/SubJenisAnggaran'
import KatalogSakti from '../views/Katalog/Sub/Sakti'
import KatalogSaktiHdr from '../views/Katalog/Sub/SaktiHdr'
import KatalogDosis from '../views/Katalog/Sub/Dosis'
import LaporanInstalasiindex from '../views/Laporan/LaporanInstalasi'
// master remunerasi
import RemunKegiatanKlpIndex from '../views/Master/RemunKegiatanKlp'
import RemunKegiatanExcludeIndex from '../views/Master/RemunKegiatanExclude'
import RemunKegiatanTarifIndex from '../views/Master/RemunKegiatanTarif'
import RemunPersentaseIndex from '../views/Master/RemunPersentase'
// Integrasi
import Sisrute from '../views/Integrasi/Sisrute/index'
import Sihepi from '../views/Integrasi/SiHepi/index'
import Bpjs from '../views/Integrasi/BPJS/index'
// EMR
import MedicalRecord from '../views/MedicalRecord/index'

Vue.use(VueRouter)

const homeChildRoutes = (prop) => [
  {
    path: '/dashboard',
    name: prop + '.home',
    component: Dashboard
  }
]

const authChildRoutes = (prop) => [
  {
    path: 'signin',
    name: prop + '.signin',
    component: SignIn
  },
  {
    path: 'signin-new',
    name: prop + '.signin-new',
    component: SignInNew
  }
]

const pegawaiChildRoutes = (prop) => [
  {
    path: 'list',
    name: prop + '.list',
    component: PegawaiList
  },
  {
    path: 'add',
    name: prop + '.add',
    component: PegawaiAdd
  },
  {
    path: 'view/:id',
    name: prop + '.view',
    component: PegawaiView
  },
  {
    path: 'edit/:id',
    name: prop + '.edit',
    component: PegawaiEdit
  }
]

const pasienChildRoutes = (prop) => [
  {
    path: 'list',
    name: prop + '.list',
    component: PasienList
  },
  {
    path: 'add',
    name: prop + '.add',
    component: PasienAdd
  },
  {
    path: 'view/:id',
    name: prop + '.view',
    component: PasienView
  },
  {
    path: 'edit/:id',
    name: prop + '.edit',
    component: PasienEdit
  },
  //
  {
    path: 'rekam-medis/:id',
    name: prop + '.rekam-medis',
    component: MedicalRecord
  }
]

const pendaftaranChildRoutes = (prop) => [
  {
    path: 'list',
    name: prop + '.list',
    component: PendaftaranList
  },
  {
    path: 'add',
    name: prop + '.add',
    component: PendaftaranAdd
  },
  {
    path: 'view/:id',
    name: prop + '.view',
    component: PendaftaranView
  },
  {
    path: 'edit/:id',
    name: prop + '.edit',
    component: PendaftaranEdit
  }
]

const moduleChildRoutes = (prop) => [
  {
    path: 'list',
    name: prop + '.list',
    component: ModuleList
  }
]

const roleChildRoutes = (prop) => [
  {
    path: 'list',
    name: prop + '.list',
    component: RoleList
  }
]

const menumoduleChildRoutes = (prop) => [
  {
    path: 'list',
    name: prop + '.list',
    component: MenuModule
  }
]

const menuChildRoutes = (prop) => [
  {
    path: 'list',
    name: prop + '.list',
    component: MenuList
  }
]

const penggunaChildRoutes = (prop) => [
  {
    path: 'list',
    name: prop + '.list',
    component: PenggunaList
  },
  {
    path: 'view/:id',
    name: prop + '.view',
    component: PenggunaView
  }
]

const userChildRoute = (prop) => [
  {
    path: 'profile',
    name: prop + '.profile',
    component: Profile
  },
  {
    path: 'profile-edit',
    name: prop + '.edit',
    component: ProfileEdit
  }
]
// incoming
const katalogChildRoutes = (prop) => [
  {
    path: 'list',
    name: prop + '.list',
    component: KatalogList
  },
  {
    path: 'pabrik',
    name: prop + '.pabrik',
    component: KatalogPabrik
  },
  {
    path: 'katalog',
    name: prop + '.katalog',
    component: KatalogView
  },
  {
    path: 'kemasan',
    name: prop + '.kemasan',
    component: KatalogKemasan
  },
  {
    path: 'pbf',
    name: prop + '.pbf',
    component: KatalogPbf
  },
  {
    path: 'generik',
    name: prop + '.generik',
    component: KatalogGenerik
  },
  {
    path: 'kelompok',
    name: prop + '.kelompok',
    component: KatalogKelompok
  },
  {
    path: 'brand',
    name: prop + '.brand',
    component: KatalogBrand
  },
  {
    path: 'buffer',
    name: prop + '.buffer',
    component: KatalogBuffer
  },
  {
    path: 'jenisanggaran',
    name: prop + '.jenisanggaran',
    component: KatalogJenisAnggaran
  },
  {
    path: 'subjenisanggaran',
    name: prop + '.subjenisanggaran',
    component: KatalogSubJenisAnggaran
  },
  {
    path: 'sakti',
    name: prop + '.sakti',
    component: KatalogSakti
  },
  {
    path: 'saktihdr',
    name: prop + '.saktihdr',
    component: KatalogSaktiHdr
  },
  {
    path: 'dosis',
    name: prop + '.dosis',
    component: KatalogDosis
  }
]

// up
const barangChildRoutes = (prop) => [
  {
    path: 'list',
    name: prop + '.list',
    component: BarangList
  }
]

const persediaanChildRoutes = (prop) => [
  {
    path: 'list',
    name: prop + '.list',
    component: PersediaanList
  }
]

const quotaChildRoutes = (prop) => [
  {
    path: 'list',
    name: prop + '.list',
    component: KuotaIndex
  },
  {
    path: 'list1',
    name: prop + '.list1',
    component: KuotaDokterIndex
  },
  {
    path: 'list2',
    name: prop + '.list2',
    component: KuotaDokterIgh
  },
  {
    path: 'list3',
    name: prop + '.list3',
    component: KuotaDokterIrj
  },
  {
    path: 'list4',
    name: prop + '.list4',
    component: KuotaDokterIrm
  },
  {
    path: 'list5',
    name: prop + '.list5',
    component: KuotaMCU
  }
]

const mastertarifChildRoutes = (prop) => [
  {
    path: 'list',
    name: prop + '.list',
    component: TarifIndex
  }
]

const mastertarifremunChildRoutes = (prop) => [
  {
    path: 'list',
    name: prop + '.list',
    component: TarifRemunIndex
  }
]

const saktiChildRoutes = (prop) => [
  {
    path: 'index',
    name: prop + '.index',
    component: Sakti
  }
]

const mhasChildRoutes = (prop) => [
  {
    path: 'index',
    name: prop + '.index',
    component: Mhas
  }
]

const biosChildRoutes = (prop) => [
  {
    path: 'index',
    name: prop + '.index',
    component: Bios
  },
  {
    path: 'saldo',
    name: prop + '.saldo',
    component: BiosSaldo
  },
  {
    path: 'master-akun',
    name: prop + '.master-akun',
    component: BiosMasterAkun
  },
  {
    path: 'master-bank',
    name: prop + '.master-bank',
    component: BiosMasterBank
  },
  {
    path: 'master-rekening',
    name: prop + '.master-rekening',
    component: BiosMasterRekening
  },
  {
    path: 'saldo-operasional',
    name: prop + '.saldo-operasional',
    component: BiosSaldoOperasional
  },
  {
    path: 'saldo-pengelolaan-kas',
    name: prop + '.saldo-pengelolaan-kas',
    component: BiosSaldoPengelolaanKas
  },
  {
    path: 'saldo-dana-kelolaan',
    name: prop + '.saldo-dana-kelolaan',
    component: BiosSaldoDanaKelolaan
  },
  {
    path: 'pengeluaran',
    name: prop + '.pengeluaran',
    component: BiosPengeluaran
  },
  {
    path: 'penerimaan',
    name: prop + '.penerimaan',
    component: BiosPenerimaan
  },
  {
    path: 'visite-dokter',
    name: prop + '.visite-dokter',
    component: BiosKepatuhanWaktuVisite
  },
  {
    path: 'visite-pasien',
    name: prop + '.visite-pasien',
    component: BiosIKTJumlahVisitePasien
  },
  {
    path: 'visite-pasien-12',
    name: prop + '.visite-pasien-12',
    component: BiosIKTJumlahVisitePasien12
  },
  {
    path: 'dpjp-tidak-visite',
    name: prop + '.dpjp-tidak-visite',
    component: BiosIKTJumlahDpjpTdkVisite
  },
  {
    path: 'rasio-pobo',
    name: prop + '.rasio-pobo',
    component: BiosIKTRasioPobo
  },
  {
    path: 'visite-pasien-pertama',
    name: prop + '.visite-pasien-pertama',
    component: BiosIKTJumlahVisitePasienPertama
  },
  {
    path: 'kepuasaan-pasien',
    name: prop + '.kepuasaan-pasien',
    component: BiosIKTKepuasanPasien
  },
  {
    path: 'waktu-tunggu-irj',
    name: prop + '.waktu-tunggu-irj',
    component: BiosIKTWaktuTungguIRJ
  },
  {
    path: 'penyelenggara-rme',
    name: prop + '.penyelenggara-rme',
    component: BiosIKTPenyelenggaraanRme
  },
  {
    path: 'penggunaan-alat',
    name: prop + '.penggunaan-alat',
    component: BiosIKTKepatuhanPenggunaanApd
  },
  {
    path: 'penundaan-operasi',
    name: prop + '.penundaan-operasi',
    component: BiosIKTPenundaanOperasi
  },
  {
    path: 'kepatuhan-terhadap-alur',
    name: prop + '.kepatuhan-terhadap-alur',
    component: BiosIKTKepatuhanTerhadapAlurKlinis
  },
  {
    path: 'kepatuhan-penggunaan-formularium',
    name: prop + '.kepatuhan-penggunaan-formularium',
    component: BiosIKTKepatuhanPenggunaanFormularium
  },
  {
    path: 'kepatuhan-waktu-visite-DPJP',
    name: prop + '.kepatuhan-waktu-visite-DPJP',
    component: BiosIKTKepatuhanWaktuVisiteDPJP
  },
  {
    path: 'kepatuhan-pelaksanaan-protokol-kesehatan',
    name: prop + '.kepatuhan-pelaksanaan-protokol-kesehatan',
    component: BiosIKTKepatuhanPelaksanaanProtokolKesehatan
  },
  {
    path: 'pembelian-alkes',
    name: prop + '.pembelian-alkes',
    component: BiosIKTPersentasePembelianAlkes
  },
  {
    path: 'kepatuhan-identifikasi-pasien',
    name: prop + '.kepatuhan-identifikasi-pasien',
    component: BiosIKTKepatuhanIdentifikasiPasien
  },
  {
    path: 'kecepatan-waktu-tanggap',
    name: prop + '.kecepatan-waktu-tanggap',
    component: BiosIKTKecepatanWaktuTanggapKomplain
  },
  {
    path: 'laporan-hasil-kritis',
    name: prop + '.laporan-hasil-kritis',
    component: BiosIKTPelaporanHasilKritisLab
  },
  {
    path: 'operasi-seksio',
    name: prop + '.operasi-seksio',
    component: BiosIKTWaktuTanggapOperasiSeksio
  },
  {
    path: 'resiko-jatuh',
    name: prop + '.resiko-jatuh',
    component: BiosIKTKepatuhanResikoPasienJatuh
  },
  {
    path: 'realisasi-pendapatan-blu',
    name: prop + '.realisasi-pendapatan-blu',
    component: BiosIKTPertumbuhanRealisasiPendapatanBlu
  },
  {
    path: 'jumlah-visite-10',
    name: prop + '.jumlah-visite-10',
    component: BiosIKTJumlahVisite10
  },
  {
    path: 'kepatuhan-kebersihan-tangan',
    name: prop + '.kepatuhan-kebersihan-tangan',
    component: BiosIKTKepatuhanKebersihanTangan
  },
  {
    path: 'bor',
    name: prop + '.bor',
    component: BiosLayananBor
  },
  {
    path: 'toi',
    name: prop + '.toi',
    component: BiosLayananToi
  },
  {
    path: 'alos',
    name: prop + '.alos',
    component: BiosLayananAlos
  },
  {
    path: 'bto',
    name: prop + '.bto',
    component: BiosLayananBto
  }
]

const eklaimChildRoutes = (prop) => [
  {
    path: '/list',
    name: prop + '.list',
    component: EklaimList
  },
  {
    path: 'add',
    name: prop + '.add',
    component: EklaimAdd
  },
  {
    path: 'vlist',
    name: prop + '.vlist',
    component: EklaimMonitor
  },
  {
    path: 'vview/:id',
    name: prop + '.vview',
    component: EklaimMonitorView
  },
  {
    path: 'importdata',
    name: prop + '.importdata',
    component: ImportData
  }
]

const ruanganChildRoutes = (prop) => [
  {
    path: 'list',
    name: prop + '.list',
    component: masterRuangan
  }
]

// laboratorium
const jadwalCathlabChildRoutes = (prop) => [
  {
    path: 'list',
    name: prop + '.list',
    component: JadwalList
  },
  {
    path: 'add',
    name: prop + '.add',
    component: JadwalAdd
  },
  {
    path: 'view/:id',
    name: prop + '.view',
    component: JadwalView
  },
  {
    path: 'dash',
    name: prop + '.dash',
    component: JadwalDash
  }
]
// public page or singel page or dashboard
const logchildRoutes = (prop) => [
  {
    path: '/reprocess',
    name: prop + '.reprocess',
    component: LogFile
  }
]

const sdmchildRoutes = (prop) => [
  {
    path: '/penugasan',
    name: prop + '.penugasan',
    component: Penugasan
  },
  //
  {
    path: '/remun',
    name: prop + '.remun',
    component: DetailRemun
  },
  {
    path: '/remundetail',
    name: prop + '.remundetail',
    component: IndexRemunerasi
  },
  {
    path: '/remundetailrekap/:id',
    name: prop + '.remundetailrekap',
    component: DetailRemunRekap
  },
  //
  {
    path: '/remun-v2',
    name: prop + '.remun-v2',
    component: DetailRemunV2
  },
  {
    path: '/remundetail-v2',
    name: prop + '.remundetail-v2',
    component: IndexRemunerasiV2
  },
  {
    path: '/remundetailrekap-v2',
    name: prop + '.remundetailrekap-v2',
    component: DetailRemunRekapV2
  },
  //
  {
    path: '/ksmpegawai',
    name: prop + '.ksmpegawai',
    component: KsmPegawai
  },
  {
    path: '/ksmpegawaigrup',
    name: prop + '.ksmpegawaigrup',
    component: KsmPegawaiGrup
  },
  //
  {
    path: '/remun-transaksi',
    name: prop + '.remun-transaksi',
    component: RemunTrans
  },
  {
    path: '/remun-transaksi-v2',
    name: prop + '.remun-transaksi-v2',
    component: RemunTransV2
  },
  //
  {
    path: '/e-kinerja',
    name: prop + '.e-kinerja',
    component: Ekinerja
  },
  {
    path: '/e-kinerja-dashobard',
    name: prop + '.e-kinerja-dashobard',
    component: EkinerjaDashboard
  }
]

const farmasichildRoutes = (prop) => [
  {
    path: '/inventoripinjam',
    name: prop + '.inventoripinjam',
    component: FarmasiList
  },
  {
    path: '/inventoripengembalian',
    name: prop + '.inventoripengembalian',
    component: FarmasiListPengembalian
  },
  {
    path: '/stockopname',
    name: prop + '.stockopname',
    component: StockOpnameList
  },
  {
    path: '/stockopnameview/:id',
    name: prop + '.stockopnameview',
    component: StockOpnameView
  },
  {
    path: '/stockopnamesee/:id',
    name: prop + '.stockopnamesee',
    component: StockOpnameSee
  },
  {
    path: '/pending',
    name: prop + '.pending',
    component: PendingFarmasiList
  },
  {
    path: '/pendingtambah/:id',
    name: prop + '.pendingtambah',
    component: PendingFarmasiTambah
  },
  {
    path: '/penjualan',
    name: prop + '.penjualan',
    component: PenjualanRetur
  },
  {
    path: '/penjualan-list',
    name: prop + '.penjualan-list',
    component: PenjualanList
  }
]

const rumahTanggachildRoutes = (prop) => [
  {
    path: '/penerimaan',
    name: prop + '.penerimaan',
    component: Penerimaan
  },
  {
    path: '/penerimaan/detail',
    name: prop + '.penerimaan-detail',
    component: PenerimaanDetail
  },
  {
    path: '/rumah-tangga-kemasan',
    name: prop + '.rumah-tangga-kemasan',
    component: RumahTanggaKemasan
  },
  {
    path: '/rumah-tangga-kelompok',
    name: prop + '.rumah-tangga-kelompok',
    component: RumahTanggaKelompok
  },
  {
    path: '/rumah-tangga-rekanan',
    name: prop + '.rumah-tangga-rekanan',
    component: RumahTanggaRekanan
  },
  {
    path: '/rumah-tangga-jenis-anggaran',
    name: prop + '.rumah-tangga-jenis-anggaran',
    component: RumahTanggaJenisAnggaran
  },
  {
    path: '/rumah-tangga',
    name: prop + '.rumah-tangga',
    component: RumahTangga
  }
]

const appChildRoute = (prop, mode = false) => [
  {
    path: 'chat',
    name: prop + '.chat',
    meta: { dark: mode, auth: true, name: 'Chat' },
    component: ChatIndex
  }
]

const utdChildRoutes = (prop) => [
  {
    path: 'list',
    name: prop + '.list',
    component: UtdList
  },
  {
    path: 'pendaftaran/:id',
    name: prop + '.pendaftaran',
    component: UtdPendaftaran
  },
  {
    path: 'pemeriksaan/:id',
    name: prop + '.pemeriksaan',
    component: UtdPemeriksaan
  },
  {
    path: 'dokter/:id',
    name: prop + '.dokter',
    component: UtdDokter
  }
]

const mcuChildRoutes = (prop) => [
  {
    path: 'list',
    name: prop + '.list',
    component: McuList
  },
  {
    path: 'view/:id',
    name: prop + '.view',
    component: McuView
  }
]

const meningitisChildRoutes = (prop) => [
  {
    path: 'list',
    name: prop + '.list',
    component: MeningitisList
  },
  {
    path: 'view/:id',
    name: prop + '.view',
    component: MeningitisView
  }
]

const masterliburnasionalChildRoutes = (prop) => [
  {
    path: 'list',
    name: prop + '.list',
    component: LiburNasionalIndex
  }
]

const masterliburdokterChildRoutes = (prop) => [
  {
    path: 'list',
    name: prop + '.list',
    component: LiburDokterIndex
  }
]

const masterliburexecutiveChildRoutes = (prop) => [
  {
    path: 'list',
    name: prop + '.list',
    component: LiburExecutiveIndex
  }
]

const masterliburirmChildRoutes = (prop) => [
  {
    path: 'list',
    name: prop + '.list',
    component: LiburIrmIndex
  }
]

const masterliburexcludeRoutes = (prop) => [
  {
    path: 'list',
    name: prop + '.list',
    component: LiburExcludeindex
  }
]

const irjChildRoutes = (prop) => [
  {
    path: 'list',
    name: prop + '.list',
    component: IrjList
  }
]

const ighChildRoutes = (prop) => [
  {
    path: 'list',
    name: prop + '.list',
    component: IghList
  }
]

const irmChildRoutes = (prop) => [
  {
    path: 'list',
    name: prop + '.list',
    component: IrmList
  }
]

const memberListChildRoutes = (prop) => [
  {
    path: 'list',
    name: prop + '.list',
    component: AnggotaList
  },
  {
    path: 'list-akun',
    name: prop + '.list-akun',
    component: AkunList
  }
]

const LaporanChildRoutes = (prop) => [
  {
    path: 'instalasi',
    name: prop + '.instalasi',
    component: LaporanInstalasiindex
  }
]

const medicalRecordChildRoutes = (prop) => [
  {
    path: 'medicalrecord/:id',
    name: prop + '.medicalrecord',
    component: MedicalRecord
  }
]

const dashchildRoutes = (prop) => [
  {
    path: 'dirut',
    name: prop + '.dirut',
    component: DashboardDirut
  },
  {
    path: 'sdm',
    name: prop + '.sdm',
    component: DashboardSDM
  },
  {
    path: 'real',
    name: prop + '.real',
    component: DashboardReal
  },
  {
    path: 'db',
    name: prop + '.db',
    component: DatabaseWrite
  },
  {
    path: 'dbread',
    name: prop + '.dbread',
    component: DatabaseRead
  }
]

const publicchildRoutes = (prop) => [
  {
    path: 'bpjskepesertaan',
    name: prop + '.bpjskepesertaan',
    component: BpjsKepesertaanAdmin
  },
  {
    path: 'reservasioffline',
    name: prop + '.reservasioffline',
    component: ReservasiOffline
  },
  {
    path: 'reservasiofflinevaksin',
    name: prop + '.reservasiofflinevaksin',
    component: ReservasiMeningitisOffline
  },
  {
    path: 'reservasiofflinev2',
    name: prop + '.reservasiofflinev2',
    component: ReservasiOfflineNew
  }
]

// master remunerasi
const masterRemunKegiatanKlp = (prop) => [
  {
    path: 'list',
    name: prop + '.list',
    component: RemunKegiatanKlpIndex
  }
]

const masterRemunKegiatanExclude = (prop) => [
  {
    path: 'list',
    name: prop + '.list',
    component: RemunKegiatanExcludeIndex
  }
]

const masterRemunKegiatanTarif = (prop) => [
  {
    path: 'list',
    name: prop + '.list',
    component: RemunKegiatanTarifIndex
  }
]

const masterRemunPersentase = (prop) => [
  {
    path: 'list',
    name: prop + '.list',
    component: RemunPersentaseIndex
  }
]

const integrasiChildRoutes = (prop) => [
  {
    path: 'sisrute',
    name: prop + '.sisrute',
    component: Sisrute
  },
  {
    path: 'sihepi',
    name: prop + '.sihepi',
    component: Sihepi
  },
  {
    path: 'bpjs',
    name: prop + '.bpjs',
    component: Bpjs
  }
]

const penjadwalanChildRoutes = (prop) => [
  {
    path: 'operasi',
    name: prop + '.operasi',
    component: PenjadwalanOperasi
  },
  {
    path: 'kalender',
    name: prop + '.kalender',
    component: KalenderOperasi
  }
]

const routes = [
  {
    path: '/dashboard',
    name: 'dashboard',
    component: Layout1,
    children: homeChildRoutes('dashboard'),
    meta: { dashboard: true }
  },
  {
    path: '/remunkegiatanklp',
    name: 'remunkegiatanklp',
    component: Layout1,
    children: masterRemunKegiatanKlp('remunkegiatanklp')
  },
  {
    path: '/remunkegiatanexclude',
    name: 'remunkegiatanexclude',
    component: Layout1,
    children: masterRemunKegiatanExclude('remunkegiatanexclude')
  },
  {
    path: '/remunkegiatantarif',
    name: 'remunkegiatantarif',
    component: Layout1,
    children: masterRemunKegiatanTarif('remunkegiatantarif')
  },
  {
    path: '/remunpersentase',
    name: 'remunpersentase',
    component: Layout1,
    children: masterRemunPersentase('remunpersentase')
  },
  {
    path: '/dash',
    name: 'dash',
    component: Layout1,
    children: dashchildRoutes('dash')
  },
  {
    path: '/public',
    name: 'public',
    component: Layout1,
    children: publicchildRoutes('public')
  },
  {
    path: '/auth',
    name: 'auth1',
    component: AuthLayout1,
    children: authChildRoutes('auth1')
  },
  {
    path: '/log',
    name: 'log',
    component: Layout1,
    children: logchildRoutes('log')
  },
  {
    path: '/sdm',
    name: 'sdm',
    component: Layout1,
    children: sdmchildRoutes('sdm')
  },
  {
    path: '/farmasi',
    name: 'farmasi',
    component: Layout1,
    children: farmasichildRoutes('farmasi')
  },
  {
    path: '/rumahtangga',
    name: 'rumahtangga',
    component: Layout1,
    children: rumahTanggachildRoutes('rumahtangga')
  },
  {
    path: '/pengguna',
    name: 'pengguna',
    component: Layout1,
    children: penggunaChildRoutes('pengguna')
  },
  {
    path: '/user',
    name: 'user',
    component: Layout1,
    children: userChildRoute('user')
  },
  {
    path: '/eklaim',
    name: 'eklaim',
    component: Layout1,
    children: eklaimChildRoutes('eklaim')
  },
  {
    path: '/kuota',
    name: 'kuota',
    component: Layout1,
    children: quotaChildRoutes('kuota')
  },
  {
    path: '/tarif',
    name: 'tarif',
    component: Layout1,
    children: mastertarifChildRoutes('tarif')
  },
  {
    path: '/liburnasional',
    name: 'liburnasional',
    component: Layout1,
    children: masterliburnasionalChildRoutes('liburnasional')
  },
  {
    path: '/liburdokter',
    name: 'liburdokter',
    component: Layout1,
    children: masterliburdokterChildRoutes('liburdokter')
  },
  {
    path: '/liburexecutive',
    name: 'liburexecutive',
    component: Layout1,
    children: masterliburexecutiveChildRoutes('liburexecutive')
  },
  {
    path: '/liburirm',
    name: 'liburirm',
    component: Layout1,
    children: masterliburirmChildRoutes('liburirm')
  },
  {
    path: '/liburnasionalexclude',
    name: 'liburnasionalexclude',
    component: Layout1,
    children: masterliburexcludeRoutes('liburnasionalexclude')
  },
  {
    path: '/tarifremun',
    name: 'tarifremun',
    component: Layout1,
    children: mastertarifremunChildRoutes('tarifremun')
  },
  {
    path: '/katalog',
    name: 'katalog',
    component: Layout1,
    children: katalogChildRoutes('katalog')
  },
  {
    path: '/sakti',
    name: 'sakti',
    component: Layout1,
    children: saktiChildRoutes('sakti')
  },
  {
    path: '/bios',
    name: 'bios',
    component: Layout1,
    children: biosChildRoutes('bios')
  },
  {
    path: '/mhas',
    name: 'mhas',
    component: Layout1,
    children: mhasChildRoutes('mhas')
  },
  {
    path: '/utd',
    name: 'utd',
    component: Layout1,
    children: utdChildRoutes('utd')
  },
  {
    path: '/mcu',
    name: 'mcu',
    component: Layout1,
    children: mcuChildRoutes('mcu')
  },
  {
    path: '/meningitis',
    name: 'meningitis',
    component: Layout1,
    children: meningitisChildRoutes('meningitis')
  },
  {
    path: '/irj',
    name: 'irj',
    component: Layout1,
    children: irjChildRoutes('irj')
  },
  {
    path: '/igh',
    name: 'igh',
    component: Layout1,
    children: ighChildRoutes('igh')
  },
  {
    path: '/irm',
    name: 'irm',
    component: Layout1,
    children: irmChildRoutes('irm')
  },
  {
    path: '/member',
    name: 'member',
    component: Layout1,
    children: memberListChildRoutes('member')
  },
  {
    path: '/pegawai',
    name: 'pegawai',
    component: Layout1,
    children: pegawaiChildRoutes('pegawai')
  },
  {
    path: '/pasien',
    name: 'pasien',
    component: Layout1,
    children: pasienChildRoutes('pasien')
  },
  {
    path: '/pendaftaran',
    name: 'pendaftaran',
    component: Layout1,
    children: pendaftaranChildRoutes('pendaftaran')
  },
  {
    path: '/module',
    name: 'module',
    component: Layout1,
    children: moduleChildRoutes('module')
  },
  {
    path: '/role',
    name: 'role',
    component: Layout1,
    children: roleChildRoutes('role')
  },
  {
    path: '/menumodule',
    name: 'menumodule',
    component: Layout1,
    children: menumoduleChildRoutes('menumodule')
  },
  {
    path: '/menu',
    name: 'menu',
    component: Layout1,
    children: menuChildRoutes('menu')
  },
  {
    path: '/barang',
    name: 'barang',
    component: Layout1,
    children: barangChildRoutes('barang')
  },
  {
    path: '/persediaan',
    name: 'persediaan',
    component: Layout1,
    children: persediaanChildRoutes('persediaan')
  },
  {
    path: '/laboratorium',
    name: 'laboratorium',
    component: Layout1,
    children: jadwalCathlabChildRoutes('laboratorium')
  },
  {
    path: '/ruangan',
    name: 'ruangan',
    component: Layout1,
    children: ruanganChildRoutes('ruangan')
  },
  {
    path: '/laporan',
    name: 'laporan',
    component: Layout1,
    children: LaporanChildRoutes('laporan')
  },
  {
    path: '/integrasi',
    name: 'integrasi',
    component: Layout1,
    children: integrasiChildRoutes('integrasi')
  },
  {
    path: '/medicalrecord',
    name: 'medicalrecord',
    component: Layout1,
    children: medicalRecordChildRoutes('medicalrecord')
  },
  {
    path: '/penjadwalan',
    name: 'penjadwalan',
    component: Layout1,
    children: penjadwalanChildRoutes('penjadwalan')
  },
  // public page or singel page or dashboard
  {
    path: '/',
    name: 'welcome',
    component: Welcome
  },
  {
    path: '/bpjs/kepesertaan',
    name: 'bpjsKepesertaan',
    component: BpjsKepesertaan
  },
  {
    path: '/dashKlaim',
    name: 'DashboardKlaim',
    component: DashboardKlaim
  },
  {
    path: '/reservasi-utd',
    name: 'reservasi-utd',
    component: ReservasiUtd
  },
  {
    path: '/reservasi-meningitis-online',
    name: 'reservasi-meningitis-online',
    component: ReservasiMeningitisOnline
  },
  {
    path: '/reservasi-irj',
    name: 'reservasi-irj',
    component: ReservasiOnlineRawatJalan
  },
  {
    path: '/reservasi-online',
    name: 'reservasi-online',
    component: ReservasiOnline
  },
  {
    path: '/error',
    name: 'error',
    component: ErrorPage
  },
  {
    path: '/apm',
    name: 'apm',
    component: AnjunganPendaftaranMandiri
  },
  {
    path: '/apm-irj',
    name: 'apm-irj',
    component: AnjunganPendaftaranMandiriDtl
  },
  {
    path: '/apm-igh',
    name: 'apm-igh',
    component: AnjunganPendaftaranMandiriDtlIGH
  },
  {
    path: '/authNew',
    name: 'authNew',
    component: AuthLayout1New
  },
  {
    path: '/apm-irj-sep',
    name: 'apm-irj-sep',
    component: ApmIrjSep
  },
  {
    path: '/apm-irj-sep-v2',
    name: 'apm-irj-sep-v2',
    component: ApmIrjSepv2
  },
  {
    path: '/antrian',
    name: 'antrian',
    component: DashboardAntrian
  },
  {
    path: '/app',
    name: 'app',
    component: Layout1,
    meta: { auth: true },
    children: appChildRoute('app')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.VUE_APP_BASE_URL,
  routes
})

router.beforeEach(async (to, from, next) => {
  const access = await routerAlredy(to.name)
  if (!access) next({ name: 'error' })
  else next()
})

const routerAlredy = (toRoute) => {
  let routerAll = ['auth1.signin', 'auth1.signin-new', 'authNew', 'welcome', 'error', 'apm', 'apm-irj', 'apm-igh', 'apm-irj-sep', 'apm-irj-sep-v2', 'antrian', 'user.profile', 'user.edit']
  if (localStorage.getItem('module') !== null) {
    jwtdecode(localStorage.getItem('module')).module.permissions.map(el => {
      if (el.link !== '') {
        routerAll.push(el.link.name)
      } else {
        if (el.children !== undefined) {
          el.children.map(ell => {
            routerAll.push(ell.link.name)
          })
        }
      }
    })
  }
  const result = routerAll.find(el => el === toRoute)
  if (!result) {
    const Address = toRoute.substring(0, toRoute.indexOf('.'))
    const Addr = routerAll.toString().split(/[,.]/)
    const res = Addr.find(el => el === Address)
    return res
  } else {
    return result
  }
}

export default router
