<template>
  <div id="list-pengguna" class="list-pengguna">
  <b-container fluid>
    <b-row>
      <b-col md="3">
        <iq-card class="calender-small">
          <template v-slot:body>
            <flat-pickr :config="config" value="" class="d-none"/>
          </template>
        </iq-card>
        <iq-card>
          <template v-slot:headerTitle>
            <h4 class="card-title ">Classification</h4>
          </template>
          <template v-slot:headerAction>
            <a href="#"><i class="fa fa-plus  mr-0" aria-hidden="true" /></a>
          </template>
          <template v-slot:body>
            <ul class="m-0 p-0 job-classification">
              <li class=""><i class="ri-check-line bg-info" />Umum</li>
              <li class=""><i class="ri-check-line bg-success" />pegawai</li>
              <li class=""><i class="ri-check-line bg-warning" />MPK</li>
            </ul>
          </template>
        </iq-card>
      </b-col>
      <b-col md="9">
        <iq-card>
          <template v-slot:headerTitle>
            <h4 class="card-title">Kuota MCU</h4>
          </template>
          <template v-slot:headerAction>
            <b-button variant="primary" class="py-2" style="border-radius: 5px;"  @click="handleNewKuota"><i class="fas fa-plus-circle "></i>Tambah</b-button>
          </template>
          <template v-slot:body>
            <FullCalendar :calendarEvents="mappedEvents" @eventClick="handleDateClick" @handleEventDrop="handleEventDrop" />
          </template>
        </iq-card>
      </b-col>
    </b-row>
  </b-container>
  <b-modal id="modal-akses-group" ref="modal-pengguna" size="md" :title="`${title} kuota`" ok-title="Oke" :ok-only=true @ok="submit" @cancel="clear" @close="clear" :no-close-on-backdrop="true" :static="true" centered>
    <b-row class="mb-2">
      <b-col>
        <b-row class="align-items-center">
          <b-col cols="12" class="pr-1 mb-2">
            <b-form-group label="Jenis" class="mb-0">
              <template>
                <multiselect v-model="selected.jenisMCU" :show-labels="false" :limit="3" track-by="deskripsi" label="deskripsi" placeholder="[ Masukan Jenis ]" :options="option.jenisMCU" @input="valJenis">
                </multiselect>
              </template>
            </b-form-group>
          </b-col>
          <b-col cols="12" class="pr-1 mb-2">
            <b-form-group label="Tanggal" class="mb-0">
              <template>
                <b-form-input id="exampleInputdate" v-model="formik.tanggal" type="date"></b-form-input>
              </template>
            </b-form-group>
          </b-col>
          <b-col cols="12" class="pr-1 mb-2">
            <b-form-group label="Total" class="mb-0">
              <template>
                <b-form-input id="exampleInputdate" v-model="formik.total" type="number"></b-form-input>
              </template>
            </b-form-group>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </b-modal>
  <ModalMsgBox ref="MsgBox"/>
  </div>
</template>
<script>
import { xray } from '../../../config/pluginInit'
// import Service from '../../../services/fatmahost/index'
import Master from '../../../services/fatmahost/master/index'
import ModalMsgBox from '../../../components/modal/ModalMsgBox'
import Multiselect from 'vue-multiselect'
import flatPickr from 'vue-flatpickr-component'
import { Indonesian as IndonesianLocale } from 'flatpickr/dist/l10n/id.js'
import 'flatpickr/dist/flatpickr.css'
export default {
  name: 'GoogleCalendar',
  components: { ModalMsgBox, Multiselect, flatPickr
  },
  data () {
    return {
      title: '',
      setIdEdit: null,
      config: {
        dateFormat: 'Y-m-d',
        inline: true
      },
      option: {
        jenisMCU: []
      },
      selected: {
        jenisMCU: []
      },
      dataKuota: [
        {
          id: 1,
          nama: 'All Day Event',
          tanggal: '2024-09-07',
          jenis: '1',
          total: '50',
          desc_jenis: 'Umum'
        },
        {
          id: 2,
          nama: 'Long Event',
          tanggal: '2024-09-07',
          jenis: '2',
          total: '20',
          desc_jenis: 'Pegawai'
        },
        {
          id: 3,
          nama: 'Long Event',
          tanggal: '2024-09-08',
          jenis: '3',
          total: '10',
          desc_jenis: 'MPK'
        }
      ],
      formik: {
        object: 'kuota-mcu',
        jenis: '',
        tanggal: '',
        total: ''
      },
      configTgl: {
        locale: IndonesianLocale,
        min: new Date().toISOString().split('T')[0],
        minDate: 'today',
        disableMobile: true,
        dateFormat: 'd-m-Y'
      },
      display: {
        tanggal: ''
      }
    }
  },
  mounted () {
    xray.index()
    this.getjenisMCU()
  },
  computed: {
    mappedEvents () {
      return this.dataKuota.map(event => {
        let color
        let title
        switch (event.jenis) {
          case '1':
            color = '#17a2b8'
            title = `UMUM : ${event.total}`
            break
          case '2':
            color = '#28a745'
            title = `PEGAWAI: ${event.total}`
            break
          default:
            color = '#fd7e14'
            title = `MPK: ${event.total}`
        }
        return {
          id: event.id,
          title: title,
          start: event.tanggal,
          color: color,
          jumlah: event.total,
          tanggal: event.tanggal,
          jenis: event.jenis,
          desc_jenis: event.desc_jenis
        }
      })
    }
  },
  methods: {
    handleDateClick (event) {
      this.title = 'Edit'
      this.setIdEdit = event.id
      this.formik.total = event.extendedProps.jumlah
      this.formik.tanggal = event.extendedProps.tanggal
      this.selected.jenisMCU = { id: '1', deskripsi: event.extendedProps.desc_jenis }
      this.$refs['modal-pengguna'].show()
    },
    handleEventDrop (event) {
      // console.log('Event dropped:', event.event)
      // const updatedEvent = {
      //   ...event.event.extendedProps,
      //   start: event.event.start.toISOString(),
      //   end: event.event.end ? event.event.end.toISOString() : null
      // }
      // console.log('Updated event:', updatedEvent)
      // You may need to send the updated data to your server here
    },
    handleNewKuota () {
      this.title = 'Tambah'
      this.$refs['modal-pengguna'].show()
    },
    valTglChange (val) {
      let date = new Date(val)
      let year = date.getFullYear()
      let month = date.getMonth() + 1
      let day = date.getDate()
      if (month < 10) {
        month = '0' + month
      }
      if (day < 10) {
        day = '0' + day
      }
      let joinDate = year + '-' + month + '-' + day
      this.formik.tanggal = joinDate
    },
    async getjenisMCU () {
      await Master.referensi('903001').then(response => {
        if (response.status === 200) {
          // console.log(response)
          this.option.jenisMCU = response.data.data
        }
      }).catch(error => {
        this.error = error.response
      })
    },
    valJenis (param) {
      // console.log(param)
    },
    async submit () {
      if (this.title === 'Edit') {
        // const value = {
        //   updated_by: 1,
        //   ...this.formik
        // }
        // console.log(value)
      } else {
        // const value = {
        //   created_by: 1,
        //   ...this.formik
        // }
        // console.log(value)
      }
    },
    clear () {
      this.formik.jenis = ''
      this.formik.tanggal = ''
      this.formik.total = ''
    }
  }
}
</script>
