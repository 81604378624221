<template>
  <div id="bios-saldo">
    <div v-show="isLoading">
      <div id="show-overlay"></div>
      <Loader :style="{ opacity: .5 }" />
    </div>
    <b-container fluid>
      <div>
        <iq-card>
          <template v-slot:headerTitle>
            <h4 class="card-title font-weight-bold">BIOS Saldo</h4>
          </template>
          <template v-slot:body>
            <div class="d-flex flex-row align-items-center mb-3">
              <div class="mr-auto">
                <b-form-radio-group v-model="options.indexRiwayat" :options="options.optionsSaldo" text-field="name" value-field="item"></b-form-radio-group>
              </div>
              <div class="text-right">
                <b-button variant="primary" @click="onShowInput"><i class="fas fa-plus"></i>Tambah</b-button>
              </div>
            </div>
            <table class="table table-striped table-sm mb-0">
              <div class="table" v-if="options.indexRiwayat === 'RPK'">
                <b-table bordered striped hover :busy="table.isBusy" ref="table" :items="table.itemsRPK" :fields="table.fieldsRPK"
                :current-page="table.currentPage">
                <template v-slot:cell(tgl_transaksi)="data">
                  <span>{{ data.item.tgl_transaksi | formatTgl }}</span>
                </template>
                <template v-slot:cell(no_bilyet)="data">
                  <span>{{ data.item.no_bilyet | money }}</span>
                </template>
                <template v-slot:cell(nilai_deposito)="data">
                  <span>{{ data.item.nilai_deposito | money }}</span>
                </template>
                <template v-slot:cell(nilai_bunga)="data">
                  <span>{{ data.item.nilai_bunga | money }}</span>
                </template>
                </b-table>
              </div>
              <div class="table" v-else-if="options.indexRiwayat === 'RO'">
                <b-table bordered striped hover :busy="table.isBusy" ref="table" :items="table.itemsRO" :fields="table.fieldsRO"
                :current-page="table.currentPage">
                <template v-slot:cell(saldo_akhir)="data">
                  <span>{{ data.item.saldo_akhir | money }}</span>
                </template>
                <template v-slot:cell(tgl_transaksi)="data">
                  <span>{{ data.item.tgl_transaksi | formatTgl }}</span>
                </template>
                </b-table>
              </div>
              <div class="table" v-else-if="options.indexRiwayat === 'RDK'">
                <b-table bordered striped hover :busy="table.isBusy" ref="table" :items="table.itemsRDK" :fields="table.fieldsRDK" :current-page="table.currentPage">
                <template v-slot:cell(saldo_akhir)="data">
                  <span>{{ data.item.saldo_akhir | money }}</span>
                </template>
                <template v-slot:cell(tgl_transaksi)="data">
                  <span>{{ data.item.tgl_transaksi | formatTgl }}</span>
                </template>
                </b-table>
              </div>
            </table>
            <div class="pagination-bios border shadow-sm p-2 mb-2">
              <b-row class="align-items-center">
                <b-col cols="6" class="d-flex flex-row align-items-center mr-auto w-100">
                  <div class="w-100">
                    <b-pagination
                      v-model="table.currentPage"
                      :total-rows="table.totalRows"
                      class="my-0"
                    ></b-pagination>
                  </div>
                  <div class="w-100">
                    <div>
                      <span class="mr-3">|</span>
                      <span @click="tableRefresh" class="bios-cursor">
                        <i class="fas fa-sync text-primary"></i>
                      </span>
                      <span class="ml-3">|</span>
                    </div>
                  </div>
                </b-col>
                <b-col cols="3">
                  <div class="d-flex flex-row align-items-center justify-content-end w-100">
                    <div>
                      <p class="small text-dark mb-0">Menampilkan {{ table.totalRows }} baris</p>
                    </div>
                  </div>
                </b-col>
              </b-row>
            </div>
          </template>
        </iq-card>
      </div>
      <div>
        <b-modal id="modal-saldo" ref="modalSaldo" size="xl" title="Saldo" @ok="kirim" @close="batal" @cancel="batal" :no-close-on-backdrop="true" :static="true" ok-title="Kirim" cancel-title="Tutup">
          <template>
            <div class="mb-3">
              <div class="d-flex flex-row align-items-center">
                <div class="mr-3">
                  <p class="mb-0 text-dark">Tanggal :</p>
                </div>
                <div>
                  <flat-pickr @on-change="getDate" v-model="tgl_transaksi" :config="config"
                    class="w-100 calender-small flatpickr-cstm-lab" placeholder="Pilih Tanggal Kirim">
                  </flat-pickr>
                </div>
              </div>
            </div>
            <div>
              <b-form-radio-group v-model="options.index" :options="options.optionsSaldo"
              value-field="item" text-field="name" class="mb-3"
              ></b-form-radio-group>
            </div>
            <div>
              <table class="table table-bordered table-striped table-sm">
                <thead>
                  <tr class="table-info">
                    <th scope="col">No</th>
                    <th scope="col">Tgl. Transaksi</th>
                    <th scope="col">Kode Bank</th>
                    <th scope="col">No. Rekening</th>
                    <th scope="col">Nama Rekening</th>
                    <th scope="col">Saldo</th>
                    <th scope="col" v-if="options.index === 'RPK'">Billing</th>
                    <th scope="col" v-if="options.index === 'RPK'">Bunga</th>
                  </tr>
                </thead>
                <tbody v-if="options.index === 'RPK'">
                  <tr v-for="(d, index) in tampungDataRPK" :key="d.id_bios_m_rekening">
                    <th scope="row">{{ index + 1 }}</th>
                    <td style="padding: 5px;">{{ d.tanggal }}</td>
                    <td>{{ d.kd_bank }}</td>
                    <td>{{ d.norek }}</td>
                    <td>{{ d.nama_rekening }}</td>
                    <td><b-input-group prepend="Rp."><b-form-input class="cstm-cntrl-bios" v-model="previewJumlahDeposito[index].nilai_deposito" @input="inputDataDeposito($event, d.kd_bank, index)" @keypress="isNumberKey"></b-form-input></b-input-group></td>
                    <td><b-input-group prepend="Rp."><b-form-input class="cstm-cntrl-bios" v-model="previewJumlahBill[index].no_bilyet" @input="inputDataBill($event, index)" @keypress="isNumberKey"></b-form-input></b-input-group></td>
                    <td><b-input-group prepend="Rp."><b-form-input class="cstm-cntrl-bios" v-model="previewJumlahBunga[index].nilai_bunga" @input="inputDataBunga($event, index)" @keypress="isNumberKey"></b-form-input></b-input-group></td>
                  </tr>
                </tbody>
                <tbody v-if="options.index === 'RO'">
                  <tr v-for="(d, index) in tampungDataRO" :key="d.id_bios_m_rekening">
                    <th scope="row">{{ index + 1 }}</th>
                    <td style="padding: 5px;">{{ d.tanggal }}</td>
                    <td>{{ d.kd_bank }}</td>
                    <td>{{ d.norek }}</td>
                    <td>{{ d.nama_rekening }}</td>
                    <td><b-input-group prepend="Rp.">
                      <b-form-input class="cstm-cntrl-bios" v-model="previewJumlahRO[index].saldo_akhir" @keypress="isNumberKey" @input="inputDataRO($event, d.kd_bank, d.norek, d.nama_rekening, index)">
                      </b-form-input></b-input-group>
                    </td>
                  </tr>
                </tbody>
                <tbody v-else-if="options.index === 'RDK'">
                  <tr v-for="(d, index) in tampungDataRDK" :key="d.id_bios_m_rekening">
                    <th scope="row">{{ index + 1 }}</th>
                    <td style="padding: 5px;">{{ d.tanggal }}</td>
                    <td>{{ d.kd_bank }}</td>
                    <td>{{ d.norek }}</td>
                    <td>{{ d.nama_rekening }}</td>
                    <td><b-input-group prepend="Rp."><b-form-input class="cstm-cntrl-bios" v-model="previewJumlahRDK[index].saldo_akhir" @keypress="isNumberKey" @input="inputDataRDK($event, d.kd_bank, d.norek, index)"></b-form-input></b-input-group></td>
                  </tr>
                </tbody>
                <tbody v-else></tbody>
              </table>
            </div>
          </template>
          <!-- <pre v-if="options.index === 'RO'">{{ kirimDataRO }}</pre>
          <pre v-if="options.index === 'RO'">{{ previewJumlahRO }}</pre>
          <pre v-if="options.index === 'RDK'">{{ kirimDataRDK }}</pre>
          <pre v-if="options.index === 'RDK'">{{ previewJumlahRDK }}</pre>
          <pre v-if="options.index === 'RPK'">{{ kirimDataRPK }}</pre>
          <pre v-if="options.index === 'RPK'">{{ previewJumlahDeposito }}</pre> -->
        </b-modal>
      </div>
      <b-modal ref="modal-sukses" size="md" title="Informasi" ok-title="Oke" @ok="reload" :ok-only=true :no-close-on-backdrop="true" :static="true" :hide-header=true centered>
        <div class="text-center px-lg-4 px-2">
          <lottie :options="defaultOptions" :autoPlay="true" :width="250" :height="250" />
          <p class="h6 text-success font-weight-bold mb-0">{{ message }}</p>
        </div>
      </b-modal>
      <b-modal ref="modal-gagal" size="md" title="Informasi" ok-title="Kembali" @ok="reload" :ok-only=true :hide-header=true :no-close-on-backdrop="true" :static="true" centered>
        <div class="text-center px-lg-4 px-2">
          <lottie :options="defaultOptions2" :autoPlay="true" :width="250" :height="250" />
          <p class="h6 text-danger font-weight-bold mb-0">Data Gagal Disimpan</p>
          <p class="text-danger font-weight-light mb-0">{{message}}</p>
        </div>
      </b-modal>
    </b-container>
  </div>
</template>
<script>
import { xray } from '../../../../config/pluginInit'
import FlatPickr from 'vue-flatpickr-component'
import Loader from '../../../../components/xray/loader/Loader'
import BIOS from '../../../../services/fatmahost/bios/index.js'
import Lottie from 'vue-lottie'
import animationLogo from '../../../../assets/lottie/checked.json'
import animationLogo2 from '../../../../assets/lottie/failed.json'

export default {
  name: 'BiosSaldo',
  components: {
    Loader,
    FlatPickr,
    Lottie
  },
  mounted () {
    xray.index()
    this.getAllRO()
    this.getAllRPK()
    this.getAllRDK()
  },
  data () {
    return {
      message: '',
      defaultOptions: { animationData: animationLogo, loop: true },
      defaultOptions2: { animationData: animationLogo2, loop: true },
      isLoading: false,
      totalRows: 1,
      tampungDataRPK: [],
      kirimDataRPK: [],
      tampungDataRO: [],
      kirimDataRO: [],
      tampungDataRDK: [],
      kirimDataRDK: [],
      previewJumlahRO: [],
      previewJumlahRDK: [],
      previewJumlahBill: [],
      previewJumlahBunga: [],
      previewJumlahDeposito: [],
      tgl_transaksi: null,
      config: {
        dateFormat: 'd-m-Y',
        maxDate: new Date().getTime() - 24 * 60 * 60 * 1000
      },
      options: {
        index: 'RPK',
        indexRiwayat: 'RPK',
        optionsSaldo: [
          { item: 'RPK', name: 'Rekening Pengelolaan Kas BLU' },
          { item: 'RO', name: 'Rekening Operasional BLU' },
          { item: 'RDK', name: 'Rekening Dana Kelolaan' }
        ]
      },
      table: {
        fieldsRPK: [
          { label: 'No', key: 'rn', class: 'text-center' },
          { label: 'Kode. Satker', key: 'kdsatker' },
          { label: 'Nama Satker', key: 'nmsatker' },
          { label: 'Tgl. Transaksi', key: 'tgl_transaksi' },
          { label: 'No. Billing', key: 'no_bilyet', class: 'text-right' },
          { label: 'Nilai Deposito', key: 'nilai_deposito', class: 'text-right' },
          { label: 'Nilai Bunga', key: 'nilai_bunga', class: 'text-right' }
        ],
        itemsRPK: this.getRiwayatRPK,
        fieldsRO: [
          { label: 'No', key: 'rn', class: 'text-center' },
          { label: 'Kode. Satker', key: 'kdsatker' },
          { label: 'Nama Satker', key: 'nmsatker' },
          { label: 'Kode Bank', key: 'kdbank' },
          { label: 'No. Rekening', key: 'no_rekening' },
          { label: 'Nama Rekening', key: 'unit' },
          { label: 'Tgl. Transaksi', key: 'tgl_transaksi' },
          { label: 'Saldo Akhir', key: 'saldo_akhir', class: 'text-right' }
        ],
        itemsRO: this.getRiwayatRO,
        fieldsRDK: [
          { label: 'No', key: 'rn', class: 'text-center' },
          { label: 'Kode. Satker', key: 'kdsatker' },
          { label: 'Nama Satker', key: 'nmsatker' },
          { label: 'Kode Bank', key: 'kdbank' },
          { label: 'No. Rekening', key: 'no_rekening' },
          { label: 'Tgl. Transaksi', key: 'tgl_transaksi' },
          { label: 'Saldo Akhir', key: 'saldo_akhir', class: 'text-right' }
        ],
        itemsRDK: this.getRiwayatRDK,
        currentPage: 1,
        isBusy: false,
        totalRows: null
      }
    }
  },
  methods: {
    isNumberKey (evt) {
      evt = (evt) || window.event
      var charCode = (evt.which) ? evt.which : evt.keyCode
      if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
        evt.preventDefault()
      } else {
        return true
      }
    },
    async kirim () {
      let rpk = this.kirimDataRPK
      let ro = this.kirimDataRO
      let rdk = this.kirimDataRDK
      if (this.options.index === 'RPK') {
        for (let i = 0; i < rpk.length; i++) {
          if (rpk[i].tgl_transaksi === '') {
            this.error = 'true'
          }
        }
        if (this.error === 'true') {
          this.message = 'Tanggal Belum di Isi'
          this.$refs['modal-gagal'].show()
          setTimeout(() => {
            this.reload()
          }, 1500)
        } else {
          this.isLoading = true
          try {
            for (let key in rpk) {
              const response = await BIOS.kirimPengelolaan(rpk[key])
              this.message = response.data.message
            }
            this.isLoading = false
            this.$refs['modal-sukses'].show()
          } catch (error) {
            this.isLoading = false
            this.message = error.response
            this.$refs['modal-gagal'].show()
            this.reload()
          }
        }
      } else if (this.options.index === 'RO') {
        for (let i = 0; i < ro.length; i++) {
          if (ro[i].tgl_transaksi === '') {
            this.error = 'true'
          }
        }
        if (this.error === 'true') {
          this.message = 'Tanggal Belum di Isi'
          this.$refs['modal-gagal'].show()
          setTimeout(() => {
            this.reload()
          }, 1500)
        } else {
          this.isLoading = true
          try {
            for (let key in ro) {
              const response = await BIOS.kirimOperasional(ro[key])
              this.message = response.data.message
            }
            this.isLoading = false
            this.$refs['modal-sukses'].show()
          } catch (error) {
            this.isLoading = false
            this.message = error.response
            this.$refs['modal-gagal'].show()
            this.reload()
          }
        }
      } else if (this.options.index === 'RDK') {
        for (let i = 0; i < rdk.length; i++) {
          if (rdk[i].tgl_transaksi === '') {
            this.error = 'true'
          }
        }
        if (this.error === 'true') {
          this.message = 'Tanggal Belum di Isi'
          this.$refs['modal-gagal'].show()
          setTimeout(() => {
            this.reload()
          }, 1500)
        } else {
          this.isLoading = true
          try {
            this.isLoading = true
            for (let key in rdk) {
              const response = await BIOS.kirimDanaKelolaan(rdk[key])
              this.message = response.data.message
            }
            this.isLoading = false
            this.$refs['modal-sukses'].show()
          } catch (error) {
            this.isLoading = false
            this.message = error.response
            this.$refs['modal-gagal'].show()
            this.reload()
          }
        }
      } else {}
    },
    vrpk () {
      let b = this.tampungDataRPK
      for (let i = 0; i < b.length; i++) {
        this.kirimDataRPK.push({
          'object': 'kirim-data',
          'tgl_transaksi': '',
          'kdbank': '',
          'no_bilyet': '0',
          'nilai_deposito': '0',
          'nilai_bunga': '0'
        })
        this.previewJumlahDeposito.push({
          'nilai_deposito': '0'
        })
        this.previewJumlahBill.push({
          'no_bilyet': '0'
        })
        this.previewJumlahBunga.push({
          'nilai_bunga': '0'
        })
      }
    },
    vro () {
      let b = this.tampungDataRO
      for (let i = 0; i < b.length; i++) {
        this.kirimDataRO.push({
          'object': 'kirim-data',
          'tgl_transaksi': '',
          'kdbank': '',
          'no_rekening': '',
          'unit': '',
          'saldo_akhir': '0'
        })
        this.previewJumlahRO.push({
          'saldo_akhir': '0'
        })
      }
    },
    vrdk () {
      let b = this.tampungDataRDK
      for (let i = 0; i < b.length; i++) {
        this.kirimDataRDK.push({
          'object': 'kirim-data',
          'tgl_transaksi': '',
          'kdbank': '',
          'no_rekening': '',
          'saldo_akhir': '0'
        })
        this.previewJumlahRDK.push({
          'saldo_akhir': '0'
        })
      }
    },
    inputDataDeposito (q, param, index) {
      this.kirimDataRPK[index].nilai_deposito = q.replace(/[.,\s]/g, '')
      this.kirimDataRPK[index].kdbank = param
      this.formattedRupiahB(q, index)
    },
    inputDataBill (q, index) {
      this.kirimDataRPK[index].no_bilyet = q.replace(/[.,\s]/g, '')
      this.formattedRupiahC(q, index)
    },
    inputDataBunga (q, index) {
      this.kirimDataRPK[index].nilai_bunga = q.replace(/[.,\s]/g, '')
      this.formattedRupiahD(q, index)
    },
    inputDataRDK (q, z, c, index) {
      this.kirimDataRDK[index].saldo_akhir = q.replace(/[.,\s]/g, '')
      this.kirimDataRDK[index].kdbank = z
      this.kirimDataRDK[index].no_rekening = c
      this.formattedRupiah2(q, index)
    },
    inputDataRO (q, z, c, v, index) {
      this.kirimDataRO[index].saldo_akhir = q.replace(/[.,\s]/g, '')
      this.kirimDataRO[index].kdbank = z
      this.kirimDataRO[index].no_rekening = c
      this.kirimDataRO[index].unit = v
      this.formattedRupiah(q, index)
    },
    formattedRupiah (q, index) {
      let numberString = q.replace(/[^,\d]/g, '').toString()
      let split = numberString.split(',')
      let sisa = split[0].length % 3
      let rupiah = split[0].substr(0, sisa)
      let ribuan = split[0].substr(sisa).match(/\d{3}/gi)
      if (ribuan) {
        let separator = sisa ? '.' : ''
        rupiah += separator + ribuan.join('.')
      }
      rupiah = split[1] !== undefined ? rupiah + ',' + split[1] : rupiah
      this.previewJumlahRO[index].saldo_akhir = rupiah
    },
    formattedRupiah2 (q, index) {
      let numberString = q.replace(/[^,\d]/g, '').toString()
      let split = numberString.split(',')
      let sisa = split[0].length % 3
      let rupiah = split[0].substr(0, sisa)
      let ribuan = split[0].substr(sisa).match(/\d{3}/gi)
      if (ribuan) {
        let separator = sisa ? '.' : ''
        rupiah += separator + ribuan.join('.')
      }
      rupiah = split[1] !== undefined ? rupiah + ',' + split[1] : rupiah
      this.previewJumlahRDK[index].saldo_akhir = rupiah
    },
    formattedRupiahB (q, index) {
      let numberString = q.replace(/[^,\d]/g, '').toString()
      let split = numberString.split(',')
      let sisa = split[0].length % 3
      let rupiah = split[0].substr(0, sisa)
      let ribuan = split[0].substr(sisa).match(/\d{3}/gi)
      if (ribuan) {
        let separator = sisa ? '.' : ''
        rupiah += separator + ribuan.join('.')
      }
      rupiah = split[1] !== undefined ? rupiah + ',' + split[1] : rupiah
      this.previewJumlahDeposito[index].nilai_deposito = rupiah
    },
    formattedRupiahC (q, index) {
      let numberString = q.replace(/[^,\d]/g, '').toString()
      let split = numberString.split(',')
      let sisa = split[0].length % 3
      let rupiah = split[0].substr(0, sisa)
      let ribuan = split[0].substr(sisa).match(/\d{3}/gi)
      if (ribuan) {
        let separator = sisa ? '.' : ''
        rupiah += separator + ribuan.join('.')
      }
      rupiah = split[1] !== undefined ? rupiah + ',' + split[1] : rupiah
      this.previewJumlahBill[index].no_bilyet = rupiah
    },
    formattedRupiahD (q, index) {
      let numberString = q.replace(/[^,\d]/g, '').toString()
      let split = numberString.split(',')
      let sisa = split[0].length % 3
      let rupiah = split[0].substr(0, sisa)
      let ribuan = split[0].substr(sisa).match(/\d{3}/gi)
      if (ribuan) {
        let separator = sisa ? '.' : ''
        rupiah += separator + ribuan.join('.')
      }
      rupiah = split[1] !== undefined ? rupiah + ',' + split[1] : rupiah
      this.previewJumlahBunga[index].nilai_bunga = rupiah
    },
    onShowInput () {
      this.$refs['modalSaldo'].show()
      // this.getAllRPK()
    },
    getDate (e) {
      let a = this.kirimDataRPK
      let r = this.kirimDataRO
      let d = this.kirimDataRDK
      let date = new Date(e)
      let year = date.getFullYear()
      let month = String(date.getMonth() + 1).padStart(2, '0')
      let day = String(date.getDate()).padStart(2, '0')
      let joinDate = year + '-' + month + '-' + day
      for (let i = 0; i < a.length; i++) {
        a[i].tgl_transaksi = joinDate
      }
      for (let i = 0; i < r.length; i++) {
        r[i].tgl_transaksi = joinDate
      }
      for (let i = 0; i < d.length; i++) {
        d[i].tgl_transaksi = joinDate
      }
    },
    alertModal () {
      this.$refs['alertModal'].show()
    },
    hideModal () {
      this.$refs['alertModal'].hide()
      location.reload()
    },
    async getAllRPK () {
      const data = 'rekening-pengelolaan-kas'
      try {
        const response = await BIOS.getAllMasterPengelolaan(data)
        this.tampungDataRPK = response.data.data
        setTimeout(() => {
          this.vrpk()
        }, 1500)
      } catch (error) {
        return []
      }
    },
    async getAllRO () {
      const data = 'rekening-operasional'
      try {
        const response = await BIOS.getAllMasterOperasional(data)
        this.tampungDataRO = response.data.data
        setTimeout(() => {
          this.vro()
        }, 1500)
      } catch (error) {
        return []
      }
    },
    async getAllRDK () {
      const data = 'rekening-dana-kelolaan'
      try {
        const response = await BIOS.getAllMasterRekening(data)
        this.tampungDataRDK = response.data.data
        setTimeout(() => {
          this.vrdk()
        }, 1500)
      } catch (error) {
        return []
      }
    },
    async getRiwayatRPK (ctx) {
      const data = {
        object: 'tarik-data',
        page: ctx.currentPage.toString() - 1
      }
      try {
        const response = await BIOS.getAllRiwayatRPK(data)
        this.table.ctx = response.data.data.data.offset
        this.table.totalRows = response.data.data.data.total
        return response.data.data.data.datas
      } catch (error) {
        return []
      }
    },
    async getRiwayatRDK (ctx) {
      const data = {
        object: 'tarik-data',
        page: ctx.currentPage.toString() - 1
      }
      try {
        const response = await BIOS.getAllRiwayatRDK(data)
        this.table.ctx = response.data.data.data.offset
        this.table.totalRows = response.data.data.data.total
        return response.data.data.data.datas
      } catch (error) {
        return []
      }
    },
    async getRiwayatRO (ctx) {
      const data = {
        object: 'tarik-data',
        page: ctx.currentPage.toString() - 1
      }
      try {
        const response = await BIOS.getAllRiwayatRO(data)
        this.table.ctx = response.data.data.data.offset
        this.table.totalRows = response.data.data.data.total
        return response.data.data.data.datas
      } catch (error) {
        return []
      }
    },
    // changeTableSaldo () {
    //   if (this.options.index === 'RPK') {
    //     this.getAllRPK()
    //   } else if (this.options.index === 'RO') {
    //     this.getAllRO()
    //   } else if (this.options.index === 'RDK') {
    //     this.getAllRDK()
    //   }
    // },
    tableRefresh () {
      this.$refs.table.refresh()
    },
    reload () {
      location.reload()
      this.$refs.table.refresh()
    },
    batal () {
      location.reload()
    }
  }
}
</script>
<style>
#bios-saldo .table, #modal-saldo .table {
    margin-bottom: 0px;
}
#bios-saldo .table td, #modal-saldo .table td {
    font-size: 12px;
    font-weight: bold;
    font-weight: 500;
    padding-top: 10px;
    padding-bottom: 10px;
}
#bios-saldo .table th, #modal-saldo .table th {
    font-size: 13px;
    font-weight: 400;
}
.bios-cursor {
  cursor: pointer;
  }
  #bios-saldo .pagination-bios .page-link {
    border: none;
    color: gray!important;
    margin-right: 15px;
    font-size: 12px;
  }
  #bios-saldo .pagination-bios .page-item.active .page-link {
      background-color: transparent;
      border-color: transparent;
      color: #089bab!important;
      font-weight: bold;
  }
  #modal-saldo .flatpickr-cstm-lab {
  background: #089bab48;
  min-height: 38px!important;
  height: 38px!important;
  line-height: 38px!important;
  border: 1px solid #d7dbda!important;
  border-radius: 5px!important;
  padding-left: 10px!important;
  padding-right: 10px!important;
  color: #000000!important;
  font-weight: bold;
}
.cstm-cntrl-bios {
  font-weight: bold!important;
  border-radius: 5px!important;
  font-size: 13px!important;
  height: 38px!important;
  color: black!important;
}
#modal-saldo .modal-dialog {
  min-width: 90%!important;
}
</style>
