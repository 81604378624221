<template>
  <b-container fluid class="utdDokter">
    <div v-show="isLoading">
        <div id="show-overlay"></div>
      <Loader :style="{ opacity: .5 }" />
    </div>
    <b-row class="mb-3">
      <b-col>
        <iq-card class="h-100">
          <template v-slot:headerTitle>
            <h4 class="card-title"><i class="fas fa-user-md text-primary"></i> Halaman Dokter</h4>
          </template>
          <template v-slot:body>
            <div>
              <b-row class="mb-4">
                <b-col cols="12" lg="6">
                  <b-row>
                    <b-col>
                      <div>
                        <div class="mb-1">
                          <b-row>
                            <b-col cols="4">
                              <p class="text-dark mb-0">Nama Pendonor</p>
                            </b-col>
                            <b-col cols="1">
                              <p class="text-dark mb-0">:</p>
                            </b-col>
                            <b-col>
                              <p class="text-dark mb-0"><span>{{nama}}</span></p>
                            </b-col>
                          </b-row>
                        </div>
                        <div class="mb-1">
                          <b-row>
                            <b-col cols="4">
                              <p class="text-dark mb-0">Umur Pendonor</p>
                            </b-col>
                            <b-col cols="1">
                              <p class="text-dark mb-0">:</p>
                            </b-col>
                            <b-col>
                              <p class="text-dark mb-0"><span>{{umur}}</span></p>
                            </b-col>
                          </b-row>
                        </div>
                        <div class="mb-0">
                          <b-row>
                            <b-col cols="4">
                              <p class="text-dark mb-0">Jenis Kelamin Pendonor</p>
                            </b-col>
                            <b-col cols="1">
                              <p class="text-dark mb-0">:</p>
                            </b-col>
                            <b-col>
                              <p class="text-dark mb-0"><span>{{ jenis_kelamin }}</span></p>
                            </b-col>
                          </b-row>
                        </div>
                      </div>
                    </b-col>
                  </b-row>
                </b-col>
                <b-col cols="12" lg="6">
                  <div class="text-right">
                    <p class="mb-0">Nomor Reservasi</p>
                    <p class="h3 text-primary font-weight-bold mb-0">{{no_resv}}</p>
                  </div>
                </b-col>
              </b-row>
              <b-row>
                <b-col class="pr-1">
                  <div class="shadow-sm border p-3 mb-2">
                    <p class="font-weight-bold text-uppercase text-dark"><u>Apakah Anda :</u></p>
                    <div class="row mb-1">
                      <div class="col-12">
                        <p class="font-weight-light text-dark mb-0">Merasa Sehat Pada Hari Ini</p>
                      </div>
                      <div class="col-12" v-if="pasien.param1 === 1">
                        <p class="font-weight-bold text-dark mb-0">Ya</p>
                      </div>
                      <div class="col-12" v-else-if="pasien.param1 === 2">
                        <p class="font-weight-bold text-dark mb-0">Tidak</p>
                      </div>
                      <div class="col-12" v-else-if="pasien.param1 === 3">
                        <p class="font-weight-bold text-dark mb-0">Tidak Tahu</p>
                      </div>
                      <div class="col-12" v-else>
                        <p class="text-dark mb-0">-</p>
                      </div>
                    </div>
                    <div class="row mb-1">
                      <div class="col-12">
                        <p class="font-weight-light text-dark mb-0">Sedang Minum Antibiotik Atau Obat Lain Untuk Infeksi</p>
                      </div>
                      <div class="col-12" v-if="pasien.param2 === 1">
                        <p class="font-weight-bold text-dark mb-0">Ya</p>
                      </div>
                      <div class="col-12" v-else-if="pasien.param2 === 2">
                        <p class="font-weight-bold text-dark mb-0">Tidak</p>
                      </div>
                      <div class="col-12" v-else-if="pasien.param2 === 3">
                        <p class="font-weight-bold text-dark mb-0">Tidak Tahu</p>
                      </div>
                      <div class="col-12" v-else>
                        <p class="text-dark mb-0">-</p>
                      </div>
                    </div>
                    <div class="row mb-0">
                      <div class="col-12">
                        <p class="font-weight-light text-dark mb-0">Sedang Minum Jamu</p>
                      </div>
                      <div class="col-12" v-if="pasien.param3 === 1">
                        <p class="font-weight-bold text-dark mb-0">Ya</p>
                      </div>
                      <div class="col-12" v-else-if="pasien.param3 === 2">
                        <p class="font-weight-bold text-dark mb-0">Tidak</p>
                      </div>
                      <div class="col-12" v-else-if="pasien.param3 === 3">
                        <p class="font-weight-bold text-dark mb-0">Tidak Tahu</p>
                      </div>
                      <div class="col-12" v-else>
                        <p class="text-dark mb-0">-</p>
                      </div>
                    </div>
                  </div>
                  <div class="shadow-sm border p-3 mb-2">
                    <p class="font-weight-bold text-uppercase text-dark"><u>Dalam Waktu 48 Jam Terakhir : </u></p>
                    <div class="row mb-1">
                      <div class="col-12">
                        <p class="font-weight-light text-dark mb-0">Apakah Anda Sedang Minum Aspirin Atau Obat Yang Mengandung Aspirin</p>
                      </div>
                      <div class="col-12" v-if="pasien.param4 === 1">
                        <p class="font-weight-bold text-dark mb-0">Ya</p>
                      </div>
                      <div class="col-12" v-else-if="pasien.param4 === 2">
                        <p class="font-weight-bold text-dark mb-0">Tidak</p>
                      </div>
                      <div class="col-12" v-else-if="pasien.param4 === 3">
                        <p class="font-weight-bold text-dark mb-0">Tidak Tahu</p>
                      </div>
                      <div class="col-12" v-else>
                        <p class="text-dark mb-0">-</p>
                      </div>
                    </div>
                  </div>
                  <div class="shadow-sm border p-3 mb-2">
                    <p class="font-weight-bold text-uppercase text-dark"><u>Dalam Waktu 1 Minggu Terakhir :</u></p>
                    <div class="row mb-1">
                      <div class="col-12">
                        <p class="font-weight-light text-dark mb-0">Apakah Anda Mengalami Sakit Kepala Dan Demam Bersamaan Atau Tindakan Cabut
                          Gigi</p>
                      </div>
                      <div class="col-12" v-if="pasien.param5 === 1">
                        <p class="font-weight-bold text-dark mb-0">Ya</p>
                      </div>
                      <div class="col-12" v-else-if="pasien.param5 === 2">
                        <p class="font-weight-bold text-dark mb-0">Tidak</p>
                      </div>
                      <div class="col-12" v-else-if="pasien.param5 === 3">
                        <p class="font-weight-bold text-dark mb-0">Tidak Tahu</p>
                      </div>
                      <div class="col-12" v-else>
                        <p class="text-dark mb-0">-</p>
                      </div>
                    </div>
                  </div>
                  <div class="shadow-sm border p-3 mb-2">
                    <p class="font-weight-bold text-uppercase text-dark"><u>Dalam Waktu 6 Minggu Terakhir :</u></p>
                    <div class="row mb-1">
                      <div class="col-12">
                        <p class="font-weight-light text-dark mb-0">Untuk Donor Darah Wanita : Apakah Anda Saat Ini Sedang Hamil ? Jika Ya,
                          Kehamilan Berapa</p>
                      </div>
                      <div class="col-12" v-if="pasien.param6 === 1">
                        <p class="font-weight-bold text-dark mb-0">Ya, {{pasien.ket_param6}}</p>
                      </div>
                      <div class="col-12" v-else-if="pasien.param6 === 2">
                        <p class="font-weight-bold text-dark mb-0">Tidak</p>
                      </div>
                      <div class="col-12" v-else-if="pasien.param6 === 3">
                        <p class="font-weight-bold text-dark mb-0">Tidak Tahu</p>
                      </div>
                      <div class="col-12" v-else>
                        <p class="text-dark mb-0">-</p>
                      </div>
                    </div>
                  </div>
                  <div class="shadow-sm border p-3 mb-2">
                    <p class="font-weight-bold text-uppercase text-dark"><u>Dalam Waktu 8 Minggu Terakhir ( 2 Bulan ) : </u></p>
                    <div class="row mb-3">
                      <div class="col-12">
                        <p class="font-weight-light text-dark mb-0">Apakah Anda Mendonorkan Darah, Trombosit, Atau Plasma</p>
                      </div>
                      <div class="col-12" v-if="pasien.param7 === 1">
                        <p class="font-weight-bold text-dark mb-0">Ya</p>
                      </div>
                      <div class="col-12" v-else-if="pasien.param7 === 2">
                        <p class="font-weight-bold text-dark mb-0">Tidak</p>
                      </div>
                      <div class="col-12" v-else-if="pasien.param7 === 3">
                        <p class="font-weight-bold text-dark mb-0">Tidak Tahu</p>
                      </div>
                      <div class="col-12" v-else>
                        <p class="text-dark mb-0">-</p>
                      </div>
                    </div>
                    <div class="row mb-3">
                      <div class="col-12">
                        <p class="font-weight-light text-dark mb-0">Apakah Anda Menerima Vaksinasi Atau Suntik Lainnya</p>
                      </div>
                      <div class="col-12" v-if="pasien.param8 === 1">
                        <p class="font-weight-bold text-dark mb-0">Ya</p>
                      </div>
                      <div class="col-12" v-else-if="pasien.param8 === 2">
                        <p class="font-weight-bold text-dark mb-0">Tidak</p>
                      </div>
                      <div class="col-12" v-else-if="pasien.param8 === 3">
                        <p class="font-weight-bold text-dark mb-0">Tidak Tahu</p>
                      </div>
                      <div class="col-12" v-else>
                        <p class="text-dark mb-0">-</p>
                      </div>
                    </div>
                    <div class="row mb-0">
                      <div class="col-12">
                        <p class="font-weight-light text-dark mb-0">Apakah Anda Pernah Kontak Dengan Orang Yang Menerima Vaksinasi Smallpox
                        </p>
                      </div>
                      <div class="col-12" v-if="pasien.param9 === 1">
                        <p class="font-weight-bold text-dark mb-0">Ya</p>
                      </div>
                      <div class="col-12" v-else-if="pasien.param9 === 2">
                        <p class="font-weight-bold text-dark mb-0">Tidak</p>
                      </div>
                      <div class="col-12" v-else-if="pasien.param9 === 3">
                        <p class="font-weight-bold text-dark mb-0">Tidak Tahu</p>
                      </div>
                      <div class="col-12" v-else>
                        <p class="text-dark mb-0">-</p>
                      </div>
                    </div>
                  </div>
                  <div class="shadow-sm border p-3 mb-2">
                    <p class="font-weight-bold text-uppercase text-dark"><u>Dalam Waktu 16 Minggu Terakhir ( 4 Bulan ) :</u></p>
                    <div class="row mb-0">
                      <div class="col-12">
                        <p class="font-weight-light text-dark mb-0">Apakah Anda Mendonorkan Darah, Trombosit, Atau Plasma</p>
                      </div>
                      <div class="col-12" v-if="pasien.param10 === 1">
                        <p class="font-weight-bold text-dark mb-0">Ya</p>
                      </div>
                      <div class="col-12" v-else-if="pasien.param10 === 2">
                        <p class="font-weight-bold text-dark mb-0">Tidak</p>
                      </div>
                      <div class="col-12" v-else-if="pasien.param10 === 3">
                        <p class="font-weight-bold text-dark mb-0">Tidak Tahu</p>
                      </div>
                      <div class="col-12" v-else>
                        <p class="text-dark mb-0">-</p>
                      </div>
                    </div>
                  </div>
                </b-col>
                <b-col class="pr-1 pl-1">
                  <div class="shadow-sm border p-3 mb-2">
                    <p class="font-weight-bold text-uppercase text-underline text-dark"><u>Dalam Waktu 12 Bulan Terakhir (1 Tahun) :</u></p>
                    <div class="row mb-3">
                      <div class="col-12">
                        <p class="font-weight-light text-dark mb-0">Apakah Anda Pernah Menerima Transfusi Darah</p>
                      </div>
                      <div class="col-12" v-if="pasien.param11 === 1">
                        <p class="font-weight-bold text-dark mb-0">Ya</p>
                      </div>
                      <div class="col-12" v-else-if="pasien.param11 === 2">
                        <p class="font-weight-bold text-dark mb-0">Tidak</p>
                      </div>
                      <div class="col-12" v-else-if="pasien.param11 === 3">
                        <p class="font-weight-bold text-dark mb-0">Tidak Tahu</p>
                      </div>
                      <div class="col-12" v-else>
                        <p class="text-dark mb-0">-</p>
                      </div>
                    </div>
                    <div class="row mb-3">
                      <div class="col-12">
                        <p class="font-weight-light text-dark mb-0">Apakah Anda Pernah Mendapat Transplantasi Organ, Jaringan, Sumsum Tulang
                          Atau Cangkok Tulang Untuk Kulit</p>
                      </div>
                      <div class="col-12" v-if="pasien.param12 === 1">
                        <p class="font-weight-bold text-dark mb-0">Ya</p>
                      </div>
                      <div class="col-12" v-else-if="pasien.param12 === 2">
                        <p class="font-weight-bold text-dark mb-0">Tidak</p>
                      </div>
                      <div class="col-12" v-else-if="pasien.param12 === 3">
                        <p class="font-weight-bold text-dark mb-0">Tidak Tahu</p>
                      </div>
                      <div class="col-12" v-else>
                        <p class="text-dark mb-0">-</p>
                      </div>
                    </div>
                    <div class="row mb-3">
                      <div class="col-12">
                        <p class="font-weight-light text-dark mb-0">Apakah Anda Pernah Mengalami Tindakan Operasi</p>
                      </div>
                      <div class="col-12" v-if="pasien.param13 === 1">
                        <p class="font-weight-bold text-dark mb-0">Ya</p>
                      </div>
                      <div class="col-12" v-else-if="pasien.param13 === 2">
                        <p class="font-weight-bold text-dark mb-0">Tidak</p>
                      </div>
                      <div class="col-12" v-else-if="pasien.param13 === 3">
                        <p class="font-weight-bold text-dark mb-0">Tidak Tahu</p>
                      </div>
                      <div class="col-12" v-else>
                        <p class="text-dark mb-0">-</p>
                      </div>
                    </div>
                    <div class="row mb-3">
                      <div class="col-12">
                        <p class="font-weight-light text-dark mb-0">Apakah Anda Pernah Tertusuk Jarum Medis, Akupuntur Atau Bekam</p>
                      </div>
                      <div class="col-12" v-if="pasien.param14 === 1">
                        <p class="font-weight-bold text-dark mb-0">Ya</p>
                      </div>
                      <div class="col-12" v-else-if="pasien.param14 === 2">
                        <p class="font-weight-bold text-dark mb-0">Tidak</p>
                      </div>
                      <div class="col-12" v-else-if="pasien.param14 === 3">
                        <p class="font-weight-bold text-dark mb-0">Tidak Tahu</p>
                      </div>
                      <div class="col-12" v-else>
                        <p class="text-dark mb-0">-</p>
                      </div>
                    </div>
                    <div class="row mb-3">
                      <div class="col-12">
                        <p class="font-weight-light text-dark mb-0">Apakah Anda Pernah Berhubungan Seksual Dengan Orang HIV/AIDS</p>
                      </div>
                      <div class="col-12" v-if="pasien.param15 === 1">
                        <p class="font-weight-bold text-dark mb-0">Ya</p>
                      </div>
                      <div class="col-12" v-else-if="pasien.param15 === 2">
                        <p class="font-weight-bold text-dark mb-0">Tidak</p>
                      </div>
                      <div class="col-12" v-else-if="pasien.param15 === 3">
                        <p class="font-weight-bold text-dark mb-0">Tidak Tahu</p>
                      </div>
                      <div class="col-12" v-else>
                        <p class="text-dark mb-0">-</p>
                      </div>
                    </div>
                    <div class="row mb-3">
                      <div class="col-12">
                        <p class="font-weight-light text-dark mb-0">Apakah Anda Pernah Berhubungan Seksual Dengan Pekerja Seks Komersial</p>
                      </div>
                      <div class="col-12" v-if="pasien.param16 === 1">
                        <p class="font-weight-bold text-dark mb-0">Ya</p>
                      </div>
                      <div class="col-12" v-else-if="pasien.param16 === 2">
                        <p class="font-weight-bold text-dark mb-0">Tidak</p>
                      </div>
                      <div class="col-12" v-else-if="pasien.param16 === 3">
                        <p class="font-weight-bold text-dark mb-0">Tidak Tahu</p>
                      </div>
                      <div class="col-12" v-else>
                        <p class="text-dark mb-0">-</p>
                      </div>
                    </div>
                    <div class="row mb-3">
                      <div class="col-12">
                        <p class="font-weight-light text-dark mb-0">Apakah Anda Pernah Berhubungan Seksual Dengan Pengguna Narkoba Jarum
                          Suntik</p>
                      </div>
                      <div class="col-12" v-if="pasien.param17 === 1">
                        <p class="font-weight-bold text-dark mb-0">Ya</p>
                      </div>
                      <div class="col-12" v-else-if="pasien.param17 === 2">
                        <p class="font-weight-bold text-dark mb-0">Tidak</p>
                      </div>
                      <div class="col-12" v-else-if="pasien.param17 === 3">
                        <p class="font-weight-bold text-dark mb-0">Tidak Tahu</p>
                      </div>
                      <div class="col-12" v-else>
                        <p class="text-dark mb-0">-</p>
                      </div>
                    </div>
                    <div class="row mb-3">
                      <div class="col-12">
                        <p class="font-weight-light text-dark mb-0">Apakah Anda Pernah Berhubungan Seksual Dengan Pengguna Konsetrat Faktor
                          Pembekuan</p>
                      </div>
                      <div class="col-12" v-if="pasien.param18 === 1">
                        <p class="font-weight-bold text-dark mb-0">Ya</p>
                      </div>
                      <div class="col-12" v-else-if="pasien.param18 === 2">
                        <p class="font-weight-bold text-dark mb-0">Tidak</p>
                      </div>
                      <div class="col-12" v-else-if="pasien.param18 === 3">
                        <p class="font-weight-bold text-dark mb-0">Tidak Tahu</p>
                      </div>
                      <div class="col-12" v-else>
                        <p class="text-dark mb-0">-</p>
                      </div>
                    </div>
                    <div class="row mb-3">
                      <div class="col-12">
                        <p class="font-weight-light text-dark mb-0">Apakah Anda Pernah Berhubungan Seksual Dengan Yang Laki - Laki Biseksual
                        </p>
                      </div>
                      <div class="col-12" v-if="pasien.param19 === 1">
                        <p class="font-weight-bold text-dark mb-0">Ya</p>
                      </div>
                      <div class="col-12" v-else-if="pasien.param19 === 2">
                        <p class="font-weight-bold text-dark mb-0">Tidak</p>
                      </div>
                      <div class="col-12" v-else-if="pasien.param19 === 3">
                        <p class="font-weight-bold text-dark mb-0">Tidak Tahu</p>
                      </div>
                      <div class="col-12" v-else>
                        <p class="text-dark mb-0">-</p>
                      </div>
                    </div>
                    <div class="row mb-3">
                      <div class="col-12">
                        <p class="font-weight-light text-dark mb-0">Apakah Anda Pernah Berhubungan Seksual Dengan Penderita Hepatitis</p>
                      </div>
                      <div class="col-12" v-if="pasien.param20 === 1">
                        <p class="font-weight-bold text-dark mb-0">Ya</p>
                      </div>
                      <div class="col-12" v-else-if="pasien.param20 === 2">
                        <p class="font-weight-bold text-dark mb-0">Tidak</p>
                      </div>
                      <div class="col-12" v-else-if="pasien.param20 === 3">
                        <p class="font-weight-bold text-dark mb-0">Tidak Tahu</p>
                      </div>
                      <div class="col-12" v-else>
                        <p class="text-dark mb-0">-</p>
                      </div>
                    </div>
                    <div class="row mb-3">
                      <div class="col-12">
                        <p class="font-weight-light text-dark mb-0">Apakah Anda Tinggal Bersama Penderita Hepatitis</p>
                      </div>
                      <div class="col-12" v-if="pasien.param21 === 1">
                        <p class="font-weight-bold text-dark mb-0">Ya</p>
                      </div>
                      <div class="col-12" v-else-if="pasien.param21 === 2">
                        <p class="font-weight-bold text-dark mb-0">Tidak</p>
                      </div>
                      <div class="col-12" v-else-if="pasien.param21 === 3">
                        <p class="font-weight-bold text-dark mb-0">Tidak Tahu</p>
                      </div>
                      <div class="col-12" v-else>
                        <p class="text-dark mb-0">-</p>
                      </div>
                    </div>
                    <div class="row mb-3">
                      <div class="col-12">
                        <p class="font-weight-light text-dark mb-0">Apakah Anda Memiliki Tato</p>
                      </div>
                      <div class="col-12" v-if="pasien.param22 === 1">
                        <p class="font-weight-bold text-dark mb-0">Ya</p>
                      </div>
                      <div class="col-12" v-else-if="pasien.param22 === 2">
                        <p class="font-weight-bold text-dark mb-0">Tidak</p>
                      </div>
                      <div class="col-12" v-else-if="pasien.param22 === 3">
                        <p class="font-weight-bold text-dark mb-0">Tidak Tahu</p>
                      </div>
                      <div class="col-12" v-else>
                        <p class="text-dark mb-0">-</p>
                      </div>
                    </div>
                    <div class="row mb-3">
                      <div class="col-12">
                        <p class="font-weight-light text-dark mb-0">Apakah Anda Memiliki Tindik Telingan Atau Dibagian Tubuh Lainnya</p>
                      </div>
                      <div class="col-12" v-if="pasien.param23 === 1">
                        <p class="font-weight-bold text-dark mb-0">Ya</p>
                      </div>
                      <div class="col-12" v-else-if="pasien.param23 === 2">
                        <p class="font-weight-bold text-dark mb-0">Tidak</p>
                      </div>
                      <div class="col-12" v-else-if="pasien.param23 === 3">
                        <p class="font-weight-bold text-dark mb-0">Tidak Tahu</p>
                      </div>
                      <div class="col-12" v-else>
                        <p class="text-dark mb-0">-</p>
                      </div>
                    </div>
                    <div class="row mb-3">
                      <div class="col-12">
                        <p class="font-weight-light text-dark mb-0">Apakah Anda Sedang Atau Pernah Mendapat Pengobatan Sifilis Atau GO(Kencing
                          Nanah)</p>
                      </div>
                      <div class="col-12" v-if="pasien.param24 === 1">
                        <p class="font-weight-bold text-dark mb-0">Ya</p>
                      </div>
                      <div class="col-12" v-else-if="pasien.param24 === 2">
                        <p class="font-weight-bold text-dark mb-0">Tidak</p>
                      </div>
                      <div class="col-12" v-else-if="pasien.param24 === 3">
                        <p class="font-weight-bold text-dark mb-0">Tidak Tahu</p>
                      </div>
                      <div class="col-12" v-else>
                        <p class="text-dark mb-0">-</p>
                      </div>
                    </div>
                    <div class="row mb-0">
                      <div class="col-12">
                        <p class="font-weight-light text-dark mb-0">Apakah Anda Pernah Ditahan Dipenjara Untuk Waktu Lebih Dari 72 Jam</p>
                      </div>
                      <div class="col-12" v-if="pasien.param25 === 1">
                        <p class="font-weight-bold text-dark mb-0">Ya</p>
                      </div>
                      <div class="col-12" v-else-if="pasien.param25 === 2">
                        <p class="font-weight-bold text-dark mb-0">Tidak</p>
                      </div>
                      <div class="col-12" v-else-if="pasien.param25 === 3">
                        <p class="font-weight-bold text-dark mb-0">Tidak Tahu</p>
                      </div>
                      <div class="col-12" v-else>
                        <p class="text-dark mb-0">-</p>
                      </div>
                    </div>
                  </div>
                </b-col>
                <b-col class="pl-1">
                  <div class="shadow-sm border p-3 mb-2">
                    <p class="font-weight-bold text-uppercase text-dark"><u>Tahun 1977 Hingga Sekarang :</u></p>
                    <div class="row mb-3">
                      <div class="col-12">
                        <p class="font-weight-light text-dark mb-0">Apakah Anda Menerima Uang, Obat, Atau Pembiayaan Lainnya Untuk Seks</p>
                      </div>
                      <div class="col-12" v-if="pasien.param26 === 1">
                        <p class="font-weight-bold text-dark mb-0">Ya</p>
                      </div>
                      <div class="col-12" v-else-if="pasien.param19 === 2">
                        <p class="font-weight-bold text-dark mb-0">Tidak</p>
                      </div>
                      <div class="col-12" v-else-if="pasien.param19 === 3">
                        <p class="font-weight-bold text-dark mb-0">Tidak Tahu</p>
                      </div>
                      <div class="col-12" v-else>
                        <p class="text-dark mb-0">-</p>
                      </div>
                    </div>
                    <div class="row mb-0">
                      <div class="col-12">
                        <p class="font-weight-light text-dark mb-0">Untuk Laki-laki: Apakah Anda Pernah Berhubungan Seksual Dengan Sesama
                          Laki-laki, Walaupun Hanya Sekali</p>
                      </div>
                      <div class="col-12" v-if="pasien.param27 === 1">
                        <p class="font-weight-bold text-dark mb-0">Ya</p>
                      </div>
                      <div class="col-12" v-else-if="pasien.param27 === 2">
                        <p class="font-weight-bold text-dark mb-0">Tidak</p>
                      </div>
                      <div class="col-12" v-else-if="pasien.param27 === 3">
                        <p class="font-weight-bold text-dark mb-0">Tidak Tahu</p>
                      </div>
                      <div class="col-12" v-else>
                        <p class="text-dark mb-0">-</p>
                      </div>
                    </div>
                  </div>
                  <div class="shadow-sm border p-3 mb-2">
                    <p class="font-weight-bold text-uppercase text-dark"><u>Tahun 1980 Hingga Sekarang :</u></p>
                    <div class="row mb-3">
                      <div class="col-12">
                        <p class="font-weight-light text-dark mb-0">Apakah Anda Tingal Selama 5 Tahun Atau Lebih Di Eropa ?</p>
                      </div>
                      <div class="col-12" v-if="pasien.param28 === 1">
                        <p class="font-weight-bold text-dark mb-0">Ya</p>
                      </div>
                      <div class="col-12" v-else-if="pasien.param28 === 2">
                        <p class="font-weight-bold text-dark mb-0">Tidak</p>
                      </div>
                      <div class="col-12" v-else-if="pasien.param28 === 3">
                        <p class="font-weight-bold text-dark mb-0">Tidak Tahu</p>
                      </div>
                      <div class="col-12" v-else>
                        <p class="text-dark mb-0">-</p>
                      </div>
                    </div>
                    <div class="row mb-0">
                      <div class="col-12">
                        <p class="font-weight-light text-dark mb-0">Apakah Anda Menerima Transfusi Darah Di Inggris</p>
                      </div>
                      <div class="col-12" v-if="pasien.param29 === 1">
                        <p class="font-weight-bold text-dark mb-0">Ya</p>
                      </div>
                      <div class="col-12" v-else-if="pasien.param29 === 2">
                        <p class="font-weight-bold text-dark mb-0">Tidak</p>
                      </div>
                      <div class="col-12" v-else-if="pasien.param29 === 3">
                        <p class="font-weight-bold text-dark mb-0">Tidak Tahu</p>
                      </div>
                      <div class="col-12" v-else>
                        <p class="text-dark mb-0">-</p>
                      </div>
                    </div>
                  </div>
                  <div class="shadow-sm border p-3 mb-2">
                    <p class="font-weight-bold text-uppercase text-dark"><u>Tahun 1980 Hingga 1996 :</u></p>
                    <div class="row mb-0">
                      <div class="col-12">
                        <p class="font-weight-light text-dark mb-0">Apakah Anda Tingal Selama 3 Bulan Atau Lebih Di Inggris</p>
                      </div>
                      <div class="col-12" v-if="pasien.param30 === 1">
                        <p class="font-weight-bold text-dark mb-0">Ya</p>
                      </div>
                      <div class="col-12" v-else-if="pasien.param30 === 2">
                        <p class="font-weight-bold text-dark mb-0">Tidak</p>
                      </div>
                      <div class="col-12" v-else-if="pasien.param30 === 3">
                        <p class="font-weight-bold text-dark mb-0">Tidak Tahu</p>
                      </div>
                      <div class="col-12" v-else>
                        <p class="text-dark mb-0">-</p>
                      </div>
                    </div>
                  </div>
                  <div class="shadow-sm border p-3 mb-2">
                    <p class="font-weight-bold text-uppercase text-dark"><u>Apakah Anda Pernah :</u></p>
                    <div class="row mb-3">
                      <div class="col-12">
                        <p class="font-weight-light text-dark mb-0">Mendapat Hasil Positif Untuk Tes HIV/AIDS</p>
                      </div>
                      <div class="col-12" v-if="pasien.param31 === 1">
                        <p class="font-weight-bold text-dark mb-0">Ya</p>
                      </div>
                      <div class="col-12" v-else-if="pasien.param32 === 2">
                        <p class="font-weight-bold text-dark mb-0">Tidak</p>
                      </div>
                      <div class="col-12" v-else-if="pasien.param33 === 3">
                        <p class="font-weight-bold text-dark mb-0">Tidak Tahu</p>
                      </div>
                      <div class="col-12" v-else>
                        <p class="text-dark mb-0">-</p>
                      </div>
                    </div>
                    <div class="row mb-3">
                      <div class="col-12">
                        <p class="font-weight-light text-dark mb-0">Menggunakan Jarum Suntik Untuk Obat - Obatan, Steroid Yang Tidak
                          Diresepkan Dokter</p>
                      </div>
                      <div class="col-12" v-if="pasien.param32 === 1">
                        <p class="font-weight-bold text-dark mb-0">Ya</p>
                      </div>
                      <div class="col-12" v-else-if="pasien.param32 === 2">
                        <p class="font-weight-bold text-dark mb-0">Tidak</p>
                      </div>
                      <div class="col-12" v-else-if="pasien.param33 === 3">
                        <p class="font-weight-bold text-dark mb-0">Tidak Tahu</p>
                      </div>
                      <div class="col-12" v-else>
                        <p class="text-dark mb-0">-</p>
                      </div>
                    </div>
                    <div class="row mb-3">
                      <div class="col-12">
                        <p class="font-weight-light text-dark mb-0">Menggunakan Konsetrat Faktor Pembekuan (AHF/FFP)</p>
                      </div>
                      <div class="col-12" v-if="pasien.param33 === 1">
                        <p class="font-weight-bold text-dark mb-0">Ya</p>
                      </div>
                      <div class="col-12" v-else-if="pasien.param33 === 2">
                        <p class="font-weight-bold text-dark mb-0">Tidak</p>
                      </div>
                      <div class="col-12" v-else-if="pasien.param33 === 3">
                        <p class="font-weight-bold text-dark mb-0">Tidak Tahu</p>
                      </div>
                      <div class="col-12" v-else>
                        <p class="text-dark mb-0">-</p>
                      </div>
                    </div>
                    <div class="row mb-3">
                      <div class="col-12">
                        <p class="font-weight-light text-dark mb-0">Menderita Hepatitis</p>
                      </div>
                      <div class="col-12" v-if="pasien.param34 === 1">
                        <p class="font-weight-bold text-dark mb-0">Ya</p>
                      </div>
                      <div class="col-12" v-else-if="pasien.param34 === 2">
                        <p class="font-weight-bold text-dark mb-0">Tidak</p>
                      </div>
                      <div class="col-12" v-else-if="pasien.param34 === 3">
                        <p class="font-weight-bold text-dark mb-0">Tidak Tahu</p>
                      </div>
                      <div class="col-12" v-else>
                        <p class="text-dark mb-0">-</p>
                      </div>
                    </div>
                    <div class="row mb-3">
                      <div class="col-12">
                        <p class="font-weight-light text-dark mb-0">Menderita Malaria</p>
                      </div>
                      <div class="col-12" v-if="pasien.param35 === 1">
                        <p class="font-weight-bold text-dark mb-0">Ya</p>
                      </div>
                      <div class="col-12" v-else-if="pasien.param35 === 2">
                        <p class="font-weight-bold text-dark mb-0">Tidak</p>
                      </div>
                      <div class="col-12" v-else-if="pasien.param35 === 3">
                        <p class="font-weight-bold text-dark mb-0">Tidak Tahu</p>
                      </div>
                      <div class="col-12" v-else>
                        <p class="text-dark mb-0">-</p>
                      </div>
                    </div>
                    <div class="row mb-3">
                      <div class="col-12">
                        <p class="font-weight-light text-dark mb-0">Menderita kanker Termasuk Leukemia</p>
                      </div>
                      <div class="col-12" v-if="pasien.param36 === 1">
                        <p class="font-weight-bold text-dark mb-0">Ya</p>
                      </div>
                      <div class="col-12" v-else-if="pasien.param36 === 2">
                        <p class="font-weight-bold text-dark mb-0">Tidak</p>
                      </div>
                      <div class="col-12" v-else-if="pasien.param36 === 3">
                        <p class="font-weight-bold text-dark mb-0">Tidak Tahu</p>
                      </div>
                      <div class="col-12" v-else>
                        <p class="text-dark mb-0">-</p>
                      </div>
                    </div>
                    <div class="row mb-3">
                      <div class="col-12">
                        <p class="font-weight-light text-dark mb-0">Bersmasalah Dengan Jantung Atau Paru-Paru Termasuk TBC</p>
                      </div>
                      <div class="col-12" v-if="pasien.param37 === 1">
                        <p class="font-weight-bold text-dark mb-0">Ya</p>
                      </div>
                      <div class="col-12" v-else-if="pasien.param37 === 2">
                        <p class="font-weight-bold text-dark mb-0">Tidak</p>
                      </div>
                      <div class="col-12" v-else-if="pasien.param37 === 3">
                        <p class="font-weight-bold text-dark mb-0">Tidak Tahu</p>
                      </div>
                      <div class="col-12" v-else>
                        <p class="text-dark mb-0">-</p>
                      </div>
                    </div>
                    <div class="row mb-3">
                      <div class="col-12">
                        <p class="font-weight-light text-dark mb-0">Menderita Pendarahan Atau Penyakit Berhubungan Dengan Darah Seperti
                          Hemofilia</p>
                      </div>
                      <div class="col-12" v-if="pasien.param38 === 1">
                        <p class="font-weight-bold text-dark mb-0">Ya</p>
                      </div>
                      <div class="col-12" v-else-if="pasien.param38 === 2">
                        <p class="font-weight-bold text-dark mb-0">Tidak</p>
                      </div>
                      <div class="col-12" v-else-if="pasien.param38 === 3">
                        <p class="font-weight-bold text-dark mb-0">Tidak Tahu</p>
                      </div>
                      <div class="col-12" v-else>
                        <p class="text-dark mb-0">-</p>
                      </div>
                    </div>
                    <div class="row mb-3">
                      <div class="col-12">
                        <p class="font-weight-light text-dark mb-0">Berhubungan Seksual Dengan Orang Yang Tinggal Di Afrika</p>
                      </div>
                      <div class="col-12" v-if="pasien.param39 === 1">
                        <p class="font-weight-bold text-dark mb-0">Ya</p>
                      </div>
                      <div class="col-12" v-else-if="pasien.param39 === 2">
                        <p class="font-weight-bold text-dark mb-0">Tidak</p>
                      </div>
                      <div class="col-12" v-else-if="pasien.param39 === 3">
                        <p class="font-weight-bold text-dark mb-0">Tidak Tahu</p>
                      </div>
                      <div class="col-12" v-else>
                        <p class="text-dark mb-0">-</p>
                      </div>
                    </div>
                    <div class="row mb-0">
                      <div class="col-12">
                        <p class="font-weight-light text-dark mb-0">Tinggal Di Afrika</p>
                      </div>
                      <div class="col-12" v-if="pasien.param40 === 1">
                        <p class="font-weight-bold text-dark mb-0">Ya</p>
                      </div>
                      <div class="col-12" v-else-if="pasien.param40 === 2">
                        <p class="font-weight-bold text-dark mb-0">Tidak</p>
                      </div>
                      <div class="col-12" v-else-if="pasien.param40 === 3">
                        <p class="font-weight-bold text-dark mb-0">Tidak Tahu</p>
                      </div>
                      <div class="col-12" v-else>
                        <p class="text-dark mb-0">-</p>
                      </div>
                    </div>
                  </div>
                </b-col>
              </b-row>
              <div class="mt-1 mb-2">
                <b-row class="mb-4">
                  <b-col cols="12" lg="6" class="pr-1">
                    <div class="shadow-sm border h-100">
                      <div class="px-4 py-3">
                        <p class="text-uppercase font-weight-bold text-dark mb-0">Detail Pemeriksaan</p>
                      </div>
                      <hr class="m-0">
                      <div class="p-4">
                        <b-row class="mb-2">
                        <b-col cols="12" lg="4">
                          <p class="text-dark mb-0">Berat Badan</p>
                        </b-col>
                        <b-col cols="12" lg="1">
                          <p class="text-dark mb-0">:</p>
                        </b-col>
                        <b-col cols="12" lg="7">
                          <p class="text-dark mb-0">{{pasien.berat_badan}} Kg</p>
                        </b-col>
                      </b-row>
                      <b-row class="mb-2">
                        <b-col cols="12" lg="4">
                          <p class="text-dark mb-0">Golongan Darah</p>
                        </b-col>
                        <b-col cols="12" lg="1">
                          <p class="text-dark mb-0">:</p>
                        </b-col>
                        <b-col cols="12" lg="7">
                          <p class="text-dark mb-0">{{pasien.deskripsi_golongan_darah}}</p>
                        </b-col>
                      </b-row>
                      <b-row class="mb-2">
                        <b-col cols="12" lg="4">
                          <p class="text-dark mb-0">Hemoglobin</p>
                        </b-col>
                        <b-col cols="12" lg="1">
                          <p class="text-dark mb-0">:</p>
                        </b-col>
                        <b-col cols="12" lg="7">
                          <p class="text-dark mb-0" v-if="pasien.hb > '12,5' && pasien.hb <= '17'">{{pasien.hb}}</p>
                          <p class="text-danger mb-0" v-else>{{pasien.hb}}  (HB Tidak memnuhi Syarat)</p>
                        </b-col>
                      </b-row>
                      <b-row class="mb-2">
                        <b-col cols="12" lg="4">
                          <p class="text-dark mb-0">Jenis Donor</p>
                        </b-col>
                        <b-col cols="12" lg="1">
                          <p class="text-dark mb-0">:</p>
                        </b-col>
                        <b-col cols="12" lg="7">
                          <p class="text-dark mb-0">{{pasien.jenis_donor}}</p>
                        </b-col>
                      </b-row>
                      <b-row class="mb-2">
                        <b-col cols="12" lg="4">
                          <p class="text-dark mb-0">Petugas Pendaftaran</p>
                        </b-col>
                        <b-col cols="12" lg="1">
                          <p class="text-dark mb-0">:</p>
                        </b-col>
                        <b-col cols="12" lg="7">
                          <p class="text-dark mb-0">{{pasien.petugas_pendaftaran}}</p>
                        </b-col>
                      </b-row>
                      <b-row>
                        <b-col cols="12" lg="4">
                          <p class="text-dark mb-0">Petugas Pemeriksaan</p>
                        </b-col>
                        <b-col cols="12" lg="1">
                          <p class="text-dark mb-0">:</p>
                        </b-col>
                        <b-col cols="12" lg="7">
                          <p class="text-dark mb-0">{{pasien.petugas_pemeriksa}}</p>
                        </b-col>
                      </b-row>
                      </div>
                    </div>
                  </b-col>
                  <b-col cols="12" lg="6" class="pl-1">
                    <div class="shadow-sm border h-100">
                      <div class="px-4 py-3">
                        <p class="text-uppercase font-weight-bold text-dark mb-0">Pemeriksaan Lanjutan Pasien</p>
                      </div>
                      <hr class="m-0">
                      <div class="p-4">
                        <b-row class="mb-2">
                          <b-col class="pr-1">
                            <b-form-group label="Tensi: ">
                              <b-form-input id="exampleInputText1" type="text" v-model="result.tensi" placeholder="Tensi saat ini">
                              </b-form-input>
                            </b-form-group>
                          </b-col>
                          <b-col class="pr-1 pl-1">
                            <b-form-group label="Nadi: ">
                              <b-form-input id="exampleInputText1" type="text" v-model="result.nadi" placeholder="Nadi saat ini">
                              </b-form-input>
                            </b-form-group>
                          </b-col>
                          <b-col class="pl-1">
                            <b-form-group label="Suhu: ">
                              <b-form-input id="exampleInputText1" type="text" v-model="result.suhu" placeholder="Suhu saat ini">
                              </b-form-input>
                            </b-form-group>
                          </b-col>
                        </b-row>
                        <b-row>
                          <b-col class="pr-1">
                            <iq-card class="mb-0">
                              <b-form-group label="Ditolak/Diambil Sebanyak:" label-for="diambil" class="mb-0">
                                <b-form-select class="selected-cstm-utd" :options="diambil" v-model="result.jumlah_darah" @change="autoIsi"></b-form-select>
                              </b-form-group>
                            </iq-card>
                          </b-col>
                          <b-col class="pl-1">
                            <iq-card class="mb-0">
                              <b-form-group label="Kantong:" label-for="kantong" class="mb-0">
                                <b-form-select class="selected-cstm-utd" :options="kantong" v-model="result.jenis_kantong_darah" @change="autoIsi"></b-form-select>
                              </b-form-group>
                            </iq-card>
                          </b-col>
                        </b-row>
                      </div>
                    </div>
                  </b-col>
                </b-row>
              </div>
              <div class="btn-cancel" v-if="statusFinal !== 0">
                <b-button variant="warning" @click="resetFinal"><i class="fas fa-edit"></i> Perubahan Data</b-button>
              </div>
              <div v-if="statusFinal === 0">
                <hr>
                <b-row class="mb-3">
                  <b-col cols="12" lg="2" class="pr-1">
                    <iq-card class="mb-0">
                    <b-form-group label="Status:" label-for="pelayanan" class="mb-0">
                      <b-form-select class="selected-cstm-utd" :options="status" v-model="result.status" @change="autoIsi">
                      </b-form-select>
                    </b-form-group>
                    </iq-card>
                  </b-col>
                  <b-col class="pr-1 pl-1" cols="12" lg="2" v-if="result.status === 2">
                    <iq-card class="mb-0">
                    <b-form-group label="Alasan Tidak Lolos:" label-for="pelayanan" class="mb-0">
                      <b-form-select class="selected-cstm-utd" :options="alasanTdkLolos" v-model="result.alasan" @change="alasancek">
                      </b-form-select>
                    </b-form-group>
                    </iq-card>
                  </b-col>
                  <b-col class="pl-1" cols="12" lg="5" v-if="result.alasan === 4">
                    <iq-card class="mb-0">
                    <b-form-group label="Penjelasan Lain - Lain:" label-for="pelayanan" class="mb-0">
                      <b-form-input id="exampleInputText1" type="text" v-model="result.keterangan_lain_lain" placeholder="Deskripsi Lain - Lain"></b-form-input>
                    </b-form-group>
                    </iq-card>
                  </b-col>
                </b-row>
                <div>
                  <b-row v-if="result.status === 1">
                    <b-col>
                      <div class="mb-3">
                        <small class="text-danger">Peringatan : <span class="font-weight-bold">Harap Teliti Saat Pengisian</span></small>
                      </div>
                      <button type="button" @click="onSubmit" class="btn btn-success">
                        <i class="fas fa-save"></i> Simpan Hasil
                      </button>
                    </b-col>
                  </b-row>
                  <b-row v-else-if="result.status === 2 && result.alasan !== null">
                    <b-col>
                      <div class="mb-3">
                        <small class="text-danger">Peringatan : <span class="font-weight-bold">Harap Teliti Saat Pengisian</span></small>
                      </div>
                      <button type="button" @click="onSubmit" class="btn btn-success">
                        <i class="fas fa-save"></i> Simpan Hasil
                      </button>
                    </b-col>
                  </b-row>
                </div>
              </div>
            </div>
          </template>
        </iq-card>
      </b-col>
    </b-row>
    <b-row class="justify-content-center">
      <b-col md="2">
        <iq-card>
          <button type="button" @click="utdList" class="btn btn-primary btn-block">
            <i class="fas fa-undo"></i>  Kembali
          </button>
        </iq-card>
      </b-col>
    </b-row>
    <b-modal ref="modal-error" size="lg" title="PERINGATAN" ok-title="Saya Mengerti" :ok-only="true" @ok="backToList" :no-close-on-backdrop="true" :static="true" :hide-header-close="true" centered>
      <div class="text-center px-4 py-5">
        <p class="h5 text-danger font-weight-bold mb-3">{{error}}</p>
        <p class="text-dark mb-0">Harap Mengisi Berat Badan Pada Formulir Pendaftaran</p>
      </div>
    </b-modal>
    <b-modal ref="modal-error-2" class="shadow" hide-backdrop size="lg" title="PERINGATAN" ok-title="Saya Mengerti" :ok-only="true" @ok="backToList" :no-close-on-backdrop="true" :static="true" :hide-header-close="true" centered>
      <div class="text-center px-4 py-5">
        <p class="h5 text-danger font-weight-bold mb-3">Data Tidak Ditemukan</p>
        <p class="text-dark mb-0">Harap Mengisi Berat Badan Pada Formulir Pendaftaran Dan Golongan Pada Formulir Pemeriksaan</p>
      </div>
    </b-modal>
    <b-modal ref="modal-error-simpan" class="shadow" hide-backdrop size="lg" title="PERINGATAN" ok-title="Saya Mengerti" :ok-only="true" @ok="backToList" :no-close-on-backdrop="true" :static="true" :hide-header-close="true" centered>
      <div class="text-center px-4 py-5">
        <p class="h3 text-success font-weight-bold mb-3">OK</p>
        <p class="text-dark mb-0">Data Disimpan</p>
      </div>
    </b-modal>
    <b-modal ref="modal-sukses-kirim" class="shadow" hide-backdrop size="lg" title="PESAN" ok-title="Selesai" :ok-only="true" @ok="move" :no-close-on-backdrop="true" :static="true" :hide-header-close="true" centered>
      <div class="text-center px-4 py-5">
        <p class="h1 text-success font-weight-bold mb-3"><i class="fas fa-thumbs-up"></i></p>
        <p class="text-success mb-0">{{notifKirim}}</p>
      </div>
    </b-modal>
    <b-modal ref="modal-gagal-kirim" class="shadow" hide-backdrop size="lg" title="PERINGATAN" ok-title="Selesai" :ok-only="true" @ok="move" :no-close-on-backdrop="true" :static="true" :hide-header-close="true" centered>
      <div class="text-center px-4 py-5">
        <p class="h1 text-danger font-weight-bold mb-3"><i class="fas fa-exclamation"></i></p>
        <p class="text-danger mb-0">{{error}}</p>
      </div>
    </b-modal>
  </b-container>
</template>
<script>
import { xray } from '../../../config/pluginInit'
import UTD from '../../../services/utd.js'
import moment from 'moment'
export default {
  name: 'UtdDokter',
  data () {
    return {
      isLoading: false,
      id: null,
      tgl: null,
      notifKirim: '',
      error: '',
      pasien: '',
      result: {
        id_dft_trx_reservasi_utd_detail: this.$route.params.id,
        tensi: '',
        nadi: '',
        suhu: '',
        jumlah_darah: null,
        jenis_kantong_darah: null,
        status: 0,
        alasan: null,
        petugas_dokter: xray.currentUser().id,
        keterangan_lain_lain: ''
      },
      status: [
        { value: 0, text: 'Pilih Status', disabled: true },
        { value: 1, text: 'Lolos' },
        { value: 2, text: 'Tidak Lolos' }
      ],
      alasanTdkLolos: [
        { value: null, text: 'Pilih Alasan', disabled: true },
        { value: 1, text: 'Kurang Berat Badan' },
        { value: 2, text: 'HB' },
        { value: 3, text: 'Tensi' },
        { value: 4, text: 'Lain - Lain' }
      ],
      diambil: [
        { value: null, text: 'Pilih cc', disabled: true },
        { value: '350cc', text: '350cc' },
        { value: '450cc', text: '450cc' }
      ],
      kantong: [
        { value: null, text: 'Pilih Kantong', disabled: true },
        { value: 's', text: 'S' },
        { value: 'dk', text: 'DK' },
        { value: 'db', text: 'DB' },
        { value: 'tr', text: 'TR' },
        { value: 'qd', text: 'QD' }
      ],
      nama: '',
      umur: '',
      jenis_kelamin: '',
      no_resv: '',
      statusFinal: 0
    }
  },
  mounted () {
    xray.index()
    this.utlity()
  },
  methods: {
    utlity () {
      const id = this.$route.params.id
      const nama = this.$route.query.nama
      const statusfnl = this.$route.query.status
      const umur = this.$route.query.umur
      const jk = this.$route.query.jk
      const noresrv = this.$route.query.noresrv
      this.detailPasien(id)
      this.capNama(nama)
      this.capUmur(umur)
      this.capJK(jk)
      this.capResrv(noresrv)
      this.statusFinal = statusfnl
    },
    utdList () {
      this.$router.push({ name: 'utd.list' })
    },
    async detailPasien (id) {
      const data = {
        id: id
      }
      await UTD.viewDokter(data).then(response => {
        if (response.status === 200) {
          if (response.data.data[0].status_berat_badan !== null && response.data.data[0].hb !== null) {
            this.pasien = response.data.data[0]
            this.result.tensi = this.pasien.tensi
            this.result.nadi = this.pasien.nadi
            this.result.suhu = this.pasien.suhu
            this.result.jumlah_darah = this.pasien.jumlah_darah
            this.result.jenis_kantong_darah = this.pasien.jenis_kantong_darah
            this.result.status = this.pasien.status
            this.result.alasan = this.pasien.alasan
            this.result.keterangan_lain_lain = this.pasien.deskripsi_alasan
          } else {
            this.$refs['modal-error-2'].show()
          }
        }
      }).catch(error => {
        this.error = error.response.data.message
        this.$refs['modal-error-2'].show()
      })
    },
    async onSubmit (e) {
      this.isLoading = true
      const pasienUtd = this.result
      try {
        const pasienUtdResponse = await UTD.dokter(pasienUtd)
        if (pasienUtdResponse.status === 200) {
          this.isLoading = false
          this.notifKirim = pasienUtdResponse.data.message
          this.$refs['modal-sukses-kirim'].show()
        } else {
          this.isLoading = false
          this.error = 'Gagal'
          this.$refs['modal-gagal-kirim'].show()
        }
      } catch (err) {
        this.isLoading = false
        this.error = err.response.data.message
        this.$refs['modal-gagal-kirim'].show()
      }
    },
    autoIsi () {
      if (this.result.status === 1) {
        this.result.alasan = null
        this.result.keterangan_lain_lain = ''
      }
    },
    alasancek () {
      if (this.result.alasan !== 4) {
        this.result.keterangan_lain_lain = ''
      }
    },
    capNama (nama) {
      this.nama = nama
    },
    capJK (jk) {
      this.jenis_kelamin = jk
    },
    capResrv (noresrv) {
      this.no_resv = noresrv
    },
    capUmur (umur) {
      const date = moment(new Date(umur))
      const today = moment(new Date())
      const dob = moment(new Date(date))
      var duration = moment.duration(today.diff(dob))
      var age = duration.years().toString().padStart(2, '0') + ' Tahun ' + duration.months().toString().padStart(2, '0') + ' Bulan ' + duration.days().toString().padStart(2, '0') + ' Hari'
      this.umur = age
    },
    resetFinal () {
      this.statusFinal = 0
    },
    backToList () {
      this.$router.push({ name: 'utd.list' })
    },
    move () {
      this.$router.push({ name: 'utd.list' })
    }
  }
}
</script>
<style>
.utdDokter legend {
    color: black!important;
}
.utdDokter .form-control {
    color: black!important;
}
.utdDokter .modal-content {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.345)!important;
}
</style>
