<template>
  <div id="emr-index" class="emr-font-size mb-4">
    <b-container fluid>
      <div>
        <DetailInfo/>
      </div>
      <div>
        <HorizontalMenu/>
      </div>
    </b-container>
  </div>
</template>
<script>
import { xray } from '../../config/pluginInit'
import HorizontalMenu from './HorizontalMenu/index.vue'
import DetailInfo from './Components/DetailInfo.vue'

export default {
  name: 'MedicalRecord',
  components: {
    HorizontalMenu,
    DetailInfo
  },
  mounted () {
    xray.index()
  }
}
</script>

<style>
.emr-font-size {
  font-size: .8rem!important;
}
#emr-index .bg-abu {
  background-color: #F7F7F7;
}
</style>
