<template>
  <div>
    <div>
      <tab-nav :pills="true" id="pills-tab" class="justify-content-center border-0 shadow-none mb-1">
        <tab-nav-items class="font-weight-bold text-uppercase" :active="true" id="pengiriman-pengiriman-tab"
          href="#pengiriman-pengiriman" ariaControls="pengiriman-pengiriman" role="tab" :ariaSelected="true"
          title="Pengiriman" />
        <tab-nav-items class="font-weight-bold text-uppercase" :active="false" id="permintaan-historyPengirimanBarang-tab"
          href="#permintaan-historyPengirimanBarang" ariaControls="permintaan-historyPengirimanBarang" role="tab" :ariaSelected="false"
          title="Histori Pengiriman Barang" />
      </tab-nav>
      <tab-content id="pills-tabContent-2">
        <tab-content-item :active="true" id="pengiriman-pengiriman" aria-labelled-by="pengiriman-pengiriman-tab">
          <PengirimanChild></PengirimanChild>
        </tab-content-item>
        <tab-content-item :active="false" id="permintaan-historyPengirimanBarang" aria-labelled-by="permintaan-historyPengirimanBarang-tab">
          <HistoriPengirimanBarang></HistoriPengirimanBarang>
        </tab-content-item>
      </tab-content>
    </div>
  </div>
</template>
<script>
import PengirimanChild from './pengirimanChild/PengirimanChild'
import HistoriPengirimanBarang from './pengirimanChild/HistoryPengirimanBarang'
export default {
  name: 'Penerimaan',
  components: {
    PengirimanChild,
    HistoriPengirimanBarang
  }
}
</script>
