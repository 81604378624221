<template>
  <div id="katalog-rekanan" class="mb-4">
    <b-container fluid>
      <div>
        <iq-card>
          <template v-slot:body>
            <div class="filter-jenisAnggaran border p-2">
              <b-row class="align-items-center">
                <b-col cols="12" lg="3" class="d-flex flex-row w-100 mr-auto">
                  <div>
                    <h5 class="card-title font-weight-bold mb-0">Master Rekanan</h5>
                  </div>
                </b-col>
                <b-col cols="12" lg="9">
                  <div class="text-right">
                    <b-button class="custom-button-radius" size="sm" variant="primary" @click="tambahModal"><i
                        class="fas fa-plus"></i> Tambah</b-button>
                  </div>
                </b-col>
              </b-row>
            </div>
            <div class="table mb-0">
              <b-table class="mb-0" ref="table" :sort-by.sync="table.sortBy" :sort-desc.sync="table.sortDesc"
                :busy="table.isBusy" :items="table.items" :fields="table.fields" :current-page="table.currentPage"
                :per-page="table.perPage" :filter="table.filter" @filtered="onFiltered" stacked="md" bordered hover
                show-empty small striped>
                <template #cell(index)="data">
                  {{ (table.currentPage * table.perPage) - table.perPage + data.index + 1 }}
                </template>
                <template #cell(sts_aktif)="data">
                  <span v-if="data.sts_aktif = 1">Aktif</span>
                  <span v-else>Tidak Aktif</span>
                </template>
                <template #cell(action)="data">
                  <b-button @click="editModal(data.item.id)" variant=" mr-1 mb-1" size="lg">
                    <i class="fas fa-edit text-primary"></i>
                  </b-button>
                </template>
                <template #cell(sysdate_in)="data">
                  <span>{{ new Date(data.item.sysdate_in) | formatDate }}</span>
                </template>
                <template #cell(sysdate_updt)="data">
                  <span>{{ new Date(data.item.sysdate_updt) | formatDate }}</span>
                </template>
              </b-table>
            </div>
            <div class="pagination-jenisAnggaran border shadow-sm p-2 mb-2">
              <b-row class="align-items-center">
                <b-col cols="4" class="d-flex flex-row align-items-center">
                  <div class="w-100">
                    <b-pagination v-model="table.currentPage" :total-rows="table.totalRows" :per-page="table.perPage"
                      size="md" class="my-0"></b-pagination>
                  </div>
                  <div class="w-100">
                    <div>
                      <span class="border-left border-dark mr-3 py-1"></span>
                      <span @click="tableRefresh" class="katalog-cursor">
                        <i class="fas fa-sync text-secondary"></i>
                      </span>
                      <span class="border-right border-dark ml-3 py-1"></span>
                    </div>
                  </div>
                </b-col>
                <b-col cols="5">
                  <div class="d-flex flex-row justify-content-end align-items-center">
                    <div class="mr-1 w-75">
                      <form class="searchbox mb-0">
                        <input v-model="table.filter" type="search" class="form-control cstm-srch-jenisAnggaran"
                          id="filter-input" placeholder="[Cari]">
                      </form>
                    </div>
                    <div>
                      <b-form-select class="cstm-slct-jenisAnggaran" v-model="selected.filter"
                        :options="options.jns_filter" @change="filterchange"></b-form-select>
                    </div>
                    <!-- <div>
                      <b-form-select class="cstm-slct-jenisAnggaran" v-model="selected.filter" :options="options.optionsFilter" @change="filterchange"></b-form-select>
                    </div> -->
                  </div>
                </b-col>
                <b-col cols="3">
                  <div class="d-flex flex-row align-items-center justify-content-end">
                    <div class="mr-3">
                      <b-form-select class="cstm-select-jenisAnggaran" id="per-page-select" v-model="table.perPage"
                        :options="table.pageOptions"></b-form-select>
                    </div>
                    <div>
                      <p class="text-dark mb-0">Menampilkan {{ table.perPage }} dari {{ table.totalRows }} baris</p>
                    </div>
                  </div>
                </b-col>
              </b-row>
            </div>
          </template>
        </iq-card>
      </div>
    </b-container>
    <div id="modal-component-rekanan">
      <b-modal id="modal-tambah-rekanan" ref="modal-tambah-rekanan" size="xl" centered
        title="Tambah Master Rekanan" ok-title="Simpan Data" cancel-title="Tutup" :no-close-on-backdrop="true"
        :static="true" @close="clearData" @cancel="clearData" @ok="tambahSave">
        <b-form class="py-2">
          <b-row>
            <b-col>
              <b-form-group label-for="jenis rekanan" class="mb-1">
                <b-row>
                  <b-col cols="7" md="3">
                    <p class="custom-label-katalog text-dark mb-0">Jenis Rekanan</p>
                  </b-col>
                  <b-col>
                    <multiselect id="jenisanggaran" :show-labels="false" label="deskripsi" track-by="id"
                      placeholder="[ Masukuan Jenis Rekanan ]" :searchable="true" :allow-empty="false"
                      v-model="selected.selectjenisrekanan" @input="valuerekanan" :options="options.idrekanan">
                    </multiselect>
                  </b-col>
                </b-row>
              </b-form-group>
              <b-form-group label-for="Kode" class="mb-1">
                <b-row>
                  <b-col cols="7" md="3">
                    <p class="custom-label-katalog text-dark mb-0">Kode</p>
                  </b-col>
                  <b-col>
                    <b-form-input class="katalog-form-area-2" v-model="tambahrekanan.kode" placeholder="[Kode]"></b-form-input>
                  </b-col>
                </b-row>
              </b-form-group>
              <b-form-group label-for="alamat" class="mb-1">
                <b-row>
                  <b-col cols="7" md="3">
                    <p class="custom-label-katalog text-dark mb-0">Alamat</p>
                  </b-col>
                  <b-col>
                    <b-form-input class="katalog-form-area-2" v-model="tambahrekanan.alamat" placeholder="[Alamat]"></b-form-input>
                  </b-col>
                </b-row>
              </b-form-group>
              <b-form-group label-for="Telp Rekanan" class="mb-1">
                <b-row>
                  <b-col cols="7" md="3">
                    <p class="custom-label-katalog text-dark mb-0">Telepon Rekanan</p>
                  </b-col>
                  <b-col>
                    <b-form-input class="katalog-form-area-2" v-model="tambahrekanan.telp" type="number" placeholder="[Telepon Rekanan]"></b-form-input>
                  </b-col>
                </b-row>
              </b-form-group>
              <b-form-group label-for="email" class="mb-1">
                <b-row>
                  <b-col cols="7" md="3">
                    <p class="custom-label-katalog text-dark mb-0">Email</p>
                  </b-col>
                  <b-col>
                    <b-form-input class="katalog-form-area-2" v-model="tambahrekanan.email" placeholder="[Email]"></b-form-input>
                  </b-col>
                </b-row>
              </b-form-group>
              <b-form-group label-for="kepala cabang" class="mb-1">
                <b-row>
                  <b-col cols="7" md="3">
                    <p class="custom-label-katalog text-dark mb-0">Kepala Cabang</p>
                  </b-col>
                  <b-col>
                    <b-form-input class="katalog-form-area-2" v-model="tambahrekanan.kepala_cabang" placeholder="[Kepala Cabang]"></b-form-input>
                  </b-col>
                </b-row>
              </b-form-group>
              <b-form-group label-for="kontak telepon" class="mb-1">
                <b-row>
                  <b-col cols="7" md="3">
                    <p class="custom-label-katalog text-dark mb-0">Kontak Telepon</p>
                  </b-col>
                  <b-col>
                    <b-form-input class="katalog-form-area-2" v-model="tambahrekanan.cp_telp" type="number" placeholder="[CP Telepon]"></b-form-input>
                  </b-col>
                </b-row>
              </b-form-group>
              <b-form-group label-for="user in" class="mb-1">
                <b-row>
                  <b-col cols="7" md="3">
                    <p class="custom-label-katalog text-dark mb-0">User in</p>
                  </b-col>
                  <b-col>
                    <b-form-input class="katalog-form-area-2" v-model="preview.user_name" placeholder="Nama User"
                      readonly></b-form-input>
                  </b-col>
                </b-row>
              </b-form-group>
              <b-form-group label-for="status aktif" class="mb-1">
                <b-row>
                  <b-col cols="7" md="3">
                    <p class="custom-label-katalog text-dark mb-0">Status Aktif</p>
                  </b-col>
                  <b-col>
                    <b-form-radio-group class="mb-0"
                    v-model="tambahrekanan.sts_aktif"
                    :options="options.optionsStsAktif"
                  ></b-form-radio-group>
                  </b-col>
                </b-row>
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group label-for="nama rekanan" class="mb-1">
                <b-row>
                  <b-col cols="7" md="3">
                    <p class="custom-label-katalog text-dark mb-0">Nama Rekanan</p>
                  </b-col>
                  <b-col>
                    <b-form-input class="katalog-form-area-2" v-model="tambahrekanan.nama_rekanan" placeholder="[Nama Rekanan]"></b-form-input>
                  </b-col>
                </b-row>
              </b-form-group>
              <b-form-group label-for="npwp" class="mb-1">
                <b-row>
                  <b-col cols="7" md="3">
                    <p class="custom-label-katalog text-dark mb-0">Npwp</p>
                  </b-col>
                  <b-col>
                    <b-form-input class="katalog-form-area-2" v-model="tambahrekanan.npwp" type="number" placeholder="[Npwp]"></b-form-input>
                  </b-col>
                </b-row>
              </b-form-group>
              <b-form-group label-for="kota" class="mb-1">
                <b-row>
                  <b-col cols="7" md="3">
                    <p class="custom-label-katalog text-dark mb-0">Kota</p>
                  </b-col>
                  <b-col>
                    <b-form-input class="katalog-form-area-2" v-model="tambahrekanan.kota" placeholder="[kota]"></b-form-input>
                  </b-col>
                </b-row>
              </b-form-group>
              <b-form-group label-for="kota pos" class="mb-1">
                <b-row>
                  <b-col cols="7" md="3">
                    <p class="custom-label-katalog text-dark mb-0">Kode Pos</p>
                  </b-col>
                  <b-col>
                    <b-form-input class="katalog-form-area-2" v-model="tambahrekanan.kodepos" type="number" placeholder="[kota pos]"></b-form-input>
                  </b-col>
                </b-row>
              </b-form-group>
              <b-form-group label-for="fax" class="mb-1">
                <b-row>
                  <b-col cols="7" md="3">
                    <p class="custom-label-katalog text-dark mb-0">Fax</p>
                  </b-col>
                  <b-col>
                    <b-form-input class="katalog-form-area-2" v-model="tambahrekanan.fax" type="number" placeholder="[Fax]"></b-form-input>
                  </b-col>
                </b-row>
              </b-form-group>
              <b-form-group label-for="kontak nama" class="mb-1">
                <b-row>
                  <b-col cols="7" md="3">
                    <p class="custom-label-katalog text-dark mb-0">Kontak Nama</p>
                  </b-col>
                  <b-col>
                    <b-form-input class="katalog-form-area-2" v-model="tambahrekanan.cp_name" placeholder="[CP Name]"></b-form-input>
                  </b-col>
                </b-row>
              </b-form-group>
            </b-col>
          </b-row>
        </b-form>
        <div class="bg-dark px-2" v-if="preview.message">
          <p class="text-right small text-white mb-0">{{ preview.message }}</p>
        </div>
      </b-modal>
      <b-modal id="modal-edit-rekanan" ref="modal-edit-rekanan" size="xl" centered title="Edit Master Rekanan" ok-title="Perbarui Data" cancel-title="Tutup" :no-close-on-backdrop="true" :static="true" @close="clear" @cancel="clear" @ok="editSave">
        <b-form class="py-2">
          <b-row>
            <b-col>
              <b-form-group label-for="jenis rekanan" class="mb-1">
                <b-row>
                  <b-col cols="7" md="3">
                    <p class="custom-label-katalog text-dark mb-0">Jenis Rekanan</p>
                  </b-col>
                  <b-col>
                    <multiselect id="jenisanggaran" :show-labels="false" label="deskripsi"
                      placeholder="[ Masukuan Jenis Rekanan ]" :searchable="true" :allow-empty="false"
                      v-model="selected.selectEditjenisrekanan" @input="valueEditrekanan" :options="options.idrekanan">
                    </multiselect>
                  </b-col>
                </b-row>
              </b-form-group>
              <b-form-group label-for="Kode" class="mb-1">
                <b-row>
                  <b-col cols="7" md="3">
                    <p class="custom-label-katalog text-dark mb-0">Kode</p>
                  </b-col>
                  <b-col>
                    <b-form-input class="katalog-form-area-2" v-model="editrekanan.kode" placeholder="[Kode]"></b-form-input>
                  </b-col>
                </b-row>
              </b-form-group>
              <b-form-group label-for="alamat" class="mb-1">
                <b-row>
                  <b-col cols="7" md="3">
                    <p class="custom-label-katalog text-dark mb-0">Alamat</p>
                  </b-col>
                  <b-col>
                    <b-form-input class="katalog-form-area-2" v-model="editrekanan.alamat" placeholder="[Alamat]"></b-form-input>
                  </b-col>
                </b-row>
              </b-form-group>
              <b-form-group label-for="Telp Rekanan" class="mb-1">
                <b-row>
                  <b-col cols="7" md="3">
                    <p class="custom-label-katalog text-dark mb-0">Telepon Rekanan</p>
                  </b-col>
                  <b-col>
                    <b-form-input class="katalog-form-area-2" v-model="editrekanan.telp" type="number" placeholder="[Telepon Rekanan]"></b-form-input>
                  </b-col>
                </b-row>
              </b-form-group>
              <b-form-group label-for="email" class="mb-1">
                <b-row>
                  <b-col cols="7" md="3">
                    <p class="custom-label-katalog text-dark mb-0">Email</p>
                  </b-col>
                  <b-col>
                    <b-form-input class="katalog-form-area-2" v-model="editrekanan.email" placeholder="[Email]"></b-form-input>
                  </b-col>
                </b-row>
              </b-form-group>
              <b-form-group label-for="kepala cabang" class="mb-1">
                <b-row>
                  <b-col cols="7" md="3">
                    <p class="custom-label-katalog text-dark mb-0">Kepala Cabang</p>
                  </b-col>
                  <b-col>
                    <b-form-input class="katalog-form-area-2" v-model="editrekanan.kepala_cabang" placeholder="[Kepala Cabang]"></b-form-input>
                  </b-col>
                </b-row>
              </b-form-group>
              <b-form-group label-for="kontak telepon" class="mb-1">
                <b-row>
                  <b-col cols="7" md="3">
                    <p class="custom-label-katalog text-dark mb-0">Kontak Telepon</p>
                  </b-col>
                  <b-col>
                    <b-form-input class="katalog-form-area-2" v-model="editrekanan.cp_telp" type="number" placeholder="[CP Telepon]"></b-form-input>
                  </b-col>
                </b-row>
              </b-form-group>
              <b-form-group label-for="user in" class="mb-1">
                <b-row>
                  <b-col cols="7" md="3">
                    <p class="custom-label-katalog text-dark mb-0">User in</p>
                  </b-col>
                  <b-col>
                    <b-form-input class="katalog-form-area-2" v-model="preview.user_name" placeholder="Nama User"
                      readonly></b-form-input>
                  </b-col>
                </b-row>
              </b-form-group>
              <b-form-group label-for="status aktif" class="mb-1">
                <b-row>
                  <b-col cols="7" md="3">
                    <p class="custom-label-katalog text-dark mb-0">Status Aktif</p>
                  </b-col>
                  <b-col>
                    <b-form-radio-group class="mb-0"
                    v-model="editrekanan.sts_aktif"
                    :options="options.optionsStsAktif"
                  ></b-form-radio-group>
                  </b-col>
                </b-row>
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group label-for="nama rekanan" class="mb-1">
                <b-row>
                  <b-col cols="7" md="3">
                    <p class="custom-label-katalog text-dark mb-0">Nama Rekanan</p>
                  </b-col>
                  <b-col>
                    <b-form-input class="katalog-form-area-2" v-model="editrekanan.nama_rekanan" placeholder="[Nama Rekanan]"></b-form-input>
                  </b-col>
                </b-row>
              </b-form-group>
              <b-form-group label-for="npwp" class="mb-1">
                <b-row>
                  <b-col cols="7" md="3">
                    <p class="custom-label-katalog text-dark mb-0">Npwp</p>
                  </b-col>
                  <b-col>
                    <b-form-input class="katalog-form-area-2" v-model="editrekanan.npwp" type="number" placeholder="[Npwp]"></b-form-input>
                  </b-col>
                </b-row>
              </b-form-group>
              <b-form-group label-for="kota" class="mb-1">
                <b-row>
                  <b-col cols="7" md="3">
                    <p class="custom-label-katalog text-dark mb-0">Kota</p>
                  </b-col>
                  <b-col>
                    <b-form-input class="katalog-form-area-2" v-model="editrekanan.kota" placeholder="[kota]"></b-form-input>
                  </b-col>
                </b-row>
              </b-form-group>
              <b-form-group label-for="kota pos" class="mb-1">
                <b-row>
                  <b-col cols="7" md="3">
                    <p class="custom-label-katalog text-dark mb-0">Kode Pos</p>
                  </b-col>
                  <b-col>
                    <b-form-input class="katalog-form-area-2" v-model="editrekanan.kodepos" type="number" placeholder="[kota pos]"></b-form-input>
                  </b-col>
                </b-row>
              </b-form-group>
              <b-form-group label-for="fax" class="mb-1">
                <b-row>
                  <b-col cols="7" md="3">
                    <p class="custom-label-katalog text-dark mb-0">Fax</p>
                  </b-col>
                  <b-col>
                    <b-form-input class="katalog-form-area-2" v-model="editrekanan.fax" type="number" placeholder="[Fax]"></b-form-input>
                  </b-col>
                </b-row>
              </b-form-group>
              <b-form-group label-for="kontak nama" class="mb-1">
                <b-row>
                  <b-col cols="7" md="3">
                    <p class="custom-label-katalog text-dark mb-0">Kontak Nama</p>
                  </b-col>
                  <b-col>
                    <b-form-input class="katalog-form-area-2" v-model="editrekanan.cp_name" placeholder="[CP Name]"></b-form-input>
                  </b-col>
                </b-row>
              </b-form-group>
            </b-col>
          </b-row>
        </b-form>
        <div class="bg-dark px-2" v-if="preview.message">
          <p class="text-right small text-white mb-0">{{ preview.message }}</p>
        </div>
      </b-modal>
      <b-modal ref="modal-delete-jenisAnggaran" size="md" centered title="Konfirmasi" ok-title="Hapus Data"
        ok-variant="danger" cancel-title="Tutup" @ok="deleteSave">
        <div class="py-3">
          <p class="h6 text-center text-dark mb-0">Yakin Ingin Menghapus Data ?</p>
        </div>
      </b-modal>
      <b-modal ref="modal-notif-rekanan" size="md" centered title="Notifikasi" ok-title="Tutup"
        :hide-footer="true" :hide-header="true">
        <div class="pb-3 px-4">
          <div v-if="preview.messageValue === '1'">
            <lottie :options="preview.lottie.defaultOptions" :autoPlay="true" :width="150" :height="150" />
          </div>
          <div v-else>
            <lottie :options="preview.lottie.defaultOptions2" :autoPlay="true" :width="150" :height="150" />
          </div>
          <p class="h6 text-dark text-center mb-0 mt-2">{{ preview.message }}</p>
        </div>
      </b-modal>
    </div>
  </div>
</template>
<script>
import { xray } from '../../../../config/pluginInit'
import Lottie from 'vue-lottie'
import jwtdecode from 'jwt-decode'
import Multiselect from 'vue-multiselect'
import Service from '../../../../services/fatmahost/katalog/index'
import animationLogo from '../../../../assets/lottie/checked.json'
import animationLogo2 from '../../../../assets/lottie/failed.json'

export default {
  name: 'MrtJenisAnggaran',
  components: {
    Lottie,
    Multiselect
  },
  data () {
    return {
      table: {
        isBusy: false,
        items: this.list,
        sortBy: '',
        sortDesc: true,
        fields: [
          { label: 'No', key: 'index', class: 'text-center' },
          { label: 'Kode', key: 'kode', sortable: true },
          { label: 'Nama rekanan', key: 'nama_rekanan', sortable: true },
          { label: 'Jenis Rekanan', key: 'desc_jns_rekanan', sortable: true },
          { label: 'User In', key: 'desc_userid_in' },
          { label: 'Last Update', key: 'sysdate_updt' },
          { label: 'Aksi', key: 'action', class: 'text-center' }
        ],
        filter: '',
        currentPage: null,
        perPage: null,
        pageOptions: [10, 25, 50, 100],
        totalRows: null
      },
      tambahrekanan: {
        object: 'rekanan',
        jns_rekanan: '',
        kode: '',
        npwp: '',
        alamat: '',
        kota: '',
        kodepos: '',
        telp: '',
        fax: '',
        email: '',
        kepala_cabang: '',
        cp_name: '',
        cp_telp: '',
        sts_aktif: '',
        userid_in: jwtdecode(localStorage.getItem('token')).user.id.toString()
      },
      editrekanan: {
        object: 'rekanan',
        jns_rekanan: '',
        kode: '',
        npwp: '',
        alamat: '',
        kota: '',
        kodepos: '',
        telp: '',
        fax: '',
        email: '',
        kepala_cabang: '',
        cp_name: '',
        cp_telp: '',
        sts_aktif: '',
        userid_updt: jwtdecode(localStorage.getItem('token')).user.id.toString()
      },
      options: {
        optionsStsAktif: [
          { text: 'Aktif', value: '1' },
          { text: 'Tidak Aktif', value: '0' }
        ],
        optionsFilter: [
          { text: 'Kode', value: '1' },
          { text: 'Jenis Anggaran', value: '2' }
        ],
        jns_filter: [
          { text: 'Kode', value: '1' },
          { text: 'nama rekanan', value: '2' }
        ],
        idrekanan: []
      },
      selected: {
        filter: '1',
        selectjenisrekanan: [],
        selectEditjenisrekanan: []
      },
      preview: {
        lottie: {
          defaultOptions: { animationData: animationLogo, loop: true },
          defaultOptions2: { animationData: animationLogo2, loop: true }
        },
        user_name: jwtdecode(localStorage.getItem('token')).user.name,
        edit_id: '',
        delete_id: '',
        message: '',
        messageValue: ''
      }
    }
  },
  mounted () {
    xray.index()
    this.jenisrekanan()
  },
  methods: {
    tableRefresh () {
      this.$refs.table.refresh()
    },
    valuerekanan (param) {
      this.tambahrekanan.jns_rekanan = param.id.toString()
    },
    valueEditrekanan (param) {
      this.editrekanan.jns_rekanan = param.id.toString()
    },
    async jenisrekanan () {
      const response = await Service.getJnsRekanan()
      this.options.idrekanan = response.data.data
    },
    clearData () {
      this.preview.message = ''
      this.tambahrekanan.jns_rekanan = ''
      this.tambahrekanan.kode = ''
      this.tambahrekanan.nama_rekanan = ''
      this.tambahrekanan.npwp = ''
      this.tambahrekanan.alamat = ''
      this.tambahrekanan.kota = ''
      this.tambahrekanan.kodepos = ''
      this.tambahrekanan.telp = ''
      this.tambahrekanan.fax = ''
      this.tambahrekanan.email = ''
      this.tambahrekanan.kepala_cabang = ''
      this.tambahrekanan.cp_name = ''
      this.tambahrekanan.cp_telp = ''
      this.tambahrekanan.sts_aktif = ''

      this.editrekanan.jns_rekanan = ''
      this.editrekanan.kode = ''
      this.editrekanan.nama_rekanan = ''
      this.editrekanan.npwp = ''
      this.editrekanan.alamat = ''
      this.editrekanan.kota = ''
      this.editrekanan.kodepos = ''
      this.editrekanan.telp = ''
      this.editrekanan.fax = ''
      this.editrekanan.email = ''
      this.editrekanan.kepala_cabang = ''
      this.editrekanan.cp_name = ''
      this.editrekanan.cp_telp = ''
      this.editrekanan.sts_aktif = ''
      this.selected.selectEditjenisrekanan = []
    },
    async list (ctx) {
      let sortfield = ''
      let sorting = ''
      if (ctx.filter === '') {
        ctx.filter = null
      }
      if (ctx.sortBy) {
        sortfield = ctx.sortBy
      } else {
        sortfield = ''
      }
      if (ctx.sortDesc === true) {
        sorting = 'DESC'
      } else if (ctx.sortDesc === false) {
        sorting = 'ASC'
      } else {
        sorting = ''
      }
      try {
        this.isBusy = true
        let ssParams = `{"xparam0":"${sortfield}","xparam1":"${sorting}"}`
        let filter = `{"xparam0":"","xparam1":"","xparam2":"","xparam3":"","xparam4":"","xparam5":"","xparam6":"","xparam7":""}`
        const jnsKode = this.selected.filter
        if (jnsKode === '1') {
          filter = `{"xparam0":"${this.table.filter}","xparam1":"","xparam2":"","xparam3":"","xparam4":"","xparam5":"","xparam6":"","xparam7":""}`
        } else {
          filter = `{"xparam0":"","xparam1":"${this.table.filter}","xparam2":"","xparam3":"","xparam4":"","xparam5":"","xparam6":"","xparam7":""}`
        }
        const response = await Service.getRekananRtAll(ctx.currentPage - 1, ctx.perPage, filter, ssParams)
        this.isBusy = false
        this.table.totalRows = response.data.pagination.total
        this.table.perPage = response.data.pagination.perPage
        this.table.currentPage = response.data.pagination.current
        return response.data.data
      } catch (error) {
        this.isBusy = false
        return []
      }
    },
    onFiltered (filteredItems) {
      this.table.totalRows = filteredItems.length
      this.table.currentPage = 1
    },
    filterchange () {
      this.table.filter = ''
    },
    tambahModal () {
      this.clearMessage()
      this.$refs['modal-tambah-rekanan'].show()
    },
    async tambahSave (bvModalEvent) {
      bvModalEvent.preventDefault()
      try {
        if (this.tambahrekanan.kode !== '' && this.tambahrekanan.alamat !== '' && this.tambahrekanan.kepala_cabang !== '' && this.tambahrekanan.npwp !== '' && this.tambahrekanan.sts_aktif !== '') {
          let data = this.tambahrekanan
          let response = await Service.saveRekananRt(data)
          if (response.status === 200) {
            this.preview.message = response.data.message
            this.preview.messageValue = '1'
            this.$refs['modal-tambah-rekanan'].hide()
            this.$refs['modal-notif-rekanan'].show()
          } else {
            this.preview.message = response.data.message
            this.preview.messageValue = '2'
          }
          this.$refs.table.refresh()
        } else {
          this.preview.message = 'Data tidak boleh kosong'
          this.preview.messageValue = '2'
        }
        this.$refs.table.refresh()
      } catch (error) {
        this.preview.message = error.message
        this.preview.messageValue = '2'
      }
    },
    async editModal (id) {
      this.clearMessage()
      let ids = id
      this.preview.message = ''
      this.preview.edit_id = ids
      try {
        const response = await Service.showMrtRekananRt(ids)
        this.editrekanan.kode = response.data.data[0].kode
        this.editrekanan.nama_rekanan = response.data.data[0].nama_rekanan
        this.selected.selectEditjenisrekanan = response.data.data[0].jns_rekanan ? { id: response.data.data[0].jns_rekanan.toString(), deskripsi: response.data.data[0].desc_jns_rekanan } : []
        this.editrekanan.jns_rekanan = response.data.data[0].jns_rekanan ? response.data.data[0].jns_rekanan.toString() : ''
        this.editrekanan.alamat = response.data.data[0].alamat
        this.editrekanan.npwp = response.data.data[0].npwp
        this.editrekanan.kota = response.data.data[0].kota
        this.editrekanan.kodepos = response.data.data[0].kodepos
        this.editrekanan.telp = response.data.data[0].telp
        this.editrekanan.fax = response.data.data[0].fax
        this.editrekanan.email = response.data.data[0].email
        this.editrekanan.kepala_cabang = response.data.data[0].kepala_cabang
        this.editrekanan.cp_name = response.data.data[0].cp_name
        this.editrekanan.cp_telp = response.data.data[0].cp_telp
        this.editrekanan.sts_aktif = response.data.data[0].sts_aktif
      } catch (error) {
        this.preview.message = error.message
        this.preview.messageValue = '2'
        // this.$refs['modal-notif-jenisAnggaran'].show()
      }
      this.$refs['modal-edit-rekanan'].show()
    },
    async editSave (bvModalEvent) {
      bvModalEvent.preventDefault()
      try {
        let id = this.preview.edit_id
        let data = this.editrekanan
        const response = await Service.updateRekananRt(id, data)
        if (response.data.statcode === 0) {
          this.preview.message = response.data.message
          this.preview.messageValue = '1'
          this.$refs['modal-edit-rekanan'].hide()
          this.$refs['modal-notif-rekanan'].show()
          this.$refs.table.refresh()
          this.clear()
        } else {
          this.preview.message = response.data.message
          this.preview.messageValue = '2'
        }
      } catch (error) {
        this.preview.message = error.message
        this.preview.messageValue = '2'
      }
    },
    deleteModal (id) {
      this.preview.delete_id = id
      this.$refs['modal-delete-jenisAnggaran'].show()
    },
    async deleteSave () {
      let id = this.preview.delete_id
      try {
        const response = await Service.deleteJenisAnggaran(id)
        if (response.data.statcode === 0) {
          this.preview.message = response.data.message
          this.preview.messageValue = '1'
        } else {
          this.preview.message = response.data.message
          this.preview.messageValue = '2'
        }
        this.$refs['modal-notif-jenisAnggaran'].show()
        this.$refs.table.refresh()
      } catch (error) {
        this.preview.message = error.message
        this.preview.messageValue = '2'
        this.$refs['modal-notif-jenisAnggaran'].show()
      }
    },
    clear () {
    },
    clearMessage () {
      this.preview.messageValue = ''
      this.preview.message = ''
    }
  }
}
</script>
<style>
#modal-tambah-rekanan .custom-control-label,
#modal-edit-rekanan .custom-control-label {
  font-size: 12px;
  margin-top: 0;
}

#modal-tambah-rekanan .multiselect__single,
#modal-edit-rekanan .multiselect__single {
  font-size: 12px !important;
}

#modal-tambah-rekanan .multiselect__placeholder,
#modal-edit-rekanan .multiselect__placeholder {
  font-size: 12px !important;
}

#modal-tambah-rekanan .multiselect__option,
#modal-edit-rekanan .multiselect__option {
  font-size: 12px;
}

#modal-tambah-rekanan .multiselect__tags,
#modal-edit-rekanan .multiselect__tags {
  padding: 5px 40px 0 8px !important;
  height: 38px !important;
  min-height: 38px !important;
}

#modal-tambah-rekanan .modal-body,
#modal-edit-rekanan .modal-body {
  background: #3399331b
}

#modal-tambah-rekanan .modal-header,
#modal-edit-rekanan .modal-header {
  background: #3399333a;
}

#modal-tambah-rekanan .modal-footer,
#modal-edit-rekanan .modal-footer {
  background: #3399333a;
}

#katalog-rekanan .page-link {
  font-size: 14px;
  padding-top: 5px;
  padding-bottom: 5px;
  margin-right: 10px !important;
}

#katalog-rekanan .custom-button-radius {
  border: 1px solid rgba(0, 0, 0, 0.112);
  border-radius: 5px !important;
}

#katalog-rekanan .table td {
  font-size: 12px;
  font-weight: 500;
  padding-top: 0px;
  padding-bottom: 0px;
}

#katalog-rekanan .table th {
  font-size: 13px;
  font-weight: 400;
}

#katalog-rekanan .cstm-select-jenisAnggaran {
  font-size: 13px;
  height: 38px !important;
  border-radius: 5px;
}

#katalog-rekanan .cstm-srch-jenisAnggaran {
  font-size: 13px;
  height: 38px !important;
  border-radius: 5px;
}

#katalog-rekanan .cstm-slct-jenisAnggaran {
  font-size: 13px;
  height: 38px !important;
  border-radius: 5px;
}

.new-cstm-cntrl-katalog {
  background: white !important;
  color: black !important;
  border-radius: 5px !important;
  font-size: 12px !important;
  height: 38px !important;
}

.katalog-form-area {
  color: black !important;
  border-radius: 5px !important;
  font-size: 12px !important;
}

#katalog-rekanan .pagination-sm .page-link {
  color: gray !important;
}

#katalog-rekanan .page-item.active .page-link {
  color: white !important;
}

#katalog-rekanan .page-item.active .page-link {
  color: white !important;
}

.custom-radius-add-katalog {
  border-radius: 5px;
  cursor: pointer;
}

.katalog-select-flex-left {
  width: 90%;
}

.katalog-select-flex-right {
  width: 10%;
}

.katalog-form-satuan-jenisAnggaran-left-right {
  width: 40%;
}

.katalog-form-satuan-jenisAnggaran-center {
  width: 15%;
}

.hr-katalog-custom {
  border-top: 1px solid rgba(0, 0, 0, 0.199) !important;
  margin: 0 !important;
}

#katalog-rekanan .pagination-jenisAnggaran .page-link {
  border: none;
  color: gray !important;
  margin-right: 15px;
}

#katalog-rekanan .pagination-jenisAnggaran .page-item.active .page-link {
  background-color: transparent;
  border-color: transparent;
  color: #089bab !important;
  font-weight: bold;
}
</style>
