<template>
  <div id="katalog-buffer" class="mb-4">
    <b-container fluid>
      <div>
        <iq-card>
          <template v-slot:body>
            <div class="filter-buffer border p-2">
              <b-row class="align-items-center">
                <b-col cols="12" lg="3" class="d-flex flex-row w-100 mr-auto">
                  <div>
                    <h5 class="card-title font-weight-bold mb-0">Master Buffer Gudang</h5>
                  </div>
                </b-col>
                <b-col cols="12" lg="9">
                  <div class="text-right">
                    <b-button class="custom-button-radius" size="sm" variant="primary" @click="tambahModal"><i class="fas fa-plus"></i> Tambah</b-button>
                  </div>
                </b-col>
              </b-row>
            </div>
            <div class="table mb-0">
              <b-table class="mb-0" ref="table" :sort-by.sync="table.sortBy" :sort-desc.sync="table.sortDesc" :busy="table.isBusy" :items="table.items" :fields="table.fields"
                :current-page="table.currentPage" :per-page="table.perPage" :filter="table.filter" @filtered="onFiltered" stacked="md"
                bordered hover show-empty small striped>
                <template #cell(index)="data">
                {{ (table.currentPage*table.perPage)-table.perPage + data.index + 1 }}
                </template>
                <template #cell(action)="data">
                  <b-button
                    @click="editModal(data.item.id)"
                    variant=" mr-1 mb-1" size="lg">
                    <i class="fas fa-edit text-primary"></i>
                  </b-button>
                  <b-button
                    @click="deleteModal(data.item.id)"
                    variant=" mr-1 mb-1" size="lg">
                    <i class="fas fa-trash-alt text-danger"></i>
                  </b-button>
                </template>
                <template #cell(sysdate_updt)="data">
                  <span>{{ new Date(data.item.sysdate_updt) | formatDate }}</span>
                </template>
              </b-table>
            </div>
            <div class="pagination-buffer border shadow-sm p-2 mb-2">
              <b-row class="align-items-center">
                <b-col cols="4" class="d-flex flex-row align-items-center">
                  <div class="w-100">
                    <b-pagination
                      v-model="table.currentPage"
                      :total-rows="table.totalRows"
                      :per-page="table.perPage"
                      size="md"
                      class="my-0"
                    ></b-pagination>
                  </div>
                  <div class="w-100">
                    <div>
                      <span class="border-left border-dark mr-3 py-1"></span>
                      <span @click="tableRefresh" class="katalog-cursor">
                        <i class="fas fa-sync text-secondary"></i>
                      </span>
                      <span class="border-right border-dark ml-3 py-1"></span>
                    </div>
                  </div>
                </b-col>
                <b-col cols="5">
                  <div class="d-flex flex-row justify-content-end align-items-center">
                    <div class="mr-1 w-75">
                      <form class="searchbox mb-0">
                        <input v-model="table.filter" type="search" class="form-control cstm-srch-buffer" id="filter-input"
                          placeholder="[Cari]">
                      </form>
                    </div>
                    <div>
                      <b-form-select class="cstm-slct-buffer" v-model="selected.filter" :options="options.optionsFilter" @change="filterchange"></b-form-select>
                    </div>
                  </div>
                </b-col>
                <b-col cols="3">
                  <div class="d-flex flex-row align-items-center justify-content-end">
                    <div class="mr-3">
                      <b-form-select
                        class="cstm-select-buffer"
                        id="per-page-select"
                        v-model="table.perPage"
                        :options="table.pageOptions"
                      ></b-form-select>
                    </div>
                    <div>
                      <p class="text-dark mb-0">Menampilkan {{ table.perPage }} dari {{ table.totalRows }} baris</p>
                    </div>
                  </div>
                </b-col>
              </b-row>
            </div>
          </template>
        </iq-card>
      </div>
    </b-container>
    <div id="modal-component-buffer">
      <b-modal id="modal-tambah-buffer" ref="modal-tambah-buffer" size="lg" centered title="Tambah Buffer" ok-title="Simpan Data" cancel-title="Tutup" :no-close-on-backdrop="true" :static="true" @close="clear" @cancel="clear" @ok="tambahSave">
        <b-form>
          <b-form-group class="mb-2">
            <b-row class="align-items-center">
              <b-col cols="2">
                <span>
                  <p class="small text-dark mb-0">ID / Kode</p>
                </span>
              </b-col>
              <b-col class="pr-1">
                <b-form-input class="new-cstm-cntrl-katalog" v-model="tambahBuffer.id_katalog" placeholder="[ID Katalog]"></b-form-input>
              </b-col>
              <b-col class="pr-1 pl-1">
                <b-form-input class="new-cstm-cntrl-katalog" v-model="tambahBuffer.id_generik" placeholder="[ID Generik]"></b-form-input>
              </b-col>
              <b-col class="pl-1">
                <b-form-input class="new-cstm-cntrl-katalog" v-model="tambahBuffer.katalog_kode" placeholder="[Kode Katalog]"></b-form-input>
              </b-col>
            </b-row>
          </b-form-group>
          <b-form-group class="mb-2">
            <b-row class="align-items-center">
              <b-col cols="2">
                <span>
                  <p class="small text-dark mb-0">Jenis / Lead</p>
                </span>
              </b-col>
              <b-col class="pr-1">
                <b-form-input class="new-cstm-cntrl-katalog" v-model="tambahBuffer.jenis_moving" placeholder="[Jenis Moving]"></b-form-input>
              </b-col>
              <b-col class="pl-1">
                <b-form-input class="new-cstm-cntrl-katalog" v-model="tambahBuffer.lead_time" placeholder="[Lead Time]"></b-form-input>
              </b-col>
            </b-row>
          </b-form-group>
          <b-form-group class="mb-2">
            <b-row class="align-items-center">
              <b-col cols="2">
                <span>
                  <p class="small text-dark mb-0">Persen</p>
                </span>
              </b-col>
              <b-col class="pr-1">
                <b-form-input class="new-cstm-cntrl-katalog" v-model="tambahBuffer.persen_buffer" placeholder="[% Buffer]"></b-form-input>
              </b-col>
              <b-col class="pl-1">
                <b-form-input class="new-cstm-cntrl-katalog" v-model="tambahBuffer.persen_leadtime" placeholder="[% Lead Time]"></b-form-input>
              </b-col>
            </b-row>
          </b-form-group>
          <b-form-group class="mb-2">
            <b-row class="align-items-center">
              <b-col cols="2">
                <span>
                  <p class="small text-dark mb-0">Jumlah</p>
                </span>
              </b-col>
              <b-col class="pr-1">
                <b-form-input class="new-cstm-cntrl-katalog" v-model="tambahBuffer.jumlah_avg" placeholder="[+ Avg]"></b-form-input>
              </b-col>
              <b-col class="pl-1 pr-1">
                <b-form-input class="new-cstm-cntrl-katalog" v-model="tambahBuffer.jumlah_buffer" placeholder="[+ Buffer]"></b-form-input>
              </b-col>
              <b-col class="pl-1 pr-1">
                <b-form-input class="new-cstm-cntrl-katalog" v-model="tambahBuffer.jumlah_leadtime" placeholder="[+ Lead Time]"></b-form-input>
              </b-col>
              <b-col class="pl-1">
                <b-form-input class="new-cstm-cntrl-katalog" v-model="tambahBuffer.jumlah_rop" placeholder="[+ Rop]"></b-form-input>
              </b-col>
            </b-row>
          </b-form-group>
          <b-form-group class="mb-2">
            <b-row class="align-items-center">
              <b-col cols="2">
                <span>
                  <p class="small text-dark mb-0">User Update</p>
                </span>
              </b-col>
              <b-col>
                <b-form-input class="new-cstm-cntrl-katalog" v-model="preview.user_name" placeholder="[Nama User]" readonly></b-form-input>
              </b-col>
            </b-row>
          </b-form-group>
          <b-form-group class="mb-0">
            <b-row class="align-items-center">
              <b-col cols="2">
                <span>
                  <p class="small text-dark mb-0">Status Aktif</p>
                </span>
              </b-col>
              <b-col>
                <b-form-radio-group class="mb-0"
                  v-model="tambahBuffer.status"
                  :options="options.optionsStsAktif"
                  ></b-form-radio-group>
              </b-col>
            </b-row>
          </b-form-group>
        </b-form>
        <div class="bg-dark px-2 mt-2" v-if="preview.messageValue === '2'">
          <p class="text-right small text-white mb-0">{{ preview.message }}</p>
        </div>
      </b-modal>
      <b-modal id="modal-edit-buffer" ref="modal-edit-buffer" size="lg" centered title="Edit Buffer" ok-title="Perbarui Data" cancel-title="Tutup" :no-close-on-backdrop="true" :static="true" @close="clear" @cancel="clear" @ok="editSave">
        <b-form>
          <b-form-group class="mb-2">
            <b-row class="align-items-center">
              <b-col cols="2">
                <span>
                  <p class="small text-dark mb-0">ID / Kode</p>
                </span>
              </b-col>
              <b-col class="pr-1">
                <b-form-input class="new-cstm-cntrl-katalog" v-model="editBuffer.id_katalog" placeholder="[ID Katalog]"></b-form-input>
              </b-col>
              <b-col class="pr-1 pl-1">
                <b-form-input class="new-cstm-cntrl-katalog" v-model="editBuffer.id_generik" placeholder="[ID Generik]"></b-form-input>
              </b-col>
              <b-col class="pl-1">
                <b-form-input class="new-cstm-cntrl-katalog" v-model="editBuffer.katalog_kode" placeholder="[Kode Katalog]"></b-form-input>
              </b-col>
            </b-row>
          </b-form-group>
          <b-form-group class="mb-2">
            <b-row class="align-items-center">
              <b-col cols="2">
                <span>
                  <p class="small text-dark mb-0">Jenis / Lead</p>
                </span>
              </b-col>
              <b-col class="pr-1">
                <b-form-input class="new-cstm-cntrl-katalog" v-model="editBuffer.jenis_moving" placeholder="[Jenis Moving]"></b-form-input>
              </b-col>
              <b-col class="pl-1">
                <b-form-input class="new-cstm-cntrl-katalog" v-model="editBuffer.lead_time" placeholder="[Lead Time]"></b-form-input>
              </b-col>
            </b-row>
          </b-form-group>
          <b-form-group class="mb-2">
            <b-row class="align-items-center">
              <b-col cols="2">
                <span>
                  <p class="small text-dark mb-0">Persen</p>
                </span>
              </b-col>
              <b-col class="pr-1">
                <b-form-input class="new-cstm-cntrl-katalog" v-model="editBuffer.persen_buffer" placeholder="[% Buffer]"></b-form-input>
              </b-col>
              <b-col class="pl-1">
                <b-form-input class="new-cstm-cntrl-katalog" v-model="editBuffer.persen_leadtime" placeholder="[% Lead Time]"></b-form-input>
              </b-col>
            </b-row>
          </b-form-group>
          <b-form-group class="mb-2">
            <b-row class="align-items-center">
              <b-col cols="2">
                <span>
                  <p class="small text-dark mb-0">Jumlah</p>
                </span>
              </b-col>
              <b-col class="pr-1">
                <b-form-input class="new-cstm-cntrl-katalog" v-model="editBuffer.jumlah_avg" placeholder="[+ Avg]"></b-form-input>
              </b-col>
              <b-col class="pr-1 pl-1">
                <b-form-input class="new-cstm-cntrl-katalog" v-model="editBuffer.jumlah_buffer" placeholder="[+ Buffer]"></b-form-input>
              </b-col>
              <b-col class="pr-1 pl-1">
                <b-form-input class="new-cstm-cntrl-katalog" v-model="editBuffer.jumlah_leadtime" placeholder="[+ Lead Time]"></b-form-input>
              </b-col>
              <b-col class="pl-1">
                <b-form-input class="new-cstm-cntrl-katalog" v-model="editBuffer.jumlah_rop" placeholder="[+ Rop]"></b-form-input>
              </b-col>
            </b-row>
          </b-form-group>
          <b-form-group class="mb-2">
            <b-row class="align-items-center">
              <b-col cols="2">
                <span>
                  <p class="small text-dark mb-0">User Update</p>
                </span>
              </b-col>
              <b-col>
                <b-form-input class="new-cstm-cntrl-katalog" v-model="preview.user_name" placeholder="[Nama User]" readonly></b-form-input>
              </b-col>
            </b-row>
          </b-form-group>
          <b-form-group class="mb-0">
            <b-row class="align-items-center">
              <b-col cols="2">
                <span>
                  <p class="small text-dark mb-0">Status Aktif</p>
                </span>
              </b-col>
              <b-col>
                <b-form-radio-group class="mb-0"
                  v-model="editBuffer.status"
                  :options="options.optionsStsAktif"
                  ></b-form-radio-group>
              </b-col>
            </b-row>
          </b-form-group>
        </b-form>
        <div class="bg-dark px-2 mt-2" v-if="preview.messageValue === '2'">
          <p class="text-right small text-white mb-0">{{ preview.message }}</p>
        </div>
      </b-modal>
      <b-modal ref="modal-delete-buffer" size="md" centered title="Konfirmasi" ok-title="Hapus Data" ok-variant="danger" cancel-title="Tutup" @ok="deleteSave">
        <div class="py-3">
          <p class="h6 text-center text-dark mb-0">Yakin Ingin Menghapus Data ?</p>
        </div>
      </b-modal>
      <b-modal ref="modal-notif-buffer" size="md" centered title="Notifikasi" ok-title="Tutup" :hide-footer="true" :hide-header="true">
        <div class="pb-3 px-4">
          <div v-if="preview.messageValue === '1'">
            <lottie :options="preview.lottie.defaultOptions" :autoPlay="true" :width="150" :height="150" />
          </div>
          <div v-else>
            <lottie :options="preview.lottie.defaultOptions2" :autoPlay="true" :width="150" :height="150" />
          </div>
          <p class="h6 text-dark text-center mb-0 mt-2">{{ preview.message }}</p>
        </div>
      </b-modal>
    </div>
  </div>
</template>
<script>
import { xray } from '../../../config/pluginInit'
import jwtdecode from 'jwt-decode'
import Services from '../../../services/fatmahost/katalog/index'
import Lottie from 'vue-lottie'
import animationLogo from '../../../assets/lottie/checked.json'
import animationLogo2 from '../../../assets/lottie/failed.json'

export default {
  name: 'Buffer',
  components: {
    Lottie
  },
  data () {
    return {
      table: {
        isBusy: false,
        items: this.list,
        sortBy: '',
        sortDesc: true,
        fields: [
          { label: 'No', key: 'index', class: 'text-center' },
          { label: 'ID Katalog', key: 'mbg.id_katalog', sortable: true },
          { label: 'Kode Katalog', key: 'mbg.katalog_kode', sortable: true },
          { label: 'ID Generik', key: 'mbg.id_generik', sortable: true },
          { label: 'Jenis Moving', key: 'mbg.jenis_moving', sortable: true },
          { label: 'Lead Time', key: 'mbg.lead_time', sortable: true },
          { label: '% Buffer', key: 'mbg.persen_buffer', sortable: true },
          { label: '% Lead Time', key: 'mbg.persen_leadtime', sortable: true },
          { label: '+ Avg', key: 'mbg.jumlah_avg', sortable: true },
          { label: '+ Buffer', key: 'mbg.jumlah_buffer', sortable: true },
          { label: '+ Lead Time', key: 'mbg.jumlah_leadtime', sortable: true },
          { label: '+ Rop', key: 'mbg.jumlah_rop', sortable: true },
          { label: 'User Update', key: 'up.name', sortable: true },
          { label: 'Last Update', key: 'mbg.sysdate_updt', sortable: true },
          { label: 'Aksi', key: 'action', class: 'text-center' }
        ],
        filter: null,
        currentPage: null,
        perPage: null,
        pageOptions: [10, 25, 50, 100],
        totalRows: null
      },
      tambahBuffer: {
        object: 'buffer-gudang',
        id_katalog: '',
        katalog_kode: '',
        id_generik: '',
        jenis_moving: '',
        lead_time: '',
        persen_buffer: '',
        persen_leadtime: '',
        jumlah_avg: '',
        jumlah_buffer: '',
        jumlah_leadtime: '',
        jumlah_rop: '',
        status: '1',
        userid_updt: jwtdecode(localStorage.getItem('token')).user.id.toString()
      },
      editBuffer: {
        object: 'buffer-gudang',
        id_katalog: '',
        katalog_kode: '',
        id_generik: '',
        jenis_moving: '',
        lead_time: '',
        persen_buffer: '',
        persen_leadtime: '',
        jumlah_avg: '',
        jumlah_buffer: '',
        jumlah_leadtime: '',
        jumlah_rop: '',
        status: '1',
        userid_updt: jwtdecode(localStorage.getItem('token')).user.id.toString()
      },
      options: {
        optionsStsAktif: [
          { text: 'Aktif', value: '1' },
          { text: 'Tidak Aktif', value: '0' }
        ],
        optionsFilter: [
          { text: 'ID Katalog', value: '1' },
          { text: 'Kode Katalog', value: '2' }
        ]
      },
      selected: {
        filter: '1'
      },
      preview: {
        lottie: {
          defaultOptions: { animationData: animationLogo, loop: true },
          defaultOptions2: { animationData: animationLogo2, loop: true }
        },
        user_name: jwtdecode(localStorage.getItem('token')).user.name,
        edit_id: '',
        delete_id: '',
        message: '',
        messageValue: ''
      }
    }
  },
  mounted () {
    xray.index()
  },
  methods: {
    tableRefresh () {
      this.$refs.table.refresh()
    },
    clear () {
      this.tambahBuffer.id_katalog = ''
      this.tambahBuffer.katalog_kode = ''
      this.tambahBuffer.id_generik = ''
      this.tambahBuffer.jenis_moving = ''
      this.tambahBuffer.lead_time = ''
      this.tambahBuffer.persen_buffer = ''
      this.tambahBuffer.persen_leadtime = ''
      this.tambahBuffer.jumlah_avg = ''
      this.tambahBuffer.jumlah_buffer = ''
      this.tambahBuffer.jumlah_leadtime = ''
      this.tambahBuffer.jumlah_rop = ''
      this.tambahBuffer.status = '1'
      //
    },
    async list (ctx) {
      let filterType = this.selected.filter
      let sortfield = ''
      let sorting = ''
      if (ctx.filter === '') {
        ctx.filter = null
      }
      if (ctx.sortBy) {
        sortfield = ctx.sortBy
      } else {
        sortfield = ''
      }
      if (ctx.sortDesc === true) {
        sorting = 'DESC'
      } else if (ctx.sortDesc === false) {
        sorting = 'ASC'
      } else {
        sorting = ''
      }
      this.isBusy = true
      try {
        const response = await Services.listBuffer(ctx.currentPage - 1, ctx.perPage, ctx.filter, filterType, sortfield, sorting)
        this.isBusy = false
        this.table.totalRows = response.data.pagination.total
        this.table.perPage = response.data.pagination.perPage
        this.table.currentPage = response.data.pagination.current
        return response.data.data
      } catch (error) {
        this.isBusy = false
        return []
      }
    },
    onFiltered (filteredItems) {
      this.table.totalRows = filteredItems.length
      this.table.currentPage = 1
    },
    filterchange () {
      this.table.filter = ''
    },
    tambahModal () {
      this.clearMessage()
      this.$refs['modal-tambah-buffer'].show()
    },
    async tambahSave (bvModalEvent) {
      bvModalEvent.preventDefault()
      try {
        let data = this.tambahBuffer
        const response = await Services.createBuffer(data)
        if (response.data.statcode === 0) {
          this.preview.message = response.data.message
          this.preview.messageValue = '1'
          this.$refs['modal-tambah-buffer'].hide()
          this.$refs['modal-notif-buffer'].show()
          this.$refs.table.refresh()
          this.clear()
        } else {
          this.preview.messageValue = '2'
          this.preview.message = response.data.message
        }
      } catch (error) {
        this.preview.messageValue = '2'
        this.preview.message = error.message
      }
    },
    async editModal (id) {
      this.clearMessage()
      let ids = id
      this.preview.edit_id = ids
      try {
        const response = await Services.showBuffer(ids)
        this.editBuffer.id_katalog = response.data.data[0].id_katalog
        this.editBuffer.katalog_kode = response.data.data[0].katalog_kode
        this.editBuffer.id_generik = response.data.data[0].id_generik
        this.editBuffer.jenis_moving = response.data.data[0].jenis_moving
        this.editBuffer.lead_time = response.data.data[0].lead_time
        this.editBuffer.persen_buffer = response.data.data[0].persen_buffer
        this.editBuffer.persen_leadtime = response.data.data[0].persen_leadtime
        this.editBuffer.jumlah_avg = response.data.data[0].jumlah_avg
        this.editBuffer.jumlah_buffer = response.data.data[0].jumlah_buffer
        this.editBuffer.jumlah_leadtime = response.data.data[0].jumlah_leadtime
        this.editBuffer.jumlah_rop = response.data.data[0].jumlah_rop
      } catch (error) {
        this.preview.message = error.message
        this.preview.messageValue = '2'
        // this.$refs['modal-notif-buffer'].show()
      }
      this.$refs['modal-edit-buffer'].show()
    },
    async editSave (bvModalEvent) {
      bvModalEvent.preventDefault()
      try {
        let id = this.preview.edit_id
        let data = this.editBuffer
        const response = await Services.editBuffer(id, data)
        if (response.data.statcode === 0) {
          this.preview.messageValue = '1'
          this.preview.message = response.data.message
          this.$refs['modal-edit-buffer'].hide()
          this.$refs['modal-notif-buffer'].show()
          this.$refs.table.refresh()
          this.clear()
        } else {
          this.preview.messageValue = '2'
          this.preview.message = response.data.message
        }
      } catch (error) {
        this.preview.messageValue = '2'
        this.preview.message = error.message
      }
    },
    deleteModal (id) {
      this.preview.delete_id = id
      this.$refs['modal-delete-buffer'].show()
    },
    async deleteSave () {
      let id = this.preview.delete_id
      try {
        const response = await Services.deleteBuffer(id)
        if (response.data.rowcount === 1 && response.data.statcode === 0) {
          this.preview.message = response.data.message
          this.preview.messageValue = '1'
        } else if (response.data.rowcount === 0 && response.data.statcode === 20001) {
          this.preview.message = response.data.message
          this.preview.messageValue = '2'
        }
        this.$refs['modal-notif-buffer'].show()
        this.$refs.table.refresh()
      } catch (error) {
        this.preview.message = error.message
        this.preview.messageValue = '2'
        this.$refs['modal-notif-buffer'].show()
      }
    },
    clearMessage () {
      this.preview.messageValue = ''
      this.preview.message = ''
    }
  }
}
</script>
<style>
#modal-tambah-buffer .custom-control-label, #modal-edit-buffer .custom-control-label {
    font-size: 12px;
    margin-top: 0;
}
#modal-tambah-buffer .multiselect__single, #modal-edit-buffer .multiselect__single {
  font-size: 12px!important;
}
#modal-tambah-buffer .multiselect__placeholder, #modal-edit-buffer .multiselect__placeholder {
  font-size: 12px!important;
}
#modal-tambah-buffer .multiselect__option, #modal-edit-buffer .multiselect__option {
    font-size: 12px;
}
#modal-tambah-buffer .multiselect__tags, #modal-edit-buffer .multiselect__tags {
  padding: 5px 40px 0 8px!important;
  height: 38px!important;
  min-height: 38px!important;
}
#modal-tambah-buffer  .modal-body, #modal-edit-buffer .modal-body {
  background: #3399331b
}

#modal-tambah-buffer  .modal-header, #modal-edit-buffer .modal-header {
  background: #3399333a;
}

#modal-tambah-buffer  .modal-footer, #modal-edit-buffer .modal-footer {
  background: #3399333a;
}
#katalog-buffer .page-link {
  font-size: 14px;
  padding-top: 5px;
  padding-bottom: 5px;
  margin-right: 10px!important;
}
#katalog-buffer .custom-button-radius {
  border: 1px solid rgba(0, 0, 0, 0.112);
  border-radius: 5px!important;
}
#katalog-buffer .table td {
    font-size: 12px;
    font-weight: 500;
    padding-top: 0px;
    padding-bottom: 0px;
}
#katalog-buffer .table th {
    font-size: 13px;
    font-weight: 400;
}
#katalog-buffer .cstm-select-buffer {
  font-size: 13px;
  height: 38px!important;
  border-radius: 5px;
}
#katalog-buffer .cstm-srch-buffer {
  font-size: 13px;
  height: 38px!important;
  border-radius: 5px;
}
#katalog-buffer .cstm-slct-buffer {
  font-size: 13px;
  height: 38px!important;
  border-radius: 5px;
}
.new-cstm-cntrl-katalog {
  background: white!important;
  color: black!important;
  border-radius: 5px!important;
  font-size: 12px!important;
  height: 38px!important;
}
.katalog-form-area {
  color: black!important;
  border-radius: 5px!important;
  font-size: 12px!important;
}
#katalog-buffer .pagination-sm .page-link {
  color: gray!important;
}
#katalog-buffer .page-item.active .page-link {
  color: white!important;
}
#katalog-buffer .page-item.active .page-link {
  color: white!important;
}
.custom-radius-add-katalog {
  border-radius: 5px;
  cursor: pointer;
}
.katalog-select-flex-left {
  width: 90%;
}
.katalog-select-flex-right {
  width: 10%;
}
.katalog-form-satuan-pabrik-left-right {
  width: 40%;
}
.katalog-form-satuan-pabrik-center {
  width: 15%;
}
.hr-katalog-custom {
  border-top: 1px solid rgba(0, 0, 0, 0.199)!important;
  margin: 0!important;
}
#katalog-buffer .pagination-buffer .page-link {
    border: none;
    color: gray!important;
    margin-right: 15px;
}
#katalog-buffer .pagination-buffer .page-item.active .page-link {
    background-color: transparent;
    border-color: transparent;
    color: #089bab!important;
    font-weight: bold;
}
</style>
