<template>
  <b-container fluid>
    <form>
      <b-row class="justify-content-center">
        <b-col lg="12">
          <iq-card body-class="p-0">
            <template v-slot:body>
              <div class="iq-edit-list">
                <tab-nav :pills="true" class="iq-edit-profile d-flex">
                  <tab-nav-items class="col-md-6 p-0" :active="true" href="#personal-information"
                    title="Personal Information" />
                  <tab-nav-items class="col-md-6 p-0" :active="false" href="#chang-pwd" title=" Change Password" />
                </tab-nav>
              </div>
            </template>
          </iq-card>
        </b-col>
        <b-col lg="12">
          <div class="iq-edit-list-data">
            <tab-content id="pills-tabContent-2">
              <tab-content-item :active="true" id="personal-information">
                <iq-card>
                  <template v-slot:headerTitle>
                    <h4 class="card-title">Personal Information</h4>
                  </template>
                  <template v-slot:body>
                    <!-- <b-form-group class="row align-items-center">
                        <b-col md="12">
                          <div class="profile-img-edit">
                            <b-img :src="user.profile_image" class="profile-pic height-150 width-150" alt="profile-pic"/>
                            <input type="hidden" v-model="user.profile_image">
                            <div class="p-image">
                              <div class="position-relative">
                                <i class="ri-pencil-line upload-button"></i>
                                <b-form-file class="h-100 position-absolute" accept="image/*" style="opacity: 0;" @change="previewImage"></b-form-file>
                              </div>
                            </div>
                          </div>
                        </b-col>
                      </b-form-group> -->
                    <b-row align-v="center mb-2">
                      <b-col cols="2 text-dark">NIP</b-col>
                      <b-col cols="1 text-dark">:</b-col>
                      <b-col cols="8 text-dark font-weight-bold">{{ pegawai.NIP}}</b-col>
                    </b-row>
                    <b-row align-v="center mb-2">
                      <b-col cols="2 text-dark">Nama Lengkap</b-col>
                      <b-col cols="1 text-dark">:</b-col>
                      <b-col cols="8 text-dark font-weight-bold">{{ pegawai.NAMA}}</b-col>
                    </b-row>
                    <b-row align-v="center mb-2">
                      <b-col cols="2 text-dark">Tanggal Lahir</b-col>
                      <b-col cols="1 text-dark">:</b-col>
                      <b-col cols="8 text-dark font-weight-bold">{{ pegawai.TANGGAL_LAHIR}}</b-col>
                    </b-row>
                    <b-row align-v="center mb-2">
                      <b-col cols="2 text-dark">Tempat Lahir</b-col>
                      <b-col cols="1 text-dark">:</b-col>
                      <b-col cols="8 text-dark font-weight-bold">{{ pegawai.TEMPAT_LAHIR}}</b-col>
                    </b-row>
                    <b-row align-v="center mb-2">
                      <b-col cols="2 text-dark">Jenis Kelamin</b-col>
                      <b-col cols="1 text-dark">:</b-col>
                      <b-col cols="8 text-dark font-weight-bold">{{ pegawai.NAMA_JENKEL}}</b-col>
                    </b-row>
                    <b-row align-v="center mb-2">
                      <b-col cols="2 text-dark">Agama</b-col>
                      <b-col cols="1 text-dark">:</b-col>
                      <b-col cols="8 text-dark font-weight-bold">{{ pegawai.NAMA_AGAMA}}</b-col>
                    </b-row>
                    <b-row align-v="center mb-2">
                      <b-col cols="2 text-dark">Profesi</b-col>
                      <b-col cols="1 text-dark">:</b-col>
                      <b-col cols="8 text-dark font-weight-bold">{{ pegawai.NAMA_PROFESI}}</b-col>
                    </b-row>
                    <b-row align-v="center">
                      <b-col cols="2 text-dark">SMF</b-col>
                      <b-col cols="1 text-dark">:</b-col>
                      <b-col cols="8 text-dark font-weight-bold">{{ pegawai.NAMA_SMF}}</b-col>
                    </b-row>
                  </template>
                </iq-card>
              </tab-content-item>
              <tab-content-item :active="false" id="chang-pwd">
                <iq-card>
                  <template v-slot:headerTitle>
                    <h4 class="card-title">Change Password</h4>
                  </template>
                  <template v-slot:body>
                    <b-form-group class="col-md-12" label="New Password:" label-for="pass">
                      <ValidationProvider name="Password" rules="confirmed:repeat_password" v-slot="{ errors }">
                        <b-form-input v-model="user.password" type="password" placeholder="Password"
                          :class="(errors.length > 0 ? ' is-invalid' : '')"></b-form-input>
                        <div class="invalid-feedback">
                          <span>{{ errors[0] }}</span>
                        </div>
                      </ValidationProvider>
                    </b-form-group>
                    <b-form-group class="col-md-12" label="Repeat Password:" label-for="rpass">
                      <ValidationProvider vid="repeat_password" name="Repeat Password" rules="required"
                        v-slot="{ errors }">
                        <b-form-input v-model="user.repeat_password" type="password" placeholder="Repeat Password"
                          :class="(errors.length > 0 ? ' is-invalid' : '')"></b-form-input>
                        <div class="invalid-feedback">
                          <span>{{ errors[0] }}</span>
                        </div>
                      </ValidationProvider>
                    </b-form-group>
                  </template>
                  <hr>
                  <div class="pb-3 text-center">
                    <b-button @click="changePassword" variant="primary" class="mr-2">Submit</b-button>
                    <b-button type="reset" variant="none" class="iq-bg-danger">Reset</b-button>
                  </div>
                </iq-card>
              </tab-content-item>
            </tab-content>
          </div>
        </b-col>
      </b-row>
    </form>
  </b-container>
</template>
<script>
import { xray } from '../../config/pluginInit'
import Service from '../../services/fatmahost/index'

export default {
  name: 'ProfileEdit',
  mounted () {
    xray.index()
    this.viewPegawai()
  },
  data () {
    return {
      pegawai: [],
      user: {
        id: xray.currentUser().id,
        password: ''
      },
      currentPassword: ''
    }
  },
  methods: {
    async viewPegawai () {
      const id = xray.currentUser().pegawai_id
      await Service.pegawai.findId(id).then(response => {
        this.pegawai = response.data.data[0]
      }).catch(error => {
        this.error = error.response || error.response
      })
    },
    async changePassword () {
      const data = {
        pegawai_id: xray.currentUser().id,
        password: this.user.password
      }
      this.$bvModal.msgBoxConfirm('Apakah Anda Yakin Ingin Merubah Password?', {
        title: 'Konfirmasi',
        size: 'md',
        buttonSize: 'md',
        okVariant: 'danger',
        okTitle: 'Ya',
        cancelTitle: 'Tidak',
        footerClass: 'p-2',
        hideHeaderClose: true,
        centered: true
      }).then(value => {
        if (value === true) {
          Service.users.forgot(data).then(res => {
            Service.auth.logout()
            localStorage.removeItem('token')
            localStorage.clear()
            this.$router.replace({ name: 'auth1.signin' })
          })
        }
      }).catch(err => {
        this.error = err.response
      })
    }
  }
}
</script>
