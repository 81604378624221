<template>
  <div id="Inventory-Pengembalian-Barang" class="mb-4">
    <b-container fluid>
      <div>
        <iq-card>
          <template v-slot:body>
            <div class="filter-brand border p-2">
              <b-row class="align-items-center">
                <b-col>
                  <div>
                    <h5 class="card-title font-weight-bold mb-0">Pengembalian Barang</h5>
                  </div>
                </b-col>
                <b-col>
                  <div class="text-right">
                    <!-- {{ 'test' + bitControl.bitcontrol2 }} -->
                    <b-button class="custom-button-radius" size="sm" variant="primary" id="show-btn" @click="showModal"><i
                        class="fas fa-plus"></i>Tambah</b-button>
                  </div>
                </b-col>
              </b-row>
            </div>
            <div class="table mb-0">
              <b-table class="mb-0" ref="table" :sort-by.sync="table.sortBy" :sort-desc.sync="table.sortDesc"
                :filter="table.filter" @filtered="onFiltered" :busy="table.isBusy" :current-page="table.currentPage"
                :per-page="table.perPage" stacked="md" bordered hover show-empty small striped :items="table.items"
                :fields="table.fields">
                <template #cell(index)="data">
                  {{ (table.currentPage*table.perPage)-table.perPage + data.index + 1 }}
                </template>
                <template #cell(tanggal)="data">
                  {{ new Date(data.item.tanggal) | formatDate }}
                </template>
                <template #cell(ver_status)="data">
                  <span class="text-secondary" v-if="data.item.ver_status === 1 || data.item.ver_status === '1'"><i class="fas fa-check-circle text-success fa-lg"></i></span>
                  <span v-else-if="data.item.ver_status === 0 || data.item.ver_status === '0'"><i class="fas fa-spinner text-secondary fa-lg"></i></span>
                </template>
                <template #cell(action)="data">
                  <b-button @click="editModal(data.item.id)" variant="bg-transparent" size="lg">
                    <i class="fas fa-edit text-primary"></i>
                  </b-button>
                </template>
              </b-table>
            </div>
            <div class="pagination-inventory border shadow-sm p-2 mb-2">
              <b-row class="align-items-center">
                <b-col cols="4" class="d-flex flex-row align-items-center">
                  <div class="w-100">
                    <b-pagination v-model="table.currentPage" :total-rows="table.totalRows" :per-page="table.perPage"
                      size="md" class="my-0"></b-pagination>
                  </div>
                  <div class="w-100">
                    <div>
                      <span class="border-left border-dark mr-3 py-1"></span>
                      <span @click="tableRefresh" class="katalog-cursor">
                        <i class="fas fa-sync text-secondary"></i>
                      </span>
                      <span class="border-right border-dark ml-3 py-1"></span>
                    </div>
                  </div>
                </b-col>
                <b-col cols="5">
                  <div class="d-flex flex-row justify-content-end align-items-center">
                    <div class="mr-1 w-75">
                      <form class="searchbox mb-0">
                        <input v-model="table.filter" type="search" class="form-control cstm-srch-brand" id="filter-input"
                          placeholder="[Cari]">
                      </form>
                    </div>
                    <div>
                      <b-form-select class="cstm-slct-brand" v-model="selected.filter" :options="options.optionsFilter"
                        @change="filterchange"></b-form-select>
                    </div>
                  </div>
                </b-col>
                <b-col cols="3">
                  <div class="d-flex flex-row align-items-right justify-content-end">
                    <div>
                      <p class="text-dark mb-0">Menampilkan {{ table.perPage }} dari {{ table.totalRows }} baris</p>
                    </div>
                  </div>
                </b-col>
              </b-row>
            </div>
          </template>
        </iq-card>
      </div>
    </b-container>
    <b-modal id="modal-tambah-inventory-kembali" ref="modal-tambah-inventory-kembali" size="xl" centered :no-close-on-backdrop="true" :static="true"
      title="Edit Data Pengembalian Barang Pinjam" ok-only ok-title="Tutup" ok-variant="secondary" @ok="clearData" @close="clearData" @cancel="clearData"
      cancel-title="Tutup">
      <div class="bg-white filter-brand border p-2">
        <b-row class="align-items-center">
          <b-col>
            <div>
              <h5 class="card-title font-weight-bold mb-0">Pengembalian Barang Pinjam</h5>
            </div>
          </b-col>
        </b-row>
      </div>
      <div class="bg-white table mb-0">
        <b-table class="mb-0" ref="table" :sort-by.sync="tablePinjam.sortBy" :sort-desc.sync="tablePinjam.sortDesc"
          :filter="tablePinjam.filter" @filtered="onFilteredPj" :busy="tablePinjam.isBusy" :current-page="tablePinjam.currentPage"
          :per-page="tablePinjam.perPage" stacked="md" bordered hover show-empty small striped :items="tablePinjam.items"
          :fields="tablePinjam.fields">
          <template #cell(index)="data">
            {{ (table.currentPage*table.perPage)-table.perPage + data.index + 1 }}
          </template>
          <template #cell(tanggal)="data">
            {{ new Date(data.item.tanggal) | formatDate }}
          </template>
          <template #cell(ver_status)="data">
            <span class="text-secondary" v-if="data.item.ver_status === 1 || data.item.ver_status === '1'"><i class="fas fa-check-circle text-success fa-lg"></i></span>
            <span v-else-if="data.item.ver_status === 0 || data.item.ver_status === '0'"><i class="fas fa-spinner text-secondary fa-lg"></i></span>
          </template>
          <template #cell(action)="data">
            <b-button variant="bg-transparent" size="lg" @click="modalCreate(data.item.id)">
              <i class="fas fa-plus text-primary font-weight-bold"></i>
            </b-button>
          </template>
        </b-table>
      </div>
      <div class="bg-white pagination-inventory border shadow-sm p-2 mb-2">
        <b-row class="align-items-center">
          <b-col cols="4" class="d-flex flex-row align-items-center">
            <div class="w-100">
              <b-pagination v-model="tablePinjam.currentPage" :total-rows="tablePinjam.totalRows" :per-page="tablePinjam.perPage"
                size="md" class="my-0"></b-pagination>
            </div>
            <div class="w-100">
              <div>
                <span class="border-left border-dark mr-3 py-1"></span>
                <span class="katalog-cursor">
                  <i class="fas fa-sync text-secondary"></i>
                </span>
                <span class="border-right border-dark ml-3 py-1"></span>
              </div>
            </div>
          </b-col>
          <b-col cols="5">
            <div class="d-flex flex-row justify-content-end align-items-center">
              <div class="mr-1 w-75">
                <form class="searchbox mb-0">
                  <input v-model="tablePinjam.filter" type="search" class="form-control cstm-srch-brand" id="filter-input"
                    placeholder="[Cari]">
                </form>
              </div>
              <div>
                <b-form-select class="cstm-slct-brand" v-model="selected.filterPj" :options="options.optionsFilterPJ"
                  @change="filterchangePj"></b-form-select>
              </div>
            </div>
          </b-col>
          <b-col cols="3">
            <div class="d-flex flex-row align-items-right justify-content-end">
              <div>
                <p class="text-dark mb-0">Menampilkan {{ tablePinjam.perPage }} dari {{ tablePinjam.totalRows }} baris</p>
              </div>
            </div>
          </b-col>
        </b-row>
      </div>
    </b-modal>
    <b-modal id="modal-edit-inventory-kembali" ref="modal-edit-inventory-kembali" size="xl" @close="clearData" @cancel="clearData" centered
      title="Edit Data" ok-title="Perbarui Data" cancel-title="Tutup" :no-close-on-backdrop="true" :static="true"
      @ok="editSave">
      <b-form class="py-2">
        <b-row class="mt-3">
          <b-col>
            <b-form-group label-for="keterangan" class="mb-1">
              <b-row>
                <b-col cols="12" md="2">
                  <p class="custom-label-katalog text-dark mb-0">Nama Pbf</p>
                </b-col>
                <b-col>
                  <b-form-input class="new-cstm-cntrl-inventori" v-model="preview.namapbf" id="pbf-e" :readonly="editKoreksi.header.ver_status === '1' ? true : false"
                    type="text" placeholder="[ Pbf ]">
                  </b-form-input>
                </b-col>
              </b-row>
            </b-form-group>
            <b-form-group label-for="keterangan" class="mb-1">
              <b-row>
                <b-col cols="12" md="2">
                  <p class="custom-label-katalog text-dark mb-0">Keterangan</p>
                </b-col>
                <b-col>
                  <b-form-textarea type="text" v-model="editKoreksi.header.keterangan" row="5"
                    class="katalog-form-area-2" placeholder="[ Keterangan ]" :readonly="editKoreksi.header.ver_status === '1' ? true : false">
                  </b-form-textarea>
                </b-col>
              </b-row>
            </b-form-group>
            <b-form-group label-for="tanggal" class="mb-0">
              <b-row>
                <b-col cols="12" md="2">
                  <p class="custom-label-katalog text-dark mb-0">Tanggal</p>
                </b-col>
                <b-col>
                  <flat-pickr :disabled="editKoreksi.header.ver_status === '1' ? true : false" :config="config" @on-change="tglEvent($event, 'e')" v-model="preview.tanggal" value="" placeholder="[ Pilih Tanggal ]" class="w-100 custom-flatpicker calender-small">
                  </flat-pickr>
                </b-col>
              </b-row>
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group label-for="tanggal" class="mb-1">
              <b-row>
                <b-col cols="12" md="3">
                  <p class="custom-label-katalog text-dark mb-0">KODE</p>
                </b-col>
                <b-col cols="12" md="1">
                  <p class="custom-label-katalog text-dark mb-0">:</p>
                </b-col>
                <b-col>
                  <div>
                    <p class="h3 text-dark mb-0">{{ preview.kode }}</p>
                  </div>
                </b-col>
              </b-row>
            </b-form-group>
            <hr class="m-0">
            <b-form-group label-for="tanggal" class="mb-1 mt-2">
              <b-row>
                <b-col cols="12" md="3">
                  <p class="custom-label-katalog text-dark mb-0">Ruangan</p>
                </b-col>
                <b-col cols="12" md="1">
                  <p class="custom-label-katalog text-dark mb-0">:</p>
                </b-col>
                <b-col>
                  <div>
                    <p class="custom-label-katalog text-dark mb-0">{{ preview.nama_ruangan }}</p>
                  </div>
                </b-col>
              </b-row>
            </b-form-group>
            <b-form-group label-for="tanggal" class="mb-1">
              <b-row>
                <b-col cols="12" md="3">
                  <p class="custom-label-katalog text-dark mb-0">User Input</p>
                </b-col>
                <b-col cols="12" md="1">
                  <p class="custom-label-katalog text-dark mb-0">:</p>
                </b-col>
                <b-col>
                  <div>
                    <p class="custom-label-katalog text-dark mb-0">{{ preview.petugas_inp }}</p>
                  </div>
                </b-col>
              </b-row>
            </b-form-group>
            <b-form-group label-for="tanggal" class="mb-0">
              <b-row>
                <b-col cols="12" md="3">
                  <p class="custom-label-katalog text-dark mb-0">User Varifikasi</p>
                </b-col>
                <b-col cols="12" md="1">
                  <p class="custom-label-katalog text-dark mb-0">:</p>
                </b-col>
                <b-col>
                  <div>
                    <p class="custom-label-katalog text-dark mb-0">{{ preview.petugas_ver }}</p>
                  </div>
                </b-col>
              </b-row>
            </b-form-group>
          </b-col>
        </b-row>
      </b-form>
      <hr class="m-0">
      <div>
        <b-row>
          <b-col cols="12">
            <div class="table-responsive-xl">
              <table class="table bg-white table-bordered table-sm m-0 p-0">
                <thead>
                  <tr>
                    <th scope="col" style="vertical-align: center;" class="text-center">
                      <p class="mb-0 font-weight-normal text-center">No.</p>
                    </th>
                    <th scope="col" style="vertical-align: center;" class="text-center">
                      <p class="mb-0 font-weight-normal text-center">Nama Katalog</p>
                    </th>
                    <th scope="col" style="vertical-align: center;" class="text-center">
                      <p class="mb-0 font-weight-normal text-center">Jumlah Pinjam</p>
                    </th>
                    <th scope="col" style="vertical-align: center;" class="text-center">
                      <p class="mb-0 font-weight-normal text-center">Jumlah Kembali</p>
                    </th>
                    <th scope="col" style="vertical-align: center;" class="text-center">
                      <p class="mb-0 font-weight-normal text-center">Jumlah</p>
                    </th>
                    <!-- <th scope="col" style="vertical-align: center;" class="text-center">
                      <b-button variant="primary" @click="addRowEdit">
                        <p class="h5 mb-0">+</p>
                      </b-button>
                    </th> -->
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(k, index) in editKoreksi.detail" v-bind:key="k.codex">
                    <td class="py-3">
                      <div>
                        <p class="text-center font-weight-bold mb-0">{{ index + 1 }}</p>
                      </div>
                    </td>
                    <td class="col-6">
                      <div>
                        <div class="w-100">
                          <multiselect :show-labels="false" label="nama_barang" placeholder="[ nama katalog ]" :disabled="editKoreksi.header.ver_status === '1' ? true : false"
                            :options="options.farmasi" v-model="select.Editfarmasi[index].detail" @input="valueEditFarmasi($event, index)"
                            :searchable="true" :allow-empty="false">
                          </multiselect>
                        </div>
                      </div>
                    </td>
                    <td>
                      <div>
                        <div class="text-center w-100">
                          <p class="mb-0">{{ preview.jumlah_pinjam[index].value }}</p>
                        </div>
                      </div>
                    </td>
                    <td>
                      <div>
                        <div class="text-center w-100">
                          <p class="mb-0">{{ preview.jumlah_kembali[index].value }}</p>
                        </div>
                      </div>
                    </td>
                    <td>
                      <div>
                        <div class="w-100">
                          <b-form-input class="new-cstm-cntrl-inventori" id="jumlah" v-model="editKoreksi.detail[index].jumlah" :readonly="editKoreksi.header.ver_status === '1' ? true : false"
                            @input="tabJumlahEdit($event, index)" type="text" placeholder="[ Jumlah ]" @keypress="isNumberKey">
                          </b-form-input>
                        </div>
                      </div>
                    </td>
                    <!-- <td class="text-center">
                      <b-button variant="white" @click="deleteRowEdit(index)" v-if="k.id === '0' ? true : false">
                        <p class="mb-0"><i class="fas fa-trash-alt text-danger"></i></p>
                      </b-button>
                    </td> -->
                  </tr>
                </tbody>
              </table>
            </div>
            <hr class="m-0">
            <div>
              <b-form-group label-for="tanggal" class="mb-0 mt-2">
                <b-form-checkbox  id="checkbox-200" v-model="editKoreksi.header.ver_status"
                  name="checkbox-1" value="1" unchecked-value="0">
                  Verifikasi Pengembalian Barang
                </b-form-checkbox>
              </b-form-group>
            </div>
          </b-col>
        </b-row>
        <div class="bg-dark px-2" v-if="preview.message">
          <p class="text-right small text-white mb-0">{{ preview.message }}</p>
        </div>
      </div>
      <!-- <pre>{{ editKoreksi }}</pre> -->
    </b-modal>
    <b-modal id="modal-create-inventory-kembali" ref="modal-create-inventory-kembali" size="xl" centered @close="clearData" @cancel="clearData"
      title="Create Data" ok-title="Penambahan Data" cancel-title="Tutup" :no-close-on-backdrop="true" :static="true" @ok="createSave">
      <b-form class="py-2">
        <b-row>
          <b-col>
            <b-form-group label-for="inp-tanggal" class="mb-1">
              <b-row>
                <b-col cols="12" md="2">
                  <p class="custom-label-katalog text-dark mb-0">Nama Pbf</p>
                </b-col>
                <b-col>
                  <multiselect v-model="selected.pbf" :show-labels="false" label="nama_pbf" placeholder="[ Pbf ]"
                    :options="options1.pbf" :searchable="true" @input="valuepbf" :allow-empty="false">
                  </multiselect>
                </b-col>
              </b-row>
            </b-form-group>
            <b-form-group label-for="keterangan" class="mb-1">
              <b-row>
                <b-col cols="12" md="2">
                  <p class="custom-label-katalog text-dark mb-0">Keterangan</p>
                </b-col>
                <b-col>
                  <b-form-textarea type="text" v-model="tambahDataKoreksi.header.keterangan" row="5"
                    class="katalog-form-area-2" placeholder="[ Keterangan ]">
                  </b-form-textarea>
                </b-col>
              </b-row>
            </b-form-group>
            <b-form-group label-for="tanggal" class="mb-0">
              <b-row>
                <b-col cols="12" md="2">
                  <p class="custom-label-katalog text-dark mb-0">Tanggal</p>
                </b-col>
                <b-col>
                  <flat-pickr :config="config" @on-change="tglEvent($event, 't')" v-model="preview.tanggal" value="" placeholder="[ Pilih Tanggal ]" class="w-100 custom-flatpicker calender-small">
                  </flat-pickr>
                </b-col>
              </b-row>
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group label-for="tanggal" class="mb-1">
              <b-row>
                <b-col cols="12" md="3">
                  <p class="custom-label-katalog text-dark mb-0">KODE</p>
                </b-col>
                <b-col cols="12" md="1">
                  <p class="custom-label-katalog text-dark mb-0">:</p>
                </b-col>
                <b-col>
                  <div>
                    <p class="h3 text-dark mb-0">{{ preview.kode }}</p>
                  </div>
                </b-col>
              </b-row>
            </b-form-group>
            <hr class="m-0">
            <b-form-group label-for="tanggal" class="mb-1 mt-2">
              <b-row>
                <b-col cols="12" md="3">
                  <p class="custom-label-katalog text-dark mb-0">Ruangan</p>
                </b-col>
                <b-col cols="12" md="1">
                  <p class="custom-label-katalog text-dark mb-0">:</p>
                </b-col>
                <b-col>
                  <div>
                    <p class="custom-label-katalog text-dark mb-0">{{ preview.nama_ruangan }}</p>
                  </div>
                </b-col>
              </b-row>
            </b-form-group>
            <b-form-group label-for="tanggal" class="mb-1">
              <b-row>
                <b-col cols="12" md="3">
                  <p class="custom-label-katalog text-dark mb-0">User Input</p>
                </b-col>
                <b-col cols="12" md="1">
                  <p class="custom-label-katalog text-dark mb-0">:</p>
                </b-col>
                <b-col>
                  <div>
                    <p class="custom-label-katalog text-dark mb-0">{{ preview.petugas_inp }}</p>
                  </div>
                </b-col>
              </b-row>
            </b-form-group>
            <b-form-group label-for="tanggal" class="mb-0">
              <b-row>
                <b-col cols="12" md="3">
                  <p class="custom-label-katalog text-dark mb-0">User Varifikasi</p>
                </b-col>
                <b-col cols="12" md="1">
                  <p class="custom-label-katalog text-dark mb-0">:</p>
                </b-col>
                <b-col>
                  <div>
                    <p class="custom-label-katalog text-dark mb-0">{{ preview.petugas_ver }}</p>
                  </div>
                </b-col>
              </b-row>
            </b-form-group>
          </b-col>
        </b-row>
      </b-form>
      <hr class="m-0">
      <div>
        <b-row>
          <b-col cols="12">
            <div class="table-responsive-xl">
              <table class="table bg-white table-bordered table-sm m-0 p-0">
                <thead>
                  <tr>
                    <th scope="col" style="vertical-align: center;" class="text-center">
                      <p class="mb-0 font-weight-normal text-center">No.</p>
                    </th>
                    <th scope="col" style="vertical-align: center;" class="text-center col-6">
                      <p class="mb-0 font-weight-normal text-center">Nama Barang</p>
                    </th>
                    <th scope="col" style="vertical-align: center;" class="text-center col-2">
                      <p class="mb-0 font-weight-normal text-center">Jumlah Pinjam</p>
                    </th>
                    <th scope="col" style="vertical-align: center;" class="text-center col-2">
                      <p class="mb-0 font-weight-normal text-center">Jumlah Kembali</p>
                    </th>
                    <th scope="col" style="vertical-align: center;" class="text-center">
                      <p class="mb-0 font-weight-normal text-center">Jumlah</p>
                    </th>
                    <!-- <th scope="col" style="vertical-align: center;" class="text-center">
                      <b-button variant="primary" @click="addRow">
                        <p class="h5 mb-0">+</p>
                      </b-button>
                    </th> -->
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(k, index) in tambahDataKoreksi.detail" v-bind:key="k.codex">
                    <td class="py-3">
                      <div>
                        <p class="text-center font-weight-bold mb-0">{{ index + 1 }}</p>
                      </div>
                    </td>
                    <td>
                      <div>
                        <div class="w-100">
                          <p class="mb-0">{{ preview.barang[index].nama }}</p>
                        </div>
                      </div>
                    </td>
                    <td>
                      <div>
                        <div class="text-center w-100">
                          <p class="mb-0">{{ preview.jumlah_pinjam[index].value }}</p>
                        </div>
                      </div>
                    </td>
                    <td>
                      <div>
                        <div class="text-center w-100">
                          <p class="mb-0">{{ preview.jumlah_kembali[index].value }}</p>
                        </div>
                      </div>
                    </td>
                    <td>
                      <div>
                        <div class="w-100">
                          <b-form-input class="new-cstm-cntrl-inventori text-center" id="jumlah" v-model="tambahDataKoreksi.detail[index].jumlah"
                            @input="tabJumlah($event, index)" type="text" placeholder="[ Jumlah ]" @keypress="isNumberKey">
                          </b-form-input>
                        </div>
                      </div>
                    </td>
                    <!-- <td class="text-center">
                      <b-button variant="white" @click="deleteRow(index)" v-if="k.id_reff === '0' && k.jumlah === '' && index !== 0">
                        <p class="mb-0"><i class="fas fa-trash-alt text-danger"></i></p>
                      </b-button>
                    </td> -->
                  </tr>
                </tbody>
              </table>
            </div>
          </b-col>
        </b-row>
        <div class="bg-dark px-2" v-if="preview.message">
          <p class="text-right small text-white mb-0">{{ preview.message }}</p>
        </div>
      </div>
      <!-- <pre>{{ tambahDataKoreksi }}</pre> -->
    </b-modal>
    <b-modal ref="modal-konfirm-delete" size="md" title="PERINGATAN!" ok-variant="danger" ok-title="Hapus"
      :no-close-on-backdrop="true" :static="true" cancel-title="Batal" centered>
      <div class="text-center px-lg-4 px-2 py-2">
        <div>
          <i class="fas fa-exclamation-circle text-danger fa-5x"></i>
          <p class="h6 text-danger mb-0 mt-3">Anda Yakin Akan Menghapus Data ?</p>
        </div>
      </div>
    </b-modal>
    <b-modal ref="modal-notif-koreksi" size="md" centered title="Notifikasi" ok-title="Tutup" :hide-footer="true"
      :hide-header="true">
      <div class="pb-3 px-4">
        <div v-if="preview.messageValue === '1'">
          <lottie :options="preview.lottie.defaultOptions" :autoPlay="true" :width="150" :height="150" />
        </div>
        <div v-else>
          <lottie :options="preview.lottie.defaultOptions2" :autoPlay="true" :width="150" :height="150" />
        </div>
        <p class="h6 text-dark text-center mb-0 mt-2">{{ preview.message }}</p>
      </div>
    </b-modal>
  </div>
</template>
<script>
import { xray } from '../../config/pluginInit'
import Multiselect from 'vue-multiselect'
import Lottie from 'vue-lottie'
import jwtdecode from 'jwt-decode'
import animationLogo from '../../assets/lottie/checked.json'
import animationLogo2 from '../../assets/lottie/failed.json'
import Services from '../../services/fatmahost/katalog/index'
import flatPickr from 'vue-flatpickr-component'
import 'flatpickr/dist/flatpickr.css'

export default {
  components: {
    Multiselect,
    Lottie,
    flatPickr
  },
  data () {
    return {
      hidden: false,
      dataku: [],
      selec: [],
      id_katalog: null,
      config: {
        dateFormat: 'd-m-Y',
        disableMobile: true
      },
      table: {
        isBusy: false,
        items: this.list,
        sortBy: '',
        sortDesc: false,
        fields: [
          { label: 'No', key: 'index', class: 'text-center' },
          { label: 'kode', key: 'kode' },
          { label: 'Nama Pbf', key: 'nama_pbf', sortable: true },
          { label: 'Keterangan', key: 'keterangan' },
          { label: 'Tanggal', key: 'tanggal' },
          { label: 'Input User', key: 'name_inp' },
          { label: 'Verifikasi User', key: 'name_ver' },
          { label: 'Verifikasi Status', key: 'ver_status', class: 'text-center' },
          { label: 'Aksi', key: 'action', class: 'text-center' }
        ],
        filter: null,
        currentPage: null,
        perPage: null,
        pageOptions: [10, 25, 50, 100],
        totalRows: null
      },
      tablePinjam: {
        isBusy: false,
        items: this.listPinjam,
        sortBy: '',
        sortDesc: false,
        fields: [
          { label: 'No', key: 'index', class: 'text-center' },
          { label: 'kode', key: 'kode' },
          { label: 'Nama Pbf', key: 'nama_pbf', sortable: true },
          { label: 'Keterangan', key: 'keterangan' },
          { label: 'Tanggal', key: 'tanggal' },
          { label: 'Input User', key: 'name_inp' },
          { label: 'Verifikasi User', key: 'name_ver' },
          { label: 'Verifikasi Status', key: 'ver_status', class: 'text-center' },
          { label: 'Aksi', key: 'action', class: 'text-center' }
        ],
        filter: null,
        currentPage: null,
        perPage: null,
        pageOptions: [10, 25, 50, 100],
        totalRows: null
      },
      tambahDataKoreksi: {
        object: 'inv-kembali',
        header: {
          id_reff: '',
          id_pbf: '',
          alasan_id: '',
          tanggal: '',
          keterangan: '',
          sts_batal: '',
          inp_user: jwtdecode(localStorage.getItem('token')).user.id.toString()
        },
        detail: [
          {
            id_reff: '',
            jumlah: ''
          }
        ]
      },
      editKoreksi: {
        object: 'inv-kembali-verif',
        header: {
          alasan_id: '1',
          tanggal: '',
          keterangan: '',
          sts_batal: '0',
          ver_user: '0',
          ver_status: '0'
        },
        detail: [
          {
            id: '0',
            jumlah: ''
          }
        ]
      },
      options1: {
        pbf: [],
        Editpbf: []
      },
      options: {
        optionsFilter: [
          { text: 'Kode', value: '1' },
          { text: 'nama', value: '2' }
        ],
        optionsFilterPJ: [
          { text: 'Kode', value: '1' },
          { text: 'nama PBF', value: '2' }
        ],
        farmasi: [],
        Editfarmasi: []
      },
      selected: {
        filter: '1',
        filterPj: '1',
        pbf: [],
        Editpbf: []
      },
      select: {
        farmasi: [{
          detail: []
        }],
        Editfarmasi: [{
          detail: []
        }]
      },
      bitControl: {
        bitcontrol1: '',
        bitcontrol2: ''
      },
      preview: {
        lottie: {
          defaultOptions: { animationData: animationLogo, loop: true },
          defaultOptions2: { animationData: animationLogo2, loop: true }
        },
        edit_id: '',
        message: '',
        messageValue: '',
        tanggal: '',
        barang: [
          {
            nama: ''
          }
        ],
        jumlah_pinjam: [
          {
            value: ''
          }
        ],
        jumlah_kembali: [
          {
            value: ''
          }
        ],
        kode: '',
        nama_ruangan: '',
        petugas_inp: '',
        petugas_ver: '',
        namapbf: ''
      }
    }
  },
  mounted () {
    xray.index()
    this.pbf_id()
    this.pbf_id_edit()
    this.katalog_id()
    this.getNamaFarmasi_id()
  },
  methods: {
    tes (tgl) {
      return tgl.split('-').reverse().join('-')
    },
    isNumberKey (evt) {
      evt = (evt) || window.event
      var charCode = (evt.which) ? evt.which : evt.keyCode
      if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
        evt.preventDefault()
      } else {
        return true
      }
    },
    async list (ctx) {
      let filterType = this.selected.filter
      let sortfield = ''
      let sorting = ''
      if (ctx.filter === '') {
        ctx.filter = null
      }
      if (ctx.sortBy) {
        sortfield = ctx.sortBy
      } else {
        sortfield = ''
      }
      if (ctx.sortDesc === true) {
        sorting = 'DESC'
      } else if (ctx.sortDesc === false) {
        sorting = 'ASC'
      } else {
        sorting = ''
      }
      try {
        const response = await Services.listPengembalianBarang(ctx.currentPage - 1, ctx.perPage, ctx.filter, filterType, sortfield, sorting)
        this.isBusy = false
        this.table.totalRows = response.data.pagination.total
        this.table.perPage = response.data.pagination.perPage
        this.table.currentPage = response.data.pagination.current
        return response.data.data
      } catch (error) {
        this.isBusy = false
        return []
      }
    },
    async listPinjam (ctx) {
      let filterType = this.selected.filterPj
      let sortfield = ''
      let sorting = ''
      if (ctx.filter === '') {
        ctx.filter = null
      }
      if (ctx.sortBy) {
        sortfield = ctx.sortBy
      } else {
        sortfield = ''
      }
      if (ctx.sortDesc === true) {
        sorting = 'DESC'
      } else if (ctx.sortDesc === false) {
        sorting = 'ASC'
      } else {
        sorting = ''
      }
      try {
        const response = await Services.listPengembalianBarangPj(ctx.currentPage - 1, ctx.perPage, ctx.filter, filterType, sortfield, sorting)
        this.isBusy = false
        this.tablePinjam.totalRows = response.data.pagination.total
        this.tablePinjam.perPage = response.data.pagination.perPage
        this.tablePinjam.currentPage = response.data.pagination.current
        return response.data.data
      } catch (error) {
        this.isBusy = false
        return []
      }
    },
    onFiltered (filteredItems) {
      this.table.totalRows = filteredItems.length
      this.table.currentPage = 1
    },
    onFilteredPj (filteredItems) {
      this.tablePinjam.totalRows = filteredItems.length
      this.tablePinjam.currentPage = 1
    },
    filterchange () {
      this.table.filter = ''
    },
    filterchangePj () {
      this.tablePinjam.filter = ''
    },
    tableRefresh () {
      this.$refs.table.refresh()
    },
    async pbf_id () {
      const response = await Services.getPbf()
      this.options1.pbf = response.data.data
    },
    async pbf_id_edit () {
      const response = await Services.getPbf()
      this.options1.Editpbf = response.data.data
    },
    async katalog_id () {
      const response = await Services.getFarmasi()
      this.options.farmasi = response.data.data
    },
    async getNamaFarmasi_id () {
      const response = await Services.getFarmasi()
      this.options.Editfarmasi = response.data.data
    },
    tglEvent ($event, param) {
      const date = new Date($event)
      let day = date.getDate()
      let month = (date.getMonth() + 1).toString().padStart(2, 0)
      let year = date.getFullYear()
      this.preview.tanggal = day + '-' + month + '-' + year
      if (param === 'e') {
        if (day < 10) {
          day = '0' + day
        }
        let currentDate = year + '-' + month + '-' + day
        this.editKoreksi.header.tanggal = currentDate
      } else if (param === 'a') {
        if (day < 10) {
          day = '0' + day
        }
        let currentDate = year + '-' + month + '-' + day
        this.editKoreksi.header.tanggal = currentDate
      } else if (param === 't') {
        if (day < 10) {
          day = '0' + day
        }
        let currentDate = year + '-' + month + '-' + day
        this.tambahDataKoreksi.header.tanggal = currentDate
      }
    },
    datetime () {
      const date = new Date()
      let day = date.getDate()
      let month = (date.getMonth() + 1).toString().padStart(2, 0)
      let year = date.getFullYear()
      if (month < 10) {
        month = '0' + month
      }
      if (day < 10) {
        day = '0' + day
      }
      let currentDate = year + '-' + month + '-' + day
      this.editKoreksi.header.inp_tanggal = currentDate
      this.editKoreksi.header.ver_tanggal = currentDate
    },
    valuepbf (param) {
      this.tambahDataKoreksi.header.id_pbf = param.id.toString()
    },
    valueEditpbf (param) {
      this.editKoreksi.header.id_pbf = param.id.toString()
    },
    showModal () {
      this.clearData()
      this.$refs['modal-tambah-inventory-kembali'].show()
    },
    async modalCreate (id) {
      const ids = id
      try {
        const response = await Services.showPengembalianBarangPj(ids)
        if (response.data.statcode === 0) {
          if (response.data.data[0].detail) {
            this.deleteRow(0)
            let farmasi = response.data.data[0].detail
            farmasi.map((value, index) => {
              this.tambahDataKoreksi.detail.push({
                id_reff: value.id.toString(),
                jumlah: '0'
              })
              this.preview.barang.push({
                nama: value.nama_barang
              })
              this.preview.jumlah_pinjam.push({
                value: value.jumlah_pinjam.toString()
              })
              this.preview.jumlah_kembali.push({
                value: value.jumlah_kembali.toString()
              })
            })
          }
          this.tambahDataKoreksi.header.keterangan = response.data.data[0].keterangan
          this.tambahDataKoreksi.header.id_reff = response.data.data[0].id.toString()
          this.tambahDataKoreksi.header.id_pbf = response.data.data[0].id_pbf.toString()
          this.tambahDataKoreksi.header.alasan_id = response.data.data[0].alasan_id.toString()
          this.tambahDataKoreksi.header.sts_batal = response.data.data[0].sts_batal.toString()
          this.tglEvent(response.data.data[0].tanggal, 't')
          this.selected.pbf = { id: response.data.data[0].id_pbf, nama_pbf: response.data.data[0].nama_pbf }
          this.preview.kode = response.data.data[0].kode
          this.preview.nama_ruangan = response.data.data[0].ruangan_nama
          this.preview.petugas_inp = response.data.data[0].name_inp
          this.preview.petugas_ver = response.data.data[0].name_ver
        } else {
          this.preview.message = response.data.message
          this.preview.messageValue = '2'
        }
      } catch (error) {
        this.preview.message = error.message
        this.preview.messageValue = '2'
      }
      this.$refs['modal-create-inventory-kembali'].show()
    },
    async editModal (id) {
      const ids = id
      this.preview.message = ''
      this.preview.edit_id = ids
      try {
        const response = await Services.showPengembalianBarang(ids)
        if (response.data.statcode === 0) {
          if (response.data.data[0].detail) {
            this.deleteRowEdit(0)
            let farmasi = response.data.data[0].detail
            farmasi.map((value, index) => {
              this.editKoreksi.detail.push({
                id: value.id.toString(),
                jumlah: value.jumlah.toString()
              })
              this.select.Editfarmasi.push({
                detail: [{ id: value.id_katalog, nama_barang: value.nama_barang }]
              })
              this.preview.jumlah_pinjam.push({
                value: value.jumlah_pinjam.toString()
              })
              this.preview.jumlah_kembali.push({
                value: value.jumlah_kembali.toString()
              })
            })
          }
          this.tglEvent(response.data.data[0].tanggal, 'a')
          this.editKoreksi.header.keterangan = response.data.data[0].keterangan ? response.data.data[0].keterangan : ''
          // this.selected.Editpbf = response.data.data[0].id_pbf ? { id: response.data.data[0].id_pbf.toString(), nama_pbf: response.data.data[0].nama_pbf } : []
          // this.editKoreksi.header.id_pbf = response.data.data[0].id_pbf ? response.data.data[0].id_pbf.toString() : ''
          this.editKoreksi.header.ver_status = response.data.data[0].ver_status ? response.data.data[0].ver_status.toString() : '0'
          this.preview.kode = response.data.data[0].kode
          this.preview.namapbf = response.data.data[0].nama_pbf
          this.preview.nama_ruangan = response.data.data[0].ruangan_nama
          this.preview.petugas_inp = response.data.data[0].name_inp
          this.preview.petugas_ver = response.data.data[0].name_ver
        } else {
          this.preview.message = response.data.message
          this.preview.messageValue = '2'
        }
      } catch (error) {
        this.preview.message = error.message
        this.preview.messageValue = '2'
      }
      this.$refs['modal-edit-inventory-kembali'].show()
    },
    async createSave (bvModalEvent) {
      bvModalEvent.preventDefault()
      try {
        const data = this.tambahDataKoreksi
        const response = await Services.createPengembalianBarangPj(data)
        if (response.data.statcode === 0) {
          this.preview.message = response.data.message
          this.preview.messageValue = '1'
          this.$refs['modal-create-inventory-kembali'].hide()
          this.$refs['modal-tambah-inventory-kembali'].hide()
          this.$refs['modal-notif-koreksi'].show()
          this.clearData()
        } else {
          this.preview.message = response.data.message
          this.preview.messageValue = '2'
        }
        this.$refs.table.refresh()
      } catch (error) {
        this.preview.message = error.message
        this.preview.messageValue = '2'
        this.$refs.table.refresh()
      }
    },
    async editSave (bvModalEvent) {
      bvModalEvent.preventDefault()
      try {
        const id = this.preview.edit_id
        const data = this.editKoreksi
        const response = await Services.editPengembalianBarang(id, data)
        if (response.data.statcode === 0) {
          this.preview.message = response.data.message
          this.preview.messageValue = '1'
          this.$refs['modal-edit-inventory-kembali'].hide()
          this.$refs['modal-notif-koreksi'].show()
          this.clearData()
        } else {
          this.preview.message = response.data.message
          this.preview.messageValue = '2'
        }
        this.$refs.table.refresh()
      } catch (error) {
        this.preview.message = error.message
        this.preview.messageValue = '2'
        this.$refs.table.refresh()
      }
    },
    deleteModal (id) {
      this.$refs['modal-konfirm-delete'].show()
    },
    tabReff ($event, index) {
      this.tambahDataKoreksi.detail[index].id_reff = $event
    },
    tabJumlah ($event, index) {
      this.tambahDataKoreksi.detail[index].jumlah = $event
    },
    tabJumlahEdit ($event, index) {
      this.editKoreksi.detail[index].jumlah = $event
    },
    valueEditFarmasi ($event, index) {
      this.editKoreksi.detail[index].id = $event.id
    },
    addRow () {
      this.tambahDataKoreksi.detail.push(
        {
          id_reff: '0',
          jumlah: '0'
        }
      )
      this.select.farmasi.push(
        {
          detail: []
        }
      )
      this.preview.barang.push(
        {
          nama: ''
        }
      )
      this.preview.jumlah_pinjam.push(
        {
          value: ''
        }
      )
      this.preview.jumlah_kembali.push(
        {
          value: ''
        }
      )
    },
    addRowEdit () {
      this.editKoreksi.detail.push(
        {
          id: '0',
          jumlah: '0'
        }
      )
      this.select.Editfarmasi.push(
        {
          detail: []
        }
      )
    },
    deleteRow (index) {
      this.tambahDataKoreksi.detail.splice(index, 1)
      this.select.farmasi.splice(index, 1)
      this.preview.barang.splice(index, 1)
      this.preview.jumlah_pinjam.splice(index, 1)
      this.preview.jumlah_kembali.splice(index, 1)
    },
    deleteRowEdit (index) {
      this.editKoreksi.detail.splice(index, 1)
      this.select.Editfarmasi.splice(index, 1)
      this.preview.jumlah_pinjam.splice(index, 1)
      this.preview.jumlah_kembali.splice(index, 1)
    },
    clearData () {
      // form
      this.preview.message = ''
      this.preview.tanggal = ''
      //
      this.tambahDataKoreksi.header.id_reff = ''
      this.tambahDataKoreksi.header.id_pbf = ''
      this.tambahDataKoreksi.header.alasan_id = ''
      this.tambahDataKoreksi.header.tanggal = ''
      this.tambahDataKoreksi.header.keterangan = ''
      this.tambahDataKoreksi.header.sts_batal = ''
      //
      this.editKoreksi.header.alasan_id = '1'
      this.editKoreksi.header.tanggal = ''
      this.editKoreksi.header.keterangan = ''
      this.editKoreksi.header.sts_batal = '0'
      this.editKoreksi.header.ver_status = '0'
      // select
      this.selected.pbf = []
      this.selected.Editpbf = []
      // function
      // this.datetime()
      // array inven
      this.clearArrA()
      this.clearArrB()
    },
    clearArrA () {
      const arr1 = this.tambahDataKoreksi.detail
      const arr2 = this.preview.jumlah_pinjam
      const arr3 = this.preview.jumlah_kembali
      const arr4 = this.preview.barang
      const arrDump1 = arr1.splice(0, arr1.length)
      const arrDump2 = arr2.splice(0, arr2.length)
      const arrDump3 = arr3.splice(0, arr3.length)
      const arrDump4 = arr4.splice(0, arr4.length)
      if (arrDump1 && arrDump2 && arrDump3 && arrDump4) {
        this.tambahDataKoreksi.detail.push(
          {
            id_reff: '',
            jumlah: '0'
          }
        )
        this.preview.barang.push(
          {
            nama: ''
          }
        )
        this.preview.jumlah_pinjam.push(
          {
            value: ''
          }
        )
        this.preview.jumlah_kembali.push(
          {
            value: ''
          }
        )
      }
    },
    clearArrB () {
      const arr1 = this.editKoreksi.detail
      const arr2 = this.select.Editfarmasi
      const arrDump1 = arr1.splice(0, arr1.length)
      const arrDump2 = arr2.splice(0, arr2.length)
      if (arrDump1 && arrDump2) {
        this.editKoreksi.detail.push(
          {
            id: '0',
            jumlah: '0'
          }
        )
        this.select.Editfarmasi.push(
          {
            detail: []
          }
        )
      }
    }
  }
}
</script>
<style>
#Inventory-Pengembalian-Barang input[type="text"][disabled],
#modal-tambah-inventory-kembali input[type="text"][disabled],
#modal-edit-inventory-kembali input[type="text"][disabled],
#modal-create-inventory-kembali input[type="text"][disabled] {
   background: #e9ecef;
}
#modal-tambah-inventory-kembali .height-static-pabrik {
  /* max-height: 12em;
 overflow-x: hidden; */
  overflow: auto;
}

#modal-tambah-inventory-kembali .multiselect, #modal-edit-inventory-kembali .multiselect, #modal-create-inventory-kembali .multiselect {
  min-height: 33px;
}

#modal-tambah-inventory-kembali .multiselect__single, #modal-edit-inventory-kembali .multiselect__single, #modal-create-inventory-kembali .multiselect__single {
  font-size: 0.9em!important;
  font-weight: normal!important;
  line-height: 26px;
}
#modal-tambah-inventory-kembali .multiselect__placeholder, #modal-edit-inventory-kembali .multiselect__placeholder, #modal-create-inventory-kembali .multiselect__placeholder {
  font-size: 0.9em!important;
  font-weight: normal;
}
#modal-tambah-inventory-kembali .multiselect__option, #modal-edit-inventory-kembali .multiselect__option, #modal-create-inventory-kembali .multiselect__option {
  font-size: 0.9em;
}
#modal-tambah-inventory-kembali .multiselect__tags, #modal-edit-inventory-kembali .multiselect__tags, #modal-create-inventory-kembali .multiselect__tags {
  overflow: hidden;
  padding: 1px 40px 0 8px!important;
  height: 33px!important;
  min-height: 33px!important;
}

#modal-edit-inventory-kembali .multiselect--disabled .multiselect__tags, #modal-create-inventory-kembali .multiselect--disabled .multiselect__tags {
  overflow: hidden;
  background: #E9ECEF;
}

#modal-edit-inventory-kembali .multiselect--disabled, #modal-create-inventory-kembali .multiselect--disabled {
  opacity: 1;
}

#modal-edit-inventory-kembali .multiselect--disabled .multiselect__tags .multiselect__single, #modal-create-inventory-kembali .multiselect--disabled .multiselect__tags {
  overflow: hidden;
  background: transparent;
}

#modal-edit-inventory-kembali .multiselect__select, #modal-create-inventory-kembali .multiselect__select {
  height: 30px;
}

.new-cstm-cntrl-inventori {
  background: white!important;
  color: black!important;
  border-radius: 5px!important;
  font-size: 12px!important;
  height: 33px!important;
}

#modal-tambah-inventory-kembali .border-merah {
  border: 2px solid red;
}

#modal-tambah-inventory-kembali .border-hijau {
  border: 2px solid green;
}

#modal-tambah-inventory-kembali .modal-body {
  background: #3399331b;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

#modal-tambah-inventory-kembali .modal-header {
  background: #3399333a;
}

#modal-tambah-inventory-kembali .modal-footer {
  background: #3399333a;
}

#modal-tambah-inventory-kembali .modal-dialog {
  min-width: 95%!important;
}

#modal-edit-inventory-kembali .border-merah {
  border: 2px solid red;
}

#modal-edit-inventory-kembali.border-hijau {
  border: 2px solid green;
}

#modal-edit-inventory-kembali .modal-body {
  background: #3399331b;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}
#modal-edit-inventory-kembali .modal-header {
  background: #3399333a;
}

#modal-edit-inventory-kembali .modal-footer {
  background: #3399333a;
}

#modal-create-inventory-kembali .border-merah {
  border: 2px solid red;
}

#modal-create-inventory-kembali .border-hijau {
  border: 2px solid green;
}
#modal-create-inventory-kembali .modal-body {
  background: #3399331b;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}
#modal-create-inventory-kembali .modal-header {
  background: #3399333a;
}

#modal-create-inventory-kembali .modal-footer {
  background: #3399333a;
}

#Inventory-Pengembalian-Barang .custom-button-radius {
  border: 1px solid rgba(0, 0, 0, 0.112);
  border-radius: 5px !important;
}

#Inventory-Pengembalian-Barang .table td {
    font-size: 12px;
    font-weight: 500;
    padding-top: 0px;
    padding-bottom: 0px;
}
#Inventory-Pengembalian-Barang .table th {
    font-size: 13px;
    font-weight: 400;
}

#Inventory-Pengembalian-Barang .table {
  text-align: left;
}

#Inventory-Pengembalian-Barang .cstm-select-Inventory {
  font-size: 13px;
  height: 38px !important;
  border-radius: 5px;
}

#Inventory-Pengembalian-Barang .cstm-srch-brand {
  font-size: 13px;
  height: 38px !important;
  border-radius: 5px;
}

#Inventory-Pengembalian-Barang .cstm-slct-brand {
  font-size: 13px;
  height: 38px !important;
  border-radius: 5px;
}

#modal-tambah-inventory-kembali .custom-flatpicker, #modal-edit-inventory-kembali .custom-flatpicker, #modal-create-inventory-kembali .custom-flatpicker {
    border: 1px solid rgb(233, 233, 233);
    height: 33px;
    padding-left: 10px;
    padding-top: 5px;
    padding-bottom: 5px;
    border: 1px solid rgb(209, 208, 208)!important;
    border-radius: 5px;
    border: 1px solid rgb(228, 228, 228);
    font-size: 12px;
    color: black;
}

#Inventory-Pengembalian-Barang .page-link {
    border: none;
    color: gray!important;
    margin-right: 15px;
}
#Inventory-Pengembalian-Barang .page-item.active .page-link {
    background-color: transparent;
    border-color: transparent;
    color: #089bab!important;
    font-weight: bold;
}

.cstm-cntrl-inventori-kembali-kode {
  color: black!important;
  background: white!important;
  font-weight: normal!important;
  border-radius: 5px!important;
  font-size: 2.5em!important;
  height: 38px!important;
  line-height: 35px;
}
</style>
