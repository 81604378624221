import Api from './fatmahost/axiosInstance'

export default {
  show (nomor, jenis) {
    return Api.laravel.get(`nik/reservasi/utd/rsf?nomor=${nomor}&jenis_donor=${jenis}`)
  },
  store (data) {
    return Api.laravel.post('create/reservasi/utd/rsf', data)
  },
  listPasien (page, params) {
    if (params !== null) {
      return Api.laravel.get(`reservasi/utd/rsf/?params=${params}`)
    } else {
      return Api.laravel.get(`reservasi/utd/rsf/?page=${page}`)
    }
  },
  viewPendaftaran (data) {
    return Api.laravel.get('show/pendaftaran/reservasi/utd/rsf', { params: data })
  },
  viewPemeriksaan (data) {
    return Api.laravel.get('show/pemeriksa/reservasi/utd/rsf', { params: data })
  },
  viewDokter (data) {
    return Api.laravel.get('show/reservasi/utd/rsf', { params: data })
  },
  pendaftaran (data) {
    return Api.laravel.post('pendaftaran/reservasi/utd/rsf', data)
  },
  pemeriksaan (data) {
    return Api.laravel.post('pemeriksa/reservasi/utd/rsf', data)
  },
  dokter (data) {
    return Api.laravel.post('update/reservasi/utd/rsf', data)
  },
  getWilayah () {
    return Api.laravel.get('getWilayah/rsf')
  },
  golDar () {
    return Api.laravel.get('getbyGolonganDarah/utd')
  }
}
