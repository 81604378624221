<template>
  <div>
    <div v-show="isLoading">
      <div id="show-overlay"></div>
      <Loader :style="{ opacity: .5 }" />
    </div>
    <div id="detail-info-emr" class="bg-white shadow-sm radius-custom-emr mb-2">
        <b-row class="align-items-center p-3">
        <b-col cols="12" lg="6">
          <div class="d-flex flex-row align-items-center">
            <div class="mr-2">
              <b-img v-if="pasien !== null" :src="pasien.jenis_kelamin === '1' ? imgProfile.pria : imgProfile.wanita" alt="profile-img" width="100rem" class="img-fluid" />
            </div>
            <div>
              <div class="mb-1">
                <p class="h6 text-dark font-weight-bold text-uppercase mb-0">{{ pasien['p.nama'] }}</p>
                <p class="text-dark mb-0">{{ pasien.desc_wilayah }}, {{ pasien.tanggal_lahir }} / {{ pasien.umur }}</p>
              </div>
              <div>
                <p class="mb-0">NO. REKAM MEDIS</p>
                <p class="h3 text-dark font-weight-bold mb-0">{{ pasien.norm }}</p>
              </div>
            </div>
          </div>
        </b-col>
        <b-col cols="12" lg="6">
          <div class="text-right">
            <div>
              <div>
              <p class="h6 text-dark font-weight-bold "><span class="text-muted font-weight-normal mr-2">NIK</span>{{ pasien.nik }}</p>
              <p class="h6 text-dark font-weight-bold"><span class="text-muted font-weight-normal mr-2">NO. BPJS</span>{{ pasien.no_bpjs }}</p>
              <p class="h6 text-dark font-weight-bold mb-0"><span class="text-muted font-weight-normal mr-2">NO. SEP</span>{{ pasien.no_sep }}</p>
            </div>
            </div>
          </div>
        </b-col>
      </b-row>
    </div>
  </div>
</template>
<script>
import { xray } from '../../../config/pluginInit'
import Loader from '../../../components/xray/loader/Loader'
import Services from '../../../services/fatmahost/index.js'

export default {
  name: 'DetailInfo',
  components: {
    Loader
  },
  data () {
    return {
      isLoading: false,
      pasien: {},
      imgProfile: {
        pria: require('../../../assets/images/user/14.jpg'),
        priatrns: require('../../../assets/images/14.png'),
        wanitatrns: require('../../../assets/images/14.png'),
        kartu: require('../../../assets/images/logo-rs.png'),
        kemkes: require('../../../assets/images/logo-kemkes-putih.png'),
        wanita: require('../../../assets/images/user/15.jpg')
      }
    }
  },
  mounted () {
    xray.index()
    this.viewPasien(this.$route.query.norm)
    this.isLoading = false
  },
  methods: {
    async viewPasien (norm) {
      try {
        const param1 = ``
        const param2 = `{"object":"SIMRS_EMR_RPASIEN","xparam0":"${xray.emrCache().norm}","xparam1":"","xparam2":"","xparam3":"","xparam4":"","xparam5":"","xparam6":"","xparam7":"","xparam8":"","xparam9":""}`
        const response = await Services.middleware.find(param1, param2)
        if (response.data.statcode === 0) {
          this.pasien = response.data.data[0]
        } else {
          this.pasien = {}
        }
      } catch (error) {
        this.pasien = {}
        this.error = error.message
      }
    }
  }
}
</script>

<style>
#detail-info-emr.radius-custom-emr {
  border-radius: .5rem;
}
</style>
