<template>
  <div id="sdm-ksm-pegawai-grup" class="mb-4">
    <b-container fluid>
      <div>
        <iq-card>
          <template v-slot:body>
            <div class="filter-pbf border p-2">
              <b-row class="align-items-center">
                <b-col cols="12" lg="3" class="d-flex flex-row w-100 mr-auto">
                  <div>
                    <h5 class="card-title font-weight-bold text-uppercase mb-0">KELOMPOK SATUAN MEDIS</h5>
                  </div>
                </b-col>
                <b-col cols="12" lg="9">
                  <div class="text-right">
                    <b-button class="custom-button-radius" size="sm" variant="primary" @click="tambahModal"><i class="fas fa-plus"></i> Tambah</b-button>
                  </div>
                </b-col>
              </b-row>
            </div>
            <div class="table mb-0">
              <b-table class="mb-0" ref="table" :busy="table.isBusy" :items="table.items" :fields="table.fields"
                :current-page="table.currentPage" :per-page="table.perPage" :filter="table.filter" @filtered="onFiltered" :sort-by.sync="table.sortBy"
                :sort-desc.sync="table.sortDesc" stacked="md"
                bordered hover show-empty small striped>
                <template #cell(index)="data">
                  <span>{{ (table.currentPage*table.perPage)-table.perPage + data.index + 1 }}</span>
                </template>
                <template #cell(sts_aktif)="data">
                  <span v-if="data.item.sts_aktif === 1"> Aktif </span>
                  <span v-else> Tidak Aktif </span>
                </template>
                <template #cell(tanggal)="data">
                  <span v-if="data.item.tanggal"> {{ data.item.tanggal.split('-').reverse().join('-') }} </span>
                </template>
                <template #cell(action)="data">
                  <b-button
                    @click="editModal(data.item.id)"
                    variant=" mr-1 mb-1" size="lg">
                    <i class="fas fa-edit text-primary"></i>
                  </b-button>
                  <b-button
                    @click="deleteModal(data.item.id)"
                    variant=" mr-1 mb-1" size="lg">
                    <i class="fas fa-trash-alt text-danger"></i>
                  </b-button>
                </template>
              </b-table>
            </div>
            <div class="pagination-pbf border shadow-sm p-2 mb-2">
              <b-row class="align-items-center">
                <b-col cols="4" class="d-flex flex-row align-items-center">
                  <div class="w-100">
                    <b-pagination
                      v-model="table.currentPage"
                      :total-rows="table.totalRows"
                      :per-page="table.perPage"
                      size="md"
                      class="my-0"
                    ></b-pagination>
                  </div>
                  <div class="w-100">
                    <div>
                      <span class="border-left border-dark mr-3 py-1"></span>
                      <span @click="tableRefresh" class="tarif-cursor">
                        <i class="fas fa-sync text-primary"></i>
                      </span>
                      <span class="border-right border-dark ml-3 py-1"></span>
                    </div>
                  </div>
                </b-col>
                <b-col cols="5">
                  <div class="d-flex flex-row justify-content-end align-items-center">
                    <div class="mr-1 w-75">
                      <form class="searchbox mb-0">
                        <input v-model="table.filter" type="search" class="form-control cstm-srch-pbf" id="filter-input"
                          placeholder="[Cari Nama Tindakan]">
                      </form>
                    </div>
                    <div>
                      <b-form-select class="cstm-slct-pbf" v-model="selected.filter" :options="options.optionsFilter" @change="filterchange"></b-form-select>
                    </div>
                  </div>
                </b-col>
                <b-col cols="3">
                  <div class="d-flex flex-row align-items-center justify-content-end">
                    <div class="mr-3">
                      <b-form-select
                        class="cstm-select-pbf"
                        id="per-page-select"
                        v-model="table.perPage"
                        :options="table.pageOptions"
                      ></b-form-select>
                    </div>
                    <div>
                      <p class="text-dark mb-0">Menampilkan {{ table.perPage }} dari {{ table.totalRows }} baris</p>
                    </div>
                  </div>
                </b-col>
              </b-row>
            </div>
          </template>
        </iq-card>
      </div>
    </b-container>
    <div id="modal-component">
      <b-modal id="modal-ksm-pegawai-grup" ref="modal-ksm-pegawai-grup" size="lg" centered title="Tambah KSM Pegawai" ok-title="Simpan Data" cancel-title="Tutup" @ok="tambahSave" :no-close-on-backdrop="true" :static="true" @close="clear" @cancel="clear">
        <b-form>
          <b-row class="mb-custom align-items-center">
            <b-col cols="3">
              <div>
                <p class="font-custom text-dark mb-0">KSM Pegawai</p>
              </div>
            </b-col>
            <b-col>
              <div>
                <b-form-input class="bg-white custom-control-ksm" v-model="formData.ksm_fatmawati" type="text" placeholder="[KSM Fatmawati]"></b-form-input>
              </div>
            </b-col>
          </b-row>
          <b-row class="mb-custom align-items-center">
            <b-col cols="3">
              <div>
                <p class="font-custom text-dark mb-0">Keterangan</p>
              </div>
            </b-col>
            <b-col>
              <div>
                <b-form-input class="bg-white custom-control-ksm" v-model="formData.keterangan" type="text" placeholder="[Keterangan]"></b-form-input>
              </div>
            </b-col>
          </b-row>
        </b-form>
        <div class="bg-dark">
          <p class="small text-right text-white px-2 mt-2 mb-0" v-if="preview.messageValue === '2'">{{ preview.message }}</p>
        </div>
        <!-- <pre>{{ formData }}</pre> -->
      </b-modal>
      <b-modal id="modal-ksm-pegawai-grup-update" ref="modal-ksm-pegawai-grup-update" size="lg" centered title="Tambah KSM Pegawai" ok-title="Simpan Data" cancel-title="Tutup" @ok="editSave" :no-close-on-backdrop="true" :static="true" @close="clear" @cancel="clear">
        <b-form>
          <b-row class="mb-custom align-items-center">
            <b-col cols="3">
              <div>
                <p class="font-custom text-dark mb-0">KSM Pegawai</p>
              </div>
            </b-col>
            <b-col>
              <div>
                <b-form-input class="bg-white custom-control-ksm" v-model="formDataUpdate.ksm_fatmawati" type="text" placeholder="[KSM Fatmawati]"></b-form-input>
              </div>
            </b-col>
          </b-row>
          <b-row class="mb-custom align-items-center">
            <b-col cols="3">
              <div>
                <p class="font-custom text-dark mb-0">Keterangan</p>
              </div>
            </b-col>
            <b-col>
              <div>
                <b-form-input class="bg-white custom-control-ksm" v-model="formDataUpdate.keterangan" type="text" placeholder="[Keterangan]"></b-form-input>
              </div>
            </b-col>
          </b-row>
        </b-form>
        <div class="bg-dark">
          <p class="small text-right text-white px-2 mt-2 mb-0" v-if="preview.messageValue === '2'">{{ preview.message }}</p>
        </div>
        <!-- <pre>{{ formDataUpdate }}</pre> -->
      </b-modal>
      <b-modal ref="modal-delete-pbf" size="md" centered title="Konfirmasi" ok-title="Hapus Data" ok-variant="danger" cancel-title="Tutup" @ok="deleteSave">
        <div class="py-3">
          <p class="h6 text-center text-dark mb-0">Yakin Ingin Menghapus Data ?</p>
        </div>
      </b-modal>
      <b-modal ref="modal-notif" size="md" centered title="Notifikasi" ok-title="Tutup" :hide-footer="true" :hide-header="true">
        <div class="pb-3 px-4">
          <div v-if="preview.messageValue === '1'">
            <lottie :options="preview.lottie.defaultOptions" :autoPlay="true" :width="150" :height="150" />
          </div>
          <div v-else>
            <lottie :options="preview.lottie.defaultOptions2" :autoPlay="true" :width="150" :height="150" />
          </div>
          <p class="h6 text-dark text-center mb-0 mt-2">{{ preview.message }}</p>
        </div>
      </b-modal>
    </div>
  </div>
</template>
<script>
import { xray } from '../../../config/pluginInit'
import jwtdecode from 'jwt-decode'
import newServices from '../../../services/fatmahost/pegawai/index.ts'
import Lottie from 'vue-lottie'
import animationLogo from '../../../assets/lottie/checked.json'
import animationLogo2 from '../../../assets/lottie/failed.json'

export default {
  name: 'Pbf',
  components: {
    Lottie
  },
  data () {
    return {
      table: {
        isBusy: false,
        items: this.list,
        fields: [
          { label: 'NO.', key: 'index', class: 'text-center' },
          { label: 'KSM Fatmawati', key: 'mk.ksm_fatmawati', sortable: true },
          { label: 'KSM Keterangan', key: 'mk.keterangan', sortable: true },
          { label: 'Created By', key: 'up.name', sortable: true },
          { label: 'Update By', key: 'up2.name', sortable: true },
          { label: 'AKSI', key: 'action', class: 'text-center' }
        ],
        sortBy: '',
        sortDesc: true,
        filter: null,
        currentPage: 1,
        perPage: 10,
        pageOptions: [10, 25, 50, 100],
        totalRows: null
      },
      formData: {
        object: 'pegawai-ksm-group',
        ksm_fatmawati: '',
        keterangan: '',
        created_by: jwtdecode(localStorage.getItem('token')).user.id.toString()
      },
      formDataUpdate: {
        object: 'pegawai-ksm-group',
        ksm_fatmawati: '',
        keterangan: '',
        updated_by: jwtdecode(localStorage.getItem('token')).user.id.toString()
      },
      options: {
        optionsFilter: [
          { text: 'KSM Fatmawati', value: '1' }
        ],
        pegawai: []
      },
      selected: {
        filter: '1',
        pegawai: []
      },
      config: {
        dateFormat: 'd-m-Y',
        disableMobile: true
      },
      preview: {
        isLoading: false,
        tanggal: '',
        lottie: {
          defaultOptions: { animationData: animationLogo, loop: true },
          defaultOptions2: { animationData: animationLogo2, loop: true }
        },
        user_name: jwtdecode(localStorage.getItem('token')).user.name,
        edit_id: '',
        delete_id: '',
        message: '',
        messageValue: '',
        modal_title: ''
      }
    }
  },
  mounted () {
    xray.index()
    this.getPegawaiKsm()
  },
  methods: {
    tableRefresh () {
      this.$refs.table.refresh()
    },
    async list (ctx) {
      let sortfield = ''
      let sorting = ''
      let filterType = this.selected.filter
      let filter = ''
      if (ctx.filter === '') {
        ctx.filter = null
      }
      if (ctx.sortBy) {
        sortfield = ctx.sortBy
      } else {
        sortfield = ''
      }
      if (ctx.sortDesc === true) {
        sorting = 'DESC'
      } else if (ctx.sortDesc === false) {
        sorting = 'ASC'
      } else {
        sorting = ''
      }
      if (ctx.filter === null) {
        filter = `param3={"xparam0":"","xparam1":"","xparam2":""}&param4={"xparam0":"${sortfield}","xparam1":"${sorting}"}`
      } else {
        if (filterType === '1') {
          filter = `param3={"xparam0":"${ctx.filter}","xparam1":"","xparam2":""}&param4={"xparam0":"${sortfield}","xparam1":"${sorting}"}`
        } else if (filterType === '2') {
          filter = `param3={"xparam0":"","xparam1":"${ctx.filter}","xparam2":""}&param4={"xparam0":"${sortfield}","xparam1":"${sorting}"}`
        }
      }
      this.isBusy = true
      try {
        const response = await newServices.findPegawaiKsmGrup(ctx.currentPage - 1, ctx.perPage, filter)
        this.isBusy = false
        this.table.totalRows = response.data.pagination.total
        this.table.perPage = response.data.pagination.perPage
        this.table.currentPage = response.data.pagination.current
        return response.data.data
      } catch (error) {
        this.isBusy = false
        return []
      }
    },
    onFiltered (filteredItems) {
      this.table.totalRows = filteredItems.length
      // this.table.currentPage = 1
    },
    filterchange () {
      this.table.filter = ''
    },
    tambahModal () {
      this.preview.messageValue = ''
      this.preview.message = ''
      //
      this.$refs['modal-ksm-pegawai-grup'].show()
    },
    async tambahSave (bvModalEvent) {
      bvModalEvent.preventDefault()
      try {
        let data = this.formData
        const response = await newServices.createPegawaiKsmGrup(data)
        if (response.data.statcode === 0) {
          this.preview.message = response.data.message
          this.preview.messageValue = '1'
          this.$refs['modal-ksm-pegawai-grup'].hide()
          this.$refs['modal-notif'].show()
          this.$refs.table.refresh()
          this.clear()
        } else {
          this.preview.message = response.data.message
          this.preview.messageValue = '2'
        }
      } catch (error) {
        this.preview.message = error.message
        this.preview.messageValue = '2'
      }
    },
    async editModal (id) {
      this.preview.messageValue = ''
      this.preview.message = ''
      //
      let ids = id
      this.preview.edit_id = ids.toString()
      try {
        const response = await newServices.showPegawaiKsmGrup(ids)
        if (response.data.statcode === 0) {
          this.formDataUpdate.ksm_fatmawati = response.data.data[0].ksm_fatmawati
          this.formDataUpdate.keterangan = response.data.data[0].keterangan
        } else {
          this.preview.message = response.data.message
          this.preview.messageValue = '2'
        }
      } catch (error) {
        this.preview.message = error.message
        this.preview.messageValue = '2'
      }
      this.$refs['modal-ksm-pegawai-grup-update'].show()
    },
    async editSave (bvModalEvent) {
      bvModalEvent.preventDefault()
      try {
        let id = this.preview.edit_id
        let data = this.formDataUpdate
        const response = await newServices.updatePegawaiKsmGrup(id, data)
        if (response.data.statcode === 0) {
          this.preview.message = response.data.message
          this.preview.messageValue = '1'
          this.$refs['modal-ksm-pegawai-grup-update'].hide()
          this.$refs['modal-notif'].show()
          this.$refs.table.refresh()
          this.clear()
        } else {
          this.preview.message = response.data.message
          this.preview.messageValue = '2'
        }
      } catch (error) {
        this.preview.message = error.message
        this.preview.messageValue = '2'
      }
    },
    deleteModal (id) {
      this.preview.delete_id = id.toString()
      this.$refs['modal-delete-pbf'].show()
    },
    async deleteSave () {
      let id = this.preview.delete_id
      try {
        const response = await newServices.deletePegawaiKsmGrup(id)
        if (response.data.statcode === 0) {
          this.preview.message = response.data.message
          this.preview.messageValue = '1'
        } else {
          this.preview.message = response.data.message
          this.preview.messageValue = '2'
        }
        this.$refs['modal-notif'].show()
        this.$refs.table.refresh()
      } catch (error) {
        this.preview.message = error.message
        this.preview.messageValue = '2'
        this.$refs['modal-notif'].show()
      }
    },
    async getPegawaiKsm () {
      try {
        const response = await newServices.selectizePegawaiKsmGrup()
        this.options.pegawai = response.data.data
      } catch (error) {
        this.preview.message = error.message
        this.preview.messageValue = '2'
      }
    },
    valuePegawai ($event, param) {
      if (param === '1') {
        this.formData.ksm_fatmawati = $event.ksm_fatmawati
        this.formData.keterangan = $event.keterangan
      } else if (param === '2') {
        this.formDataUpdate.ksm_fatmawati = $event.ksm_fatmawati
        this.formDataUpdate.keterangan = $event.keterangan
      }
    },
    clear () {
      this.selected.pegawai = []
      //
      this.formData.ksm_fatmawati = ''
      this.formData.keterangan = ''
      //
      this.formDataUpdate.ksm_fatmawati = ''
      this.formDataUpdate.keterangan = ''
    }
  }
}
</script>
<style>
#modal-ksm-pegawai-grup .custom-control-label, #modal-ksm-pegawai-grup-update .custom-control-label {
  font-size: 12px;
  margin-top: 0;
}
#modal-ksm-pegawai-grup .multiselect__single, #modal-ksm-pegawai-grup-update .multiselect__single {
  font-weight: normal;
  font-size: 13px!important;
}
#modal-ksm-pegawai-grup .multiselect__placeholder, #modal-ksm-pegawai-grup-update .multiselect__placeholder {
  font-weight: normal;
  font-size: 13px!important;
}
#modal-ksm-pegawai-grup .multiselect__option, #modal-ksm-pegawai-grup-update .multiselect__option {
  font-weight: normal;
    font-size: 13px;
}
#modal-ksm-pegawai-grup .multiselect__tags, #modal-ksm-pegawai-grup-update .multiselect__tags {
  font-weight: normal;
  padding: 5px 40px 0 8px!important;
  height: 38px!important;
  min-height: 38px!important;
}
#modal-ksm-pegawai-grup .modal-body, #modal-ksm-pegawai-grup-update .modal-body {
  background: #3399331b
}

#modal-ksm-pegawai-grup .modal-header, #modal-ksm-pegawai-grup-update .modal-header {
  background: #3399333a;
}

#modal-ksm-pegawai-grup .modal-footer, #modal-ksm-pegawai-grup-update .modal-footer {
  background: #3399333a;
}
#sdm-ksm-pegawai-grup .page-link {
  font-size: 14px;
  padding-top: 5px;
  padding-bottom: 5px;
  margin-right: 10px!important;
}
#sdm-ksm-pegawai-grup .custom-button-radius {
  border: 1px solid rgba(0, 0, 0, 0.112);
  border-radius: 5px!important;
}
#sdm-ksm-pegawai-grup .table td {
    font-size: 12px;
    font-weight: 500;
    padding-top: 0px;
    padding-bottom: 0px;
}
#sdm-ksm-pegawai-grup .table th {
    font-size: 13px;
    font-weight: 400;
}
#sdm-ksm-pegawai-grup .cstm-select-pbf {
  font-size: 13px;
  height: 38px!important;
  border-radius: 5px;
}
#sdm-ksm-pegawai-grup .cstm-srch-pbf {
  font-size: 13px;
  height: 38px!important;
  border-radius: 5px;
}
#sdm-ksm-pegawai-grup .cstm-slct-pbf {
  font-size: 13px;
  height: 38px!important;
  border-radius: 5px;
}
.cstm-cntrl-tarif {
  background: white!important;
  color: black!important;
  border-radius: 5px!important;
  font-size: 12px!important;
  height: 38px!important;
}
.tarif-form-area {
  color: black!important;
  font-weight: bold!important;
  border-radius: 5px!important;
  font-size: 12px!important;
}
#sdm-ksm-pegawai-grup .pagination-sm .page-link {
  color: gray!important;
}
#sdm-ksm-pegawai-grup .page-item.active .page-link {
  color: white!important;
}
#sdm-ksm-pegawai-grup .page-item.active .page-link {
  color: white!important;
}
.custom-radius-add-tarif {
  border-radius: 5px;
  cursor: pointer;
}
.tarif-select-flex-left {
  width: 90%;
}
.tarif-select-flex-right {
  width: 10%;
}
.tarif-form-satuan-pbf-left-right {
  width: 40%;
}
.tarif-form-satuan-pbf-center {
  width: 15%;
}
.hr-tarif-custom {
  border-top: 1px solid rgba(0, 0, 0, 0.199)!important;
  margin: 0!important;
}
#sdm-ksm-pegawai-grup .pagination-pbf .page-link {
    border: none;
    color: gray!important;
    margin-right: 15px;
}
#sdm-ksm-pegawai-grup .pagination-pbf .page-item.active .page-link {
    background-color: transparent;
    border-color: transparent;
    color: #089bab!important;
    font-weight: bold;
}
#sdm-ksm-pegawai-grup .mb-custom {
  margin-bottom: 6px;
}
#sdm-ksm-pegawai-grup .font-custom {
  font-size: 13px;
}
#sdm-ksm-pegawai-grup .multiselect__input, .multiselect__single {
    font-weight: normal!important;
}
#sdm-ksm-pegawai-grup .multiselect__input, .multiselect__single {
    line-height: 25px;
}
#sdm-ksm-pegawai-grup .tarif-cursor {
  cursor: pointer;
}
#sdm-ksm-pegawai-grup  .multiselect__input, .multiselect {
  font-weight: normal!important;
}
#sdm-ksm-pegawai-grup .flatpickr-input[readonly], #modal-ksm-pegawai-grup .flatpickr-input[readonly], #modal-ksm-pegawai-grup-update .flatpickr-input[readonly] {
    border: 1px solid rgb(233, 233, 233);
    padding-left: 10px;
    padding-top: 5px;
    padding-bottom: 5px;
    border-radius: 5px;
}
#modal-ksm-pegawai-grup .custom-control-ksm, #modal-ksm-pegawai-grup-update .custom-control-ksm {
  height: 38px!important;
  font-size: 13px!important;
  color: black!important;
  border-radius: 5px;
  border: 1px solid rgba(0, 0, 0, 0.103);
}
</style>
