<template>
  <div class="form-wizard-mcu-offline pb-2">
    <b-container fluid>
      <div v-show="isLoading">
        <div id="show-overlay"></div>
        <Loader :style="{ opacity: .5 }" />
      </div>
      <b-row class="justify-content-center">
        <!-- <b-col cols="12" md="6" lg="4" xl="3" class="mt-3">
          <div>
            <div class="wadah rounded p-lg-3 p-2 bg-review position-relative">
              <div :class="kuotaClass">
                <div class="position-absolute wadah-child py-4 d-flex flex-column justify-content-between align-items-center">
                <div>
                  <p class="h4 text-white text-center font-weight-bold txt-shdw-reservasi mb-0">{{viewTgl}}</p>
                </div>
                <div>
                  <div v-for="k in kuotaSee" :key="k.deskripsi" class="bg-white shadow-sm rounded mb-4">
                    <div class="px-4 pt-3">
                      <p class="h6 text-black font-weight-bold mb-2">{{k.deskripsi}}</p>
                    </div>
                    <hr>
                    <div class="px-4 pb-3">
                      <p class="h6 text-black mb-2">Total Kuota : <span class="font-weight-bold text-success">{{k.jumlah_off}}</span></p>
                      <p class="h6 text-black mb-0">Kuota Tersedia : <span class="font-weight-bold text-danger">{{k.sisa}}</span></p>
                    </div>
                  </div>
                  <div v-if="kuotaSee.length === 0">
                    <p class="h6 text-white txt-shdw-reservasi mb-2">Kuota Tidak Tersedia</p>
                  </div>
                </div>
                <div>
                  <b-button variant="danger" class="font-weight-bold" @click="kuotaClose"><i class="fas fa-times-circle"></i> Tutup</b-button>
                </div>
              </div>
              </div>
              <div class="head-calendar shadow bg-white rounded text-center p-3 mb-4">
                <p class="h6 text-dark mb-0"><i class="fas fa-calendar-check"></i> CEK KUOTA VAKSIN ONLIE</p>
              </div>
              <div class="body-calendar ">
                <div class="d-flex justify-content-center shadow rounded p-3 mb-4 bg-white calender-small">
                  <template>
                    <flat-pickr :config="config" @on-change="lihatkuota" value="" class="d-none" />
                  </template>
                </div>
              </div>
              <div class="bottom-calendar shadow bg-white rounded text-center p-3">
                <p class="small text-dark mb-0">Klik Tanggal Untuk Melihat Kuota</p>
              </div>
            </div>
            <div class="bg-white rounded p-lg-3 p-2 bg-review position-relative mt-5">
              <pre>{{formData}}</pre>
            </div>
          </div>
        </b-col> -->
        <b-col cols="12" md="12" lg="8" xl="9">
          <form-wizard @onComplete="onComplete">
            <tab-content title="DATA DIRI" :selected="true" class="px-1">
              <div class="datadiri-wizard mb-4 page-scroll pb-2">
                <div class="mb-4 mt-2">
                    <div class="text-center">
                      <b-form-group class="mt-2">
                        <b-form-radio-group id="radio-group-1" v-model="formData.jenis_pasien" @change="onChangePasien" :options="jenisPasien"
                          name="radio-options"></b-form-radio-group>
                      </b-form-group>
                    </div>
                    <div class="d-flex flex-row justify-content-center" v-if="formData.jenis_pasien === '1'">
                      <div>
                        <b-form-group class="mb-0">
                          <b-form-input v-model="norm" v-on:keyup.enter="getNorm"  :state="nameState" class="form-control-utd-cek utd-form-input-cstm"
                            placeholder="Nomor Rekam Medis..." id="cekData" type="search"></b-form-input>
                        </b-form-group>
                      </div>
                      <div>
                        <b-button variant="primary" class="utd-btn-cari" @click="getNorm">Cek</b-button>
                      </div>
                    </div>
                </div>
                <div>
                  <b-row class="justify-content-center mt-3">
                    <b-col cols="12" lg="6">
                      <b-form-group label="Jenis Identitas :" class="mb-4">
                        <multiselect v-model="selectedJenisIdentitas" :show-labels="false" :limit="3" @input="valJenisIdentitas"
                          label="name" placeholder="Jenis Identitas" :options="comboJenisIdentitas" :searchable="true" :class="hasError('jenis') ? 'multiselect__tags-error' : ''">
                        </multiselect>
                      </b-form-group>
                      <b-form-group label="Nomor Identitas :" class="mb-4">
                        <b-form-input id="nomor" type="text" v-model="formData.nomor" placeholder="Nomor NIK/SIM/Passport" :class="hasError('nomor') ? 'is-invalid' : ''"></b-form-input>
                      </b-form-group>
                      <b-form-group label="Nama Lengkap :" class="mb-4">
                        <b-form-input id="nama" type="text" v-model="formData.nama" placeholder="Nama" :class="hasError('nama') ? 'is-invalid' : ''"></b-form-input>
                      </b-form-group>
                      <b-form-group label="Jenis Kelamin :" class="mb-4">
                        <multiselect v-model="selectedJenisKelamin" :show-labels="false" :limit="3" @input="valJenisKelamin"
                          label="name" placeholder="Jenis Kelamin" :options="comboJenisKelamin" :searchable="true" :class="hasError('jenis_kelamin') ? 'multiselect__tags-error' : ''">
                        </multiselect>
                      </b-form-group>
                      <b-form-group label="Tempat Lahir :" class="mb-4">
                        <multiselect v-model="selectedTempatLahir" :show-labels="false" :limit="3" @input="valTempatLahir"
                          label="deskripsi" placeholder="Tempat Lahir" :options="comboTempatLahir" :searchable="true" :class="hasError('tempat_lahir') ? 'multiselect__tags-error' : ''">
                        </multiselect>
                      </b-form-group>
                      <!-- <b-form-group label="Tanggal Lahir:" label-for="tanggal" class="mb-4">
                        <b-input-group>
                          <b-form-input id="example-input" v-model="formData.tanggal_lahir" type="text" placeholder="DD-MM-YYYY" :class="hasError('tanggal_lahir') ? 'invalid-cstm' : ''">
                          </b-form-input>
                          <b-input-group-append>
                            <b-form-datepicker id="example-datepicker2" v-model="formData.tanggal_lahir" nav-button-variant="dark"
                              button-only right locale="id" aria-controls="example-input">
                            </b-form-datepicker>
                          </b-input-group-append>
                        </b-input-group>
                      </b-form-group> -->
                      <b-form-group label="Tanggal Lahir">
                        <template>
                          <flat-pickr v-model="tglLahir" @on-change="valTglLahir" :config="configTglLahir" placeholder="Pilih Tanggal "
                            class="w-100 custom-flatpicker calender-small" :class="hasError('tanggal_lahir') ? 'flatpickr-input-error' : ''">
                          </flat-pickr>
                        </template>
                      </b-form-group>
                      <b-form-group label="Usia:" label-for="tanggal" class="mb-4">
                        <b-form-input readonly id="usia" type="text" v-model="formData.usia" placeholder="Usia Saat Ini" :class="hasError('usia') ? 'is-invalid' : ''"></b-form-input>
                      </b-form-group>
                      <b-form-group label="Agama :" class="mb-4">
                        <multiselect v-model="selectedAgama" :show-labels="false" :limit="3" @input="valAgama"
                          label="deskripsi" placeholder="Agama" :options="comboAgama" :searchable="true" :class="hasError('agama') ? 'multiselect__tags-error' : ''">
                        </multiselect>
                      </b-form-group>
                      <b-form-group label="Pendidikan :" class="mb-4">
                        <multiselect v-model="selectedPendidikan" :show-labels="false" :limit="3" @input="valPendidikan"
                          label="deskripsi" placeholder="Pendidikan" :options="comboPendidikan" :searchable="true" :class="hasError('pendidikan') ? 'multiselect__tags-error' : ''">
                        </multiselect>
                      </b-form-group>
                      <b-form-group label="Pekerjaan:" label-for="tanggal" class="mb-4">
                        <multiselect v-model="selectedPekerjaan" :show-labels="false" :limit="3" @input="valPekerjaan"
                          label="deskripsi" placeholder="Pekerjaan" :options="comboPekerjaan" :searchable="true" :class="hasError('pekerjaan') ? 'multiselect__tags-error' : ''">
                        </multiselect>
                      </b-form-group>
                      <b-form-group label="Warga Negara:" label-for="tanggal" class="mb-4">
                        <multiselect v-model="selectedNegara" :show-labels="false" :limit="3" @input="valNegara"
                          label="deskripsi" placeholder="Kewarganegaraan (Country)" :options="comboNegara" :searchable="true" :class="hasError('kewarganegaraan') ? 'multiselect__tags-error' : ''">
                        </multiselect>
                      </b-form-group>
                    </b-col>
                    <b-col cols="12" lg="6">
                      <b-form-group label="Provinsi :" class="mb-4">
                        <multiselect v-model="selectedProvinsi" :show-labels="false" :limit="3" @input="getkabupaten"
                          label="deskripsi" placeholder="Provinsi" :options="comboProvinsi" :searchable="true" :class="hasError('wilayah') ? 'multiselect__tags-error' : ''">
                        </multiselect>
                      </b-form-group>
                      <b-form-group label="Kota:" label-for="tanggal" class="mb-4">
                        <multiselect v-model="selectedKabupaten" :show-labels="false" :limit="3" @input="getkecamatan"
                          label="deskripsi" placeholder="Kabupaten/Kota" :options="comboKabupaten" :searchable="true" :class="hasError('wilayah') ? 'multiselect__tags-error' : ''">
                        </multiselect>
                      </b-form-group>
                      <b-form-group label="Kecamatan :" class="mb-4">
                        <multiselect v-model="selectedKecamatan" :show-labels="false" :limit="3" @input="getkelurahan"
                          label="deskripsi" placeholder="Kecamatan" :options="comboKecamatan" :searchable="true" :class="hasError('wilayah') ? 'multiselect__tags-error' : ''">
                        </multiselect>
                      </b-form-group>
                      <b-form-group label="Kelurahan:" label-for="tanggal" class="mb-4">
                        <multiselect v-model="selectedKelurahan" :show-labels="false" :limit="3" @input="valWilayah"
                          label="deskripsi" placeholder="Kabupaten/Kota" :options="comboKelurahan" :searchable="true" :class="hasError('wilayah') ? 'multiselect__tags-error' : ''">
                        </multiselect>
                      </b-form-group>
                      <div class="mb-4">
                        <b-row>
                          <b-col cols="12" lg="4">
                            <b-form-group label="RT :" class="mb-4">
                              <b-form-input id="nama" type="text" v-model="formData.rt" placeholder="RT" :class="hasError('rt') ? 'is-invalid' : ''"></b-form-input>
                            </b-form-group>
                          </b-col>
                          <b-col cols="12" lg="4">
                            <b-form-group label="RW :" class="mb-4">
                              <b-form-input id="nama" type="text" v-model="formData.rw" placeholder="RW" :class="hasError('rw') ? 'is-invalid' : ''"></b-form-input>
                            </b-form-group>
                          </b-col>
                          <b-col cols="12" lg="4">
                            <b-form-group label="Kodepos :" class="mb-0">
                              <b-form-input id="nama" type="text" v-model="formData.kodepos" placeholder="Kodepos" :class="hasError('kodepos') ? 'is-invalid' : ''"></b-form-input>
                            </b-form-group>
                          </b-col>
                        </b-row>
                      </div>
                      <b-form-group label="Alamat Tinggal :" class="mb-4">
                        <b-form-textarea id="textarea" v-model="formData.alamat" placeholder="Alamat" rows="3" :class="hasError('alamat') ? 'is-invalid' : ''"></b-form-textarea>
                      </b-form-group>
                      <b-form-group label="No. Handpone :" class="mb-4">
                        <b-form-input id="handpone" v-model="formData.nomor_kontak" type="text" placeholder="Handpone" :class="hasError('nomor_kontak') ? 'is-invalid' : ''"></b-form-input>
                      </b-form-group>
                      <b-form-group label="Email :" class="mb-4">
                        <b-form-input id="email" type="email" v-model="formData.email" placeholder="Email" :class="hasError('email') ? 'is-invalid' : ''"></b-form-input>
                      </b-form-group>
                    </b-col>
                  </b-row>
                </div>
              </div>
            </tab-content>
            <tab-content title="TUJUAN" class="px-3">
              <div class="tujuan-wizard py-2 my-1">
                <!-- <b-row class="">
                  <b-col cols="12" lg="6">
                    <b-form-group label="Tanggal Kedatangan:" label-for="tanggal">
                      <b-input-group>
                        <b-form-input id="example-input" v-model="formData.tanggal_reservasi"
                          type="text" placeholder="DD-MM-YYYY" :class="hasError('tanggal_reservasi') ? 'invalid-cstm' : ''">
                        </b-form-input>
                        <b-input-group-append>
                          <b-form-datepicker id="example-datepicker2" v-model="formData.tanggal_reservasi"
                            nav-button-variant="dark" :date-disabled-fn="dateDisabled" button-only right locale="id"
                            aria-controls="example-input"></b-form-datepicker>
                        </b-input-group-append>
                      </b-input-group>
                    </b-form-group>
                  </b-col>
                  <b-col cols="12" lg="6">
                    <b-form-group label="Ruangan :">
                        <multiselect v-model="selectedRuangan" :show-labels="false" :limit="3" @input="valRuangan"
                          label="name" placeholder="Ruangan" :options="comboRuangan" :searchable="true" :class="hasError('ruangan') ? 'multiselect__tags-error' : ''">
                        </multiselect>
                    </b-form-group>
                  </b-col>
                </b-row> -->
                <b-row class="mb-4">
                  <b-col cols="12" lg="6" class="mb-4">
                    <b-form-group label="Instalasi :" class="mb-0">
                      <multiselect :show-labels="false" v-model="selectedInstalasi" :options="comboInstalasi" @input="valInstalasi" :limit="3" label="deskripsi"
                        placeholder="Pilih Gedung" :searchable="true" :allow-empty="false">
                      </multiselect>
                    </b-form-group>
                  </b-col>
                  <b-col cols="12" lg="6" class="mb-4">
                    <b-form-group label="Unit :" class="mb-0">
                      <multiselect :show-labels="false" v-model="selectedUnit" :options="comboUnit" @input="valUnit" :limit="3" label="deskripsi"
                        placeholder="Pilih Gedung" :searchable="true" :allow-empty="false">
                      </multiselect>
                    </b-form-group>
                  </b-col>
                  <b-col cols="12" lg="6" class="mb-4">
                    <b-form-group label="Poli Tujuan :" class="mb-0">
                      <multiselect :show-labels="false" v-model="selectedRuangan" :options="comboRuangan" @input="valRuangan" :limit="3" label="deskripsi"
                        placeholder="Pilih Ruangan Poli" :searchable="true" :allow-empty="false" :class="hasError('ruangan') ? 'multiselect__tags-error' : ''">
                      </multiselect>
                    </b-form-group>
                  </b-col>
                  <b-col cols="12" lg="6" class="mb-4">
                    <b-form-group label="Tanggal Kedatangan :" label-for="tanggal" class="mb-0">
                      <multiselect :show-labels="false" v-model="selectedTanggal" :options="comboTanggal" @input="valTanggal" :limit="3" label="tanggal"
                        placeholder="Pilih Tanggal" :searchable="true" :allow-empty="false" :custom-label="tanggalWithHari" :class="hasError('tanggal_reservasi') ? 'multiselect__tags-error' : ''">
                      </multiselect>
                    </b-form-group>
                  </b-col>
                </b-row>
              </div>
            </tab-content>
            <tab-content title="REVIEW" class="px-lg-3">
              <div class="bg-review rounded shadow-sm p-lg-4">
                <div class="d-none d-lg-block">
                  <div class="d-flex justify-content-center mb-3">
                  <div class="title bg-white rounded shadow-sm">
                    <p class="h6 text-dark px-4 py-2 mb-0">Review Data Pasien</p>
                  </div>
                </div>
                </div>
                <div class="bg-white rounded shadow-sm p-4" v-if="formData.nik !== '' && formData.nama !== ''">
                  <b-row>
                    <b-col cols="12" lg="6">
                      <b-row class="mb-2">
                        <b-col cols="3" lg="4">
                          <p class="small text-dark mb-0">Nomor Identitas</p>
                        </b-col>
                        <b-col cols="1" lg="1">
                          <p class="small text-dark mb-0">:</p>
                        </b-col>
                        <b-col>
                          <p class="small text-dark font-weight-bold mb-0">{{formData.nomor}}</p>
                        </b-col>
                      </b-row>
                      <b-row class="mb-2">
                        <b-col cols="3" lg="4">
                          <p class="small text-dark mb-0">Nama Lengkap</p>
                        </b-col>
                        <b-col cols="1" lg="1">
                          <p class="small text-dark mb-0">:</p>
                        </b-col>
                        <b-col>
                          <p class="small text-dark font-weight-bold mb-0">{{formData.nama}}</p>
                        </b-col>
                      </b-row>
                       <b-row class="mb-2">
                        <b-col cols="3" lg="4">
                          <p class="small text-dark mb-0">Jenis Kelamin</p>
                        </b-col>
                        <b-col cols="1" lg="1">
                          <p class="small text-dark mb-0">:</p>
                        </b-col>
                        <b-col>
                          <p class="small text-dark font-weight-bold mb-0">{{selectedJenisKelamin.name}}</p>
                        </b-col>
                      </b-row>
                      <b-row class="mb-2">
                        <b-col cols="3" lg="4">
                          <p class="small text-dark mb-0">Tanggal Lahir</p>
                        </b-col>
                        <b-col cols="1" lg="1">
                          <p class="small text-dark mb-0">:</p>
                        </b-col>
                        <b-col>
                          <p class="small text-dark font-weight-bold mb-0">{{tglLahirCard}}</p>
                        </b-col>
                      </b-row>
                      <b-row class="mb-2">
                        <b-col cols="3" lg="4">
                          <p class="small text-dark mb-0">Alamat Tinggal</p>
                        </b-col>
                        <b-col cols="1" lg="1">
                          <p class="small text-dark mb-0">:</p>
                        </b-col>
                        <b-col>
                          <p class="small text-dark font-weight-bold mb-0">{{formData.alamat}}</p>
                        </b-col>
                      </b-row>
                    </b-col>
                    <b-col cols="12" lg="6">
                      <b-row class="mb-2">
                        <b-col cols="3" lg="4">
                          <p class="small text-dark mb-0">Tempat Lahir</p>
                        </b-col>
                        <b-col cols="1" lg="1">
                          <p class="small text-dark mb-0">:</p>
                        </b-col>
                        <b-col>
                          <p class="small text-dark font-weight-bold mb-0">{{selectedTempatLahir.deskripsi}}</p>
                        </b-col>
                      </b-row>
                      <b-row class="mb-2">
                        <b-col cols="3" lg="4">
                          <p class="small text-dark mb-0">Nomor Handpone</p>
                        </b-col>
                        <b-col cols="1" lg="1">
                          <p class="small text-dark mb-0">:</p>
                        </b-col>
                        <b-col>
                          <p class="small text-dark font-weight-bold mb-0">{{formData.nomor_kontak}}</p>
                        </b-col>
                      </b-row>
                      <b-row class="mb-2">
                        <b-col cols="3" lg="4">
                          <p class="small text-dark mb-0">Email</p>
                        </b-col>
                        <b-col cols="1" lg="1">
                          <p class="small text-dark mb-0">:</p>
                        </b-col>
                        <b-col>
                          <p class="small text-dark font-weight-bold mb-0">{{formData.email}}</p>
                        </b-col>
                      </b-row>
                      <b-row class="mb-2">
                        <b-col cols="3" lg="4">
                          <p class="small text-dark mb-0">Ruangan</p>
                        </b-col>
                        <b-col cols="1" lg="1">
                          <p class="small text-dark mb-0">:</p>
                        </b-col>
                        <b-col>
                          <p class="small text-success font-weight-bold mb-0">{{ selectedRuangan.deskripsi }}</p>
                        </b-col>
                      </b-row>
                      <b-row class="mb-2">
                        <b-col cols="3" lg="4">
                          <p class="small text-dark mb-0">Tanggal Reservasi</p>
                        </b-col>
                        <b-col cols="1" lg="1">
                          <p class="small text-dark mb-0">:</p>
                        </b-col>
                        <b-col>
                          <p class="small text-success font-weight-bold mb-0">{{formData.tanggal_reservasi}}</p>
                        </b-col>
                      </b-row>
                    </b-col>
                  </b-row>
                </div>
                <div v-else>
                  <p class="text-white-50 text-center mb-0">Data Belum Lengkap</p>
                </div>
              </div>
            </tab-content>
          </form-wizard>
        </b-col>
      </b-row>
      <!-- <pre>{{ formData }}</pre> -->
    </b-container>
    <b-modal ref="modal-sukses-norm" size="md" title="Informasi" ok-title="Oke" :ok-only=true
      :hide-header=true centered>
      <div class="text-center px-lg-4 px-2">
        <lottie :options="defaultOptions" :autoPlay="true" :width="150" :height="150" />
        <p class="h6 text-success font-weight-bold text-uppercase mb-0">{{respon}}</p>
      </div>
    </b-modal>
    <b-modal ref="modal-sukses-qrcode" size="md" title="Informasi" ok-title="Oke" @ok="refreshpage" :no-close-on-backdrop="true" :static="true" :ok-only="true" :hide-header="true" centered>
      <div class="text-center px-2 py-3">
        <div class="mb-2">
          <p class="h3 text-success font-weight-bold mb-1"><i class="fas fa-check-circle"></i> Berhasil</p>
          <p class="text-dark text-capitalize mb-0"> {{respon}}</p>
        </div>
        <div>
          <template>
            <qrcode-vue :value="qrcode ? qrcode[0].nomor_reservasi : ''" :size="size" level="H" />
          </template>
        </div>
        <div class="mt-3">
          <p class="h3 font-weight-bold text-dark mb-0" v-if="qrcode">{{qrcode[0].nomor_reservasi}}</p>
        </div>
         <div class="mt-3">
          <p class="text-dark mb-0" v-if="qrcode">Estimasi : {{qrcode[0].estimasi}}</p>
          <p class="h6 font-weight-bold text-dark mb-0" v-if="qrcode">No. Antrian : {{qrcode[0].antrian}}</p>
        </div>
      </div>
    </b-modal>
    <b-modal ref="modal-gagal-norm" size="md" title="Informasi" ok-title="Saya Mengerti" :ok-only=true
      :hide-header=true centered>
      <div class="text-center">
        <lottie :options="defaultOptions2" :autoPlay="true" :width="150" :height="150" />
        <p class="h6 font-weight-bold text-capitalize text-danger">{{error}}</p>
      </div>
    </b-modal>
    <b-modal ref="modal-gagal-normValid" size="md" title="Informasi" ok-title="Saya Mengerti" :ok-only=true
      :hide-header=true centered>
      <div class="text-center">
        <lottie :options="defaultOptions2" :autoPlay="true" :width="150" :height="150" />
        <p class="h6 font-weight-bold text-capitalize text-danger">{{error}}</p>
      </div>
    </b-modal>
    <b-modal ref="modal-gagal-kirim-2" size="md" title="Informasi" ok-title="Saya Mengerti" :ok-only=true
      :hide-header=true centered>
      <div class="text-center">
        <lottie :options="defaultOptions2" :autoPlay="true" :width="150" :height="150" />
        <p class="h6 text-danger">Gagal : {{error}}</p>
      </div>
    </b-modal>
  </div>
</template>
<script>
import { xray } from '../../config/pluginInit'
import { FormWizard, TabContent, ValidationHelper } from 'vue-step-wizard'
import 'vue-step-wizard/dist/vue-step-wizard.css'
import Loader from '../../components/xray/loader/Loader'
import jwtdecode from 'jwt-decode'
import Online from '../../services/reservasi_offline'
import Service from '../../services/fatmahost/index'
import Reservasi from '../../services/fatmahost/reservasi/index.ts'
import Multiselect from 'vue-multiselect'
import Lottie from 'vue-lottie'
import animationLogo from '../../assets/lottie/checked.json'
import animationLogo2 from '../../assets/lottie/failed.json'
import QrcodeVue from 'qrcode.vue'
import flatPickr from 'vue-flatpickr-component'
import 'flatpickr/dist/flatpickr.css'
import { required, email, numeric } from 'vuelidate/lib/validators'
import moment from 'moment'
// import Token from '../../services/token.js'

export default {
  name: 'ReservasiMeningitisOffline',
  components: {
    FormWizard,
    TabContent,
    Loader,
    Multiselect,
    QrcodeVue,
    Lottie,
    flatPickr
  },
  data () {
    return {
      isLoading: false,
      jenispasien: 1,
      viewTgl: '',
      formData: {
        object: 'meningitis',
        jenis: '',
        nomor: '',
        norm: 0,
        nama: '',
        tempat_lahir: null,
        tanggal_lahir: '',
        usia: '',
        jenis_kelamin: '',
        agama: null,
        nomor_kontak: '',
        alamat: '',
        rt: '',
        rw: '',
        kodepos: '',
        kewarganegaraan: null,
        pendidikan: null,
        pekerjaan: null,
        jenis_reservasi: 2,
        jenis_pasien: '1',
        ruangan: null,
        tanggal_reservasi: '',
        email: '',
        wilayah: null,
        status: '1',
        created_in: xray.currentUser().id
      },
      tglpelayanan: '',
      tglLahir: '',
      validationRules: [
        { jenis: { required }, nomor: { required, numeric }, nama: { required }, tempat_lahir: { required }, tanggal_lahir: { required }, wilayah: { required }, usia: { required }, agama: { required }, pekerjaan: { required }, kewarganegaraan: { required }, pendidikan: { required }, jenis_kelamin: { required }, alamat: { required }, rt: { required }, rw: { required }, kodepos: { required }, nomor_kontak: { required }, email: { required, email } },
        { tanggal_reservasi: { required }, ruangan: { required } }
      ],
      selectedTempatLahir: [],
      selectedAgama: [],
      selectedPendidikan: [],
      selectedPekerjaan: [],
      selectedNegara: [],
      selectedProvinsi: [],
      selectedInstalasi: [],
      selectedUnit: [],
      selectedRuangan: [],
      selectedTanggal: [],
      selectedKabupaten: [],
      selectedKecamatan: [],
      selectedKelurahan: [],
      selectedJenisKelamin: [],
      selectedJenisIdentitas: [],
      selectedMember: [],
      selectedSkrining: [],
      comboMember: [],
      comboTempatLahir: [],
      comboAgama: [],
      comboPendidikan: [],
      comboPekerjaan: [],
      comboNegara: [],
      comboProvinsi: [],
      comboKabupaten: [],
      comboKecamatan: [],
      comboKelurahan: [],
      comboInstalasi: [],
      comboUnit: [],
      comboRuangan: [],
      comboTanggal: [],
      comboJenisKelamin: [
        { name: 'Laki - laki', id: 1 },
        { name: 'Perempuan', id: 2 }
      ],
      comboJenisIdentitas: [
        { name: 'KTP (NIK)', id: 1 },
        { name: 'SIM', id: 2 },
        { name: 'Passport', id: 4 }
      ],
      comboSkrining: [
        { name: 'Skrining 1', id: 1 },
        { name: 'Skrining 2', id: 2 },
        { name: 'Skrining 3', id: 3 },
        { name: 'Skrining 4', id: 4 },
        { name: 'Skrining 5', id: 5 }
      ],
      jenisPasien: [
        { text: 'Pasien Lama', value: '1' },
        { text: 'Pasien Baru', value: '2' }
      ],
      tabIndex: 1,
      norm: '',
      value: '',
      qrcode: '',
      size: 250,
      error: '',
      respon: '',
      classHide: 'd-none',
      defaultOptions: { animationData: animationLogo, loop: true },
      defaultOptions2: { animationData: animationLogo2, loop: true },
      config: {
        minDate: 'today',
        dateFormat: 'd-m-Y',
        inline: true
      },
      configTglLahir: {
        dateFormat: 'd-m-Y',
        disableMobile: true
      },
      kuotaSee: [],
      kuotaClass: 'd-none',
      selected: 'first',
      options: [
        { text: 'Toggle this custom radio', value: 'first' },
        { text: 'Or toggle this other custom radio', value: 'second' },
        { text: 'This one is Disabled', value: 'third', disabled: true },
        { text: 'This is the 4th radio', value: { fourth: 4 } }
      ],
      tglResponNorm: '',
      normMember: ''
    }
  },
  mounted () {
    // this.cekToken()
    xray.index()
    this.wilayah()
    this.agama()
    this.pendidikan()
    this.pekerjaan()
    this.Negara()
    this.getprovinsi()
    // this.refreshtoken()
    this.member()
    this.getinstalasi()
  },
  computed: {
    nameState () {
      return this.norm.length === 8
    },
    tglLahirCard () {
      var months = new Array(12)
      months[0] = 'January'
      months[1] = 'February'
      months[2] = 'March'
      months[3] = 'April'
      months[4] = 'May'
      months[5] = 'June'
      months[6] = 'July'
      months[7] = 'August'
      months[8] = 'September'
      months[9] = 'October'
      months[10] = 'November'
      months[11] = 'December'
      var date = new Date(this.formData.tanggal_lahir)
      var year = date.getFullYear()
      var month = date.getMonth()
      var day = date.getDate()
      var tglFinal
      tglFinal = day + ' ' + months[month] + ', ' + year
      return tglFinal
    }
  },
  mixins: [ValidationHelper],
  methods: {
    onChangePasien () {
      this.norm = ''
      this.formData.jenis = ''
      this.formData.nomor = ''
      this.formData.norm = 0
      this.formData.nama = ''
      this.formData.tempat_lahir = null
      this.selectedTempatLahir = []
      this.formData.tanggal_lahir = ''
      this.tglLahir = ''
      this.formData.usia = ''
      this.formData.jenis_kelamin = ''
      this.selectedJenisKelamin = []
      this.formData.agama = null
      this.selectedAgama = []
      this.formData.nomor_kontak = ''
      this.formData.alamat = ''
      this.formData.rt = ''
      this.formData.rw = ''
      this.formData.kodepos = ''
      this.formData.kewarganegaraan = null
      this.selectedNegara = []
      this.formData.pendidikan = null
      this.selectedPendidikan = []
      this.formData.pekerjaan = null
      this.selectedPekerjaan = []
      this.formData.email = ''
      this.formData.nomor_kontak_keluarga = ''
      this.formData.wilayah = null
      this.selectedProvinsi = []
      this.selectedKabupaten = []
      this.selectedKecamatan = []
      this.selectedKelurahan = []
    },
    async getNorm () {
      const data = this.norm
      if (data.length === 8) {
        this.isLoading = true
        await Online.showByRm(data).then(response => {
          if (response.status === 200) {
            this.formData.norm = response.data.data[0].NORM
            this.formData.jenis = response.data.data[0].JENIS_NIK
            this.selectedJenisIdentitas = response.data.data[0].JENIS_NIK === 1 ? { name: 'KTP (NIK)', id: response.data.data[0].JENIS_NIK } : response.data.data[0].JENIS_NIK === 2 ? { name: 'SIM', id: response.data.data[0].JENIS_NIK } : response.data.data[0].JENIS_NIK === 4 ? { name: 'Passport', id: response.data.data[0].JENIS_NIK } : []
            this.formData.nomor = response.data.data[0].JENIS_NIK === 1 ? response.data.data[0].NIK : response.data.data[0].JENIS_NIK === 2 ? response.data.data[0].SIM : response.data.data[0].PASSPORT
            this.formData.nama = response.data.data[0].NAMA
            this.formData.jenis_kelamin = response.data.data[0].JENIS_KELAMIN
            this.selectedJenisKelamin = response.data.data[0].JENIS_KELAMIN === null ? [] : { name: response.data.data[0].NAMA_JENIS_KELAMIN, id: response.data.data[0].JENIS_KELAMIN }
            this.formData.agama = response.data.data[0].AGAMA
            this.selectedAgama = response.data.data[0].AGAMA === null ? [] : { deskripsi: response.data.data[0].DESKRIPSI_AGAMA, id: response.data.data[0].AGAMA }
            this.formData.pendidikan = response.data.data[0].PENDIDIKAN
            this.selectedPendidikan = response.data.data[0].PENDIDIKAN === null ? [] : { deskripsi: response.data.data[0].DESKRIPSI_PENDIDIKAN, id: response.data.data[0].PENDIDIKAN }
            this.formData.pekerjaan = response.data.data[0].PEKERJAAN
            this.selectedPekerjaan = response.data.data[0].PEKERJAAN === null ? [] : { deskripsi: response.data.data[0].DESKRIPSI_PEKERJAAN, id: response.data.data[0].PEKERJAAN }
            this.formData.kewarganegaraan = response.data.data[0].KEWARGANEGARAAN
            this.selectedNegara = response.data.data[0].KEWARGANEGARAAN === null ? [] : { deskripsi: response.data.data[0].DESKRIPSI_KEWARGANEGARAAN, id: response.data.data[0].KEWARGANEGARAAN }
            this.formData.alamat = response.data.data[0].ALAMAT
            this.selectedProvinsi = response.data.data[0].KELURAHAN === null ? [] : { deskripsi: response.data.data[0].NAMA_PROVINSI, id: response.data.data[0].PROVINSI }
            this.selectedKabupaten = response.data.data[0].KELURAHAN === null ? [] : { deskripsi: response.data.data[0].NAMA_KABUPATEN, id: response.data.data[0].KABUPATEN }
            this.selectedKecamatan = response.data.data[0].KELURAHAN === null ? [] : { deskripsi: response.data.data[0].NAMA_KECAMATAN, id: response.data.data[0].KECAMATAN }
            this.selectedKelurahan = response.data.data[0].KELURAHAN === null ? [] : { deskripsi: response.data.data[0].NAMA_KELURAHAN, id: response.data.data[0].KELURAHAN }
            this.formData.wilayah = response.data.data[0].KELURAHAN === null ? '' : response.data.data[0].KELURAHAN
            this.formData.rt = response.data.data[0].RT
            this.formData.rw = response.data.data[0].RW
            this.formData.kodepos = response.data.data[0].KODEPOS
            this.formData.nomor_kontak = response.data.data[0].NOMOR
            this.tglResponNorm = response.data.data[0].TANGGAL_LAHIR
            this.formData.tempat_lahir = response.data.data[0].TEMPAT_LAHIR
            this.selectedTempatLahir = { deskripsi: response.data.data[0].nmTempatLahir, id: response.data.data[0].TEMPAT_LAHIR }
            this.classHide = 'd-block'
            this.classReviewHide = 'd-block'
            this.respon = response.data.message
            this.$refs['modal-sukses-norm'].show()
            const tglUmur = response.data.data[0].TANGGAL_LAHIR
            this.tglLahir = tglUmur
            this.getAge(tglUmur)
          }
          this.isLoading = false
        }).catch(error => {
          this.error = error.response
          this.isLoading = false
          this.formData.jenis = ''
          this.formData.nomor = ''
          this.formData.norm = 0
          this.formData.nama = ''
          this.formData.tempat_lahir = null
          this.selectedTempatLahir = []
          this.formData.tanggal_lahir = ''
          this.tglLahir = ''
          this.formData.usia = ''
          this.formData.jenis_kelamin = ''
          this.selectedJenisKelamin = []
          this.formData.agama = null
          this.selectedAgama = []
          this.formData.nomor_kontak = ''
          this.formData.alamat = ''
          this.formData.rt = ''
          this.formData.rw = ''
          this.formData.kodepos = ''
          this.formData.kewarganegaraan = null
          this.selectedNegara = []
          this.formData.pendidikan = null
          this.selectedPendidikan = []
          this.formData.pekerjaan = null
          this.selectedPekerjaan = []
          this.formData.email = ''
          this.formData.nomor_kontak_keluarga = ''
          this.formData.wilayah = null
          this.selectedProvinsi = []
          this.selectedKabupaten = []
          this.selectedKecamatan = []
          this.selectedKelurahan = []
          this.error = error.response.data.message
          this.$refs['modal-gagal-norm'].show()
        })
      } else {
        this.isLoading = false
        this.formData.jenis = ''
        this.formData.nomor = ''
        this.formData.norm = 0
        this.formData.nama = ''
        this.formData.tempat_lahir = null
        this.selectedTempatLahir = []
        this.formData.tanggal_lahir = ''
        this.tglLahir = ''
        this.formData.usia = ''
        this.formData.jenis_kelamin = ''
        this.selectedJenisKelamin = []
        this.formData.agama = null
        this.selectedAgama = []
        this.formData.nomor_kontak = ''
        this.formData.alamat = ''
        this.formData.rt = ''
        this.formData.rw = ''
        this.formData.kodepos = ''
        this.formData.kewarganegaraan = null
        this.selectedNegara = []
        this.formData.pendidikan = null
        this.selectedPendidikan = []
        this.formData.pekerjaan = null
        this.selectedPekerjaan = []
        this.formData.email = ''
        this.formData.nomor_kontak_keluarga = ''
        this.formData.wilayah = null
        this.selectedProvinsi = []
        this.selectedKabupaten = []
        this.selectedKecamatan = []
        this.selectedKelurahan = []
        this.error = 'nomor rekam medis tidak 8 digit'
        this.$refs['modal-gagal-normValid'].show()
      }
    },
    async lihatkuota (selectedDates, dateStr, instance) {
      const jnsrsv = this.formData.jenis_reservasi
      const date = dateStr.split('-').reverse().join('-')
      const ruangan = 101270132
      await Online.cekKuota(ruangan, date, jnsrsv).then(response => {
        this.viewTgl = dateStr
        this.kuotaSee = response.data.data
        this.kuotaClass = 'd-block'
      }).catch(error => {
        this.viewTgl = dateStr
        this.kuotaSee = error.response.data.data
        this.kuotaClass = 'd-block'
      })
    },
    valTglLahir () {
      const tglStr = this.tglLahir
      const tgl = tglStr.split('-').reverse().join('-')
      this.formData.tanggal_lahir = tgl
      this.getAge(tglStr)
    },
    async member () {
      try {
        const res = await Online.getMember(jwtdecode(localStorage.getItem('patient')).user.id)
        this.comboMember = res.data.data
        this.respon = res.data.message
      } catch (err) {
        this.error = err.response.data.message
      }
    },
    async wilayah () {
      try {
        const res = await Online.getWilayah()
        this.comboTempatLahir = res.data.tempatLahir
      } catch (err) {
        this.error = err
      }
    },
    async agama () {
      try {
        const res = await Online.agama()
        this.comboAgama = res.data.agama
      } catch (err) {
        this.error = err
      }
    },
    async pendidikan () {
      try {
        const res = await Online.pendidikan()
        this.comboPendidikan = res.data.pendidikan
      } catch (err) {
        this.error = err
      }
    },
    async pekerjaan () {
      try {
        const res = await Online.pekerjaan()
        this.comboPekerjaan = res.data.pekerjaan
      } catch (err) {
        this.error = err
      }
    },
    async Negara () {
      try {
        const res = await Online.kewarganegaraan()
        this.comboNegara = res.data.kewarganegaraan
      } catch (err) {
        this.error = err
      }
    },
    async getprovinsi () {
      await Online.provinsi().then(response => {
        if (response.status === 200) {
          this.comboProvinsi = response.data.provinsi
        }
      }).catch(error => {
        this.error = error.response
      })
    },
    async getkabupaten (value) {
      const data = {
        id: value.id
      }
      await Online.kabupaten(data).then(response => {
        if (response.status === 200) {
          this.comboKabupaten = response.data.kabupaten
        }
      }).catch(error => {
        this.error = error.response
      })
    },
    async getkecamatan (value) {
      const data = {
        id: value.id
      }
      await Online.kecamatan(data).then(response => {
        if (response.status === 200) {
          this.comboKecamatan = response.data.kecamatan
        }
      }).catch(error => {
        this.error = error.response
      })
    },
    async getkelurahan (value) {
      const data = {
        id: value.id
      }
      await Online.kelurahan(data).then(response => {
        if (response.status === 200) {
          this.comboKelurahan = response.data.desa
        }
      }).catch(error => {
        this.error = error.response
      })
    },
    async getinstalasi () {
      await Service.master.instalasimeningitis().then(response => {
        if (response.status === 200) {
          this.comboInstalasi = response.data.data
        }
      }).catch(error => {
        this.error = error.response
      })
    },
    async getunit (value) {
      const data = value.id
      await Service.master.unitmeningitis(data).then(response => {
        if (response.status === 200) {
          this.comboUnit = response.data.data
        }
      }).catch(error => {
        this.error = error.response
      })
    },
    async getruangan (value) {
      const data = value.id
      await Service.master.ruangancekkuotameningitis(data).then(response => {
        if (response.data.statcode === 0) {
          this.comboRuangan = response.data.data
        } else {
          this.comboRuangan = [{ id: null, deskripsi: 'Kuota Poli Tidak Tersedia', $isDisabled: true }]
        }
      }).catch(error => {
        this.error = error.response
        this.comboRuangan = [{ id: null, deskripsi: 'FrontEnd Error', $isDisabled: true }]
      })
    },
    async gettanggal (value) {
      const data = value.id
      await Service.master.tanggalcekkuotameningitis(data).then(response => {
        if (response.data.statcode === 0) {
          this.comboTanggal = response.data.data
        } else {
          this.comboTanggal = [{ id: null, tanggal: 'Tanggal Tidak Tersedia', $isDisabled: true }]
        }
      }).catch(error => {
        this.error = error.response
        this.comboTanggal = [{ id: null, tanggal: 'FrontEnd Error', $isDisabled: true }]
      })
    },
    nameWithNorm ({ nama, norm, deskripsi }) {
      return `${nama} — ${norm.toString().padStart(8, '0')} — ${deskripsi}`
    },
    tanggalWithHari ({ tanggal, hari }) {
      return `${hari} (${tanggal.split('-').reverse().join('-')})`
    },
    valMember (value) {
      this.normMember = value.norm
      this.valMemberAutoDigit()
    },
    valMemberAutoDigit () {
      const temp = this.normMember?.toString() || ''
      const generate = temp.padStart(8, '0')
      this.getNorm(generate)
    },
    valTempatLahir (value) {
      this.formData.tempat_lahir = value.id
    },
    valJenisKelamin (value) {
      this.formData.jenis_kelamin = value.id
    },
    valJenisIdentitas (value) {
      this.formData.jenis = value.id
    },
    valInstalasi (value) {
      this.getunit(value)
    },
    valUnit (value) {
      this.getruangan(value)
    },
    valRuangan (value) {
      this.formData.ruangan = value.id
      this.gettanggal(value)
    },
    valTanggal (value) {
      this.formData.tanggal_reservasi = value.tanggal
    },
    valAgama (value) {
      this.formData.agama = value.id_referensi
    },
    valPendidikan (value) {
      this.formData.pendidikan = value.id_referensi
    },
    valPekerjaan (value) {
      this.formData.pekerjaan = value.id_referensi
    },
    valNegara (value) {
      this.formData.kewarganegaraan = value.id
    },
    valWilayah (value) {
      this.formData.wilayah = value.id
    },
    async onComplete () {
      const data = this.formData
      await Reservasi.createRsv(data).then(response => {
        if (response.data[0].statcode === 0) {
          this.respon = response.data[0].message
          this.qrcode = JSON.parse(response.data[0].data)
          this.$refs['modal-sukses-qrcode'].show()
        } else {
          this.error = response.data[0].message
          this.$refs['modal-gagal-kirim-2'].show()
        }
        this.isLoading = false
      }).catch(error => {
        this.error = error.message
        this.$refs['modal-gagal-kirim-2'].show()
      })
    },
    kuotaClose () {
      this.kuotaClass = 'd-none'
    },
    dateDisabled (ymd, date) {
      const today = new Date()
      today.setHours(0, 0, 0, 0)
      return date < today
    },
    resetTujuan () {
      this.formData.ruangan = null
      this.formData.jenis_skrining = null
      this.formData.tanggal_reservasi = ''
    },
    refreshpage () {
      location.reload()
    },
    getAge (date) {
      if (date) {
        const tgl = date.split('-').reverse().join('-')
        const today = moment(new Date())
        const dob = moment(new Date(tgl))
        var duration = moment.duration(today.diff(dob))
        var age = duration.years().toString().padStart(2, '0') + ' Tahun ' + duration.months().toString().padStart(2, '0') + ' Bulan ' + duration.days().toString().padStart(2, '0') + ' Hari'
      }
      this.formData.usia = age
    }
  }
}
</script>
<style>
@import '../../assets/css/pages/public/reservasi-meningitis-mcu-offline.css';
</style>
