<template>
  <div id="monitoring-db" class="mb-4">
    <div v-show="isLoading">
        <div id="show-overlay"></div>
      <Loader :style="{ opacity: .5 }" />
    </div>
    <b-container fluid>
      <div class="bg-white border custom-radius-top">
        <div class="py-2 px-2">
          <div class="d-flex flex-row align-items-center">
            <div class="mr-auto">
              <h5 class="card-title text-dark text-uppercase m-0 p-0">Monitoring Database Read</h5>
            </div>
            <div class="mr-2">
              <multiselect :options="options.interval" v-model="selected.interval" @input="valueInterval" :allow-empty="false" label="desc" :show-labels="false" placeholder="Interval" :searchable="true">
              </multiselect>
            </div>
            <div class="mr-2">
              <b-button :disabled="disablebtn" :variant="disablebtn === true ? 'secondary' : 'danger'" size="sm" @click="stopInterval">
                <i class="far fa-stop-circle"></i> Berhenti
              </b-button>
            </div>
            <!-- <div>
              <b-button variant="primary" size="sm" @click="refreshTable">
                <i class="fas fa-sync"></i>
                Refresh
              </b-button>
            </div> -->
          </div>
        </div>
        <div class="table table-list-pasien mb-0">
          <b-table class="mb-0" ref="table" :busy="table.isBusy" :items="table.items" :fields="table.fields" :sort-by.sync="table.sortBy" :sort-desc.sync="table.sortDesc"
            :current-page="table.currentPage" :per-page="table.perPage" :filter="table.filter" @filtered="onFiltered" :tbody-tr-class="rowClass" stacked="md"
            bordered hover small show-empty>
            <template #table-busy>
              <div class="text-center my-2">
                <b-spinner class="align-middle text-primary mr-2"></b-spinner>
                <strong class="text-dark">Memuat Data...</strong>
              </div>
            </template>
            <template #cell(index)="data">
              <div>
                <span>{{ (table.currentPage*table.perPage)-table.perPage + data.index + 1 }}</span>
              </div>
            </template>
            <template #cell(second)="data">
              <div>
                <span>{{ data.item.second }} detik</span>
              </div>
            </template>
            <template #cell(query)="data">
              <div :id="'query-id-' + data.index" class="height-static">
                <span>{{ data.item.query }}</span>
              </div>
            </template>
            <template #cell(action)="data">
              <div class="px-2 d-flex px-3">
                <b-button class="mr-auto" size="sm" variant="white" v-b-popover.hover.top="'Salin Query'" @click="copyClipboard(data.index)">
                  <i class="far fa-copy fa-lg text-primary"></i>
                </b-button>
                <span>
                  <b-button variant="white" size="sm" @click="read(data.index, 'more')" v-if="conditionArr[data.index].value === false && data.item.query.length > 170"><i class="fas fa-chevron-down fa-lg"></i></b-button>
                  <b-button variant="white" size="sm" @click="read(data.index, 'less')" v-if="conditionArr[data.index].value === true"><i class="fas fa-chevron-up fa-lg"></i></b-button>
                </span>
              </div>
            </template>
          </b-table>
        </div>
      </div>
    </b-container>
  </div>
</template>
<script>
import { xray } from '../../../config/pluginInit.js'
import Loader from '../../../components/xray/loader/Loader'
import Services from '../../../services/fatmahost/log/index.ts'
import Multiselect from 'vue-multiselect'

export default {
  name: 'Database',
  components: {
    Multiselect,
    Loader
  },
  data () {
    return {
      isLoading: false,
      message: '',
      state: null,
      interval: null,
      disablebtn: true,
      table: {
        isBusy: false,
        items: this.list,
        fields: [
          { label: 'NO.', key: 'index', class: 'text-lg-center font-weight-bold py-2' },
          { label: 'ADRESS', key: 'client_addr', class: 'text-center' },
          { label: 'PORT', key: 'client_port', class: 'text-center' },
          { label: 'PID', key: 'pid', class: 'text-center' },
          { label: 'TIME', key: 'second', class: 'text-center' },
          // { label: 'BLOCK STATS', key: 'block_stats', class: 'text-center' },
          // { label: 'BLOCK STATUS', key: 'blockstatus', class: 'text-center' },
          { label: 'QUERY', key: 'query', class: 'col-8 py-1' },
          { label: 'ACTION', key: 'action', class: 'text-center col-1' }
        ],
        filter: null,
        filterOn: [],
        pageOptions: [10, 50, 100, 500],
        currentPage: null,
        perPage: null,
        totalRows: null,
        sortBy: '',
        sortDesc: false,
        selectMode: 'single'
      },
      options: {
        interval: [
          { value: 1000, desc: '1 Detik' },
          { value: 2000, desc: '2 Detik' },
          { value: 3000, desc: '3 Detik' },
          { value: 4000, desc: '4 Detik' },
          { value: 5000, desc: '5 Detik' },
          { value: 10000, desc: '10 Detik' },
          { value: 20000, desc: '20 Detik' },
          { value: 40000, desc: '40 Detik' },
          { value: 60000, desc: '1 Menit' }
        ]
      },
      selected: {
        interval: []
      },
      conditionArr: []
    }
  },
  mounted () {
    xray.index()
  },
  methods: {
    reloadTable () {
      this.state = setInterval(() => {
        this.$refs.table.refresh()
      }, this.interval)
    },
    async list () {
      this.table.isBusy = true
      try {
        const obj = 'postgres-read'
        const response = await Services.find(obj)
        if (response.status === 200) {
          this.table.isBusy = false
          this.readCondition(response.data.data.length)
          return response.data.data
        } else {
          return []
        }
      } catch (error) {
        this.table.isBusy = false
        this.message = error.message
        return []
      }
    },
    rowClass (item, type) {
      if (!item || type !== 'row') return
      if (item.block_stats === 0 && item.second > 0 && item.second < 20) return 'bg-success text-white'
      if (item.block_stats === 0 && item.second > 20 && item.second < 40) return 'bg-warning text-dark'
      if (item.block_stats === 0 && item.second > 40) return 'bg-danger text-dark'
      if (item.block_stats === 1 && item.second > 100) return 'bg-pink text-white'
    },
    onFiltered (filteredItems) {
      this.table.totalRows = filteredItems.length
    },
    refreshTable () {
      this.$refs.table.refresh()
    },
    valueInterval (e) {
      clearInterval(this.state)
      this.interval = e.value
      this.disablebtn = false
      this.reloadTable()
    },
    stopInterval () {
      this.selected.interval = []
      clearInterval(this.state)
      this.disablebtn = true
    },
    readCondition (length) {
      this.conditionArr = []
      for (let i = 0; i < length; i++) {
        this.conditionArr.push({
          value: false
        })
      }
    },
    read (value, param) {
      const vElement = document.getElementById('query-id-' + value)
      if (param === 'more') {
        this.conditionArr[value].value = true
        vElement.classList.remove('height-static')
        vElement.classList.add('height-auto')
      } else {
        this.conditionArr[value].value = false
        vElement.classList.remove('height-auto')
        vElement.classList.add('height-static')
      }
    },
    copyClipboard (value) {
      const vElement = document.getElementById('query-id-' + value).textContent
      const vTextArea = document.createElement('textarea')
      vTextArea.value = vElement
      document.body.appendChild(vTextArea)
      vTextArea.select()
      document.execCommand('copy')
      document.body.removeChild(vTextArea)
      this.toast('success', 'Menyalin Query')
    },
    toast (variant, message) {
      this.$bvToast.toast(message, {
        title: `Notifikasi`,
        toaster: 'b-toaster-bottom-right',
        variant: variant,
        solid: true
      })
    }
  }
}
</script>

<style>
#monitoring-db .table td {
    font-size: 12px;
    font-weight: 500;
    padding-top: 0px;
    padding-bottom: 0px;
}
#monitoring-db .table th {
    font-size: 13px;
    font-weight: 400;
}
#monitoring-db .page-link {
  background: transparent!important;
    border: none!important;
    color: gray!important;
    margin-right: 15px;
}
#monitoring-db .page-item.active .page-link {
    background-color: transparent;
    border-color: transparent;
    color: #089bab!important;
    font-weight: bold;
}
#monitoring-db .custom-radius-top {
  border-top-left-radius: .5rem!important;
  border-top-right-radius: .5rem!important;
}
#monitoring-db .multiselect__placeholder {
  font-weight: normal;
  font-size: 13px!important;
  color: #495057c9;
}
#monitoring-db .multiselect__option {
  font-weight: normal;
  font-size: 13px;
}
#monitoring-db .multiselect__tags {
  overflow: hidden;
  font-weight: normal;
  padding: 3px 40px 0 8px!important;
  height: 33px!important;
  min-height: 33px!important;
  border: 1px solid rgba(0, 0, 0, 0.212);
}
#monitoring-db .multiselect {
  height: 33px!important;
  min-height: 33px!important;
}
#monitoring-db .multiselect__select {
  height: 33px!important;
  min-height: 33px!important;
}
#monitoring-db .multiselect__single  {
  padding-left: 0px;
}
#monitoring-db .height-auto {
  transition: 0.5s ease;
  max-height: auto;
  overflow: hidden;
}
#monitoring-db .height-static {
  transition: 0.5s ease;
  max-height: 1.5rem;
  overflow: hidden;
}
#monitoring-db .bg-pink {
  background-color: rgb(207, 113, 129);
}
</style>
