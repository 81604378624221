<template>
  <b-container fluid class="utdPemeriksaan">
    <div v-show="isLoading">
        <div id="show-overlay"></div>
      <Loader :style="{ opacity: .5 }" />
    </div>
    <b-row class="mb-3">
      <b-col>
        <iq-card class="h-100">
          <template v-slot:headerTitle>
            <h4 class="card-title"><i class="fas fa-book-medical custom-yellow"></i> Halaman Pemeriksaan</h4>
          </template>
          <template v-slot:body>
            <div>
              <b-row class="mb-3">
                <b-col cols="12" lg="7">
                  <b-row>
                    <b-col>
                      <div>
                        <div class="mb-1">
                          <b-row>
                            <b-col cols="4">
                              <p class="text-dark mb-0">Nama Pendonor</p>
                            </b-col>
                            <b-col cols="1">
                              <p class="text-dark mb-0">:</p>
                            </b-col>
                            <b-col>
                              <p class="text-dark mb-0"><span>{{nama}}</span></p>
                            </b-col>
                          </b-row>
                        </div>
                        <div class="mb-1">
                          <b-row>
                            <b-col cols="4">
                              <p class="text-dark mb-0">Umur Pendonor</p>
                            </b-col>
                            <b-col cols="1">
                              <p class="text-dark mb-0">:</p>
                            </b-col>
                            <b-col>
                              <p class="text-dark mb-0"><span>{{umur}}</span></p>
                            </b-col>
                          </b-row>
                        </div>
                        <div class="mb-0">
                          <b-row>
                            <b-col cols="4">
                              <p class="text-dark mb-0">Jenis Kelamin Pendonor</p>
                            </b-col>
                            <b-col cols="1">
                              <p class="text-dark mb-0">:</p>
                            </b-col>
                            <b-col>
                              <p class="text-dark mb-0"><span>{{ jenis_kelamin }}</span></p>
                            </b-col>
                          </b-row>
                        </div>
                      </div>
                    </b-col>
                  </b-row>
                </b-col>
                <b-col cols="12" lg="5">
                  <div class="text-right">
                    <p class="h6 mb-0">Nomor Reservasi</p>
                    <p class="h3 text-primary  mb-0">{{no_resv}}</p>
                  </div>
                </b-col>
              </b-row>
              <b-row class="mb-2">
                <b-col cols="12" lg="5" class="pr-1">
                  <div class="shadow-sm border p-3 h-100">
                    <p class="h6 mb-0 text-dark">Riwayat Berat Badan</p>
                    <div class="mt-4">
                      <div class="row mb-1">
                        <div class="col-12 col-lg-5">
                          <p class="text-dark mb-0">Berat Badan</p>
                        </div>
                        <div class="col-12 col-lg-1">
                          <p class="text-dark mb-0">:</p>
                        </div>
                        <div class="col-12 col-lg-6">
                          <p class="text-dark  mb-0">{{pasien.berat_badan}} Kg</p>
                        </div>
                      </div>
                      <div class="row mb-1">
                        <div class="col-12 col-lg-5">
                          <p class="text-dark mb-0">Status Berat Badan</p>
                        </div>
                        <div class="col-12 col-lg-1">
                          <p class="text-dark mb-0">:</p>
                        </div>
                        <div class="col-12 col-lg-6">
                          <p class="text-dark  mb-0" v-if="pasien.status_berat_badan === 1">{{pasien.deskripsi_berat_badan}}</p>
                          <p class="text-dark  mb-0" v-else><span class="text-danger">{{pasien.deskripsi_berat_badan}}</span></p>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-12 col-lg-5">
                          <p class="text-dark mb-0">Petugas Pendaftaran</p>
                        </div>
                        <div class="col-12 col-lg-1">
                          <p class="text-dark mb-0">:</p>
                        </div>
                        <div class="col-12 col-lg-6">
                          <p class="text-dark  mb-0">{{pasien.petugas_pendaftaran}}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </b-col>
                <b-col cols="12" lg="7" class="pl-1">
                  <div class="shadow-sm border p-3">
                    <p class="h6 mb-0 text-dark">Form Cek Darah :</p>
                    <b-form class="mt-4">
                      <div class="row">
                      <div class="col-12 col-lg-4 pr-1">
                        <b-form-group label="HB " class="mb-0">
                          <b-form-input id="exampleInputText1" type="text" v-model="result.hb" :state="validationHB" placeholder="Masukan HB"></b-form-input>
                          <b-form-invalid-feedback :state="validationHB">
                            HB harus lebih dari 12,5 dan kurang dari 17
                          </b-form-invalid-feedback>
                          <b-form-valid-feedback :state="validationHB">
                            HB Normal
                          </b-form-valid-feedback>
                        </b-form-group>
                      </div>
                      <div class="col-12 col-lg-4 pr-1 pl-1">
                        <b-form-group label="Golongan Darah :" class="mb-0">
                          <multiselect v-model="vselectDarah" :show-labels="false" label="deskripsi"
                            @input="valGolDarah" placeholder="Pilih Golongan Darah" :options="golDarah" :searchable="true">
                          </multiselect>
                        </b-form-group>
                      </div>
                      <div class="col-12 col-lg-4 pl-1">
                        <b-form-group label="Jenis Donor :" class="mb-0">
                          <multiselect v-model="vselectJenisPendonor" :show-labels="false" :limit="3" @input="valJenisPendonor" label="name"
                            placeholder="Pilih Jenis Pendonor" :options="comboJenisPendonor" :searchable="true">
                          </multiselect>
                        </b-form-group>
                      </div>
                    </div>
                    </b-form>
                  </div>
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="12" lg="5" class="pr-1">
                  <div class="shadow-sm border p-3 mb-4">
                    <p class="h6 mb-0 text-dark">Lot Reagen :</p>
                    <b-form class="mt-4">
                      <div class="row">
                        <div class="col-12 col-lg-6 pr-1">
                          <b-form-group label="Nomor Lot Reagen A" id="exampleInputText2">
                            <b-form-input v-model="result.nomor_reagen_a" id="exampleInputText2" type="text" placeholder="Nomor"></b-form-input>
                          </b-form-group>
                        </div>
                        <div class="col-12 col-lg-6 pl-1">
                          <b-form-group label="Tanggal Kadaluarsa Lot Reagen A">
                            <flat-pickr :config="config" @on-change="tglLot($event, 'a')" v-model="tanggalA" value="" placeholder="Pilih Tanggal"  class="w-100 custom-flatpicker calender-small">
                          </flat-pickr>
                          </b-form-group>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-12 col-lg-6 pr-1">
                          <b-form-group label="Nomor Lot Reagen B" id="exampleInputText3">
                            <b-form-input v-model="result.nomor_reagen_b" id="exampleInputText3" type="text" placeholder="Nomor"></b-form-input>
                          </b-form-group>
                        </div>
                        <div class="col-12 col-lg-6 pl-1">
                          <b-form-group label="Tanggal Kadaluarsa Lot Reagen B">
                            <flat-pickr :config="config" @on-change="tglLot($event, 'b')" v-model="tanggalB" value="" placeholder="Pilih Tanggal " class="w-100 custom-flatpicker calender-small">
                          </flat-pickr>
                          </b-form-group>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-12 col-lg-6 pr-1">
                          <b-form-group label="Nomor Lot Reagen D" id="exampleInputText4">
                            <b-form-input v-model="result.nomor_reagen_d" id="exampleInputText4" type="text" placeholder="Nomor"></b-form-input>
                          </b-form-group>
                        </div>
                        <div class="col-12 col-lg-6 pl-1">
                          <b-form-group label="Tanggal Kadaluarsa Lot Reagen D">
                            <flat-pickr :config="config" @on-change="tglLot($event, 'c')" v-model="tanggalD" value="" placeholder="Pilih Tanggal " class="w-100 custom-flatpicker calender-small">
                          </flat-pickr>
                          </b-form-group>
                        </div>
                      </div>
                    </b-form>
                  </div>
                </b-col>
              </b-row>
              <!-- <div>
                <pre>{{ result }}</pre>
                <pre>{{ pasien }}</pre>
              </div> -->
              <div class="btn-cancel" v-if="statusFinal !== 0">
                <b-button variant="warning" @click="resetFinal"><i class="fas fa-edit"></i> Perubahan Data</b-button>
              </div>
              <b-row v-if="statusFinal === 0 && result.hb !== null && result.golongan_darah !== null && result.jenis_donor !== null">
                <b-col>
                  <div class="mb-1">
                    <small class="text-danger">Peringatan : Harap Teliti Saat Pengisian</small>
                  </div>
                  <button type="button" @click="onSubmit" class="btn btn-primary">
                    <i class="fas fa-window-restore"></i> Simpan Pemeriksaan
                  </button>
                </b-col>
              </b-row>
            </div>
          </template>
        </iq-card>
      </b-col>
    </b-row>
    <b-row class="justify-content-center mb-5">
      <b-col md="2">
        <iq-card>
          <button type="button" @click="utdList" class="btn btn-primary btn-block">
            <i class="fas fa-undo"></i>  Kembali
          </button>
        </iq-card>
      </b-col>
    </b-row>
    <!-- <pre>{{ result }}</pre> -->
    <b-modal ref="modal-error" size="lg" hide-backdrop title="PERINGATAN" ok-title="Saya Mengerti" :ok-only="true" @ok="backToList" :no-close-on-backdrop="true" :static="true" :hide-header-close="true" centered>
      <div class="text-center px-4 py-5">
        <p class="h5 text-danger  mb-3">{{error}}</p>
      </div>
    </b-modal>
    <b-modal ref="modal-peringatan" size="md" title="Informasi" ok-title="Saya Mengerti" :ok-only="true" @ok="backToList" :no-close-on-backdrop="true" :static="true" :hide-header-close="true" centered>
      <div class="text-center">
        <p class="h1 text-success  mb-3"><i class="fas fa-thumbs-up"></i></p>
        <p class="h6 text-success mb-0">{{notif}}</p>
      </div>
    </b-modal>
  </b-container>
</template>
<script>
import { xray } from '../../../config/pluginInit'
import UTD from '../../../services/utd.js'
import Multiselect from 'vue-multiselect'
import flatPickr from 'vue-flatpickr-component'
import 'flatpickr/dist/flatpickr.css'
import moment from 'moment'
export default {
  name: 'UtdPemeriksaan',
  data () {
    return {
      isLoading: false,
      access: {
        isAdmin: false,
        bitcontrol2: 0
      },
      id: null,
      tgl: null,
      error: '',
      pasien: '',
      notif: '',
      dataUtility: {},
      utilityClass: 0,
      tanggalA: '',
      tanggalB: '',
      tanggalD: '',
      result: {
        id_dft_trx_reservasi_utd_detail: this.$route.params.id,
        jenis_donor: null,
        hb: null,
        golongan_darah: null,
        nomor_reagen_a: null,
        tanggal_kadaluarsa_reagen_a: '',
        nomor_reagen_b: null,
        tanggal_kadaluarsa_reagen_b: '',
        nomor_reagen_d: null,
        tanggal_kadaluarsa_reagen_d: '',
        status_pemeriksaan: 1,
        petugas_pemeriksa: xray.currentUser().id
      },
      vselectDarah: [],
      vselectJenisPendonor: [],
      golDarah: [],
      comboJenisPendonor: [
        { name: 'Langsung', id: 1 },
        { name: 'Sukarela', id: 2 },
        { name: 'Pengganti', id: 3 },
        { name: 'Plasma Aferesis', id: 4 },
        { name: 'TC Aferesis', id: 5 }
      ],
      nama: '',
      umur: '',
      jenis_kelamin: '',
      no_resv: '',
      statusFinal: 0,
      config: {
        dateFormat: 'd-m-Y',
        disableMobile: true
      },
      validasiLot: false
    }
  },
  components: {
    Multiselect,
    flatPickr
  },
  mounted () {
    xray.index()
    this.utlity()
  },
  computed: {
    validationHB () {
      return this.result.hb > '12,5' && this.result.hb <= '17'
    }
  },
  methods: {
    utlity () {
      const id = this.$route.params.id
      const nama = this.$route.query.nama
      const status = this.$route.query.status
      const umur = this.$route.query.umur
      const jk = this.$route.query.jk
      const noresrv = this.$route.query.noresrv
      this.detailPasien(id)
      this.golonganDarah()
      this.capNama(nama)
      this.capUmur(umur)
      this.capJK(jk)
      this.capResrv(noresrv)
      this.statusFinal = status
      this.access.isAdmin = xray.isAdministrator()
      this.access.bitcontrol2 = xray.bitcontrol(this.$route.name).bitcontrol2.slice(0, 1)
      if (this.access.bitcontrol2 === '1' || this.access.isAdmin === 'true') {
        this.utilityClass = 1
      }
    },
    tglLot (val, param) {
      let date = new Date(val)
      let year = date.getFullYear()
      let month = date.getMonth() + 1
      let day = date.getDate()
      if (month < 10) {
        month = '0' + month
      }
      if (day < 10) {
        day = '0' + day
      }
      let joinDate = year + '-' + month + '-' + day
      if (param === 'a') {
        this.result.tanggal_kadaluarsa_reagen_a = joinDate.toString()
      } else if (param === 'b') {
        this.result.tanggal_kadaluarsa_reagen_b = joinDate.toString()
      } else if (param === 'c') {
        this.result.tanggal_kadaluarsa_reagen_d = joinDate.toString()
      }
    },
    responseTgl () {
      let date = new Date()
      let year = date.getFullYear()
      let month = date.getMonth() + 1
      let day = date.getDate()
      if (month < 10) {
        month = '0' + month
      }
      if (day < 10) {
        day = '0' + day
      }
      let joinDate = day + '-' + month + '-' + year
      return joinDate.toString()
    },
    utdList () {
      this.$router.push({ name: 'utd.list' })
    },
    async detailPasien (id) {
      const data = {
        id: id
      }
      await UTD.viewPemeriksaan(data).then(response => {
        if (response.status === 200) {
          if (response.data.data[0].status_berat_badan !== null && response.data.data[0].berat_badan !== null) {
            if (response.data.data[0].status_berat_badan !== 2) {
              this.pasien = response.data.data[0]
              this.result.jenis_donor = this.pasien.jenis_donor
              this.result.hb = this.pasien.hb
              this.result.golongan_darah = this.pasien.golongan_darah
              this.vselectDarah = this.pasien.golongan_darah === null ? [] : { id: this.pasien.golongan_darah, deskripsi: this.pasien.deskripsi_golongan_darah }
              this.vselectJenisPendonor = this.pasien.jenis_donor === null ? [] : { id: this.pasien.jenis_donor, name: this.pasien.deskripsi_jenis_donor }
              this.result.nomor_reagen_a = this.pasien.nomor_reagen_a !== '0' ? this.pasien.nomor_reagen_a : this.pasien.nra
              this.tanggalA = this.pasien.tanggal_kadaluarsa_reagen_a ? this.pasien.tanggal_kadaluarsa_reagen_a.split('-').reverse().join('-') : this.responseTgl()
              this.result.nomor_reagen_b = this.pasien.nomor_reagen_b !== '0' ? this.pasien.nomor_reagen_b : this.pasien.nrb
              this.tanggalB = this.pasien.tanggal_kadaluarsa_reagen_b ? this.pasien.tanggal_kadaluarsa_reagen_b.split('-').reverse().join('-') : this.responseTgl()
              this.result.nomor_reagen_d = this.pasien.nomor_reagen_d !== '0' ? this.pasien.nomor_reagen_d : this.pasien.nrc
              this.tanggalD = this.pasien.tanggal_kadaluarsa_reagen_d ? this.pasien.tanggal_kadaluarsa_reagen_d.split('-').reverse().join('-') : this.responseTgl()
            } else {
              this.error = 'Tidak Lolos Berat Badan'
              this.$refs['modal-error'].show()
            }
          } else {
            this.error = 'Data Belum Lengkap'
            this.$refs['modal-error'].show()
          }
        }
      }).catch(error => {
        if (error.response.data.data[0] === undefined) {
          this.error = error.response.data.message
          this.$refs['modal-error'].show()
        }
      })
    },
    async golonganDarah () {
      try {
        const res = await UTD.golDar()
        this.golDarah = res.data.golonganDarah
      } catch (err) {
        this.error = err
      }
    },
    async onSubmit (e) {
      this.isLoading = true
      const pasienUtd = this.result
      try {
        const pasienUtdResponse = await UTD.pemeriksaan(pasienUtd)
        if (pasienUtdResponse.status === 200) {
          this.isLoading = false
          this.notif = pasienUtdResponse.data.message
          this.$refs['modal-peringatan'].show()
        } else {
          this.error = 'Gagal Menyimpan'
          this.$refs['modal-error'].show()
        }
      } catch (err) {
        this.isLoading = false
        this.error = err.response.data.message
        this.$refs['modal-error'].show()
      }
    },
    valGolDarah (value) {
      this.result.golongan_darah = value.id_referensi
    },
    valJenisPendonor (value) {
      this.result.jenis_donor = value.id
    },
    capNama (nama) {
      this.nama = nama
    },
    capJK (jk) {
      this.jenis_kelamin = jk
    },
    capResrv (noresrv) {
      this.no_resv = noresrv
    },
    capUmur (umur) {
      const date = moment(new Date(umur))
      const today = moment(new Date())
      const dob = moment(new Date(date))
      var duration = moment.duration(today.diff(dob))
      var age = duration.years().toString().padStart(2, '0') + ' Tahun ' + duration.months().toString().padStart(2, '0') + ' Bulan ' + duration.days().toString().padStart(2, '0') + ' Hari'
      this.umur = age
    },
    resetFinal () {
      this.statusFinal = 0
    },
    backToList () {
      this.$router.push({ name: 'utd.list' })
    }
  }
}
</script>
<style>
.utdPemeriksaan .multiselect__tags {
    min-height: 45px;
    border-radius: 10px;
}
.utdPemeriksaan .form-control {
    color: black!important;
}
.utdPemeriksaan .modal-content {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.345)!important;
}
.utdPemeriksaan .flatpickr-input[readonly] {
    height: 45px;
    border-radius: 10px;
    border: 1px solid rgb(228, 228, 228);
    padding-left: 10px;
    padding-right: 10px;
}
.utdPemeriksaan .multiselect__input, .utdPemeriksaan .multiselect {
  font-weight: normal!important;
}
.utdPemeriksaan .multiselect__input, .utdPemeriksaan .multiselect__single {
  font-weight: normal!important;
}
.utdPemeriksaan .custom-yellow {
  color: #eea703;
}
</style>
