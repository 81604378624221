<template>
  <b-container fluid>
    <div v-show="isLoading">
      <div id="show-overlay"></div>
      <Loader :style="{ opacity: .5 }" />
    </div>
    <tab-nav :pills="true" class="nav-fill mb-3 bg-white shadow-sm rounded" id="pills-tab-1">
      <tab-nav-items :active="true" id="pills-home-tab-fill" href="#pills-home-fill" ariaControls="pills-home-fill" role="tab" :ariaSelected="true" title="Rawat Jalan" />
      <tab-nav-items :active="false" id="pills-profile-tab-fill" href="#pills-profile-fill" ariaControls="pills-profile-fill" role="tab" :ariaSelected="false" title="Rawat Inap" />
    </tab-nav>
    <tab-content id="pills-tabContent-1" class="mt-0">
      <tab-content-item :active="true" id="pills-home-fill" aria-labelled-by="pills-home-tab-fill">
        <b-row>
          <b-col lg="12">
            <b-row>
              <b-col md="4">
                <b-button-group class="mb-4">
                  <b-button variant="primary" @click="hariini">Hari ini</b-button>
                  <b-button variant="primary" @click="kemarin">Kemarin</b-button>
                  <b-button variant="primary" @click="hmin2">H-2</b-button>
                  <b-button variant="primary" @click="hmin3">H-3</b-button>
                </b-button-group>
              </b-col>
              <b-col md="4" offset-md="4" class="text-right">
                <!-- <b-button variant="primary" class="mb-4" @click="kemarin">Grafik</b-button> -->
              </b-col>
            </b-row>
            <b-row>
              <b-col lg="12">
                <div class="h-100">
                  <iq-card class-name="iq-card-block iq-card-stretch iq-card-height shadow-sm" body-class="iq-bg-primary rounded">
                    <b-table
                      :items="rajal"
                      :fields="tablerajal.fields"
                      :current-page="tablerajal.currentPage"
                      :per-page="tablerajal.perPage"
                      :filter="tablerajal.filter"
                      :filter-included-fields="tablerajal.filterOn"
                      stacked="md"
                      show-empty
                      medium
                    >

                      <template #cell(actions)="row">
                        <b-button size="sm" variant="warning" @click="listrajal(row.item.tautan4)" class="mr-1">
                          Detail
                        </b-button>
                      </template>

                    </b-table>
                  </iq-card>
                </div>
              </b-col>
              <!-- <b-col cols="12" lg="4" v-for="rjj in rajal" :key="rjj.id">
                <div class="h-100">
                  <a href="#" @click="listrajal  (rjj.tautan4)">
                    <iq-card class-name="iq-card-block iq-card-stretch iq-card-height shadow-sm" body-class="iq-bg-primary rounded">
                      <template v-slot:body>
                        <div class="d-flex align-items-end justify-content-between">
                          <div>
                            <div>
                              <p class="h6 font-weight-bold mb-2">{{ rjj.judul2.slice(7) }}</p>
                            </div>
                            <div class="rounded-circle iq-card-icon bg-primary">
                              <i class="fas fa-ambulance"></i>
                            </div>
                          </div>
                          <div class="text-right">
                            <h2 class="mb-0 font-weight-bold text-primary"><span class="counter">{{ rjj.jumlah_kunjungan }}</span></h2>
                            <h5 class="text-primary">{{ rjj.instalasi.toUpperCase() }}</h5>
                          </div>
                        </div>
                      </template>
                    </iq-card>
                  </a>
                </div>
              </b-col> -->
            </b-row>
          </b-col>
        </b-row>
      </tab-content-item>
      <tab-content-item :active="false" id="pills-profile-fill" aria-labelled-by="pills-profile-tab-fill">
        <b-row>
          <b-col lg="12">
            <b-row>
              <b-col md="4">
                <b-button-group class="mb-4">
                  <b-button variant="primary" @click="hariinirnn">Hari ini</b-button>
                  <b-button variant="primary" @click="kemarinrnn">Kemarin</b-button>
                  <b-button variant="primary" @click="hmin2rnn">H-2</b-button>
                  <b-button variant="primary" @click="hmin3rnn">H-3</b-button>
                </b-button-group>
              </b-col>
              <b-col md="4" offset-md="4" class="text-right">
              </b-col>
            </b-row>
            <b-row>
              <b-col lg="12">
                <div class="h-100">
                  <iq-card class-name="iq-card-block iq-card-stretch iq-card-height shadow-sm" body-class="iq-bg-primary rounded">
                    <b-table
                      :items="ranap"
                      :fields="tableranap.fields"
                      :current-page="tableranap.currentPage"
                      :per-page="tableranap.perPage"
                      stacked="md"
                      show-empty
                      medium
                    >
                      <template #cell(name)="row">
                        {{ row.value.first }} {{ row.value.last }}
                      </template>

                      <template #cell(actions)="row">
                        <b-button size="sm" variant="warning" @click="listrajal(row.item.tautan4)" class="mr-1">
                          Detail
                        </b-button>
                      </template>

                    </b-table>
                  </iq-card>
                </div>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </tab-content-item>
    </tab-content>
    <b-modal class="modal-list-rajal p-0" ref="modal-list-rajal" size="xl" title="Data Pasien" ok-title="Oke" :hide-footer="true" centered>
      <div class="">
        <b-table id="table"
        :busy="isBusy"
        :items="table.items"
        :filter="table.filter"
        :fields="table.fields"
        :per-page="table.perPage"
        :current-page="table.currentPage"
        striped hover bordered show-empty responsive="sm">
        <template v-slot:cell(pasien_jeniskelamin)="data">
          <span class="text-dark" v-if="data.item.pasien_jeniskelamin === 'L'">Laki - Laki</span>
          <span class="text-dark" v-else>Perempuan</span>
        </template>
        </b-table>
        <div class="d-flex flex-row align-items-center justify-content-between">
          <div>
            <b-pagination class="mb-0" v-model="table.currentPage" :total-rows="table.totalRows" :per-page="table.perPage" aria-controls="table"></b-pagination>
          </div>
          <div>
            <p class="text-dark mb-0">Menampilkan <span class="font-weight-bold">{{ table.totalRows }}</span> data tersedia</p>
          </div>
        </div>
      </div>
    </b-modal>
    <b-modal class="modal-list-ranap p-0" ref="modal-list-rajal" size="xl" title="Data Pasien" ok-title="Oke" :hide-footer="true" centered>
      <div class="">
        <b-table id="table"
        :busy="isBusy"
        :items="table.items"
        :filter="table.filter"
        :fields="table.fields"
        :per-page="table.perPage"
        :current-page="table.currentPage"
        striped hover bordered show-empty responsive="sm">
        <template v-slot:cell(pasien_jeniskelamin)="data">
          <span class="text-dark" v-if="data.item.pasien_jeniskelamin === 'L'">Laki - Laki</span>
          <span class="text-dark" v-else>Perempuan</span>
        </template>
        </b-table>
        <div class="d-flex flex-row align-items-center justify-content-between">
          <div>
            <b-pagination class="mb-0" v-model="table.currentPage" :total-rows="table.totalRows" :per-page="table.perPage" aria-controls="table"></b-pagination>
          </div>
          <div>
            <p class="text-dark mb-0">Menampilkan <span class="font-weight-bold">{{ table.totalRows }}</span> data tersedia</p>
          </div>
        </div>
      </div>
    </b-modal>
    <b-row>
      <b-col lg="12" v-for="(item,index) in charts" :key="index">
        <iq-card>
          <template v-slot:headerTitle>
            <h4>{{ item.title }}</h4>
          </template>
          <template v-slot:body>
            <MorrisChart :element="item.type+index" :type="item.type" :xKeys="item.xKeys" :data="item.bodyData" :colors="item.colors" :yKeys="item.yKeys" :labels="item.labels"/>
          </template>
        </iq-card>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { xray } from '../../config/pluginInit'
import Loader from '../../components/xray/loader/Loader'
import Dashboard from '../../services/fatmahost/dashboard/index'

export default {
  name: 'DashboardDirut',
  components: {
    Loader
  },
  async mounted () {
    xray.index()
    this.Dashrajal()
    this.Dashranap()
    this.DataGrafikrjj()
    this.DataGrafikrnn()
  },
  data () {
    return {
      isLoading: false,
      error: [],
      isBusy: false,
      tes: [],
      tablerajal: {
        fields: [
          { label: 'INSTALASI', key: 'instalasi', sortable: true },
          { label: 'JUMLAH PASIEN', key: 'jumlah_kunjungan', sortable: true },
          { label: 'JUMLAH BERKAS LENGKAP', key: 'jumlah_berkasLengkap', sortable: true },
          { label: 'JUMLAH BERKAS TIDAK LENGKAP', key: 'jumlah_berkasKurang', sortable: true },
          { label: 'Actions', key: 'actions' }
        ],
        items: [],
        totalRows: 1,
        currentPage: 1,
        perPage: 10,
        filterOn: [],
        filter: null
      },
      tableranap: {
        fields: [
          { label: 'INSTALASI', key: 'instalasi', sortable: true },
          { label: 'JUMLAH PENDAFTARAN', key: 'jumlah_kunjungan', sortable: true },
          { label: 'JUMLAH PASIEN PULANG', key: 'jumlah_pulang', sortable: true },
          { label: 'JUMLAH BERKAS LENGKAP', key: 'jumlah_berkasLengkap', sortable: true },
          { label: 'JUMLAH BERKAS TIDAK LENGKAP', key: 'jumlah_berkasKurang', sortable: true },
          { label: 'Actions', key: 'actions' }
        ],
        items: [],
        totalRows: 1,
        currentPage: 1,
        perPage: 10,
        filterOn: [],
        filter: null
      },
      table: {
        isBusy: false,
        fields: [
          // { label: 'No', key: 'index', class: 'text-center' },
          { label: 'NORM', key: 'pasien_norm', sortable: true },
          { label: 'NAMA', key: 'pasien_nama', sortable: true },
          { label: 'TANGGAL LAHIR', key: 'pasien_tgl_lahir', sortable: true },
          { label: 'JENIS KELAMIN', key: 'pasien_jeniskelamin', sortable: true },
          { label: 'KUNJUNGAN', key: 'daftar_pengunjung', sortable: true },
          { label: 'NO PENDAFTARAN', key: 'daftar_nomor', sortable: true },
          { label: 'CARABAYAR', key: 'daftar_carabayar', sortable: true },
          { label: 'UNIT PELAYANAN', key: 'instalasi_unit', sortable: true }
        ],
        items: [],
        totalRows: 1,
        currentPage: 1,
        perPage: 5,
        filterOn: [],
        filter: null
      },
      tglrajal: 'H',
      rajal: null,
      ranap: null,
      charts: [
        {
          title: 'Grafik Pendaftaran Pasien Rawat Jalan dalam 7 hari terakhir',
          type: 'bar',
          bodyData: [],
          xKeys: 'tanggal',
          yKeys: ['jumlah_berkasLengkap', 'jumlah_kunjungan'],
          labels: [ 'Jumlah Berkas Lengkap', 'Jumlah Kunjungan' ],
          colors: [ '#FF6384', '#36A2EB', '#FFCE56' ]
        },
        {
          title: 'Grafik Pendaftaran Pasien Rawat Inap dalam 7 hari terakhir',
          type: 'bar',
          bodyData: [],
          xKeys: 'tanggal',
          yKeys: ['jumlah_berkasLengkap', 'jumlah_kunjungan', 'jumlah_pulang'],
          labels: [ 'Jumlah Berkas Lengkap', 'Jumlah Kunjungan', 'Jumlah Pulang' ],
          colors: [ '#FF6384', '#36A2EB', '#FFCE56' ]
        }
      ]
    }
  },
  methods: {
    hariini () {
      this.tglrajal = 'H'
      this.Dashrajal()
    },
    kemarin () {
      this.tglrajal = 'H-1'
      this.Dashrajal()
    },
    hmin2 () {
      this.tglrajal = 'H-2'
      this.Dashrajal()
    },
    hmin3 () {
      this.tglrajal = 'H-3'
      this.Dashrajal()
    },
    hariinirnn () {
      this.tglrajal = 'H'
      this.Dashranap()
    },
    kemarinrnn () {
      this.tglrajal = 'H-1'
      this.Dashranap()
    },
    hmin2rnn () {
      this.tglrajal = 'H-2'
      this.Dashranap()
    },
    hmin3rnn () {
      this.tglrajal = 'H-3'
      this.Dashranap()
    },
    // async listrajal (ruang) {
    //   this.$refs['modal-list-rajal'].show()
    //   this.table.isBusy = true
    //   const tgl = this.tglrajal
    //   await Data.rajal2(tgl, ruang).then(response => {
    //     this.table.items = response.data.data
    //     this.table.totalRows = this.table.items.length
    //     this.table.isBusy = false
    //   }).catch(error => {
    //     this.error = error.response.data.message
    //     this.table.isBusy = false
    //   })
    // },
    // async listranap (ruang) {
    //   this.$refs['modal-list-rajal'].show()
    //   this.table.isBusy = true
    //   const tgl = this.tglrajal
    //   await Data.ranap2(tgl, ruang).then(response => {
    //     this.table.items = response.data.data
    //     this.table.totalRows = this.table.items.length
    //     this.table.isBusy = false
    //   }).catch(error => {
    //     this.error = error.response.data.message
    //     this.table.isBusy = false
    //   })
    // },
    // async Dashrajal () {
    //   this.isLoading = true
    //   const tgl = this.tglrajal
    //   const response = await Data.rajal1(tgl)
    //   this.rajal = response.data.data
    //   this.isLoading = false
    // },
    // async Dashranap () {
    //   this.isLoading = true
    //   const tgl = this.tglrajal
    //   const response = await Data.ranap1(tgl)
    //   this.ranap = response.data.data
    //   this.isLoading = false
    // }
    async Dashrajal () {
      this.isLoading = true
      const tgl = this.tglrajal
      const param1 = 'token'
      const param2 = `{"method":"home","parm1":"${tgl}","parm2":"-","parm3":"-"}`
      const response = await Dashboard.dash_rajal(param1, param2)
      this.rajal = response.data.data
      this.isLoading = false
    },
    async Dashranap () {
      this.isLoading = true
      const tgl = this.tglrajal
      const param1 = 'token'
      const param2 = `{"method":"home","parm1":"${tgl}","parm2":"-","parm3":"-"}`
      const response = await Dashboard.dash_ranap(param1, param2)
      this.ranap = response.data.data
      this.isLoading = false
    },
    async listrajal (ruang) {
      this.$refs['modal-list-rajal'].show()
      this.table.isBusy = true
      const tgl = this.tglrajal
      const param1 = 'token'
      const param2 = `{"method":"pendaftaranDetil","parm1":"${tgl}","parm2":"${ruang}","parm3":"-"}`
      await Dashboard.dash_rajal(param1, param2).then(response => {
        this.table.items = response.data.data
        this.table.totalRows = this.table.items.length
        this.table.isBusy = false
      }).catch(error => {
        this.error = error.response.data.message
        this.table.isBusy = false
      })
    },
    async listranap (ruang) {
      this.$refs['modal-list-rajal'].show()
      this.table.isBusy = true
      const tgl = this.tglrajal
      const param1 = 'token'
      const param2 = `{"method":"pendaftaranDetil","parm1":"${tgl}","parm2":"${ruang}","parm3":"-"}`
      await Dashboard.dash_ranap(param1, param2).then(response => {
        this.table.items = response.data.data
        this.table.totalRows = this.table.items.length
        this.table.isBusy = false
      }).catch(error => {
        this.error = error.response.data.message
        this.table.isBusy = false
      })
    },
    async DataGrafikrjj () {
      this.isLoading = true
      const param1 = 'token'
      const param2 = `{"xparm0":"rajal","parm1":"H","method":"homeChart"}`
      const response = await Dashboard.dash_rajal(param1, param2)
      const grafikrajal = response.data.data[0].dataChart
      grafikrajal.tanggal.map((value, index) => {
        this.charts[0].bodyData.push({
          tanggal: value,
          jumlah_berkasLengkap: grafikrajal.jumlah_berkasLengkap[index],
          jumlah_kunjungan: grafikrajal.jumlah_kunjungan[index]
        })
      })
      this.isLoading = false
    },
    async DataGrafikrnn () {
      this.isLoading = true
      const param1 = 'token'
      const param2 = `{"xparm0":"ranap","parm1":"H","method":"homeChart"}`
      const response = await Dashboard.dash_ranap(param1, param2)
      const grafikranap = response.data.data[0].dataChart
      grafikranap.tanggal.map((value, index) => {
        this.charts[1].bodyData.push({
          tanggal: value,
          jumlah_berkasLengkap: grafikranap.jumlah_berkasLengkap[index],
          jumlah_kunjungan: grafikranap.jumlah_kunjungan[index],
          jumlah_pulang: grafikranap.jumlah_pulang[index]
        })
      })
      this.isLoading = false
    }
  }
}
</script>
