import Api from '../axiosInstance'
const version = '/v1'
const title = 'master'
export default {
  find (param1, param2) {
    return Api.middleware.get(`${version}/${title}?param1=${param1}&param2=${param2}`)
  },
  find_v2 (body) {
    return Api.middleware.post(`${version}/${title}`, body)
  }
}
