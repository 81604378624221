<template>
  <div>
    <b-row class="align-items-center">
      <b-col cols="12" lg="3">
        <div>
          <b-form-group class="text-dark" label="No. Berkas :">
            <b-form-input class="form-control" placeholder="Nama Layanan..." size="sm"></b-form-input>
          </b-form-group>
        </div>
      </b-col>
      <b-col cols="12" lg="3">
        <div>
          <b-form-group class="text-dark" label="Nama Pelayanan :">
            <b-form-input class="form-control" placeholder="Tanggal..." size="sm"></b-form-input>
          </b-form-group>
        </div>
      </b-col>
      <b-col cols="12" lg="3">
        <div>
          <b-form-group class="text-dark" label="Tanggal :">
            <b-form-input class="form-control" placeholder="Dokter..." size="sm"></b-form-input>
          </b-form-group>
        </div>
      </b-col>
      <b-col cols="12" lg="3">
        <div>
          <b-form-group class="text-dark" label="Dokter Baca :">
            <b-form-input class="form-control" placeholder="Nama Layanan..." size="sm"></b-form-input>
          </b-form-group>
        </div>
      </b-col>
      <b-col cols="12" lg="4">
        <div>
          <b-form-group class="text-dark" label="Dokter Pengirim :">
            <b-form-input class="form-control" placeholder="Tanggal..." size="sm"></b-form-input>
          </b-form-group>
        </div>
      </b-col>
      <b-col cols="12" lg="4">
        <div>
          <b-form-group class="text-dark" label="Diagnosa Klinis :">
            <b-form-input class="form-control" placeholder="Dokter..." size="sm"></b-form-input>
          </b-form-group>
        </div>
      </b-col>
      <b-col cols="12" lg="4">
        <div>
          <b-form-group class="text-dark" label="Keterangan :">
            <b-form-input class="form-control" placeholder="Nama Layanan..." size="sm"></b-form-input>
          </b-form-group>
        </div>
      </b-col>
      <b-col cols="12" lg="4">
        <div>
          <b-form-group class="text-dark" label="Jaringan Asal :">
            <b-form-input class="form-control" placeholder="Tanggal..." size="sm"></b-form-input>
          </b-form-group>
        </div>
      </b-col>
      <b-col cols="12" lg="8">
        <div>
          <b-form-group class="text-dark" label="Pilihan :" v-slot="{ ariaDescribedby }">
            <b-form-radio-group
              id="radio-group-1"
              v-model="selected"
              :options="options"
              :aria-describedby="ariaDescribedby"
              name="radio-options"
            ></b-form-radio-group>
          </b-form-group>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
export default {
  data () {
    return {
      selected: 'first',
      options: [
        { text: 'Histori Patologi', value: 'first' },
        { text: 'SITOLOGI', value: 'second' },
        { text: 'FINAB', value: 'third', disabled: true },
        { text: 'Pap Smear', value: 'fourth' },
        { text: 'Potong Beku', value: 'five' },
        { text: 'Imunohistori', value: 'six' }
      ]
    }
  }
}
</script>
