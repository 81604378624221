<template>
  <div class="irjgh-trouble-akun">
    <div v-show="isLoading">
        <div id="show-overlay"></div>
      <Loader :style="{ opacity: .5 }" />
    </div>
    <b-container fluid>
      <div class="bg-white custom-radius-top border mb-4">
        <div>
          <div class="py-2 px-2">
            <b-row class="align-items-center">
              <b-col cols="2">
                <div>
                  <h5 class="card-title text-dark m-0 p-0">Reservasi List Akun </h5>
                </div>
              </b-col>
              <b-col>
                <div class="d-flex flex-row align-items-center mb-0 flex-wrap justify-content-end">
                  <div class="mr-1">
                    <div>
                      <b-form-input type="search" v-model="filter.nama" @input="tableRefresh" class="bg-white border border-dark custom-search-kuota" placeholder="Nama..."></b-form-input>
                    </div>
                  </div>
                  <div class="mr-1">
                    <div>
                      <b-form-input type="search" v-model="filter.username" @input="tableRefresh" class="bg-white border border-dark custom-search-kuota" placeholder="Username..."></b-form-input>
                    </div>
                  </div>
                  <!-- <div class="mr-1">
                    <div>
                      <b-form-input type="search" v-model="filter.norm" @input="tableRefresh" class="bg-white border border-dark custom-search-kuota" placeholder="Norm..."></b-form-input>
                    </div>
                  </div> -->
                  <div class="mr-1">
                    <div>
                      <b-form-input type="search" v-model="filter.nik" @input="tableRefresh" class="bg-white border border-dark custom-search-kuota" placeholder="NIK..."></b-form-input>
                    </div>
                  </div>
                  <!-- <div class="mr-1">
                    <div>
                      <flat-pickr :config="config" v-model="filter.tanggal" value="" @on-change="convertDate($event, 'use')" placeholder="[ Tanggal Lahir ]" class="w-100 calender-small custom-flat-pickr m-0">
                      </flat-pickr>
                    </div>
                  </div> -->
                  <div class="mr-1">
                    <div>
                      <b-button class="custom-radius py-2 px-3 rounded-1" variant="warning" @click="resetFilter"><i class="fas fa-sync-alt"></i> Reset Filter</b-button>
                    </div>
                  </div>
                </div>
              </b-col>
            </b-row>
          </div>
          <div class="table table-list-pasien mb-0">
            <b-table class="mb-0" ref="table" :busy="table.isBusy" :items="table.items" :fields="table.fields" :sort-by.sync="table.sortBy" :sort-desc.sync="table.sortDesc"
              :current-page="table.currentPage" :per-page="table.perPage" :filter="table.filter" @filtered="onFiltered" :select-mode="table.selectMode" stacked="md"
              bordered hover small show-empty selectable>
              <template #table-busy>
                <div class="text-center my-2">
                  <b-spinner class="align-middle text-primary mr-2"></b-spinner>
                  <strong class="text-dark">Memuat Data...</strong>
                </div>
              </template>
              <template #cell(index)="data">
                <span>{{ (table.currentPage*table.perPage)-table.perPage + data.index + 1 }}</span>
              </template>
              <template #cell(identitas)="data">
                <span class="text-capitalize" v-if="data.item.identitas === null || data.item.identitas === 'null'">-</span>
                <span class="text-capitalize" v-else>{{ data.item.identitas }}</span>
              </template>
              <template v-slot:cell(action)="data">
                <b-button variant="white" size="lg" @click="showModal(data.item.patient_id, columnDotsConvert(data.item, 'up.username'), columnDotsConvert(data.item, 'up.name'), data.item.identitas, '1')">
                  <i class="ri-eye-fill ri-lg text-primary"></i>
                </b-button>
                <!-- <b-button variant="white" size="lg" @click="showModal(data.item.id, '2')" v-if="akses.bitcontrol1.charAt(4) === '1' || group === 1">
                  <i class="ri-user-follow-fill ri-lg text-success"></i>
                </b-button> -->
                <b-button variant="white" size="lg" @click="showModal(data.item.patient_id, columnDotsConvert(data.item, 'up.username'), columnDotsConvert(data.item, 'up.name'), data.item.identitas, '3')" v-if="akses.bitcontrol1.charAt(4) === '1' || group === 1">
                  <i class="ri-shield-user-fill ri-lg text-warning"></i>
                </b-button>
              </template>
            </b-table>
          </div>
          <div class="bg-white shadow-sm pb-2 pt-2 pl-3 pr-3">
            <b-row class="align-items-center justify-content-between">
              <b-col cols="12" lg="3" class="mr-auto">
                <div class="d-flex align-items-center">
                  <div>
                    <b-pagination v-model="table.currentPage" :total-rows="table.totalRows" :per-page="table.perPage" size="sm" class="my-0">
                    </b-pagination>
                  </div>
                  <div class="border-left border-right px-3">
                    <span class="cursor-point" @click="tableRefresh">
                      <i class="fas fa-sync"></i>
                    </span>
                  </div>
                </div>
              </b-col>
              <b-col cols="12" lg="4" class="d-flex justify-content-end align-items-center">
                <div class="mr-3">
                  <b-form-select id="per-page-select" v-model="table.perPage" :options="table.pageOptions" size="sm"
                  ></b-form-select>
                </div>
                <div>
                  <p class="font-weight-normal mb-0 text-dark" v-if="table.totalRows">Menampilkan 1 -  {{ table.perPage }} dari {{ table.totalRows }}</p>
                </div>
              </b-col>
            </b-row>
          </div>
        </div>
      </div>
    </b-container>
    <b-modal id="modal-show-member-akun" ref="modal-show-member-akun" header-class="text-uppercase" title="ANGGOTA AKUN" size="lg" centered scrollable :no-close-on-backdrop="true" :static="true" :no-close-on-esc="true" cancel-title="Tutup" ok-title="Tutup" ok-variant="secondary" :ok-only="true">
      <div>
        <div>
          <div class="border p-2 m-2">
            <div class="d-flex flex-row">
              <div class="mr-auto">
                <p class="m-0 p-0 text-secodanry">NAMA</p>
              </div>
              <div>
                <p class="m-0 p-0 text-secodanry"><span class="font-weight-bold text-dark">{{ showName }}</span></p>
              </div>
            </div>
            <div class="d-flex flex-row">
              <div class="mr-auto">
                <p class="m-0 p-0 text-secodanry">USERNAME</p>
              </div>
              <div>
                <p class="m-0 p-0 text-secodanry"><span class="font-weight-bold text-dark">{{ showUsername }}</span></p>
              </div>
            </div>
            <div class="d-flex flex-row">
              <div class="mr-auto">
                <p class="m-0 p-0 text-secodanry">NOMOR IDENTITAS</p>
              </div>
              <div>
                <p class="m-0 p-0 text-secodanry"><span class="font-weight-bold text-dark">{{ showIdentitas }}</span></p>
              </div>
            </div>
          </div>
          <div class="table-responsive m-0" v-if="statcode === 0">
            <table class="table table-bordered shadow-sm">
              <thead>
                <tr>
                  <th scope="col" class="text-center">NO.</th>
                  <th scope="col">NAMA ANGGOTA</th>
                  <th scope="col">NO. REKAM MEDIS</th>
                  <th scope="col">TGL. LAHIR</th>
                  <th scope="col" class="text-center">STATUS</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(d, index) in dataMember" :key="d.index">
                  <td class="text-center">
                    <span :class="['text-capitalize', d.status === 1 ? 'text-dark' : 'text-secondary']">
                      {{ index + 1 }}
                    </span>
                  </td>
                  <td>
                    <span :class="['text-capitalize', d.status === 1 ? 'text-dark' : 'text-secondary']">
                      {{ d.nama }}
                    </span>
                  </td>
                  <td>
                    <span :class="['text-capitalize', d.status === 1 ? 'text-dark' : 'text-secondary']">
                      {{ d.norm }}
                    </span>
                  </td>
                  <td>
                    <span :class="['text-capitalize', d.status === 1 ? 'text-dark' : 'text-secondary']">
                      {{ d.tanggal_lahir.split('-').reverse().join('-') }}
                    </span>
                  </td>
                  <td class="text-center">
                    <span :class="['text-capitalize', d.status === 1 ? 'text-dark' : 'text-secondary']">
                      {{ d.deskripsi }} <i :class="['ri-checkbox-circle-fill ri-lg', d.status === 1 ? 'text-success' : 'text-secondary']"></i>
                    </span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div>
          </div>
        </div>
      </div>
    </b-modal>
    <b-modal id="modal-username-member-akun" ref="modal-username-member-akun" header-class="text-uppercase" title="PERUBAHAN USERNAME" size="md" centered scrollable :no-close-on-backdrop="true" :static="true" :no-close-on-esc="true" @ok="onSaveUsername" cancel-title="Tutup" ok-title="Simpan">
      <div class="mt-1">
        <div class="border p-2 mb-2">
          <div class="d-flex flex-row">
            <div class="mr-auto">
              <p class="m-0 p-0 text-secodanry">NAMA</p>
            </div>
            <div>
              <p class="m-0 p-0 text-secodanry"><span class="font-weight-bold text-dark">{{ showName }}</span></p>
            </div>
          </div>
          <div class="d-flex flex-row">
            <div class="mr-auto">
              <p class="m-0 p-0 text-secodanry">USERNAME</p>
            </div>
            <div>
              <p class="m-0 p-0 text-secodanry"><span class="font-weight-bold text-dark">{{ showUsername }}</span></p>
            </div>
          </div>
          <div class="d-flex flex-row">
            <div class="mr-auto">
              <p class="m-0 p-0 text-secodanry">NOMOR IDENTITAS</p>
            </div>
            <div>
              <p class="m-0 p-0 text-secodanry"><span class="font-weight-bold text-dark">{{ showIdentitas }}</span></p>
            </div>
          </div>
        </div>
        <b-form-input
          class="form-control-custom-member text-dark"
          v-model="showUsername"
          placeholder="Username..."
          type="text"
          size="sm"
        ></b-form-input>
        <p class="text-danger font-weight-bold font-italic mb-0 small">termasuk semua anggota yang tertaut ke username tersebut akan ikut berubah</p>
      </div>
      <div :class="['mt-2 px-2', statcode === 0 ? 'bg-primary ' : 'bg-danger']" v-if="statcode !== null">
        <div class="d-flex flex-row justify-content-between">
          <div>
            <p class="small text-right m-0 p-0">{{ message }}</p>
          </div>
          <div @click="clearMessage">
            <p class="small text-right m-0 p-0"><i class="fas fa-times-circle"></i></p>
          </div>
        </div>
      </div>
    </b-modal>
  </div>
</template>
<script>
import { xray } from '../../../config/pluginInit.js'
import Services from '../../../services/fatmahost/member/index.js'
// import flatPickr from 'vue-flatpickr-component'
import 'flatpickr/dist/flatpickr.css'

export default {
  name: 'MemberList',
  components: {
    // flatPickr
  },
  data () {
    return {
      isLoading: false,
      message: '',
      statcode: null,
      showId: '',
      showUsername: '',
      showIdentitas: '',
      showName: '',
      table: {
        isBusy: false,
        items: this.listMember,
        fields: [
          { label: 'NO.', key: 'index', class: 'text-lg-center' },
          { label: 'NAMA', key: 'up.name', sortable: true },
          { label: 'USERNAME', key: 'up.username', sortable: true },
          { label: 'NIK', key: 'identitas', sortable: true },
          { label: 'AKSI', key: 'action', class: 'text-center' }
        ],
        filter: null,
        filterOn: [],
        pageOptions: [10, 50, 100, 500],
        currentPage: null,
        perPage: null,
        totalRows: null,
        sortBy: '',
        sortDesc: false,
        selectMode: 'single'
      },
      filter: {
        username: '',
        nik: '',
        nomor: '',
        norm: '',
        nama: '',
        tanggal: '',
        tanggalReverse: ''
      },
      selected: {
        status: [
          { value: 1, text: 'Aktif' },
          { value: 0, text: 'Tidak Aktif' }
        ]
      },
      dataMember: [],
      config: {
        dateFormat: 'd-m-Y',
        disableMobile: true
      },
      akses: '',
      group: ''
    }
  },
  mounted () {
    xray.index()
    this.akses = xray.bitcontrol(this.$route.name)
    this.group = xray.currentUser().id_grp
  },
  methods: {
    tableRefresh () {
      this.table.currentPage = 1
      this.$refs.table.refresh()
    },
    columnDotsConvert (column, desc) {
      return column[desc]
    },
    convertDate (value, param) {
      const date = new Date(value)
      const day = String(date.getDate()).padStart(2, '0')
      const month = String(date.getMonth() + 1).padStart(2, '0')
      const year = String(date.getFullYear())
      if (param === 'convert') {
        return day + '-' + month + '-' + year
      } else if (param === 'use') {
        this.filter.tanggalReverse = year + '-' + month + '-' + day
        this.tableRefresh()
      }
    },
    onFiltered (filteredItems) {
      this.table.totalRows = filteredItems.length
    },
    async listMember (ctx) {
      this.table.isBusy = true
      //
      let tglLahir = this.filter.tanggalReverse === 'NaN-NaN-NaN' ? '' : this.filter.tanggalReverse
      let obj = 'profil-login'
      let vParams3 = `{"xparam0":"${this.filter.username}","xparam1":"${this.filter.nik}","xparam2":"${this.filter.nomor}","xparam3":"${this.filter.nama}","xparam4":"${tglLahir}","xparam5":"","xparam6":""}`
      //
      let sortfield = ''
      let sorting = ''
      if (ctx.sortBy) {
        sortfield = ctx.sortBy
      } else {
        sortfield = ''
      }
      if (ctx.sortDesc === true) {
        sorting = 'ASC'
      } else if (ctx.sortDesc === false) {
        sorting = 'DESC'
      } else {
        sorting = ''
      }
      let vParams4 = `{"xparam0":"${sortfield}","xparam1":"${sorting}"}`
      try {
        const response = await Services.findAll(ctx.currentPage - 1, ctx.perPage, obj, vParams3, vParams4)
        if (response.data.data) {
          this.table.isBusy = false
          this.table.totalRows = response.data.pagination.total
          this.table.currentPage = response.data.pagination.current
          this.table.perPage = response.data.pagination.perPage
          return response.data.data
        } else {
          this.table.isBusy = false
          this.message = response.data.message
          return []
        }
      } catch (error) {
        this.table.isBusy = false
        this.message = error.message
        return []
      }
    },
    rowClass (item, type) {
      if (!item || type !== 'row') return
      if (item.status !== 1) return 'text-secondary'
    },
    async showModal (id, username, nama, identitas, param) {
      this.isLoading = true
      this.showId = id
      this.showUsername = username
      this.showName = nama
      this.showIdentitas = identitas
      this.clearMessage()
      //
      this.dataMember = []
      let obj = 'list'
      let params = 'profil-login'
      try {
        const response = await Services.show(id, obj, params)
        if (response.data.data[0].patient_id) {
          if (response.data.statcode === 0) {
            this.isLoading = false
            this.statcode = response.data.statcode
            this.message = response.data.message
            this.dataMember = response.data.data
          } else {
            this.isLoading = false
            this.statcode = response.data.statcode
            this.message = response.data.message
          }
        } else {
          this.statcode = 20001
          this.message = 'Akun Belum Mempunyai Anggota'
        }
      } catch (error) {
        this.isLoading = false
        this.statcode = 20001
        this.message = error.message
      }
      if (param === '1') {
        this.$refs['modal-show-member-akun'].show()
      } else if (param === '2') {
        if (param === '2') this.$refs['modal-status-member-akun'].show()
      } else if (param === '3') {
        if (param === '3') this.$refs['modal-username-member-akun'].show()
      }
    },
    async onSaveUsername (bvModalEvent) {
      bvModalEvent.preventDefault()
      this.isLoading = true
      this.clearMessage()
      const data = {
        object: 'username',
        username: this.showUsername
      }
      try {
        const response = await Services.status(this.showId.toString(), data)
        if (response.data.statcode === 0) {
          this.isLoading = false
          this.statcode = response.data.statcode
          this.message = response.data.message
          this.tableRefresh()
        } else {
          this.isLoading = false
          this.statcode = response.data.statcode
          this.message = response.data.message
        }
      } catch (error) {
        this.isLoading = false
        this.statcode = 20001
        this.message = error.message
      }
    },
    clearMessage () {
      this.message = ''
      this.statcode = null
    },
    resetFilter () {
      this.filter.username = ''
      this.filter.nik = ''
      this.filter.nomor = ''
      this.filter.norm = ''
      this.filter.nama = ''
      this.filter.tanggal = ''
      this.filter.tanggalReverse = ''
      this.tableRefresh()
    }
  }
}
</script>
<style>
.irjgh-trouble-akun .wrn-ungu {
  color: purple;
}
.irjgh-trouble-akun .custom-flatpicker {
  height: 38px;
  border: 1px solid rgba(0, 0, 0, 0.13);
  border-radius: 5px;
  padding-left: 10px;
}
.irjgh-trouble-akun .custom-select {
  border: 1px solid rgba(0, 0, 0, 0.13);
  min-height: 38px;
  font-size: 13px!important;
}
.irjgh-trouble-akun .bg-filter {
  border: 1px solid rgba(0, 0, 0, 0.13);
  background-color: #089bab1e;
}
.irjgh-trouble-akun .custom-search-kuota {
  border-radius: 5px!important;
  font-size: 13px!important;
  height: 38px!important;
}
.irjgh-trouble-akun .table td {
    font-size: 12px;
    font-weight: 500;
    padding-top: 0px;
    padding-bottom: 0px;
}
.irjgh-trouble-akun .table th {
    font-size: 13px;
    font-weight: 400;
}
.irjgh-trouble-akun .page-link {
  background: transparent!important;
    border: none!important;
    color: gray!important;
    margin-right: 15px;
}
.irjgh-trouble-akun .page-item.active .page-link {
    background-color: transparent;
    border-color: transparent;
    color: #089bab!important;
    font-weight: bold;
}
.irjgh-trouble-akun .cursor-point {
    cursor: pointer;
}
.cstm-slct-irj-Admin {
  font-size: 13px!important;
  height: 38px!important;
  border-radius: 5px;
}
.irjgh-trouble-akun .cstm-cntrl-irj-Admin {
  color: black!important;
  border-radius: 5px!important;
  font-size: 12px!important;
  height: 4px!important;
}
.irjgh-trouble-akun .custom-fontsize {
  font-size: 13px;
}
#modal-username-member-akun .form-control-custom-member {
  height: 38px!important;
  background-color: transparent;
  border-radius: .1rem!important;
}
.irjgh-trouble-akun .custom-cursor {
  cursor: pointer;
}
.irjgh-trouble-akun .custom-radius {
  border-radius: .2rem!important;
}
.irjgh-trouble-akun .custom-radius-top {
  border-top-left-radius: .5rem!important;
  border-top-right-radius: .5rem!important;
}
#modal-status-member-akun .modal-body, #modal-username-member-akun .modal-body {
    padding: .5rem;
}
#modal-show-member-akun .modal-body {
  padding: 0;
}
.irjgh-trouble-akun .custom-flat-pickr {
    border: 1px solid rgb(233, 233, 233);
    height: 38px;
    padding-left: 10px;
    padding-top: 5px;
    padding-bottom: 5px;
    border: 1px solid rgb(192, 192, 192)!important;
    border-radius: 5px;
    font-size: 12px;
    color: black;
}
</style>
