import Api from '../axiosInstance'
const version = '/v1'
export default {
  register (data) {
    return Api.users.post(`${version}/practitioner/register`, data)
  },
  forgot (data) {
    return Api.users.put(`${version}/practitioner/forgot`, data)
  }
}
