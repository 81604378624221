<template>
  <div id="pending-farmasi" class="pending-farmasi">
    <div v-show="isLoading">
        <div id="show-overlay"></div>
      <Loader :style="{ opacity: .5 }" />
    </div>
    <b-container fluid class="mb-4">
      <iq-card class="mb-2">
        <div  class="iq-email-to-list pt-3 pl-3 pr-3" id="header-table-filter">
          <div class="px-2 py-2 border d-flex align-items-center justify-content-between">
            <div>
              <p class="text-dark font-weight-bold h6 mb-0">PENDING</p>
            </div>
            <div>
              <b-button variant="primary" class="py-1" style="border-radius: 5px;" @click="toPendingTambah"><i class="fas fa-plus-circle "></i>Tambah Pending</b-button>
            </div>
          </div>
        </div>
        <div class="iq-email-listbox pl-3 pr-3 pb-3">
          <div class="table mb-0">
            <b-table
              class="mb-0 pointer-table"
              ref="table"
              :busy="table.isBusy"
              :items="table.items"
              :fields="table.fields"
              :current-page="table.currentPage"
              :per-page="table.perPage"
              :filter="table.filter"
              @filtered="onFiltered"
              :sort-by.sync="table.sortBy"
              :sort-desc.sync="table.sortDesc"
              stacked="md"
              bordered hover show-empty small striped>
              <template #table-busy>
                <div class="text-center my-2">
                  <b-spinner class="align-middle text-primary mr-2"></b-spinner>
                  <strong class="text-dark">Memuat Data...</strong>
                </div>
              </template>
              <template #cell(index)="data">
                {{ (table.currentPage*table.perPage)-table.perPage + data.index + 1 }}
              </template>
              <template #cell(tp.tgl_pending)="data">
                <span>{{ new Date(tglWithDots(data.item)) | formatDate }}</span>
              </template>
              <template #cell(poli)="data">
                <span>-</span>
              </template>
              <template #cell(dokter)="data">
                <span>-</span>
              </template>
              <template #cell(action)="data">
                <b-button variant=" mr-1 mb-1" size="lg" @click="handleNewKuota(data.item.id, data.item.order_id)">
                  <i class="fas fa-edit text-primary text-primary"></i>
                </b-button>
              </template>
            </b-table>
          </div>
          <div class="pagination-pending border shadow-sm p-2 mb-2">
            <b-row class="align-items-center">
              <b-col cols="4" class="d-flex flex-row align-items-center">
                <div class="w-100">
                  <b-pagination v-model="table.currentPage" :total-rows="table.totalRows" :per-page="table.perPage"
                    size="md" class="my-0"></b-pagination>
                </div>
                <div class="w-100">
                  <div>
                    <span class="border-left border-dark mr-3 py-1"></span>
                    <span class="katalog-cursor" @click="tableRefresh">
                      <i class="fas fa-sync text-secondary"></i>
                    </span>
                    <span class="border-right border-dark ml-3 py-1"></span>
                  </div>
                </div>
              </b-col>
              <b-col cols="5">
                <div class="d-flex flex-row justify-content-end align-items-center">
                  <div class="mr-1 ">
                    <b-form-select class="cstm-slct-stock" v-model="selected.filterType" :options="options.optionsFilter"
                      @change="filterchange"></b-form-select>
                  </div>
                  <div class="mr-1 ">
                    <b-form-select class="cstm-slct-stock" v-model="selected.filterType2" :options="options.optionsFilter2"
                      @change="filterchange"></b-form-select>
                  </div>
                  <div class="w-50">
                    <form class="searchbox mb-0">
                      <input v-model="table.filter" type="search" class="form-control cstm-srch-stock" id="filter-input"
                        placeholder="[Cari]">
                    </form>
                  </div>
                </div>
              </b-col>
              <b-col cols="3">
                <div class="d-flex flex-row align-items-right justify-content-end">
                  <div class="mr-2">
                    <b-form-select id="per-page-select" class="custom-perpage" v-model="table.perPage" :options="table.pageOptions" size="sm"
                    ></b-form-select>
                  </div>
                  <div>
                    <p class="text-dark mb-0">Menampilkan {{ table.perPage }} dari {{ table.totalRows }} baris</p>
                  </div>
                </div>
              </b-col>
            </b-row>
          </div>
        </div>
      </iq-card>
    </b-container>
    <b-modal id="modal-pending" ref="modal-pending" size="xl" title="Detail Pending" cancel-title="Tutup" :no-close-on-backdrop="true" :static="true" :no-close-on-esc="true"
    cancel-variant="danger" @ok="handleSubmit" @close="clearForm" @cancel="clearForm" ok-title="Simpan" :hide-header=false centered>
      <div class="mb-1">
        <div class="mb-2 mt-1">
          <p class="h6 font-weight-bold text-uppercase text-dark mb-0"><u>{{ display.form.nm_pending }}</u></p>
        </div>
        <!-- <b-row class="align-items-center">
          <b-col cols="12" lg="3">
            <p class="text-dark  mb-0">No. Rekam Medis</p>
          </b-col>
          <b-col cols="12" lg="9">
            <b-form-group class="mb-2">
              <b-form-input disabled class="cstm-cntrl-stockopname" v-model="display.form.norm" type="text" placeholder="No. Rekam Medis"></b-form-input>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row class="align-items-center">
          <b-col cols="12" lg="3">
            <p class="text-dark  mb-0">Kunjungan</p>
          </b-col>
          <b-col cols="12" lg="9">
            <b-form-group class="mb-2">
              <b-form-input disabled class="cstm-cntrl-stockopname" v-model="display.form.kunjungan" type="text" placeholder="Kunjungan"></b-form-input>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row class="align-items-center">
          <b-col cols="12" lg="3">
            <p class="text-dark  mb-0">Poli</p>
          </b-col>
          <b-col cols="12" lg="9">
            <b-form-group class="mb-2">
              <b-form-input disabled class="cstm-cntrl-stockopname" v-model="display.form.poli" type="text" placeholder="Poli"></b-form-input>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row class="align-items-center">
          <b-col cols="12" lg="3">
            <p class="text-dark  mb-0">Dokter</p>
          </b-col>
          <b-col cols="12" lg="9">
            <b-form-group class="mb-2">
              <b-form-input disabled class="cstm-cntrl-stockopname" v-model="display.form.dokter" type="text" placeholder="Dokter"></b-form-input>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row class="align-items-center">
          <b-col cols="12" lg="3">
            <p class="text-dark  mb-0">Quantity</p>
          </b-col>
          <b-col cols="12" lg="9">
            <b-form-group class="mb-2">
              <b-form-input disabled class="cstm-cntrl-stockopname" v-model="display.form.qty_pending" type="text" placeholder="Quantity"></b-form-input>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row class="align-items-center">
          <b-col cols="12" lg="3">
            <p class="text-dark  mb-0">Obat</p>
          </b-col>
          <b-col cols="12" lg="9">
            <b-form-group class="mb-2">
              <b-form-input disabled class="cstm-cntrl-stockopname" v-model="display.namaObat" type="text" placeholder="Obat"></b-form-input>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row class="align-items-center">
          <b-col cols="12" lg="3">
            <p class="text-dark  mb-0">Tanggal</p>
          </b-col>
          <b-col cols="12" lg="9">
            <b-form-group class="mb-0">
              <b-form-input disabled class="cstm-cntrl-stockopname" v-model="pendingformview.tgl_view" type="text" placeholder="Tanggal"></b-form-input>
            </b-form-group>
          </b-col>
        </b-row> -->
        <!-- <div class="mb-2 mt-1">
          <p class="h6 font-weight-bold text-dark mb-0"><u>PENGEMBALIAN</u></p>
        </div> -->
        <div>
          <div class="table-responsive-xl">
            <table class="table bg-white table-bordered table-sm m-0 p-0">
              <thead>
                <b-tr>
                  <b-th variant="white" class="py-2 text-center">No.</b-th>
                  <b-th variant="white" class="py-2 text-center">Perubahan</b-th>
                  <b-th variant="white" class="py-2 text-center">Nama Barang</b-th>
                  <b-th variant="white" class="py-2 text-center">Nama Pabrik</b-th>
                  <b-th variant="white" class="py-2 text-center">Jenis</b-th>
                  <b-th variant="white" class="py-2 text-center">Norm</b-th>
                  <b-th variant="white" class="py-2 text-center">Kunjungan</b-th>
                  <b-th variant="white" class="py-2 text-center">Depo</b-th>
                  <b-th variant="white" class="py-2 text-center">Quantity</b-th>
                  <b-th variant="white" class="py-2 text-center">Tanggal</b-th>
                  <b-th variant="white" class="py-2 text-center">Tanggal Selesai</b-th>
                  <b-th variant="white" class="py-2 text-center">Selesai</b-th>
                  <b-th variant="white" class="py-2 text-center" @click="reload">Cetak <i class="fas fa-sync-alt text-primary katalog-cursor ml-2"></i></b-th>
                </b-tr>
              </thead>
              <tbody>
                <b-tr v-for="(p, index) in pendingform.detail" v-bind:key="p.cdx">
                  <b-td>
                    <div class="py-1 px-2">
                      <p class="mb-0">{{ index + 1 }}</p>
                    </div>
                  </b-td>
                  <b-td>
                    <div class="py-1 px-2">
                      <b-form-group class="mb-0 text-center">
                        <b-form-checkbox
                          :disabled="pendingform.detail[index].sts_selesai === '1' ? true : false"
                          :id="'checkbox-' + index"
                          v-model="pendingformview.detail[index].status"
                          :name="'checkbox-' + index"
                          value="1"
                          unchecked-value="0"
                        >
                        <span v-if="pendingformview.detail[index].status === '1'">Ya</span>
                        <span v-else>Tidak</span>
                        </b-form-checkbox>
                      </b-form-group>
                    </div>
                  </b-td>
                  <b-td>
                    <div class="py-1">
                      <multiselect :disabled="pendingformview.detail[index].status === '1' ? false : true" :show-labels="false" label="namaBarang" placeholder="[ Nama Barang ]" :custom-label="nameWithKode"
                        :options="combo.pabrik" track-by="id_katalog" v-model="selected.barang[index].detail" @search-change="getPabrik" @input="valuepabrik($event, index)"
                        :searchable="true" :allow-empty="false">
                      </multiselect>
                      <!-- <b-form-input v-model="pendingform.detail[index].id_katalog_selesai" class="cstm-cntrl-stockopname" type="text" placeholder="..."></b-form-input> -->
                    </div>
                  </b-td>
                  <b-td class="col-1">
                    <div class="py-1">
                      <b-form-input disabled v-model="pendingformview.detail[index].pabrik" class="cstm-cntrl-stockopname" type="text" placeholder="..."></b-form-input>
                    </div>
                  </b-td>
                  <b-td class="col-1">
                    <div class="py-1">
                      <b-form-input disabled v-model="pendingformview.detail[index].jenis" class="cstm-cntrl-stockopname" type="text" placeholder="..."></b-form-input>
                    </div>
                  </b-td>
                  <b-td>
                    <div class="py-1">
                      <b-form-input disabled v-model="pendingformview.detail[index].norm" class="cstm-cntrl-stockopname" type="text" placeholder="..."></b-form-input>
                    </div>
                  </b-td>
                  <b-td>
                    <div class="py-1">
                      <b-form-input disabled v-model="pendingformview.detail[index].kunjungan" class="cstm-cntrl-stockopname" type="text" placeholder="..."></b-form-input>
                    </div>
                  </b-td>
                  <b-td>
                    <div class="py-1">
                      <b-form-input disabled v-model="pendingformview.detail[index].depo" class="cstm-cntrl-stockopname" type="text" placeholder="..."></b-form-input>
                    </div>
                  </b-td>
                  <b-td>
                    <div class="py-1">
                      <b-form-input disabled v-model="pendingform.detail[index].qty_selesai" class="cstm-cntrl-stockopname text-center" type="text" placeholder="..."></b-form-input>
                    </div>
                  </b-td>
                  <b-td>
                    <div class="py-1">
                      <b-form-input disabled v-model="pendingformview.detail[index].tanggal" class="cstm-cntrl-stockopname" type="text" placeholder="..."></b-form-input>
                    </div>
                  </b-td>
                  <b-td>
                    <div class="py-1">
                      <b-form-input disabled v-model="pendingformview.detail[index].tanggal_selesai" class="cstm-cntrl-stockopname" type="text" placeholder="..."></b-form-input>
                    </div>
                  </b-td>
                  <b-td>
                    <div class="text-center py-1" v-if="display.disable_sts[index].value === '1'">
                      <i class="fas fa-check-square fa-lg text-primary mr-2 border shadow-sm"></i>
                    </div>
                    <div class="text-center py-1" v-else>
                      <b-form-checkbox
                        :id="'checkbox-sts-' + index"
                        v-model="pendingform.detail[index].sts_selesai"
                        :name="'checkbox-sts-' + index"
                        value="1"
                        unchecked-value="0"
                      >
                      </b-form-checkbox>
                    </div>
                  </b-td>
                  <b-td class="text-center col-1">
                    <div v-if="pendingformview.detail[index].c_jenis === 3 && pendingformview.detail[index].c_sts === 1">
                      <b-dropdown text="Cetak" variant="primary" class="bg-primary" style="border-radius: 5px;" size="sm">
                        <b-dropdown-item href="#" @click="openNewWindow(pendingformview.detail[index].kunjungan, '1')"><span class="font-weight-normal"><i class="fas fa-print"></i> Etiket</span></b-dropdown-item>
                        <b-dropdown-item href="#" @click="openNewWindow(pendingformview.detail[index].kunjungan, '2')"><span class="font-weight-normal"><i class="fas fa-print"></i> Struk</span></b-dropdown-item>
                      </b-dropdown>
                    </div>
                    <div class="text-center" v-else>
                      -
                    </div>
                  </b-td>
                </b-tr>
              </tbody>
            </table>
          </div>
        </div>
        <!-- <b-row class="align-items-center">
          <b-col cols="12" lg="3">
            <p class="text-dark  mb-0">Obat Pengganti</p>
          </b-col>
          <b-col cols="12" lg="9">
            <b-form-group class="mb-2">
              <b-form-checkbox
                id="checkbox-1"
                v-model="selected.status"
                name="checkbox-1"
                value="1"
                unchecked-value="0"
                @change="valueChck"
              >
              <span v-if="selected.status === '1'">Ya</span>
              <span v-else>Tidak</span>
              </b-form-checkbox>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row class="align-items-center">
          <b-col cols="12" lg="3">
            <p class="text-dark  mb-0">Obat</p>
          </b-col>
          <b-col cols="12" lg="9">
            <b-form-group class="mb-2">
              <multiselect :disabled="display.pendingObat" v-model="selected.pabrik" :show-labels="false" :allow-empty="false"
                label="namaBarang" track-by="id_pabrik" :custom-label="nameWithKode" placeholder="[Barang]" :options="combo.pabrik" @search-change="getPabrik"
                :searchable="true" @input="valuepabrik">
              </multiselect>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row class="align-items-center">
          <b-col cols="12" lg="3">
            <p class="text-dark  mb-0">Quantity</p>
          </b-col>
          <b-col cols="12" lg="9">
            <b-form-group class="mb-2">
              <b-form-input disabled @keypress="isNumberKey" class="cstm-cntrl-stockopname" v-model="pendingform.qty_selesai" type="text" placeholder="Quantity..."></b-form-input>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row class="align-items-center">
          <b-col cols="12" lg="3">
            <p class="text-dark  mb-0">Tanggal</p>
          </b-col>
          <b-col cols="12" lg="9">
            <b-form-group class="mb-2">
              <b-form-input disabled class="cstm-cntrl-stockopname" v-model="pendingformview.tgl" type="text" placeholder="Tanggal..."></b-form-input>
            </b-form-group>
          </b-col>
        </b-row> -->
        <!-- <pre>{{ pendingform }}</pre> -->
        <div class="bg-dark px-2 mt-2" v-if="error">
          <p class="text-right small text-white mb-0">{{ error }}</p>
        </div>
      </div>
      <!-- <pre>{{ display.disable_sts }}</pre> -->
    </b-modal>
    <b-modal id="modal-iterasi" ref="modal-iterasi" size="xl" title="Iterasi" ok-title="Oke" centered>
      <div class="text-center">
        <b-row>
          <b-col class="pr-1" cols="5">
            <div>
              <b-form-group class="mb-0">
                <multiselect :show-labels="false" label="namaBarang" placeholder="[ Nama Barang ]"
                  :options="combo.pabrik" track-by="id_pabrik" v-model="selected.pabrik" @input="valuebarangiter"
                  :searchable="true" :allow-empty="false" @search-change="getPabrik">
                </multiselect>
              </b-form-group>
            </div>
          </b-col>
          <b-col class="pr-1 pl-1" cols="2">
            <div>
              <b-form-group class="mb-0">
                <b-form-input v-model="table2param.norm" class="bg-white cstm-cntrl-stockopname" type="text" placeholder="Norm"></b-form-input>
              </b-form-group>
            </div>
          </b-col>
          <b-col class="pr-1 pl-1" cols="2">
            <div>
              <b-form-group class="mb-0">
                <flat-pickr :config="config" v-model="table2param.tgl_awal" value="" placeholder="[ Pilih Tanggal ]" class="w-100 custom-flatpicker calender-small m-0 p-0">
                </flat-pickr>
              </b-form-group>
            </div>
          </b-col>
          <b-col class="pr-1 pl-1" cols="2">
            <div>
              <b-form-group class="mb-0">
                <flat-pickr :config="config" v-model="table2param.tgl_akhir" value="" placeholder="[ Pilih Tanggal ]" class="w-100 custom-flatpicker calender-small m-0 p-0">
                </flat-pickr>
              </b-form-group>
            </div>
          </b-col>
          <b-col class="pl-1" cols="1">
            <div>
              <b-form-group class="mb-0">
                <b-button class="w-100" variant="primary" style="border-radius: 5px;" @click="loadtable2">Cari</b-button>
              </b-form-group>
            </div>
          </b-col>
        </b-row>
      </div>
      <div class="table bg-white mt-2 mb-0">
        <b-table
          class="mb-0 pointer-table"
          ref="table2"
          :busy="table2.isBusy"
          :items="table2.items"
          :fields="table2.fields"
          :current-page="table2.currentPage"
          :per-page="table2.perPage"
          :filter="table2.filter"
          @filtered="onFiltered2"
          :sort-by.sync="table2.sortBy"
          :sort-desc.sync="table2.sortDesc"
          stacked="md"
          bordered hover show-empty small striped>
          <template #cell(index)="data">
            {{ (table2.currentPage*table2.perPage)-table2.perPage + data.index + 1 }}
          </template>
          <template #cell(action)="data">
            <b-button variant=" mr-1 mb-1" size="lg">
              <i class="fas fa-edit text-primary text-primary"></i>
            </b-button>
          </template>
        </b-table>
      </div>
      <div class="pagination-pending bg-white border shadow-sm p-2 mb-2">
          <b-row class="align-items-center">
            <b-col cols="4" class="d-flex flex-row align-items-center">
              <div class="w-100">
                <b-pagination v-model="table2.currentPage" :total-rows="table2.totalRows" :per-page="table2.perPage"
                  size="md" class="my-0"></b-pagination>
              </div>
              <div class="w-100">
                <div>
                  <span class="border-left border-dark mr-3 py-1"></span>
                  <span @click="tableRefresh2" class="katalog-cursor">
                    <i class="fas fa-sync text-secondary"></i>
                  </span>
                  <span class="border-right border-dark ml-3 py-1"></span>
                </div>
              </div>
            </b-col>
            <b-col cols="5">
              <div class="d-flex flex-row justify-content-end align-items-center">
                <div class="mr-1 w-75">
                  <form class="searchbox mb-0">
                    <input v-model="table2.filter" type="search" class="form-control cstm-srch-stock" id="filter-input"
                      placeholder="[Cari]">
                  </form>
                </div>
                <div>
                  <b-form-select class="cstm-slct-stock" v-model="selected.filterType" :options="options.optionsFilter"></b-form-select>
                </div>
              </div>
            </b-col>
            <b-col cols="3">
              <div class="d-flex flex-row align-items-right justify-content-end">
                <div class="mr-2">
                  <b-form-select id="per-page-select" class="custom-perpage" v-model="table2.perPage" :options="table2.pageOptions" size="sm"
                  ></b-form-select>
                </div>
                <div>
                  <p class="text-dark mb-0">Menampilkan {{ table2.perPage }} dari {{ table2.totalRows }} baris</p>
                </div>
              </div>
            </b-col>
          </b-row>
        </div>
    </b-modal>
    <b-modal ref="modal-sukses" size="md" title="Informasi" ok-title="Oke" :ok-only="true" :hide-header="true" centered>
      <div class="text-center px-lg-4 px-2">
        <lottie :options="defaultOptions" :autoPlay="true" :width="250" :height="250" />
        <p class="h6 text-success font-weight-bold mb-0">{{ message }}</p>
      </div>
    </b-modal>
    <b-modal ref="modal-gagal" size="md" title="Informasi" ok-title="Kembali" :ok-only="true" :hide-header="true" centered>
      <div class="text-center px-lg-4 px-2">
        <lottie :options="defaultOptions2" :autoPlay="true" :width="250" :height="250" />
        <p class="h6 text-danger font-weight-bold mb-2">Data Gagal Disimpan</p>
        <p class="small text-danger font-weight-light mb-0">{{error}}</p>
      </div>
    </b-modal>
    <b-modal ref="modal-konfirm-delete-hard" size="md" title="KONFIRMASI HAPUS" ok-variant="danger" ok-title="Hapus" @ok="handleDelete" :no-close-on-backdrop="true" :static="true"
    cancel-title="Batal" centered>
      <div class="px-1 py-2">
        <div class="text-center ">
          <i class="fas fa-exclamation-circle text-danger fa-5x"></i>
          <p class="h6 text-danger mb-0 mt-3">Anda Yakin Akan Menghapus Barang ?</p>
        </div>
        <div class="bg-dark px-2 mt-2" v-if="error">
          <p class="small text-white mb-0">{{ error }}</p>
        </div>
      </div>
    </b-modal>
    <ModalMsgbox ref="MsgBox" />
  </div>
</template>
<script>
import { xray } from '../../config/pluginInit'
import Loader from '../../components/xray/loader/Loader'
import Lottie from 'vue-lottie'
import animationLogo from '../../assets/lottie/checked.json'
import animationLogo2 from '../../assets/lottie/failed.json'
import Service from '../../services/fatmahost/index'
import Multiselect from 'vue-multiselect'
import ModalMsgbox from '../../components/modal/ModalMsgBox'
import flatPickr from 'vue-flatpickr-component'
import { Indonesian as IndonesianLocale } from 'flatpickr/dist/l10n/id.js'
import 'flatpickr/dist/flatpickr.css'
// import { mapGetters } from 'vuex'
export default {
  name: 'StockOpname',
  components: {
    ModalMsgbox,
    Multiselect,
    Lottie,
    Loader,
    flatPickr
  },
  data () {
    return {
      table: {
        isBusy: false,
        items: this.list,
        sortBy: '',
        sortDesc: false,
        fields: [
          { label: 'NO', key: 'index', class: 'text-center' },
          { label: 'TANGGAL', key: 'tp.tgl_pending', sortable: true },
          { label: 'JENIS', key: 'nm_pending' },
          { label: 'NORM', key: 'tp.norm', sortable: true },
          { label: 'PASIEN', key: 'p.NAMA', sortable: true },
          { label: 'BARANG', key: 'nama_barang', sortable: true },
          { label: 'PABRIK', key: 'nama_pabrik', sortable: true },
          // { label: 'DOKTER', key: 'dokter' },
          { label: 'DEPO', key: 'mr.deskripsi' },
          { label: 'QUANTITY', key: 'qty_pending', class: 'text-center' },
          { label: 'Aksi', key: 'action', class: 'text-center' }
        ],
        filter: null,
        currentPage: null,
        perPage: null,
        pageOptions: [5, 10, 25, 50, 100, 500],
        totalRows: null
      },
      table2: {
        isBusy: false,
        items: this.list2,
        sortBy: '',
        sortDesc: false,
        fields: [
          { label: 'NO', key: 'index', class: 'text-center' },
          { label: 'Nama Barang', key: 'nama_barang' },
          { label: 'Nama Pabrik', key: 'nama_pabrik' },
          { label: 'Nomor Resep', key: 'nomor_resep' },
          { label: 'TA', key: 'TA' },
          { label: 'Iter', key: 'iter' },
          { label: 'Pinjam', key: 'PINJAM' },
          { label: 'Jumlah', key: 'JUMLAH' },
          { label: 'Satuan', key: 'satuan' },
          { label: 'Tujuan', key: 'deskripsi' },
          { label: 'Aksi', key: 'action', class: 'text-center' }
        ],
        filter: null,
        currentPage: null,
        perPage: null,
        pageOptions: [5, 10, 25, 50, 100, 500],
        totalRows: null
      },
      table2param: {
        obat: '',
        norm: '',
        tgl_awal: '',
        tgl_akhir: ''
      },
      combo: {
        instalasi: [],
        unit: [],
        ruangan: [],
        jenis: [
          { value: 1, text: 'Reservasi Online' },
          { value: 2, text: 'Reservasi Offline' }
        ],
        filterType: [
          { value: '1', text: 'Filter Dokter' },
          { value: '2', text: 'Filter Ruangan' }
        ],
        filterRuang: [
          { value: '', text: 'Rawat Jalan' }
        ],
        pabrik: [],
        status: [
          { value: '1', text: 'Aktif' },
          { value: '0', text: 'tidak Aktif' }
        ]
      },
      options: {
        optionsFilter: [
          { text: 'Semua', value: '' },
          { text: 'Pinjam', value: '2' },
          { text: 'Tinggal Ambil', value: '1' },
          { text: 'Iterasi', value: '3' }
        ],
        optionsFilter2: [
          { text: 'NORM', value: '1' },
          { text: 'Barang', value: '2' },
          { text: 'Pabrik', value: '3' }
        ],
        jenis: [
          { text: 'Pinjam', value: '2' },
          { text: 'Tinggal Ambil', value: '1' },
          { text: 'Iterasi', value: '3' }
        ]
      },
      selected: {
        barang: [
          {
            detail: []
          }
        ],
        instalasi: [],
        unit: [],
        ruangan: [],
        kode: [{
          value: ''
        }],
        pabrik: [],
        filterType: '',
        filterType2: '1',
        jenis: '',
        status: '0'
      },
      id: null,
      kunjunganId: null,
      ruangan: '',
      pendingform: {
        object: 'pending',
        detail: [
          {
            id: '',
            id_katalog_selesai: '',
            id_pabrik_selesai: '',
            tgl_selesai: '',
            qty_selesai: '',
            sts_selesai: '1',
            userid_selesai: xray.currentUser().id.toString()
          }
        ]
      },
      pendingformview: {
        detail: [
          {
            tanggal: '',
            tanggal_selesai: '',
            status: '',
            c_jenis: '',
            c_sts: '',
            //
            norm: '',
            kunjungan: '',
            depo: '',
            pabrik: '',
            jenis: '',
            status_selesai: ''
          }
        ],
        obat: '',
        tgl_view: '',
        tgl: ''
      },
      display: {
        form: {},
        pendingObat: true,
        pendingtgl: '',
        namaObat: '',
        //
        displayruangan: '',
        stockopnamekeluaron: '',
        stockopnamekeluaroff: '',
        viewTgltmbh: '',
        viewTglEdit: '',
        viewTglAwal: '',
        viewTglAkhir: '',
        kodeName: [
          {
            kode: '',
            nama: ''
          }
        ],
        tglInp: [
          {
            value: ''
          }
        ],
        tglExp: [
          {
            date: ''
          }
        ],
        namabarang: [
          {
            nama: ''
          }
        ],
        kodedetail: [
          {
            kode: ''
          }
        ],
        disable_sts: [
          {
            value: ''
          }
        ],
        tanggal: ''
      },
      filtering: {
        valTglAwal: '',
        valTglAkhir: ''
      },
      config: {
        locale: IndonesianLocale,
        disableMobile: true,
        dateFormat: 'Y-m-d'
      },
      status: 'not_accepted',
      statusEdit: 'not_accepted',
      valuedisable: false,
      valuedisable2: false,
      error: '',
      message: '',
      messageTgl: '',
      isLoading: false,
      defaultOptions: { animationData: animationLogo, loop: true },
      defaultOptions2: { animationData: animationLogo2, loop: true }
    }
  },
  mounted () {
    xray.index()
  },
  methods: {
    openNewWindow (kunjungan, param) {
      var baseUrl = 'http://192.168.5.52/pro/simrsfReport/show?'
      var thePath = param === '1' ? '/reports/Simrsf/Farmasi/cetak_etiket' : '/reports/Simrsf/Farmasi/cetak_struk'
      var meter = `{"PKUNJUNGAN": "${kunjungan}"}`
      var format = 'pdf'
      var url = `${baseUrl}path=${thePath}&params=${meter}&format=${format}`
      var newWindow = window.open(url, '_blank')
      if (newWindow) {
        newWindow.focus()
      }
    },
    getbitcontrol () {
      let access = xray.bitcontrol(this.$route.name)
      // bit control 1
      if (access.bitcontrol1.charAt(2) === '1') {
        this.bitcontrol1_access.add = '1'
      }
      if (access.bitcontrol1.charAt(3) === '1') {
        this.bitcontrol1_access.show = '1'
      }
      if (access.bitcontrol1.charAt(4) === '1') {
        this.bitcontrol1_access.edit = '1'
      }
      // bit control 2
      if (access.bitcontrol2.charAt(0) === '0') {
        this.bitcontrol2_group.read = '1'
      } else if (access.bitcontrol2.charAt(0) === '1') {
        this.bitcontrol2_group.entry = '1'
      } else if (access.bitcontrol2.charAt(0) === '2') {
        this.bitcontrol2_group.verif = '1'
      } else if (access.bitcontrol2.charAt(0) === '3') {
        this.bitcontrol2_group.admin = '1'
      }
    },
    toPendingTambah () {
      var id = JSON.stringify(xray.bitcontrol(this.$route.name))
      this.$router.push({
        name: 'farmasi.pendingtambah', params: { id }
      })
    },
    nameWithKode ({ kode, namaBarang }) {
      return `${kode} - ${namaBarang}`
    },
    filterchange () {
      this.$refs.table.refresh()
    },
    async list (ctx) {
      let sortfield = ''
      let sorting = ''
      let filterType = this.selected.filterType ? this.selected.filterType : ''
      let filterType2 = this.selected.filterType2 ? this.selected.filterType2 : ''
      let userid = xray.currentUser().id.toString()
      if (ctx.filter === '') {
        ctx.filter = null
      }
      if (ctx.sortBy) {
        sortfield = ctx.sortBy
      } else {
        sortfield = ''
      }
      if (ctx.sortDesc === true) {
        sorting = 'ASC'
      } else if (ctx.sortDesc === false) {
        sorting = 'DESC'
      } else {
        sorting = ''
      }
      this.table.isBusy = true
      try {
        const response = await Service.katalog.listStokPending(ctx.currentPage - 1, ctx.perPage, ctx.filter, filterType, filterType2, sortfield, sorting, userid)
        this.table.isBusy = false
        this.table.totalRows = response.data.pagination.total
        this.table.perPage = response.data.pagination.perPage
        this.table.currentPage = response.data.pagination.current
        return response.data.data
      } catch (error) {
        this.table.isBusy = false
        return []
      }
    },
    async list2 (ctx) {
      let sortfield = ''
      let sorting = ''
      let filterType = this.selected.filterType ? this.selected.filterType : ''
      let userid = xray.currentUser().id.toString()
      if (ctx.filter === '') {
        ctx.filter = null
      }
      if (ctx.sortBy) {
        sortfield = ctx.sortBy
      } else {
        sortfield = ''
      }
      if (ctx.sortDesc === true) {
        sorting = 'ASC'
      } else if (ctx.sortDesc === false) {
        sorting = 'DESC'
      } else {
        sorting = ''
      }
      this.table2.isBusy = true
      try {
        const response = await Service.katalog.listIterasi(ctx.currentPage - 1, ctx.perPage, ctx.filter, filterType, sortfield, sorting, userid)
        this.table2.isBusy = false
        this.table2.totalRows = response.data.pagination.total
        this.table2.perPage = response.data.pagination.perPage
        this.table2.currentPage = response.data.pagination.current
        this.table2.items = response.data.data
      } catch (error) {
        this.table2.isBusy = false
        return []
      }
    },
    loadtable2 () {
      this.$refs.table2.refresh()
    },
    getDateAuto (value) {
      const date = value === undefined || value === null || value === '' ? new Date() : new Date(value)
      let day = date.getDate()
      let month = (date.getMonth() + 1).toString().padStart(2, 0)
      let year = date.getFullYear()
      if (day < 10) {
        day = '0' + day
      }
      return year + '-' + month + '-' + day
    },
    valuebarangiter (value) {
      this.table2param.obat = value.kode
    },
    valuepabrik ($event, index) {
      this.pendingform.detail[index].id_katalog_selesai = $event.id_katalog.toString()
      this.pendingform.detail[index].id_pabrik_selesai = $event.id_pabrik.toString()
      this.pendingformview.detail[index].pabrik = $event.nama_pabrik
    },
    valueChck (e) {
      if (e === '1') {
        this.display.pendingObat = false
      } else if (e === '0') {
        this.pendingform.qty_selesai = this.display.form.qty_pending.toString()
        this.pendingformview.obat = this.display.form.nama_barang
        this.pendingform.id_katalog_selesai = this.display.form.id_katalog.toString()
        this.pendingform.id_pabrik_selesai = this.display.form.id_pabrik.toString()
        this.selected.pabrik = { id: this.display.form.id_katalog, kode: this.display.form.kode, namaBarang: this.display.form.nama_barang }
        this.display.pendingObat = true
      }
    },
    async handleNewKuota (id, kunjungan) {
      this.id = id
      this.kunjungan = kunjungan
      this.isLoading = true
      try {
        const response = await Service.katalog.showStokPending(kunjungan.toString())
        if (response.data.statcode === 0) {
          this.isLoading = false
          this.deleteRow(0)
          var farmasi = response.data.data
          farmasi.map((value, index) => {
            this.pendingform.detail.push({
              id: value.id.toString(),
              id_katalog_selesai: value.id_katalog_selesai !== null ? value.id_katalog_selesai.toString() : '',
              id_pabrik_selesai: value.id_pabrik_selesai !== null ? value.id_pabrik_selesai.toString() : '',
              tgl_selesai: this.tglConvert(value.tgl_pending, '1'),
              qty_selesai: value.qty_pending.toString(),
              sts_selesai: value.sts_selesai.toString(),
              userid_selesai: xray.currentUser().id.toString()
            })
            this.pendingformview.detail.push({
              tanggal: this.tglConvert(value.tgl_pending, '2'),
              tanggal_selesai: value.tgl_selesai !== null ? this.tglConvert(value.tgl_selesai, '2') : '-',
              status: '0',
              norm: value.norm,
              kunjungan: value.kunjungan,
              depo: value.deskripsi,
              pabrik: value.nama_pabrik_selesai,
              jenis: value.nm_pending,
              c_jenis: value.jns_pending,
              c_sts: value.sts_selesai
            })
            this.selected.barang.push(
              {
                detail: [{ id: value.id_katalog_selesai !== null ? value.id_katalog_selesai : '', kode: value.kode, namaBarang: value.nama_barang_selesai !== null ? value.nama_barang_selesai : '' }]
              }
            )
            this.display.disable_sts.push(
              {
                value: value.sts_selesai.toString()
              }
            )
          })
        } else {
          this.isLoading = false
          this.error = response.data.message
        }
      } catch (error) {
        this.isLoading = false
        this.error = error.message
      }
      this.$refs['modal-pending'].show()
    },
    async reload () {
      this.clearForm()
      this.isLoading = true
      try {
        const response = await Service.katalog.showStokPending(this.kunjungan.toString())
        if (response.data.statcode === 0) {
          this.isLoading = false
          this.deleteRow(0)
          var farmasi = response.data.data
          farmasi.map((value, index) => {
            this.pendingform.detail.push({
              id: value.id.toString(),
              id_katalog_selesai: value.id_katalog_selesai !== null ? value.id_katalog_selesai.toString() : '',
              id_pabrik_selesai: value.id_pabrik_selesai !== null ? value.id_pabrik_selesai.toString() : '',
              tgl_selesai: this.tglConvert(value.tgl_pending, '1'),
              qty_selesai: value.qty_pending.toString(),
              sts_selesai: value.sts_selesai.toString(),
              userid_selesai: xray.currentUser().id.toString()
            })
            this.pendingformview.detail.push({
              tanggal: this.tglConvert(value.tgl_pending, '2'),
              tanggal_selesai: value.tgl_selesai !== null ? this.tglConvert(value.tgl_selesai, '2') : '-',
              status: '0',
              norm: value.norm,
              kunjungan: value.kunjungan,
              depo: value.deskripsi,
              pabrik: value.nama_pabrik_selesai,
              jenis: value.nm_pending,
              c_jenis: value.jns_pending,
              c_sts: value.sts_selesai
            })
            this.selected.barang.push(
              {
                detail: [{ id: value.id_katalog_selesai !== null ? value.id_katalog_selesai : '', kode: value.kode, namaBarang: value.nama_barang_selesai !== null ? value.nama_barang_selesai : '' }]
              }
            )
            this.display.disable_sts.push(
              {
                value: value.sts_selesai.toString()
              }
            )
          })
        } else {
          this.isLoading = false
          this.error = response.data.message
        }
      } catch (error) {
        this.isLoading = false
        this.error = error.message
      }
    },
    async handleSubmit (bvModalEvent) {
      bvModalEvent.preventDefault()
      // this.isLoading = true
      try {
        const id = this.id.toString()
        const data = this.pendingform
        const response = await Service.katalog.updatePending(id, data)
        if (response.data.statcode === 0) {
          // this.isLoading = false
          this.message = response.data.message
          this.reload()
        } else {
          // this.isLoading = false
          this.error = response.data.message
        }
      } catch (error) {
        // this.isLoading = false
        this.error = error.message
      }
    },
    deleteModal (id) {
      this.id = id
      this.$refs['modal-konfirm-delete-hard'].show()
    },
    async handleDelete () {
      const id = this.id
      const response = await Service.katalog.deleteStockOpname(id)
      if (response.data.statcode === 0) {
        this.error = response.data.message
        this.$refs.table.refresh()
      } else {
        this.error = response.data.message
        this.$refs.table.refresh()
      }
    },
    modaliter () {
      this.$refs['modal-iterasi'].show()
    },
    async getPabrik ($event) {
      if ($event.length >= 2) {
        await Service.katalog.getPabrikStokOP_v2($event).then(response => {
          if (response.status === 200) {
            this.combo.pabrik = response.data.data
          }
        }).catch(error => {
          this.error = error.response.data.errors.message
        })
      } else {
        this.combo.pabrik = []
      }
    },
    onFiltered (filteredItems) {
      this.table.totalRows = filteredItems.length
    },
    onFiltered2 (filteredItems) {
      this.table2.totalRows = filteredItems.length
    },
    tableRefresh () {
      this.$refs.table.refresh()
    },
    tableRefresh2 () {
      this.$refs.table2.refresh()
    },
    isNumberKey (evt) {
      evt = (evt) || window.event
      var charCode = (evt.which) ? evt.which : evt.keyCode
      if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
        evt.preventDefault()
      } else {
        return true
      }
    },
    tglConvert (value, param) {
      var date = new Date(value)
      let day = date.getDate()
      let month = (date.getMonth() + 1).toString().padStart(2, 0)
      let year = date.getFullYear()
      if (month < 10) {
        month = '0' + month
      }
      if (day < 10) {
        day = '0' + day
      }
      if (param === '1') {
        let joinDate = year + '-' + month + '-' + day
        return joinDate
      } else if (param === '2') {
        let joinDate = day + '-' + month + '-' + year
        return joinDate
      }
    },
    clearForm () {
      this.error = ''
      this.selected.status = '0'
      this.selected.pabrik = []
      this.pendingformview.tgl = ''
      this.display.pendingObat = true
      //
      this.pendingform.detail = [
        {
          id: '',
          id_katalog_selesai: '',
          id_pabrik_selesai: '',
          tgl_selesai: '',
          qty_selesai: '',
          sts_selesai: '1',
          userid_selesai: xray.currentUser().id.toString()
        }
      ]
      this.pendingformview.detail = [
        {
          tanggal_selesai: '',
          tanggal: '',
          status: '',
          norm: '',
          kunjungan: '',
          depo: '',
          pabrik: '',
          jenis: '',
          c_jenis: '',
          c_sts: ''
        }
      ]
      this.selected.barang = [
        {
          detail: []
        }
      ]
      this.display.disable_sts = [
        {
          value: ''
        }
      ]
    },
    deleteRow (index) {
      this.pendingform.detail.splice(index, 1)
      this.pendingformview.detail.splice(index, 1)
      this.selected.barang.splice(index, 1)
      this.display.disable_sts.splice(index, 1)
    },
    tglWithDots (item) {
      return item['tp.tgl_pending']
    }
  }
}
</script>

<style>
/* #modal-pending .modal-body, #modal-pending-edit .modal-body, #modal-filter .modal-body {
  background: #3399331b;
} */
[dir=ltr] .btn-primary:not(:disabled):not(.disabled).active, [dir=ltr] .btn-primary:not(:disabled):not(.disabled):active, [dir=ltr] .show > .btn-primary.dropdown-toggle {
  color: #fff;
  background: #089bab!important;
  border-color: #089bab!important;
}

#pending-farmasi input[type="text"][disabled],
#modal-pending input[type="text"][disabled],
#modal-pending-edit input[type="text"][disabled]
#modal-iterasi input[type="text"][disabled] {
   background: #e9ecef;
}

#modal-pending .table-pending-farmasi, #modal-pending-edit .table-pending-farmasi, #modal-iterasi .table-pending-farmasi {
  height: auto;
}

#modal-pending .multiselect, #modal-pending-edit .multiselect, #modal-iterasi .multiselect {
  min-height: 33px;
}

#modal-pending .multiselect__single, #modal-pending-edit .multiselect__single, #modal-iterasi .multiselect__single {
  font-size: 0.9em!important;
  font-weight: normal!important;
  line-height: 26px;
}
#modal-pending .multiselect__placeholder, #modal-pending-edit .multiselect__placeholder, #modal-iterasi .multiselect__placeholder {
  font-size: 0.9em!important;
  font-weight: normal;
}
#modal-pending .multiselect__option, #modal-pending-edit .multiselect__option, #modal-iterasi .multiselect__option {
  font-size: .8em!important;
}
#modal-pending .multiselect__tags, #modal-pending-edit .multiselect__tags, #modal-iterasi .multiselect__tags {
  padding: 1px 40px 0 8px!important;
  height: 33px!important;
  min-height: 33px!important;
  font-size: 0.9em!important;
  overflow: hidden;
}

#modal-pending-edit .multiselect--disabled .multiselect__tags, #modal-pending .multiselect--disabled .multiselect__tags, #modal-iterasi .multiselect--disabled .multiselect__tags {
  border: 1px solid rgba(0, 0, 0, 0.171);
  background: #E9ECEF;
}

#modal-pending-edit .multiselect--disabled, #modal-pending .multiselect--disabled, #modal-iterasi .multiselect--disabled  {
  opacity: 1;
}

#modal-pending-edit .multiselect--disabled .multiselect__tags .multiselect__single, #modal-pending .multiselect--disabled .multiselect__tags .multiselect__single, #modal-iterasi .multiselect--disabled .multiselect__tags .multiselect__single {
  background: transparent;
}

#modal-pending-edit .multiselect__select, #modal-pending .multiselect__select, #modal-iterasi .multiselect__select {
  height: 30px;
}

#modal-pending-edit .disable-bg, #modal-pending .disable-bg, #modal-iterasi .disable-bg {
  background: #e9ecef;
  padding-top: 5px;
  padding-bottom: 5px;
}

#modal-pending .flatpickr-input[readonly], #modal-pending-edit .flatpickr-input[readonly], #modal-iterasi .flatpickr-input[readonly] #modal-filter .flatpickr-input[readonly] {
  height: 33px;
  font-size: 13px;
  border-radius: 5px;
  border: 1px solid rgba(128, 128, 128, 0.292);
  padding-left: 10px;
  color: black;
}

#modal-pending .cstm-cntrl-stockopname, #modal-pending-edit .cstm-cntrl-stockopname, #modal-iterasi .cstm-cntrl-stockopname, #modal-filter .cstm-cntrl-stockopname {
  height: 38px!important;
  font-size: 13px!important;
  color: black!important;
  border-radius: 3px;
}

#modal-pending .modal-dialog, #modal-iterasi .modal-dialog {
  min-width: 95%!important;
}

#modal-pending .modal-body, #modal-pending-edit .modal-body, #modal-iterasi .modal-body {
  background: #3399331b;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

#modal-pending .modal-header, #modal-pending-edit .modal-header, #modal-iterasi .modal-header {
  background: #3399333a;
}

#modal-pending .modal-footer, #modal-pending-edit .modal-footer, #modal-iterasi .modal-footer {
  background: #3399333a;
}
#modal-pending .cstm-cntrl-stockopname, #modal-pending-edit .cstm-cntrl-stockopname, #modal-iterasi .cstm-cntrl-stockopname {
  border-radius: 5px!important;
  font-size: 13px!important;
  height: 34px!important;
}
#modal-pending .cstm-slct-stockopname, #modal-pending-edit .cstm-slct-stockopname, #modal-iterasi .cstm-slct-stockopname  {
  font-size: 13px!important;
  height: 38px!important;
  border-radius: 5px;
}

#pending-farmasi .bg-filter {
  border: 1px solid rgba(0, 0, 0, 0.13);
  background-color: #089bab1e;
}

#header-table-filter .flatpickr-input[readonly] {
  height: 38px;
  font-size: 12px;
  border-radius: 5px;
  border: 1px solid rgba(17, 17, 17, 0.292);
  padding-left: 10px;
}

#pending-farmasi legend {
    color: darkslategrey;
}
#pending-farmasi .form-control:disabled, #pending-farmasi .form-control[readonly] {
    background-color: #e9ecef;
    opacity: 0.5;
}
#pending-farmasi .form-control {
  color: black!important;
}
#pending-farmasi .fc-unthemed .fc-toolbar .fc-button {
    margin: 3px;
}
#pending-farmasi tr:first-child > td > .fc-day-grid-event {
    cursor: pointer;
}

#pending-farmasi .flatpickr-input[readonly], #modal-iterasi .flatpickr-input[readonly] {
  height: 34px;
  font-size: 12px;
  border-radius: 5px;
  border: 1px solid rgba(17, 17, 17, 0.292);
  padding-left: 10px!important;
}

#pending-farmasi .flex-half-width-left {
  width: 76%;
}
#pending-farmasi .flex-half-width-right {
  width: 23%;
}
#pending-farmasi .flex-full-width-left {
  width: 96%;
}
#pending-farmasi .flex-full-width-right {
  width: 3%;
}
#pending-farmasi .txt-farmasi-base {
  font-size: 12px;
}
#pending-farmasi .txt-farmasi-base-head {
  font-size: 14px;
}
#pending-farmasi .table td, #modal-iterasi .table td {
    font-size: 12px;
    font-weight: 500;
    padding-top: 0px;
    padding-bottom: 0px;
}
#pending-farmasi .table th, #modal-iterasi .table th {
    font-size: 13px;
    font-weight: 400;
}
#pending-farmasi .table td {
    font-size: 12px;
    font-weight: 500;
    padding-top: 0px;
    padding-bottom: 0px;
}
#pending-farmasi .table th {
    font-size: 13px;
    font-weight: 400;
}
#pending-farmasi .form-control {
    border: 1px solid rgb(194, 194, 194);
    background: white;
    height: 40px;
}

#pending-farmasi .btn-height-custom {
    height: 39px;
}
#pending-farmasi .cursor-point {
    cursor: pointer;
}
#pending-farmasi .bg-abu {
  background: #e9ecef;
}
#pending-farmasi .label-brg  {
  font-size: 13px;
  padding-left: 14px;
  padding-right: 14px;
  border: 1px solid rgba(0, 0, 0, 0.13);
  border-radius: 3px;
}
#pending-farmasi .form-control {
    font-size: 11px;
}
#pending-farmasi .b-table-sticky-header, #pending-farmasi .table-responsive {
    margin-bottom: 0px;
}
#pending-farmasi .table-barang .btn {
    font-size: 11px;
}
#pending-farmasi .page-link {
    border: none;
    color: gray!important;
    margin-right: 15px;
}
#pending-farmasi .page-item.active .page-link {
    background-color: transparent;
    border-color: transparent;
    color: #089bab!important;
    font-weight: bold;
}
#pending-farmasi .vertical-txt {
  writing-mode: vertical-rl;
  letter-spacing: 10px;
}
#pending-farmasi .transition-cstm {
  transition: .3s linear;
}

#pending-farmasi .form-control:disabled, #pending-farmasi .form-control[readonly] {
  background-color: #e9ecef;
  opacity: 1;
}

#pending-farmasi .cstm-srch-stock, #modal-iterasi .cstm-srch-stock {
  font-size: 13px;
  height: 38px !important;
  border-radius: 5px;
}

#pending-farmasi .cstm-slct-stock {
  font-size: 13px;
  height: 38px !important;
  border-radius: 5px;
}
.alert-radius-stockopname {
  border-radius: 5px;
}

.font-size-responsive {
  font-size: 7px;
}

.pagination-pending .page-link, #modal-iterasi .pagination-pending .page-link {
    border: none;
    color: gray!important;
    margin-right: 15px;
}
.pagination-pending .page-item.active .page-link, #modal-iterasi .pagination-pending .page-item.active .page-link {
    background-color: transparent;
    border-color: transparent;
    color: #089bab!important;
    font-weight: bold;
}

@media (min-width: 576px) {
  .font-size-responsive {
    font-size: 8px;
  }
 }

@media (min-width: 768px) {
  .font-size-responsive {
    font-size: 9px;
  }
 }

@media (min-width: 992px) {
  .font-size-responsive {
    font-size: 10px;
  }
 }

@media (min-width: 1200px) {
  .font-size-responsive {
    font-size: 11px;
  }
 }
 .pointer-table tbody tr {
  cursor: pointer;
}
</style>
