<template>
  <div id="farmasi-barang" class="mb-3">
    <b-container fluid>
      <div>
        <div class="d-flex flex-wrap justify-content-between transition-cstm">
          <div :class="['transition-cstm mb-2 mb-lg-0', responsiveFlex.left]">
            <div class="left-list bg-white shadow-sm">
              <div class="table-head bg-primary px-2 py-1">
                <p class="txt-farmasi-base-head text-white font-weight-normal mb-0"><i class="fas fa-list"></i> List Barang</p>
              </div>
              <div>
                <div class="table-barang">
                  <b-table responsive bordered striped hover :items="items" :fields="fields" :current-page="currentPage"
                    :per-page="perPage" @row-clicked="onRowClicked">
                    <template v-slot:cell(action)="data">
                      <b-form-checkbox :id="'checkbox'+data.item.no" :name="'checkbox'+data.item.no" value="accepted" unchecked-value="not_accepted">
                      </b-form-checkbox>
                    </template>
                    <template v-slot:cell(actionB)="data">
                      <b-button class="rounded-0" variant="primary" size="sm"> Harga{{ data.item.no }}</b-button>
                    </template>
                  </b-table>
                </div>
                <div class="bg-white pb-2 pt-2 pl-3 pr-3 border-bottom border-right border-left">
                  <b-row class="align-items-center justify-content-between">
                    <b-col cols="12" lg="3" class="mr-auto">
                      <div class="d-flex align-items-center">
                        <div>
                          <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage" align="" size="md" class="my-0">
                          </b-pagination>
                        </div>
                        <div class="border-left border-right px-3">
                          <span class="cursor-point">
                            <i class="fas fa-sync"></i>
                          </span>
                        </div>
                      </div>
                    </b-col>
                    <b-col cols="12" lg="5">
                      <div class="d-flex flex-row">
                        <div class="mr-2 w-75">
                          <b-input-group size="sm">
                            <b-form-input id="filter-input" type="search" placeholder="Cari Nama Obat"></b-form-input>
                          </b-input-group>
                        </div>
                        <div>
                          <multiselect v-model="selected.tambahBarang" :show-labels="false" :limit="3" label="deskripsi"
                            placeholder="[ Status ]" :options="combo.tambahBarang" :searchable="true">
                          </multiselect>
                        </div>
                      </div>
                    </b-col>
                    <b-col cols="12" lg="3">
                      <span class="text-right">
                        <p class="font-weight-normal mb-0">Menampilkan 1 - {{ perPage }} dari {{ totalRows }}</p>
                      </span>
                    </b-col>
                  </b-row>
                </div>
              </div>
            </div>
          </div>
          <div :class="['transition-cstm', responsiveFlex.right]">
            <div class="right-form h-100">
              <div class="table-head bg-primary px-2 py-1" v-if="responsiveFlex.condition === false">
                <div class="d-flex flex-row align-items-center">
                  <div class="mr-auto">
                    <p class="txt-farmasi-base-head text-white font-weight-normal mb-0">Tambah Barang Baru</p>
                  </div>
                  <div>
                    <span class="cursor-point" @click="fullWidth">
                      <i class="text-white fas fa-caret-right fa-lg"></i>
                    </span>
                  </div>
                </div>
              </div>
              <div class="table-head bg-primary px-2 py-1 h-100 shadow-sm border cursor-point" @click="halfWidth" v-else-if="responsiveFlex.condition === true">
                <div class="d-flex flex-row align-items-center justify-content-center h-100">
                  <div class="mr-1">
                    <span>
                      <i class="text-white fas fa-caret-left fa-lg"></i>
                    </span>
                  </div>
                  <div>
                    <p class="small text-white font-weight-normal vertical-txt m-0">Tambah Barang Baru</p>
                  </div>
                </div>
              </div>
              <div :class="['transition-cstm', responsiveFlex.visibility]">
                <div class="scroll-y pr-2">
                  <div class="line-1 d-flex flex-row mt-2">
                    <div class="w-75 mr-2">
                      <b-form-group class="mb-0">
                        <multiselect v-model="selected.tambahBarang" :show-labels="false" :limit="3" label="deskripsi"
                          placeholder="[ NAMA BARANG ]" :options="combo.tambahBarang" :searchable="true">
                        </multiselect>
                      </b-form-group>
                    </div>
                    <div class="w-25">
                      <b-form-group class="mb-0">
                        <b-form-input class="rounded-0" type="number" v-model="input.tambahBarang"></b-form-input>
                      </b-form-group>
                    </div>
                  </div>
                  <div class="line-2 d-flex flex-row mt-2">
                    <div class="w-100">
                      <b-form-group class="mb-0">
                        <multiselect v-model="selected.tambahBarang" :show-labels="false" :limit="3" label="deskripsi"
                          placeholder="[ NAMA GENERIK ]" :options="combo.tambahBarang" :searchable="true">
                        </multiselect>
                      </b-form-group>
                    </div>
                  </div>
                  <div class="line-3 d-flex flex-row mt-2">
                    <div class="w-100">
                      <b-form-group class="mb-0">
                        <multiselect v-model="selected.tambahBarang" :show-labels="false" :limit="3" label="deskripsi"
                          placeholder="[ MEREK ]" :options="combo.tambahBarang" :searchable="true">
                        </multiselect>
                      </b-form-group>
                    </div>
                  </div>
                  <div class="line-4 d-flex flex-row mt-2">
                    <div class="w-50 mr-2">
                      <b-form-group class="mb-0">
                        <multiselect v-model="selected.tambahBarang" :show-labels="false" :limit="3" label="deskripsi"
                          placeholder="[ JENIS INVENTORY ]" :options="combo.tambahBarang" :searchable="true">
                        </multiselect>
                      </b-form-group>
                    </div>
                    <div class="w-50">
                      <b-form-group class="mb-0">
                        <multiselect v-model="selected.tambahBarang" :show-labels="false" :limit="3" label="deskripsi"
                          placeholder="[ JENIS KATEGORI ]" :options="combo.tambahBarang" :searchable="true">
                        </multiselect>
                      </b-form-group>
                    </div>
                  </div>
                  <div class="line-5 d-flex flex-row mt-2">
                    <div class="w-100">
                      <b-form-group class="mb-0">
                        <multiselect v-model="selected.tambahBarang" :show-labels="false" :limit="3" label="deskripsi"
                          placeholder="[ KATEGORI BARANG ]" :options="combo.tambahBarang" :searchable="true">
                        </multiselect>
                      </b-form-group>
                    </div>
                  </div>
                  <div class="line-6 d-flex flex-row mt-2">
                    <div class="w-100">
                      <b-form-group class="mb-0">
                        <multiselect v-model="selected.tambahBarang" :show-labels="false" :limit="3" label="deskripsi"
                          placeholder="[ PENGGUNAAN BARANG ]" :options="combo.tambahBarang" :searchable="true">
                        </multiselect>
                      </b-form-group>
                    </div>
                  </div>
                  <div class="line-7 d-flex flex-row mt-2">
                    <div class="w-75 mr-2">
                      <b-form-group class="mb-0">
                        <multiselect v-model="selected.tambahBarang" :show-labels="false" :limit="3" label="deskripsi"
                          placeholder="[ SATUAN BARANG ]" :options="combo.tambahBarang" :searchable="true">
                        </multiselect>
                      </b-form-group>
                    </div>
                    <div class="w-25">
                      <b-form-group class="mb-0">
                        <b-button class="btn-height-custom border border-white w-100 rounded-0" variant="secondary"><i
                            class="fas fa-plus"></i></b-button>
                      </b-form-group>
                    </div>
                  </div>
                  <div class="line-8 d-flex flex-row mt-2">
                    <div class="w-100">
                      <b-form-group class="mb-0">
                        <multiselect v-model="selected.tambahBarang" :show-labels="false" :limit="3" label="deskripsi"
                          placeholder="[ NAMA PENYEDIA BARANG ]" :options="combo.tambahBarang" :searchable="true">
                        </multiselect>
                      </b-form-group>
                    </div>
                  </div>
                  <div class="line-9 d-flex flex-row mt-2">
                    <div class="w-100">
                      <b-form-group class="mb-0">
                        <multiselect v-model="selected.tambahBarang" :show-labels="false" :limit="3" label="deskripsi"
                          placeholder="[ JENIS GENERIK ]" :options="combo.tambahBarang" :searchable="true">
                        </multiselect>
                      </b-form-group>
                    </div>
                  </div>
                  <div class="line-10 d-flex flex-row mt-2">
                    <div class="w-100">
                      <b-form-group class="mb-0">
                        <multiselect v-model="selected.tambahBarang" :show-labels="false" :limit="3" label="deskripsi"
                          placeholder="[ FORMULARIUM ]" :options="combo.tambahBarang" :searchable="true">
                        </multiselect>
                      </b-form-group>
                    </div>
                  </div>
                  <div class="line-11 d-flex flex-row mt-2">
                    <div class="w-100">
                      <b-form-group class="mb-0">
                        <multiselect v-model="selected.tambahBarang" :show-labels="false" :limit="3" label="deskripsi"
                          placeholder="[ KLAIM TERPISAH ]" :options="combo.tambahBarang" :searchable="true">
                        </multiselect>
                      </b-form-group>
                    </div>
                  </div>
                  <div class="collapse-1 mt-2">
                    <div>
                      <div class="d-flex flex-row align-items-center">
                        <div class="w-50 cursor-point">
                          <p class="mb-0" v-b-toggle.collapse-1><i class="ri-add-box-fill"></i> Pengelolaan Barang</p>
                        </div>
                        <div class="w-50">
                          <hr>
                        </div>
                      </div>
                      <div>
                        <b-collapse class="border-bottom border-right border-left" id="collapse-1">
                          <div class="bg-white">
                            <div class="d-flex flex-row px-3 py-1 bg-abu">
                              <div class="mr-auto">
                                <p class="text-dark mb-0">OOT</p>
                              </div>
                              <div>
                                <b-form-checkbox id="checkbox-1" name="checkbox-1" value="accepted" unchecked-value="not_accepted">
                                </b-form-checkbox>
                              </div>
                            </div>
                            <div class="d-flex flex-row px-3 py-1">
                              <div class="mr-auto">
                                <p class="text-dark mb-0">Narkotika</p>
                              </div>
                              <div>
                                <b-form-checkbox id="checkbox-2" name="checkbox-2" value="accepted" unchecked-value="not_accepted">
                                </b-form-checkbox>
                              </div>
                            </div>
                            <div class="d-flex flex-row px-3 py-1 bg-abu">
                              <div class="mr-auto">
                                <p class="text-dark mb-0">Antibiotik</p>
                              </div>
                              <div>
                                <b-form-checkbox id="checkbox-3" name="checkbox-3" value="accepted" unchecked-value="not_accepted">
                                </b-form-checkbox>
                              </div>
                            </div>
                          </div>
                        </b-collapse>
                      </div>
                    </div>
                  </div>
                  <div class="collapse-2 mt-2">
                    <div>
                      <div class="d-flex flex-row align-items-center">
                        <div class="w-75 cursor-point">
                          <p class="mb-0" v-b-toggle.collapse-2><i class="ri-add-box-fill"></i> Katalog & Kode Persediaan</p>
                        </div>
                        <div class="w-25">
                          <hr>
                        </div>
                      </div>
                      <div>
                        <b-collapse class="border-bottom border-right border-left" id="collapse-2">
                          <div class="bg-abu">
                            <div>
                              <b-form-input class="rounded-0 border-0" placeholder="[ KODE KATALOG ]"></b-form-input>
                            </div>
                            <div class="mt-2">
                              <b-form-input class="rounded-0 border-0" placeholder="[ KODE PERSEDIAAN ]"></b-form-input>
                            </div>
                          </div>
                        </b-collapse>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="table-head bg-abu px-2 pb-1 pt-2 mt-4 border-top text-right">
                  <b-button class="rounded-0 mr-2" variant="success" size="sm"><i class="far fa-save"></i> Simpan</b-button>
                  <b-button class="rounded-0" variant="danger" size="sm"><i class="fas fa-times"></i> Reset</b-button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </b-container>
  </div>
</template>
<script>
import { xray } from '../../config/pluginInit'
import Multiselect from 'vue-multiselect'

export default {
  name: 'Index',
  components: {
    Multiselect
  },
  data () {
    return {
      responsiveFlex: {
        left: 'flex-full-width-left',
        right: 'flex-full-width-right',
        visibility: 'd-none',
        condition: true
      },
      currentPage: 1,
      perPage: 12,
      totalRows: 15,
      items: [
        { no: 1, NAMA_BARANG: 'Pulpen Isi', KATEGORI: 'Alat Tulis', SATUAN: 'CASSETTE', MEREK: '3M', PENYEDIAAN: '-', GENERIK: 'Acarbose', JENIS_PENGGUNAAN: '-', STOK_MINIMUM: '10' },
        { no: 2, NAMA_BARANG: 'Pulpen Isi', KATEGORI: 'Alat Tulis', SATUAN: 'CASSETTE', MEREK: '3M', PENYEDIAAN: '-', GENERIK: 'Acarbose', JENIS_PENGGUNAAN: '-', STOK_MINIMUM: '10' },
        { no: 3, NAMA_BARANG: 'Pulpen Isi', KATEGORI: 'Alat Tulis', SATUAN: 'CASSETTE', MEREK: '3M', PENYEDIAAN: '-', GENERIK: 'Acarbose', JENIS_PENGGUNAAN: '-', STOK_MINIMUM: '10' },
        { no: 4, NAMA_BARANG: 'Pulpen Isi', KATEGORI: 'Alat Tulis', SATUAN: 'CASSETTE', MEREK: '3M', PENYEDIAAN: '-', GENERIK: 'Acarbose', JENIS_PENGGUNAAN: '-', STOK_MINIMUM: '10' },
        { no: 5, NAMA_BARANG: 'Pulpen Isi', KATEGORI: 'Alat Tulis', SATUAN: 'CASSETTE', MEREK: '3M', PENYEDIAAN: '-', GENERIK: 'Acarbose', JENIS_PENGGUNAAN: '-', STOK_MINIMUM: '10' },
        { no: 6, NAMA_BARANG: 'Pulpen Isi', KATEGORI: 'Alat Tulis', SATUAN: 'CASSETTE', MEREK: '3M', PENYEDIAAN: '-', GENERIK: 'Acarbose', JENIS_PENGGUNAAN: '-', STOK_MINIMUM: '10' },
        { no: 7, NAMA_BARANG: 'Pulpen Isi', KATEGORI: 'Alat Tulis', SATUAN: 'CASSETTE', MEREK: '3M', PENYEDIAAN: '-', GENERIK: 'Acarbose', JENIS_PENGGUNAAN: '-', STOK_MINIMUM: '10' },
        { no: 8, NAMA_BARANG: 'Pulpen Isi', KATEGORI: 'Alat Tulis', SATUAN: 'CASSETTE', MEREK: '3M', PENYEDIAAN: '-', GENERIK: 'Acarbose', JENIS_PENGGUNAAN: '-', STOK_MINIMUM: '10' },
        { no: 9, NAMA_BARANG: 'Pulpen Isi', KATEGORI: 'Alat Tulis', SATUAN: 'CASSETTE', MEREK: '3M', PENYEDIAAN: '-', GENERIK: 'Acarbose', JENIS_PENGGUNAAN: '-', STOK_MINIMUM: '10' },
        { no: 10, NAMA_BARANG: 'Pulpen Isi', KATEGORI: 'Alat Tulis', SATUAN: 'CASSETTE', MEREK: '3M', PENYEDIAAN: '-', GENERIK: 'Acarbose', JENIS_PENGGUNAAN: '-', STOK_MINIMUM: '10' },
        { no: 11, NAMA_BARANG: 'Pulpen Isi', KATEGORI: 'Alat Tulis', SATUAN: 'CASSETTE', MEREK: '3M', PENYEDIAAN: '-', GENERIK: 'Acarbose', JENIS_PENGGUNAAN: '-', STOK_MINIMUM: '10' },
        { no: 12, NAMA_BARANG: 'Pulpen Isi', KATEGORI: 'Alat Tulis', SATUAN: 'CASSETTE', MEREK: '3M', PENYEDIAAN: '-', GENERIK: 'Acarbose', JENIS_PENGGUNAAN: '-', STOK_MINIMUM: '10' },
        { no: 13, NAMA_BARANG: 'Pulpen Isi', KATEGORI: 'Alat Tulis', SATUAN: 'CASSETTE', MEREK: '3M', PENYEDIAAN: '-', GENERIK: 'Acarbose', JENIS_PENGGUNAAN: '-', STOK_MINIMUM: '10' },
        { no: 14, NAMA_BARANG: 'Pulpen Isi', KATEGORI: 'Alat Tulis', SATUAN: 'CASSETTE', MEREK: '3M', PENYEDIAAN: '-', GENERIK: 'Acarbose', JENIS_PENGGUNAAN: '-', STOK_MINIMUM: '10' },
        { no: 15, NAMA_BARANG: 'Pulpen Isi', KATEGORI: 'Alat Tulis', SATUAN: 'CASSETTE', MEREK: '3M', PENYEDIAAN: '-', GENERIK: 'Acarbose', JENIS_PENGGUNAAN: '-', STOK_MINIMUM: '10' }
      ],
      fields: [
        { label: 'No', key: 'no', class: 'text-center' },
        { label: 'NAMA BARANG', key: 'NAMA_BARANG', class: 'text-left' },
        { label: 'KATEGORI', key: 'KATEGORI', class: 'text-left' },
        { label: 'SATUAN', key: 'SATUAN', class: 'text-left' },
        { label: 'MEREK', key: 'MEREK', class: 'text-left' },
        { label: 'PENYEDIAAN', key: 'PENYEDIAAN', class: 'text-left' },
        { label: 'GENERIK', key: 'GENERIK', class: 'text-left' },
        { label: 'JENIS PENGGUNAAN', key: 'JENIS_PENGGUNAAN', class: 'text-left' },
        { label: 'STOK MINIMUM', key: 'STOK_MINIMUM', class: 'text-left' },
        { label: 'STATUS', key: 'action', class: 'text-center' },
        { label: '#', key: 'actionB', class: 'text-center' }
      ],
      selected: {
        tambahBarang: []
      },
      combo: {
        tambahBarang: []
      },
      input: {
        tambahBarang: 0
      }
    }
  },
  mounted () {
    xray.index()
  },
  methods: {
    onRowClicked (item, index, event) {
      // console.log(item)
      // console.log(index)
      // console.log(event)
    },
    fullWidth () {
      this.responsiveFlex.visibility = 'd-none'
      this.responsiveFlex.condition = true
      this.responsiveFlex.left = 'flex-full-width-left'
      this.responsiveFlex.right = 'flex-full-width-right'
    },
    halfWidth () {
      this.responsiveFlex.visibility = 'd-block'
      this.responsiveFlex.condition = false
      this.responsiveFlex.left = 'flex-half-width-left'
      this.responsiveFlex.right = 'flex-half-width-right'
    }

  }
}
</script>
<style>
#farmasi-barang .flex-half-width-left {
  width: 76%;
}
#farmasi-barang .flex-half-width-right {
  width: 23%;
}
#farmasi-barang .flex-full-width-left {
  width: 96%;
}
#farmasi-barang .flex-full-width-right {
  width: 3%;
}
#farmasi-barang .txt-farmasi-base {
  font-size: 12px;
}
#farmasi-barang .txt-farmasi-base-head {
  font-size: 14px;
}
#farmasi-barang .table td, #farmasi-barang .table th {
    vertical-align: middle;
    font-size: 12px!important;
    padding: 0.5rem!important;
}
#farmasi-barang .form-control {
    border: 1px solid rgb(224, 224, 224);
    background: white;
    height: 40px;
}
#farmasi-barang .multiselect__tags {
    border-radius: 0px;
    border: 1px solid rgb(224, 224, 224);
    background: white;
    font-size: 11px;
    font-weight: 550;
    height: 10px;
}
#farmasi-barang .btn-height-custom {
    height: 39px;
}
#farmasi-barang .cursor-point {
    cursor: pointer;
}
#farmasi-barang .scroll-y {
    overflow-y: scroll;
    height: 550px;
}
#farmasi-barang .bg-abu {
  background: rgba(236, 236, 236, 0.541);
}
#farmasi-barang .form-control {
    font-size: 11px;
    font-weight: 550;
}
#farmasi-barang .b-table-sticky-header, #farmasi-barang .table-responsive {
    margin-bottom: 0px;
}
#farmasi-barang .table-barang .btn {
    font-size: 11px;
}
#farmasi-barang .page-link {
    border: none;
    color: gray!important;
    margin-right: 15px;
}
#farmasi-barang .page-item.active .page-link {
    background-color: transparent;
    border-color: transparent;
    color: #089bab!important;
    font-weight: bold;
}
#farmasi-barang .vertical-txt {
  writing-mode: vertical-rl;
  letter-spacing: 10px;
}
#farmasi-barang .transition-cstm {
  transition: .3s linear;
}
</style>
