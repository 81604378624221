<template>
  <div id="dashboard-remun" class="mb-4">
    <b-container fluid>
      <div class="card-remun-dash">
        <b-row>
          <!-- <b-col cols="3" class="pr-1">
            <iq-card class="rounded-custom bg-primary card-child-remun m-0 h-100">
              <div class="d-flex flex-row align-items-center justify-content-between px-4 py-3">
                <div>
                  <p class="h6 text-white mb-1">{{ preview.userJwt.profesi }}</p>
                  <p class="h6 font-weight-bold mb-0 text-white">{{ preview.userJwt.name }}</p>
                </div>
                <div>
                  <i class="fas fa-user-md fa-3x text-white"></i>
                </div>
              </div>
            </iq-card>
          </b-col> -->
          <b-col cols="6" lg="3" class="pr-1 mb-2 mb-lg-0">
            <iq-card class="rounded-custom card-child-remun shadow-sm m-0 h-100">
              <div class="px-4 py-3" @click="active('1')">
                <p :class="['h6 text-primary mb-1', classActive.all === '1' ? 'text-primary' : 'text-secondary']">TOTAL <i :class="classActive.all === '1' ? 'fas fa-check-circle text-success' : ''"></i></p>
                <p :class="['h6 font-weight-bold mb-0', classActive.all === '1' ? 'text-dark' : 'text-secondary']">
                  <span v-if="preview.saldo === '1'">{{ data.ALLIN }}</span>
                  <span v-else>************</span>
                </p>
              </div>
            </iq-card>
          </b-col>
          <b-col cols="6" lg="3" class="pr-lg-1 pl-1 mb-2 mb-lg-0">
            <iq-card class="rounded-custom card-child-remun shadow-sm m-0 h-100">
              <div class="px-4 py-3" @click="active('4')">
                <p :class="['h6 text-primary mb-1', classActive.jkn === '1' ? 'text-primary' : 'text-secondary']">JKN <i :class="classActive.jkn === '1' ? 'fas fa-check-circle text-success' : ''"></i></p>
                <p :class="['h6 font-weight-bold mb-0', classActive.jkn === '1' ? 'text-dark' : 'text-secondary']">
                  <span v-if="preview.saldo === '1'">{{ data.JKN }}</span>
                  <span v-else>************</span>
                </p>
              </div>
            </iq-card>
          </b-col>
          <b-col cols="6" lg="3" class="pr-1 pl-lg-1 mb-2 mb-lg-0">
            <iq-card class="rounded-custom card-child-remun shadow-sm m-0 h-100">
              <div class="px-4 py-3"  @click="active('3')">
                <p :class="['h6 text-primary mb-1', classActive.reg === '1' ? 'text-primary' : 'text-secondary']">REGULER <i :class="classActive.reg === '1' ? 'fas fa-check-circle text-success' : ''"></i></p>
                <p :class="['h6 font-weight-bold mb-0', classActive.reg === '1' ? 'text-dark' : 'text-secondary']">
                  <span v-if="preview.saldo === '1'">{{ data.REGULER }}</span>
                  <span v-else>************</span>
                </p>
              </div>
            </iq-card>
          </b-col>
          <b-col cols="6" lg="3" class="pl-1 mb-2 mb-lg-0">
            <iq-card class="rounded-custom card-child-remun shadow-sm m-0 h-100">
              <div class="px-4 py-3" @click="active('2')">
                <p :class="['h6 text-primary mb-1', classActive.exc === '1' ? 'text-primary' : 'text-secondary']">EXECUTIVE <i :class="classActive.exc === '1' ? 'fas fa-check-circle text-success' : ''"></i></p>
                <p :class="['h6 font-weight-bold mb-0', classActive.exc === '1' ? 'text-dark' : 'text-secondary']">
                  <span v-if="preview.saldo === '1'">{{ data.EXECUTIVE }}</span>
                  <span v-else>************</span>
                </p>
              </div>
            </iq-card>
          </b-col>
        </b-row>
      </div>
      <div class="list-remun-dash mt-2">
        <iq-card class="rounded-custom shadow-sm">
          <template v-slot:body>
            <div class="head-tb-remun border py-2 px-2">
              <b-row class="align-items-center">
                <b-col cols="12" md="6">
                  <div>
                    <h5 class="card-title font-weight-bold text-center text-lg-left text-uppercase mb-0">RINCIAN {{ preview.title }}  {{ data.PERIODE }}</h5>
                  </div>
                </b-col>
                <b-col cols="12" md="6" class="d-flex flex-row justify-content-center justify-content-lg-end align-items-center">
                  <div>
                    <b-form-checkbox
                      id="checkbox-1"
                      v-model="preview.saldo"
                      name="checkbox-1"
                      value="1"
                      unchecked-value="0"
                    >
                      <span class="text-uppercase font-weight-bold">Tampilkan Nilai</span>
                    </b-form-checkbox>
                  </div>
                  <div class="mx-2">
                    <p class="text-dark mb-0">|</p>
                  </div>
                  <div>
                    <b-button :disabled="data.historyRemun === 0 ? true : false" :variant="data.historyRemun === 0 ? 'secondary' : 'primary'" size="sm" @click="toGrafik"><i class="fas fa-chart-bar"></i> RIWAYAT REMUN</b-button>
                  </div>
                </b-col>
              </b-row>
            </div>
            <div class="head-tb-remun-filter border py-2 px-2">
              <b-row class="align-items-center">
                <b-col cols="12" md="5" class="mb-3 mb-lg-0 pr-1">
                  <div class="d-flex flex-row align-items-center">
                    <div class="mr-1 w-25">
                      <b-form-select class="cstm-slct-remun-dash" v-model="selected.filter" :options="options.optionsFilter" @change="filterchange"></b-form-select>
                    </div>
                    <div class="w-50" v-if="selected.filter === '1'">
                      <form class="searchbox mb-0">
                        <input v-model="table.filter" type="search" class="form-control cstm-srch-remun-dash" id="filter-input"
                          placeholder="Cari">
                      </form>
                    </div>
                    <div class="w-50" v-else>
                      <div>
                        <b-form-select class="cstm-slct-remun-dash" v-model="selected.kegiatan_kelompok" :options="options.optionsKegKelompok" @change="filterchange"></b-form-select>
                      </div>
                    </div>
                  </div>
                </b-col>
              </b-row>
            </div>
            <div class="table-responsive mb-0">
              <b-table class="mb-0" ref="table" :sort-by.sync="table.sortBy" :sort-desc.sync="table.sortDesc"
                :filter="table.filter" @filtered="onFiltered" :busy="table.isBusy" :current-page="table.currentPage"
                :per-page="table.perPage" responsive stacked="md" bordered hover show-empty small striped :items="table.items"
                :fields="table.fields">
                <template #cell(index)="data">
                  {{ (table.currentPage*table.perPage)-table.perPage + data.index + 1 }}
                </template>
                <template #cell(tr.tgl_hasil)="data">
                  <span>{{ new Date(tglWithDots(data.item)) | formatDate }}</span>
                </template>
                <template #cell(tr.kegiatan_tarif_jpd)="data">
                  <span v-if="preview.saldo === '1'">{{ formatRibuan(tarifWithDots(data.item)) }}</span>
                  <span v-else>************</span>
                </template>
              </b-table>
            </div>
            <div class="pagination-remun-dash border shadow-sm p-2 mb-0">
              <b-row class="align-items-center justify-content-center justify-content-lg-between">
                <b-col lg="3" class="d-flex flex-row align-items-center justify-content-center justify-content-lg-between mb-3 mb-lg-0">
                  <div class="">
                    <b-pagination
                      v-model="table.currentPage"
                      :total-rows="table.totalRows"
                      :per-page="table.perPage"
                      size="md"
                      class="my-0"
                    ></b-pagination>
                  </div>
                  <div class="">
                    <div>
                      <span class="border-left border-dark mr-3 py-1"></span>
                      <span @click="tableRefresh" class="katalog-cursor">
                        <i class="fas fa-sync text-primary"></i>
                      </span>
                      <span class="border-right border-dark ml-3 py-1"></span>
                    </div>
                  </div>
                </b-col>
                <b-col lg="3">
                  <div class="d-flex flex-row align-items-center justify-content-center justify-content-lg-end">
                    <div class="mr-3">
                      <b-form-select
                        class="cstm-select-remun-dash"
                        id="per-page-select"
                        v-model="table.perPage"
                        :options="table.pageOptions"
                      ></b-form-select>
                    </div>
                    <div>
                      <p class="text-dark mb-0">Menampilkan {{ table.perPage }} dari {{ table.totalRows }} baris</p>
                    </div>
                  </div>
                </b-col>
              </b-row>
            </div>
          </template>
        </iq-card>
        <!-- <pre>{{ table }}</pre> -->
      </div>
    </b-container>
  </div>
</template>
<script>
import { xray } from '../../../config/pluginInit'
import newServices from '../../../services/fatmahost/pegawai/index.ts'
// import jwtdecode from 'jwt-decode'

export default {
  name: 'Remun',
  data () {
    return {
      data: {},
      table: {
        isBusy: false,
        items: this.list,
        sortBy: '',
        sortDesc: false,
        fields: [
          { label: 'NO.', key: 'index', class: 'text-lg-center py-2' },
          { label: 'TANGGAL', key: 'tr.tgl_hasil', sortable: true },
          { label: 'NORM', key: 'tr.pasien_nomor', sortable: true },
          { label: 'NAMA PASIEN', key: 'tr.pasien_nama', sortable: true },
          { label: 'KEG. ID ', key: 'tr.kegiatan_id', sortable: true },
          { label: 'KEG. NAMA', key: 'tr.kegiatan_nama', sortable: true },
          { label: 'RUANGAN', key: 'tr.kegiatan_ruang', sortable: true },
          { label: 'KEG. KELOMPOK', key: 'tr.kegiatan_kelompok' },
          { label: 'DTO KEG. KELOMPOK', key: 'tr.dto_kegiatan_klp' },
          { label: 'JASA', key: 'tr.kegiatan_tarif_jpd', class: 'text-lg-right' }
        ],
        filter: null,
        currentPage: null,
        perPage: null,
        pageOptions: [10, 25, 50, 100],
        totalRows: null,
        type: 'rinci-dash-remun-allin'
      },
      classActive: {
        all: '1',
        exc: '0',
        reg: '0',
        jkn: '0'
      },
      options: {
        optionsFilter: [
          { text: 'Nama Kegiatan', value: '1' },
          { text: 'Kegiatan Kelompok', value: '2' }
        ],
        optionsKegKelompok: [
          { text: 'Rawat Jalan', value: 'rawatjalan' },
          { text: 'Tindakan', value: 'tindakan' },
          { text: 'Visite', value: 'visite' },
          { text: 'Penunjang', value: 'penunjang' }
        ]
      },
      selected: {
        filter: '1',
        kegiatan_kelompok: 'rawatjalan'
      },
      preview: {
        id: xray.currentUser().pegawai_id.toString(),
        periode: '000000',
        userJwt: {},
        title: 'REMUNERASI',
        message: '',
        valMessage: '',
        saldo: '0'
      }
    }
  },
  mounted () {
    xray.index()
    this.preview.id = this.$route.params.id ? this.$route.params.id : xray.currentUser().pegawai_id.toString()
    this.preview.periode = this.$route.query.periode ? this.$route.query.periode : '000000'
    let id = this.$route.params.id ? this.$route.params.id : xray.currentUser().pegawai_id.toString()
    let periode = this.$route.query.periode ? this.$route.query.periode : '000000'
    this.load(id, periode)
    this.preview.userJwt = xray.currentUser()
  },
  methods: {
    async load (id, periode) {
      try {
        const response = await newServices.loadRemun(id, periode)
        this.data = response.data.data[0]
      } catch (e) {
        this.preview.message = e.message
      }
    },
    toGrafik () {
      this.$router.push({
        name: 'sdm.remundetail'
      })
    },
    async list (ctx) {
      this.isBusy = true
      try {
        this.isBusy = false
        let id = this.preview.id
        let periode = this.preview.periode
        let jenis = this.table.type
        let kegKel = this.selected.kegiatan_kelompok
        let sortfield = ''
        let sorting = ''
        if (ctx.sortBy) {
          sortfield = ctx.sortBy
        } else {
          sortfield = ''
        }
        if (ctx.sortDesc === true) {
          sorting = 'DESC'
        } else if (ctx.sortDesc === false) {
          sorting = 'ASC'
        } else {
          sorting = ''
        }
        if (ctx.filter === null) {
          ctx.filter = ''
        }
        const response = await newServices.rinciRekapRemun(ctx.currentPage - 1, ctx.perPage, id, jenis, periode, kegKel, ctx.filter, sortfield, sorting)
        this.table.totalRows = response.data.pagination.total
        this.table.perPage = response.data.pagination.perPage
        this.table.currentPage = response.data.pagination.current
        return response.data.data
      } catch (e) {
        this.isBusy = false
        this.preview.message = e.message
        return []
      }
    },
    onFiltered (filteredItems) {
      this.table.totalRows = filteredItems.length
    },
    filterchange () {
      this.table.filter = ''
      this.$refs.table.refresh()
    },
    tableRefresh () {
      this.$refs.table.refresh()
    },
    active (value) {
      if (value === '1') {
        this.classActive.all = '1'
        this.classActive.exc = '0'
        this.classActive.reg = '0'
        this.classActive.jkn = '0'
        this.table.type = 'rinci-dash-remun-allin'
        this.selected.filter = '1'
        this.selected.kegiatan_kelompok = 'rawatjalan'
        this.preview.title = 'REMUNERASI'
      } else if (value === '2') {
        this.classActive.all = '0'
        this.classActive.exc = '1'
        this.classActive.reg = '0'
        this.classActive.jkn = '0'
        this.table.type = 'rinci-dash-remun-executive'
        this.selected.filter = '1'
        this.selected.kegiatan_kelompok = 'rawatjalan'
        this.preview.title = 'EXECUTIVE'
      } else if (value === '3') {
        this.classActive.all = '0'
        this.classActive.exc = '0'
        this.classActive.reg = '1'
        this.classActive.jkn = '0'
        this.table.type = 'rinci-dash-remun-reguler'
        this.selected.filter = '1'
        this.selected.kegiatan_kelompok = 'rawatjalan'
        this.preview.title = 'REGULER'
      } else if (value === '4') {
        this.classActive.all = '0'
        this.classActive.exc = '0'
        this.classActive.reg = '0'
        this.classActive.jkn = '1'
        this.table.type = 'rinci-dash-remun-jkn'
        this.selected.filter = '1'
        this.selected.kegiatan_kelompok = 'rawatjalan'
        this.preview.title = 'JKN'
      }
      this.table.currentPage = 1
      this.$refs.table.refresh()
    },
    formatRibuan (value) {
      if (value !== null || value !== '' || value) {
        const amount = parseInt(value)
        return amount.toLocaleString()
      } else {
        return '0'
      }
    },
    tglWithDots (item) {
      return item['tr.tgl_hasil']
    },
    tarifWithDots (item) {
      return item['tr.kegiatan_tarif_jpd']
    }
    // limitString (value) {
    //   var maxLength = 10
    //   if (value.length <= maxLength) {
    //     return value
    //   } else {
    //     return value.slice(0, maxLength - 3) + '...'
    //   }
    // }
    //
  }
}
</script>
<style>
#dashboard-remun .card-child-remun {
  cursor: pointer;
}
#dashboard-remun .table td {
  font-size: .75rem;
  font-weight: 500;
  padding-top: 0px;
  padding-bottom: 0px;
}
#dashboard-remun .table th {
  font-size: .8rem;
  font-weight: 400;
}
#dashboard-remun .rounded-custom {
  border-radius: 5px;
}
#dashboard-remun .page-link {
  border: none;
  color: gray!important;
  margin-right: 15px;
}
#dashboard-remun .page-item.active .page-link {
  background-color: transparent;
  border-color: transparent;
  color: #089bab!important;
  font-weight: bold;
}
#dashboard-remun .cstm-srch-remun-dash {
  font-size: 13px;
  height: 38px!important;
  border-radius: 5px;
}
#dashboard-remun .cstm-slct-remun-dash {
  font-size: 13px;
  height: 38px!important;
  border-radius: 5px;
}
#dashboard-remun .cstm-select-remun-dash {
  font-size: 13px;
  height: 38px!important;
  border-radius: 5px;
}
</style>
