<template>
  <div>
    <b-row>
      <b-col cols="12" lg="4">
        <div class="bg-white shadow-sm px-3 py-3 radius">
          <div class="stok-opname-1 radius bg-primary px-3 py-2">
            <b-row>
              <b-col class="d-flex align-items-center">
                <p class="text-white mb-0">Stok Opname</p>
              </b-col>
              <b-col>
                <multiselect class="shadow-sm border-0 rounded" v-model="vselectJenisPendonor" :show-labels="false"
                  :limit="3" @input="valJenisPendonor" label="name" placeholder="Ruang Transaksi"
                  :options="comboJenisPendonor" :searchable="true">
                </multiselect>
              </b-col>
            </b-row>
          </div>
          <div class="stok-opname-2 mt-3">
            <b-row class="justify-content-center">
              <b-col cols="12" lg="6">
                <b-form-group class="mb-0">
            <div class="border radius">
              <multiselect v-model="vselectJenisPendonor" :show-labels="false" :limit="3" @input="valJenisPendonor" label="name"
                placeholder="Kode Barang" :options="comboJenisPendonor" :searchable="true">
              </multiselect>
            </div>
          </b-form-group>
              </b-col>
              <b-col cols="12" lg="6">
                <b-form-group class="mb-0">
                  <b-form-input id="exampleInputText1" type="date" placeholder="Enter Name" value="Mark Jhon"></b-form-input>
                </b-form-group>
              </b-col>
              <b-col class="mt-3" cols="12" lg="4">
                <b-button variant="success" class="btn-block shadow-sm">Create Form</b-button>
              </b-col>
            </b-row>
          </div>
        </div>
        <div class="mt-3">
          <div class="stok-opname-3 radius-top bg-primary px-3 py-3">
            <p class="text-white mb-0"><i class="fas fa-list"></i> Periode</p>
          </div>
          <div class="bg-white text-center py-3 px-3 shadow-sm">
            <small class="text-dark">No data</small>
          </div>
        </div>
      </b-col>
      <b-col cols="12" lg="8">
        <div class="bg-white radius shadow-sm">
          <div class="bg-primary radius-top px-3 py-2 mb-0">
            <b-row>
              <b-col cols="12" lg="3" class="d-flex align-items-center">
                <p class="mb-0"><i class="fas fa-list"></i> List Barang</p>
              </b-col>
              <b-col cols="12" lg="6">
                <multiselect class="shadow-sm border-0 rounded" v-model="vselectJenisPendonor" :show-labels="false"
                  :limit="3" @input="valJenisPendonor" label="name"
                  placeholder="Filter Hanya Barang Yang Ada Transaksi Dan Stok" :options="comboJenisPendonor"
                  :searchable="true">
                </multiselect>
              </b-col>
              <b-col cols="12" lg="3">
                <multiselect class="shadow-sm border-0 rounded" v-model="vselectJenisPendonor" :show-labels="false"
                  :limit="3" @input="valJenisPendonor" label="name" placeholder="Semua" :options="comboJenisPendonor"
                  :searchable="true">
                </multiselect>
              </b-col>
            </b-row>
          </div>
          <div>
            <b-table class="bg-white" ref="table" stacked="md" striped hover show-empty :items="items"></b-table>
          </div>
        </div>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import Multiselect from 'vue-multiselect'
export default {
  name: 'StokOpname',
  components: {
    Multiselect
  },
  data () {
    return {
      items: [
        { No: 1, kode: 'Barang 1', item_barang: 'Kategori 1', kategori: 'Merk 1', satuan: 'Penyedia 1', ED: 'Stok 1', status: 'Status 1', nomor_Seri: 'No. Seri 1' }
      ],
      fields: [
        'kode',
        { key: 'awal', label: 'Awal' },
        { key: 'masuk', label: 'Masuk' },
        { key: 'keluar', label: 'Keluar' },
        { key: 'jumlah', label: 'Type 2C' }
      ],
      comboJenisPendonor: [
        { name: 'Langsung', id: 1 },
        { name: 'Sukarela', id: 2 },
        { name: 'Pengganti', id: 3 },
        { name: 'Aferesis', id: 4 }
      ],
      vselectJenisPendonor: []
    }
  },
  methods: {
    valJenisPendonor (value) {
      this.result.jenis_donor = value.id
    }
  }
}
</script>
