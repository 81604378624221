<template>
  <div id="menu-module" class="mb-4">
    <div v-show="isLoading">
    <div id="show-overlay"></div>
    <Loader :style="{ opacity: .5 }" />
  </div>
    <b-container fluid class="mb-5">
      <iq-card>
        <div class="px-4 py-3 d-flex align-items-center justify-content-between">
          <div>
            <h4 class="card-title  mb-0">Menu Module</h4>
          </div>
          <div>
            <b-button variant="primary" @click="modalModul('', '', '1')"><i class="fas fa-plus"></i> Tambah Module</b-button>
          </div>
        </div>
        <hr class="m-0 p-0">
        <div class="body p-4">
          <div class="table mb-0">
              <b-table class="mb-0" ref="table" :filter="table.filter"
                  @filtered="onFiltered" :busy="table.isBusy" :items="table.items" :fields="table.fields"
                :current-page="table.currentPage" :per-page="table.perPage" stacked="md"
                bordered hover show-empty small striped>
                <template #cell(index)="data">
                  {{ (table.currentPage*table.perPage)-table.perPage + data.index + 1 }}
                </template>
                <template #cell(action)="data">
                  <b-button variant="white" class="my-1 mx-1" size="md" @click="modalModul(data.item.id_smenu, data.item.kode_smenu, '2')">
                    <i class="fas fa-edit text-primary"></i>
                  </b-button>
                  <b-button variant="white" class="my-1 mx-1" size="md" @click="modalModul(data.item.id_smenu, data.item.kode_smenu, '3')">
                    <i class="fas fa-trash-alt text-danger"></i>
                  </b-button>
                </template>
              </b-table>
            </div>
            <div class="bg-white pb-2 pt-2 pl-3 pr-3 border-bottom border-right border-left">
              <b-row class="align-items-center justify-content-between">
                <b-col cols="12" lg="3" class="mr-auto">
                  <div class="d-flex align-items-center">
                    <div>
                      <b-pagination v-model="table.currentPage" :total-rows="table.totalRows" :per-page="table.perPage" size="sm" class="my-0">
                      </b-pagination>
                    </div>
                    <div class="border-left border-right px-3">
                      <span class="cursor-point" @click="tableRefresh">
                        <i class="fas fa-sync"></i>
                      </span>
                    </div>
                  </div>
                </b-col>
                <b-col cols="12" lg="5">
                  <div class="d-flex flex-row">
                    <div class="mr-2 w-75">
                      <b-input-group>
                        <b-form-input class="cstm-slct-pegawai rounded-0" id="filter-input" v-model="table.filter" type="search" placeholder="Pencarian"></b-form-input>
                      </b-input-group>
                    </div>
                    <div>
                      <b-form-select class="cstm-slct-pegawai" v-model="table.selected.filterType" :options="table.combo.filterType" @change="filterchange"></b-form-select>
                    </div>
                  </div>
                </b-col>
                <b-col cols="12" lg="3">
                  <span class="text-right">
                    <p class="font-weight-normal mb-0">Menampilkan 1 - {{ table.perPage }} dari {{ table.totalRows }}</p>
                  </span>
                </b-col>
              </b-row>
            </div>
        </div>
      </iq-card>
    </b-container>
    <div id="modal-component">
      <b-modal ref="modal-module" id="modal-module" size="md" :title="modalTitle" ok-title="Oke" @ok="proccess" @close="clear" @cancel="clear" :ok-only="true" :no-close-on-backdrop="true" centered>
        <b-form v-if="modulIndex === '1'">
          <div class="mb-2">
            <b-row class="align-items-center">
              <b-col cols="4">
                <p class="txt-module-base text-dark mb-0">Strucktur Menu :</p>
              </b-col>
              <b-col>
                <div>
                  <multiselect v-model="selected.modules" :show-labels="false" :limit="3" label="smenuname" placeholder="Structur Menu" :options="combo.modules" @input="valmodules">
                  </multiselect>
                </div>
              </b-col>
            </b-row>
          </div>
          <div class="mb-2 ">
            <b-row class="align-items-center">
              <b-col cols="4">
                <p class="txt-module-base text-dark mb-0">Level :</p>
              </b-col>
              <b-col>
                <div>
                  <multiselect v-model="selected.level" :show-labels="false" :limit="3" label="deskripsi" placeholder="Level" :options="combo.level" @input="valLevel">
                  </multiselect>
                </div>
              </b-col>
            </b-row>
          </div>
          <div class="mb-2 ">
            <b-row class="align-items-center">
              <b-col cols="4">
                <p class="txt-module-base text-dark mb-0">Kode Sub Menu :</p>
              </b-col>
              <b-col>
                <div>
                  <b-form-input class="cstm-cntrl-module" v-model="created.kode_smenu" @keypress="isNumberKey" maxlength=10 placeholder="Kode Sub Menu"></b-form-input>
                </div>
              </b-col>
            </b-row>
          </div>
          <div class="mb-2 ">
            <b-row class="align-items-center">
              <b-col cols="4">
                <p class="txt-module-base text-dark mb-0">Nama Sub Menu :</p>
              </b-col>
              <b-col>
                <div>
                  <b-form-input class="cstm-cntrl-module" v-model="created.smenu_name" placeholder="Nama Sub Menu"></b-form-input>
                </div>
              </b-col>
            </b-row>
          </div>
          <div class="mb-2 ">
            <b-row class="align-items-center">
              <b-col cols="4">
                <p class="txt-module-base text-dark mb-0">Icon :</p>
              </b-col>
              <b-col>
                <div>
                  <b-form-input class="cstm-cntrl-module" v-model="created.icon" placeholder="Icon"></b-form-input>
                </div>
              </b-col>
            </b-row>
          </div>
          <div class="mb-0">
            <b-row class="align-items-center">
              <b-col cols="4">
                <p class="txt-module-base text-dark mb-0">Sub Modul :</p>
              </b-col>
              <b-col>
                <div>
                  <multiselect v-model="selected.submenu" :show-labels="false" :limit="3" label="name" placeholder="Sub Modul" :options="combo.submenu" @input="valsubmenu" :disabled="disableMultiSelect">
                  </multiselect>
                </div>
              </b-col>
            </b-row>
          </div>
          <!-- <div class="mb-0">
            <b-row class="align-items-center">
              <b-col cols="3">
                <p class="txt-module-base text-dark mb-0">Created By :</p>
              </b-col>
              <b-col>
                <div>
                  <b-form-input class="cstm-cntrl-module" v-model="created.created_by" placeholder="Created By" readonly></b-form-input>
                </div>
              </b-col>
            </b-row>
          </div> -->
        </b-form>
        <b-form v-else-if="modulIndex === '2'">
          <!-- <div class="mb-2">
            <b-row class="align-items-center">
              <b-col cols="4">
                <p class="txt-module-base text-dark mb-0">Menu :</p>
              </b-col>
              <b-col>
                <div>
                  <multiselect v-model="selected.modules" :show-labels="false" :limit="3" label="smenuname" placeholder="[ Modules ]" :options="combo.modules" @input="valEditmodules">
                  </multiselect>
                </div>
              </b-col>
            </b-row>
          </div> -->
          <div class="mb-2 ">
            <b-row class="align-items-center">
              <b-col cols="4">
                <p class="txt-module-base text-dark mb-0">Level :</p>
              </b-col>
              <b-col>
                <div>
                  <multiselect v-model="selected.level" :show-labels="false" :limit="3" label="deskripsi" placeholder="[ Level ]" :options="combo.level" @input="valEditLevel">
                  </multiselect>
                </div>
              </b-col>
            </b-row>
          </div>
          <div class="mb-2 ">
            <b-row class="align-items-center">
              <b-col cols="4">
                <p class="txt-module-base text-dark mb-0">Nama Sub Menu :</p>
              </b-col>
              <b-col>
                <div>
                  <b-form-input class="cstm-cntrl-module" v-model="edited.smenu_name" placeholder="[ Nama Sub Menu ]"></b-form-input>
                </div>
              </b-col>
            </b-row>
          </div>
          <div class="mb-2 ">
            <b-row class="align-items-center">
              <b-col cols="4">
                <p class="txt-module-base text-dark mb-0">Icon :</p>
              </b-col>
              <b-col>
                <div>
                  <b-form-input class="cstm-cntrl-module" v-model="edited.icon" placeholder="[ Icon ]"></b-form-input>
                </div>
              </b-col>
            </b-row>
          </div>
          <div class="mb-0">
            <b-row class="align-items-center">
              <b-col cols="4">
                <p class="txt-module-base text-dark mb-0">Sub Modul :</p>
              </b-col>
              <b-col>
                <div>
                  <multiselect v-model="selected.submenu" :show-labels="false" :limit="3" label="name" placeholder="[ Sub Modul ]" :options="combo.submenu" @input="valEditsubmenu" :disabled="disableMultiSelect">
                  </multiselect>
                </div>
              </b-col>
            </b-row>
          </div>
        </b-form>
        <!-- <div>
          <small class="text-danger">blm bisa create level 1 , id_modules gabisa kosong</small>
          <pre>{{ created }}</pre>
          <pre>{{ edited }}</pre>
        </div> -->
      </b-modal>
      <b-modal ref="modal-delete" size="md" centered title="Konfirmasi" ok-title="Hapus Data" ok-variant="danger" cancel-title="Tutup" @ok="konfirmdelete">
        <div class="py-3">
          <p class="h6 text-center text-dark mb-0">Yakin Ingin Menghapus Data ?</p>
        </div>
      </b-modal>
      <ModalMsgbox ref="MsgBox" />
    </div>
  </div>
</template>
<script>
import { xray } from '../../../config/pluginInit'
import Service from '../../../services/fatmahost/index'
import Multiselect from 'vue-multiselect'
import Loader from '../../../components/xray/loader/Loader'
import ModalMsgbox from '../../../components/modal/ModalMsgBox'

export default {
  name: 'ModuleList',
  components: {
    Loader,
    Multiselect,
    ModalMsgbox
  },
  data () {
    return {
      messageId: '',
      message: '',
      isLoading: false,
      module: null,
      submodule: null,
      moduleId: '',
      moduleKode: '',
      modulIndex: '',
      modalTitle: '',
      disableMultiSelect: false,
      selected: {
        submenu: [],
        modules: [],
        level: []
      },
      combo: {
        submenu: [],
        modules: [],
        level: [
          { deskripsi: '1', id: '1' },
          { deskripsi: '2', id: '2' }
        ]
      },
      table: {
        isBusy: false,
        items: this.list,
        fields: [
          { label: 'No', key: 'index', class: 'text-center' },
          { label: 'Kode Sub Menu', key: 'kode_smenu' },
          { label: 'Nama Sub Menu', key: 'smenu_name' },
          { label: 'Nama Struktur Sub Menu', key: 'smenuname' },
          { label: 'Level', key: 'level' },
          { label: 'Icon', key: 'icon' },
          { label: 'Aksi', key: 'action', class: 'text-center' }
        ],
        currentPage: null,
        perPage: null,
        pageOptions: [10, 25, 50, 100],
        totalRows: null,
        filter: '',
        selected: {
          filterType: '2'
        },
        combo: {
          filterType: [
            // { value: '1', text: 'KODE' },
            { value: '2', text: 'NAMA' }
          ]
        }
      },
      created: {
        object: 'menu-sub-module-detail',
        id_smenu: '',
        kode_smenu: '0000000000',
        smenu_name: '',
        is_heading: 0,
        level: '',
        id_modules: 0,
        icon: '',
        created_by: xray.currentUser().id
      },
      edited: {
        object: 'menu-sub-module-detail',
        smenu_name: '',
        is_heading: 0,
        level: '',
        id_modules: 0,
        icon: '',
        created_by: '',
        created_at: '',
        updated_by: xray.currentUser().id
      }
    }
  },
  mounted () {
    xray.index()
    this.listSelectize()
    this.selectMenu()
    this.newselectStrctureMenu()
    this.newselectSubmodul()
  },
  methods: {
    filterchange () {
      this.table.filter = ''
    },
    onFiltered () {
    },
    isNumberKey (evt) {
      evt = (evt) || window.event
      var charCode = (evt.which) ? evt.which : evt.keyCode
      if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
        evt.preventDefault()
      } else {
        return true
      }
    },
    tableRefresh () {
      this.$refs.table.refresh()
    },
    async list (ctx) {
      this.isBusy = true
      let filterType = this.table.selected.filterType
      let filters = ''
      if (ctx.filter === '') {
        ctx.filter = null
        filters = `{"xparam0":"","xparam1":""}`
      }
      if (ctx.filter === null) {
        filters = `{"xparam0":"","xparam1":""}`
      } else {
        if (filterType === '1') {
          filters = `{"xparam0":"${ctx.filter}","xparam1":""}`
        } else if (filterType === '2') {
          filters = `{"xparam0":"","xparam1":"${ctx.filter}"}`
        }
      }
      try {
        const response = await Service.module.findAllMenuModule(ctx.currentPage - 1, ctx.perPage, filters)
        this.isBusy = false
        this.table.totalRows = response.data.pagination.total
        this.table.perPage = response.data.pagination.perPage
        this.table.currentPage = response.data.pagination.current
        return response.data.data
      } catch (error) {
        this.isBusy = false
        return []
      }
    },
    async listSelectize () {
      const selectConfig = {
        currentPage: 0,
        perPage: 1000
      }
      const response = await Service.module.findAll(selectConfig.currentPage, selectConfig.perPage)
      this.combo.submenu = response.data.data
    },
    async selectMenu () {
      const selectConfig = {
        currentPage: 0,
        perPage: 1000
      }
      const response = await Service.menu.findAll(selectConfig.currentPage, selectConfig.perPage)
      this.combo.modules = response.data.data
    },
    async newselectStrctureMenu () {
      try {
        const response = await Service.menu.selectStrctureMenu()
        this.combo.modules = response.data.data
      } catch (error) {
        this.message = error.response
      }
    },
    async newselectSubmodul () {
      try {
        const response = await Service.menu.selectSubmodul()
        this.combo.submenu = response.data.data
      } catch (error) {
        this.message = error.response
      }
    },
    modalModul (event, e, param) {
      this.moduleId = event
      this.moduleKode = e
      this.modulIndex = param
      if (param === '1') {
        this.modalTitle = 'Tambah Modul'
      } else if (param === '2') {
        this.modalTitle = 'Edit Modul'
        this.showModul()
      } else if (param === '3') {
        this.$refs['modal-delete'].show()
      }
      if (param !== '3') {
        this.$refs['modal-module'].show()
      }
    },
    konfirmdelete () {
      this.deleteModul()
    },
    proccess () {
      if (this.modulIndex === '1') {
        this.createModul()
      } else if (this.modulIndex === '2') {
        this.editModul()
      }
    },
    async createModul () {
      this.isLoading = true
      let data = this.created
      await Service.module.createMenuModule(data).then(response => {
        this.$refs.MsgBox.show({
          type: 'success',
          msg: response.data.message[0].statmessage
        })
      }).catch(error => {
        this.$refs.MsgBox.show({
          type: 'error',
          msg: error.response.data.errors.message
        })
        this.isLoading = false
      }).finally(() => {
        this.$refs.table.refresh()
        this.clear()
        this.isLoading = false
      })
    },
    async editModul () {
      this.isLoading = true
      let id = this.moduleId
      let kode = this.moduleKode
      let data = this.edited
      await Service.module.updateMenuModule(id, kode, data).then(response => {
        this.$refs.MsgBox.show({
          type: 'success',
          msg: response.data.message[0].statmessage
        })
      }).catch(error => {
        this.$refs.MsgBox.show({
          type: 'error',
          msg: error.response.data.errors.message
        })
        this.isLoading = false
      }).finally(() => {
        this.$refs.table.refresh()
        this.clear()
        this.isLoading = false
      })
    },
    async deleteModul () {
      this.isLoading = true
      let id = this.moduleId
      let kode = this.moduleKode
      await Service.module.deleteMenuModule(id, kode).then(response => {
        this.$refs.MsgBox.show({
          type: 'success',
          msg: response.data.message[0].statmessage
        })
      }).catch(error => {
        this.$refs.MsgBox.show({
          type: 'error',
          msg: error.response.data.errors.message
        })
      }).finally(() => {
        this.$refs.table.refresh()
        this.clear()
        this.isLoading = false
      })
    },
    async showModul () {
      let id = this.moduleId.toString()
      let kode = this.moduleKode
      await Service.module.findIdMenuModule(id, kode).then(response => {
        this.edited.smenu_name = response.data.data[0].smenu_name
        this.edited.created_by = response.data.data[0].created_by
        this.edited.created_at = response.data.data[0].created_at
        this.valResLevel(response.data.data[0].level.toString())
        this.selected.level = { id: response.data.data[0].level, deskripsi: response.data.data[0].level }
        this.edited.icon = response.data.data[0].icon
        this.edited.id_modules = response.data.data[0].id_modules.toString()
        this.selected.submenu = { id: response.data.data[0].id_modules, name: 'deskripsi/nama blm ada' }
        this.$refs.table.refresh()
      }).catch(error => {
        this.message = error.response
        this.$refs.table.refresh()
      })
    },
    valsubmenu (event) {
      this.created.id_modules = event.id.toString()
    },
    valmodules (event) {
      this.created.id_smenu = event.id_smenu.toString()
    },
    valLevel (event) {
      this.created.level = event.id.toString()
      // if (event.id === '1') {
      //   this.disableMultiSelect = true
      //   this.created.id_modules = '0'
      //   this.selected.submenu = []
      // } else {
      //   this.disableMultiSelect = false
      // }
    },
    valEditsubmenu (event) {
      this.edited.id_modules = event.id.toString()
    },
    valEditLevel (event) {
      this.edited.level = event.id.toString()
      // if (event.id === '1') {
      //   this.disableMultiSelect = true
      //   this.edited.id_modules = ''
      //   this.selected.submenu = []
      // } else {
      //   this.disableMultiSelect = false
      // }
    },
    valResLevel (val) {
      this.edited.level = val
      if (val === '1') {
        this.disableMultiSelect = true
        this.edited.id_modules = ''
        this.selected.submenu = []
      } else {
        this.disableMultiSelect = false
      }
    },
    clear () {
      this.created.object = 'menu-sub-module-detail'
      this.created.id_smenu = ''
      this.created.kode_smenu = ''
      this.created.smenu_name = ''
      this.created.is_heading = 0
      this.created.level = ''
      this.created.id_modules = 0
      this.created.icon = ''
      this.created_by = xray.currentUser().id
      this.edited.object = 'menu-sub-module-detail'
      this.edited.smenu_name = ''
      this.edited.is_heading = 0
      this.edited.level = ''
      this.edited.id_modules = ''
      this.edited.icon = ''
      this.edited.created_by = ''
      this.edited.created_at = ''
      this.edited.updated_by = xray.currentUser().id
      this.selected.submenu = []
      this.selected.modules = []
      this.selected.level = []
    }
  }
}
</script>
<style>
#menu-module .form-control {
  font-weight: bold!important;
  color: black!important;
}
#menu-module .fc-unthemed .fc-toolbar .fc-button {
    margin: 3px;
}
#menu-module tr:first-child > td > .fc-day-grid-event {
    cursor: pointer;
}
#menu-module .multiselect__input, .multiselect {
  font-weight: bold!important;
}
td.fc-day.fc-past {
  background-color: #EEEEEE;
}
#modal-module .multiselect__single {
  font-size: 12px!important;
}
#modal-module .multiselect__placeholder {
  font-size: 12px!important;
  padding-left: 4px;
}
#modal-module .multiselect__option {
  font-size: 12px;
}
#modal-module .multiselect__tags {
  padding: 5px 40px 0 8px!important;
  height: 38px!important;
  min-height: 38px!important;
}
#menu-module .flex-half-width-left {
  width: 76%;
}
#menu-module .flex-half-width-right {
  width: 23%;
}
#menu-module .flex-full-width-left {
  width: 96%;
}
#menu-module .flex-full-width-right {
  width: 3%;
}
#menu-module .txt-module-base, #modal-module .txt-module-base {
  font-size: 12px;
}
#menu-module .txt-module-base-head {
  font-size: 14px;
}
#menu-module .table td {
    font-size: 12px;
    font-weight: 500;
    padding-top: 0px;
    padding-bottom: 0px;
}
#menu-module .table th {
    font-size: 13px;
    font-weight: 400;
}
#menu-module .form-control {
    border: 1px solid rgb(224, 224, 224);
    background: white;
    height: 40px;
}
#modal-module .multiselect__tags {
    border-radius: 0px;
    border: 1px solid rgb(224, 224, 224);
    background: white;
    font-size: 11px;
    font-weight: 550;
    height: 10px;
}
#menu-module .btn-height-custom {
    height: 39px;
}
#menu-module .cursor-point {
    cursor: pointer;
}
#menu-module .scroll-y {
    overflow-y: scroll;
    height: 550px;
}
#menu-module .bg-abu {
  background: rgba(236, 236, 236, 0.541);
}
#menu-module .form-control {
    font-size: 11px;
    font-weight: 550;
}
#menu-module .b-table-sticky-header, #menu-module .table-responsive {
    margin-bottom: 0px;
}
#menu-module .table-barang .btn {
    font-size: 11px;
}
#menu-module .page-link {
    border: none;
    color: gray!important;
    margin-right: 10px!important;
}
#menu-module .page-item.active .page-link {
    background-color: transparent;
    border-color: transparent;
    color: #089bab!important;
    font-weight: bold;
}
#menu-module .vertical-txt {
  writing-mode: vertical-rl;
  letter-spacing: 10px;
}
#menu-module .transition-cstm {
  transition: .3s linear;
}
.cstm-cntrl-module {
  border-radius: 5px!important;
  font-size: 12px!important;
  height: 38px!important;
}
#menu-module .custom-control-label, #modal-module .custom-control-label {
    font-size: 12px;
    margin-top: 0;
}
#menu-module .modal-dialog {
  min-width: 65%!important;
}
.cstm-slct-kuota {
  font-size: 13px!important;
  height: 38px!important;
  border-radius: 5px;
}
.alert-radius-kuota {
  border-radius: 5px;
}
</style>
