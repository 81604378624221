<template>
  <div id="katalog-brand" class="mb-4">
    <b-container fluid>
      <div>
        <iq-card>
          <template v-slot:body>
            <div class="filter-brand border p-2">
              <b-row class="align-items-center">
                <b-col cols="12" lg="3" class="d-flex flex-row w-100 mr-auto">
                  <div>
                    <h5 class="card-title font-weight-bold mb-0">Data Log</h5>
                  </div>
                </b-col>
              </b-row>
            </div>
            <div class="table mb-0">
              <b-table
                class="mb-0"
                ref="table"
                :busy="table.isBusy"
                :items="table.items"
                :fields="table.fields"
                :current-page="table.currentPage"
                :per-page="table.perPage"
                :filter="table.filter"
                @filtered="onFiltered"
                stacked="md"
                bordered
                hover
                show-empty
                small
                striped>
                <template #cell(index)="data">
                  {{ (table.currentPage*table.perPage)-table.perPage + data.index + 1 }}
                </template>
                <template #cell(action)="data">
                  <b-button
                    @click="viewModal(data.item.id, data.item.object_id_jenis)"
                    variant=" mr-1 mb-1" size="lg">
                    <i class="fas fa-eye text-success"></i>
                  </b-button>
                  <b-button
                    @click="sendData(data.item.keyid, data.item.object_id_jenis, data.item.object_id)"
                    variant=" mr-1 mb-1" size="lg">
                    <i class="fas fa-paper-plane text-warning"></i>
                  </b-button>
                </template>
                <template #cell(created_at)="data">
                  <span>{{ new Date(data.item.created_at) | formatDate }}</span>
                </template>
              </b-table>
            </div>
            <div class="pagination-brand border shadow-sm p-2 mb-2">
              <b-row class="align-items-center">
                <b-col cols="4" class="d-flex flex-row align-items-center">
                  <div class="w-100">
                    <b-pagination
                      v-model="table.currentPage"
                      :total-rows="table.totalRows"
                      :per-page="table.perPage"
                      size="md"
                      class="my-0"
                    ></b-pagination>
                  </div>
                  <div class="w-100">
                    <div>
                      <span class="border-left border-dark mr-3 py-1"></span>
                      <span @click="tableRefresh" class="katalog-cursor">
                        <i class="fas fa-sync text-secondary"></i>
                      </span>
                      <span class="border-right border-dark ml-3 py-1"></span>
                    </div>
                  </div>
                </b-col>
                <b-col cols="5">
                  <div class="d-flex flex-row justify-content-end align-items-center">
                    <div class="mr-1 w-75">
                      <form class="searchbox mb-0">
                        <input v-model="table.filter" type="search" class="form-control cstm-srch-brand" id="filter-input"
                          placeholder="[Cari]">
                      </form>
                    </div>
                    <div>
                      <b-form-select class="cstm-slct-brand" v-model="selected.filterType" :options="combo.filterType" @change="filterchange"></b-form-select>
                    </div>
                  </div>
                </b-col>
                <b-col cols="3">
                  <div class="d-flex flex-row align-items-center justify-content-end">
                    <div class="mr-3">
                      <b-form-select
                        class="cstm-select-brand"
                        id="per-page-select"
                        v-model="table.perPage"
                        :options="table.pageOptions"
                      ></b-form-select>
                    </div>
                    <div>
                      <p class="text-dark mb-0">Menampilkan {{ table.perPage }} dari {{ table.totalRows }} baris</p>
                    </div>
                  </div>
                </b-col>
              </b-row>
            </div>
          </template>
        </iq-card>
      </div>
    </b-container>
    <b-modal id="modal-reprocess" ref="modal-view" size="lg" title="Detail Log" @ok="sendData(viewHDR.keyid, viewHDR.object_id_jenis, viewHDR.object_id )" ok-title="retry" cancel-title="Kembali" :ok-only=false :no-close-on-backdrop="true" :static="true" centered>
      <div class="mb-3">
        <p class="text-dark mb-0">KEY ID: <span class="font-weight-bold"></span>{{ viewHDR.keyid }}</p>
        <p class="text-dark mb-0">Object ID: <span class="font-weight-bold"></span>{{ viewHDR.object_id }}</p>
        <p class="text-dark mb-0">Jenis: <span class="font-weight-bold"></span>{{ viewHDR.object_id_jenis }}</p>
        <p class="text-dark mb-0">Deskripsi: <span class="font-weight-bold"></span>{{ viewHDR.deskripsi }}</p>
        <p class="text-dark mb-0">Deskripsi Detail: <span class="font-weight-bold"></span>{{ viewHDR.deskripsi2 }}</p>
        <p class="text-dark mb-0">Keterangan: <span class="font-weight-bold"></span>{{ viewHDR.keterangan }}</p>
      </div>
      <b-table
        class="mb-0"
        ref="dtltable"
        :busy="dtltable.isBusy"
        :items="viewDTL"
        :fields="dtltable.fields"
        :current-page="dtltable.currentPage"
        :per-page="dtltable.perPage"
        stacked="md"
        bordered
        hover
        show-empty
        small
        striped>
        <template #cell(index)="data">
          {{ (table.currentPage*table.perPage)-table.perPage + data.index + 1 }}
        </template>
        <template #cell(created_at)="data">
          <span>{{ new Date(data.item.created_at) | formatDate }}</span>
        </template>
      </b-table>
    </b-modal>
    <ModalMsgBox ref="MsgBox" />
  </div>
</template>
<script>
import { xray } from '../../config/pluginInit'
import ModalMsgBox from '../../components/modal/ModalMsgBox'
import Service from '../../services/fatmahost/index'

export default {
  name: 'Log',
  components: {
    ModalMsgBox
  },
  data () {
    return {
      table: {
        isBusy: false,
        items: this.list,
        fields: [
          { label: 'No', key: 'index' },
          { label: 'KEY ID', key: 'keyid' },
          { label: 'Jenis', key: 'object_id_jenis' },
          { label: 'Deskripsi', key: 'deskripsi' },
          { label: 'Tanggal', key: 'created_at' },
          { label: 'Aksi', key: 'action', class: 'text-center' }
        ],
        filter: null,
        currentPage: null,
        perPage: null,
        pageOptions: [10, 25, 50, 100],
        totalRows: null
      },
      dtltable: {
        isBusy: false,
        fields: [
          { label: 'No', key: 'index' },
          { label: 'Object ID', key: 'object_id' },
          { label: 'Keterangan', key: 'keterangan' },
          { label: 'Deskripsi', key: 'deskripsi' },
          { label: 'Key ID', key: 'keyid' },
          { label: 'Jenis Object ID', key: 'object_id_jenis' }
        ],
        currentPage: null,
        perPage: null,
        pageOptions: [10, 25, 50, 100],
        totalRows: null,
        items: []
      },
      viewHDR: [],
      viewDTL: [],
      selected: {
        filterType: '1'
      },
      combo: {
        filterType: [
          { value: '1', text: 'Jenis' },
          { value: '2', text: 'KEY ID' }
        ]
      }
    }
  },
  mounted () {
    xray.index()
  },
  methods: {
    tableRefresh () {
      this.$refs.table.refresh()
    },
    async list (ctx) {
      let filterType = this.selected.filterType
      let filter = ''
      if (ctx.filter === '') {
        ctx.filter = null
        filter = `{"xparam0":"","xparam1":""}`
      }
      if (ctx.filter === null) {
        filter = `{"xparam0":"","xparam1":""}`
      } else {
        if (filterType === '1') {
          filter = `{"xparam0":"${ctx.filter}","xparam1":""}`
        } else if (filterType === '2') {
          filter = `{"xparam0":"","xparam1":"${ctx.filter}"}`
        }
      }
      this.isBusy = true
      try {
        const response = await Service.log.findAll(ctx.currentPage - 1, ctx.perPage, filter)
        this.table.totalRows = response.data.pagination.total
        this.table.currentPage = response.data.pagination.current
        this.table.perPage = response.data.pagination.perPage
        this.isBusy = false
        return response.data.data
      } catch (error) {
        this.isBusy = false
        return []
      }
    },
    onFiltered (filteredItems) {
      this.table.totalRows = filteredItems.length
      this.table.currentPage = 1
    },
    filterchange () {
      this.table.filter = ''
    },
    async viewModal (id, key) {
      const response = await Service.log.findId(id, key)
      this.viewHDR = response.data.data[0].HDR[0]
      this.viewDTL = response.data.data[1].DTL
      this.$refs['modal-view'].show()
    },
    async sendData (keyid, object, objectid) {
      const data = {
        keyid: keyid,
        object: object,
        object_id: objectid
      }
      await Service.log.create(data).then(response => {
        this.$refs.MsgBox.show({
          type: 'success',
          msg: response.data.message
        })
      }).catch(error => {
        this.$refs.table.refresh()
        this.$refs.MsgBox.show({
          type: 'error',
          msg: error.response.data.errors.message
        })
      })
    }
  }
}
</script>
<style>
#modal-tambah-brand .custom-control-label, #modal-edit-brand .custom-control-label {
    font-size: 12px;
    margin-top: 0;
}
#modal-tambah-brand .multiselect__single, #modal-edit-brand .multiselect__single {
  font-size: 12px!important;
}
#modal-tambah-brand .multiselect__placeholder, #modal-edit-brand .multiselect__placeholder {
  font-size: 12px!important;
}
#modal-tambah-brand .multiselect__option, #modal-edit-brand .multiselect__option {
    font-size: 12px;
}
#modal-tambah-brand .multiselect__tags, #modal-edit-brand .multiselect__tags {
  padding: 5px 40px 0 8px!important;
  height: 38px!important;
  min-height: 38px!important;
}
#katalog-brand .page-link {
  font-size: 14px;
  padding-top: 5px;
  padding-bottom: 5px;
  margin-right: 10px!important;
}
#katalog-brand .custom-button-radius {
  border: 1px solid rgba(0, 0, 0, 0.112);
  border-radius: 5px!important;
}
#katalog-brand .table td {
    font-size: 12px;
    font-weight: 500;
    padding-top: 0px;
    padding-bottom: 0px;
}
#katalog-brand .table th {
    font-size: 13px;
    font-weight: 400;
}
#katalog-brand .cstm-select-brand {
  font-size: 13px;
  height: 38px!important;
  border-radius: 5px;
}

#katalog-brand .cstm-srch-brand {
  font-size: 13px;
  height: 38px!important;
  border-radius: 5px;
}
#katalog-brand .cstm-slct-brand {
  font-size: 13px;
  height: 38px!important;
  border-radius: 5px;
}

.cstm-cntrl-katalog {
  color: black!important;
  font-weight: bold!important;
  border-radius: 5px!important;
  font-size: 12px!important;
  height: 38px!important;
}
.katalog-form-area {
  color: black!important;
  font-weight: bold!important;
  border-radius: 5px!important;
  font-size: 12px!important;
}
#katalog-brand .pagination-sm .page-link {
  color: gray!important;
}
#katalog-brand .page-item.active .page-link {
  color: white!important;
}
#katalog-brand .page-item.active .page-link {
  color: white!important;
}
.custom-radius-add-katalog {
  border-radius: 5px;
  cursor: pointer;
}
.katalog-select-flex-left {
  width: 90%;
}
.katalog-select-flex-right {
  width: 10%;
}
.katalog-form-satuan-kemasan-left-right {
  width: 40%;
}
.katalog-form-satuan-kemasan-center {
  width: 15%;
}
.hr-katalog-custom {
  border-top: 1px solid rgba(0, 0, 0, 0.199)!important;
  margin: 0!important;
}
#katalog-brand .pagination-brand .page-link {
    border: none;
    color: gray!important;
    margin-right: 15px;
}
#katalog-brand .pagination-brand .page-item.active .page-link {
    background-color: transparent;
    border-color: transparent;
    color: #089bab!important;
    font-weight: bold;
}
#modal-reprocess .modal-dialog {
    max-width: 100%;
    margin: 0;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100vh;
    display: flex;
    position: fixed;
    z-index: 100000;
}
</style>
