<template>
  <b-container fluid>
    <b-row>
      <b-col md="12">
        <iq-card class="p-4">
          <b-row class="mb-3">
            <b-col md="3" class="d-flex align-items-center justify-content-end">
              <div class="text-right">
                <p class="h6 font-weight-light mb-0">Nomor Pendaftaran :</p>
              </div>
            </b-col>
            <b-col md="9">
              <div>
                <b-form-input type="search" v-model="filter" class="form-control" placeholder="Ketik Nomor Pendaftaran">
                </b-form-input>
              </div>
            </b-col>
          </b-row>
          <b-row class="mb-3">
            <b-col md="3" class="d-flex align-items-center justify-content-end">
              <div class="text-right">
                <p class="h6 font-weight-light mb-0">Nomor RM :</p>
              </div>
            </b-col>
            <b-col md="9">
              <div>
                <b-form-input type="search" v-model="filter" class="form-control" placeholder="Ketik Nomor RM">
                </b-form-input>
              </div>
            </b-col>
          </b-row>
          <b-row class="mb-3">
            <b-col md="3" class="d-flex align-items-center justify-content-end">
              <div class="text-right">
                <p class="h6 font-weight-light mb-0">Nomor Kartu :</p>
              </div>
            </b-col>
            <b-col md="9">
              <div>
                <b-form-input type="search" v-model="filter" class="form-control" placeholder="Ketik Nomor Kartu">
                </b-form-input>
              </div>
            </b-col>
          </b-row>
          <b-row class="mb-3">
            <b-col md="3" class="d-flex align-items-center justify-content-end">
              <div class="text-right">
                <p class="h6 font-weight-light mb-0">Nomor Surat Elegibilitas Pasien :</p>
              </div>
            </b-col>
            <b-col md="9">
              <div>
                <b-form-input type="search" v-model="filter" class="form-control"
                  placeholder="Ketik Nomor Surat Elegibilitas Pasien"></b-form-input>
              </div>
            </b-col>
          </b-row>
          <b-row class="mb-3 justify-content-end">
            <b-col md="9">
              <div>
                <b-button variant="success" class="mr-1"><i class="fas fa-filter"></i> Filter</b-button>
                <b-button variant="warning"><i class="fas fa-sync"></i> Bersihkan Filter</b-button>
              </div>
            </b-col>
          </b-row>
        </iq-card>
        <iq-card class="py-4 px-4">
          <template>
            <div class="pb-4">
              <b-row>
                <b-col md="3" class="d-flex align-items-center text-left">
                  <h4 class="card-title mb-0">Pembentukan E-Klaim</h4>
                </b-col>
                <b-col md="6" class="text-center">
                  <form class="searchbox">
                    <input type="search" v-model="filter" class="form-control" id="filter-input"
                      placeholder="Cari NIP / Nama Pegawai">
                  </form>
                </b-col>
                <b-col md="3" class="text-right d-flex align-items-center justify-content-end">
                  <div>
                    <b-button variant="primary" @click="tambah"><i class="fas fa-plus"></i> Buat Pembentukan E-Klaim</b-button>
                  </div>
                </b-col>
              </b-row>
            </div>
          </template>
          <template>
            <b-row>
              <b-col md="12">
                <b-table outlined hover :busy="isBusy" :items="listPegawai" :fields="fields" :current-page="currentPage"
                  :per-page="perPage" :filter="filter" stacked="md" show-empty @filtered="onFiltered">
                  <template v-slot:cell(tempat_lahir)="data">
                    <span>{{ new Date(data.item.tanggal_lahir) | formatDate }}</span>
                  </template>

                  <template v-slot:cell(jenis_kelamin)="data">
                    <span>{{ data.item.jenis_kelamin == 1 ? 'Laki - laki' : 'Perempuan' }}</span>
                  </template>

                  <template v-slot:cell(agama0)="data">
                    <div v-if="data.item.agama === 4" style="font-size:24px">
                      <i class="ri-checkbox-circle-fill text-success"></i>
                    </div>
                    <div v-else-if="data.item.agama === 3" style="font-size:24px">
                      <i class="ri-error-warning-fill text-warning"></i>
                    </div>
                    <div v-else-if="data.item.agama === 1" style="font-size:24px">
                      <i class="ri-close-circle-fill text-danger"></i>
                    </div>
                    <div v-else style="font-size:24px">
                      <i class="ri-error-warning-fill text-info"></i>
                    </div>
                  </template>

                  <template v-slot:cell(agama1)="data">
                    <div v-if="data.item.agama === 1" style="font-size:24px">
                      <i class="ri-checkbox-circle-fill text-success"></i>
                    </div>
                    <div v-else-if="data.item.agama === 2" style="font-size:24px">
                      <i class="ri-error-warning-fill text-warning"></i>
                    </div>
                    <div v-else-if="data.item.agama === 3" style="font-size:24px">
                      <i class="ri-close-circle-fill text-danger"></i>
                    </div>
                    <div v-else style="font-size:24px">
                      <i class="ri-error-warning-fill text-info"></i>
                    </div>
                  </template>

                  <template v-slot:cell(agama2)="data">
                    <div v-if="data.item.agama === 4" style="font-size:24px">
                      <i class="ri-checkbox-circle-fill text-success"></i>
                    </div>
                    <div v-else-if="data.item.agama === 3"  style="font-size:24px">
                      <i class="ri-error-warning-fill text-warning"></i>
                    </div>
                    <div v-else-if="data.item.agama === 2"  style="font-size:24px">
                      <i class="ri-close-circle-fill text-danger"></i>
                    </div>
                    <div v-else style="font-size:24px">
                      <i class="ri-error-warning-fill text-info"></i>
                    </div>
                  </template>

                  <template v-slot:cell(agama3)="data">
                    <div v-if="data.item.agama === 3" style="font-size:24px">
                      <i class="ri-checkbox-circle-fill text-success"></i>
                    </div>
                    <div v-else-if="data.item.agama === 1"  style="font-size:24px">
                      <i class="ri-error-warning-fill text-warning"></i>
                    </div>
                    <div v-else-if="data.item.agama === 2"  style="font-size:24px">
                      <i class="ri-close-circle-fill text-danger"></i>
                    </div>
                    <div v-else style="font-size:24px">
                      <i class="ri-error-warning-fill text-info"></i>
                    </div>
                  </template>

                  <template v-slot:cell(agama4)="data">
                    <div v-if="data.item.agama === 2" style="font-size:24px">
                      <i class="ri-checkbox-circle-fill text-success"></i>
                    </div>
                    <div v-else-if="data.item.agama === 4"  style="font-size:24px">
                      <i class="ri-error-warning-fill text-warning"></i>
                    </div>
                    <div v-else-if="data.item.agama === 3"  style="font-size:24px">
                      <i class="ri-close-circle-fill text-danger"></i>
                    </div>
                    <div v-else style="font-size:24px">
                      <i class="ri-error-warning-fill text-info"></i>
                    </div>
                  </template>

                  <template v-slot:cell(action)="data">
                    <b-button variant=" iq-bg-success mr-1 mb-1" size="lg" @click="editPegawai(data.item.id)"><i class="ri-ball-pen-fill m-0"></i></b-button>
                  </template>

                </b-table>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
              </b-col>
              <b-col>
              </b-col>
              <b-col sm="12" md="3" class=" my-1">
                <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage" align="fill" size="sm"
                  class="my-0"></b-pagination>
              </b-col>
            </b-row>
          </template>
        </iq-card>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { xray } from '../../config/pluginInit'
import Service from '../../services/fatmahost/index'
import 'sweetalert2/src/sweetalert2.scss'

export default {
  name: 'UiDataTable',
  mounted () {
    xray.index()
  },
  methods: {
    default () {
      return {
        errors: [],
        id: this.items.length,
        nip: '',
        nama: '',
        tempat_lahir: '',
        tanggal_lahir: '',
        jenis_kelamin: '',
        alamat: ''
      }
    },
    async listPegawai (ctx) {
      this.isBusy = true
      try {
        const response = await Service.pegawai.findAll(ctx.currentPage - 1, ctx.perPage, ctx.filter)
        this.totalRows = response.data.total
        this.isBusy = false
        return response.data.data
      } catch (err) {
        this.isBusy = false
        return []
      }
    },
    viewPegawai (id) {
      this.$router.push('view/' + id)
    },
    editPegawai (id) {
      this.$router.push('edit/' + id)
    },
    tambah () {
      this.$router.push({ name: 'eklaim.add' })
    },
    onFiltered (filteredItems) {
      this.totalRows = filteredItems.length
      this.currentPage = 1
    }
  },
  data () {
    return {
      isBusy: false,
      error: [],
      fields: [
        { label: 'No', key: 'id', class: 'text-center' },
        { label: 'Nama Pasien', key: 'nama', class: 'text-left' },
        { label: 'Nomor Pendaftaran', key: 'nip', class: 'text-left' },
        // { label: 'Tanggal Masuk', key: 'tempat_lahir', class: 'text-left' },
        // { label: 'Tanggal Keluar', key: 'update_at', class: 'text-left' },
        { label: 'Nomor RM', key: 'rw', class: 'text-left' },
        { label: 'Nomor Kartu', key: 'rt', class: 'text-left' },
        // { label: 'Nomor Surat Elegibilitas Pasien', key: 'wilayah', class: 'text-left' },
        // { label: 'Pelayanan', key: 'smf', class: 'text-left' },
        { label: 'Tanggal Input', key: 'tempat_lahir', class: 'text-left' },
        { label: 'Import', key: 'agama0', class: 'text-left' },
        { label: 'Grouper1', key: 'agama1', class: 'text-left' },
        { label: 'Grouper2', key: 'agama2', class: 'text-left' },
        { label: 'Final', key: 'agama3', class: 'text-left' },
        { label: 'Klaim', key: 'agama4', class: 'text-left' },
        // { label: 'Profesi', key: 'PROFESI', class: 'text-left' },
        { label: 'Action', key: 'action', class: 'text-center  col-sm-1' }
      ],
      items: [],
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      filterOn: [],
      filter: null
    }
  }
}
</script>
