<template>
  <div id="kuota-dokter" class="kuota-dokter">
    <b-container fluid class="mb-5">
      <iq-card class="mb-2">
        <div  class="iq-email-to-list pt-3 pl-3 pr-3" id="header-table-filter">
          <div class="d-flex flex-row align-items-center mb-0 py-1 px-1 bg-filter flex-wrap">
            <div cols="2" class="mr-1">
              <b-form-group class="mb-0">
                <b-form-select class="cstm-cntrl-kuota" style="font-size: 13px;" plain v-model="selected.filterRuang" @change="valFilterRuang" :options="combo.filterRuang" size="lg">
                </b-form-select>
              </b-form-group>
              <!-- <b-form-select class="cstm-slct-kuota" v-model="selected.filterRuang" @change="valFilterRuang" :options="combo.filterRuang"></b-form-select> -->
            </div>
            <div cols="2" class="mr-1">
              <b-form-group class="mb-0">
                <b-form-select class="cstm-cntrl-kuota" style="font-size: 13px;" plain v-model="selected.filterType" :options="combo.filterType" size="lg">
                </b-form-select>
              </b-form-group>
              <!-- <b-form-select class="cstm-slct-kuota" v-model="selected.filterType" :options="combo.filterType"></b-form-select> -->
            </div>
            <div class="mr-1">
              <template>
                <flat-pickr :config="configTgl" @on-change="filterTglAwal" v-model="display.viewTglAwal" placeholder="Tgl Awal" class="w-100 custom-flatpicker calender-small" />
              </template>
            </div>
            <div class="mr-1">
              <template>
                <flat-pickr :config="configTgl" @on-change="filterTglAkhir" v-model="display.viewTglAkhir" placeholder="Tgl Akhir" class="w-100 custom-flatpicker calender-small" />
              </template>
            </div>
            <div cols="2" class="mr-1">
              <b-input-group>
                <b-form-input class="cstm-cntrl-kuota" style="font-size: 13px;" id="filter-input" v-model="table.filter" @keypress="isAlphabetKey" type="search" placeholder="[ Pencarian ]"></b-form-input>
              </b-input-group>
            </div>
            <div class="mr-lg-auto">
                <b-button v-b-tooltip.top title="Reset" size="sm" variant="warning" class="border-0 rounded-1 py-2" style="border-radius: 5px;" @click="filterBtn"><i class="fas fa-undo"></i> Reset Filter</b-button>
            </div>
            <div class="mt-1 mt-lg-0 ml-1 ml-lg-0">
              <b-button variant="primary" class="py-2" style="border-radius: 5px;"  @click="handleNewKuota"><i class="fas fa-plus-circle "></i>Tambah</b-button>
              <!-- @click="tambah" -->
            </div>
          </div>
        </div>
          <div class="iq-email-listbox pl-3 pr-3 pb-3">
            <div class="table mb-0">
              <b-table
                class="mb-0 pointer-table"
                ref="table"
                :busy="table.isBusy"
                :items="table.items"
                :fields="table.fields"
                :current-page="table.currentPage"
                :per-page="table.perPage"
                :filter="table.filter"
                @filtered="onFiltered"
                @row-clicked="handleClick"
                stacked="md"
                bordered hover show-empty small striped>
                <template #thead-top="">
                  <b-tr>
                    <b-th colspan="6" class="text-center"><strong>Jadwal & Kuota Dokter</strong></b-th>
                    <b-th colspan="3" class="text-center" variant="success"><span>Kuota Online</span></b-th>
                    <b-th colspan="3" class="text-center" variant="danger"><span>Kuota Offline</span></b-th>
                    <b-th></b-th>
                  </b-tr>
                </template>
                <template #cell(index)="data">
                  {{ (table.currentPage*table.perPage)-table.perPage + data.index + 1 }}
                </template>
                <template #cell(instalasi)="data">
                  <span>{{ data.item.instalasi }}</span>
                </template>
                <template #cell(nama_ruangan)="data">
                  <span>{{ data.item.nama_ruangan }}</span>
                </template>
                <template #cell(nama_dokter)="data">
                  <span>{{ data.item.nama_dokter }}</span>
                </template>
                <template #cell(start)="data">
                  <span>{{ data.item.start }} - {{ data.item.end }}</span>
                </template>
                <template #cell(tanggal)="data">
                  <span>{{ new Date(data.item.tanggal) | formatDate }}</span>
                </template>
                <template #cell(jumlah_on)="data">
                  <span>{{ data.item.jumlah_on }}</span>
                </template>
                <template #cell(keluar_on)="data">
                  <span>{{ data.item.keluar_on }}</span>
                </template>
                <template #cell(sisa_on)="data">
                  <span>{{ data.item.sisa_on }}</span>
                </template>
                <template #cell(jumlah_off)="data">
                  <span>{{ data.item.jumlah_off }}</span>
                </template>
                <template #cell(keluar_off)="data">
                  <span>{{ data.item.keluar_off }}</span>
                </template>
                <template #cell(sisa_off)="data">
                  <span>{{ data.item.sisa_off }}</span>
                </template>
                <template #cell(action)="data">
                  <b-button
                    @click="handleClick(data.item)"
                    variant=" mr-1 mb-1" size="lg">
                    <i class="fas fa-edit text-primary"></i>
                  </b-button>
                  <b-button
                    @click="deleteModal(data.item.id)"
                    variant=" mr-1 mb-1" size="lg">
                    <i class="fas fa-trash-alt text-danger"></i>
                  </b-button>
                </template>
              </b-table>
            </div>
            <div class="bg-white border pb-2 pt-2 pl-3 pr-3">
              <b-row class="align-items-center justify-content-between">
                <b-col cols="12" lg="3" class="mr-auto">
                  <div class="d-flex align-items-center">
                    <div>
                      <b-pagination v-model="table.currentPage" :total-rows="table.totalRows" :per-page="table.perPage" size="sm" class="my-0">
                      </b-pagination>
                    </div>
                    <div class="border-left border-right px-3">
                      <span class="cursor-point" @click="tableRefresh">
                        <i class="fas fa-sync text-primary"></i>
                      </span>
                    </div>
                  </div>
                </b-col>
                <b-col cols="12" lg="4" class="d-flex justify-content-end align-items-center">
                  <div class="mr-3">
                    <b-form-select id="per-page-select" v-model="table.perPage" :options="table.pageOptions" size="sm"
                    ></b-form-select>
                  </div>
                  <div>
                    <p class="font-weight-normal mb-0 text-dark" v-if="table.totalRows">Menampilkan 1 -  {{ table.perPage }} dari {{ table.totalRows }}</p>
                  </div>
                </b-col>
              </b-row>
            </div>
          </div>
          <!-- <div class="bg-white pb-2 pt-2 pl-3 pr-3">
            <b-row class="align-items-center justify-content-between">
              <b-col cols="12" lg="3" class="mr-auto">
                <div class="d-flex align-items-center">
                  <div>
                    <b-pagination v-model="table.currentPage" :total-rows="table.totalRows" :per-page="table.perPage" align="" size="md" class="my-0">
                    </b-pagination>
                  </div>
                  <div class="border-left border-right px-3">
                    <span class="cursor-point" @click="tableRefresh">
                      <i class="fas fa-sync"></i>
                    </span>
                  </div>
                </div>
              </b-col>
              <b-col cols="12" lg="5">
                <div class="d-flex flex-row justify-content-end">
                  <div class="mr-1">
                    <span class="text-right float-right">
                      <b-form-select class="cstm-slct-kuota" v-model="selected.filterRuang" @change="valFilterRuang" :options="combo.filterRuang"></b-form-select>
                    </span>
                  </div>
                  <div class="mr-1">
                    <span class="text-right float-right">
                      <b-button variant="white" class="border py-2 rounded-0 text-right" @click="filterAdvancedModal"><i class="fas fa-filter"></i> Pencarian Lanjutan</b-button>
                    </span>
                  </div>
                  <div>
                    <b-form-select
                      class="cstm-slct-kuota"
                      id="per-page-select"
                      v-model="table.perPage"
                      :options="table.pageOptions"
                    ></b-form-select>
                  </div>
                </div>
              </b-col>
            </b-row>
          </div> -->
      </iq-card>
    </b-container>
    <b-modal id="modal-kuota-add" ref="modal-new-kuota" size="xl" title="Tambah Kuota Dokter" cancel-title="Tutup" :no-close-on-backdrop="true" :static="true"
    @close="clearData" @cancel="clearData" cancel-variant="danger" @ok="handleSubmit" ok-title="Simpan" :hide-header=false centered>
      <div class="mb-1">
        <b-row class="mb-2">
          <!-- <b-col cols="2">
            <p class="text-dark small mb-0">Instalasi/Ruangan/Dokter</p>
          </b-col> -->
          <b-col>
            <b-row>
              <b-col class="pr-1" cols="3">
                <b-form-group label="Instalasi" class="mb-0">
                  <multiselect v-model="selected.instalasi" :show-labels="false" :limit="3" @input="getunit"
                    label="deskripsi" placeholder="[ Instalasi ]" :options="combo.instalasi" :searchable="true">
                  </multiselect>
                </b-form-group>
              </b-col>
              <b-col class="pl-1 pr-1" cols="3">
                <b-form-group label="Unit" class="mb-0">
                  <multiselect v-model="selected.unit" :show-labels="false" :limit="3" @input="getruangan"
                    label="deskripsi" placeholder="[ Unit ]" :options="combo.unit" :searchable="true">
                  </multiselect>
                </b-form-group>
              </b-col>
              <b-col class="pr-1 pl-1" cols="3">
                <b-form-group label="Poli" class="mb-0">
                  <multiselect v-model="selected.ruangan" :show-labels="false" :limit="3"
                    @input="getdokter" label="deskripsi" placeholder="[ Poli ]" :options="combo.ruangan"
                    :searchable="true">
                  </multiselect>
                </b-form-group>
              </b-col>
              <b-col cols="3" class="pl-1">
                <b-form-group label="Dokter" class="mb-0">
                  <multiselect v-model="selected.dokter" :show-labels="false" :limit="3"
                    @input="valDokter" label="deskripsi" placeholder="[ Dokter ]" :options="combo.dokter"
                    :searchable="true">
                  </multiselect>
                </b-form-group>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
        <b-row class="mb-2">
          <!-- <b-col cols="2">
            <p class="text-dark small mb-0">Tanggal/Jam Mulai/Jam Akhir/Kuota Online/Kuota Offline</p>
          </b-col> -->
          <b-col>
            <b-row class="align-items-center">
              <b-col cols="3" class="pr-1">
                <b-form-group label="Tanggal" class="mb-0">
                  <template>
                    <flat-pickr :config="configTgl" @on-change="valTglTmbh" v-model="display.viewTgltmbh" placeholder="[ Tanggal Kuota ]" class="w-100 custom-flatpicker calender-small" />
                  </template>
                </b-form-group>
              </b-col>
              <b-col class="pr-1 pl-1">
                <b-form-group label="Jam Mulai" class="mb-0">
                  <b-form-input class="cstm-cntrl-kuota" v-model="kuota.start" type="time"></b-form-input>
                </b-form-group>
              </b-col>
              <b-col class="pl-1">
                <b-form-group label="Jam Akhir" class="mb-0">
                  <b-form-input class="cstm-cntrl-kuota" v-model="kuota.end" type="time" :disabled="valuedisable"></b-form-input>
                </b-form-group>
              </b-col>
              <b-col cols="2" class="pl-1">
                <b-form-group label="." class="mb-0">
                  <b-form-checkbox
                    id="checkbox-1"
                    name="checkbox-1"
                    v-model="status"
                    value="accepted"
                    unchecked-value="not_accepted"
                    @change="valSelesai"
                  >
                  Selesai
                  </b-form-checkbox>
                </b-form-group>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="3" class="pr-1">
            <b-form-group label="Kuota Online" class="mb-0">
              <b-form-input class="cstm-cntrl-kuota" v-model="kuota.jumlah_on" type="text" placeholder="[ Kuota Online ]"></b-form-input>
            </b-form-group>
          </b-col>
          <b-col cols="3" class="pl-1">
            <b-form-group label="Kuota Offline" class="mb-0">
              <b-form-input class="cstm-cntrl-kuota" v-model="kuota.jumlah_off" type="text" placeholder="[ Kuota Offline ]"></b-form-input>
            </b-form-group>
          </b-col>
        </b-row>
      </div>
      <!-- <pre>{{ kuota }}</pre> -->
    </b-modal>
    <b-modal id="modal-kuota-edit" ref="modal-edit-kuota" size="xl" title="Edit Kuota Dokter" :no-close-on-backdrop="true" :static="true"
      @ok="handleUpdate" @close="clearData" @cancel="clearData" cancel-title="Tutup" cancel-variant="danger"
      ok-title="Ubah" :hide-header=false centered>
      <div class="mb-1">
        <b-row class="mb-2">
          <b-col>
            <b-row>
              <b-col class="pr-1" cols="3">
                <b-form-group label="Instalasi" class="mb-0">
                  <multiselect v-model="selected.instalasi" :show-labels="false" :limit="3" @input="geteditunit"
                    label="deskripsi" placeholder="[ Instalasi ]" :options="combo.instalasi" :searchable="true">
                  </multiselect>
                </b-form-group>
              </b-col>
              <b-col class="pr-1 pl-1" cols="3">
                <b-form-group label="Unit" class="mb-0">
                  <multiselect v-model="selected.unit" :show-labels="false" :limit="3" @input="geteditruangan"
                    label="deskripsi" placeholder="[ Unit ]" :options="combo.unit" :searchable="true">
                  </multiselect>
                </b-form-group>
              </b-col>
              <b-col class="pr-1 pl-1" cols="3">
                <b-form-group label="Poli" class="mb-0">
                  <multiselect v-model="selected.ruangan" :show-labels="false" :limit="3"
                    @input="geteditdokter" label="deskripsi" placeholder="[ Poli ]" :options="combo.ruangan"
                    :searchable="true">
                  </multiselect>
                </b-form-group>
              </b-col>
              <b-col cols="3" class="pl-1">
                <b-form-group label="Dokter" class="mb-0">
                  <multiselect v-model="selected.dokter" :show-labels="false" :limit="3"
                    @input="valEditDokter" label="deskripsi" placeholder="[ Dokter ]" :options="combo.dokter"
                    :searchable="true">
                  </multiselect>
                </b-form-group>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
        <b-row class="mb-2">
          <b-col>
            <b-row class="align-items-center">
              <b-col cols="3" class="pr-1">
                <b-form-group label="Tanggal" class="mb-0">
                  <template>
                    <flat-pickr :config="configTgl" @on-change="valTglEdt" v-model="display.viewTglEdit" placeholder="[ Tanggal Kuota ]" class="w-100 custom-flatpicker calender-small" />
                  </template>
                </b-form-group>
              </b-col>
              <b-col class="pr-1 pl-1">
                <b-form-group label="Jam Mulai" class="mb-0">
                  <b-form-input class="cstm-cntrl-kuota" v-model="editKuota.start" type="time"></b-form-input>
                </b-form-group>
              </b-col>
              <b-col class="pr-1 pl-1">
                <b-form-group label="Jam Akhir" class="mb-0">
                  <b-form-input class="cstm-cntrl-kuota" v-model="editKuota.end" type="time" :disabled="valuedisable2"></b-form-input>
                </b-form-group>
              </b-col>
              <b-col cols="2" class="pl-1">
                <b-form-group label="." class="mb-0">
                  <b-form-checkbox
                    id="checkbox-2"
                    name="checkbox-2"
                    v-model="statusEdit"
                    value="accepted"
                    unchecked-value="not_accepted"
                    @change="valSelesaiEdit"
                  >
                  Selesai
                  </b-form-checkbox>
                </b-form-group>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
        <b-row class="mb-3">
          <b-col cols="3" class="pr-1">
            <b-form-group label="Kuota Online" class="mb-0">
              <b-form-input class="cstm-cntrl-kuota" v-model="editKuota.jumlah_on" type="text" placeholder="[ Kuota Online ]"></b-form-input>
            </b-form-group>
          </b-col>
          <b-col cols="3" class="pl-1">
            <b-form-group label="Kuota Offline" class="mb-0">
              <b-form-input class="cstm-cntrl-kuota" v-model="editKuota.jumlah_off" type="text" placeholder="[ Kuota Offline ]"></b-form-input>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row class="justify-content-center" v-if="display.kuotakeluaron > 0 || display.kuotakeluaroff > 0">
          <b-col cols="4">
            <div class="alert-radius-kuota shadow-sm bg-danger shadow-sm">
              <div class="px-3 py-1 d-flex flex-row align-items-center">
                <div class="mr-3">
                  <i class="fas fa-exclamation-circle fa-2x"></i>
                </div>
                <div>
                  <p class="font-weight-normal mb-0">Kuota Online sudah digunakan : <span class="font-weight-bold">{{ display.kuotakeluaron }}</span></p>
                  <p class="font-weight-normal mb-0">Kuota Offline sudah digunakan : <span class="font-weight-bold">{{ display.kuotakeluaroff }}</span></p>
                </div>
              </div>
            </div>
          </b-col>
        </b-row>
      </div>
    </b-modal>
    <b-modal ref="modal-konfirm-delete" size="md" title="PERINGATAN!" ok-variant="danger" ok-title="Hapus" @ok="handleDelete" :no-close-on-backdrop="true" :static="true"
    @close="clearData" @cancel="clearData" cancel-title="Batal" centered>
      <div class="text-center px-lg-4 px-2 py-2">
        <div>
          <i class="fas fa-exclamation-circle text-danger fa-5x"></i>
          <p class="h6 text-danger mb-0 mt-3">Anda Yakin Akan Menghapus Kuota ?</p>
        </div>
        <div class="mt-3 d-flex flex-row w-100 justify-content-center" v-if="display.kuotakeluaron > 0 || display.kuotakeluaroff > 0">
          <div class="alert-radius-kuota shadow-sm bg-danger shadow-sm">
            <div class="px-3 py-1 d-flex flex-row align-items-center">
              <div class="mr-3">
                <i class="fas fa-exclamation-circle fa-2x"></i>
              </div>
              <div>
                <p class="font-weight-normal mb-0">Kuota Online sudah digunakan : <span class="font-weight-bold">{{ display.kuotakeluaron }}</span></p>
                <p class="font-weight-normal mb-0">Kuota Offline sudah digunakan : <span class="font-weight-bold">{{ display.kuotakeluaroff }}</span></p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </b-modal>
    <b-modal ref="modal-sukses" size="md" title="Informasi" ok-title="Oke" :ok-only="true" :hide-header="true" centered>
      <div class="text-center px-lg-4 px-2">
        <lottie :options="defaultOptions" :autoPlay="true" :width="250" :height="250" />
        <p class="h6 text-success font-weight-bold mb-0">{{ message }}</p>
      </div>
    </b-modal>
    <b-modal ref="modal-gagal" size="md" title="Informasi" ok-title="Kembali" :ok-only="true" :hide-header="true" centered>
      <div class="text-center px-lg-4 px-2">
        <lottie :options="defaultOptions2" :autoPlay="true" :width="250" :height="250" />
        <p class="h6 text-danger font-weight-bold mb-2">Data Gagal Disimpan</p>
        <p class="small text-danger font-weight-light mb-0">{{error}}</p>
      </div>
    </b-modal>
    <b-modal id="modal-filter" ref="modal-filter" size="lg" title="Pencarian Lanjutan" ok-title="Tutup" :ok-only="true" :no-close-on-backdrop="true" :static="true" centered>
      <div class="px-lg-4 px-2">
        <b-row>
          <b-col cols="6" class="pr-1 pl-1 mb-2">
            <span class="text-dark"><i class="fas fa-calendar-alt"></i> Tanggal Awal</span>
            <template>
              <flat-pickr :config="configTgl" @on-change="filterTglAwal" v-model="display.viewTglAwal" placeholder="Tanggal Awal" class="w-100 custom-flatpicker calender-small" />
            </template>
            <small class="mb-0 text-danger">{{ messageTgl }}</small>
          </b-col>
          <b-col cols="6" class="pr-1 pl-1 mb-2">
            <span class="text-dark"><i class="fas fa-calendar-alt"></i> Tanggal Akhir</span>
            <template>
              <flat-pickr :config="configTgl" @on-change="filterTglAkhir" v-model="display.viewTglAkhir" placeholder="Tanggal Akhir" class="w-100 custom-flatpicker calender-small" />
            </template>
          </b-col>
          <!-- <b-col cols="6" class="pr-1 pl-1 mb-2">
            <span class="text-dark"><i class="fas fa-building"></i> Instalasi</span>
            <b-form-select class="cstm-slct-kuota" v-model="selected.filterRuang" @change="valFilterRuang" :options="combo.filterRuang"></b-form-select>
          </b-col> -->
          <b-col cols="6" class="pr-1 pl-1 mb-2">
            <span class="text-dark"><i class="fas fa-compress-arrows-alt"></i> Jenis Pencarian</span>
            <b-form-select class="cstm-slct-kuota" v-model="selected.filterType" :options="combo.filterType"></b-form-select>
          </b-col>
          <b-col cols="6" class="pr-1 pl-1">
            <span class="text-dark"><i class="fas fa-search"></i> Pencarian</span>
            <b-form-input id="filter-input" class="custom-search-kuota rounded-0" v-model="table.filter" @keypress="isAlphabetKey" type="search" placeholder="Pencarian"></b-form-input>
          </b-col>
        </b-row>
        <div class="text-center mt-3">
          <b-button variant="warning" class="text-center rounded-0" @click="filterBtn"> Reset Pencarian</b-button>
        </div>
        <!-- <pre>{{ selected }}</pre> <br>
        <pre>{{ filtering }}</pre> -->
      </div>
    </b-modal>
    <ModalMsgbox ref="MsgBox" />
  </div>
</template>
<script>
import { xray } from '../../../config/pluginInit'
import Lottie from 'vue-lottie'
import animationLogo from '../../../assets/lottie/checked.json'
import animationLogo2 from '../../../assets/lottie/failed.json'
import Service from '../../../services/fatmahost/index'
import Multiselect from 'vue-multiselect'
import flatPickr from 'vue-flatpickr-component'
import { Indonesian as IndonesianLocale } from 'flatpickr/dist/l10n/id.js'
import 'flatpickr/dist/flatpickr.css'
import ModalMsgbox from '../../../components/modal/ModalMsgBox'
// import kuota from '../../../services/kuota'
// import { mapGetters } from 'vuex'
export default {
  name: 'Kuotadokter',
  components: {
    ModalMsgbox,
    Multiselect,
    Lottie,
    flatPickr
  },
  data () {
    return {
      table: {
        isBusy: false,
        items: this.list,
        fields: [
          { label: 'No', key: 'index', class: 'text-center' },
          { label: 'Instalasi', key: 'instalasi' },
          { label: 'Ruangan', key: 'nama_ruangan' },
          { label: 'Dokter', key: 'nama_dokter' },
          { label: 'Tanggal', key: 'tanggal' },
          { label: 'Jam', key: 'start' },
          { label: 'Jumlah', class: 'text-center', variant: 'success', key: 'jumlah_on' },
          { label: 'Digunakan', class: 'text-center', variant: 'success', key: 'keluar_on' },
          { label: 'Sisa', class: 'text-center', variant: 'success', key: 'sisa_on' },
          { label: 'Jumlah', class: 'text-center', variant: 'danger', key: 'jumlah_off' },
          { label: 'Digunakan', class: 'text-center', variant: 'danger', key: 'keluar_off' },
          { label: 'Sisa', class: 'text-center', variant: 'danger', key: 'sisa_off' },
          { label: 'Aksi', key: 'action', class: 'text-center' }
        ],
        filter: null,
        currentPage: null,
        perPage: 10,
        pageOptions: [10, 50, 100, 500],
        totalRows: null
      },
      combo: {
        instalasi: [],
        unit: [],
        ruangan: [],
        dokter: [],
        jenis: [
          { value: 1, text: 'Reservasi Online' },
          { value: 2, text: 'Reservasi Offline' }
        ],
        filterType: [
          { value: '1', text: 'Filter Dokter' },
          { value: '2', text: 'Filter Ruangan' }
        ],
        filterRuang: [
          { value: '0', text: 'Semua Instalasi' },
          { value: '10101', text: 'Rawat Jalan' },
          { value: '10127', text: 'Griya Husada' },
          { value: '10110', text: 'Rehabilitasi Medik' }
        ]
      },
      selected: {
        instalasi: [],
        unit: [],
        ruangan: [],
        dokter: [],
        filterType: '1',
        filterRuang: '0'
      },
      id: null,
      kuota: {
        object: 'dokter',
        ruangan: '',
        dokter: '',
        tanggal: '',
        jumlah_on: 0,
        jumlah_off: 0,
        jumlah_bridging: 0,
        start: '',
        end: ''
      },
      editKuota: {
        object: 'dokter',
        ruangan: '',
        dokter: '',
        tanggal: '',
        jumlah_on: 0,
        jumlah_off: 0,
        jumlah_bridging: 0,
        start: '',
        end: '',
        created_at: ''
      },
      display: {
        displayruangan: '',
        displaydokter: '',
        kuotakeluaron: '',
        kuotakeluaroff: '',
        viewTgltmbh: '',
        viewTglEdit: '',
        viewTglAwal: '',
        viewTglAkhir: ''
      },
      filtering: {
        valTglAwal: '',
        valTglAkhir: ''
      },
      status: 'not_accepted',
      statusEdit: 'not_accepted',
      valuedisable: false,
      valuedisable2: false,
      error: '',
      message: '',
      messageTgl: '',
      defaultOptions: { animationData: animationLogo, loop: true },
      defaultOptions2: { animationData: animationLogo2, loop: true },
      configTgl: {
        locale: IndonesianLocale,
        minDate: 'today',
        disableMobile: true,
        dateFormat: 'd-m-Y'
      }
    }
  },
  mounted () {
    xray.index()
    this.getinstalasi()
    this.list()
  },
  computed: {
  },
  methods: {
    filterchange () {
      this.table.filter = ''
    },
    async list (ctx) {
      if (ctx.filter === '') {
        ctx.filter = null
      }
      let filter = ''
      let filterRuang = this.selected.filterRuang ? this.selected.filterRuang : ''
      let filterType = this.selected.filterType ? this.selected.filterType : ''
      let tglAwl = this.filtering.valTglAwal ? this.filtering.valTglAwal : ''
      let tglAkhr = this.filtering.valTglAkhir ? this.filtering.valTglAkhir : ''
      if (tglAwl === 'NaN-NaN-NaN') {
        tglAwl = ''
      }
      if (tglAkhr === 'NaN-NaN-NaN') {
        tglAkhr = ''
      }
      if (ctx.filter === null) {
        filter = `{"xparam0":"","xparam1":"", "xparam2":"${filterRuang}", "xparam3":"${tglAwl}", "xparam4":"${tglAkhr}"}`
      } else {
        if (filterRuang === '0') {
          if (ctx.filter) {
            if (filterType === '1') {
              filter = `{"xparam0":"","xparam1":"${ctx.filter}", "xparam2":"", "xparam3":"${tglAwl}", "xparam4":"${tglAkhr}"}`
            } else if (filterType === '2') {
              filter = `{"xparam0":"${ctx.filter}","xparam1":"", "xparam2":"", "xparam3":"${tglAwl}", "xparam4":"${tglAkhr}"}`
            }
          } else {
            filter = `{"xparam0":"","xparam1":"", "xparam2":"", "xparam3":"${tglAwl}", "xparam4":"${tglAkhr}"}`
          }
        } else {
          if (ctx.filter) {
            if (filterType === '1') {
              filter = `{"xparam0":"","xparam1":"${ctx.filter}", "xparam2":"${filterRuang}", "xparam3":"${tglAwl}", "xparam4":"${tglAkhr}"}`
            } else if (filterType === '2') {
              filter = `{"xparam0":"${ctx.filter}","xparam1":"", "xparam2":"${filterRuang}", "xparam3":"${tglAwl}", "xparam4":"${tglAkhr}"}`
            }
          } else {
            filter = `{"xparam0":"","xparam1":"", "xparam2":"${filterRuang}", "xparam3":"${tglAwl}", "xparam4":"${tglAkhr}"}`
          }
        }
      }
      this.isBusy = true
      try {
        const response = await Service.master.kuota_dokter_findall(ctx.currentPage - 1, ctx.perPage, filter)
        this.isBusy = false
        this.table.totalRows = response.data.pagination.total
        this.table.perPage = response.data.pagination.perPage
        this.table.currentPage = response.data.pagination.current
        return response.data.data
      } catch (error) {
        this.isBusy = false
        return []
      }
    },
    async viewKuota (id) {
      await Service.master.kuota_dokter_findid(id).then(response => {
        if (response.status === 200) {
          this.id = response.data.data[0].id
          this.editKuota.tanggal = response.data.data[0].tanggal
          this.responViewTglEdt(response.data.data[0].tanggal.toString())
          this.editKuota.start = this.convertTimeToHourMinute(response.data.data[0].start)
          this.editKuota.end = response.data.data[0].end
          this.checkSelesai(response.data.data[0].end)
          this.editKuota.ruangan = response.data.data[0].ruangan
          this.geteditunit({ id: response.data.data[0].kd_instalasi })
          this.geteditruangan({ id: response.data.data[0].kd_unit })
          this.geteditdokter({ id: response.data.data[0].kd_ruangan })
          this.editKuota.dokter = response.data.data[0].dokter.toString()
          this.editKuota.jumlah_on = parseInt(response.data.data[0].jumlah_on)
          this.editKuota.jumlah_off = parseInt(response.data.data[0].jumlah_off)
          this.editKuota.jumlah_bridging = parseInt(response.data.data[0].jumlah_bridging)
          this.editKuota.created_at = response.data.data[0].created_at
          this.selected.instalasi = response.data.data[0].kd_instalasi ? { deskripsi: response.data.data[0].instalasi, id_referensi: response.data.data[0].kd_instalasi } : []
          this.selected.unit = response.data.data[0].kd_unit ? { deskripsi: response.data.data[0].unit, id_referensi: response.data.data[0].kd_unit } : []
          this.selected.ruangan = response.data.data[0].kd_ruangan ? { deskripsi: response.data.data[0].ruangan, id_referensi: response.data.data[0].kd_ruangan } : []
          this.selected.dokter = response.data.data[0].dokter ? { deskripsi: response.data.data[0].nama_dokter, id_referensi: response.data.data[0].dokter } : []
          this.display.kuotakeluaron = response.data.data[0].keluar_on
          this.display.kuotakeluaroff = response.data.data[0].keluar_off
        }
      }).catch(error => {
        this.error = error.response
      })
    },
    convertTimeToHourMinute (timeString) {
      const timeParts = timeString.split(':')
      const hour = parseInt(timeParts[0])
      const minute = parseInt(timeParts[1])

      const formattedTime = `${hour.toString().padStart(2, '0')}:${minute.toString().padStart(2, '0')}`

      return formattedTime
    },
    handleClick (item) {
      const id = item.id
      this.$refs['modal-edit-kuota'].show()
      this.viewKuota(id)
    },
    handleNewKuota () {
      this.$refs['modal-new-kuota'].show()
    },
    async handleSubmit () {
      try {
        const data = this.kuota
        const response = await Service.master.kuota_dokter_create(data)
        if (response.data.statcode === 0) {
          this.message = response.data.message
          this.$refs.MsgBox.show({
            type: 'success',
            msg: response.data.message
          })
        } else {
          this.error = response.data.message
          this.$refs.MsgBox.show({
            type: 'error',
            msg: response.data.message
          })
        }
        this.$refs.table.refresh()
        this.clearData()
      } catch (error) {
        this.error = error.message
        this.$refs.MsgBox.show({
          type: 'error',
          msg: error.message
        })
        this.$refs.table.refresh()
        this.clearData()
      }
    },
    async handleUpdate () {
      const id = this.id
      const data = this.editKuota
      try {
        const response = await Service.master.kuota_dokter_update(id, data)
        if (response.data.statcode === 0) {
          this.message = response.data.message
          this.$refs.MsgBox.show({
            type: 'success',
            msg: response.data.message
          })
        } else {
          this.error = response.data.message
          this.$refs.MsgBox.show({
            type: 'error',
            msg: response.data.message
          })
        }
        this.$refs.table.refresh()
      } catch (error) {
        this.error = error.message
        this.$refs.MsgBox.show({
          type: 'error',
          msg: error.message
        })
        this.$refs.table.refresh()
      }
    },
    deleteModal (id) {
      this.id = id
      this.viewKuota(id)
      this.$refs['modal-konfirm-delete'].show()
    },
    async handleDelete () {
      const id = this.id
      try {
        const response = await Service.master.kuota_dokter_delete(id)
        if (response.data.statcode === 0) {
          this.message = response.data.message
          this.$refs['modal-sukses'].show()
        } else {
          this.error = response.data.message
          this.$refs['modal-gagal'].show()
        }
        this.$refs.table.refresh()
      } catch (error) {
        this.error = error.message
        this.$refs['modal-gagal'].show()
        this.$refs.table.refresh()
      }
    },
    clearData () {
      this.kuota.tanggal = ''
      this.kuota.ruangan = ''
      this.kuota.dokter = ''
      this.kuota.start = ''
      this.kuota.end = ''
      this.kuota.jumlah_on = 0
      this.kuota.jumlah_off = 0
      this.kuota.jumlah_bridging = 0
      this.editKuota.tanggal = ''
      this.editKuota.ruangan = ''
      this.editKuota.dokter = ''
      this.editKuota.start = ''
      this.editKuota.end = ''
      this.editKuota.jumlah_on = 0
      this.editKuota.jumlah_off = 0
      this.editKuota.jumlah_bridging = 0
      this.editKuota.created_at = ''
      this.selected.instalasi = []
      this.selected.unit = []
      this.selected.ruangan = []
      this.selected.dokter = []
      this.combo.unit = []
      this.combo.ruangan = []
      this.combo.dokter = []
      this.display.kuotakeluaron = ''
      this.display.kuotakeluaroff = ''
      this.status = 'not_accepted'
      this.statusEdit = 'not_accepted'
      this.valuedisable = false
      this.valuedisable2 = false
      this.display.viewTgltmbh = ''
      this.display.viewTglEdit = ''
    },
    async getinstalasi () {
      await Service.master.instalasireservasi().then(response => {
        if (response.status === 200) {
          this.combo.instalasi = response.data.data
        }
      }).catch(error => {
        this.error = error.response
      })
    },
    async getunit (value) {
      const data = value.id.toString()
      await Service.master.unitreservasi(data).then(response => {
        if (response.status === 200) {
          this.combo.unit = response.data.data
        }
      }).catch(error => {
        this.error = error.response.data.errors.message
      })
    },
    async geteditunit (value) {
      const data = value.id.toString()
      await Service.master.unitreservasi(data).then(response => {
        if (response.status === 200) {
          this.combo.unit = response.data.data
        }
      }).catch(error => {
        this.error = error.response.data.errors.message
      })
    },
    async getruangan (value) {
      const data = value.id.toString()
      await Service.master.ruangankuota(data).then(response => {
        if (response.status === 200) {
          this.combo.ruangan = response.data.data
        }
      }).catch(error => {
        this.error = error.response.data.errors.message
      })
    },
    async geteditruangan (value) {
      const data = value.id.toString()
      await Service.master.ruangankuota(data).then(response => {
        if (response.status === 200) {
          this.combo.ruangan = response.data.data
        }
      }).catch(error => {
        this.error = error.response.data.errors.message
      })
    },
    async getdokter (value) {
      const data = value.id.toString()
      this.display.displayruangan = value.deskripsi
      this.kuota.ruangan = value.id
      await Service.master.dokter(data).then(response => {
        if (response.status === 200) {
          this.combo.dokter = response.data.data
        }
      }).catch(error => {
        this.error = error.response.data.errors.message
      })
    },
    async geteditdokter (value) {
      const data = value.id.toString()
      this.display.displayruangan = value.deskripsi
      this.editKuota.ruangan = value.id
      await Service.master.dokter(data).then(response => {
        if (response.status === 200) {
          this.combo.dokter = response.data.data
        }
      }).catch(error => {
        this.error = error.response.data.errors.message
      })
    },
    valDokter (value) {
      this.display.displaydokter = value.deskripsi
      this.kuota.dokter = value.id
    },
    valEditDokter (value) {
      this.display.displaydokter = value.deskripsi
      this.editKuota.dokter = value.id
    },
    onFiltered (filteredItems) {
      this.table.totalRows = filteredItems.length
      // this.table.currentPage = 1
    },
    valFilterRuang () {
      this.table.filter = ''
      this.$refs.table.refresh()
    },
    tableRefresh () {
      this.$refs.table.refresh()
    },
    valSelesai (val) {
      if (val === 'not_accepted') {
        this.valuedisable = false
        this.kuota.end = ''
      } else {
        this.kuota.end = 'selesai'
        this.valuedisable = true
      }
    },
    valSelesaiEdit (val) {
      if (val === 'not_accepted') {
        this.valuedisable2 = false
        this.editKuota.end = ''
      } else {
        this.editKuota.end = 'selesai'
        this.valuedisable2 = true
      }
    },
    checkSelesai (val) {
      if (val === 'selesai' || val === '') {
        this.statusEdit = 'accepted'
        this.valuedisable2 = true
      } else {
        this.statusEdit = 'not_accepted'
        this.valuedisable2 = false
      }
    },
    valTglTmbh (val) {
      let date = new Date(val)
      let year = date.getFullYear()
      let month = date.getMonth() + 1
      let day = date.getDate()
      if (month < 10) {
        month = '0' + month
      }
      if (day < 10) {
        day = '0' + day
      }
      let joinDate = year + '-' + month + '-' + day
      this.kuota.tanggal = joinDate
    },
    valTglEdt (val) {
      let date = new Date(val)
      let year = date.getFullYear()
      let month = date.getMonth() + 1
      let day = date.getDate()
      if (month < 10) {
        month = '0' + month
      }
      if (day < 10) {
        day = '0' + day
      }
      let joinDate = year + '-' + month + '-' + day
      this.editKuota.tanggal = joinDate
    },
    responViewTglEdt (val) {
      let a = val.split('-').reverse().join('-')
      this.display.viewTglEdit = a
    },
    filterAdvancedModal () {
      this.$refs['modal-filter'].show()
    },
    filterTglAwal (val) {
      let date = new Date(val)
      let year = date.getFullYear()
      let month = date.getMonth() + 1
      let day = date.getDate()
      if (month < 10) {
        month = '0' + month
      }
      if (day < 10) {
        day = '0' + day
      }
      let joinDate = year + '-' + month + '-' + day
      this.filtering.valTglAwal = joinDate
      this.filtering.valTglAkhir = joinDate
      this.messageTgl = ''
      this.table.filter = ''
      this.$refs.table.refresh()
    },
    filterTglAkhir (val) {
      if (!this.filtering.valTglAwal) {
        this.messageTgl = 'Tanggal Awal Harus Dipilh'
      }
      let date = new Date(val)
      let year = date.getFullYear()
      let month = date.getMonth() + 1
      let day = date.getDate()
      if (month < 10) {
        month = '0' + month
      }
      if (day < 10) {
        day = '0' + day
      }
      let joinDate = year + '-' + month + '-' + day
      this.filtering.valTglAkhir = joinDate
      this.table.filter = ''
      this.$refs.table.refresh()
    },
    filterBtn () {
      this.selected.filterType = '1'
      this.selected.filterRuang = '0'
      this.filtering.valTglAwal = ''
      this.filtering.valTglAkhir = ''
      this.display.viewTglAwal = ''
      this.display.viewTglAkhir = ''
      this.table.filter = ''
      this.$refs.table.refresh()
    },
    isAlphabetKey (evt) {
      evt = (evt) || window.event
      var charCode = (evt.which) ? evt.which : evt.keyCode
      if ((charCode < 65 || charCode > 90) && (charCode < 97 || charCode > 123) && charCode !== 32) {
        evt.preventDefault()
      } else {
        return true
      }
    }
  }
}
</script>

<style>
/* #modal-kuota-add .modal-body, #modal-kuota-edit .modal-body, #modal-filter .modal-body {
  background: #3399331b;
} */
#kuota-dokter .bg-filter {
  border: 1px solid rgba(0, 0, 0, 0.13);
  background-color: #089bab1e;
}
#modal-kuota-add .flatpickr-input[readonly], #modal-kuota-edit .flatpickr-input[readonly], #modal-filter .flatpickr-input[readonly] {
  height: 37px;
  font-size: 12px;
  border-radius: 5px;
  border: 1px solid rgba(128, 128, 128, 0.292);
  padding-left: 10px;
}
#header-table-filter .flatpickr-input[readonly] {
  height: 38px;
  font-size: 12px;
  border-radius: 5px;
  border: 1px solid rgba(17, 17, 17, 0.292);
  padding-left: 10px;
}
#modal-kuota-add .custom-search-kuota, #modal-kuota-edit .custom-search-kuota, #modal-filter .custom-search-kuota {
  font-size: 13px!important;
  color: black!important;
}
#kuota-dokter legend {
    color: darkslategrey;
}
#kuota-dokter .form-control:disabled, #kuota-dokter .form-control[readonly] {
    background-color: #e9ecef;
    opacity: 0.5;
}
#kuota-dokter .form-control {
  color: black!important;
}
#kuota-dokter .fc-unthemed .fc-toolbar .fc-button {
    margin: 3px;
}
#kuota-dokter tr:first-child > td > .fc-day-grid-event {
    cursor: pointer;
}
#kuota-dokter .multiselect__input, .multiselect {
  font-weight: bold!important;
}
td.fc-day.fc-past {
  background-color: #EEEEEE;
}
#modal-kuota-add .multiselect__single, #modal-kuota-edit .multiselect__single {
  font-size: 12px!important;
  font-weight: normal!important;
}
#modal-kuota-add .multiselect__placeholder, #modal-kuota-edit .multiselect__placeholder {
  font-size: 12px!important;
  font-weight: normal!important;
}
#modal-kuota-add .multiselect__option, #modal-kuota-edit .multiselect__option {
  font-size: 12px;
  font-weight: normal!important;
}
#modal-kuota-add .multiselect__tags, #modal-kuota-edit .multiselect__tags {
  font-weight: normal!important;
  padding: 5px 40px 0 8px!important;
  height: 38px!important;
  min-height: 38px!important;
}
/* #modal-kuota-edit .flatpickr-input[readonly] {
  background-color: rgb(245, 245, 245);
} */
#kuota-dokter .flex-half-width-left {
  width: 76%;
}
#kuota-dokter .flex-half-width-right {
  width: 23%;
}
#kuota-dokter .flex-full-width-left {
  width: 96%;
}
#kuota-dokter .flex-full-width-right {
  width: 3%;
}
#kuota-dokter .txt-farmasi-base {
  font-size: 12px;
}
#kuota-dokter .txt-farmasi-base-head {
  font-size: 14px;
}
#kuota-dokter .table td {
    font-size: 12px;
    font-weight: 500;
    padding-top: 0px;
    padding-bottom: 0px;
}
#kuota-dokter .table th {
    font-size: 13px;
    font-weight: 400;
}
#kuota-dokter .form-control {
    border: 1px solid rgb(194, 194, 194);
    background: white;
    height: 40px;
}
#kuota-dokter .multiselect__tags {
    border-radius: 0px;
    border: 1px solid rgb(194, 194, 194);
    background: white;
    font-size: 11px;
    font-weight: 550;
    height: 10px;
}
#kuota-dokter .btn-height-custom {
    height: 39px;
}
#kuota-dokter .cursor-point {
    cursor: pointer;
}
#kuota-dokter .scroll-y {
    overflow-y: scroll;
    height: 550px;
}
#kuota-dokter .bg-abu {
  background: rgba(236, 236, 236, 0.541);
}
#kuota-dokter .form-control {
    font-size: 11px;
}
#kuota-dokter .b-table-sticky-header, #kuota-dokter .table-responsive {
    margin-bottom: 0px;
}
#kuota-dokter .table-barang .btn {
    font-size: 11px;
}
#kuota-dokter .page-link {
    border: none;
    color: gray!important;
    margin-right: 15px;
}
#kuota-dokter .page-item.active .page-link {
    background-color: transparent;
    border-color: transparent;
    color: #089bab!important;
    font-weight: bold;
}
#kuota-dokter .vertical-txt {
  writing-mode: vertical-rl;
  letter-spacing: 10px;
}
#kuota-dokter .transition-cstm {
  transition: .3s linear;
}
.cstm-cntrl-kuota {
  border-radius: 5px!important;
  font-size: 13px!important;
  height: 38px!important;
}
#modal-kuota-add .modal-dialog, #modal-kuota-edit .modal-dialog {
  min-width: 85%!important;
}
.cstm-slct-kuota {
  font-size: 13px!important;
  height: 38px!important;
  border-radius: 5px;
}
.alert-radius-kuota {
  border-radius: 5px;
}

.font-size-responsive {
  font-size: 7px;
}

@media (min-width: 576px) {
  .font-size-responsive {
    font-size: 8px;
  }
 }

@media (min-width: 768px) {
  .font-size-responsive {
    font-size: 9px;
  }
 }

@media (min-width: 992px) {
  .font-size-responsive {
    font-size: 10px;
  }
 }

@media (min-width: 1200px) {
  .font-size-responsive {
    font-size: 11px;
  }
 }
 .pointer-table tbody tr {
  cursor: pointer;
}
</style>
