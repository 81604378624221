import Api from './fatmahost/axiosInstance'

export default {
  showAll (page, params) {
    if (params !== null) {
      return Api.laravel.get(`penjadwalan/cathlab/?params=${params}`)
    } else {
      return Api.laravel.get(`penjadwalan/cathlab/?page=${page}`)
    }
  },
  getNorm (data) {
    return Api.laravel.get(`getPasien?norm=${data}`)
  },
  showById (data) {
    return Api.laravel.get(`penjadwalan/cathlab/${data}`)
  },
  showExport (tglAwl, tglAhr) {
    return Api.laravel.get(`export/penjadwalan/cathlab/?tanggal_awal=${tglAwl}&tanggal_akhir=${tglAhr}`)
  },
  showDokter (data) {
    return Api.laravel.post('setDokterRuangan', data)
  },
  showTindakan (data) {
    return Api.laravel.post('setMasterTindakan', data)
  },
  showDashboard () {
    return Api.laravel.get('dashboard/penjadwalan/cathlab')
  },
  store (data) {
    return Api.laravel.post('penjadwalan/cathlab', data)
  },
  cancel (id, data) {
    return Api.laravel.put(`update/penjadwalan/cathlab?id=${id}`, data)
  }
}
