import Api from '../axiosInstance'
const version = '/v1'
export default {
  findAll (currentPage, perPage, filter) {
    return Api.users.get(`${version}/practitioner/pengguna?param0=pengguna-practitioner&param1=${currentPage}&param2=${perPage}&param3=${filter}`)
  },
  findId (id) {
    return Api.users.put(`${version}/practitioner/pengguna/${id}?param0=pengguna-practitioner`)
  },
  update (id, data) {
    return Api.users.put(`${version}/practitioner/pengguna/${id}`, data)
  }
}
