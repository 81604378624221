<template>
  <ValidationObserver ref="form" v-slot="{ handleSubmit }">
    <b-alert
      :show="dismissCountDown"
      dismissible
      fade
      variant="danger"
      @dismiss-count-down="countDownChanged"
    >
      Connection failed. Please check your network connection
    </b-alert>
    <form class="mt-2 pb-5 mb-5" novalidate @submit.prevent="handleSubmit(onSubmit)">
      <ValidationProvider
        vid="username"
        name="username"
        rules="required"
        v-slot="{ errors }"
      >
        <div class="form-group d-flex flex-row align-items-center form-border shadow-sm">
          <div class="px-2 text-center">
            <i class="fas fa-envelope fa-lg"></i>
          </div>
          <div class="w-100">
            <input
              type="text"
              :class="
                'form-control cstm-cntrl-sign border-0 form-border-child-radius mb-0 py-4' + (errors.length > 0 ? ' is-invalid' : '')
              "
              id="usernameInput"
              aria-describedby="emailHelp"
              v-model="user.username"
              placeholder="Username..."
              required
            />
          </div>
          <!-- <div class="invalid-feedback">
            <span>{{ errors[0] }}</span>
          </div> -->
        </div>
      </ValidationProvider>
      <ValidationProvider
        vid="password"
        name="Password"
        rules="required"
        v-slot="{ errors }"
      >
        <div class="form-group d-flex flex-row align-items-center form-border shadow-sm">
          <div class="px-2 text-center fa-lg">
            <i class="fas fa-unlock-alt"></i>
          </div>
          <div class="w-100">
            <input
              type="password"
              :class="
                'form-control cstm-cntrl-sign border-0 form-border-child-radius mb-0 py-4' + (errors.length > 0 ? ' is-invalid' : '')
              "
              id="passwordInput"
              v-model="user.password"
              placeholder="Password..."
              required
            />
          </div>
          <!-- <div class="invalid-feedback">
            <span>{{ errors[0] }}</span>
          </div> -->
        </div>
      </ValidationProvider>
      <!-- <div class="mb-2">
        <vue-recaptcha sitekey="6Ld-5YEkAAAAAIq22U20o0NqoGno9gm1nwsMnPUE" @verify="verifyMethod"></vue-recaptcha>
        <small class="text-danger">{{ captchaMessage }}</small>
      </div> -->
      <!-- <div class="mb-4">
        <b-form-checkbox switch>
          Remember me
        </b-form-checkbox>
      </div> -->
      <button type="submit" class="btn custom-button-login py-3 py-lg-2 shadow-sm btn-login-responsive mt-2">
        <i class="fas fa-long-arrow-alt-right"></i> CONFIRM
      </button>
    </form>
    <ModalMsgBox ref="MsgBox" />
  </ValidationObserver>
</template>

<script>
import { xray } from '../../config/pluginInit'
import { mapActions } from 'vuex'
// import { VueRecaptcha } from 'vue-recaptcha'
import ModalMsgBox from '../../components/modal/ModalMsgBox.vue'

export default {
  name: 'SignInForm',
  props: ['username', 'password'],
  components: {
    ModalMsgBox
  },
  data: () => ({
    errors: null,
    error: '',
    user: {
      username: '',
      password: ''
    },
    root: '',
    dismissSecs: 5,
    dismissCountDown: 0,
    showDismissibleAlert: false,
    codeCaptcha: null,
    captchaMessage: ''
  }),
  mounted () {
    this.user.username = this.$props.username
    this.user.password = this.$props.password
    this.refresh()
  },
  methods: {
    ...mapActions({
      signIn: 'Auth/signIn'
    }),
    onSubmit () {
      const data = {
        param0: this.user.username,
        param1: this.user.password
      }
      this.signIn(data).then((response) => {
        this.root = xray.rootHome()
      }).catch(error => {
        this.$refs.form.setErrors(error.response.data.errors)
        this.$refs.MsgBox.show({
          type: 'error',
          msg: error.response.data.errors
        })
      }).finally(() => {
        this.$router.replace({ name: this.root })
      })
      // if (this.codeCaptcha) {
      //   const data = {
      //     param0: this.user.username,
      //     param1: this.user.password
      //   }
      //   this.signIn(data)
      //     .then(() => {
      //       this.$router.replace({ name: 'dashboard.home' })
      //     })
      //     .catch((error) => {
      //       this.$refs.form.setErrors(error.response.data.errors)
      //       if (error.response.status === 400) {
      //         this.$refs['modal-gagal'].show()
      //         this.error = error.response.data.errors.message
      //       }
      //     })
      // } else {
      //   this.captchaMessage = 'reCAPATCHA Belum Di Ceklis'
      // }
    },
    countDownChanged (dismissCountDown) {
      this.dismissCountDown = dismissCountDown
    },
    showAlert () {
      this.dismissCountDown = this.dismissSecs
    },
    refresh () {
      localStorage.removeItem('token')
    },
    verifyMethod (response) {
      this.codeCaptcha = response
    }
  }
}
</script>
<style>
.cstm-cntrl-sign {
  color: black!important;
  border-radius: 5px!important;
  font-size: 12px!important;
  height: 38px!important;
}
.custom-button-login {
  background-color: #0b7275!important;
  color: white!important;
}
.btn-login-responsive {
    width: 100%
}
@media (min-width: 768px) {
  .btn-login-responsive {
    width: auto;
  }
}
</style>
