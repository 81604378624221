<template>
  <div>
    <b-container fluid>
      <iq-card>
        <template v-slot:headerTitle>
          <h4 class="card-title font-weight-bold">Pasien Medical Check Up</h4>
        </template>
        <template v-slot:body>
          <b-row>
            <b-col>
              <iq-card body-class="text-center">
                <div class="p-1">
                  <form class="searchbox">
                    <input type="search" v-model="filter" class="form-control" id="filter-input"
                      placeholder="Cari NIK / Nama Pasien">
                  </form>
                </div>
              </iq-card>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <b-table ref="table" :busy="isBusy" :items="items" :fields="fields" :filter="filter"
                @filtered="onFiltered" :current-page="currentPage" :per-page="perPage" stacked="md" bordered hover
                show-empty>
                <template #cell(index)="data">
                  {{ data.index + 1 }}
                </template>
                <template v-slot:cell(tanggal_lahir)="data">
                  <span>{{ data.item.deskripsi }}, {{ data.item.tanggal_lahir }}</span>
                </template>
                <template v-slot:cell(jenis_kelamin)="data">
                  <span>{{ data.item.jenis_kelamin == 1 ? 'Laki - laki' : 'Perempuan' }}</span>
                </template>
                <template v-slot:cell(tanggal)="data">
                  <span>{{ data.item.tanggal_reservasi }}</span>
                </template>
                 <template v-slot:cell(jenis_reservasi)="data">
                  <span class="text-dark" v-if="data.item.jenis_reservasi == null || data.item.status == 0">---</span>
                  <span class="text-info font-weight-bold" v-else-if="data.item.jenis_reservasi == 1">Online</span>
                  <span class="text-warning font-weight-bold" v-else-if="data.item.jenis_reservasi == 2">Offline</span>
                </template>
                 <template v-slot:cell(status)="data">
                  <span class="text-dark" v-if="data.item.status == null || data.item.status == 0">---</span>
                  <span class="text-success font-weight-bold" v-else-if="data.item.status == 2">Terdaftar</span>
                  <span class="text-danger font-weight-bold" v-else-if="data.item.status == 1">Belum daftar</span>
                </template>
                <template v-slot:cell(action)="data">
                  <b-button variant=" iq-bg-warning mr-1 mb-1" size="lg"
                    @click="daftarMcu(data.item.id)">
                    <i class="ri-file-line"></i>
                    Daftar
                  </b-button>
                </template>
              </b-table>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage" align="" size="md"
                class="my-0 float-right"></b-pagination>
            </b-col>
          </b-row>
        </template>
      </iq-card>
      <b-modal ref="modal-sukses" size="md" title="Informasi" ok-title="Oke" :ok-only=true :hide-header=true
        centered>
        <div class="text-center px-lg-4 px-2">
          <lottie :options="defaultOptions" :autoPlay="true" :width="250" :height="250" />
          <p class="h6 font-weight-bold text-success mb-0">Data Berhasil Disimpan</p>
        </div>
      </b-modal>
      <b-modal ref="modal-gagal" size="md" title="Informasi" ok-title="Kembali" :ok-only=true :hide-header=true
        centered>
        <div class="text-center px-lg-4 px-2">
          <lottie :options="defaultOptions2" :autoPlay="true" :width="250" :height="250" />
          <p class="h6 text-danger font-weight-bold mb-0">Data Gagal Disimpan</p>
        </div>
      </b-modal>
    </b-container>
  </div>
</template>
<script>
import { xray } from '../../config/pluginInit'
import MCU from '../../services/mcu'
import Lottie from 'vue-lottie'
import animationLogo from '../../assets/lottie/checked.json'
import animationLogo2 from '../../assets/lottie/failed.json'
export default {
  name: 'mcu',
  components: {
    Lottie
  },
  mounted () {
    xray.index()
  },
  methods: {
    async listMcu (ctx) {
      if (ctx.filter === '') {
        ctx.filter = null
      }
      this.isBusy = true
      try {
        const res = await MCU.all(ctx.currentPage, ctx.filter)
        this.totalRows = res.data.data.total
        this.isBusy = false
        return res.data.data.data
      } catch (err) {
        this.isBusy = false
        return []
      }
    },
    async daftarMcu (id) {
      const data = {
        id: id,
        paspor: '',
        status: 2
      }
      await MCU.store(data).then((response) => {
        this.$refs['modal-sukses'].show()
        this.$refs.table.refresh()
      }).catch((error) => {
        this.$refs.table.refresh()
        this.error = error.response
        this.$refs['modal-gagal'].show()
      })
    },
    onFiltered (filteredItems) {
      this.totalRows = filteredItems.length
      this.currentPage = 1
    }
  },
  data () {
    return {
      defaultOptions: { animationData: animationLogo, loop: true },
      defaultOptions2: { animationData: animationLogo2, loop: true },
      isBusy: false,
      error: [],
      items: this.listMcu,
      filterOn: [],
      filter: null,
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      fields: [
        { label: 'No', key: 'index', class: 'text-center' },
        { label: 'No. Reservasi', key: 'nomor_reservasi', thClass: 'text-center', tdClass: 'text-center' },
        { label: 'NIK', key: 'nik', thClass: 'text-center', tdClass: 'text-center' },
        { label: 'Nama Lengkap', key: 'nama', thClass: 'text-center', tdClass: 'text-left' },
        { label: 'Tempat Tgl. Lahir', key: 'tanggal_lahir', thClass: 'text-center', tdClass: 'text-left' },
        { label: 'Jenis Kelamin', key: 'jenis_kelamin', thClass: 'text-center', tdClass: 'text-center' },
        { label: 'Tgl Reservasi', key: 'tanggal', thClass: 'text-center', tdClass: 'text-center' },
        { label: 'Jenis Pasien', key: 'deskripsi_jenis_pasien', thClass: 'text-center', tdClass: 'text-center' },
        { label: 'Jenis Reservasi', key: 'jenis_reservasi', thClass: 'text-center', tdClass: 'text-center' },
        { label: 'Status', key: 'status', thClass: 'text-center', tdClass: 'text-center' },
        { label: 'Aksi', key: 'action', class: 'text-center' }
      ]
    }
  }
}
</script>
