<template>
  <div>
    <div v-show="isLoading">
      <div id="show-overlay"></div>
      <Loader :style="{ opacity: .5 }" />
    </div>
    <b-container fluid>
      <div>
        <b-row>
          <b-col md="3" class="mb-4">
            <div class="iq-card shadow-sm p-3 mb-3">
              <b-form-group label="Pilih Jenis Kartu" v-slot="{ ariaDescribedby }">
                <b-form-radio-group
                  id="radio-group-1"
                  v-model="selected"
                  :options="options"
                  :aria-describedby="ariaDescribedby"
                  name="radio-options"
                ></b-form-radio-group>
              </b-form-group>
              <div>
                <b-form-input size="sm" type="search" v-model="nomor" v-on:keyup.enter="bpjsnik" class="mb-3"
                  placeholder="Masukan Nomor"></b-form-input>
                <b-button size="lg" variant="primary" class="btn-block" type="submit" @click="bpjsnik"><i
                    class="fas fa-search"></i> Cari</b-button>
              </div>
            </div>
          </b-col>
          <b-col md="6">
            <div class="iq-card shadow mb-4">
              <div class="card-head d-flex flex-row align-items-center px-3 py-1">
                <div class="p-2">
                  <b-img :src="require('../../assets/images/logo-garuda.png')" width="60rem" fluid alt="Responsive image">
                  </b-img>
                </div>
                <div class="p-2 mx-auto">
                  <p class="text-bpjs-title text-white text-center font-weight-bold mb-0">KARTU INDONESIA SEHAT</p>
                </div>
                <div class="p-2">
                  <b-img :src="require('../../assets/images/logo-rs.png')" width="65rem" fluid alt="Responsive image">
                  </b-img>
                </div>
              </div>
              <div class="card-content px-3 pt-4 pb-4">
                <div class="d-none d-md-block">
                  <b-row class="mb-2">
                    <b-col cols="5" lg="4">
                      <p class="text-bpjs-card font-weight-normal text-dark mb-0">Nomor Kartu</p>
                    </b-col>
                    <b-col cols="1" lg="1">
                      <p class="text-bpjs-card font-weight-normal text-dark mb-0">:</p>
                    </b-col>
                    <b-col cols="6" lg="7">
                      <p class="text-bpjs-card font-weight-normal text-dark mb-0">{{ peserta.nokartu }}</p>
                    </b-col>
                  </b-row>
                  <b-row class="mb-2">
                    <b-col cols="5" lg="4">
                      <p class="text-bpjs-card font-weight-normal text-dark mb-0">Nama</p>
                    </b-col>
                    <b-col cols="1" lg="1">
                      <p class="text-bpjs-card font-weight-normal text-dark mb-0">:</p>
                    </b-col>
                    <b-col cols="6" lg="7">
                      <p class="text-bpjs-card font-weight-normal text-dark mb-0">{{ peserta.nama }}</p>
                    </b-col>
                  </b-row>
                  <b-row class="mb-2">
                    <b-col cols="5" lg="4">
                      <p class="text-bpjs-card font-weight-normal text-dark mb-0">Status</p>
                    </b-col>
                    <b-col cols="1" lg="1">
                      <p class="text-bpjs-card font-weight-normal text-dark mb-0">:</p>
                    </b-col>
                    <b-col cols="6" lg="7">
                      <p class="text-bpjs-card font-weight-normal text-dark mb-0">{{ peserta.status }}</p>
                    </b-col>
                  </b-row>
                  <b-row class="mb-2">
                    <b-col cols="5" lg="4">
                      <p class="text-bpjs-card font-weight-normal text-dark mb-0">Tanggal Lahir</p>
                    </b-col>
                    <b-col cols="1" lg="1">
                      <p class="text-bpjs-card font-weight-normal text-dark mb-0">:</p>
                    </b-col>
                    <b-col cols="6" lg="7">
                      <p class="text-bpjs-card font-weight-normal text-dark mb-0" v-if="peserta.tglLahir !== ''">{{ new Date(peserta.tglLahir) | formatDate }}</p>
                    </b-col>
                  </b-row>
                  <b-row class="mb-2">
                    <b-col cols="5" lg="4">
                      <p class="text-bpjs-card font-weight-normal text-dark mb-0">NIK</p>
                    </b-col>
                    <b-col cols="1" lg="1">
                      <p class="text-bpjs-card font-weight-normal text-dark mb-0">:</p>
                    </b-col>
                    <b-col cols="6" lg="7">
                      <p class="text-bpjs-card font-weight-normal text-dark mb-0">{{ peserta.nik }}</p>
                    </b-col>
                  </b-row>
                  <b-row class="mb-4">
                    <b-col cols="5" lg="4">
                      <p class="text-bpjs-card font-weight-normal text-dark mb-0">Faskes Tingkat I</p>
                    </b-col>
                    <b-col cols="1" lg="1">
                      <p class="text-bpjs-card font-weight-normal text-dark mb-0">:</p>
                    </b-col>
                    <b-col cols="6" lg="7">
                      <p class="text-bpjs-card font-weight-normal text-dark mb-0">{{ peserta.faskes1 }}</p>
                    </b-col>
                  </b-row>
                </div>
                <div class="d-block d-md-none mb-5">
                  <div class="d-flex flex-row mb-2">
                    <div class="mr-auto">
                      <p class="text-bpjs-card text-dark mb-0">Nomor Kartu</p>
                    </div>
                    <div>
                      <p class="text-bpjs-card text-dark font-weight-bold mb-0">{{ peserta.nokartu }}</p>
                    </div>
                  </div>
                  <div class="d-flex flex-row mb-2">
                    <div class="mr-auto">
                      <p class="text-bpjs-card text-dark mb-0">Nama</p>
                    </div>
                    <div>
                      <p class="text-bpjs-card text-dark font-weight-bold mb-0">{{ peserta.nama }}</p>
                    </div>
                  </div>
                  <div class="d-flex flex-row mb-2">
                    <div class="mr-auto">
                      <p class="text-bpjs-card text-dark mb-0">Status</p>
                    </div>
                    <div>
                      <p class="text-bpjs-card text-dark font-weight-bold mb-0">{{ peserta.status }}</p>
                    </div>
                  </div>
                  <div class="d-flex flex-row mb-2">
                    <div class="mr-auto">
                      <p class="text-bpjs-card text-dark mb-0">Tanggal Lahir</p>
                    </div>
                    <div>
                      <p class="text-bpjs-card text-dark font-weight-bold mb-0" v-if="peserta.tglLahir !== ''">{{ new Date(peserta.tglLahir) | formatDate }}</p>
                    </div>
                  </div>
                  <div class="d-flex flex-row mb-2">
                    <div class="mr-auto">
                      <p class="text-bpjs-card text-dark mb-0">NIK</p>
                    </div>
                    <div>
                      <p class="text-bpjs-card text-dark font-weight-bold mb-0">{{ peserta.nik }}</p>
                    </div>
                  </div>
                  <div class="d-flex flex-row mb-2">
                    <div class="mr-auto">
                      <p class="text-bpjs-card text-dark mb-0">Faskes Tingkat I</p>
                    </div>
                    <div>
                      <p class="text-bpjs-card text-dark font-weight-bold mb-0">{{ peserta.faskes1 }}</p>
                    </div>
                  </div>
                </div>
                <b-row class="mb-0">
                  <b-col size="md" cols="12">
                    <p class="font-weight-bold text-dark mb-1">Syarat dan Ketentuan:</p>
                    <p class="small text-dark mb-0">1. Kartu Peserta harap dibawa ketika berobat</p>
                    <p class="small text-dark mb-0">2. Apabila kartu ini disalahgunakan akan dikenakan
                      sanksi</p>
                    <p class="small text-dark mb-0">3. Apabila ada perubahan atau kehilangan kehilangan
                      kartu, segera lapor ke kantor BPJS terdekat</p>
                  </b-col>
                </b-row>
                <b-row class="mt-3">
                  <b-col>
                    <b-button class="bg-btn-bpjs border-oval px-3" variant="success" size="sm" v-b-modal.modal-lg><i
                        class="fas fa-info"></i> Lihat
                      Detail Kartu</b-button>
                  </b-col>
                </b-row>
              </div>
            </div>
          </b-col>
        </b-row>
      </div>
      <b-modal id="modal-lg" header-class="d-none" body-clas="p-0" content-class="bg-transparent border-0"
        footer-class="d-none" modal-class="p-0" size="xl" title="Detail Peserta">
        <b-row class="justify-content-center">
          <b-col md="12">
            <div class="card-head d-flex flex-row align-items-center px-3 py-1">
              <div class="p-2">
                <b-img :src="require('../../assets/images/logo-garuda.png')" width="60rem" fluid alt="Responsive image">
                </b-img>
              </div>
              <div class="p-2 mx-auto">
                <p class="h6 text-center text-white font-weight-bold mb-0">DETAIL KARTU INDONESIA SEHAT</p>
              </div>
              <div class="p-2">
                <b-img :src="require('../../assets/images/logo-rs.png')" width="65rem" fluid alt="Responsive image">
                </b-img>
              </div>
            </div>
            <div class="card-content  px-3 pt-4 pb-4">
              <b-row class="mb-1">
                <b-col cols="10" lg="3">
                  <p class="font-weight-normal text-dark mb-0">Nama</p>
                </b-col>
                <b-col cols="1" lg="1">
                  <p class="font-weight-normal text-dark mb-0">:</p>
                </b-col>
                <b-col cols="12" lg="7">
                  <p class="font-weight-bold text-dark mb-0">{{ peserta.nama }}</p>
                </b-col>
              </b-row>
              <b-row class="mb-1">
                <b-col cols="10" lg="3">
                  <p class="font-weight-normal text-dark mb-0">Hak Kelas Rawat</p>
                </b-col>
                <b-col cols="1" lg="1">
                  <p class="font-weight-normal text-dark mb-0">:</p>
                </b-col>
                <b-col cols="12" lg="7">
                  <p class="font-weight-bold text-dark mb-0">{{ peserta.hakKelas }}</p>
                </b-col>
              </b-row>
              <b-row class="mb-1">
                <b-col cols="10" lg="3">
                  <p class="font-weight-normal text-dark mb-0">Jenis Kepesertaan</p>
                </b-col>
                <b-col cols="1" lg="1">
                  <p class="font-weight-normal text-dark mb-0">:</p>
                </b-col>
                <b-col cols="12" lg="7">
                  <p class="font-weight-bold text-dark mb-0">{{ peserta.jenisPeserta }}</p>
                </b-col>
              </b-row>
              <b-row class="mb-1">
                <b-col cols="10" lg="3">
                  <p class="font-weight-normal text-dark mb-0">P/I/S/A/T</p>
                </b-col>
                <b-col cols="1" lg="1">
                  <p class="font-weight-normal text-dark mb-0">:</p>
                </b-col>
                <b-col cols="12" lg="7">
                  <p class="font-weight-bold text-dark mb-0">{{ peserta.pisa }}</p>
                </b-col>
              </b-row>
              <b-row class="mb-1">
                <b-col cols="10" lg="3">
                  <p class="font-weight-normal text-dark mb-0">Faskes Tingkat I</p>
                </b-col>
                <b-col cols="1" lg="1">
                  <p class="font-weight-normal text-dark mb-0">:</p>
                </b-col>
                <b-col cols="12" lg="7">
                  <p class="font-weight-bold text-dark mb-0">{{ peserta.faskes1 }}</p>
                </b-col>
              </b-row>
              <b-row class="mb-1">
                <b-col cols="10" lg="3">
                  <p class="font-weight-normal text-dark mb-0">Tanggal Cetak Kartu</p>
                </b-col>
                <b-col cols="1" lg="1">
                  <p class="font-weight-normal text-dark mb-0">:</p>
                </b-col>
                <b-col cols="12" lg="7">
                  <p class="font-weight-bold text-dark mb-0" v-if="peserta.tglCetakKartu !== ''">{{ new Date(peserta.tglCetakKartu) | formatDate }}</p>
                </b-col>
              </b-row>
              <b-row class="mb-1">
                <b-col cols="10" lg="3">
                  <p class="font-weight-normal text-dark mb-0">Keterangan Kepesertaan</p>
                </b-col>
                <b-col cols="1" lg="1">
                  <p class="font-weight-normal text-dark mb-0">:</p>
                </b-col>
                <b-col cols="12" lg="7">
                  <p class="font-weight-bold text-dark mb-0">{{ peserta.status }}</p>
                </b-col>
              </b-row>
              <b-row class="mb-1">
                <b-col cols="10" lg="3">
                  <p class="font-weight-normal text-dark mb-0">Jenis Kelamin</p>
                </b-col>
                <b-col cols="1" lg="1">
                  <p class="font-weight-normal text-dark mb-0">:</p>
                </b-col>
                <b-col cols="12" lg="7">
                  <p class="font-weight-bold text-dark mb-0">{{ peserta.sex }}</p>
                </b-col>
              </b-row>
              <b-row class="mb-1">
                <b-col cols="10" lg="3">
                  <p class="font-weight-normal text-dark mb-0">NIK</p>
                </b-col>
                <b-col cols="1" lg="1">
                  <p class="font-weight-normal text-dark mb-0">:</p>
                </b-col>
                <b-col cols="12" lg="7">
                  <p class="font-weight-bold text-dark mb-0">{{ peserta.nik }}</p>
                </b-col>
              </b-row>
              <b-row class="mb-1">
                <b-col cols="10" lg="3">
                  <p class="font-weight-normal text-dark mb-0">NoRM</p>
                </b-col>
                <b-col cols="1" lg="1">
                  <p class="font-weight-normal text-dark mb-0">:</p>
                </b-col>
                <b-col cols="12" lg="7">
                  <p class="font-weight-bold text-dark mb-0">{{ peserta.noMR }}</p>
                </b-col>
              </b-row>
              <b-row class="mb-1">
                <b-col cols="10" lg="3">
                  <p class="font-weight-normal text-dark mb-0">Telepone</p>
                </b-col>
                <b-col cols="1" lg="1">
                  <p class="font-weight-normal text-dark mb-0">:</p>
                </b-col>
                <b-col cols="12" lg="7">
                  <p class="font-weight-bold text-dark mb-0">{{ peserta.noTelepon }}</p>
                </b-col>
              </b-row>
              <b-row class="mb-1">
                <b-col cols="10" lg="3">
                  <p class="font-weight-normal text-dark mb-0">Umur Saat Pelayanan</p>
                </b-col>
                <b-col cols="1" lg="1">
                  <p class="font-weight-normal text-dark mb-0">:</p>
                </b-col>
                <b-col cols="12" lg="7">
                  <p class="font-weight-bold text-dark mb-0">{{ peserta.umurSaatPelayanan }}</p>
                </b-col>
              </b-row>
              <b-row class="mb-1">
                <b-col cols="10" lg="3">
                  <p class="font-weight-normal text-dark mb-0">Umur Sekarang</p>
                </b-col>
                <b-col cols="1" lg="1">
                  <p class="font-weight-normal text-dark mb-0">:</p>
                </b-col>
                <b-col cols="12" lg="7">
                  <p class="font-weight-bold text-dark mb-0">{{ peserta.umurSekarang }}</p>
                </b-col>
              </b-row>
              <b-row class="mb-1">
                <b-col cols="10" lg="3">
                  <p class="font-weight-normal text-dark mb-0">Kode Prov Rujukan</p>
                </b-col>
                <b-col cols="1" lg="1">
                  <p class="font-weight-normal text-dark mb-0">:</p>
                </b-col>
                <b-col cols="12" lg="7">
                  <p class="font-weight-bold text-dark mb-0">{{ peserta.kdProvider }}</p>
                </b-col>
              </b-row>
              <b-row class="mb-1">
                <b-col cols="10" lg="3">
                  <p class="font-weight-normal text-dark mb-0">Nama Prov Rujukan</p>
                </b-col>
                <b-col cols="1" lg="1">
                  <p class="font-weight-normal text-dark mb-0">:</p>
                </b-col>
                <b-col cols="12" lg="7">
                  <p class="font-weight-bold text-dark mb-0">{{ peserta.nmProvider }}</p>
                </b-col>
              </b-row>
              <b-row class="mb-1">
                <b-col cols="10" lg="3">
                  <p class="font-weight-normal text-dark mb-0">No Kartu Bpjs</p>
                </b-col>
                <b-col cols="1" lg="1">
                  <p class="font-weight-normal text-dark mb-0">:</p>
                </b-col>
                <b-col cols="12" lg="7">
                  <p class="font-weight-bold text-dark mb-0">{{ peserta.nokartu }}</p>
                </b-col>
              </b-row>
            </div>
          </b-col>
        </b-row>
      </b-modal>
    </b-container>
  </div>
</template>

<script>
import { xray } from '../../config/pluginInit'
import Loader from '../../components/xray/loader/Loader'
import BPJS from '../../services/bpjs.js'

export default {
  name: 'BpjsKepesertaan',
  components: {
    Loader
  },
  data () {
    return {
      isLoading: false,
      router: '/',
      error: '',
      nomor: null,
      peserta: {
        nokartu: '',
        nama: '',
        status: '',
        tglLahir: '',
        nik: '',
        faskes1: '',
        hakKelas: '',
        jenisPeserta: '',
        pisa: '',
        tglCetakKartu: '',
        sex: '',
        noMR: '',
        noTelepon: '',
        kode: '',
        umurSaatPelayanan: '',
        umurSekarang: '',
        kdProvider: '',
        nmProvider: '',
        statusText: ''
      },
      selected: '1',
      options: [
        { text: 'Nomor KTP', value: '1' },
        { text: 'Nomor BPJS', value: '2' }
      ]
    }
  },
  mounted () {
    xray.index()
  },
  methods: {
    async bpjsnik () {
      this.isLoading = true
      if (this.selected === '1') {
        const data = {
          nik: this.nomor
        }
        try {
          const res = await BPJS.nikadmin(data)
          this.peserta.nokartu = res.data.data.peserta.noKartu
          this.peserta.nama = res.data.data.peserta.nama
          this.peserta.status = res.data.data.peserta.statusPeserta.keterangan
          this.peserta.tglLahir = res.data.data.peserta.tglLahir
          this.peserta.nik = res.data.data.peserta.nik
          this.peserta.faskes1 = res.data.data.peserta.provUmum.nmProvider
          this.peserta.hakKelas = res.data.data.peserta.hakKelas.keterangan
          this.peserta.jenisPeserta = res.data.data.peserta.jenisPeserta.keterangan
          this.peserta.pisa = res.data.data.peserta.pisa
          this.peserta.tglCetakKartu = res.data.data.peserta.tglCetakKartu
          this.peserta.sex = res.data.data.peserta.sex
          this.peserta.noMR = res.data.data.peserta.mr.noMR
          this.peserta.noTelepon = res.data.data.peserta.mr.noTelepon
          this.peserta.kode = res.status
          this.peserta.statusText = res.statusText
          this.peserta.umurSaatPelayanan = res.data.data.peserta.umur.umurSaatPelayanan
          this.peserta.umurSekarang = res.data.data.peserta.umur.umurSekarang
          this.peserta.kdProvider = res.data.data.peserta.provUmum.kdProvider
          this.peserta.nmProvider = res.data.data.peserta.provUmum.nmProvider
          this.isLoading = false
        } catch (err) {
          this.error = err
          this.isLoading = false
        }
      } else if (this.selected === '2') {
        const data = {
          noKartu: this.nomor
        }
        try {
          const res = await BPJS.nokartuadmin(data)
          this.peserta.nokartu = res.data.data.peserta.noKartu
          this.peserta.nama = res.data.data.peserta.nama
          this.peserta.status = res.data.data.peserta.statusPeserta.keterangan
          this.peserta.tglLahir = res.data.data.peserta.tglLahir
          this.peserta.nik = res.data.data.peserta.nik
          this.peserta.faskes1 = res.data.data.peserta.provUmum.nmProvider
          this.peserta.hakKelas = res.data.data.peserta.hakKelas.keterangan
          this.peserta.jenisPeserta = res.data.data.peserta.jenisPeserta.keterangan
          this.peserta.pisa = res.data.data.peserta.pisa
          this.peserta.tglCetakKartu = res.data.data.peserta.tglCetakKartu
          this.peserta.sex = res.data.data.peserta.sex
          this.peserta.noMR = res.data.data.peserta.mr.noMR
          this.peserta.noTelepon = res.data.data.peserta.mr.noTelepon
          this.peserta.kode = res.status
          this.peserta.statusText = res.statusText
          this.peserta.umurSaatPelayanan = res.data.data.peserta.umur.umurSaatPelayanan
          this.peserta.umurSekarang = res.data.data.peserta.umur.umurSekarang
          this.peserta.kdProvider = res.data.data.peserta.provUmum.kdProvider
          this.peserta.nmProvider = res.data.data.peserta.provUmum.nmProvider
          this.isLoading = false
        } catch (err) {
          this.error = err
          this.isLoading = false
        }
      }
      this.isLoading = false
    }
  }
}
</script>

<style>
@import '../../assets/css/pages/public/cek-kepesertaan.css';
</style>
