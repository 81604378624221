import Api from './fatmahost/axiosInstance'

export default {
  profilesummery (data) {
    return Api.laravel.get('getbyProfileSummary', { params: { pin: data } })
  },
  profilereport (data) {
    return Api.laravel.get('getbyProfileReport', { params: { pin: data } })
  },
  store (data) {
    return Api.laravel.post('pegawaiKontak', data)
  },
  show (id) {
    return Api.laravel.get('getPegawaiKontak/', { params: { pegawai_id: id } })
  }
}
