<template>
  <div class="irjgh-trouble-anggota">
    <div v-show="isLoading">
        <div id="show-overlay"></div>
      <Loader :style="{ opacity: .5 }" />
    </div>
    <b-container fluid>
      <div class="bg-white custom-radius-top border mb-4">
        <div>
          <div class="py-2 px-2">
            <b-row class="align-items-center">
              <b-col cols="2">
                <div>
                  <h5 class="card-title text-dark m-0 p-0">Reservasi List Anggota </h5>
                </div>
              </b-col>
              <b-col>
                <div class="d-flex flex-row align-items-center mb-0 flex-wrap justify-content-end">
                  <div class="mr-1">
                    <div>
                      <b-form-input type="search" v-model="filter.nama" @input="tableRefresh" class="bg-white border border-dark custom-search-kuota" placeholder="Nama..."></b-form-input>
                    </div>
                  </div>
                  <div class="mr-1">
                    <div>
                      <b-form-input type="search" v-model="filter.norm" @input="tableRefresh" class="bg-white border border-dark custom-search-kuota" placeholder="Norm..."></b-form-input>
                    </div>
                  </div>
                  <!-- <div class="mr-1">
                    <div>
                      <b-form-input type="search" v-model="filter.nik" @input="tableRefresh" class="bg-white border border-dark custom-search-kuota" placeholder="NIK Akun..."></b-form-input>
                    </div>
                  </div> -->
                  <div class="mr-1">
                    <div>
                      <flat-pickr :config="config" v-model="filter.tanggal" value="" @on-change="convertDate($event, 'use')" placeholder="[ Tanggal Lahir ]" class="w-100 calender-small custom-flat-pickr m-0">
                      </flat-pickr>
                    </div>
                  </div>
                  <div class="mr-1">
                    <div>
                      <b-form-input type="search" v-model="filter.username" @input="tableRefresh" class="bg-white border border-dark custom-search-kuota" placeholder="Username Tertaut..."></b-form-input>
                    </div>
                  </div>
                  <div class="mr-1">
                    <div>
                      <b-button class="custom-radius py-2 px-3 rounded-1" variant="warning" @click="resetFilter"><i class="fas fa-sync-alt"></i> Reset Filter</b-button>
                    </div>
                  </div>
                </div>
              </b-col>
            </b-row>
          </div>
          <div class="table table-list-pasien mb-0">
            <b-table class="mb-0" ref="table" :busy="table.isBusy" :items="table.items" :fields="table.fields" :sort-by.sync="table.sortBy" :sort-desc.sync="table.sortDesc"
              :current-page="table.currentPage" :per-page="table.perPage" :filter="table.filter" @filtered="onFiltered" :tbody-tr-class="rowClass" :select-mode="table.selectMode" stacked="md"
              bordered hover small show-empty selectable>
              <template #table-busy>
                <div class="text-center my-2">
                  <b-spinner class="align-middle text-primary mr-2"></b-spinner>
                  <strong class="text-dark">Memuat Data...</strong>
                </div>
              </template>
              <template #cell(index)="data">
                <span>{{ (table.currentPage*table.perPage)-table.perPage + data.index + 1 }}</span>
              </template>
              <template #cell(hm.tanggal_lahir)="data">
                <span>{{ convertDate(columnDotsConvert(data.item, 'hm.tanggal_lahir'), 'convert') }}</span>
              </template>
              <template #cell(identitas)="data">
                <span class="text-capitalize" v-if="data.item.identitas === null || data.item.identitas === 'null'">-</span>
                <span class="text-capitalize" v-else>{{ data.item.identitas }}</span>
              </template>
              <template #cell(deskripsi)="data">
                <span class="text-capitalize" v-if="data.item.status === 1">{{ data.item.deskripsi }} <i :class="['ri-checkbox-circle-fill ri-lg', data.item.status === 1 ? 'text-success' : 'tezt-dark']"></i>
                </span>
                <span class="text-capitalize" v-else>{{ data.item.deskripsi }} <i class="ri-forbid-line ri-lg"></i>
                </span>
              </template>
              <template v-slot:cell(action)="data">
                <b-button variant="white" size="lg" @click="showModal(data.item.id, '1')">
                  <i class="ri-eye-fill ri-lg text-primary"></i>
                </b-button>
                <b-button variant="white" size="lg" @click="showModal(data.item.id, '2')" v-if="akses.bitcontrol1.charAt(4) === '1' || group === 1">
                  <i class="ri-user-follow-fill ri-lg text-success"></i>
                </b-button>
                <!-- <b-button variant="white" size="lg" @click="showModal(data.item.id, '3')" v-if="akses.bitcontrol1.charAt(4) === '1' || group === 1">
                  <i class="ri-shield-user-fill ri-lg text-warning"></i>
                </b-button> -->
              </template>
            </b-table>
          </div>
          <div class="bg-white shadow-sm pb-2 pt-2 pl-3 pr-3">
            <b-row class="align-items-center justify-content-between">
              <b-col cols="12" lg="3" class="mr-auto">
                <div class="d-flex align-items-center">
                  <div>
                    <b-pagination v-model="table.currentPage" :total-rows="table.totalRows" :per-page="table.perPage" size="sm" class="my-0">
                    </b-pagination>
                  </div>
                  <div class="border-left border-right px-3">
                    <span class="cursor-point" @click="tableRefresh">
                      <i class="fas fa-sync"></i>
                    </span>
                  </div>
                </div>
              </b-col>
              <b-col cols="12" lg="4" class="d-flex justify-content-end align-items-center">
                <div class="mr-3">
                  <b-form-select id="per-page-select" v-model="table.perPage" :options="table.pageOptions" size="sm"
                  ></b-form-select>
                </div>
                <div>
                  <p class="font-weight-normal mb-0 text-dark" v-if="table.totalRows">Menampilkan 1 -  {{ table.perPage }} dari {{ table.totalRows }}</p>
                </div>
              </b-col>
            </b-row>
          </div>
        </div>
      </div>
    </b-container>
    <b-modal id="modal-show-member" ref="modal-show-member" header-class="text-uppercase" title="ANGGOTA DETAIL" size="lg" centered :no-close-on-backdrop="true" :static="true" :no-close-on-esc="true" cancel-title="Tutup" ok-title="Tutup" ok-variant="secondary" :ok-only="true">
      <div class="mb-2">
        <b-row>
          <b-col lg="6">
            <div class="mb-0">
              <div class="border-bottom mb-3">
                <p class="text-secondary mb-1">Nama</p>
                <p class="h6 text-dark mb-0">{{ dataMember.nama }}</p>
              </div>
              <div class="border-bottom mb-3">
                <p class="text-secondary mb-1">Nomor Rekam Medis</p>
                <p class="h6 text-dark mb-0">{{ dataMember.norm }}</p>
              </div>
              <div class="border-bottom">
                <p class="text-secondary mb-1">Username Tertaut</p>
                <p class="h6 text-dark mb-0">{{ dataMember.username }}</p>
              </div>
            </div>
          </b-col>
          <b-col lg="6">
            <div class="mb-0">
              <div class="border-bottom mb-3">
                <p class="text-secondary mb-1">Tanggal Lahir</p>
                <p class="h6 text-dark mb-0">{{ dataMember.tanggal_lahir.split('-').reverse().join('-') }}</p>
              </div>
              <div class="border-bottom mb-3">
                <p class="text-secondary mb-1">Nomor Identitas Tertaut</p>
                <p class="h6 text-dark mb-0" v-if="dataMember.identitas !== 'null' || dataMember.identitas !== null">{{ dataMember.identitas }}</p>
                <p class="h6 text-dark mb-0" v-else>-</p>
              </div>
              <div class="border-bottom">
                <p class="text-secondary mb-1">Status</p>
                <p class="h6 text-dark text-capitalize mb-0">{{ dataMember.deskripsi }}</p>
              </div>
            </div>
          </b-col>
        </b-row>
      </div>
    </b-modal>
    <b-modal id="modal-status-member" ref="modal-status-member" header-class="text-uppercase" title="PERUBAHAN STATUS" size="md" centered :no-close-on-backdrop="true" :static="true" :no-close-on-esc="true" @ok="onSaveStatus" cancel-title="Tutup" ok-title="Simpan">
      <div class="mt-1">
        <div class="mb-2 border p-2">
          <div class="d-flex flex-row">
            <div class="mr-auto">
              <p class="m-0 p-0 text-secodanry text-uppercase">Nama</p>
            </div>
            <div>
              <p class="m-0 p-0 text-secodanry text-uppercase"><span class="font-weight-bold text-dark">{{ dataMember.nama }}</span></p>
            </div>
          </div>
          <div class="d-flex flex-row">
            <div class="mr-auto">
              <p class="m-0 p-0 text-secodanry text-uppercase">No. Rekam Medis</p>
            </div>
            <div>
              <p class="m-0 p-0 text-secodanry text-uppercase"><span class="font-weight-bold text-dark">{{ dataMember.norm }}</span></p>
            </div>
          </div>
          <div class="d-flex flex-row">
            <div class="mr-auto">
              <p class="m-0 p-0 text-secodanry text-uppercase">Tanggal Lahir</p>
            </div>
            <div>
              <p class="m-0 p-0 text-secodanry text-uppercase"><span class="font-weight-bold text-dark">{{ dataMember.tanggal_lahir.split('-').reverse().join('-') }}</span></p>
            </div>
          </div>
        </div>
        <b-form-group class="m-0 p-0">
          <b-form-select v-model="dataMember.status" :options="selected.status"></b-form-select>
        </b-form-group>
      </div>
      <div :class="['mt-2 px-2', statcode === 0 ? 'bg-primary ' : 'bg-danger']" v-if="statcode !== null">
        <div class="d-flex flex-row justify-content-between">
          <div>
            <p class="small text-right m-0 p-0">{{ message }}</p>
          </div>
          <div @click="clearMessage">
            <p class="small text-right m-0 p-0"><i class="fas fa-times-circle"></i></p>
          </div>
        </div>
      </div>
    </b-modal>
    <!-- <b-modal id="modal-username-member" ref="modal-username-member" header-class="text-uppercase" title="PERUBAHAN USERNAME" size="md" centered :no-close-on-backdrop="true" :static="true" :no-close-on-esc="true" @ok="onSaveUsername" cancel-title="Tutup" ok-title="Simpan">
      <div class="mt-1">
        <div class="mb-2 border p-2">
          <div class="d-flex flex-row">
            <div class="mr-auto">
              <p class="m-0 p-0 text-secodanry text-uppercase">Nama</p>
            </div>
            <div>
              <p class="m-0 p-0 text-secodanry text-uppercase"><span class="font-weight-bold text-dark">{{ dataMember.nama }}</span></p>
            </div>
          </div>
          <div class="d-flex flex-row">
            <div class="mr-auto">
              <p class="m-0 p-0 text-secodanry text-uppercase">No. Rekam Medis</p>
            </div>
            <div>
              <p class="m-0 p-0 text-secodanry text-uppercase"><span class="font-weight-bold text-dark">{{ dataMember.norm }}</span></p>
            </div>
          </div>
          <div class="d-flex flex-row">
            <div class="mr-auto">
              <p class="m-0 p-0 text-secodanry text-uppercase">Tanggal Lahir</p>
            </div>
            <div>
              <p class="m-0 p-0 text-secodanry text-uppercase"><span class="font-weight-bold text-dark">{{ dataMember.tanggal_lahir.split('-').reverse().join('-') }}</span></p>
            </div>
          </div>
        </div>
        <b-form-input
          class="form-control-custom-member text-dark"
          v-model="dataMember.username"
          placeholder="Username..."
          type="text"
          size="sm"
        ></b-form-input>
        <small class="text-danger font-weight-bold font-italic">*termasuk semua anggota yang tertaut ke username tersebut akan ikut berubah</small>
      </div>
      <div :class="['mt-2 px-2', statcode === 0 ? 'bg-primary ' : 'bg-danger']" v-if="statcode === 0">
        <div class="d-flex flex-row justify-content-between">
          <div>
            <p class="text-right m-0 p-0">{{ message }}</p>
          </div>
          <div @click="clearMessage">
            <p class="text-right m-0 p-0"><i class="fas fa-times-circle"></i></p>
          </div>
        </div>
      </div>
    </b-modal> -->
  </div>
</template>
<script>
import { xray } from '../../../config/pluginInit.js'
import Services from '../../../services/fatmahost/member/index.js'
import flatPickr from 'vue-flatpickr-component'
import 'flatpickr/dist/flatpickr.css'

export default {
  name: 'MemberList',
  components: {
    flatPickr
  },
  data () {
    return {
      isLoading: false,
      message: '',
      statcode: null,
      showId: '',
      table: {
        isBusy: false,
        items: this.listMember,
        fields: [
          { label: 'NO.', key: 'index', class: 'text-lg-center' },
          { label: 'NAMA', key: 'hm.nama', sortable: true },
          { label: 'NO. RM', key: 'hm.norm', sortable: true },
          // { label: 'NIK AKUN', key: 'identitas', sortable: true },
          { label: 'TGL. LAHIR', key: 'hm.tanggal_lahir', sortable: true },
          { label: 'USERNAME TERTAUT', key: 'up.username', sortable: true },
          { label: 'STATUS', key: 'deskripsi', class: 'text-center', sortable: true },
          { label: 'AKSI', key: 'action', class: 'text-center' }
        ],
        filter: null,
        filterOn: [],
        pageOptions: [10, 50, 100, 500],
        currentPage: null,
        perPage: null,
        totalRows: null,
        sortBy: '',
        sortDesc: false,
        selectMode: 'single'
      },
      filter: {
        username: '',
        nik: '',
        nomor: '',
        norm: '',
        nama: '',
        tanggal: '',
        tanggalReverse: ''
      },
      selected: {
        status: [
          { value: 1, text: 'Aktif' },
          { value: 0, text: 'Tidak Aktif' }
        ]
      },
      dataMember: {
        id: null,
        nama: '',
        norm: null,
        status: null,
        username: '',
        deskripsi: '',
        identitas: '',
        patient_id: null,
        tanggal_lahir: ''
      },
      config: {
        dateFormat: 'd-m-Y',
        disableMobile: true
      },
      akses: '',
      group: ''
    }
  },
  mounted () {
    xray.index()
    this.akses = xray.bitcontrol(this.$route.name)
    this.group = xray.currentUser().id_grp
  },
  methods: {
    tableRefresh () {
      this.table.currentPage = 1
      this.$refs.table.refresh()
    },
    columnDotsConvert (column, desc) {
      return column[desc]
    },
    convertDate (value, param) {
      const date = new Date(value)
      const day = String(date.getDate()).padStart(2, '0')
      const month = String(date.getMonth() + 1).padStart(2, '0')
      const year = String(date.getFullYear())
      if (param === 'convert') {
        return day + '-' + month + '-' + year
      } else if (param === 'use') {
        this.filter.tanggalReverse = year + '-' + month + '-' + day
        this.tableRefresh()
      }
    },
    onFiltered (filteredItems) {
      this.table.totalRows = filteredItems.length
    },
    async listMember (ctx) {
      this.table.isBusy = true
      //
      let tglLahir = this.filter.tanggalReverse === 'NaN-NaN-NaN' ? '' : this.filter.tanggalReverse
      let obj = 'profil'
      let vParams3 = `{"xparam0":"${this.filter.username}","xparam1":"${this.filter.nik}","xparam2":"${this.filter.nomor}","xparam3":"${this.filter.norm}","xparam4":"${this.filter.nama}","xparam5":"${tglLahir}","xparam6":""}`
      //
      let sortfield = ''
      let sorting = ''
      if (ctx.sortBy) {
        sortfield = ctx.sortBy
      } else {
        sortfield = ''
      }
      if (ctx.sortDesc === true) {
        sorting = 'ASC'
      } else if (ctx.sortDesc === false) {
        sorting = 'DESC'
      } else {
        sorting = ''
      }
      let vParams4 = `{"xparam0":"${sortfield}","xparam1":"${sorting}"}`
      try {
        const response = await Services.findAll(ctx.currentPage - 1, ctx.perPage, obj, vParams3, vParams4)
        if (response.data.data) {
          this.table.isBusy = false
          this.table.totalRows = response.data.pagination.total
          this.table.currentPage = response.data.pagination.current
          this.table.perPage = response.data.pagination.perPage
          return response.data.data
        } else {
          this.table.isBusy = false
          this.message = response.data.message
          return []
        }
      } catch (error) {
        this.table.isBusy = false
        this.message = error.message
        return []
      }
    },
    rowClass (item, type) {
      if (!item || type !== 'row') return
      if (item.status !== 1) return 'text-secondary'
    },
    async showModal (id, param) {
      this.isLoading = true
      this.showId = id
      this.clearMessage()
      //
      let obj = 'profil'
      let params = obj
      try {
        const response = await Services.show(id, obj, params)
        if (response.data.statcode === 0) {
          this.isLoading = false
          this.statcode = response.data.statcode
          this.message = response.data.message
          this.dataMember.id = response.data.data[0].id
          this.dataMember.nama = response.data.data[0].nama
          this.dataMember.norm = response.data.data[0].norm
          this.dataMember.status = response.data.data[0].status
          this.dataMember.username = response.data.data[0].username
          this.dataMember.deskripsi = response.data.data[0].deskripsi
          this.dataMember.identitas = response.data.data[0].identitas
          this.dataMember.patient_id = response.data.data[0].patient_id
          this.dataMember.tanggal_lahir = response.data.data[0].tanggal_lahir
        } else {
          this.isLoading = false
          this.statcode = response.data.statcode
          this.message = response.data.message
        }
      } catch (error) {
        this.isLoading = false
        this.statcode = 20001
        this.message = error.message
      }
      if (param === '1') {
        this.$refs['modal-show-member'].show()
      } else if (param === '2') {
        if (param === '2') this.$refs['modal-status-member'].show()
      } else if (param === '3') {
        if (param === '3') this.$refs['modal-username-member'].show()
      }
    },
    async onSaveStatus (bvModalEvent) {
      bvModalEvent.preventDefault()
      this.isLoading = true
      this.clearMessage()
      const data = {
        object: 'profil',
        status: this.dataMember.status.toString()
      }
      try {
        const response = await Services.status(this.dataMember.id.toString(), data)
        if (response.data.statcode === 0) {
          this.isLoading = false
          this.statcode = response.data.statcode
          this.message = response.data.message
          this.tableRefresh()
        } else {
          this.isLoading = false
          this.statcode = response.data.statcode
          this.message = response.data.message
        }
      } catch (error) {
        this.isLoading = false
        this.statcode = 20001
        this.message = error.message
      }
    },
    clearMessage () {
      this.message = ''
      this.statcode = null
    },
    resetFilter () {
      this.filter.username = ''
      this.filter.nik = ''
      this.filter.nomor = ''
      this.filter.norm = ''
      this.filter.nama = ''
      this.filter.tanggal = ''
      this.filter.tanggalReverse = ''
      this.tableRefresh()
    }
  }
}
</script>
<style>
.irjgh-trouble-anggota .wrn-ungu {
  color: purple;
}
.irjgh-trouble-anggota .custom-flatpicker {
  height: 38px;
  border: 1px solid rgba(0, 0, 0, 0.13);
  border-radius: 5px;
  padding-left: 10px;
}
.irjgh-trouble-anggota .custom-select {
  border: 1px solid rgba(0, 0, 0, 0.13);
  min-height: 38px;
  font-size: 13px!important;
}
.irjgh-trouble-anggota .bg-filter {
  border: 1px solid rgba(0, 0, 0, 0.13);
  background-color: #089bab1e;
}
.irjgh-trouble-anggota .custom-search-kuota {
  border-radius: 5px!important;
  font-size: 13px!important;
  height: 38px!important;
}
.irjgh-trouble-anggota .table td {
    font-size: 12px;
    font-weight: 500;
    padding-top: 0px;
    padding-bottom: 0px;
}
.irjgh-trouble-anggota .table th {
    font-size: 13px;
    font-weight: 400;
}
.irjgh-trouble-anggota .page-link {
  background: transparent!important;
    border: none!important;
    color: gray!important;
    margin-right: 15px;
}
.irjgh-trouble-anggota .page-item.active .page-link {
    background-color: transparent;
    border-color: transparent;
    color: #089bab!important;
    font-weight: bold;
}
.irjgh-trouble-anggota .cursor-point {
    cursor: pointer;
}
.cstm-slct-irj-Admin {
  font-size: 13px!important;
  height: 38px!important;
  border-radius: 5px;
}
.irjgh-trouble-anggota .cstm-cntrl-irj-Admin {
  color: black!important;
  border-radius: 5px!important;
  font-size: 12px!important;
  height: 4px!important;
}
.irjgh-trouble-anggota .custom-fontsize {
  font-size: 13px;
}
#modal-username-member .form-control-custom-member {
  height: 38px!important;
  background-color: transparent;
  border-radius: .1rem!important;
}
.irjgh-trouble-anggota .custom-cursor {
  cursor: pointer;
}
.irjgh-trouble-anggota .custom-radius {
  border-radius: .2rem!important;
}
.irjgh-trouble-anggota .custom-radius-top {
  border-top-left-radius: .5rem!important;
  border-top-right-radius: .5rem!important;
}
#modal-status-member .modal-body, #modal-username-member .modal-body {
    padding: .5rem;
}
.irjgh-trouble-anggota .custom-flat-pickr {
    border: 1px solid rgb(233, 233, 233);
    height: 38px;
    padding-left: 10px;
    padding-top: 5px;
    padding-bottom: 5px;
    border: 1px solid rgb(192, 192, 192)!important;
    border-radius: 5px;
    font-size: 12px;
    color: black;
}
</style>
