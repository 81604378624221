<template>
  <b-container fluid class="penjadwalan-cathlab">
    <div v-show="isLoading">
      <div id="show-overlay"></div>
      <Loader :style="{ opacity: .5 }" />
    </div>
    <b-row>
      <b-col md="12">
        <iq-card class="p-4">
          <b-row class="mb-1">
            <b-col cols="12" lg="4">
              <div>
                <b-form-group label="Tanggal Awal">
                  <template>
                    <flat-pickr v-model="tanggal_awal" @on-change="convertTgl" :config="config" placeholder="Pilih Tanggal" class="w-100 tgl-height-cstm custom-flatpicker calender-small">
                    </flat-pickr>
                  </template>
                </b-form-group>
              </div>
            </b-col>
            <b-col cols="12" lg="4">
              <div>
                <b-form-group label="Tanggal Akhir">
                  <template>
                    <flat-pickr v-model="tanggal_akhir" @on-change="convertTgl" :config="config" placeholder="Pilih Tanggal" class="w-100 tgl-height-cstm custom-flatpicker calender-small">
                    </flat-pickr>
                  </template>
                </b-form-group>
              </div>
            </b-col>
            <b-col cols="12" lg="2">
              <div>
                <b-form-group label="Filter" v-if="sheets[0].data.length === 0">
                  <b-button variant="warning" @click="getExport" class="btn-block btn-height-cstm"><i class="fas fa-filter"></i> Filter</b-button>
                </b-form-group>
                <b-form-group label="Download" v-else-if="sheets[0].data.length !== 0">
                  <xlsx-workbook>
                      <xlsx-sheet :collection="sheet.data" v-for="sheet in sheets" :key="sheet.name" :sheet-name="sheet.name" />
                      <xlsx-download>
                        <b-button variant="success" class="btn-block"><i class="fas fa-file-excel"></i> Download</b-button>
                      </xlsx-download>
                    </xlsx-workbook>
                </b-form-group>
              </div>
            </b-col>
            <b-col cols="12" lg="2">
              <div>
                <b-form-group label="Bersihkan Filter">
                <b-button variant="danger" @click="filterclear" class="btn-block btn-height-cstm"><i class="fas fa-sync"></i> Bersihkan Filter</b-button>
                </b-form-group>
              </div>
            </b-col>
          </b-row>
        </iq-card>
        <iq-card class="py-4 px-4">
          <template>
            <div class="pb-4">
              <b-row class="justify-content-between">
                <b-col md="4" class="text-center">
                  <form class="searchbox">
                    <input type="search" v-model="filter" class="form-control" id="filter-input"
                      placeholder="Cari No. Pendaftaran">
                  </form>
                </b-col>
                <b-col md="3" class="text-right d-flex align-items-center justify-content-end">
                  <div class="mr-3">
                    <b-button :href="href" @click="toDash" variant="secondary"><i class="fas fa-chart-bar"></i> Dashboard</b-button>
                  </div>
                  <div class="mr-0">
                    <b-button :href="href" @click="tambah" variant="primary"><i class="fas fa-plus"></i> Buat Jadwal</b-button>
                  </div>
                </b-col>
              </b-row>
            </div>
          </template>
          <template>
            <b-row>
              <b-col md="12">
                <b-table ref="table" :busy="isBusy" :items="items" :fields="fields" :filter="filter"
                @filtered="onFiltered" :current-page="currentPage" :per-page="perPage" stacked="md" bordered hover
                show-empty>
                <template v-slot:cell(norm)="data">
                  <span>{{data.item.norm | norm}}</span>
                </template>
                <template v-slot:cell(tanggal_lahir)="data">
                  <span>{{data.item.tanggal_lahir | formatTgl}}</span>
                </template>
                <template v-slot:cell(tanggal)="data">
                  <span>{{data.item.tanggal | formatTgl}}</span>
                </template>
                <template v-slot:cell(status_batal)="data">
                  <span class="text-danger font-weight-bold" v-if="data.item.status_batal !== ''">{{data.item.status_batal}}</span>
                  <span class="text-dark" v-else>-</span>
                </template>
                <template v-slot:cell(action)="data">
                  <b-button v-b-tooltip.hover title="Detail" variant=" iq-bg-primary mr-1 mb-1" size="lg" @click="view(data.item.id)">
                    <i class="far fa-eye"></i>
                  </b-button>
                  <b-button v-b-tooltip.hover title="Batal" variant=" iq-bg-danger mr-1 mb-1" size="lg" @click="modalBatal(data.item.id)">
                    <i class="fas fa-window-close"></i>
                  </b-button>
                </template>
              </b-table>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
              </b-col>
              <b-col>
              </b-col>
              <b-col sm="12" md="3" class=" my-1">
                <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage" align="fill" size="sm"
                  class="my-0"></b-pagination>
              </b-col>
            </b-row>
          </template>
        </iq-card>
      </b-col>
    </b-row>
    <b-modal ref="modal" size="md" title="Informasi" ok-title="Oke" :ok-only=true :hide-header=true centered>
      <div class="text-center px-lg-4 px-2">
        <lottie :options="defaultOptions" :autoPlay="true" :width="250" :height="250" />
        <p class="h6 text-success font-weight-bold text-uppercase mb-0">{{notif}}</p>
      </div>
    </b-modal>
    <b-modal ref="modal-gagal" size="md" title="Informasi" ok-title="Oke" :ok-only=true :hide-header=true centered>
      <div class="text-center px-lg-4 px-2">
        <lottie :options="defaultOptions2" :autoPlay="true" :width="250" :height="250" />
        <p class="h6 text-danger font-weight-bold text-uppercase mb-0">{{notif}}</p>
      </div>
    </b-modal>
    <b-modal ref="modal-batal" size="lg" title="Informasi" :hide-footer=true :no-close-on-backdrop="true" :hide-header="true" :static="true" centered>
      <div class="px-lg-4 px-2 py-3">
        <p class="h5 text-dark font-weight-bold mb-3">Alasan Batal :</p>
        <b-input type="text" placeholder="Alasan (Wajib Diisi)" v-model="formBatal.keterangan" class="form-control"></b-input>
      </div>
      <hr>
      <div class="px-lg-4 px-2 py-1 text-right">
        <b-button variant="danger" class="mr-3" @click="stsBtlBtnCancel">Batal</b-button>
        <b-button disabled variant="primary" @click="cancelLab" v-if="formBatal.keterangan === ''">Konfirmasi</b-button>
        <b-button variant="primary" @click="cancelLab" v-else>Konfirmasi</b-button>
      </div>
      <div class="text-center px-lg-4 px-2 py-1 text-right" v-if="notif !== ''">
        <p class="h6 mb-0 text-success">{{notif}}</p>
      </div>
      <div class="text-center px-lg-4 px-2 py-1 text-right" v-if="error.length !== 0">
        <p class="h6 mb-0 text-success">{{error}}</p>
      </div>
    </b-modal>
  </b-container>
</template>
<script>
import { xray } from '../../../config/pluginInit'
import Loader from '../../../components/xray/loader/Loader'
import Lab from '../../../services/laboratorium'
import 'sweetalert2/src/sweetalert2.scss'
import flatPickr from 'vue-flatpickr-component'
import 'flatpickr/dist/flatpickr.css'
import XlsxWorkbook from 'vue-xlsx/dist/components/XlsxWorkbook'
import XlsxSheet from 'vue-xlsx/dist/components/XlsxSheet'
import XlsxDownload from 'vue-xlsx/dist/components/XlsxDownload'
import Lottie from 'vue-lottie'
import animationLogo from '../../../assets/lottie/checked.json'
import animationLogo2 from '../../../assets/lottie/failed.json'

export default {
  name: 'UiDataTable',
  components: {
    flatPickr,
    Loader,
    XlsxWorkbook,
    XlsxSheet,
    XlsxDownload,
    Lottie
  },
  mounted () {
    xray.index()
  },
  data () {
    return {
      isBusy: false,
      isLoading: false,
      tanggal_awal: '',
      tanggal_akhir: '',
      formData: {
        tanggal_awal: '',
        tanggal_akhir: ''
      },
      idbatal: null,
      formBatal: {
        status_batal: 2,
        keterangan: ''
      },
      config: {
        dateFormat: 'd-m-Y'
      },
      selectedKlaim: [],
      comboJenisKlaim: [
        { name: 'Rawat Jalan', id: 1 },
        { name: 'Rawat Inap', id: 2 },
        { name: 'IGD', id: 3 }
      ],
      error: [],
      notif: '',
      fields: [
        { label: 'ID', key: 'id', class: 'text-center' },
        { label: 'NORM', key: 'norm', class: 'text-left' },
        { label: 'NAMA', key: 'nama', class: 'text-left' },
        { label: 'TGL. LAHIR', key: 'tanggal_lahir', class: 'text-left' },
        { label: 'NO. RESERVASI', key: 'nomor_reservasi', class: 'text-left' },
        { label: 'NOMOR', key: 'nomor', class: 'text-left' },
        { label: 'TANGGAL', key: 'tanggal', class: 'text-left' },
        { label: 'STATUS', key: 'status', class: 'text-left' },
        { label: 'STATUS BATAL', key: 'status_batal', class: 'text-center' },
        { label: 'Aksi', key: 'action', class: 'text-center' }
      ],
      items: this.show,
      filterOn: [],
      filter: null,
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      sheets: [
        {
          name: 'SheetOne',
          data: []
        }
      ],
      hideExcl: 'd-none',
      href: '',
      defaultOptions: { animationData: animationLogo, loop: true },
      defaultOptions2: { animationData: animationLogo2, loop: true }
    }
  },
  methods: {
    convertTgl () {
      var tglAwl = this.tanggal_awal
      var tglAhr = this.tanggal_akhir
      this.formData.tanggal_awal = tglAwl.split('-').reverse().join('-')
      this.formData.tanggal_akhir = tglAhr.split('-').reverse().join('-')
    },
    async show (ctx) {
      if (ctx.filter === '') {
        ctx.filter = null
      }
      this.isBusy = true
      try {
        const res = await Lab.showAll(ctx.currentPage, ctx.filter)
        this.totalRows = res.data.data.total
        this.idbatal = res.data.data.data[0].id
        this.isBusy = false
        return res.data.data.data
      } catch (err) {
        this.isBusy = false
      }
    },
    async getExport () {
      this.isLoading = true
      const tglAwl = this.formData.tanggal_awal
      const tglAhr = this.formData.tanggal_akhir
      if (tglAwl !== '' && tglAhr !== '') {
        await Lab.showExport(tglAwl, tglAhr).then(response => {
          this.isLoading = false
          this.sheets[0].data = response.data.data
          this.notif = response.data.message
          this.$refs['modal'].show()
        }).catch(error => {
          this.isLoading = false
          this.notif = error.response.data.message
          this.$refs['modal-gagal'].show()
        })
      } else {
        this.isLoading = false
        this.notif = 'Tanggal Tidak Boleh Kosong'
        this.$refs['modal-gagal'].show()
      }
    },
    async cancelLab () {
      const id = this.idbatal
      const data = this.formBatal
      await Lab.cancel(id, data).then(response => {
        this.notif = response.data.message
        this.$refs['modal-batal'].hide()
        location.reload()
      }).catch(error => {
        this.error = error.response.data.message
      })
    },
    modalBatal (id) {
      this.idbatal = id
      this.$refs['modal-batal'].show()
    },
    onFiltered (filteredItems) {
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
    filterup () {
      this.listImport()
      this.$refs.table.refresh()
    },
    filterclear () {
      this.tanggal_awal = ''
      this.tanggal_akhir = ''
      this.formData.tanggal_awal = ''
      this.formData.tanggal_akhir = ''
      this.sheets[0].data = []
    },
    tambah () {
      this.$router.push({ name: 'laboratorium.add' })
    },
    toDash () {
      this.$router.push({ name: 'laboratorium.dash' })
    },
    view (id) {
      this.$router.replace({
        name: 'laboratorium.view', params: { id }
      })
    },
    stsBtlBtnCancel () {
      this.$refs['modal-batal'].hide()
    }
  }
}
</script>
<style>
.penjadwalan-cathlab .multiselect__tags {
  border: 1px solid #d7dbda!important;
  border-radius: 10px!important;
  background: #ceebee;
  height: 45px;
}
.penjadwalan-cathlab .custom-flatpicker {
  min-height: 45px;
  height: 45px;
  border: 1px solid #d7dbda;
  padding: 10px;
  border-radius: 10px;
  cursor: pointer;
  background: #ceebee;
  font-weight: bold;
}
.penjadwalan-cathlab .multiselect__single {
  background: #ceebee;
}
.flatpickr-day.selected {
  background: #089bab!important;
}
.flatpickr-day.today {
    background: #089bab!important;
    color: white;
}
.penjadwalan-cathlab .btn-height-cstm {
  line-height: 25px!important;
}
.penjadwalan-cathlab .tgl-height-cstm {
  line-height: 40px!important;
  height: 40px!important;
  min-height: 40px!important;
}
</style>
