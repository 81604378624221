<template>
  <div id="kuota-dokter" class="kuota-dokter">
    <b-container fluid class="mb-5">
      <iq-card class="mb-2">
        <div  class="iq-email-to-list pt-3 pl-3 pr-3" id="header-table-filter">
          <div class="px-2 mb-3">
            <p class="text-dark h5 mb-0">KUOTA DOKTER REHABILITASI MEDIK</p>
          </div>
          <div class="d-flex flex-row align-items-center mb-0 py-1 px-1 bg-filter flex-wrap">
            <div cols="2" class="mr-1">
              <b-form-group class="mb-0">
                <b-form-select class="cstm-cntrl-kuota" style="font-size: 13px;" plain v-model="selected.filterType" :options="combo.filterType" @input="actionrefresh" size="lg">
                </b-form-select>
              </b-form-group>
              <!-- <b-form-select class="cstm-slct-kuota" v-model="selected.filterType" :options="combo.filterType"></b-form-select> -->
            </div>
            <div class="mr-1">
              <b-form-select class="cstm-cntrl-kuota" style="font-size: 13px;" plain v-model="selected.filterHari" :options="combo.hariFil" @input="actionrefresh" size="lg">
              </b-form-select>
            </div>
            <div cols="2" class="mr-1">
              <b-input-group>
                <b-form-input class="cstm-cntrl-kuota" style="font-size: 13px;" id="filter-input" v-model="table.filter" @keypress="isAlphabetKey" type="search" placeholder="[ Pencarian ]"></b-form-input>
              </b-input-group>
            </div>
            <div class="mr-lg-auto">
                <b-button v-b-tooltip.top title="Reset" size="sm" variant="warning" class="border-0 rounded-1 py-2" style="border-radius: 5px;" @click="filterBtn"><i class="fas fa-undo"></i> Reset Filter</b-button>
            </div>
            <div class="mt-1 mt-lg-0 ml-1 ml-lg-0">
              <b-button variant="primary" class="py-2" style="border-radius: 5px;"  @click="handleNewKuota"><i class="fas fa-plus-circle "></i>Tambah</b-button>
              <!-- @click="tambah" -->
            </div>
          </div>
        </div>
          <div class="iq-email-listbox pl-3 pr-3 pb-3">
            <div class="table mb-0">
              <b-table
                class="mb-0 pointer-table"
                ref="table"
                :busy="table.isBusy"
                :items="table.items"
                :fields="table.fields"
                :current-page="table.currentPage"
                :per-page="table.perPage"
                :filter="table.filter"
                @filtered="onFiltered"
                :sort-by.sync="table.sortBy"
                :sort-desc.sync="table.sortDesc"
                stacked="md"
                bordered hover show-empty small striped>
                <template #table-busy>
                  <div class="text-center my-2">
                    <b-spinner class="align-middle text-primary mr-2"></b-spinner>
                    <strong class="text-dark">Memuat Data...</strong>
                  </div>
                </template>
                <template #cell(index)="data">
                  {{ (table.currentPage*table.perPage)-table.perPage + data.index + 1 }}
                </template>
                <template #cell(action)="data">
                  <b-button
                    @click="handleShowDtl(data.item.id_dokter)"
                    variant=" mr-1 mb-1" size="lg">
                    <i class="fas fa-edit text-primary"></i>
                  </b-button>
                  <b-button
                    @click="deleteModal(data.item.id_dokter, data.item.ruangan)"
                    variant=" mr-1 mb-1" size="lg">
                    <i class="fas fa-trash-alt text-danger"></i>
                  </b-button>
                </template>
              </b-table>
            </div>
            <div class="bg-white border pb-2 pt-2 pl-3 pr-3">
              <b-row class="align-items-center justify-content-between">
                <b-col cols="12" lg="3" class="mr-auto">
                  <div class="d-flex align-items-center">
                    <div>
                      <b-pagination v-model="table.currentPage" :total-rows="table.totalRows" :per-page="table.perPage" size="sm" class="my-0">
                      </b-pagination>
                    </div>
                    <div class="border-left border-right px-3">
                      <span class="cursor-point" @click="tableRefresh">
                        <i class="fas fa-sync text-primary"></i>
                      </span>
                    </div>
                  </div>
                </b-col>
                <b-col cols="12" lg="4" class="d-flex justify-content-end align-items-center">
                  <div class="mr-3">
                    <b-form-select id="per-page-select" v-model="table.perPage" :options="table.pageOptions" size="sm"
                    ></b-form-select>
                  </div>
                  <div>
                    <p class="font-weight-normal mb-0 text-dark" v-if="table.totalRows">Menampilkan 1 -  {{ table.perPage }} dari {{ table.totalRows }}</p>
                  </div>
                </b-col>
              </b-row>
            </div>
          </div>
      </iq-card>
    </b-container>
    <b-modal id="modal-kuota-add" ref="modal-new-kuota" size="xl" title="Tambah Kuota Dokter Rehabilitasi Medik" cancel-title="Tutup" :no-close-on-backdrop="true" :static="true" :no-close-on-esc="true"
    cancel-variant="danger" @close="clearAll" @cancel="clearAll" @ok="handleSubmit" ok-title="Simpan" :hide-header=false centered>
      <div class="mb-1">
        <b-row class="mb-2">
          <b-col>
            <b-row>
              <b-col cols="3" class="pr-1">
                <b-form-group label="Poli" class="mb-0">
                  <multiselect  v-model="selected.ruangan" :show-labels="false" :allow-empty="false"
                    @input="valRuang($event, 't')" label="deskripsi" placeholder="[Poli]" :options="combo.ruangan"
                    :searchable="true">
                  </multiselect>
                </b-form-group>
              </b-col>
              <b-col cols="5" class="pr-1 pl-1">
                <b-form-group label="Dokter" class="mb-0">
                  <multiselect v-model="selected.dokter" :show-labels="false" :allow-empty="false"
                    @input="valDokter($event, 't')" label="nama" placeholder="[Dokter]" :options="combo.dokter"
                    :searchable="true">
                  </multiselect>
                </b-form-group>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
        <hr class="m-0">
        <div>
          <b-row>
            <b-col cols="12">
              <div class="table-responsive-xl">
                <table class="table bg-white table-bordered table-sm m-0 p-0">
                  <thead>
                    <tr>
                      <th scope="col" style="vertical-align: center;" class="text-center">
                        <p class="mb-0 font-weight-normal text-center">Nomor</p>
                      </th>
                      <th scope="col" style="vertical-align: center;" class="text-center">
                        <p class="mb-0 font-weight-normal text-center">Hari</p>
                      </th>
                      <th scope="col" style="vertical-align: center;" class="text-center">
                        <p class="mb-0 font-weight-normal text-center">Jam Mulai</p>
                      </th>
                      <th scope="col" style="vertical-align: center;" class="text-center">
                        <p class="mb-0 font-weight-normal text-center">Jam Akhir</p>
                      </th>
                      <th scope="col" style="vertical-align: center;" class="text-center">
                        <p class="mb-0 font-weight-normal text-center">Kuota Total</p>
                      </th>
                      <th scope="col" style="vertical-align: center;" class="text-center">
                        <p class="mb-0 font-weight-normal text-center">Kuota Online</p>
                      </th>
                      <th scope="col" style="vertical-align: center;" class="text-center">
                        <p class="mb-0 font-weight-normal text-center">Kuota Bridging</p>
                      </th>
                      <th scope="col" style="vertical-align: center;" class="text-center">
                        <p class="mb-0 font-weight-normal text-center">Kuota Sisa</p>
                      </th>
                      <!-- <th scope="col" style="vertical-align: center;" class="text-center">
                        <p class="mb-0 font-weight-normal text-center">Menit Layanan</p>
                      </th> -->
                      <th scope="col" style="vertical-align: center;" class="text-center">
                        <p class="mb-0 font-weight-normal text-center">Tanggal Tampil</p>
                      </th>
                      <th scope="col" style="vertical-align: center;" class="text-center">
                        <p class="mb-0 font-weight-normal text-center">Status Aktif</p>
                      </th>
                      <th scope="col" style="vertical-align: center;" class="text-center">
                        <b-button variant="primary" @click="addRow('t')">
                          <p class="h5 mb-0">+</p>
                        </b-button>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(k, index) in kuota.detail" v-bind:key="k.codex">
                      <td class="py-3">
                        <div class="w-100">
                          <p class="text-center font-weight-bold mb-0">{{ index + 1 }}</p>
                        </div>
                      </td>
                      <td class="col-2">
                        <div class="w-100">
                          <multiselect v-model="selected.hari[index].detail" :show-labels="false" :allow-empty="false"
                            label="deskripsi" placeholder="[Hari]" :options="combo.hari" @input="valHari($event, index, 't')"
                            :searchable="true">
                          </multiselect>
                        </div>
                      </td>
                      <td>
                        <div class="w-100 d-flex align-items-center">
                          <b-form-input class="custom-search-kuota text-center" maxlength="2" v-model="display.jamAwal[index].first" @keypress="isNumberKey" @input="jamAwal($event, index, 't')" type="text" placeholder="_ _"></b-form-input>
                          <span class="mx-1">:</span>
                          <b-form-input class="custom-search-kuota text-center" maxlength="2" v-model="display.jamAwal[index].last" @keypress="isNumberKey" @input="jamAwal($event, index, 't')" type="text" placeholder="_ _"></b-form-input>
                        </div>
                      </td>
                      <td>
                        <div class="w-100 d-flex align-items-center">
                          <b-form-input class="custom-search-kuota text-center" maxlength="2" v-model="display.jamAkhir[index].first" @input="jamAkhir($event, index, 't')" @keypress="isNumberKey" type="text" placeholder="_ _"></b-form-input>
                          <span class="mx-1">:</span>
                          <b-form-input class="custom-search-kuota text-center" maxlength="2" v-model="display.jamAkhir[index].last" @input="jamAkhir($event, index, 't')" @keypress="isNumberKey" type="text" placeholder="_ _"></b-form-input>
                        </div>
                      </td>
                      <td class="col-1">
                        <div class="w-100">
                          <b-form-input class="custom-search-kuota text-center" maxlength="3" v-model="kuota.detail[index].quota_total" @keypress="isNumberKey" @input="totalQ(index, 't')" type="text" placeholder="Kuota Online"></b-form-input>
                        </div>
                      </td>
                      <td class="col-1">
                        <div class="w-100">
                          <b-form-input class="custom-search-kuota text-center" maxlength="3" v-model="kuota.detail[index].quota_online" @keypress="isNumberKey" @input="totalQ(index, 't')" type="text" placeholder="Kuota Online"></b-form-input>
                        </div>
                      </td>
                      <td class="col-1">
                        <div class="w-100">
                          <b-form-input class="custom-search-kuota text-center" maxlength="3" v-model="kuota.detail[index].quota_bridging" @keypress="isNumberKey" @input="totalQ(index, 't')" type="text" placeholder="Kuota Bridging"></b-form-input>
                        </div>
                      </td>
                      <td class="col-1">
                        <div class="w-100">
                          <b-form-input class="custom-search-kuota text-center" v-model="display.kuota_sisa[index].value" @keypress="isNumberKey" @input="evtKuota(index)" type="text" placeholder="Kuota Sisa"></b-form-input>
                        </div>
                      </td>
                      <!-- <td class="col-1">
                        <div class="w-100">
                          <b-form-input class="custom-search-kuota text-center" maxlength="2" v-model="kuota.detail[index].menit_layanan" @keypress="isNumberKey" type="text" placeholder="Lama Layanan"></b-form-input>
                        </div>
                      </td> -->
                      <td class="col-1">
                        <div class="w-100">
                          <b-form-input class="custom-search-kuota text-center" maxlength="3" v-model="kuota.detail[index].max_hari_reservasi" @keypress="isNumberKey" type="text" placeholder="Tanggal Tampil"></b-form-input>
                        </div>
                      </td>
                      <td class="col-1">
                        <div class="text-center w-100">
                          <b-form-checkbox
                            v-model="kuota.detail[index].sts_aktif"
                            name="checkbox-sts"
                            value="1"
                            unchecked-value="0"
                          >
                          </b-form-checkbox>
                        </div>
                      </td>
                      <td>
                        <div class="text-center w-100">
                          <b-button variant="white" @click="deleteRow(index, 't', '')" v-if=" index !== 0">
                            <p class="mb-0"><i class="fas fa-trash-alt text-danger"></i></p>
                          </b-button>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </b-col>
          </b-row>
          <div class="bg-dark px-2" v-if="error">
            <p class="text-right small text-white mb-0"><i class="fas fa-exclamation-circle"></i> {{ error }}</p>
          </div>
        </div>
      </div>
      <!-- <pre>{{ kuota }}</pre> -->
    </b-modal>
    <b-modal id="modal-kuota-edit" ref="modal-edit-kuota" size="xl" title="Edit Kuota Dokter Rehabilitasi Medik" :no-close-on-backdrop="true" :static="true" :no-close-on-esc="true"
      @ok="handleUpdate" @close="clearAll" @cancel="clearAll" cancel-title="Tutup" cancel-variant="danger"
      ok-title="Ubah" :hide-header=false centered>
      <div class="mb-1">
        <b-row class="mb-2">
          <b-col>
            <b-row>
              <b-col cols="3" class="pr-1">
                <b-form-group label="Poli" class="mb-0">
                  <multiselect  v-model="selected.ruangan" :show-labels="false" disabled
                    @input="valRuang($event, 'e')" label="deskripsi" placeholder="[Poli]" :options="combo.ruangan"
                    :searchable="true">
                  </multiselect>
                </b-form-group>
              </b-col>
              <b-col cols="5" class="pr-1 pl-1">
                <b-form-group label="Dokter" class="mb-0">
                  <multiselect v-model="selected.dokter" :show-labels="false"
                    @input="valDokter($event, 'e')" label="nama" placeholder="[Dokter]" disabled :options="combo.dokter"
                    :searchable="true">
                  </multiselect>
                </b-form-group>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
        <hr class="m-0">
        <div>
          <b-row>
            <b-col cols="12">
              <div class="table-responsive-xl">
                <table class="table bg-white table-bordered table-sm m-0 p-0">
                  <thead>
                    <tr>
                      <th scope="col" style="vertical-align: center;" class="text-center">
                        <p class="mb-0 font-weight-normal text-center">Nomor</p>
                      </th>
                      <th scope="col" style="vertical-align: center;" class="text-center">
                        <p class="mb-0 font-weight-normal text-center">Hari</p>
                      </th>
                      <th scope="col" style="vertical-align: center;" class="text-center">
                        <p class="mb-0 font-weight-normal text-center">Jam Mulai</p>
                      </th>
                      <th scope="col" style="vertical-align: center;" class="text-center">
                        <p class="mb-0 font-weight-normal text-center">Jam Akhir</p>
                      </th>
                      <th scope="col" style="vertical-align: center;" class="text-center">
                        <p class="mb-0 font-weight-normal text-center">Kuota Total</p>
                      </th>
                      <th scope="col" style="vertical-align: center;" class="text-center">
                        <p class="mb-0 font-weight-normal text-center">Kuota Online</p>
                      </th>
                      <th scope="col" style="vertical-align: center;" class="text-center">
                        <p class="mb-0 font-weight-normal text-center">Kuota Bridging</p>
                      </th>
                      <th scope="col" style="vertical-align: center;" class="text-center">
                        <p class="mb-0 font-weight-normal text-center">Kuota Sisa</p>
                      </th>
                      <!-- <th scope="col" style="vertical-align: center;" class="text-center">
                        <p class="mb-0 font-weight-normal text-center">Menit Layanan</p>
                      </th> -->
                      <th scope="col" style="vertical-align: center;" class="text-center">
                        <p class="mb-0 font-weight-normal text-center">Tanggal Tampil</p>
                      </th>
                      <th scope="col" style="vertical-align: center;" class="text-center">
                        <p class="mb-0 font-weight-normal text-center">Status Aktif</p>
                      </th>
                      <th scope="col" style="vertical-align: center;" class="text-center">
                        <b-button variant="primary" @click="addRow('e')">
                          <p class="h5 mb-0">+</p>
                        </b-button>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(k, index) in editKuota.detail" v-bind:key="k.codex">
                      <td class="py-3">
                        <div class="w-100">
                          <p class="text-center font-weight-bold mb-0">{{ index + 1 }}</p>
                        </div>
                      </td>
                      <td class="col-2">
                        <div class="w-100">
                          <multiselect v-model="selected.hariEdit[index].detail" :show-labels="false" :allow-empty="false"
                            label="deskripsi" placeholder="[Hari]" :options="combo.hari" @input="valHari($event, index, 'e')"
                            :searchable="true">
                          </multiselect>
                        </div>
                      </td>
                      <td>
                        <div class="w-100 d-flex align-items-center">
                          <b-form-input class="custom-search-kuota text-center" maxlength="2" v-model="display.jamAwalEdit[index].first" @keypress="isNumberKey" @input="jamAwal($event, index, 'e')" type="text" placeholder="_ _"></b-form-input>
                          <span class="mx-1">:</span>
                          <b-form-input class="custom-search-kuota text-center" maxlength="2" v-model="display.jamAwalEdit[index].last" @keypress="isNumberKey" @input="jamAwal($event, index, 'e')" type="text" placeholder="_ _"></b-form-input>
                        </div>
                      </td>
                      <td>
                        <div class="w-100 d-flex align-items-center">
                          <b-form-input class="custom-search-kuota text-center" maxlength="2" v-model="display.jamAkhirEdit[index].first" @input="jamAkhir($event, index, 'e')" @keypress="isNumberKey" type="text" placeholder="_ _"></b-form-input>
                          <span class="mx-1">:</span>
                          <b-form-input class="custom-search-kuota text-center" maxlength="2" v-model="display.jamAkhirEdit[index].last" @input="jamAkhir($event, index, 'e')" @keypress="isNumberKey" type="text" placeholder="_ _"></b-form-input>
                        </div>
                      </td>
                      <td class="col-1">
                        <div class="w-100">
                          <b-form-input class="custom-search-kuota text-center" v-model="editKuota.detail[index].quota_total" @input="totalQ(index, 'e')" @keypress="isNumberKey" type="text" placeholder="Kuota Sisa"></b-form-input>
                        </div>
                      </td>
                      <td class="col-1">
                        <div class="w-100">
                          <b-form-input class="custom-search-kuota text-center" maxlength="3" v-model="editKuota.detail[index].quota_online" @keypress="isNumberKey" @input="totalQ(index, 'e')" type="text" placeholder="Kuota Online"></b-form-input>
                        </div>
                      </td>
                      <td class="col-1">
                        <div class="w-100">
                          <b-form-input class="custom-search-kuota text-center" maxlength="3" v-model="editKuota.detail[index].quota_bridging" @keypress="isNumberKey" @input="totalQ(index, 'e')" type="text" placeholder="Kuota Bridging"></b-form-input>
                        </div>
                      </td>
                      <td class="col-1">
                        <div class="w-100">
                          <b-form-input class="custom-search-kuota text-center" v-model="display.kuota_sisa[index].value" @keypress="isNumberKey" @input="evtKuota(index)" type="text" placeholder="Kuota Sisa"></b-form-input>
                        </div>
                      </td>
                      <!-- <td class="col-1">
                        <div class="w-100">
                          <b-form-input class="custom-search-kuota text-center" maxlength="2" v-model="editKuota.detail[index].menit_layanan" @keypress="isNumberKey" type="text" placeholder="Lama Layanan"></b-form-input>
                        </div>
                      </td> -->
                      <td class="col-1">
                        <div class="w-100">
                          <b-form-input class="custom-search-kuota text-center" maxlength="3" v-model="editKuota.detail[index].max_hari_reservasi" @keypress="isNumberKey" type="text" placeholder="Tanggal Tampil"></b-form-input>
                        </div>
                      </td>
                      <td class="col-1">
                        <div class="text-center w-100">
                          <b-form-checkbox
                            v-model="editKuota.detail[index].sts_aktif"
                            name="checkbox-sts"
                            value="1"
                            unchecked-value="0"
                          >
                          </b-form-checkbox>
                        </div>
                      </td>
                      <td>
                        <div class="text-center w-100">
                          <b-button variant="white" @click="deleteRow(index, 'e', k.id)" v-if=" index !== 0">
                            <p class="mb-0"><i class="fas fa-trash-alt text-danger"></i></p>
                          </b-button>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </b-col>
          </b-row>
          <div class="bg-dark px-2" v-if="error">
            <p class="text-right small text-white mb-0"><i class="fas fa-exclamation-circle"></i> {{ error }}</p>
          </div>
          <!-- <pre>{{ editKuota }}</pre> -->
          <!-- <pre>{{ editKuota }}</pre> -->
        </div>
      </div>
    </b-modal>
    <b-modal ref="modal-konfirm-delete-soft" size="md" title="KONFIRMASI HAPUS HARI" ok-variant="danger" ok-title="Hapus" :no-close-on-backdrop="true" @ok="dayDelete" @close="clearErr" @cancel="clearErr" :static="true"
    cancel-title="Batal" centered>
      <div class="px-1 py-2">
        <div class="text-center ">
          <i class="fas fa-exclamation-circle text-danger fa-5x"></i>
          <p class="h6 text-danger mb-0 mt-3">Anda Yakin Akan Menghapus Hari ?</p>
        </div>
        <div class="bg-dark px-2 mt-2" v-if="errorsoft">
          <p class="small text-white mb-0">{{ errorsoft }}</p>
        </div>
      </div>
    </b-modal>
    <b-modal ref="modal-konfirm-delete-hard" size="md" title="KONFIRMASI HAPUS DOKTER" ok-variant="danger" ok-title="Hapus" @ok="handleDelete" @close="clearErr" @cancel="clearErr" :no-close-on-backdrop="true" :static="true"
    cancel-title="Batal" centered>
      <div class="px-1 py-2">
        <div class="text-center ">
          <i class="fas fa-exclamation-circle text-danger fa-5x"></i>
          <p class="h6 text-danger mb-0 mt-3">Anda Yakin Akan Menghapus Kuota ?</p>
        </div>
        <div class="bg-dark px-2 mt-2" v-if="error">
          <p class="small text-white mb-0">{{ error }}</p>
        </div>
      </div>
    </b-modal>
    <b-modal ref="modal-sukses" size="md" title="Informasi" ok-title="Oke" :ok-only="true" :hide-header="true" centered>
      <div class="text-center px-lg-4 px-2">
        <lottie :options="defaultOptions" :autoPlay="true" :width="250" :height="250" />
        <p class="h6 text-success font-weight-bold mb-0">{{ message }}</p>
      </div>
    </b-modal>
    <b-modal ref="modal-gagal" size="md" title="Informasi" ok-title="Kembali" :ok-only="true" :hide-header="true" centered>
      <div class="text-center px-lg-4 px-2">
        <lottie :options="defaultOptions2" :autoPlay="true" :width="250" :height="250" />
        <p class="h6 text-danger font-weight-bold mb-2">Data Gagal Disimpan</p>
        <p class="small text-danger font-weight-light mb-0">{{error}}</p>
      </div>
    </b-modal>
    <ModalMsgbox ref="MsgBox" />
  </div>
</template>
<script>
import { xray } from '../../../config/pluginInit'
import Lottie from 'vue-lottie'
import animationLogo from '../../../assets/lottie/checked.json'
import animationLogo2 from '../../../assets/lottie/failed.json'
import Service from '../../../services/fatmahost/index'
import Multiselect from 'vue-multiselect'
// import flatPickr from 'vue-flatpickr-component'
// import { Indonesian as IndonesianLocale } from 'flatpickr/dist/l10n/id.js'
// import 'flatpickr/dist/flatpickr.css'
import ModalMsgbox from '../../../components/modal/ModalMsgBox'
// import kuota from '../../../services/kuota'
// import { mapGetters } from 'vuex'
export default {
  name: 'Kuotadokter',
  components: {
    ModalMsgbox,
    Multiselect,
    Lottie
    // flatPickr
  },
  data () {
    return {
      table: {
        isBusy: false,
        items: this.list,
        sortBy: '',
        sortDesc: false,
        fields: [
          { label: 'No', key: 'index', class: 'text-center' },
          { label: 'Dokter', key: 'tPegawai.nama', sortable: true },
          { label: 'Poli', key: 'mRuang.deskripsi', sortable: true },
          { label: 'Senin', key: 'senin_start', class: 'text-center', sortable: true },
          { label: 'Selasa', key: 'selasa_start', class: 'text-center', sortable: true },
          { label: 'Rabu', key: 'rabu_start', class: 'text-center', sortable: true },
          { label: 'Kamis', key: 'kamis_start', class: 'text-center', sortable: true },
          { label: 'Jumat', key: 'jumat_start', class: 'text-center', sortable: true },
          { label: 'Sabtu', key: 'sabtu_start', sortable: true, class: 'bg-secondary text-center text-dark' },
          { label: 'Minggu', key: 'minggu_start', sortable: true, class: 'bg-secondary text-center text-dark' },
          { label: 'Aksi', key: 'action', class: 'text-center' }
        ],
        filter: null,
        currentPage: null,
        perPage: 10,
        pageOptions: [10, 50, 100, 500],
        totalRows: null
      },
      combo: {
        instalasi: [],
        unit: [],
        ruangan: [],
        dokter: [],
        jenis: [
          { value: 1, text: 'Reservasi Online' },
          { value: 2, text: 'Reservasi Offline' }
        ],
        filterType: [
          { value: '1', text: 'Filter Dokter' },
          { value: '2', text: 'Filter Ruangan' }
        ],
        hariFil: [
          { value: '', text: 'Semua Hari' },
          { value: '2', text: 'Senin' },
          { value: '3', text: 'Selasa' },
          { value: '4', text: 'Rabu' },
          { value: '5', text: 'Kamis' },
          { value: '6', text: 'Jumat' },
          { value: '7', text: 'Sabtu' },
          { value: '1', text: 'Minggu' }
        ],
        filterRuang: [
          { value: '', text: 'Rehabilitasi Medik' }
        ],
        hari: [
          { id: '2', deskripsi: 'Senin' },
          { id: '3', deskripsi: 'Selasa' },
          { id: '4', deskripsi: 'Rabu' },
          { id: '5', deskripsi: 'Kamis' },
          { id: '6', deskripsi: 'Jumat' },
          { id: '7', deskripsi: 'Sabtu', $isDisabled: true },
          { id: '1', deskripsi: 'Minggu', $isDisabled: true }
        ],
        status: [
          { value: '1', text: 'Aktif' },
          { value: '0', text: 'tidak Aktif' }
        ]
      },
      selected: {
        instalasi: [],
        unit: [],
        ruangan: [],
        dokter: [],
        hari: [{
          detail: []
        }],
        hariEdit: [{
          detail: []
        }],
        filterType: '1',
        filterRuang: '',
        filterHari: ''
      },
      id: null,
      dokter: '',
      ruangan: '',
      kuota: {
        object: 'kuota-dokter',
        header: {
          dokter: '',
          ruangan: ''
        },
        detail: [
          {
            hari: '',
            quota_online: '0',
            quota_bridging: '0',
            quota_total: '0',
            start: '',
            end: '',
            menit_layanan: '0',
            max_hari_reservasi: '0',
            sts_aktif: '1'
          }
        ]
      },
      editKuota: {
        object: 'kuota-dokter',
        detail: [
          {
            id: '',
            dokter: '',
            ruangan: '',
            hari: '',
            quota_online: '0',
            quota_bridging: '0',
            quota_total: '0',
            start: '',
            end: '',
            menit_layanan: '0',
            max_hari_reservasi: '0',
            sts_aktif: '1'
          }
        ]
      },
      display: {
        displayruangan: '',
        displaydokter: '',
        kuotakeluaron: '',
        kuotakeluaroff: '',
        viewTgltmbh: '',
        viewTglEdit: '',
        viewTglAwal: '',
        viewTglAkhir: '',
        jamAwal: [
          {
            first: '',
            last: ''
          }
        ],
        jamAkhir: [
          {
            first: '',
            last: ''
          }
        ],
        jamAwalEdit: [
          {
            first: '',
            last: ''
          }
        ],
        jamAkhirEdit: [
          {
            first: '',
            last: ''
          }
        ],
        kuota_sisa: [
          {
            value: '0'
          }
        ],
        kuota_sisa_show: [
          {
            value: '0'
          }
        ]
      },
      filtering: {
        valTglAwal: '',
        valTglAkhir: ''
      },
      status: 'not_accepted',
      statusEdit: 'not_accepted',
      valuedisable: false,
      valuedisable2: false,
      error: '',
      errorsoft: '',
      message: '',
      messageTgl: '',
      defaultOptions: { animationData: animationLogo, loop: true },
      defaultOptions2: { animationData: animationLogo2, loop: true },
      softDelete: {
        id: '',
        index: ''
      }
      // configTgl: {
      //   locale: IndonesianLocale,
      //   minDate: 'today',
      //   disableMobile: true,
      //   dateFormat: 'd-m-Y'
      // }
    }
  },
  mounted () {
    xray.index()
    this.getinstalasi()
    this.getruangan()
    // this.getDokter()
  },
  methods: {
    filterchange () {
      this.table.filter = ''
    },
    async list (ctx) {
      let filter = ''
      let sortfield = ''
      let sorting = ''
      let filterType = this.selected.filterType ? this.selected.filterType : ''
      let filterHari = this.selected.filterHari ? this.selected.filterHari : ''
      if (ctx.filter === '') {
        ctx.filter = null
      }
      if (ctx.sortBy) {
        sortfield = ctx.sortBy
      } else {
        sortfield = ''
      }
      if (ctx.sortDesc === true) {
        sorting = 'DESC'
      } else if (ctx.sortDesc === false) {
        sorting = 'ASC'
      } else {
        sorting = ''
      }
      if (ctx.filter === null) {
        filter = `param3={"xparam0":"","xparam1":"","xparam2":"${filterHari}","xparam3":"","xparam4":""}&param4={"xparam0":"${sortfield}","xparam1":"${sorting}"}`
      } else {
        if (filterType === '1') {
          filter = `param3={"xparam0":"","xparam1":"${ctx.filter}", "xparam2":"${filterHari}", "xparam3":"", "xparam4":""}&param4={"xparam0":"${sortfield}","xparam1":"${sorting}"}`
        } else if (filterType === '2') {
          filter = `param3={"xparam0":"${ctx.filter}","xparam1":"", "xparam2":"${filterHari}", "xparam3":"", "xparam4":""}&param4={"xparam0":"${sortfield}","xparam1":"${sorting}"}`
        }
      }
      this.table.isBusy = true
      try {
        const response = await Service.master.kuota_dokter_findall_v2_irm(ctx.currentPage - 1, ctx.perPage, filter)
        this.table.isBusy = false
        this.table.totalRows = response.data.pagination.total
        this.table.perPage = response.data.pagination.perPage
        this.table.currentPage = response.data.pagination.current
        return response.data.data
      } catch (error) {
        this.table.isBusy = false
        return []
      }
    },
    actionrefresh () {
      this.$refs.table.refresh()
    },
    handleNewKuota () {
      this.$refs['modal-new-kuota'].show()
    },
    async handleSubmit (bvModalEvent) {
      bvModalEvent.preventDefault()
      try {
        const data = this.kuota
        const response = await Service.master.kuota_dokter_create_v2(data)
        if (response.data.statcode === 0) {
          this.message = response.data.message
          this.$refs['modal-new-kuota'].hide()
          this.$refs.MsgBox.show({
            type: 'success',
            msg: response.data.message
          })
          this.clearAll()
        } else {
          this.error = response.data.message
        }
        this.$refs.table.refresh()
      } catch (error) {
        this.error = error.message
        this.$refs.table.refresh()
      }
    },
    async handleShowDtl (id) {
      try {
        const ids = id.toString()
        const response = await Service.master.kuota_dokter_findid_detail_v2_irm(ids)
        if (response.data.statcode === 0) {
          this.dokter = response.data.data[0].id_dokter.toString()
          this.ruangan = response.data.data[0].id_ruangan
          this.selected.dokter = { id: response.data.data[0].id_dokter, nama: response.data.data[0].nama_dokter }
          this.selected.ruangan = { id: response.data.data[0].id_ruangan, deskripsi: response.data.data[0].ruangan }
          if (response.data.data) {
            this.deleteRowRespons(0)
            let kuota = response.data.data
            kuota.map((value, index) => {
              this.editKuota.detail.push({
                id: value.id.toString(),
                dokter: value.id_dokter.toString(),
                ruangan: value.id_ruangan,
                hari: value.hari.toString(),
                quota_online: value.quota_online.toString(),
                quota_bridging: value.quota_bridging.toString(),
                quota_total: value.quota_total.toString(),
                start: value.start,
                end: value.end,
                menit_layanan: value.menit_layanan.toString(),
                max_hari_reservasi: value.max_hari_reservasi.toString(),
                sts_aktif: value.sts_aktif.toString()
              })
              this.selected.hariEdit.push(
                {
                  detail: [{ id: value.hari, deskripsi: value.desc_hari }]
                }
              )
              this.display.jamAwalEdit.push(
                {
                  first: /[^:]*/.exec(value.start)[0],
                  last: /[^:]*$/.exec(value.start)[0]
                }
              )
              this.display.jamAkhirEdit.push(
                {
                  first: /[^:]*/.exec(value.end)[0],
                  last: /[^:]*$/.exec(value.end)[0]
                }
              )
              this.display.kuota_sisa.push(
                {
                  value: value.quota_total - value.quota_online - value.quota_bridging
                }
              )
              this.display.kuota_sisa_show.push(
                {
                  value: value.quota_total - value.quota_online - value.quota_bridging
                }
              )
            })
          }
        } else {
          this.error = response.data.message
          this.$refs.table.refresh()
        }
      } catch (error) {
        this.error = error.message
        this.$refs.table.refresh()
      }
      this.$refs['modal-edit-kuota'].show()
    },
    async handleUpdate (bvModalEvent) {
      bvModalEvent.preventDefault()
      try {
        const id = this.id
        const data = this.editKuota
        const response = await Service.master.kuota_dokter_update_v2(id, data)
        if (response.data.statcode === 0) {
          this.message = response.data.message
          this.$refs['modal-edit-kuota'].hide()
          this.$refs.MsgBox.show({
            type: 'success',
            msg: response.data.message
          })
          this.clearAll()
        } else {
          this.error = response.data.message
        }
        this.$refs.table.refresh()
      } catch (error) {
        this.error = error.message
        this.$refs.table.refresh()
      }
    },
    deleteModal (id, ruangan) {
      this.id = id
      this.ruangan = ruangan
      this.$refs['modal-konfirm-delete-hard'].show()
    },
    async handleDelete (bvModalEvent) {
      bvModalEvent.preventDefault()
      try {
        const id = this.id
        const ruangan = this.ruangan
        const response = await Service.master.kuota_dokter_delete_v2_hard(id, ruangan)
        if (response.data.statcode === 0) {
          this.$refs['modal-konfirm-delete-hard'].hide()
          this.$refs.MsgBox.show({
            type: 'success',
            msg: response.data.message
          })
          this.$refs.table.refresh()
        } else {
          this.error = response.data.message
          this.$refs.table.refresh()
        }
      } catch (error) {
        this.error = error.message
        this.$refs.table.refresh()
      }
    },
    async dayDelete (bvModalEvent) {
      bvModalEvent.preventDefault()
      try {
        const ids = this.softDelete.id
        const index = this.softDelete.index
        const response = await Service.master.kuota_dokter_delete_v2_soft(ids)
        if (response.data.statcode === 0) {
          this.editKuota.detail.splice(index, 1)
          this.selected.hariEdit.splice(index, 1)
          this.display.jamAwalEdit.splice(index, 1)
          this.display.jamAkhirEdit.splice(index, 1)
          this.display.kuota_sisa.splice(index, 1)
          this.display.kuota_sisa_show.splice(index, 1)
          this.$refs['modal-konfirm-delete-soft'].hide()
        } else {
          this.errorsoft = response.data.message
        }
      } catch (error) {
        this.errorsoft = error.message
      }
    },
    async getinstalasi () {
      await Service.master.instalasireservasi().then(response => {
        if (response.status === 200) {
          this.combo.instalasi = response.data.data
        }
      }).catch(error => {
        this.error = error.response
      })
    },
    async getDokter ($event) {
      let id = $event.id
      await Service.master.dokterIrm(id).then(response => {
        if (response.status === 200) {
          this.combo.dokter = response.data.data
        }
      }).catch(error => {
        this.error = error.response
      })
    },
    async getruangan () {
      const data = '1011001'
      await Service.master.ruangankuota(data).then(response => {
        if (response.status === 200) {
          this.combo.ruangan = response.data.data
        }
      }).catch(error => {
        this.error = error.response.data.errors.message
      })
    },
    async valRuang ($event, param) {
      if (param === 't') {
        this.kuota.header.ruangan = $event.id
      }
      this.getDokter($event)
    },
    valHari ($event, index, param) {
      if (param === 't') {
        this.kuota.detail[index].hari = $event.id.toString()
      } else if (param === 'e') {
        this.editKuota.detail[index].hari = $event.id.toString()
      }
    },
    valDokter ($event, param) {
      if (param === 't') {
        this.kuota.header.dokter = $event.id_dokter.toString()
      }
    },
    onFiltered (filteredItems) {
      this.table.totalRows = filteredItems.length
    },
    valFilterRuang () {
      this.table.filter = ''
      this.$refs.table.refresh()
    },
    tableRefresh () {
      this.$refs.table.refresh()
    },
    filterBtn () {
      this.selected.filterType = '1'
      this.selected.filterRuang = '0'
      this.selected.filterHari = ''
      this.filtering.valTglAwal = ''
      this.filtering.valTglAkhir = ''
      this.display.viewTglAwal = ''
      this.display.viewTglAkhir = ''
      this.table.filter = ''
      this.$refs.table.refresh()
    },
    isAlphabetKey (evt) {
      evt = (evt) || window.event
      var charCode = (evt.which) ? evt.which : evt.keyCode
      if ((charCode < 65 || charCode > 90) && (charCode < 97 || charCode > 123) && charCode !== 32) {
        evt.preventDefault()
      } else {
        return true
      }
    },
    isNumberKey (evt) {
      evt = (evt) || window.event
      var charCode = (evt.which) ? evt.which : evt.keyCode
      if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
        evt.preventDefault()
      } else {
        return true
      }
    },
    arrContent () {
      this.kuota.detail.push(
        {
          hari: '',
          quota_online: '0',
          quota_bridging: '0',
          quota_total: '0',
          start: '',
          end: '',
          menit_layanan: '0',
          max_hari_reservasi: '0',
          sts_aktif: '1'
        }
      )
      this.selected.hari.push(
        {
          detail: []
        }
      )
      this.display.jamAwal.push(
        {
          first: '',
          last: ''
        }
      )
      this.display.jamAkhir.push(
        {
          first: '',
          last: ''
        }
      )
      this.display.kuota_sisa.push(
        {
          value: '0'
        }
      )
      this.display.kuota_sisa_show.push(
        {
          value: '0'
        }
      )
    },
    arrContentEdit () {
      this.editKuota.detail.push(
        {
          id: '0',
          dokter: this.dokter,
          ruangan: this.ruangan,
          hari: '',
          quota_online: '0',
          quota_bridging: '0',
          quota_total: '0',
          start: '',
          end: '',
          menit_layanan: '0',
          max_hari_reservasi: '0',
          sts_aktif: '1'
        }
      )
      this.selected.hariEdit.push(
        {
          detail: []
        }
      )
      this.display.jamAwalEdit.push(
        {
          first: '',
          last: ''
        }
      )
      this.display.jamAkhirEdit.push(
        {
          first: '',
          last: ''
        }
      )
      this.display.kuota_sisa.push(
        {
          value: '0'
        }
      )
      this.display.kuota_sisa_show.push(
        {
          value: '0'
        }
      )
    },
    addRow (param) {
      if (param === 't') {
        this.arrContent()
      } else if (param === 'e') {
        this.arrContentEdit()
      }
    },
    deleteRow (index, param, id) {
      if (param === 't') {
        this.kuota.detail.splice(index, 1)
        this.selected.hari.splice(index, 1)
        this.display.jamAwal.splice(index, 1)
        this.display.jamAkhir.splice(index, 1)
        this.display.kuota_sisa.splice(index, 1)
        this.display.kuota_sisa_show.splice(index, 1)
      } else if (param === 'e') {
        this.softDelete.id = id
        this.softDelete.index = index
        this.$refs['modal-konfirm-delete-soft'].show()
        // this.dayDelete(index, id)
      }
    },
    deleteRowRespons (index) {
      this.editKuota.detail.splice(index, 1)
      this.selected.hariEdit.splice(index, 1)
      this.display.jamAwalEdit.splice(index, 1)
      this.display.jamAkhirEdit.splice(index, 1)
      this.display.kuota_sisa.splice(index, 1)
      this.display.kuota_sisa_show.splice(index, 1)
    },
    jamAwal ($event, index, param) {
      if ($event.length >= 2) {
        if (param === 't') {
          this.kuota.detail[index].start = this.display.jamAwal[index].first + ':' + this.display.jamAwal[index].last
        } else if (param === 'e') {
          this.editKuota.detail[index].start = this.display.jamAwalEdit[index].first + ':' + this.display.jamAwalEdit[index].last
        }
        this.error = ''
      } else {
        this.error = 'Format jam harus memiliki 2 digit angka sebelum dan sesudah tanda " : ", misal 07:00'
      }
    },
    jamAkhir ($event, index, param) {
      if ($event.length >= 2) {
        if (param === 't') {
          this.kuota.detail[index].end = this.display.jamAkhir[index].first + ':' + this.display.jamAkhir[index].last
        } else if (param === 'e') {
          this.editKuota.detail[index].end = this.display.jamAkhirEdit[index].first + ':' + this.display.jamAkhirEdit[index].last
        }
        this.error = ''
      } else {
        this.error = 'Format jam harus memiliki 2 digit angka sebelum dan sesudah tanda " : ", misal 07:00'
      }
    },
    totalQ (index, param) {
      if (param === 't') {
        let number = Number(this.kuota.detail[index].quota_total) - Number(this.kuota.detail[index].quota_online) - Number(this.kuota.detail[index].quota_bridging)
        this.display.kuota_sisa_show[index].value = number.toString()
      } else if (param === 'e') {
        let number = Number(this.editKuota.detail[index].quota_total) - Number(this.editKuota.detail[index].quota_online) - Number(this.editKuota.detail[index].quota_bridging)
        this.display.kuota_sisa_show[index].value = number.toString()
      }
      this.evtKuota(index)
    },
    evtKuota (index) {
      if (Number(this.display.kuota_sisa[index].value) > Number(this.display.kuota_sisa_show[index].value)) {
        this.error = 'Sisa Kuota Tidak Sesuai'
      } else {
        this.error = ''
      }
    },
    clearAll () {
      this.clearCreate()
      this.clearEdit()
      this.clearErr()
    },
    clearCreate () {
      this.kuota.header.dokter = ''
      this.kuota.header.ruangan = ''
      this.selected.dokter = []
      this.selected.ruangan = []
      this.kuota.detail = []
      this.selected.hari = []
      this.display.jamAwal = []
      this.display.jamAkhir = []
      this.display.kuota_sisa = []
      this.display.kuota_sisa_show = []
      this.arrContent()
    },
    clearEdit () {
      this.editKuota.detail = []
      this.selected.hariEdit = []
      this.display.jamAwalEdit = []
      this.display.jamAkhirEdit = []
      this.display.kuota_sisa = []
      this.display.kuota_sisa_show = []
      this.arrContentEdit()
    },
    clearErr () {
      this.error = ''
      this.errorsoft = ''
      this.softDelete.index = ''
      this.softDelete.id = ''
    }
    // validasiJam (index, cond) {
    //   if (cond === 'a') {
    //     if (this.display.jamAwal[index].first < 10 && this.display.jamAwal[index].first !== '') {
    //       if (this.display.jamAwal[index].first[0] === '0') {
    //         return this.display.jamAwal[index].first
    //       } else {
    //         return '0' + this.display.jamAwal[index].first
    //       }
    //     } else {
    //       return this.display.jamAwal[index].first
    //     }
    //   } else if (cond === 'b') {
    //     if (this.display.jamAkhir[index].first < 10 && this.display.jamAkhir[index].first !== '') {
    //       if (this.display.jamAkhir[index].first[0] === '0') {
    //         return this.display.jamAkhir[index].first
    //       } else {
    //         return '0' + this.display.jamAkhir[index].first
    //       }
    //     } else {
    //       return this.display.jamAkhir[index].first
    //     }
    //   }
    // },
  }
}
</script>

<style>
/* #modal-kuota-add .modal-body, #modal-kuota-edit .modal-body, #modal-filter .modal-body {
  background: #3399331b;
} */
#kuota-dokter .bg-filter {
  border: 1px solid rgba(0, 0, 0, 0.13);
  background-color: #089bab1e;
}
#modal-kuota-add .flatpickr-input[readonly], #modal-kuota-edit .flatpickr-input[readonly], #modal-filter .flatpickr-input[readonly] {
  height: 37px;
  font-size: 12px;
  border-radius: 5px;
  border: 1px solid rgba(128, 128, 128, 0.292);
  padding-left: 10px;
}
#header-table-filter .flatpickr-input[readonly] {
  height: 38px;
  font-size: 12px;
  border-radius: 5px;
  border: 1px solid rgba(17, 17, 17, 0.292);
  padding-left: 10px;
}
#modal-kuota-add .custom-search-kuota, #modal-kuota-edit .custom-search-kuota, #modal-filter .custom-search-kuota {
  font-size: 13px!important;
  color: black!important;
  border-radius: 3px;
}
#modal-kuota-add .modal-body, #modal-kuota-edit .modal-body {
  background: #3399331b;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}
#modal-kuota-add .modal-header, #modal-kuota-edit .modal-header {
  background: #3399333a;
}

#modal-kuota-add .modal-footer, #modal-kuota-edit .modal-footer {
  background: #3399333a;
}
#kuota-dokter legend {
    color: darkslategrey;
}
#kuota-dokter .form-control:disabled, #kuota-dokter .form-control[readonly] {
    background-color: #e9ecef;
    opacity: 0.5;
}
#kuota-dokter .form-control {
  color: black!important;
}
#kuota-dokter .fc-unthemed .fc-toolbar .fc-button {
    margin: 3px;
}
#kuota-dokter tr:first-child > td > .fc-day-grid-event {
    cursor: pointer;
}
#kuota-dokter .multiselect__input, .multiselect {
  font-weight: bold!important;
}
td.fc-day.fc-past {
  background-color: #EEEEEE;
}
#modal-kuota-add .multiselect__single, #modal-kuota-edit .multiselect__single {
  font-size: 12px!important;
  font-weight: normal!important;
}
#modal-kuota-add .multiselect__placeholder, #modal-kuota-edit .multiselect__placeholder {
  font-size: 12px!important;
  font-weight: normal!important;
}
#modal-kuota-add .multiselect__option, #modal-kuota-edit .multiselect__option {
  font-size: 12px;
  font-weight: normal!important;
}
#modal-kuota-add .multiselect__tags, #modal-kuota-edit .multiselect__tags {
  font-weight: normal!important;
  padding: 5px 40px 0 8px!important;
  height: 38px!important;
  min-height: 38px!important;
}
#modal-kuota-edit .multiselect--disabled {
  opacity: 1;
}
/* #modal-kuota-edit .flatpickr-input[readonly] {
  background-color: rgb(245, 245, 245);
} */
#kuota-dokter .flex-half-width-left {
  width: 76%;
}
#kuota-dokter .flex-half-width-right {
  width: 23%;
}
#kuota-dokter .flex-full-width-left {
  width: 96%;
}
#kuota-dokter .flex-full-width-right {
  width: 3%;
}
#kuota-dokter .txt-farmasi-base {
  font-size: 12px;
}
#kuota-dokter .txt-farmasi-base-head {
  font-size: 14px;
}
#kuota-dokter .table td {
    font-size: 12px;
    font-weight: 500;
    padding-top: 0px;
    padding-bottom: 0px;
}
#kuota-dokter .table th {
    font-size: 13px;
    font-weight: 400;
}
#kuota-dokter .form-control {
    border: 1px solid rgb(194, 194, 194);
    background: white;
    height: 40px;
}
#kuota-dokter .multiselect__tags {
    border-radius: 3px;
    border: 1px solid rgb(194, 194, 194);
    background: white;
    font-size: 11px;
    font-weight: 550;
    height: 10px;
}
#kuota-dokter .btn-height-custom {
    height: 39px;
}
#kuota-dokter .cursor-point {
    cursor: pointer;
}
#kuota-dokter .scroll-y {
    overflow-y: scroll;
    height: 550px;
}
#kuota-dokter .bg-abu {
  background: rgba(236, 236, 236, 0.541);
}
#kuota-dokter .form-control {
    font-size: 11px;
}
#kuota-dokter .b-table-sticky-header, #kuota-dokter .table-responsive {
    margin-bottom: 0px;
}
#kuota-dokter .table-barang .btn {
    font-size: 11px;
}
#kuota-dokter .page-link {
    border: none;
    color: gray!important;
    margin-right: 15px;
}
#kuota-dokter .page-item.active .page-link {
    background-color: transparent;
    border-color: transparent;
    color: #089bab!important;
    font-weight: bold;
}
#kuota-dokter .vertical-txt {
  writing-mode: vertical-rl;
  letter-spacing: 10px;
}
#kuota-dokter .transition-cstm {
  transition: .3s linear;
}
.cstm-cntrl-kuota {
  border-radius: 5px!important;
  font-size: 13px!important;
  height: 38px!important;
}
#modal-kuota-add .modal-dialog, #modal-kuota-edit .modal-dialog {
  min-width: 95%!important;
}
.cstm-slct-kuota {
  font-size: 13px!important;
  height: 38px!important;
  border-radius: 5px;
}
.alert-radius-kuota {
  border-radius: 5px;
}

.font-size-responsive {
  font-size: 7px;
}

@media (min-width: 576px) {
  .font-size-responsive {
    font-size: 8px;
  }
 }

@media (min-width: 768px) {
  .font-size-responsive {
    font-size: 9px;
  }
 }

@media (min-width: 992px) {
  .font-size-responsive {
    font-size: 10px;
  }
 }

@media (min-width: 1200px) {
  .font-size-responsive {
    font-size: 11px;
  }
 }
 .pointer-table tbody tr {
  cursor: pointer;
}
</style>
