import Api from '../axiosInstance'
const version = '/v1'
export default {
  findAll (currentPage, perPage, obj, vParams3, vParams4) {
    return Api.member.get(`${version}/service/practitioner/profil?param0=${obj}&param1=${currentPage}&param2=${perPage}&param3=${vParams3}&param4=${vParams4}`)
  },
  show (id, obj, params) {
    return Api.member.get(`${version}/service/practitioner/${obj}/${id}?param0=${params}`)
  },
  status (id, data) {
    return Api.member.patch(`${version}/service/practitioner/profil/${id}`, data)
  }
}
