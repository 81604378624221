import Service from '../../services/fatmahost/index'
import jwtdecode from 'jwt-decode'
import router from '../../router'

export default {
  async signIn ({ dispatch }, data) {
    let response = await Service.auth.login(data)
    dispatch('attempt', response.data)
  },
  async refreshtoken ({ dispatch }) {
    await Service.auth.token().then((res) => {
      dispatch('attempt', res.data)
    }).catch(() => {
      router.replace({ name: 'auth1.signin' })
    })
  },
  async attempt ({ commit }, data) {
    const token = jwtdecode(data.accessToken)
    // const utility = jwtdecode(data.accessModule).module.utility
    const module = jwtdecode(data.accessModules).module.permissions

    commit('SET_TOKEN', data.accessToken)
    commit('SET_USER', token.user)
    commit('SET_MODULE', module)
    // commit('SET_UTILITY', utility)
    commit('SET_MODULE_LOCAL', data.accessModules)
    // commit('SET_UTILITY_LOCAL', data.accessModule)
  }
}
