<template>
  <div id="list-pegawai" class="list-pegawai">
    <b-container fluid>
      <b-row>
        <b-col md="12">
          <iq-card>
            <template v-slot:headerTitle>
              <h4 class="card-title">Data ADK Interkoneksi SIMRS SAKTI</h4>
            </template>
            <template v-slot:body>
              <div class="py-2">
                <div class="table mb-0">
                  <b-table class="mb-0"
                  ref="table"
                  :busy="table.isBusy"
                  :items="table.items"
                  :fields="table.fields"
                  :current-page="table.currentPage"
                  :per-page="table.perPage"
                  :filter="table.filter"
                  @filtered="onFiltered"
                  stacked="md"
                  bordered hover show-empty small striped>
                  <template #table-busy>
                    <div class="text-center text-danger my-2">
                      <b-spinner class="align-middle" variant="primary" type="grow" style="width: 3rem; height: 3rem;"></b-spinner>
                      <!-- <strong> Loading...</strong> -->
                    </div>
                  </template>
                  <template #cell(index)="data">
                    {{ (table.currentPage * table.perPage) - table.perPage + data.index + 1 }}
                  </template>
                  <template #cell(sysdate_updt)="data">
                  <span>{{ new Date(data.item.sysdate_updt) | formatDate }}</span>
                </template>
                  <template v-slot:cell(action)="data">
                    <!-- <b-button variant="white" class="my-1 mr-1" size="md" @click="viewModal(data.item.kode)"><i class="fas fa-eye text-warning"></i></b-button> -->
                    <b-button variant="white" class="my-1 mr-1" size="md" @click="eksportData(data.item.no_doc, 'DRAFT')"><i class="fas fa-file-export text-success"></i></b-button>
                  </template>
                  </b-table>
                </div>
                <div class="bg-white pb-2 pt-2 pl-3 pr-3 border-bottom border-right border-left">
                  <b-row class="align-items-center justify-content-between">
                    <b-col cols="12" lg="3" class="mr-auto">
                      <div class="d-flex align-items-center">
                        <div>
                          <b-pagination v-model="table.currentPage" :total-rows="table.totalRows" :per-page="table.perPage" size="sm" class="my-0">
                          </b-pagination>
                        </div>
                        <div class="border-left border-right px-3">
                          <span class="cursor-point" @click="tableRefresh">
                            <i class="fas fa-sync"></i>
                          </span>
                        </div>
                      </div>
                    </b-col>
                    <b-col cols="12" lg="5">
                      <div class="d-flex flex-row">
                        <div class="mr-2 w-75">
                          <b-input-group>
                            <b-form-input class="cstm-slct-pegawai rounded-0" id="filter-input" v-model="table.filter" type="search" placeholder="[ Pencarian ]"></b-form-input>
                          </b-input-group>
                        </div>
                        <div>
                          <b-form-select class="cstm-slct-pegawai" v-model="table.selected.filterType" :options="table.combo.filterType" @change="filterchange"></b-form-select>
                        </div>
                      </div>
                    </b-col>
                    <b-col cols="12" lg="3">
                      <span class="text-right">
                        <p class="font-weight-normal mb-0">Menampilkan 1 - {{ table.perPage }} dari {{ table.totalRows }}</p>
                      </span>
                    </b-col>
                  </b-row>
                </div>
              </div>
            </template>
          </iq-card>
        </b-col>
      </b-row>
    </b-container>
     <!-- @ok="sendData(view.keyid, view.object_id_jenis, view.object_id )" -->
    <b-modal ref="modal-view" size="lg" title="Detail Sakti" ok-title="Simpan" cancel-title="Kembali" :ok-only=false :no-close-on-backdrop="true" :static="true" centered>
      <div class="mb-2">
        <pre>{{ view }}</pre>
      </div>
    </b-modal>
    <b-modal ref="modal-eksport" size="xl" title="Detail Sakti" ok-title="Kembali" :ok-only=true ok-variant="secondary" :no-close-on-backdrop="true" :static="true" centered>
      <div class="mb-2 text-black">
        <div class="row">
          <div class="col-lg-2">
            <strong>FILE PREFIX</strong>
          </div>
          <div class="col-lg-10">
            <p>: {{ bastfile_pre }}</p>
          </div>
          <div class="col-lg-2">
            <strong>FILE SUFFIX</strong>
          </div>
          <div class="col-lg-10">
            <p>: {{ bastfile_suff }}</p>
          </div>
          <div class="col-lg-2">
            <strong>BAST-A</strong>
          </div>
          <div class="col-lg-10">
            <p>: {{ A }}</p>
          </div>
          <div class="col-lg-2">
            <strong>BAST-B</strong>
          </div>
          <div class="col-lg-10">
            <p>: {{ B }}</p>
          </div>
          <div class="col-lg-2">
            <strong>BAST-C</strong>
          </div>
          <div class="col-lg-10">
            <p>: {{ C }}</p>
          </div>
        </div>
        <!-- <p><strong>FILE PREFIX : </strong> {{ bastfile_pre }}</p>
        <p><strong>FILE SUFFIX : </strong> {{ bastfile_suff }}</p> -->
        <!-- <p><strong>BAST-A      : </strong> {{ A }}</p>
        <p><strong>BAST-B      : </strong> {{ B }}</p>
        <p><strong>BAST-C      : </strong> {{ C }}</p> -->
      </div>
      <div class="text-center">
        <b-button variant="primary" class="my-1 mr-1" size="md" @click="savefileas()"><i class="fas fa-file-csv"> EXPORT</i></b-button>
      </div>
    </b-modal>
    <ModalMsgbox ref="MsgBox" />
  </div>
</template>

<script>
import { xray } from '../../../config/pluginInit'
import Service from '../../../services/fatmahost/index'
import ModalMsgbox from '../../../components/modal/ModalMsgBox'
import { saveAs } from 'file-saver'
import JSZip from 'jszip'
import Minizip from 'minizip-asm.js'

export default {
  name: 'Sakti',
  components: {
    ModalMsgbox
  },
  data () {
    return {
      isBusy: false,
      table: {
        items: this.list,
        fields: [
          { label: 'No', key: 'index', class: 'text-center' },
          { label: 'Kode', key: 'kode' },
          { label: 'No Doc', key: 'no_doc' },
          { label: 'No Faktur', key: 'no_faktur' },
          { label: 'No Surat Jalan', key: 'no_suratjalan' },
          { label: 'Last Update', key: 'sysdate_updt' },
          { label: 'Aksi', key: 'action', class: 'text-center' }
        ],
        filter: null,
        currentPage: null,
        perPage: null,
        pageOptions: [10, 25, 50, 100],
        totalRows: null,
        selected: {
          filterType: '1'
        },
        combo: {
          filterType: [
            { value: '1', text: 'Kode' },
            { value: '2', text: 'No Dokument' }
          ]
        }
      },
      view: [],
      A: '',
      B: '',
      C: '',
      bastfile_pre: '',
      bastfile_suff: '',
      no_doc: '',
      dataEksport: []
    }
  },
  mounted () {
    xray.index()
  },
  methods: {
    async list (ctx) {
      let filterType = this.table.selected.filterType
      let filter = ''
      if (ctx.filter === '') {
        ctx.filter = null
        filter = `{"xparam0":"","xparam1":""}`
      }
      if (ctx.filter === null) {
        filter = `{"xparam0":"","xparam1":""}`
      } else {
        if (filterType === '1') {
          filter = `{"xparam0":"${ctx.filter}","xparam1":""}`
        } else if (filterType === '2') {
          filter = `{"xparam0":"","xparam1":"${ctx.filter}"}`
        }
      }
      this.isBusy = true
      try {
        const response = await Service.sakti.findAll(ctx.currentPage - 1, ctx.perPage, filter)
        this.isBusy = false
        this.table.totalRows = response.data.pagination.total
        this.table.perPage = response.data.pagination.perPage
        this.table.currentPage = response.data.pagination.current
        return response.data.data
      } catch (error) {
        this.isBusy = false
        return []
      }
    },
    async viewModal (id) {
      const response = await Service.sakti.findId(id)
      this.view = response.data.data[0]
      this.$refs['modal-view'].show()
    },
    async eksportData (noDoc, aProses) {
      const data = {
        object: 'sakti',
        no_doc: noDoc,
        proses: aProses,
        userid: 1
      }
      const response = await Service.sakti.upload(data)
      this.no_doc = noDoc
      if ((JSON.stringify(response.data.data)) === 'null') {
        this.A = 'null'
        this.B = 'null'
        this.C = 'null'
        this.bastfile_pre = 'null'
        this.bastfile_suff = 'null'
      } else {
        this.A = response.data.data[0].A
        this.B = response.data.data[0].B
        this.C = response.data.data[0].C
        this.bastfile_pre = response.data.data[0].nmfilepre
        this.bastfile_suff = response.data.data[0].nmfilesuff
        if (aProses === 'PRESAVE') {
          // let dataFile = response.data.file.split(';base64,').pop()
          // fs.writefile('test/test.zip', dataFile)
          // const link = document.createElement('a')
          // link.href = URL.createObjectURL(new Blob(content, { type: 'application/zip' }))
          // link.href = URL.createObjectURL(dataFile)
          // link.download = this.bastfile_pre + this.bastfile_suff + '.zip'
          // link.click()
          // URL.revokeObjectURL(link.href)
          // this.generateZipViaJSZIP()
          // this.saveFile()
          // this.generateZip()

          var buffer = this.base64ToBuffer(response.data.file)
          var binaryData = []
          binaryData.push(buffer)
          const link = document.createElement('a')
          link.href = URL.createObjectURL(new Blob(binaryData), { type: 'application/zip' })
          // window.URL.createObjectURL(new Blob(binaryData, {type: "application/zip"}))
          // link.href = URL.createObjectURL(buffer)
          link.download = this.bastfile_pre + this.bastfile_suff + '.zip'
          link.click()
        }
      }
      this.$refs['modal-eksport'].show()
    },
    base64ToBuffer (str) {
      str = window.atob(str)
      var buffer = new ArrayBuffer(str.length)
      var view = new Uint8Array(buffer)
      for (var i = 0; i < str.length; i++) { view[i] = str.charCodeAt(i) }
      return buffer
    },
    tableRefresh () {
      this.$refs.table.refresh()
    },
    async generateZip () {
      var vArray = ''
      var vDataBASTa = ''
      var vDataBASTb = ''
      var vDataBASTc = ''
      vArray = JSON.parse(JSON.stringify(this.A))
      for (var a = 0; a < vArray.length; a++) {
        if (a > 0) vDataBASTa = vDataBASTa + '\r\n'
        vDataBASTa = vDataBASTa + vArray[a]
      }
      vArray = JSON.parse(JSON.stringify(this.B))
      for (var b = 0; b < vArray.length; b++) {
        if (b > 0) vDataBASTb = vDataBASTb + '\r\n'
        vDataBASTb = vDataBASTb + vArray[b]
      }
      vArray = JSON.parse(JSON.stringify(this.C))
      for (var c = 0; c < vArray.length; c++) {
        if (c > 0) vDataBASTc = vDataBASTc + '\r\n'
        vDataBASTc = vDataBASTc + vArray[c]
      }
      var mz = new Minizip()
      mz.append(this.bastfile_pre + 'A' + this.bastfile_suff + '.csv', Buffer.from(vDataBASTa), { password: 'sakti123' })
      mz.append(this.bastfile_pre + 'B' + this.bastfile_suff + '.csv', Buffer.from(vDataBASTb), { password: 'sakti123' })
      mz.append(this.bastfile_pre + 'C' + this.bastfile_suff + '.csv', Buffer.from(vDataBASTc), { password: 'sakti123' })
      // const content = mz.zip() // '123' // mz.zip() // await zip.generateAsync({ type: 'blob', password: 'sakti123', encryptStrength: 3 })
      const link = document.createElement('a')
      // link.href = URL.createObjectURL(new Blob(content, { type: 'application/zip' }))
      link.href = URL.createObjectURL(new Blob(mz.zip()), { type: 'application/zip' })
      link.download = this.bastfile_pre + this.bastfile_suff + '.zip'
      link.click()
      URL.revokeObjectURL(link.href)
    },
    async generateZipViaJSZIP () {
      var vArray = ''
      var vDataBASTa = ''
      var vDataBASTb = ''
      var vDataBASTc = ''
      vArray = JSON.parse(JSON.stringify(this.A))
      for (var a = 0; a < vArray.length; a++) {
        if (a > 0) vDataBASTa = vDataBASTa + '\r\n'
        vDataBASTa = vDataBASTa + vArray[a]
      }
      vArray = JSON.parse(JSON.stringify(this.B))
      for (var b = 0; b < vArray.length; b++) {
        if (b > 0) vDataBASTb = vDataBASTb + '\r\n'
        vDataBASTb = vDataBASTb + vArray[b]
      }
      vArray = JSON.parse(JSON.stringify(this.C))
      for (var c = 0; c < vArray.length; c++) {
        if (c > 0) vDataBASTc = vDataBASTc + '\r\n'
        vDataBASTc = vDataBASTc + vArray[c]
      }
      const zip = new JSZip()
      zip.file(this.bastfile_pre + 'A' + this.bastfile_suff + '.csv', vDataBASTa)
      zip.file(this.bastfile_pre + 'B' + this.bastfile_suff + '.csv', vDataBASTb)
      zip.file(this.bastfile_pre + 'C' + this.bastfile_suff + '.csv', vDataBASTc)
      const content = await zip.generateAsync({ type: 'blob', password: 'sakti123', encryptStrength: 3 })
      const link = document.createElement('a')
      link.href = URL.createObjectURL(content)
      link.download = this.bastfile_pre + this.bastfile_suff + '.zip'
      link.click()
      URL.revokeObjectURL(link.href)
    },
    savefileas () {
      this.eksportData(this.no_doc, 'PRESAVE')
    },
    createFile () {
      const text = '1234'
      const filename = 'example.txt'
      const blob = new Blob([text], { type: 'text/plain;charset=utf-8' })
      saveAs(blob, filename)
    },
    async saveFile () {
      var filename
      var blob
      var vArray = ''
      var vDataBAST = ''
      // a
      vArray = JSON.parse(JSON.stringify(this.A))
      vDataBAST = ''
      for (var a = 0; a < vArray.length; a++) {
        if (a > 0) vDataBAST = vDataBAST + '\r\n' // await writable.write('\r\n')
        vDataBAST = vDataBAST + vArray[a] // await writable.write(vArray[i])
      }
      filename = this.bastfile_pre + 'A' + this.bastfile_suff + '.csv'
      blob = new Blob([vDataBAST], { type: 'text/plain;charset=utf-8' })
      saveAs(blob, filename)
      // b
      vArray = JSON.parse(JSON.stringify(this.B))
      vDataBAST = ''
      for (var b = 0; b < vArray.length; b++) {
        if (b > 0) vDataBAST = vDataBAST + '\r\n' // await writable.write('\r\n')
        vDataBAST = vDataBAST + vArray[b] // await writable.write(vArray[i])
      }
      filename = this.bastfile_pre + 'B' + this.bastfile_suff + '.csv'
      blob = new Blob([vDataBAST], { type: 'text/plain;charset=utf-8' })
      saveAs(blob, filename)
      // c
      vArray = JSON.parse(JSON.stringify(this.C))
      vDataBAST = ''
      for (var i = 0; i < vArray.length; i++) {
        if (i > 0) vDataBAST = vDataBAST + '\r\n' // await writable.write('\r\n')
        vDataBAST = vDataBAST + vArray[i] // await writable.write(vArray[i])
      }
      filename = this.bastfile_pre + 'C' + this.bastfile_suff + '.csv'
      blob = new Blob([vDataBAST], { type: 'text/plain;charset=utf-8' })
      saveAs(blob, filename)
    },
    async saveFileAll () {
      // const pickerOpts = {
      //  types: [
      //    {
      //      description: 'csv file',
      //      accept: {
      //        'image/*': ['.csv']
      //      }
      //    }
      //  ],
      //  excludeAcceptAllOption: true,
      //  multiple: false
      // }
      // const [fileHandle] = await window.showOpenFilePicker(pickerOpts)
      // const writable = await fileHandle.createWritable()
      var filename
      var blob
      var vArray = ''
      var vDataBAST = ''
      // a
      vArray = JSON.parse(JSON.stringify(this.A))
      vDataBAST = ''
      for (var a = 0; a < vArray.length; a++) {
        if (a > 0) vDataBAST = vDataBAST + '\r\n' // await writable.write('\r\n')
        vDataBAST = vDataBAST + vArray[a] // await writable.write(vArray[i])
      }
      filename = this.bastfile_pre + 'A' + this.bastfile_suff + '.csv'
      blob = new Blob([vDataBAST], { type: 'text/plain;charset=utf-8' })
      saveAs(blob, filename)
      // b
      vArray = JSON.parse(JSON.stringify(this.B))
      vDataBAST = ''
      for (var b = 0; b < vArray.length; b++) {
        if (b > 0) vDataBAST = vDataBAST + '\r\n' // await writable.write('\r\n')
        vDataBAST = vDataBAST + vArray[b] // await writable.write(vArray[i])
      }
      filename = this.bastfile_pre + 'B' + this.bastfile_suff + '.csv'
      blob = new Blob([vDataBAST], { type: 'text/plain;charset=utf-8' })
      saveAs(blob, filename)
      // c
      vArray = JSON.parse(JSON.stringify(this.C))
      vDataBAST = ''
      for (var i = 0; i < vArray.length; i++) {
        if (i > 0) vDataBAST = vDataBAST + '\r\n' // await writable.write('\r\n')
        vDataBAST = vDataBAST + vArray[i] // await writable.write(vArray[i])
      }
      filename = this.bastfile_pre + 'C' + this.bastfile_suff + '.csv'
      blob = new Blob([vDataBAST], { type: 'text/plain;charset=utf-8' })
      saveAs(blob, filename)
    },
    onFiltered (filteredItems) {
      this.table.totalRows = filteredItems.length
      this.table.currentPage = 1
    },
    filterchange () {
      this.table.filter = ''
    }
  }
}
</script>
<style>
#list-pegawai .table td {
    font-size: 12px;
    font-weight: 500;
    padding-top: 0px;
    padding-bottom: 0px;
}
#list-pegawai .table th {
    font-size: 13px;
    font-weight: 400;
}
#list-pegawai .page-link {
    border: none;
    color: gray!important;
    margin-right: 15px;
}
#list-pegawai .page-item.active .page-link {
    background-color: transparent;
    border-color: transparent;
    color: #089bab!important;
    font-weight: bold;
}
#list-pegawai .cursor-point {
    cursor: pointer;
}
.cstm-slct-pegawai {
  font-size: 13px!important;
  height: 38px!important;
  border-radius: 5px;
}
.cstm-cntrl-pegawai {
  color: black!important;
  font-weight: bold!important;
  border-radius: 5px!important;
  font-size: 12px!important;
  height: 38px!important;
}
.custom-fontsize {
  font-size: 12px;
}
</style>
