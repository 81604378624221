<template>
  <div id="katalog-kelompok" class="mb-4">
    <b-container fluid>
      <div>
        <iq-card>
          <template v-slot:body>
            <div class="filter-kelompok border p-2">
              <b-row class="align-items-center">
                <b-col cols="12" lg="3" class="d-flex flex-row w-100 mr-auto">
                  <div>
                    <h5 class="card-title font-weight-bold mb-0">Master Kelompok</h5>
                  </div>
                </b-col>
                <b-col cols="12" lg="9">
                  <div class="text-right">
                    <b-button class="custom-button-radius" size="sm" variant="primary" @click="tambahModal"><i class="fas fa-plus"></i> Tambah</b-button>
                  </div>
                </b-col>
              </b-row>
            </div>
            <div class="table mb-0">
              <b-table class="mb-0" ref="table" :sort-by.sync="table.sortBy" :sort-desc.sync="table.sortDesc" :busy="table.isBusy" :items="table.items" :fields="table.fields"
                :current-page="table.currentPage" :per-page="table.perPage" :filter="table.filter" @filtered="onFiltered"
                stacked="md" bordered hover show-empty small striped>
                <template #cell(index)="data">
                {{ (table.currentPage*table.perPage)-table.perPage + data.index + 1 }}
                </template>
                <template #cell(action)="data">
                  <b-button @click="editModal(data.item.id)" variant=" mr-1 mb-1"
                    size="lg">
                    <i class="fas fa-edit text-primary"></i>
                  </b-button>
                  <b-button @click="deleteModal(data.item.id)" variant=" mr-1 mb-1"
                    size="lg">
                    <i class="fas fa-trash-alt text-danger"></i>
                  </b-button>
                </template>
                <template #cell(sysdate_updt)="data">
                  <span>{{ new Date(data.item.sysdate_updt) | formatDate }}</span>
                </template>
              </b-table>
            </div>
            <div class="pagination-kelompok border shadow-sm p-2 mb-2">
              <b-row class="align-items-center">
                <b-col cols="4" class="d-flex flex-row align-items-center">
                  <div class="w-100">
                    <b-pagination
                      v-model="table.currentPage"
                      :total-rows="table.totalRows"
                      :per-page="table.perPage"
                      size="md"
                      class="my-0"
                    ></b-pagination>
                  </div>
                  <div class="w-100">
                    <div>
                      <span class="border-left border-dark mr-3 py-1"></span>
                      <span @click="tableRefresh" class="katalog-cursor">
                        <i class="fas fa-sync text-secondary"></i>
                      </span>
                      <span class="border-right border-dark ml-3 py-1"></span>
                    </div>
                  </div>
                </b-col>
                <b-col cols="5">
                  <div class="d-flex flex-row justify-content-end align-items-center">
                    <div class="mr-1 w-75">
                      <form class="searchbox mb-0">
                        <input v-model="table.filter" type="search" class="form-control cstm-srch-kelompok" id="filter-input"
                          placeholder="[Cari]">
                      </form>
                    </div>
                    <div>
                      <b-form-select class="cstm-slct-kelompok" v-model="selected.filter" :options="options.optionsFilter" @change="filterchange"></b-form-select>
                    </div>
                  </div>
                </b-col>
                <b-col cols="3">
                  <div class="d-flex flex-row align-items-center justify-content-end">
                    <div class="mr-3">
                      <b-form-select
                        class="cstm-select-kelompok"
                        id="per-page-select"
                        v-model="table.perPage"
                        :options="table.pageOptions"
                      ></b-form-select>
                    </div>
                    <div>
                      <p class="text-dark mb-0">Menampilkan {{ table.perPage }} dari {{ table.totalRows }} baris</p>
                    </div>
                  </div>
                </b-col>
              </b-row>
            </div>
          </template>
        </iq-card>
      </div>
      <!-- <pre>{{ tambahKelompok }}</pre> -->
      <!-- <pre>{{ editKelompok }}</pre> -->
    </b-container>
    <div id="modal-component-kelompok">
      <b-modal id="modal-tambah-kelompok" ref="modal-tambah-kelompok" size="lg" centered title="Tambah Kelompok" ok-title="Simpan Data"
        cancel-title="Tutup" :no-close-on-backdrop="true" :static="true" @close="clear" @cancel="clear" @ok="tambahSave">
        <b-form>
          <b-form-group class="mb-2">
            <b-row class="align-items-center">
              <b-col cols="2" class="pr-1">
                <span>
                  <p class="small text-dark mb-0">Kode & Kelompok</p>
                </span>
              </b-col>
              <b-col class="pr-1">
                <b-form-input class="new-cstm-cntrl-katalog" v-model="tambahKelompok.kode"
                  placeholder="[Kode]">
                </b-form-input>
              </b-col>
              <b-col class="pl-1">
                <b-form-input class="new-cstm-cntrl-katalog" v-model="tambahKelompok.kelompok_barang"
                  placeholder="[Kelompok Barang]"></b-form-input>
              </b-col>
              <!-- <b-col class="pr-1 pl-1">
                <b-form-input class="new-cstm-cntrl-katalog" v-model="tambahKelompok.kode_temp"
                  placeholder="[Kode Temp]"></b-form-input>
              </b-col> -->
              <!-- <b-col class="pl-1">
                <multiselect v-model="selected.kategori" :show-labels="false"
                    label="nama" placeholder="[ Kode Simrsgos ]" :options="options.kategori" @input="valueKategori"
                    :searchable="true">
                </multiselect>
              </b-col> -->
            </b-row>
          </b-form-group>
          <b-form-group class="mb-2">
            <b-row class="align-items-center">
              <b-col cols="2" class="pr-1">
                <span>
                  <p class="small text-dark mb-0">Inventori/Hardcode</p>
                </span>
              </b-col>
              <!-- <b-col class="pr-1">
                <b-form-input class="new-cstm-cntrl-katalog" v-model="tambahKelompok.kelompok_barang"
                  placeholder="[Kelompok Barang]"></b-form-input>
              </b-col> -->
              <b-col class="pr-1">
                <div class="select-kelompok">
                  <multiselect v-model="selected.inventori" :show-labels="false" track-by="id"
                    label="uraian_inventory" placeholder="[ Kelompok Inventori ]" :options="options.inventori" @input="valueInv($event, '1')"
                    :searchable="true">
                  </multiselect>
                </div>
              </b-col>
              <b-col class="pl-1">
                <div class="select-kelompok">
                  <multiselect v-model="selected.hardcode" :show-labels="false"
                    label="keterangan" placeholder="[ Hardcode ]" :options="options.hardcode" @input="valueHardCode"
                    :searchable="true">
                  </multiselect>
                </div>
              </b-col>
            </b-row>
          </b-form-group>
          <!-- <b-form-group class="mb-2">
            <b-row class="align-items-center">
              <b-col cols="2">
                <span>
                  <p class="small text-dark mb-0">Kategori</p>
                </span>
              </b-col>
              <b-col class="pr-1">
                <b-form-input class="new-cstm-cntrl-katalog" v-model="tambahKelompok.no_urut"
                  placeholder="[Nomor Urut]"></b-form-input>
              </b-col>
              <b-col class="pr-1 pl-1">
                <b-form-input class="new-cstm-cntrl-katalog" v-model="tambahKelompok.gol"
                  placeholder="[Golongan]"></b-form-input>
              </b-col>
              <b-col class="pl-1">
                <b-form-input class="new-cstm-cntrl-katalog" v-model="tambahKelompok.bid"
                  placeholder="[Bidang]"></b-form-input>
              </b-col>
            </b-row>
          </b-form-group> -->
          <!-- <b-form-group class="mb-2">
            <b-row class="align-items-center">
              <b-col cols="2">
                <span>
                  <p class="small text-dark mb-0">Kelompok</p>
                </span>
              </b-col>
              <b-col class="pr-1">
                <b-form-input class="new-cstm-cntrl-katalog" v-model="tambahKelompok.kel"
                  placeholder="[Kelomp]ok"></b-form-input>
              </b-col>
              <b-col class="pr-1 pl-1">
                <b-form-input class="new-cstm-cntrl-katalog" v-model="tambahKelompok.subkel"
                  placeholder="[Sub Kelompok]"></b-form-input>
              </b-col>
              <b-col class="pl-1">
                <b-form-input class="new-cstm-cntrl-katalog" v-model="tambahKelompok.subsubkel"
                  placeholder="[Sub-Sub Kelompok]"></b-form-input>
              </b-col>
            </b-row>
          </b-form-group> -->
          <b-form-group class="mb-1">
            <b-row class="align-items-center">
              <b-col cols="2" class="pr-1">
                <span>
                  <p class="small text-dark mb-0">User Update</p>
                </span>
              </b-col>
              <b-col>
                <b-form-input class="new-cstm-cntrl-katalog" v-model="preview.user_name" placeholder="[Nama User]" readonly></b-form-input>
              </b-col>
            </b-row>
          </b-form-group>
          <b-form-group class="mb-0">
            <b-row class="align-items-center">
              <b-col cols="2">
                <span>
                  <p class="small text-dark mb-0">Status Aktif</p>
                </span>
              </b-col>
              <b-col>
                <b-form-radio-group class="mb-0"
                  v-model="tambahKelompok.sts_aktif"
                  :options="options.optionsStsAktif"
                  ></b-form-radio-group>
              </b-col>
            </b-row>
          </b-form-group>
        </b-form>
        <div class="bg-dark px-2 mt-2" v-if="preview.messageValue === '2'">
          <p class="text-right small text-white mb-0">{{ preview.message }}</p>
        </div>
        <!-- <pre>{{ tambahKelompok }}</pre> -->
      </b-modal>
      <b-modal id="modal-edit-kelompok" ref="modal-edit-kelompok" size="lg" centered title="Edit Kelompok" ok-title="Perbarui Data"
        cancel-title="Tutup" :no-close-on-backdrop="true" :static="true" @close="clear" @cancel="clear" @ok="editSave">
        <b-form>
          <b-form-group class="mb-2">
            <b-row class="align-items-center">
              <b-col cols="2" class="pr-1">
                <span>
                  <p class="small text-dark mb-0">Kode & Kelompok</p>
                </span>
              </b-col>
              <b-col class="pr-1">
                <b-form-input class="new-cstm-cntrl-katalog" v-model="editKelompok.kode"
                  placeholder="[Kode]"></b-form-input>
              </b-col>
              <b-col class="pl-1">
                <b-form-input class="new-cstm-cntrl-katalog" v-model="editKelompok.kelompok_barang"
                  placeholder="[Kelompok Barang]"></b-form-input>
              </b-col>
              <!-- <b-col class="pr-1 pl-1">
                <b-form-input class="new-cstm-cntrl-katalog" v-model="editKelompok.kode_temp"
                  placeholder="[Kode Temp]"></b-form-input>
              </b-col> -->
              <!-- <b-col class="pl-1">
                <multiselect v-model="selected.editkategori" :show-labels="false"
                    label="nama" track-by="id" placeholder="[ Kode Simrsgos ]" :options="options.editkategori" @input="valueKategoriEdit"
                    :searchable="true">
                </multiselect>
              </b-col> -->
            </b-row>
          </b-form-group>
          <b-form-group class="mb-2">
            <b-row class="align-items-center">
              <b-col cols="2" class="pr-1">
                <span>
                  <p class="small text-dark mb-0">Inventori/Hardcode</p>
                </span>
              </b-col>
              <!-- <b-col class="pr-1">
                <b-form-input class="new-cstm-cntrl-katalog" v-model="editKelompok.kelompok_barang"
                  placeholder="[Kelompok Barang]"></b-form-input>
              </b-col> -->
              <b-col class="pr-1">
                <div class="select-kelompok">
                  <multiselect v-model="selected.editinventori" :show-labels="false" track-by="id"
                    label="uraian_inventory" placeholder="[ Kelompok Inventori ]" :options="options.editinventori" @input="valueInv($event, '2')"
                    :searchable="true">
                  </multiselect>
                  <!-- <multiselect v-model="selected.edithardcode" :show-labels="false"
                    label="keterangan" placeholder="[ Hardcode ]" :options="options.edithardcode" @input="valueHardCodeEdit"
                    :searchable="true">
                  </multiselect> -->
                </div>
              </b-col>
              <b-col class="pl-1">
                <div class="select-kelompok">
                  <multiselect v-model="selected.edithardcode" :show-labels="false"
                    label="keterangan" placeholder="[ Hardcode ]" :options="options.edithardcode" @input="valueHardCodeEdit"
                    :searchable="true">
                  </multiselect>
                </div>
              </b-col>
            </b-row>
          </b-form-group>
          <!-- <b-form-group class="mb-2">
            <b-row class="align-items-center">
              <b-col cols="2">
                <span>
                  <p class="small text-dark mb-0">Kategori</p>
                </span>
              </b-col>
              <b-col class="pr-1">
                <b-form-input class="new-cstm-cntrl-katalog" v-model="editKelompok.no_urut"
                  placeholder="[Nomor Urut]"></b-form-input>
              </b-col>
              <b-col class="pr-1 pl-1">
                <b-form-input class="new-cstm-cntrl-katalog" v-model="editKelompok.gol"
                  placeholder="[Golongan]"></b-form-input>
              </b-col>
              <b-col class="pl-1">
                <b-form-input class="new-cstm-cntrl-katalog" v-model="editKelompok.bid"
                  placeholder="[Bidang]"></b-form-input>
              </b-col>
            </b-row>
          </b-form-group> -->
          <!-- <b-form-group class="mb-2">
            <b-row class="align-items-center">
              <b-col cols="2">
                <span>
                  <p class="small text-dark mb-0">Kelompok</p>
                </span>
              </b-col>
              <b-col class="pr-1">
                <b-form-input class="new-cstm-cntrl-katalog" v-model="editKelompok.kel"
                  placeholder="[Kelompok]"></b-form-input>
              </b-col>
              <b-col class="pr-1 pl-1">
                <b-form-input class="new-cstm-cntrl-katalog" v-model="editKelompok.subkel"
                  placeholder="[Sub Kelompok]"></b-form-input>
              </b-col>
              <b-col class="pl-1">
                <b-form-input class="new-cstm-cntrl-katalog" v-model="editKelompok.subsubkel"
                  placeholder="[Sub-Sub Kelompok]"></b-form-input>
              </b-col>
            </b-row>
          </b-form-group> -->
          <b-form-group class="mb-1">
            <b-row class="align-items-center">
              <b-col cols="2" class="pr-1">
                <span>
                  <p class="small text-dark mb-0">User Update</p>
                </span>
              </b-col>
              <b-col>
                <b-form-input class="new-cstm-cntrl-katalog" v-model="preview.user_name" placeholder="Nama User" readonly></b-form-input>
              </b-col>
            </b-row>
          </b-form-group>
          <b-form-group class="mb-0">
            <b-row class="align-items-center">
              <b-col cols="2">
                <span>
                  <p class="small text-dark mb-0">Status Aktif</p>
                </span>
              </b-col>
              <b-col>
                <b-form-radio-group class="mb-0"
                  v-model="editKelompok.sts_aktif"
                  :options="options.optionsStsAktif"
                  ></b-form-radio-group>
              </b-col>
            </b-row>
          </b-form-group>
        </b-form>
        <div class="bg-dark px-2 mt-2" v-if="preview.messageValue === '2'">
          <p class="text-right small text-white mb-0">{{ preview.message }}</p>
        </div>
        <!-- <pre>{{ editKelompok }}</pre> -->
      </b-modal>
      <b-modal ref="modal-delete-kelompok" size="md" centered title="Konfirmasi" ok-title="Hapus Data" ok-variant="danger"
        cancel-title="Tutup" @ok="deleteSave">
        <div class="py-3">
          <p class="h6 text-center text-dark mb-0">Yakin Ingin Menghapus Data ?</p>
        </div>
      </b-modal>
      <b-modal ref="modal-notif-kelompok" size="md" centered title="Notifikasi" ok-title="Tutup" :hide-footer="true"
        :hide-header="true">
        <div class="pb-3 px-4">
          <div v-if="preview.messageValue === '1'">
            <lottie :options="preview.lottie.defaultOptions" :autoPlay="true" :width="100" :height="100" />
          </div>
          <div v-else>
            <lottie :options="preview.lottie.defaultOptions2" :autoPlay="true" :width="100" :height="100" />
          </div>
          <p class="h6 text-dark text-center mb-0 mt-2">{{ preview.message }}</p>
        </div>
      </b-modal>
    </div>
  </div>
</template>
<script>
import { xray } from '../../../config/pluginInit'
import jwtdecode from 'jwt-decode'
import Services from '../../../services/fatmahost/katalog/index'
import Multiselect from 'vue-multiselect'
import Lottie from 'vue-lottie'
import animationLogo from '../../../assets/lottie/checked.json'
import animationLogo2 from '../../../assets/lottie/failed.json'

export default {
  name: 'Kelompok',
  components: {
    Multiselect,
    Lottie
  },
  data () {
    return {
      table: {
        isBusy: false,
        items: this.list,
        sortBy: '',
        sortDesc: true,
        fields: [
          { label: 'No', key: 'index', class: 'text-center' },
          { label: 'Kode', key: 'mk.kode', sortable: true },
          // { label: 'Kategori Simrsgos', key: 'nama' },
          // { label: 'Kelompok', key: 'kel' },
          { label: 'Kelompok Barang', key: 'mk.kelompok_barang', sortable: true },
          // { label: 'Kode Temp', key: 'kode_temp' },
          // { label: 'Sub Kel', key: 'subkel' },
          // { label: 'Sub-Sub Kel', key: 'subsubkel' },
          { label: 'User Update', key: 'up.name', sortable: true },
          { label: 'Last Update', key: 'mk.sysdate_updt', sortable: true },
          { label: 'Aksi', key: 'action', class: 'text-center' }
        ],
        filter: null,
        currentPage: null,
        perPage: null,
        pageOptions: [10, 25, 50, 100],
        totalRows: null
      },
      tambahKelompok: {
        object: 'kelompok',
        id_inventory: '',
        id_hardcode: '0',
        id_kategori_simrsgos: '',
        kode: '',
        kelompok_barang: '',
        kode_temp: '',
        no_urut: '',
        gol: '',
        bid: '',
        kel: '',
        subkel: '',
        subsubkel: '',
        sts_aktif: '1',
        userid_updt: jwtdecode(localStorage.getItem('token')).user.id.toString()
      },
      editKelompok: {
        object: 'kelompok',
        id_inventory: '',
        id_hardcode: '0',
        id_kategori_simrsgos: '',
        kode: '',
        kelompok_barang: '',
        kode_temp: '',
        no_urut: '',
        gol: '',
        bid: '',
        kel: '',
        subkel: '',
        subsubkel: '',
        sts_aktif: '1',
        userid_updt: jwtdecode(localStorage.getItem('token')).user.id.toString()
      },
      options: {
        optionsStsAktif: [
          { text: 'Aktif', value: '1' },
          { text: 'Tidak Aktif', value: '0' }
        ],
        optionsFilter: [
          { text: 'Kode', value: '1' },
          { text: 'Kelompok Barang', value: '2' }
        ],
        hardcode: [],
        edithardcode: [],
        kategori: [],
        editkategori: [],
        inventori: [],
        editinventori: []
      },
      selected: {
        filter: '1',
        hardcode: [],
        edithardcode: [],
        kategori: [],
        editkategori: [],
        inventori: [],
        editinventori: []
      },
      preview: {
        lottie: {
          defaultOptions: { animationData: animationLogo, loop: true },
          defaultOptions2: { animationData: animationLogo2, loop: true }
        },
        user_name: jwtdecode(localStorage.getItem('token')).user.name,
        edit_id: '',
        delete_id: '',
        message: '',
        messageValue: ''
      }
    }
  },
  mounted () {
    xray.index()
    this.hardCodeGet()
    this.kategoriGet()
    this.kelompokInvget()
  },
  methods: {
    tableRefresh () {
      this.$refs.table.refresh()
    },
    async kategoriGet () {
      try {
        const response = await Services.getKategori()
        this.options.kategori = response.data.data
        this.options.editkategori = response.data.data
        if (response.data.rowcount === 1 && response.data.statcode === 0) {
          this.preview.message = response.data.message
          this.preview.messageValue = '1'
        } else if (response.data.rowcount === 0 && response.data.statcode === 20001) {
          this.preview.message = response.data.message
          this.preview.messageValue = '2'
        }
      } catch (error) {
        this.preview.message = error.message
        this.preview.messageValue = '2'
        this.$refs['modal-notif-sakti'].show()
      }
    },
    async hardCodeGet () {
      try {
        const response = await Services.getHardCode()
        this.options.hardcode = response.data.data ? response.data.data : []
        this.options.edithardcode = response.data.data ? response.data.data : []
        if (response.data.rowcount === 1 && response.data.statcode === 0) {
          this.preview.message = response.data.message
          this.preview.messageValue = '1'
        } else if (response.data.rowcount === 0 && response.data.statcode === 20001) {
          this.preview.message = response.data.message
          this.preview.messageValue = '2'
        }
      } catch (error) {
        this.preview.message = error.message
        this.preview.messageValue = '2'
        this.$refs['modal-notif-sakti'].show()
      }
    },
    async kelompokInvget () {
      try {
        const response = await Services.getInvKelmpk()
        if (response.data.statcode === 0) {
          this.options.inventori = response.data.data ? response.data.data : []
          this.options.editinventori = response.data.data ? response.data.data : []
          this.preview.message = response.data.message
          this.preview.messageValue = '1'
        } else {
          this.preview.message = response.data.message
          this.preview.messageValue = '2'
        }
      } catch (error) {
        this.preview.message = error.message
        this.preview.messageValue = '2'
        this.$refs['modal-notif-sakti'].show()
      }
    },
    valueHardCode (param) {
      this.tambahKelompok.id_hardcode = param.id_hardcode.toString()
    },
    valueHardCodeEdit (param) {
      this.editKelompok.id_hardcode = param.id_hardcode.toString()
    },
    valueKategori (param) {
      this.tambahKelompok.id_kategori_simrsgos = param.id.toString()
    },
    valueKategoriEdit (param) {
      this.editKelompok.id_kategori_simrsgos = param.id.toString()
    },
    valueInv ($event, param) {
      if (param === '1') {
        this.tambahKelompok.id_inventory = $event.id.toString()
      } else if (param === '2') {
        this.editKelompok.id_inventory = $event.id.toString()
      }
    },
    async list (ctx) {
      let filterType = this.selected.filter
      let sortfield = ''
      let sorting = ''
      if (ctx.filter === '') {
        ctx.filter = null
      }
      if (ctx.sortBy) {
        sortfield = ctx.sortBy
      } else {
        sortfield = ''
      }
      if (ctx.sortDesc === true) {
        sorting = 'DESC'
      } else if (ctx.sortDesc === false) {
        sorting = 'ASC'
      } else {
        sorting = ''
      }
      this.isBusy = true
      try {
        const response = await Services.listKelompok(ctx.currentPage - 1, ctx.perPage, ctx.filter, filterType, sortfield, sorting)
        this.isBusy = false
        this.table.totalRows = response.data.pagination.total
        this.table.perPage = response.data.pagination.perPage
        this.table.currentPage = response.data.pagination.current
        return response.data.data
      } catch (error) {
        this.isBusy = false
        return []
      }
    },
    onFiltered (filteredItems) {
      this.table.totalRows = filteredItems.length
      this.table.currentPage = 1
    },
    filterchange () {
      this.table.filter = ''
    },
    tambahModal () {
      this.clearMessage()
      this.$refs['modal-tambah-kelompok'].show()
    },
    async tambahSave (bvModalEvent) {
      bvModalEvent.preventDefault()
      try {
        let data = this.tambahKelompok
        const response = await Services.createKelompok(data)
        if (response.data.statcode === 0) {
          this.preview.message = response.data.message
          this.preview.messageValue = '1'
          this.$refs['modal-tambah-kelompok'].hide()
          this.$refs['modal-notif-kelompok'].show()
          this.$refs.table.refresh()
          this.clear()
        } else {
          this.preview.message = response.data.message
          this.preview.messageValue = '2'
        }
      } catch (error) {
        this.preview.message = error.message
        this.preview.messageValue = '2'
      }
    },
    async editModal (id) {
      this.clearMessage()
      let ids = id
      this.preview.edit_id = ids
      try {
        const response = await Services.showKelompok(ids)
        this.editKelompok.kode = response.data.data[0].kode
        this.editKelompok.kelompok_barang = response.data.data[0].kelompok_barang
        this.editKelompok.kode_temp = response.data.data[0].kode_temp
        this.editKelompok.no_urut = response.data.data[0].no_urut
        this.editKelompok.gol = response.data.data[0].gol
        this.editKelompok.bid = response.data.data[0].bid
        this.editKelompok.kel = response.data.data[0].kel
        this.editKelompok.subkel = response.data.data[0].subkel
        this.editKelompok.subsubkel = response.data.data[0].subsubkel
        this.editKelompok.id_hardcode = response.data.data[0].id_hardcode
        this.editKelompok.id_inventory = response.data.data[0].id_inventory
        this.selected.edithardcode = response.data.data[0].id_hardcode ? { id: response.data.data[0].id_hardcode, keterangan: response.data.data[0].keterangan } : []
        this.selected.editinventori = response.data.data[0].id_inventory ? { id: response.data.data[0].id_inventory, uraian_inventory: response.data.data[0].nama_inventory } : []
        // this.editKelompok.id_kategori_simrsgos = response.data.data[0].id_kategori_simrsgos ? response.data.data[0].id_kategori_simrsgos : ''
        // this.selected.editkategori = response.data.data[0].id_kategori_simrsgos ? { id: response.data.data[0].id_kategori_simrsgos, nama: response.data.data[0].nama } : []
      } catch (error) {
        this.preview.message = error.message
        this.preview.messageValue = '2'
        // this.$refs['modal-notif-kelompok'].show()
      }
      this.$refs['modal-edit-kelompok'].show()
    },
    async editSave (bvModalEvent) {
      bvModalEvent.preventDefault()
      try {
        let id = this.preview.edit_id
        let data = this.editKelompok
        const response = await Services.editKelompok(id, data)
        if (response.data.statcode === 0) {
          this.preview.message = response.data.message
          this.preview.messageValue = '1'
          this.$refs['modal-edit-kelompok'].hide()
          this.$refs['modal-notif-kelompok'].show()
          this.$refs.table.refresh()
          this.clear()
        } else {
          this.preview.message = response.data.message
          this.preview.messageValue = '2'
        }
      } catch (error) {
        this.preview.message = error.message
        this.preview.messageValue = '2'
        this.$refs['modal-notif-kelompok'].show()
      }
    },
    deleteModal (id) {
      this.preview.delete_id = id
      this.$refs['modal-delete-kelompok'].show()
    },
    async deleteSave () {
      let id = this.preview.delete_id
      try {
        const response = await Services.deleteKelompok(id)
        if (response.data.statcode === 0) {
          this.preview.message = response.data.message
          this.preview.messageValue = '1'
        } else {
          this.preview.message = response.data.message
          this.preview.messageValue = '2'
        }
        this.$refs['modal-notif-kelompok'].show()
        this.$refs.table.refresh()
      } catch (error) {
        this.preview.message = error.message
        this.preview.messageValue = '2'
        this.$refs['modal-notif-kelompok'].show()
      }
    },
    clear () {
      this.tambahKelompok.object = 'kelompok'
      this.tambahKelompok.id_inventory = ''
      this.tambahKelompok.id_hardcode = '0'
      this.tambahKelompok.id_kategori_simrsgos = ''
      this.tambahKelompok.kode = ''
      this.tambahKelompok.kelompok_barang = ''
      this.tambahKelompok.kode_temp = ''
      this.tambahKelompok.no_urut = ''
      this.tambahKelompok.gol = ''
      this.tambahKelompok.bid = ''
      this.tambahKelompok.kel = ''
      this.tambahKelompok.subkel = ''
      this.tambahKelompok.subsubkel = ''
      this.tambahKelompok.sts_aktif = '1'
      this.tambahKelompok.userid_updt = jwtdecode(localStorage.getItem('token')).user.id.toString()
    },
    clearMessage () {
      this.preview.messageValue = ''
      this.preview.message = ''
    }
  }
}
</script>
<style>
#modal-tambah-kelompok .custom-control-label, #modal-edit-kelompok .custom-control-label {
    font-size: 12px;
    margin-top: 0;
}
#modal-tambah-kelompok .multiselect__single, #modal-edit-kelompok .multiselect__single {
  font-size: 12px!important;
}
#modal-tambah-kelompok .multiselect__placeholder, #modal-edit-kelompok .multiselect__placeholder {
  font-size: 12px!important;
}
#modal-tambah-kelompok .multiselect__option, #modal-edit-kelompok .multiselect__option {
    font-size: 12px;
}
#modal-tambah-kelompok .multiselect__tags, #modal-edit-kelompok .multiselect__tags {
  padding: 5px 40px 0 8px!important;
  height: 38px!important;
  min-height: 38px!important;
}
#modal-tambah-kelompok .modal-body, #modal-edit-kelompok .modal-body {
  background: #3399331b
}

#modal-tambah-kelompok .modal-header, #modal-edit-kelompok .modal-header {
  background: #3399333a;
}

#modal-tambah-kelompok .modal-footer, #modal-edit-kelompok .modal-footer {
  background: #3399333a;
}
#katalog-kelompok .page-link {
  font-size: 14px;
  padding-top: 5px;
  padding-bottom: 5px;
  margin-right: 10px!important;
}
#katalog-kelompok .custom-button-radius {
  border: 1px solid rgba(0, 0, 0, 0.112);
  border-radius: 5px!important;
}
#katalog-kelompok .table td {
    font-size: 12px;
    font-weight: 500;
    padding-top: 0px;
    padding-bottom: 0px;
}
#katalog-kelompok .table th {
    font-size: 13px;
    font-weight: 400;
}
#katalog-kelompok .cstm-select-kelompok {
  font-size: 13px;
  height: 38px!important;
  border-radius: 5px;
}
#katalog-kelompok .cstm-srch-kelompok {
  font-size: 13px;
  height: 38px!important;
  border-radius: 5px;
}
#katalog-kelompok .cstm-slct-kelompok {
  font-size: 13px;
  height: 38px!important;
  border-radius: 5px;
}
.new-cstm-cntrl-katalog {
  background: white!important;
  color: black!important;
  border-radius: 5px!important;
  font-size: 12px!important;
  height: 38px!important;
}
.katalog-form-area {
  color: black!important;
  border-radius: 5px!important;
  font-size: 12px!important;
}
#katalog-kelompok .pagination-sm .page-link {
  color: gray!important;
}
#katalog-kelompok .page-item.active .page-link {
  color: white!important;
}
#katalog-kelompok .page-item.active .page-link {
  color: white!important;
}
.custom-radius-add-katalog {
  border-radius: 5px;
  cursor: pointer;
}
.katalog-select-flex-left {
  width: 90%;
}
.katalog-select-flex-right {
  width: 10%;
}
.katalog-form-satuan-kemasan-left-right {
  width: 40%;
}
.katalog-form-satuan-kemasan-center {
  width: 15%;
}
.hr-katalog-custom {
  border-top: 1px solid rgba(0, 0, 0, 0.199)!important;
  margin: 0!important;
}
#katalog-kelompok .pagination-kelompok .page-link {
    border: none;
    color: gray!important;
    margin-right: 15px;
}
#katalog-kelompok .pagination-kelompok .page-item.active .page-link {
    background-color: transparent;
    border-color: transparent;
    color: #089bab!important;
    font-weight: bold;
}
</style>
