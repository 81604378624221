import Api from './fatmahost/axiosInstance'

export default {
  getallagama () {
    return Api.laravel.get('getbyAgama')
  },
  getallprofesi () {
    return Api.laravel.get('getbyProfesi')
  },
  getallsmf () {
    return Api.laravel.get('getbySmf')
  },
  getjeniskelamin () {
    return Api.laravel.get('getbyJenisKelamin')
  },
  getalltemlahir () {
    return Api.laravel.get('getbyTempatLahir')
  },
  getallprovinsi () {
    return Api.laravel.get('getbyProvinsi')
  },
  getallkabupaten (idProvinsi) {
    return Api.laravel.post('setbyKabupaten', idProvinsi)
  },
  getallkecamatan (idKabupaten) {
    return Api.laravel.post('setbyKecamatan', idKabupaten)
  },
  getalldesa (idKecamatan) {
    return Api.laravel.post('setbyDesa', idKecamatan)
  },
  getallkartu () {
    return Api.laravel.get('getbyKartu')
  },
  getallperkawinan () {
    return Api.laravel.get('getbyStatusPerkawinan')
  },
  getallpendidikan () {
    return Api.laravel.get('getbyPendidikan')
  },
  getallpekerjaan () {
    return Api.laravel.get('getbyPekerjaan')
  },
  getallgolongandarah () {
    return Api.laravel.get('getbyGolonganDarah')
  },
  getallshdk () {
    return Api.laravel.get('getbyShdk')
  },
  getallsuku () {
    return Api.laravel.get('getbySuku')
  },
  getallkewarganegaraan () {
    return Api.laravel.get('getbyKewarganegaraan')
  },
  getallstatuspasien () {
    return Api.laravel.get('getbyStatusPasien')
  },
  getallkontak () {
    return Api.laravel.get('getbyKontak')
  },
  getallhubungan () {
    return Api.laravel.get('getbyHubungan')
  },
  getallinstalasi () {
    return Api.laravel.get('getbyInstalasi')
  },
  getallunit (idInstalasi) {
    return Api.laravel.post('getbyUnit', idInstalasi)
  },
  getallruangan (idUnit) {
    return Api.laravel.post('getbyRuangan', idUnit)
  },
  getalldokruangan (idRuang) {
    return Api.laravel.post('setDokterRuangan', idRuang)
  }
}
