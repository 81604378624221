import Api from '../axiosInstance'
const version = '/v1'
export default {
  // module
  findAll (currentPage, perPage, filters) {
    return Api.users.get(`${version}/practitioner/module?param0=module&param1=${currentPage}&param2=${perPage}&param3=${filters}`)
  },
  findId (id) {
    return Api.users.get(`${version}/practitioner/module/${id}?param0=module`)
  },
  create (data) {
    return Api.users.post(`${version}/practitioner/module`, data)
  },
  update (id, data) {
    return Api.users.put(`${version}/practitioner/module/${id}`, data)
  },
  delete (id) {
    return Api.users.delete(`${version}/practitioner/module/${id}?param0=module`)
  },
  // menu module
  findAllMenuModule (currentPage, perPage, filters) {
    return Api.users.get(`${version}/practitioner/menuSubModuleDetil?param0=menu-sub-module-detail&param1=${currentPage}&param2=${perPage}&param3=${filters}`)
  },
  findIdMenuModule (id, kode) {
    return Api.users.get(`${version}/practitioner/menuSubModuleDetil/${id}/${kode}?param0=menu-sub-module-detail`)
  },
  createMenuModule (data) {
    return Api.users.post(`${version}/practitioner/menuSubModuleDetil`, data)
  },
  updateMenuModule (id, kode, data) {
    return Api.users.put(`${version}/practitioner/menuSubModuleDetil/${id}/${kode}`, data)
  },
  deleteMenuModule (id, kode) {
    return Api.users.delete(`${version}/practitioner/menuSubModuleDetil/${id}/${kode}?param0=menu-sub-module-detail`)
  },
  // group module
  showModuleByIdGroup (id) {
    return Api.users.get(`${version}/practitioner/groupModule/group/${id}?param0=module-id-group`)
  },
  createModuleByIdGroup (data) {
    return Api.users.post(`${version}/practitioner/groupModule`, data)
  }
}
