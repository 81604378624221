<template>
  <b-container id="pasien-show" fluid>
    <div v-show="isLoading">
      <div id="show-overlay"></div>
      <Loader :style="{ opacity: .5 }" />
    </div>
    <div id="pasien-info" class="shadow-hover bg-white border mb-2">
      <div class="d-flex flex-row justify-content-between align-items-center py-2 px-3">
        <div>
          <p class="h6 text-dark font-weight-light mb-0"><i class="fas fa-male mr-1"></i>Pasien Info</p>
        </div>
        <div>
          <b-button size="sm" disabled class="mr-1" variant="secondary" v-b-modal.pasien-show><i class="fas fa-list-alt"></i></b-button>
          <b-button size="sm" disabled variant="secondary"><i class="fas fa-edit"></i></b-button>
        </div>
      </div>
      <hr class="m-0">
      <!-- <pre>{{pasien}}</pre> -->
      <b-row class="p-2 p-lg-3 align-items-center">
        <b-col cols="12" lg="6">
          <div class="d-flex flex-row align-items-center">
            <div class="mr-2">
              <b-img v-if="pasien !== null" :src="pasien.jenis_kelamin === '1' ? imgProfile.pria : imgProfile.wanita" alt="profile-img" class="avatar-130 img-fluid" />
            </div>
            <div v-if="pasien !== null">
              <div class="mb-3">
                <p class="h5 text-dark font-weight-bold text-uppercase mb-0">{{ pasien['p.nama'] }}</p>
                <p class="text-dark mb-0">{{ pasien.desc_wilayah }}, {{ convertDate(pasien.tanggal_lahir, 'convert') }} / {{ pasien.umur }}</p>
                <!-- <p class="text-dark mb-0">{{ pasien.alamat }}</p> -->
              </div>
              <div>
                <p class="mb-0">NO. REKAM MEDIS</p>
                <p class="h3 text-dark font-weight-bold mb-0">{{ pasien.norm }}</p>
              </div>
            </div>
          </div>
        </b-col>
        <b-col cols="12" lg="6">
          <div class="text-right" v-if="pasien !== null">
            <div class="mb-3">
              <p class="h6 text-dark font-weight-bold "><span class="text-muted font-weight-normal mr-2">NIK</span>{{ pasien.nik }}</p>
              <p class="h6 text-dark font-weight-bold"><span class="text-muted font-weight-normal mr-2">NO. BPJS</span>{{ pasien.no_bpjs }}</p>
              <p class="h6 text-dark font-weight-bold mb-0"><span class="text-muted font-weight-normal mr-2">NO. SEP</span>{{ pasien.no_sep }}</p>
            </div>
          </div>
        </b-col>
      </b-row>
    </div>
    <b-row class="mb-4">
      <b-col cols="12" lg="6" class="pr-lg-1">
        <div id="tagihan" class="shadow-hover bg-white border mb-2">
          <div class="d-flex flex-row justify-content-between align-items-center py-2 px-3">
            <div>
              <p class="h6 text-dark font-weight-light mb-0"><i class="fas fa-user-plus"></i> Riwayat Pendaftaran</p>
            </div>
            <div>
              <b-button size="sm" v-b-tooltip.top="'Pendaftaran Baru'" class="mr-1" disabled variant="secondary"><i class="fas fa-file"></i></b-button>
            </div>
          </div>
          <hr class="m-0 p-0">
          <div>
            <div>
              <b-table ref="table" class="mb-0" @row-clicked="listKunjungan" :busy="tablePendaftaran.isBusy" :items="tablePendaftaran.items" :fields="tablePendaftaran.fields" :select-mode="tablePendaftaran.selectMode" :current-page="tablePendaftaran.currentPage" :per-page="tablePendaftaran.perPage" stacked="md"
                hover small show-empty selectable>
                <template #table-busy>
                  <div class="text-center my-2">
                    <b-spinner class="align-middle text-primary mr-2"></b-spinner>
                    <strong class="text-dark">Memuat Data...</strong>
                  </div>
                </template>
                <template #cell(index)="data">
                  <span>{{ (tablePendaftaran.currentPage*tablePendaftaran.perPage)-tablePendaftaran.perPage + data.index + 1 }}</span>
                </template>
                <template #cell(isaktif)="data">
                  <span>{{ data.isaktif === 0 ? 'Tidak Aktif' : 'Aktif' }}</span>
                </template>
                <!-- <template #cell(aksi)="data">
                  <b-button class="px-3 py-1" variant="primary" size="sm" @click="listKunjungan(data.item.nomor_id)">
                    <i class="far fa-eye"></i>
                  </b-button>
                </template> -->
              </b-table>
            </div>
          </div>
          <hr class="m-0 p-0">
          <!-- <hr class="m-0 p-0">
          <div class="px-3 py-2 d-flex flex-row align-items-center">
            <div class="mr-auto">
              <b-pagination v-model="tablePendaftaran.currentPage" :total-rows="tablePendaftaran.totalRows" :per-page="tablePendaftaran.perPage" size="sm" class="my-0"></b-pagination>
            </div>
            <div class="mr-3">
              <span class="my-hover" @click="tableRefresh(1)">
                <i class="fas fa-sync text-primary"></i>
              </span>
            </div>
            <div>
              <b-form-select id="per-page-select" v-model="tablePendaftaran.perPage" :options="tablePendaftaran.pageOptions" size="sm"
              ></b-form-select>
            </div>
          </div> -->
        </div>
      </b-col>
      <b-col cols="12" lg="6" class="pl-lg-1">
        <div id="tagihan" class="shadow-hover bg-white border mb-2">
          <div class="d-flex flex-row justify-content-between align-items-center py-2 px-3">
            <div>
              <p class="h6 text-dark font-weight-light mb-0"><i class="fas fa-user-plus"></i> Riwayat Kunjungan</p>
            </div>
            <div>
              <b-button size="sm" v-b-tooltip.top="'Pendaftaran Baru'" class="mr-1" disabled variant="secondary"><i class="fas fa-file"></i></b-button>
            </div>
          </div>
          <hr class="m-0 p-0">
          <div>
            <div>
              <b-table ref="tableB" class="mb-0" :busy="tableKunjungan.isBusy" :items="tableKunjungan.items" :fields="tableKunjungan.fields" :select-mode="tableKunjungan.selectMode" :current-page="tableKunjungan.currentPage" :per-page="tableKunjungan.perPage" stacked="md"
                hover small show-empty selectable>
                <template #table-busy>
                  <div class="text-center my-2">
                    <b-spinner class="align-middle text-primary mr-2"></b-spinner>
                    <strong class="text-dark">Memuat Data...</strong>
                  </div>
                </template>
                <template #cell(index)="data">
                  <span>{{ (tableKunjungan.currentPage*tableKunjungan.perPage)-tableKunjungan.perPage + data.index + 1 }}</span>
                </template>
                <template #cell(isaktif)="data">
                  <span>{{ data.isaktif === 0 ? 'Tidak Aktif' : 'Aktif' }}</span>
                </template>
                <template #cell(aksi)="data">
                  <b-button class="px-3 py-1" variant="primary" size="sm" @click="toMedicalRecord(data.item.nomor_id, pasien.norm)">
                    <i class="fas fa-stethoscope"></i>
                  </b-button>
                </template>
              </b-table>
            </div>
          </div>
          <hr class="m-0 p-0">
          <!-- <hr class="m-0 p-0">
          <div class="px-3 py-2 d-flex flex-row align-items-center">
            <div class="mr-auto">
              <b-pagination v-model="tableKunjungan.currentPage" :total-rows="tableKunjungan.totalRows" :per-page="tableKunjungan.perPage" size="sm" class="my-0"></b-pagination>
            </div>
            <div class="mr-3">
              <span class="my-hover" @click="tableRefresh(1)">
                <i class="fas fa-sync text-primary"></i>
              </span>
            </div>
            <div>
              <b-form-select id="per-page-select" v-model="tableKunjungan.perPage" :options="tableKunjungan.pageOptions" size="sm"
              ></b-form-select>
            </div>
          </div> -->
        </div>
      </b-col>
      <!-- <b-col class="pl-lg-1">
        <div id="tagihan" class="shadow-hover bg-white border mb-2">
          <div class="d-flex flex-row justify-content-between align-items-center py-2 px-3">
            <div>
              <p class="h6 font-weight-light text-dark mb-0"><i class="fas fa-users"></i> Riwayat Kunjungan</p>
            </div>
            <div>
              <b-button size="sm" v-b-tooltip.top="'Pendaftaran Baru'" class="mr-1" variant="dark"><i class="fas fa-file"></i></b-button>
            </div>
          </div>
          <hr class="m-0 p-0">
          <div>
            <div>
              <b-table ref="table-2" class="mb-0" :busy="tableKunjungan.isBusy" :items="tableKunjungan.items" :fields="tableKunjungan.fields" :select-mode="tableKunjungan.selectMode" stacked="md"
                hover small show-empty selectable>
                <template #table-busy>
                  <div class="text-center my-2">
                    <b-spinner class="align-middle text-primary mr-2"></b-spinner>
                    <strong class="text-dark">Memuat Data...</strong>
                  </div>
                </template>
                <template #cell(index)="data">
                  <span>{{ (tableKunjungan.currentPage*tableKunjungan.perPage)-tableKunjungan.perPage + data.index + 1 }}</span>
                </template>
                <template #cell(tglpelayanan)="data">
                  <span>{{ convertDate(columnDotsConvert(data.item, 'tglpelayanan'), 'timestamp') }}</span>
                </template>
                <template #cell(aksi)="data">
                  <b-button class="px-3 py-1" variant="primary" size="sm">
                    <i class="fas fa-stethoscope"></i> LIHAT
                  </b-button>
                </template>
              </b-table>
            </div>
          </div>
          <hr class="m-0 p-0">
          <div class="px-3 py-2 d-flex flex-row align-items-center">
            <div class="mr-auto">
              <b-pagination v-model="tableKunjungan.currentPage" :total-rows="tableKunjungan.totalRows" :per-page="tableKunjungan.perPage" size="sm" class="my-0"></b-pagination>
            </div>
            <div class="mr-3">
              <span class="my-hover" @click="tableRefresh(2)">
                <i class="fas fa-sync text-primary"></i>
              </span>
            </div>
            <div>
              <b-form-select id="per-page-select" v-model="tableKunjungan.perPage" :options="tableKunjungan.pageOptions" size="sm"
              ></b-form-select>
            </div>
          </div>
        </div>
      </b-col> -->
    </b-row>
    <b-modal id="modal-show-kunjungan" ref="modal-show-kunjungan" header-class="text-uppercase" :title="'RIWAYAT KUNJUNGAN' + ' : ' + noKunjungan" size="xl" centered scrollable :no-close-on-backdrop="true" :static="true" :no-close-on-esc="true" :ok-only="true" cancel-title="Tutup" ok-variant="secondary" ok-title="Tutup">
      <div class="bg-white border">
        <div>
          <div>
            <b-table ref="table-2" class="mb-0" :busy="tableKunjungan.isBusy" :items="tableKunjungan.items" :fields="tableKunjungan.fields" :select-mode="tableKunjungan.selectMode" stacked="md"
              hover small show-empty selectable>
              <template #table-busy>
                <div class="text-center my-2">
                  <b-spinner class="align-middle text-primary mr-2"></b-spinner>
                  <strong class="text-dark">Memuat Data...</strong>
                </div>
              </template>
              <template #cell(index)="data">
                <span>{{ (tableKunjungan.currentPage*tableKunjungan.perPage)-tableKunjungan.perPage + data.index + 1 }}</span>
              </template>
              <template #cell(tglpelayanan)="data">
                <span>{{ convertDate(columnDotsConvert(data.item, 'tglpelayanan'), 'timestamp') }}</span>
              </template>
              <template #cell(aksi)="data">
                <b-button class="px-3 py-1" variant="primary" size="sm" @click="toMedicalRecord(data.item.nomor_id, data.item.norm)">
                  <i class="fas fa-stethoscope"></i> EMR
                </b-button>
              </template>
            </b-table>
          </div>
        </div>
        <hr class="m-0 p-0">
        <div class="px-3 py-2 d-flex flex-row align-items-center">
          <div class="mr-auto">
            <b-pagination v-model="tableKunjungan.currentPage" :total-rows="tableKunjungan.totalRows" :per-page="tableKunjungan.perPage" size="sm" class="my-0"></b-pagination>
          </div>
          <div class="mr-3">
            <span class="my-hover" @click="tableRefresh(2)">
              <i class="fas fa-sync text-primary"></i>
            </span>
          </div>
          <div>
            <b-form-select id="per-page-select" v-model="tableKunjungan.perPage" :options="tableKunjungan.pageOptions" size="sm"
            ></b-form-select>
          </div>
        </div>
      </div>
    </b-modal>
    <b-modal id="pasien-show-modal" :ok-only="true" title="Detail Pasien" class="p-0">
      <div>
        <!-- <div class="head-card">
          <b-row class="justify-content-start">
            <b-col cols="12" lg="4">
              <div class="cstm-card bg-dark shadow p-4">
                <div class="d-flex flex-column justify-content-between h-100 w-100">
                  <div class="mb-4">
                    <b-row>
                      <b-col class="d-flex align-items-center">
                        <div>
                          <p class=" text-white txt-shdw mb-0">Direktorat Jendral Pelayanan Kesehatan</p>
                          <p class="h5 text-white txt-shdw font-weight-bold mb-0">RSUP Fatmawati</p>
                        </div>
                      </b-col>
                      <b-col cols="3">
                        <div class="tex-right">
                          <b-img :src="imgProfile.kemkes" alt="profile-img" width="125rem" class="img-fluid mb-3" />
                        </div>
                      </b-col>
                    </b-row>
                  </div>
                  <div class="mb-4">
                    <b-row>
                      <b-col>
                        <div class="d-flex align-items-center w-100">
                          <div>
                            <b-img :src="imgProfile.kartu" alt="profile-img" width="150rem" class="img-fluid mb-3" />
                          </div>
                          <div class="text-right w-100">
                            <p class="h1 text-white txt-shdw font-weight-bold mb-1">{{pasien.norm | norm}}</p>
                            <p class="h3 text-white txt-shdw mb-0">{{pasien.nama}}</p>
                          </div>
                        </div>
                      </b-col>
                    </b-row>
                  </div>
                  <div>
                    <b-row>
                      <b-col>
                        <div class="d-flex">
                          <div class="p-2">
                            <p class="h5 font-weight-bold txt-shdw text-white mb-1">Protection For Your Hospital</p>
                          </div>
                        </div>
                      </b-col>
                    </b-row>
                  </div>
                </div>
              </div>
            </b-col>
            <b-col cols="12" lg="8">
              <div class="p-4 shadow border border-primary">
                <b-row>
                  <b-col cols="12" lg="2" class="bg-foto text-center">
                    <div>
                      <b-img :src="pasien.jenis_kelamin === 1 ? imgProfile.priatrns : imgProfile.wanitatrns" alt="profile-img"
                        class="avatar-130 img-fluid mb-3" />
                    </div>
                    <p class="h4 font-weight-bold text-primary mb-0 txt-shdw2">{{pasien.norm | norm}}</p>
                    <p class=" font-weight-bold text-dark mb-0">{{pasien.nama}}</p>
                  </b-col>
                  <b-col cols="12" lg="5">
                    <b-row class="mb-2">
                      <b-col cols="5">
                        <p class="small text-primary mb-0">TEMPAT LAHIR</p>
                      </b-col>
                      <b-col cols="1">
                        <p class="small text-dark mb-0">:</p>
                      </b-col>
                      <b-col cols="6">
                        <p class="small font-weight-bold text-uppercase text-dark mb-0" v-if="pasien.nm_tempat_lahir === null">-
                        </p>
                        <p class="small font-weight-bold text-uppercase text-dark mb-0" v-else>{{pasien.nm_tempat_lahir}}</p>
                      </b-col>
                    </b-row>
                    <b-row class="mb-2">
                      <b-col cols="5">
                        <p class="small text-primary mb-0">TANGGAL LAHIR</p>
                      </b-col>
                      <b-col cols="1">
                        <p class="small text-dark mb-0">:</p>
                      </b-col>
                      <b-col cols="6">
                        <p class="small font-weight-bold text-uppercase text-dark mb-0">{{ new Date(pasien.tanggal_lahir) | formatDate }}</p>
                      </b-col>
                    </b-row>
                    <b-row class="mb-2">
                      <b-col cols="5">
                        <p class="small text-primary mb-0">UMUR</p>
                      </b-col>
                      <b-col cols="1">
                        <p class="small text-dark mb-0">:</p>
                      </b-col>
                      <b-col cols="6">
                        <p class="small font-weight-bold text-uppercase text-dark mb-0">{{ pasien.tanggal_lahir | umurymd }}</p>
                      </b-col>
                    </b-row>
                    <b-row class="mb-2">
                      <b-col cols="5">
                        <p class="small text-primary mb-0">AGAMA</p>
                      </b-col>
                      <b-col cols="1">
                        <p class="small text-dark mb-0">:</p>
                      </b-col>
                      <b-col cols="6">
                        <p class="small font-weight-bold text-uppercase text-dark mb-0" v-if="pasien.nm_agama === null">-</p>
                        <p class="small font-weight-bold text-uppercase text-dark mb-0" v-else>{{pasien.nm_agama}}</p>
                      </b-col>
                    </b-row>
                    <b-row class="mb-2">
                      <b-col cols="5">
                        <p class="small text-primary mb-0">JENIS KELAMIN</p>
                      </b-col>
                      <b-col cols="1">
                        <p class="small text-dark mb-0">:</p>
                      </b-col>
                      <b-col cols="6">
                        <p class="small font-weight-bold text-uppercase text-dark mb-0">{{pasien.nm_jenis_kelamin}}</p>
                      </b-col>
                    </b-row>
                    <b-row class="mb-2">
                      <b-col cols="5">
                        <p class="small text-primary mb-0">PENDIDIKAN</p>
                      </b-col>
                      <b-col cols="1">
                        <p class="small text-dark mb-0">:</p>
                      </b-col>
                      <b-col cols="6">
                        <p class="small font-weight-bold text-uppercase text-dark mb-0" v-if="pasien.nm_pendidikan === null">-</p>
                        <p class="small font-weight-bold text-uppercase text-dark mb-0" v-else>{{pasien.nm_pendidikan}}</p>
                      </b-col>
                    </b-row>
                    <b-row class="mb-2">
                      <b-col cols="5">
                        <p class="small text-primary mb-0">PEKERJAAN</p>
                      </b-col>
                      <b-col cols="1">
                        <p class="small text-dark mb-0">:</p>
                      </b-col>
                      <b-col cols="6">
                        <p class="small font-weight-bold text-uppercase text-dark mb-0" v-if="pasien.nm_pekerjaan === null">-</p>
                        <p class="small font-weight-bold text-uppercase text-dark mb-0" v-else>{{pasien.nm_pekerjaan}}</p>
                      </b-col>
                    </b-row>
                    <b-row class="mb-2">
                      <b-col cols="5">
                        <p class="small text-primary mb-0">GOLONGAN DARAH</p>
                      </b-col>
                      <b-col cols="1">
                        <p class="small text-dark mb-0">:</p>
                      </b-col>
                      <b-col cols="6">
                        <p class="small font-weight-bold text-uppercase text-dark mb-0" v-if="pasien.nm_golongan_darah === null">-</p>
                        <p class="small font-weight-bold text-uppercase text-dark mb-0" v-else>{{pasien.nm_golongan_darah}}</p>
                      </b-col>
                    </b-row>
                    <b-row class="mb-2">
                      <b-col cols="5">
                        <p class="small text-primary mb-0">STATUS PERKAWINAN</p>
                      </b-col>
                      <b-col cols="1">
                        <p class="small text-dark mb-0">:</p>
                      </b-col>
                      <b-col cols="6">
                        <p class="small font-weight-bold text-uppercase text-dark mb-0" v-if="pasien.nm_status_perkawinan === null">-</p>
                        <p class="small font-weight-bold text-uppercase text-dark mb-0" v-else>{{pasien.nm_status_perkawinan}}</p>
                      </b-col>
                    </b-row>
                  </b-col>
                  <b-col cols="12" lg="5">
                    <b-row class="mb-2">
                      <b-col cols="5">
                        <p class="small text-primary mb-0">KEWARGANEGARAAN</p>
                      </b-col>
                      <b-col cols="1">
                        <p class="small text-dark mb-0">:</p>
                      </b-col>
                      <b-col cols="6">
                        <p class="small font-weight-bold text-uppercase text-dark mb-0" v-if="pasien.nm_kewarganegaraan === null">-</p>
                        <p class="small font-weight-bold text-uppercase text-dark mb-0" v-else>{{pasien.nm_kewarganegaraan}}</p>
                      </b-col>
                    </b-row>
                    <b-row class="mb-2">
                      <b-col cols="5">
                        <p class="small text-primary mb-0">ALAMAT</p>
                      </b-col>
                      <b-col cols="1">
                        <p class="small text-dark mb-0">:</p>
                      </b-col>
                      <b-col cols="6">
                        <p class="small font-weight-bold text-uppercase text-dark mb-0" v-if="pasien.alamat === null">-</p>
                        <p class="small font-weight-bold text-uppercase text-dark mb-0" v-else>{{pasien.alamat}}</p>
                      </b-col>
                    </b-row>
                    <b-row class="mb-2">
                      <b-col cols="5">
                        <p class="small text-primary mb-0">RT</p>
                      </b-col>
                      <b-col cols="1">
                        <p class="small text-dark mb-0">:</p>
                      </b-col>
                      <b-col cols="6">
                        <p class="small font-weight-bold text-uppercase text-dark mb-0" v-if="pasien.rt === null">-</p>
                        <p class="small font-weight-bold text-uppercase text-dark mb-0" v-else>{{pasien.rt}}</p>
                      </b-col>
                    </b-row>
                    <b-row class="mb-2">
                      <b-col cols="5">
                        <p class="small text-primary mb-0">RW</p>
                      </b-col>
                      <b-col cols="1">
                        <p class="small text-dark mb-0">:</p>
                      </b-col>
                      <b-col cols="6">
                        <p class="small font-weight-bold text-uppercase text-dark mb-0" v-if="pasien.rw === null">-</p>
                        <p class="small font-weight-bold text-uppercase text-dark mb-0" v-else>{{pasien.rw}}</p>
                      </b-col>
                    </b-row>
                    <b-row class="mb-2">
                      <b-col cols="5">
                        <p class="small text-primary mb-0">KODE POS</p>
                      </b-col>
                      <b-col cols="1">
                        <p class="small text-dark mb-0">:</p>
                      </b-col>
                      <b-col cols="6">
                        <p class="small font-weight-bold text-uppercase text-dark mb-0" v-if="pasien.kodepos === null || pasien.kodepos == ''">-</p>
                        <p class="small font-weight-bold text-uppercase text-dark mb-0" v-else>{{pasien.kodepos}}</p>
                      </b-col>
                    </b-row>
                    <b-row class="mb-2">
                      <b-col cols="5">
                        <p class="small text-primary mb-0">KELURAHAN</p>
                      </b-col>
                      <b-col cols="1">
                        <p class="small text-dark mb-0">:</p>
                      </b-col>
                      <b-col cols="6">
                        <p class="small font-weight-bold text-uppercase text-dark mb-0" v-if="pasien.nm_wilayah_kelurahan === null">-</p>
                        <p class="small font-weight-bold text-uppercase text-dark mb-0" v-else>{{pasien.nm_wilayah_kelurahan}}</p>
                      </b-col>
                    </b-row>
                    <b-row class="mb-2">
                      <b-col cols="5">
                        <p class="small text-primary mb-0">KECAMATAN</p>
                      </b-col>
                      <b-col cols="1">
                        <p class="small text-dark mb-0">:</p>
                      </b-col>
                      <b-col cols="6">
                        <p class="small font-weight-bold text-uppercase text-dark mb-0" v-if="pasien.nm_wilayah_kecamatan === null">-</p>
                        <p class="small font-weight-bold text-uppercase text-dark mb-0" v-else>{{pasien.nm_wilayah_kecamatan}}</p>
                      </b-col>
                    </b-row>
                    <b-row class="mb-2">
                      <b-col cols="5">
                        <p class="small text-primary mb-0">KABUPATEN / KOTA</p>
                      </b-col>
                      <b-col cols="1">
                        <p class="small text-dark mb-0">:</p>
                      </b-col>
                      <b-col cols="6">
                        <p class="small font-weight-bold text-uppercase text-dark mb-0" v-if="pasien.nm_wilayah_kabupaten === null">-</p>
                        <p class="small font-weight-bold text-uppercase text-dark mb-0" v-else>{{pasien.nm_wilayah_kabupaten}}</p>
                      </b-col>
                    </b-row>
                    <b-row class="mb-2">
                      <b-col cols="5">
                        <p class="small text-primary mb-0">PROVINSI</p>
                      </b-col>
                      <b-col cols="1">
                        <p class="small text-dark mb-0">:</p>
                      </b-col>
                      <b-col cols="6">
                        <p class="small font-weight-bold text-uppercase text-dark mb-0" v-if="pasien.nm_wilayah_provinsi === null">-</p>
                        <p class="small font-weight-bold text-uppercase text-dark mb-0" v-else>{{pasien.nm_wilayah_provinsi}}</p>
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row>
              </div>
            </b-col>
          </b-row>
          <div class="mt-5">
            <tab-nav :tabs="true" id="myTab-two" class="justify-content-center justify-content-lg-start">
              <tab-nav-items :active="true" id="home-tab-two" ariaControls="home" href="#home-two" role="tab"
                :ariaSelected="true">
                <template v-slot:title>
                  <div>
                    <i class="fas fa-address-card"></i> Kartu Identitas
                  </div>
                </template>
              </tab-nav-items>
              <tab-nav-items :active="false" id="profile-tab-two" ariaControls="profile" href="#profile-two" role="tab"
                :ariaSelected="false">
                <template v-slot:title>
                  <div>
                    <i class="fas fa-address-card"></i> Kartu Jaminan Asuransi
                  </div>
                </template>
              </tab-nav-items>
              <tab-nav-items :active="false" id="contact-tab-two" ariaControls="contact" href="#contact-two" role="tab"
                :ariaSelected="false">
                <template v-slot:title>
                  <div>
                    <i class="fas fa-users"></i> Keluarga Pasien
                  </div>
                </template>
              </tab-nav-items>
              <tab-nav-items :active="false" id="contact-tab-three" ariaControls="contactB" href="#contact-three" role="tab"
                :ariaSelected="false">
                <template v-slot:title>
                  <div>
                    <i class="fas fa-phone-alt"></i> Kontak
                  </div>
                </template>
              </tab-nav-items>
            </tab-nav>
            <tab-content id="myTabContent-1">
              <tab-content-item :active="true" id="home-two" aria-labelled-by="home-tab-two">
                <template>
                  <div>
                    <b-table striped hover bordered :items="tableIdentitas" :fields="fieldsIdentitas">
                      <template #cell(index)="data">
                        {{ data.index + 1 }}
                      </template>
                    </b-table>
                  </div>
                </template>
              </tab-content-item>
              <tab-content-item :active="false" id="profile-two" aria-labelled-by="profile-tab-two">
                <template>
                  <div>
                    <b-table striped hover bordered :items="itemsTabelB"></b-table>
                  </div>
                </template>
              </tab-content-item>
              <tab-content-item :active="false" id="contact-two" aria-labelled-by="contact-tab-two">
                <template>
                  <div>
                    <b-table striped hover bordered :items="tableKeluarga" :fields="fieldsKeluarga"></b-table>
                  </div>
                </template>
              </tab-content-item>
              <tab-content-item :active="false" id="contact-three" aria-labelled-by="contact-tab-three">
                <template>
                  <div>
                    <b-table striped hover bordered :items="tableKontak" :fields="fieldsKontak">
                      <template #cell(index)="data">
                        {{ data.index + 1 }}
                      </template>
                    </b-table>
                  </div>
                </template>
              </tab-content-item>
            </tab-content>
          </div>
        </div> -->
      </div>
    </b-modal>
    <b-modal ref="modal-sync-ihs" size="md" title="Informasi" ok-title="Oke" :ok-only=true :hide-header=true centered>
      <div class="text-center py-4">
        <lottie :options="defaultOptions" :autoPlay="true" :width="250" :height="250" />
        <p class="h5 text-dark font-weight-bold mb-0">{{pesan}}</p>
      </div>
    </b-modal>
    <b-modal ref="modal-sync-ihs-gagal" size="md" title="Informasi" ok-title="Oke" :ok-only=true :hide-header=true centered>
      <div class="text-center py-4">
        <lottie :options="defaultOptions2" :autoPlay="true" :width="250" :height="250" />
        <p class="h5 text-dark font-weight-bold mb-0">{{error}}</p>
      </div>
    </b-modal>
    <b-modal ref="modal-notifikasi" size="md" title="Informasi" ok-title="Oke" :ok-only=true :hide-header="true" :hide-footer="true" centered>
      <div class="text-center py-4">
        <p class="h5 text-dark font-weight-normal text-uppercase mb-0">{{notifikasi}}</p>
      </div>
    </b-modal>
  </b-container>
</template>
<script>
import { xray } from '../../../config/pluginInit'
import Loader from '../../../components/xray/loader/Loader'
// import Pasien from '../../../services/fatmahost/pasien/index.ts'
import Services from '../../../services/fatmahost/index.js'
import Lottie from 'vue-lottie'
import animationLogo from '../../../assets/lottie/checked.json'
import animationLogo2 from '../../../assets/lottie/failed.json'
// import pasien from '../../../services/fatmahost/pasien/index.ts'

export default {
  components: {
    Loader,
    Lottie
  },
  mounted () {
    xray.index()
  },
  watch: {
    now () {},
    routeId: {
      immediate: true,
      handler (newVal, oldVal) {
        // this.listKunjungan.items = []
        // this.listPendaftaranKunjungan.items = []
        this.listPendaftaranKunjungan()
      }
    }
  },
  computed: {
    now () {
      return this.viewPasien(this.$route.params.id)
    },
    routeId () {
      return this.$route.params.id
    }
  },
  data () {
    return {
      isLoading: false,
      itemsTabelB: [
        { No: 1, Nomor: '-', Jenis: 'TUNAI' }
      ],
      error: [],
      notifikasi: '',
      imgProfile: {
        pria: require('../../../assets/images/user/14.jpg'),
        priatrns: require('../../../assets/images/14.png'),
        wanitatrns: require('../../../assets/images/14.png'),
        kartu: require('../../../assets/images/logo-rs.png'),
        kemkes: require('../../../assets/images/logo-kemkes-putih.png'),
        wanita: require('../../../assets/images/user/15.jpg')
      },
      doctor: {
        profile: require('../../../assets/images/user/1.jpg')
      },
      pasien: {},
      identitas: [],
      kontak: [],
      tableIdentitas: [],
      ihs: [],
      noPendaftaran: '',
      noKunjungan: '',
      pesan: '',
      norm: '',
      fieldsIdentitas: [
        { label: 'No', key: 'index', class: 'text-center' },
        { label: 'Nomor', key: 'nomor', class: 'text-left' },
        { label: 'Jenis', key: 'nm_jenis', class: 'text-left' },
        { label: 'Alamat', key: 'alamat', class: 'text-left' },
        { label: 'Kode Pos', key: 'kodepos', class: 'text-left' }
      ],
      tableKontak: [],
      fieldsKontak: [
        { label: 'No', key: 'index', class: 'text-center' },
        { label: 'Jenis Kontak', key: 'nm_jenis', class: 'text-left' },
        { label: 'Nomor', key: 'nomor', class: 'text-left' }
      ],
      tableKeluarga: [],
      fieldsKeluarga: [
        { label: 'No', key: 'index', class: 'text-center' },
        { label: 'Nama', key: 'nama', class: 'text-left' },
        { label: 'Hubungan', key: 'nm_shdk', class: 'text-left' },
        { label: 'Jenis Kontak', key: 'nm_jenis', class: 'text-left' },
        { label: 'Nomor', key: 'nomor', class: 'text-left' }
      ],
      monthName: [
        '',
        'Januari',
        'Februari',
        'Maret',
        'April',
        'Mei',
        'Juni',
        'Juli',
        'Agustus',
        'September',
        'Oktober',
        'November',
        'Desember'
      ],
      tablePendaftaran: {
        isBusy: false,
        items: [],
        fields: [
          { label: 'NO.', key: 'index', class: 'text-lg-center py-2' },
          { label: 'NOMOR PENDAFTARAN', key: 'nomor' },
          { label: 'TANGGAL MASUK', key: 'tanggal' },
          { label: 'CARA BAYAR', key: 'desc_carabayar' },
          { label: 'STATUS', key: 'isaktif' }
          // { label: 'AKSI', key: 'aksi', class: 'text-center py-1' }
        ],
        pageOptions: [5, 10, 50, 100],
        currentPage: 1,
        perPage: 10,
        totalRows: 1000,
        selectMode: 'single',
        condition: ''
      },
      tableKunjungan: {
        isBusy: false,
        items: [],
        fields: [
          { label: 'NO.', key: 'index', class: 'text-lg-center py-2' },
          { label: 'RUANGAN', key: 'desc_ruangan' },
          { label: 'TANGGAL MASUK', key: 'masuk' },
          { label: 'DOKTER', key: 'desc_dokter' },
          { label: 'STATUS', key: 'isaktif' },
          { label: 'AKSI', key: 'aksi', class: 'text-center py-1' }
        ],
        pageOptions: [5, 10, 50, 100],
        currentPage: null,
        perPage: null,
        totalRows: null,
        selectMode: 'single',
        condition: ''
      },
      defaultOptions: { animationData: animationLogo, loop: true },
      defaultOptions2: { animationData: animationLogo2, loop: true }
    }
  },
  methods: {
    async viewPasien (norm) {
      try {
        const param1 = ``
        const param2 = `{"object":"SIMRS_EMR_RPASIEN","xparam0":"${norm}","xparam1":"","xparam2":"","xparam3":"","xparam4":"","xparam5":"","xparam6":"","xparam7":"","xparam8":"","xparam9":""}`
        const response = await Services.middleware.find(param1, param2)
        if (response.data.statcode === 0) {
          if (response.data.rowcount === 1) {
            this.pasien = response.data.data[0]
          } else {
            this.notifikasi = response.data.message
            this.$refs['modal-notifikasi'].show()
          }
        } else {
          this.pasien = {}
          this.notifikasi = response.data.message
          this.$refs['modal-notifikasi'].show()
        }
      } catch (error) {
        this.pasien = {}
        this.notifikasi = error.message
      }
    },
    async listPendaftaranKunjungan () {
      this.tablePendaftaran.isBusy = true
      this.tableKunjungan.items = []
      try {
        const param1 = ``
        const param2 = `{"object":"SIMRS_EMR_RPENDAFTARAN","xparam0":"${this.$route.params.id}","xparam1":"","xparam2":"","xparam3":"","xparam4":"","xparam5":"","xparam6":"","xparam7":"","xparam8":"","xparam9":""}`
        const response = await Services.middleware.find(param1, param2)
        if (response.data.statcode === 0) {
          this.tablePendaftaran.isBusy = false
          if (response.data.rowcount !== 0) {
            this.tablePendaftaran.items = response.data.data
          } else {
            this.tablePendaftaran.items = []
          }
        } else {
          this.tablePendaftaran.isBusy = false
          this.tablePendaftaran.items = []
        }
      } catch (error) {
        this.tablePendaftaran.isBusy = false
        this.tablePendaftaran.items = []
        this.error = error.message
      }
    },
    async listKunjungan (item, index, event) {
      this.tableKunjungan.isBusy = true
      try {
        const param1 = ``
        const param2 = `{"object":"SIMRS_EMR_RKUNJUNGAN","xparam0":"${item.nomor_id}","xparam1":"","xparam2":"","xparam3":"","xparam4":"","xparam5":"","xparam6":"","xparam7":"","xparam8":"","xparam9":""}`
        const response = await Services.middleware.find(param1, param2)
        if (response.data.statcode === 0) {
          this.tableKunjungan.items = response.data.data
          this.tableKunjungan.isBusy = false
        } else {
          this.tableKunjungan.isBusy = false
          this.tableKunjungan.items = []
        }
      } catch (error) {
        this.tableKunjungan.isBusy = false
        this.tableKunjungan.items = []
        this.error = error.message
      }
    },
    openModal (value) {
      this.noKunjungan = value
      this.$refs[`modal-show-kunjungan`].show()
      this.tableRefresh(2)
    },
    convertDate (value, param) {
      const date = new Date(value)
      const day = String(date.getDate()).padStart(2, '0')
      const month = String(date.getMonth() + 1).padStart(2, '0')
      const year = String(date.getFullYear())
      const hours = String(date.getHours()).padStart(2, '0')
      const minutes = String(date.getMinutes()).padStart(2, '0')
      const seconds = String(date.getSeconds()).padStart(2, '0')
      if (param === 'timestamp') return day + ' ' + this.monthName[parseInt(month)] + ', ' + year + ' ' + hours + ':' + minutes + ':' + seconds
      if (param === 'convert') return day + ' ' + this.monthName[parseInt(month)] + ' ' + year
      if (param === 'use') return year + '-' + month + '-' + day
    },
    columnDotsConvert (column, desc) {
      return column[desc]
    },
    tableRefresh (param) {
      if (param === 1) {
        this.$refs['table'].refresh()
      } else {
        this.$refs['table-2'].refresh()
      }
    },
    async toMedicalRecord (id, norm) {
      const data = {
        fhir: id,
        norm: norm
      }
      this.$store.dispatch('Emr/createCache', data)
      this.$router.push({
        name: 'pasien.rekam-medis', params: { id }, query: { norm }
      })
    }
  }
}
</script>
<style>
/* #pasien-show-modal .modal-dialog {
    min-width: 100%!important;
    margin: 0px!important;
}
#pasien-show-modal .modal-dialog {
    width: 100%!important;
    height: 100%!important;
}
#pasien-show-modal .modal-content {
    min-height: 100%;
} */
#modal-show-kunjungan .modal-body {
  padding: 0px;
}
#pasien-show .cstm-card {
    background-image: url('../../../assets/images/batik-bg.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center right;
}
#pasien-show .bg-foto {
    background-image: url('../../../assets/images/bg-photo.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center right;
}
#pasien-show .txt-color {
  color: #396033;
}
#pasien-show .txt-shdw {
  text-shadow: 2px 2px #616161;
}
#pasien-show .bg-detail {
  background: #89c8d725;
}
#pasien-show .bg-detail2 {
  background: #fff8e3;
}
#pasien-show .shadow-hover {
  box-shadow: 10px 10px 15px 1px rgba(0, 0, 0, 0);
  transition: all ease-in-out .3s;
}
#pasien-show .shadow-hover:hover {
  box-shadow: 10px 10px 15px 1px rgba(0, 0, 0, 0.167);
  transition: all ease-in-out .3s;
}
#pasien-show .txt-shdw2 {
  text-shadow: rgb(255, 255, 255) 1px 0 10px;
}
#pasien-show .nav-tabs {
  margin-bottom: 0px;
}
#pasien-show .table td {
  font-size: 12px;
  font-weight: 500;
  padding-top: 0px;
  padding-bottom: 0px;
}

#pasien-show .table th {
  font-size: 13px;
  font-weight: 400;
}

#pasien-show .page-link {
  background: transparent !important;
  border: none !important;
  color: gray !important;
  margin-right: 15px;
}

#pasien-show .page-item.active .page-link {
  background-color: transparent;
  border-color: transparent;
  color: #089bab !important;
  font-weight: bold;
}
#pasien-show .my-hover {
  cursor: pointer;
}
</style>
