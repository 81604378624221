<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12">
        <b-row>
          <b-col md="6" lg="3">
            <iq-card class-name="iq-card-block iq-card-stretch iq-card-height" body-class="iq-bg-primary rounded">
              <template v-slot:body>
                <div class="d-flex align-items-center justify-content-between">
                  <div class="rounded-circle iq-card-icon bg-primary"><i class="fas fa-ambulance"></i></div>
                  <div class="text-right">
                    <h2 class="mb-0"><span class="counter">{{ pasienigd }}</span></h2>
                    <h5 class="font-weight-bold">IGD</h5>
                  </div>
                </div>
              </template>
            </iq-card>
          </b-col>
          <b-col md="6" lg="3">
            <iq-card class-name="iq-card-block iq-card-stretch iq-card-height" body-class="iq-bg-warning rounded">
              <template v-slot:body >
                <div class="d-flex align-items-center justify-content-between">
                  <div class="rounded-circle iq-card-icon bg-warning"><i class="fas fa-x-ray"></i></div>
                  <div class="text-right">
                    <h2 class="mb-0"><span class="counter">{{ pasienradiologi }}</span></h2>
                    <h5 class="font-weight-bold">Radiologi</h5>
                  </div>
                </div>
              </template>
            </iq-card>
          </b-col>
          <b-col md="6" lg="3">
            <iq-card class-name="iq-card-block iq-card-stretch iq-card-height" body-class="iq-bg-danger rounded">
              <template v-slot:body >
                <div class="d-flex align-items-center justify-content-between">
                  <div class="rounded-circle iq-card-icon bg-danger"><i class="fas fa-vial"></i></div>
                  <div class="text-right">
                    <h2 class="mb-0"><span class="counter">{{ pasienlab }}</span></h2>
                    <h5 class="font-weight-bold">Laboratorium</h5>
                  </div>
                </div>
              </template>
            </iq-card>
          </b-col>
          <b-col md="6" lg="3">
            <iq-card class-name="iq-card-block iq-card-stretch iq-card-height" body-class="iq-bg-info rounded">
              <template v-slot:body >
                <div class="d-flex align-items-center justify-content-between">
                  <div class="rounded-circle iq-card-icon bg-info"><i class="fas fa-prescription-bottle-alt"></i></div>
                  <div class="text-right">
                    <h2 class="mb-0"><span class="counter">{{ pasienfarmasi }}</span></h2>
                    <h5 class="font-weight-bold">Farmasi</h5>
                  </div>
                </div>
              </template>
            </iq-card>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <b-row>
      <b-col lg="6" v-for="(item,index) in charts" :key="index">
        <iq-card>
          <template v-slot:headerTitle>
            <h4>{{ item.title }}</h4>
          </template>
          <template v-slot:body>
            <MorrisChart :element="item.type+index" :type="item.type" :xKeys="item.xKeys" :data="item.bodyData" :colors="item.colors" :yKeys="item.yKeys" :labels="item.labels"/>
          </template>
        </iq-card>
      </b-col>
    </b-row>
    <b-row>
      <b-col lg="12" v-for="(item,index) in chartsunit" :key="index">
        <iq-card>
          <template v-slot:headerTitle>
            <h4>{{ item.title }}</h4>
          </template>
          <template v-slot:body>
            <HighChart :ref="item.type" :option="item.bodyData"/>
          </template>
        </iq-card>
      </b-col>
    </b-row>
    <b-row>
      <b-col lg="6" v-for="(item,index) in chartsranap" :key="index">
        <iq-card>
          <template v-slot:headerTitle>
            <h4>{{ item.title }}</h4>
          </template>
          <template v-slot:body>
            <HighChart :ref="item.type" :option="item.bodyData"/>
          </template>
        </iq-card>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { xray } from '../../config/pluginInit'
import IqCard from '../../components/xray/cards/iq-card'
import Dashboard from '../../services/fatmahost/dashboard/index'
import HighCharts from 'highcharts'
import More from 'highcharts/highcharts-more'
// import Master from '../../services/fatmahost/master/index'
More(HighCharts)
export default {
  name: 'Dashboard1',
  components: { IqCard },
  async mounted () {
    xray.index()
    this.viewDashboard()
  },
  data () {
    return {
      pasienigd: 0,
      pasienradiologi: 0,
      pasienlab: 0,
      pasienfarmasi: 0,
      charts: [
        {
          title: 'IRJ ( Instalasi Rawat Jalan )',
          type: 'bar',
          bodyData: [],
          xKeys: 'CARABAYAR',
          yKeys: [],
          labels: [],
          colors: [ '#FF6384', '#36A2EB', '#FFCE56', '#6FEDD6' ]
        },
        {
          title: 'IRJ ( Instalasi Rawat Jalan )',
          type: 'bar',
          bodyData: [],
          xKeys: 'CARABAYAR',
          yKeys: [],
          labels: [],
          colors: [ '#FF6384', '#36A2EB', '#FFCE56', '#6FEDD6' ]
        }
      ],
      chartsunit: [
        {
          title: 'IRJ (Instalasi Rawat Jalan )',
          bodyData: {
            chart: {
              plotBackgroundColor: null,
              plotBorderWidth: null,
              plotShadow: false,
              type: 'pie'
            },
            colorAxis: {},
            title: {
              text: 'Data Kunjungan Pasien PerUnit'
            },
            tooltip: {
              pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>'
            },
            plotOptions: {
              pie: {
                allowPointSelect: true,
                cursor: 'pointer',
                dataLabels: {
                  enabled: true,
                  format: '<b>{point.name}</b>: {point.percentage:.1f} %'
                }
              }
            },
            series: [{
              name: 'Brands',
              colorByPoint: true,
              data: []
            }]
          }
        }
      ],
      chartsranap: [
        {
          title: 'IRNA (Instalasi Rawat Inap )',
          bodyData: {
            chart: {
              plotBackgroundColor: null,
              plotBorderWidth: null,
              plotShadow: false,
              type: 'pie'
            },
            colorAxis: {},
            title: {
              text: 'Data Pasien Masuk'
            },
            tooltip: {
              pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>'
            },
            plotOptions: {
              pie: {
                allowPointSelect: true,
                cursor: 'pointer',
                dataLabels: {
                  enabled: true,
                  format: '<b>{point.name}</b>: {point.percentage:.1f} %'
                }
              }
            },
            series: [{
              name: 'Brands',
              colorByPoint: true,
              data: []
            }]
          }
        },
        {
          title: 'IRNA (Instalasi Rawat Inap )',
          bodyData: {
            chart: {
              plotBackgroundColor: null,
              plotBorderWidth: null,
              plotShadow: false,
              type: 'pie'
            },
            colorAxis: {},
            title: {
              text: 'Data Pasien Keluar'
            },
            tooltip: {
              pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>'
            },
            plotOptions: {
              pie: {
                allowPointSelect: true,
                cursor: 'pointer',
                dataLabels: {
                  enabled: true,
                  format: '<b>{point.name}</b>: {point.percentage:.1f} %'
                }
              }
            },
            series: [{
              name: 'Brands',
              colorByPoint: true,
              data: []
            }]
          }
        }
      ]
    }
  },
  methods: {
    async viewDashboard () {
      try {
        const res = await Dashboard.dash_home()
        const response = res.data
        const dataigd = response.data.dataIgd
        const dataradiologi = response.data.dataPenunjangRad
        const datalab = response.data.dataPenunjangLab
        const datafarmasi = response.data.dataPenunjangFarmasi
        let totaligd = dataigd.reduce((val, element) => { return val + element.JUMLAH }, 0)
        let totalradiologi = dataradiologi.reduce((val, element) => { return val + element.JUMLAH }, 0)
        let totallab = datalab.reduce((val, element) => { return val + element.JUMLAH }, 0)
        let totalfarmasi = datafarmasi.reduce((val, element) => { return val + element.JUMLAH }, 0)

        this.pasienigd = totaligd
        this.pasienradiologi = totalradiologi
        this.pasienlab = totallab
        this.pasienfarmasi = totalfarmasi

        response.data.dataKunjunganPerCarabayar.slice(0, 5).map((value, index) => {
          this.charts[0].bodyData.push({
            CARABAYAR: value.CARABAYAR,
            [index]: value.JUMLAH
          })
          this.charts[0].yKeys.push(index)
          this.charts[0].labels.push(value.CARABAYAR)
        })
        response.data.dataKunjunganPerCarabayar.slice(5, 11).map((value, index) => {
          this.charts[1].bodyData.push({
            CARABAYAR: value.CARABAYAR,
            [index]: value.JUMLAH
          })
          this.charts[1].yKeys.push(index)
          this.charts[1].labels.push(value.CARABAYAR)
        })
        response.data.dataKunjunganPerUnit.map((value) => {
          this.chartsunit[0].bodyData.series[0].data.push({
            name: value.UNIT,
            y: value.JUMLAH
          })
        })
        response.data.dataRanapPasienMasuk.map((value) => {
          this.chartsranap[0].bodyData.series[0].data.push({
            name: value.CARABAYAR,
            y: value.JUMLAH
          })
        })
        response.data.dataRanapPasienKeluar.map((value) => {
          this.chartsranap[1].bodyData.series[0].data.push({
            name: value.CARABAYAR,
            y: value.JUMLAH
          })
        })
      } catch (error) {
        // console.log(error)
      }
    },
    pasienmasuk () {

    }
  }
}
</script>

<style>
.iq-card-body{
  flex: unset;
}
</style>
