<template>
  <div id="katalog-jenisAnggaran" class="mb-4">
    <b-container fluid>
      <div>
        <iq-card>
          <template v-slot:body>
            <div class="filter-jenisAnggaran border p-2">
              <b-row class="align-items-center">
                <b-col cols="12" lg="3" class="d-flex flex-row w-100 mr-auto">
                  <div>
                    <h5 class="card-title font-weight-bold mb-0">Master Jenis Anggaran</h5>
                  </div>
                </b-col>
                <b-col cols="12" lg="9">
                  <div class="text-right">
                    <b-button class="custom-button-radius" size="sm" variant="primary" @click="tambahModal"><i class="fas fa-plus"></i> Tambah</b-button>
                  </div>
                </b-col>
              </b-row>
            </div>
            <div class="table mb-0">
              <b-table class="mb-0" ref="table" :sort-by.sync="table.sortBy" :sort-desc.sync="table.sortDesc" :busy="table.isBusy" :items="table.items" :fields="table.fields"
                :current-page="table.currentPage" :per-page="table.perPage" :filter="table.filter" @filtered="onFiltered" stacked="md"
                bordered hover show-empty small striped>
                <template #cell(index)="data">
                {{ (table.currentPage*table.perPage)-table.perPage + data.index + 1 }}
                </template>
                <template #cell(sts_aktif)="data">
                  <span v-if="data.sts_aktif = 1">Aktif</span>
                  <span v-else>Tidak Aktif</span>
                </template>
                <template #cell(action)="data">
                  <b-button
                    @click="editModal(data.item.id)"
                    variant=" mr-1 mb-1" size="lg">
                    <i class="fas fa-edit text-primary"></i>
                  </b-button>
                  <!-- <b-button
                    @click="deleteModal(data.item.id)"
                    variant=" mr-1 mb-1" size="lg">
                    <i class="fas fa-trash-alt text-danger"></i>
                  </b-button> -->
                </template>
                <template #cell(sysdate_in)="data">
                  <span>{{ new Date(data.item.sysdate_in) | formatDate }}</span>
                </template>
                <template #cell(sysdate_updt)="data">
                  <span>{{ new Date(data.item.sysdate_updt) | formatDate }}</span>
                </template>
              </b-table>
            </div>
            <div class="pagination-jenisAnggaran border shadow-sm p-2 mb-2">
              <b-row class="align-items-center">
                <b-col cols="4" class="d-flex flex-row align-items-center">
                  <div class="w-100">
                    <b-pagination
                      v-model="table.currentPage"
                      :total-rows="table.totalRows"
                      :per-page="table.perPage"
                      size="md"
                      class="my-0"
                    ></b-pagination>
                  </div>
                  <div class="w-100">
                    <div>
                      <span class="border-left border-dark mr-3 py-1"></span>
                      <span @click="tableRefresh" class="katalog-cursor">
                        <i class="fas fa-sync text-secondary"></i>
                      </span>
                      <span class="border-right border-dark ml-3 py-1"></span>
                    </div>
                  </div>
                </b-col>
                <b-col cols="5">
                  <div class="d-flex flex-row justify-content-end align-items-center">
                    <div class="mr-1 w-75">
                      <form class="searchbox mb-0">
                        <input v-model="table.filter" type="search" class="form-control cstm-srch-jenisAnggaran" id="filter-input"
                          placeholder="[Cari]">
                      </form>
                    </div>
                    <!-- <div>
                      <b-form-select class="cstm-slct-jenisAnggaran" v-model="selected.filter" :options="options.optionsFilter" @change="filterchange"></b-form-select>
                    </div> -->
                  </div>
                </b-col>
                <b-col cols="3">
                  <div class="d-flex flex-row align-items-center justify-content-end">
                    <div class="mr-3">
                      <b-form-select
                        class="cstm-select-jenisAnggaran"
                        id="per-page-select"
                        v-model="table.perPage"
                        :options="table.pageOptions"
                      ></b-form-select>
                    </div>
                    <div>
                      <p class="text-dark mb-0">Menampilkan {{ table.perPage }} dari {{ table.totalRows }} baris</p>
                    </div>
                  </div>
                </b-col>
              </b-row>
            </div>
          </template>
        </iq-card>
      </div>
    </b-container>
    <div id="modal-component-jenisAnggaran">
      <b-modal id="modal-tambah-jenisAnggaran" ref="modal-tambah-jenisAnggaran" size="lg" centered title="Tambah Jenis Anggaran" ok-title="Simpan Data" cancel-title="Tutup" :no-close-on-backdrop="true" :static="true" @close="clearData" @cancel="clearData" @ok="tambahSave">
        <b-form>
          <b-form-group class="mb-2">
            <b-row class="align-items-center">
              <b-col cols="2">
                <span>
                  <p class="small text-dark mb-0">Kode</p>
                </span>
              </b-col>
              <b-col>
                <b-form-input class="new-cstm-cntrl-katalog" v-model="tambahJenisAnggaran.kode" placeholder="Kode"></b-form-input>
              </b-col>
            </b-row>
          </b-form-group>
          <b-form-group class="mb-2">
            <b-row class="align-items-center">
              <b-col cols="2">
                <span>
                  <p class="small text-dark mb-0">Kode dc</p>
                </span>
              </b-col>
              <b-col>
                <b-form-input class="new-cstm-cntrl-katalog" v-model="tambahJenisAnggaran.kode_doc" placeholder="Kode dc"></b-form-input>
              </b-col>
            </b-row>
          </b-form-group>
          <b-form-group class="mb-2">
            <b-row class="align-items-center">
              <b-col cols="2">
                <span>
                  <p class="small text-dark mb-0">Jenis Anggaran</p>
                </span>
              </b-col>
              <b-col>
                <b-form-input class="new-cstm-cntrl-katalog" v-model="tambahJenisAnggaran.keterangan" placeholder="Jenis Anggaran"></b-form-input>
              </b-col>
            </b-row>
          </b-form-group>
          <b-form-group class="mb-2">
            <b-row class="align-items-center">
              <b-col cols="2">
                <span>
                  <p class="small text-dark mb-0">User In</p>
                </span>
              </b-col>
              <b-col>
                <b-form-input class="new-cstm-cntrl-katalog" v-model="preview.user_name" placeholder="Nama User" readonly></b-form-input>
              </b-col>
            </b-row>
          </b-form-group>
          <b-form-group class="mb-0">
            <b-row class="align-items-center">
              <b-col cols="2">
                <span>
                  <p class="small text-dark mb-0">Status Aktif</p>
                </span>
              </b-col>
              <b-col>
                <b-form-radio-group class="mb-0"
                  v-model="tambahJenisAnggaran.sts_aktif"
                  :options="options.optionsStsAktif"
                  ></b-form-radio-group>
              </b-col>
            </b-row>
          </b-form-group>
        </b-form>
        <div class="bg-dark px-2" v-if="preview.message">
          <p class="text-right small text-white mb-0">{{ preview.message }}</p>
        </div>
      </b-modal>
      <b-modal id="modal-edit-jenisAnggaran" ref="modal-edit-jenisAnggaran" size="lg" centered title="Edit Jenis Anggaran" ok-title="Perbarui Data" cancel-title="Tutup" :no-close-on-backdrop="true" :static="true" @close="clear" @cancel="clear" @ok="editSave">
        <b-form>
          <b-form-group class="mb-2">
            <b-row class="align-items-center">
              <b-col cols="2">
                <span>
                  <p class="small text-dark mb-0">Kode</p>
                </span>
              </b-col>
              <b-col>
                <b-form-input class="new-cstm-cntrl-katalog" v-model="editJenisAnggaran.kode" placeholder="Enter Kode"></b-form-input>
              </b-col>
            </b-row>
          </b-form-group>
          <b-form-group class="mb-2">
            <b-row class="align-items-center">
              <b-col cols="2">
                <span>
                  <p class="small text-dark mb-0">Kode doc</p>
                </span>
              </b-col>
              <b-col>
                <b-form-input class="new-cstm-cntrl-katalog" v-model="editJenisAnggaran.kode_doc" placeholder="Enter Kode"></b-form-input>
              </b-col>
            </b-row>
          </b-form-group>
          <b-form-group class="mb-2">
            <b-row class="align-items-center">
              <b-col cols="2">
                <span>
                  <p class="small text-dark mb-0">Jenis Anggaran</p>
                </span>
              </b-col>
              <b-col>
                <b-form-input class="new-cstm-cntrl-katalog" v-model="editJenisAnggaran.keterangan" placeholder="Jenis Anggaran"></b-form-input>
              </b-col>
            </b-row>
          </b-form-group>
          <!-- <b-form-group class="mb-2">
            <b-row class="align-items-center">
              <b-col cols="2">
                <span>
                  <p class="small text-dark mb-0">User In</p>
                </span>
              </b-col>
              <b-col>
                <b-form-input class="new-cstm-cntrl-katalog" v-model="preview.user_name_in" placeholder="User In" readonly></b-form-input>
              </b-col>
            </b-row>
          </b-form-group> -->
          <!-- <b-form-group class="mb-2">
            <b-row class="align-items-center">
              <b-col cols="2">
                <span>
                  <p class="small text-dark mb-0">Sys In</p>
                </span>
              </b-col>
              <b-col>
                <b-form-input class="new-cstm-cntrl-katalog" v-model="editJenisAnggaran.sysdate_in" placeholder="Sys In" readonly></b-form-input>
              </b-col>
            </b-row>
          </b-form-group> -->
          <b-form-group class="mb-2">
            <b-row class="align-items-center">
              <b-col cols="2">
                <span>
                  <p class="small text-dark mb-0">User In</p>
                </span>
              </b-col>
              <b-col>
                <b-form-input class="new-cstm-cntrl-katalog" v-model="editJenisAnggaran.desc_userid_in" placeholder="Nama User" readonly></b-form-input>
              </b-col>
            </b-row>
          </b-form-group>
          <b-form-group class="mb-0">
            <b-row class="align-items-center">
              <b-col cols="2">
                <span>
                  <p class="small text-dark mb-0">Status Aktif</p>
                </span>
              </b-col>
              <b-col>
                <b-form-radio-group class="mb-0"
                  v-model="editJenisAnggaran.sts_aktif"
                  :options="options.optionsStsAktif"
                  ></b-form-radio-group>
              </b-col>
            </b-row>
          </b-form-group>
        </b-form>
        <div class="bg-dark px-2 mt-2" v-if="preview.messageValue === '2'">
          <p class="text-right small text-white mb-0">{{ preview.message }}</p>
        </div>
      </b-modal>
      <b-modal ref="modal-delete-jenisAnggaran" size="md" centered title="Konfirmasi" ok-title="Hapus Data" ok-variant="danger" cancel-title="Tutup" @ok="deleteSave">
        <div class="py-3">
          <p class="h6 text-center text-dark mb-0">Yakin Ingin Menghapus Data ?</p>
        </div>
      </b-modal>
      <b-modal ref="modal-notif-jenisAnggaran" size="md" centered title="Notifikasi" ok-title="Tutup" :hide-footer="true" :hide-header="true">
        <div class="pb-3 px-4">
          <div v-if="preview.messageValue === '1'">
            <lottie :options="preview.lottie.defaultOptions" :autoPlay="true" :width="150" :height="150" />
          </div>
          <div v-else>
            <lottie :options="preview.lottie.defaultOptions2" :autoPlay="true" :width="150" :height="150" />
          </div>
          <p class="h6 text-dark text-center mb-0 mt-2">{{ preview.message }}</p>
        </div>
      </b-modal>
    </div>
  </div>
</template>
<script>
import { xray } from '../../../../config/pluginInit'
import Lottie from 'vue-lottie'
import jwtdecode from 'jwt-decode'
import Service from '../../../../services/fatmahost/katalog/index'
import animationLogo from '../../../../assets/lottie/checked.json'
import animationLogo2 from '../../../../assets/lottie/failed.json'

export default {
  name: 'MrtJenisAnggaran',
  components: {
    Lottie
  },
  data () {
    return {
      table: {
        isBusy: false,
        items: this.list,
        sortBy: '',
        sortDesc: true,
        fields: [
          { label: 'No', key: 'index', class: 'text-center' },
          { label: 'Kode', key: 'kode', sortable: true },
          { label: 'Jenis Anggaran', key: 'keterangan', sortable: true },
          { label: 'User In', key: 'desc_userid_in' },
          // { label: 'User Update', key: 'desc_userid_updt', sortable: true },
          { label: 'Last In', key: 'sysdate_in' },
          { label: 'Last Update', key: 'sysdate_updt' },
          { label: 'Aksi', key: 'action', class: 'text-center' }
        ],
        filter: '',
        currentPage: null,
        perPage: null,
        pageOptions: [10, 25, 50, 100],
        totalRows: null
      },
      tambahJenisAnggaran: {
        object: 'jenis-anggaran-rt',
        kode: '',
        keterangan: '',
        sts_aktif: '1',
        userid_in: jwtdecode(localStorage.getItem('token')).user.id.toString(),
        sysdate_in: '',
        userid_updt: '1',
        sysdate_updt: '',
        kode_doc: ''
      },
      editJenisAnggaran: {
        object: 'jenis-anggaran-rt',
        kode: '',
        keterangan: '',
        sts_aktif: '1',
        userid_in: jwtdecode(localStorage.getItem('token')).user.id.toString(),
        sysdate_in: '',
        userid_updt: '1',
        sysdate_updt: '',
        kode_doc: ''
      },
      options: {
        optionsStsAktif: [
          { text: 'Aktif', value: '1' },
          { text: 'Tidak Aktif', value: '0' }
        ],
        optionsFilter: [
          { text: 'Kode', value: '1' },
          { text: 'Jenis Anggaran', value: '2' }
        ]
      },
      selected: {
        filter: '1'
      },
      preview: {
        lottie: {
          defaultOptions: { animationData: animationLogo, loop: true },
          defaultOptions2: { animationData: animationLogo2, loop: true }
        },
        user_name: jwtdecode(localStorage.getItem('token')).user.name,
        edit_id: '',
        delete_id: '',
        message: '',
        messageValue: ''
      }
    }
  },
  mounted () {
    xray.index()
  },
  methods: {
    tableRefresh () {
      this.$refs.table.refresh()
    },
    clearData () {
      this.tambahJenisAnggaran.kode = ''
      this.tambahJenisAnggaran.kode_doc = ''
      this.tambahJenisAnggaran.keterangan = ''
      this.tambahJenisAnggaran.sts_aktif = ''
      this.preview.message = ''
    },
    async list (ctx) {
      let sortfield = ''
      let sorting = ''
      if (ctx.filter === '') {
        ctx.filter = null
      }
      if (ctx.sortBy) {
        sortfield = ctx.sortBy
      } else {
        sortfield = ''
      }
      if (ctx.sortDesc === true) {
        sorting = 'DESC'
      } else if (ctx.sortDesc === false) {
        sorting = 'ASC'
      } else {
        sorting = ''
      }
      try {
        this.isBusy = true
        let filter = `{"xparam0":"${this.table.filter}","xparam1":"","xparam2":"","xparam3":"","xparam4":"","xparam5":"","xparam6":"","xparam7":"","xparam8":"","xparam9":""}`
        let params5 = `{"xparam0":"${sortfield}","xparam1":"${sorting}"}`
        const response = await Service.listMrtjenisAnggaran(ctx.currentPage - 1, ctx.perPage, filter, params5)
        this.isBusy = false
        this.table.totalRows = response.data.pagination.total
        this.table.perPage = response.data.pagination.perPage
        this.table.currentPage = response.data.pagination.current
        return response.data.data
      } catch (error) {
        this.isBusy = false
        return []
      }
    },
    onFiltered (filteredItems) {
      this.table.totalRows = filteredItems.length
      this.table.currentPage = 1
    },
    filterchange () {
      this.table.filter = ''
    },
    tambahModal () {
      this.clearMessage()
      this.$refs['modal-tambah-jenisAnggaran'].show()
    },
    async tambahSave (bvModalEvent) {
      bvModalEvent.preventDefault()
      try {
        if (this.tambahJenisAnggaran.kode !== '' && this.tambahJenisAnggaran.kode_doc !== '' && this.tambahJenisAnggaran.keterangan !== '' && this.tambahJenisAnggaran.sts_aktif !== '') {
          let data = this.tambahJenisAnggaran
          let response = await Service.createMrtjenisAnggaran(data)
          if (response.status === 200) {
            this.preview.message = response.data.message
            this.preview.messageValue = '1'
            this.$refs['modal-tambah-jenisAnggaran'].hide()
            this.$refs['modal-notif-jenisAnggaran'].show()
          } else {
            this.preview.message = response.data.message
            this.preview.messageValue = '2'
          }
          this.$refs.table.refresh()
        } else {
          this.preview.message = 'Data tidak boleh kosong'
          this.preview.messageValue = '2'
        }
        this.$refs.table.refresh()
      } catch (error) {
        this.preview.message = error.message
        this.preview.messageValue = '2'
      }
    },
    async editModal (id) {
      this.clearMessage()
      let ids = id
      this.preview.edit_id = ids
      try {
        const response = await Service.showMrtjenisAnggaran(ids)
        this.editJenisAnggaran.kode = response.data.data[0].kode
        this.editJenisAnggaran.kode_doc = response.data.data[0].kode_doc
        this.editJenisAnggaran.keterangan = response.data.data[0].keterangan
        this.editJenisAnggaran.desc_userid_in = response.data.data[0].desc_userid_in
        this.editJenisAnggaran.sts_aktif = response.data.data[0].sts_aktif
      } catch (error) {
        this.preview.message = error.message
        this.preview.messageValue = '2'
        // this.$refs['modal-notif-jenisAnggaran'].show()
      }
      this.$refs['modal-edit-jenisAnggaran'].show()
    },
    async editSave (bvModalEvent) {
      bvModalEvent.preventDefault()
      try {
        let id = this.preview.edit_id
        let data = this.editJenisAnggaran
        const response = await Service.updateMrtjenisAnggaran(id, data)
        if (response.data.statcode === 0) {
          this.preview.message = response.data.message
          this.preview.messageValue = '1'
          this.$refs['modal-edit-jenisAnggaran'].hide()
          this.$refs['modal-notif-jenisAnggaran'].show()
          this.$refs.table.refresh()
          this.clear()
        } else {
          this.preview.message = response.data.message
          this.preview.messageValue = '2'
        }
      } catch (error) {
        this.preview.message = error.message
        this.preview.messageValue = '2'
      }
    },
    deleteModal (id) {
      this.preview.delete_id = id
      this.$refs['modal-delete-jenisAnggaran'].show()
    },
    async deleteSave () {
      let id = this.preview.delete_id
      try {
        const response = await Service.deleteJenisAnggaran(id)
        if (response.data.statcode === 0) {
          this.preview.message = response.data.message
          this.preview.messageValue = '1'
        } else {
          this.preview.message = response.data.message
          this.preview.messageValue = '2'
        }
        this.$refs['modal-notif-jenisAnggaran'].show()
        this.$refs.table.refresh()
      } catch (error) {
        this.preview.message = error.message
        this.preview.messageValue = '2'
        this.$refs['modal-notif-jenisAnggaran'].show()
      }
    },
    clear () {
      this.tambahJenisAnggaran.kode = ''
      this.tambahJenisAnggaran.jenisAnggaran = ''
      this.tambahJenisAnggaran.sts_aktif = ''
    },
    clearMessage () {
      this.preview.messageValue = ''
      this.preview.message = ''
    }
  }
}
</script>
<style>
#modal-tambah-jenisAnggaran .custom-control-label, #modal-edit-jenisAnggaran .custom-control-label {
    font-size: 12px;
    margin-top: 0;
}
#modal-tambah-jenisAnggaran .multiselect__single, #modal-edit-jenisAnggaran .multiselect__single {
  font-size: 12px!important;
}
#modal-tambah-jenisAnggaran .multiselect__placeholder, #modal-edit-jenisAnggaran .multiselect__placeholder {
  font-size: 12px!important;
}
#modal-tambah-jenisAnggaran .multiselect__option, #modal-edit-jenisAnggaran .multiselect__option {
    font-size: 12px;
}
#modal-tambah-jenisAnggaran .multiselect__tags, #modal-edit-jenisAnggaran .multiselect__tags {
  padding: 5px 40px 0 8px!important;
  height: 38px!important;
  min-height: 38px!important;
}
#modal-tambah-jenisAnggaran  .modal-body, #modal-edit-jenisAnggaran .modal-body {
  background: #3399331b
}

#modal-tambah-jenisAnggaran  .modal-header, #modal-edit-jenisAnggaran .modal-header {
  background: #3399333a;
}

#modal-tambah-jenisAnggaran  .modal-footer, #modal-edit-jenisAnggaran .modal-footer {
  background: #3399333a;
}
#katalog-jenisAnggaran .page-link {
  font-size: 14px;
  padding-top: 5px;
  padding-bottom: 5px;
  margin-right: 10px!important;
}
#katalog-jenisAnggaran .custom-button-radius {
  border: 1px solid rgba(0, 0, 0, 0.112);
  border-radius: 5px!important;
}
#katalog-jenisAnggaran .table td {
    font-size: 12px;
    font-weight: 500;
    padding-top: 0px;
    padding-bottom: 0px;
}
#katalog-jenisAnggaran .table th {
    font-size: 13px;
    font-weight: 400;
}
#katalog-jenisAnggaran .cstm-select-jenisAnggaran {
  font-size: 13px;
  height: 38px!important;
  border-radius: 5px;
}
#katalog-jenisAnggaran .cstm-srch-jenisAnggaran {
  font-size: 13px;
  height: 38px!important;
  border-radius: 5px;
}
#katalog-jenisAnggaran .cstm-slct-jenisAnggaran {
  font-size: 13px;
  height: 38px!important;
  border-radius: 5px;
}
.new-cstm-cntrl-katalog {
  background: white!important;
  color: black!important;
  border-radius: 5px!important;
  font-size: 12px!important;
  height: 38px!important;
}
.katalog-form-area {
  color: black!important;
  border-radius: 5px!important;
  font-size: 12px!important;
}
#katalog-jenisAnggaran .pagination-sm .page-link {
  color: gray!important;
}
#katalog-jenisAnggaran .page-item.active .page-link {
  color: white!important;
}
#katalog-jenisAnggaran .page-item.active .page-link {
  color: white!important;
}
.custom-radius-add-katalog {
  border-radius: 5px;
  cursor: pointer;
}
.katalog-select-flex-left {
  width: 90%;
}
.katalog-select-flex-right {
  width: 10%;
}
.katalog-form-satuan-jenisAnggaran-left-right {
  width: 40%;
}
.katalog-form-satuan-jenisAnggaran-center {
  width: 15%;
}
.hr-katalog-custom {
  border-top: 1px solid rgba(0, 0, 0, 0.199)!important;
  margin: 0!important;
}
#katalog-jenisAnggaran .pagination-jenisAnggaran .page-link {
    border: none;
    color: gray!important;
    margin-right: 15px;
}
#katalog-jenisAnggaran .pagination-jenisAnggaran .page-item.active .page-link {
    background-color: transparent;
    border-color: transparent;
    color: #089bab!important;
    font-weight: bold;
}
</style>
