<template>
  <div id="penjualan-retur" class="penjualan-retur">
    <div v-show="isLoading">
        <div id="show-overlay"></div>
      <Loader :style="{ opacity: .5 }" />
    </div>
    <b-container fluid class="mb-4">
      <iq-card class="mb-2">
        <div  class="iq-email-to-list pt-3 pl-3 pr-3" id="header-table-filter">
          <div class="px-2 py-2 border d-flex align-items-center justify-content-between">
            <div>
              <p class="text-dark font-weight-bold h6 mb-0">PENJUALAN</p>
            </div>
            <div>
              <b-button variant="primary" class="py-1" style="border-radius: 5px;"  @click="tabahRetur"><i class="fas fa-plus-circle "></i>Tambah Retur</b-button>
            </div>
          </div>
        </div>
          <div class="iq-email-listbox pl-3 pr-3 pb-3">
            <div class="table mb-0">
              <b-table
                class="mb-0 pointer-table"
                ref="table"
                :busy="table.isBusy"
                :items="table.items"
                :fields="table.fields"
                :current-page="table.currentPage"
                :per-page="table.perPage"
                :filter="table.filter"
                @filtered="onFiltered"
                :sort-by.sync="table.sortBy"
                :sort-desc.sync="table.sortDesc"
                stacked="md"
                bordered hover show-empty small striped>
                <template #table-busy>
                  <div class="text-center my-2">
                    <b-spinner class="align-middle text-primary mr-2"></b-spinner>
                    <strong class="text-dark">Memuat Data...</strong>
                  </div>
                </template>
                <template #cell(to2.tanggal)="data">
                  <span>{{ new Date(tglWithDots(data.item)) | formatDate }}</span>
                </template>
                <template #cell(to2.inp_tanggal)="data">
                  <span>{{ new Date(inptglWithDots(data.item)) | formatDate }}</span>
                </template>
                <template #cell(index)="data">
                  {{ (table.currentPage*table.perPage)-table.perPage + data.index + 1 }}
                </template>
                <template #cell(ver_status)="data">
                  <span class="text-secondary" v-if="data.item.ver_status === 1 || data.item.ver_status === '1'"><i class="fas fa-check-circle text-success fa-lg"></i></span>
                  <span v-else-if="data.item.ver_status === 0 || data.item.ver_status === '0'"><i class="fas fa-spinner text-secondary fa-lg"></i></span>
                </template>
                <template #cell(to2.close_status)="data">
                  <span v-if="closeDots(data.item) === 1">Ya</span>
                  <span v-else>Tidak</span>
                </template>
                <template #cell(action)="data">
                  <!-- <b-button :disabled="bitcontrol1_access.show === '1' ? false : true"
                  @click="toSOSee(data.item.id)"
                    variant=" mr-1 mb-1"
                    size="lg">
                    <i :class="['fas fa-eye', bitcontrol1_access.show === '1' ? 'text-warning' : 'text-secondary']"></i>
                  </b-button> -->
                  <b-button
                    @click="toSOView(data.item.id)"
                    variant=" mr-1 mb-1"
                    size="lg">
                    <i class="fas fa-sign-out-alt text-primary"></i>
                  </b-button>
                  <!-- <b-button :disabled="closeDots(data.item) === 0 && bitcontrol1_access.add === '1' ? false : true"
                    @click="closeModal(data.item.id)"
                    variant=" mr-1 mb-1"
                    size="lg">
                    <i :class="['fas', closeDots(data.item) === 0 && bitcontrol1_access.add === '1' ? 'text-dark fa-door-open' : 'text-secondary fa-door-closed']"></i>
                  </b-button> -->
                </template>
              </b-table>
            </div>
            <div class="pagination-inventory border shadow-sm p-2 mb-2">
              <b-row class="align-items-center">
                <b-col cols="4" class="d-flex flex-row align-items-center">
                  <div class="w-100">
                    <b-pagination v-model="table.currentPage" :total-rows="table.totalRows" :per-page="table.perPage"
                      size="md" class="my-0"></b-pagination>
                  </div>
                  <div class="w-100">
                    <div>
                      <span class="border-left border-dark mr-3 py-1"></span>
                      <span @click="tableRefresh" class="katalog-cursor">
                        <i class="fas fa-sync text-secondary"></i>
                      </span>
                      <span class="border-right border-dark ml-3 py-1"></span>
                    </div>
                  </div>
                </b-col>
                <b-col cols="5">
                  <div class="d-flex flex-row justify-content-end align-items-center">
                    <div class="mr-1 w-75">
                      <form class="searchbox mb-0">
                        <input v-model="table.filter" type="search" class="form-control cstm-srch-stock" id="filter-input"
                          placeholder="[Cari]">
                      </form>
                    </div>
                    <div>
                      <b-form-select class="cstm-slct-stock" v-model="selected.filterType" :options="options.optionsFilter"
                        @change="filterchange"></b-form-select>
                    </div>
                  </div>
                </b-col>
                <b-col cols="3">
                  <div class="d-flex flex-row align-items-center justify-content-end">
                    <div class="mr-3">
                      <b-form-select
                        class="cstm-select-so-dash"
                        id="per-page-select"
                        v-model="table.perPage"
                        :options="table.pageOptions"
                      ></b-form-select>
                    </div>
                    <div>
                      <p class="text-dark mb-0">Menampilkan {{ table.perPage }} dari {{ table.totalRows }} baris</p>
                    </div>
                  </div>
                </b-col>
              </b-row>
            </div>
          </div>
      </iq-card>
    </b-container>
    <b-modal id="modal-retur-add" ref="modal-retur-add" size="md" title="Tambah Retur" cancel-title="Tutup" :no-close-on-backdrop="true" :static="true" :no-close-on-esc="true"
    cancel-variant="danger" @ok="handleSubmit" @close="clearForm" @cancel="clearForm" ok-title="Simpan" :hide-header=false centered>
      <div class="mb-1">
        <b-row>
          <b-col>
            <b-row>
              <b-col cols="12" lg="3">
                <p class="text-dark mb-0">Ruangan</p>
              </b-col>
              <b-col cols="12" lg="9">
                <b-form-group class="mb-2">
                  <multiselect  v-model="selected.ruangan" :show-labels="false" :allow-empty="false" :disabled="stockopname.header.ver_status === '1' ? true : false"
                    @input="valRuang($event, 't')" label="deskripsi" placeholder="Ruangan" :options="combo.ruangan"
                    :searchable="true">
                  </multiselect>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="12" lg="3">
                <p class="text-dark  mb-0">Tanggal</p>
              </b-col>
              <b-col cols="12" lg="9">
                <b-form-group class="mb-2">
                  <flat-pickr :disabled="stockopname.header.ver_status === '1' ? true : false" :config="configTgl" @on-change="valueTgl" v-model="display.tanggal" value="" placeholder="Tanggal" class="w-100 custom-flatpicker calender-small" />
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="12" lg="3">
                <p class="text-dark  mb-0">Keterangan</p>
              </b-col>
              <b-col cols="12" lg="9">
                <b-form-group class="mb-0">
                  <b-form-input :disabled="stockopname.header.ver_status === '1' ? true : false" class="cstm-cntrl-stockopname" v-model="stockopname.header.keterangan" type="text" placeholder="Keterangan"></b-form-input>
                </b-form-group>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
        <!-- <pre>{{ stockopname }}</pre> -->
        <hr class="mx-0 my-2">
        <div class="bg-dark px-2 mt-2" v-if="error">
          <p class="text-right small text-white mb-0">{{ error }}</p>
        </div>
        <!-- <div>
          <b-row>
            <b-col cols="12">
              <div class="table-penjualan-retur table-responsive-xl">
                <table class="table bg-white table-bordered table-sm m-0 p-0">
                  <thead>
                    <tr>
                      <th scope="col" style="vertical-align: center;" class="text-center">
                        <p class="mb-0 font-weight-normal text-center">Nama Barang</p>
                      </th>
                      <th scope="col" style="vertical-align: center;" class="text-center">
                        <p class="mb-0 font-weight-normal text-center">Pabrik</p>
                      </th>
                      <th scope="col" style="vertical-align: center;" class="text-center">
                        <p class="mb-0 font-weight-normal text-center">Jumlah Adm</p>
                      </th>
                      <th scope="col" style="vertical-align: center;" class="text-center">
                        <p class="mb-0 font-weight-normal text-center">Jumlah Fisik</p>
                      </th>
                      <th scope="col" style="vertical-align: center;" class="text-center">
                        <p class="mb-0 font-weight-normal text-center">Tanggal Expired</p>
                      </th>
                      <th scope="col" style="vertical-align: center;" class="text-center">
                        <b-button :disabled="stockopname.header.ver_status === '1' ? true : false" :variant="stockopname.header.ver_status === '1' ? 'secondary' : 'primary'" @click="addRow">
                          <p class="h5 mb-0">+</p>
                        </b-button>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(k, index) in stockopname.detail" v-bind:key="k.codex">
                      <td class="col-4 py-2 px-2">
                        <div class="w-100">
                          <multiselect v-model="selected.pabrik[index].detail" :show-labels="false" :allow-empty="false" :disabled="stockopname.header.ver_status === '1' ? true : false"
                            label="namaBarang" track-by="id_pabrik" placeholder="[Barang]" :options="combo.pabrik" :custom-label="nameWithKode" @input="valuepabrik($event, index, '2')" @search-change="getPabrik"
                            :searchable="true">
                          </multiselect>
                        </div>
                      </td>
                      <td class="col-3">
                        <div class="w-100 disable-bg border border-dark alert-radius-stockopname px-2">
                          <p class="mb-0" v-if="display.namabarang[index].nama">{{ display.namabarang[index].nama }}</p>
                          <p class="mb-0" v-else>...</p>
                        </div>
                      </td>
                      <td>
                        <div class="w-100">
                          <b-form-input disabled class="cstm-cntrl-stockopname text-center" maxlength="3" v-model="stockopname.detail[index].jumlah_adm" @keypress="isNumberKey" type="text" placeholder="[Jumlah Adm]"></b-form-input>
                        </div>
                      </td>
                      <td>
                        <div class="w-100">
                          <b-form-input :disabled="stockopname.header.ver_status === '1' ? true : false" class="cstm-cntrl-stockopname text-center" v-model="stockopname.detail[index].jumlah_fisik" @keypress="isNumberKey" type="text" placeholder="[ Jumlah Fisik ]"></b-form-input>
                        </div>
                      </td>
                      <td>
                        <div class="d-flex flex-row w-100">
                          <div class="mr-1">
                            <b-form-input :disabled="stockopname.header.ver_status === '1' ? true : false" class="cstm-cntrl-stockopname" maxlength="4" v-model="display.tglInp[index].value" @blur="generatetgl(index, '1')" @keyup.enter="generatetgl(index, '2')" @keypress="isNumberKey" type="text" placeholder="[ Inp Tanggal ]"></b-form-input>
                          </div>
                          <div >
                            <flat-pickr disabled :config="configTgl" @on-change="valueTglDetail($event, index)" value="" v-model="display.tglExp[index].date" placeholder="[ Tanggal ]" class="w-100 custom-flatpicker calender-small" />
                          </div>
                        </div>
                      </td>
                      <td class="col-1">
                        <div class="text-center w-100">
                          <b-button variant="white" @click="deleteRow(index, 't')" v-if=" index !== 0">
                            <p class="mb-0"><i :class="['fas fa-trash-alt', stockopname.header.ver_status === '1' ? 'text-secondary' : 'text-danger']"></i></p>
                          </b-button>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </b-col>
          </b-row>
          <hr class="my-0 mx-0">
          <div class="bg-dark px-2 mt-2" v-if="error">
            <p class="text-right small text-white mb-0">{{ error }}</p>
          </div>
        </div> -->
      </div>
    </b-modal>
    <b-modal ref="modal-sukses" size="md" title="Informasi" ok-title="Oke" :ok-only="true" :hide-header="true" centered>
      <div class="text-center px-lg-4 px-2">
        <lottie :options="defaultOptions" :autoPlay="true" :width="250" :height="250" />
        <p class="h6 text-success font-weight-bold mb-0">{{ message }}</p>
      </div>
    </b-modal>
    <b-modal ref="modal-gagal" size="md" title="Informasi" ok-title="Kembali" :ok-only="true" :hide-header="true" centered>
      <div class="text-center px-lg-4 px-2">
        <lottie :options="defaultOptions2" :autoPlay="true" :width="250" :height="250" />
        <p class="h6 text-danger font-weight-bold mb-2">Data Gagal Disimpan</p>
        <p class="small text-danger font-weight-light mb-0">{{error}}</p>
      </div>
    </b-modal>
    <b-modal ref="modal-konfirm-delete-hard" size="md" title="KONFIRMASI CLOSE STATUS" ok-variant="danger" ok-title="Close" @ok="handleClose" :no-close-on-backdrop="true" :static="true"
    cancel-title="Batal" centered>
      <div class="px-1 py-2">
        <div class="text-center ">
          <i class="fas fa-exclamation-circle text-danger fa-5x"></i>
          <p class="h6 text-danger mb-0 mt-3">Status Akan Di Close ?</p>
        </div>
        <div class="bg-dark px-2 mt-2" v-if="error">
          <p class="small text-white mb-0">{{ error }}</p>
        </div>
      </div>
    </b-modal>
    <ModalMsgbox ref="MsgBox" />
  </div>
</template>
<script>
import { xray } from '../../config/pluginInit'
// import jwtdecode from 'jwt-decode'
import Loader from '../../components/xray/loader/Loader'
import Lottie from 'vue-lottie'
import animationLogo from '../../assets/lottie/checked.json'
import animationLogo2 from '../../assets/lottie/failed.json'
import Service from '../../services/fatmahost/index'
import Multiselect from 'vue-multiselect'
import flatPickr from 'vue-flatpickr-component'
import { Indonesian as IndonesianLocale } from 'flatpickr/dist/l10n/id.js'
import 'flatpickr/dist/flatpickr.css'
import ModalMsgbox from '../../components/modal/ModalMsgBox'
// import { mapGetters } from 'vuex'
export default {
  name: 'StockOpname',
  components: {
    ModalMsgbox,
    Multiselect,
    Lottie,
    Loader,
    flatPickr
  },
  data () {
    return {
      table: {
        isBusy: false,
        items: [],
        sortBy: '',
        sortDesc: false,
        fields: [
          { label: 'No', key: 'index', class: 'text-center' },
          // { label: 'Ruangan', key: 'ruangan_id', sortable: true },
          { label: 'Ruangan', key: 'mr.deskripsi', sortable: true },
          { label: 'ID Berkas', key: 'to2.berkas_id', sortable: true },
          { label: 'Keterangan', key: 'to2.keterangan', sortable: true },
          { label: 'Tanggal', key: 'to2.tanggal', sortable: true },
          { label: 'Input Tanggal', key: 'to2.inp_tanggal', sortable: true },
          { label: 'User Create', key: 'up.name', sortable: true },
          { label: 'Close Status', key: 'to2.close_status', sortable: true },
          // { label: 'User Verifikasi', key: 'up2.name', sortable: true },
          // { label: 'Status verifikasi', key: 'ver_status', sortable: true, class: 'text-center' },
          { label: 'Aksi', key: 'action', class: 'text-center' }
        ],
        filter: null,
        currentPage: null,
        perPage: null,
        pageOptions: [5, 10, 25, 50, 100, 500],
        totalRows: null
      },
      // table2: {
      //   items: this.listStockEditSO,
      //   fields: [
      //     { label: 'Nama Barang', key: 'nama_barang', class: 'py-2' },
      //     { label: 'Pabrik', key: 'nama_pabrik' },
      //     { label: 'Jumlah Adm', key: 'jumlah_adm' },
      //     { label: 'Jumlah Fisik', key: 'jumlah_fisik' },
      //     { label: 'Tanggal Expired', key: 'tgl_expired' },
      //     { label: 'Aksi', key: 'action', class: 'text-center' }
      //   ],
      //   filter: null,
      //   currentPage: null,
      //   perPage: 10,
      //   totalRows: null
      // },
      combo: {
        instalasi: [],
        unit: [],
        ruangan: [],
        jenis: [
          { value: 1, text: 'Reservasi Online' },
          { value: 2, text: 'Reservasi Offline' }
        ],
        filterType: [
          { value: '1', text: 'Filter Dokter' },
          { value: '2', text: 'Filter Ruangan' }
        ],
        filterRuang: [
          { value: '', text: 'Rawat Jalan' }
        ],
        pabrik: [],
        status: [
          { value: '1', text: 'Aktif' },
          { value: '0', text: 'tidak Aktif' }
        ]
      },
      options: {
        optionsFilter: [
          { text: 'Ruangan', value: '1' },
          { text: 'ID Berkas', value: '2' }
        ]
      },
      selected: {
        instalasi: [],
        unit: [],
        ruangan: [],
        kode: [{
          value: ''
        }],
        pabrik: [{
          detail: []
        }],
        filterType: '1'
      },
      id: null,
      ruangan: '',
      stockopname: {
        object: 'stok-opname',
        header: {
          ruangan_id: '',
          berkas_id: '1',
          tanggal: '',
          keterangan: '',
          sts_batal: '0',
          inp_tanggal: this.getDateAuto(),
          inp_user: xray.currentUser().id.toString().toString(),
          ver_tanggal: '',
          ver_user: '',
          ver_status: '',
          close_status: '0'
        },
        detail: [
          {
            id: '0',
            id_katalog: '',
            id_pabrik: '',
            jumlah_adm: '0',
            jumlah_fisik: '',
            tgl_expired: '',
            sts_batal: '0',
            userid_in: xray.currentUser().id.toString().toString()
          }
        ]
      },
      bitcontrol1_access: {
        show: '0',
        add: '0',
        edit: '0',
        delete: '0'
      },
      bitcontrol2_group: {
        read: '0',
        entry: '0',
        verif: '0',
        admin: '0'
      },
      display: {
        displayruangan: '',
        stockopnamekeluaron: '',
        stockopnamekeluaroff: '',
        viewTgltmbh: '',
        viewTglEdit: '',
        viewTglAwal: '',
        viewTglAkhir: '',
        kodeName: [
          {
            kode: '',
            nama: ''
          }
        ],
        tglInp: [
          {
            value: ''
          }
        ],
        tglExp: [
          {
            date: ''
          }
        ],
        namabarang: [
          {
            nama: ''
          }
        ],
        kodedetail: [
          {
            kode: ''
          }
        ],
        tanggal: ''
      },
      filtering: {
        valTglAwal: '',
        valTglAkhir: ''
      },
      status: 'not_accepted',
      statusEdit: 'not_accepted',
      valuedisable: false,
      valuedisable2: false,
      error: '',
      message: '',
      messageTgl: '',
      isLoading: false,
      defaultOptions: { animationData: animationLogo, loop: true },
      defaultOptions2: { animationData: animationLogo2, loop: true },
      configTgl: {
        locale: IndonesianLocale,
        disableMobile: true,
        dateFormat: 'd-m-Y'
      }
    }
  },
  mounted () {
    xray.index()
    this.getruangan()
    this.getbitcontrol()
  },
  methods: {
    toSOSee (id, rgnId, berkasId, tanggal, keterangan) {
      this.$router.push({
        name: 'farmasi.stockopnamesee', params: { id }, query: { rgnId, berkasId, tanggal, keterangan }
      })
    },
    toSOView (id, rgnId, berkasId, tanggal, keterangan) {
      this.$router.push({
        name: 'farmasi.stockopnameview', params: { id }, query: { rgnId, berkasId, tanggal, keterangan }
      })
    },
    getbitcontrol () {
      // let access = {
      //   bitcontrol1: '0011110000000000',
      //   bitcontrol2: '3000000000000000'
      // }
      let access = xray.bitcontrol(this.$route.name)
      // bit control 1
      if (access.bitcontrol1.charAt(2) === '1') {
        this.bitcontrol1_access.add = '1'
      }
      if (access.bitcontrol1.charAt(3) === '1') {
        this.bitcontrol1_access.show = '1'
      }
      if (access.bitcontrol1.charAt(4) === '1') {
        this.bitcontrol1_access.edit = '1'
      }
      // bit control 2
      if (access.bitcontrol2.charAt(0) === '0') {
        this.bitcontrol2_group.read = '1'
      } else if (access.bitcontrol2.charAt(0) === '1') {
        this.bitcontrol2_group.entry = '1'
      } else if (access.bitcontrol2.charAt(0) === '2') {
        this.bitcontrol2_group.verif = '1'
      } else if (access.bitcontrol2.charAt(0) === '3') {
        this.bitcontrol2_group.admin = '1'
      }
    },
    nameWithKode ({ kode, namaBarang }) {
      return `${kode} - ${namaBarang}`
    },
    filterchange () {
      this.table.filter = ''
    },
    async list (ctx) {
      let sortfield = ''
      let sorting = ''
      let filterType = this.selected.filterType ? this.selected.filterType : ''
      let userid = xray.currentUser().id.toString().toString()
      if (ctx.filter === '') {
        ctx.filter = null
      }
      if (ctx.sortBy) {
        sortfield = ctx.sortBy
      } else {
        sortfield = ''
      }
      if (ctx.sortDesc === true) {
        sorting = 'ASC'
      } else if (ctx.sortDesc === false) {
        sorting = 'DESC'
      } else {
        sorting = ''
      }
      this.table.isBusy = true
      try {
        const response = await Service.katalog.listStokOpname(ctx.currentPage - 1, ctx.perPage, ctx.filter, filterType, sortfield, sorting, userid)
        this.table.isBusy = false
        this.table.totalRows = response.data.pagination.total
        this.table.perPage = response.data.pagination.perPage
        this.table.currentPage = response.data.pagination.current
        return response.data.data
      } catch (error) {
        this.table.isBusy = false
        return []
      }
    },
    generatetgl (index, param) {
      var a = this.display.tglInp[index].value
      var b = a.replace(/(..)(..)/, '$1-$2')
      var c = b.replace(/-/g, '-20')
      var d = '01' + '-' + c
      var e = d.split('-')
      var e1 = e[0]
      var e2 = e[1]
      var e3 = e[2]
      var f = new Date(e2 + '-' + e1 + '-' + e3)
      f.setDate(1)
      f.setMonth(f.getMonth() + 1)
      f.setDate(f.getDate() - 1)
      var lastDayOfMonth = f.getDate()
      var match = lastDayOfMonth + '-' + e2 + '-' + e3
      this.display.tglExp[index].date = match
      //
      if (param === '2') {
        this.addRow()
      }
    },
    generatetglResponse (value) {
      var a = value.replace(/^.{2}/, '')
      var b = a.split('-').reverse().join('-')
      var c = b.replace(/-/g, '')
      var d = c.replace(/^.{2}/, '')
      return d
    },
    getDateAuto (value) {
      const date = value === undefined ? new Date() : new Date(value)
      let day = date.getDate()
      let month = (date.getMonth() + 1).toString().padStart(2, 0)
      let year = date.getFullYear()
      if (day < 10) {
        day = '0' + day
      }
      if (value === undefined) {
        return year + '-' + month + '-' + day
      } else {
        return day + '-' + month + '-' + year
      }
    },
    valueTgl (value) {
      const date = new Date(value)
      let day = date.getDate()
      let month = (date.getMonth() + 1).toString().padStart(2, 0)
      let year = date.getFullYear()
      if (day < 10) {
        day = '0' + day
      }
      let currentDate = year + '-' + month + '-' + day
      this.stockopname.header.tanggal = currentDate
    },
    valueTglDetail ($event, index) {
      const date = new Date($event)
      let day = date.getDate()
      let month = (date.getMonth() + 1).toString().padStart(2, 0)
      let year = date.getFullYear()
      if (day < 10) {
        day = '0' + day
      }
      let currentDate = year + '-' + month + '-' + day
      this.stockopname.detail[index].tgl_expired = currentDate
      // this.display.tglInp[index].value = month.toString() + (year.toString()).replace(/^.{2}/, '')
    },
    validasi (param) {
      if (param === '1') {
        this.stockopname.header.ver_user = xray.currentUser().id.toString()
        this.stockopname.header.ver_status = '1'
        this.stockopname.header.ver_tanggal = this.getDateAuto()
      } else if (param === '0') {
        this.stockopname.header.ver_user = ''
        this.stockopname.header.ver_status = ''
        this.stockopname.header.ver_tanggal = ''
      }
    },
    valuepabrik ($event, index, param) {
      this.stockopname.detail[index].id_katalog = $event.id_katalog.toString()
      this.stockopname.detail[index].id_pabrik = $event.id_pabrik.toString()
      this.display.namabarang[index].nama = $event.nama_pabrik
      this.display.kodedetail[index].kode = $event.kode
      if (param === '1') {
        this.selected.pabrik[index].detail = { id: $event.id_pabrik, namaBarang: $event.namaBarang }
      } else if (param === '2') {
        this.selected.kode[index].value = { id: $event.id_pabrik, kode: $event.kode }
      }
    },
    tabahRetur () {
      this.$refs['modal-retur-add'].show()
    },
    async handleSubmit (bvModalEvent) {
      bvModalEvent.preventDefault()
      this.isLoading = true
      try {
        const data = this.stockopname
        const response = await Service.katalog.createStockOpname(data)
        if (response.data.statcode === 0) {
          this.isLoading = false
          this.message = response.data.message
          this.$refs['modal-retur-add'].hide()
          this.$refs.MsgBox.show({
            type: 'success',
            msg: response.data.message
          })
          this.clearForm()
          this.$refs.table.refresh()
        } else {
          this.isLoading = false
          this.error = response.data.message
        }
      } catch (error) {
        this.isLoading = false
        this.error = error.message
      }
    },
    // async handleShowDtl (value) {
    //   this.id = value.toString()
    //   try {
    //     const response = await Service.katalog.showStockOpname(value.toString())
    //     if (response.data.statcode === 0) {
    //       this.stockopname.header.ruangan_id = response.data.data[0].ruangan_id !== null ? response.data.data[0].ruangan_id.toString() : ''
    //       this.stockopname.header.berkas_id = response.data.data[0].berkas_id !== null ? response.data.data[0].berkas_id.toString() : ''
    //       this.stockopname.header.keterangan = response.data.data[0].keterangan !== null ? response.data.data[0].keterangan : ''
    //       this.stockopname.header.ver_status = response.data.data[0].ver_status !== null ? response.data.data[0].ver_status.toString() : ''
    //       this.stockopname.header.ver_user = response.data.data[0].ver_user !== null ? response.data.data[0].ver_user.toString() : ''
    //       this.stockopname.header.close_status = response.data.data[0].close_status !== null ? response.data.data[0].close_status.toString() : ''
    //       this.stockopname.header.sts_batal = response.data.data[0].sts_batal !== null ? response.data.data[0].sts_batal.toString() : ''
    //       if (response.data.data[0].tanggal !== null) {
    //         this.display.tanggal = this.getDateAuto(response.data.data[0].tanggal)
    //       }
    //       this.selected.ruangan = { id: response.data.data[0].ruangan_id, deskripsi: response.data.data[0].deskripsi }
    //       this.listStockEditSO()
    //     } else {
    //       this.error = response.data.message
    //       this.$refs.table.refresh()
    //     }
    //   } catch (error) {
    //     this.error = error.message
    //     this.$refs.table.refresh()
    //   }
    //   this.$refs.table.refresh()
    //   this.$refs['modal-stokopname-edit'].show()
    // },
    // async handleUpdate (bvModalEvent) {
    //   bvModalEvent.preventDefault()
    //   try {
    //     const id = this.id
    //     const data = this.stockopname
    //     const response = await Service.katalog.updateStockOpname(id, data)
    //     if (response.data.statcode === 0) {
    //       this.message = response.data.message
    //       this.$refs['modal-stokopname-edit'].hide()
    //       this.$refs.MsgBox.show({
    //         type: 'success',
    //         msg: response.data.message
    //       })
    //       this.clearForm()
    //     } else {
    //       this.error = response.data.message
    //     }
    //     this.$refs.table.refresh()
    //   } catch (error) {
    //     this.error = error.message
    //     this.$refs.table.refresh()
    //   }
    // },
    closeModal (id) {
      this.id = id
      this.$refs['modal-konfirm-delete-hard'].show()
    },
    async handleClose (bvModalEvent) {
      bvModalEvent.preventDefault()
      try {
        const id = this.id
        const data = {
          object: 'stok-opname-close-status',
          close_status: '1'
        }
        const response = await Service.katalog.close(id, data)
        if (response.data.statcode === 0) {
          this.error = response.data.message
          this.$refs.table.refresh()
          this.$refs['modal-konfirm-delete-hard'].hide()
        } else {
          this.error = response.data.message
        }
      } catch (error) {
        this.error = error.message
      }
    },
    async getruangan () {
      await Service.katalog.getRuangStokOP().then(response => {
        if (response.status === 200) {
          this.combo.ruangan = response.data.data
        }
      }).catch(error => {
        this.error = error.response.data.errors.message
      })
    },
    async getPabrik ($event) {
      if ($event.length >= 2) {
        await Service.katalog.getPabrikStokOP_v2($event).then(response => {
          if (response.status === 200) {
            this.combo.pabrik = response.data.data
          }
        }).catch(error => {
          this.error = error.response.data.errors.message
        })
      } else {
        this.combo.pabrik = []
      }
    },
    async valRuang ($event, param) {
      if (param === 't') {
        this.stockopname.header.ruangan_id = $event.id
      }
    },
    onFiltered (filteredItems) {
      this.table.totalRows = filteredItems.length
    },
    valFilterRuang () {
      this.table.filter = ''
      this.$refs.table.refresh()
    },
    tableRefresh () {
      this.$refs.table.refresh()
    },
    filterBtn () {
      this.selected.filterType = '1'
      this.selected.filterRuang = '0'
      this.filtering.valTglAwal = ''
      this.filtering.valTglAkhir = ''
      this.display.viewTglAwal = ''
      this.display.viewTglAkhir = ''
      this.table.filter = ''
      this.$refs.table.refresh()
    },
    isNumberKey (evt) {
      evt = (evt) || window.event
      var charCode = (evt.which) ? evt.which : evt.keyCode
      if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
        evt.preventDefault()
      } else {
        return true
      }
    },
    arrContent () {
      this.stockopname.detail.push(
        {
          id: '0',
          id_katalog: '',
          id_pabrik: '',
          jumlah_adm: '0',
          jumlah_fisik: '',
          tgl_expired: '',
          sts_batal: '0',
          userid_in: xray.currentUser().id.toString()
        }
      )
      this.selected.kode.push(
        {
          value: []
        }
      )
      this.selected.pabrik.push(
        {
          detail: []
        }
      )
      this.display.tglInp.push(
        {
          value: ''
        }
      )
      this.display.tglExp.push(
        {
          date: ''
        }
      )
      this.display.namabarang.push(
        {
          nama: ''
        }
      )
      this.display.kodedetail.push(
        {
          kode: ''
        }
      )
      this.display.kodeName.push(
        {
          kode: '',
          nama: ''
        }
      )
    },
    addRow () {
      this.arrContent()
    },
    deleteRow (index, param) {
      if (param === 't') {
        this.stockopname.detail.splice(index, 1)
        this.display.tglInp.splice(index, 1)
        this.display.tglExp.splice(index, 1)
        this.selected.kode.splice(index, 1)
        this.selected.pabrik.splice(index, 1)
        this.display.namabarang.splice(index, 1)
        this.display.kodedetail.splice(index, 1)
        this.display.kodeName.splice(index, 1)
      }
    },
    clearForm () {
      this.error = ''
      //
      this.stockopname.header.ruangan_id = ''
      this.stockopname.header.berkas_id = '1'
      this.stockopname.header.tanggal = ''
      this.stockopname.header.keterangan = ''
      this.stockopname.header.sts_batal = '0'
      this.stockopname.header.inp_tanggal = this.getDateAuto()
      this.stockopname.header.inp_user = xray.currentUser().id.toString().toString()
      this.stockopname.header.ver_tanggal = ''
      this.stockopname.header.ver_user = ''
      this.stockopname.header.ver_status = ''
      this.stockopname.header.close_status = ''
      //
      this.selected.ruangan = []
      this.display.tanggal = ''
      //
      const a = this.stockopname.detail
      const aP = a.splice(0, a.length)
      const b = this.selected.pabrik
      const bP = b.splice(0, b.length)
      const g = this.selected.kode
      const gP = g.splice(0, g.length)
      const c = this.display.tglExp
      const cP = c.splice(0, c.length)
      const d = this.display.namabarang
      const dP = d.splice(0, d.length)
      const e = this.display.kodedetail
      const eP = e.splice(0, e.length)
      const f = this.display.tglInp
      const fP = f.splice(0, f.length)
      const h = this.display.kodeName
      const hP = h.splice(0, h.length)
      if (aP && bP && cP && dP && eP && fP && gP && hP) {
        this.arrContent()
      }
      //
    },
    tglWithDots (item) {
      return item['to2.tanggal']
    },
    inptglWithDots (item) {
      return item['to2.inp_tanggal']
    },
    berkasDots (item) {
      return item['to2.berkas_id']
    },
    tglDots (item) {
      return item['to2.tanggal']
    },
    ketDots (item) {
      return item['to2.keterangan']
    },
    closeDots (item) {
      return item['to2.close_status']
    }
  }
}
</script>

<style>
/* #modal-retur-add .modal-body, #modal-stokopname-edit .modal-body, #modal-filter .modal-body {
  background: #3399331b;
} */
#penjualan-retur .cstm-select-so-dash {
  font-size: 13px;
  height: 38px!important;
  border-radius: 5px;
}
#penjualan-retur input[type="text"][disabled],
#modal-retur-add input[type="text"][disabled],
#modal-stokopname-edit input[type="text"][disabled] {
   background: #e9ecef;
}

#modal-retur-add .table-penjualan-retur, #modal-stokopname-edit .table-penjualan-retur {
  height: auto;
}

#modal-retur-add .multiselect, #modal-stokopname-edit .multiselect {
  min-height: 33px;
}

#modal-retur-add .multiselect__single, #modal-stokopname-edit .multiselect__single {
  font-size: 0.9em!important;
  font-weight: normal!important;
  line-height: 26px;
}
#modal-retur-add .multiselect__placeholder, #modal-stokopname-edit .multiselect__placeholder {
  font-size: 0.9em!important;
  font-weight: normal;
}
#modal-retur-add .multiselect__option, #modal-stokopname-edit .multiselect__option {
  font-size: .8em!important;
}
#modal-retur-add .multiselect__tags, #modal-stokopname-edit .multiselect__tags {
  padding: 1px 40px 0 8px!important;
  height: 33px!important;
  min-height: 33px!important;
  font-size: 0.9em!important;
}

#modal-stokopname-edit .multiselect--disabled .multiselect__tags, #modal-retur-add .multiselect--disabled .multiselect__tags {
  border: 1px solid rgba(0, 0, 0, 0.171);
  background: #E9ECEF;
}

#modal-stokopname-edit .multiselect--disabled, #modal-retur-add .multiselect--disabled {
  opacity: 1;
}

#modal-stokopname-edit .multiselect--disabled .multiselect__tags .multiselect__single, #modal-retur-add .multiselect--disabled .multiselect__tags .multiselect__single {
  background: transparent;
}

#modal-stokopname-edit .multiselect__select, #modal-retur-add .multiselect__select {
  height: 30px;
}

#modal-stokopname-edit .disable-bg, #modal-retur-add .disable-bg {
  background: #e9ecef;
  padding-top: 5px;
  padding-bottom: 5px;
}

#penjualan-retur .bg-filter {
  border: 1px solid rgba(0, 0, 0, 0.13);
  background-color: #089bab1e;
}
#modal-retur-add .flatpickr-input[readonly], #modal-stokopname-edit .flatpickr-input[readonly], #modal-filter .flatpickr-input[readonly] {
  height: 33px;
  font-size: 13px;
  border-radius: 5px;
  border: 1px solid rgba(128, 128, 128, 0.292);
  padding-left: 10px;
  color: black;
}

/* #modal-retur-add .modal-dialog, #modal-stokopname-edit .modal-dialog {
  min-width: 85%!important;
} */

#header-table-filter .flatpickr-input[readonly] {
  height: 38px;
  font-size: 12px;
  border-radius: 5px;
  border: 1px solid rgba(17, 17, 17, 0.292);
  padding-left: 10px;
}
#modal-retur-add .cstm-cntrl-stockopname, #modal-stokopname-edit .cstm-cntrl-stockopname, #modal-filter .cstm-cntrl-stockopname {
  height: 38px!important;
  font-size: 13px!important;
  color: black!important;
  border-radius: 3px;
}
#penjualan-retur legend {
    color: darkslategrey;
}
#penjualan-retur .form-control:disabled, #penjualan-retur .form-control[readonly] {
    background-color: #e9ecef;
    opacity: 0.5;
}
#penjualan-retur .form-control {
  color: black!important;
}
#penjualan-retur .fc-unthemed .fc-toolbar .fc-button {
    margin: 3px;
}
#penjualan-retur tr:first-child > td > .fc-day-grid-event {
    cursor: pointer;
}
/* #modal-stokopname-edit .flatpickr-input[readonly] {
  background-color: rgb(245, 245, 245);
} */
#penjualan-retur .flex-half-width-left {
  width: 76%;
}
#penjualan-retur .flex-half-width-right {
  width: 23%;
}
#penjualan-retur .flex-full-width-left {
  width: 96%;
}
#penjualan-retur .flex-full-width-right {
  width: 3%;
}
#penjualan-retur .txt-farmasi-base {
  font-size: 12px;
}
#penjualan-retur .txt-farmasi-base-head {
  font-size: 14px;
}
#penjualan-retur .table td {
    font-size: 12px;
    font-weight: 500;
    padding-top: 0px;
    padding-bottom: 0px;
}
#penjualan-retur .table th {
    font-size: 13px;
    font-weight: 400;
}
#penjualan-retur .form-control {
    border: 1px solid rgb(194, 194, 194);
    background: white;
    height: 40px;
}

#penjualan-retur .btn-height-custom {
    height: 39px;
}
#penjualan-retur .cursor-point {
    cursor: pointer;
}
#penjualan-retur .bg-abu {
  background: #e9ecef;
}
#penjualan-retur .label-brg  {
  font-size: 13px;
  padding-left: 14px;
  padding-right: 14px;
  border: 1px solid rgba(0, 0, 0, 0.13);
  border-radius: 3px;
}
#penjualan-retur .form-control {
    font-size: 11px;
}
#penjualan-retur .b-table-sticky-header, #penjualan-retur .table-responsive {
    margin-bottom: 0px;
}
#penjualan-retur .table-barang .btn {
    font-size: 11px;
}
#penjualan-retur .page-link {
    border: none;
    color: gray!important;
    margin-right: 15px;
}
#penjualan-retur .page-item.active .page-link {
    background-color: transparent;
    border-color: transparent;
    color: #089bab!important;
    font-weight: bold;
}
#penjualan-retur .vertical-txt {
  writing-mode: vertical-rl;
  letter-spacing: 10px;
}
#penjualan-retur .transition-cstm {
  transition: .3s linear;
}

#modal-retur-add .modal-body, #modal-stokopname-edit .modal-body {
  background: #3399331b;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

#modal-retur-add .modal-header, #modal-stokopname-edit .modal-header {
  background: #3399333a;
}

#modal-retur-add .modal-footer, #modal-stokopname-edit .modal-footer {
  background: #3399333a;
}
#modal-retur-add .cstm-cntrl-stockopname, #modal-stokopname-edit .cstm-cntrl-stockopname {
  border-radius: 5px!important;
  font-size: 13px!important;
  height: 34px!important;
}
#modal-retur-add .cstm-slct-stockopname, #modal-stokopname-edit .cstm-slct-stockopname  {
  font-size: 13px!important;
  height: 38px!important;
  border-radius: 5px;
}
#penjualan-retur .form-control:disabled, #penjualan-retur .form-control[readonly] {
  background-color: #e9ecef;
  opacity: 1;
}

#penjualan-retur .cstm-srch-stock {
  font-size: 13px;
  height: 38px !important;
  border-radius: 5px;
}

#penjualan-retur .cstm-slct-stock {
  font-size: 13px;
  height: 38px !important;
  border-radius: 5px;
}
.alert-radius-stockopname {
  border-radius: 5px;
}

.font-size-responsive {
  font-size: 7px;
}

@media (min-width: 576px) {
  .font-size-responsive {
    font-size: 8px;
  }
 }

@media (min-width: 768px) {
  .font-size-responsive {
    font-size: 9px;
  }
 }

@media (min-width: 992px) {
  .font-size-responsive {
    font-size: 10px;
  }
 }

@media (min-width: 1200px) {
  .font-size-responsive {
    font-size: 11px;
  }
 }
 .pointer-table tbody tr {
  cursor: pointer;
}
</style>
