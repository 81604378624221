<template>
  <div>
    <div v-show="isLoading">
      <div id="show-overlay"></div>
      <Loader :style="{ opacity: .5 }" />
    </div>
    <div id="cstm-nav" class="cstm-nav">
      <b-navbar class="bg-white fixed-top shadow-sm" variant="faded" type="light">
        <b-navbar-brand href="#" class="text-center d-flex flex-row justify-content-center align-items-center mx-auto">
          <!-- <div class="mr-3 mr-lg-4">
            <img :src="require('../../assets/images/logo1.png')" width="40rem" class="d-inline-block align-top"
              alt="Kitten">
          </div> -->
          <div class="mr-3 mr-lg-4">
            <img :src="require('../../assets/images/logo-rsup.png')" width="45rem" class="d-inline-block align-top"
              alt="Kitten">
          </div>
          <div class="mr-3 mr-lg-4">
            <img :src="require('../../assets/images/logo-kemkes.png')" width="85rem" class="d-inline-block align-top"
              alt="Kitten">
          </div>
          <div>
            <img :src="require('../../assets/images/germas.png')" width="85rem" class="d-inline-block align-top"
              alt="Kitten">
          </div>
        </b-navbar-brand>
      </b-navbar>
    </div>
    <div id="top" class="top bg-img-welcome">
      <b-container>
        <div class="text-center text-position-welcome w-100">
          <div class="text-center mb-3">
            <img :src="require('../../assets/images/logo-rsup.png')" width="150rem" alt="Kitten">
          </div>
          <p class="h2 text-responsive-welcome text-dark text-uppercase mb-2">Percayakan Kepada Kami</p>
          <p class="h2 text-responsive-welcome text-dark font-weight-bold text-uppercase mb-4">RSUP Fatmawati</p>
          <div class="loading-welcome">
            <b-row class="justify-content-center">
              <b-col cols="10" lg="4">
                <b-progress class="shadow-sm border" :value="bars.value" :max="bars.max" :variant="bars.variant"></b-progress>
              </b-col>
            </b-row>
          </div>
          <!-- <b-button class="btn-custom-welcome" variant="primary" @click="signin()">
            <i class="fas fa-sign-in-alt"></i>Login / Register
          </b-button> -->
        </div>
      </b-container>
    </div>
    <!-- <div id="center" class="center margin-collapse-welcome mb-5">
      <b-container>
        <iq-card class="card-color-welcome">
          <b-row class="align-items-center">
            <b-col cols="12" md="6" lg="3" v-for="c in dataNakes" :key="c.id">
              <iq-card v-bind:class="['my-iq-card-welcome shadow-sm m-3', c.iqCardBg]">
                <div class="d-flex flex-row justify-content-between align-items-center py-2 px-4">
                  <div>
                    <i v-bind:class="['fas fa-3x', c.icon]"></i>
                  </div>
                  <div class="text-right">
                    <p class="h5 mb-0">{{c.val}}</p>
                    <p class="small mb-0">{{c.title}}</p>
                  </div>
                </div>
              </iq-card>
            </b-col>
            <b-col cols="12" md="6" lg="3">
              <iq-card class="my-iq-card-welcome bg-primary shadow-sm m-3 py-1">
                <div class="d-flex flex-row justify-content-center align-items-center">
                  <div class="text-white py-1">
                    <b-button v-b-modal.modal-3 variant="link" class="text-white text-decoration-none py-2">
                      <p class="mb-0"><i class="fas fa-bars"></i> Lihat Semua</p>
                    </b-button>
                  </div>
                </div>
              </iq-card>
            </b-col>
          </b-row>
        </iq-card>
      </b-container>
    </div> -->
    <!-- <div id="bottom" class="bottom mb-5">
      <b-container>
        <b-row class="justify-content-md-center mt-5 mb-5">
          <b-col md="4">
            <div class="bg-white border-oval-welcome shadow-sm py-2 px-4 h-100">
              <div class="d-flex flex-row justify-content-between align-items-center">
                <div>
                  <p class="h5 txt-cstm-welcome font-weight-bold text-center mb-0">GET BPJS</p>
                </div>
                <div>
                  <b-button type="button" class="btn btn-primary" size="lg" v-on:click="cekKoneksi"><i
                      class="fas fa-plug"></i> Cek Koneksi
                  </b-button>
                </div>
              </div>
            </div>
          </b-col>
        </b-row>
        <b-row class="justify-content-md-center mt-5">
          <b-col cols="12" md="6" lg="3" class="mb-3" v-for="b in bpjsView" :key="b.id">
            <router-link :to="{path:b.router}">
              <a v-b-modal="b.modalID" class="bpjs-card-parent-welcome">
                <div class="bpjs-card-child-welcome bg-white shadow-sm border-oval-welcome h-100 py-4">
                  <div class="img-head text-center pt-4 pb-1">
                    <i :class="['fas fa-4x', b.icon]"></i>
                  </div>
                  <div class="card-body px-3">
                    <p class="font-weight-bold text-center mb-0">{{ b.title }}</p>
                    <p class="small text-center mb-0">{{ b.desc }}</p>
                  </div>
                </div>
              </a>
            </router-link>
          </b-col>
        </b-row>
        <div class="mt-5 d-none d-lg-block">
          <div class="text-center">
            <p class="h6 font-weight-light text-primary mb-0">Version {{ version }}</p>
          </div>
        </div>
        <div class="mt-3 d-lg-none">
          <b-row class="justify-content-center">
            <b-col cols="6">
              <div class="bg-primary px-4 py-2 rounded shadow-sm">
                <p class="h6 text-center font-weight-light text-white mb-0">Version {{ version }}</p>
              </div>
            </b-col>
          </b-row>
        </div>
      </b-container>
    </div> -->
    <b-modal id="modal-1" centered title="Pilih Menu" :ok-only="true" ok-title="Tutup">
      <div class="bg-primary border-oval-welcome modal-hov-welcome mb-3 p-3" v-for="m1 in modalData1" :key="m1.id">
        <router-link :to="{path:m1.router}">
          <a href="" class="modal-parent">
            <b-row>
              <b-col md="2" class="d-flex align-items-center">
                <div>
                  <p class="text-white mb-0"><i v-bind:class="m1.icon"></i></p>
                </div>
              </b-col>
              <b-col md="10" class="d-flex align-items-center">
                <div>
                  <p class="h6 text-white font-weight-bold mb-1">{{ m1.title }}</p>
                  <p class="small text-white mb-0">{{ m1.desc }}</p>
                </div>
              </b-col>
            </b-row>
          </a>
        </router-link>
      </div>
    </b-modal>
    <b-modal id="modal-2" centered title="Pilih Menu" :ok-only="true" ok-title="Tutup">
      <div class="bg-primary border-oval-welcome modal-hov-welcome mb-3 p-3" v-for="m1 in modalData2" :key="m1.id">
        <router-link :to="{path:m1.router}">
          <a href="" class="modal-parent">
            <b-row>
              <b-col md="2" class="d-flex align-items-center">
                <div>
                  <p class="text-white mb-0"><i v-bind:class="m1.icon"></i></p>
                </div>
              </b-col>
              <b-col md="10" class="d-flex align-items-center">
                <div>
                  <p class="h6 text-white font-weight-bold mb-1">{{ m1.title }}</p>
                  <p class="small text-white mb-0">{{ m1.desc }}</p>
                </div>
              </b-col>
            </b-row>
          </a>
        </router-link>
      </div>
    </b-modal>
    <b-modal id="modal-3" size="lg" centered title="Data Nakes" :ok-only="true" ok-title="Tutup">
      <b-row>
        <b-col cols="12" md="6" lg="4" v-for="c in dataNakesAll" :key="c.id">
          <iq-card v-bind:class="['my-iq-card-welcome shadow-sm m-3', c.iqCardBg]">
            <div class="d-flex flex-row justify-content-between align-items-center py-2 px-4">
              <div>
                <i v-bind:class="['fas fa-3x', c.icon]"></i>
              </div>
              <div class="text-right">
                <p class="h5 mb-0">{{c.val}}</p>
                <p class="small mb-0">{{c.title}}</p>
              </div>
            </div>
          </iq-card>
        </b-col>
      </b-row>
    </b-modal>
  </div>
</template>
<script>
import 'sweetalert2/src/sweetalert2.scss'
import Loader from '../../components/xray/loader/Loader'
// import Token from '../../services/token.js'
export default {
  name: 'WelcomePage',
  components: {
    Loader
  },
  data () {
    return {
      isLoading: false,
      version: '',
      error: '',
      bars: {
        variant: 'primary',
        value: 1,
        max: 50
      },
      dataNakes: [
        {
          id: 1,
          iqCardBg: 'iq-bg-info',
          icon: 'fa-user-md',
          val: '0',
          title: 'Dokter'
        },
        {
          id: 2,
          iqCardBg: 'iq-bg-success',
          icon: 'fa-user-nurse',
          val: '0',
          title: 'Perawat'
        },
        {
          id: 3,
          iqCardBg: 'iq-bg-warning',
          icon: 'fa-user-injured',
          val: '0',
          title: 'Farmasi'
        }
      ],
      dataNakesAll: [
        {
          id: 1,
          iqCardBg: 'iq-bg-primary',
          icon: 'fa-user-md',
          val: '600',
          title: 'Dokter'
        },
        {
          id: 2,
          iqCardBg: 'iq-bg-primary',
          icon: 'fa-user-nurse',
          val: '1200',
          title: 'Perawat'
        },
        {
          id: 3,
          iqCardBg: 'iq-bg-primary',
          icon: 'fa-user-injured',
          val: '20',
          title: 'Farmasi'
        },
        {
          id: 4,
          iqCardBg: 'iq-bg-primary',
          icon: 'fa-user-tie',
          val: '3000',
          title: 'Apoteker'
        },
        {
          id: 5,
          iqCardBg: 'iq-bg-primary',
          icon: 'fa-user-tie',
          val: '600',
          title: 'Penata Anastesi'
        },
        {
          id: 6,
          iqCardBg: 'iq-bg-primary',
          icon: 'fa-user-tie',
          val: '1200',
          title: 'Fisioterapis'
        },
        {
          id: 7,
          iqCardBg: 'iq-bg-primary',
          icon: 'fa-user-tie',
          val: '20',
          title: 'Radiografer'
        },
        {
          id: 8,
          iqCardBg: 'iq-bg-primary',
          icon: 'fa-user-tie',
          val: '3000',
          title: 'Refraksionis Optisien'
        },
        {
          id: 9,
          iqCardBg: 'iq-bg-primary',
          icon: 'fa-user-tie',
          val: '3000',
          title: 'Okupasi Terapi'
        },
        {
          id: 10,
          iqCardBg: 'iq-bg-primary',
          icon: 'fa-user-tie',
          val: '600',
          title: 'Ortotik Prostetik'
        },
        {
          id: 11,
          iqCardBg: 'iq-bg-primary',
          icon: 'fa-user-tie',
          val: '1200',
          title: 'Terapi wicara'
        },
        {
          id: 12,
          iqCardBg: 'iq-bg-primary',
          icon: 'fa-user-tie',
          val: '20',
          title: 'Terapis Gigi'
        },
        {
          id: 13,
          iqCardBg: 'iq-bg-primary',
          icon: 'fa-user-tie',
          val: '3000',
          title: 'Psikologi'
        },
        {
          id: 14,
          iqCardBg: 'iq-bg-primary',
          icon: 'fa-user-tie',
          val: '1200',
          title: 'Nutrisionis'
        },
        {
          id: 15,
          iqCardBg: 'iq-bg-primary',
          icon: 'fa-user-tie',
          val: '20',
          title: 'Bidan'
        },
        {
          id: 16,
          iqCardBg: 'iq-bg-primary',
          icon: 'fa-user-tie',
          val: '3000',
          title: 'Nutrisionis'
        },
        {
          id: 17,
          iqCardBg: 'iq-bg-primary',
          icon: 'fa-user-tie',
          val: '3000',
          title: 'Audiologis'
        }
      ],
      cardBottom: [
        {
          id: 1,
          title: 'Visi',
          icon: 'fa-hands',
          desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque scelerisque'
        },
        {
          id: 2,
          title: 'Misi',
          icon: 'fa-praying-hands',
          desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque scelerisque'
        },
        {
          id: 3,
          title: 'Motto',
          icon: 'fa-hand-paper',
          desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque scelerisque'
        }
      ],
      bpjsView: [
        {
          id: 1,
          icon: 'fa-id-card',
          title: 'CEK KEPESERTAAN',
          desc: 'Kepesertaan BPJS Kesehatan',
          quote: 'Klik untuk melakukan proses',
          modalID: '',
          router: '/bpjs/kepesertaan'
        },
        {
          id: 2,
          icon: 'fa-hospital-alt',
          title: 'CEK RUJUKAN',
          desc: 'Rujukan BPJS Ke Rs Fatmawati',
          quote: 'Klik untuk melakukan proses',
          modalID: '',
          router: ''
        }
        // {
        //   id: 2,
        //   icon: 'fa-chart-area',
        //   title: 'Dashboard',
        //   desc: 'Monitoring Data',
        //   quote: 'Klik untuk melakukan proses',
        //   modalID: 'modal-1',
        //   router: ''
        // },
        // {
        //   id: 3,
        //   icon: 'fa-bullhorn',
        //   title: 'Public Pages',
        //   desc: 'Formulir',
        //   quote: 'Klik untuk melakukan proses',
        //   modalID: 'modal-2',
        //   router: ''
        // }
      ],
      modalData1: [
        {
          id: 1,
          icon: 'fas fa-chart-bar fa-3x',
          title: 'SDM Monitoring ',
          desc: 'Monitoring STR Dan STP Pegawai',
          router: '/dashSdm'
        },
        {
          id: 2,
          icon: 'fas fa-chart-bar fa-3x',
          title: 'Klaim Monitoring ',
          desc: 'Monitoring Klaim',
          router: '/dashKlaim'
        }
      ],
      modalData2: [
        {
          id: 1,
          icon: 'fas fa-book fa-3x',
          title: 'Unit Transfusi Darah',
          desc: 'Reservasi Online',
          router: '/formUtd'
        },
        {
          id: 2,
          icon: 'fas fa-book fa-3x',
          title: 'MCU Dan Vaksin Meningitis',
          desc: 'Reservasi Online',
          router: '/reservasi-online'
        }
        // {
        //   id: 3,
        //   icon: 'fas fa-book fa-3x',
        //   title: 'Rawat Jalan',
        //   desc: 'Reservasi Online',
        //   router: '/reservasi-irj'
        // }
      ]
    }
  },
  mounted () {
    // this.cekToken()
    // this.DataNakes()
    var pjson = require('../../../package.json')
    this.version = pjson.version
    this.removeToken()
    this.tes(50)
  },
  methods: {
    removeToken () {
      localStorage.removeItem('patient')
      localStorage.removeItem('token')
      localStorage.removeItem('_grecaptcha')
      localStorage.clear()
    },
    tes (param) {
      var i = 0
      var ref = setInterval(() => {
        this.bars.value = ++i
        if (i === param) {
          clearInterval(ref)
          this.signin()
        }
      }, 50)
    },
    // async cekToken () {
    //   this.isLoading = true
    //   const origin = {
    //     url: location.origin
    //   }
    //   let one = localStorage.getItem('ref')
    //   const res = await Token.myToken(origin)
    //   localStorage.setItem('token', res.data.token)
    //   localStorage.setItem('ref', one = one + 1)
    //   this.isLoading = false
    //   // const tk = localStorage.getItem('token')
    //   const rf = localStorage.getItem('ref')
    //   if (rf === '1' || rf === '1111') {
    //     location.reload()
    //   }
    // },
    myalert () {
      alert('Belum Ada')
    },
    signin () {
      this.$router.replace({ name: 'auth1.signin' })
    }
    // async cekKoneksi () {
    //   this.isLoading = true
    //   try {
    //     const response = await BPJS.cekkoneksi()
    //     this.isLoading = false
    //     Swal.fire({
    //       title: response.statusText,
    //       text: response.data.message,
    //       showCancelButton: false,
    //       showConfirmButton: false
    //     })
    //   } catch (err) {
    //     this.isLoading = false
    //     Swal.fire({
    //       title: 'Gagal koneksi',
    //       text: err,
    //       showCancelButton: false,
    //       showConfirmButton: true
    //     })
    //   }
    // },
    // async DataNakes () {
    //   try {
    //     const res = await SDM.getStp()
    //     this.dataNakes[0].val = res.data.rekapNakes[6].TOTAL
    //     this.dataNakes[1].val = res.data.rekapNakes[14].TOTAL
    //     this.dataNakes[2].val = res.data.rekapNakes[0].TOTAL
    //     this.dataNakesAll[0].val = res.data.rekapNakes[6].TOTAL
    //     this.dataNakesAll[1].val = res.data.rekapNakes[14].TOTAL
    //     this.dataNakesAll[2].val = res.data.rekapNakes[0].TOTAL
    //     this.dataNakesAll[3].val = res.data.rekapNakes[1].TOTAL
    //     this.dataNakesAll[4].val = res.data.rekapNakes[2].TOTAL
    //     this.dataNakesAll[5].val = res.data.rekapNakes[3].TOTAL
    //     this.dataNakesAll[6].val = res.data.rekapNakes[4].TOTAL
    //     this.dataNakesAll[7].val = res.data.rekapNakes[5].TOTAL
    //     this.dataNakesAll[8].val = res.data.rekapNakes[7].TOTAL
    //     this.dataNakesAll[9].val = res.data.rekapNakes[8].TOTAL
    //     this.dataNakesAll[10].val = res.data.rekapNakes[9].TOTAL
    //     this.dataNakesAll[11].val = res.data.rekapNakes[10].TOTAL
    //     this.dataNakesAll[12].val = res.data.rekapNakes[11].TOTAL
    //     this.dataNakesAll[13].val = res.data.rekapNakes[12].TOTAL
    //     this.dataNakesAll[14].val = res.data.rekapNakes[13].TOTAL
    //     this.dataNakesAll[15].val = res.data.rekapNakes[15].TOTAL
    //     this.dataNakesAll[16].val = res.data.rekapNakes[16].TOTAL
    //   } catch (err) {
    //     this.error = err
    //   }
    // }
  }
}
</script>
<style>
@import '../../assets/css/pages/public/welcome-page.css';
</style>
