import Api from '../axiosInstance'
const version = '/v1'
export default {
  findAll (currentPage, perPage, filter) {
    return Api.pegawai.get(`${version}/service/pegawai?param0=pegawai&param1=${currentPage}&param2=${perPage}&param3=${filter}`)
  },
  findId (id) {
    return Api.pegawai.get(`${version}/service/pegawai/${id}?param0=pegawai`)
  },
  store (data) {
    return Api.pegawai.post(`${version}/service/pegawai`, data)
  },
  findPegawaiDokter (currentPage, perPage, filter) {
    return Api.pegawai.get(`${version}/service/trxPegawaiDokter?param0=pegawai-dokter&param1=${currentPage}&param2=${perPage}&param3=${filter}`)
  },
  showPegawaiDokter (id) {
    return Api.pegawai.get(`${version}/service/trxPegawaiDokter/${id}?param0=pegawai-dokter`)
  },
  selectizePegawai () {
    return Api.pegawai.get(`${version}/service/selectize?param0=trx-pegawai`)
  },
  createPegawaiDokter (data) {
    return Api.pegawai.post(`${version}/service/trxPegawaiDokter/`, data)
  },
  updatePegawaiDokter (id, data) {
    return Api.pegawai.put(`${version}/service/trxPegawaiDokter/${id}`, data)
  },
  deletePegawaiDokter (id) {
    return Api.pegawai.delete(`${version}/service/trxPegawaiDokter/1?param0=pegawai-dokter&param1=${id}`)
  },
  dashboardparam (id) {
    return Api.pegawai.get(`${version}/service/dashRemun/grafik?param0=load-dashboard-params&param1=0&param2=10&param3={"xparam0":"${id}","xparam1":"","xparam2":""}&param4={"xparam0":"","xparam1":""}`)
  },
  dashboardparamv2 (id) {
    return Api.pegawai.get(`${version}/service/dashRemun/grafik?param0=load-dashboard-params-v2&param1=0&param2=10&param3={"xparam0":"${id}","xparam1":"","xparam2":""}&param4={"xparam0":"","xparam1":""}`)
  },
  grafikparam (id) {
    return Api.pegawai.get(`${version}/service/dashRemun/grafik?param0=load-dashboard-grafik&param1=0&param2=10&param3={"xparam0":"${id}","xparam1":"","xparam2":""}&param4={"xparam0":"","xparam1":""}`)
  },
  grafikparamv2 (id) {
    return Api.pegawai.get(`${version}/service/dashRemun/grafik?param0=load-dashboard-grafik-v2&param1=0&param2=10&param3={"xparam0":"${id}","xparam1":"","xparam2":""}&param4={"xparam0":"","xparam1":""}`)
  },
  loadProfile (id) {
    return Api.pegawai.get(`${version}/service/dashRemun/grafik?param0=load-dashboard-profile&param1=0&param2=10&param3={"xparam0":"${id}","xparam1":"","xparam2":""}&param4={"xparam0":"","xparam1":""}`)
  },
  loadProfilev2 (id) {
    return Api.pegawai.get(`${version}/service/dashRemun/grafik?param0=load-dashboard-profile-v2&param1=0&param2=10&param3={"xparam0":"${id}","xparam1":"","xparam2":""}&param4={"xparam0":"","xparam1":""}`)
  },
  loadRemun (id, periode) {
    return Api.pegawai.get(`${version}/service/dashRemun?param0=load-dash-remun&param1=0&param2=10&param3={"xparam0":"${id}","xparam1":"","xparam2":"${periode}","xparam3":"","xparam4":"","xparam5":""}&param4={"xparam0":"","xparam1":""}`)
  },
  loadRemunv2 (id, periode) {
    return Api.pegawai.get(`${version}/service/dashRemun?param0=load-dash-remun-v2&param1=0&param2=10&param3={"xparam0":"${id}","xparam1":"","xparam2":"${periode}","xparam3":"","xparam4":"","xparam5":""}&param4={"xparam0":"","xparam1":""}`)
  },
  rinciRekapRemun (currentPage, perPage, id, jenis, periode, kegKel, filter, sortfield, sorting) {
    return Api.pegawai.get(`${version}/service/dashRemun?param0=${jenis}&param1=${currentPage}&param2=${perPage}&param3={"xparam0":"${id}","xparam1":"${filter}","xparam2":"${periode}","xparam3":"${kegKel}","xparam4":"","xparam5":""}&param4={"xparam0":"${sortfield}","xparam1":"${sorting}"}`)
  },
  findPegawaiKsm (currentPage, perPage, filter) {
    return Api.pegawai.get(`${version}/service/pegawaiKsm?param0=pegawai-ksm&param1=${currentPage}&param2=${perPage}&${filter}`)
  },
  findPegawaiKsmGrup (currentPage, perPage, filter) {
    return Api.pegawai.get(`${version}/service/pegawaiKsmGrp?param0=pegawai-ksm-group&param1=${currentPage}&param2=${perPage}&${filter}`)
  },
  selectizePegawaiKsm () {
    return Api.pegawai.get(`${version}/service/selectize?param0=pegawai-ksm&param1={"xparam0":"","xparam1":"","xparam2":""}`)
  },
  createPegawaiKsm (data) {
    return Api.pegawai.post(`${version}/service/pegawaiKsm/`, data)
  },
  createPegawaiKsmGrup (data) {
    return Api.pegawai.post(`${version}/service/pegawaiKsmGrp/`, data)
  },
  showPegawaiKsm (id) {
    return Api.pegawai.get(`${version}/service/pegawaiKsm/${id}?param0=pegawai-ksm`)
  },
  showPegawaiKsmGrup (id) {
    return Api.pegawai.get(`${version}/service/pegawaiKsmGrp/${id}?param0=pegawai-ksm-group`)
  },
  updatePegawaiKsm (id, data) {
    return Api.pegawai.put(`${version}/service/pegawaiKsm/${id}`, data)
  },
  updatePegawaiKsmGrup (id, data) {
    return Api.pegawai.put(`${version}/service/pegawaiKsmGrp/${id}`, data)
  },
  deletePegawaiKsm (id) {
    return Api.pegawai.delete(`${version}/service/pegawaiKsm/${id}?param0=pegawai-ksm`)
  },
  deletePegawaiKsmGrup (id) {
    return Api.pegawai.delete(`${version}/service/pegawaiKsmGrp/${id}?param0=pegawai-ksm-group`)
  },
  poli (ruangan) {
    return Api.pegawai.get(`${version}/service/selectize?param0=poli&param1={"xparam0":"${ruangan}", "xparam1":"", "xparam2":""}`)
  },
  subspesialis (id, ruangan) {
    return Api.pegawai.get(`${version}/service/selectize?param0=sub-spesialis&param1={"xparam0":"${id}", "xparam1":"${ruangan}", "xparam2":""}`)
  },
  dokter (id, ruangan) {
    return Api.pegawai.get(`${version}/service/selectize?param0=dokter&param1={"xparam0":"${id}", "xparam1":"${ruangan}", "xparam2":""}`)
  },
  remunKegKlp (currentPage, perPage, filter, sortfield, sorting) {
    return Api.pegawai.get(`${version}/service/mremun?param0=kegiatan-klp&param1=${currentPage}&param2=${perPage}&param3={"xparam0":"${filter}","xparam1":"","xparam2":""}&param4={"xparam0":"${sortfield}","xparam1":"${sorting}"}`)
  },
  remunKegKlpCreate (data) {
    return Api.pegawai.post(`${version}/service/mremun/`, data)
  },
  remunKegKlpShow (id) {
    return Api.pegawai.get(`${version}/service/mremun/${id}?param0=kegiatan-klp`)
  },
  remunKegKlpDelete (id) {
    return Api.pegawai.delete(`${version}/service/mremun/${id}?param0=kegiatan-klp`)
  },
  remunKegExl (currentPage, perPage, filter, sortfield, sorting) {
    return Api.pegawai.get(`${version}/service/mremun?param0=kegiatan-exclude&param1=${currentPage}&param2=${perPage}&param3={"xparam0":"${filter}","xparam1":"","xparam2":""}&param4={"xparam0":"${sortfield}","xparam1":"${sorting}"}`)
  },
  remunKegExlCreate (data) {
    return Api.pegawai.post(`${version}/service/mremun/`, data)
  },
  remunKegExlShow (id) {
    return Api.pegawai.get(`${version}/service/mremun/${id}?param0=kegiatan-exclude`)
  },
  remunKegExlDelete (id) {
    return Api.pegawai.delete(`${version}/service/mremun/${id}?param0=kegiatan-exclude`)
  },
  remunKegTrf (currentPage, perPage, filter, sortfield, sorting) {
    return Api.pegawai.get(`${version}/service/mremun?param0=kegiatan-tarif&param1=${currentPage}&param2=${perPage}&param3={"xparam0":"${filter}","xparam1":"","xparam2":""}&param4={"xparam0":"${sortfield}","xparam1":"${sorting}"}`)
  },
  remunKegTrfCreate (data) {
    return Api.pegawai.post(`${version}/service/mremun/`, data)
  },
  remunKegTrfShow (id) {
    return Api.pegawai.get(`${version}/service/mremun/${id}?param0=kegiatan-tarif`)
  },
  remunKegTrfDelete (id) {
    return Api.pegawai.delete(`${version}/service/mremun/${id}?param0=kegiatan-tarif`)
  },
  remunKegPrsn (currentPage, perPage, filter, sortfield, sorting) {
    return Api.pegawai.get(`${version}/service/mremun?param0=persentase&param1=${currentPage}&param2=${perPage}&param3={"xparam0":"${filter}","xparam1":"","xparam2":""}&param4={"xparam0":"${sortfield}","xparam1":"${sorting}"}`)
  },
  remunKegPrsnCreate (data) {
    return Api.pegawai.post(`${version}/service/mremun/`, data)
  },
  remunKegPrsnShow (id) {
    return Api.pegawai.get(`${version}/service/mremun/${id}?param0=persentase`)
  },
  remunKegPrsnDelete (id) {
    return Api.pegawai.delete(`${version}/service/mremun/${id}?param0=persentase`)
  },
  remunPer (currentPage, perPage) {
    return Api.pegawai.get(`${version}/service/mremun?param0=kegiatan-klp&param1=${currentPage}&param2=${perPage}&param3={"xparam0":"","xparam1":"","xparam2":""}&param4={"xparam0":"","xparam1":""}`)
  },
  getkegiatanksm () {
    return Api.pegawai.get(`${version}/service/selectize?param0=pegawai-ksm-group&param1={"xparam0":"", "xparam1":"", "xparam2":""}`)
  },
  // Transaksi Remun Start
  findOrAllTrxRemun (v, currentPage, perPage, object, tanggalA, tanggalB, tanggalC, tanggalD, tanggalE, tanggalF, nomor, dokter, ruangan, tindakan, kegiatan, carabayar, ksm, sortfield, sorting) {
    return Api.pegawai.get(`${v}/service/trxRemun?param0=${object}&param1=${currentPage}&param2=${perPage}&param3={"xparam0":"${tanggalA}","xparam1":"${tanggalB}","xparam2":"${tanggalC}","xparam3":"${tanggalD}","xparam4":"${tanggalE}","xparam5":"${tanggalF}","xparam6":"${nomor}","xparam7":"${dokter}","xparam8":"${ruangan}","xparam9":"${tindakan}","xparam10":"${kegiatan}","xparam11":"${carabayar}","xparam12":"${ksm}"}&param4={"xparam0":"${sortfield}","xparam1":"${sorting}"}`)
  },
  update_nopen (v, nopen, data) {
    return Api.pegawai.put(`${v}/service/trxRemun/${nopen}`, data)
  },
  update_kunjungan (v, nokunjungan, data) {
    return Api.pegawai.put(`${v}/service/trxRemun/${nokunjungan}`, data)
  },
  update_detil (v, idtransaksi, iddetail, data) {
    return Api.pegawai.put(`${v}/service/trxRemun/${idtransaksi}/${iddetail}`, data)
  },
  update_detil_v2 (v, idtransaksi, iddetail, data) {
    return Api.pegawai.put(`${v}/service/trxRemun/${idtransaksi}`, data)
  },
  show_nopendafataran (v, nopendafataran, objName, dokter) {
    return Api.pegawai.get(`${v}/service/trxRemun/${nopendafataran}/${dokter}?param0=${objName}`)
  },
  show_nokunjungan_rinci (v, object, nokunjungan, dokter) {
    return Api.pegawai.get(`${v}/service/trxRemun/${nokunjungan}/${dokter}?param0=${object}`)
  },
  getKegiatanPosisi () {
    return Api.pegawai.get(`${version}/service/selectize?param0=kegiatan-posisi&param1={"xparam0":"", "xparam1":"", "xparam2":""}`)
  },
  trx_pegawai (value) {
    return Api.pegawai.get(`${version}/service/selectize?param0=trx-pegawai&param1={"xparam0":"${value}", "xparam1":"", "xparam2":""}`)
  },
  selectizePegawaiKsmGrup () {
    return Api.pegawai.get(`${version}/service/selectize?param0=pegawai-ksm-group&param1={"xparam0":"","xparam1":"","xparam2":""}`)
  },
  tindakan_remun () {
    return Api.pegawai.get(`${version}/service/selectize?param0=tindakan-remun&param1={"xparam0":"", "xparam1":"", "xparam2":""}`)
  },
  getRuangRemun () {
    return Api.pegawai.get(`${version}/service/selectize?param0=ruangan-remun&param1={"xparam0":"", "xparam1":"", "xparam2":""}`)
  },
  // Transaksi Remun End
  findOrAllTrxKinerja (currentPage, perPage, sortfield, sorting, tag, uraian, status, statusKin, id, tglAwal, tglAkhir) {
    return Api.pegawai.get(`${version}/service/trxKinerja?param0=tkinerja-event&param1=${currentPage}&param2=${perPage}&param3={"xparam0":"${id}","xparam1":"${tag}","xparam2":"${status}","xparam3":"${uraian}","xparam4":"${tglAwal}","xparam5":"${tglAkhir}","xparam6":"${statusKin}","xparam7":"","xparam8":"","xparam9":""}&param4={"xparam0":"${sortfield}","xparam1":"${sorting}"}`)
  },
  showTrxKinerja (id, data) {
    return Api.pegawai.get(`${version}/service/trxKinerja/${id}?param0=tkinerja-event`, data)
  },
  mworktype () {
    return Api.pegawai.get(`${version}/service/selectize?param0=mwork-etype&param1={"xparam0":"", "xparam1":"", "xparam2":""}`)
  },
  stskinerja () {
    return Api.pegawai.get(`${version}/service/selectize?param0=status-kinerja&param1={"xparam0":"", "xparam1":"", "xparam2":""}`)
  },
  createTrxKinerja (data) {
    return Api.pegawai.post(`${version}/service/trxKinerja`, data)
  },
  findLogTrxKinerja (id) {
    return Api.pegawai.get(`${version}/service/trxKinerja/log?param0=tkinerja-event-log&param1=0&param2=100&param3={"xparam0":"${id}","xparam1":"","xparam2":"","xparam3":"","xparam4":"","xparam5":"","xparam6":"","xparam7":"","xparam8":"","xparam9":""}&param4={"xparam0":"","xparam1":""}`)
  },
  findIssueTrxKinerja (id) {
    return Api.pegawai.get(`${version}/service/trxKinerja/log?param0=tkinerja-issue&param1=0&param2=10&param3={"xparam0":"${id}","xparam1":"","xparam2":"","xparam3":"","xparam4":"","xparam5":"","xparam6":"","xparam7":"","xparam8":"","xparam9":""}&param4={"xparam0":"","xparam1":""}`)
  },
  findUserTrxKinerja (id) {
    return Api.pegawai.get(`${version}/service/trxKinerja/log?param0=tkinerja-issue-assign&param1=0&param2=10&param3={"xparam0":"${id}","xparam1":"","xparam2":"","xparam3":"","xparam4":"","xparam5":"","xparam6":"","xparam7":"","xparam8":"","xparam9":""}&param4={"xparam0":"","xparam1":""}`)
  },
  findDashboardTrxKinerja (currentPage, perPage, id, tag, uraian, status, tglAwal, tglAkhir, statusKin) {
    return Api.pegawai.get(`${version}/service/trxKinerja/log?param0=tkinerja-dash&param1=${currentPage}&param2=${perPage}&param3={"xparam0":"${id}","xparam1":"${tag}","xparam2":"${status}","xparam3":"${uraian}","xparam4":"${tglAwal}","xparam5":"${tglAkhir}","xparam6":"${statusKin}","xparam7":"","xparam8":"","xparam9":""}&param4={"xparam0":"","xparam1":""}`)
  },
  updateTrxKinerja (id, data) {
    return Api.pegawai.put(`${version}/service/trxKinerja/${id}`, data)
  },
  patchTrxKinerja (id, data) {
    return Api.pegawai.patch(`${version}/service/trxKinerja/${id}`, data)
  },
  assign () {
    return Api.pegawai.get(`${version}/service/selectize?param0=assign&param1={"xparam0":"", "xparam1":"", "xparam2":""}`)
  },
  assignType () {
    return Api.pegawai.get(`${version}/service/selectize?param0=assign-type&param1={"xparam0":"", "xparam1":"", "xparam2":""}`)
  },
  tkinerjaStart (url, body) {
    return Api.pegawai.post(`${version}/${url}`, body)
  },
  tkinerjaUpdate (url, body) {
    return Api.pegawai.put(`${version}/${url}`, body)
  }
}
//
