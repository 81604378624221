import Api from '../axiosInstance'
const version = '/v1'
export default {
  // katalogFarmasi-------------------------------------
  listFarmasi (currentPage, perPage, filter, filterType, sortfield, sorting) {
    if (filter) {
      if (filterType === '1') {
        return Api.katalog.get(`${version}/master/practitioner/farmasi?param0=farmasi&param1=${currentPage}&param2=${perPage}&param3={"xparam0":"${filter}","xparam1":"","xparam2":""}&param4={"xparam0":"${sortfield}","xparam1":"${sorting}"}`)
      } else if (filterType === '2') {
        return Api.katalog.get(`${version}/master/practitioner/farmasi?param0=farmasi&param1=${currentPage}&param2=${perPage}&param3={"xparam0":"","xparam1":"${filter}","xparam2":""}&param4={"xparam0":"${sortfield}","xparam1":"${sorting}"}`)
      } else if (filterType === '3') {
        return Api.katalog.get(`${version}/master/practitioner/farmasi?param0=farmasi&param1=${currentPage}&param2=${perPage}&param3={"xparam0":"","xparam1":"","xparam2":"${filter}"}&param4={"xparam0":"${sortfield}","xparam1":"${sorting}"}`)
      }
    } else {
      return Api.katalog.get(`${version}/master/practitioner/farmasi?param0=farmasi&param1=${currentPage}&param2=${perPage}&param3={"xparam0":"","xparam1":"","xparam2":""}&param4={"xparam0":"${sortfield}","xparam1":"${sorting}"}`)
    }
  },
  showFarmasi (ids) {
    return Api.katalog.get(`${version}/master/practitioner/farmasi/${ids}?param0=farmasi`)
  },
  createFarmasi (data) {
    return Api.katalog.post(`${version}/master/practitioner/farmasi`, data)
  },
  editFarmasi (id, data) {
    return Api.katalog.put(`${version}/master/practitioner/farmasi/${id}`, data)
  },
  editKodeFarmasi (id, data) {
    return Api.katalog.put(`${version}/master/practitioner/farmasi/updateKode/${id}`, data)
  },
  deleteFarmasi (id) {
    return Api.katalog.delete(`${version}/master/practitioner/farmasi/${id}?param0=farmasi`)
  },
  // Pabrik-------------------------------------
  list (currentPage, perPage, filter, filterType, sortfield, sorting) {
    if (filter) {
      if (filterType === '1') {
        return Api.katalog.get(`${version}/master/practitioner/pabrik?param0=pabrik&param1=${currentPage}&param2=${perPage}&param3={"xparam0":"${filter}","xparam1":""}&param4={"xparam0":"${sortfield}","xparam1":"${sorting}"}`)
      } else if (filterType === '2') {
        return Api.katalog.get(`${version}/master/practitioner/pabrik?param0=pabrik&param1=${currentPage}&param2=${perPage}&param3={"xparam0":"","xparam1":"${filter}"}&param4={"xparam0":"${sortfield}","xparam1":"${sorting}"}`)
      }
    } else {
      return Api.katalog.get(`${version}/master/practitioner/pabrik?param0=pabrik&param1=${currentPage}&param2=${perPage}&param3={"xparam0":"","xparam1":""}&param4={"xparam0":"${sortfield}","xparam1":"${sorting}"}`)
    }
  },
  show (ids) {
    return Api.katalog.get(`${version}/master/practitioner/pabrik/${ids}?param0=pabrik`)
  },
  create (data) {
    return Api.katalog.post(`${version}/master/practitioner/pabrik`, data)
  },
  edit (id, data) {
    return Api.katalog.put(`${version}/master/practitioner/pabrik/${id}`, data)
  },
  delete (id) {
    return Api.katalog.delete(`${version}/master/practitioner/pabrik/${id}?param0=pabrik`)
  },
  // Kemasan-------------------------------------
  listKemasan (currentPage, perPage, filter, filterType, sortfield, sorting) {
    if (filter) {
      if (filterType === '1') {
        return Api.katalog.get(`${version}/master/practitioner/kemasan?param0=kemasan&param1=${currentPage}&param2=${perPage}&param3={"xparam0":"${filter}","xparam1":""}&param4={"xparam0":"${sortfield}","xparam1":"${sorting}"}`)
      } else if (filterType === '2') {
        return Api.katalog.get(`${version}/master/practitioner/kemasan?param0=kemasan&param1=${currentPage}&param2=${perPage}&param3={"xparam0":"","xparam1":"${filter}"}&param4={"xparam0":"${sortfield}","xparam1":"${sorting}"}`)
      }
    } else {
      return Api.katalog.get(`${version}/master/practitioner/kemasan?param0=kemasan&param1=${currentPage}&param2=${perPage}&param3={"xparam0":"","xparam1":""}&param4={"xparam0":"${sortfield}","xparam1":"${sorting}"}`)
    }
  },
  showKemasan (ids) {
    return Api.katalog.get(`${version}/master/practitioner/kemasan/${ids}?param0=kemasan`)
  },
  createKemasan (data) {
    return Api.katalog.post(`${version}/master/practitioner/kemasan`, data)
  },
  editKemasan (id, data) {
    return Api.katalog.put(`${version}/master/practitioner/kemasan/${id}`, data)
  },
  deleteKemasan (id) {
    return Api.katalog.delete(`${version}/master/practitioner/kemasan/${id}?param0=kemasan`)
  },
  // Pbf-------------------------------------
  listPbf (currentPage, perPage, filter, filterType, sortfield, sorting) {
    if (filter) {
      if (filterType === '1') {
        return Api.katalog.get(`${version}/master/practitioner/pbf?param0=pbf&param1=${currentPage}&param2=${perPage}&param3={"xparam0":"${filter}","xparam1":""}&param4={"xparam0":"${sortfield}","xparam1":"${sorting}"}`)
      } else if (filterType === '2') {
        return Api.katalog.get(`${version}/master/practitioner/pbf?param0=pbf&param1=${currentPage}&param2=${perPage}&param3={"xparam0":"","xparam1":"${filter}"}&param4={"xparam0":"${sortfield}","xparam1":"${sorting}"}`)
      }
    } else {
      return Api.katalog.get(`${version}/master/practitioner/pbf?param0=pbf&param1=${currentPage}&param2=${perPage}&param3={"xparam0":"","xparam1":""}&param4={"xparam0":"${sortfield}","xparam1":"${sorting}"}`)
    }
  },
  showPbf (ids) {
    return Api.katalog.get(`${version}/master/practitioner/pbf/${ids}?param0=pbf`)
  },
  createPbf (data) {
    return Api.katalog.post(`${version}/master/practitioner/pbf`, data)
  },
  editPbf (id, data) {
    return Api.katalog.put(`${version}/master/practitioner/pbf/${id}`, data)
  },
  deletePbf (id) {
    return Api.katalog.delete(`${version}/master/practitioner/pbf/${id}?param0=pbf`)
  },
  // Generik-------------------------------------
  listGenerik (currentPage, perPage, filter, filterType, sortfield, sorting) {
    if (filter) {
      if (filterType === '1') {
        return Api.katalog.get(`${version}/master/practitioner/generik?param0=generik&param1=${currentPage}&param2=${perPage}&param3={"xparam0":"${filter}","xparam1":""}&param4={"xparam0":"${sortfield}","xparam1":"${sorting}"}`)
      } else if (filterType === '2') {
        return Api.katalog.get(`${version}/master/practitioner/generik?param0=generik&param1=${currentPage}&param2=${perPage}&param3={"xparam0":"","xparam1":"${filter}"}&param4={"xparam0":"${sortfield}","xparam1":"${sorting}"}`)
      }
    } else {
      return Api.katalog.get(`${version}/master/practitioner/generik?param0=generik&param1=${currentPage}&param2=${perPage}&param3={"xparam0":"","xparam1":""}&param4={"xparam0":"${sortfield}","xparam1":"${sorting}"}`)
    }
  },
  showGenerik (ids) {
    return Api.katalog.get(`${version}/master/practitioner/generik/${ids}?param0=generik`)
  },
  createGenerik (data) {
    return Api.katalog.post(`${version}/master/practitioner/generik`, data)
  },
  editGenerik (id, data) {
    return Api.katalog.put(`${version}/master/practitioner/generik/${id}`, data)
  },
  deleteGenerik (id) {
    return Api.katalog.delete(`${version}/master/practitioner/generik/${id}?param0=generik`)
  },
  // Kelompok-------------------------------------
  listKelompok (currentPage, perPage, filter, filterType, sortfield, sorting) {
    if (filter) {
      if (filterType === '1') {
        return Api.katalog.get(`${version}/master/practitioner/kelompok?param0=kelompok&param1=${currentPage}&param2=${perPage}&param3={"xparam0":"${filter}","xparam1":""}&param4={"xparam0":"${sortfield}","xparam1":"${sorting}"}`)
      } else if (filterType === '2') {
        return Api.katalog.get(`${version}/master/practitioner/kelompok?param0=kelompok&param1=${currentPage}&param2=${perPage}&param3={"xparam0":"","xparam1":"${filter}"}&param4={"xparam0":"${sortfield}","xparam1":"${sorting}"}`)
      }
    } else {
      return Api.katalog.get(`${version}/master/practitioner/kelompok?param0=kelompok&param1=${currentPage}&param2=${perPage}&param3={"xparam0":"","xparam1":""}&param4={"xparam0":"${sortfield}","xparam1":"${sorting}"}`)
    }
  },
  showKelompok (ids) {
    return Api.katalog.get(`${version}/master/practitioner/kelompok/${ids}?param0=kelompok`)
  },
  createKelompok (data) {
    return Api.katalog.post(`${version}/master/practitioner/kelompok`, data)
  },
  editKelompok (id, data) {
    return Api.katalog.put(`${version}/master/practitioner/kelompok/${id}`, data)
  },
  deleteKelompok (id) {
    return Api.katalog.delete(`${version}/master/practitioner/kelompok/${id}?param0=kelompok`)
  },
  // Brand-------------------------------------
  listBrand (currentPage, perPage, filter, filterType, sortfield, sorting) {
    if (filter) {
      if (filterType === '1') {
        return Api.katalog.get(`${version}/master/practitioner/brand?param0=brand&param1=${currentPage}&param2=${perPage}&param3={"xparam0":"${filter}","xparam1":""}&param4={"xparam0":"${sortfield}","xparam1":"${sorting}"}`)
      } else if (filterType === '2') {
        return Api.katalog.get(`${version}/master/practitioner/brand?param0=brand&param1=${currentPage}&param2=${perPage}&param3={"xparam0":"","xparam1":"${filter}"}&param4={"xparam0":"${sortfield}","xparam1":"${sorting}"}`)
      }
    } else {
      return Api.katalog.get(`${version}/master/practitioner/brand?param0=brand&param1=${currentPage}&param2=${perPage}&param3={"xparam0":"","xparam1":""}&param4={"xparam0":"${sortfield}","xparam1":"${sorting}"}`)
    }
  },
  showBrand (ids) {
    return Api.katalog.get(`${version}/master/practitioner/brand/${ids}?param0=brand`)
  },
  createBrand (data) {
    return Api.katalog.post(`${version}/master/practitioner/brand`, data)
  },
  editBrand (id, data) {
    return Api.katalog.put(`${version}/master/practitioner/brand/${id}`, data)
  },
  deleteBrand (id) {
    return Api.katalog.delete(`${version}/master/practitioner/brand/${id}?param0=brand`)
  },
  // Buffer Gudang-----------------------------------------
  listBuffer (currentPage, perPage, filter, filterType, sortfield, sorting) {
    if (filter) {
      if (filterType === '1') {
        return Api.katalog.get(`${version}/master/practitioner/buffer-gudang?param0=buffer-gudang&param1=${currentPage}&param2=${perPage}&param3={"xparam0":"${filter}","xparam1":""}&param4={"xparam0":"${sortfield}","xparam1":"${sorting}"}`)
      } else if (filterType === '2') {
        return Api.katalog.get(`${version}/master/practitioner/buffer-gudang?param0=buffer-gudang&param1=${currentPage}&param2=${perPage}&param3={"xparam0":"","xparam1":"${filter}"}&param4={"xparam0":"${sortfield}","xparam1":"${sorting}"}`)
      }
    } else {
      return Api.katalog.get(`${version}/master/practitioner/buffer-gudang?param0=buffer-gudang&param1=${currentPage}&param2=${perPage}&param3={"xparam0":"","xparam1":""}&param4={"xparam0":"${sortfield}","xparam1":"${sorting}"}`)
    }
  },
  showBuffer (ids) {
    return Api.katalog.get(`${version}/master/practitioner/buffer-gudang/${ids}?param0=buffer-gudang`)
  },
  createBuffer (data) {
    return Api.katalog.post(`${version}/master/practitioner/buffer-gudang`, data)
  },
  editBuffer (id, data) {
    return Api.katalog.put(`${version}/master/practitioner/buffer-gudang/${id}`, data)
  },
  deleteBuffer (id) {
    return Api.katalog.delete(`${version}/master/practitioner/buffer-gudang/${id}?param0=buffer-gudang`)
  },
  // Jenis Anggaran-----------------------------------------
  listJenisAnggaran (currentPage, perPage, filter, filterType, sortfield, sorting) {
    if (filter) {
      if (filterType === '1') {
        return Api.katalog.get(`${version}/master/practitioner/jenis-anggaran?param0=jenis-anggaran&param1=${currentPage}&param2=${perPage}&param3={"xparam0":"${filter}","xparam1":""}&param4={"xparam0":"${sortfield}","xparam1":"${sorting}"}`)
      } else if (filterType === '2') {
        return Api.katalog.get(`${version}/master/practitioner/jenis-anggaran?param0=jenis-anggaran&param1=${currentPage}&param2=${perPage}&param3={"xparam0":"","xparam1":"${filter}"}&param4={"xparam0":"${sortfield}","xparam1":"${sorting}"}`)
      }
    } else {
      return Api.katalog.get(`${version}/master/practitioner/jenis-anggaran?param0=jenis-anggaran&param1=${currentPage}&param2=${perPage}&param3={"xparam0":"","xparam1":""}&param4={"xparam0":"${sortfield}","xparam1":"${sorting}"}`)
    }
  },
  showJenisAnggaran (ids) {
    return Api.katalog.get(`${version}/master/practitioner/jenis-anggaran/${ids}?param0=jenis-anggaran`)
  },
  createJenisAnggaran (data) {
    return Api.katalog.post(`${version}/master/practitioner/jenis-anggaran`, data)
  },
  editJenisAnggaran (id, data) {
    return Api.katalog.put(`${version}/master/practitioner/jenis-anggaran/${id}`, data)
  },
  deleteJenisAnggaran (id) {
    return Api.katalog.delete(`${version}/master/practitioner/jenis-anggaran/${id}?param0=jenis-anggaran`)
  },
  // Sub Jenis Anggaran-----------------------------------------
  listSubJenisAnggaran (currentPage, perPage, filter, filterType, sortfield, sorting) {
    if (filter) {
      if (filterType === '1') {
        return Api.katalog.get(`${version}/master/practitioner/sub-jenis-anggaran?param0=sub-jenis-anggaran&param1=${currentPage}&param2=${perPage}&param3={"xparam0":"${filter}","xparam1":""}&param4={"xparam0":"${sortfield}","xparam1":"${sorting}"}`)
      } else if (filterType === '2') {
        return Api.katalog.get(`${version}/master/practitioner/sub-jenis-anggaran?param0=sub-jenis-anggaran&param1=${currentPage}&param2=${perPage}&param3={"xparam0":"","xparam1":"${filter}"}&param4={"xparam0":"${sortfield}","xparam1":"${sorting}"}`)
      }
    } else {
      return Api.katalog.get(`${version}/master/practitioner/sub-jenis-anggaran?param0=sub-jenis-anggaran&param1=${currentPage}&param2=${perPage}&param3={"xparam0":"","xparam1":""}&param4={"xparam0":"${sortfield}","xparam1":"${sorting}"}`)
    }
  },
  showSubJenisAnggaran (ids) {
    return Api.katalog.get(`${version}/master/practitioner/sub-jenis-anggaran/${ids}?param0=sub-jenis-anggaran`)
  },
  createSubJenisAnggaran (data) {
    return Api.katalog.post(`${version}/master/practitioner/sub-jenis-anggaran`, data)
  },
  editSubJenisAnggaran (id, data) {
    return Api.katalog.put(`${version}/master/practitioner/sub-jenis-anggaran/${id}`, data)
  },
  deleteSubJenisAnggaran (id) {
    return Api.katalog.delete(`${version}/master/practitioner/sub-jenis-anggaran/${id}?param0=sub-jenis-anggaran`)
  },
  // Sakti-----------------------------------------
  listSakti (currentPage, perPage, filter, filterType, sortfield, sorting) {
    if (filter) {
      if (filterType === '1') {
        return Api.katalog.get(`${version}/master/practitioner/sakti?param0=sakti&param1=${currentPage}&param2=${perPage}&param3={"xparam0":"${filter}","xparam1":""}&param4={"xparam0":"${sortfield}","xparam1":"${sorting}"}`)
      } else if (filterType === '2') {
        return Api.katalog.get(`${version}/master/practitioner/sakti?param0=sakti&param1=${currentPage}&param2=${perPage}&param3={"xparam0":"","xparam1":"${filter}"}&param4={"xparam0":"${sortfield}","xparam1":"${sorting}"}`)
      }
    } else {
      return Api.katalog.get(`${version}/master/practitioner/sakti?param0=sakti&param1=${currentPage}&param2=${perPage}&param3={"xparam0":"","xparam1":""}&param4={"xparam0":"${sortfield}","xparam1":"${sorting}"}`)
    }
  },
  showSakti (ids) {
    return Api.katalog.get(`${version}/master/practitioner/sakti/${ids}?param0=sakti`)
  },
  createSakti (data) {
    return Api.katalog.post(`${version}/master/practitioner/sakti`, data)
  },
  editSakti (id, data) {
    return Api.katalog.put(`${version}/master/practitioner/sakti/${id}`, data)
  },
  deleteSakti (id) {
    return Api.katalog.delete(`${version}/master/practitioner/sakti/${id}?param0=sakti`)
  },
  // Sakti HDR-----------------------------------------
  listSaktiHdr (currentPage, perPage, filter, filterType, sortfield, sorting) {
    if (filter) {
      if (filterType === '1') {
        return Api.katalog.get(`${version}/master/practitioner/sakti-hdr?param0=sakti-hdr&param1=${currentPage}&param2=${perPage}&param3={"xparam0":"${filter}","xparam1":""}&param4={"xparam0":"${sortfield}","xparam1":"${sorting}"}`)
      } else if (filterType === '2') {
        return Api.katalog.get(`${version}/master/practitioner/sakti-hdr?param0=sakti-hdr&param1=${currentPage}&param2=${perPage}&param3={"xparam0":"","xparam1":"${filter}"}&param4={"xparam0":"${sortfield}","xparam1":"${sorting}"}`)
      }
    } else {
      return Api.katalog.get(`${version}/master/practitioner/sakti-hdr?param0=sakti-hdr&param1=${currentPage}&param2=${perPage}&param3={"xparam0":"","xparam1":""}&param4={"xparam0":"${sortfield}","xparam1":"${sorting}"}`)
    }
  },
  showSaktiHdr (ids) {
    return Api.katalog.get(`${version}/master/practitioner/sakti-hdr/${ids}?param0=sakti-hdr`)
  },
  createSaktiHdr (data) {
    return Api.katalog.post(`${version}/master/practitioner/sakti-hdr`, data)
  },
  editSaktiHdr (id, data) {
    return Api.katalog.put(`${version}/master/practitioner/sakti-hdr/${id}`, data)
  },
  deleteSaktiHdr (id) {
    return Api.katalog.delete(`${version}/master/practitioner/sakti-hdr/${id}?param0=sakti-hdr`)
  },
  // Dosis-----------------------------------------
  listDosis (currentPage, perPage, filter, filterType, sortfield, sorting) {
    if (filter) {
      if (filterType === '1') {
        return Api.katalog.get(`${version}/master/practitioner/dosis?param0=dosis&param1=${currentPage}&param2=${perPage}&param3={"xparam0":"${filter}","xparam1":""}&param4={"xparam0":"${sortfield}","xparam1":"${sorting}"}`)
      } else if (filterType === '2') {
        return Api.katalog.get(`${version}/master/practitioner/dosis?param0=dosis&param1=${currentPage}&param2=${perPage}&param3={"xparam0":"","xparam1":"${filter}"}&param4={"xparam0":"${sortfield}","xparam1":"${sorting}"}`)
      }
    } else {
      return Api.katalog.get(`${version}/master/practitioner/dosis?param0=dosis&param1=${currentPage}&param2=${perPage}&param3={"xparam0":"","xparam1":""}&param4={"xparam0":"${sortfield}","xparam1":"${sorting}"}`)
    }
  },
  showDosis (ids) {
    return Api.katalog.get(`${version}/master/practitioner/dosis/${ids}?param0=dosis`)
  },
  createDosis (data) {
    return Api.katalog.post(`${version}/master/practitioner/dosis`, data)
  },
  editDosis (id, data) {
    return Api.katalog.put(`${version}/master/practitioner/dosis/${id}`, data)
  },
  deleteDosis (id) {
    return Api.katalog.delete(`${version}/master/practitioner/dosis/${id}?param0=dosis`)
  },
  // Selectize-------------------------------------
  getJenis () {
    return Api.katalog.get(`${version}/master/practitioner/selectize?param0=jenis-anggaran`)
  },
  getSubJenis () {
    return Api.katalog.get(`${version}/master/practitioner/selectize?param0=jenis-anggaran-sub`)
  },
  getKelompok () {
    return Api.katalog.get(`${version}/master/practitioner/selectize?param0=kelompok`)
  },
  getGenerik () {
    return Api.katalog.get(`${version}/master/practitioner/selectize?param0=generik`)
  },
  getBrand () {
    return Api.katalog.get(`${version}/master/practitioner/selectize?param0=brand`)
  },
  getPabrik () {
    return Api.katalog.get(`${version}/master/practitioner/selectize?param0=pabrik`)
  },
  getKemasan () {
    return Api.katalog.get(`${version}/master/practitioner/selectize?param0=kemasan`)
  },
  getPbf () {
    return Api.katalog.get(`${version}/master/practitioner/selectize?param0=pbf`)
  },
  kfa91 () {
    return Api.katalog.get(`${version}/master/practitioner/selectize?param0=kfa91`)
  },
  kfa92 () {
    return Api.katalog.get(`${version}/master/practitioner/selectize?param0=kfa92`)
  },
  kfa93 () {
    return Api.katalog.get(`${version}/master/practitioner/selectize?param0=kfa93`)
  },
  getSakti () {
    return Api.katalog.get(`${version}/master/practitioner/selectize?param0=sakti`)
  },
  getSaktiHdr () {
    return Api.katalog.get(`${version}/master/practitioner/selectize?param0=sakti-hdr`)
  },
  getDosis () {
    return Api.katalog.get(`${version}/master/practitioner/selectize?param0=dosis`)
  },
  getHardCode () {
    return Api.katalog.get(`${version}/master/practitioner/selectize?param0=hardcode`)
  },
  getKategori () {
    return Api.katalog.get(`${version}/master/practitioner/selectize?param0=kategori`)
  },
  getInvKelmpk () {
    return Api.katalog.get(`${version}/master/practitioner/selectize?param0=kelompok-inv`)
  },
  suggest (xparam0, xparam1) {
    return Api.katalog.get(`${version}/master/practitioner/sugest?param0=sugest&param1={"xparam0":"${xparam0}","xparam1":"${xparam1}"}`)
  },
  validasi (xparam0, xparam1) {
    return Api.katalog.get(`${version}/master/practitioner/sugest?param0=validasi&param1={"xparam0":"${xparam0}","xparam1":"${xparam1}"}`)
  },
  getJnsRekanan () {
    return Api.katalog.get(`${version}/master/practitioner/selectize?param0=jenis-rekanan`)
  },
  // Peminjaman Barang-----------------------------------------
  listPinjamBarang (currentPage, perPage, filter, filterType, sortfield, sorting) {
    if (filter) {
      if (filterType === '1') {
        return Api.katalog.get(`${version}/transaksi/practitioner/invPinjam?param0=inv-pinjam&param1=${currentPage}&param2=${perPage}&param3={"xparam0":"${filter}","xparam1":""}&param4={"xparam0":"${sortfield}","xparam1":"${sorting}"}`)
      } else if (filterType === '2') {
        return Api.katalog.get(`${version}/transaksi/practitioner/invPinjam?param0=inv-pinjam&param1=${currentPage}&param2=${perPage}&param3={"xparam0":"","xparam1":"${filter}"}&param4={"xparam0":"${sortfield}","xparam1":"${sorting}"}`)
      }
    } else {
      return Api.katalog.get(`${version}/transaksi/practitioner/invPinjam?param0=inv-pinjam&param1=${currentPage}&param2=${perPage}&param3={"xparam0":"","xparam1":""}&param4={"xparam0":"${sortfield}","xparam1":"${sorting}"}`)
    }
  },
  createPinjamBarang (data) {
    return Api.katalog.post(`${version}/transaksi/practitioner/invPinjam`, data)
  },
  editPinjamBarang (id, data) {
    return Api.katalog.put(`${version}/transaksi/practitioner/invPinjam/${id}`, data)
  },
  showPinjamBarang (ids) {
    return Api.katalog.get(`${version}/transaksi/practitioner/invPinjam/${ids}?param0=inv-pinjam`)
  },
  getFarmasi () {
    return Api.katalog.get(`${version}/master/practitioner/selectize?param0=farmasi`)
  },
  editPinjamanBarang (id, data) {
    return Api.katalog.put(`${version}/transaksi/practitioner/invPinjam/${id}`, data)
  },
  // Pengembalian Barang-----------------------------------------
  listPengembalianBarang (currentPage, perPage, filter, filterType, sortfield, sorting) {
    if (filter) {
      if (filterType === '1') {
        return Api.katalog.get(`${version}/transaksi/practitioner/invKembali?param0=inv-kembali&param1=${currentPage}&param2=${perPage}&param3={"xparam0":"${filter}","xparam1":""}&param4={"xparam0":"${sortfield}","xparam1":"${sorting}"}`)
      } else if (filterType === '2') {
        return Api.katalog.get(`${version}/transaksi/practitioner/invKembali?param0=inv-kembali&param1=${currentPage}&param2=${perPage}&param3={"xparam0":"","xparam1":"${filter}"}&param4={"xparam0":"${sortfield}","xparam1":"${sorting}"}`)
      }
    } else {
      return Api.katalog.get(`${version}/transaksi/practitioner/invKembali?param0=inv-kembali&param1=${currentPage}&param2=${perPage}&param3={"xparam0":"","xparam1":""}&param4={"xparam0":"${sortfield}","xparam1":"${sorting}"}`)
    }
  },
  showPengembalianBarang (ids) {
    return Api.katalog.get(`${version}/transaksi/practitioner/invKembali/${ids}?param0=inv-kembali`)
  },
  editPengembalianBarang (id, data) {
    return Api.katalog.put(`${version}/transaksi/practitioner/invKembali/${id}`, data)
  },
  listPengembalianBarangPj (currentPage, perPage, filter, filterType, sortfield, sorting) {
    if (filter) {
      if (filterType === '1') {
        return Api.katalog.get(`${version}/transaksi/practitioner/invKembali?param0=inv-kembali-to-pinjam&param1=${currentPage}&param2=${perPage}&param3={"xparam0":"${filter}","xparam1":""}&param4={"xparam0":"${sortfield}","xparam1":"${sorting}"}`)
      } else if (filterType === '2') {
        return Api.katalog.get(`${version}/transaksi/practitioner/invKembali?param0=inv-kembali-to-pinjam&param1=${currentPage}&param2=${perPage}&param3={"xparam0":"","xparam1":"${filter}"}&param4={"xparam0":"${sortfield}","xparam1":"${sorting}"}`)
      }
    } else {
      return Api.katalog.get(`${version}/transaksi/practitioner/invKembali?param0=inv-kembali-to-pinjam&param1=${currentPage}&param2=${perPage}&param3={"xparam0":"","xparam1":""}&param4={"xparam0":"${sortfield}","xparam1":"${sorting}"}`)
    }
  },
  showPengembalianBarangPj (ids) {
    return Api.katalog.get(`${version}/transaksi/practitioner/invKembali/${ids}?param0=inv-kembali-on-pinjam`)
  },
  createPengembalianBarangPj (data) {
    return Api.katalog.post(`${version}/transaksi/practitioner/invPinjam`, data)
  },
  // Stok Opname-----------------------------------------
  getRuangStokOP () {
    return Api.katalog.get(`${version}/master/practitioner/selectize?param0=ruangan-stok-opname`)
  },
  getHargaStokOP (id) {
    return Api.katalog.get(`${version}/master/practitioner/sugest?param0=harga&param1={"xparam0":"${id}","xparam1":""}`)
  },
  getPabrikStokOP () {
    return Api.katalog.get(`${version}/master/practitioner/selectize?param0=pabrik-stok-opname`)
  },
  getPabrikStokOP_v2 (filter) {
    return Api.katalog.get(`${version}/transaksi/practitioner/selectize?param0=pabrik-stok-opname&param1={"xparam0":"${filter}","xparam1":"","xparam2":"","xparam3":"","xparam4":""}`)
  },
  listStokOpname (currentPage, perPage, filter, filterType, sortfield, sorting, userid) {
    if (filter) {
      if (filterType === '1') {
        return Api.katalog.get(`${version}/transaksi/practitioner/stokOpname?param0=stok-opname&param1=${currentPage}&param2=${perPage}&param3={"xparam0":"${filter}","xparam1":"","xparam2":"","xparam3":"${userid}","xparam4":""}&param4={"xparam0":"${sortfield}","xparam1":"${sorting}"}`)
      } else if (filterType === '2') {
        return Api.katalog.get(`${version}/transaksi/practitioner/stokOpname?param0=stok-opname&param1=${currentPage}&param2=${perPage}&param3={"xparam0":"","xparam1":"${filter}","xparam2":"","xparam3":"${userid}","xparam4":""}&param4={"xparam0":"${sortfield}","xparam1":"${sorting}"}`)
      } else if (filterType === '3') {
        return Api.katalog.get(`${version}/transaksi/practitioner/stokOpname?param0=stok-opname&param1=${currentPage}&param2=${perPage}&param3={"xparam0":"","xparam1":"","xparam2":"${filter}","xparam3":"${userid}","xparam4":""}&param4={"xparam0":"${sortfield}","xparam1":"${sorting}"}`)
      }
    } else {
      return Api.katalog.get(`${version}/transaksi/practitioner/stokOpname?param0=stok-opname&param1=${currentPage}&param2=${perPage}&param3={"xparam0":"","xparam1":"","xparam2":"","xparam3":"${userid}","xparam4":""}&param4={"xparam0":"${sortfield}","xparam1":"${sorting}"}`)
    }
  },
  showStockOpname (id, curpage, perpage, userid, filtertype, ctxfilter, obj) {
    if (ctxfilter) {
      if (filtertype === '1') {
        return Api.katalog.get(`${version}/transaksi/practitioner/stokOpname/${id}?param0=stok-opname-${obj}&param1=${curpage}&param2=${perpage}&param3={"xparam0":"${userid}","xparam1":"${ctxfilter}","xparam2":"","xparam3":"","xparam4":""}`)
      } else if (filtertype === '2') {
        return Api.katalog.get(`${version}/transaksi/practitioner/stokOpname/${id}?param0=stok-opname-${obj}&param1=${curpage}&param2=${perpage}&param3={"xparam0":"${userid}","xparam1":"","xparam2":"${ctxfilter}","xparam3":"","xparam4":""}`)
      }
    } else {
      return Api.katalog.get(`${version}/transaksi/practitioner/stokOpname/${id}?param0=stok-opname-${obj}&param1=${curpage}&param2=${perpage}&param3={"xparam0":"${userid}","xparam1":"","xparam2":"","xparam3":"","xparam4":""}`)
    }
  },
  createStockOpname (data) {
    return Api.katalog.post(`${version}/transaksi/practitioner/stokOpname`, data)
  },
  updateStockOpname (id, data) {
    return Api.katalog.put(`${version}/transaksi/practitioner/stokOpname/${id}`, data)
  },
  deleteStockOpname (id) {
    return Api.katalog.delete(`${version}/transaksi/practitioner/stokOpname/${id}?param0=stok-opname-detail`)
  },
  close (id, data) {
    return Api.katalog.put(`${version}/transaksi/practitioner/stokOpname/${id}`, data)
  },
  //
  listStokPending (currentPage, perPage, filter, filterType, filterType2, sortfield, sorting, userid) {
    if (filter) {
      if (filterType2 === '1') {
        return Api.katalog.get(`${version}/transaksi/practitioner/pending?param0=pending&param1=${currentPage}&param2=${perPage}&param3={"xparam0":"${filter}","xparam1":"${filterType}","xparam2":"${userid}","xparam3":"","xparam4":""}&param4={"xparam0":"${sortfield}","xparam1":"${sorting}"}`)
      } else if (filterType2 === '2') {
        return Api.katalog.get(`${version}/transaksi/practitioner/pending?param0=pending&param1=${currentPage}&param2=${perPage}&param3={"xparam0":"","xparam1":"${filterType}","xparam2":"${userid}","xparam3":"${filter}","xparam4":""}&param4={"xparam0":"${sortfield}","xparam1":"${sorting}"}`)
      } else if (filterType2 === '3') {
        return Api.katalog.get(`${version}/transaksi/practitioner/pending?param0=pending&param1=${currentPage}&param2=${perPage}&param3={"xparam0":"","xparam1":"${filterType}","xparam2":"${userid}","xparam3":"","xparam4":"${filter}"}&param4={"xparam0":"${sortfield}","xparam1":"${sorting}"}`)
      }
    } else {
      return Api.katalog.get(`${version}/transaksi/practitioner/pending?param0=pending&param1=${currentPage}&param2=${perPage}&param3={"xparam0":"","xparam1":"${filterType}","xparam2":"${userid}","xparam3":"","xparam4":""}&param4={"xparam0":"${sortfield}","xparam1":"${sorting}"}`)
    }
  },
  showStokPending (id) {
    return Api.katalog.get(`${version}/transaksi/practitioner/pending/${id}?param0=pending`)
  },
  updatePending (id, data) {
    return Api.katalog.put(`${version}/transaksi/practitioner/pending/${id}`, data)
  },
  //
  listIterasi (currentPage, perPage, filter, filterType, sortfield, sorting, userid, obat, norm, tglawal, tglakhir) {
    if (filter) {
      return Api.katalog.get(`${version}/transaksi/practitioner/pending?param0=pending-add&param1=${currentPage}&param2=${perPage}&param3={"xparam0":"${obat}","xparam1":"${norm}","xparam2":"${userid}","xparam3":"${tglawal}","xparam4":"${tglakhir}"}&param4={"xparam0":"${sortfield}","xparam1":"${sorting}"}`)
    } else {
      return Api.katalog.get(`${version}/transaksi/practitioner/pending?param0=pending-add&param1=${currentPage}&param2=${perPage}&param3={"xparam0":"${obat}","xparam1":"${norm}","xparam2":"${userid}","xparam3":"${tglawal}","xparam4":"${tglakhir}"}&param4={"xparam0":"${sortfield}","xparam1":"${sorting}"}`)
    }
  },
  showIter (value) {
    return Api.katalog.get(`${version}/transaksi/practitioner/pending/${value}?param0=pending-add`)
  },
  updateIter (id, data) {
    return Api.katalog.put(`${version}/transaksi/practitioner/pending/${id}`, data)
  },
  updateIterTable (id, data) {
    return Api.katalog.put(`${version}/transaksi/practitioner/pending/${id}`, data)
  },
  createEmpty (data) {
    return Api.katalog.post(`${version}/transaksi/practitioner/pending`, data)
  },
  // Penerimaan Rumah Tangga-----------------------------------------
  listPenerimaan (currentPage, perPage, param3, param4) {
    return Api.katalog.get(`${version}/transaksi/practitioner/penerimaan?param0=penerimaan-rumah-tangga&param1=${currentPage}&param2=${perPage}&param3=${param3}&param4=${param4}`)
  },
  createPenerimaan (data) {
    return Api.katalog.post(`${version}/transaksi/practitioner/penerimaan`, data)
  },
  updatePenerimaan (id, data) {
    return Api.katalog.put(`${version}/transaksi/practitioner/penerimaan/${id}`, data)
  },
  softDeletePenerimaan (id, data) {
    return Api.katalog.patch(`${version}/transaksi/practitioner/penerimaan/${id}`, data)
  },
  selectizePenerimaan (param0) {
    return Api.katalog.get(`${version}/master/practitioner/selectize?param0=${param0}`)
  },
  selectizePenerimaanTable (xparam0) {
    return Api.katalog.get(`${version}/transaksi/practitioner/selectize?param0=barang-rt&param1={"xparam0":"${xparam0}","xparam1":"","xparam2":"","xparam3":"","xparam4":""}`)
  },
  showPenerimaan (id) {
    return Api.katalog.get(`${version}/transaksi/practitioner/penerimaan/${id}?param0=penerimaan-rumah-tangga`)
  },
  cetakPenerimaan (id) {
    return Api.katalog.get(`${version}/transaksi/practitioner/report?param0=penerimaan-rumah-tangga&param1={"xparam0":"${id}","xparam1":"","xparam2":"","xparam3":"","xparam4":"","xparam5":"","xparam6":"","xparam7":"","xparam8":"","xparam9":""}`)
  },
  // ---------------------------------------------------------------------
  getKelompokRtAll (currentPage, perPage, filter, sorting) {
    return Api.katalog.get(`${version}/master/practitioner/katalog?param0=kelompok-rt&param1=${currentPage}&param2=${perPage}&param3=${filter}&param4=${sorting}`)
  },
  saveKelompokRt (data) {
    return Api.katalog.post(`${version}/master/practitioner/katalog`, data)
  },
  updateKelompokRt (id, data) {
    return Api.katalog.put(`${version}/master/practitioner/katalog/${id}`, data)
  },
  getKemasanAll (currentPage, perPage, filter, sorting) {
    return Api.katalog.get(`${version}/master/practitioner/katalog?param0=kemasan-rt&param1=${currentPage}&param2=${perPage}&param3=${filter}&param4=${sorting}`)
  },
  saveKemasanRt (data) {
    return Api.katalog.post(`${version}/master/practitioner/katalog`, data)
  },
  updateKemasanRt (id, data) {
    return Api.katalog.put(`${version}/master/practitioner/katalog/${id}`, data)
  },
  // rumah tangga rekanan
  getRekananRtAll (currentPage, perPage, filter, sorting) {
    return Api.katalog.get(`${version}/master/practitioner/katalog?param0=rekanan&param1=${currentPage}&param2=${perPage}&param3=${filter}&param4=${sorting}`)
  },
  saveRekananRt (data) {
    return Api.katalog.post(`${version}/master/practitioner/katalog`, data)
  },
  updateRekananRt (id, data) {
    return Api.katalog.put(`${version}/master/practitioner/katalog/${id}`, data)
  },
  showMrtRekananRt (id) {
    return Api.katalog.get(`${version}/master/practitioner/katalog/${id}?param0=rekanan`)
  },
  // jenis anggaran Rumah Tangga -----------------------------------------
  listMrtjenisAnggaran (currentPage, perPage, filter, sorting) {
    return Api.katalog.get(`${version}/master/practitioner/katalog?param0=jenis-anggaran-rt&param1=${currentPage}&param2=${perPage}&param3=${filter}&param4=${sorting}`)
  },
  createMrtjenisAnggaran (data) {
    return Api.katalog.post(`${version}/master/practitioner/katalog`, data)
  },
  showMrtjenisAnggaran (id) {
    return Api.katalog.get(`${version}/master/practitioner/katalog/${id}?param0=jenis-anggaran-rt`)
  },
  updateMrtjenisAnggaran (id, data) {
    return Api.katalog.put(`${version}/master/practitioner/katalog/${id}`, data)
  },
  // jenis anggaran Rumah Tangga -----------------------------------------
  listMrtrumahtangga (currentPage, perPage, filter, sorting) {
    return Api.katalog.get(`${version}/master/practitioner/katalog?param0=rumah-tangga&param1=${currentPage}&param2=${perPage}&param3=${filter}&param4=${sorting}`)
  },
  createMrtRumahTangga (data) {
    return Api.katalog.post(`${version}/master/practitioner/katalog`, data)
  },
  updateMrtRumahTangga (id, data) {
    return Api.katalog.put(`${version}/master/practitioner/katalog/${id}`, data)
  },
  showMrtRumahTangga (id) {
    return Api.katalog.get(`${version}/master/practitioner/katalog/${id}?param0=rumah-tangga`)
  },
  getJenisAnggaran () {
    return Api.katalog.get(`${version}/master/practitioner/selectize?param0=jenis-anggaran-rt`)
  },
  getJenisKelompok () {
    return Api.katalog.get(`${version}/master/practitioner/selectize?param0=kelompok-rt`)
  },
  getJenisKemasanBesar () {
    return Api.katalog.get(`${version}/master/practitioner/selectize?param0=kemasan-rt`)
  },
  getJenisKemasanKecil () {
    return Api.katalog.get(`${version}/master/practitioner/selectize?param0=kemasan-rt`)
  }
}
