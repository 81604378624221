<template>
  <div>
    <b-container fluid>
      <iq-card class="px-4 py-4">
        <div class="mb-3">
          <p class="h5 text-dark font-weight-bold mb-0"><i class="fas fa-chart-bar"></i> LAPORAN CATH-LAB</p>
        </div>
        <b-table stacked="sm" class="border" striped hover id="my-table" :items="pasien" :filter="filter"
          :fields="fields" :per-page="perPage" :current-page="currentPage">
          <template v-slot:cell(tanggal)="data">
            <span>{{ data.item.tanggal | formatTgl }}</span>
          </template>
        </b-table>
        <div>
          <b-button @click="toDash" variant="primary"><i class="fas fa-undo"></i> Kembali</b-button>
        </div>
      </iq-card>
    </b-container>
  </div>
</template>
<script>
import { xray } from '../../../config/pluginInit'
import Lab from '../../../services/laboratorium'

export default {
  name: 'BlankPage',
  mounted () {
    xray.index()
    this.dash()
  },
  methods: {
    async dash () {
      await Lab.showDashboard().then(response => {
        this.pasien = response.data.data
      }).catch(error => {
        this.error = error.response.data.message
      })
    },
    toDash () {
      this.$router.push({ name: 'laboratorium.list' })
    }
  },
  data () {
    return {
      pasien: [],
      perPage: 5,
      currentPage: 1,
      rows: '',
      filter: '',
      error: '',
      fields: [
        { label: 'NORM', key: 'norm' },
        { label: 'NO. RESERVASI', key: 'nomor_reservasi' },
        { label: 'DOKTER', key: 'dokter' },
        { label: 'NOMOR KONTAK', key: 'nomor' },
        { label: 'TANGGAL', key: 'tanggal' }
      ]
    }
  }
}
</script>
