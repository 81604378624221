import Api from './fatmahost/axiosInstance'

const END_POINT = 'setMonitoringKlaim'
export default {
  all (page, data, params) {
    if (params !== null) {
      return Api.laravel.get(`getSearchMonitoringKlaim/?params=${params}&sort=desc`)
    } else {
      return Api.laravel.get(`${END_POINT}/?page=${page}${data}`)
    }
  },
  exportlist (data) {
    return Api.laravel.get(`getExportMonitoringKlaim/?${data}`)
  },
  show (id) {
    return Api.laravel.get(`getShowMonitoringKlaim/?nopen=${id}`)
  },
  // utility
  getRekon (tgl, ruang) {
    return Api.laravel.get(`getRekonKlaim/?tanggal=${tgl}&ruangan=${ruang}`)
  },
  storeImport1 (data) {
    return Api.laravel.post('importKlaimBahvPertama', data, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
  },
  storeImport2 (data) {
    return Api.laravel.post('importKlaimBahvKedua', data, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
  },
  storeImport3 (data) {
    return Api.laravel.post('importKlaimBahvKetiga', data, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
  },
  allImport (page, data, params) {
    if (params !== null) {
      return Api.laravel.get(`getIndexRekonKlaim/?params=${params}&sort=desc`)
    } else if (data !== '') {
      return Api.laravel.get(`getIndexPeriodRekonKlaim/?page=${page}${data}`)
    } else {
      return Api.laravel.get(`getIndexRekonKlaim/?page=${page}${data}`)
    }
  },
  // Public
  getShow () {
    return Api.laravel.get('getDashRekonKlaim')
  },
  getShowDetail (status, periode) {
    return Api.laravel.get(`getDashRincRekonKlaim/?status=${status}&periode=${periode}`)
  }
}
