<template>
  <b-container fluid>
    <b-row class="mb-3">
      <b-col>
        <iq-card class="h-100">
          <template v-slot:headerTitle>
            <h4 class="card-title">Personal Informasi</h4>
          </template>
          <template v-slot:body>
            <b-row>
              <b-col cols="12" lg="6">
                <b-row class="mb-3">
                  <b-col cols="3" lg="4">
                    <p class="h6 text-dark mb-0">Jenis Identitas</p>
                  </b-col>
                  <b-col cols="1" lg="1">
                    <p class="h6 text-dark mb-0">:</p>
                  </b-col>
                  <b-col>
                    <p class="h6 text-dark font-weight-bold mb-0">{{pasien.deskripsi_jenis}}</p>
                  </b-col>
                </b-row>
                <b-row class="mb-3">
                  <b-col cols="3" lg="4">
                    <p class="h6 text-dark mb-0">Nama Lengkap</p>
                  </b-col>
                  <b-col cols="1" lg="1">
                    <p class="h6 text-dark mb-0">:</p>
                  </b-col>
                  <b-col>
                    <p class="h6 text-dark font-weight-bold mb-0">{{pasien.nama}}</p>
                  </b-col>
                </b-row>
                <b-row class="mb-3">
                  <b-col cols="3" lg="4">
                    <p class="h6 text-dark mb-0">Jenis Kelamin</p>
                  </b-col>
                  <b-col cols="1" lg="1">
                    <p class="h6 text-dark mb-0">:</p>
                  </b-col>
                  <b-col>
                    <p class="h6 text-dark font-weight-bold mb-0">{{pasien.deskripsi_jenis_kelamin}}</p>
                  </b-col>
                </b-row>
                <b-row class="mb-3">
                  <b-col cols="3" lg="4">
                    <p class="h6 text-dark mb-0">Tanggal Lahir</p>
                  </b-col>
                  <b-col cols="1" lg="1">
                    <p class="h6 text-dark mb-0">:</p>
                  </b-col>
                  <b-col>
                    <p class="h6 text-dark font-weight-bold mb-0">{{pasien.tanggal_lahir}}</p>
                  </b-col>
                </b-row>
                <b-row class="mb-3">
                  <b-col cols="3" lg="4">
                    <p class="h6 text-dark mb-0">Alamat Tinggal</p>
                  </b-col>
                  <b-col cols="1" lg="1">
                    <p class="h6 text-dark mb-0">:</p>
                  </b-col>
                  <b-col>
                    <p class="h6 text-dark font-weight-bold mb-0">{{pasien.alamat}}</p>
                  </b-col>
                </b-row>
              </b-col>
              <b-col cols="12" lg="6">
                <b-row class="mb-3">
                  <b-col cols="3" lg="4">
                    <p class="h6 text-dark mb-0">Tempat Lahir</p>
                  </b-col>
                  <b-col cols="1" lg="1">
                    <p class="h6 text-dark mb-0">:</p>
                  </b-col>
                  <b-col>
                    <p class="h6 text-dark font-weight-bold mb-0">{{pasien.deskripsi_tempat_lahir}}</p>
                  </b-col>
                </b-row>
                <b-row class="mb-3">
                  <b-col cols="3" lg="4">
                    <p class="h6 text-dark mb-0">Nomor Handpone</p>
                  </b-col>
                  <b-col cols="1" lg="1">
                    <p class="h6 text-dark mb-0">:</p>
                  </b-col>
                  <b-col>
                    <p class="h6 text-dark font-weight-bold mb-0">{{pasien.nomor_kontak}}</p>
                  </b-col>
                </b-row>
                <b-row class="mb-3">
                  <b-col cols="3" lg="4">
                    <p class="h6 text-dark mb-0">Email</p>
                  </b-col>
                  <b-col cols="1" lg="1">
                    <p class="h6 text-dark mb-0">:</p>
                  </b-col>
                  <b-col>
                    <p class="h6 text-dark font-weight-bold mb-0">{{pasien.email}}</p>
                  </b-col>
                </b-row>
                <b-row class="mb-3">
                  <b-col cols="3" lg="4">
                    <p class="h6 text-dark mb-0">Ruangan</p>
                  </b-col>
                  <b-col cols="1" lg="1">
                    <p class="h6 text-dark mb-0">:</p>
                  </b-col>
                  <b-col>
                    <p class="h6 text-dark font-weight-bold mb-0">{{pasien.deskripsi_ruangan}}</p>
                  </b-col>
                </b-row>
                <b-row class="mb-3">
                  <b-col cols="3" lg="4">
                    <p class="h6 text-dark mb-0">Tanggal Reservasi</p>
                  </b-col>
                  <b-col cols="1" lg="1">
                    <p class="h6 text-dark mb-0">:</p>
                  </b-col>
                  <b-col>
                    <p class="h6 text-dark font-weight-bold mb-0">{{pasien.tanggal_reservasi}}</p>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
            <hr>
            <b-row class="justify-content-center mt-5">
              <b-col cols="12" md="6" lg="4">
                <b-form class="text-center">
                  <b-form-group>
                    <b-form-input id="exampleInputText1" type="text" v-model="data.paspor" placeholder="Masukan Passport"></b-form-input>
                  </b-form-group>
                  <b-button variant="success" @click="onSubmit" v-if="data.paspor == ''" disabled>Daftarkan Pasien</b-button>
                  <b-button variant="success" @click="onSubmit" v-else-if="data.paspor !== ''">Daftarkan Pasien</b-button>
                </b-form>
              </b-col>
            </b-row>
          </template>
        </iq-card>
      </b-col>
    </b-row>
    <b-row class="justify-content-center">
      <b-col md="2">
        <iq-card>
          <button type="button" @click="meningitisList" class="btn btn-primary btn-block">
            <i class="fas fa-undo"></i>  Kembali
          </button>
        </iq-card>
      </b-col>
    </b-row>
    <b-modal ref="modal-sukses" size="md" title="Informasi" ok-title="Oke" :ok-only=true @ok="backToList" :hide-header=true
        centered>
        <div class="text-center px-lg-4 px-2">
          <lottie :options="defaultOptions" :autoPlay="true" :width="250" :height="250" />
          <p class="h6 font-weight-bold text-success mb-0">Data Berhasil Disimpan</p>
        </div>
      </b-modal>
      <b-modal ref="modal-gagal" size="md" title="Informasi" ok-title="Kembali" :ok-only=true :hide-header=true
        centered>
        <div class="text-center px-lg-4 px-2">
          <p class="h6 text-danger font-weight-bold mb-0">Data Gagal Disimpan</p>
        </div>
      </b-modal>
  </b-container>
</template>
<script>
import { xray } from '../../../config/pluginInit'
import Meningitis from '../../../services/meningitis'
import Lottie from 'vue-lottie'
import animationLogo from '../../../assets/lottie/checked.json'
import animationLogo2 from '../../../assets/lottie/failed.json'
export default {
  name: 'MeningitisView',
  components: {
    Lottie
  },
  mounted () {
    xray.index()
    this.id = this.$route.params.id
    this.viewMeningitis(this.id)
  },
  methods: {
    meningitisList () {
      this.$router.push({ name: 'meningitis.list' })
    },
    async onSubmit (e) {
      const data = this.data
      try {
        const meningitisResponse = await Meningitis.store(data)
        if (meningitisResponse.status === 200) {
          this.$refs['modal-sukses'].show()
        } else {
          this.error = 'error'
        }
      } catch (err) {
        this.error = err
      }
    },
    async viewMeningitis (id) {
      await Meningitis.show(id).then(response => {
        if (response.status === 200) {
          this.pasien = response.data.data
          this.data.id = response.data.data.id
          this.data.paspor = response.data.data.paspor
        }
      }).catch(error => {
        this.error = error.response || error.response.status
      })
    },
    backToList () {
      this.$router.push({ name: 'meningitis.list' })
    }
  },
  data () {
    return {
      defaultOptions: { animationData: animationLogo, loop: true },
      defaultOptions2: { animationData: animationLogo2, loop: true },
      error: '',
      pasien: [],
      data: {
        id: null,
        paspor: '',
        status: 2
      }
    }
  }
}
</script>
