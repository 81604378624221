<template>
  <div>
    <div id="login-title-v2" class="mb-4">
      <div class="d-none d-lg-block">
        <div>
          <p class="h3 font-weight-bold kemenkes-color m-0 mb-1">RS Fatmawati</p>
        </div>
      </div>
      <div class="d-block d-lg-none mt-4 mb-2">
        <div class="text-center text-lg-left">
          <img :src="logokmks" class="img-fluid width-responsive" alt="logo">
        </div>
      </div>
      <p class="m-0 p-0 text-center text-lg-left">Login to your account</p>
    </div>
    <sign-in-form></sign-in-form>
  </div>
</template>

<script>
import SignInForm from './SignInFormNew'
import kmks from '../../assets/images/kmenkes_rsup_logo.svg'

export default {
  name: 'SignIn',
  components: { SignInForm },
  data: () => ({
    logokmks: kmks
  })
}
</script>

<style>
#login-title-v2 .kemenkes-color {
  color: #047d78 ;
}
</style>
