<template>
  <div id="laporan-mhas">
    <b-container fluid>
      <b-row class="">
        <b-col cols="12" lg="4" class="pl-0 pr-1">
          <div class="bg-white shadow-sm border h-100">
            <div class="pt-3 pb-3 px-3">
              <p class="text-dark h6 mb-0"><i class="fas fa-filter"></i> Filter Laporan</p>
            </div>
            <div class="pb-4 pt-4 px-3 bg-abu">
              <b-row class="align-items-center mb-2">
                <b-col>
                  <flat-pickr :config="config" @on-change="tglEvent($event, '1')" value="" id="Periode_awal"
                    placeholder="Periode Awal" class="w-100 custom-flatpicker calender-small bg-white">
                  </flat-pickr>
                </b-col>
              </b-row>
              <b-row class="align-items-center mb-2">
                <b-col>
                  <flat-pickr :config="config" @on-change="tglEvent($event, '2')" value="" id="Periode_ahkir"
                    placeholder="Periode Akhir" class="w-100 custom-flatpicker calender-small bg-white">
                  </flat-pickr>
                </b-col>
              </b-row>
              <b-row class="mb-2">
                <b-col sm="12">
                  <b-form-input hidden v-model="forms.idKasir" type="text" class="text-dark form-control-laporan"
                    placeholder="No. Id Kasir"></b-form-input>
                  <!-- <multiselect disabled id="carabayar" :show-labels="false" label="deskripsi" placeholder="Cara Bayar"
                        :searchable="true" :allow-empty="false" v-model="selected.carabayar" :options="options.carabayar"
                        @input="valuecarabayar">
                    </multiselect> -->
                </b-col>
              </b-row>
              <b-row class="mb-2">
                <b-col sm="2">
                </b-col>
                <b-col sm="12">
                  <multiselect disabled id="ruangan" :show-labels="false" label="deskripsi" track-by="id"
                    placeholder="Ruangan" :searchable="true" :allow-empty="false" v-model="selected.ruangan"
                    :options="options.ruangan" @input="valueruangan">
                  </multiselect>
                </b-col>
              </b-row>
              <b-row class="mb-2">
                <b-col sm="2">
                </b-col>
                <b-col sm="12">
                  <multiselect disabled id="ksm" :show-labels="false" label="name" track-by="id" placeholder="KSM"
                    :searchable="true" :allow-empty="false" v-model="selected.ksm" :options="options.ksm">
                  </multiselect>
                </b-col>
              </b-row>
              <b-row class="mb-2">
                <b-col sm="2">
                </b-col>
                <b-col sm="12">
                  <multiselect disabled id="dokterksm" :show-labels="false" label="name" track-by="id"
                    placeholder="Pilih Dokter KSM" :searchable="true" :allow-empty="false" v-model="selected.dokterksm"
                    :options="options.dokterksm">
                  </multiselect>
                </b-col>
              </b-row>
              <b-row>
                <b-col sm="12" class="d-flex flex-row w-100">
                  <div class="w-100 mr-1">
                    <button
                      :class="['btn rounded-custom text-secondary shadow-sm py-2 w-100', format === 'pdf' ? 'pdf-color-bg text-white' : 'bg-white']"
                      @click="valuex('pdf')" target="_blank" rel="noreferrer"><i class="fas fa-file-pdf fa-1x">
                        PDF</i></button>
                  </div>
                  <div class="w-100">
                    <button
                      :class="['btn rounded-custom text-secondary shadow-sm py-2 w-100', format === 'excel' ? 'excel-color-bg text-white' : 'bg-white']"
                      @click="valuex('excel')" target="_blank" rel="noreferrer"><i class="fas fa-file-excel fa-1x">
                        EXCEL</i></button>
                  </div>
                </b-col>
              </b-row>
            </div>
          </div>
        </b-col>
        <b-col cols="12" lg="8" class="pr-0 pl-1">
          <div class="bg-abu shadow-sm h-100">
            <div class="bg-white pt-3 pb-3 px-3">
              <p class="text-dark h6 mb-0"><i class="fas fa-file-download"></i> Download Laporan</p>
            </div>
            <div class="pb-3 px-3 pt-3">
              <div>
                <b-row>
                  <b-col cols="12" lg="6" :class="['mb-4', i.hide === false ? 'd-block' : 'd-none']"
                    v-for="(i, index) in card" :key="i.link">
                    <div
                      class="laporan-mhas laporan-active bg-white p-4 d-flex flex-row align-items-center my-cursor rounded-custom shadow-sm"
                      @click="openNewWindow(index)" target="_blank" rel="noreferrer">
                      <div class="mr-3">
                        <i
                          :class="['fas fa-3x', format === 'pdf' ? 'pdf-color fa-file-pdf' : 'excel-color fa-file-excel']"></i>
                      </div>
                      <div>
                        <p class="text-dark text-uppercase mb-0">{{ title(i.link) }}</p>
                      </div>
                      <div class="ml-auto">
                        <i class="download-aniamte fas fa-download text-primary fa-2x"></i>
                      </div>
                    </div>
                  </b-col>
                </b-row>
              </div>
              <div class="bg-dark px-2 py-1 mt-3 w-100" v-if="message !== ''">
                <p class="small text-right mb-0 text-white"><i class="fas fa-calendar-alt"></i> {{ message }}</p>
              </div>
            </div>
          </div>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>
<script>
import { xray } from '../../config/pluginInit'
import Services from '../../services/fatmahost/master/index'
import Multiselect from 'vue-multiselect'
import flatPickr from 'vue-flatpickr-component'
import { Indonesian as IndonesianLocale } from 'flatpickr/dist/l10n/id.js'
import 'flatpickr/dist/flatpickr.css'

export default {
  name: 'Mhas',
  components: {
    flatPickr,
    Multiselect
  },
  data () {
    return {
      url: '',
      baseUrl: 'http://192.168.5.52/pro/simrsfReport/show?',
      format: 'pdf',
      card: [
        {
          link: '/reports/Simrsf/latihan/Laporan_mhas_kwitansi',
          hide: true
        },
        {
          link: '/reports/Simrsf/latihan/Laporan_Visite_Dokter',
          hide: true
        },
        {
          link: '/reports/Simrsf/latihan/Laporan_Kasir_Keseluruhan',
          hide: true
        }
      ],
      forms: {
        tglAwal: '',
        tglAhkir: '',
        ruangan: '',
        carabayar: '',
        idKasir: ''
      },
      options: {
        carabayar: [],
        ksm: [],
        dokterksm: [],
        ruangan: []
      },
      selected: {
        carabayar: [],
        ksm: [],
        dokterksm: [],
        ruangan: []
      },
      config: {
        locale: IndonesianLocale,
        maxDate: 'today',
        dateFormat: 'd-m-Y',
        disableMobile: true
      },
      message: ''
    }
  },
  mounted () {
    xray.index()
    this.getcarabayar()
    this.getruangan()
    const bitcontrolLoad = xray.bitcontrol(this.$route.name)
    if (bitcontrolLoad.bitcontrol2.charAt(0) === '1') {
      this.card[0].hide = true
      this.card[1].hide = true
      this.card[2].hide = false
    } else if (bitcontrolLoad.bitcontrol2.charAt(0) === '0') {
      this.card[0].hide = false
      this.card[1].hide = false
      this.card[2].hide = true
    }
  },
  methods: {
    openNewWindow (index) {
      const thePath = this.card[index].link
      const bitcontrol = xray.bitcontrol(this.$route.name)
      const bc = bitcontrol.bitcontrol2.charAt(0) === '0' ? xray.currentUser().id.toString() : ''
      if (this.forms.tglAwal !== '' && this.forms.tglAhkir !== '') {
        const meter = `{"TGLAWAL": "${this.forms.tglAwal}","TGLAKHIR": "${this.forms.tglAhkir}","USERID_IN": "${bc}"}`
        this.url = `${this.baseUrl}path=${thePath}&params=${meter}&format=${this.format}`
        const newWindow = window.open(this.url, '_blank')
        if (newWindow) {
          newWindow.focus()
        }
      } else {
        this.message = 'Tanggal Periode Awal & Akhir Tidak Boleh Kosong'
      }
    },
    title (value) {
      var lastOfA = value.split('/')
      return lastOfA[4].replace(/_/g, ' ')
    },
    tglEvent ($event, param) {
      this.message = ''
      //
      const date = new Date($event)
      let day = date.getDate()
      let month = (date.getMonth() + 1).toString().padStart(2, 0)
      let year = date.getFullYear()
      if (param === '1') {
        let currentDate = year + '-' + month + '-' + day + ' ' + '00' + ':' + '00' + ':' + '00'
        this.forms.tglAwal = currentDate
      } else if (param === '2') {
        let currentDate = year + '-' + month + '-' + day + ' ' + '23' + ':' + '59' + ':' + '59'
        this.forms.tglAhkir = currentDate
      }
    },
    async getcarabayar () {
      const response = await Services.getcarabayar()
      this.options.carabayar = response.data.data
    },
    valuecarabayar (param) {
      this.message = ''
      //
      this.forms.carabayar = param.id.toString()
    },
    async getruangan () {
      const response = await Services.getruangan()
      this.options.ruangan = response.data.data
    },
    valueruangan (param) {
      this.message = ''
      //
      this.forms.ruangan = param.id.toString()
    },
    valuex (e) {
      this.message = ''
      this.format = e
    }
  }
}
</script>
<style>
#laporan-mhas .form-control {
  height: 38px;
  line-height: 38px;
  border-radius: 5px;
  background: white;
}

#laporan-mhas .bg-abu {
  background: #0da1b125;
}

#laporan-mhas .custom-control-label {
  margin-top: 0;
}

#laporan-mhas .multiselect__single {
  font-size: 13px !important;
}

#laporan-mhas .multiselect__placeholder {
  font-weight: normal !important;
  font-size: 13px !important;
}

#laporan-mhas .multiselect__option {
  font-size: 13px;
}

#laporan-mhas .multiselect__tags {
  padding: 5px 40px 0 8px !important;
  height: 38px !important;
  min-height: 38px !important;
  border: 1px solid rgb(214, 214, 214);
}

#laporan-mhas .multiselect__content-wrapper {
  font-size: 12px;
  font-weight: normal !important;
}

#laporan-mhas .body {
  padding: 0 !important;
  margin: 0 !important;
  border-radius: 4px;
  margin: 10px;
  padding: 20px;
}

#laporan-mhas .table td {
  font-size: 12px;
  font-weight: 500;
  padding-top: 5px;
  padding-bottom: 5px;
  padding: 0 !important;
  margin: 0 !important;
  border-radius: 4px;
}

#laporan-mhas .table th {
  font-size: 13px;
  font-weight: 400;
  padding: 20px;
  border-radius: 4px;
  margin: 1px;
  padding: 2px;
}

#laporan-mhas .cstm-cntrl {
  color: black !important;
  border-radius: 5px !important;
  font-size: 12px !important;
  height: 38px !important;
  border-radius: 4px;
  margin: 1px;
  padding: 2px;
}

#laporan-mhas .custom-min-height {
  min-height: 100%;
  text-align: center;
  border-radius: 5px;
  border: 1px solid rgb(209, 208, 208) !important;
  background: #edf2f1 !important;
}

#laporan-mhas .custom-min-height1 {
  min-height: 100%;
  border-radius: 5px;
  border: 1px solid rgb(209, 208, 208) !important;
  background: #edf2f1 !important;
}

#laporan-mhas .laporan-donwload-pdf {
  min-height: 100%;
}

#laporan-mhas .custom-flatpicker {
  border: 1px solid rgb(233, 233, 233);
  height: 38px;
  padding-left: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
  border: 1px solid rgb(209, 208, 208) !important;
  border-radius: 4px;
  border: 1px solid rgb(228, 228, 228);
  font-size: 13px;
  color: black;
}

#laporan-mhas .my-cursor {
  cursor: pointer;
}

#laporan-mhas .rounded-custom {
  border-radius: 5px;
  border: 1px solid rgb(209, 209, 209);
}

#laporan-mhas .pdf-color {
  color: rgb(214, 0, 0);
}

#laporan-mhas .pdf-color-bg {
  background-color: rgb(214, 0, 0);
}

#laporan-mhas .excel-color {
  color: green;
}

#laporan-mhas .excel-color-bg {
  background-color: green;
}

#laporan-mhas .laporan-active {
  -webkit-box-shadow: 0 0 1px #bdbdbd;
  box-shadow: 0 0 1px #bdbdbd;
  -webkit-transition: all .3s;
  -o-transition: all .3s;
  transition: all .3s;
}

#laporan-mhas .laporan-active:hover {
  -webkit-box-shadow: 0 0 15px #bbbbbb;
  box-shadow: 0 0 15px #bbbbbb;
}

#laporan-mhas .download-aniamte {
  -webkit-animation: cylon-animation 2s linear infinite;
  animation: cylon-animation 2s linear infinite;
}

@-webkit-keyframes cylon-animation {

  0%,
  100% {
    -webkit-transform: translateY(-20%);
    transform: translateY(-20%);
  }

  50% {
    -webkit-transform: translateY(20%);
    transform: translateY(20%);
  }
}

@keyframes cylon-animation {

  0%,
  100% {
    -webkit-transform: translateY(-20%);
    transform: translateY(-20%);
  }

  50% {
    -webkit-transform: translateY(20%);
    transform: translateY(20%);
  }
}
</style>
