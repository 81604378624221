import Api from './fatmahost/axiosInstance'

export default {
  show (id) {
    return Api.laravel.post('/setbyUserId', id)
  },
  findAll (page, params) {
    if (params !== null) {
      return Api.laravel.get(`userAccess/?params=${params}`)
    } else {
      return Api.laravel.get(`userAccess/?page=${page}`)
    }
  },
  findId (id) {
    return Api.laravel.get('/getUserAccess', { params: { pegawai_id: id } })
  },
  store (data) {
    return Api.laravel.post('/userAccess', data)
  }

}
