<template>
  <div id="list-pasien" class="list-pasien">
    <b-container fluid>
    <b-row>
      <b-col md="12">
        <iq-card>
          <template v-slot:headerTitle>
            <h4 class="card-title">Data Pasien</h4>
          </template>
          <template v-slot:headerAction>
            <b-button disabled variant="secondary" size="sm" @click="tambah"><i class="fas fa-user-plus"></i> Tambah Pasien</b-button>
          </template>
          <template v-slot:body>
            <div class="table mb-0">
              <b-table ref="table" class="m-0" :sort-by.sync="table.sortBy" :sort-desc.sync="table.sortDesc"
                :filter="table.filter" @filtered="onFiltered" :busy="table.isBusy" :current-page="table.currentPage"
                :per-page="table.perPage" responsive stacked="md" bordered hover show-empty small :items="table.items"
                :fields="table.fields" :select-mode="table.selectMode" selectable>
                <template #table-busy>
                  <div class="text-center my-2">
                    <b-spinner class="align-middle text-primary mr-2"></b-spinner>
                    <strong class="text-dark ">Loading...</strong>
                  </div>
                </template>
                <template #cell(index)="data">
                  <span>{{ (table.currentPage*table.perPage)-table.perPage + data.index + 1 }}</span>
                </template>
                <template v-slot:cell(action)="data">
                  <b-button variant="white" size="lg" @click="viewPasien(columnDotsConvert(data.item, 'p.norm'))"><i class="fas fa-eye text-success"></i></b-button>
                  <b-button disabled variant="iq-bg-secondary" size="lg" @click="editPasien(data.item.norm)"><i class="ri-ball-pen-fill text-secondary m-0"></i></b-button>
                </template>
              </b-table>
            </div>
            <div>
                <div class="bg-white pb-2 pt-2 pl-3 pr-3 border-bottom border-right border-left">
                <b-row class="align-items-center justify-content-between">
                  <b-col cols="12" lg="3" class="mr-auto">
                    <div class="d-flex align-items-center">
                      <div>
                        <b-pagination v-model="table.currentPage" :total-rows="table.totalRows" :per-page="table.perPage" size="sm" class="my-0">
                        </b-pagination>
                      </div>
                      <div class="border-left border-right px-3">
                        <span class="cursor-point" @click="tableRefresh">
                          <i class="fas fa-sync"></i>
                        </span>
                      </div>
                    </div>
                  </b-col>
                  <b-col cols="12" lg="5">
                    <div class="d-flex flex-row">
                      <div class="mr-2 w-25">
                        <b-input-group>
                          <b-form-select class="cstm-slct-pegawai" v-model="table.filterTable.selected" :options="options"></b-form-select>
                        </b-input-group>
                      </div>
                      <div class="mr-2 w-75">
                        <b-input-group>
                          <b-form-input class="cstm-slct-pegawai rounded-0" id="filter-input" v-model="table.filterTable.value" @input="tableRefresh" type="search" placeholder="[ Pencarian ]"></b-form-input>
                        </b-input-group>
                      </div>
                    </div>
                  </b-col>
                  <b-col cols="12" lg="3">
                    <span class="text-right">
                      <p class="font-weight-normal mb-0">Menampilkan 1 - {{ table.perPage }} dari {{ formatRibuan(table.totalRows) }}</p>
                    </span>
                  </b-col>
                </b-row>
              </div>
            </div>
          </template>
        </iq-card>
      </b-col>
    </b-row>
  </b-container>
  </div>
</template>
<script>
import { xray } from '../../../config/pluginInit'
// import Services from '../../../services/pasien.js'
import NewServices from '../../../services/fatmahost/pasien/index.ts'

export default {
  name: 'PasienList',
  mounted () {
    xray.index()
  },
  data () {
    return {
      usernameState: null,
      passwordState: null,
      table: {
        isBusy: false,
        items: this.listPasien,
        sortBy: '',
        sortDesc: false,
        filter: null,
        currentPage: null,
        perPage: null,
        pageOptions: [10, 25, 50, 100],
        totalRows: null,
        selectMode: 'single',
        selected: [],
        // fields: [
        //   { label: 'NORM', key: 'norm', class: 'text-lg-center col-1' },
        //   { label: 'NAMA', key: 'nama' },
        //   { label: 'TGL. LAHIR', key: 'tanggal_lahir' },
        //   { label: 'JENIS KELAMIN', key: 'jenis_kelamin' },
        //   { label: 'AKSI', key: 'action', class: 'text-center col-1' }
        // ],
        fields: [
          { label: 'NO.', key: 'index', class: 'text-lg-center py-2' },
          { label: 'NORM', key: 'p.norm', class: 'col-1' },
          { label: 'NAMA', key: 'p.nama' },
          { label: 'TGL. LAHIR', key: 'p.TANGGAL_LAHIR' },
          { label: 'JENIS KELAMIN', key: 'nmJnsKelamin' },
          { label: 'TEMPAT LAHIR', key: 'nmTmpLhr' },
          { label: 'ALAMAT', key: 'alamat' },
          { label: 'AKSI', key: 'action', class: 'text-center col-1' }
        ],
        filterTable: {
          selected: '1',
          value: '',
          tglLahir: ''
        }
      },
      options: [
        { value: '1', text: 'Nama' },
        { value: '2', text: 'NORM' }
      ]
    }
  },
  methods: {
    tableRefresh () {
      this.$refs.table.refresh()
    },
    async listPasien (ctx) {
      this.isBusy = true
      let norm = ''
      let nama = ''
      if (this.table.filterTable.selected === '1') {
        nama = this.table.filterTable.value
        norm = ''
      } else if (this.table.filterTable.selected === '2') {
        nama = ''
        norm = this.table.filterTable.value
      }
      let tglLahir = this.table.filterTable.tglLahir !== '' ? this.table.filterTable.tglLahir : ''
      try {
        const response = await NewServices.findAllNew(ctx.currentPage - 1, ctx.perPage, norm, nama, tglLahir)
        if (response.data.data) {
          this.isBusy = false
          this.table.totalRows = response.data.pagination.total
          this.table.perPage = response.data.pagination.perPage
          this.table.currentPage = response.data.pagination.current
          return response.data.data
        } else {
          return []
        }
      } catch (err) {
        this.isBusy = false
        return []
      }
    },
    viewPasien (id) {
      this.$router.push('view/' + id)
    },
    editPasien (id) {
      this.$router.push('edit/' + id)
    },
    tambah () {
      this.$router.push({ name: 'pasien.add' })
    },
    onFiltered (filteredItems) {
      this.table.totalRows = filteredItems.length
      this.table.currentPage = 1
    },
    formatRibuan (value) {
      if (value) {
        return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
      } else {
        return 0
      }
    },
    columnDotsConvert (column, desc) {
      return column[desc]
    }
  }
}
</script>
<style>
#list-pasien .table td {
    font-size: 12px;
    font-weight: 500;
    padding-top: 0px;
    padding-bottom: 0px;
}
#list-pasien .table th {
    font-size: 13px;
    font-weight: 400;
}
#list-pasien .page-link {
    border: none;
    color: gray!important;
    margin-right: 15px;
}
#list-pasien .page-item.active .page-link {
    background-color: transparent;
    border-color: transparent;
    color: #089bab!important;
    font-weight: bold;
}
#list-pasien .cursor-point {
    cursor: pointer;
}
.cstm-slct-pegawai {
  font-size: 13px!important;
  height: 38px!important;
  border-radius: 5px;
}
.cstm-cntrl-pegawai {
  color: black!important;
  font-weight: bold!important;
  border-radius: 5px!important;
  font-size: 12px!important;
  height: 38px!important;
}
.custom-fontsize {
  font-size: 12px;
}
</style>
