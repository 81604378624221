import actions from './actions'
import mutations from './mutations'

const state = {
  token: null,
  user: null,
  module: null,
  // utility: null,
  module_local: null
  // utility_local: null
}

export default {
  namespaced: true,
  state,
  actions,
  mutations
}
