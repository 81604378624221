<template>
  <div id="pending-farmasi-tambah" class="pending-farmasi-tambah">
    <div v-show="isLoading">
        <div id="show-overlay"></div>
      <Loader :style="{ opacity: .5 }" />
    </div>
    <b-container fluid class="mb-4">
      <iq-card class="mb-2">
        <div  class="iq-email-to-list pt-3 pl-3 pr-3" id="header-table-filter">
          <div class="px-2 py-2 border d-flex align-items-center justify-content-between">
            <div>
              <p class="text-dark font-weight-bold h6 mb-0">TAMBAH PENDING ITERASI</p>
            </div>
            <div>
              <b-button variant="warning" class="py-1" style="border-radius: 5px;" @click="toPending"><i class="fas fa-undo"></i> Kembali</b-button>
            </div>
          </div>
        </div>
        <div class="mx-3 px-1 py-1 border">
          <b-row>
            <b-col class="pr-1" cols="6">
              <div>
                <b-form-group class="mb-0">
                  <multiselect :show-labels="false" label="namaBarang" placeholder="Nama Barang" :custom-label="nameWithKode"
                    :options="combo.pabrik" track-by="id_pabrik" v-model="selected.pabrik" @input="valuebarangiter"
                    :searchable="true" :allow-empty="false" @search-change="getPabrik">
                  </multiselect>
                </b-form-group>
              </div>
            </b-col>
            <b-col class="pr-1 pl-1" cols="2">
              <div>
                <b-form-group class="mb-0">
                  <b-form-input v-model="tableparam.norm" class="bg-white cstm-cntrl-stockopname" type="text" placeholder="Norm" @keyup.enter="loadtable"></b-form-input>
                </b-form-group>
              </div>
            </b-col>
            <b-col class="pr-1 pl-1" cols="1">
              <div>
                <b-form-group class="mb-0">
                  <flat-pickr :config="config" v-model="tableparam.tgl_awal" value="" placeholder="Pilih Tanggal" class="w-100 custom-flatpicker calender-small m-0 p-0">
                  </flat-pickr>
                </b-form-group>
              </div>
            </b-col>
            <b-col class="pr-1 pl-1" cols="1">
              <div>
                <b-form-group class="mb-0">
                  <flat-pickr :config="config" v-model="tableparam.tgl_akhir" value="" placeholder="Pilih Tanggal" class="w-100 custom-flatpicker calender-small m-0 p-0">
                  </flat-pickr>
                </b-form-group>
              </div>
            </b-col>
            <b-col class="pr-1 pl-1" cols="1">
              <div>
                <b-form-group class="mb-0">
                  <b-button class="w-100" variant="primary" style="border-radius: 5px;" @click="loadtable"><i class="fas fa-search"></i> Cari</b-button>
                </b-form-group>
              </div>
            </b-col>
            <b-col class="pl-1" cols="1">
              <div>
                <b-form-group class="mb-0">
                  <b-button class="w-100" variant="primary" style="border-radius: 5px;" @click="resetForm"><i class="fas fa-sync-alt"></i> Reset</b-button>
                </b-form-group>
              </div>
            </b-col>
          </b-row>
        </div>
        <div class="iq-email-listbox pl-3 pr-3 pb-3">
          <div class="table mb-0">
            <b-table
              class="mb-0 pointer-table"
              ref="table"
              :busy="table.isBusy"
              :items="table.items"
              :fields="table.fields"
              :current-page="table.currentPage"
              :per-page="table.perPage"
              :filter="table.filter"
              @filtered="onFiltered"
              :sort-by.sync="table.sortBy"
              :sort-desc.sync="table.sortDesc"
              stacked="md"
              bordered hover show-empty small striped>
              <template #table-busy>
                <div class="text-center my-2">
                  <b-spinner class="align-middle text-primary mr-2"></b-spinner>
                  <strong class="text-dark">Memuat Data...</strong>
                </div>
              </template>
              <template #cell(index)="data">
                {{ (table.currentPage*table.perPage)-table.perPage + data.index + 1 }}
              </template>
              <template #cell(iter)="data">
                <b-button :disabled="data.item.sts_lock === '0.00' ? false : true" :variant="data.item.sts_lock === '0.00' ? 'primary' : 'secondary'" class="w-75 py-1" style="border-radius: 5px;" @click="modalIter(data.item.ID, data.item.flag_iterasi, data.item.kunjungan, data.item.TANGGAL, data.item.nomor_resep)">
                  <span v-if="data.item.flag_iterasi === 0">Non-Iter</span>
                  <span v-else-if="data.item.flag_iterasi === 1">Iterasi-1</span>
                  <span v-else-if="data.item.flag_iterasi === 2">Iterasi-2</span>
                </b-button>
              </template>
              <template #cell(JUMLAH)="data">
                <div>
                  <span>
                    {{ data.item.JUMLAH.replace(/\.\d+/g, '') }}
                  </span>
                </div>
              </template>
              <template #cell(diberikan)="data">
                <b-form-input :disabled="data.item.sts_lock === '0.00' ? false : true" :id="'input-iter' + data.index" :value="data.item.diberikan" @input="calculate($event, data.item.JUMLAH, data.index)" @keypress="isNumberKey($event)" :class="['cstm-cntrl-stockopname text-center', items[data.index].TA < 0 || items[data.index].TA < '0'  ? 'border-salah' : '']" type="text" placeholder="Diberikan"></b-form-input>
              </template>
              <template #cell(PINJAM)="data">
                <b-form-checkbox
                  :disabled="data.item.sts_lock === '0.00' ? false : true"
                  :id="'checkbox-iter' + data.index"
                  v-model="data.item.PINJAM"
                  :name="'checkbox-iter' + data.index"
                  value="1"
                  unchecked-value="0"
                >
                </b-form-checkbox>
              </template>
              <template #cell(TA)="data">
                <div>
                  <span :class="data.item.TA < 0 || data.item.TA < '0' ? 'text-danger font-weight-bold' : ''">
                    {{ items[data.index].TA }}
                  </span>
                </div>
              </template>
              <template #cell(action)="data">
                <div class="d-flex flex-row">
                  <div v-if="bitcontrol1_access.add === true" class="mr-1">
                    <b-button class="text-center px-3 shadow-sm" size="sm" variant="primary" :id="'input-create' + data.index" style="border-radius: 5px;" @click="specificEmpty(data.item)">
                      <i class="fas fa-plus"></i>
                    </b-button>
                  </div>
                  <div v-if="data.item.sts_lock === '0.00' && (items[data.index].TA > '0' || items[data.index].TA > 0) && items[data.index].diberikan !== ''">
                    <b-button class="text-center" size="sm" variant="success" :id="'input-iter' + data.index" style="border-radius: 5px;" @click="specificColumn(data.item)">Simpan</b-button>
                  </div>
                </div>
              </template>
            </b-table>
          </div>
          <div class="pagination-pending border shadow-sm p-2 mb-2">
            <b-row class="align-items-center">
              <b-col cols="4" class="d-flex flex-row align-items-center">
                <div class="w-100">
                  <b-pagination v-model="table.currentPage" :total-rows="table.totalRows" :per-page="table.perPage"
                    size="md" class="my-0"></b-pagination>
                </div>
                <div class="w-100">
                  <div>
                    <span class="border-left border-dark mr-3 py-1"></span>
                    <span class="katalog-cursor" @click="tableRefresh">
                      <i class="fas fa-sync text-secondary"></i>
                    </span>
                    <span class="border-right border-dark ml-3 py-1"></span>
                  </div>
                </div>
              </b-col>
              <b-col class="ml-auto" cols="6">
                <div :class="['w-100 d-flex flex-row justify-content-between shadow-sm px-3 py-1 mx-1', messageVal === '1' ? 'bg-success text-white' : 'bg-danger text-white']" v-if="message">
                  <p class="text-center font-weight-bold small text-uppercase mb-0">{{ message }}</p>
                  <p class="text-center font-weight-bold small text-uppercase mb-0" @click="clearForm"><i class="fas fa-times-circle fa-lg"></i></p>
                </div>
              </b-col>
              <!-- <b-col cols="5">
                <div class="d-flex flex-row justify-content-end align-items-center">
                  <div class="mr-1 w-75">
                    <form class="searchbox mb-0">
                      <input v-model="table.filter" type="search" class="form-control cstm-srch-stock" id="filter-input"
                        placeholder="[Cari]">
                    </form>
                  </div>
                  <div>
                    <b-form-select class="cstm-slct-stock" v-model="selected.filterType" :options="options.optionsFilter"
                      @change="filterchange"></b-form-select>
                  </div>
                </div>
              </b-col>
              <b-col cols="3">
                <div class="d-flex flex-row align-items-right justify-content-end">
                  <div class="mr-2">
                    <b-form-select id="per-page-select" class="custom-perpage" v-model="table.perPage" :options="table.pageOptions" size="sm"
                    ></b-form-select>
                  </div>
                  <div>
                    <p class="text-dark mb-0">Menampilkan {{ table.perPage }} dari {{ table.totalRows }} baris</p>
                  </div>
                </div>
              </b-col> -->
            </b-row>
          </div>
        </div>
      </iq-card>
    </b-container>
    <b-modal ref="modal-sukses" size="md" title="Informasi" ok-title="Oke" :ok-only="true" :hide-header="true" @ok="toPending" :no-close-on-backdrop="true" centered>
      <div class="text-center px-lg-4 px-2">
        <lottie :options="defaultOptions" :autoPlay="true" :width="150" :height="150" />
        <p class="h6 text-dark font-weight-bold mb-0">{{ messageModal }}</p>
      </div>
    </b-modal>
    <b-modal ref="modal-gagal" size="md" title="Informasi" ok-title="Kembali" :ok-only="true" :hide-header="true" centered>
      <div class="text-center px-lg-4 px-2">
        <lottie :options="defaultOptions2" :autoPlay="true" :width="150" :height="150" />
        <p class="h6 text-danger font-weight-bold mb-2">Data Gagal Disimpan</p>
        <p class="small text-danger font-weight-light mb-0">{{error}}</p>
      </div>
    </b-modal>
    <b-modal ref="modal-konfirm-delete-hard" size="md" title="KONFIRMASI HAPUS" ok-variant="danger" ok-title="Hapus" @ok="handleDelete" :no-close-on-backdrop="true" :static="true"
    cancel-title="Batal" centered>
      <div class="px-1 py-2">
        <div class="text-center ">
          <i class="fas fa-exclamation-circle text-danger fa-5x"></i>
          <p class="h6 text-danger mb-0 mt-3">Anda Yakin Akan Menghapus Barang ?</p>
        </div>
        <div class="bg-dark px-2 mt-2" v-if="error">
          <p class="small text-white mb-0">{{ error }}</p>
        </div>
      </div>
    </b-modal>
    <b-modal id="modal-iter-show" ref="modal-iter-show" size="md" :title="display.resep" ok-title="Simpan" :no-close-on-backdrop="true" :static="true" @close="clearForm" @cancel="clearForm" @ok="updateIter" :ok-disabled="flagiter !== 0 ? true : false" cancel-variant="danger" :ok-variant="flagiter !== 0 ? 'secondary' : 'primary'" centered>
      <div class="px-2 py-2">
        <b-row class="align-items-center mb-2">
          <b-col cols="3">
            <b-form-group class="mb-1">
              <b-form-checkbox
                :disabled="disable.iter1 === 1 ? true : false"
                id="check-iterasi1"
                v-model="formiterasi.iterasi1"
                name="check-iterasi1"
                value="1"
                unchecked-value="0"
                @change="valueIterasi($event, '1')"
              >
                Iterasi-1
              </b-form-checkbox>
            </b-form-group>
          </b-col>
          <b-col>
            <div v-if="formiterasi.iterasi1 === '1'">
              <flat-pickr disabled v-model="display.tglIterasi1" :config="configModal" value="" placeholder="Tanggal Iterasi 1" class="w-100 custom-flatpicker calender-small">
              </flat-pickr>
            </div>
          </b-col>
        </b-row>
        <b-row class="align-items-center">
          <b-col cols="3">
            <b-form-group class="mb-0">
              <b-form-checkbox
                :disabled="disable.iter2 === 1 ? true : false"
                id="check-iterasi2"
                v-model="formiterasi.iterasi2"
                name="check-iterasi2"
                value="1"
                unchecked-value="0"
                @change="valueIterasi($event, '2')"
              >
                Iterasi-2
              </b-form-checkbox>
            </b-form-group>
          </b-col>
          <b-col>
            <div v-if="formiterasi.iterasi2 === '1'">
              <flat-pickr disabled v-model="display.tglIterasi2" :config="configModal" value="" placeholder="Tanggal Iterasi 2" class="w-100 custom-flatpicker calender-small">
              </flat-pickr>
            </div>
          </b-col>
        </b-row>
      </div>
      <div class="bg-dark px-2 mt-2" v-if="error">
        <p class="small text-white mb-0">{{ error }}</p>
      </div>
      <!-- <pre>{{ formiterasi }}</pre> -->
    </b-modal>
    <b-modal id="modal-pending-empty" ref="modal-pending-empty" size="md" :title="display.resep" ok-title="Tambah" :ok-only="true" :no-close-on-backdrop="true" :static="true" @ok="sendEmpty" @close="clearForm" @cancel="clearForm" centered>
      <div class="px-2">
        <b-form-group class="mb-2">
          <multiselect :show-labels="false" :allow-empty="false" v-model="selected.pabrik_2" @input="barangEmpty"
            label="namaBarang" track-by="id_katalog" placeholder="Barang" :options="combo.pabrik" :custom-label="nameWithKode" @search-change="getPabrik"
            :searchable="true">
          </multiselect>
        </b-form-group>
        <b-form-group class="mb-0">
          <b-form-input v-model="tableEmpty.qty_pending" @keypress="isNumberKey" class="bg-white cstm-cntrl-stockopname" type="text" placeholder="Quantity"></b-form-input>
        </b-form-group>
        <div class="bg-dark px-2 mt-2" v-if="messageModal">
          <p class="small text-white mb-0">{{ messageModal }}</p>
        </div>
        <!-- <pre>{{ tableEmpty }}</pre> -->
      </div>
    </b-modal>
    <ModalMsgbox ref="MsgBox" />
  </div>
</template>
<script>
import { xray } from '../../config/pluginInit'
import Loader from '../../components/xray/loader/Loader'
import Lottie from 'vue-lottie'
import animationLogo from '../../assets/lottie/checked.json'
import animationLogo2 from '../../assets/lottie/failed.json'
import Service from '../../services/fatmahost/index'
import Multiselect from 'vue-multiselect'
import ModalMsgbox from '../../components/modal/ModalMsgBox'
import flatPickr from 'vue-flatpickr-component'
import { Indonesian as IndonesianLocale } from 'flatpickr/dist/l10n/id.js'
import 'flatpickr/dist/flatpickr.css'
// import { mapGetters } from 'vuex'
export default {
  name: 'StockOpname',
  components: {
    ModalMsgbox,
    Multiselect,
    Lottie,
    Loader,
    flatPickr
  },
  data () {
    return {
      table: {
        isBusy: false,
        items: this.list,
        sortBy: '',
        sortDesc: false,
        fields: [
          { label: 'NO', key: 'index', class: 'text-center py-2' },
          { label: 'NOMOR RESEP', key: 'nomor_resep' },
          { label: 'ITER', key: 'iter', class: 'text-center' },
          { label: 'NAMA OBAT', key: 'nama_barang' },
          { label: 'NAMA PABRIK', key: 'nama_pabrik' },
          { label: 'SATUAN', key: 'satuan' },
          { label: 'JUMLAH', key: 'JUMLAH', class: 'text-center' },
          { label: 'DIBERIKAN', key: 'diberikan', class: 'col-1 text-center' },
          { label: 'TA', key: 'TA', class: 'text-center col-1' },
          { label: 'PINJAM', key: 'PINJAM', class: 'text-center' },
          { label: 'AKSI', key: 'action', class: 'text-center col-1' }
        ],
        filter: null,
        currentPage: null,
        perPage: null,
        pageOptions: [5, 10, 25, 50, 100, 500],
        totalRows: null
      },
      tableparam: {
        obat: '',
        norm: '',
        tgl_awal: '',
        tgl_akhir: ''
      },
      combo: {
        instalasi: [],
        unit: [],
        ruangan: [],
        jenis: [
          { value: 1, text: 'Reservasi Online' },
          { value: 2, text: 'Reservasi Offline' }
        ],
        filterType: [
          { value: '1', text: 'Filter Dokter' },
          { value: '2', text: 'Filter Ruangan' }
        ],
        filterRuang: [
          { value: '', text: 'Rawat Jalan' }
        ],
        pabrik: [],
        status: [
          { value: '1', text: 'Aktif' },
          { value: '0', text: 'tidak Aktif' }
        ]
      },
      options: {
        optionsFilter: [
          { text: 'Semua', value: '' },
          { text: 'Pinjam', value: '2' },
          { text: 'Tinggal Ambil', value: '1' },
          { text: 'Iterasi', value: '3' }
        ],
        jenis: [
          { text: 'Pinjam', value: '2' },
          { text: 'Tinggal Ambil', value: '1' },
          { text: 'Iterasi', value: '3' }
        ]
      },
      selected: {
        barang: [
          {
            detail: []
          }
        ],
        instalasi: [],
        unit: [],
        ruangan: [],
        kode: [{
          value: ''
        }],
        pabrik: [],
        pabrik_2: [],
        filterType: '',
        jenis: '',
        status: '0'
      },
      id: null,
      flagiter: null,
      kunjunganId: null,
      ruangan: '',
      pendingform: {
        object: 'pending',
        detail: [
          {
            id: '',
            id_katalog_selesai: '',
            id_pabrik_selesai: '',
            tgl_selesai: '',
            qty_selesai: '',
            sts_selesai: '1',
            userid_selesai: xray.currentUser().id.toString()
          }
        ]
      },
      bitcontrol1_access: {
        show: false,
        add: false,
        edit: false,
        delete: false
      },
      pendingformview: {
        detail: [
          {
            tanggal: '',
            status: '',
            //
            norm: '100',
            kunjungan: '',
            depo: '',
            pabrik: '',
            jenis: ''
          }
        ],
        obat: '',
        tgl_view: '',
        tgl: ''
      },
      formiterasi: {
        object: 'pending-iter',
        id_farmasi: '',
        kunjungan: '',
        iterasi1: '0',
        iterasi1_tanggal: '',
        iterasi2: '0',
        iterasi2_tanggal: ''
      },
      tableiterasi: {
        object: 'pending-add',
        detail: [
          {
            id_farmasi: '',
            diberikan: '',
            pinjam: ''
          }
        ]
      },
      tableEmpty: {
        object: 'pending-add',
        id_katalog: '',
        id_pabrik: '',
        ruangan: '',
        kunjungan: '',
        farmasi: '',
        norm: '',
        jns_pending: '4',
        qty_pending: '',
        tgl_pending: '',
        userid_pending: '',
        order_id: ''
      },
      items: [],
      display: {
        form: {},
        pendingObat: true,
        pendingtgl: '',
        namaObat: '',
        //
        displayruangan: '',
        stockopnamekeluaron: '',
        stockopnamekeluaroff: '',
        viewTgltmbh: '',
        viewTglEdit: '',
        viewTglAwal: '',
        viewTglAkhir: '',
        kodeName: [
          {
            kode: '',
            nama: ''
          }
        ],
        tglInp: [
          {
            value: ''
          }
        ],
        tglExp: [
          {
            date: ''
          }
        ],
        namabarang: [
          {
            nama: ''
          }
        ],
        kodedetail: [
          {
            kode: ''
          }
        ],
        tanggal: '',
        btnValidationSave: true,
        resep: '',
        tglIterasi1: '',
        tglIterasi2: '',
        tglItems: ''
      },
      disable: {
        iter1: 0,
        iter2: 0
      },
      filtering: {
        valTglAwal: '',
        valTglAkhir: ''
      },
      config: {
        locale: IndonesianLocale,
        disableMobile: true,
        dateFormat: 'Y-m-d'
      },
      configModal: {
        locale: IndonesianLocale,
        disableMobile: true,
        dateFormat: 'd-m-Y'
      },
      status: 'not_accepted',
      statusEdit: 'not_accepted',
      valuedisable: false,
      valuedisable2: false,
      error: '',
      message: '',
      messageModal: '',
      messageVal: '',
      messageTgl: '',
      isLoading: false,
      defaultOptions: { animationData: animationLogo, loop: true },
      defaultOptions2: { animationData: animationLogo2, loop: true }
    }
  },
  mounted () {
    xray.index()
    this.getbitcontrol(this.$route.params.id)
  },
  methods: {
    getbitcontrol (data) {
      let access = JSON.parse(data)
      // bit control 1
      if (access.bitcontrol1.charAt(2) === '1') {
        this.bitcontrol1_access.add = true
      }
    },
    toPending () {
      this.$router.push({
        name: 'farmasi.pending'
      })
    },
    nameWithKode ({ kode, namaBarang }) {
      return `${kode} - ${namaBarang}`
    },
    filterchange () {
      this.$refs.table.refresh()
    },
    async list (ctx) {
      let sortfield = ''
      let sorting = ''
      let filterType = this.selected.filterType ? this.selected.filterType : ''
      let userid = xray.currentUser().id.toString()
      let obat = this.tableparam.obat
      let norm = this.tableparam.norm
      let tglawal = this.tableparam.tgl_awal
      let tglakhir = this.tableparam.tgl_akhir
      if (ctx.filter === '') {
        ctx.filter = null
      }
      if (ctx.sortBy) {
        sortfield = ctx.sortBy
      } else {
        sortfield = ''
      }
      if (ctx.sortDesc === true) {
        sorting = 'ASC'
      } else if (ctx.sortDesc === false) {
        sorting = 'DESC'
      } else {
        sorting = ''
      }
      this.table.isBusy = true
      try {
        const response = await Service.katalog.listIterasi(ctx.currentPage - 1, ctx.perPage, ctx.filter, filterType, sortfield, sorting, userid, obat, norm, tglawal, tglakhir)
        this.items = response.data.data
        this.table.isBusy = false
        this.table.totalRows = response.data.pagination.total
        this.table.perPage = response.data.pagination.perPage
        this.table.currentPage = response.data.pagination.current
        return response.data.data
      } catch (error) {
        this.table.isBusy = false
        return []
      }
    },
    specificColumn (item) {
      this.tableiterasi.detail[0].id_farmasi = item.ID.toString()
      this.tableiterasi.detail[0].diberikan = item.diberikan.toString()
      this.tableiterasi.detail[0].pinjam = item.PINJAM.toString()
      if (Object.entries(this.tableiterasi.detail).length !== 0) {
        this.senditer(item.ID)
      } else {
        this.message = 'ERR'
      }
    },
    async senditer (ids) {
      var id = ids
      var data = this.tableiterasi
      this.isLoading = true
      try {
        const response = await Service.katalog.updateIterTable(id, data)
        if (response.data.statcode === 0) {
          this.isLoading = false
          this.messageVal = '1'
          this.message = response.data.message
          this.$refs.table.refresh()
        } else {
          this.isLoading = false
          this.messageVal = '0'
          this.message = response.data.message
        }
      } catch (error) {
        this.isLoading = false
        this.messageVal = '0'
        this.message = error.message
      }
    },
    specificEmpty (item) {
      this.selected.pabrik_2 = []
      this.messageModal = ''
      this.error = ''
      this.display.resep = 'No. Resep : ' + item.nomor_resep
      this.tableEmpty.ruangan = item.TUJUAN
      this.tableEmpty.kunjungan = item.kunjungan
      this.tableEmpty.farmasi = item.ID
      this.tableEmpty.norm = item.NORM.toString()
      this.tableEmpty.jns_pending = '4'
      this.tableEmpty.qty_pending = ''
      this.tableEmpty.tgl_pending = this.getDateAuto(item.TANGGAL)
      this.tableEmpty.userid_pending = xray.currentUser().id.toString()
      this.tableEmpty.order_id = item.nomor_resep
      this.$refs['modal-pending-empty'].show()
    },
    async sendEmpty (bvModalEvent) {
      bvModalEvent.preventDefault()
      this.isLoading = true
      try {
        const data = this.tableEmpty
        const response = await Service.katalog.createEmpty(data)
        if (response.data.statcode === 0) {
          this.isLoading = false
          this.messageModal = response.data.message
          this.$refs['modal-pending-empty'].hide()
          this.$refs['modal-sukses'].show()
        } else {
          this.isLoading = false
          this.messageModal = response.data.message
        }
      } catch (error) {
        this.isLoading = false
        this.messageModal = error.message
      }
    },
    barangEmpty (value) {
      this.tableEmpty.id_katalog = value.id_katalog.toString()
      this.tableEmpty.id_pabrik = value.id_pabrik.toString()
    },
    loadtable () {
      this.$refs.table.refresh()
    },
    resetForm () {
      this.tableparam.obat = ''
      this.tableparam.norm = ''
      this.tableparam.tgl_awal = ''
      this.tableparam.tgl_akhir = ''
      this.selected.pabrik = []
      this.$refs.table.refresh()
    },
    getDateAuto (value) {
      const date = value === undefined || value === null || value === '' ? new Date() : new Date(value)
      let day = date.getDate()
      let month = (date.getMonth() + 1).toString().padStart(2, 0)
      let year = date.getFullYear()
      if (day < 10) {
        day = '0' + day
      }
      return year + '-' + month + '-' + day
    },
    valuebarangiter (value) {
      this.tableparam.obat = value.id_katalog
    },
    valuepabrik ($event, index) {
      this.pendingform.detail[index].id_katalog_selesai = $event.id_katalog.toString()
      this.pendingform.detail[index].id_pabrik_selesai = $event.id_pabrik.toString()
    },
    valueChck (e) {
      if (e === '1') {
        this.display.pendingObat = false
      } else if (e === '0') {
        this.pendingform.qty_selesai = this.display.form.qty_pending.toString()
        this.pendingformview.obat = this.display.form.nama_barang
        this.pendingform.id_katalog_selesai = this.display.form.id_katalog.toString()
        this.pendingform.id_pabrik_selesai = this.display.form.id_pabrik.toString()
        this.selected.pabrik = { id: this.display.form.id_katalog, kode: this.display.form.kode, namaBarang: this.display.form.nama_barang }
        this.display.pendingObat = true
      }
    },
    calculate ($event, jmlh, index) {
      this.items[index].diberikan = $event
      var vTotal = jmlh.replace(/\.\d+/g, '') - $event
      var vTotalDot = vTotal + '.'
      this.items[index].TA = vTotalDot.replace(/\.$/, '.00')
    },
    isNumberKey ($event) {
      $event = ($event) || window.event
      var charCode = ($event.which) ? $event.which : $event.keyCode
      if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
        $event.preventDefault()
      } else {
        return true
      }
    },
    async modalIter (a, b, c, d, e) {
      this.disable.iter1 = 0
      this.disable.iter2 = 0
      this.flagiter = b
      this.display.tglItems = d
      this.display.resep = 'Pending Iterasi : ' + e
      try {
        const response = await Service.katalog.showIter(a)
        if (response.data.statcode === 0) {
          this.id = response.data.data[0].ID
          this.formiterasi.id_farmasi = response.data.data[0].ID_FARMASI
          this.formiterasi.kunjungan = response.data.data[0].KUNJUNGAN
          this.formiterasi.iterasi1 = response.data.data[0].ITERASI1.toString()
          this.formiterasi.iterasi2 = response.data.data[0].ITERASI2.toString()
          if (response.data.data[0].ITERASI1 !== 0) {
            this.disable.iter1 = 1
            this.display.tglIterasi1 = this.tglIterasi(response.data.data[0].TANGGAL_ITERASI1, '1')
          } else {
            this.disable.iter1 = 0
            this.display.tglIterasi1 = ''
            this.formiterasi.iterasi1_tanggal = ''
          }
          if (response.data.data[0].ITERASI2 !== 0) {
            this.disable.iter2 = 1
            this.display.tglIterasi2 = this.tglIterasi(response.data.data[0].TANGGAL_ITERASI2, '2')
          } else {
            this.disable.iter2 = 2
            this.display.tglIterasi2 = ''
            this.formiterasi.iterasi2_tanggal = ''
          }
        } else {
          this.id = '00'
          this.disable.iter1 = 0
          this.disable.iter2 = 0
          this.formiterasi.id_farmasi = a
          this.formiterasi.kunjungan = c
          this.formiterasi.iterasi1 = '0'
          this.formiterasi.iterasi2 = '0'
          this.display.tglIterasi1 = ''
          this.display.tglIterasi2 = ''
          this.formiterasi.iterasi1_tanggal = ''
          this.formiterasi.iterasi2_tanggal = ''
        }
      } catch (error) {
        this.error = error.message
      }
      this.$refs['modal-iter-show'].show()
    },
    async updateIter (bvModalEvent) {
      bvModalEvent.preventDefault()
      this.isLoading = true
      try {
        const id = this.id.toString()
        const data = this.formiterasi
        const response = await Service.katalog.updateIter(id, data)
        if (response.data.statcode === 0) {
          this.isLoading = false
          this.clearForm()
          this.$refs['modal-iter-show'].hide()
          this.$refs.MsgBox.show({
            type: 'success',
            msg: response.data.message
          })
        } else {
          this.isLoading = false
          this.error = response.data.message
        }
      } catch (error) {
        this.isLoading = false
        this.error = error.message
      }
    },
    deleteModal (id) {
      this.id = id
      this.$refs['modal-konfirm-delete-hard'].show()
    },
    async handleDelete () {
      const id = this.id
      const response = await Service.katalog.deleteStockOpname(id)
      if (response.data.statcode === 0) {
        this.error = response.data.message
        this.$refs.table.refresh()
      } else {
        this.error = response.data.message
        this.$refs.table.refresh()
      }
    },
    modaliter () {
      this.$refs['pend#pending-farmasi-tambah'].show()
    },
    async getPabrik ($event) {
      if ($event.length >= 2) {
        await Service.katalog.getPabrikStokOP_v2($event).then(response => {
          if (response.status === 200) {
            this.combo.pabrik = response.data.data
          }
        }).catch(error => {
          this.error = error.response.data.errors.message
        })
      } else {
        this.combo.pabrik = []
      }
    },
    onFiltered (filteredItems) {
      this.table.totalRows = filteredItems.length
    },
    tableRefresh () {
      this.$refs.table.refresh()
    },
    tglConvert (value, param) {
      var date = new Date(value)
      let day = date.getDate()
      let month = (date.getMonth() + 1).toString().padStart(2, 0)
      let year = date.getFullYear()
      if (month < 10) {
        month = '0' + month
      }
      if (day < 10) {
        day = '0' + day
      }
      if (param === '1') {
        let joinDate = year + '-' + month + '-' + day
        return joinDate
      } else if (param === '2') {
        let joinDate = day + '-' + month + '-' + year
        return joinDate
      }
    },
    tglIterasi (value, param) {
      if (value !== '' || value !== null || value !== undefined) {
        var current = ''
        var day = ''
        var month = ''
        var year = ''
        var final = ''
        current = new Date(value)
        if (param === '1') {
          current.setDate(current.getDate() + 30)
        } else {
          current.setDate(current.getDate() + 60)
        }
        day = current.getDate()
        month = (current.getMonth() + 1).toString().padStart(2, 0)
        year = current.getFullYear()
        if (day < 10) {
          day = '0' + day
        }
        final = day + '-' + month + '-' + year
        if (param === '1') {
          this.formiterasi.iterasi1_tanggal = year + '-' + month + '-' + day
        } else {
          this.formiterasi.iterasi2_tanggal = year + '-' + month + '-' + day
        }
        return final
      } else {
        return ''
      }
    },
    valueIterasi ($event, param) {
      if ($event === '1') {
        var current = new Date(this.display.tglItems)
        var day = ''
        var month = ''
        var year = ''
        if (param === '1') {
          current.setDate(current.getDate() + 30)
        } else {
          current.setDate(current.getDate() + 60)
        }
        day = current.getDate()
        month = (current.getMonth() + 1).toString().padStart(2, 0)
        year = current.getFullYear()
        if (day < 10) {
          day = '0' + day
        }
        if (param === '1') {
          this.display.tglIterasi1 = day + '-' + month + '-' + year
          this.formiterasi.iterasi1_tanggal = year + '-' + month + '-' + day
        } else {
          this.display.tglIterasi2 = day + '-' + month + '-' + year
          this.formiterasi.iterasi2_tanggal = year + '-' + month + '-' + day
        }
      } else {
        if (param === '1') {
          this.display.tglIterasi1 = ''
          this.formiterasi.iterasi1_tanggal = ''
        } else {
          this.display.tglIterasi2 = ''
          this.formiterasi.iterasi2_tanggal = ''
        }
      }
    },
    valueTglItr1 (value) {
      this.formiterasi.iterasi1_tanggal = value.split('-').reverse().join('-')
    },
    valueTglItr2 (value) {
      this.formiterasi.iterasi2_tanggal = value.split('-').reverse().join('-')
    },
    clearForm () {
      this.error = ''
      this.messageVal = ''
      this.message = ''
      this.selected.pabrik_2 = []
    },
    deleteRow (index) {
      this.pendingform.detail.splice(index, 1)
      this.pendingformview.detail.splice(index, 1)
      this.selected.barang.splice(index, 1)
    },
    tglWithDots (item) {
      return item['tp.tgl_pending']
    }
  }
}
</script>

<style>
/* #modal-pending .modal-body, #modal-pending-edit .modal-body, #modal-filter .modal-body {
  background: #3399331b;
} */
#pending-farmasi-tambah input[type="text"][disabled],
#modal-pending input[type="text"][disabled],
#modal-pending-edit input[type="text"][disabled],
#modal-pending-empty input[type="text"][disabled],
#pending-farmasi-tambah input[type="text"][disabled] {
   background: #e9ecef;
}

#pending-farmasi-tambah .border-salah {
  border: 2px solid red!important;
}

#modal-pending-empty .table-pending-farmasi-tambah, #modal-pending .table-pending-farmasi-tambah, #modal-pending-edit .table-pending-farmasi-tambah, #pending-farmasi-tambah .table-pending-farmasi-tambah {
  height: auto;
}

#modal-pending-empty .multiselect, #modal-pending .multiselect, #modal-pending-edit .multiselect, #pending-farmasi-tambah .multiselect {
  min-height: 33px;
}

#modal-pending-empty .multiselect__single, #modal-pending .multiselect__single, #modal-pending-edit .multiselect__single, #pending-farmasi-tambah .multiselect__single {
  font-size: 0.9em!important;
  font-weight: normal!important;
  line-height: 26px;
}
#modal-pending-empty .multiselect__placeholder, #modal-pending .multiselect__placeholder, #modal-pending-edit .multiselect__placeholder, #pending-farmasi-tambah .multiselect__placeholder {
  font-size: 0.9em!important;
  font-weight: normal;
}
#modal-pending-empty .multiselect__option, #modal-pending .multiselect__option, #modal-pending-edit .multiselect__option, #pending-farmasi-tambah .multiselect__option {
  font-size: .8em!important;
}
#modal-pending-empty .multiselect__tags, #modal-pending .multiselect__tags, #modal-pending-edit .multiselect__tags, #pending-farmasi-tambah .multiselect__tags {
  padding: 1px 40px 0 8px!important;
  height: 33px!important;
  min-height: 33px!important;
  font-size: 0.9em!important;
  overflow: hidden;
}

#modal-pending-empty .multiselect--disabled .multiselect__tags, #modal-pending-edit .multiselect--disabled .multiselect__tags, #modal-pending .multiselect--disabled .multiselect__tags, #pending-farmasi-tambah .multiselect--disabled .multiselect__tags {
  border: 1px solid rgba(0, 0, 0, 0.171);
  background: #E9ECEF;
}

#modal-pending-empty .multiselect--disabled, #modal-pending-edit .multiselect--disabled, #modal-pending .multiselect--disabled, #pending-farmasi-tambah .multiselect--disabled  {
  opacity: 1;
}

#modal-pending-empty .multiselect--disabled .multiselect__tags .multiselect__single, #modal-pending-edit .multiselect--disabled .multiselect__tags .multiselect__single, #modal-pending .multiselect--disabled .multiselect__tags .multiselect__single, #pending-farmasi-tambah .multiselect--disabled .multiselect__tags .multiselect__single {
  background: transparent;
}

#modal-pending-empty .multiselect__select, #modal-pending-edit .multiselect__select, #modal-pending .multiselect__select, #pending-farmasi-tambah .multiselect__select {
  height: 30px;
}

#modal-pending-empty .disable-bg, #modal-pending-edit .disable-bg, #modal-pending .disable-bg, #pending-farmasi-tambah .disable-bg {
  background: #e9ecef;
  padding-top: 5px;
  padding-bottom: 5px;
}

#modal-pending-empty .flatpickr-input[readonly], #modal-pending-edit .flatpickr-input[readonly],#modal-pending .flatpickr-input[readonly], #modal-pending-edit .flatpickr-input[readonly], #pending-farmasi-tambah .flatpickr-input[readonly] #modal-filter .flatpickr-input[readonly] {
  height: 33px;
  font-size: 13px;
  border-radius: 5px;
  border: 1px solid rgba(128, 128, 128, 0.292);
  padding-left: 10px;
  color: black;
}

#modal-pending-empty .cstm-cntrl-stockopname, #modal-pending .cstm-cntrl-stockopname, #modal-pending-edit .cstm-cntrl-stockopname, #pending-farmasi-tambah .cstm-cntrl-stockopname, #modal-filter .cstm-cntrl-stockopname {
  height: 38px!important;
  font-size: 13px!important;
  color: black!important;
  border-radius: 3px;
}

#modal-pending .modal-dialog, #pending-farmasi-tambah .modal-dialog {
  min-width: 95%!important;
}

#modal-iter-show .modal-dialog, #modal-pending-empty .modal-dialog {
  min-width: 10%!important;
}

#modal-pending-empty .modal-body,#modal-pending .modal-body, #modal-pending-edit .modal-body, #pending-farmasi-tambah .modal-body {
  background: #3399331b;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

#modal-pending-empty .modal-header, #modal-pending .modal-header, #modal-pending-edit .modal-header, #pending-farmasi-tambah .modal-header {
  background: #3399333a;
}

#modal-pending-empty .modal-footer, #modal-pending .modal-footer, #modal-pending-edit .modal-footer, #pending-farmasi-tambah .modal-footer {
  background: #3399333a;
}
#modal-pending-empty .cstm-cntrl-stockopname, #modal-pending .cstm-cntrl-stockopname, #modal-pending-edit .cstm-cntrl-stockopname, #pending-farmasi-tambah .cstm-cntrl-stockopname {
  border-radius: 5px!important;
  font-size: 13px!important;
  height: 34px!important;
}
#modal-pending-empty .cstm-slct-stockopname, #modal-pending .cstm-slct-stockopname, #modal-pending-edit .cstm-slct-stockopname, #pending-farmasi-tambah .cstm-slct-stockopname  {
  font-size: 13px!important;
  height: 38px!important;
  border-radius: 5px;
}

#pending-farmasi-tambah .bg-filter {
  border: 1px solid rgba(0, 0, 0, 0.13);
  background-color: #089bab1e;
}

#header-table-filter .flatpickr-input[readonly] {
  height: 38px;
  font-size: 12px;
  border-radius: 5px;
  border: 1px solid rgba(17, 17, 17, 0.292);
  padding-left: 10px;
}

#pending-farmasi-tambah legend {
    color: darkslategrey;
}
#pending-farmasi-tambah .form-control:disabled, #pending-farmasi-tambah .form-control[readonly] {
    background-color: #e9ecef;
    opacity: 0.5;
}
#pending-farmasi-tambah .form-control {
  color: black!important;
}
#pending-farmasi-tambah .fc-unthemed .fc-toolbar .fc-button {
    margin: 3px;
}
#pending-farmasi-tambah tr:first-child > td > .fc-day-grid-event {
    cursor: pointer;
}

#pending-farmasi-tambah .flatpickr-input[readonly], #pending-farmasi-tambah .flatpickr-input[readonly] {
  height: 34px;
  font-size: 12px;
  border-radius: 5px;
  border: 1px solid rgba(17, 17, 17, 0.292);
  padding-left: 10px!important;
}

#pending-farmasi-tambah .flex-half-width-left {
  width: 76%;
}
#pending-farmasi-tambah .flex-half-width-right {
  width: 23%;
}
#pending-farmasi-tambah .flex-full-width-left {
  width: 96%;
}
#pending-farmasi-tambah .flex-full-width-right {
  width: 3%;
}
#pending-farmasi-tambah .txt-farmasi-base {
  font-size: 12px;
}
#pending-farmasi-tambah .txt-farmasi-base-head {
  font-size: 14px;
}
#pending-farmasi-tambah .table td, #pending-farmasi-tambah .table td {
    font-size: 12px;
    font-weight: 500;
    padding-top: 0px;
    padding-bottom: 0px;
}
#pending-farmasi-tambah .table th, #pending-farmasi-tambah .table th {
    font-size: 13px;
    font-weight: 400;
}
#pending-farmasi-tambah .table td {
    font-size: 12px;
    font-weight: 500;
    padding-top: 0px;
    padding-bottom: 0px;
}
#pending-farmasi-tambah .table th {
    font-size: 13px;
    font-weight: 400;
}
#pending-farmasi-tambah .form-control {
    border: 1px solid rgb(194, 194, 194);
    background: white;
    height: 40px;
}

#pending-farmasi-tambah .btn-height-custom {
    height: 39px;
}
#pending-farmasi-tambah .cursor-point {
    cursor: pointer;
}
#pending-farmasi-tambah .bg-abu {
  background: #e9ecef;
}
#pending-farmasi-tambah .label-brg  {
  font-size: 13px;
  padding-left: 14px;
  padding-right: 14px;
  border: 1px solid rgba(0, 0, 0, 0.13);
  border-radius: 3px;
}
#pending-farmasi-tambah .form-control {
    font-size: 11px;
}
#pending-farmasi-tambah .b-table-sticky-header, #pending-farmasi-tambah .table-responsive {
    margin-bottom: 0px;
}
#pending-farmasi-tambah .table-barang .btn {
    font-size: 11px;
}
#pending-farmasi-tambah .page-link {
    border: none;
    color: gray!important;
    margin-right: 15px;
}
#pending-farmasi-tambah .page-item.active .page-link {
    background-color: transparent;
    border-color: transparent;
    color: #089bab!important;
    font-weight: bold;
}
#pending-farmasi-tambah .vertical-txt {
  writing-mode: vertical-rl;
  letter-spacing: 10px;
}
#pending-farmasi-tambah .transition-cstm {
  transition: .3s linear;
}

#pending-farmasi-tambah .form-control:disabled, #pending-farmasi-tambah .form-control[readonly] {
  background-color: #e9ecef;
  opacity: 1;
}

#pending-farmasi-tambah .cstm-srch-stock, #pending-farmasi-tambah .cstm-srch-stock {
  font-size: 13px;
  height: 38px !important;
  border-radius: 5px;
}

#pending-farmasi-tambah .cstm-slct-stock {
  font-size: 13px;
  height: 38px !important;
  border-radius: 5px;
}
.alert-radius-stockopname {
  border-radius: 5px;
}

.font-size-responsive {
  font-size: 7px;
}

.pagination-pending .page-link, #pending-farmasi-tambah .pagination-pending .page-link {
    border: none;
    color: gray!important;
    margin-right: 15px;
}
.pagination-pending .page-item.active .page-link, #pending-farmasi-tambah .pagination-pending .page-item.active .page-link {
    background-color: transparent;
    border-color: transparent;
    color: #089bab!important;
    font-weight: bold;
}

@media (min-width: 576px) {
  .font-size-responsive {
    font-size: 8px;
  }
 }

@media (min-width: 768px) {
  .font-size-responsive {
    font-size: 9px;
  }
 }

@media (min-width: 992px) {
  .font-size-responsive {
    font-size: 10px;
  }
 }

@media (min-width: 1200px) {
  .font-size-responsive {
    font-size: 11px;
  }
 }
 .pointer-table tbody tr {
  cursor: pointer;
}
</style>
