<template>
  <div id="bios-bank" class="mb-4">
    <b-container fluid>
      <div>
        <iq-card>
          <template v-slot:body>
            <div class="filter-brand border p-2">
              <b-row class="align-items-center">
                <b-col>
                  <div>
                    <h5 class="card-title font-weight-bold mb-0">Master Bank</h5>
                  </div>
                </b-col>
                <b-col>
                  <div class="text-right">
                    <b-button class="custom-button-radius" size="sm" variant="primary" id="show-btn" @click="showModal"><i
                        class="fas fa-plus"></i>Tambah</b-button>
                  </div>
                </b-col>
              </b-row>
            </div>
            <div class="table mb-0">
              <b-table
                class="mb-0 pointer-table"
                ref="table"
                :sort-by.sync="table.sortBy"
                :filter="table.filter"
                @filtered="onFiltered"
                @row-clicked="openDetail"
                :sort-desc.sync="table.sortDesc"
                :busy="table.isBusy"
                :current-page="table.currentPage"
                :per-page="table.perPage"
                stacked="md" bordered hover show-empty striped
                :items="table.items"
                :fields="table.fields">
                <template #cell(index)="data">
                  <div class="py-2">
                    {{ (table.currentPage*table.perPage)-table.perPage + data.index + 1 }}
                  </div>
                </template>
                <template #cell(status)="data">
                  <span class="text-success" v-if="data.item.status === 1 || data.item.status === '1'">Aktif</span>
                  <span class="text-danger" v-else >Tidak Aktif </span>
                </template>
              </b-table>
            </div>
            <div class="pagination-bank-bios border shadow-sm p-2 mb-2">
              <b-row class="align-items-center">
                <b-col cols="4" class="d-flex flex-row align-items-center">
                  <div class="w-100">
                    <b-pagination v-model="table.currentPage" :total-rows="table.totalRows" :per-page="table.perPage"
                      size="md" class="my-0"></b-pagination>
                  </div>
                  <div class="w-100">
                    <div>
                      <span class="border-left border-dark mr-3 py-1"></span>
                      <span @click="tableRefresh" class="katalog-cursor">
                        <i class="fas fa-sync text-secondary"></i>
                      </span>
                      <span class="border-right border-dark ml-3 py-1"></span>
                    </div>
                  </div>
                </b-col>
                <b-col cols="5">
                  <div class="d-flex flex-row justify-content-end align-items-center">
                    <div class="mr-1 w-75">
                      <form class="searchbox mb-0">
                        <input v-model="table.filter" type="search" class="form-control cstm-srch-brand" id="filter-input"
                          placeholder="[Cari]">
                      </form>
                    </div>
                    <div>
                      <b-form-select class="cstm-slct-brand" v-model="selected.filter" :options="options.optionsFilter"
                        @change="filterchange"></b-form-select>
                    </div>
                  </div>
                </b-col>
                <b-col cols="3">
                  <div class="d-flex flex-row align-items-center justify-content-end">
                    <div class="mr-3">
                      <b-form-select
                        class="cstm-select-rekening"
                        id="per-page-select"
                        v-model="table.perPage"
                        :options="table.pageOptions"
                      ></b-form-select>
                    </div>
                    <div>
                      <p class="text-dark mb-0">Menampilkan {{ table.perPage }} dari {{ table.totalRows }} baris</p>
                    </div>
                  </div>
                </b-col>
              </b-row>
            </div>
          </template>
        </iq-card>
      </div>
    </b-container>
    <b-modal id="modal-tambah-bank" ref="modal-tambah-bank" size="xl" centered :no-close-on-backdrop="true" :static="true"
      title="Tambah Data Master Bank" ok-title="Simpan Data" @close="clearData" @cancel="clearData" scrollable
      cancel-title="Tutup" @ok="tambahSave">
      <hr class="m-0">
      <div>
        <b-row>
          <b-col cols="12">
            <div class="table-responsive-xl">
              <table class="table bg-white table-bordered table-sm m-0 p-0">
                <thead>
                  <tr>
                    <th scope="col" style="vertical-align: center;" class="text-center">
                      <p class="mb-0 font-weight-normal text-center">Nomor</p>
                    </th>
                    <th scope="col" style="vertical-align: center;" class="text-center">
                      <p class="mb-0 font-weight-normal text-center">Nama bank</p>
                    </th>
                    <th scope="col" style="vertical-align: center;" class="text-center">
                      <p class="mb-0 font-weight-normal text-center">Status</p>
                    </th>
                    <th scope="col" style="vertical-align: center;" class="text-center">
                      <b-button variant="primary" @click="addRow">
                        <p class="h5 mb-0">+</p>
                      </b-button>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(k, index) in tambahTransaksi.detail" v-bind:key="k.index">
                    <td class="py-3">
                      <div>
                        <p class="text-center font-weight-bold mb-0">{{ index + 1 }}</p>
                      </div>
                    </td>
                    <td class="col-8">
                      <div>
                        <div class="w-100">
                          <multiselect :show-labels="false" :custom-label="selectLabelBank" placeholder="[ nama bank ]"
                            :options="options.bank" v-model="selected.bank[index]" @input="tabbank($event, index)"
                            :searchable="true" :allow-empty="false">
                          </multiselect>
                        </div>
                      </div>
                    </td>
                    <td class="">
                      <div>
                        <div class="w-100">
                          <multiselect :show-labels="false" :custom-label="selectLabelStatus" placeholder="[ status ]"
                            :options="options.status" v-model="selected.status[index]" @input="tabstatus($event, index)"
                            :searchable="true" :allow-empty="false">
                          </multiselect>
                        </div>
                      </div>
                    </td>
                    <td class="text-center">
                      <b-button variant="white" @click="deleteRow(index)" v-if="index !== 0">
                        <p class="mb-0"><i class="fas fa-trash-alt text-danger"></i></p>
                      </b-button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </b-col>
        </b-row>
      </div>
    </b-modal>
    <ModalMsgbox ref="MsgBox" />
  </div>
</template>
<script>
import { xray } from '../../../../config/pluginInit'
import Multiselect from 'vue-multiselect'
import ModalMsgbox from '../../../../components/modal/ModalMsgBox'
import BIOS from '../../../../services/fatmahost/bios'

export default {
  components: {
    Multiselect,
    ModalMsgbox
  },
  mounted () {
    xray.index()
  },
  data () {
    return {
      hidden: false,
      // new saldo bios rekening operasional
      tanggal: '',
      currentDate: '',
      table: {
        filter: '',
        fields: [
          { label: 'No', key: 'index', class: 'text-center col-1' },
          { label: 'Kode Bank', key: 'kd_bank', class: 'text-center col-1' },
          { label: 'Nama Bank', key: 'nama_bank' },
          { label: 'Status', key: 'status', class: 'text-center col-1' }
        ],
        items: this.list,
        currentPage: null,
        pageOptions: [10, 25, 50, 100],
        perPage: null,
        isBusy: false,
        totalRows: null
      },
      options: {
        bank: [],
        status: [
          { text: 'Aktif', value: '1' },
          { text: 'Tidak Aktif', value: '0' }
        ],
        optionsFilter: [
          { text: 'Kode Akun', value: '1' },
          { text: 'Uraian', value: '2' }
        ]
      },
      selected: {
        bank: [],
        status: [],
        filter: '1'
      },
      tambahTransaksi: {
        object: 'bank',
        detail: []
      }
    }
  },
  methods: {
    // eslint-disable-next-line camelcase
    selectLabelBank ({ kd_bank, nama_bank }) {
      // eslint-disable-next-line camelcase
      return `${kd_bank} - ${nama_bank}`
    },
    // eslint-disable-next-line camelcase
    selectLabelStatus ({ text }) {
      // eslint-disable-next-line camelcase
      return `${text}`
    },
    async list (ctx) {
      this.isBusy = true
      let filters = ''
      let filterType = this.selected.filter
      if (ctx.filter === '') {
        ctx.filter = null
        filters = `{"xparam0":"","xparam1":"","xparam2":"","xparam3":"","xparam4":""}`
      }
      if (ctx.filter === null) {
        filters = `{"xparam0":"","xparam1":"","xparam2":"","xparam3":"","xparam4":""}`
      } else {
        if (filterType === '1') {
          filters = `{"xparam0":"${ctx.filter}","xparam1":"","xparam2":"","xparam3":"","xparam4":""}`
        } else if (filterType === '2') {
          filters = `{"xparam0":"","xparam1":"${ctx.filter}","xparam2":"","xparam3":"","xparam4":""}`
        }
      }
      try {
        const response = await BIOS.bankFindAll(ctx.currentPage - 1, ctx.perPage, filters, '{"xparam0":"status","xparam1":"DESC"}')
        this.isBusy = false
        if (response.data.statcode === 20001) {
          return []
        }
        this.isBusy = false
        this.table.totalRows = response.data.pagination.total
        this.table.currentPage = response.data.pagination.current
        this.table.perPage = response.data.pagination.perPage
        return response.data.data
      } catch (error) {
        this.isBusy = false
        return []
      }
    },
    onFiltered (filteredItems) {
      this.table.totalRows = filteredItems.length
    },
    filterchange () {
      this.table.filter = ''
    },
    tableRefresh () {
      this.$refs.table.refresh()
    },
    tabbank ($event, index) {
      this.tambahTransaksi.detail[index].id = $event.id
    },
    tabstatus ($event, index) {
      this.tambahTransaksi.detail[index].status = $event.value
    },
    openDetail (items) {
      this.showModal()
    },
    clearData () {
      this.tambahTransaksi.detail = []
      this.selected.bank = []
      this.selected.status = []
    },
    tglEvent ($event, param) {
      const date = new Date($event)
      let day = date.getDate()
      let month = (date.getMonth() + 1).toString().padStart(2, 0)
      let year = date.getFullYear()
      if (day < 10) {
        day = '0' + day
      }
      let currentDate = year + '-' + month + '-' + day
      this.currentDate = currentDate
    },
    showModal () {
      this.clearData()
      this.showDataActive()
      this.$refs['modal-tambah-bank'].show()
    },
    async showDataActive () {
      const response = await BIOS.bankFindAll(0, 1000, '{"xparam0":"","xparam1":"","xparam2":"","xparam3":"","xparam4":""}', '{"xparam0":"","xparam1":""}')
      const data = response.data.data
      const bankActive = data.filter(item => {
        return item.status === 1
      })
      this.options.bank = data
      bankActive.map(el => {
        this.tambahTransaksi.detail.push({
          id: el.id,
          status: el.status,
          updated_in: xray.currentUser().id
        })
        this.selected.bank.push({
          kd_bank: el.kd_bank, nama_bank: el.nama_bank
        })
        this.selected.status.push({
          text: (el.status === '1' || el.status === 1 ? 'Aktif' : 'Tidak Aktif'), value: el.status
        })
      })
    },
    async tambahSave (bvModalEvent) {
      bvModalEvent.preventDefault()
      try {
        const response = await BIOS.bankUpdate(this.tambahTransaksi)
        if (response.data.statcode === 0) {
          this.$refs['modal-tambah-bank'].hide()
          this.$refs.MsgBox.show({
            type: 'success',
            msg: response.data.message
          })
        } else {
          this.$refs['modal-tambah-bank'].hide()
          this.$refs.MsgBox.show({
            type: 'error',
            msg: response.data.message
          })
        }
      } catch (error) {
        this.$refs['modal-tambah-bank'].hide()
        this.$refs.MsgBox.show({
          type: 'error',
          msg: 'Data gagal diubah.'
        })
      }
      this.tableRefresh()
    },
    deleteModal (id) {
      this.$refs['modal-konfirm-delete'].show()
    },
    addRow () {
      this.tambahTransaksi.detail.push(
        {
          id: '',
          status: '',
          updated_in: xray.currentUser().id
        }
      )
    },
    deleteRow (index) {
      this.tambahTransaksi.detail.splice(index, 1)
    }
  }
}
</script>
<style>
#modal-tambah-bank .height-static-pabrik {
  /* max-height: 12em;
 overflow-x: hidden; */
  overflow: auto;
}

#modal-tambah-bank .multiselect, #modal-edit-bank .multiselect {
  min-height: 33px;
}

#modal-tambah-bank .multiselect__single, #modal-edit-bank .multiselect__single {
  font-size: 0.9em!important;
  font-weight: normal!important;
  line-height: 26px;
}
#modal-tambah-bank .multiselect__placeholder, #modal-edit-bank .multiselect__placeholder {
  font-size: 0.9em!important;
  font-weight: normal;
}
#modal-tambah-bank .multiselect__option, #modal-edit-bank .multiselect__option {
  font-size: 0.9em;
}
#modal-tambah-bank .multiselect__tags, #modal-edit-bank .multiselect__tags {
  overflow: hidden;
  padding: 1px 40px 0 8px!important;
  height: 33px!important;
  min-height: 33px!important;
}

#modal-edit-bank .multiselect--disabled .multiselect__tags {
  overflow: hidden;
  background: #E9ECEF;
}

#modal-edit-bank .multiselect--disabled {
  opacity: 1;
}

#modal-edit-bank .multiselect--disabled .multiselect__tags .multiselect__single {
  overflow: hidden;
  background: transparent;
}

#modal-edit-bank .multiselect__select {
    height: 30px;
}

.new-cstm-cntrl-inventori {
  background: white!important;
  color: black!important;
  border-radius: 5px!important;
  font-size: 12px!important;
  height: 33px!important;
}

#modal-tambah-bank .border-merah {
  border: 2px solid red;
}

#modal-tambah-bank .border-hijau {
  border: 2px solid green;
}

#modal-tambah-bank .modal-body {
  background: #3399331b;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

#modal-tambah-bank .modal-header {
  background: #3399333a;
}

#modal-tambah-bank .modal-footer {
  background: #3399333a;
}

#modal-edit-bank .border-merah {
  border: 2px solid red;
}

#modal-edit-bank.border-hijau {
  border: 2px solid green;
}

#modal-edit-bank .modal-body {
  background: #3399331b;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

#modal-edit-bank .modal-header {
  background: #3399333a;
}

#modal-edit-bank .modal-footer {
  background: #3399333a;
}

#bios-bank .custom-button-radius {
  border: 1px solid rgba(0, 0, 0, 0.112);
  border-radius: 5px !important;
}

#bios-bank .table td {
    font-size: 12px;
    font-weight: 500;
    padding-top: 0px;
    padding-bottom: 0px;
}
#bios-bank .table th {
    font-size: 13px;
    font-weight: 400;
}

#bios-bank .table {
  text-align: left;
}

#bios-bank .cstm-select-Inventory {
  font-size: 13px;
  height: 38px !important;
  border-radius: 5px;
}

#bios-bank .cstm-srch-brand {
  font-size: 13px;
  height: 38px !important;
  border-radius: 5px;
}

#bios-bank .cstm-slct-brand {
  font-size: 13px;
  height: 38px !important;
  border-radius: 5px;
}

#bios-bank .pagination-bank-bios .page-link {
  border: none;
  color: gray !important;
  margin-right: 15px;
}

#bios-bank .page-item.active .page-link {
    background-color: transparent;
    border-color: transparent;
}

#bios-bank .pagination-bank-bios .page-item.active .page-link {
  background-color: transparent;
  border-color: transparent;
  color: #089bab !important;
  font-weight: bold;
}

#modal-tambah-bank .custom-flatpicker, #modal-edit-bank .custom-flatpicker {
    border: 1px solid rgb(233, 233, 233);
    height: 33px;
    padding-left: 10px;
    padding-top: 5px;
    padding-bottom: 5px;
    border: 1px solid rgb(209, 208, 208)!important;
    border-radius: 5px;
    border: 1px solid rgb(228, 228, 228);
    font-size: 12px;
    color: black;
}

.pointer-table tbody tr {
  cursor: pointer;
}
</style>
