<template>
  <div id="menu-module" class="mb-4">
    <div v-show="isLoading">
    <div id="show-overlay"></div>
    <Loader :style="{ opacity: .5 }" />
  </div>
    <b-container fluid class="mb-5">
      <iq-card>
        <div class="px-4 py-3 d-flex align-items-center justify-content-between">
          <div>
            <h4 class="card-title  mb-0">Menu Struktur</h4>
          </div>
          <div>
            <b-button variant="primary" @click="modalModul('', '1')"><i class="fas fa-plus"></i> Tambah Module</b-button>
          </div>
        </div>
        <hr class="m-0 p-0">
        <div class="body p-4">
          <div class="table mb-0">
              <b-table class="mb-0" ref="table" :busy="table.isBusy" :filter="table.filter"
                  @filtered="onFiltered" :items="table.items" :fields="table.fields"
                :current-page="table.currentPage" :per-page="table.perPage" stacked="md"
                bordered hover show-empty small striped>
                <template #cell(index)="data">
                  {{ (table.currentPage*table.perPage)-table.perPage + data.index + 1 }}
                </template>
                <template #cell(name_updated)="data">
                  {{ (data.item.name_updated === '' ? data.item.name_created : data.item.name_updated) }}
                </template>
                <template #cell(action)="data">
                  <b-button variant="white" class="my-1 mx-1" size="md" @click="modalModul(data.item.id_smenu, '2')">
                    <i class="fas fa-edit text-primary"></i>
                  </b-button>
                  <b-button variant="white" class="my-1 mx-1" size="md" @click="modalModul(data.item.id_smenu, '3')">
                    <i class="fas fa-trash-alt text-danger"></i>
                  </b-button>
                </template>
              </b-table>
            </div>
            <div class="bg-white pb-2 pt-2 pl-3 pr-3 border-bottom border-right border-left">
              <b-row class="align-items-center justify-content-between">
                <b-col cols="12" lg="3" class="mr-auto">
                  <div class="d-flex align-items-center">
                    <div>
                      <b-pagination v-model="table.currentPage" :total-rows="table.totalRows" :per-page="table.perPage" size="sm" class="my-0">
                      </b-pagination>
                    </div>
                    <div class="border-left border-right px-3">
                      <span class="cursor-point" @click="tableRefresh">
                        <i class="fas fa-sync"></i>
                      </span>
                    </div>
                  </div>
                </b-col>
                <b-col cols="12" lg="5">
                  <div class="d-flex flex-row">
                    <div class="mr-2 w-75">
                      <b-input-group>
                        <b-form-input class="cstm-slct-pegawai rounded-0" id="filter-input" v-model="table.filter" type="search" placeholder="Pencarian"></b-form-input>
                      </b-input-group>
                    </div>
                    <div>
                      <b-form-select class="cstm-slct-pegawai" v-model="table.selected.filterType" :options="table.combo.filterType" @change="filterchange"></b-form-select>
                    </div>
                  </div>
                </b-col>
                <b-col cols="12" lg="3">
                  <span class="text-right">
                    <p class="font-weight-normal mb-0">Menampilkan 1 - {{ table.perPage }} dari {{ table.totalRows }}</p>
                  </span>
                </b-col>
              </b-row>
            </div>
        </div>
      </iq-card>
    </b-container>
    <div id="modal-component">
      <b-modal ref="modal-module" id="modal-module" size="md" :title="modalTitle" ok-title="Oke" @ok="proccess" @close="clear" @cancel="clear" :ok-only="true" :no-close-on-backdrop="true" centered>
        <b-form v-if="modulIndex === '1'">
          <div class="mb-2 ">
            <b-row class="align-items-center">
              <b-col cols="4">
                <p class="txt-module-base text-dark mb-0">Nama Sub Menu :</p>
              </b-col>
              <b-col>
                <div>
                  <b-form-input class="cstm-cntrl-module" v-model="created.smenuname" placeholder="Nama Sub Menu"></b-form-input>
                </div>
              </b-col>
            </b-row>
          </div>
        </b-form>
        <b-form v-else-if="modulIndex === '2'">
          <div class="mb-2 ">
            <b-row class="align-items-center">
              <b-col cols="4">
                <p class="txt-module-base text-dark mb-0">Nama Sub Menu :</p>
              </b-col>
              <b-col>
                <div>
                  <b-form-input class="cstm-cntrl-module" v-model="edited.smenuname" placeholder="Nama Sub Menu"></b-form-input>
                </div>
              </b-col>
            </b-row>
          </div>
        </b-form>
        <!-- <div>
          <small class="text-danger">blm bisa create level 1 , id_modules gabisa kosong</small>
          <pre>{{ created }}</pre>
          <pre>{{ edited }}</pre>
        </div> -->
      </b-modal>
      <b-modal ref="modal-delete" size="md" centered title="Konfirmasi" ok-title="Hapus Data" ok-variant="danger" cancel-title="Tutup" @ok="konfirmdelete">
        <div class="py-3">
          <p class="h6 text-center text-dark mb-0">Yakin Ingin Menghapus Data ?</p>
        </div>
      </b-modal>
      <ModalMsgbox ref="MsgBox" />
    </div>
  </div>
</template>
<script>
import { xray } from '../../../config/pluginInit'
import Service from '../../../services/fatmahost/index'
import Loader from '../../../components/xray/loader/Loader'
import ModalMsgbox from '../../../components/modal/ModalMsgBox'

export default {
  name: 'ModuleList',
  components: {
    Loader,
    ModalMsgbox
  },
  data () {
    return {
      messageId: '',
      message: '',
      isLoading: false,
      module: null,
      submodule: null,
      moduleId: '',
      modulIndex: '',
      modalTitle: '',
      disableMultiSelect: false,
      selected: {
        submenu: [],
        modules: [],
        level: []
      },
      combo: {
        submenu: [],
        modules: [],
        level: [
          { deskripsi: '1', id: '1' },
          { deskripsi: '2', id: '2' }
        ]
      },
      table: {
        isBusy: false,
        items: this.list,
        fields: [
          { label: 'No', key: 'index', class: 'text-center' },
          { label: 'Struktur Sub Menu', key: 'smenuname' },
          { label: 'User Update', key: 'name_updated' },
          { label: 'Aksi', key: 'action', class: 'text-center' }
        ],
        currentPage: null,
        perPage: null,
        pageOptions: [10, 25, 50, 100],
        totalRows: null,
        filter: '',
        selected: {
          filterType: '1'
        },
        combo: {
          filterType: [
            { value: '1', text: 'NAMA' }
          ]
        }
      },
      created: {
        object: 'menu-sub-module',
        smenuname: '',
        created_by: xray.currentUser().id
      },
      edited: {
        object: 'menu-sub-module',
        smenuname: '',
        created_by: '',
        created_at: '',
        updated_by: xray.currentUser().id
      }
    }
  },
  mounted () {
    xray.index()
  },
  methods: {
    filterchange () {
      this.table.filter = ''
    },
    onFiltered () {
    },
    isNumberKey (evt) {
      evt = (evt) || window.event
      var charCode = (evt.which) ? evt.which : evt.keyCode
      if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
        evt.preventDefault()
      } else {
        return true
      }
    },
    tableRefresh () {
      this.$refs.table.refresh()
    },
    async list (ctx) {
      this.isBusy = true
      let filterType = this.table.selected.filterType
      let filters = ''
      if (ctx.filter === '') {
        ctx.filter = null
        filters = `{"xparam0":""}`
      }
      if (ctx.filter === null) {
        filters = `{"xparam0":""}`
      } else {
        if (filterType === '1') {
          filters = `{"xparam0":"${ctx.filter}"}`
        }
      }
      try {
        const response = await Service.menu.findAll(ctx.currentPage - 1, ctx.perPage, filters)
        // const rowData = response.data.data
        // const realData = rowData.filter(function (data) {
        //   return data.level === 1
        // })
        this.isBusy = false
        this.table.totalRows = response.data.pagination.total
        this.table.perPage = response.data.pagination.perPage
        this.table.currentPage = response.data.pagination.current
        return response.data.data
      } catch (error) {
        this.isBusy = false
        return []
      }
    },
    modalModul (event, param) {
      this.moduleId = event
      this.modulIndex = param
      if (param === '1') {
        this.modalTitle = 'Tambah Struktur'
      } else if (param === '2') {
        this.modalTitle = 'Edit Struktur'
        this.showModul()
      } else if (param === '3') {
        this.$refs['modal-delete'].show()
      }
      if (param !== '3') {
        this.$refs['modal-module'].show()
      }
    },
    konfirmdelete () {
      this.deleteModul()
    },
    proccess () {
      if (this.modulIndex === '1') {
        this.createModul()
      } else if (this.modulIndex === '2') {
        this.editModul()
      }
    },
    async createModul () {
      this.isLoading = true
      let data = this.created
      await Service.menu.create(data).then(response => {
        this.$refs.MsgBox.show({
          type: 'success',
          msg: response.data.message
        })
      }).catch(error => {
        this.$refs.MsgBox.show({
          type: '',
          msg: error.response.data.message
        })
      }).finally(() => {
        this.$refs.table.refresh()
        this.clear()
        this.isLoading = false
      })
    },
    async editModul () {
      this.isLoading = true
      let id = this.moduleId
      let data = this.edited
      await Service.menu.update(id, data).then(response => {
        this.$refs.MsgBox.show({
          type: 'success',
          msg: response.data.message
        })
      }).catch(error => {
        this.$refs.MsgBox.show({
          type: '',
          msg: error.response.data.message
        })
      }).finally(() => {
        this.$refs.table.refresh()
        this.clear()
        this.isLoading = false
      })
    },
    async deleteModul () {
      this.isLoading = true
      let id = this.moduleId
      await Service.menu.delete(id).then(response => {
        this.$refs.MsgBox.show({
          type: 'success',
          msg: response.data.message
        })
      }).catch(error => {
        this.$refs.MsgBox.show({
          type: '',
          msg: error.response.data.message
        })
      }).finally(() => {
        this.$refs.table.refresh()
        this.clear()
        this.isLoading = false
      })
    },
    async showModul () {
      let ids = this.moduleId.toString()
      await Service.menu.findId(ids).then(response => {
        this.edited.smenuname = response.data.data[0].smenuname
        this.edited.created_by = response.data.data[0].created_by
        this.edited.created_at = response.data.data[0].updated_at
        this.$refs.table.refresh()
      }).catch(error => {
        this.message = error.response
        this.$refs.table.refresh()
      })
    },
    clear () {
      this.created.object = 'menu-sub-module'
      this.created.smenuname = ''
      this.created_by = xray.currentUser().id
      this.edited.object = 'menu-sub-module'
      this.edited.smenuname = ''
      this.edited.created_by = ''
      this.edited.created_at = ''
      this.edited.updated_by = xray.currentUser().id
    }
  }
}
</script>
<style>
#menu-module .form-control {
  font-weight: bold!important;
  color: black!important;
}
#menu-module .fc-unthemed .fc-toolbar .fc-button {
    margin: 3px;
}
#menu-module tr:first-child > td > .fc-day-grid-event {
    cursor: pointer;
}
#menu-module .multiselect__input, .multiselect {
  font-weight: bold!important;
}
td.fc-day.fc-past {
  background-color: #EEEEEE;
}
#modal-module .multiselect__single {
  font-size: 12px!important;
}
#modal-module .multiselect__placeholder {
  font-size: 12px!important;
  padding-left: 4px;
}
#modal-module .multiselect__option {
  font-size: 12px;
}
#modal-module .multiselect__tags {
  padding: 5px 40px 0 8px!important;
  height: 38px!important;
  min-height: 38px!important;
}
#menu-module .flex-half-width-left {
  width: 76%;
}
#menu-module .flex-half-width-right {
  width: 23%;
}
#menu-module .flex-full-width-left {
  width: 96%;
}
#menu-module .flex-full-width-right {
  width: 3%;
}
#menu-module .txt-module-base, #modal-module .txt-module-base {
  font-size: 12px;
}
#menu-module .txt-module-base-head {
  font-size: 14px;
}
#menu-module .table td {
    font-size: 12px;
    font-weight: 500;
    padding-top: 0px;
    padding-bottom: 0px;
}
#menu-module .table th {
    font-size: 13px;
    font-weight: 400;
}
#menu-module .form-control {
    border: 1px solid rgb(224, 224, 224);
    background: white;
    height: 40px;
}
#modal-module .multiselect__tags {
    border-radius: 0px;
    border: 1px solid rgb(224, 224, 224);
    background: white;
    font-size: 11px;
    font-weight: 550;
    height: 10px;
}
#menu-module .btn-height-custom {
    height: 39px;
}
#menu-module .cursor-point {
    cursor: pointer;
}
#menu-module .scroll-y {
    overflow-y: scroll;
    height: 550px;
}
#menu-module .bg-abu {
  background: rgba(236, 236, 236, 0.541);
}
#menu-module .form-control {
    font-size: 11px;
    font-weight: 550;
}
#menu-module .b-table-sticky-header, #menu-module .table-responsive {
    margin-bottom: 0px;
}
#menu-module .table-barang .btn {
    font-size: 11px;
}
#menu-module .page-link {
    border: none;
    color: gray!important;
    margin-right: 10px!important;
}
#menu-module .page-item.active .page-link {
    background-color: transparent;
    border-color: transparent;
    color: #089bab!important;
    font-weight: bold;
}
#menu-module .vertical-txt {
  writing-mode: vertical-rl;
  letter-spacing: 10px;
}
#menu-module .transition-cstm {
  transition: .3s linear;
}
.cstm-cntrl-module {
  border-radius: 5px!important;
  font-size: 12px!important;
  height: 38px!important;
}
#menu-module .custom-control-label, #modal-module .custom-control-label {
    font-size: 12px;
    margin-top: 0;
}
#menu-module .modal-dialog {
  min-width: 65%!important;
}
.cstm-slct-kuota {
  font-size: 13px!important;
  height: 38px!important;
  border-radius: 5px;
}
.alert-radius-kuota {
  border-radius: 5px;
}
</style>
