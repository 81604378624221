<template>
  <b-container fluid>
    <b-row class="mb-3">
      <b-col>
        <iq-card class="h-100">
          <template v-slot:headerTitle>
            <h4 class="card-title">Personal Informasi</h4>
          </template>
          <template v-slot:body>
            <div class="iq-card-body">
              <b-row>
                <b-col>
                  <div class="shadow p-4 rounded mb-4">
                    <p class="h6 font-weight-bold text-dark">Apakah Anda:</p>
                    <hr>
                    <div class="row mb-3">
                      <div class="col-12">
                        <p class="h6 font-weight-light text-dark mb-0">Merasa Sehat Pada Hari Ini</p>
                      </div>
                      <div class="col-12" v-if="pasien.param1 === 1">
                        <p class="h6 font-weight-bold text-dark mb-0">Ya</p>
                      </div>
                      <div class="col-12" v-else-if="pasien.param1 === 2">
                        <p class="h6 font-weight-bold text-dark mb-0">Tidak</p>
                      </div>
                      <div class="col-12" v-else-if="pasien.param1 === 3">
                        <p class="h6 font-weight-bold text-dark mb-0">Tidak Tahu</p>
                      </div>
                      <div class="col-12" v-else>
                        <p class="h6 text-dark mb-0">-</p>
                      </div>
                    </div>
                    <div class="row mb-3">
                      <div class="col-12">
                        <p class="h6 font-weight-light text-dark mb-0">Sedang Minum Antibiotik Atau Obat Lain Untuk Infeksi</p>
                      </div>
                      <div class="col-12" v-if="pasien.param2 === 1">
                        <p class="h6 font-weight-bold text-dark mb-0">Ya</p>
                      </div>
                      <div class="col-12" v-else-if="pasien.param2 === 2">
                        <p class="h6 font-weight-bold text-dark mb-0">Tidak</p>
                      </div>
                      <div class="col-12" v-else-if="pasien.param2 === 3">
                        <p class="h6 font-weight-bold text-dark mb-0">Tidak Tahu</p>
                      </div>
                      <div class="col-12" v-else>
                        <p class="h6 text-dark mb-0">-</p>
                      </div>
                    </div>
                    <div class="row mb-0">
                      <div class="col-12">
                        <p class="h6 font-weight-light text-dark mb-0">Sedang Minum Jamu</p>
                      </div>
                      <div class="col-12" v-if="pasien.param3 === 1">
                        <p class="h6 font-weight-bold text-dark mb-0">Ya</p>
                      </div>
                      <div class="col-12" v-else-if="pasien.param3 === 2">
                        <p class="h6 font-weight-bold text-dark mb-0">Tidak</p>
                      </div>
                      <div class="col-12" v-else-if="pasien.param3 === 3">
                        <p class="h6 font-weight-bold text-dark mb-0">Tidak Tahu</p>
                      </div>
                      <div class="col-12" v-else>
                        <p class="h6 text-dark mb-0">-</p>
                      </div>
                    </div>
                  </div>
                  <div class="shadow p-4 rounded mb-4">
                    <p class="h6 font-weight-bold text-dark">Dalam Waktu 48 Jam Terakhir</p>
                    <hr>
                    <div class="row mb-3">
                      <div class="col-12">
                        <p class="h6 font-weight-light text-dark mb-0">Apakah Anda Sedang Minum Aspirin Atau Obat Yang Mengandung Aspirin</p>
                      </div>
                      <div class="col-12" v-if="pasien.param4 === 1">
                        <p class="h6 font-weight-bold text-dark mb-0">Ya</p>
                      </div>
                      <div class="col-12" v-else-if="pasien.param4 === 2">
                        <p class="h6 font-weight-bold text-dark mb-0">Tidak</p>
                      </div>
                      <div class="col-12" v-else-if="pasien.param4 === 3">
                        <p class="h6 font-weight-bold text-dark mb-0">Tidak Tahu</p>
                      </div>
                      <div class="col-12" v-else>
                        <p class="h6 text-dark mb-0">-</p>
                      </div>
                    </div>
                  </div>
                  <div class="shadow p-4 rounded mb-4">
                    <p class="h6 font-weight-bold text-dark">Dalam Waktu 1 Minggu Terakhir</p>
                    <hr>
                    <div class="row mb-3">
                      <div class="col-12">
                        <p class="h6 font-weight-light text-dark mb-0">Apakah Anda Mengalami Sakit Kepala Dan Demam Bersamaan Atau Tindakan Cabut
                          Gigi</p>
                      </div>
                      <div class="col-12" v-if="pasien.param5 === 1">
                        <p class="h6 font-weight-bold text-dark mb-0">Ya</p>
                      </div>
                      <div class="col-12" v-else-if="pasien.param5 === 2">
                        <p class="h6 font-weight-bold text-dark mb-0">Tidak</p>
                      </div>
                      <div class="col-12" v-else-if="pasien.param5 === 3">
                        <p class="h6 font-weight-bold text-dark mb-0">Tidak Tahu</p>
                      </div>
                      <div class="col-12" v-else>
                        <p class="h6 text-dark mb-0">-</p>
                      </div>
                    </div>
                  </div>
                  <div class="shadow p-4 rounded mb-4">
                    <p class="h6 font-weight-bold text-dark">Dalam Waktu 6 Minggu Terakhir</p>
                    <hr>
                    <div class="row mb-3">
                      <div class="col-12">
                        <p class="h6 font-weight-light text-dark mb-0">Untuk Donor Darah Wanita : Apakah Anda Saat Ini Sedang Hamil ? Jika Ya,
                          Kehamilan Berapa</p>
                      </div>
                      <div class="col-12" v-if="pasien.param6 === 1">
                        <p class="h6 font-weight-bold text-dark mb-0">Ya, {{pasien.ket_param6}}</p>
                      </div>
                      <div class="col-12" v-else-if="pasien.param6 === 2">
                        <p class="h6 font-weight-bold text-dark mb-0">Tidak</p>
                      </div>
                      <div class="col-12" v-else-if="pasien.param6 === 3">
                        <p class="h6 font-weight-bold text-dark mb-0">Tidak Tahu</p>
                      </div>
                      <div class="col-12" v-else>
                        <p class="h6 text-dark mb-0">-</p>
                      </div>
                    </div>
                  </div>
                  <div class="shadow p-4 rounded mb-4">
                    <p class="h6 font-weight-bold text-dark">Dalam Waktu 8 Minggu Terakhir ( 2 Bulan )</p>
                    <hr>
                    <div class="row mb-3">
                      <div class="col-12">
                        <p class="h6 font-weight-light text-dark mb-0">Apakah Anda Mendonorkan Darah, Trombosit, Atau Plasma</p>
                      </div>
                      <div class="col-12" v-if="pasien.param7 === 1">
                        <p class="h6 font-weight-bold text-dark mb-0">Ya</p>
                      </div>
                      <div class="col-12" v-else-if="pasien.param7 === 2">
                        <p class="h6 font-weight-bold text-dark mb-0">Tidak</p>
                      </div>
                      <div class="col-12" v-else-if="pasien.param7 === 3">
                        <p class="h6 font-weight-bold text-dark mb-0">Tidak Tahu</p>
                      </div>
                      <div class="col-12" v-else>
                        <p class="h6 text-dark mb-0">-</p>
                      </div>
                    </div>
                    <div class="row mb-3">
                      <div class="col-12">
                        <p class="h6 font-weight-light text-dark mb-0">Apakah Anda Menerima Vaksinasi Atau Suntik Lainnya</p>
                      </div>
                      <div class="col-12" v-if="pasien.param8 === 1">
                        <p class="h6 font-weight-bold text-dark mb-0">Ya</p>
                      </div>
                      <div class="col-12" v-else-if="pasien.param8 === 2">
                        <p class="h6 font-weight-bold text-dark mb-0">Tidak</p>
                      </div>
                      <div class="col-12" v-else-if="pasien.param8 === 3">
                        <p class="h6 font-weight-bold text-dark mb-0">Tidak Tahu</p>
                      </div>
                      <div class="col-12" v-else>
                        <p class="h6 text-dark mb-0">-</p>
                      </div>
                    </div>
                    <div class="row mb-3">
                      <div class="col-12">
                        <p class="h6 font-weight-light text-dark mb-0">Apakah Anda Pernah Kontak Dengan Orang Yang Menerima Vaksinasi Smallpox
                        </p>
                      </div>
                      <div class="col-12" v-if="pasien.param9 === 1">
                        <p class="h6 font-weight-bold text-dark mb-0">Ya</p>
                      </div>
                      <div class="col-12" v-else-if="pasien.param9 === 2">
                        <p class="h6 font-weight-bold text-dark mb-0">Tidak</p>
                      </div>
                      <div class="col-12" v-else-if="pasien.param9 === 3">
                        <p class="h6 font-weight-bold text-dark mb-0">Tidak Tahu</p>
                      </div>
                      <div class="col-12" v-else>
                        <p class="h6 text-dark mb-0">-</p>
                      </div>
                    </div>
                  </div>
                  <div class="shadow p-4 rounded mb-4">
                    <p class="h6 font-weight-bold text-dark">Dalam Waktu 16 Minggu Terakhir ( 4 Bulan )</p>
                    <hr>
                    <div class="row mb-3">
                      <div class="col-12">
                        <p class="h6 font-weight-light text-dark mb-0">Apakah Anda Mendonorkan Darah, Trombosit, Atau Plasma</p>
                      </div>
                      <div class="col-12" v-if="pasien.param10 === 1">
                        <p class="h6 font-weight-bold text-dark mb-0">Ya</p>
                      </div>
                      <div class="col-12" v-else-if="pasien.param10 === 2">
                        <p class="h6 font-weight-bold text-dark mb-0">Tidak</p>
                      </div>
                      <div class="col-12" v-else-if="pasien.param10 === 3">
                        <p class="h6 font-weight-bold text-dark mb-0">Tidak Tahu</p>
                      </div>
                      <div class="col-12" v-else>
                        <p class="h6 text-dark mb-0">-</p>
                      </div>
                    </div>
                  </div>
                </b-col>
                <b-col>
                  <div class="shadow p-4 rounded mb-4">
                    <p class="h6 font-weight-bold text-dark">Dalam Waktu 12 Bulan Terakhir (1 Tahun)</p>
                    <hr>
                    <div class="row mb-3">
                      <div class="col-12">
                        <p class="h6 font-weight-light text-dark mb-0">Apakah Anda Pernah Menerima Transfusi Darah</p>
                      </div>
                      <div class="col-12" v-if="pasien.param11 === 1">
                        <p class="h6 font-weight-bold text-dark mb-0">Ya</p>
                      </div>
                      <div class="col-12" v-else-if="pasien.param11 === 2">
                        <p class="h6 font-weight-bold text-dark mb-0">Tidak</p>
                      </div>
                      <div class="col-12" v-else-if="pasien.param11 === 3">
                        <p class="h6 font-weight-bold text-dark mb-0">Tidak Tahu</p>
                      </div>
                      <div class="col-12" v-else>
                        <p class="h6 text-dark mb-0">-</p>
                      </div>
                    </div>
                    <div class="row mb-3">
                      <div class="col-12">
                        <p class="h6 font-weight-light text-dark mb-0">Apakah Anda Pernah Mendapat Transplantasi Organ, Jaringan, Sumsum Tulang
                          Atau Cangkok Tulang Untuk Kulit</p>
                      </div>
                      <div class="col-12" v-if="pasien.param12 === 1">
                        <p class="h6 font-weight-bold text-dark mb-0">Ya</p>
                      </div>
                      <div class="col-12" v-else-if="pasien.param12 === 2">
                        <p class="h6 font-weight-bold text-dark mb-0">Tidak</p>
                      </div>
                      <div class="col-12" v-else-if="pasien.param12 === 3">
                        <p class="h6 font-weight-bold text-dark mb-0">Tidak Tahu</p>
                      </div>
                      <div class="col-12" v-else>
                        <p class="h6 text-dark mb-0">-</p>
                      </div>
                    </div>
                    <div class="row mb-3">
                      <div class="col-12">
                        <p class="h6 font-weight-light text-dark mb-0">Apakah Anda Pernah Mengalami Tindakan Operasi</p>
                      </div>
                      <div class="col-12" v-if="pasien.param13 === 1">
                        <p class="h6 font-weight-bold text-dark mb-0">Ya</p>
                      </div>
                      <div class="col-12" v-else-if="pasien.param13 === 2">
                        <p class="h6 font-weight-bold text-dark mb-0">Tidak</p>
                      </div>
                      <div class="col-12" v-else-if="pasien.param13 === 3">
                        <p class="h6 font-weight-bold text-dark mb-0">Tidak Tahu</p>
                      </div>
                      <div class="col-12" v-else>
                        <p class="h6 text-dark mb-0">-</p>
                      </div>
                    </div>
                    <div class="row mb-3">
                      <div class="col-12">
                        <p class="h6 font-weight-light text-dark mb-0">Apakah Anda Pernah Tertusuk Jarum Medis, Akupuntur Atau Bekam</p>
                      </div>
                      <div class="col-12" v-if="pasien.param14 === 1">
                        <p class="h6 font-weight-bold text-dark mb-0">Ya</p>
                      </div>
                      <div class="col-12" v-else-if="pasien.param14 === 2">
                        <p class="h6 font-weight-bold text-dark mb-0">Tidak</p>
                      </div>
                      <div class="col-12" v-else-if="pasien.param14 === 3">
                        <p class="h6 font-weight-bold text-dark mb-0">Tidak Tahu</p>
                      </div>
                      <div class="col-12" v-else>
                        <p class="h6 text-dark mb-0">-</p>
                      </div>
                    </div>
                    <div class="row mb-3">
                      <div class="col-12">
                        <p class="h6 font-weight-light text-dark mb-0">Apakah Anda Pernah Berhubungan Seksual Dengan Orang HIV/AIDS</p>
                      </div>
                      <div class="col-12" v-if="pasien.param15 === 1">
                        <p class="h6 font-weight-bold text-dark mb-0">Ya</p>
                      </div>
                      <div class="col-12" v-else-if="pasien.param15 === 2">
                        <p class="h6 font-weight-bold text-dark mb-0">Tidak</p>
                      </div>
                      <div class="col-12" v-else-if="pasien.param15 === 3">
                        <p class="h6 font-weight-bold text-dark mb-0">Tidak Tahu</p>
                      </div>
                      <div class="col-12" v-else>
                        <p class="h6 text-dark mb-0">-</p>
                      </div>
                    </div>
                    <div class="row mb-3">
                      <div class="col-12">
                        <p class="h6 font-weight-light text-dark mb-0">Apakah Anda Pernah Berhubungan Seksual Dengan Pekerja Seks Komersial</p>
                      </div>
                      <div class="col-12" v-if="pasien.param16 === 1">
                        <p class="h6 font-weight-bold text-dark mb-0">Ya</p>
                      </div>
                      <div class="col-12" v-else-if="pasien.param16 === 2">
                        <p class="h6 font-weight-bold text-dark mb-0">Tidak</p>
                      </div>
                      <div class="col-12" v-else-if="pasien.param16 === 3">
                        <p class="h6 font-weight-bold text-dark mb-0">Tidak Tahu</p>
                      </div>
                      <div class="col-12" v-else>
                        <p class="h6 text-dark mb-0">-</p>
                      </div>
                    </div>
                    <div class="row mb-3">
                      <div class="col-12">
                        <p class="h6 font-weight-light text-dark mb-0">Apakah Anda Pernah Berhubungan Seksual Dengan Pengguna Narkoba Jarum
                          Suntik</p>
                      </div>
                      <div class="col-12" v-if="pasien.param17 === 1">
                        <p class="h6 font-weight-bold text-dark mb-0">Ya</p>
                      </div>
                      <div class="col-12" v-else-if="pasien.param17 === 2">
                        <p class="h6 font-weight-bold text-dark mb-0">Tidak</p>
                      </div>
                      <div class="col-12" v-else-if="pasien.param17 === 3">
                        <p class="h6 font-weight-bold text-dark mb-0">Tidak Tahu</p>
                      </div>
                      <div class="col-12" v-else>
                        <p class="h6 text-dark mb-0">-</p>
                      </div>
                    </div>
                    <div class="row mb-3">
                      <div class="col-12">
                        <p class="h6 font-weight-light text-dark mb-0">Apakah Anda Pernah Berhubungan Seksual Dengan Pengguna Konsetrat Faktor
                          Pembekuan</p>
                      </div>
                      <div class="col-12" v-if="pasien.param18 === 1">
                        <p class="h6 font-weight-bold text-dark mb-0">Ya</p>
                      </div>
                      <div class="col-12" v-else-if="pasien.param18 === 2">
                        <p class="h6 font-weight-bold text-dark mb-0">Tidak</p>
                      </div>
                      <div class="col-12" v-else-if="pasien.param18 === 3">
                        <p class="h6 font-weight-bold text-dark mb-0">Tidak Tahu</p>
                      </div>
                      <div class="col-12" v-else>
                        <p class="h6 text-dark mb-0">-</p>
                      </div>
                    </div>
                    <div class="row mb-3">
                      <div class="col-12">
                        <p class="h6 font-weight-light text-dark mb-0">Apakah Anda Pernah Berhubungan Seksual Dengan Yang Laki - Laki Biseksual
                        </p>
                      </div>
                      <div class="col-12" v-if="pasien.param19 === 1">
                        <p class="h6 font-weight-bold text-dark mb-0">Ya</p>
                      </div>
                      <div class="col-12" v-else-if="pasien.param19 === 2">
                        <p class="h6 font-weight-bold text-dark mb-0">Tidak</p>
                      </div>
                      <div class="col-12" v-else-if="pasien.param19 === 3">
                        <p class="h6 font-weight-bold text-dark mb-0">Tidak Tahu</p>
                      </div>
                      <div class="col-12" v-else>
                        <p class="h6 text-dark mb-0">-</p>
                      </div>
                    </div>
                    <div class="row mb-3">
                      <div class="col-12">
                        <p class="h6 font-weight-light text-dark mb-0">Apakah Anda Pernah Berhubungan Seksual Dengan Penderita Hepatitis</p>
                      </div>
                      <div class="col-12" v-if="pasien.param20 === 1">
                        <p class="h6 font-weight-bold text-dark mb-0">Ya</p>
                      </div>
                      <div class="col-12" v-else-if="pasien.param20 === 2">
                        <p class="h6 font-weight-bold text-dark mb-0">Tidak</p>
                      </div>
                      <div class="col-12" v-else-if="pasien.param20 === 3">
                        <p class="h6 font-weight-bold text-dark mb-0">Tidak Tahu</p>
                      </div>
                      <div class="col-12" v-else>
                        <p class="h6 text-dark mb-0">-</p>
                      </div>
                    </div>
                    <div class="row mb-3">
                      <div class="col-12">
                        <p class="h6 font-weight-light text-dark mb-0">Apakah Anda Tinggal Bersama Penderita Hepatitis</p>
                      </div>
                      <div class="col-12" v-if="pasien.param21 === 1">
                        <p class="h6 font-weight-bold text-dark mb-0">Ya</p>
                      </div>
                      <div class="col-12" v-else-if="pasien.param21 === 2">
                        <p class="h6 font-weight-bold text-dark mb-0">Tidak</p>
                      </div>
                      <div class="col-12" v-else-if="pasien.param21 === 3">
                        <p class="h6 font-weight-bold text-dark mb-0">Tidak Tahu</p>
                      </div>
                      <div class="col-12" v-else>
                        <p class="h6 text-dark mb-0">-</p>
                      </div>
                    </div>
                    <div class="row mb-3">
                      <div class="col-12">
                        <p class="h6 font-weight-light text-dark mb-0">Apakah Anda Memiliki Tato</p>
                      </div>
                      <div class="col-12" v-if="pasien.param22 === 1">
                        <p class="h6 font-weight-bold text-dark mb-0">Ya</p>
                      </div>
                      <div class="col-12" v-else-if="pasien.param22 === 2">
                        <p class="h6 font-weight-bold text-dark mb-0">Tidak</p>
                      </div>
                      <div class="col-12" v-else-if="pasien.param22 === 3">
                        <p class="h6 font-weight-bold text-dark mb-0">Tidak Tahu</p>
                      </div>
                      <div class="col-12" v-else>
                        <p class="h6 text-dark mb-0">-</p>
                      </div>
                    </div>
                    <div class="row mb-3">
                      <div class="col-12">
                        <p class="h6 font-weight-light text-dark mb-0">Apakah Anda Memiliki Tindik Telingan Atau Dibagian Tubuh Lainnya</p>
                      </div>
                      <div class="col-12" v-if="pasien.param23 === 1">
                        <p class="h6 font-weight-bold text-dark mb-0">Ya</p>
                      </div>
                      <div class="col-12" v-else-if="pasien.param23 === 2">
                        <p class="h6 font-weight-bold text-dark mb-0">Tidak</p>
                      </div>
                      <div class="col-12" v-else-if="pasien.param23 === 3">
                        <p class="h6 font-weight-bold text-dark mb-0">Tidak Tahu</p>
                      </div>
                      <div class="col-12" v-else>
                        <p class="h6 text-dark mb-0">-</p>
                      </div>
                    </div>
                    <div class="row mb-3">
                      <div class="col-12">
                        <p class="h6 font-weight-light text-dark mb-0">Apakah Anda Sedang Atau Pernah Mendapat Pengobatan Sifilis Atau GO(Kencing
                          Nanah)</p>
                      </div>
                      <div class="col-12" v-if="pasien.param24 === 1">
                        <p class="h6 font-weight-bold text-dark mb-0">Ya</p>
                      </div>
                      <div class="col-12" v-else-if="pasien.param24 === 2">
                        <p class="h6 font-weight-bold text-dark mb-0">Tidak</p>
                      </div>
                      <div class="col-12" v-else-if="pasien.param24 === 3">
                        <p class="h6 font-weight-bold text-dark mb-0">Tidak Tahu</p>
                      </div>
                      <div class="col-12" v-else>
                        <p class="h6 text-dark mb-0">-</p>
                      </div>
                    </div>
                    <div class="row mb-3">
                      <div class="col-12">
                        <p class="h6 font-weight-light text-dark mb-0">Apakah Anda Pernah Ditahan Dipenjara Untuk Waktu Lebih Dari 72 Jam</p>
                      </div>
                      <div class="col-12" v-if="pasien.param25 === 1">
                        <p class="h6 font-weight-bold text-dark mb-0">Ya</p>
                      </div>
                      <div class="col-12" v-else-if="pasien.param25 === 2">
                        <p class="h6 font-weight-bold text-dark mb-0">Tidak</p>
                      </div>
                      <div class="col-12" v-else-if="pasien.param25 === 3">
                        <p class="h6 font-weight-bold text-dark mb-0">Tidak Tahu</p>
                      </div>
                      <div class="col-12" v-else>
                        <p class="h6 text-dark mb-0">-</p>
                      </div>
                    </div>
                  </div>
                  <div class="shadow p-4 rounded mb-4">
                    <p class="h6 font-weight-bold text-dark">Tahun 1977 Hingga Sekarang</p>
                    <hr>
                    <div class="row mb-3">
                      <div class="col-12">
                        <p class="h6 font-weight-light text-dark mb-0">Apakah Anda Menerima Uang, Obat, Atau Pembiayaan Lainnya Untuk Seks</p>
                      </div>
                      <div class="col-12" v-if="pasien.param26 === 1">
                        <p class="h6 font-weight-bold text-dark mb-0">Ya</p>
                      </div>
                      <div class="col-12" v-else-if="pasien.param19 === 2">
                        <p class="h6 font-weight-bold text-dark mb-0">Tidak</p>
                      </div>
                      <div class="col-12" v-else-if="pasien.param19 === 3">
                        <p class="h6 font-weight-bold text-dark mb-0">Tidak Tahu</p>
                      </div>
                      <div class="col-12" v-else>
                        <p class="h6 text-dark mb-0">-</p>
                      </div>
                    </div>
                    <div class="row mb-3">
                      <div class="col-12">
                        <p class="h6 font-weight-light text-dark mb-0">Untuk Laki-laki: Apakah Anda Pernah Berhubungan Seksual Dengan Sesama
                          Laki-laki, Walaupun Hanya Sekali</p>
                      </div>
                      <div class="col-12" v-if="pasien.param27 === 1">
                        <p class="h6 font-weight-bold text-dark mb-0">Ya</p>
                      </div>
                      <div class="col-12" v-else-if="pasien.param27 === 2">
                        <p class="h6 font-weight-bold text-dark mb-0">Tidak</p>
                      </div>
                      <div class="col-12" v-else-if="pasien.param27 === 3">
                        <p class="h6 font-weight-bold text-dark mb-0">Tidak Tahu</p>
                      </div>
                      <div class="col-12" v-else>
                        <p class="h6 text-dark mb-0">-</p>
                      </div>
                    </div>
                  </div>
                </b-col>
                <b-col>
                  <div class="shadow p-4 rounded mb-4">
                    <p class="h6 font-weight-bold text-dark">Tahun 1980 Hingga Sekarang</p>
                    <hr>
                    <div class="row mb-3">
                      <div class="col-12">
                        <p class="h6 font-weight-light text-dark mb-0">Apakah Anda Tingal Selama 5 Tahun Atau Lebih Di Eropa ?</p>
                      </div>
                      <div class="col-12" v-if="pasien.param28 === 1">
                        <p class="h6 font-weight-bold text-dark mb-0">Ya</p>
                      </div>
                      <div class="col-12" v-else-if="pasien.param28 === 2">
                        <p class="h6 font-weight-bold text-dark mb-0">Tidak</p>
                      </div>
                      <div class="col-12" v-else-if="pasien.param28 === 3">
                        <p class="h6 font-weight-bold text-dark mb-0">Tidak Tahu</p>
                      </div>
                      <div class="col-12" v-else>
                        <p class="h6 text-dark mb-0">-</p>
                      </div>
                    </div>
                    <div class="row mb-3">
                      <div class="col-12">
                        <p class="h6 font-weight-light text-dark mb-0">Apakah Anda Menerima Transfusi Darah Di Inggris</p>
                      </div>
                      <div class="col-12" v-if="pasien.param29 === 1">
                        <p class="h6 font-weight-bold text-dark mb-0">Ya</p>
                      </div>
                      <div class="col-12" v-else-if="pasien.param29 === 2">
                        <p class="h6 font-weight-bold text-dark mb-0">Tidak</p>
                      </div>
                      <div class="col-12" v-else-if="pasien.param29 === 3">
                        <p class="h6 font-weight-bold text-dark mb-0">Tidak Tahu</p>
                      </div>
                      <div class="col-12" v-else>
                        <p class="h6 text-dark mb-0">-</p>
                      </div>
                    </div>
                  </div>
                  <div class="shadow p-4 rounded mb-4">
                    <p class="h6 font-weight-bold text-dark">Tahun 1980 Hingga 1996</p>
                    <hr>
                    <div class="row mb-3">
                      <div class="col-12">
                        <p class="h6 font-weight-light text-dark mb-0">Apakah Anda Tingal Selama 3 Bulan Atau Lebih Di Inggris</p>
                      </div>
                      <div class="col-12" v-if="pasien.param30 === 1">
                        <p class="h6 font-weight-bold text-dark mb-0">Ya</p>
                      </div>
                      <div class="col-12" v-else-if="pasien.param30 === 2">
                        <p class="h6 font-weight-bold text-dark mb-0">Tidak</p>
                      </div>
                      <div class="col-12" v-else-if="pasien.param30 === 3">
                        <p class="h6 font-weight-bold text-dark mb-0">Tidak Tahu</p>
                      </div>
                      <div class="col-12" v-else>
                        <p class="h6 text-dark mb-0">-</p>
                      </div>
                    </div>
                  </div>
                  <div class="shadow p-4 rounded mb-4">
                    <p class="h6 font-weight-bold text-dark">Apakah Anda Pernah</p>
                    <hr>
                    <div class="row mb-3">
                      <div class="col-12">
                        <p class="h6 font-weight-light text-dark mb-0">Mendapat Hasil Positif Untuk Tes HIV/AIDS</p>
                      </div>
                      <div class="col-12" v-if="pasien.param31 === 1">
                        <p class="h6 font-weight-bold text-dark mb-0">Ya</p>
                      </div>
                      <div class="col-12" v-else-if="pasien.param32 === 2">
                        <p class="h6 font-weight-bold text-dark mb-0">Tidak</p>
                      </div>
                      <div class="col-12" v-else-if="pasien.param33 === 3">
                        <p class="h6 font-weight-bold text-dark mb-0">Tidak Tahu</p>
                      </div>
                      <div class="col-12" v-else>
                        <p class="h6 text-dark mb-0">-</p>
                      </div>
                    </div>
                    <div class="row mb-3">
                      <div class="col-12">
                        <p class="h6 font-weight-light text-dark mb-0">Menggunakan Jarum Suntik Untuk Obat - Obatan, Steroid Yang Tidak
                          Diresepkan Dokter</p>
                      </div>
                      <div class="col-12" v-if="pasien.param32 === 1">
                        <p class="h6 font-weight-bold text-dark mb-0">Ya</p>
                      </div>
                      <div class="col-12" v-else-if="pasien.param32 === 2">
                        <p class="h6 font-weight-bold text-dark mb-0">Tidak</p>
                      </div>
                      <div class="col-12" v-else-if="pasien.param33 === 3">
                        <p class="h6 font-weight-bold text-dark mb-0">Tidak Tahu</p>
                      </div>
                      <div class="col-12" v-else>
                        <p class="h6 text-dark mb-0">-</p>
                      </div>
                    </div>
                    <div class="row mb-3">
                      <div class="col-12">
                        <p class="h6 font-weight-light text-dark mb-0">Menggunakan Konsetrat Faktor Pembekuan (AHF/FFP)</p>
                      </div>
                      <div class="col-12" v-if="pasien.param33 === 1">
                        <p class="h6 font-weight-bold text-dark mb-0">Ya</p>
                      </div>
                      <div class="col-12" v-else-if="pasien.param33 === 2">
                        <p class="h6 font-weight-bold text-dark mb-0">Tidak</p>
                      </div>
                      <div class="col-12" v-else-if="pasien.param33 === 3">
                        <p class="h6 font-weight-bold text-dark mb-0">Tidak Tahu</p>
                      </div>
                      <div class="col-12" v-else>
                        <p class="h6 text-dark mb-0">-</p>
                      </div>
                    </div>
                    <div class="row mb-3">
                      <div class="col-12">
                        <p class="h6 font-weight-light text-dark mb-0">Menderita Hepatitis</p>
                      </div>
                      <div class="col-12" v-if="pasien.param34 === 1">
                        <p class="h6 font-weight-bold text-dark mb-0">Ya</p>
                      </div>
                      <div class="col-12" v-else-if="pasien.param34 === 2">
                        <p class="h6 font-weight-bold text-dark mb-0">Tidak</p>
                      </div>
                      <div class="col-12" v-else-if="pasien.param34 === 3">
                        <p class="h6 font-weight-bold text-dark mb-0">Tidak Tahu</p>
                      </div>
                      <div class="col-12" v-else>
                        <p class="h6 text-dark mb-0">-</p>
                      </div>
                    </div>
                    <div class="row mb-3">
                      <div class="col-12">
                        <p class="h6 font-weight-light text-dark mb-0">Menderita Malaria</p>
                      </div>
                      <div class="col-12" v-if="pasien.param35 === 1">
                        <p class="h6 font-weight-bold text-dark mb-0">Ya</p>
                      </div>
                      <div class="col-12" v-else-if="pasien.param35 === 2">
                        <p class="h6 font-weight-bold text-dark mb-0">Tidak</p>
                      </div>
                      <div class="col-12" v-else-if="pasien.param35 === 3">
                        <p class="h6 font-weight-bold text-dark mb-0">Tidak Tahu</p>
                      </div>
                      <div class="col-12" v-else>
                        <p class="h6 text-dark mb-0">-</p>
                      </div>
                    </div>
                    <div class="row mb-3">
                      <div class="col-12">
                        <p class="h6 font-weight-light text-dark mb-0">Menderita kanker Termasuk Leukemia</p>
                      </div>
                      <div class="col-12" v-if="pasien.param36 === 1">
                        <p class="h6 font-weight-bold text-dark mb-0">Ya</p>
                      </div>
                      <div class="col-12" v-else-if="pasien.param36 === 2">
                        <p class="h6 font-weight-bold text-dark mb-0">Tidak</p>
                      </div>
                      <div class="col-12" v-else-if="pasien.param36 === 3">
                        <p class="h6 font-weight-bold text-dark mb-0">Tidak Tahu</p>
                      </div>
                      <div class="col-12" v-else>
                        <p class="h6 text-dark mb-0">-</p>
                      </div>
                    </div>
                    <div class="row mb-3">
                      <div class="col-12">
                        <p class="h6 font-weight-light text-dark mb-0">Bersmasalah Dengan Jantung Atau Paru-Paru Termasuk TBC</p>
                      </div>
                      <div class="col-12" v-if="pasien.param37 === 1">
                        <p class="h6 font-weight-bold text-dark mb-0">Ya</p>
                      </div>
                      <div class="col-12" v-else-if="pasien.param37 === 2">
                        <p class="h6 font-weight-bold text-dark mb-0">Tidak</p>
                      </div>
                      <div class="col-12" v-else-if="pasien.param37 === 3">
                        <p class="h6 font-weight-bold text-dark mb-0">Tidak Tahu</p>
                      </div>
                      <div class="col-12" v-else>
                        <p class="h6 text-dark mb-0">-</p>
                      </div>
                    </div>
                    <div class="row mb-3">
                      <div class="col-12">
                        <p class="h6 font-weight-light text-dark mb-0">Menderita Pendarahan Atau Penyakit Berhubungan Dengan Darah Seperti
                          Hemofilia</p>
                      </div>
                      <div class="col-12" v-if="pasien.param38 === 1">
                        <p class="h6 font-weight-bold text-dark mb-0">Ya</p>
                      </div>
                      <div class="col-12" v-else-if="pasien.param38 === 2">
                        <p class="h6 font-weight-bold text-dark mb-0">Tidak</p>
                      </div>
                      <div class="col-12" v-else-if="pasien.param38 === 3">
                        <p class="h6 font-weight-bold text-dark mb-0">Tidak Tahu</p>
                      </div>
                      <div class="col-12" v-else>
                        <p class="h6 text-dark mb-0">-</p>
                      </div>
                    </div>
                    <div class="row mb-3">
                      <div class="col-12">
                        <p class="h6 font-weight-light text-dark mb-0">Berhubungan Seksual Dengan Orang Yang Tinggal Di Afrika</p>
                      </div>
                      <div class="col-12" v-if="pasien.param39 === 1">
                        <p class="h6 font-weight-bold text-dark mb-0">Ya</p>
                      </div>
                      <div class="col-12" v-else-if="pasien.param39 === 2">
                        <p class="h6 font-weight-bold text-dark mb-0">Tidak</p>
                      </div>
                      <div class="col-12" v-else-if="pasien.param39 === 3">
                        <p class="h6 font-weight-bold text-dark mb-0">Tidak Tahu</p>
                      </div>
                      <div class="col-12" v-else>
                        <p class="h6 text-dark mb-0">-</p>
                      </div>
                    </div>
                    <div class="row mb-3">
                      <div class="col-12">
                        <p class="h6 font-weight-light text-dark mb-0">Tinggal Di Afrika</p>
                      </div>
                      <div class="col-12" v-if="pasien.param40 === 1">
                        <p class="h6 font-weight-bold text-dark mb-0">Ya</p>
                      </div>
                      <div class="col-12" v-else-if="pasien.param40 === 2">
                        <p class="h6 font-weight-bold text-dark mb-0">Tidak</p>
                      </div>
                      <div class="col-12" v-else-if="pasien.param40 === 3">
                        <p class="h6 font-weight-bold text-dark mb-0">Tidak Tahu</p>
                      </div>
                      <div class="col-12" v-else>
                        <p class="h6 text-dark mb-0">-</p>
                      </div>
                    </div>
                  </div>
                </b-col>
              </b-row>
              <div v-if="pasien.status === 0">
                <hr>
                <b-row class="mb-3">
                  <b-col cols="12" lg="4">
                    <iq-card class="mb-0">
                    <b-form-group label="Status:" label-for="pelayanan" class="mb-0">
                      <b-form-select class="selected-cstm-utd" :options="status" v-model="result.status">
                      </b-form-select>
                    </b-form-group>
                    </iq-card>
                  </b-col>
                  <b-col cols="12" lg="4" v-if="result.status === 1">
                    <iq-card class="mb-0">
                    <b-form-group label="Kelolosan:" label-for="pelayanan" class="mb-0">
                      <b-form-select class="selected-cstm-utd" :options="alasanLolos" v-model="result.alasan">
                      </b-form-select>
                    </b-form-group>
                    </iq-card>
                  </b-col>
                  <b-col cols="12" lg="4" v-if="result.status === 2">
                    <iq-card class="mb-0">
                    <b-form-group label="Alasan Tidak Lolos:" label-for="pelayanan" class="mb-0">
                      <b-form-select class="selected-cstm-utd" :options="alasanTdkLolos" v-model="result.alasan">
                      </b-form-select>
                    </b-form-group>
                    </iq-card>
                  </b-col>
                </b-row>
                <b-row v-if="result.status === 1 && result.alasan === 1">
                  <b-col>
                    <button type="button" @click="onSubmit" class="btn btn-success">
                      <i class="fas fa-save"></i> Simpan Hasil
                    </button>
                  </b-col>
                </b-row>
                <b-row v-else-if="result.status === 2 && result.alasan === 2">
                  <b-col>
                    <button type="button" @click="onSubmit" class="btn btn-success">
                      <i class="fas fa-save"></i> Simpan Hasil
                    </button>
                  </b-col>
                </b-row>
                <b-row v-else-if="result.status === 2 && result.alasan === 3">
                  <b-col>
                    <button type="button" @click="onSubmit" class="btn btn-success">
                      <i class="fas fa-save"></i> Simpan Hasil
                    </button>
                  </b-col>
                </b-row>
              </div>
              <div v-if="pasien.status === 1 || pasien.status === 2">
                <hr>
                <b-row class="mb-3 d-none">
                  <b-col cols="12" lg="4">
                    <iq-card class="mb-0">
                    <b-form-group label="Ingin Mengembalikan Status ?" label-for="pelayanan" class="mb-0">
                      <b-form-select class="selected-cstm-utd" :options="statusAwal" v-model="result.status">
                      </b-form-select>
                      <b-form-select class="selected-cstm-utd" :options="alasanAwal" v-model="result.alasan">
                      </b-form-select>
                    </b-form-group>
                    </iq-card>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col>
                    <div>
                      <button type="button" @click="onSubmit" class="btn btn-warning">
                        <i class="fas fa-window-restore"></i> Hapus Status
                      </button>
                    </div>
                    <div>
                      <small>Kembalikan Status, Jika Ada Kesalahan Verifikasi</small>
                    </div>
                  </b-col>
                </b-row>
              </div>
            </div>
          </template>
        </iq-card>
      </b-col>
    </b-row>
    <b-row class="justify-content-center">
      <b-col md="2">
        <iq-card>
          <button type="button" @click="utdList" class="btn btn-primary btn-block">
            <i class="fas fa-undo"></i>  Kembali
          </button>
        </iq-card>
      </b-col>
    </b-row>
    <b-modal ref="modal-sukses" size="md" title="Informasi" ok-title="Saya Mengerti" centered>
      <div class="text-center">
        <p class="h5 text-danger">Berhasil Menyimpan Status</p>
      </div>
    </b-modal>
    <b-modal ref="modal-rubah" size="md" title="Informasi" ok-title="Saya Mengerti" centered>
      <div class="text-center">
        <p class="h5 text-danger">Status Dirubah</p>
      </div>
    </b-modal>
  </b-container>
</template>
<script>
import UTD from '../../services/utd.js'
export default {
  name: 'UtdView',
  mounted () {
    const id = this.$route.params.id
    const tgl = this.$route.query.tgl
    this.detailPasien(id, tgl)
  },
  methods: {
    utdList () {
      this.$router.push({ name: 'utd.list' })
    },
    async detailPasien (id, tgl) {
      const data = {
        id: id,
        tanggal: tgl
      }
      await UTD.viewPasien(data).then(response => {
        if (response.status === 200) {
          this.pasien = response.data.data[0]
          this.result.id_dft_trx_reservasi_utd_detail = response.data.data[0].id_dft_trx_reservasi_utd_detail
        }
      }).catch(error => {
        this.error = error.response || error.response.status
      })
    },
    async onSubmit (e) {
      const pasienUtd = this.result
      try {
        const pasienUtdResponse = await UTD.updateDetail(pasienUtd)
        if (pasienUtdResponse.status === 200) {
          this.$router.push({ name: 'utd.list' })
        } else {
          this.$bvToast.toast('Verifikasi gagal', {
            title: 'Message',
            variant: 'danger',
            solid: true
          })
        }
      } catch (err) {
        this.error = err
      }
    }
  },
  data () {
    return {
      id: null,
      tgl: null,
      error: '',
      pasien: '',
      result: {
        id_dft_trx_reservasi_utd_detail: null,
        status: 0,
        alasan: null
      },
      status: [
        { value: 0, text: 'Pilih Status', disabled: true },
        { value: 1, text: 'Lolos' },
        { value: 2, text: 'Tidak Lolos' }
      ],
      statusAwal: [
        { value: 0, text: 'Kembalikan Status' }
      ],
      alasanAwal: [
        { value: null, text: '---' }
      ],
      alasanLolos: [
        { value: null, text: 'Pilih Kelolosan', disabled: true },
        { value: 1, text: 'Memenuhi Syarat' }
      ],
      alasanTdkLolos: [
        { value: null, text: 'Pilih Alasan', disabled: true },
        { value: 2, text: 'Kurang Berat Badan' },
        { value: 3, text: 'Lain - lain' }
      ]
    }
  }
}
</script>
