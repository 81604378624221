<template>
  <b-container fluid class="pasien-edit">
    <div v-show="isLoading">
      <div id="show-overlay"></div>
      <Loader :style="{ opacity: .5 }" />
    </div>
    <b-form>
      <iq-card id="data-identitas" class="pasien-form-identitas bg-white shadow-sm border mb-4">
        <div class="py-3 px-3 head-title">
          <p class="h6 text-dark mb-0"><i class="fas fa-user-alt"></i> Data Identitas</p>
        </div>
        <div class="body-form p-4">
          <b-row class="mb-4">
            <b-col cols="12" lg="2">
              <b-form-input v-model="pasien.gelar_depan" :class="['my-input', gelarDepanIsValid ? 'form-control-valid' : '']" size="sm" type="text" placeholder="Gelar Depan"></b-form-input>
            </b-col>
            <b-col cols="12" lg="5">
              <b-form-input v-model="pasien.nama" :class="['my-input', namaIsValid ? 'form-control-valid' : 'form-control-notvalid']" size="sm" type="text" placeholder="Masukan Nama Tanpa Gelar"></b-form-input>
            </b-col>
            <b-col cols="12" lg="3">
              <b-form-input v-model="pasien.gelar_belakang" :class="['my-input', gelarBelakangIsValid ? 'form-control-valid' : '']" size="sm" type="text" placeholder="Gelar Belakang"></b-form-input>
            </b-col>
            <b-col cols="12" lg="2">
              <b-form-input v-model="pasien.panggilan" :class="['my-input', panggilanIsValid ? 'form-control-valid' : '']" size="sm" type="text" placeholder="Nama Panggilan"></b-form-input>
            </b-col>
          </b-row>
          <b-row class="mb-4">
            <b-col cols="12" lg="3">
              <b-form-input v-model="pasien.nik" :class="[ktpIsValid ? 'form-control-valid' : 'form-control-notvalid']" size="sm" type="text" placeholder="No. ktp"></b-form-input>
            </b-col>
            <b-col cols="12" lg="4">
              <multiselect v-model="selected.tempat_lahir" :class="[tempatLahirIsValid ? 'multiselect__tags_color_valid' : '']" :show-labels="false" :limit="3" @input="valTempatLahir" label="deskripsi" placeholder="Tempat Lahir" :options="combo.tempatlahir" :searchable="true">
              </multiselect>
            </b-col>
            <b-col cols="12" lg="3">
              <b-input-group>
                <b-form-input id="example-input" v-model="selectedtgllahir" :class="[tgllahirIsValid ? 'form-control-valid' : 'form-control-notvalid']"
                  type="text" placeholder="DD-MM-YYYY" autocomplete="on"></b-form-input>
                <b-input-group-append>
                  <b-form-datepicker id="example-datepicker1"
                    nav-button-variant="dark" v-model="pasien.tanggal_lahir" button-only right locale="id"
                    aria-controls="example-input" @context="onContextTglLahir"></b-form-datepicker>
                </b-input-group-append>
              </b-input-group>
            </b-col>
            <b-col cols="12" lg="2">
              <multiselect v-model="selected.jenis_kelamin" :class="[jenisKelaminIsValid ? 'multiselect__tags_color_valid' : 'multiselect__tags_color_notvalid']" :show-labels="false" :limit="3" @input="valJenisKelamin" label="deskripsi" placeholder="Jenis Kelamin" :options="combo.jeniskelamin" :searchable="true">
              </multiselect>
            </b-col>
          </b-row>
          <b-row class="mb-4">
            <b-col cols="12" lg="3">
              <multiselect v-model="selected.agama" :class="[agamaIsValid ? 'multiselect__tags_color_valid' : '']" :show-labels="false" :limit="3" @input="valAgama" label="deskripsi" placeholder="Agama" :options="combo.agama" :searchable="true">
              </multiselect>
            </b-col>
            <b-col cols="12" lg="3">
              <multiselect v-model="selected.status_perkawinan" :class="[perkawinanIsValid ? 'multiselect__tags_color_valid' : '']" :show-labels="false" :limit="3" @input="valKawin" label="deskripsi" placeholder="Status Perkawinan" :options="combo.perkawinan" :searchable="true">
              </multiselect>
            </b-col>
            <b-col cols="12" lg="3">
              <multiselect v-model="selected.pendidikan" :class="[pendidikanIsValid ? 'multiselect__tags_color_valid' : '']" :show-labels="false" :limit="3" @input="valPendidikan" label="deskripsi" placeholder="Pendidikan" :options="combo.pendidikan" :searchable="true">
              </multiselect>
            </b-col>
            <b-col cols="12" lg="3">
              <multiselect v-model="selected.pekerjaan" :class="[pekerjaanIsValid ? 'multiselect__tags_color_valid' : '']" :show-labels="false" :limit="3" @input="valPekerjaan" label="deskripsi" placeholder="Pekerjaan" :options="combo.pekerjaan" :searchable="true">
              </multiselect>
            </b-col>
          </b-row>
          <b-row class="mb-0">
            <b-col cols="12" lg="3">
              <multiselect v-model="selected.golongan_darah" :class="[darahIsValid ? 'multiselect__tags_color_valid' : '']" :show-labels="false" :limit="3" @input="valGolDarah" label="deskripsi" placeholder="Gol. Darah" :options="combo.golonganDarah" :searchable="true">
              </multiselect>
            </b-col>
            <b-col cols="12" lg="3">
              <multiselect v-model="selected.kewarganegaraan" :class="[kewarganegaraanIsValid ? 'multiselect__tags_color_valid' : 'multiselect__tags_color_notvalid']" :show-labels="false" :limit="3" @input="valKewarganegaraan" label="deskripsi" placeholder="Kewarganegaraan" :options="combo.kewarganegaraan" :searchable="true">
              </multiselect>
            </b-col>
            <b-col cols="12" lg="3">
              <multiselect v-model="selected.suku" :class="[sukuIsValid ? 'multiselect__tags_color_valid' : 'multiselect__tags_color_notvalid']" :show-labels="false" :limit="3" @input="valSuku" label="deskripsi" placeholder="Suku" :options="combo.suku" :searchable="true">
              </multiselect>
            </b-col>
            <b-col cols="12" lg="3">
              <multiselect v-model="selected.status" :class="[statusIsValid ? 'multiselect__tags_color_valid' : 'multiselect__tags_color_notvalid']" :show-labels="false" :limit="3" @input="valStatusPasien" label="deskripsi" placeholder="Status" :options="combo.statusPasien" :searchable="true">
              </multiselect>
            </b-col>
          </b-row>
        </div>
      </iq-card>
      <b-row>
        <b-col cols="12" lg="6">
          <iq-card id="data-identitas" class="pasien-form-identitas bg-white shadow-sm border mb-4">
            <div class="py-3 px-3 head-title">
              <p class="h6 text-dark mb-0"><i class="fas fa-location-arrow"></i> Alamat Sekarang</p>
            </div>
            <div class="body-form p-4">
              <b-row class="mb-4">
                <b-col cols="12">
                  <b-form-input v-model="pasien.alamat" :class="[alamatIsValid ? 'form-control-valid' : '']" size="sm" type="text" placeholder="Alamat"></b-form-input>
                </b-col>
              </b-row>
              <b-row class="mb-4">
                <b-col cols="12" lg="4">
                  <b-form-input v-model="pasien.rt" :class="[rtIsValid ? 'form-control-valid' : '']" size="sm" type="text" placeholder="RT"></b-form-input>
                </b-col>
                <b-col cols="12" lg="4">
                  <b-form-input v-model="pasien.rw" :class="[rwIsValid ? 'form-control-valid' : '']" size="sm" type="text" placeholder="RW"></b-form-input>
                </b-col>
                <b-col cols="12" lg="4">
                  <b-form-input v-model="pasien.kodepos" :class="[kodeposIsValid ? 'form-control-valid' : '']" size="sm" type="text" placeholder="Kode Pos"></b-form-input>
                </b-col>
              </b-row>
              <b-row class="mb-4">
                <b-col cols="12" lg="6">
                  <multiselect v-model="selected.provinsi" :class="[provinsiIsValid ? 'multiselect__tags_color_valid' : '']" :show-labels="false" :limit="3" @input="getkabupaten" label="deskripsi" placeholder="Provinsi" :options="combo.provinsi" :searchable="true">
                  </multiselect>
                </b-col>
                <b-col cols="12" lg="6">
                  <multiselect v-model="selected.kabupaten" :class="[kabupatenIsValid ? 'multiselect__tags_color_valid' : '']" :show-labels="false" :limit="3" @input="getkecamatan" label="deskripsi" placeholder="Kabupaten" :options="combo.kabupaten" :searchable="true">
                  </multiselect>
                </b-col>
              </b-row>
              <b-row class="mb-0">
                <b-col cols="12" lg="6">
                  <multiselect v-model="selected.kecamatan" :class="[kecamatanIsValid ? 'multiselect__tags_color_valid' : '']" :show-labels="false" :limit="3" @input="getdesa" label="deskripsi" placeholder="Kecamatan" :options="combo.kecamatan" :searchable="true">
                  </multiselect>
                </b-col>
                <b-col cols="12" lg="6">
                  <multiselect v-model="selected.desa" :class="[desaIsValid ? 'multiselect__tags_color_valid' : '']" :show-labels="false" :limit="3" @input="valWilayah" label="deskripsi" placeholder="Desa" :options="combo.desa" :searchable="true">
                  </multiselect>
                </b-col>
              </b-row>
            </div>
          </iq-card>
          <iq-card id="data-identitas" class="pasien-form-identitas bg-white shadow-sm border">
            <div class="d-flex flex-row justify-content-between align-items-center py-3 px-3 head-title">
              <div>
                <p class="h6 text-dark mb-0"><i class="far fa-id-card"></i> Kartu Identitas</p>
              </div>
              <div>
                <b-form-checkbox id="checkbox-1" v-model="statuscheck" @change="autoFill" name="checkbox-1" value="accepted" unchecked-value="not_accepted">
                  <h6 class="mb-0">Sama Dengan Alamat Sekarang ?</h6>
                </b-form-checkbox>
              </div>
            </div>
            <div class="body-form p-4">
              <b-row class="mb-4">
                <b-col cols="12" lg="6">
                  <multiselect v-model="selected.jenis" :class="[jenisIsValid ? 'multiselect__tags_color_valid' : '']" :show-labels="false" :limit="3" @input="valKartu" label="deskripsi" placeholder="Jenis Kartu" :options="combo.jenis" :searchable="true">
                  </multiselect>
                </b-col>
                <b-col cols="12" lg="6">
                  <b-form-input v-model="pasien.identitas[0].nomor" :class="[nokartuIsValid ? 'form-control-valid' : '']" size="sm" type="text" placeholder="No.Kartu"></b-form-input>
                </b-col>
              </b-row>
              <b-row class="mb-4">
                <b-col cols="12">
                  <b-form-input v-model="pasien.identitas[0].alamat" :class="[alamatIdentitasIsValid ? 'form-control-valid' : '']" size="sm" type="text" placeholder="Alamat"></b-form-input>
                </b-col>
              </b-row>
              <b-row class="mb-4">
                <b-col cols="12" lg="4">
                  <b-form-input v-model="pasien.identitas[0].rt" :class="[rtIdentitasIsValid? 'form-control-valid' : '']" size="sm" type="text" placeholder="RT"></b-form-input>
                </b-col>
                <b-col cols="12" lg="4">
                  <b-form-input v-model="pasien.identitas[0].rw" :class="[rwIdentitasIsValid? 'form-control-valid' : '']" size="sm" type="text" placeholder="RW"></b-form-input>
                </b-col>
                <b-col cols="12" lg="4">
                  <b-form-input v-model="pasien.identitas[0].kodepos" :class="[kodeposIdentitasIsValid? 'form-control-valid' : '']" size="sm" type="text" placeholder="Kode Pos"></b-form-input>
                </b-col>
              </b-row>
              <b-row class="mb-4">
                <b-col cols="12" lg="6">
                  <multiselect v-model="selected.identitas.provinsi" :class="[provinsiIdentitasIsValid ? 'multiselect__tags_color_valid' : '']" :show-labels="false" :limit="3" @input="getkabupaten" label="deskripsi" placeholder="Provinsi" :options="combo.provinsi" :searchable="true">
                  </multiselect>
                </b-col>
                <b-col cols="12" lg="6">
                  <multiselect v-model="selected.identitas.kabupaten" :class="[kabupatenIdentitasIsValid ? 'multiselect__tags_color_valid' : '']" :show-labels="false" :limit="3" @input="getkecamatan" label="deskripsi" placeholder="Kabupaten" :options="combo.kabupaten" :searchable="true">
                  </multiselect>
                </b-col>
              </b-row>
              <b-row class="mb-0">
                <b-col cols="12" lg="6">
                  <multiselect v-model="selected.identitas.kecamatan" :class="[kecamatanIdentitasIsValid ? 'multiselect__tags_color_valid' : '']" :show-labels="false" :limit="3" @input="getdesa" label="deskripsi" placeholder="Kecamatan" :options="combo.kecamatan" :searchable="true">
                  </multiselect>
                </b-col>
                <b-col cols="12" lg="6">
                  <multiselect v-model="selected.identitas.desa" :class="[desaIdentitasIsValid ? 'multiselect__tags_color_valid' : '']" :show-labels="false" :limit="3" @input="valWilayahIdentitas" label="deskripsi" placeholder="Desa" :options="combo.desa" :searchable="true">
                  </multiselect>
                </b-col>
              </b-row>
            </div>
          </iq-card>
        </b-col>
        <b-col cols="12" lg="6">
          <iq-card id="data-identitas" class="pasien-form-identitas bg-white shadow-sm border mb-4">
            <div class="py-3 px-3 head-title d-flex align-items-center justify-content-between">
              <div>
                <p class="h6 text-dark mb-0"><i class="fas fa-phone-alt"></i> Kontak</p>
              </div>
              <div>
                <b-button class="mr-2" variant="success" @click="addRow"><h6 class="font-weight-bold text-white">+</h6></b-button>
              </div>
            </div>
            <div class="body-form p-4">
              <b-row class="mb-0 align-items-center mb-2" v-for="(k, index) in pasien.kontak" v-bind:key="k.id">
                <b-col cols="12" lg="1">
                  <b-button variant="danger" @click="deleteRow(index)"><h6 class="font-weight-bold text-white">x</h6></b-button>
                </b-col>
                <b-col cols="12" lg="5">
                   <multiselect v-model="selected.kontak[index].jenis" :class="[jenisKontakIsValid ? 'multiselect__tags_color_valid' : '']" :show-labels="false" :limit="3" @input="valKontak" @select="ValIndex(index)" label="deskripsi" placeholder="Jenis Kontak" :options="combo.kontak" :searchable="true">
                  </multiselect>
                </b-col>
                <b-col cols="12" lg="6">
                 <b-form-input v-model="k.nomor" :class="[nomorKontakIsValid ? 'form-control-valid' : '']" size="sm" type="text" placeholder="ex.08123456789"></b-form-input>
                </b-col>
              </b-row>
            </div>
          </iq-card>
          <iq-card id="data-identitas" class="pasien-form-identitas bg-white shadow-sm border">
            <div class="py-3 px-3 head-title d-flex align-items-center justify-content-between">
              <div>
                <p class="h6 text-dark mb-0"><i class="fas fa-users"></i> Keluarga</p>
              </div>
              <div>
                <b-button class="mr-2" variant="success" @click="addRowB"><h6 class="font-weight-bold text-white">+</h6></b-button>
              </div>
            </div>
            <div class="body-form p-4">
              <div class="mb-lg-3" v-for="(d, index) in pasien.kontakKeluarga" v-bind:key="d.id">
                <b-row class="mb-4">
                  <b-col cols="12" lg="5">
                    <multiselect v-model="selected.kontakKeluarga[index].shdk" :class="[hubunganKeluargaIsValid ? 'multiselect__tags_color_valid' : '']" :show-labels="false" :limit="3" @input="valShdk" @select="ValIndex(index)" label="deskripsi" placeholder="Hubungan dgn Pasien" :options="combo.shdk" :searchable="true">
                    </multiselect>
                  </b-col>
                  <b-col cols="12" lg="7">
                    <b-form-input size="sm" v-model="d.nama" :class="[namaKeluargaIsValid ? 'form-control-valid' : '']" type="text" placeholder="Nama Keluarga Tanpa Gelar"></b-form-input>
                  </b-col>
                </b-row>
                <b-row class="mb-4">
                  <b-col cols="12">
                    <b-form-input size="sm" v-model="d.alamat" :class="[alamatKeluargaIsValid ? 'form-control-valid' : '']" type="text" placeholder="Alamat"></b-form-input>
                  </b-col>
                </b-row>
                <b-row class="">
                  <b-col cols="12" lg="5">
                    <b-form-input size="sm" v-model="d.nomor" :class="[nomorKeluargaIsValid ? 'form-control-valid' : '']" type="text" placeholder="No.Kartu"></b-form-input>
                  </b-col>
                  <b-col cols="12" lg="7">
                    <multiselect v-model="selected.kontakKeluarga[index].jenis" :class="[jenisKeluargaIsValid ? 'form-control-valid' : '']" :show-labels="false" :limit="3" @input="valKeluargaKartu" @select="ValIndex(index)" label="deskripsi" placeholder="Jenis Kartu" :options="combo.kontak" :searchable="true">
                    </multiselect>
                  </b-col>
                </b-row>
                <div class="mt-4">
                  <b-button variant="danger" @click="deleteRowB(index)"><h6 class="font-weight-bold text-white">x</h6></b-button>
                </div>
              </div>
            </div>
          </iq-card>
        </b-col>
      </b-row>
      <iq-card>
        <div class="d-flex flex-row align-items-center justify-content-end px-3 py-2">
          <!-- <div class="m-1">
            <b-button variant="success" class=""><i class="fas fa-print"></i> Simpan & Cetak Kartu</b-button>
          </div> -->
          <div class="m-1">
            <b-button variant="secondary" @click="backtopasien" class=""><i class="fas fa-undo"></i> Kembali</b-button>
          </div>
          <div class="m-1">
            <b-button variant="primary" @click="SimpanData" class=""><i class="far fa-arrow-alt-circle-up"></i> Perbarui Data</b-button>
          </div>
        </div>
      </iq-card>
    </b-form>
    <b-modal ref="modal-sukses" size="md" title="Informasi" ok-title="OK" :ok-only=true :hide-header=true
      centered>
      <div class="text-center px-lg-4 px-2">
        <lottie :options="defaultOptions" :autoPlay="true" :width="250" :height="250" />
        <p class="h6 font-weight-bold text-success mb-0">Data Berhasil Disimpan</p>
      </div>
    </b-modal>
    <b-modal ref="modal-gagal" size="md" title="Informasi" ok-title="OK" :ok-only=true :hide-header=true
      centered>
      <div class="text-center px-lg-4 px-2">
        <lottie :options="defaultOptions2" :autoPlay="true" :width="250" :height="250" />
        <p class="h6 text-danger font-weight-bold mb-0">Data Gagal Disimpan</p>
      </div>
    </b-modal>
  </b-container>
</template>

<script>
import iqCard from '../../../components/xray/cards/iq-card.vue'
import { xray } from '../../../config/pluginInit'
import Multiselect from 'vue-multiselect'
import combo from '../../../services/combo'
import Pasien from '../../../services/pasien'
import Loader from '../../../components/xray/loader/Loader'
import Lottie from 'vue-lottie'
import animationLogo from '../../../assets/lottie/checked.json'
import animationLogo2 from '../../../assets/lottie/failed.json'

export default {
  components: {
    iqCard,
    Lottie,
    Loader,
    Multiselect
  },
  mounted () {
    xray.index()
    this.viewPasien(this.$route.params.id)
    this.getjk()
    this.gettempatlahir()
    this.getagama()
    this.getkawin()
    this.getpendidikan()
    this.getpekerjaan()
    this.getgoldarah()
    this.getkewarganegaraan()
    this.getsuku()
    this.getstatuspasien()
    this.getprovinsi()
    this.getkartu()
    this.getkontak()
    this.getshdk()
  },
  data () {
    return {
      defaultOptions: { animationData: animationLogo, loop: true },
      defaultOptions2: { animationData: animationLogo2, loop: true },
      index: null,
      statuscheck: '',
      selectedtgllahir: '',
      combo: {
        tempatlahir: [],
        jeniskelamin: [],
        agama: [],
        perkawinan: [],
        pendidikan: [],
        pekerjaan: [],
        golonganDarah: [],
        kewarganegaraan: [],
        suku: [],
        statusPasien: [],
        provinsi: [],
        kabupaten: [],
        kecamatan: [],
        desa: [],
        jenis: [],
        kontak: [],
        shdk: []
      },
      selected: {
        tempat_lahir: [],
        jenis_kelamin: [],
        agama: [],
        status_perkawinan: [],
        pendidikan: [],
        pekerjaan: [],
        golongan_darah: [],
        kewarganegaraan: [],
        suku: [],
        status: [],
        provinsi: [],
        kabupaten: [],
        kecamatan: [],
        desa: [],
        jenis: [],
        identitas: {
          provinsi: [],
          kabupaten: [],
          kecamatan: [],
          desa: []
        },
        kontak: [{
          jenis: []
        }],
        kontakKeluarga: [{
          shdk: [],
          jenis: []
        }]
      },
      pasien: {
        nik: '',
        gelar_depan: '',
        nama: '',
        gelar_belakang: '',
        panggilan: '',
        tanggal_lahir: '',
        alamat: '',
        rt: '',
        rw: '',
        kodepos: '',
        oleh: JSON.parse(localStorage.getItem('user')).id,
        tempat_lahir: null,
        jenis_kelamin: null,
        agama: null,
        status_perkawinan: null,
        pendidikan: null,
        pekerjaan: null,
        golongan_darah: null,
        kewarganegaraan: null,
        suku: null,
        status: null,
        wilayah: null,
        identitas: [{
          jenis: null,
          nomor: null,
          alamat: '',
          rt: null,
          rw: null,
          kodepos: null,
          wilayah: null
        }],
        kontak: [{
          jenis: null,
          nomor: null
        }],
        kontakKeluarga: [{
          shdk: null,
          nama: '',
          alamat: '',
          jenis: null,
          nomor: null
        }]
      },
      isLoading: false,
      error: []
    }
  },
  computed: {
    gelarDepanIsValid () {
      return (
        this.pasien.gelar_depan !== '' && this.pasien.gelar_depan != null
      )
    },
    namaIsValid () {
      return (
        this.pasien.nama !== '' && this.pasien.nama != null
      )
    },
    gelarBelakangIsValid () {
      return (
        this.pasien.gelar_belakang !== '' && this.pasien.gelar_belakang != null
      )
    },
    panggilanIsValid () {
      return (
        this.pasien.panggilan !== '' && this.pasien.panggilan != null
      )
    },
    ktpIsValid () {
      return (
        this.pasien.nik !== '' && this.pasien.nik != null
      )
    },
    tempatLahirIsValid () {
      return (
        this.pasien.tempat_lahir != null
      )
    },
    tgllahirIsValid () {
      return (
        this.pasien.tanggal_lahir !== ''
      )
    },
    jenisKelaminIsValid () {
      return (
        this.pasien.jenis_kelamin != null
      )
    },
    agamaIsValid () {
      return (
        this.pasien.agama != null
      )
    },
    perkawinanIsValid () {
      return (
        this.pasien.status_perkawinan != null
      )
    },
    pendidikanIsValid () {
      return (
        this.pasien.pendidikan != null
      )
    },
    pekerjaanIsValid () {
      return (
        this.pasien.pekerjaan != null
      )
    },
    darahIsValid () {
      return (
        this.pasien.golongan_darah != null
      )
    },
    kewarganegaraanIsValid () {
      return (
        this.pasien.kewarganegaraan != null
      )
    },
    sukuIsValid () {
      return (
        this.pasien.suku != null
      )
    },
    statusIsValid () {
      return (
        this.pasien.status != null
      )
    },
    alamatIsValid () {
      return (
        this.pasien.alamat != null && this.pasien.alamat !== ''
      )
    },
    rtIsValid () {
      return (
        this.pasien.rt !== '' && this.pasien.rt !== null
      )
    },
    rwIsValid () {
      return (
        this.pasien.rw !== '' && this.pasien.rw !== null
      )
    },
    kodeposIsValid () {
      return (
        this.pasien.kodepos !== '' && this.pasien.kodepos !== null
      )
    },
    jenisIsValid () {
      return (
        this.pasien.identitas[0].jenis != null
      )
    },
    nokartuIsValid () {
      return (
        this.pasien.identitas[0].nomor != null && this.pasien.identitas[0].nomor !== ''
      )
    },
    alamatIdentitasIsValid () {
      return (
        this.pasien.identitas[0].alamat !== '' && this.pasien.identitas[0].alamat != null
      )
    },
    rtIdentitasIsValid () {
      return (
        this.pasien.identitas[0].rt != null && this.pasien.identitas[0].rt !== ''
      )
    },
    rwIdentitasIsValid () {
      return (
        this.pasien.identitas[0].rw != null && this.pasien.identitas[0].rw !== ''
      )
    },
    kodeposIdentitasIsValid () {
      return (
        this.pasien.identitas[0].kodepos != null && this.pasien.identitas[0].kodepos !== ''
      )
    },
    jenisKontakIsValid () {
      return (
        this.pasien.kontak[0].jenis != null
      )
    },
    nomorKontakIsValid () {
      return (
        this.pasien.kontak[0].nomor != null && this.pasien.kontak[0].nomor !== ''
      )
    },
    hubunganKeluargaIsValid () {
      return (
        this.pasien.kontakKeluarga[0].shdk != null && this.pasien.kontakKeluarga[0].shdk !== ''
      )
    },
    namaKeluargaIsValid () {
      return (
        this.pasien.kontakKeluarga[0].nama != null && this.pasien.kontakKeluarga[0].nama !== ''
      )
    },
    alamatKeluargaIsValid () {
      return (
        this.pasien.kontakKeluarga[0].alamat != null && this.pasien.kontakKeluarga[0].alamat !== ''
      )
    },
    jenisKeluargaIsValid () {
      return (
        this.pasien.kontakKeluarga[0].jenis != null && this.pasien.kontakKeluarga[0].jenis !== ''
      )
    },
    nomorKeluargaIsValid () {
      return (
        this.pasien.kontakKeluarga[0].nomor != null && this.pasien.kontakKeluarga[0].nomor !== ''
      )
    },
    provinsiIsValid () {
      return (
        this.selected.provinsi != null && this.selected.provinsi !== ''
      )
    },
    kabupatenIsValid () {
      return (
        this.selected.kabupaten != null && this.selected.kabupaten !== ''
      )
    },
    kecamatanIsValid () {
      return (
        this.selected.kecamatan != null && this.selected.kecamatan !== ''
      )
    },
    desaIsValid () {
      return (
        this.selected.desa != null && this.selected.desa !== ''
      )
    },
    provinsiIdentitasIsValid () {
      return (
        this.selected.identitas.provinsi != null && this.selected.provinsi !== '' && this.selected.identitas.provinsi.length !== 0
      )
    },
    kabupatenIdentitasIsValid () {
      return (
        this.selected.identitas.kabupaten != null && this.selected.kabupaten !== '' && this.selected.identitas.kabupaten.length !== 0
      )
    },
    kecamatanIdentitasIsValid () {
      return (
        this.selected.identitas.kecamatan !== null && this.selected.kecamatan !== '' && this.selected.identitas.kecamatan.length !== 0
      )
    },
    desaIdentitasIsValid () {
      return (
        this.selected.identitas.desa !== null && this.selected.identitas.desa !== '' && this.selected.identitas.desa.length !== 0
      )
    }
  },
  methods: {
    autoFill () {
      if (this.statuscheck === 'accepted') {
        this.selected.identitas.provinsi = this.selected.provinsi
        this.selected.identitas.kabupaten = this.selected.kabupaten
        this.selected.identitas.kecamatan = this.selected.kecamatan
        this.selected.identitas.desa = this.selected.desa

        this.pasien.identitas[0].alamat = this.pasien.alamat
        this.pasien.identitas[0].rt = this.pasien.rt
        this.pasien.identitas[0].rw = this.pasien.rw
        this.pasien.identitas[0].kodepos = this.pasien.kodepos
        this.pasien.identitas[0].wilayah = this.pasien.wilayah
      } else {
        this.selected.identitas.provinsi = ''
        this.selected.identitas.kabupaten = ''
        this.selected.identitas.kecamatan = ''
        this.selected.identitas.desa = ''

        this.pasien.identitas[0].alamat = ''
        this.pasien.identitas[0].rt = ''
        this.pasien.identitas[0].rw = ''
        this.pasien.identitas[0].kodepos = ''
        this.pasien.identitas[0].wilayah = ''
      }
    },
    async viewPasien (id) {
      await Pasien.show(id).then(response => {
        if (response.status === 200) {
          this.pasien.nik = response.data.ihs[0].nik
          this.pasien.gelar_depan = response.data.pasien[0].gelar_depan
          this.pasien.nama = response.data.pasien[0].nama
          this.pasien.gelar_belakang = response.data.pasien[0].gelar_belakang
          this.pasien.tanggal_lahir = response.data.pasien[0].tanggal_lahir
          this.pasien.alamat = response.data.pasien[0].alamat
          this.pasien.rt = response.data.pasien[0].rt
          this.pasien.rw = response.data.pasien[0].rw
          this.pasien.kodepos = response.data.pasien[0].kodepos
          this.pasien.tempat_lahir = response.data.pasien[0].tempat_lahir
          this.pasien.jenis_kelamin = response.data.pasien[0].jenis_kelamin
          this.pasien.agama = response.data.pasien[0].agama
          this.pasien.status_perkawinan = response.data.pasien[0].status_perkawinan
          this.pasien.pendidikan = response.data.pasien[0].pendidikan
          this.pasien.pekerjaan = response.data.pasien[0].pekerjaan
          this.pasien.golongan_darah = response.data.pasien[0].golongan_darah
          this.pasien.kewarganegaraan = response.data.pasien[0].kewarganegaraan
          this.pasien.suku = response.data.pasien[0].suku
          this.pasien.status = response.data.pasien[0].status
          this.pasien.wilayah = response.data.pasien[0].id_wilayah_kelurahan

          this.selected.tempat_lahir = { deskripsi: response.data.pasien[0].nm_tempat_lahir, id_referensi: response.data.pasien[0].tempat_lahir }
          this.selected.jenis_kelamin = { deskripsi: response.data.pasien[0].nm_jenis_kelamin, id_referensi: response.data.pasien[0].jenis_kelamin }
          this.selected.agama = response.data.pasien[0].agama !== null ? { deskripsi: response.data.pasien[0].nm_agama, id_referensi: response.data.pasien[0].agama } : ''
          this.selected.status_perkawinan = response.data.pasien[0].status_perkawinan !== null ? { deskripsi: response.data.pasien[0].nm_status_perkawinan, id_referensi: response.data.pasien[0].status_perkawinan } : ''
          this.selected.pendidikan = response.data.pasien[0].pendidikan !== null ? { deskripsi: response.data.pasien[0].nm_pendidikan, id_referensi: response.data.pasien[0].pendidikan } : ''
          this.selected.pekerjaan = response.data.pasien[0].pekerjaan !== null ? { deskripsi: response.data.pasien[0].nm_pekerjaan, id_referensi: response.data.pasien[0].pekerjaan } : ''
          this.selected.golongan_darah = response.data.pasien[0].golongan_darah !== null ? { deskripsi: response.data.pasien[0].nm_golongan_darah, id_referensi: response.data.pasien[0].golongan_darah } : ''
          this.selected.kewarganegaraan = response.data.pasien[0].kewarganegaraan !== null ? { deskripsi: response.data.pasien[0].nm_kewarganegaraan, id_referensi: response.data.pasien[0].kewarganegaraan } : ''
          this.selected.suku = response.data.pasien[0].suku !== null ? { deskripsi: response.data.pasien[0].nm_suku, id_referensi: response.data.pasien[0].suku } : ''
          this.selected.status = response.data.pasien[0].status !== null ? { deskripsi: response.data.pasien[0].nm_status, id_referensi: response.data.pasien[0].status } : ''
          this.selected.provinsi = response.data.pasien[0].id_wilayah_provinsi !== null ? { deskripsi: response.data.pasien[0].nm_wilayah_provinsi, id_referensi: response.data.pasien[0].id_wilayah_provinsi } : ''
          this.selected.kabupaten = response.data.pasien[0].id_wilayah_kabupaten !== null ? { deskripsi: response.data.pasien[0].nm_wilayah_kabupaten, id_referensi: response.data.pasien[0].id_wilayah_kabupaten } : ''
          this.selected.kecamatan = response.data.pasien[0].id_wilayah_kecamatan !== null ? { deskripsi: response.data.pasien[0].nm_wilayah_kecamatan, id_referensi: response.data.pasien[0].id_wilayah_kecamatan } : ''
          this.selected.desa = response.data.pasien[0].id_wilayah_kelurahan !== null ? { deskripsi: response.data.pasien[0].nm_wilayah_kelurahan, id_referensi: response.data.pasien[0].id_wilayah_kelurahan } : ''

          if (response.data.identitas !== undefined) {
            this.pasien.identitas[0].jenis = response.data.identitas[0].jenis
            this.pasien.identitas[0].nomor = response.data.identitas[0].nomor
            this.pasien.identitas[0].alamat = response.data.identitas[0].alamat
            this.pasien.identitas[0].rt = response.data.identitas[0].rt
            this.pasien.identitas[0].rw = response.data.identitas[0].rw
            this.pasien.identitas[0].kodepos = response.data.identitas[0].kodepos
            this.pasien.identitas[0].wilayah = response.data.identitas[0].id_wilayah_kelurahan

            this.selected.jenis = response.data.identitas[0].jenis !== null ? { deskripsi: response.data.identitas[0].nm_jenis, id_referensi: response.data.identitas[0].jenis } : ''
            this.selected.identitas.provinsi = response.data.identitas[0].id_wilayah_provinsi !== null ? { deskripsi: response.data.identitas[0].nm_wilayah_provinsi, id_referensi: response.data.identitas[0].id_wilayah_provinsi } : ''
            this.selected.identitas.kabupaten = response.data.identitas[0].id_wilayah_kabupaten !== null ? { deskripsi: response.data.identitas[0].nm_wilayah_kabupaten, id_referensi: response.data.identitas[0].id_wilayah_kabupaten } : ''
            this.selected.identitas.kecamatan = response.data.identitas[0].id_wilayah_kecamatan !== null ? { deskripsi: response.data.identitas[0].nm_wilayah_kecamatan, id_referensi: response.data.identitas[0].id_wilayah_kecamatan } : ''
            this.selected.identitas.desa = response.data.identitas[0].id_wilayah_kelurahan !== null ? { deskripsi: response.data.identitas[0].nm_wilayah_kelurahan, id_referensi: response.data.identitas[0].id_wilayah_kelurahan } : ''
          }
          if (response.data.kontak !== undefined) {
            this.deleteRow(0)
            response.data.kontak.map((value, index) => {
              this.pasien.kontak.push({
                jenis: value.jenis,
                nomor: value.nomor
              })
              this.selected.kontak.push({
                jenis: [{ deskripsi: value.nm_jenis, id_referensi: value.jenis }]
              })
            })
          }
          if (response.data.kontakKeluarga !== undefined) {
            this.deleteRowB(0)
            response.data.kontakKeluarga.map((value, index) => {
              this.pasien.kontakKeluarga.push({
                shdk: value.shdk,
                nama: value.nama,
                alamat: value.alamat,
                jenis: value.jenis,
                nomor: value.nomor
              })
              this.selected.kontakKeluarga.push({
                shdk: [{ deskripsi: value.nm_shdk, id_referensi: value.shdk }],
                jenis: [{ deskripsi: value.nm_jenis, id_referensi: value.jenis }]
              })
            })
          }
        }
      }).catch(error => {
        this.error = error.response
      })
    },
    pad2 (n) {
      return (n < 10 ? '0' : '') + n
    },
    onContextTglLahir (ctx) {
      var date = new Date(ctx.selectedYMD)
      var month = this.pad2(date.getMonth() + 1)
      var day = this.pad2(date.getDate())
      var year = date.getFullYear()

      var formattedDate = day + '-' + month + '-' + year
      this.selectedtgllahir = formattedDate === 'NaN-NaN-NaN' ? ctx.selectedYMD : formattedDate
      this.pasien.tanggal_lahir = ctx.selectedYMD
    },
    addRow () {
      this.pasien.kontak.push(
        {
          jenis: null,
          nomor: ''
        }
      )
      this.selected.kontak.push(
        {
          jenis: []
        }
      )
    },
    deleteRow (index) {
      this.pasien.kontak.splice(index, 1)
      this.selected.kontak.splice(index, 1)
    },
    addRowB () {
      this.pasien.kontakKeluarga.push(
        {
          shdk: null,
          nama: '',
          alamat: '',
          jenis: null,
          nomor: null
        }
      )
      this.selected.kontakKeluarga.push(
        {
          shdk: [],
          jenis: []
        }
      )
    },
    deleteRowB (index) {
      this.pasien.kontakKeluarga.splice(index, 1)
      this.selected.kontakKeluarga.splice(index, 1)
    },

    // valselectoption start
    valTempatLahir (value) {
      this.pasien.tempat_lahir = value.id
    },
    valJenisKelamin (value) {
      this.pasien.jenis_kelamin = value.id_referensi
    },
    valAgama (value) {
      this.pasien.agama = value.id_referensi
    },
    valKawin (value) {
      this.pasien.status_perkawinan = value.id_referensi
    },
    valPendidikan (value) {
      this.pasien.pendidikan = value.id_referensi
    },
    valPekerjaan (value) {
      this.pasien.pekerjaan = value.id_referensi
    },
    valGolDarah (value) {
      this.pasien.golongan_darah = value.id_referensi
    },
    valKewarganegaraan (value) {
      this.pasien.kewarganegaraan = value.id
    },
    valSuku (value) {
      this.pasien.suku = value.id_referensi
    },
    valStatusPasien (value) {
      this.pasien.status = value.id_referensi
    },
    valWilayah (value) {
      this.pasien.wilayah = value.id
    },
    valKartu (value) {
      this.pasien.identitas[0].jenis = value.id_referensi
    },
    valWilayahIdentitas (value) {
      this.pasien.identitas[0].wilayah = value.id
    },
    valKontak (value) {
      const index = this.index
      this.pasien.kontak[index].jenis = value.id_referensi
    },
    // valselectoption end
    valShdk (value) {
      const index = this.index
      this.pasien.kontakKeluarga[index].shdk = value.id_referensi
    },
    valKeluargaKartu (value) {
      const index = this.index
      this.pasien.kontakKeluarga[index].jenis = value.id_referensi
    },
    // index add row select option
    ValIndex (index) {
      this.index = index
    },
    // combo start
    async gettempatlahir () {
      await combo.getalltemlahir().then(response => {
        if (response.status === 200) {
          this.combo.tempatlahir = response.data.tempatLahir
        }
      }).catch(error => {
        this.error = error.response
      })
    },
    async getjk () {
      await combo.getjeniskelamin().then(response => {
        if (response.status === 200) {
          this.combo.jeniskelamin = response.data.jenisKelamin
        }
      }).catch(error => {
        this.error = error.response
      })
    },
    async getagama () {
      await combo.getallagama().then(response => {
        if (response.status === 200) {
          this.combo.agama = response.data.agama
        }
      }).catch(error => {
        this.error = error.response
      })
    },
    async getkawin () {
      await combo.getallperkawinan().then(response => {
        if (response.status === 200) {
          this.combo.perkawinan = response.data.statusPerkawinan
        }
      }).catch(error => {
        this.error = error.response
      })
    },
    async getpendidikan () {
      await combo.getallpendidikan().then(response => {
        if (response.status === 200) {
          this.combo.pendidikan = response.data.pendidikan
        }
      }).catch(error => {
        this.error = error.response
      })
    },
    async getpekerjaan () {
      await combo.getallpekerjaan().then(response => {
        if (response.status === 200) {
          this.combo.pekerjaan = response.data.pekerjaan
        }
      }).catch(error => {
        this.error = error.response
      })
    },
    async getgoldarah () {
      await combo.getallgolongandarah().then(response => {
        if (response.status === 200) {
          this.combo.golonganDarah = response.data.golonganDarah
        }
      }).catch(error => {
        this.error = error.response
      })
    },
    async getkewarganegaraan () {
      await combo.getallkewarganegaraan().then(response => {
        if (response.status === 200) {
          this.combo.kewarganegaraan = response.data.kewarganegaraan
        }
      }).catch(error => {
        this.error = error.response
      })
    },
    async getsuku () {
      await combo.getallsuku().then(response => {
        if (response.status === 200) {
          this.combo.suku = response.data.suku
        }
      }).catch(error => {
        this.error = error.response
      })
    },
    async getstatuspasien () {
      await combo.getallstatuspasien().then(response => {
        if (response.status === 200) {
          this.combo.statusPasien = response.data.statusPasien
        }
      }).catch(error => {
        this.error = error.response
      })
    },
    // alamat sekarang
    async getprovinsi () {
      await combo.getallprovinsi().then(response => {
        if (response.status === 200) {
          this.combo.provinsi = response.data.provinsi
        }
      }).catch(error => {
        this.error = error.response
      })
    },
    async getkabupaten (value) {
      const data = {
        id: value.id
      }
      await combo.getallkabupaten(data).then(response => {
        if (response.status === 200) {
          this.combo.kabupaten = response.data.kabupaten
        }
      }).catch(error => {
        this.error = error.response
      })
    },
    async getkecamatan (value) {
      const data = {
        id: value.id
      }
      await combo.getallkecamatan(data).then(response => {
        if (response.status === 200) {
          this.combo.kecamatan = response.data.kecamatan
        }
      }).catch(error => {
        this.error = error.response
      })
    },
    async getdesa (value) {
      const data = {
        id: value.id
      }
      await combo.getalldesa(data).then(response => {
        if (response.status === 200) {
          this.combo.desa = response.data.desa
        }
      }).catch(error => {
        this.error = error.response
      })
    },
    // kartu identitas
    async getkartu () {
      await combo.getallkartu().then(response => {
        if (response.status === 200) {
          this.combo.jenis = response.data.kartu
        }
      }).catch(error => {
        this.error = error.response
      })
    },
    // Kontak
    async getkontak () {
      await combo.getallkontak().then(response => {
        if (response.status === 200) {
          this.combo.kontak = response.data.kontak
        }
      }).catch(error => {
        this.error = error.response
      })
    },
    // Kontak Keluarga
    async getshdk () {
      await combo.getallshdk().then(response => {
        if (response.status === 200) {
          this.combo.shdk = response.data.shdk
        }
      }).catch(error => {
        this.error = error.response
      })
    },
    // combo end
    async SimpanData () {
      this.isLoading = true
      const data = this.pasien
      await Pasien.store(data)
        .then((response) => {
          if (response.status === 200) {
            this.isLoading = false
            this.$refs['modal-sukses'].show()
          } else {
            this.isLoading = false
            this.$refs['modal-gagal'].show()
          }
        })
        .catch(() => {
          this.isLoading = false
          this.$refs['modal-gagal'].show()
        })
    },
    backtopasien () {
      this.$router.replace({ path: `/pasien/view/${this.$route.params.id}` })
    }
  }
}
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style>
  .pasien-form-identitas .head-title {
    background: #ceebee;
    border-top-left-radius: 25px;
    border-top-right-radius: 25px;
  }
  .pasien-form-identitas .pasien-cstm-slctd{
    height: 45px!important;
    height: 45px!important;
    border-radius: 10px!important;
  }
  .pasien-edit .multiselect__tags {
    border-radius: 10px;
    height: 45px!important;
  }
  .pasien-edit .multiselect, .pasien-edit .multiselect__input, .pasien-edit .multiselect__single {
    font-size: 14px!important;
    color: black;
    font-weight: bold!important;
  }
  .pasien-edit .multiselect__tags_color_valid {
      border: 2px solid #28a745!important;
      border-radius: 10px;
  }
  .pasien-edit .multiselect__tags_color_notvalid {
      border: 2px solid #cf0000!important;
      border-radius: 10px;
  }
  .pasien-edit .form-control-valid {
    border: 2px solid!important;
    border-color: #28a745!important;
  }
  .pasien-edit .form-control-notvalid {
    border: 2px solid!important;
    border-color: #cf0000!important;
  }
  .pasien-edit .form-control {
    color: black!important;
    font-weight: bold!important;
  }
</style>
