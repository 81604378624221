import Api from '../axiosInstance'
const version = '/v1'
export default {
  findAll (currentPage, perPage, filter) {
    return Api.eksport.get(`${version}/practitioner/sakti?param0=sakti&param1=${currentPage}&param2=${perPage}&param3=${filter}`)
  },
  findId (id) {
    return Api.eksport.get(`${version}/practitioner/sakti/${id}?param0=sakti`)
  },
  upload (data) {
    return Api.eksport.post(`${version}/practitioner/sakti`, data)
  }
}
