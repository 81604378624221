<template>
  <div class="jadwal-cathlab-add mb-4">
    <div v-show="isLoading">
      <div id="show-overlay"></div>
      <Loader :style="{ opacity: .5 }" />
    </div>
    <b-container fluid>
      <div class="bg-white px-4 py-4 rounded shadow-sm">
        <div class="body-section">
          <b-row>
            <b-col>
              <iq-card class="shadow">
                <div class="head-card bg-primary px-4 py-3 card-top-radius">
                  <p class="h6 text-white font-weight-bold text-uppercase mb-0"><i class="fas fa-id-card"></i> data diri
                  </p>
                </div>
                <hr class="m-0">
                <div class="px-4 pt-3">
                  <b-row class="mb-3 align-items-center">
                    <b-col cols="12" lg="3">
                      <p class="h6 text-dark text-uppercase mb-0">Nama</p>
                    </b-col>
                    <b-col cols="12" lg="1">
                      <p class="h6 text-dark text-uppercase mb-0">:</p>
                    </b-col>
                    <b-col>
                      <b-form-input class="form-control-cathlab" v-model="nama" type="text" size="sm" id="colFormLabelSm" readonly>
                      </b-form-input>
                    </b-col>
                  </b-row>
                </div>
                <hr class="m-0">
                <div class="body-card px-4 py-4">
                  <b-row class="mb-3 align-items-center">
                    <b-col cols="12" lg="3">
                      <p class="h6 text-dark text-uppercase mb-0">Norm</p>
                    </b-col>
                    <b-col cols="12" lg="1">
                      <p class="h6 text-dark text-uppercase mb-0">:</p>
                    </b-col>
                    <b-col>
                      <b-form-input v-model="form.norm" :state="validationNorm" class="form-control-cathlab" type="text" size="sm"
                        id="colFormLabelSm" readonly></b-form-input>
                    </b-col>
                  </b-row>
                  <b-row class="mb-3 align-items-center">
                    <b-col cols="12" lg="3">
                      <p class="h6 text-dark text-uppercase mb-0">Nomor Kontak</p>
                    </b-col>
                    <b-col cols="12" lg="1">
                      <p class="h6 text-dark text-uppercase mb-0">:</p>
                    </b-col>
                    <b-col>
                      <b-form-input v-model="form.nomor" class="form-control-cathlab" type="text" size="sm"
                        id="colFormLabelSm"></b-form-input>
                    </b-col>
                  </b-row>
                  <b-row class="mb-3 align-items-center">
                    <b-col cols="12" lg="3">
                      <p class="h6 text-dark text-uppercase mb-0">Email</p>
                    </b-col>
                    <b-col cols="12" lg="1">
                      <p class="h6 text-dark text-uppercase mb-0">:</p>
                    </b-col>
                    <b-col>
                      <b-form-input v-model="form.email" class="form-control-cathlab" type="text" size="sm"
                        id="colFormLabelSm"></b-form-input>
                    </b-col>
                  </b-row>
                </div>
                <hr class="m-0">
                <div class="body-section-2 px-4 py-4">
                  <b-row class="mb-0 align-items-center">
                    <b-col cols="12" lg="3">
                      <p class="h6 text-dark text-uppercase mb-0">Status</p>
                    </b-col>
                    <b-col cols="12" lg="1">
                      <p class="h6 text-dark text-uppercase mb-0">:</p>
                    </b-col>
                    <b-col>
                      <b-form-group class="mb-0">
                        <b-form-radio-group id="radio-group-1" @change="valStatus" v-model="selectedStatus" :options="options"
                          name="radio-options"></b-form-radio-group>
                      </b-form-group>
                    </b-col>
                  </b-row>
                </div>
              </iq-card>
              <iq-card class="shadow mb-4">
                <div class="head-card bg-primary px-4 py-3 card-top-radius">
                  <p class="h6 text-white font-weight-bold text-uppercase mb-0"><i class="far fa-calendar-alt"></i>
                    tanggal</p>
                </div>
                <hr class="m-0">
                <div class="body-card px-4 py-4">
                  <flat-pickr @on-change="valTgl" v-model="tanggal" :config="config" class="w-100 calender-small flatpickr-cstm-lab"
                    placeholder="PILIH TANGGAL">
                  </flat-pickr>
                </div>
              </iq-card>
            </b-col>
            <b-col>
              <iq-card class="mb-4 shadow">
                <div class="head-card bg-primary px-4 py-3 card-top-radius">
                  <p class="h6 text-white font-weight-bold text-uppercase mb-0"><i class="fas fa-user-md"></i>
                    dokter</p>
                </div>
                <hr class="m-0">
                <div class="body-card px-4 py-4">
                  <multiselect v-model="selected.dokter" :show-labels="false" :limit="3" @input="valDokter"
                    label="deskripsi" placeholder="DOKTER" :options="combo.dokter" :searchable="true">
                  </multiselect>
                </div>
              </iq-card>
              <iq-card class="shadow mb-4">
                <div
                  class="head-card bg-primary px-4 py-3 card-top-radius d-flex flex-row justify-content-between align-items-center">
                  <div>
                    <p class="h6 text-white font-weight-bold text-uppercase mb-0"><i
                        class="fas fa-hand-holding-medical"></i>tindakan</p>
                  </div>
                  <div class="cursor-effect">
                    <p class="h5 mb-0" @click="addRow">
                      <i class="fas fa-plus-circle"></i>
                    </p>
                  </div>
                </div>
                <hr class="m-0">
                <div class="body-card px-4 py-4">
                  <div>
                    <b-row class="align-items-center mb-3" v-for="(k, index) in form.detail" v-bind:key="k.id">
                      <b-col cols="12" lg="11">
                        <multiselect v-model="selected.tindakan[index].detail" :show-labels="false" :limit="3"
                          @input="valTindakan" @select="valIndex(index)" label="deskripsi" placeholder="TINDAKAN"
                          :options="combo.tindakan" :searchable="true">
                        </multiselect>
                      </b-col>
                      <b-col cols="12" lg="1">
                        <div class="cursor-effect text-right">
                          <p class="h5 mb-0" @click="deleteRow(index)"><i class="fas text-danger fa-minus-circle"></i></p>
                        </div>
                      </b-col>
                    </b-row>
                  </div>
                </div>
              </iq-card>
            </b-col>
          </b-row>
        </div>
        <div class="footer-section">
          <iq-card class="bg-transparent shadow-none">
            <div class="d-flex flex-row align-items-center justify-content-start px-3 py-2">
              <div class="m-1">
                <b-button @click="backtojadwal" variant="secondary" class=""><i class="fas fa-undo"></i> Kembali
                </b-button>
              </div>
              <div class="m-1" v-if="pasien.head[0].status_batal === 2">
                <b-button disabled variant="primary" @click="onUpdate" class=""><i class="fas fa-save"></i> Update</b-button>
              </div>
              <div class="m-1" v-else>
                <b-button variant="primary" @click="onUpdate" class=""><i class="fas fa-save"></i> Update</b-button>
              </div>
            </div>
          </iq-card>
        </div>
        <b-modal ref="modal" size="md" title="Informasi" ok-title="Oke" @ok="backtojadwal" :ok-only=true :hide-header=true centered>
          <div class="text-center px-lg-4 px-2">
            <lottie :options="defaultOptions" :autoPlay="true" :width="250" :height="250" />
            <p class="h6 text-success font-weight-bold text-uppercase mb-0">{{notif}}</p>
          </div>
        </b-modal>
        <b-modal ref="modal-gagal" size="md" title="Informasi" ok-title="Oke" @ok="backtojadwal" :ok-only=true :hide-header=true centered>
          <div class="text-center px-lg-4 px-2">
            <lottie :options="defaultOptions2" :autoPlay="true" :width="250" :height="250" />
            <p class="h6 text-danger font-weight-bold text-uppercase mb-0">{{notif}}</p>
          </div>
        </b-modal>
      </div>
    </b-container>
  </div>
</template>
<script>
import { xray } from '../../../config/pluginInit'
import Lab from '../../../services/laboratorium'
import iqCard from '../../../components/xray/cards/iq-card.vue'
import Loader from '../../../components/xray/loader/Loader'
import Multiselect from 'vue-multiselect'
import FlatPickr from 'vue-flatpickr-component'
import 'flatpickr/dist/flatpickr.css'
import Lottie from 'vue-lottie'
import animationLogo from '../../../assets/lottie/checked.json'
import animationLogo2 from '../../../assets/lottie/failed.json'

export default {
  name: 'EditCathlab',
  components: {
    iqCard,
    Loader,
    Multiselect,
    FlatPickr,
    Lottie
  },
  computed: {
    validationNorm () {
      return this.form.norm.length === 8
    }
  },
  mounted () {
    xray.index()
    const id = this.$route.params.id
    this.detail(id)
    this.getDokter()
    this.getTindakan()
  },
  methods: {
    async onUpdate () {
      this.isLoading = true
      const data = this.form
      if (data.norm.length === 8) {
        try {
          const res = await Lab.store(data)
          this.notif = res.data.message
          this.$refs['modal'].show()
          this.autoNorm()
          this.isLoading = false
        } catch (err) {
          this.notif = err.response.data.message
          this.$refs['modal-gagal'].show()
          this.isLoading = false
        }
      } else {
        this.notif = 'Nomor Rekam Medis Harus 8 Digit'
        this.$refs['modal-gagal'].show()
        this.isLoading = false
      }
    },
    backtojadwal () {
      this.$router.push({ name: 'laboratorium.list' })
    },
    async getDokter () {
      const data = {
        ruangan: 101170101
      }
      await Lab.showDokter(data).then(response => {
        if (response.status === 200) {
          this.combo.dokter = response.data.data
        }
      })
      // .catch(error => {
      //   console.log(error.response)
      // })
    },
    async getTindakan () {
      const data = {
        ruangan: 101170101
      }
      await Lab.showTindakan(data).then(response => {
        if (response.status === 200) {
          this.combo.tindakan = response.data.data
        }
      })
      // .catch(error => {
      //   console.log(error.response)
      // })
    },
    async detail (id) {
      const data = id
      await Lab.showById(data).then(response => {
        this.pasien = response.data.data
        this.nama = this.pasien.head[0].nama
        this.form.norm = this.pasien.head[0].norm
        this.form.nomor = this.pasien.head[0].nomor
        this.autoNorm()
        this.form.email = this.pasien.head[0].email
        this.valStatus(this.pasien.head[0].status)
        this.tglRes(this.pasien.head[0].tanggal)
        this.form.dokter = this.pasien.head[0].dokter
        this.selected.dokter = { id: this.pasien.head[0].dokter, deskripsi: this.pasien.head[0].deskripsi_dokter }
        const detail = response.data.data.detail
        if (detail !== undefined) {
          this.deleteRow(0)
          detail.map((value, index) => {
            this.form.detail.push({
              kode: value.kode
            })
            this.selected.tindakan.push({
              detail: [{ deskripsi: value.deskripsi_kode, id_referensi: value.kode }]
            })
          })
        }
      }).catch(error => {
        this.error = error.response.data.message
      })
    },
    valStatus (value) {
      this.selectedStatus = value
      this.form.status = value
    },
    tglRes (resTgl) {
      this.form.tanggal = resTgl
      var tgl = resTgl.split('-').reverse().join('-')
      this.tanggal = tgl
    },
    valTgl () {
      const tglStr = this.tanggal
      const tgl = tglStr.split('-').reverse().join('-')
      this.form.tanggal = tgl
    },
    valDokter (value) {
      this.form.dokter = value.id
    },
    valTindakan (value) {
      const index = this.index
      this.form.detail[index].kode = value.id
    },
    valIndex (index) {
      this.index = index
    },
    addRow () {
      this.form.detail.push(
        {
          kode: null
        }
      )
      this.selected.tindakan.push(
        {
          detail: []
        }
      )
    },
    deleteRow (index) {
      this.form.detail.splice(index, 1)
      this.selected.tindakan.splice(index, 1)
    },
    autoNorm () {
      const norm = this.form.norm?.toString() || ''
      this.form.norm = norm.padStart(8, '0')
    }
  },
  data () {
    return {
      isLoading: false,
      error: '',
      selectedStatus: null,
      options: [
        { text: 'Cito', value: 1 },
        { text: 'Elektif', value: 2 }
      ],
      config: {
        dateFormat: 'd-m-Y',
        disableMobile: true,
        minDate: new Date()
      },
      pasien: {},
      tanggal: '',
      selected: {
        dokter: [],
        tindakan: [{
          detail: []
        }]
      },
      combo: {
        dokter: [],
        tindakan: []
      },
      form: {
        norm: '',
        nomor: '',
        email: '',
        ruangan: 101170101,
        dokter: '',
        tanggal: '',
        status: '',
        detail: [{
          kode: null
        }]
      },
      nama: '',
      notif: '',
      defaultOptions: { animationData: animationLogo, loop: true },
      defaultOptions2: { animationData: animationLogo2, loop: true }
    }
  }
}
</script>
