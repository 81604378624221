<template>
  <div class="emr-radiologi">
    <!-- <div class="arrow bg-primary p-2">
      <span class="h6"><i class="fas fa-prescription-bottle-alt"></i> &nbsp; Form Resep</span>
    </div> -->
    <div class="overflow-emr" :style="{height: heightPercent}">
      <div>
        <b-card no-body>
          <b-tabs pills card>
            <b-tab title="ORDER" active>
              <b-row>
                <b-col cols="6" class="pr-0">
                  <div class="shadow-sm border h-100">
                    <div class="eresep-head p-2">
                      <p class="m-0 p-0 font-weight-bold text-dark">ORDER DETIL</p>
                    </div>
                    <hr class="m-0 p-0">
                    <div class="eresep-body p-2">
                      <b-table class="mb-0"
                        ref="table"
                        select-mode="single"
                        hover small show-empty selectable
                        :items="items.table">
                        <template #table-busy>
                          <div class="text-center my-2">
                            <b-spinner class="align-middle text-primary mr-2"></b-spinner>
                            <strong class="text-dark">Memuat Data...</strong>
                          </div>
                        </template>
                      </b-table>
                    </div>
                  </div>
                </b-col>
                <b-col cols="6" class="pl-0">
                  <div class="shadow-sm border h-100">
                    <b-card no-body>
                      <b-tabs pills card>
                        <b-tab title="TINDAKAN" active>
                          <div class="p-2">
                            <b-row class="align-items-center">
                              <b-col cols="6" class="pr-1">
                                <div>
                                  <p class="m-0 p-0 text-dark">DAFTAR TINDAKAN</p>
                                </div>
                              </b-col>
                              <b-col cols="6" class="pl-1">
                                <div>
                                  <multiselect :options="options.depo"
                                    label="deskripsi" placeholder="Cari Tindakan">
                                  </multiselect>
                                </div>
                              </b-col>
                            </b-row>
                          </div>
                        </b-tab>
                        <b-tab title="PAKET">
                          <div class="p-2">
                            <b-row class="align-items-center">
                              <b-col cols="6" class="pr-1">
                                <div>
                                  <p class="m-0 p-0 text-dark">DAFTAR PAKET</p>
                                </div>
                              </b-col>
                              <b-col cols="6" class="pl-1">
                                <div>
                                  <multiselect :options="options.depo"
                                    label="deskripsi" placeholder="Cari Paket">
                                  </multiselect>
                                </div>
                              </b-col>
                            </b-row>
                          </div>
                        </b-tab>
                      </b-tabs>
                    </b-card>
                  </div>
                </b-col>
              </b-row>
            </b-tab>
            <b-tab title="DAFTAR ORDER">
              <b-row>
                <b-col cols="6" class="pr-0">
                  <div class="shadow-sm border h-100">
                    <div class="eresep-head p-2">
                      <p class="m-0 p-0 font-weight-bold text-dark">ORDERS</p>
                    </div>
                    <hr class="m-0 p-0">
                    <div class="eresep-body p-2">
                      <b-table class="mb-0"
                        ref="table"
                        select-mode="single"
                        hover small show-empty selectable
                        :items="items.table">
                        <template #table-busy>
                          <div class="text-center my-2">
                            <b-spinner class="align-middle text-primary mr-2"></b-spinner>
                            <strong class="text-dark">Memuat Data...</strong>
                          </div>
                        </template>
                      </b-table>
                    </div>
                  </div>
                </b-col>
                <b-col cols="6" class="pl-0">
                  <div class="shadow-sm border h-100">
                    <div class="eresep-head p-2">
                      <p class="m-0 p-0 font-weight-bold text-dark">ORDER DETIL</p>
                    </div>
                    <hr class="m-0 p-0">
                    <div class="eresep-body p-2">
                      <b-table class="mb-0"
                        ref="table"
                        select-mode="single"
                        hover small show-empty selectable
                        :items="items.table">
                        <template #table-busy>
                          <div class="text-center my-2">
                            <b-spinner class="align-middle text-primary mr-2"></b-spinner>
                            <strong class="text-dark">Memuat Data...</strong>
                          </div>
                        </template>
                      </b-table>
                    </div>
                  </div>
                </b-col>
              </b-row>
            </b-tab>
          </b-tabs>
        </b-card>
      </div>
    </div>
    <div class="arrow bg-white border p-2 text-right">
      <b-button variant="primary" size="sm" class="px-4">FINAL</b-button>
    </div>
  </div>
</template>
<script>
import { xray } from '../../../../config/pluginInit.js'
import Services from '../../../../services/fatmahost/index.js'
import Multiselect from 'vue-multiselect'

export default {
  name: 'EResepDaftarOrder',
  components: {
    Multiselect
  },
  data () {
    return {
      windowHeight: 0,
      heightPercent: 0,
      formOrder: '6',
      formDetail: '6',
      message: '',
      selected: [],
      fields: {
        table: [
          { key: 'no_urut', label: 'No.', class: 'text-uppercase text-center' },
          { key: 'no_resep', label: 'No. Resep', class: 'text-uppercase' },
          { key: 'asal', label: 'Asal / Unit', class: 'text-uppercase' },
          { key: 'tujuan', label: 'Tujuan', class: 'text-uppercase' },
          { key: 'tanggal', label: 'Tgl. Resep', class: 'text-uppercase' },
          { key: 'dpjp', label: 'Dpjp', class: 'text-uppercase' }
        ],
        table2: [
          { key: 'no_urut', label: 'No.', class: 'text-uppercase text-center' },
          { key: 'kemasan', label: 'Kemasan', class: 'text-uppercase' },
          { key: 'nama_obat', label: 'Nama Obat', class: 'text-uppercase' },
          { key: 'jumlah', label: 'Jumlah', class: 'text-uppercase' },
          { key: 'dosisi', label: 'Dosis', class: 'text-uppercase' },
          { key: 'aturan_pakai', label: 'Aturan Pakai', class: 'text-uppercase' }
        ]
      },
      items: {
        table: [],
        table2: []
      },
      options: {
        depo: [],
        pemberi_resep: []
      }
    }
  },
  mounted () {
    xray.index()
    this.getWindowHeight()
    window.addEventListener('resize', this.getWindowHeight)
  },
  destroyed () {
    window.removeEventListener('resize', this.getWindowHeight)
  },
  methods: {
    async getTableOrder () {
      try {
        const param1 = ``
        const param2 = `{"object":"SIMRS_EMR_RORDERRESEP","xparam0":"${this.$route.params.id}","xparam1":"","xparam2":"","xparam3":"","xparam4":"","xparam5":"","xparam6":"","xparam7":"","xparam8":"","xparam9":""}`
        const response = await Services.middleware.find(param1, param2)
        return response.data.data
      } catch (error) {
        this.message = error
      }
    },
    async getDetailOrder (item, index, event) {
      try {
        const param1 = ``
        const param2 = `{"object":"SIMRS_EMR_RORDERRESEP_RINC","xparam0":"${item.id}","xparam1":"","xparam2":"","xparam3":"","xparam4":"","xparam5":"","xparam6":"","xparam7":"","xparam8":"","xparam9":""}`
        const response = await Services.middleware.find(param1, param2)
        this.items.table2 = response.data.data
      } catch (error) {
        this.message = error
      }
    },
    getWindowHeight () {
      this.windowHeight = window.innerHeight
      var calc = (50 / 100) * this.windowHeight
      this.heightPercent = calc + 'px'
    },
    closeRightForm () {
      this.formOrder = '11'
      this.formDetail = '1'
    },
    openRightForm () {
      this.formOrder = '8'
      this.formDetail = '4'
    }
  }
}
</script>

<style>

.form-control-emr {
  height: 33px!important;
  line-height: 33px!important;
  border-radius: 5px!important;
  font-size: 12px!important;
}

.emr-radiologi .overflow-emr {
  overflow-y: scroll;
  overflow-x: hidden;
}

.emr-radiologi .card-header {
    padding: 0.5rem 1rem!important;
}

.emr-radiologi .radius-custom-emr {
  border-radius: .5rem;
}

.emr-radiologi .radius-custom-emr-head {
  border-top-left-radius: .5rem;
  border-top-right-radius: .5rem;
}

.emr-radiologi .radius-custom-form-head-eresep-daftar-order {
  border-top-right-radius: .5rem;
}

.emr-radiologi .eresep-daftar-order-fc-black {
  color: black;
  font-weight: bold;
}

.emr-radiologi .eresep-daftar-order-form-input {
  font-size: 13px;
  height: 33px!important;
  border-radius: 5px;
}

.emr-radiologi .table td {
    font-size: 13px;
    padding-top: 0px;
    padding-bottom: 0px;
}
.emr-radiologi .table th {
    font-size: 13px;
    font-weight: bold;
}

.emr-radiologi .table td, .emr-radiologi .table th {
    padding: 0.20rem!important;
}

.emr-radiologi .hover {
  transition: all .3s;
  cursor: pointer;
  background-color: rgb(255, 255, 255);
  color: rgb(107, 107, 107);
}

.emr-radiologi .arrow:hover .hover {
  background-color: rgba(0, 0, 0, 0.596);
  color: rgb(255, 255, 255);
}

.emr-radiologi .multiselect__single {
  font-size: 13px !important;
}
.emr-radiologi .multiselect__placeholder {
  font-weight: normal !important;
  font-size: 13px !important;
  padding-top: 0px!important;
}
.emr-radiologi .multiselect__option {
  font-size: 13px;
}
.emr-radiologi .multiselect__tags {
  padding: 5px 40px 0 8px !important;
  height: 33px !important;
  min-height: 33px !important;
  border: 1px solid rgb(214, 214, 214);
}
.emr-radiologi .multiselect__content-wrapper {
  font-size: 12px;
  font-weight: normal !important;
}
</style>
