<template>
  <div class="emr-layanan-farmasi">
    <div class="arrow bg-primary p-2">
      <span class="h6"> &nbsp; <i class="fas fa-sitemap"></i> DETIL</span>
    </div>
    <!-- <pre>{{ fields.table }}</pre> -->
    <div class="custom-scroller" :style="{height: heightPercent}">
      <div class="table mb-0">
        <b-table class="mb-0"
        responsive
        :busy="tableBusy"
        size="sm"
        selectable
        select-mode="single"
        @row-clicked="getDetailLab"
        ref="table"
        hover
        :items="items.table"
        :fields="fields.table">
        <template #table-busy>
          <div class="text-center my-2">
            <b-spinner class="align-middle text-primary mr-2"></b-spinner>
            <strong class="text-dark">Memuat Data...</strong>
          </div>
        </template>
        </b-table>
      </div>
    </div>
    <div class="arrow bg-white border p-2 text-left d-flex flex-row justify-content-between align-items-center">
      <div>
        <b-button variant="primary" size="sm" class="mr-1">
          CETAK
        </b-button>
        <b-button variant="primary" size="sm">
          CETAK E-TIKET
        </b-button>
      </div>
      <div>
        <p class="m-0 p-0 text-dark font-weight-bold">TOTAL(Rp) : 0</p>
      </div>
    </div>
  </div>
</template>
<script>
import { xray } from '../../../../config/pluginInit'
import Services from '../../../../services/fatmahost/index.js'

export default {
  name: 'EResepDaftarOrder',
  components: {
  },
  data () {
    return {
      windowHeight: 0,
      heightPercent: 0,
      formOrder: '6',
      formDetail: '6',
      message: '',
      response: {
        resep: [
          { key: 'no_urut', label: 'No.', class: 'text-uppercase text-center' },
          { key: 'kemasan', label: 'Kemasan', class: 'text-uppercase' },
          { key: 'produk_nama', label: 'Nama Obat', class: 'text-uppercase' },
          { key: 'jumlah', label: 'Jumlah', class: 'text-uppercase' },
          { key: 'dosisi', label: 'Dosis', class: 'text-uppercase' },
          { key: 'aturan_pakai', label: 'Aturan Pakai', class: 'text-uppercase' }
        ],
        nonResep: [
          { key: 'no_urut', label: 'No.', class: 'text-uppercase text-center' },
          { key: 'produk_nama', label: 'Tindakan', class: 'text-uppercase' }
        ]
      },
      selected: [],
      tableBusy: false,
      tableBusyB: false,
      fields: {
        table: [
          { key: '1', label: 'No.', class: 'text-uppercase text-center' },
          { key: '2', label: 'Lihat Stock', class: 'text-uppercase' },
          { key: '3', label: 'Iterasi', class: 'text-uppercase' },
          { key: '4', label: 'Nama OBAT', class: 'text-uppercase' },
          { key: '5', label: 'STOK', class: 'text-uppercase' },
          { key: '7', label: 'DOSIS', class: 'text-uppercase' },
          { key: '8', label: 'jumlah', class: 'text-uppercase' },
          { key: '9', label: 'diberikan', class: 'text-uppercase' },
          { key: '10', label: 'tinggal ambil', class: 'text-uppercase' },
          { key: '11', label: 'pinjam', class: 'text-uppercase' },
          { key: '12', label: 'aturan pakai', class: 'text-uppercase' },
          { key: '13', label: 'signa 1', class: 'text-uppercase' },
          { key: '14', label: 'signa 2', class: 'text-uppercase' },
          { key: '15', label: 'hari', class: 'text-uppercase' },
          { key: '17', label: 'alasan', class: 'text-uppercase' },
          { key: '18', label: 'paket', class: 'text-uppercase' },
          { key: '19', label: 'harga', class: 'text-uppercase' },
          { key: '20', label: 'total', class: 'text-uppercase' }
        ],
        table2: []
      },
      items: {
        table: [],
        table2: []
      },
      currentPage: 1,
      totalRows: 100,
      perPage: 10
    }
  },
  mounted () {
    xray.index()
    this.getWindowHeight()
    window.addEventListener('resize', this.getWindowHeight)
  },
  destroyed () {
    window.removeEventListener('resize', this.getWindowHeight)
  },
  methods: {
    async getTableLab () {
      this.tableBusy = true
      try {
        const param1 = ``
        const param2 = `{"object":"SIMRS_EMR_RORDER","xparam0":"${this.$route.query.norm}","xparam1":"","xparam2":"","xparam3":"","xparam4":"","xparam5":"","xparam6":"","xparam7":"","xparam8":"","xparam9":""}`
        const response = await Services.middleware.find(param1, param2)
        this.tableBusy = false
        return response.data.data
      } catch (error) {
        this.tableBusy = false
        this.message = error
      }
    },
    async getDetailLab (item, index, event) {
      this.tableBusyB = true
      try {
        const param1 = ``
        const param2 = `{"object":"SIMRS_EMR_RORDER_RINC","xparam0":"${item.order_id}","xparam1":"","xparam2":"","xparam3":"","xparam4":"","xparam5":"","xparam6":"","xparam7":"","xparam8":"","xparam9":""}`
        const response = await Services.middleware.find(param1, param2)
        if (item.order_kelompoktrx === 'resep') {
          this.fields.table2 = this.response.resep
        } else {
          this.fields.table2 = this.response.nonResep
        }
        this.items.table2 = response.data.data
        this.tableBusyB = false
      } catch (error) {
        this.tableBusyB = false
        this.message = error
      }
    },
    getWindowHeight () {
      this.windowHeight = window.innerHeight
      var calc = (50 / 100) * this.windowHeight
      this.heightPercent = calc + 'px'
    },
    closeRightForm () {
      this.formOrder = '11'
      this.formDetail = '1'
    },
    openRightForm () {
      this.formOrder = '8'
      this.formDetail = '4'
    }
  }
}
</script>

<style>
.emr-layanan-farmasi .custom-scroller {
  position: relative;
  overflow-y: auto;
}

.emr-layanan-farmasi .radius-custom-emr {
  border-radius: .5rem;
}

.emr-layanan-farmasi .radius-custom-emr-head {
  border-top-left-radius: .5rem;
  border-top-right-radius: .5rem;
}

.emr-layanan-farmasi .radius-custom-form-head-eresep-daftar-order {
  border-top-right-radius: .5rem;
}

.emr-layanan-farmasi .eresep-daftar-order-fc-black {
  color: black;
  font-weight: normal;
}

.emr-layanan-farmasi .eresep-daftar-order-form-input {
  font-size: 13px;
  height: 33px!important;
  border-radius: 5px;
}

.emr-layanan-farmasi .table td {
    font-size: 13px;
    padding-top: 0px;
    padding-bottom: 0px;
}
.emr-layanan-farmasi .table th {
    font-size: 13px;
    font-weight: normal;
}

.emr-layanan-farmasi .table td, .emr-layanan-farmasi .table th {
    padding: 0.20rem!important;
}

.emr-layanan-farmasi .hover {
  transition: all .3s;
  cursor: pointer;
  background-color: rgb(255, 255, 255);
  color: rgb(107, 107, 107);
}

.emr-layanan-farmasi .arrow:hover .hover {
  background-color: rgba(0, 0, 0, 0.596);
  color: rgb(255, 255, 255);
}

.emr-layanan-farmasi .page-link {
  background: transparent !important;
  border: none !important;
  color: gray !important;
  margin-right: 15px;
}

.emr-layanan-farmasi .page-item.active .page-link {
  background-color: transparent;
  border-color: transparent;
  color: #089bab !important;
  font-weight: normal;
}
</style>
