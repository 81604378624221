<template>
  <div id="bios-akun" class="mb-4">
    <b-container fluid>
      <b-row>
        <b-col cols="6">
          <div>
            <iq-card>
              <template v-slot:body>
                <div class="filter-brand border p-2">
                  <b-row class="align-items-center">
                    <b-col>
                      <div>
                        <h5 class="card-title font-weight-bold mb-0">Master Akun Penerimaan</h5>
                      </div>
                    </b-col>
                    <b-col>
                      <div class="text-right">
                        <b-button class="custom-button-radius" size="sm" variant="primary" id="show-btn" @click="showModal('Penerimaan')"><i
                            class="fas fa-plus"></i>Update</b-button>
                      </div>
                    </b-col>
                  </b-row>
                </div>
                <div class="table mb-0">
                  <b-table
                    class="mb-0 pointer-table"
                    ref="table"
                    :sort-by.sync="table.penerimaan.sortBy"
                    :filter="table.penerimaan.filter"
                    @filtered="onFiltered"
                    @row-clicked="openDetail"
                    :sort-desc.sync="table.penerimaan.sortDesc"
                    :busy="table.penerimaan.isBusy"
                    :current-page="table.penerimaan.currentPage"
                    :per-page="table.penerimaan.perPage"
                    stacked="md" bordered hover show-empty striped
                    :items="table.penerimaan.items"
                    :fields="table.penerimaan.fields">
                    <template #cell(index)="data">
                      <div class="py-2">
                        {{ (table.penerimaan.currentPage*table.penerimaan.perPage)-table.penerimaan.perPage + data.index + 1 }}
                      </div>
                    </template>
                    <template #cell(status)="data">
                      <span class="text-success" v-if="data.item.status === 1 || data.item.status === '1'">Aktif</span>
                      <span class="text-danger" v-else >Tidak Aktif </span>
                    </template>
                  </b-table>
                </div>
                <!-- <div class="pagination-inventory border shadow-sm p-2 mb-2">
                  <b-row class="align-items-center">
                    <b-col cols="4" class="d-flex flex-row align-items-center">
                      <div class="w-100">
                        <b-pagination v-model="table.currentPage" :total-rows="table.totalRows" :per-page="table.perPage"
                          size="md" class="my-0"></b-pagination>
                      </div>
                      <div class="w-100">
                        <div>
                          <span class="border-left border-dark mr-3 py-1"></span>
                          <span @click="tableRefresh" class="katalog-cursor">
                            <i class="fas fa-sync text-secondary"></i>
                          </span>
                          <span class="border-right border-dark ml-3 py-1"></span>
                        </div>
                      </div>
                    </b-col>
                    <b-col cols="5">
                      <div class="d-flex flex-row justify-content-end align-items-center">
                        <div class="mr-1 w-75">
                          <form class="searchbox mb-0">
                            <input v-model="table.filter" type="search" class="form-control cstm-srch-brand" id="filter-input"
                              placeholder="[Cari]">
                          </form>
                        </div>
                        <div>
                          <b-form-select class="cstm-slct-brand" v-model="selected.filterType" :options="optionsFilter"
                            @change="filterchange"></b-form-select>
                        </div>
                      </div>
                    </b-col>
                    <b-col cols="3">
                      <div class="d-flex flex-row align-items-right justify-content-end">
                        <div>
                          <p class="text-dark mb-0">Menampilkan {{ table.perPage }} dari {{ table.totalRows }} baris</p>
                        </div>
                      </div>
                    </b-col>
                  </b-row>
                </div> -->
              </template>
            </iq-card>
          </div>
        </b-col>
        <b-col cols="6">
          <div>
            <iq-card>
              <template v-slot:body>
                <div class="filter-brand border p-2">
                  <b-row class="align-items-center">
                    <b-col>
                      <div>
                        <h5 class="card-title font-weight-bold mb-0">Master Akun Pengeluaran</h5>
                      </div>
                    </b-col>
                    <b-col>
                      <div class="text-right">
                        <b-button class="custom-button-radius" size="sm" variant="primary" id="show-btn" @click="showModal('Pengeluaran')"><i
                            class="fas fa-plus"></i>Update</b-button>
                      </div>
                    </b-col>
                  </b-row>
                </div>
                <div class="table mb-0">
                  <b-table
                    class="mb-0 pointer-table"
                    ref="table"
                    :sort-by.sync="table.sortBy"
                    :filter="table.filter"
                    @filtered="onFiltered"
                    @row-clicked="openDetail"
                    :sort-desc.sync="table.sortDesc"
                    :busy="table.isBusy"
                    :current-page="table.currentPage"
                    :per-page="table.perPage"
                    stacked="md" bordered hover show-empty striped
                    :items="table.pengeluaran.items"
                    :fields="table.pengeluaran.fields">
                    <template #cell(index)="data">
                      <div class="py-2">
                        {{ (table.pengeluaran.currentPage*table.pengeluaran.perPage)-table.pengeluaran.perPage + data.index + 1 }}
                      </div>
                    </template>
                    <template #cell(status)="data">
                      <span class="text-success" v-if="data.item.status === 1 || data.item.status === '1'">Aktif</span>
                      <span class="text-danger" v-else >Tidak Aktif </span>
                    </template>
                  </b-table>
                </div>
                <!-- <div class="pagination-inventory border shadow-sm p-2 mb-2">
                  <b-row class="align-items-center">
                    <b-col cols="4" class="d-flex flex-row align-items-center">
                      <div class="w-100">
                        <b-pagination v-model="table.currentPage" :total-rows="table.totalRows" :per-page="table.perPage"
                          size="md" class="my-0"></b-pagination>
                      </div>
                      <div class="w-100">
                        <div>
                          <span class="border-left border-dark mr-3 py-1"></span>
                          <span @click="tableRefresh" class="katalog-cursor">
                            <i class="fas fa-sync text-secondary"></i>
                          </span>
                          <span class="border-right border-dark ml-3 py-1"></span>
                        </div>
                      </div>
                    </b-col>
                    <b-col cols="5">
                      <div class="d-flex flex-row justify-content-end align-items-center">
                        <div class="mr-1 w-75">
                          <form class="searchbox mb-0">
                            <input v-model="table.filter" type="search" class="form-control cstm-srch-brand" id="filter-input"
                              placeholder="[Cari]">
                          </form>
                        </div>
                        <div>
                          <b-form-select class="cstm-slct-brand" v-model="selected.filterType" :options="optionsFilter"
                            @change="filterchange"></b-form-select>
                        </div>
                      </div>
                    </b-col>
                    <b-col cols="3">
                      <div class="d-flex flex-row align-items-right justify-content-end">
                        <div>
                          <p class="text-dark mb-0">Menampilkan {{ table.perPage }} dari {{ table.totalRows }} baris</p>
                        </div>
                      </div>
                    </b-col>
                  </b-row>
                </div> -->
              </template>
            </iq-card>
          </div>
        </b-col>
      </b-row>
    </b-container>
    <b-modal id="modal-tambah-akun-bios" ref="modal-tambah-akun-bios" size="xl" centered :no-close-on-backdrop="true" :static="true"
      title="Update Data" ok-title="Simpan Data" @close="clearData" @cancel="clearData" scrollable
      cancel-title="Tutup" @ok="tambahSave">
      <hr class="m-0">
      <div>
        <b-row>
          <b-col cols="12">
            <div class="table-responsive-xl">
              <table class="table bg-white table-bordered table-sm m-0 p-0">
                <thead>
                  <tr>
                    <th scope="col" style="vertical-align: center;" class="text-center">
                      <p class="mb-0 font-weight-normal text-center">Nomor</p>
                    </th>
                    <th scope="col" style="vertical-align: center;" class="text-center">
                      <p class="mb-0 font-weight-normal text-center">Uraian</p>
                    </th>
                    <th scope="col" style="vertical-align: center;" class="text-center">
                      <p class="mb-0 font-weight-normal text-center">Status</p>
                    </th>
                    <th scope="col" style="vertical-align: center;" class="text-center">
                      <b-button variant="primary" @click="addRow">
                        <p class="h5 mb-0">+</p>
                      </b-button>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(k, index) in tambahTransaksi.detail" v-bind:key="k.index">
                    <td class="py-3">
                      <div>
                        <p class="text-center font-weight-bold mb-0">{{ index + 1 }}</p>
                      </div>
                    </td>
                    <td class="col-8">
                      <div>
                        <div class="w-100">
                          <multiselect :show-labels="false" :custom-label="selectLabelAkun" placeholder="[ nama akun ]"
                            :options="options.akun" v-model="selected.akun[index]" @input="tabakun($event, index)"
                            :searchable="true" :allow-empty="false">
                          </multiselect>
                        </div>
                      </div>
                    </td>
                    <td class="col-3">
                      <div>
                        <div class="w-100">
                          <multiselect :show-labels="false" :custom-label="selectLabelStatus" placeholder="[ status ]"
                            :options="options.status" v-model="selected.status[index]" @input="tabstatus($event, index)"
                            :searchable="true" :allow-empty="false">
                          </multiselect>
                        </div>
                      </div>
                    </td>
                    <td class="text-center">
                      <b-button variant="white" @click="deleteRow(index)" v-if="index !== 0">
                        <p class="mb-0"><i class="fas fa-trash-alt text-danger"></i></p>
                      </b-button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </b-col>
        </b-row>
      </div>
    </b-modal>
    <ModalMsgbox ref="MsgBox" />
  </div>
</template>
<script>
import { xray } from '../../../../config/pluginInit'
import Multiselect from 'vue-multiselect'
import ModalMsgbox from '../../../../components/modal/ModalMsgBox'
import BIOS from '../../../../services/fatmahost/bios'

export default {
  components: {
    Multiselect,
    ModalMsgbox
  },
  mounted () {
    xray.index()
    this.getAkunAll()
  },
  data () {
    return {
      hidden: false,
      config: {
        dateFormat: 'd-m-Y',
        disableMobile: true
      },
      // new saldo bios rekening operasional
      tanggal: '',
      currentDate: '',
      table: {
        penerimaan: {
          filter: '',
          fields: [
            { label: 'No', key: 'index', class: 'text-center' },
            { label: 'Kode', key: 'kd_akun', class: 'text-center' },
            { label: 'Uraian', key: 'uraian' },
            // { label: 'Nama Status', key: 'nama_status' }
            { label: 'Status', key: 'status', class: 'text-center' }
          ],
          items: [],
          currentPage: null,
          perPage: null,
          isBusy: false,
          totalRows: null
        },
        pengeluaran: {
          filter: '',
          fields: [
            { label: 'No', key: 'index', class: 'text-center' },
            { label: 'Kode', key: 'kd_akun', class: 'text-center' },
            { label: 'Uraian', key: 'uraian' },
            // { label: 'Nama Status', key: 'nama_status' }
            { label: 'Status', key: 'status', class: 'text-center' }
          ],
          items: [],
          currentPage: null,
          perPage: null,
          isBusy: false,
          totalRows: null
        }
      },
      options: {
        akun: [],
        status: [
          { text: 'Aktif', value: '1' },
          { text: 'Tidak Aktif', value: '0' }
        ]
      },
      selected: {
        akun: [],
        status: []
      },
      tambahTransaksi: {
        object: 'akun',
        detail: []
      }
    }
  },
  methods: {
    // eslint-disable-next-line camelcase
    selectLabelAkun ({ kd_akun, uraian }) {
      // eslint-disable-next-line camelcase
      return `${kd_akun} - ${uraian}`
    },
    // eslint-disable-next-line camelcase
    selectLabelStatus ({ text }) {
      // eslint-disable-next-line camelcase
      return `${text}`
    },
    async getAkunAll (ctx) {
      const response = await BIOS.akunFindAll(0, 1000, '{"xparam0":"","xparam1":"","xparam2":"","xparam3":"","xparam4":""}', '{"xparam0":"","xparam1":""}')
      const data = response.data.data
      const penerimaan = data.filter(item => {
        return item.nama_status === 'Penerimaan' && item.status === 1
      })
      this.table.penerimaan.items = penerimaan

      const pengeluaran = data.filter(item => {
        return item.nama_status === 'Pengeluaran' && item.status === 1
      })
      this.table.pengeluaran.items = pengeluaran
    },
    tabakun ($event, index) {
      this.tambahTransaksi.detail[index].id = $event.id
    },
    tabstatus ($event, index) {
      this.tambahTransaksi.detail[index].status = $event.value
    },
    clearData () {
      this.tambahTransaksi.detail = []
      this.selected.akun = []
      this.selected.status = []
    },
    // trash
    onFiltered (filteredItems) {
      this.table.totalRows = filteredItems.length
    },
    filterchange () {
      this.table.filter = ''
    },
    tableRefresh () {
      this.$refs.table.refresh()
    },
    openDetail (items) {
      this.showModal()
    },
    tglEvent ($event, param) {
      const date = new Date($event)
      let day = date.getDate()
      let month = (date.getMonth() + 1).toString().padStart(2, 0)
      let year = date.getFullYear()
      if (day < 10) {
        day = '0' + day
      }
      let currentDate = year + '-' + month + '-' + day
      this.currentDate = currentDate
    },
    showModal (param) {
      this.clearData()
      this.showDataActive(param)
      this.$refs['modal-tambah-akun-bios'].show()
    },
    async showDataActive (param) {
      const response = await BIOS.akunFindAll(0, 1000, '{"xparam0":"","xparam1":"","xparam2":"","xparam3":"","xparam4":""}', '{"xparam0":"","xparam1":""}')
      const data = response.data.data
      if (param === 'Penerimaan') {
        const penerimaan = data.filter(item => {
          return item.nama_status === 'Penerimaan' && item.status === 1
        })
        const selectizepenerimaan = data.filter(item => {
          return item.nama_status === 'Penerimaan'
        })
        this.options.akun = selectizepenerimaan
        penerimaan.map(el => {
          this.tambahTransaksi.detail.push({
            id: el.id,
            status: el.status,
            updated_in: xray.currentUser().id
          })
          this.selected.akun.push({
            kd_akun: el.kd_akun, uraian: el.uraian
          })
          this.selected.status.push({
            text: (el.status === '1' || el.status === 1 ? 'Aktif' : 'Tidak Aktif'), value: el.status
          })
        })
      } else {
        const pengeluaran = data.filter(item => {
          return item.nama_status === 'Pengeluaran' && item.status === 1
        })
        const selectizepenerimaan = data.filter(item => {
          return item.nama_status === 'Pengeluaran'
        })
        this.options.akun = selectizepenerimaan
        pengeluaran.map(el => {
          this.tambahTransaksi.detail.push({
            id: el.id,
            status: el.status,
            updated_in: xray.currentUser().id
          })
          this.selected.akun.push({
            kd_akun: el.kd_akun, uraian: el.uraian
          })
          this.selected.status.push({
            text: (el.status === '1' || el.status === 1 ? 'Aktif' : 'Tidak Aktif'), value: el.status
          })
        })
      }
    },
    async tambahSave (bvModalEvent) {
      bvModalEvent.preventDefault()
      try {
        const response = await BIOS.akunUpdate(this.tambahTransaksi)
        if (response.data.statcode === 0) {
          this.$refs['modal-tambah-akun-bios'].hide()
          this.$refs.MsgBox.show({
            type: 'success',
            msg: response.data.message
          })
        } else {
          this.$refs['modal-tambah-akun-bios'].hide()
          this.$refs.MsgBox.show({
            type: 'error',
            msg: response.data.message
          })
        }
      } catch (error) {
        this.$refs['modal-tambah-akun-bios'].hide()
        this.$refs.MsgBox.show({
          type: 'error',
          msg: 'Data gagal diubah.'
        })
      }
      this.$refs.table.refresh()
    },
    deleteModal (id) {
      this.$refs['modal-konfirm-delete'].show()
    },
    addRow () {
      this.tambahTransaksi.detail.push(
        {
          id: '',
          status: '',
          updated_in: xray.currentUser().id
        }
      )
    },
    deleteRow (index) {
      this.tambahTransaksi.detail.splice(index, 1)
    }
  }
}
</script>
<style>
#modal-tambah-akun-bios .height-static-pabrik {
  /* max-height: 12em;
 overflow-x: hidden; */
  overflow: auto;
}

#modal-tambah-akun-bios .multiselect, #modal-edit-akun-bios .multiselect {
  min-height: 33px;
}

#modal-tambah-akun-bios .multiselect__single, #modal-edit-akun-bios .multiselect__single {
  font-size: 0.9em!important;
  font-weight: normal!important;
  line-height: 26px;
}
#modal-tambah-akun-bios .multiselect__placeholder, #modal-edit-akun-bios .multiselect__placeholder {
  font-size: 0.9em!important;
  font-weight: normal;
}
#modal-tambah-akun-bios .multiselect__option, #modal-edit-akun-bios .multiselect__option {
  font-size: 0.9em;
}
#modal-tambah-akun-bios .multiselect__tags, #modal-edit-akun-bios .multiselect__tags {
  overflow: hidden;
  padding: 1px 40px 0 8px!important;
  height: 33px!important;
  min-height: 33px!important;
}

#modal-edit-akun-bios .multiselect--disabled .multiselect__tags {
  overflow: hidden;
  background: #E9ECEF;
}

#modal-edit-akun-bios .multiselect--disabled {
  opacity: 1;
}

#modal-edit-akun-bios .multiselect--disabled .multiselect__tags .multiselect__single {
  overflow: hidden;
  background: transparent;
}

#modal-edit-akun-bios .multiselect__select {
    height: 30px;
}

.new-cstm-cntrl-inventori {
  background: white!important;
  color: black!important;
  border-radius: 5px!important;
  font-size: 12px!important;
  height: 33px!important;
}

#modal-tambah-akun-bios .border-merah {
  border: 2px solid red;
}

#modal-tambah-akun-bios .border-hijau {
  border: 2px solid green;
}

#modal-tambah-akun-bios .modal-body {
  background: #3399331b;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

#modal-tambah-akun-bios .modal-header {
  background: #3399333a;
}

#modal-tambah-akun-bios .modal-footer {
  background: #3399333a;
}

#modal-edit-akun-bios .border-merah {
  border: 2px solid red;
}

#modal-edit-akun-bios.border-hijau {
  border: 2px solid green;
}

#modal-edit-akun-bios .modal-body {
  background: #3399331b;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

#modal-edit-akun-bios .modal-header {
  background: #3399333a;
}

#modal-edit-akun-bios .modal-footer {
  background: #3399333a;
}

#bios-akun .pagination-inventory .page-link {
  border: none;
  color: gray !important;
  margin-right: 15px;
}

#bios-akun .custom-button-radius {
  border: 1px solid rgba(0, 0, 0, 0.112);
  border-radius: 5px !important;
}

#bios-akun .table td {
    font-size: 12px;
    font-weight: 500;
    padding-top: 0px;
    padding-bottom: 0px;
}
#bios-akun .table th {
    font-size: 13px;
    font-weight: 400;
}

#bios-akun .table {
  text-align: left;
}

#bios-akun .cstm-select-Inventory {
  font-size: 13px;
  height: 38px !important;
  border-radius: 5px;
}

#bios-akun .cstm-srch-brand {
  font-size: 13px;
  height: 38px !important;
  border-radius: 5px;
}

#bios-akun .cstm-slct-brand {
  font-size: 13px;
  height: 38px !important;
  border-radius: 5px;
}

#bios-akun .pagination-Inventory .page-link {
  border: none;
  color: gray !important;
  margin-right: 15px;
}

#bios-akun .page-item.active .page-link {
    background-color: transparent;
    border-color: transparent;
}

#bios-akun .pagination-Inventory .page-item.active .page-link {
  background-color: transparent;
  border-color: transparent;
  color: #089bab !important;
  font-weight: bold;
}

#modal-tambah-akun-bios .custom-flatpicker, #modal-edit-akun-bios .custom-flatpicker {
    border: 1px solid rgb(233, 233, 233);
    height: 33px;
    padding-left: 10px;
    padding-top: 5px;
    padding-bottom: 5px;
    border: 1px solid rgb(209, 208, 208)!important;
    border-radius: 5px;
    border: 1px solid rgb(228, 228, 228);
    font-size: 12px;
    color: black;
}

#Inventory-Pinjaman-Barang .page-link {
    border: none;
    color: gray!important;
    margin-right: 15px;
}
#Inventory-Pinjaman-Barang .page-item.active .page-link {
    background-color: transparent;
    border-color: transparent;
    color: #089bab!important;
    font-weight: bold;
}

.pointer-table tbody tr {
  cursor: pointer;
}
</style>
