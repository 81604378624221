import Api from '../axiosInstance'
const version = '/v1'
export default {
  findAll (currentPage, perPage, filters) {
    return Api.users.get(`${version}/practitioner/menuSubModule?param0=menu-sub-module&param1=${currentPage}&param2=${perPage}&param3=${filters}`)
  },
  selectStrctureMenu () {
    return Api.users.get(`${version}/practitioner/selectize?param0=menu-sub-module`)
  },
  selectSubmodul () {
    return Api.users.get(`${version}/practitioner/selectize?param0=module`)
  },
  selectgroup () {
    return Api.users.get(`${version}/practitioner/selectize?param0=user-group`)
  },
  findId (id) {
    return Api.users.get(`${version}/practitioner/menuSubModule/${id}?param0=menu-sub-module`)
  },
  create (data) {
    return Api.users.post(`${version}/practitioner/menuSubModule`, data)
  },
  update (id, data) {
    return Api.users.put(`${version}/practitioner/menuSubModule/${id}`, data)
  },
  delete (id) {
    return Api.users.delete(`${version}/practitioner/menuSubModule/${id}?param0=menu-sub-module`)
  }
}
