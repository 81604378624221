<template>
  <div id="katalog-farmasi" class="mb-4">
    <div v-show="preview.isLoading">
      <div id="show-overlay"></div>
      <Loader :style="{ opacity: .5 }" />
    </div>
    <b-container fluid>
      <div>
        <iq-card>
          <template v-slot:body>
            <div class="filter-farmasi border p-2">
              <b-row class="align-items-center">
                <b-col cols="12" lg="3" class="d-flex flex-row w-100 mr-auto">
                  <div>
                    <h5 class="card-title font-weight-bold mb-0">Katalog Farmasi</h5>
                  </div>
                </b-col>
                <b-col cols="12" lg="6" class="text-right">
                  <div v-if="dummy.aksesTambah === true">
                    <!-- {{ 'test' + bitControl.bitcontrol1 }}
                    {{ 'test' + bitControl.bitcontrol2 }} -->
                    <b-button class="custom-button-radius" size="sm" variant="primary" @click="tambahModal"><i class="fas fa-plus"></i> Tambah</b-button>
                  </div>
                </b-col>
              </b-row>
            </div>
            <div class="table mb-0">
              <b-table class="mb-0" ref="table" :sort-by.sync="table.sortBy" :sort-desc.sync="table.sortDesc" :busy="table.isBusy" :items="table.items" :fields="table.fields"
                :current-page="table.currentPage" :per-page="table.perPage" :filter="table.filter" @filtered="onFiltered" stacked="md"
                bordered hover show-empty small striped>
                <template #table-busy>
                  <div class="text-center my-2">
                    <b-spinner class="align-middle text-primary mr-2"></b-spinner>
                    <strong class="text-dark">Memuat Data...</strong>
                  </div>
                </template>
                <template #cell(index)="data">
                  {{ (table.currentPage*table.perPage)-table.perPage + data.index + 1 }}
                </template>
                <template #cell(action)="data">
                  <b-button
                    @click="viewModal(data.item.id)"
                    variant="white" size="lg" v-if="dummy.aksesShow === true">
                    <i class="fas fa-eye text-warning"></i>
                  </b-button>
                  <b-button
                    @click="editModal(data.item.id)"
                    variant="white" size="lg" v-if="dummy.aksesEdit === true">
                    <i class="fas fa-edit text-primary"></i>
                  </b-button>
                  <b-button
                    @click="deleteModal(data.item.id)"
                    variant="white" size="lg" v-if="dummy.aksesDelete === true">
                    <i class="fas fa-trash-alt text-danger"></i>
                  </b-button>
                </template>
                <template #cell(sysdate_updt)="data">
                  <span>{{ new Date(data.item.sysdate_updt) | formatDate }}</span>
                </template>
              </b-table>
            </div>
            <div class="pagination-farmasi border shadow-sm p-2 mb-2">
              <b-row class="align-items-center">
                <b-col cols="4" class="d-flex flex-row align-items-center">
                  <div class="w-100">
                    <b-pagination
                      v-model="table.currentPage"
                      :total-rows="table.totalRows"
                      :per-page="table.perPage"
                      size="md"
                      class="my-0"
                    ></b-pagination>
                  </div>
                  <div class="w-100">
                    <div>
                      <span class="border-left border-dark mr-3 py-1"></span>
                      <span @click="tableRefresh" class="katalog-cursor">
                        <i class="fas fa-sync text-secondary"></i>
                      </span>
                      <span class="border-right border-dark ml-3 py-1"></span>
                    </div>
                  </div>
                </b-col>
                <b-col cols="5">
                  <div class="d-flex flex-row justify-content-end align-items-center">
                    <div class="mr-1 w-75">
                      <form class="searchbox mb-0">
                        <input v-model="table.filter" type="search" class="form-control cstm-srch-farmasi" id="filter-input"
                          placeholder="[Cari]">
                      </form>
                    </div>
                    <div>
                      <b-form-select class="cstm-slct-farmasi" v-model="selected.filter" :options="options.optionsFilter" @change="filterchange"></b-form-select>
                    </div>
                  </div>
                </b-col>
                <b-col cols="3">
                  <div class="d-flex flex-row align-items-center justify-content-end">
                    <div class="mr-3">
                      <b-form-select
                        class="cstm-select-farmasi"
                        id="per-page-select"
                        v-model="table.perPage"
                        :options="table.pageOptions"
                      ></b-form-select>
                    </div>
                    <div>
                      <p class="text-dark mb-0">Menampilkan {{ table.perPage }} dari {{ table.totalRows }} baris</p>
                    </div>
                  </div>
                </b-col>
              </b-row>
            </div>
          </template>
        </iq-card>
      </div>
    </b-container>
    <div class="modal-component-farmasi">
      <b-modal id="modal-view-katalog" ref="modal-view-farmasi" size="xl" centered title="Informasi" ok-title="Tutup" :ok-only="true">
        <div class="pb-0 px-2">
          <b-row>
            <b-col cols="6">
              <b-form-group label-for="edit-nama-barang" class="mb-2">
                <div class="d-flex flex-row">
                  <div class="katalog-select-label-2">
                    <p class="custom-label-katalog text-dark mb-0">Nama Barang</p>
                  </div>
                  <div class="katalog-select-flex-left-2">
                    <b-form-input type="text" v-model="preview.viewData.nama_barang" class="cstm-cntrl-katalog-10" placeholder="[ - ]" readonly></b-form-input>
                  </div>
                </div>
              </b-form-group>
              <b-form-group label-for="edit-nama-barang" class="mb-2">
                <div class="d-flex flex-row">
                  <div class="katalog-select-label-2">
                    <p class="custom-label-katalog text-dark mb-0">Jenis Barang</p>
                  </div>
                  <div class="katalog-select-flex-left-2">
                    <b-form-input type="text" v-model="preview.viewData.nm_subjenis_anggaran" class="cstm-cntrl-katalog-10" placeholder="[ - ]" readonly></b-form-input>
                  </div>
                </div>
              </b-form-group>
              <b-form-group label-for="edit-nama-barang" class="mb-2">
                <div class="d-flex flex-row">
                  <div class="katalog-select-label-2">
                    <p class="custom-label-katalog text-dark mb-0">Kelompok Barang</p>
                  </div>
                  <div class="katalog-select-flex-left-2">
                    <b-form-input type="text" v-model="preview.viewData.kelompok_barang" class="cstm-cntrl-katalog-10" placeholder="[ - ]" readonly></b-form-input>
                  </div>
                </div>
              </b-form-group>
              <b-form-group label-for="edit-nama-barang" class="mb-2">
                <div class="d-flex flex-row">
                  <div class="katalog-select-label-2">
                    <p class="custom-label-katalog text-dark mb-0">Nama Dagang</p>
                  </div>
                  <div class="katalog-select-flex-left-2">
                    <b-form-input type="text" v-model="preview.viewData.nama_brand" class="cstm-cntrl-katalog-10" placeholder="[ - ]" readonly></b-form-input>
                  </div>
                </div>
              </b-form-group>
              <!-- <b-form-group label-for="edit-nama-barang" class="mb-2">
                <div class="d-flex flex-row">
                  <div class="katalog-select-label-2">
                    <p class="custom-label-katalog text-dark mb-0">Nama Generik</p>
                  </div>
                  <div class="katalog-select-flex-left-2">
                    <b-form-input type="text" class="cstm-cntrl-katalog-10" placeholder="[ - ]" readonly></b-form-input>
                  </div>
                </div>
              </b-form-group> -->
              <b-form-group label-for="edit-nama-barang" class="mb-2">
                <div class="d-flex flex-row">
                  <div class="katalog-select-label-2">
                    <p class="custom-label-katalog text-dark mb-0">Pbf</p>
                  </div>
                  <div class="katalog-select-flex-left-2">
                    <b-form-input type="text" v-model="preview.viewData.nama_pbf" class="cstm-cntrl-katalog-10" placeholder="[ - ]" readonly></b-form-input>
                  </div>
                </div>
              </b-form-group>
              <b-form-group label-for="edit-nama-barang" class="mb-2">
                <div class="d-flex flex-row">
                  <div class="katalog-select-label-2">
                    <p class="custom-label-katalog text-dark mb-0">Zat Aktif</p>
                  </div>
                  <div class="katalog-select-flex-left-2">
                    <b-form-textarea type="text" v-model="preview.viewData.zat_aktif" row="3" class="katalog-form-area-2" placeholder="[ - ]" readonly></b-form-textarea>
                  </div>
                </div>
              </b-form-group>
              <b-form-group label-for="edit-nama-barang" class="mb-2">
                <div class="d-flex flex-row">
                  <div class="katalog-select-label-2">
                    <p class="custom-label-katalog text-dark mb-0">Retriksi</p>
                  </div>
                  <div class="katalog-select-flex-left-2">
                    <b-form-textarea type="text" v-model="preview.viewData.retriksi" row="3" class="katalog-form-area-2" placeholder="[ - ]" readonly></b-form-textarea>
                  </div>
                </div>
              </b-form-group>
              <b-form-group label-for="edit-nama-barang" class="mb-2">
                <div class="d-flex flex-row">
                  <div class="katalog-select-label-2">
                    <p class="custom-label-katalog text-dark mb-0">Keterangan</p>
                  </div>
                  <div class="katalog-select-flex-left-2">
                    <b-form-textarea type="text" v-model="preview.viewData.keterangan" row="3" class="katalog-form-area-2" placeholder="[ - ]" readonly></b-form-textarea>
                  </div>
                </div>
              </b-form-group>
              <b-form-group label-for="keterangan" class="mb-1">
                <div class="d-flex flex-row">
                  <div class="katalog-select-label-2">
                    <p class="custom-label-katalog text-dark mb-0">Kelas Terapi</p>
                  </div>
                  <div class="katalog-select-flex-left-2">
                    <b-form-textarea size="sm" class="katalog-form-area-2" v-model="preview.viewData.kelas_terapi" rows="3" placeholder="[ Kelas Terapi ]" readonly></b-form-textarea>
                  </div>
                </div>
              </b-form-group>
              <b-form-group label-for="keterangan" class="mb-0">
                <div class="d-flex flex-row">
                  <div class="katalog-select-label-2">
                    <p class="custom-label-katalog text-dark mb-0">Peresepan Maksimal</p>
                  </div>
                  <div class="katalog-select-flex-left-2-peresepan-maksimal mr-auto">
                    <b-form-textarea size="sm" class="katalog-form-area-2" v-model="preview.viewData.resep_max_kasus" rows="3" placeholder="[ Per Kasus ]" readonly></b-form-textarea>
                  </div>
                  <div class="katalog-select-flex-left-2-peresepan-maksimal">
                    <b-form-textarea size="sm" class="katalog-form-area-2" v-model="preview.viewData.resep_max_bulan" rows="3" placeholder="[ Per Bulan ]" readonly></b-form-textarea>
                  </div>
                </div>
              </b-form-group>
            </b-col>
            <b-col cols="6">
              <b-form-group label-for="edit-nama-barang" class="mb-2">
                <div class="d-flex flex-row">
                  <div class="katalog-select-label-2">
                    <p class="custom-label-katalog text-dark mb-0">Kode Barang</p>
                  </div>
                  <div class="katalog-select-flex-left-2">
                    <b-form-input type="text" v-model="preview.viewData.kode" class="cstm-cntrl-katalog-kode" placeholder="[ - ]" readonly></b-form-input>
                  </div>
                </div>
              </b-form-group>
              <b-form-group label-for="edit-nama-barang" class="mb-2">
                <div class="d-flex flex-row">
                  <div class="katalog-select-label-2">
                    <p class="custom-label-katalog text-dark mb-0">Satuan Kemasan</p>
                  </div>
                  <div class="katalog-select-flex-left-2">
                    <b-row>
                      <b-col cols="4" class="pr-1">
                        <b-form-input type="text" v-model="preview.viewData.kemasan_besar" class="cstm-cntrl-katalog-10" placeholder="[ - ]" readonly></b-form-input>
                      </b-col>
                      <b-col cols="4" class="pr-1 pl-1">
                        <b-form-input type="text" v-model="preview.viewData.isi_kemasan" class="cstm-cntrl-katalog-10" placeholder="[ - ]" readonly></b-form-input>
                      </b-col>
                      <b-col cols="4" class="pl-1">
                        <b-form-input type="text" v-model="preview.viewData.kemasan_kecil" class="cstm-cntrl-katalog-10" placeholder="[ - ]" readonly></b-form-input>
                      </b-col>
                    </b-row>
                  </div>
                </div>
              </b-form-group>
              <b-form-group label-for="edit-nama-barang" class="mb-2">
                <div class="d-flex flex-row">
                  <div class="katalog-select-label-2">
                    <p class="custom-label-katalog text-dark mb-0">Dosis Kfa</p>
                  </div>
                  <div class="katalog-select-flex-left-2">
                    <b-row>
                      <b-col class="pr-1">
                        <b-form-input type="text" v-model="preview.viewData.isi_dosis" class="cstm-cntrl-katalog-10" placeholder="[ - ]" readonly></b-form-input>
                      </b-col>
                      <b-col cols="8" class="pl-1">
                        <b-form-input type="text" v-model="preview.viewData.nama_dosis" class="cstm-cntrl-katalog-10" placeholder="[ - ]" readonly></b-form-input>
                      </b-col>
                    </b-row>
                  </div>
                </div>
              </b-form-group>
              <b-form-group label-for="edit-nama-barang" class="mb-2">
                <div class="d-flex flex-row">
                  <div class="katalog-select-label-2">
                    <p class="custom-label-katalog text-dark mb-0">Kemasan Barang</p>
                  </div>
                  <div class="katalog-select-flex-left-2">
                    <b-form-input type="text" v-model="preview.viewData.kemasan" class="cstm-cntrl-katalog-10" placeholder="[ - ]" readonly></b-form-input>
                  </div>
                </div>
              </b-form-group>
              <b-form-group label-for="edit-nama-barang" class="mb-2">
                <div class="d-flex flex-row">
                  <div class="katalog-select-label-2">
                    <p class="custom-label-katalog text-dark mb-0">Kfa 91 <span class="text-primary pl-2"><a :href="preview.viewData.url_kfa91" onclick="window.open(this.href, 'new', 'popup'); return false;">URL</a></span></p>
                  </div>
                  <div class="katalog-select-flex-left-2">
                    <b-form-input type="text" v-model="preview.viewData.uraian_kfa91" class="cstm-cntrl-katalog-10" placeholder="[ - ]" readonly></b-form-input>
                  </div>
                </div>
              </b-form-group>
              <b-form-group label-for="edit-nama-barang" class="mb-2">
                <div class="d-flex flex-row">
                  <div class="katalog-select-label-2">
                    <p class="custom-label-katalog text-dark mb-0">Kfa 92 <span class="text-primary pl-2"><a :href="preview.viewData.url_kfa92" onclick="window.open(this.href, 'new', 'popup'); return false;">URL</a></span></p>
                  </div>
                  <div class="katalog-select-flex-left-2">
                    <b-form-input type="text" v-model="preview.viewData.uraian_kfa92" class="cstm-cntrl-katalog-10" placeholder="[ - ]" readonly></b-form-input>
                  </div>
                </div>
              </b-form-group>
              <b-form-group label-for="edit-nama-barang" class="mb-2">
                <div class="d-flex flex-row">
                  <div class="katalog-select-label-2">
                    <p class="custom-label-katalog text-dark mb-0">Sakti</p>
                  </div>
                  <div class="katalog-select-flex-left-2">
                    <b-form-input type="text" v-model="preview.viewData.uraian" class="cstm-cntrl-katalog-10" placeholder="[ - ]" readonly></b-form-input>
                  </div>
                </div>
              </b-form-group>
              <b-form-group label-for="edit-nama-barang" class="mb-2">
                <div class="d-flex flex-row">
                  <div class="katalog-select-label-2">
                    <p class="custom-label-katalog text-dark mb-0">Aktifasi</p>
                  </div>
                  <div class="katalog-select-flex-left-2">
                    <b-form-radio-group
                      v-model="preview.selected.sts_aktif"
                      :options="preview.options.sts_aktif"
                      disabled
                    ></b-form-radio-group>
                  </div>
                </div>
              </b-form-group>
              <b-form-group label-for="edit-nama-barang" class="mb-2">
                <div class="d-flex flex-row">
                  <div class="katalog-select-label-2">
                    <p class="custom-label-katalog text-dark mb-0">Set Harga</p>
                  </div>
                  <div class="katalog-select-flex-left-2">
                    <b-row>
                      <b-col class="pr-1">
                        <b-form-input type="text" v-model="preview.viewData.harga_beli" class="cstm-cntrl-katalog-10" placeholder="[ - ]" readonly></b-form-input>
                      </b-col>
                      <b-col cols="8" class="pl-1">
                        <b-form-input type="text" v-model="preview.viewData.diskon_beli" class="cstm-cntrl-katalog-10" placeholder="[ - ]" readonly></b-form-input>
                      </b-col>
                    </b-row>
                  </div>
                </div>
              </b-form-group>
              <b-form-group label-for="edit-nama-barang" class="mb-2">
                <div class="d-flex flex-row">
                  <div class="katalog-select-label-2">
                    <p class="custom-label-katalog text-dark mb-0">Keterangan Barang</p>
                  </div>
                  <div class="katalog-select-flex-left-2">
                    <b-row>
                      <b-col cols="4" class="pr-1">
                        <b-form-input type="text" v-model="preview.viewData.leadtime" class="cstm-cntrl-katalog-10" placeholder="[ - ]" readonly></b-form-input>
                      </b-col>
                      <b-col cols="4" class="pr-1 pl-1">
                        <b-form-input type="text" v-model="preview.viewData.buffer" class="cstm-cntrl-katalog-10" placeholder="[ - ]" readonly></b-form-input>
                      </b-col>
                      <b-col cols="4" class="pl-1">
                        <b-form-input type="text" v-model="preview.viewData.moving" class="cstm-cntrl-katalog-10" placeholder="[ - ]" readonly></b-form-input>
                      </b-col>
                    </b-row>
                  </div>
                </div>
              </b-form-group>
              <b-form-group label-for="edit-nama-barang" class="mb-2">
                <div class="d-flex flex-row">
                  <div class="katalog-select-label-2">
                    <p class="custom-label-katalog text-dark mb-0">Formularium</p>
                  </div>
                  <div class="katalog-select-flex-left-2">
                    <b-row>
                      <b-col cols="4" class="pr-1">
                        <b-form-checkbox
                          id="checkbox-100"
                          v-model="preview.selected.formularium_nas"
                          name="checkbox-1"
                          value="1"
                          unchecked-value="0"
                          disabled
                        >
                          Formularium Nasional
                        </b-form-checkbox>
                      </b-col>
                      <b-col cols="4" class="pl-1">
                        <b-form-checkbox
                          id="checkbox-200"
                          v-model="preview.selected.formularium_rs"
                          name="checkbox-1"
                          value="1"
                          unchecked-value="0"
                          disabled
                        >
                        Formularium RSUPF
                        </b-form-checkbox>
                      </b-col>
                    </b-row>
                  </div>
                </div>
              </b-form-group>
              <b-form-group label-for="edit-nama-barang" class="mb-0">
                <div class="d-flex flex-row mb-1">
                  <div class="katalog-select-label-2">
                    <p class="custom-label-katalog text-dark mb-0">Kategori Obat</p>
                  </div>
                  <div class="katalog-select-flex-left-2">
                    <b-row class="align-items-center">
                      <b-col cols="8" class="pr-1">
                        <div class="d-flex flex-row">
                          <div class="mr-3">
                            <b-form-checkbox
                              id="checkbox-100"
                              v-model="preview.selected.livesaving"
                              name="checkbox-1"
                              value="1"
                              unchecked-value="0"
                              disabled
                            >
                              Live Saving
                            </b-form-checkbox>
                          </div>
                          <div class="mr-3">
                            <b-form-checkbox
                              id="checkbox-100"
                              v-model="preview.selected.generik"
                              name="checkbox-1"
                              value="1"
                              unchecked-value="0"
                              disabled
                            >
                              Obat Generik
                            </b-form-checkbox>
                          </div>
                          <div>
                            <b-form-checkbox
                              id="checkbox-200"
                              v-model="preview.selected.kronis"
                              name="checkbox-1"
                              value="1"
                              unchecked-value="0"
                              disabled
                            >
                            Obat Kronis
                            </b-form-checkbox>
                          </div>
                        </div>
                      </b-col>
                      <b-col class="pr-1 pl-1">
                        <div class="custom-div text-dark px-2 py-1 border" v-if="preview.viewData.kategori_warna === 1">Access (hijau)</div>
                        <div class="custom-div text-dark px-2 py-1 border" v-else-if="preview.viewData.kategori_warna === 2">Watch (kuning)</div>
                        <div class="custom-div text-dark px-2 py-1 border" v-else-if="preview.viewData.kategori_warna === 3">Reserve (merah)</div>
                      </b-col>
                    </b-row>
                  </div>
                </div>
                <div class="d-flex flex-row mb-1">
                  <div class="katalog-select-label-2">
                    <p class="custom-label-katalog text-dark mb-0"></p>
                  </div>
                  <div class="katalog-select-flex-left-2">
                    <b-row class="align-items-center">
                      <b-col cols="12" class="pr-1">
                        <div class="d-flex flex-row">
                          <div class="mr-3">
                            <b-form-checkbox
                              id="checkbox-100"
                              v-model="preview.selected.sts_narkotika"
                              name="checkbox-1"
                              value="1"
                              unchecked-value="0"
                              disabled
                            >
                              Narkotika
                            </b-form-checkbox>
                          </div>
                          <div class="mr-3">
                            <b-form-checkbox
                              id="checkbox-100"
                              v-model="preview.selected.sts_psikotropika"
                              name="checkbox-1"
                              value="1"
                              unchecked-value="0"
                              disabled
                            >
                              Psikotropika
                            </b-form-checkbox>
                          </div>
                          <div class="mr-3">
                            <b-form-checkbox
                              id="checkbox-200"
                              v-model="preview.selected.sts_oot"
                              name="checkbox-1"
                              value="1"
                              unchecked-value="0"
                              disabled
                            >
                              OOT
                            </b-form-checkbox>
                          </div>
                          <div>
                            <b-form-checkbox
                              id="checkbox-200"
                              v-model="preview.selected.sts_prekursor"
                              name="checkbox-1"
                              value="1"
                              unchecked-value="0"
                              disabled
                            >
                              Prekursor
                            </b-form-checkbox>
                          </div>
                          <div>
                            <b-form-checkbox
                              id="checkbox-200"
                              v-model="preview.selected.sts_bpjs"
                              name="checkbox-1"
                              value="1"
                              unchecked-value="0"
                              disabled
                            >
                              BPJS
                            </b-form-checkbox>
                          </div>
                        </div>
                      </b-col>
                    </b-row>
                  </div>
                </div>
              </b-form-group>
            </b-col>
          </b-row>
          <div class="mt-3">
            <b-form-group>
              <table class="custom-div table table-bordered table-sm mb-0 pb-0">
                <thead>
                  <tr>
                    <th class="text-center font-weight-normal" scope="col">Nomor</th>
                    <th class="text-center font-weight-normal" scope="col">Pabrik</th>
                    <th class="text-center font-weight-normal" scope="col">KFA 93</th>
                    <th class="text-center font-weight-normal" scope="col">ID Transmedik</th>
                    <th class="text-center font-weight-normal" scope="col">Status Opname</th>
                    <th class="text-center font-weight-normal" scope="col">Status Aktif</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="p in preview.viewData.pabrik" :key="p.codex">
                    <td class="text-center"><span class="custom-label-katalog">{{ p.no_urut }}</span></td>
                    <td><span class="custom-label-katalog">{{ p.nm_pabrik }}</span></td>
                    <td><span class="custom-label-katalog">{{ p.uraian_kfa93 }} <a :href="p.url_kfa93" onclick="window.open(this.href, 'new', 'popup'); return false;">URL</a></span></td>
                    <td class="text-center"><span class="custom-label-katalog">{{ p.id_transmedik }}</span></td>
                    <td class="text-center"><span class="custom-label-katalog">{{ p.sts_opname === 1 ? 'Aktif' : 'Tidak Aktif' }}</span></td>
                    <td class="text-center"><span class="custom-label-katalog">{{ p.sts_aktif === 1 ? 'Aktif' : 'Tidak Aktif' }}</span></td>
                  </tr>
                </tbody>
              </table>
            </b-form-group>
          </div>
        </div>
        <!-- <pre>{{ preview.viewData }}</pre> -->
      </b-modal>
      <b-modal id="modal-tambah-edit-katalog" ref="modal-tambah-farmasi" size="xl" centered title="Tambah Katalog" ok-title="Simpan Data" cancel-title="Tutup" @ok="tambahSave" :ok-disabled="dummy.simpankatalog" :no-close-on-backdrop="true" :static="true" :no-close-on-esc="true" @close="clearForm" @cancel="clearForm">
        <b-form class="py-3">
          <b-row class="mb-3">
            <b-col>
              <b-form-group label-for="nama-barang" class="mb-1">
                <div class="d-flex flex-row">
                  <div class="katalog-select-label-2">
                    <p class="custom-label-katalog text-dark mb-0">Nama Barang</p>
                  </div>
                  <div class="katalog-select-flex-left-2">
                    <b-form-input id="nama-barang" type="text" v-model="tambahFarmasi.farmasi.nama_barang" @blur="suggsetNmBrg()" class="cstm-cntrl-katalog-10" placeholder="[ Nama Barang ]" :disabled="dummy.disabledField"></b-form-input>
                  </div>
                </div>
              </b-form-group>
              <b-form-group label-for="jenis-barang" class="mb-1">
                <div class="d-flex flex-row">
                  <div class="katalog-select-label">
                    <p class="custom-label-katalog text-dark mb-0">Jenis Barang</p>
                  </div>
                  <div class="katalog-select-flex-left">
                    <multiselect id="jenis-barang" :show-labels="false" label="subjenis_anggaran" track-by="id" placeholder="[ Jenis Barang ]" :searchable="true" :allow-empty="false"
                    v-model="selected.namaJenisBarang" :options="options.namaJenisBarang" @input="valueJenisBarang" :disabled="dummy.disabledField">
                    </multiselect>
                  </div>
                  <div class="katalog-select-flex-right pl-2" v-if="dummy.aksesTambah === true">
                    <div @click="tambahExtendForm('1')" class="bg-primary w-100 h-100 shadow-sm text-center d-flex align-items-center justify-content-center custom-radius-add-katalog">
                      <span class="h5 mb-0">+</span>
                    </div>
                  </div>
                  <div class="katalog-select-flex-right pl-2" v-else>
                    <div class="bg-secondary w-100 h-100 shadow-sm text-center d-flex align-items-center justify-content-center custom-radius-add-katalog-disable">
                      <span class="h5 mb-0">+</span>
                    </div>
                  </div>
                </div>
              </b-form-group>
              <b-form-group label-for="kelompok-barang" class="mb-1">
                <div class="d-flex flex-row">
                  <div class="katalog-select-label">
                    <p class="custom-label-katalog text-dark mb-0">Kelompok Barang</p>
                  </div>
                  <div class="katalog-select-flex-left">
                    <multiselect id="kelompok-barang" :show-labels="false" label="kelompok_barang" track-by="id" placeholder="[ Kelompok Barang ]" :searchable="true" :allow-empty="false"
                    v-model="selected.kelompokBarang" :options="options.kelompokBarang" @input="valueKelompokBarang" :disabled="dummy.disabledField">
                    </multiselect>
                  </div>
                  <div class="katalog-select-flex-right pl-2" v-if="dummy.aksesTambah === true">
                    <div @click="tambahExtendForm('2')" class="bg-primary w-100 h-100 shadow-sm text-center d-flex align-items-center justify-content-center custom-radius-add-katalog">
                      <span class="h5 mb-0">+</span>
                    </div>
                  </div>
                  <div class="katalog-select-flex-right pl-2" v-else>
                    <div class="bg-secondary w-100 h-100 shadow-sm text-center d-flex align-items-center justify-content-center custom-radius-add-katalog-disable">
                      <span class="h5 mb-0">+</span>
                    </div>
                  </div>
                </div>
              </b-form-group>
              <b-form-group class="mb-1">
                <div class="d-flex flex-row">
                  <div class="katalog-select-label ">
                    <p class="custom-label-katalog text-dark mb-0">Nama Dagang</p>
                  </div>
                  <div class="katalog-select-flex-left">
                    <multiselect id="nama-dagang" :show-labels="false" label="nama_dagang" track-by="id" placeholder="[ Nama Dagang ]" :searchable="true" :allow-empty="false"
                    v-model="selected.namaDagang" :options="options.namaDagang" @input="valueNamaDagang" :disabled="dummy.disabledField">
                    </multiselect>
                  </div>
                  <div class="katalog-select-flex-right pl-2" v-if="dummy.aksesTambah === true">
                    <div @click="tambahExtendForm('3')" class="bg-primary w-100 h-100 shadow-sm text-center d-flex align-items-center justify-content-center custom-radius-add-katalog">
                      <span class="h5 mb-0">+</span>
                    </div>
                  </div>
                  <div class="katalog-select-flex-right pl-2" v-else>
                    <div class="bg-secondary w-100 h-100 shadow-sm text-center d-flex align-items-center justify-content-center custom-radius-add-katalog-disable">
                      <span class="h5 mb-0">+</span>
                    </div>
                  </div>
                </div>
              </b-form-group>
              <!-- <b-form-group class="mb-1">
                <div class="d-flex flex-row">
                  <div class="katalog-select-label">
                    <p class="custom-label-katalog text-dark mb-0">Nama Generik</p>
                  </div>
                  <div class="katalog-select-flex-left">
                    <multiselect id="nama-generik" :show-labels="false" label="nama_generik" track-by="id" placeholder="[ Nama Generik ]" :searchable="true" :allow-empty="false"
                    v-model="selected.namaGenerik" :options="options.namaGenerik" :disabled="dummy.disabledField">
                    </multiselect>
                  </div>
                  <div class="katalog-select-flex-right pl-2" v-if="dummy.aksesTambah === true">
                    <div @click="tambahExtendForm('4')" class="bg-primary w-100 h-100 shadow-sm text-center d-flex align-items-center justify-content-center custom-radius-add-katalog">
                      <span class="h5 mb-0">+</span>
                    </div>
                  </div>
                  <div class="katalog-select-flex-right pl-2" v-else>
                    <div class="bg-secondary w-100 h-100 shadow-sm text-center d-flex align-items-center justify-content-center custom-radius-add-katalog-disable">
                      <span class="h5 mb-0">+</span>
                    </div>
                  </div>
                </div>
              </b-form-group> -->
              <!-- <b-form-group label-for="pabrik" class="mb-1">
                <div class="d-flex flex-row">
                  <div class="katalog-select-label">
                    <p class="custom-label-katalog text-dark mb-0">Pabrik</p>
                  </div>
                  <div class="katalog-select-flex-left">
                    <multiselect id="pabrik" :show-labels="false" label="nama_pabrik" placeholder="[ Pabrik ]" :searchable="true"
                    v-model="selected.pabrik" :options="options.Pabrik" @input="valuePabrik" :disabled="dummy.disabledField">
                    </multiselect>
                  </div>
                  <div class="katalog-select-flex-right pl-2" v-if="dummy.aksesTambah === true">
                    <div @click="tambahExtendForm('5')" class="bg-primary w-100 h-100 shadow-sm text-center d-flex align-items-center justify-content-center custom-radius-add-katalog">
                      <span class="h5 mb-0">+</span>
                    </div>
                  </div>
                  <div class="katalog-select-flex-right pl-2" v-else>
                    <div class="bg-secondary w-100 h-100 shadow-sm text-center d-flex align-items-center justify-content-center custom-radius-add-katalog-disable">
                      <span class="h5 mb-0">+</span>
                    </div>
                  </div>
                </div>
              </b-form-group> -->
              <b-form-group label-for="nama-pbf" class="mb-1">
                <div class="d-flex flex-row">
                  <div class="katalog-select-label">
                    <p class="custom-label-katalog text-dark mb-0">Pbf</p>
                  </div>
                  <div class="katalog-select-flex-left">
                    <multiselect id="nama-pbf" :show-labels="false" label="nama_pbf" track-by="id" placeholder="[ Nama Pbf ]" :searchable="true" :allow-empty="false"
                    v-model="selected.pbf" :options="options.Pbf" @input="valuePbf" :disabled="dummy.disabledField">
                    </multiselect>
                  </div>
                  <div class="katalog-select-flex-right pl-2" v-if="dummy.aksesTambah === true">
                    <div @click="tambahExtendForm('6')" class="bg-primary w-100 h-100 shadow-sm text-center d-flex align-items-center justify-content-center custom-radius-add-katalog">
                      <span class="h5 mb-0">+</span>
                    </div>
                  </div>
                  <div class="katalog-select-flex-right pl-2" v-else>
                    <div class="bg-secondary w-100 h-100 shadow-sm text-center d-flex align-items-center justify-content-center custom-radius-add-katalog-disable">
                      <span class="h5 mb-0">+</span>
                    </div>
                  </div>
                </div>
              </b-form-group>
              <b-form-group label-for="zat-aktif" class="mb-1">
                <div class="d-flex flex-row">
                  <div class="katalog-select-label-2">
                    <p class="custom-label-katalog text-dark mb-0">Zat Aktif</p>
                  </div>
                  <div class="katalog-select-flex-left-2">
                    <b-form-textarea size="sm" class="katalog-form-area-2" v-model="tambahFarmasi.farmasi.zat_aktif" rows="3" id="zat-aktif" placeholder="[ Zat Aktif ]" :disabled="dummy.disabledField"></b-form-textarea>
                  </div>
                </div>
              </b-form-group>
              <b-form-group label-for="retriksi" class="mb-1">
                <div class="d-flex flex-row">
                  <div class="katalog-select-label-2">
                    <p class="custom-label-katalog text-dark mb-0">Retriksi</p>
                  </div>
                  <div class="katalog-select-flex-left-2">
                    <b-form-textarea size="sm" class="katalog-form-area-2" v-model="tambahFarmasi.farmasi.retriksi" rows="3" id="retriksi" placeholder="[ Retriksi ]" :disabled="dummy.disabledField"></b-form-textarea>
                  </div>
                </div>
              </b-form-group>
              <b-form-group label-for="keterangan" class="mb-1">
                <div class="d-flex flex-row">
                  <div class="katalog-select-label-2">
                    <p class="custom-label-katalog text-dark mb-0">Keterangan</p>
                  </div>
                  <div class="katalog-select-flex-left-2">
                    <b-form-textarea size="sm" class="katalog-form-area-2" v-model="tambahFarmasi.farmasi.keterangan" rows="3" id="keterangan" placeholder="[ Keterangan ]" :disabled="dummy.disabledField"></b-form-textarea>
                  </div>
                </div>
              </b-form-group>
              <b-form-group label-for="keterangan" class="mb-1">
                <div class="d-flex flex-row">
                  <div class="katalog-select-label-2">
                    <p class="custom-label-katalog text-dark mb-0">Kelas Terapi</p>
                  </div>
                  <div class="katalog-select-flex-left-2">
                    <b-form-textarea size="sm" class="katalog-form-area-2" v-model="tambahFarmasi.farmasi.kelas_terapi" rows="3" id="kelas_terapi" placeholder="[ Kelas Terapi ]" :disabled="dummy.disabledField"></b-form-textarea>
                  </div>
                </div>
              </b-form-group>
              <b-form-group label-for="keterangan" class="mb-0">
                <div class="d-flex flex-row">
                  <div class="katalog-select-label-2">
                    <p class="custom-label-katalog text-dark mb-0">Peresepan Maksimal</p>
                  </div>
                  <div class="katalog-select-flex-left-2-peresepan-maksimal mr-auto">
                    <b-form-textarea size="sm" class="katalog-form-area-2" v-model="tambahFarmasi.farmasi.resep_max_kasus" rows="3" id="resep_max_kasus" placeholder="[ Per Kasus ]" :disabled="dummy.disabledField"></b-form-textarea>
                  </div>
                  <div class="katalog-select-flex-left-2-peresepan-maksimal">
                    <b-form-textarea size="sm" class="katalog-form-area-2" v-model="tambahFarmasi.farmasi.resep_max_bulan" rows="3" id="resep_max_bulan" placeholder="[ Per Bulan ]" :disabled="dummy.disabledField"></b-form-textarea>
                  </div>
                </div>
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group label-for="kode-barang" class="mb-1">
                <div class="d-flex flex-row">
                  <div class="katalog-select-label-2">
                    <p class="custom-label-katalog text-dark mb-0">Kode Barang</p>
                  </div>
                  <div class="katalog-select-flex-left-2">
                    <b-row>
                      <b-col cols="5" class="pr-1">
                        <b-form-input id="kode-barang" type="text" v-model="tambahFarmasi.farmasi.kode" @blur="validasiKdBrg" class="cstm-cntrl-katalog-kode" placeholder="" :disabled="dummy.disabledField"></b-form-input>
                      </b-col>
                      <b-col class="pl-1">
                        <p class="custom-font-katalog text-danger mb-0 mt-2" v-if="dummy.msgValidasiKd.statcode === 0">
                          <i class="fas fa-times-circle fa-2x"></i>
                        </p>
                        <p class="custom-font-katalog text-success mb-0 mt-2" v-else-if="dummy.msgValidasiKd.statcode === 20001">
                          <i class="fas fa-check-circle fa-2x"></i>
                        </p>
                      </b-col>
                    </b-row>
                  </div>
                </div>
              </b-form-group>
              <b-form-group label-for="nama-sediaan" class="mb-1">
                <div class="d-flex flex-row">
                  <div class="katalog-select-label-2">
                    <p class="custom-label-katalog text-dark mb-0">Satuan Kemasan</p>
                  </div>
                  <div class="katalog-select-flex-left-2">
                    <b-row class="mb-1">
                      <b-col cols="4" class="pr-1">
                        <multiselect id="kemasan-besar" :show-labels="false" label="nama_kemasan" track-by="id" placeholder="[ Kemasan Besar ]" @input="valueKemasanBesar" :searchable="true" :allow-empty="false"
                        v-model="selected.satuanKemasanBesar" :options="options.satuanKemasanBesar" :disabled="dummy.disabledField">
                        </multiselect>
                      </b-col>
                      <b-col cols="4" class="pr-1 pl-1">
                        <b-form-input v-model="preview.isi_kemasan" @input="valueIsiKemasan" @keypress="isNumberKey" id="isi-kemasan" class="cstm-cntrl-katalog-10" maxlength="9" placeholder="[ Isi Kemasan ]" :disabled="dummy.disabledField"></b-form-input>
                      </b-col>
                      <b-col cols="4" class="pl-1">
                        <multiselect id="kemasan-kecil" :show-labels="false" label="nama_kemasan" track-by="id" placeholder="[ Kemasan Kecil ]" @input="valueKemasanKecil" :searchable="true" :allow-empty="false"
                        v-model="selected.satuanKemasanKecil" :options="options.satuanKemasanKecil" :disabled="dummy.disabledField">
                        </multiselect>
                      </b-col>
                    </b-row>
                  </div>
                </div>
                <div class="d-flex flex-row">
                  <div class="katalog-select-label-2">
                    <p class="custom-label-katalog text-dark mb-0">Dosis Kfa</p>
                  </div>
                  <div class="katalog-select-flex-left-2">
                    <b-row class="mb-1">
                      <b-col class="pr-1">
                        <b-form-input id="isi-dosis" v-model="preview.isi_dosis" @input="valueIsiDosis" @keypress="isNumberKey" class="cstm-cntrl-katalog-10" placeholder="[ Isi Dosis ]" :disabled="dummy.disabledField"></b-form-input>
                      </b-col>
                      <b-col cols="8" class="pl-1 d-flex flex-row">
                        <div class="dosis-left-width mr-1">
                          <multiselect id="tamba-dosis" :show-labels="false" label="nama" @input="valueDosis" track-by="id" placeholder="[ Dosis ]" :searchable="true" :allow-empty="false"
                          v-model="selected.dosis" :options="options.dosis" :disabled="dummy.disabledField">
                          </multiselect>
                        </div>
                        <div class="dosis-right-width pl-2" v-if="dummy.aksesTambah === true">
                          <div @click="tambahExtendForm('7')" class="bg-primary w-100 h-100 shadow-sm text-center d-flex align-items-center justify-content-center custom-radius-add-katalog">
                            <span class="h5 mb-0">+</span>
                          </div>
                        </div>
                        <div class="dosis-right-width pl-2" v-else>
                          <div class="bg-secondary w-100 h-100 shadow-sm text-center d-flex align-items-center justify-content-center custom-radius-add-katalog-disable">
                            <span class="h5 mb-0">+</span>
                          </div>
                        </div>
                      </b-col>
                    </b-row>
                  </div>
                </div>
                <div>
                  <div class="d-flex flex-row">
                    <div class="katalog-select-label-2">
                      <div>
                        <p class="custom-label-katalog text-dark mb-0">Kemasan Barang</p>
                      </div>
                    </div>
                    <div class="katalog-select-flex-left-2">
                      <b-row>
                        <b-col lg="12">
                          <b-form-input v-model="tambahFarmasi.farmasi.kemasan" id="kemasan-barang" class="cstm-cntrl-katalog-10" placeholder="[ Kemasan Barang ]" readonly></b-form-input>
                        </b-col>
                      </b-row>
                    </div>
                  </div>
                </div>
              </b-form-group>
              <b-form-group label-for="kategori-obat" class="mb-1">
                <div>
                  <div class="satusehat-selectize">
                    <b-row>
                      <b-col cols="12" class="mb-1">
                        <div class="d-flex flex-row">
                          <div class="katalog-select-label-2">
                            <div>
                              <p class="custom-label-katalog text-dark mb-0">Kfa 91 <span class="text-primary pl-2" v-if="link.kfa91"><a :href="link.kfa91" onclick="window.open(this.href, 'new', 'popup'); return false;">URL</a></span></p>
                            </div>
                          </div>
                          <div class="katalog-select-flex-left-2">
                            <b-row>
                              <b-col>
                                <multiselect v-model="selected.kfa91" :options="options.kfa91" @input="valueKfa1" label="uraian" track-by="id" :searchable="true" :show-labels="false" :allow-empty="false" placeholder="[ KFA 91 ]" :disabled="dummy.disabledField"></multiselect>
                              </b-col>
                            </b-row>
                          </div>
                        </div>
                      </b-col>
                      <b-col cols="12" class="mb-0">
                        <div class="d-flex flex-row">
                          <div class="katalog-select-label-2">
                            <div>
                              <p class="custom-label-katalog text-dark mb-0">Kfa 92 <span class="text-primary pl-2" v-if="link.kfa92"><a :href="link.kfa92" onclick="window.open(this.href, 'new', 'popup'); return false;">URL</a></span></p>
                            </div>
                          </div>
                          <div class="katalog-select-flex-left-2">
                            <b-row>
                              <b-col>
                                <multiselect v-model="selected.kfa92" :options="options.kfa92" @input="valueKfa2" label="uraian" track-by="id" :searchable="true" :show-labels="false" :allow-empty="false" placeholder="[ KFA 92 ]" :disabled="dummy.disabledField"></multiselect>
                              </b-col>
                            </b-row>
                          </div>
                        </div>
                      </b-col>
                      <!-- <b-col cols="12">
                        <div class="d-flex flex-row">
                          <div class="katalog-select-label-2">
                            <div>
                              <p class="custom-label-katalog text-dark mb-0">Kfa 93</p>
                            </div>
                          </div>
                          <div class="katalog-select-flex-left-2">
                            <b-row>
                              <b-col>
                                <multiselect v-model="selected.kfa93" :options="options.kfa93" @input="valueKfa3" label="uraian" track-by="id" :searchable="true" :show-labels="false" placeholder="[ KFA 93 ]" :disabled="dummy.disabledField"></multiselect>
                              </b-col>
                            </b-row>
                          </div>
                        </div>
                      </b-col> -->
                    </b-row>
                  </div>
                </div>
              </b-form-group>
              <b-form-group label-for="kategori-obat" class="mb-1">
                <div class="d-flex flex-row">
                  <div class="katalog-select-label-2">
                    <div>
                      <p class="custom-label-katalog text-dark mb-0">Sakti</p>
                    </div>
                  </div>
                  <div class="katalog-select-flex-left-2">
                    <div class="d-flex flex-row">
                      <div class="sakti-left-width mr-1">
                        <multiselect v-model="selected.sakti" :options="options.sakti" @input="valueSakti" label="uraian" track-by="id" :searchable="true" :show-labels="false" :allow-empty="false" placeholder="[ Sakti ]" :disabled="dummy.disableSakti"></multiselect>
                      </div>
                      <div class="sakti-right-width" v-if="dummy.aksesTambah === true && dummy.aksesSakti === true && dummy.disableSakti === false">
                        <div @click="tambahExtendForm('8')" class="bg-primary w-100 h-100 shadow-sm text-center d-flex align-items-center justify-content-center custom-radius-add-katalog">
                          <span class="h5 mb-0">+</span>
                        </div>
                      </div>
                      <div class="sakti-right-width" v-else>
                        <div class="bg-secondary w-100 h-100 shadow-sm text-center d-flex align-items-center justify-content-center custom-radius-add-katalog-disable">
                          <span class="h5 mb-0">+</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </b-form-group>
              <!-- <b-form-group label-for="kategori-obat" class="mb-1">
                <div class="d-flex flex-row">
                  <div class="katalog-select-label-2">
                    <div>
                      <p class="custom-label-katalog text-dark mb-0">Aktifasi Barang</p>
                    </div>
                  </div>
                  <div class="katalog-select-flex-left-2">
                    <b-row>
                      <b-col>
                        <b-form-radio-group
                          v-model="tambahFarmasi.farmasi.aktifasi"
                          :options="options.aktivasiBarang"
                          name="radio-options"
                          :disabled="dummy.disableStsAktif"
                        ></b-form-radio-group>
                      </b-col>
                    </b-row>
                  </div>
                </div>
              </b-form-group> -->
              <b-form-group label-for="harga-diskon" class="mb-1">
                <div class="d-flex flex-row">
                  <div class="katalog-select-label-2">
                    <div>
                      <p class="custom-label-katalog text-dark mb-0">Set Harga</p>
                    </div>
                  </div>
                  <div class="katalog-select-flex-left-2">
                    <b-row>
                      <b-col class="pr-1">
                        <b-form-input id="harga" v-model="preview.rupiah" @input="formatRupiah" @keypress="isNumberKey" class="cstm-cntrl-katalog-10"  placeholder="[ Harga (0) ]" :disabled="dummy.disabledField"></b-form-input>
                      </b-col>
                      <b-col class="pl-1">
                        <b-form-input id="diskon" v-model="preview.diskon" @input="formatDiskon" @keypress="isNumberKey" class="cstm-cntrl-katalog-10" maxlength="5" placeholder="[ Diskon % (00.00) ]" :disabled="dummy.disabledField"></b-form-input>
                      </b-col>
                    </b-row>
                  </div>
                </div>
              </b-form-group>
              <b-form-group label-for="keterangan-barang" class="mb-1">
                <div class="d-flex flex-row">
                  <div class="katalog-select-label-2">
                    <div>
                      <p class="custom-label-katalog text-dark mb-0">Keterangan Barang</p>
                    </div>
                  </div>
                  <div class="katalog-select-flex-left-2">
                    <b-row>
                      <b-col class="pr-1">
                        <b-form-input id="leadtime" type="text" v-model="tambahFarmasi.farmasi.leadtime" @keypress="isNumberKey" class="cstm-cntrl-katalog-10" maxlength="2" placeholder="[ Lead Time ]" :disabled="dummy.disabledField"></b-form-input>
                      </b-col>
                      <b-col class="pr-1 pl-1">
                        <b-form-input id="buffer" type="text" v-model="preview.buffer" @input="formatBuffer" @keypress="isNumberKey" class="cstm-cntrl-katalog-10" maxlength="2" placeholder="[ Buffer % ]" :disabled="dummy.disabledField"></b-form-input>
                      </b-col>
                      <b-col class="pl-1">
                        <multiselect id="death-moving" :show-labels="false" label="name" track-by="id" placeholder="[ Moving ]" :searchable="true" :allow-empty="false"
                        v-model="selected.deathMoving" :options="options.deathMoving" @input="valuemoving" :disabled="dummy.disabledField">
                        </multiselect>
                      </b-col>
                    </b-row>
                  </div>
                </div>
              </b-form-group>
              <b-form-group label-for="formularium" class="mb-1" v-if="dummy.conditionKatObt === true">
                <div class="d-flex flex-row">
                  <div class="katalog-select-label-2">
                    <div>
                      <p class="custom-label-katalog text-dark mb-0">Formularium</p>
                    </div>
                  </div>
                  <div class="katalog-select-flex-left-2">
                    <b-row>
                      <b-col>
                        <div class="d-flex flex-row align-items-center">
                          <div class="mr-3">
                            <b-form-checkbox
                              id="checkbox-formularium-nasional"
                              v-model="tambahFarmasi.farmasi.formularium_nas"
                              name="checkbox-formularium-nasional"
                              value="1"
                              unchecked-value="0"
                              :disabled="dummy.disabledField"
                            >
                              Formularium Nasional
                            </b-form-checkbox>
                          </div>
                          <div>
                            <b-form-checkbox
                              id="checkbox-formularium-rsupf"
                              v-model="tambahFarmasi.farmasi.formularium_rs"
                              name="checkbox-formularium-rsupf"
                              value="1"
                              unchecked-value="0"
                              :disabled="dummy.disabledField"
                            >
                              Formularium RSUPF
                            </b-form-checkbox>
                          </div>
                        </div>
                      </b-col>
                    </b-row>
                  </div>
                </div>
              </b-form-group>
              <b-form-group label-for="kategori-obat" class="mb-1" v-if="dummy.conditionKatObt === true">
                <div class="d-flex flex-row mb-1">
                  <div class="katalog-select-label-2">
                    <div>
                      <p class="custom-label-katalog text-dark mb-0">Kategori Obat</p>
                    </div>
                  </div>
                  <div class="katalog-select-flex-left-2">
                    <b-row class="align-items-center">
                      <b-col>
                        <div class="d-flex flex-row align-items-center">
                          <div class="mr-3">
                            <b-form-checkbox
                              id="checkbox-livesaving"
                              v-model="tambahFarmasi.farmasi.live_saving"
                              name="checkbox-formularium-nasional"
                              value="1"
                              unchecked-value="0"
                              :disabled="dummy.disabledField"
                            >
                              Live Saving
                            </b-form-checkbox>
                          </div>
                          <div class="mr-3">
                            <b-form-checkbox
                              id="checkbox-generik"
                              name="checkbox-geenrik"
                              v-model="tambahFarmasi.farmasi.generik"
                              value="1"
                              unchecked-value="0"
                              :disabled="dummy.disabledField"
                              @input="valueGenerik"
                            >
                              Obat Generik
                            </b-form-checkbox>
                          </div>
                          <div>
                            <b-form-checkbox
                              id="checkbox-kronis"
                              v-model="tambahFarmasi.farmasi.sts_kronis"
                              name="checkbox-kronis"
                              value="1"
                              unchecked-value="0"
                              :disabled="dummy.disabledField"
                            >
                              Obat Kronis
                            </b-form-checkbox>
                          </div>
                        </div>
                      </b-col>
                      <b-col cols="5">
                        <multiselect id="kategori-warna" :show-labels="false" label="name" track-by="id" placeholder="[ Kategori Warna ]" @input="valueKategoriWarna" :searchable="true"
                        :allow-empty="false" v-model="selected.kategoriWarna" :options="options.kategoriWarna" :disabled="dummy.disabledField">
                        </multiselect>
                      </b-col>
                    </b-row>
                  </div>
                </div>
                <div class="d-flex flex-row">
                  <div class="katalog-select-label-2">
                    <div>
                      <p class="custom-label-katalog text-dark mb-0"></p>
                    </div>
                  </div>
                  <div class="katalog-select-flex-left-2">
                    <b-row class="align-items-center">
                      <b-col>
                        <div class="d-flex flex-row align-items-center">
                          <div class="mr-3">
                            <b-form-checkbox
                              id="checkbox-narkotika"
                              v-model="tambahFarmasi.farmasi.sts_narkotika"
                              name="checkbox-narkotik"
                              value="1"
                              unchecked-value="0"
                              :disabled="dummy.disabledField"
                            >
                              Narkotika
                            </b-form-checkbox>
                          </div>
                          <div class="mr-3">
                            <b-form-checkbox
                              id="checkbox-psikotropika"
                              name="checkbox-psikotropika"
                              v-model="tambahFarmasi.farmasi.sts_psikotropika"
                              value="1"
                              unchecked-value="0"
                              :disabled="dummy.disabledField"
                            >
                              Psikotropika
                            </b-form-checkbox>
                          </div>
                          <div class="mr-3">
                            <b-form-checkbox
                              id="checkbox-oot"
                              v-model="tambahFarmasi.farmasi.sts_oot"
                              name="checkbox-oot"
                              value="1"
                              unchecked-value="0"
                              :disabled="dummy.disabledField"
                            >
                              OOT
                            </b-form-checkbox>
                          </div>
                          <div class="mr-3">
                            <b-form-checkbox
                              id="checkbox-prekursor"
                              v-model="tambahFarmasi.farmasi.sts_prekursor"
                              name="checkbox-prekursor"
                              value="1"
                              unchecked-value="0"
                              :disabled="dummy.disabledField"
                            >
                              Prekursor
                            </b-form-checkbox>
                          </div>
                          <div>
                            <b-form-checkbox
                              id="checkbox-bpjs"
                              v-model="tambahFarmasi.farmasi.sts_bpjs"
                              name="checkbox-bpjs"
                              value="1"
                              unchecked-value="0"
                              :disabled="dummy.disabledField"
                            >
                              BPJS
                            </b-form-checkbox>
                          </div>
                        </div>
                      </b-col>
                    </b-row>
                  </div>
                </div>
              </b-form-group>
            </b-col>
          </b-row>
          <hr class="m-0">
          <div>
            <b-row>
              <b-col cols="12">
                <table class="table bg-white table-bordered table-sm mb-0 pb-0">
                  <thead>
                    <tr>
                      <th scope="col" style="vertical-align: center;"><p class="mb-0 font-weight-normal text-center">Nomor</p></th>
                      <th scope="col" style="vertical-align: center;"><p class="mb-0 font-weight-normal text-center">Pabrik</p></th>
                      <th scope="col" style="vertical-align: center;"><p class="mb-0 font-weight-normal text-center">Kfa 93</p></th>
                      <th scope="col" style="vertical-align: center;"><p class="mb-0 font-weight-normal text-center">Status Aktif</p></th>
                      <th scope="col" style="vertical-align: center;"><p class="mb-0 font-weight-normal text-center">Hapus</p></th>
                      <th scope="col" style="vertical-align: center;" class="text-center">
                        <b-button variant="primary" class="border shadow-sm" @click="addRow" :disabled="dummy.aksesTambah === true ? false : true"><p class="h5 mb-0">+</p></b-button>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(k, index) in tambahFarmasi.pabrik" v-bind:key="k.codex">
                      <td class="col-1 py-2">
                        <div>
                          <p class="text-center font-weight-bold mb-0">{{ index + 1 }}</p>
                        </div>
                      </td>
                      <td class="col-5">
                        <div class="d-flex flex-row">
                          <div class="katalog-select-flex-left-10">
                            <multiselect :show-labels="false" label="nama_pabrik" track-by="id" placeholder="[ Pabrik ]" :searchable="true" :allow-empty="false"
                            v-model="dummy.selected.pabrik[index].detail" @input="valuePabrik($event, index)" :options="options.Pabrik">
                            </multiselect>
                          </div>
                          <div class="katalog-select-flex-right-2 pl-1" v-if="dummy.aksesTambah === true">
                            <div @click="tambahExtendForm('5')" class="bg-primary w-100 h-100 shadow-sm text-center d-flex align-items-center justify-content-center custom-radius-add-katalog">
                              <span class="h5 mb-0">+</span>
                            </div>
                          </div>
                          <div class="katalog-select-flex-right-2 pl-1" v-else>
                            <div class="bg-secondary w-100 h-100 shadow-sm text-center d-flex align-items-center justify-content-center custom-radius-add-katalog-disable">
                              <span class="h5 mb-0">+</span>
                            </div>
                          </div>
                      </div>
                      </td>
                      <td class="col-5">
                        <div class="d-flex flex-row align-items-center">
                          <div class="w-100">
                            <multiselect :show-labels="false" label="uraian" track-by="id" placeholder="[ KFA 93 ]" :searchable="true" :allow-empty="false"
                            v-model="dummy.selected.kfa93[index].detail" @input="valueKfa3($event, index)" :options="options.kfa93">
                            </multiselect>
                          </div>
                          <div class="pl-1" v-if="link.kfa93[index].url">
                            <span><a :href="link.kfa93[index].url" onclick="window.open(this.href, 'new', 'popup'); return false;">URL</a></span>
                          </div>
                        </div>
                      </td>
                      <td class="col-1 text-center">
                        <div>
                          <b-form-checkbox
                            v-model="tambahFarmasi.pabrik[index].sts_aktif"
                            name="checkbox-formularium-nasional"
                            value="1"
                            unchecked-value="0"
                            :disabled="dummy.disableStsAktif"
                          >
                          </b-form-checkbox>
                        </div>
                      </td>
                      <td class="text-center">
                        <b-button variant="white" @click="deleteRow(index)" v-if="index !== 0"><p class="mb-0"><i class="fas fa-trash-alt text-danger"></i></p></b-button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </b-col>
            </b-row>
          </div>
        </b-form>
        <div class="bg-dark px-2">
          <p class="small text-white text-right mb-0">{{ preview.message }}</p>
        </div>
       <!-- <pre>{{ tambahFarmasi }}</pre> -->
      </b-modal>
      <b-modal id="modal-tambah-edit-katalog" ref="modal-edit-farmasi" size="xl" centered title="Edit Katalog" ok-title="Perbarui Data" cancel-title="Tutup" @ok="editSave" :ok-disabled="dummy.simpankatalog" :no-close-on-backdrop="true" :static="true" :no-close-on-esc="true" @close="clearArr" @cancel="clearArr">
        <b-form>
          <b-row class="mb-3">
            <b-col>
              <b-form-group label-for="edit-nama-barang" class="mb-1">
                <div class="d-flex flex-row">
                  <div class="katalog-select-label-2">
                    <p class="custom-label-katalog text-dark mb-0">Nama Barang</p>
                  </div>
                  <div class="katalog-select-flex-left-2">
                    <b-form-input id="nama-barang" type="text" v-model="editFarmasi.farmasi.nama_barang" @blur="suggsetNmBrgEdit" class="cstm-cntrl-katalog-10" placeholder="[ Nama Barang ]" :disabled="dummy.disabledField"></b-form-input>
                  </div>
                </div>
              </b-form-group>
              <b-form-group label-for="edit-jenis-barang" class="mb-1">
                <div class="d-flex flex-row">
                  <div class="katalog-select-label">
                    <p class="custom-label-katalog text-dark mb-0">Jenis Barang</p>
                  </div>
                  <div class="katalog-select-flex-left">
                    <multiselect id="edit-jenis-barang" :show-labels="false" label="subjenis_anggaran" track-by="id" placeholder="[ Jenis Barang ]" :searchable="true" :allow-empty="false"
                    v-model="selected.editNamaJenisBarang" :options="options.editNamaJenisBarang" @input="valueEditJenisBarang" :disabled="dummy.disabledField">
                    </multiselect>
                  </div>
                  <div class="katalog-select-flex-right pl-2" v-if="dummy.aksesTambah === true">
                    <div @click="tambahExtendForm('1')" class="bg-primary w-100 h-100 shadow-sm text-center d-flex align-items-center justify-content-center custom-radius-add-katalog">
                      <span class="h5 mb-0">+</span>
                    </div>
                  </div>
                  <div class="katalog-select-flex-right pl-2" v-else>
                    <div class="bg-secondary w-100 h-100 shadow-sm text-center d-flex align-items-center justify-content-center custom-radius-add-katalog-disable">
                      <span class="h5 mb-0">+</span>
                    </div>
                  </div>
                </div>
              </b-form-group>
              <b-form-group label-for="edit-kelompok-barang" class="mb-1">
                <div class="d-flex flex-row">
                  <div class="katalog-select-label">
                    <p class="custom-label-katalog text-dark mb-0">Kelompok Barang</p>
                  </div>
                  <div class="katalog-select-flex-left">
                    <multiselect id="edit-kelompok-barang" :show-labels="false" label="kelompok_barang" track-by="id" placeholder="[ Kelompok Barang ]" :searchable="true" :allow-empty="false"
                    v-model="selected.editKelompokBarang" :options="options.editKelompokBarang" @input="valueEditKelompokBarang" :disabled="dummy.disabledField">
                    </multiselect>
                  </div>
                  <div class="katalog-select-flex-right pl-2" v-if="dummy.aksesTambah === true">
                    <div @click="tambahExtendForm('2')" class="bg-primary w-100 h-100 shadow-sm text-center d-flex align-items-center justify-content-center custom-radius-add-katalog">
                      <span class="h5 mb-0">+</span>
                    </div>
                  </div>
                  <div class="katalog-select-flex-right pl-2" v-else>
                    <div class="bg-secondary w-100 h-100 shadow-sm text-center d-flex align-items-center justify-content-center custom-radius-add-katalog-disable">
                      <span class="h5 mb-0">+</span>
                    </div>
                  </div>
                </div>
              </b-form-group>
              <b-form-group label-for="edit-nama-dagang" class="mb-1">
                <div class="d-flex flex-row">
                  <div class="katalog-select-label">
                    <p class="custom-label-katalog text-dark mb-0">Nama Dagang</p>
                  </div>
                  <div class="katalog-select-flex-left">
                    <multiselect id="edit-nama-dagang" :show-labels="false" label="nama_dagang" track-by="id" placeholder="[ Nama Dagang ]" :searchable="true" :allow-empty="false"
                    v-model="selected.editNamaDagang" :options="options.editNamaDagang" @input="valueEditNamaDagang" :disabled="dummy.disabledField">
                    </multiselect>
                  </div>
                  <div class="katalog-select-flex-right pl-2" v-if="dummy.aksesTambah === true">
                    <div @click="tambahExtendForm('3')" class="bg-primary w-100 h-100 shadow-sm text-center d-flex align-items-center justify-content-center custom-radius-add-katalog">
                      <span class="h5 mb-0">+</span>
                    </div>
                  </div>
                  <div class="katalog-select-flex-right pl-2" v-else>
                    <div class="bg-secondary w-100 h-100 shadow-sm text-center d-flex align-items-center justify-content-center custom-radius-add-katalog-disable">
                      <span class="h5 mb-0">+</span>
                    </div>
                  </div>
                </div>
              </b-form-group>
              <!-- <b-form-group label-for="edit-nama-generik" class="mb-1">
                <div class="d-flex flex-row">
                  <div class="katalog-select-label">
                    <p class="custom-label-katalog text-dark mb-0">Nama Generik</p>
                  </div>
                  <div class="katalog-select-flex-left">
                    <multiselect id="edit-nama-generik" :show-labels="false" label="nama_generik" track-by="id" placeholder="[ Nama Generik ]" :searchable="true" :allow-empty="false"
                    v-model="selected.editNamaGenerik" :options="options.editNamaGenerik" :disabled="dummy.disabledField">
                    </multiselect>
                  </div>
                  <div class="katalog-select-flex-right pl-2" v-if="dummy.aksesTambah === true">
                    <div @click="tambahExtendForm('4')" class="bg-primary w-100 h-100 shadow-sm text-center d-flex align-items-center justify-content-center custom-radius-add-katalog">
                      <span class="h5 mb-0">+</span>
                    </div>
                  </div>
                  <div class="katalog-select-flex-right pl-2" v-else>
                    <div class="bg-secondary w-100 h-100 shadow-sm text-center d-flex align-items-center justify-content-center custom-radius-add-katalog-disable">
                      <span class="h5 mb-0">+</span>
                    </div>
                  </div>
                </div>
              </b-form-group> -->
              <!-- <b-form-group label-for="edit-pabrik" class="mb-2">
                <div class="d-flex flex-row">
                  <div class="katalog-select-label">
                    <p class="custom-label-katalog text-dark mb-0">Nama Pabrik</p>
                  </div>
                  <div class="katalog-select-flex-left">
                    <multiselect id="edit-pabrik" :show-labels="false" label="nama_pabrik" placeholder="[ Pabrik ]" :searchable="true"
                    v-model="selected.editPabrik" :options="options.editPabrik" @input="valueEditPabrik" :disabled="dummy.disabledField">
                    </multiselect>
                  </div>
                  <div class="katalog-select-flex-right pl-2" v-if="dummy.aksesTambah === true">
                    <div @click="tambahExtendForm('5')" class="bg-primary w-100 h-100 shadow-sm text-center d-flex align-items-center justify-content-center custom-radius-add-katalog">
                      <span class="h5 mb-0">+</span>
                    </div>
                  </div>
                  <div class="katalog-select-flex-right pl-2" v-else>
                    <div class="bg-secondary w-100 h-100 shadow-sm text-center d-flex align-items-center justify-content-center custom-radius-add-katalog-disable">
                      <span class="h5 mb-0">+</span>
                    </div>
                  </div>
                </div>
              </b-form-group> -->
              <b-form-group label-for="edit-nama-pbf" class="mb-1">
                <div class="d-flex flex-row">
                  <div class="katalog-select-label">
                    <p class="custom-label-katalog text-dark mb-0">Pbf</p>
                  </div>
                  <div class="katalog-select-flex-left">
                    <multiselect id="edit-nama-pbf" :show-labels="false" label="nama_pbf" track-by="id" placeholder="[ Nama Pbf ]" :searchable="true" :allow-empty="false"
                    v-model="selected.editPbf" :options="options.editPbf" @input="valueEditPbf" :disabled="dummy.disabledField">
                    </multiselect>
                  </div>
                  <div class="katalog-select-flex-right pl-2" v-if="dummy.aksesTambah === true">
                    <div @click="tambahExtendForm('6')" class="bg-primary w-100 h-100 shadow-sm text-center d-flex align-items-center justify-content-center custom-radius-add-katalog">
                      <span class="h5 mb-0">+</span>
                    </div>
                  </div>
                  <div class="katalog-select-flex-right pl-2" v-else>
                    <div class="bg-secondary w-100 h-100 shadow-sm text-center d-flex align-items-center justify-content-center custom-radius-add-katalog-disable">
                      <span class="h5 mb-0">+</span>
                    </div>
                  </div>
                </div>
              </b-form-group>
              <b-form-group label-for="edit-zat-aktif" class="mb-1">
                <div class="d-flex flex-row">
                  <div class="katalog-select-label-2">
                    <p class="custom-label-katalog text-dark mb-0">Zat Aktif</p>
                  </div>
                  <div class="katalog-select-flex-left-2">
                    <b-form-textarea class="katalog-form-area-2" v-model="editFarmasi.farmasi.zat_aktif" rows="3" id="edit-zat-aktif" placeholder="[ Zat Aktif ]" :disabled="dummy.disabledField"></b-form-textarea>
                  </div>
                </div>
              </b-form-group>
              <b-form-group label-for="edit-retriksi" class="mb-1">
                <div class="d-flex flex-row">
                  <div class="katalog-select-label-2">
                    <p class="custom-label-katalog text-dark mb-0">Retriksi</p>
                  </div>
                  <div class="katalog-select-flex-left-2">
                    <b-form-textarea class="katalog-form-area-2" v-model="editFarmasi.farmasi.retriksi" rows="3" id="edit-retriksi" placeholder="[ Retriksi ]" :disabled="dummy.disabledField"></b-form-textarea>
                  </div>
                </div>
              </b-form-group>
              <b-form-group label-for="edit-keterangan" class="mb-1">
                <div class="d-flex flex-row">
                  <div class="katalog-select-label-2">
                    <p class="custom-label-katalog text-dark mb-0">Keterangan</p>
                  </div>
                  <div class="katalog-select-flex-left-2">
                    <b-form-textarea class="katalog-form-area-2" v-model="editFarmasi.farmasi.keterangan" rows="3" id="edit-keterangan" placeholder="[ Keterangan ]" :disabled="dummy.disabledField"></b-form-textarea>
                  </div>
                </div>
              </b-form-group>
              <b-form-group label-for="keterangan" class="mb-1">
                <div class="d-flex flex-row">
                  <div class="katalog-select-label-2">
                    <p class="custom-label-katalog text-dark mb-0">Kelas Terapi</p>
                  </div>
                  <div class="katalog-select-flex-left-2">
                    <b-form-textarea size="sm" class="katalog-form-area-2" v-model="editFarmasi.farmasi.kelas_terapi" rows="3" id="edit-kelas_terapi" placeholder="[ Kelas Terapi ]" :disabled="dummy.disabledField"></b-form-textarea>
                  </div>
                </div>
              </b-form-group>
              <b-form-group label-for="keterangan" class="mb-0">
                <div class="d-flex flex-row">
                  <div class="katalog-select-label-2">
                    <p class="custom-label-katalog text-dark mb-0">Peresepan Maksimal</p>
                  </div>
                  <div class="katalog-select-flex-left-2-peresepan-maksimal mr-auto">
                    <b-form-textarea size="sm" class="katalog-form-area-2" v-model="editFarmasi.farmasi.resep_max_kasus" rows="3" id="edit-resep_max_kasus" placeholder="[ Per Kasus ]" :disabled="dummy.disabledField"></b-form-textarea>
                  </div>
                  <div class="katalog-select-flex-left-2-peresepan-maksimal">
                    <b-form-textarea size="sm" class="katalog-form-area-2" v-model="editFarmasi.farmasi.resep_max_bulan" rows="3" id="edit-resep_max_bulan" placeholder="[ Per Bulan ]" :disabled="dummy.disabledField"></b-form-textarea>
                  </div>
                </div>
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group label-for="edit-kode-barang" class="mb-1">
                <div class="d-flex flex-row">
                  <div class="katalog-select-label-2">
                    <p class="custom-label-katalog text-dark mb-0">Kode Barang</p>
                  </div>
                  <div class="katalog-select-flex-left-2">
                    <b-row>
                      <b-col cols="5" class="pr-1">
                        <b-form-input id="edit-kode-barang" type="text" v-model="editFarmasi.farmasi.kode" @blur="validasiKdBrgEdit" class="cstm-cntrl-katalog-kode" placeholder="" :disabled="dummy.disabledField" readonly></b-form-input>
                      </b-col>
                      <b-col class="pl-1" cols="1">
                        <p class="custom-font-katalog text-danger mb-0 mt-2" v-if="dummy.msgValidasiKd.statcode === 0">
                          <i class="fas fa-times-circle fa-2x"></i>
                        </p>
                        <p class="custom-font-katalog text-success mb-0 mt-2" v-else-if="dummy.msgValidasiKd.statcode === 20001">
                          <span class=""><i class="fas fa-check-circle fa-2x"></i></span>
                        </p>
                      </b-col>
                      <b-col class="pl-1">
                        <p class="text-dark custom-font-katalog mb-0 mt-2 text-right">
                          <span>Edit Kode Barang ?</span>
                          <span class="ml-2 bg-warning py-2 px-2 border custom-button-radius katalog-cursor" @click="modalEditKode"> Edit Kode</span>
                        </p>
                      </b-col>
                    </b-row>
                  </div>
                </div>
              </b-form-group>
              <b-form-group label-for="edit-nama-sediaan" class="mb-1">
                <div class="d-flex flex-row">
                  <div class="katalog-select-label-2">
                    <p class="custom-label-katalog text-dark mb-0">Satuan Kemasan</p>
                  </div>
                  <div class="katalog-select-flex-left-2">
                    <b-row class="mb-1">
                      <b-col cols="4" class="pr-1">
                        <multiselect id="edit-kemasan-besar" :show-labels="false" label="nama_kemasan" track-by="id" placeholder="[ Kemasan Besar ]" @input="valueEditKemasanBesar" :searchable="true" :allow-empty="false"
                        v-model="selected.editSatuanKemasanBesar" :options="options.editSatuanKemasanBesar" :disabled="dummy.disabledField">
                        </multiselect>
                      </b-col>
                      <b-col cols="4" class="pr-1 pl-1">
                        <b-form-input v-model="preview.editIsi_kemasan" @input="valueEditIsiKemasan" @keypress="isNumberKey" id="edit-isi-kemasan" maxlength="9" class="cstm-cntrl-katalog-10" placeholder="[ Isi Kemasan ]" :disabled="dummy.disabledField"></b-form-input>
                      </b-col>
                      <b-col cols="4" class="pl-1">
                        <multiselect id="edit-kemasan-kecil" :show-labels="false" label="nama_kemasan" track-by="id" placeholder="[ Kemasan Kecil ]" @input="valueEditKemasanKecil" :searchable="true" :allow-empty="false"
                        v-model="selected.editSatuanKemasanKecil" :options="options.editSatuanKemasanKecil" :disabled="dummy.disabledField">
                        </multiselect>
                      </b-col>
                    </b-row>
                  </div>
                </div>
                <div class="d-flex flex-row">
                  <div class="katalog-select-label-2">
                    <p class="custom-label-katalog text-dark mb-0">Dosis Kfa</p>
                  </div>
                  <div class="katalog-select-flex-left-2">
                    <b-row class="mb-1">
                      <b-col class="pr-1">
                        <b-form-input id="edit-isi-dosis" v-model="preview.edit_isi_dosis" @input="valueEditIsiDosis" @keypress="isNumberKey" class="cstm-cntrl-katalog-10" placeholder="[ Isi Dosis ]" :disabled="dummy.disabledField"></b-form-input>
                      </b-col>
                      <b-col cols="8" class="d-flex flex-row pl-1">
                        <div class="dosis-left-width mr-1">
                          <multiselect id="edit-dosis" :show-labels="false" label="nama" track-by="id" @input="valueEditDosis" placeholder="[ Dosis ]" :searchable="true" :allow-empty="false"
                          v-model="selected.editDosis" :options="options.editDosis" :disabled="dummy.disabledField">
                          </multiselect>
                        </div>
                        <div class="dosis-right-width pl-2" v-if="dummy.aksesTambah === true">
                          <div @click="tambahExtendForm('7')" class="bg-primary w-100 h-100 shadow-sm text-center d-flex align-items-center justify-content-center custom-radius-add-katalog">
                            <span class="h5 mb-0">+</span>
                          </div>
                        </div>
                        <div class="dosis-right-width pl-2" v-else>
                          <div class="bg-secondary w-100 h-100 shadow-sm text-center d-flex align-items-center justify-content-center custom-radius-add-katalog-disable">
                            <span class="h5 mb-0">+</span>
                          </div>
                        </div>
                      </b-col>
                    </b-row>
                  </div>
                </div>
                <div>
                  <div class="d-flex flex-row">
                    <div class="katalog-select-label-2">
                      <div>
                        <p class="custom-label-katalog text-dark mb-0">Kemasan Barang</p>
                      </div>
                    </div>
                    <div class="katalog-select-flex-left-2">
                      <b-row>
                        <b-col lg="12">
                          <b-form-input v-model="editFarmasi.farmasi.kemasan" id="edit-kemasan-barang" class="cstm-cntrl-katalog-10" placeholder="[ Kemasan Barang ]" readonly :disabled="dummy.disabledField"></b-form-input>
                        </b-col>
                      </b-row>
                    </div>
                  </div>
                </div>
              </b-form-group>
              <b-form-group label-for="kategori-obat-edit" class="mb-1">
                <div>
                  <div class="satusehat-selectize">
                    <b-row>
                      <b-col cols="12" class="mb-1">
                        <div class="d-flex flex-row">
                          <div class="katalog-select-label-2">
                            <div>
                              <p class="custom-label-katalog text-dark mb-0">Kfa 91 <span class="text-primary pl-2" v-if="link.kfa91"><a :href="link.kfa91" onclick="window.open(this.href, 'new', 'popup'); return false;">URL</a></span></p>
                            </div>
                          </div>
                          <div class="katalog-select-flex-left-2">
                            <b-row>
                              <b-col>
                                <multiselect v-model="selected.editKfa91" :options="options.editKfa91" @input="valueEditKfa1" label="uraian" track-by="id" :searchable="true" :allow-empty="false" placeholder="KFA 1" :disabled="dummy.disabledField"></multiselect>
                              </b-col>
                            </b-row>
                          </div>
                        </div>
                      </b-col>
                      <b-col cols="12" class="mb-1">
                        <div class="d-flex flex-row">
                          <div class="katalog-select-label-2">
                            <div>
                              <p class="custom-label-katalog text-dark mb-0">Kfa 92 <span class="text-primary pl-2" v-if="link.kfa92"><a :href="link.kfa92" onclick="window.open(this.href, 'new', 'popup'); return false;">URL</a></span></p>
                            </div>
                          </div>
                          <div class="katalog-select-flex-left-2">
                            <b-row>
                              <b-col>
                                <multiselect v-model="selected.editKfa92" :options="options.editKfa92" @input="valueEditKfa2" label="uraian" track-by="id" :searchable="true" :show-labels="false" :allow-empty="false" placeholder="KFA 2" :disabled="dummy.disabledField"></multiselect>
                              </b-col>
                            </b-row>
                          </div>
                        </div>
                      </b-col>
                      <!-- <b-col cols="12">
                        <div class="d-flex flex-row">
                          <div class="katalog-select-label-2">
                            <div>
                              <p class="custom-label-katalog text-dark mb-0">Kfa 93</p>
                            </div>
                          </div>
                          <div class="katalog-select-flex-left-2">
                            <b-row>
                              <b-col>
                                <multiselect v-model="selected.editKfa93" :options="options.editKfa93" @input="valueEditKfa3" label="uraian" track-by="id" :searchable="true" placeholder="KFA 3" :disabled="dummy.disabledField"></multiselect>
                              </b-col>
                            </b-row>
                          </div>
                        </div>
                      </b-col> -->
                    </b-row>
                  </div>
                </div>
              </b-form-group>
              <b-form-group label-for="kategori-obat" class="mb-1">
                <div class="d-flex flex-row">
                  <div class="katalog-select-label-2">
                    <div>
                      <p class="custom-label-katalog text-dark mb-0">Sakti</p>
                    </div>
                  </div>
                  <div class="katalog-select-flex-left-2">
                    <div class="d-flex flex-row">
                      <div class="sakti-left-width mr-1">
                        <multiselect v-model="selected.editSakti" :options="options.editSakti" @input="valueEditSakti" label="uraian" track-by="id" :searchable="true" :show-labels="false" :allow-empty="false" placeholder="[ Sakti ]" :disabled="dummy.disableSakti"></multiselect>
                      </div>
                      <div class="sakti-right-width" v-if="dummy.aksesTambah === true && dummy.aksesSakti === true && dummy.disableSakti === false">
                        <div @click="tambahExtendForm('8')" class="bg-primary w-100 h-100 shadow-sm text-center d-flex align-items-center justify-content-center custom-radius-add-katalog">
                          <span class="h5 mb-0">+</span>
                        </div>
                      </div>
                      <div class="sakti-right-width" v-else>
                        <div class="bg-secondary w-100 h-100 shadow-sm text-center d-flex align-items-center justify-content-center custom-radius-add-katalog-disable">
                          <span class="h5 mb-0">+</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </b-form-group>
              <!-- <b-form-group label-for="kategori-obat-edit" class="mb-1">
                <div class="d-flex flex-row">
                  <div class="katalog-select-label-2">
                    <div>
                      <p class="custom-label-katalog text-dark mb-0">Aktifasi Barang</p>
                    </div>
                  </div>
                  <div class="katalog-select-flex-left-2">
                    <b-row>
                      <b-col>
                        <b-form-radio-group
                          id="radio-group-10"
                          v-model="editFarmasi.farmasi.aktifasi"
                          :options="options.aktivasiBarang"
                          name="radio-options"
                          :disabled="dummy.disableStsAktif"
                        ></b-form-radio-group>
                      </b-col>
                    </b-row>
                  </div>
                </div>
              </b-form-group> -->
              <b-form-group label-for="edit-harga-diskon" class="mb-1">
                <div class="d-flex flex-row">
                  <div class="katalog-select-label-2">
                    <div>
                      <p class="custom-label-katalog text-dark mb-0">Set Harga</p>
                    </div>
                  </div>
                  <div class="katalog-select-flex-left-2">
                    <b-row>
                      <b-col class="pr-1">
                        <b-form-input id="harga" v-model="preview.editRupiah" @input="formatEditRupiah" @keypress="isNumberKey" class="cstm-cntrl-katalog-10"  placeholder="[ Harga (0) ]" :disabled="dummy.disabledField"></b-form-input>
                      </b-col>
                      <b-col class="pl-1">
                        <b-form-input id="diskon" v-model="preview.editDiskon" @input="formatEditDiskon" @keypress="isNumberKey" class="cstm-cntrl-katalog-10" maxlength="5" placeholder="[ Diskon % (00,00) ]" :disabled="dummy.disabledField"></b-form-input>
                      </b-col>
                    </b-row>
                  </div>
                </div>
              </b-form-group>
              <b-form-group label-for="edit-keterangan-barang" class="mb-1">
                <div class="d-flex flex-row">
                    <div class="katalog-select-label-2">
                      <div>
                        <p class="custom-label-katalog text-dark mb-0">Keterangan Barang</p>
                      </div>
                    </div>
                    <div class="katalog-select-flex-left-2">
                      <b-row>
                        <b-col class="pr-1">
                          <b-form-input id="leadtime" v-model="editFarmasi.farmasi.leadtime" @keypress="isNumberKey" class="cstm-cntrl-katalog-10" maxlength="2" placeholder="[ Lead Time ]" :disabled="dummy.disabledField"></b-form-input>
                        </b-col>
                        <b-col class="pr-1 pl-1">
                          <b-form-input id="buffer" v-model="preview.editBuffer" @input="formatEditBuffer" @keypress="isNumberKey" class="cstm-cntrl-katalog-10" maxlength="2" placeholder="[ Buffer % ]" :disabled="dummy.disabledField"></b-form-input>
                        </b-col>
                        <b-col class="pl-1">
                          <multiselect id="death-moving" :show-labels="false" label="name" track-by="id" placeholder="[ Moving ]" :searchable="true" :allow-empty="false"
                          v-model="selected.editDeathMoving" :options="options.editDeathMoving" @input="valueeditmoving" :disabled="dummy.disabledField">
                          </multiselect>
                        </b-col>
                      </b-row>
                    </div>
                  </div>
              </b-form-group>
              <b-form-group label-for="edit-formularium" class="mb-1" v-if="dummy.conditionKatObtEdit === true">
                <div class="d-flex flex-row">
                  <div class="katalog-select-label-2">
                    <div>
                      <p class="custom-label-katalog text-dark mb-0">Formularium</p>
                    </div>
                  </div>
                  <div class="katalog-select-flex-left-2">
                    <b-row>
                      <b-col>
                        <div class="d-flex flex-row align-items-center">
                          <div class="mr-3">
                            <b-form-checkbox
                              id="checkbox-formularium-nasional-edit"
                              v-model="editFarmasi.farmasi.formularium_nas"
                              name="checkbox-formularium-nasional-edit"
                              value="1"
                              unchecked-value="0"
                              :disabled="dummy.disabledField"
                            >
                              Formularium Nasional
                            </b-form-checkbox>
                          </div>
                          <div>
                            <b-form-checkbox
                              id="checkbox-formularium-rsupf-edit"
                              v-model="editFarmasi.farmasi.formularium_rs"
                              name="checkbox-formularium-rsupf-edit"
                              value="1"
                              unchecked-value="0"
                              :disabled="dummy.disabledField"
                            >
                              Formularium RSUPF
                            </b-form-checkbox>
                          </div>
                        </div>
                      </b-col>
                    </b-row>
                  </div>
                </div>
              </b-form-group>
              <b-form-group label-for="edit-kategori-obat" class="mb-2" v-if="dummy.conditionKatObtEdit === true">
                <div class="d-flex flex-row mb-1">
                  <div class="katalog-select-label-2">
                    <div>
                      <p class="custom-label-katalog text-dark mb-0">Kategori Obat</p>
                    </div>
                  </div>
                  <div class="katalog-select-flex-left-2">
                    <b-row class="align-items-center">
                      <b-col>
                        <div class="d-flex flex-row align-items-center">
                          <div class="mr-3">
                            <b-form-checkbox
                              id="checkbox-livesaving-edit"
                              v-model="editFarmasi.farmasi.live_saving"
                              name="checkbox-livesaving-edit"
                              value="1"
                              unchecked-value="0"
                              :disabled="dummy.disabledField"
                            >
                              Live Saving
                            </b-form-checkbox>
                          </div>
                          <div class="mr-3">
                            <b-form-checkbox
                              id="checkbox-generik-edit"
                              name="checkbox-generik-edit"
                              v-model="editFarmasi.farmasi.generik"
                              value="1"
                              unchecked-value="0"
                              :disabled="dummy.disabledField"
                              @input="valueGenerikEdit"
                            >
                              Obat Generik
                            </b-form-checkbox>
                          </div>
                          <div>
                            <b-form-checkbox
                              id="checkbox-kronis-edit"
                              v-model="editFarmasi.farmasi.sts_kronis"
                              name="checkbox-kronis-edit"
                              value="1"
                              unchecked-value="0"
                              :disabled="dummy.disabledField"
                            >
                              Obat Kronis
                            </b-form-checkbox>
                          </div>
                        </div>
                      </b-col>
                      <b-col cols="5">
                        <multiselect id="kategori-warna" :show-labels="false" label="name" track-by="id" placeholder="[ Kategori Warna ]" @input="valueEditKategoriWarna" :searchable="true" :allow-empty="false"
                          v-model="selected.editkategoriWarna" :options="options.kategoriWarna" :disabled="dummy.disabledField">
                        </multiselect>
                      </b-col>
                    </b-row>
                  </div>
                </div>
                <div class="d-flex flex-row">
                  <div class="katalog-select-label-2">
                    <div>
                      <p class="custom-label-katalog text-dark mb-0"></p>
                    </div>
                  </div>
                  <div class="katalog-select-flex-left-2">
                    <b-row class="align-items-center">
                      <b-col>
                        <div class="d-flex flex-row align-items-center">
                          <div class="mr-3">
                            <b-form-checkbox
                              id="edit-checkbox-narkotika"
                              v-model="editFarmasi.farmasi.sts_narkotika"
                              name="edit-checkbox-narkotika"
                              value="1"
                              unchecked-value="0"
                              :disabled="dummy.disabledField"
                            >
                              Narkotika
                            </b-form-checkbox>
                          </div>
                          <div class="mr-3">
                            <b-form-checkbox
                              id="edit-checkbox-psikotropika"
                              name="edit-checkbox-psikotropika"
                              v-model="editFarmasi.farmasi.sts_psikotropika"
                              value="1"
                              unchecked-value="0"
                              :disabled="dummy.disabledField"
                            >
                              Psikotropika
                            </b-form-checkbox>
                          </div>
                          <div class="mr-3">
                            <b-form-checkbox
                              id="edit-checkbox-oot"
                              v-model="editFarmasi.farmasi.sts_oot"
                              name="edit-checkbox-oot"
                              value="1"
                              unchecked-value="0"
                              :disabled="dummy.disabledField"
                            >
                              OOT
                            </b-form-checkbox>
                          </div>
                          <div class="mr-3">
                            <b-form-checkbox
                              id="edit-checkbox-prekursor"
                              v-model="editFarmasi.farmasi.sts_prekursor"
                              name="edit-checkbox-prekursor"
                              value="1"
                              unchecked-value="0"
                              :disabled="dummy.disabledField"
                            >
                              Prekursor
                            </b-form-checkbox>
                          </div>
                          <div>
                            <b-form-checkbox
                              id="edit-checkbox-bpjs"
                              v-model="editFarmasi.farmasi.sts_bpjs"
                              name="edit-checkbox-bpjs"
                              value="1"
                              unchecked-value="0"
                              :disabled="dummy.disabledField"
                            >
                              BPJS
                            </b-form-checkbox>
                          </div>
                        </div>
                      </b-col>
                    </b-row>
                  </div>
                </div>
              </b-form-group>
            </b-col>
          </b-row>
          <hr class="m-0">
          <div>
            <b-row>
              <b-col cols="12">
                <table class="table bg-white table-bordered table-sm">
                  <thead>
                    <tr>
                      <th scope="col" style="vertical-align: center;" class="text-center"><p class="mb-0">Nomor</p></th>
                      <th scope="col" style="vertical-align: center;" class="text-center">Pabrik</th>
                      <th scope="col" style="vertical-align: center;" class="text-center">Kfa 93</th>
                      <th scope="col" style="vertical-align: center;" class="text-center">ID Transmedik</th>
                      <th scope="col" style="vertical-align: center;" class="text-center">Sts. Opname</th>
                      <th scope="col" style="vertical-align: center;" class="text-center">Sts. Aktif</th>
                      <th scope="col" style="vertical-align: center;" class="text-center">Hapus</th>
                      <th scope="col" style="vertical-align: center;" class="text-center">
                        <b-button :variant="dummy.aksesTambah === true && dummy.sts_pabrik === 0 ? 'primary' : 'secondary'" class="border shadow-sm" @click="addRowEdit" :disabled="dummy.aksesTambah === true && dummy.sts_pabrik === 0 ? false : true"><p class="h5 mb-0">+</p></b-button>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(k, index) in editFarmasi.pabrik" v-bind:key="k.codex">
                      <td class="col-1">
                        <div>
                          <p class="text-center mb-0">{{ k.no_urut }}</p>
                        </div>
                      </td>
                      <td class="col-4">
                        <div class="d-flex flex-row">
                        <div class="katalog-select-flex-left-10">
                          <multiselect id="pabrik" :show-labels="false" label="nama_pabrik" track-by="id" placeholder="[ Pabrik ]" :searchable="true" :allow-empty="false"
                          v-model="dummy.selected.editpabrik[index].detail" @input="valuePabrikEdit($event, index)" :options="options.editPabrik" :disabled="dummy.disablePabrik === false || k.id_pabrik === '0' ? false : true">
                          </multiselect>
                        </div>
                        <div class="katalog-select-flex-right-2 pl-1" v-if="dummy.aksesTambah === true && k.no_urut ==='0'">
                          <div @click="tambahExtendForm('5')" class="bg-primary w-100 h-100 shadow-sm text-center d-flex align-items-center justify-content-center custom-radius-add-katalog">
                            <span class="h5 mb-0">+</span>
                          </div>
                        </div>
                        <div class="katalog-select-flex-right-2 pl-1" v-else>
                          <div class="bg-secondary w-100 h-100 shadow-sm text-center d-flex align-items-center justify-content-center custom-radius-add-katalog-disable">
                            <span class="h5 mb-0">+</span>
                          </div>
                        </div>
                      </div>
                      </td>
                      <td class="col-4">
                        <div class="d-flex flex-row align-items-center">
                          <div class="w-100">
                            <multiselect :show-labels="false" label="uraian" track-by="id" placeholder="[ KFA 93 ]" :searchable="true" :allow-empty="false"
                            v-model="dummy.selected.editkfa93[index].detail" @input="valueEditKfa3($event, index)" :options="options.editKfa93">
                            </multiselect>
                          </div>
                          <div class="pl-1" v-if="link.editkfa93[index].url">
                            <span><a :href="link.editkfa93[index].url" onclick="window.open(this.href, 'new', 'popup'); return false;">URL</a></span>
                          </div>
                        </div>
                      </td>
                      <td class="col-1">
                        <div>
                          <b-form-input id="id-transmedik" type="text" :disabled="bitControl.bitcontrol2.charAt(1) === '0'" v-model="editFarmasi.pabrik[index].id_transmedik" class="cstm-cntrl-katalog-10" placeholder="ID Transmedik"></b-form-input>
                        </div>
                      </td>
                      <td class="text-center">
                        <div class="py-2">
                          <b-form-checkbox
                            v-model="editFarmasi.pabrik[index].sts_opname"
                            name="checkbox-sts-opname"
                            value="1"
                            unchecked-value="0"
                            :disabled="dummy.disableStsAktif"
                          >
                          </b-form-checkbox>
                        </div>
                      </td>
                      <td class="text-center">
                        <div class="py-2">
                          <b-form-checkbox
                            v-model="editFarmasi.pabrik[index].sts_aktif"
                            name="checkbox-sts-aktif"
                            value="1"
                            unchecked-value="0"
                            :disabled="dummy.disableStsAktif"
                          >
                          </b-form-checkbox>
                        </div>
                      </td>
                      <td class="text-center">
                        <b-button variant="white" @click="deleteRowEdit(index)" v-if="k.no_urut === '0' && index !== 0"><p class="mb-0"><i class="fas fa-trash-alt text-danger"></i></p></b-button>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <!-- <pre>{{ editFarmasi }}</pre> -->
                <!-- <pre>{{ dummy.form.editpabrik }}</pre> -->
              </b-col>
            </b-row>
          </div>
        </b-form>
        <div class="bg-dark px-2">
          <p class="text-white small text-right mb-0">{{ preview.message }}</p>
        </div>
        <!-- <div class="mt-2">
          <p class="small text-dark mb-0">User In : {{ preview.user_in }}, Created In : {{ editFarmasi.farmasi.sysdate_in }}</p>
        </div> -->
        <!-- <pre>{{ editFarmasi }}</pre> -->
      </b-modal>
      <b-modal ref="modal-delete-farmasi" size="md" centered title="Konfirmasi" ok-title="Hapus Data" ok-variant="danger" cancel-title="Tutup" @ok="deleteSave">
        <div class="py-3">
          <p class="h6 text-center text-dark mb-0">Yakin Ingin Menghapus Data ?</p>
        </div>
      </b-modal>
      <b-modal ref="modal-notif-farmasi" size="md" centered title="Notifikasi" ok-title="Tutup" :hide-footer="true" :hide-header="true">
        <div class="pb-3 px-4">
          <div v-if="preview.messageValue === '1'">
            <lottie :options="preview.lottie.defaultOptions" :autoPlay="true" :width="150" :height="150" />
          </div>
          <div v-else>
            <lottie :options="preview.lottie.defaultOptions2" :autoPlay="true" :width="150" :height="150" />
          </div>
          <p class="h6 text-dark text-center mb-0 mt-2">{{ preview.message }}</p>
        </div>
      </b-modal>
      <b-modal id="modal-editkode-farmasi" ref="modal-editkode-farmasi" size="md" centered title="Edit Kode" ok-title="Edit kode" ok-variant="warning" cancel-title="Tutup" :no-close-on-backdrop="true" @ok="simpanEditKodeBaru" :static="true" :ok-disabled="dummy.msgValidasiKd2.statcode ? false : dummy.msgValidasiKd2.statcode === 20001 ? false : true">
        <div>
          <div class="mb-2">
            <p class="text-dark mb-1">Kode Lama :</p>
            <b-form-input id="kodelama" v-model="editFarmasi.farmasi.kode" class="cstm-cntrl-katalog-kode" placeholder="[ Kode Lama ]" :disabled="dummy.disabledField" readonly></b-form-input>
          </div>
          <div>
            <p class="text-dark mb-1">Kode Baru :</p>
            <b-row>
              <b-col cols="11">
                <b-form-input id="kodebaru" v-model="dummy.editKodeBrg.kode" @blur="validasiKdBaru" @mouseleave="validasiKdBaru" class="cstm-cntrl-katalog-kode" placeholder="[ Kode Baru ]" :disabled="dummy.disabledField"></b-form-input>
              </b-col>
              <div cols="1" class="d-flex align-items-center">
                <p class="custom-font-katalog text-danger mb-0" v-if="dummy.msgValidasiKd2.statcode === 0">
                  <i class="fas fa-times-circle fa-2x"></i>
                </p>
                <p class="custom-font-katalog text-success mb-0" v-else-if="dummy.msgValidasiKd2.statcode === 20001">
                  <span class=""><i class="fas fa-check-circle fa-2x"></i></span>
                </p>
              </div>
            </b-row>
          </div>
        </div>
      </b-modal>
      <b-modal id="modal-extend-tambah" ref="modal-extend-tambah" size="lg" centered title="Tambah" ok-title="Tambah" ok-variant="primary" cancel-title="Tutup" @ok="tambahExtend">
        <div class="py-2">
          <b-form v-if="created.index === '1'">
            <b-form-group class="mb-2">
              <b-row class="align-items-center">
                <b-col cols="2">
                  <span>
                    <p class="small text-dark mb-0">Jenis Anggaran</p>
                  </span>
                </b-col>
                <b-col>
                  <div class="d-flex flex-row w-100">
                    <div class="katalog-select-flex-left">
                      <multiselect v-model="created.selected.subjenisanggaran" :show-labels="false"
                        label="jenis_anggaran" placeholder="[ Jenis Anggaran ]" :options="created.options.subjenisanggaran" @input="valueExtendJenisBarang" :searchable="true">
                      </multiselect>
                    </div>
                    <div class="katalog-select-flex-right pl-2">
                      <div @click="tambahExtendFormSub('1')" class="bg-primary w-100 h-100 shadow-sm text-center d-flex align-items-center justify-content-center custom-radius-add-katalog">
                        <span class="h5 mb-0">+</span>
                      </div>
                    </div>
                  </div>
                </b-col>
              </b-row>
            </b-form-group>
            <b-form-group class="formtahun mb-2">
              <b-row class="align-items-center">
                <b-col cols="2">
                  <span>
                    <p class="small text-dark mb-0">Tahun Aktif</p>
                  </span>
                </b-col>
                <b-col>
                  <div class="tahun-aktif">
                    <b-form-input class="cstm-cntrl-katalog-10" v-model="created.subjenisanggaran.thn_aktif" placeholder="[ Tahun Aktif ]"></b-form-input>
                  </div>
                </b-col>
              </b-row>
            </b-form-group>
            <b-form-group class="mb-2">
              <b-row class="align-items-center">
                <b-col cols="2">
                  <span>
                    <p class="small text-dark mb-0">Kode</p>
                  </span>
                </b-col>
                <b-col>
                  <b-form-input class="cstm-cntrl-katalog-10" v-model="created.subjenisanggaran.kode" placeholder="[ Kode ]"></b-form-input>
                </b-col>
              </b-row>
            </b-form-group>
            <b-form-group class="mb-2">
              <b-row class="align-items-center">
                <b-col cols="2">
                  <span>
                    <p class="small text-dark mb-0">Keterangan</p>
                  </span>
                </b-col>
                <b-col>
                  <b-form-input class="cstm-cntrl-katalog-10" v-model="created.subjenisanggaran.keterangan" placeholder="[ Keterangan ]"></b-form-input>
                </b-col>
              </b-row>
            </b-form-group>
            <b-form-group class="mb-2">
              <b-row class="align-items-center">
                <b-col cols="2">
                  <span>
                    <p class="small text-dark mb-0">User In</p>
                  </span>
                </b-col>
                <b-col>
                  <b-form-input class="cstm-cntrl-katalog-10" v-model="preview.user_name" placeholder="Nama User" readonly></b-form-input>
                </b-col>
              </b-row>
            </b-form-group>
            <b-form-group class="mb-0">
              <b-row class="align-items-center">
                <b-col cols="2">
                  <span>
                    <p class="small text-dark mb-0">Status Aktif</p>
                  </span>
                </b-col>
                <b-col>
                  <b-form-radio-group class="mb-0"
                    v-model="created.subjenisanggaran.sts_aktif"
                    :options="options.optionsStsAktif"
                    ></b-form-radio-group>
                </b-col>
              </b-row>
            </b-form-group>
          </b-form>
          <b-form v-else-if="created.index === '2'">
            <b-form-group class="mb-2">
              <b-row class="align-items-center">
                <b-col cols="2">
                  <span>
                    <p class="small text-dark mb-0">Kode</p>
                  </span>
                </b-col>
                <b-col class="pr-1">
                  <b-form-input class="cstm-cntrl-katalog-10" v-model="created.tambahKelompok.kode"
                    placeholder="[ Kode ]"></b-form-input>
                </b-col>
                <b-col class="pl-1">
                  <b-form-input class="cstm-cntrl-katalog-10" v-model="created.tambahKelompok.kelompok_barang"
                    placeholder="[ Kelompok Barang ]"></b-form-input>
                </b-col>
                <!-- <b-col class="pr-1 pl-1">
                  <b-form-input class="cstm-cntrl-katalog-10" v-model="created.tambahKelompok.kode_temp"
                    placeholder="[ Kode Temp ]"></b-form-input>
                </b-col> -->
                <!-- <b-col class="pl-1">
                  <multiselect v-model="created.selected.kodesimrsgos" :show-labels="false"
                    label="nama" placeholder="[ Kode Simrsgos ]" :options="created.options.kodesimrsgos" @input="valueSimrsgos"
                    :searchable="true">
                </multiselect>
                </b-col> -->
              </b-row>
            </b-form-group>
            <b-form-group class="mb-2">
              <b-row class="align-items-center">
                <b-col cols="2">
                  <span>
                    <p class="small text-dark mb-0">Kelompok Barang</p>
                  </span>
                </b-col>
                <b-col class="pr-1">
                  <multiselect v-model="created.selected.inventori" :show-labels="false"
                    label="uraian_inventory" placeholder="[ Inventory ]" :options="created.options.inventori" @input="valueKelInv"
                    :searchable="true">
                  </multiselect>
                </b-col>
                <b-col class="pl-1">
                  <multiselect v-model="created.selected.hardcode" :show-labels="false"
                    label="keterangan" placeholder="[ Hardcode ]" :options="created.options.hardcode" @input="valueHardCode"
                    :searchable="true">
                  </multiselect>
                </b-col>
              </b-row>
            </b-form-group>
            <b-form-group class="mb-2">
              <b-row class="align-items-center">
                <b-col cols="2">
                  <span>
                    <p class="small text-dark mb-0">Status Aktif</p>
                  </span>
                </b-col>
                <b-col>
                  <b-form-radio-group class="mb-0"
                    v-model="created.tambahKelompok.sts_aktif"
                    :options="options.optionsStsAktif"
                    ></b-form-radio-group>
                </b-col>
              </b-row>
            </b-form-group>
            <!-- <b-form-group class="mb-2">
              <b-row class="align-items-center">
                <b-col cols="2">
                  <span>
                    <p class="small text-dark mb-0">Kategori</p>
                  </span>
                </b-col>
                <b-col class="pr-1">
                  <b-form-input class="cstm-cntrl-katalog-10" v-model="created.tambahKelompok.no_urut"
                    placeholder="[ Nomor Urut ]"></b-form-input>
                </b-col>
                <b-col class="pr-1 pl-1">
                  <b-form-input class="cstm-cntrl-katalog-10" v-model="created.tambahKelompok.gol"
                    placeholder="[ Golongan ]"></b-form-input>
                </b-col>
                <b-col class="pl-1">
                  <b-form-input class="cstm-cntrl-katalog-10" v-model="created.tambahKelompok.bid"
                    placeholder="[ Bidang ]"></b-form-input>
                </b-col>
              </b-row>
            </b-form-group> -->
            <!-- <b-form-group class="mb-2">
              <b-row class="align-items-center">
                <b-col cols="2">
                  <span>
                    <p class="small text-dark mb-0">Kelompok</p>
                  </span>
                </b-col>
                <b-col class="pr-1">
                  <b-form-input class="cstm-cntrl-katalog-10" v-model="created.tambahKelompok.kel"
                    placeholder="[ Kelompok ]"></b-form-input>
                </b-col>
                <b-col class="pr-1 pl-1">
                  <b-form-input class="cstm-cntrl-katalog-10" v-model="created.tambahKelompok.subkel"
                    placeholder="[ Sub Kelompok ]"></b-form-input>
                </b-col>
                <b-col class="pl-1">
                  <b-form-input class="cstm-cntrl-katalog-10" v-model="created.tambahKelompok.subsubkel"
                    placeholder="[ Sub-Sub Kelompok ]"></b-form-input>
                </b-col>
              </b-row>
            </b-form-group> -->
            <b-form-group class="mb-0">
              <b-row class="align-items-center">
                <b-col cols="2">
                  <span>
                    <p class="small text-dark mb-0">User Update</p>
                  </span>
                </b-col>
                <b-col>
                  <b-form-input class="cstm-cntrl-katalog-10" v-model="preview.user_name" placeholder="Nama User" readonly></b-form-input>
                </b-col>
              </b-row>
            </b-form-group>
          </b-form>
          <b-form v-else-if="created.index === '3'">
            <b-form-group class="mb-2">
              <b-row class="align-items-center">
                <b-col cols="2">
                  <span>
                    <p class="small text-dark mb-0">Kode</p>
                  </span>
                </b-col>
                <b-col>
                  <b-form-input class="cstm-cntrl-katalog-10" v-model="created.tambahBrand.kode" placeholder="[ Kode ]"></b-form-input>
                </b-col>
              </b-row>
            </b-form-group>
            <b-form-group class="mb-2">
              <b-row class="align-items-center">
                <b-col cols="2">
                  <span>
                    <p class="small text-dark mb-0">Generik</p>
                  </span>
                </b-col>
                <b-col>
                  <multiselect v-model="created.selected.generik" :show-labels="false" @input="valueExtendGenerik"
                    label="nama_generik" track-by="id" placeholder="[ Generik ]" :options="options.namaGenerik"
                    :searchable="true">
                  </multiselect>
                </b-col>
              </b-row>
            </b-form-group>
            <b-form-group class="mb-2">
              <b-row class="align-items-center">
                <b-col cols="2">
                  <span>
                    <p class="small text-dark mb-0">Nama Dagang</p>
                  </span>
                </b-col>
                <b-col>
                  <b-form-input class="cstm-cntrl-katalog-10" v-model="created.tambahBrand.nama_dagang" placeholder="[ Nama Brand ]"></b-form-input>
                </b-col>
              </b-row>
            </b-form-group>
            <b-form-group class="mb-0">
              <b-row class="align-items-center">
                <b-col cols="2">
                  <span>
                    <p class="small text-dark mb-0">User Update</p>
                  </span>
                </b-col>
                <b-col>
                  <b-form-input class="cstm-cntrl-katalog-10" v-model="preview.user_name" placeholder="[ Nama User ]" readonly></b-form-input>
                </b-col>
              </b-row>
            </b-form-group>
          </b-form>
          <b-form v-else-if="created.index === '4'">
            <b-form-group class="mb-2">
              <b-row class="align-items-center">
                <b-col cols="2">
                  <span>
                    <p class="small text-dark mb-0">Kode</p>
                  </span>
                </b-col>
                <b-col>
                  <b-form-input class="cstm-cntrl-katalog-10" v-model="created.tambahGenerik.kode" placeholder="[ Enter Kode ]"></b-form-input>
                </b-col>
              </b-row>
            </b-form-group>
            <b-form-group class="mb-2">
              <b-row class="align-items-center">
                <b-col cols="2">
                  <span>
                    <p class="small text-dark mb-0">Nama Generik</p>
                  </span>
                </b-col>
                <b-col>
                  <b-form-input class="cstm-cntrl-katalog-10" v-model="created.tambahGenerik.nama_generik" placeholder="[ Nama Generik ]"></b-form-input>
                </b-col>
              </b-row>
            </b-form-group>
            <b-form-group class="mb-2">
              <b-row class="align-items-center">
                <b-col cols="2">
                  <span>
                    <p class="small text-dark mb-0">Restriksi</p>
                  </span>
                </b-col>
                <b-col>
                  <b-form-input class="cstm-cntrl-katalog-10" v-model="created.tambahGenerik.restriksi" placeholder="[ Restriksi ]"></b-form-input>
                </b-col>
              </b-row>
            </b-form-group>
            <b-form-group class="mb-0">
              <b-row class="align-items-center">
                <b-col cols="2">
                  <span>
                    <p class="small text-dark mb-0">User Update</p>
                  </span>
                </b-col>
                <b-col>
                  <b-form-input class="cstm-cntrl-katalog-10" v-model="preview.user_name" placeholder="[ Nama User ]" readonly></b-form-input>
                </b-col>
              </b-row>
            </b-form-group>
          </b-form>
          <b-form v-else-if="created.index ==='5'">
            <b-form-group class="mb-2">
              <b-row class="align-items-center">
                <b-col cols="2">
                  <span>
                    <p class="small text-dark mb-0">Kode</p>
                  </span>
                </b-col>
                <b-col>
                  <b-form-input class="cstm-cntrl-katalog-10" v-model="created.tambahPabrik.kode" placeholder="[ Enter Kode ]"></b-form-input>
                </b-col>
              </b-row>
            </b-form-group>
            <b-form-group class="mb-2">
              <b-row class="align-items-center">
                <b-col cols="2">
                  <span>
                    <p class="small text-dark mb-0">NPWP</p>
                  </span>
                </b-col>
                <b-col>
                  <b-form-input class="cstm-cntrl-katalog-10" v-model="created.tambahPabrik.npwp" placeholder="[ NPWP Pabrik ]"></b-form-input>
                </b-col>
              </b-row>
            </b-form-group>
            <b-form-group class="mb-2">
              <b-row class="align-items-center">
                <b-col cols="2">
                  <span>
                    <p class="small text-dark mb-0">Nama Pabrik</p>
                  </span>
                </b-col>
                <b-col>
                  <b-form-input class="cstm-cntrl-katalog-10" v-model="created.tambahPabrik.nama_pabrik" placeholder="[ Nama Pabrik ]"></b-form-input>
                </b-col>
              </b-row>
            </b-form-group>
            <b-form-group class="mb-2">
              <b-row class="align-items-center">
                <b-col cols="2">
                  <span>
                    <p class="small text-dark mb-0">Alamat</p>
                  </span>
                </b-col>
                <b-col>
                  <b-form-input class="cstm-cntrl-katalog-10" v-model="created.tambahPabrik.alamat" placeholder="[ Alamat Pabrik ]"></b-form-input>
                </b-col>
              </b-row>
            </b-form-group>
            <b-form-group class="mb-2">
              <b-row class="align-items-center">
                <b-col cols="2">
                  <span>
                    <p class="small text-dark mb-0">Kota</p>
                  </span>
                </b-col>
                <b-col cols="6" class="pr-1">
                  <b-form-input class="cstm-cntrl-katalog-10" v-model="created.tambahPabrik.kota" placeholder="[ Kota ]"></b-form-input>
                </b-col>
                <b-col class="pl-1">
                  <b-form-input class="cstm-cntrl-katalog-10" v-model="created.tambahPabrik.kodepos" placeholder="[ Kode Pos ]"></b-form-input>
                </b-col>
              </b-row>
            </b-form-group>
            <b-form-group class="mb-2">
              <b-row class="align-items-center">
                <b-col cols="2">
                  <span>
                    <p class="small text-dark mb-0">Kontak Pabrik</p>
                  </span>
                </b-col>
                <b-col class="pr-1">
                  <b-form-input class="cstm-cntrl-katalog-10" v-model="created.tambahPabrik.telp" placeholder="[ Telepon ]"></b-form-input>
                </b-col>
                <b-col class="pr-1 pl-1">
                  <b-form-input class="cstm-cntrl-katalog-10" v-model="created.tambahPabrik.fax" placeholder="[ Fax ]"></b-form-input>
                </b-col>
                <b-col class="pl-1">
                  <b-form-input class="cstm-cntrl-katalog-10" v-model="created.tambahPabrik.email" placeholder="[ Email ]"></b-form-input>
                </b-col>
              </b-row>
            </b-form-group>
            <b-form-group class="mb-2">
              <b-row class="align-items-center">
                <b-col cols="2">
                  <span>
                    <p class="small text-dark mb-0">Nama CP</p>
                  </span>
                </b-col>
                <b-col cols="6" class="pr-1">
                  <b-form-input class="cstm-cntrl-katalog-10" v-model="created.tambahPabrik.cp_name" placeholder="[ Nama Kontak ]"></b-form-input>
                </b-col>
                <b-col cols="4" class="pl-1">
                  <b-form-input class="cstm-cntrl-katalog-10" v-model="created.tambahPabrik.cp_telp" placeholder="[ Telepon CP ]"></b-form-input>
                </b-col>
              </b-row>
            </b-form-group>
            <b-form-group class="mb-2">
              <b-row class="align-items-center">
                <b-col cols="2">
                  <span>
                    <p class="small text-dark mb-0">User Update</p>
                  </span>
                </b-col>
                <b-col>
                  <b-form-input class="cstm-cntrl-katalog-10" v-model="preview.user_name" placeholder="[ Nama User ]" readonly></b-form-input>
                </b-col>
              </b-row>
            </b-form-group>
            <b-form-group class="mb-0">
              <b-row class="align-items-center">
                <b-col cols="2">
                  <span>
                    <p class="small text-dark mb-0">Status Aktif</p>
                  </span>
                </b-col>
                <b-col>
                  <b-form-radio-group class="mb-0"
                    v-model="created.tambahPabrik.sts_aktif"
                    :options="options.optionsStsAktif"
                    ></b-form-radio-group>
                </b-col>
              </b-row>
            </b-form-group>
          </b-form>
          <b-form v-else-if="created.index ==='6'">
            <b-form-group class="mb-2">
              <b-row class="align-items-center">
                <b-col cols="2">
                  <span>
                    <p class="small text-dark mb-0">Kode</p>
                  </span>
                </b-col>
                <b-col>
                  <b-form-input class="cstm-cntrl-katalog-10" v-model="created.tambahPbf.kode" placeholder="[ Enter Kode ]"></b-form-input>
                </b-col>
              </b-row>
            </b-form-group>
            <b-form-group class="mb-2">
              <b-row class="align-items-center">
                <b-col cols="2">
                  <span>
                    <p class="small text-dark mb-0">NPWP</p>
                  </span>
                </b-col>
                <b-col>
                  <b-form-input class="cstm-cntrl-katalog-10" v-model="created.tambahPbf.npwp" placeholder="[ NPWP Pbf ]"></b-form-input>
                </b-col>
              </b-row>
            </b-form-group>
            <b-form-group class="mb-2">
              <b-row class="align-items-center">
                <b-col cols="2">
                  <span>
                    <p class="small text-dark mb-0">Nama Pbf</p>
                  </span>
                </b-col>
                <b-col>
                  <b-form-input class="cstm-cntrl-katalog-10" v-model="created.tambahPbf.nama_pbf" placeholder="[ Nama Pbf ]"></b-form-input>
                </b-col>
              </b-row>
            </b-form-group>
            <b-form-group class="mb-2">
              <b-row class="align-items-center">
                <b-col cols="2">
                  <span>
                    <p class="small text-dark mb-0">Alamat</p>
                  </span>
                </b-col>
                <b-col>
                  <b-form-input class="cstm-cntrl-katalog-10" v-model="created.tambahPbf.alamat" placeholder="[ Alamat Pbf ]"></b-form-input>
                </b-col>
              </b-row>
            </b-form-group>
            <b-form-group class="mb-2">
              <b-row class="align-items-center">
                <b-col cols="2">
                  <span>
                    <p class="small text-dark mb-0">Kota</p>
                  </span>
                </b-col>
                <b-col cols="6" class="pr-1">
                  <b-form-input class="cstm-cntrl-katalog-10" v-model="created.tambahPbf.kota" placeholder="[ Kota ]"></b-form-input>
                </b-col>
                <b-col cols="3" class="pl-1">
                  <b-form-input class="cstm-cntrl-katalog-10" v-model="created.tambahPbf.kodepos" placeholder="[ Kode Pos ]"></b-form-input>
                </b-col>
              </b-row>
            </b-form-group>
            <b-form-group class="mb-2">
              <b-row class="align-items-center">
                <b-col cols="2">
                  <span>
                    <p class="small text-dark mb-0">Kontak Pbf</p>
                  </span>
                </b-col>
                <b-col class="pr-1">
                  <b-form-input class="cstm-cntrl-katalog-10" v-model="created.tambahPbf.telp" placeholder="[ Telepon ]"></b-form-input>
                </b-col>
                <b-col class="pr-1 pl-1">
                  <b-form-input class="cstm-cntrl-katalog-10" v-model="created.tambahPbf.fax" placeholder="[ Fax ]"></b-form-input>
                </b-col>
                <b-col class="pl-1">
                  <b-form-input class="cstm-cntrl-katalog-10" v-model="created.tambahPbf.email" placeholder="[ Email ]"></b-form-input>
                </b-col>
              </b-row>
            </b-form-group>
            <b-form-group class="mb-2">
              <b-row class="align-items-center">
                <b-col cols="2">
                  <span>
                    <p class="small text-dark mb-0">Kepala Cabang</p>
                  </span>
                </b-col>
                <b-col>
                  <b-form-input class="cstm-cntrl-katalog-10" v-model="created.tambahPbf.kepala_cabang" placeholder="[ Kepala Cabang ]"></b-form-input>
                </b-col>
              </b-row>
            </b-form-group>
            <b-form-group class="mb-2">
              <b-row class="align-items-center">
                <b-col cols="2">
                  <span>
                    <p class="small text-dark mb-0">Nama CP</p>
                  </span>
                </b-col>
                <b-col cols="6" class="pr-1">
                  <b-form-input class="cstm-cntrl-katalog-10" v-model="created.tambahPbf.cp_name" placeholder="[ Nama Kontak ]"></b-form-input>
                </b-col>
                <b-col cols="4" class="pl-1">
                  <b-form-input class="cstm-cntrl-katalog-10" v-model="created.tambahPbf.cp_telp" placeholder="[ Telepon CP ]"></b-form-input>
                </b-col>
              </b-row>
            </b-form-group>
            <b-form-group class="mb-0">
              <b-row class="align-items-center">
                <b-col cols="2">
                  <span>
                    <p class="small text-dark mb-0">User Update</p>
                  </span>
                </b-col>
                <b-col>
                  <b-form-input class="cstm-cntrl-katalog-10" v-model="preview.user_name" placeholder="[ Nama User ]" readonly></b-form-input>
                </b-col>
              </b-row>
            </b-form-group>
          </b-form>
          <b-form v-else-if="created.index === '7'">
            <b-form-group class="mb-2">
              <b-row class="align-items-center">
                <b-col cols="2">
                  <span>
                    <p class="small text-dark mb-0">Kode</p>
                  </span>
                </b-col>
                <b-col>
                  <b-form-input class="cstm-cntrl-katalog-10" v-model="created.tambahDosis.kode" placeholder="[ Kode ]"></b-form-input>
                </b-col>
              </b-row>
            </b-form-group>
            <b-form-group class="mb-2">
              <b-row class="align-items-center">
                <b-col cols="2">
                  <span>
                    <p class="small text-dark mb-0">Nama</p>
                  </span>
                </b-col>
                <b-col>
                  <b-form-input class="cstm-cntrl-katalog-10" v-model="created.tambahDosis.nama" placeholder="[ Nama ]"></b-form-input>
                </b-col>
              </b-row>
            </b-form-group>
            <b-form-group class="mb-2">
              <b-row class="align-items-center">
                <b-col cols="2">
                  <span>
                    <p class="small text-dark mb-0">User In</p>
                  </span>
                </b-col>
                <b-col>
                  <b-form-input class="cstm-cntrl-katalog-10" v-model="preview.user_name" placeholder="Nama User" readonly></b-form-input>
                </b-col>
              </b-row>
            </b-form-group>
            <b-form-group class="mb-0">
              <b-row class="align-items-center">
                <b-col cols="2">
                  <span>
                    <p class="small text-dark mb-0">Status Aktif</p>
                  </span>
                </b-col>
                <b-col>
                  <b-form-radio-group class="mb-0"
                    v-model="created.tambahDosis.sts_aktif"
                    :options="options.optionsStsAktif"
                    ></b-form-radio-group>
                </b-col>
              </b-row>
            </b-form-group>
          </b-form>
          <b-form v-else-if="created.index === '8'">
            <b-form-group class="mb-2">
              <b-row class="align-items-center">
                <b-col cols="2">
                  <span>
                    <p class="small text-dark mb-0">Hdr</p>
                  </span>
                </b-col>
                <b-col>
                  <div class="d-flex flex-row">
                    <div class="select-sakti katalog-select-flex-left">
                      <multiselect v-model="created.selected.sakti" :show-labels="false" @input="valueExtendSakti"
                        label="uraian" placeholder="[ Sakti Hdr ]" :options="created.options.sakti"
                        :searchable="true">
                      </multiselect>
                    </div>
                    <div class="katalog-select-flex-right pl-2">
                      <div @click="tambahExtendFormSub('2')" class="bg-primary shadow-sm text-center d-flex align-items-center justify-content-center custom-radius-add-katalog h-100">
                        <span class="h5 mb-0">+</span>
                      </div>
                    </div>
                  </div>
                </b-col>
              </b-row>
            </b-form-group>
            <b-form-group class="mb-2">
              <b-row class="align-items-center">
                <b-col cols="2">
                  <span>
                    <p class="small text-dark mb-0">Kode</p>
                  </span>
                </b-col>
                <b-col>
                  <div>
                    <b-form-input class="cstm-cntrl-katalog-10" v-model="created.tambahSakti.kode" placeholder="[ Kode ]"></b-form-input>
                  </div>
                </b-col>
              </b-row>
            </b-form-group>
            <b-form-group class="mb-2">
              <b-row class="align-items-center">
                <b-col cols="2">
                  <span>
                    <p class="small text-dark mb-0">Uraian</p>
                  </span>
                </b-col>
                <b-col>
                  <div>
                    <b-form-input class="cstm-cntrl-katalog-10" v-model="created.tambahSakti.uraian" placeholder="[ Uraian ]"></b-form-input>
                  </div>
                </b-col>
              </b-row>
            </b-form-group>
            <b-form-group class="mb-2">
              <b-row class="align-items-center">
                <b-col cols="2">
                  <span>
                    <p class="small text-dark mb-0">Status Aktif</p>
                  </span>
                </b-col>
                <b-col>
                  <b-form-radio-group class="mb-0"
                    v-model="created.tambahSakti.sts_aktif"
                    :options="options.optionsStsAktif"
                    ></b-form-radio-group>
                </b-col>
              </b-row>
            </b-form-group>
            <b-form-group class="mb-0">
              <b-row class="align-items-center">
                <b-col cols="2">
                  <span>
                    <p class="small text-dark mb-0">User In</p>
                  </span>
                </b-col>
                <b-col>
                  <b-form-input class="cstm-cntrl-katalog-10" v-model="preview.user_name" placeholder="[ Nama User ]" readonly></b-form-input>
                </b-col>
              </b-row>
            </b-form-group>
          </b-form>
          <!-- <pre>{{ created }}</pre> -->
        </div>
      </b-modal>
      <b-modal id="modal-extend-tambah-2" ref="modal-extend-tambah-2" size="md" centered title="Tambah" ok-title="Tambah" ok-variant="primary" cancel-title="Tutup" @ok="tambahExtendSub">
        <b-form v-if="created.indexSub === '1'">
          <b-form-group class="mb-2">
            <b-row class="align-items-center">
              <b-col cols="3">
                <span>
                  <p class="small text-dark mb-0">Kode</p>
                </span>
              </b-col>
              <b-col>
                <b-form-input class="cstm-cntrl-katalog-10" v-model="created.tambahJenisAnggaran.kode" placeholder="[ Kode ]"></b-form-input>
              </b-col>
            </b-row>
          </b-form-group>
          <b-form-group class="mb-2">
            <b-row class="align-items-center">
              <b-col cols="3">
                <span>
                  <p class="small text-dark mb-0">Jenis Anggaran</p>
                </span>
              </b-col>
              <b-col>
                <b-form-input class="cstm-cntrl-katalog-10" v-model="created.tambahJenisAnggaran.jenis_anggaran" placeholder="[ Jenis Anggaran ]"></b-form-input>
              </b-col>
            </b-row>
          </b-form-group>
          <b-form-group class="mb-2">
            <b-row class="align-items-center">
              <b-col cols="3">
                <span>
                  <p class="small text-dark mb-0">User In</p>
                </span>
              </b-col>
              <b-col>
                <b-form-input class="cstm-cntrl-katalog-10" v-model="preview.user_name" placeholder="[ Nama User ]" readonly></b-form-input>
              </b-col>
            </b-row>
          </b-form-group>
          <b-form-group class="mb-0">
            <b-row class="align-items-center">
              <b-col cols="3">
                <span>
                  <p class="small text-dark mb-0">Status Aktif</p>
                </span>
              </b-col>
              <b-col>
                <b-form-radio-group class="mb-0"
                  v-model="created.tambahJenisAnggaran.sts_aktif"
                  :options="options.optionsStsAktif"
                  ></b-form-radio-group>
              </b-col>
            </b-row>
          </b-form-group>
        </b-form>
        <b-form v-else-if="created.indexSub === '2'">
          <b-form-group class="mb-2">
            <b-row class="align-items-center">
              <b-col cols="2">
                <span>
                  <p class="small text-dark mb-0">Kode</p>
                </span>
              </b-col>
              <b-col>
                <div>
                  <b-form-input class="cstm-cntrl-katalog-10" v-model="created.tambahSaktiHdr.kode" placeholder="[ Kode ]"></b-form-input>
                </div>
              </b-col>
            </b-row>
          </b-form-group>
          <b-form-group class="mb-2">
            <b-row class="align-items-center">
              <b-col cols="2">
                <span>
                  <p class="small text-dark mb-0">Uraian</p>
                </span>
              </b-col>
              <b-col>
                <div>
                  <b-form-input class="cstm-cntrl-katalog-10" v-model="created.tambahSaktiHdr.uraian" placeholder="[ Uraian ]"></b-form-input>
                </div>
              </b-col>
            </b-row>
          </b-form-group>
          <b-form-group class="mb-0">
            <b-row class="align-items-center">
              <b-col cols="2">
                <span>
                  <p class="small text-dark mb-0">User In</p>
                </span>
              </b-col>
              <b-col>
                <b-form-input class="cstm-cntrl-katalog-10" v-model="preview.user_name" placeholder="[ Nama User ]" readonly></b-form-input>
              </b-col>
            </b-row>
          </b-form-group>
          <!-- <pre>{{ created }}</pre> -->
        </b-form>
      </b-modal>
    </div>
    <!-- <pre>{{ editFarmasi }}</pre> -->
  </div>
</template>
<script>
import { xray } from '../../../config/pluginInit'
import Loader from '../../../components/xray/loader/Loader'
import jwtdecode from 'jwt-decode'
import Services from '../../../services/fatmahost/katalog/index'
import Multiselect from 'vue-multiselect'
import Lottie from 'vue-lottie'
import animationLogo from '../../../assets/lottie/checked.json'
import animationLogo2 from '../../../assets/lottie/failed.json'

export default {
  name: 'Farmasi',
  components: {
    Multiselect,
    Lottie,
    Loader
  },
  data () {
    return {
      table: {
        isBusy: false,
        items: this.list,
        sortBy: '',
        sortDesc: true,
        fields: [
          { label: 'No', key: 'index', class: 'text-center' },
          { label: 'KODE', key: 'f.kode', sortable: true },
          // { label: 'NAMA PABRIK', key: 'nama_pabrik' },
          { label: 'Nama Barang', key: 'f.nama_barang', sortable: true },
          // { label: 'Kemasan', key: 'kemasan' },
          // { label: 'Kemasan Kecil', key: 'kemasan_kecil' },
          // { label: 'Harga Beli', key: 'harga_beli' },
          { label: 'KELOMPOK BARANG', key: 'mk.kelompok_barang', sortable: true },
          { label: 'USER IN', key: 'up.name', sortable: true },
          { label: 'USER UPDATE', key: 'up1.name', sortable: true },
          { label: 'LAST UPDATE', key: 'f.sysdate_updt', sortable: true },
          { label: 'AKSI', key: 'action', class: 'text-center' }
        ],
        filter: null,
        currentPage: null,
        perPage: null,
        pageOptions: [10, 25, 50, 100],
        totalRows: null
      },
      tambahFarmasi: {
        object: 'farmasi',
        farmasi: {
          kode: '',
          nama_barang: '',
          id_brand: '',
          id_jenisbarang: '',
          id_kelompokbarang: '',
          id_kemasanbesar: '',
          id_kemasankecil: '',
          isi_kemasan: '',
          kemasan: '',
          id_pbf: '',
          // id_pabrik: '',
          harga_beli: '',
          diskon_beli: '',
          formularium_rs: '0',
          formularium_nas: '0',
          generik: '0',
          live_saving: '0',
          sts_kronis: '0',
          sts_oot: '0',
          sts_bpjs: '0',
          sts_narkotika: '0',
          sts_psikotropika: '0',
          sts_prekursor: '0',
          moving: '',
          leadtime: '',
          buffer: '',
          zat_aktif: '',
          retriksi: '',
          keterangan: '',
          kelas_terapi: '',
          resep_max_kasus: '',
          resep_max_bulan: '',
          id_kfa91: '',
          id_kfa92: '',
          // id_kfa93: '',
          id_barang_sakti: '',
          id_dosis: '',
          isi_dosis: '',
          kategori_warna: '',
          aktifasi: '0',
          userid_in: jwtdecode(localStorage.getItem('token')).user.id.toString()
        },
        pabrik: [
          {
            id_pabrik: '',
            id_kfa93: '',
            no_urut: '0',
            sts_aktif: '0',
            created_by: jwtdecode(localStorage.getItem('token')).user.id.toString()
          }
        ]
      },
      editFarmasi: {
        object: 'farmasi',
        farmasi: {
          kode: '',
          nama_barang: '',
          id_brand: '',
          id_jenisbarang: '',
          id_kelompokbarang: '',
          id_kemasanbesar: '',
          id_kemasankecil: '',
          isi_kemasan: '',
          kemasan: '',
          id_pbf: '',
          // id_pabrik: '',
          harga_beli: '',
          diskon_beli: '',
          formularium_rs: '0',
          formularium_nas: '0',
          generik: '0',
          live_saving: '0',
          sts_kronis: '0',
          sts_oot: '0',
          sts_bpjs: '0',
          sts_narkotika: '0',
          sts_psikotropika: '0',
          sts_prekursor: '0',
          moving: '',
          leadtime: '',
          buffer: '',
          zat_aktif: '',
          retriksi: '',
          keterangan: '',
          kelas_terapi: '',
          resep_max_kasus: '',
          resep_max_bulan: '',
          id_kfa91: '',
          id_kfa92: '',
          // id_kfa93: '',
          id_barang_sakti: '',
          id_dosis: '',
          isi_dosis: '',
          kategori_warna: '',
          aktifasi: '0',
          userid_in: '',
          sysdate_in: '',
          userid_updt: jwtdecode(localStorage.getItem('token')).user.id.toString()
        },
        pabrik: [
          {
            id_pabrik: '',
            id_kfa93: '',
            no_urut: '0',
            sts_aktif: '0',
            updated_by: jwtdecode(localStorage.getItem('token')).user.id.toString(),
            id_transmedik: '',
            sts_opname: ''
          }
        ]
      },
      created: {
        index: '',
        indexSub: '',
        selected: {
          subjenisanggaran: [],
          sakti: [],
          hardcode: [],
          kodesimrsgos: [],
          generik: [],
          inventori: []
        },
        options: {
          subjenisanggaran: [],
          sakti: [],
          hardcode: [],
          kodesimrsgos: [],
          generik: [],
          inventori: []
        },
        tambahJenisAnggaran: {
          object: 'jenis-anggaran',
          kode: '',
          jenis_anggaran: '',
          sts_aktif: '1',
          userid_in: jwtdecode(localStorage.getItem('token')).user.id.toString()
        },
        subjenisanggaran: {
          object: 'sub-jenis-anggaran',
          id_jenis: '',
          thn_aktif: '',
          kode: '',
          subjenis_anggaran: '',
          keterangan: '',
          sts_aktif: '1',
          userid_in: jwtdecode(localStorage.getItem('token')).user.id.toString()
        },
        tambahKelompok: {
          object: 'kelompok',
          id_inventory: '',
          id_hardcode: '0',
          id_kategori_simrsgos: '',
          kode: '',
          kelompok_barang: '',
          kode_temp: '',
          no_urut: '',
          gol: '',
          bid: '',
          kel: '',
          subkel: '',
          subsubkel: '',
          sts_aktif: '1',
          userid_updt: jwtdecode(localStorage.getItem('token')).user.id.toString()
        },
        tambahBrand: {
          object: 'brand',
          kode: '',
          id_generik: '',
          nama_dagang: '',
          sts_aktif: '1',
          userid_updt: jwtdecode(localStorage.getItem('token')).user.id.toString()
        },
        tambahGenerik: {
          object: 'generik',
          kode: '',
          nama_generik: '',
          restriksi: '',
          userid_updt: jwtdecode(localStorage.getItem('token')).user.id.toString()
        },
        tambahPabrik: {
          object: 'pabrik',
          kode: '',
          nama_pabrik: '',
          npwp: '',
          alamat: '',
          kota: '',
          kodepos: '',
          telp: '',
          fax: '',
          email: '',
          cp_name: '',
          cp_telp: '',
          sts_aktif: '1',
          userid_updt: jwtdecode(localStorage.getItem('token')).user.id.toString()
        },
        tambahPbf: {
          object: 'pbf',
          kode: '',
          nama_pbf: '',
          npwp: '',
          alamat: '',
          kota: '',
          kodepos: '',
          telp: '',
          fax: '',
          email: '',
          cp_name: '',
          cp_telp: '',
          kepala_cabang: '',
          userid_updt: jwtdecode(localStorage.getItem('token')).user.id.toString()
        },
        tambahDosis: {
          object: 'dosis',
          kode: '',
          nama: '',
          sts_aktif: '1',
          userid_in: jwtdecode(localStorage.getItem('token')).user.id.toString()
        },
        tambahSakti: {
          object: 'sakti',
          id_hdr: '',
          kode: '',
          uraian: '',
          sts_aktif: '2',
          userid_in: jwtdecode(localStorage.getItem('token')).user.id.toString()
        },
        tambahSaktiHdr: {
          object: 'sakti-hdr',
          kode: '',
          uraian: '',
          userid_in: jwtdecode(localStorage.getItem('token')).user.id.toString()
        }
      },
      options: {
        optionsStsAktif: [
          { text: 'Aktif', value: '1' },
          { text: 'Tidak Aktif', value: '2' }
        ],
        optionsFilter: [
          { text: 'Kode', value: '1' },
          // { text: 'Nama Pabrik', value: '2' },
          { text: 'Nama Barang', value: '3' }
        ],
        deathMoving: [
          { name: 'Death Moving', id: 'DM' },
          { name: 'Small Moving', id: 'SM' },
          { name: 'Medium Moving', id: 'MM' },
          { name: 'Fast Moving', id: 'FM' }
        ],
        namaJenisBarang: [],
        kelompokBarang: [],
        namaDagang: [],
        namaGenerik: [],
        Pabrik: [],
        Pbf: [],
        satuanKemasanBesar: [],
        satuanKemasanKecil: [],
        sediaanKemasan: [],
        formularium: {
          nasional: '',
          rsupf: ''
        },
        kfa91: [],
        kfa92: [],
        kfa93: [],
        sakti: [],
        dosis: [],
        editNamaJenisBarang: [],
        editKelompokBarang: [],
        editNamaDagang: [],
        editNamaGenerik: [],
        editPabrik: [],
        editPbf: [],
        editSatuanKemasanBesar: [],
        editSatuanKemasanKecil: [],
        editSediaanKemasan: [],
        editFormularium: {
          nasional: '',
          rsupf: ''
        },
        editKfa91: [],
        editKfa92: [],
        editKfa93: [],
        editSakti: [],
        editDosis: [],
        kategoriObat: [
          { text: 'Obat Live Saving', value: '1' },
          { text: 'Obat Generik', value: '2' },
          { text: 'Obat Kronis', value: '3' }
        ],
        aktivasiBarang: [
          { text: 'Aktif', value: '1' },
          { text: 'Tidak Aktif', value: '0' }
        ],
        editKategoriObat: [
          { text: 'Obat Live Saving', value: '1' },
          { text: 'Obat Generik', value: '2' },
          { text: 'Obat Kronis', value: '3' }
        ],
        editAktivasiBarang: [
          { text: 'Aktif', value: '1' },
          { text: 'Tidak Aktif', value: '0' }
        ],
        editDeathMoving: [
          { name: 'Death Moving', id: 'DM' },
          { name: 'Small Moving', id: 'SM' },
          { name: 'Medium Moving', id: 'MM' },
          { name: 'Fast Moving', id: 'FM' }
        ],
        kategoriWarna: [
          { id: '1', name: 'Access (hijau)' },
          { id: '2', name: 'Watch (kuning)' },
          { id: '3', name: 'Reserve (merah)' }
        ]
      },
      selected: {
        filter: '1',
        namaJenisBarang: [],
        kelompokBarang: [],
        namaDagang: [],
        namaGenerik: [],
        Pabrik: [],
        Pbf: [],
        satuanKemasanBesar: [],
        satuanKemasanKecil: [],
        sediaanKemasan: [],
        deathMoving: [],
        formularium: [],
        kategoriObat: [],
        aktivasiBarang: [],
        kfa91: [],
        kfa92: [],
        kfa93: [],
        sakti: [],
        dosis: [],
        editNamaJenisBarang: [],
        editKelompokBarang: [],
        editNamaDagang: [],
        editNamaGenerik: [],
        editPabrik: [],
        editPbf: [],
        editSatuanKemasanBesar: [],
        editSatuanKemasanKecil: [],
        editSediaanKemasan: [],
        editDeathMoving: [],
        editFormularium: [],
        editKategoriObat: [],
        editAktivasiBarang: [],
        editKfa91: [],
        editKfa92: [],
        editKfa93: [],
        editSakti: [],
        editDosis: [],
        kategoriWarna: [],
        editkategoriWarna: []
      },
      bitControl: {
        bitcontrol1: '',
        bitcontrol2: ''
      },
      preview: {
        isLoading: false,
        lottie: {
          defaultOptions: { animationData: animationLogo, loop: true },
          defaultOptions2: { animationData: animationLogo2, loop: true }
        },
        activeModal: '',
        user_name: jwtdecode(localStorage.getItem('token')).user.name,
        user_in: '',
        detail_id: '',
        edit_id: '',
        delete_id: '',
        message: '',
        messageValue: '',
        satuanKemasanBesar: '',
        satuanKemasanKecil: '',
        dosis: '',
        isi_dosis: '',
        isi_kemasan: '',
        rupiah: '',
        diskon: '',
        buffer: '',
        editSatuanKemasanBesar: '',
        editSatuanKemasanKecil: '',
        editDosis: '',
        edit_isi_dosis: '',
        editIsi_kemasan: '',
        editRupiah: '',
        editDiskon: '',
        editBuffer: '',
        viewData: {},
        selected: {
          sts_aktif: '0',
          formularium_nas: '0',
          formularium_rs: '0',
          livesaving: '',
          generik: '',
          kronis: '',
          sts_oot: '0',
          sts_bpjs: '0',
          sts_narkotika: '0',
          sts_psikotropika: '0',
          sts_prekursor: '0'
        },
        options: {
          sts_aktif: [
            { value: '1', text: 'Aktif' },
            { value: '0', text: 'Tidak Aktif' }
          ]
        }
      },
      dummy: {
        selected: {
          satuSehat: [],
          pabrik: [{
            detail: []
          }],
          editpabrik: [{
            detail: []
          }],
          kfa93: [{
            detail: []
          }],
          editkfa93: [{
            detail: []
          }]
        },
        options: {
          satuSehat: [],
          pabrik: [],
          editpabrik: []
        },
        editKodeBrg: {
          object: 'farmasi-kode-katalog',
          kode: '',
          userid_in: jwtdecode(localStorage.getItem('token')).user.id.toString()
        },
        kodeBaru: '',
        msgValidasiKd: '',
        msgValidasiKd2: '',
        verifikasiAkutansi: '',
        kodeSakti: '',
        conditionKatObt: false,
        conditionKatObtEdit: false,
        conditionGenerik: false,
        disabledField: true,
        disableStsAktif: true,
        disableSakti: true,
        disablePabrik: true,
        aksesTambah: false,
        aksesSakti: false,
        aksesEdit: false,
        aksesShow: false,
        aksesDelete: false,
        simpankatalog: true,
        sts_pabrik: 0,
        sts_akhir: '0',
        dataGroup: ''
      },
      link: {
        kfa91: '',
        kfa92: '',
        kfa93: [{
          url: ''
        }],
        editkfa93: [{
          url: ''
        }]
      }
    }
  },
  mounted () {
    xray.index()
    this.checkBitControl()
    this.getSelectize()
  },
  methods: {
    getSelectize () {
      this.getJenisBarang()
      this.getKelompokBarang()
      this.getNamaDagang()
      this.getNamaGenerik()
      this.getNamaPabrik()
      this.getNamaPbf()
      this.getSatuanKemasan()
      this.getKfa91()
      this.getKfa92()
      this.getKfa93()
      this.getSakti()
      this.getDosis()
      this.kategoriGet()
      this.hardCodeGet()
      this.kelinventoryGet()
    },
    checkBitControl () {
      const data = xray.bitcontrol(this.$route.name)
      const dataGroup = xray.currentUser().id_grp
      this.bitControl.bitcontrol1 = data.bitcontrol1
      this.bitControl.bitcontrol2 = data.bitcontrol2
      this.dummy.dataGroup = dataGroup
      if (data) {
        let a = this.bitControl.bitcontrol1
        let b = this.bitControl.bitcontrol2
        if (a) {
          if (a.charAt(2) === '1' || dataGroup === 1) {
            this.dummy.aksesTambah = true
            this.dummy.simpankatalog = false
          }
          if (a.charAt(3) === '1' || dataGroup === 1) {
            this.dummy.aksesShow = true
          }
          if (a.charAt(4) === '1' || dataGroup === 1) {
            this.dummy.aksesEdit = true
            this.dummy.simpankatalog = false
          }
          if (a.charAt(5) === '1' || dataGroup === 1) {
            this.dummy.aksesDelete = true
          }
        }
        if (b) {
          if (b.charAt(0) === '0' || dataGroup === 1) {
            this.dummy.disabledField = true
            this.dummy.disableStsAktif = true
            this.dummy.disableSakti = true
          }
          if (b.charAt(0) === '1' || dataGroup === 1) {
            this.dummy.disabledField = false
            this.dummy.disableStsAktif = true
            this.dummy.disableSakti = true
          }
          if (b.charAt(0) === '2' || dataGroup === 1) {
            this.dummy.disabledField = true
            this.dummy.disableStsAktif = true
            this.dummy.disableSakti = false
            this.dummy.aksesSakti = true
          }
          if (b.charAt(0) === '3' || dataGroup === 1) {
            this.dummy.disabledField = false
            this.dummy.disableStsAktif = false
            this.dummy.disableSakti = false
            this.dummy.disablePabrik = false
          }
          if (b.charAt(0) === '5' || dataGroup === 1) {
            this.dummy.disabledField = false
            this.dummy.disableStsAktif = false
            this.dummy.disableSakti = false
            this.dummy.disablePabrik = false
          }
        }
      } else {
        this.bitControl.bitcontrol1 = ''
        this.bitControl.bitcontrol2 = ''
      }
    },
    modalEditKode () {
      if (this.editFarmasi.farmasi.nama_barang && this.editFarmasi.farmasi.id_kelompokbarang) {
        this.suggestKodeEdit()
      }
      this.$refs['modal-editkode-farmasi'].show()
    },
    suggsetNmBrg () {
      this.suggestKode()
    },
    async suggestKode () {
      this.dummy.msgValidasiKd = ''
      let xparam0 = this.tambahFarmasi.farmasi.id_kelompokbarang
      let xparam1 = this.tambahFarmasi.farmasi.nama_barang.replace(/%/g, '')
      if (xparam0 !== '' && xparam1 !== '') {
        try {
          const response = await Services.suggest(xparam0, xparam1)
          this.tambahFarmasi.farmasi.kode = response.data.kode ? response.data.kode : ''
          this.validasiKode(this.tambahFarmasi.farmasi.kode)
        } catch (error) {
          this.preview.message = error.message
          this.preview.messageValue = '2'
        }
      }
    },
    validasiKdBrg () {
      this.dummy.msgValidasiKd = ''
      this.validasiKode()
    },
    async validasiKode (value) {
      let xparam0 = value || this.tambahFarmasi.farmasi.kode
      let xparam1 = '0'
      try {
        const response = await Services.validasi(xparam0, xparam1)
        this.dummy.msgValidasiKd = response.data
        if (response.data.statcode === 0) {
          this.dummy.simpankatalog = true
        } else if (response.data.statcode === 20001) {
          this.dummy.simpankatalog = false
        }
      } catch (error) {
        this.preview.message = error.message
        this.preview.messageValue = '2'
      }
    },
    suggsetNmBrgEdit () {
      // this.suggestKodeEdit()
    },
    async suggestKodeEdit () {
      this.dummy.msgValidasiKd = ''
      let xparam0 = this.editFarmasi.farmasi.id_kelompokbarang
      let xparam1 = this.editFarmasi.farmasi.nama_barang.replace(/%/g, '')
      if (xparam0 !== '' && xparam1 !== '') {
        try {
          const response = await Services.suggest(xparam0, xparam1)
          // this.editFarmasi.farmasi.kode = response.data.kode ? response.data.kode : ''
          this.validasiKodeEdit(this.editFarmasi.farmasi.kode)
          if (response.data.kode !== this.editFarmasi.kode) {
            this.dummy.editKodeBrg.kode = response.data.kode ? response.data.kode : ''
            this.validasiKdBaru()
          }
        } catch (error) {
          this.preview.message = error.message
          this.preview.messageValue = '2'
        }
      }
    },
    validasiKdBrgEdit () {
      this.dummy.msgValidasiKd = ''
      this.validasiKodeEdit()
    },
    async simpanEditKodeBaru () {
      let id = this.preview.edit_id.toString()
      let data = this.dummy.editKodeBrg
      try {
        const response = await Services.editKodeFarmasi(id, data)
        if (response.data.rowcount === 1 && response.data.statcode === 0) {
          this.preview.message = response.data.message
          this.preview.messageValue = '1'
        } else if (response.data.rowcount === 0 && response.data.statcode === 20001) {
          this.preview.message = response.data.message
          this.preview.messageValue = '2'
        }
        this.$refs['modal-notif-farmasi'].show()
        this.refreshKode()
        this.$refs.table.refresh()
      } catch (error) {
        this.preview.message = error.message
        this.preview.messageValue = '2'
        this.$refs['modal-notif-farmasi'].show()
        this.refreshKode()
        this.$refs.table.refresh()
      }
    },
    async refreshKode () {
      let id = this.preview.edit_id.toString()
      try {
        const response = await Services.showFarmasi(id)
        this.editFarmasi.farmasi.kode = response.data.data[0].kode ? response.data.data[0].kode : ''
      } catch (error) {
        this.preview.message = error.message
        this.preview.messageValue = '2'
      }
    },
    async validasiKodeEdit (value) {
      this.dummy.msgValidasiKd = ''
      let xparam0 = value || this.editFarmasi.farmasi.kode
      let xparam1 = this.preview.edit_id.toString()
      try {
        const response = await Services.validasi(xparam0, xparam1)
        this.dummy.msgValidasiKd = response.data
        if (response.data.statcode === 0) {
          this.dummy.simpankatalog = true
        } else if (response.data.statcode === 20001) {
          this.dummy.simpankatalog = false
        }
      } catch (error) {
        this.preview.message = error.message
        this.preview.messageValue = '2'
      }
    },
    async validasiKdBaru () {
      this.dummy.msgValidasiKd2 = ''
      let xparam0 = this.dummy.editKodeBrg.kode
      let xparam1 = this.preview.edit_id.toString()
      try {
        const response = await Services.validasi(xparam0, xparam1)
        this.dummy.msgValidasiKd2 = response.data
      } catch (error) {
        this.preview.message = error.message
        this.preview.messageValue = '2'
      }
    },
    addRow () {
      this.tambahFarmasi.pabrik.push(
        {
          id_pabrik: '',
          id_kfa93: '',
          no_urut: '0',
          sts_aktif: '0',
          created_by: jwtdecode(localStorage.getItem('token')).user.id.toString()
        }
      )
      this.dummy.selected.pabrik.push(
        {
          detail: []
        }
      )
      this.dummy.selected.kfa93.push(
        {
          detail: []
        }
      )
      this.link.kfa93.push(
        {
          url: ''
        }
      )
    },
    addRowEdit () {
      this.editFarmasi.pabrik.push(
        {
          id_pabrik: '',
          id_kfa93: '',
          no_urut: '0',
          sts_aktif: this.dummy.sts_akhir,
          updated_by: jwtdecode(localStorage.getItem('token')).user.id.toString(),
          id_transmedik: '',
          sts_opname: ''
        }
      )
      this.dummy.selected.editpabrik.push(
        {
          detail: []
        }
      )
      this.dummy.selected.editkfa93.push(
        {
          detail: []
        }
      )
      this.link.editkfa93.push(
        {
          url: ''
        }
      )
    },
    deleteRow (index) {
      this.tambahFarmasi.pabrik.splice(index, 1)
      this.dummy.selected.pabrik.splice(index, 1)
      this.dummy.selected.kfa93.splice(index, 1)
      this.link.kfa93.splice(index, 1)
    },
    deleteRowEdit (index) {
      this.editFarmasi.pabrik.splice(index, 1)
      this.dummy.selected.editpabrik.splice(index, 1)
      this.dummy.selected.editkfa93.splice(index, 1)
      this.link.editkfa93.splice(index, 1)
    },
    clearArr () {
      const arr1 = this.editFarmasi.pabrik
      const arr2 = this.dummy.selected.editpabrik
      const arr3 = this.dummy.selected.editkfa93
      const arr4 = this.link.editkfa93
      const arrDump1 = arr1.splice(0, arr1.length)
      const arrDump2 = arr2.splice(0, arr2.length)
      const arrDump3 = arr3.splice(0, arr3.length)
      const arrDump4 = arr4.splice(0, arr4.length)
      if (arrDump1 && arrDump2 && arrDump3 && arrDump4) {
        this.editFarmasi.pabrik.push(
          {
            id_pabrik: '',
            id_kfa93: '',
            no_urut: '0',
            sts_aktif: '0',
            updated_by: jwtdecode(localStorage.getItem('token')).user.id.toString(),
            id_transmedik: '',
            sts_opname: ''
          }
        )
        this.dummy.selected.editpabrik.push(
          {
            detail: []
          }
        )
        this.dummy.selected.editkfa93.push(
          {
            detail: []
          }
        )
        this.link.editkfa93.push(
          {
            url: ''
          }
        )
      }
      this.dummy.conditionKatObt = false
      this.dummy.conditionGenerik = false
      this.dummy.msgValidasiKd = ''
      this.dummy.editKodeBrg.kode = ''
      this.dummy.msgValidasiKd2 = ''
      this.link.kfa91 = ''
      this.link.kfa92 = ''
    },
    clearForm () {
      // form Tambah Clear
      this.tambahFarmasi.farmasi.kode = ''
      this.tambahFarmasi.farmasi.nama_barang = ''
      this.tambahFarmasi.farmasi.id_brand = ''
      this.tambahFarmasi.farmasi.id_jenisbarang = ''
      this.tambahFarmasi.farmasi.id_kelompokbarang = ''
      this.tambahFarmasi.farmasi.id_kemasanbesar = ''
      this.tambahFarmasi.farmasi.id_kemasankecil = ''
      this.tambahFarmasi.farmasi.isi_kemasan = ''
      this.tambahFarmasi.farmasi.kemasan = ''
      this.tambahFarmasi.farmasi.id_pbf = ''
      this.tambahFarmasi.farmasi.harga_beli = ''
      this.tambahFarmasi.farmasi.diskon_beli = ''
      this.tambahFarmasi.farmasi.formularium_rs = '0'
      this.tambahFarmasi.farmasi.formularium_nas = '0'
      this.tambahFarmasi.farmasi.generik = '0'
      this.tambahFarmasi.farmasi.live_saving = '0'
      this.tambahFarmasi.farmasi.sts_kronis = '0'
      this.tambahFarmasi.farmasi.moving = ''
      this.tambahFarmasi.farmasi.leadtime = ''
      this.tambahFarmasi.farmasi.buffer = ''
      this.tambahFarmasi.farmasi.zat_aktif = ''
      this.tambahFarmasi.farmasi.retriksi = ''
      this.tambahFarmasi.farmasi.keterangan = ''
      this.tambahFarmasi.farmasi.id_kfa91 = ''
      this.tambahFarmasi.farmasi.id_kfa92 = ''
      this.tambahFarmasi.farmasi.id_barang_sakti = ''
      this.tambahFarmasi.farmasi.id_dosis = ''
      this.tambahFarmasi.farmasi.isi_dosis = ''
      this.tambahFarmasi.farmasi.kategori_warna = ''
      this.tambahFarmasi.farmasi.aktifasi = '0'
      // Selectize Clear
      this.selected.namaJenisBarang = []
      this.selected.kelompokBarang = []
      this.selected.namaDagang = []
      this.selected.namaGenerik = []
      this.selected.Pabrik = []
      this.selected.pbf = []
      this.selected.satuanKemasanBesar = []
      this.selected.satuanKemasanKecil = []
      this.selected.dosis = []
      this.selected.kfa91 = []
      this.selected.kfa92 = []
      this.selected.sakti = []
      this.selected.deathMoving = []
      this.selected.kategoriWarna = []
      // Preview Clear
      this.preview.isi_kemasan = ''
      this.preview.isi_dosis = ''
      this.preview.rupiah = ''
      this.preview.diskon = ''
      this.preview.buffer = ''
      // Validasi Clear
      this.dummy.msgValidasiKd = ''
      this.dummy.conditionKatObt = false
      // pabrik
      const arr1 = this.tambahFarmasi.pabrik
      const arr2 = this.dummy.selected.pabrik
      const arr3 = this.dummy.selected.kfa93
      const arr4 = this.link.kfa93
      const arrDump1 = arr1.splice(0, arr1.length)
      const arrDump2 = arr2.splice(0, arr2.length)
      const arrDump3 = arr3.splice(0, arr3.length)
      const arrDump4 = arr4.splice(0, arr4.length)
      if (arrDump1 && arrDump2 && arrDump3 && arrDump4) {
        this.tambahFarmasi.pabrik.push(
          {
            id_pabrik: '',
            id_kfa93: '',
            no_urut: '0',
            sts_aktif: '0',
            created_by: jwtdecode(localStorage.getItem('token')).user.id.toString()
          }
        )
        this.dummy.selected.pabrik.push(
          {
            detail: []
          }
        )
        this.dummy.selected.kfa93.push(
          {
            detail: []
          }
        )
        this.link.kfa93.push(
          {
            url: ''
          }
        )
      }
    },
    valueGenerik (value) {
      if (value === '1') {
        this.dummy.conditionGenerik = true
      } else {
        this.dummy.conditionGenerik = false
      }
    },
    valueGenerikEdit (value) {
      if (value === '1') {
        this.dummy.conditionGenerik = true
      } else {
        this.dummy.conditionGenerik = false
      }
    },
    valuePabrik ($event, index) {
      this.tambahFarmasi.pabrik[index].id_pabrik = $event.id.toString()
    },
    valuePabrikEdit ($event, index) {
      this.editFarmasi.pabrik[index].id_pabrik = $event.id.toString()
    },
    valueKfa3 ($event, index) {
      this.tambahFarmasi.pabrik[index].id_kfa93 = $event.id.toString()
      this.link.kfa93[index].url = $event.url
    },
    valueEditKfa3 ($event, index) {
      this.editFarmasi.pabrik[index].id_kfa93 = $event.id.toString()
      this.link.editkfa93[index].url = $event.url
    },
    tableRefresh () {
      this.$refs.table.refresh()
    },
    isNumberKey (evt) {
      evt = (evt) || window.event
      var charCode = (evt.which) ? evt.which : evt.keyCode
      if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
        evt.preventDefault()
      } else {
        return true
      }
    },
    tambahExtendForm (value) {
      this.created.index = value
      if (value === '1') {
        this.getjenisAnggaran()
      } else if (value === '8') {
        this.saktiHdrGet()
      }
      this.$refs['modal-extend-tambah'].show()
    },
    tambahExtendFormSub (value) {
      this.created.indexSub = value
      this.$refs['modal-extend-tambah-2'].show()
    },
    //  value for tambahFarmasi  ---start here---
    valueKfa1 (param) {
      this.tambahFarmasi.farmasi.id_kfa91 = param.id.toString()
      this.link.kfa91 = param.url
    },
    valueKfa2 (param) {
      this.tambahFarmasi.farmasi.id_kfa92 = param.id.toString()
      this.link.kfa92 = param.url
    },
    valueSakti (param) {
      this.tambahFarmasi.farmasi.id_barang_sakti = param.id.toString()
    },
    valueEditSakti (param) {
      this.editFarmasi.farmasi.id_barang_sakti = param.id.toString()
    },
    valueJenisBarang (param) {
      this.tambahFarmasi.farmasi.id_jenisbarang = param.id.toString()
      // this.tambahFarmasi.farmasi.nama_barang = param.subjenis_anggaran
    },
    valueKelompokBarang (param) {
      this.tambahFarmasi.farmasi.id_kelompokbarang = param.id.toString()
      if (param.id_hardcode === 1) {
        this.dummy.conditionKatObt = true
      } else {
        this.dummy.conditionKatObt = false
        // this.tambahFarmasi.pabrik[0].id_pabrik = '0'
      }
      this.suggestKode()
    },
    valueNamaDagang (param) {
      this.tambahFarmasi.farmasi.id_brand = param.id.toString()
    },
    // valuePabrik (param) {
    //   this.tambahFarmasi.farmasi.id_pabrik = param.id.toString()
    // },
    valuePbf (param) {
      this.tambahFarmasi.farmasi.id_pbf = param.id.toString()
    },
    valueKemasanBesar (param) {
      this.preview.satuanKemasanBesar = param.kode
      this.tambahFarmasi.farmasi.id_kemasanbesar = param.id.toString()
      this.inputKemasanBarang()
    },
    valueKemasanKecil (param) {
      this.preview.satuanKemasanKecil = param.kode
      this.tambahFarmasi.farmasi.id_kemasankecil = param.id.toString()
      this.inputKemasanBarang()
    },
    valueIsiKemasan (param) {
      this.preview.isi_kemasan = param
      this.tambahFarmasi.farmasi.isi_kemasan = param.toString().replace(/,/g, '.')
      this.inputKemasanBarang()
    },
    valueDosis (param) {
      this.preview.dosis = param.kode
      this.tambahFarmasi.farmasi.id_dosis = param.id.toString()
      this.inputKemasanBarang()
    },
    valueIsiDosis (param) {
      this.preview.isi_dosis = param
      this.tambahFarmasi.farmasi.isi_dosis = param.toString().replace(/,/g, '.')
      this.inputKemasanBarang()
    },
    valueKategoriWarna (param) {
      this.tambahFarmasi.farmasi.kategori_warna = param.id
    },
    valueEditKategoriWarna (param) {
      this.editFarmasi.farmasi.kategori_warna = param.id
    },
    inputKemasanBarang () {
      let a = this.preview.satuanKemasanBesar !== '' ? this.preview.satuanKemasanBesar : ''
      let b = this.preview.satuanKemasanKecil !== '' ? this.preview.satuanKemasanKecil : ''
      let c = this.preview.isi_kemasan !== '' ? this.preview.isi_kemasan : ''
      let d = this.preview.dosis !== '' ? this.preview.dosis : ''
      let e = this.preview.isi_dosis !== '' ? this.preview.isi_dosis : ''
      this.tambahFarmasi.farmasi.kemasan = a + ' ' + c + ' ' + b + ' ' + d + ' ' + e
    },
    formatRupiah (value) {
      this.formatted(value, 1, 'created')
    },
    formatDiskon (value) {
      this.formatted(value, 2, 'created')
    },
    formatBuffer (value) {
      this.formatted(value, 3, 'created')
    },
    valuemoving (param) {
      this.tambahFarmasi.farmasi.moving = param.id
    },
    //  value for tambahFarmasi.farmasi  ---end here---
    //  value for editFarmasi.farmasi  ---start here---
    valueEditKfa1 (param) {
      this.editFarmasi.farmasi.id_kfa91 = param.id.toString()
      this.link.kfa91 = param.url
    },
    valueEditKfa2 (param) {
      this.editFarmasi.farmasi.id_kfa92 = param.id.toString()
      this.link.kfa92 = param.url
    },
    valueEditJenisBarang (param) {
      this.editFarmasi.farmasi.id_jenisbarang = param.id.toString()
      // this.editFarmasi.farmasi.nama_barang = param.subjenis_anggaran
    },
    valueEditKelompokBarang (param) {
      this.editFarmasi.farmasi.id_kelompokbarang = param.id.toString()
      if (param.id_hardcode === 1) {
        this.dummy.conditionKatObtEdit = true
      } else {
        this.dummy.conditionKatObtEdit = false
        // this.editFarmasi.pabrik[0].id_pabrik = '0'
      }
      // this.suggestKodeEdit()
    },
    valueEditKelompokBarangRspn (param) {
      if (param === '1') {
        this.dummy.conditionKatObtEdit = true
      } else {
        this.dummy.conditionKatObtEdit = false
      }
    },
    valueEditNamaDagang (param) {
      this.editFarmasi.farmasi.id_brand = param.id.toString()
    },
    valueEditPabrik (param) {
      this.editFarmasi.farmasi.id_pabrik = param.id.toString()
    },
    valueEditPbf (param) {
      this.editFarmasi.farmasi.id_pbf = param.id.toString()
    },
    valueEditKemasanBesar (param) {
      this.preview.editSatuanKemasanBesar = param.kode
      this.editFarmasi.farmasi.id_kemasanbesar = param.id.toString()
      this.inputEditKemasanBarang()
    },
    valueEditKemasanKecil (param) {
      this.preview.editSatuanKemasanKecil = param.kode
      this.editFarmasi.farmasi.id_kemasankecil = param.id.toString()
      this.inputEditKemasanBarang()
    },
    valueEditIsiKemasan (param) {
      this.preview.editIsi_kemasan = param
      this.editFarmasi.farmasi.isi_kemasan = param.toString().replace(/,/g, '.')
      this.inputEditKemasanBarang()
    },
    valueEditDosis (param) {
      this.preview.editDosis = param.kode
      this.editFarmasi.farmasi.id_dosis = param.id.toString()
      this.inputEditKemasanBarang()
    },
    valueEditIsiDosis (param) {
      this.preview.edit_isi_dosis = param
      this.editFarmasi.farmasi.isi_dosis = param.toString().replace(/,/g, '.')
      this.inputEditKemasanBarang()
    },
    inputEditKemasanBarang () {
      let a = this.preview.editSatuanKemasanBesar !== '' ? this.preview.editSatuanKemasanBesar : ''
      let b = this.preview.editSatuanKemasanKecil !== '' ? this.preview.editSatuanKemasanKecil : ''
      let c = this.preview.editIsi_kemasan !== '' ? this.preview.editIsi_kemasan : ''
      let d = this.preview.editDosis !== '' ? this.preview.editDosis : ''
      let e = this.preview.edit_isi_dosis !== '' ? this.preview.edit_isi_dosis : ''
      this.editFarmasi.farmasi.kemasan = a + ' ' + c + ' ' + b + ' ' + d + ' ' + e
    },
    formatEditRupiah (value) {
      this.formatted(value, 1, 'edit')
    },
    formatEditDiskon (value) {
      this.formatted(value, 2, 'edit')
    },
    formatEditBuffer (value) {
      this.formatted(value, 3, 'edit')
    },
    valueeditmoving (param) {
      this.editFarmasi.farmasi.moving = param.id
    },
    sliceEditIsiKmsn (param) {
      let a = param
      if (a.slice(-2) === '00') {
        this.editFarmasi.farmasi.isi_kemasan = param !== null ? param.split('.')[0] : ''
        this.preview.editIsi_kemasan = param !== null ? param.split('.')[0] : ''
      } else {
        this.editFarmasi.farmasi.isi_kemasan = param !== null ? param : ''
        this.preview.editIsi_kemasan = param !== null ? param : ''
      }
    },
    //  value for editFarmasi.farmasi  ---end here---
    formatted (value, param, condition) {
      if (param === 1) {
        // let numberString = value.replace(/[^,\d]/g, '').toString()
        // let split = numberString.split(',')
        // let sisa = split[0].length % 3
        // let rupiah = split[0].substr(0, sisa)
        // let ribuan = split[0].substr(sisa).match(/\d{3}/gi)
        // if (ribuan) {
        //   let separator = sisa ? '.' : ''
        //   rupiah += separator + ribuan.join('.')
        // }
        // rupiah = split[1] !== undefined ? rupiah + ',' + split[1] : rupiah
        // new money format -->
        let conv = value.replace(/[^0-9.]/g, '')
        let parts = conv.split('.')
        let integerPart = parts[0]
        let decimalPart = parts[1] || ''
        integerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
        decimalPart = decimalPart.substring(0, 3)
        let rupiah = integerPart + (decimalPart.length > 0 ? '.' : '') + decimalPart
        // end new money format -->
        if (condition === 'created') {
          this.preview.rupiah = rupiah
          this.tambahFarmasi.farmasi.harga_beli = rupiah.replace(/[.,\s]/g, '')
        } else if (condition === 'edit') {
          this.preview.editRupiah = rupiah
          this.editFarmasi.farmasi.harga_beli = rupiah.replace(/[.,\s]/g, '')
        }
      } else if (param === 2) {
        if (condition === 'created') {
          this.preview.diskon = value
          this.tambahFarmasi.farmasi.diskon_beli = value.toString().replace(/,/g, '.')
        } else if (condition === 'edit') {
          if (value.slice(-2) === '00') {
            this.preview.editDiskon = value.split('.')[0]
            this.editFarmasi.farmasi.diskon_beli = value.replace(/,/g, '.').split('.')[0]
          } else {
            this.preview.editDiskon = value
            this.editFarmasi.farmasi.diskon_beli = value.replace(/,/g, '.')
          }
        }
      } else if (param === 3) {
        if (condition === 'created') {
          this.preview.buffer = value
          this.tambahFarmasi.farmasi.buffer = value.toString()
        } else if (condition === 'edit') {
          this.preview.editBuffer = value
          this.editFarmasi.farmasi.buffer = value.toString()
        }
      }
    },
    // for get selected  ---start here---
    async getJenisBarang () {
      try {
        const response = await Services.getSubJenis()
        this.options.namaJenisBarang = response.data.data
        this.options.editNamaJenisBarang = response.data.data
      } catch (error) {
        this.options.namaJenisBarang = []
        this.preview.message = error.message
        this.preview.messageValue = '2'
        // this.$refs['modal-notif-farmasi'].show()
      }
    },
    async getKelompokBarang () {
      try {
        const response = await Services.getKelompok()
        this.options.kelompokBarang = response.data.data
        this.options.editKelompokBarang = response.data.data
      } catch (error) {
        this.options.kelompokBarang = []
        this.preview.message = error.message
        this.preview.messageValue = '2'
        // this.$refs['modal-notif-farmasi'].show()
      }
    },
    async getNamaDagang () {
      try {
        const response = await Services.getBrand()
        this.options.namaDagang = response.data.data
        this.options.editNamaDagang = response.data.data
      } catch (error) {
        this.options.namaDagang = []
        this.preview.message = error.message
        this.preview.messageValue = '2'
        // this.$refs['modal-notif-farmasi'].show()
      }
    },
    async getNamaGenerik () {
      try {
        const response = await Services.getGenerik()
        this.options.namaGenerik = response.data.data
        this.options.editNamaGenerik = response.data.data
      } catch (error) {
        this.options.namaGenerik = []
        this.preview.message = error.message
        this.preview.messageValue = '2'
        // this.$refs['modal-notif-farmasi'].show()
      }
    },
    async getNamaPabrik () {
      try {
        const response = await Services.getPabrik()
        this.options.Pabrik = response.data.data
        this.options.editPabrik = response.data.data
      } catch (error) {
        this.options.Pabrik = []
        this.preview.message = error.message
        this.preview.messageValue = '2'
        // this.$refs['modal-notif-farmasi'].show()
      }
    },
    async getNamaPbf () {
      try {
        const response = await Services.getPbf()
        this.options.Pbf = response.data.data
        this.options.editPbf = response.data.data
      } catch (error) {
        this.options.Pbf = []
        this.preview.message = error.message
        this.preview.messageValue = '2'
        // this.$refs['modal-notif-farmasi'].show()
      }
    },
    async getSatuanKemasan () {
      try {
        const response = await Services.getKemasan()
        this.options.satuanKemasanBesar = response.data.data
        this.options.satuanKemasanKecil = response.data.data
        this.options.editSatuanKemasanBesar = response.data.data
        this.options.editSatuanKemasanKecil = response.data.data
      } catch (error) {
        this.options.satuanKemasanBesar = []
        this.options.satuanKemasanKecil = []
        this.preview.message = error.message
        this.preview.messageValue = '2'
        // this.$refs['modal-notif-farmasi'].show()
      }
    },
    async getjenisAnggaran () {
      try {
        const response = await Services.getJenis()
        this.created.options.subjenisanggaran = response.data.data
      } catch (error) {
        this.preview.message = error.message
        this.preview.messageValue = '2'
        // this.$refs['modal-notif-subjenisAnggaran'].show()
      }
    },
    async getKfa91 () {
      try {
        const response = await Services.kfa91()
        this.options.editKfa91 = response.data.data
        this.options.kfa91 = response.data.data
      } catch (error) {
        this.options.Pabrik = []
        this.preview.message = error.message
        this.preview.messageValue = '2'
        // this.$refs['modal-notif-farmasi'].show()
      }
    },
    async getKfa92 () {
      try {
        const response = await Services.kfa92()
        this.options.kfa92 = response.data.data
        this.options.editKfa92 = response.data.data
      } catch (error) {
        this.options.Pabrik = []
        this.preview.message = error.message
        this.preview.messageValue = '2'
        // this.$refs['modal-notif-farmasi'].show()
      }
    },
    async getKfa93 () {
      try {
        const response = await Services.kfa93()
        this.options.kfa93 = response.data.data
        this.options.editKfa93 = response.data.data
      } catch (error) {
        this.options.Pabrik = []
        this.preview.message = error.message
        this.preview.messageValue = '2'
        // this.$refs['modal-notif-farmasi'].show()
      }
    },
    async getSakti () {
      try {
        const response = await Services.getSakti()
        this.options.sakti = response.data.data
        this.options.editSakti = response.data.data
      } catch (error) {
        this.options.Pabrik = []
        this.preview.message = error.message
        this.preview.messageValue = '2'
        // this.$refs['modal-notif-farmasi'].show()
      }
    },
    async getDosis () {
      try {
        const response = await Services.getDosis()
        this.options.dosis = response.data.data
        this.options.editDosis = response.data.data
      } catch (error) {
        this.options.Pabrik = []
        this.preview.message = error.message
        this.preview.messageValue = '2'
        // this.$refs['modal-notif-farmasi'].show()
      }
    },
    async saktiHdrGet () {
      try {
        const response = await Services.getSaktiHdr()
        this.created.options.sakti = response.data.data
      } catch (error) {
        this.preview.message = error.message
        this.preview.messageValue = '2'
        // this.$refs['modal-notif-subjenisAnggaran'].show()
      }
    },
    async kategoriGet () {
      try {
        const response = await Services.getKategori()
        this.created.options.kodesimrsgos = response.data.data
      } catch (error) {
        this.preview.message = error.message
        this.preview.messageValue = '2'
        // this.$refs['modal-notif-subjenisAnggaran'].show()
      }
    },
    async hardCodeGet () {
      try {
        const response = await Services.getHardCode()
        this.created.options.hardcode = response.data.data
      } catch (error) {
        this.preview.message = error.message
        this.preview.messageValue = '2'
        // this.$refs['modal-notif-subjenisAnggaran'].show()
      }
    },
    async kelinventoryGet () {
      try {
        const response = await Services.getInvKelmpk()
        this.created.options.inventori = response.data.data
      } catch (error) {
        this.preview.message = error.message
        this.preview.messageValue = '2'
        // this.$refs['modal-notif-subjenisAnggaran'].show()
      }
    },
    // for get selected  ---end here---
    codewithdesc ({ kode, uraian }) {
      return `${kode} : ${uraian}`
    },
    valueExtendJenisBarang (param) {
      this.created.subjenisanggaran.id_jenis = param.id
      this.created.subjenisanggaran.subjenis_anggaran = param.jenis_anggaran
    },
    valueExtendSakti (param) {
      this.created.tambahSakti.id_hdr = param.id.toString()
    },
    valueExtendGenerik (param) {
      this.created.tambahBrand.id_generik = param.id.toString()
    },
    valueHardCode (param) {
      this.created.tambahKelompok.id_hardcode = param.id_hardcode.toString()
    },
    valueSimrsgos (param) {
      this.created.tambahKelompok.id_kategori_simrsgos = param.id.toString()
    },
    valueKelInv (param) {
      this.created.tambahKelompok.id_inventory = param.id.toString()
    },
    async list (ctx) {
      this.table.isBusy = true
      let filterType = this.selected.filter
      let sortfield = ''
      let sorting = ''
      if (ctx.filter === '') {
        ctx.filter = null
      }
      if (ctx.sortBy) {
        sortfield = ctx.sortBy
      } else {
        sortfield = ''
      }
      if (ctx.sortDesc === true) {
        sorting = 'DESC'
      } else if (ctx.sortDesc === false) {
        sorting = 'ASC'
      } else {
        sorting = ''
      }
      try {
        const response = await Services.listFarmasi(ctx.currentPage - 1, ctx.perPage, ctx.filter, filterType, sortfield, sorting)
        this.table.isBusy = false
        this.table.totalRows = response.data.pagination.total
        this.table.perPage = response.data.pagination.perPage
        this.table.currentPage = response.data.pagination.current
        return response.data.data
      } catch (error) {
        this.table.isBusy = false
        return []
      }
    },
    onFiltered (filteredItems) {
      this.table.totalRows = filteredItems.length
      this.table.currentPage = 1
    },
    filterchange () {
      this.table.filter = ''
    },
    async viewModal (id) {
      try {
        const response = await Services.showFarmasi(id)
        this.preview.viewData = response.data.data[0]
        this.preview.selected.sts_aktif = this.preview.viewData.aktifasi
        this.preview.selected.formularium_nas = this.preview.viewData.formularium_nas
        this.preview.selected.formularium_rs = this.preview.viewData.formularium_rs
        this.preview.selected.livesaving = this.preview.viewData.live_saving
        this.preview.selected.generik = this.preview.viewData.generik
        this.preview.selected.kronis = this.preview.viewData.sts_kronis
        this.preview.selected.sts_oot = this.preview.viewData.sts_oot
        this.preview.selected.sts_bpjs = this.preview.viewData.sts_bpjs
        this.preview.selected.sts_prekursor = this.preview.viewData.sts_prekursor
        this.preview.selected.sts_narkotika = this.preview.viewData.sts_narkotika
        this.preview.selected.sts_psikotropika = this.preview.viewData.sts_psikotropika
      } catch (e) {
        this.preview.message = e.message
        this.preview.messageValue = '2'
        this.$refs['modal-notif-farmasi'].show()
      }
      this.$refs['modal-view-farmasi'].show()
      this.preview.isLoading = false
    },
    tambahModal () {
      this.preview.message = ''
      this.preview.activeModal = '1'
      this.$refs['modal-tambah-farmasi'].show()
    },
    async tambahSave (bvModalEvent) {
      bvModalEvent.preventDefault()
      try {
        const data = this.tambahFarmasi
        const response = await Services.createFarmasi(data)
        if (response.data.statcode === 0) {
          this.preview.message = response.data.message
          this.preview.messageValue = '1'
          this.$refs['modal-tambah-farmasi'].hide()
          this.$refs['modal-notif-farmasi'].show()
          this.$refs.table.refresh()
          this.clearForm()
        } else {
          this.preview.message = response.data.message
          this.preview.messageValue = '2'
        }
      } catch (error) {
        this.preview.message = error.message
        this.preview.messageValue = '2'
      }
    },
    async editModal (id) {
      const ids = id
      this.preview.message = ''
      this.preview.edit_id = ids
      try {
        const response = await Services.showFarmasi(ids)
        if (response.data.statcode === 0) {
          if (response.data.data[0].pabrik) {
            this.deleteRowEdit(0)
            let farmasi = response.data.data[0].pabrik
            farmasi.map((value, index) => {
              this.editFarmasi.pabrik.push({
                id_pabrik: value.id_pabrik.toString(),
                id_kfa93: value.id_kfa93.toString(),
                no_urut: value.no_urut.toString(),
                sts_aktif: value.sts_aktif.toString(),
                updated_by: jwtdecode(localStorage.getItem('token')).user.id.toString(),
                id_transmedik: value.id_transmedik.toString(),
                sts_opname: value.sts_opname
              })
              this.dummy.selected.editpabrik.push({
                detail: value.id_pabrik !== 0 ? [{ id: value.id_pabrik, nama_pabrik: value.nm_pabrik }] : []
              })
              this.dummy.selected.editkfa93.push({
                detail: value.id_kfa93 !== 0 ? [{ id: value.id_kfa93, uraian: value.uraian_kfa93 }] : []
              })
              this.link.editkfa93.push({
                url: value.url_kfa93
              })
              this.dummy.sts_akhir = value.sts_aktif.toString()
            })
          }
          this.link.kfa91 = response.data.data[0].url_kfa91 ? response.data.data[0].url_kfa91 : ''
          this.link.kfa92 = response.data.data[0].url_kfa92 ? response.data.data[0].url_kfa92 : ''
          this.editFarmasi.farmasi.id_jenisbarang = response.data.data[0].id_jenisbarang !== null ? response.data.data[0].id_jenisbarang.toString() : ''
          this.selected.editNamaJenisBarang = response.data.data[0].id_jenisbarang !== null ? { id: response.data.data[0].id_jenisbarang, subjenis_anggaran: response.data.data[0].nm_subjenis_anggaran } : []
          this.editFarmasi.farmasi.nama_barang = response.data.data[0].nama_barang ? response.data.data[0].nama_barang : ''
          this.editFarmasi.farmasi.id_kelompokbarang = response.data.data[0].id_kelompokbarang !== null ? response.data.data[0].id_kelompokbarang.toString() : ''
          if (response.data.data[0].nama_barang && response.data.data[0].id_kelompokbarang !== null) {
            this.suggestKodeEdit()
          }
          if (response.data.data[0].id_hardcode !== null) {
            this.valueEditKelompokBarangRspn(response.data.data[0].id_hardcode.toString())
          }
          this.selected.editKelompokBarang = response.data.data[0].id_kelompokbarang !== null ? { id: response.data.data[0].id_kelompokbarang, kelompok_barang: response.data.data[0].kelompok_barang } : []
          this.editFarmasi.farmasi.id_brand = response.data.data[0].id_brand !== null ? response.data.data[0].id_brand.toString() : ''
          this.selected.editNamaDagang = response.data.data[0].id_brand !== null ? { id: response.data.data[0].id_brand, nama_dagang: response.data.data[0].nama_brand } : []
          this.editFarmasi.farmasi.id_pbf = response.data.data[0].id_pbf !== null ? response.data.data[0].id_pbf.toString() : ''
          this.selected.editPbf = response.data.data[0].id_pbf !== null ? { id: response.data.data[0].id_pbf, nama_pbf: response.data.data[0].nama_pbf } : []
          this.editFarmasi.farmasi.zat_aktif = response.data.data[0].zat_aktif ? response.data.data[0].zat_aktif : ''
          this.editFarmasi.farmasi.retriksi = response.data.data[0].retriksi ? response.data.data[0].retriksi : ''
          this.editFarmasi.farmasi.keterangan = response.data.data[0].keterangan ? response.data.data[0].keterangan : ''
          this.editFarmasi.farmasi.kelas_terapi = response.data.data[0].kelas_terapi ? response.data.data[0].kelas_terapi : ''
          this.editFarmasi.farmasi.resep_max_bulan = response.data.data[0].resep_max_bulan ? response.data.data[0].resep_max_bulan : ''
          this.editFarmasi.farmasi.resep_max_kasus = response.data.data[0].resep_max_kasus ? response.data.data[0].resep_max_kasus : ''
          this.editFarmasi.farmasi.kode = response.data.data[0].kode !== null ? response.data.data[0].kode : ''
          this.editFarmasi.farmasi.id_kemasanbesar = response.data.data[0].id_kemasanbesar !== null ? response.data.data[0].id_kemasanbesar.toString() : ''
          this.selected.editSatuanKemasanBesar = response.data.data[0].id_kemasanbesar !== null ? { id: response.data.data[0].id_kemasanbesar, nama_kemasan: response.data.data[0].kemasan_besar } : []
          this.preview.editSatuanKemasanBesar = response.data.data[0].kode_kemasan_besar !== null ? response.data.data[0].kode_kemasan_besar : ''
          this.editFarmasi.farmasi.id_kemasankecil = response.data.data[0].id_kemasankecil !== null ? response.data.data[0].id_kemasankecil.toString() : ''
          this.selected.editSatuanKemasanKecil = response.data.data[0].id_kemasankecil !== null ? { id: response.data.data[0].id_kemasankecil, nama_kemasan: response.data.data[0].kemasan_kecil } : []
          this.preview.editSatuanKemasanKecil = response.data.data[0].kode_kemasan_kecil !== null ? response.data.data[0].kode_kemasan_kecil : ''
          if (response.data.data[0].isi_kemasan !== null) {
            this.sliceEditIsiKmsn(response.data.data[0].isi_kemasan.toString())
          }
          this.editFarmasi.farmasi.isi_kemasan = response.data.data[0].isi_kemasan !== null ? response.data.data[0].isi_kemasan.toString() : ''
          this.preview.editIsi_kemasan = response.data.data[0].isi_kemasan !== null ? response.data.data[0].isi_kemasan : ''
          this.editFarmasi.farmasi.id_dosis = response.data.data[0].id_dosis !== null ? response.data.data[0].id_dosis.toString() : ''
          this.selected.editDosis = response.data.data[0].id_dosis !== null ? { id: response.data.data[0].id_dosis, nama: response.data.data[0].nama_dosis } : []
          this.preview.editDosis = response.data.data[0].kode_dosis !== null ? response.data.data[0].kode_dosis : ''
          this.editFarmasi.farmasi.isi_dosis = response.data.data[0].isi_dosis !== null ? response.data.data[0].isi_dosis.toString() : ''
          this.preview.edit_isi_dosis = response.data.data[0].isi_dosis !== null ? response.data.data[0].isi_dosis : ''
          this.inputEditKemasanBarang()
          if (response.data.data[0].diskon_beli !== null) {
            this.formatted(response.data.data[0].diskon_beli.toString(), 2, 'edit')
          }
          if (response.data.data[0].harga_beli !== null) {
            this.formatted(response.data.data[0].harga_beli.toString().split('.')[0], 1, 'edit')
          }
          this.editFarmasi.farmasi.leadtime = response.data.data[0].leadtime !== null ? response.data.data[0].leadtime.toString() : ''
          this.preview.editBuffer = response.data.data[0].buffer !== null ? response.data.data[0].buffer : ''
          this.editFarmasi.farmasi.buffer = response.data.data[0].buffer !== null ? response.data.data[0].buffer.toString() : ''
          this.editFarmasi.farmasi.moving = response.data.data[0].moving !== null ? response.data.data[0].moving : ''
          this.selected.editDeathMoving = response.data.data[0].moving !== null ? { id: response.data.data[0].moving, name: response.data.data[0].moving === 'DM' ? 'Death Moving' : response.data.data[0].moving === 'SM' ? 'Small Moving' : response.data.data[0].moving === 'MM' ? 'Medium Moving' : 'Fast Moving' } : []
          this.editFarmasi.farmasi.formularium_nas = response.data.data[0].formularium_nas ? response.data.data[0].formularium_nas.toString() : '0'
          this.editFarmasi.farmasi.formularium_rs = response.data.data[0].formularium_rs ? response.data.data[0].formularium_rs.toString() : '0'
          this.editFarmasi.farmasi.generik = response.data.data[0].generik ? response.data.data[0].generik.toString() : '0'
          this.editFarmasi.farmasi.live_saving = response.data.data[0].live_saving ? response.data.data[0].live_saving.toString() : '0'
          this.editFarmasi.farmasi.sts_kronis = response.data.data[0].sts_kronis ? response.data.data[0].sts_kronis.toString() : '0'
          this.editFarmasi.farmasi.sts_oot = response.data.data[0].sts_oot ? response.data.data[0].sts_oot.toString() : '0'
          this.editFarmasi.farmasi.sts_bpjs = response.data.data[0].sts_bpjs ? response.data.data[0].sts_bpjs.toString() : '0'
          this.editFarmasi.farmasi.sts_narkotika = response.data.data[0].sts_narkotika ? response.data.data[0].sts_narkotika.toString() : '0'
          this.editFarmasi.farmasi.sts_psikotropika = response.data.data[0].sts_psikotropika ? response.data.data[0].sts_psikotropika.toString() : '0'
          this.editFarmasi.farmasi.sts_prekursor = response.data.data[0].sts_prekursor ? response.data.data[0].sts_prekursor.toString() : '0'
          this.editFarmasi.farmasi.id_kfa91 = response.data.data[0].id_kfa91 !== null ? response.data.data[0].id_kfa91.toString() : ''
          this.selected.editKfa91 = response.data.data[0].id_kfa91 !== null ? { id: response.data.data[0].id_kfa91, uraian: response.data.data[0].uraian_kfa91 } : []
          this.editFarmasi.farmasi.id_kfa92 = response.data.data[0].id_kfa92 !== null ? response.data.data[0].id_kfa92.toString() : ''
          this.selected.editKfa92 = response.data.data[0].id_kfa92 !== null ? { id: response.data.data[0].id_kfa92, uraian: response.data.data[0].uraian_kfa92 } : []
          this.editFarmasi.farmasi.id_barang_sakti = response.data.data[0].id_barang_sakti !== null ? response.data.data[0].id_barang_sakti.toString() : ''
          this.selected.editSakti = response.data.data[0].id_barang_sakti !== null ? { id: response.data.data[0].id_barang_sakti, uraian: response.data.data[0].uraian } : []
          this.editFarmasi.farmasi.kategori_warna = response.data.data[0].kategori_warna !== null ? response.data.data[0].kategori_warna.toString() : ''
          this.selected.editkategoriWarna = response.data.data[0].kategori_warna !== null ? { id: response.data.data[0].kategori_warna, name: response.data.data[0].nama_kategori } : []
          this.editFarmasi.farmasi.aktifasi = response.data.data[0].aktifasi ? response.data.data[0].aktifasi.toString() : '0'
          this.editFarmasi.farmasi.userid_in = response.data.data[0].userid_in ? response.data.data[0].userid_in.toString() : ''
          this.editFarmasi.farmasi.sysdate_in = response.data.data[0].sysdate_in ? response.data.data[0].sysdate_in.toString() : ''
          this.preview.user_in = response.data.data[0].name_in ? response.data.data[0].name_in : ''
          this.dummy.sts_pabrik = response.data.data[0].sts_pabrik
        } else {
          this.preview.message = response.data.message
          this.preview.messageValue = '2'
        }
      } catch (error) {
        this.preview.message = error.message
        this.preview.messageValue = '2'
      }
      this.preview.activeModal = '2'
      this.$refs['modal-edit-farmasi'].show()
    },
    async editSave (bvModalEvent2) {
      bvModalEvent2.preventDefault()
      try {
        const id = this.preview.edit_id
        const data = this.editFarmasi
        const response = await Services.editFarmasi(id, data)
        if (response.data.statcode === 0) {
          this.preview.message = response.data.message
          this.preview.messageValue = '1'
          this.$refs['modal-edit-farmasi'].hide()
          this.$refs['modal-notif-farmasi'].show()
          this.clearArr()
          this.$refs.table.refresh()
        } else {
          this.preview.message = response.data.message
          this.preview.messageValue = '2'
        }
      } catch (error) {
        this.preview.message = error.message
        this.preview.messageValue = '2'
      }
    },
    deleteModal (id) {
      this.preview.delete_id = id
      this.$refs['modal-delete-farmasi'].show()
    },
    async deleteSave () {
      const id = this.preview.delete_id
      try {
        const response = await Services.deleteFarmasi(id)
        if (response.data.statcode === 0) {
          this.preview.message = response.data.message
          this.preview.messageValue = '1'
          this.$refs.table.refresh()
        } else {
          this.preview.message = response.data.message
          this.preview.messageValue = '2'
        }
        this.$refs['modal-notif-farmasi'].show()
      } catch (error) {
        this.preview.message = error.message
        this.preview.messageValue = '2'
        this.$refs['modal-notif-farmasi'].show()
      }
    },
    async tambahExtend () {
      try {
        const data = this.created.index === '1' ? this.created.subjenisanggaran : this.created.index === '2' ? this.created.tambahKelompok : this.created.index === '3' ? this.created.tambahBrand : this.created.index === '4' ? this.created.tambahGenerik : this.created.index === '5' ? this.created.tambahPabrik : this.created.index === '6' ? this.created.tambahPbf : this.created.index === '7' ? this.created.tambahDosis : this.created.index === '8' ? this.created.tambahSakti : {}
        const response = this.created.index === '1' ? await Services.createSubJenisAnggaran(data) : this.created.index === '2' ? await Services.createKelompok(data) : this.created.index === '3' ? await Services.createBrand(data) : this.created.index === '4' ? await Services.createGenerik(data) : this.created.index === '5' ? await Services.create(data) : this.created.index === '6' ? await Services.createPbf(data) : this.created.index === '7' ? await Services.createDosis(data) : this.created.index === '8' ? await Services.createSakti(data) : undefined
        if (response.data.statcode === 0) {
          this.preview.message = response.data.message
          this.preview.messageValue = '1'
        } else {
          this.preview.message = response.data.message
          this.preview.messageValue = '2'
        }
        this.$refs['modal-notif-farmasi'].show()
        this.getJenisBarang()
        this.getKelompokBarang()
        this.getNamaDagang()
        this.getNamaGenerik()
        this.getNamaPabrik()
        this.getNamaPbf()
        this.getSatuanKemasan()
        this.getSakti()
        this.getDosis()
      } catch (error) {
        this.preview.message = error.message
        this.preview.messageValue = '2'
        this.$refs['modal-notif-farmasi'].show()
      }
    },
    async tambahExtendSub () {
      try {
        const data = this.created.indexSub === '1' ? this.created.tambahJenisAnggaran : this.created.indexSub === '2' ? this.created.tambahSaktiHdr : {}
        const response = this.created.indexSub === '1' ? await Services.createJenisAnggaran(data) : this.created.indexSub === '2' ? await Services.createSaktiHdr(data) : undefined
        if (response.data.statcode === 0) {
          this.preview.message = response.data.message
          this.preview.messageValue = '1'
        } else {
          this.preview.message = response.data.message
          this.preview.messageValue = '2'
        }
        this.$refs['modal-notif-farmasi'].show()
        this.getjenisAnggaran()
        this.saktiHdrGet()
      } catch (error) {
        this.preview.message = error.message
        this.preview.messageValue = '2'
        this.$refs['modal-notif-farmasi'].show()
      }
    }
  }
}
</script>
<style>
#modal-view-katalog .custom-div {
  background-color: #e9ecef;
  border-radius: 5px;
}
#modal-tambah-edit-katalog .height-static-pabrik {
  /* max-height: 12em;
  overflow-x: hidden; */
  overflow: auto;
}
#modal-tambah-edit-katalog .multiselect__option--selected, #modal-extend-tambah .multiselect__option--selected {
    background: #41B883;
    color: white;
}
#modal-tambah-edit-katalog .border-merah {
  border: 2px solid red;
}
#modal-tambah-edit-katalog .border-hijau {
  border: 2px solid green;
}
#modal-tambah-edit-katalog .multiselect, #modal-extend-tambah .multiselect {
  min-height: 30px;
}
#modal-tambah-edit-katalog .multiselect--disabled {
  opacity: 1;
}
#modal-tambah-edit-katalog .modal-body, #modal-extend-tambah .modal-body, #modal-editkode-farmasi .modal-body {
  background: #3399331b;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem ;
}
#modal-view-katalog .modal-body {
  background: #3399331b
}
#modal-view-katalog .modal-dialog {
  min-width: 95%!important;
}
#modal-extend-tambah-2 .modal-body {
  background: #3399331b;
}
#modal-view-katalog .modal-header {
  background: #3399333a;
}
#modal-view-katalog .modal-footer {
  background: #3399333a;
}
#modal-tambah-edit-katalog .multiselect--disabled .multiselect__tags, #modal-extend-tambah .multiselect--disabled .multiselect__tags {
  overflow: hidden;
  background: #E9ECEF;
}
#modal-tambah-edit-katalog .multiselect__select {
  height: 28px;
}
#modal-tambah-edit-katalog .multiselect__current, .multiselect__select {
    line-height: 15px;
}
#modal-tambah-edit-katalog .multiselect--disabled .multiselect__tags .multiselect__single, #modal-extend-tambah .multiselect--disabled .multiselect__tags .multiselect__single {
  overflow: hidden;
  background: transparent;
}
#modal-tambah-edit-katalog .dosis-left-width {
  width: 87%;
}
#modal-tambah-edit-katalog .dosis-right-width {
  width: 13%;
}
#modal-tambah-edit-katalog .sakti-left-width {
  width: 92%;
}
#modal-tambah-edit-katalog .sakti-right-width {
  width: 8%;
}
#modal-tambah-edit-katalog .custom-control-label, #modal-extend-tambah .custom-control-label, #modal-view-katalog .custom-control-label {
    font-size: 0.9em;
    margin-top: 0;
}
#modal-tambah-edit-katalog .modal-header, #modal-extend-tambah .modal-header, #modal-extend-tambah-2 .modal-header {
  background: #3399333a;
}
#modal-tambah-edit-katalog .modal-footer, #modal-extend-tambah .modal-footer, #modal-extend-tambah-2 .modal-footer {
  background: #3399333a;
}
#modal-tambah-edit-katalog .multiselect__content-wrapper, #modal-extend-tambah .multiselect__content-wrapper, #modal-extend-tambah-2 .multiselect__content-wrapper {
    font-size: 13px;
    font-weight: normal;
}
#modal-tambah-edit-katalog .multiselect__single, #modal-extend-tambah .multiselect__single {
  font-size: 0.9em!important;
  font-weight: normal!important;
  line-height: 26px;
}
#modal-tambah-edit-katalog .multiselect__placeholder, #modal-extend-tambah .multiselect__placeholder {
  font-size: 0.9em!important;
  font-weight: normal;
}
#modal-tambah-edit-katalog .multiselect__option, #modal-extend-tambah .multiselect__option {
    font-size: 0.9em;
}
#modal-tambah-edit-katalog .multiselect__tags, #modal-extend-tambah .multiselect__tags {
  overflow: hidden;
  padding: 1px 40px 0 8px!important;
  height: 30px!important;
  min-height: 30px!important;
}
#katalog-farmasi .page-link {
  font-size: 14px;
  padding-top: 5px;
  padding-bottom: 5px;
  margin-right: 8px!important;
}
#katalog-farmasi .custom-button-radius {
  border: 1px solid rgba(0, 0, 0, 0.112);
  border-radius: 5px!important;
}
.border-satu-sehat-katalog {
  border: 1px solid rgba(0, 129, 151, 0.664);
  border-radius: 5px!important;
}
#katalog-farmasi .table td {
    font-size: 12px;
    font-weight: 500;
    padding-top: 0px;
    padding-bottom: 0px;
}
#katalog-farmasi .table th {
    font-size: 13px;
    font-weight: 400;
}
#katalog-farmasi .cstm-select-farmasi {
  font-size: 0.9em;
  height: 38px!important;
  border-radius: 5px;
}
#katalog-farmasi .cstm-srch-farmasi {
  font-size: 0.9em;
  height: 38px!important;
  border-radius: 5px;
}
#katalog-farmasi .cstm-slct-farmasi {
  font-size: 0.9em;
  height: 38px!important;
  border-radius: 5px;
}
#katalog-farmasi .form-control:disabled, .form-control[readonly] {
    background-color: #E9ECEF!important;
}
.cstm-cntrl-katalog-kode {
  color: black!important;
  background: white!important;
  font-weight: normal!important;
  border-radius: 5px!important;
  font-size: 2.5em!important;
  height: 38px!important;
  line-height: 35px;
}
.cstm-cntrl-katalog-10 {
  color: black!important;
  background: white!important;
  font-weight: normal!important;
  border-radius: 5px!important;
  font-size: 0.9em!important;
  height: 38px!important;
  line-height: 35px;
}
.cstm-cntrl-katalog-10 {
  color: black!important;
  background: white!important;
  font-weight: normal!important;
  border-radius: 5px!important;
  font-size: 0.9em!important;
  height: 30px!important;
  line-height: 35px;
}
.katalog-form-area {
  background: white!important;
  color: black!important;
  font-weight: normal!important;
  border-radius: 5px!important;
  font-size: 0.9em!important;
  line-height: 20px;
}
.katalog-form-area-2 {
  background: white!important;
  color: black!important;
  font-weight: normal!important;
  border-radius: 5px!important;
  font-size: 0.9em!important;
  line-height: 20px!important;
}
#katalog-farmasi .pagination-sm .page-link {
  color: gray!important;
}
#katalog-farmasi .page-item.active .page-link {
  color: white!important;
}
#katalog-farmasi .page-item.active .page-link {
  color: white!important;
}
.custom-radius-add-katalog {
  border-radius: 5px;
  cursor: pointer;
}
.custom-radius-add-katalog-disable {
  border-radius: 5px;
}
.katalog-select-label {
  width: 25%;
}
.katalog-select-label-2 {
  width: 20%;
}
.katalog-select-flex-left {
  width: 65%;
}
.katalog-select-flex-left-2 {
  width: 80%;
}
.katalog-select-flex-left-2-peresepan-maksimal {
  width: 39.7%;
}
.katalog-select-flex-left-10 {
  width: 95%;
}
.katalog-select-flex-right {
  width: 10%;
}
.katalog-select-flex-right-2 {
  width: 8%;
}
.katalog-form-satuan-kemasan-left-right {
  width: 40%;
}
.katalog-form-satuan-kemasan-center {
  width: 15%;
}
.hr-katalog-custom {
  border-top: 1px solid rgba(0, 0, 0, 0.199)!important;
  margin: 0!important;
}
#modal-tambah-edit-katalog .modal-dialog {
  min-width: 95%!important;
}
.custom-label-katalog {
  font-size: 0.9em;
  margin: 0;
}
.custom-font-katalog {
  font-size: 0.9em;
}
.katalog-cursor {
  cursor: pointer;
}
#katalog-farmasi .pagination-farmasi .page-link {
    border: none;
    color: gray!important;
    margin-right: 15px;
}
#katalog-farmasi .pagination-farmasi .page-item.active .page-link {
    background-color: transparent;
    border-color: transparent;
    color: #089bab!important;
    font-weight: bold;
}
</style>
