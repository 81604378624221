const serviceFatmahostAuth = process.env.VUE_APP_FATMAHOST_AUTH_SERVICE
const serviceFatmahostLog = process.env.VUE_APP_FATMAHOST_LOG_SERVICE
const serviceFatmahostMaster = process.env.VUE_APP_FATMAHOST_MASTER_SERVICE
const serviceFatmahostPegawai = process.env.VUE_APP_FATMAHOST_PEGAWAI_SERVICE
const serviceFatmahostUsers = process.env.VUE_APP_FATMAHOST_USERS_SERVICE
const serviceFatmahostPasien = process.env.VUE_APP_FATMAHOST_PASIEN_SERVICE
const serviceFatmahostMember = process.env.VUE_APP_FATMAHOST_MEMBER_SERVICE
const serviceFatmahostKatalog = process.env.VUE_APP_FATMAHOST_KATALOG_SERVICE
const serviceFatmahostDashboard = process.env.VUE_APP_FATMAHOST_DASHBOARD_SERVICE
const serviceFatmahostKeuangan = process.env.VUE_APP_FATMAHOST_KEUANGAN_SERVICE
const serviceFatmahostEksport = process.env.VUE_APP_FATMAHOST_EKSPORT_SERVICE
const serviceFatmahostReservasi = process.env.VUE_APP_FATMAHOST_RESERVASI_SERVICE
const serviceFatmahostConnected = process.env.VUE_APP_FATMAHOST_CONNECTED_SERVICE
const serviceFatmahostMiddleware = process.env.VUE_APP_FATMAHOST_MIDDLEWARE_SERVICE
const serviceHimsBackend = process.env.VUE_APP_HIMS_BACKEND_SERVICE

export default {
  serviceFatmahostAuth: serviceFatmahostAuth,
  serviceFatmahostLog: serviceFatmahostLog,
  serviceFatmahostMaster: serviceFatmahostMaster,
  serviceFatmahostUsers: serviceFatmahostUsers,
  serviceFatmahostPegawai: serviceFatmahostPegawai,
  serviceFatmahostPasien: serviceFatmahostPasien,
  serviceFatmahostMember: serviceFatmahostMember,
  serviceFatmahostKatalog: serviceFatmahostKatalog,
  serviceFatmahostDashboard: serviceFatmahostDashboard,
  serviceFatmahostKeuangan: serviceFatmahostKeuangan,
  serviceFatmahostEksport: serviceFatmahostEksport,
  serviceFatmahostReservasi: serviceFatmahostReservasi,
  serviceFatmahostConnected: serviceFatmahostConnected,
  serviceFatmahostMiddleware: serviceFatmahostMiddleware,
  serviceHimsBackend: serviceHimsBackend
}
