<template>
  <div id="dashboard-remun" class="mb-5">
    <b-container fluid>
      <div class="card-remun-dash">
        <b-row>
          <b-col cols="12" lg="4">
            <div class="bg-white rounded-custom shadow-sm mb-3">
              <div class="py-2 px-3">
                <p class="text-dark font-weight-bold mb-0"><i class="fas fa-user"></i> &nbsp; PROFIL</p>
              </div>
              <hr class="m-0">
              <div class="py-3 px-3">
                <div class="text-center mb-5">
                  <i class="fas fa-user-md fa-5x text-primary"></i>
                  <p class="h6 text-dark mb-0 mt-3">{{ data.NAMA }}</p>
                </div>
                <div class="d-flex flex-row justify-content-between">
                  <p class="text-dark mb-0">KSM</p>
                  <p class="text-dark mb-0">{{ data.KSM }}</p>
                </div>
                <div class="d-flex flex-row justify-content-between">
                  <p class="text-dark mb-0">NIP</p>
                  <p class="text-dark mb-0">{{ data.NIP }}</p>
                </div>
                <div class="d-flex flex-row justify-content-between">
                  <p class="text-dark mb-0">Tanggal Lahir</p>
                  <p class="text-dark mb-0">{{ data.TGL_LAHIR }}</p>
                </div>
                <div class="d-flex flex-row justify-content-between">
                  <p class="text-dark mb-0">Tempat Lahir</p>
                  <p class="text-dark mb-0">{{ data.TEMPAT_LAHIR }}</p>
                </div>
              </div>
            </div>
            <!-- <div class="bg-white rounded-custom shadow-sm mb-3">
              <div class="py-2 px-3">
                <p class="mb-0 font-weight-bold text-dark"><i class="fas fa-list"></i> &nbsp; REMUN BERJALAN</p>
              </div>
              <hr class="m-0">
              <div class="list-remun-histori py-2 px-3" @click="toDetail">
                <p class="mb-0" v-if="Object.keys(riwayatrekap).length !== 0">{{ riwayatrekap.REALTIME.LABEL }} &nbsp; <i class="fas fa-caret-right"></i> &nbsp; REAL TIME</p>
                <p class="mb-0" v-else>-</p>
              </div>
              <div>
              </div>
            </div> -->
            <div class="bg-white rounded-custom shadow-sm mb-0">
              <div class="py-2 px-3">
                <p class="mb-0 font-weight-bold text-dark"><i class="fas fa-calendar-alt"></i> &nbsp; RIWAYAT REMUN</p>
              </div>
              <hr class="m-0">
              <div class="scroll-card">
                <div v-for="d in riwayatrekap.HISTORI" v-bind:key="d.codex">
                  <div class="list-remun-histori py-2 px-3" @click="toDetail(d.PERIODE)">
                    <p class="mb-0">{{ d.LABEL }}</p>
                  </div>
                  <hr class="m-0">
                </div>
              </div>
            </div>
          </b-col>
          <b-col cols="12" lg="8">
            <div class="bg-white rounded-custom shadow-sm mb-3">
              <div class="py-2 px-3">
                <p class="text-dark font-weight-bold mb-0"><i class="fas fa-chart-line"></i> &nbsp; {{ charts[0].title }}</p>
              </div>
              <hr class="m-0">
              <div class="py-3 px-3 w-100">
                <HighChart :ref="charts[0].type" :option="charts[0].bodyData"/>
              </div>
            </div>
            <div class="bg-white rounded-custom shadow-sm mb-3">
              <div class="py-2 px-3">
                <p class="text-dark font-weight-bold mb-0"><i class="fas fa-chart-line"></i> &nbsp; {{ charts[1].title }}</p>
              </div>
              <hr class="m-0">
              <div class="py-3 px-3 w-100">
                <HighChart :ref="charts[1].type" :option="charts[1].bodyData"/>
              </div>
            </div>
          </b-col>
        </b-row>
      </div>
    </b-container>
  </div>
</template>
<script>
import { xray } from '../../../config/pluginInit'
import newServices from '../../../services/fatmahost/pegawai/index.ts'

export default {
  name: 'MenuRemun',
  data () {
    return {
      data: {},
      riwayatrekap: {},
      message: '',
      charts: [
        {
          title: 'GRAFIK PASIEN',
          bodyData: {
            title: {
              text: 'Jumlah Pasien Beberapa Bulan Terakhir'
            },
            subtitle: {
              text: 'Rumah Sakit Umum Pusat Fatmawati'
            },
            chart: {
              height: 250
            },
            yAxis: {
              title: {
                text: 'Jumlah Pasien'
              },
              labels: {
                formatter: function () {
                  return this.value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                }
              }
            },
            xAxis: [{
              categories: [],
              reversed: true,
              crosshair: true,
              labels: {
                formatter: function () {
                  return this.value.toUpperCase()
                }
              }
            }],
            legend: {
              layout: 'vertical',
              align: 'left',
              verticalAlign: 'middle'
            },
            plotOptions: {
              series: {
                label: {
                  connectorAllowed: false
                }
              }
            },
            series: [{
              name: 'JKN Pasien',
              reversed: true,
              data: [],
              color: '#28a745'
            }, {
              name: 'Reguler Pasien',
              reversed: true,
              data: [],
              color: '#fbbd3c'
            }, {
              name: 'Executive Pasien',
              reversed: true,
              data: [],
              color: '#00939d'
            }, {
              name: 'Total Pasien',
              reversed: true,
              data: [],
              color: '#bb0000'
            }],
            responsive: {
              rules: [{
                condition: {
                  maxWidth: 992
                },
                chartOptions: {
                  legend: {
                    layout: 'horizontal',
                    align: 'center',
                    verticalAlign: 'bottom'
                  }
                }
              }]
            }
          }
        },
        {
          title: 'GRAFIK REMUN',
          bodyData: {
            title: {
              text: 'Nilai Remun Beberapa Bulan Terakhir'
            },
            subtitle: {
              text: 'Rumah Sakit Umum Pusat Fatmawati'
            },
            chart: {
              height: 250
            },
            yAxis: {
              title: {
                text: 'Nilai Remun'
              },
              labels: {
                formatter: function () {
                  return this.value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                }
              }
            },
            xAxis: [{
              categories: [],
              reversed: true,
              crosshair: true,
              labels: {
                formatter: function () {
                  return this.value.toUpperCase()
                }
              }
            }],
            legend: {
              layout: 'vertical',
              align: 'left',
              verticalAlign: 'middle'
            },
            plotOptions: {
              series: {
                label: {
                  connectorAllowed: false
                }
              }
            },
            series: [{
              name: 'JKN NILAI',
              reversed: true,
              data: [],
              color: '#28a745'
            }, {
              name: 'Reguler NILAI',
              reversed: true,
              data: [],
              color: '#fbbd3c'
            }, {
              name: 'Executive NILAI',
              reversed: true,
              data: [],
              color: '#00939d'
            }, {
              name: 'Total NILAI',
              reversed: true,
              data: [],
              color: '#bb0000'
            }],
            responsive: {
              rules: [{
                condition: {
                  maxWidth: 992
                },
                chartOptions: {
                  legend: {
                    layout: 'horizontal',
                    align: 'center',
                    verticalAlign: 'bottom'
                  }
                }
              }]
            }
          }
        }
      ]
    }
  },
  mounted () {
    xray.index()
    this.loadProfile()
    this.loadriwayatrekap()
    this.grafik()
  },
  methods: {
    toDetail (periode) {
      let id = xray.currentUser().pegawai_id.toString()
      this.$router.push({
        name: 'sdm.remun', params: { id }, query: { periode }
      })
    },
    toDetailRekap (periode) {
      let id = xray.currentUser().pegawai_id.toString()
      this.$router.push({
        name: 'sdm.remundetailrekap', params: { id }, query: { periode }
      })
    },
    async loadProfile () {
      try {
        const id = xray.currentUser().pegawai_id.toString()
        const response = await newServices.loadProfilev2(id)
        this.data = response.data.data
      } catch (error) {
        this.message = error.message
      }
    },
    async loadriwayatrekap () {
      try {
        const id = xray.currentUser().pegawai_id.toString()
        const response = await newServices.dashboardparamv2(id)
        this.riwayatrekap = response.data.data
      } catch (error) {
        this.message = error.message
      }
    },
    async grafik () {
      try {
        const id = xray.currentUser().pegawai_id.toString()
        const response = await newServices.grafikparamv2(id)
        //
        var convertQtyJKN = response.data.data.JKN.QTY.map((str) => parseInt(str, 10))
        var convertQtyEXC = response.data.data.EXECUTIVE.QTY.map((str) => parseInt(str, 10))
        var convertQtyREG = response.data.data.REGULER.QTY.map((str) => parseInt(str, 10))
        var convertQtyTO = response.data.data.TOTAL.QTY.map((str) => parseInt(str, 10))
        this.charts[0].bodyData.xAxis[0].categories = response.data.data.BULAN
        this.charts[0].bodyData.series[0].data = convertQtyJKN
        this.charts[0].bodyData.series[1].data = convertQtyEXC
        this.charts[0].bodyData.series[2].data = convertQtyREG
        this.charts[0].bodyData.series[3].data = convertQtyTO
        //
        var convertNilaiJKN = response.data.data.JKN.NILAI.map((str) => parseInt(str, 10))
        var convertNilaiEXC = response.data.data.EXECUTIVE.NILAI.map((str) => parseInt(str, 10))
        var convertNilaiREG = response.data.data.REGULER.NILAI.map((str) => parseInt(str, 10))
        var convertNilaiTO = response.data.data.TOTAL.NILAI.map((str) => parseInt(str, 10))
        this.charts[1].bodyData.xAxis[0].categories = response.data.data.BULAN
        this.charts[1].bodyData.series[0].data = convertNilaiJKN
        this.charts[1].bodyData.series[1].data = convertNilaiEXC
        this.charts[1].bodyData.series[2].data = convertNilaiREG
        this.charts[1].bodyData.series[3].data = convertNilaiTO
      } catch (error) {
        this.message = error.message
      }
    }
  }
}
</script>
<style>
#dashboard-remun .card-child-remun {
  cursor: pointer;
}
#dashboard-remun .table td {
  font-size: .75rem;
  font-weight: 500;
  padding-top: 0px;
  padding-bottom: 0px;
}
#dashboard-remun .table th {
  font-size: .8rem;
  font-weight: 400;
}
#dashboard-remun .rounded-custom {
  border-radius: 5px;
}
#dashboard-remun .page-link {
    border: none;
    color: gray!important;
    margin-right: 15px;
}
#dashboard-remun .page-item.active .page-link {
    background-color: transparent;
    border-color: transparent;
    color: #089bab!important;
    font-weight: bold;
}
#dashboard-remun .cstm-srch-remun-dash {
  font-size: 13px;
  height: 33px!important;
  border-radius: 2px;
}
#dashboard-remun .cstm-slct-remun-dash {
  font-size: 13px;
  height: 38px!important;
  border-radius: 5px;
}
#dashboard-remun .cstm-select-remun-dash {
  font-size: 13px;
  height: 38px!important;
  border-radius: 5px;
}
#dashboard-remun .list-remun-histori {
  color: black;
  cursor: pointer;
  transition: all ease-in-out .3s;
}
#dashboard-remun .list-remun-histori:hover {
  background: #089bab;
  color: white;
}
#dashboard-remun .scroll-card {
  overflow-y: scroll;
  max-height: 180px;
}
</style>
