<template>
  <div id="penjadwalan-operasi">
    <b-container fluid>
      <div class="bg-white shadow-sm custon-nav-radius p-2">
        <div class="head px-2 py-2 d-flex flex-row border align-items-center">
          <div>
            <p class="m-0 p-0 text-dark font-weight-bold text-uppercase">PENJADWALAN OPERASI <span v-if="preview.tanggal_tabel !== ''">: {{ preview.tanggal_tabel }}</span></p>
          </div>
          <div class="ml-auto">
            <b-button class="mr-2" variant="primary" size="sm" @click.prevent="_openModal"><i class="fas fa-plus"></i> JADWAL</b-button>
            <b-button variant="primary" size="sm" @click.prevent="_movePage"><i class="fas fa-sign-in-alt"></i> KALENDER</b-button>
          </div>
        </div>
        <div class="head border py-2 px-2">
          <b-row class="align-items-center">
            <b-col cols="12" md="2" class="mb-3 mb-lg-0 pr-lg-1">
              <div>
                <flat-pickr :config="configTgl" v-model="preview.tanggal_filter" @on-change="_valueTgl" value="" placeholder="Range Tanggal"
                class="w-100 custom-flatpicker calender-small bg-kuning-input" />
              </div>
            </b-col>
            <b-col cols="12" md="2" class="mb-3 mb-lg-0 px-lg-1">
              <div>
                <form class="searchbox mb-0">
                  <input v-model="preview.norm" @input="_tableRefresh" type="search" class="form-control form-control-op text-dark" id="filter-input"
                    placeholder="No. Rekam Medis...">
                </form>
              </div>
            </b-col>
            <b-col cols="12" md="2" class="mb-3 mb-lg-0 px-lg-1">
              <div>
                <form class="searchbox mb-0">
                  <input v-model="preview.namaPasien" @input="_tableRefresh" type="search" class="form-control form-control-op text-dark" id="filter-input"
                    placeholder="Nama Pasien...">
                </form>
              </div>
            </b-col>
            <b-col class="mb-3 mb-lg-0 pl-lg-1">
              <div>
                <b-button variant="warning" size="sm" @click="_filterReset"><i class="fas fa-sync"></i> Reset</b-button>
              </div>
            </b-col>
          </b-row>
        </div>
        <div class="body border">
          <b-table ref="table" class="m-0 p-0" :items="table.items" :fields="table.fields" :busy="table.isBusy" :current-page="table.currentPage" :per-page="table.perPage" size="sm" responsive stacked="md" bordered hover show-empty small striped>
            <template #table-busy>
              <div class="text-center my-2">
                <b-spinner class="align-middle text-primary mr-2"></b-spinner>
                <strong class="text-dark">Memuat Data...</strong>
              </div>
            </template>
            <template #cell(index)="data">
              <span>{{ (table.currentPage*table.perPage)-table.perPage + data.index + 1 }}</span>
            </template>
            <template #cell(tglorder)="data">
              <span>{{ data.item.tglorder | formatTanggalJam }}</span>
            </template>
            <template #cell(pasien_nomor)="data">
              <span class="rekap-hover" @click="_listFilter(data.item.pasien_nomor, 'norm')">
                <u>{{ data.item.pasien_nomor }}</u>
              </span>
            </template>
            <template #cell(pasien_nama)="data">
              <span class="rekap-hover" @click="_listFilter(data.item.pasien_nama, 'namaPasien')">
                <u>{{ data.item.pasien_nama }}</u>
              </span>
            </template>
          </b-table>
        </div>
        <div class="pagination-remun-dash border shadow-sm p-2 mb-0">
          <b-row class="align-items-center justify-content-center justify-content-lg-between">
            <b-col lg="3" class="d-flex flex-row align-items-center justify-content-center justify-content-lg-between mb-3 mb-lg-0">
              <div class="">
                <b-pagination
                  v-model="table.currentPage"
                  :total-rows="table.totalRows"
                  :per-page="table.perPage"
                  size="md"
                  class="my-0"
                ></b-pagination>
              </div>
              <div class="">
                <div>
                  <span class="border-left border-dark mr-3 py-1"></span>
                  <span @click="_tableRefresh" class="katalog-cursor">
                    <i class="fas fa-sync text-primary"></i>
                  </span>
                  <span class="border-right border-dark ml-3 py-1"></span>
                </div>
              </div>
            </b-col>
            <b-col lg="3">
              <div class="d-flex flex-row align-items-center justify-content-center justify-content-lg-end">
                <div class="mr-3">
                  <b-form-select
                    class="cstm-select-penjadwalan-op"
                    id="per-page-select"
                    v-model="table.perPage"
                    :options="table.pageOptions"
                  ></b-form-select>
                </div>
                <div>
                  <!-- <p class="text-dark mb-0">Menampilkan {{ table.perPage }} dari {{ table.totalRows }} baris</p> -->
                  <p class="text-dark mb-0">Menampilkan {{ table.totalRows }} Operasi</p>
                </div>
              </div>
            </b-col>
          </b-row>
        </div>
      </div>
    </b-container>
    <b-modal id="modal-operasi" ref="modal-operasi" centered scrollable size="lg"  title="BUAT JADWAL" ok-title="SIMPAN" cancel-title="RESET">
      <div>
        <div>
          <p class="text-dark font-weight-bold">PERMINTAAN</p>
        </div>
        <b-row class=" align-items-center mb-2">
          <b-col cols="2">
            <p class="m-0 p-0 text-dark">DOKTER</p>
          </b-col>
          <b-col>
            <b-form-input class="form-control-op" small size="sm" v-model="form.durasi" placeholder="Durasi Operasi"></b-form-input>
          </b-col>
        </b-row>
        <b-row class=" align-items-center mb-2">
          <b-col cols="2">
            <p class="m-0 p-0 text-dark">TANGGAL</p>
          </b-col>
          <b-col>
            <flat-pickr :config="configTgl" v-model="preview.tanggal" value="" placeholder="Tanggal Operasi"
            class="w-100 custom-flatpicker calender-small bg-kuning-input" />
          </b-col>
        </b-row>
        <b-row class=" align-items-center mb-2">
          <b-col cols="2">
            <p class="m-0 p-0 text-dark">JAM</p>
          </b-col>
          <b-col>
            <flat-pickr :config="configTgl" v-model="preview.tanggal" value="" placeholder="Tanggal Operasi"
            class="w-100 custom-flatpicker calender-small bg-kuning-input" />
          </b-col>
        </b-row>
        <b-row class=" align-items-center mb-2">
          <b-col cols="2">
            <p class="m-0 p-0 text-dark">JENIS</p>
          </b-col>
          <b-col>
            <multiselect :options="options.kamar"
              label="deskripsi" placeholder="Pilih Kamar">
            </multiselect>
          </b-col>
        </b-row>
        <b-row class="align-items-center mb-2">
          <b-col cols="2">
            <p class="m-0 p-0 text-dark">DURASI</p>
          </b-col>
          <b-col>
            <b-form-input class="form-control-op" small size="sm" v-model="form.durasi" placeholder="Durasi Operasi"></b-form-input>
          </b-col>
        </b-row>
        <b-row class="align-items-center mb-2">
          <b-col cols="2">
            <p class="m-0 p-0 text-dark">URAIAN</p>
          </b-col>
          <b-col>
            <b-form-input class="form-control-op" small size="sm" v-model="form.durasi" placeholder="Durasi Operasi"></b-form-input>
          </b-col>
        </b-row>
        <b-row class="align-items-center">
          <b-col cols="2">
            <p class="m-0 p-0 text-dark">DIAGNOSA</p>
          </b-col>
          <b-col>
            <b-form-input class="form-control-op" small size="sm" v-model="form.durasi" placeholder="Durasi Operasi"></b-form-input>
          </b-col>
        </b-row>
      </div>
      <hr>
      <div>
        <div>
          <p class="text-dark font-weight-bold">PENJADWALAN</p>
        </div>
        <b-row class=" align-items-center mb-2">
          <b-col cols="2">
            <p class="m-0 p-0 text-dark">DURASI</p>
          </b-col>
          <b-col>
            <b-form-input class="form-control-op" small size="sm" v-model="form.durasi" placeholder="Durasi Operasi"></b-form-input>
          </b-col>
        </b-row>
        <b-row class=" align-items-center mb-2">
          <b-col cols="2">
            <p class="m-0 p-0 text-dark">TANGGAL</p>
          </b-col>
          <b-col>
            <flat-pickr :config="configTgl" v-model="preview.tanggal" value="" placeholder="Tanggal Operasi"
            class="w-100 custom-flatpicker calender-small bg-kuning-input" />
          </b-col>
        </b-row>
        <b-row class=" align-items-center mb-2">
          <b-col cols="2">
            <p class="m-0 p-0 text-dark">KAMAR</p>
          </b-col>
          <b-col>
            <multiselect :options="options.kamar"
              label="deskripsi" placeholder="Pilih Kamar">
            </multiselect>
          </b-col>
        </b-row>
        <b-row class="align-items-center">
          <b-col cols="2">
            <p class="m-0 p-0 text-dark">JAM</p>
          </b-col>
          <b-col>
            <flat-pickr :config="configJam" v-model="preview.time" value="" placeholder="Jam Operasi"
            class="w-100 custom-flatpicker calender-small bg-kuning-input" />
          </b-col>
        </b-row>
      </div>
    </b-modal>
  </div>
</template>
<script>
import { xray } from '../../../config/pluginInit'
import Multiselect from 'vue-multiselect'
import flatPickr from 'vue-flatpickr-component'
import { Indonesian as IndonesianLocale } from 'flatpickr/dist/l10n/id.js'
import 'flatpickr/dist/flatpickr.css'
import Services from '../../../services/fatmahost/index'

export default {
  name: 'PenjadwalanOperasi',
  components: {
    Multiselect,
    flatPickr
  },
  data () {
    return {
      table: {
        isBusy: false,
        fields: [
          { label: 'No', key: 'index', class: 'text-center py-2' },
          { label: 'TGL. ORDER', key: 'tglorder' },
          { label: 'NO. ORDER', key: 'order_nomor' },
          { label: 'DOKTER ORDER', key: 'order_dokter' },
          { label: 'NORM', key: 'pasien_nomor' },
          { label: 'NAMA', key: 'pasien_nama' },
          { label: 'RUANG ORDER', key: 'order_ruang' },
          // { label: 'RUANG ORDER TJNID', key: 'order_ruang_tjnid' },
          { label: 'RUANG ORDER TUJUAN', key: 'order_ruang_tjn' }
        ],
        items: this._list,
        perPage: 10,
        currentPage: 1,
        pageOptions: [10, 25, 50, 100],
        totalRows: null
      },
      configTgl: {
        locale: IndonesianLocale,
        disableMobile: true,
        altInput: true,
        altFormat: 'j M, Y',
        dateFormat: 'Y-m-d',
        maxDate: 'today',
        mode: 'range'
      },
      configJam: {
        locale: IndonesianLocale,
        enableTime: true,
        noCalendar: true,
        dateFormat: 'H:i',
        time_24hr: true
      },
      form: {
        durasi: '',
        tanggal: '',
        kamar: '',
        jam: ''
      },
      options: {
        kamar: []
      },
      preview: {
        tanggal: null,
        time: null,
        //
        tanggal_filter: null,
        tanggal_awal: null,
        tanggal_akhir: null,
        tanggal_tabel: '',
        norm: '',
        namaPasien: ''
      }
    }
  },
  mounted () {
    xray.index()
    this._getTodayLastFirstDate()
  },
  methods: {
    _openModal () {
      this.$refs['modal-operasi'].show()
    },
    _movePage () {
      this.$router.push({ name: 'penjadwalan.kalender' })
    },
    async _list (ctx) {
      this.isBusy = true
      try {
        const body = `{
            "param1": "",
            "param2": {
              "object": "LYNOPERASI_order",
              "xfilter1": "${this.preview.tanggal_awal}",
              "xfilter2": "${this.preview.tanggal_akhir}",
              "xfilter3": "${this.preview.norm}",
              "xfilter4": "${this.preview.namaPasien}",
              "xpage": "${ctx.currentPage - 1}",
              "xlimit": "${ctx.perPage}"
          }
        }`
        const response = await Services.middleware.find_v2(body)
        if (response.data.statcode === 0) {
          this.table.isBusy = false
          this.table.totalRows = response.data.pagination.total
          this.table.perPage = response.data.pagination.perPage
          this.table.currentPage = response.data.pagination.current + 1
          return response.data.data
        } else {
          this.table.isBusy = false
          return []
        }
      } catch (e) {
        this.table.isBusy = false
        this.preview.message = e.message
        return []
      }
    },
    _valueTgl (e) {
      this.preview.tanggal_awal = formated(new Date(e[0]))
      this.preview.tanggal_akhir = formated(new Date(e[1]))
      this.preview.tanggal_tabel = this._convertTanggal(this.preview.tanggal_awal.split('-').reverse().join('-')) + ' - ' + this._convertTanggal(this.preview.tanggal_akhir.split('-').reverse().join('-'))
      function formated (value) {
        const day = String(value.getDate()).padStart(2, '0')
        const month = String(value.getMonth() + 1).padStart(2, '0')
        const year = String(value.getFullYear())
        return year + '-' + month + '-' + day
      }
      this.table.currentPage = 1
      this.$refs.table.refresh()
    },
    _convertTanggal (value) {
      const [day, month, year] = value.split('-')
      const date = new Date(`${year}-${month}-${day}`)
      const options = { year: 'numeric', month: 'long', day: 'numeric' }
      const tanggalDalamBahasaIndonesia = date.toLocaleDateString('id-ID', options)
      return tanggalDalamBahasaIndonesia
    },
    _getTodayLastFirstDate () {
      function padZero (value) {
        return value < 10 ? '0' + value : value
      }
      const today = new Date()
      const startOfMonth = new Date(today.getFullYear(), today.getMonth(), 1)
      // endOfMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0)
      const startFormatted = `${startOfMonth.getFullYear()}-${padZero(startOfMonth.getMonth() + 1)}-${padZero(startOfMonth.getDate())}`
      const endFormatted = `${today.getFullYear()}-${padZero(today.getMonth() + 1)}-${padZero(today.getDate())}`
      this.preview.tanggal_filter = startFormatted + ' - ' + endFormatted
      this.preview.tanggal_tabel = this._convertTanggal(String(startFormatted.split('-').reverse().join('-'))) + ' - ' + this._convertTanggal(String(endFormatted.split('-').reverse().join('-')))
      this.preview.tanggal_awal = String(startFormatted)
      this.preview.tanggal_akhir = String(endFormatted)
    },
    _listFilter (e, t) {
      this.preview[`${t}`] = e
      this.table.currentPage = 1
      this.$refs.table.refresh()
    },
    _filterReset () {
      this.preview.norm = ''
      this.preview.namaPasien = ''
      this.table.currentPage = 1
      this.$refs.table.refresh()
    },
    _tableRefresh () {
      this.table.currentPage = 1
      this.$refs.table.refresh()
    }
  }
}
</script>

<style>
#penjadwalan-operasi .form-control-op, #modal-operasi .form-control-op {
  height: 33px!important;
  line-height: 33px!important;
  border-radius: 3px!important;
  font-size: 12px!important;
}
#penjadwalan-operasi .cstm-select-penjadwalan-op {
  font-size: 13px;
  height: 38px!important;
  border-radius: 5px;
}
#penjadwalan-operasi .page-link {
    border: none;
    color: gray!important;
    margin-right: 15px;
}
#penjadwalan-operasi .page-item.active .page-link {
    background-color: transparent;
    border-color: transparent;
    color: #089bab!important;
    font-weight: bold;
}
#penjadwalan-operasi .table td {
    font-size: 12px;
    font-weight: 500;
    padding-top: 0px;
    padding-bottom: 0px;
}
#penjadwalan-operasi .table th {
    font-size: 13px;
    font-weight: 400;
}
#penjadwalan-operasi .multiselect__single, #modal-operasi .multiselect__single {
  font-size: 13px !important;
}
#penjadwalan-operasi .multiselect__placeholder, #modal-operasi .multiselect__placeholder {
  font-weight: normal !important;
  font-size: 13px !important;
  padding-top: 0px!important;
}
#penjadwalan-operasi .multiselect__option, #modal-operasi .multiselect__option {
  font-size: 13px;
}
#penjadwalan-operasi .multiselect__tags, #modal-operasi .multiselect__tags {
  padding: 5px 40px 0 8px !important;
  height: 33px !important;
  min-height: 33px !important;
  border: 1px solid rgb(214, 214, 214);
}
#penjadwalan-operasi .multiselect__content-wrapper, #modal-operasi .multiselect__content-wrapper {
  font-size: 12px;
  font-weight: normal !important;
}
#modal-operasi .custom-flatpicker, #penjadwalan-operasi .custom-flatpicker {
  height: 33px;
  padding-left: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
  border-radius: 3px;
  border: 1px solid rgb(228, 228, 228);
  background-color: white!important;
  font-size: 0.8rem;
  color: black;
}
#penjadwalan-operasi .rekap-hover {
  cursor: pointer;
}
</style>
