<template>
  <div id="module-list" class="mb-4">
    <div v-show="isLoading">
    <div id="show-overlay"></div>
    <Loader :style="{ opacity: .5 }" />
  </div>
    <b-container fluid class="mb-5">
      <iq-card>
        <div class="px-4 py-3 d-flex align-items-center justify-content-between">
          <div>
            <h4 class="card-title  mb-0">Data Modules</h4>
          </div>
          <div>
            <b-button variant="primary" @click="modalModul('e', '1')"><i class="fas fa-plus"></i> Tambah Module</b-button>
          </div>
        </div>
        <hr class="m-0 p-0">
        <div class="body p-4">
          <div class="table mb-0">
              <b-table class="mb-0" ref="table" :filter="table.filter"
                  @filtered="onFiltered" :busy="table.isBusy" :items="table.items" :fields="table.fields"
                :current-page="table.currentPage" :per-page="table.perPage" stacked="md"
                bordered hover show-empty small striped>
                <template #cell(index)="data">
                  {{ (table.currentPage*table.perPage)-table.perPage + data.index + 1 }}
                </template>
                <template #cell(action)="data">
                  <b-button variant="white" class="my-1 mx-1" size="md" @click="modalModul(data.item.id, '2')">
                    <i class="fas fa-edit text-primary"></i>
                  </b-button>
                  <b-button variant="white" class="my-1 mx-1" size="md" @click="modalModul(data.item.id, '3')">
                    <i class="fas fa-trash-alt text-danger"></i>
                  </b-button>
                </template>
              </b-table>
            </div>
            <div class="bg-white pb-2 pt-2 pl-3 pr-3 border-bottom border-right border-left">
              <b-row class="align-items-center justify-content-between">
                <b-col cols="12" lg="3" class="mr-auto">
                  <div class="d-flex align-items-center">
                    <div>
                      <b-pagination v-model="table.currentPage" :total-rows="table.totalRows" :per-page="table.perPage" size="sm" class="my-0">
                      </b-pagination>
                    </div>
                    <div class="border-left border-right px-3">
                      <span class="cursor-point" @click="tableRefresh">
                        <i class="fas fa-sync"></i>
                      </span>
                    </div>
                  </div>
                </b-col>
                <b-col cols="12" lg="5">
                  <div class="d-flex flex-row">
                    <div class="mr-2 w-75">
                      <b-input-group>
                        <b-form-input class="cstm-slct-pegawai rounded-0" id="filter-input" v-model="table.filter" type="search" placeholder="Pencarian"></b-form-input>
                      </b-input-group>
                    </div>
                    <div>
                      <b-form-select class="cstm-slct-pegawai" v-model="selected.filterType" :options="combo.filterType" @change="filterchange"></b-form-select>
                    </div>
                  </div>
                </b-col>
                <b-col cols="12" lg="3">
                  <span class="text-right">
                    <p class="font-weight-normal mb-0">Menampilkan 1 - {{ table.perPage }} dari {{ table.totalRows }}</p>
                  </span>
                </b-col>
              </b-row>
            </div>
            <!-- <div class="bg-white pb-2 pt-2 pl-3 pr-3 border-bottom border-right border-left">
              <b-row class="align-items-center justify-content-between">
                <b-col cols="12" lg="3" class="mr-auto">
                  <div class="d-flex align-items-center">
                    <div>
                      <b-pagination v-model="table.currentPage" :total-rows="table.totalRows" :per-page="table.perPage" align="" size="md" class="my-0">
                      </b-pagination>
                    </div>
                    <div class="border-left border-right px-3">
                      <span class="cursor-point" @click="tableRefresh">
                        <i class="fas fa-sync"></i>
                      </span>
                    </div>
                  </div>
                </b-col>
                <b-col cols="12" lg="5">
                  <div class="d-flex flex-row align-items-center justify-content-end w-100">
                    <div class="mr-3">
                      <b-form-select
                        class="cstm-select-module"
                        id="per-page-select"
                        v-model="table.perPage"
                        :options="table.pageOptions"
                      ></b-form-select>
                    </div>
                    <div>
                      <p class="small text-dark mb-0">Menampilkan {{ table.perPage }} dari {{ table.totalRows }} baris</p>
                    </div>
                  </div>
                </b-col>
              </b-row>
            </div> -->
        </div>
      </iq-card>
    </b-container>
    <div id="modal-component">
      <b-modal ref="modal-module" id="modal-module" size="lg" :title="modalTitle" ok-title="Oke" @ok="proccess" @cancel="clear" @close="clear" :ok-only="true" :no-close-on-backdrop="true" centered>
        <b-form v-if="modulIndex === '1'">
          <!-- <div class="mb-2">
            <b-row class="align-items-center">
              <b-col cols="3">
                <p class="txt-module-base text-dark mb-0">id_ref :</p>
              </b-col>
              <b-col>
                <b-form-input v-model="created.id_ref" @keypress="isNumberKey" class="cstm-cntrl-module" size="sm" placeholder="id_ref"></b-form-input>
              </b-col>
            </b-row>
          </div> -->
          <div class="mb-2">
            <b-row class="align-items-center">
              <b-col cols="3">
                <p class="txt-module-base text-dark mb-0">Nama :</p>
              </b-col>
              <b-col>
                <b-form-input v-model="created.name" class="cstm-cntrl-module" size="sm" placeholder="Nama Module"></b-form-input>
              </b-col>
            </b-row>
          </div>
          <!-- <div class="mb-2">
            <b-row class="align-items-center">
              <b-col cols="3">
                <p class="txt-module-base text-dark mb-0">title :</p>
              </b-col>
              <b-col>
                <b-form-input v-model="created.title" class="cstm-cntrl-module" size="sm" placeholder="title"></b-form-input>
              </b-col>
            </b-row>
          </div> -->
          <!-- <div class="mb-2">
            <b-row class="align-items-center">
              <b-col cols="3">
                <p class="txt-module-base text-dark mb-0">level :</p>
              </b-col>
              <b-col>
                <b-form-input v-model="created.level" @keypress="isNumberKey" class="cstm-cntrl-module" size="sm" placeholder="level"></b-form-input>
              </b-col>
            </b-row>
          </div> -->
          <!-- <div class="mb-2">
            <b-row class="align-items-center">
              <b-col cols="3">
                <p class="txt-module-base text-dark mb-0">is_heading :</p>
              </b-col>
              <b-col>
                <b-form-input v-model="created.is_heading" class="cstm-cntrl-module" size="sm" placeholder="is_heading" readonly></b-form-input>
              </b-col>
            </b-row>
          </div> -->
          <!-- <div class="mb-2">
            <b-row class="align-items-center">
              <b-col cols="3">
                <p class="txt-module-base text-dark mb-0">description :</p>
              </b-col>
              <b-col>
                <b-form-input v-model="created.description" class="cstm-cntrl-module" size="sm" placeholder="description"></b-form-input>
              </b-col>
            </b-row>
          </div> -->
          <!-- <div class="mb-2">
            <b-row class="align-items-center">
              <b-col cols="3">
                <p class="txt-module-base text-dark mb-0">is_active :</p>
              </b-col>
              <b-col>
                <b-form-input v-model="created.is_active" class="cstm-cntrl-module" size="sm" placeholder="is_active" readonly></b-form-input>
              </b-col>
            </b-row>
          </div> -->
          <div class="mb-2">
            <b-row class="align-items-center">
              <b-col cols="3">
                <p class="txt-module-base text-dark mb-0">Link :</p>
              </b-col>
              <b-col>
                <b-form-input v-model="created.link" class="cstm-cntrl-module" size="sm" placeholder="Link / Nama Router"></b-form-input>
              </b-col>
            </b-row>
          </div>
          <!-- <div class="mb-2">
            <b-row class="align-items-center">
              <b-col cols="3">
                <p class="txt-module-base text-dark mb-0">class_name :</p>
              </b-col>
              <b-col>
                <b-form-input v-model="created.class_name" class="cstm-cntrl-module" size="sm" placeholder="class_name"></b-form-input>
              </b-col>
            </b-row>
          </div> -->
          <!-- <div class="mb-2">
            <b-row class="align-items-center">
              <b-col cols="3">
                <p class="txt-module-base text-dark mb-0">is_icon_class :</p>
              </b-col>
              <b-col>
                <b-form-input v-model="created.is_icon_class" class="cstm-cntrl-module" size="sm" placeholder="is_icon_class" readonly></b-form-input>
              </b-col>
            </b-row>
          </div> -->
          <div class="mb-0">
            <b-row class="align-items-center">
              <b-col cols="3">
                <p class="txt-module-base text-dark mb-0">Icon :</p>
              </b-col>
              <b-col>
                <b-form-input v-model="created.icon" class="cstm-cntrl-module" size="sm" placeholder="Icon"></b-form-input>
              </b-col>
            </b-row>
          </div>
        </b-form>
        <b-form v-else-if="modulIndex === '2'">
          <!-- <div class="mb-2">
            <b-row class="align-items-center">
              <b-col cols="3">
                <p class="txt-module-base text-dark mb-0">id_ref :</p>
              </b-col>
              <b-col>
                <b-form-input v-model="edited.id_ref" @keypress="isNumberKey" class="cstm-cntrl-module" size="sm" placeholder="id_ref"></b-form-input>
              </b-col>
            </b-row>
          </div> -->
          <div class="mb-2">
            <b-row class="align-items-center">
              <b-col cols="3">
                <p class="txt-module-base text-dark mb-0">name :</p>
              </b-col>
              <b-col>
                <b-form-input v-model="edited.name" class="cstm-cntrl-module" size="sm" placeholder="name"></b-form-input>
              </b-col>
            </b-row>
          </div>
          <!-- <div class="mb-2">
            <b-row class="align-items-center">
              <b-col cols="3">
                <p class="txt-module-base text-dark mb-0">title :</p>
              </b-col>
              <b-col>
                <b-form-input v-model="edited.title" class="cstm-cntrl-module" size="sm" placeholder="title"></b-form-input>
              </b-col>
            </b-row>
          </div> -->
          <!-- <div class="mb-2">
            <b-row class="align-items-center">
              <b-col cols="3">
                <p class="txt-module-base text-dark mb-0">level :</p>
              </b-col>
              <b-col>
                <b-form-input v-model="edited.level" @keypress="isNumberKey" class="cstm-cntrl-module" size="sm" placeholder="level"></b-form-input>
              </b-col>
            </b-row>
          </div> -->
          <!-- <div class="mb-2">
            <b-row class="align-items-center">
              <b-col cols="3">
                <p class="txt-module-base text-dark mb-0">is_heading :</p>
              </b-col>
              <b-col>
                <b-form-input v-model="edited.is_heading" class="cstm-cntrl-module" size="sm" placeholder="is_heading" readonly></b-form-input>
              </b-col>
            </b-row>
          </div> -->
          <!-- <div class="mb-2">
            <b-row class="align-items-center">
              <b-col cols="3">
                <p class="txt-module-base text-dark mb-0">description :</p>
              </b-col>
              <b-col>
                <b-form-input v-model="edited.description" class="cstm-cntrl-module" size="sm" placeholder="description"></b-form-input>
              </b-col>
            </b-row>
          </div> -->
          <!-- <div class="mb-2">
            <b-row class="align-items-center">
              <b-col cols="3">
                <p class="txt-module-base text-dark mb-0">is_active :</p>
              </b-col>
              <b-col>
                <b-form-input v-model="edited.is_active" class="cstm-cntrl-module" size="sm" placeholder="is_active" readonly></b-form-input>
              </b-col>
            </b-row>
          </div> -->
          <div class="mb-2">
            <b-row class="align-items-center">
              <b-col cols="3">
                <p class="txt-module-base text-dark mb-0">link :</p>
              </b-col>
              <b-col>
                <b-form-input v-model="edited.link" class="cstm-cntrl-module" size="sm" placeholder="link"></b-form-input>
              </b-col>
            </b-row>
          </div>
          <!-- <div class="mb-2">
            <b-row class="align-items-center">
              <b-col cols="3">
                <p class="txt-module-base text-dark mb-0">class_name :</p>
              </b-col>
              <b-col>
                <b-form-input v-model="edited.class_name" class="cstm-cntrl-module" size="sm" placeholder="class_name"></b-form-input>
              </b-col>
            </b-row>
          </div> -->
          <!-- <div class="mb-2">
            <b-row class="align-items-center">
              <b-col cols="3">
                <p class="txt-module-base text-dark mb-0">is_icon_class :</p>
              </b-col>
              <b-col>
                <b-form-input v-model="edited.is_icon_class" class="cstm-cntrl-module" size="sm" placeholder="is_icon_class" readonly></b-form-input>
              </b-col>
            </b-row>
          </div> -->
          <div class="mb-0">
            <b-row class="align-items-center">
              <b-col cols="3">
                <p class="txt-module-base text-dark mb-0">icon :</p>
              </b-col>
              <b-col>
                <b-form-input v-model="edited.icon" class="cstm-cntrl-module" size="sm" placeholder="icon"></b-form-input>
              </b-col>
            </b-row>
          </div>
        </b-form>
        <!-- <div>
          <pre>{{ created }}</pre>
          <pre>{{ edited }}</pre>
        </div> -->
        <!-- {{ typeof moduleId }} -->
      </b-modal>
      <ModalMsgbox ref="MsgBox" />
    </div>
  </div>
</template>
<script>
import { xray } from '../../../config/pluginInit'
import Service from '../../../services/fatmahost/index'
import Loader from '../../../components/xray/loader/Loader'
import ModalMsgbox from '../../../components/modal/ModalMsgBox'

export default {
  name: 'ModuleList',
  components: {
    Loader,
    ModalMsgbox
  },
  data () {
    return {
      filter: '',
      messageId: '',
      message: '',
      isLoading: false,
      module: null,
      submodule: null,
      moduleId: '',
      modulIndex: '',
      modalTitle: '',
      table: {
        isBusy: false,
        items: this.list,
        fields: [
          { label: 'No', key: 'index', class: 'text-center' },
          { label: 'ID', key: 'id' },
          { label: 'Nama', key: 'name' },
          // { label: 'Level', key: 'level' },
          { label: 'Icon', key: 'icon' },
          { label: 'Link', key: 'link' },
          { label: 'Aksi', key: 'action', class: 'text-center' }
        ],
        currentPage: null,
        perPage: null,
        pageOptions: [10, 25, 50, 100],
        totalRows: null,
        filter: ''
      },
      selected: {
        filterType: '2'
      },
      combo: {
        filterType: [
          // { value: '1', text: 'ID REF' },
          { value: '2', text: 'NAMA' }
        ]
      },
      created: {
        object: 'module',
        id_ref: '',
        name: '',
        title: '',
        level: 0,
        is_heading: 0,
        description: '',
        is_active: 0,
        link: '',
        class_name: '',
        is_icon_class: 1,
        icon: ''
      },
      edited: {
        object: 'module',
        id_ref: '',
        name: '',
        title: '',
        level: 0,
        is_heading: 0,
        description: '',
        is_active: 0,
        link: '',
        class_name: '',
        is_icon_class: 1,
        icon: '',
        created_at: ''
      }
    }
  },
  mounted () {
    xray.index()
  },
  methods: {
    filterchange () {
      this.table.filter = ''
    },
    onFiltered () {
    },
    isNumberKey (evt) {
      evt = (evt) || window.event
      var charCode = (evt.which) ? evt.which : evt.keyCode
      if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
        evt.preventDefault()
      } else {
        return true
      }
    },
    tableRefresh () {
      this.$refs.table.refresh()
    },
    async list (ctx) {
      this.isBusy = true
      let filterType = this.selected.filterType
      let filters = ''
      if (ctx.filter === '') {
        ctx.filter = null
        filters = `{"xparam0":"","xparam1":""}`
      }
      if (ctx.filter === null) {
        filters = `{"xparam0":"","xparam1":""}`
      } else {
        if (filterType === '1') {
          filters = `{"xparam0":"${ctx.filter}","xparam1":""}`
        } else if (filterType === '2') {
          filters = `{"xparam0":"","xparam1":"${ctx.filter}"}`
        }
      }
      try {
        const response = await Service.module.findAll(ctx.currentPage - 1, ctx.perPage, filters)
        // const rowData = response.data.data
        // const realData = rowData.filter(function (data) {
        //   return data.level === 1
        // })
        this.isBusy = false
        this.table.totalRows = response.data.pagination.total
        this.table.perPage = response.data.pagination.perPage
        this.table.currentPage = response.data.pagination.current
        return response.data.data
      } catch (error) {
        this.isBusy = false
        return []
      }
    },
    modalModul (event, param) {
      this.moduleId = event
      this.modulIndex = param
      if (param === '1') {
        this.modalTitle = 'Tambah Modul'
      } else if (param === '2') {
        this.modalTitle = 'Edit Modul'
        this.showModul()
      } else if (param === '3') {
        this.deleteModul()
      }
      if (param !== '3') {
        this.$refs['modal-module'].show()
      }
    },
    proccess () {
      if (this.modulIndex === '1') {
        this.createModul()
      } else if (this.modulIndex === '2') {
        this.editModul()
      } else if (this.modulIndex === '3') {
        this.deleteModul()
      }
    },
    async createModul () {
      this.isLoading = true
      this.created.link = '{"name":"' + this.created.link + '"}'
      this.created.title = this.created.name
      this.created.description = this.created.name
      let data = this.created
      await Service.module.create(data).then(response => {
        this.$refs.MsgBox.show({
          type: 'success',
          msg: response.data.message
        })
      }).catch(error => {
        this.$refs.MsgBox.show({
          type: '',
          msg: error.response.data.message
        })
      }).finally(() => {
        this.$refs.table.refresh()
        this.isLoading = false
        this.clear()
      })
    },
    async editModul () {
      this.isLoading = true
      let id = this.moduleId
      let data = this.edited
      await Service.module.update(id, data).then(response => {
        this.$refs.MsgBox.show({
          type: 'success',
          msg: response.data.message
        })
      }).catch(error => {
        this.$refs.MsgBox.show({
          type: '',
          msg: error.response.data.message
        })
      }).finally(() => {
        this.$refs.table.refresh()
        this.isLoading = false
        this.clear()
      })
    },
    async deleteModul () {
      this.isLoading = true
      let id = this.moduleId
      await Service.module.delete(id).then(response => {
        this.$refs.MsgBox.show({
          type: 'success',
          msg: response.data.message
        })
      }).catch(error => {
        this.$refs.MsgBox.show({
          type: '',
          msg: error.response.data.message
        })
      }).finally(() => {
        this.$refs.table.refresh()
        this.isLoading = false
      })
    },
    async showModul () {
      let id = this.moduleId
      await Service.module.findId(id).then(response => {
        this.edited.id_ref = response.data.data[0].id_ref
        this.edited.name = response.data.data[0].name
        this.edited.title = response.data.data[0].title
        this.edited.level = response.data.data[0].level.toString()
        this.edited.is_heading = response.data.data[0].is_heading.toString()
        this.edited.description = response.data.data[0].description
        this.edited.is_active = response.data.data[0].is_active.toString()
        this.edited.link = response.data.data[0].link
        this.edited.class_name = response.data.data[0].class_name
        this.edited.is_icon_class = response.data.data[0].is_icon_class.toString()
        this.edited.icon = response.data.data[0].icon
        this.edited.created_at = response.data.data[0].created_at
        this.$refs.table.refresh()
      }).catch(error => {
        this.message = error.response
        this.$refs.table.refresh()
      })
    },
    clear () {
      this.created.object = 'module'
      this.created.id_ref = ''
      this.created.name = ''
      this.created.title = ''
      this.created.level = 0
      this.created.is_heading = 0
      this.created.description = ''
      this.created.is_active = 0
      this.created.link = ''
      this.created.class_name = ''
      this.created.is_icon_class = 1
      this.created.icon = ''
      this.edited.object = 'module'
      this.edited.id_ref = ''
      this.edited.name = ''
      this.edited.title = ''
      this.edited.level = 0
      this.edited.is_heading = 0
      this.edited.description = ''
      this.edited.is_active = 0
      this.edited.link = ''
      this.edited.class_name = ''
      this.edited.is_icon_class = 1
      this.edited.icon = ''
      this.edited.created_at = ''
    }
  }
}
</script>
<style>
#module-list .form-control {
  font-weight: bold!important;
  color: black!important;
}
#module-list .fc-unthemed .fc-toolbar .fc-button {
    margin: 3px;
}
#module-list tr:first-child > td > .fc-day-grid-event {
    cursor: pointer;
}
#module-list .multiselect__input, .multiselect {
  font-weight: bold!important;
}
td.fc-day.fc-past {
  background-color: #EEEEEE;
}
#module-list .multiselect__single {
  font-size: 12px!important;
}
#module-list .multiselect__placeholder {
  font-size: 12px!important;
}
#module-list .multiselect__option {
  font-size: 12px;
}
#module-list .multiselect__tags {
  padding: 5px 40px 0 8px!important;
  height: 38px!important;
  min-height: 38px!important;
}
#module-list .flex-half-width-left {
  width: 76%;
}
#module-list .flex-half-width-right {
  width: 23%;
}
#module-list .flex-full-width-left {
  width: 96%;
}
#module-list .flex-full-width-right {
  width: 3%;
}
#module-list .txt-module-base, #modal-module .txt-module-base {
  font-size: 12px;
}
#module-list .txt-module-base-head {
  font-size: 14px;
}
#module-list .table td {
    font-size: 12px;
    font-weight: 500;
    padding-top: 0px;
    padding-bottom: 0px;
}
#module-list .table th {
    font-size: 13px;
    font-weight: 400;
}
#module-list .form-control {
    border: 1px solid rgb(224, 224, 224);
    background: white;
    height: 40px;
}
#module-list .multiselect__tags {
    border-radius: 0px;
    border: 1px solid rgb(224, 224, 224);
    background: white;
    font-size: 11px;
    font-weight: 550;
    height: 10px;
}
#module-list .btn-height-custom {
    height: 39px;
}
#module-list .cursor-point {
    cursor: pointer;
}
#module-list .scroll-y {
    overflow-y: scroll;
    height: 550px;
}
#module-list .bg-abu {
  background: rgba(236, 236, 236, 0.541);
}
#module-list .form-control {
    font-size: 11px;
    font-weight: 550;
}
#module-list .b-table-sticky-header, #module-list .table-responsive {
    margin-bottom: 0px;
}
#module-list .table-barang .btn {
    font-size: 11px;
}
#module-list .page-link {
    border: none;
    color: gray!important;
    margin-right: 10px!important;
}
#module-list .page-item.active .page-link {
    background-color: transparent;
    border-color: transparent;
    color: #089bab!important;
    font-weight: bold;
}
#module-list .vertical-txt {
  writing-mode: vertical-rl;
  letter-spacing: 10px;
}
#module-list .transition-cstm {
  transition: .3s linear;
}
.cstm-cntrl-module {
  border-radius: 5px!important;
  font-size: 13px!important;
  height: 38px!important;
}
#module-list .modal-dialog {
  min-width: 65%!important;
}
.cstm-slct-kuota {
  font-size: 13px!important;
  height: 38px!important;
  border-radius: 5px;
}
.alert-radius-kuota {
  border-radius: 5px;
}
</style>
