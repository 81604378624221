<template>
  <div id="katalog-pbf" class="mb-4">
    <b-container fluid>
      <div>
        <iq-card>
          <template v-slot:body>
            <div class="filter-pbf border p-2">
              <b-row class="align-items-center">
                <b-col cols="12" lg="3" class="d-flex flex-row w-100 mr-auto">
                  <div>
                    <h5 class="card-title font-weight-bold mb-0">Master PBF</h5>
                  </div>
                </b-col>
                <b-col cols="12" lg="9">
                  <div class="text-right">
                    <b-button class="custom-button-radius" size="sm" variant="primary" @click="tambahModal"><i class="fas fa-plus"></i> Tambah</b-button>
                  </div>
                </b-col>
              </b-row>
            </div>
            <div class="table mb-0">
              <b-table class="mb-0" ref="table" :sort-by.sync="table.sortBy" :sort-desc.sync="table.sortDesc" :busy="table.isBusy" :items="table.items" :fields="table.fields"
                :current-page="table.currentPage" :per-page="table.perPage" :filter="table.filter" @filtered="onFiltered" stacked="md"
                bordered hover show-empty small striped>
                <template #cell(index)="data">
                {{ (table.currentPage*table.perPage)-table.perPage + data.index + 1 }}
                </template>
                <template #cell(action)="data">
                  <b-button
                    @click="editModal(data.item.id)"
                    variant=" mr-1 mb-1" size="lg">
                    <i class="fas fa-edit text-primary"></i>
                  </b-button>
                  <b-button
                    @click="deleteModal(data.item.id)"
                    variant=" mr-1 mb-1" size="lg">
                    <i class="fas fa-trash-alt text-danger"></i>
                  </b-button>
                </template>
                <template #cell(sysdate_updt)="data">
                  <span>{{ new Date(data.item.sysdate_updt) | formatDate }}</span>
                </template>
              </b-table>
            </div>
            <div class="pagination-pbf border shadow-sm p-2 mb-2">
              <b-row class="align-items-center">
                <b-col cols="4" class="d-flex flex-row align-items-center">
                  <div class="w-100">
                    <b-pagination
                      v-model="table.currentPage"
                      :total-rows="table.totalRows"
                      :per-page="table.perPage"
                      size="md"
                      class="my-0"
                    ></b-pagination>
                  </div>
                  <div class="w-100">
                    <div>
                      <span class="border-left border-dark mr-3 py-1"></span>
                      <span @click="tableRefresh" class="katalog-cursor">
                        <i class="fas fa-sync text-secondary"></i>
                      </span>
                      <span class="border-right border-dark ml-3 py-1"></span>
                    </div>
                  </div>
                </b-col>
                <b-col cols="5">
                  <div class="d-flex flex-row justify-content-end align-items-center">
                    <div class="mr-1 w-75">
                      <form class="searchbox mb-0">
                        <input v-model="table.filter" type="search" class="form-control cstm-srch-pbf" id="filter-input"
                          placeholder="[Cari]">
                      </form>
                    </div>
                    <div>
                      <b-form-select class="cstm-slct-pbf" v-model="selected.filter" :options="options.optionsFilter" @change="filterchange"></b-form-select>
                    </div>
                  </div>
                </b-col>
                <b-col cols="3">
                  <div class="d-flex flex-row align-items-center justify-content-end">
                    <div class="mr-3">
                      <b-form-select
                        class="cstm-select-pbf"
                        id="per-page-select"
                        v-model="table.perPage"
                        :options="table.pageOptions"
                      ></b-form-select>
                    </div>
                    <div>
                      <p class="text-dark mb-0">Menampilkan {{ table.perPage }} dari {{ table.totalRows }} baris</p>
                    </div>
                  </div>
                </b-col>
              </b-row>
            </div>
          </template>
        </iq-card>
      </div>
    </b-container>
    <div id="modal-component-pbf">
      <b-modal id="modal-tambah-pbf" ref="modal-tambah-pbf" size="lg" centered title="Tambah Pbf" ok-title="Simpan Data" cancel-title="Tutup" @ok="tambahSave" @close="clear" @cancel="clear" :no-close-on-backdrop="true" :static="true">
        <b-form>
          <b-form-group class="mb-2">
            <b-row class="align-items-center">
              <b-col cols="2">
                <span>
                  <p class="small text-dark mb-0">Kode</p>
                </span>
              </b-col>
              <b-col>
                <b-form-input class="new-cstm-cntrl-katalog" v-model="tambahPbf.kode" placeholder="Enter Kode"></b-form-input>
              </b-col>
            </b-row>
          </b-form-group>
          <b-form-group class="mb-2">
            <b-row class="align-items-center">
              <b-col cols="2">
                <span>
                  <p class="small text-dark mb-0">NPWP</p>
                </span>
              </b-col>
              <b-col>
                <b-form-input class="new-cstm-cntrl-katalog" v-model="tambahPbf.npwp" placeholder="NPWP Pbf"></b-form-input>
              </b-col>
            </b-row>
          </b-form-group>
          <b-form-group class="mb-2">
            <b-row class="align-items-center">
              <b-col cols="2">
                <span>
                  <p class="small text-dark mb-0">Nama Pbf</p>
                </span>
              </b-col>
              <b-col>
                <b-form-input class="new-cstm-cntrl-katalog" v-model="tambahPbf.nama_pbf" placeholder="Nama Pbf"></b-form-input>
              </b-col>
            </b-row>
          </b-form-group>
          <b-form-group class="mb-2">
            <b-row class="align-items-center">
              <b-col cols="2">
                <span>
                  <p class="small text-dark mb-0">Alamat</p>
                </span>
              </b-col>
              <b-col>
                <b-form-input class="new-cstm-cntrl-katalog" v-model="tambahPbf.alamat" placeholder="Alamat Pbf"></b-form-input>
              </b-col>
            </b-row>
          </b-form-group>
          <b-form-group class="mb-2">
            <b-row class="align-items-center">
              <b-col cols="2">
                <span>
                  <p class="small text-dark mb-0">Kota</p>
                </span>
              </b-col>
              <b-col cols="6" class="pr-1">
                <b-form-input class="new-cstm-cntrl-katalog" v-model="tambahPbf.kota" placeholder="Kota"></b-form-input>
              </b-col>
              <b-col class="pl-1">
                <b-form-input class="new-cstm-cntrl-katalog" v-model="tambahPbf.kodepos" placeholder="Kode Pos"></b-form-input>
              </b-col>
            </b-row>
          </b-form-group>
          <b-form-group class="mb-2">
            <b-row class="align-items-center">
              <b-col cols="2">
                <span>
                  <p class="small text-dark mb-0">Kontak Pbf</p>
                </span>
              </b-col>
              <b-col class="pr-1">
                <b-form-input class="new-cstm-cntrl-katalog" v-model="tambahPbf.telp" placeholder="Telepon"></b-form-input>
              </b-col>
              <b-col class="pr-1 pl-1">
                <b-form-input class="new-cstm-cntrl-katalog" v-model="tambahPbf.fax" placeholder="Fax"></b-form-input>
              </b-col>
              <b-col class="pl-1">
                <b-form-input class="new-cstm-cntrl-katalog" v-model="tambahPbf.email" placeholder="Email"></b-form-input>
              </b-col>
            </b-row>
          </b-form-group>
          <b-form-group class="mb-2">
            <b-row class="align-items-center">
              <b-col cols="2">
                <span>
                  <p class="small text-dark mb-0">Kepala Cabang</p>
                </span>
              </b-col>
              <b-col>
                <b-form-input class="new-cstm-cntrl-katalog" v-model="tambahPbf.kepala_cabang" placeholder="Kepala Cabang"></b-form-input>
              </b-col>
            </b-row>
          </b-form-group>
          <b-form-group class="mb-2">
            <b-row class="align-items-center">
              <b-col cols="2">
                <span>
                  <p class="small text-dark mb-0">Nama CP</p>
                </span>
              </b-col>
              <b-col cols="6" class="pr-1">
                <b-form-input class="new-cstm-cntrl-katalog" v-model="tambahPbf.cp_name" placeholder="Nama Kontak"></b-form-input>
              </b-col>
              <b-col class="pl-1">
                <b-form-input class="new-cstm-cntrl-katalog" v-model="tambahPbf.cp_telp" placeholder="Telepon CP"></b-form-input>
              </b-col>
            </b-row>
          </b-form-group>
          <b-form-group class="mb-2">
            <b-row class="align-items-center">
              <b-col cols="2">
                <span>
                  <p class="small text-dark mb-0">ID Transmedik</p>
                </span>
              </b-col>
              <b-col>
                <b-form-input class="new-cstm-cntrl-katalog" v-model="tambahPbf.id_transmedik" placeholder="ID Transmedik"></b-form-input>
              </b-col>
            </b-row>
          </b-form-group>
          <b-form-group class="mb-0">
            <b-row class="align-items-center">
              <b-col cols="2">
                <span>
                  <p class="small text-dark mb-0">User Update</p>
                </span>
              </b-col>
              <b-col>
                <b-form-input class="new-cstm-cntrl-katalog" v-model="preview.user_name" placeholder="Nama User" readonly></b-form-input>
              </b-col>
            </b-row>
          </b-form-group>
        </b-form>
        <div class="bg-dark px-2 mt-2" v-if="preview.messageValue === '2'">
          <p class="text-right small text-white mb-0">{{ preview.message }}</p>
        </div>
       <!-- <pre>{{ tambahPbf }}</pre> -->
      </b-modal>
      <b-modal id="modal-edit-pbf" ref="modal-edit-pbf" size="lg" centered title="Edit Pbf" ok-title="Perbarui Data" cancel-title="Tutup" @ok="editSave" @close="clear" @cancel="clear" :no-close-on-backdrop="true" :static="true">
        <b-form>
          <b-form-group class="mb-2">
            <b-row class="align-items-center">
              <b-col cols="2">
                <span>
                  <p class="small text-dark mb-0">Kode</p>
                </span>
              </b-col>
              <b-col>
                <b-form-input class="new-cstm-cntrl-katalog" v-model="editPbf.kode" placeholder="Enter Kode"></b-form-input>
              </b-col>
            </b-row>
          </b-form-group>
          <b-form-group class="mb-2">
            <b-row class="align-items-center">
              <b-col cols="2">
                <span>
                  <p class="small text-dark mb-0">NPWP</p>
                </span>
              </b-col>
              <b-col>
                <b-form-input class="new-cstm-cntrl-katalog" v-model="editPbf.npwp" placeholder="NPWP Pbf"></b-form-input>
              </b-col>
            </b-row>
          </b-form-group>
          <b-form-group class="mb-2">
            <b-row class="align-items-center">
              <b-col cols="2">
                <span>
                  <p class="small text-dark mb-0">Nama Pbf</p>
                </span>
              </b-col>
              <b-col>
                <b-form-input class="new-cstm-cntrl-katalog" v-model="editPbf.nama_pbf" placeholder="Nama Pbf"></b-form-input>
              </b-col>
            </b-row>
          </b-form-group>
          <b-form-group class="mb-2">
            <b-row class="align-items-center">
              <b-col cols="2">
                <span>
                  <p class="small text-dark mb-0">Alamat</p>
                </span>
              </b-col>
              <b-col>
                <b-form-input class="new-cstm-cntrl-katalog" v-model="editPbf.alamat" placeholder="Alamat Pbf"></b-form-input>
              </b-col>
            </b-row>
          </b-form-group>
          <b-form-group class="mb-2">
            <b-row class="align-items-center">
              <b-col cols="2">
                <span>
                  <p class="small text-dark mb-0">Kota</p>
                </span>
              </b-col>
              <b-col cols="6" class="pr-1">
                <b-form-input class="new-cstm-cntrl-katalog" v-model="editPbf.kota" placeholder="Kota"></b-form-input>
              </b-col>
              <b-col class="pl-1">
                <b-form-input class="new-cstm-cntrl-katalog" v-model="editPbf.kodepos" placeholder="Kode Pos"></b-form-input>
              </b-col>
            </b-row>
          </b-form-group>
          <b-form-group class="mb-2">
            <b-row class="align-items-center">
              <b-col cols="2">
                <span>
                  <p class="small text-dark mb-0">Kontak Pbf</p>
                </span>
              </b-col>
              <b-col class="pr-1">
                <b-form-input class="new-cstm-cntrl-katalog" v-model="editPbf.telp" placeholder="Telepon"></b-form-input>
              </b-col>
              <b-col class="pr-1 pl-1">
                <b-form-input class="new-cstm-cntrl-katalog" v-model="editPbf.fax" placeholder="Fax"></b-form-input>
              </b-col>
              <b-col class="pl-1">
                <b-form-input class="new-cstm-cntrl-katalog" v-model="editPbf.email" placeholder="Email"></b-form-input>
              </b-col>
            </b-row>
          </b-form-group>
          <b-form-group class="mb-2">
            <b-row class="align-items-center">
              <b-col cols="2">
                <span>
                  <p class="small text-dark mb-0">Kepala Cabang</p>
                </span>
              </b-col>
              <b-col>
                <b-form-input class="new-cstm-cntrl-katalog" v-model="editPbf.kepala_cabang" placeholder="Kepala Cabang"></b-form-input>
              </b-col>
            </b-row>
          </b-form-group>
          <b-form-group class="mb-2">
            <b-row class="align-items-center">
              <b-col cols="2">
                <span>
                  <p class="small text-dark mb-0">Nama CP</p>
                </span>
              </b-col>
              <b-col cols="6" class="pr-1">
                <b-form-input class="new-cstm-cntrl-katalog" v-model="editPbf.cp_name" placeholder="Nama Kontak"></b-form-input>
              </b-col>
              <b-col class="pl-1">
                <b-form-input class="new-cstm-cntrl-katalog" v-model="editPbf.cp_telp" placeholder="Telepon CP"></b-form-input>
              </b-col>
            </b-row>
          </b-form-group>
          <b-form-group class="mb-2">
            <b-row class="align-items-center">
              <b-col cols="2">
                <span>
                  <p class="small text-dark mb-0">ID Transmedik</p>
                </span>
              </b-col>
              <b-col>
                <b-form-input class="new-cstm-cntrl-katalog" v-model="editPbf.id_transmedik" placeholder="ID Transmedik"></b-form-input>
              </b-col>
            </b-row>
          </b-form-group>
          <b-form-group class="mb-0">
            <b-row class="align-items-center">
              <b-col cols="2">
                <span>
                  <p class="small text-dark mb-0">User Update</p>
                </span>
              </b-col>
              <b-col>
                <b-form-input v-model="preview.user_name" placeholder="Nama User" readonly></b-form-input>
              </b-col>
            </b-row>
          </b-form-group>
        </b-form>
        <div class="bg-dark px-2 mt-2" v-if="preview.messageValue === '2'">
          <p class="text-right small text-white mb-0">{{ preview.message }}</p>
        </div>
        <!-- <pre>{{ editPbf }}</pre> -->
      </b-modal>
      <b-modal ref="modal-delete-pbf" size="md" centered title="Konfirmasi" ok-title="Hapus Data" ok-variant="danger" cancel-title="Tutup" @ok="deleteSave">
        <div class="py-3">
          <p class="h6 text-center text-dark mb-0">Yakin Ingin Menghapus Data ?</p>
        </div>
      </b-modal>
      <b-modal ref="modal-notif-pbf" size="md" centered title="Notifikasi" ok-title="Tutup" :hide-footer="true" :hide-header="true">
        <div class="pb-3 px-4">
          <div v-if="preview.messageValue === '1'">
            <lottie :options="preview.lottie.defaultOptions" :autoPlay="true" :width="150" :height="150" />
          </div>
          <div v-else>
            <lottie :options="preview.lottie.defaultOptions2" :autoPlay="true" :width="150" :height="150" />
          </div>
          <p class="h6 text-dark text-center mb-0 mt-2">{{ preview.message }}</p>
        </div>
      </b-modal>
    </div>
  </div>
</template>
<script>
import { xray } from '../../../config/pluginInit'
import jwtdecode from 'jwt-decode'
import Services from '../../../services/fatmahost/katalog/index'
import Lottie from 'vue-lottie'
import animationLogo from '../../../assets/lottie/checked.json'
import animationLogo2 from '../../../assets/lottie/failed.json'

export default {
  name: 'Pbf',
  components: {
    Lottie
  },
  data () {
    return {
      table: {
        isBusy: false,
        items: this.list,
        sortBy: '',
        sortDesc: true,
        fields: [
          { label: 'No', key: 'index', class: 'text-center' },
          { label: 'Kode', key: 'mp.kode', sortable: true },
          { label: 'ID Transmedik', key: 'id_transmedik', sortable: true },
          { label: 'NPWP', key: 'mp.npwp', sortable: true },
          { label: 'Nama Pbf', key: 'mp.nama_pbf', sortable: true },
          { label: 'Kota', key: 'mp.kota', sortable: true },
          { label: 'Telepon', key: 'mp.telp', sortable: true },
          { label: 'Email', key: 'mp.email', sortable: true },
          { label: 'User Update', key: 'up.name', sortable: true },
          { label: 'Last Update', key: 'mp.sysdate_updt', sortable: true },
          { label: 'Aksi', key: 'action', class: 'text-center' }
        ],
        filter: null,
        currentPage: null,
        perPage: null,
        pageOptions: [10, 25, 50, 100],
        totalRows: null
      },
      tambahPbf: {
        object: 'pbf',
        kode: '',
        nama_pbf: '',
        npwp: '',
        alamat: '',
        kota: '',
        kodepos: '',
        telp: '',
        fax: '',
        email: '',
        cp_name: '',
        cp_telp: '',
        kepala_cabang: '',
        id_transmedik: '',
        userid_updt: jwtdecode(localStorage.getItem('token')).user.id.toString()
      },
      editPbf: {
        object: 'pbf',
        kode: '',
        nama_pbf: '',
        npwp: '',
        alamat: '',
        kota: '',
        kodepos: '',
        telp: '',
        fax: '',
        email: '',
        cp_name: '',
        cp_telp: '',
        kepala_cabang: '',
        id_transmedik: '',
        userid_updt: jwtdecode(localStorage.getItem('token')).user.id.toString()
      },
      options: {
        optionsStsAktif: [
          { text: 'Aktif', value: '1' },
          { text: 'Tidak Aktif', value: '2' }
        ],
        optionsFilter: [
          { text: 'Kode', value: '1' },
          { text: 'Nama Pbf', value: '2' }
        ]
      },
      selected: {
        filter: '1'
      },
      preview: {
        lottie: {
          defaultOptions: { animationData: animationLogo, loop: true },
          defaultOptions2: { animationData: animationLogo2, loop: true }
        },
        user_name: jwtdecode(localStorage.getItem('token')).user.name,
        edit_id: '',
        delete_id: '',
        message: '',
        messageValue: ''
      }
    }
  },
  mounted () {
    xray.index()
  },
  methods: {
    tableRefresh () {
      this.$refs.table.refresh()
    },
    async list (ctx) {
      let filterType = this.selected.filter
      let sortfield = ''
      let sorting = ''
      if (ctx.filter === '') {
        ctx.filter = null
      }
      if (ctx.sortBy) {
        sortfield = ctx.sortBy
      } else {
        sortfield = ''
      }
      if (ctx.sortDesc === true) {
        sorting = 'DESC'
      } else if (ctx.sortDesc === false) {
        sorting = 'ASC'
      } else {
        sorting = ''
      }
      this.isBusy = true
      try {
        const response = await Services.listPbf(ctx.currentPage - 1, ctx.perPage, ctx.filter, filterType, sortfield, sorting)
        this.isBusy = false
        this.table.totalRows = response.data.pagination.total
        this.table.perPage = response.data.pagination.perPage
        this.table.currentPage = response.data.pagination.current
        return response.data.data
      } catch (error) {
        this.isBusy = false
        return []
      }
    },
    onFiltered (filteredItems) {
      this.table.totalRows = filteredItems.length
      this.table.currentPage = 1
    },
    filterchange () {
      this.table.filter = ''
    },
    tambahModal () {
      this.clearMessage()
      this.$refs['modal-tambah-pbf'].show()
    },
    async tambahSave (bvModalEvent) {
      bvModalEvent.preventDefault()
      try {
        let data = this.tambahPbf
        const response = await Services.createPbf(data)
        if (response.data.statcode === 0) {
          this.preview.messageValue = '1'
          this.preview.message = response.data.message
          this.$refs['modal-tambah-pbf'].hide()
          this.$refs['modal-notif-pbf'].show()
          this.$refs.table.refresh()
          this.clear()
        } else {
          this.preview.messageValue = '2'
          this.preview.message = response.data.message
        }
      } catch (error) {
        this.preview.messageValue = '2'
        this.preview.message = error.message
      }
    },
    async editModal (id) {
      this.clearMessage()
      let ids = id
      this.preview.edit_id = ids
      try {
        const response = await Services.showPbf(ids)
        this.editPbf.kode = response.data.data[0].kode
        this.editPbf.npwp = response.data.data[0].npwp
        this.editPbf.nama_pbf = response.data.data[0].nama_pbf
        this.editPbf.alamat = response.data.data[0].alamat
        this.editPbf.kota = response.data.data[0].kota
        this.editPbf.kodepos = response.data.data[0].kodepos
        this.editPbf.telp = response.data.data[0].telp
        this.editPbf.fax = response.data.data[0].fax
        this.editPbf.email = response.data.data[0].email
        this.editPbf.kepala_cabang = response.data.data[0].kepala_cabang
        this.editPbf.cp_name = response.data.data[0].cp_name
        this.editPbf.cp_telp = response.data.data[0].cp_telp
        this.editPbf.id_transmedik = response.data.data[0].id_transmedik
      } catch (error) {
        this.preview.message = error.message
        this.preview.messageValue = '2'
        // this.$refs['modal-notif-pbf'].show()
      }
      this.$refs['modal-edit-pbf'].show()
    },
    async editSave (bvModalEvent) {
      bvModalEvent.preventDefault()
      try {
        let id = this.preview.edit_id
        let data = this.editPbf
        const response = await Services.editPbf(id, data)
        if (response.data.statcode === 0) {
          this.preview.messageValue = '1'
          this.preview.message = response.data.message
          this.$refs['modal-edit-pbf'].hide()
          this.$refs['modal-notif-pbf'].show()
          this.$refs.table.refresh()
          this.clear()
        } else {
          this.preview.messageValue = '2'
          this.preview.message = response.data.message
        }
      } catch (error) {
        this.preview.messageValue = '2'
        this.preview.message = error.message
      }
    },
    deleteModal (id) {
      this.preview.delete_id = id
      this.$refs['modal-delete-pbf'].show()
    },
    async deleteSave () {
      let id = this.preview.delete_id
      try {
        const response = await Services.deletePbf(id)
        if (response.data.statcode === 0) {
          this.preview.message = response.data.message
          this.preview.messageValue = '1'
        } else {
          this.preview.message = response.data.message
          this.preview.messageValue = '2'
        }
        this.$refs['modal-notif-pbf'].show()
        this.$refs.table.refresh()
      } catch (error) {
        this.preview.message = error.message
        this.preview.messageValue = '2'
        this.$refs['modal-notif-pbf'].show()
      }
    },
    clear () {
      this.tambahPbf.kode = ''
      this.tambahPbf.nama_pbf = ''
      this.tambahPbf.npwp = ''
      this.tambahPbf.alamat = ''
      this.tambahPbf.kota = ''
      this.tambahPbf.kodepos = ''
      this.tambahPbf.telp = ''
      this.tambahPbf.fax = ''
      this.tambahPbf.email = ''
      this.tambahPbf.cp_name = ''
      this.tambahPbf.cp_telp = ''
      this.tambahPbf.kepala_cabang = ''
      //
      this.editPbf.kode = ''
      this.editPbf.nama_pbf = ''
      this.editPbf.npwp = ''
      this.editPbf.alamat = ''
      this.editPbf.kota = ''
      this.editPbf.kodepos = ''
      this.editPbf.telp = ''
      this.editPbf.fax = ''
      this.editPbf.email = ''
      this.editPbf.cp_name = ''
      this.editPbf.cp_telp = ''
      this.editPbf.kepala_cabang = ''
    },
    clearMessage () {
      this.preview.messageValue = ''
      this.preview.message = ''
    }
  }
}
</script>
<style>
#modal-tambah-pbf .custom-control-label, #modal-edit-pbf .custom-control-label {
    font-size: 12px;
    margin-top: 0;
}
#modal-tambah-pbf .multiselect__single, #modal-edit-pbf .multiselect__single {
  font-size: 12px!important;
}
#modal-tambah-pbf .multiselect__placeholder, #modal-edit-pbf .multiselect__placeholder {
  font-size: 12px!important;
}
#modal-tambah-pbf .multiselect__option, #modal-edit-pbf .multiselect__option {
    font-size: 12px;
}
#modal-tambah-pbf .multiselect__tags, #modal-edit-pbf .multiselect__tags {
  padding: 5px 40px 0 8px!important;
  height: 38px!important;
  min-height: 38px!important;
}
#modal-tambah-pbf  .modal-body, #modal-edit-pbf  .modal-body {
  background: #3399331b
}

#modal-tambah-pbf  .modal-header, #modal-edit-pbf  .modal-header {
  background: #3399333a;
}

#modal-tambah-pbf  .modal-footer, #modal-edit-pbf  .modal-footer {
  background: #3399333a;
}
#katalog-pbf .page-link {
  font-size: 14px;
  padding-top: 5px;
  padding-bottom: 5px;
  margin-right: 10px!important;
}
#katalog-pbf .custom-button-radius {
  border: 1px solid rgba(0, 0, 0, 0.112);
  border-radius: 5px!important;
}
#katalog-pbf .table td {
    font-size: 12px;
    font-weight: 500;
    padding-top: 0px;
    padding-bottom: 0px;
}
#katalog-pbf .table th {
    font-size: 13px;
    font-weight: 400;
}
#katalog-pbf .cstm-select-pbf {
  font-size: 13px;
  height: 38px!important;
  border-radius: 5px;
}
#katalog-pbf .cstm-srch-pbf {
  font-size: 13px;
  height: 38px!important;
  border-radius: 5px;
}
#katalog-pbf .cstm-slct-pbf {
  font-size: 13px;
  height: 38px!important;
  border-radius: 5px;
}
.new-cstm-cntrl-katalog {
  background: white!important;
  color: black!important;
  border-radius: 5px!important;
  font-size: 12px!important;
  height: 38px!important;
}
.katalog-form-area {
  color: black!important;
  border-radius: 5px!important;
  font-size: 12px!important;
}
#katalog-pbf .pagination-sm .page-link {
  color: gray!important;
}
#katalog-pbf .page-item.active .page-link {
  color: white!important;
}
#katalog-pbf .page-item.active .page-link {
  color: white!important;
}
.custom-radius-add-katalog {
  border-radius: 5px;
  cursor: pointer;
}
.katalog-select-flex-left {
  width: 90%;
}
.katalog-select-flex-right {
  width: 10%;
}
.katalog-form-satuan-pbf-left-right {
  width: 40%;
}
.katalog-form-satuan-pbf-center {
  width: 15%;
}
.hr-katalog-custom {
  border-top: 1px solid rgba(0, 0, 0, 0.199)!important;
  margin: 0!important;
}
#katalog-pbf .pagination-pbf .page-link {
    border: none;
    color: gray!important;
    margin-right: 15px;
}
#katalog-pbf .pagination-pbf .page-item.active .page-link {
    background-color: transparent;
    border-color: transparent;
    color: #089bab!important;
    font-weight: bold;
}
</style>
