import Api from '../axiosInstance'
import lz from '../../../Utils/lzstring'

export default {
  // PRACTIONERS
  login (data) {
    return Api.applikasi.post(`/auth/practitioner/login`, lz.encrypt(data))
  },
  token () {
    return Api.applikasi.get(`/auth/practitioner/token`)
  },
  logout () {
    return Api.applikasi.delete('/auth/practitioner/logout')
  }
}
