<template>
  <div id="farmasi">
    <b-container fluid>
      <iq-card class="px-3 py-3 bg-gray shadow-sm border">
        <div class="my-nav">
          <tab-nav :pills="true" id="pills-tab" class="bg-white mb-3">
            <tab-nav-items class="font-weight-bold text-uppercase" :active="true" id="daftar-barang-tab" href="#daftar-barang" ariaControls="daftar-barang" role="tab"
              :ariaSelected="true" title="Daftar Barang" />
            <tab-nav-items class="font-weight-bold text-uppercase" :active="false" id="stok-opname-tab" href="#stok-opname" ariaControls="stok-opname" role="tab"
              :ariaSelected="false" title="Stok Opname" />
            <tab-nav-items class="font-weight-bold text-uppercase" :active="false" id="permintaan-tab" href="#permintaan" ariaControls="permintaan" role="tab"
              :ariaSelected="false" title="Permintaan" />
            <tab-nav-items class="font-weight-bold text-uppercase" :active="false" id="pengiriman-tab" href="#pengiriman" ariaControls="pengiriman" role="tab"
              :ariaSelected="false" title="Pengiriman" />
            <tab-nav-items class="font-weight-bold text-uppercase" :active="false" id="penerimaanPbf-tab" href="#penerimaanPbf" ariaControls="penerimaanPbf" role="tab"
              :ariaSelected="false" title="Penerimaan PBF" />
            <tab-nav-items class="font-weight-bold text-uppercase" :active="false" id="barangProduksi-tab" href="#barangProduksi" ariaControls="barangProduksi" role="tab"
              :ariaSelected="false" title="Barang Produksi" />
          </tab-nav>
          <tab-content id="pills-tabContent-2">
            <tab-content-item :active="true" id="daftar-barang" aria-labelled-by="daftar-barang-tab">
              <PersediaanDaftarBarang></PersediaanDaftarBarang>
            </tab-content-item>
            <tab-content-item :active="false" id="stok-opname" aria-labelled-by="stok-opname-tab">
              <PersediaanStokOpname></PersediaanStokOpname>
            </tab-content-item>
            <tab-content-item :active="false" id="permintaan" aria-labelled-by="permintaan-tab">
              <PersediaanPermintaan></PersediaanPermintaan>
            </tab-content-item>
            <tab-content-item :active="false" id="pengiriman" aria-labelled-by="pengiriman-tab">
              <PersediaanPengiriman></PersediaanPengiriman>
            </tab-content-item>
            <tab-content-item :active="false" id="penerimaanPbf" aria-labelled-by="penerimaanPbf-tab">
              <PenerimaanPBF></PenerimaanPBF>
            </tab-content-item>
            <tab-content-item :active="false" id="barangProduksi" aria-labelled-by="barangProduksi-tab">
              <PersediaanBarangProduksi></PersediaanBarangProduksi>
            </tab-content-item>
          </tab-content>
        </div>
      </iq-card>
    </b-container>
  </div>
</template>
<script>
import { xray } from '../../config/pluginInit'
import PersediaanDaftarBarang from '../../components/hospia/persediaan/DaftarBarang'
import PersediaanStokOpname from '../../components/hospia/persediaan/StokOpname'
import PersediaanPermintaan from '../../components/hospia/persediaan/Permintaan'
import PersediaanPengiriman from '../../components/hospia/persediaan/Pengiriman'
import PenerimaanPBF from '../../components/hospia/persediaan/PenerimaanPBF'
import PersediaanBarangProduksi from '../../components/hospia/persediaan/BarangProduksi'

export default {
  name: 'Index',
  components: {
    PersediaanDaftarBarang,
    PersediaanStokOpname,
    PersediaanPermintaan,
    PersediaanPengiriman,
    PenerimaanPBF,
    PersediaanBarangProduksi
  },
  mounted () {
    xray.index()
  }
}
</script>
<style>
#farmasi .my-nav .nav {
  padding: 10px;
  border-radius: 10px;
  box-shadow: 2px 2px 2px 2px rgba(179, 179, 179, 0.281);
}
#farmasi .multiselect__tags {
    border-radius: 10px;
    border: none;
    min-height: 45px!important;
}
#farmasi .multiselect__placeholder {
    font-weight: lighter;
}
#farmasi .search-class-farm {
  box-shadow: 2px 2px 2px 2px rgba(179, 179, 179, 0.281);
  font-weight: light;
}
#farmasi .radius {
  border-radius: 10px!important;
}
#farmasi .radius-top {
  border-radius: 10px 10px 0px 0px!important;
}
#farmasi .btn {
  height: 45px!important;
}
#farmasi .bg-gray {
  background: rgb(222, 250, 255);
}
#farmasi .farm-hr {
    border-top: 1px solid rgb(255, 255, 255);
}
#farmasi .nav-pills .nav-link.active, [dir=ltr] .nav-pills .show > .nav-link {
    background: #ffffff;
}
</style>
