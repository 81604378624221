import Api from './fatmahost/axiosInstance'

export default {
  // public
  showByRm (data) {
    return Api.laravel.get(`rm/reservasi/off?norm=${data}`)
  },
  getMember (id) {
    return Api.laravel.get(`profil/member?patient_id=${id}`)
  },
  createReservasi (data) {
    return Api.laravel.post('create/reservasi/off', data)
  },
  cekKuota (ruangan, tgl, jnsrsv) {
    return Api.laravel.get(`kuota/poli/off?ruangan=${ruangan}&tanggal=${tgl}&jenis_reservasi=${jnsrsv}`)
  },
  agama () {
    return Api.laravel.get('getbyAgama')
  },
  pendidikan () {
    return Api.laravel.get('getbyPendidikan')
  },
  pekerjaan () {
    return Api.laravel.get('getbyPekerjaan')
  },
  kewarganegaraan () {
    return Api.laravel.get('getbyKewarganegaraan')
  },
  provinsi () {
    return Api.laravel.get('getbyProvinsi')
  },
  kabupaten (data) {
    return Api.laravel.post('setbyKabupaten', data)
  },
  kecamatan (data) {
    return Api.laravel.post('setbyKecamatan', data)
  },
  kelurahan (data) {
    return Api.laravel.post('setbyDesa', data)
  },
  // Public IRJ--------------------------------------------------------------------------------------------------------------
  caraBayar () {
    return Api.laravel.get('getbyCarabayar')
  },
  instalasi () {
    return Api.laravel.get('getbyInstalasi')
  },
  unit (data) {
    return Api.laravel.post('getbyUnit', data)
  },
  ruangan (data) {
    return Api.laravel.post('getbyRuangan', data)
  },
  cekKuotaIrj (ruangan, tgl, jnsrsv) {
    return Api.laravel.get(`kuota/reservasi/irj/off?ruangan=${ruangan}&tanggal=${tgl}&jenis_reservasi=${jnsrsv}`)
  },
  cekKBpjsNik (nik) {
    return Api.laravel.get(`bpjs/nik/off?nik=${nik}`)
  },
  cekKBpjsNoKa (noka) {
    return Api.laravel.get(`bpjs/nokartu/off?noKartu=${noka}`)
  },
  createReservasiIRJ (data) {
    return Api.laravel.post('create/reservasi/irj/off', data)
  },
  // private IRJ----------------------------------------------------------------------------------------------------------
  getListIrj (page, params) {
    if (params !== null) {
      return Api.laravel.get(`reservasi/RJ/index/?params=${params}`)
    } else {
      return Api.laravel.get(`reservasi/RJ/index/?page=${page}`)
    }
  },
  irjBatal (data) {
    return Api.laravel.put('reservasi/RJ/batal', data)
  },
  irjCetakUlang (data) {
    return Api.laravel.get(`reservasi/RJ/cetakUlang?id=${data}`)
  },
  // Public Griya --------------------------------------------------------------------------------------------------------------
  getDokterRuangan (data) {
    return Api.laravel.post('setDokterRuangan', data)
  },
  cekKuotaGriya (ruangan, tgl, jnsrsv, dokter) {
    return Api.laravel.get(`kuota/reservasi/igh/off?dokter=${dokter}&ruangan=${ruangan}&tanggal=${tgl}&jenis_reservasi=${jnsrsv}`)
  },
  menuGetDokter (ruangan, tanggal) {
    return Api.laravel.get(`dokter/reservasi/off?ruangan=${ruangan}&tanggal=${tanggal}`)
  },
  menuCekKuotaGriya (ruangan, tgl, jnsrsv, dokter) {
    return Api.laravel.get(`kuota/reservasi/off?dokter=${dokter}&ruangan=${ruangan}&tanggal=${tgl}&jenis_reservasi=${jnsrsv}`)
  },
  createReservasiGriya (data) {
    return Api.laravel.post('create/reservasi/irj/off', data)
  },
  // private Griya----------------------------------------------------------------------------------------------------------
  getListGriya (page, params) {
    if (params !== null) {
      return Api.laravel.get(`reservasi/IGH/index/?params=${params}`)
    } else {
      return Api.laravel.get(`reservasi/IGH/index/?page=${page}`)
    }
  },
  ighBatal (data) {
    return Api.laravel.put('reservasi/IGH/batal', data)
  },
  ighCetakUlang (data) {
    return Api.laravel.get(`reservasi/IGH/cetakUlang?id=${data}`)
  },
  getWilayah () {
    return Api.laravel.get('getbyTempatLahir')
  }
}
