<template>
  <div id="module-role">
    <b-container fluid>
      <div>
        <b-row class="pb-3">
          <b-col cols="4" class="pr-1">
            <iq-card class="custom-min-height border">
              <template v-slot:headerTitle>
                <h6 class="card-title font-weight-bold mb-0">Group Pengguna</h6>
              </template>
              <template v-slot:headerAction>
                  <b-button variant="primary" @click="modalShow"><i class="fas fa-users"></i> Tambah</b-button>
              </template>
              <template v-slot:body>
                <div>
                  <b-input-group>
                    <b-form-input class="cstm-slct-role rounded-md text-center border-0" width id="filter-input" v-model="group.filter" type="search" placeholder=" Pencarian "></b-form-input>
                  </b-input-group>
                </div>
                <b-row class="pb-4">
                  <b-col>
                    <div class="chat-sidebar-channel scroller">
                      <div class="table mb-0">
                        <b-table class="mb-0"
                          ref="tablegroup"
                          bordered
                          hover
                          :busy="group.isBusy"
                          :items="group.items"
                          :fields="group.fields"
                          :current-page="group.currentPage"
                          :per-page="group.perPage"
                          @row-clicked="moduleByIdGroup"
                          :tbody-tr-class="rowClassGroup"
                          :filter="group.filter"
                          show-empty
                          thead-class="d-none"
                          small
                          @filtered="onFiltered"
                        >
                          <template #cell(index)="data">
                            {{ (group.currentPage*group.perPage)-group.perPage + data.index + 1 }}
                          </template>
                          <template #cell(action)="data">
                            <b-button v-b-tooltip.hover @click="editModal(data.item.id_grp)" variant="white">
                              <i class="fas fa-pen text-primary"></i>
                            </b-button>
                          </template>
                        </b-table>
                      </div>
                    </div>
                  </b-col>
                </b-row>
              </template>
            </iq-card>
          </b-col>
          <b-col cols="8" class="pl-1">
            <iq-card class="custom-min-height border">
              <template v-slot:headerTitle>
                <h6 class="card-title font-weight-bold mb-0">Module Akses</h6>
              </template>
              <template v-slot:headerAction>
              </template>
              <template v-slot:body>
                <div class="chat-sidebar-channel scroller pb-0">
                  <b-form-checkbox-group
                    id="checkbox-group-2"
                    v-model="module.selected"
                    name="flavour-2"
                  >
                    <b-table class="mb-0"
                      ref="tablemodule"
                      bordered
                      hover
                      :busy="module.isBusy"
                      :items="module.items"
                      :fields="module.fields"
                      :current-page="module.currentPage"
                      :per-page="module.perPage"
                      show-empty
                      small
                      thead-class="d-none"
                    >
                      <template #cell(name)="data">
                        <div class="ml-4">
                          <div class="ml-4">
                            <b-form-checkbox :value="data.item.id_modules">
                              {{ data.item.name.toUpperCase() }}
                            </b-form-checkbox>
                          </div>
                        </div>
                      </template>
                      <template #cell(bitcontrol1)="data">
                        <b-form-input v-model="data.item.bitcontrol1" :value="data.item.bitcontrol1" class="cstm-slct-role rounded-0" type="text" placeholder="[ BIT CONTROL 1 ]"></b-form-input>
                      </template>
                      <template #cell(bitcontrol2)="data">
                        <b-form-input v-model="data.item.bitcontrol2" :value="data.item.bitcontrol2" class="cstm-slct-role rounded-0" type="text" placeholder="[ BIT CONTROL 1 ]"></b-form-input>
                      </template>
                    </b-table>
                  </b-form-checkbox-group>
                </div>
                <div class="border-top">
                  <b-row class="justify-content-end">
                    <b-col cols="12">
                      <div class="bg-white text-right px-4 pt-3 pb-0">
                        <b-button @click="saveModule" variant="primary"><i class="fas fa-save"></i> Simpan</b-button>
                      </div>
                    </b-col>
                  </b-row>
                </div>
              </template>
            </iq-card>
          </b-col>
        </b-row>
      </div>
    </b-container>
    <b-modal ref="modal-tambah" size="xl" title="Tambah Group" ok-title="Simpan" @ok="saveGroup" cancel-title="Kembali" :ok-only=false :no-close-on-backdrop="true" :static="true" @cancel="clearForm" @close="clearForm" centered>
      <div class="mb-2">
        <p class="text-dark mb-0">Group Pengguna: <span class="font-weight-bold"></span></p>
      </div>
      <b-row>
        <b-col cols="12" lg="6" class="pr-1">
          <div>
            <b-input-group>
              <b-form-input v-model="group.grpname" class="cstm-slct-role" id="filter-input" type="text" placeholder="[ Masukan Nama Group ]"></b-form-input>
            </b-input-group>
          </div>
        </b-col>
        <b-col cols="12" lg="6" class="pl-1">
          <div>
            <div>
              <multiselect v-model="group.selected" :show-labels="false" :limit="3" track-by="smenuname" label="smenuname" placeholder="[ Masukan Menu ]" :options="group.combo">
              </multiselect>
            </div>
          </div>
        </b-col>
      </b-row>
      <hr>
      <div>
        <table class="table table-bordered table-sm">
          <thead>
            <tr>
              <th scope="col" class="text-center">Instalasi</th>
              <th scope="col" class="text-center">Unit</th>
              <th scope="col" class="text-center">Ruangan</th>
              <th scope="col" class="text-center">
                <b-button variant="primary" @click="addRow">
                  <p class="h5 mb-0">+</p>
                </b-button>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(k, index) in group.detailRuangan" v-bind:key="k.codex">
              <td class="col-4">
                <div @click="tes(index)">
                  <multiselect v-model="group.selectedRuangan.instalasi[index].id_instalasi" :show-labels="false" :limit="3" track-by="id" label="deskripsi" placeholder="[ Instalasi ]" :options="group.instalasi" @input="valueInstalasi">
                  </multiselect>
                </div>
              </td>
              <td class="col-4">
                <multiselect v-model="group.selectedRuangan.unit[index].id_unit" :show-labels="false" :limit="3" track-by="id" label="deskripsi" placeholder="[ Unit ]" :options="group.comboRuangan.unit[index].data" @input="valueUnit">
                </multiselect>
              </td>
              <td class="col-4">
                <multiselect v-model="group.selectedRuangan.ruangan[index].id_ruangan" :show-labels="false" :limit="3" track-by="id" label="deskripsi" placeholder="[ Ruangan ]" :options="group.comboRuangan.ruangan[index].data" @input="valueRuangan($event, index)">
                </multiselect>
              </td>
              <td class="col-1 text-center">
                <b-button variant="white" @click="deleteRow(index, k.id_ruangan, '', 't')" v-if="index !== 0">
                  <p class="mb-0"><i class="fas fa-trash-alt text-danger"></i></p>
                </b-button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="bg-dark px-2 mt-2" v-if="group.message">
        <p class="text-right small text-white mb-0">{{ group.message }}</p>
      </div>
    </b-modal>
    <b-modal ref="modal-update" size="xl" title="Edit Group" ok-title="Simpan" @ok="updateGroup" cancel-title="Kembali" :ok-only=false :no-close-on-backdrop="true" :static="true" @cancel="clearForm" @close="clearForm" centered>
      <div class="mb-2">
        <p class="text-dark mb-0">Group Pengguna: <span class="font-weight-bold"></span></p>
      </div>
      <b-row>
        <b-col cols="12" lg="6" class="pr-1">
          <div>
            <b-input-group>
              <b-form-input v-model="group.grpname" class="cstm-slct-role" id="filter-input" type="text" placeholder="[ Masukan Nama Group ]"></b-form-input>
            </b-input-group>
          </div>
        </b-col>
        <b-col cols="12" lg="6" class="pl-1">
          <div>
            <multiselect v-model="group.selected" :show-labels="false" :limit="3" track-by="smenuname" label="smenuname" placeholder="[ Masukan Menu ]" :options="group.combo">
            </multiselect>
          </div>
        </b-col>
      </b-row>
      <hr>
      <div>
        <table class="table table-bordered table-sm">
          <thead>
            <tr>
              <th scope="col" class="text-center">Instalasi</th>
              <th scope="col" class="text-center">Unit</th>
              <th scope="col" class="text-center">Ruangan</th>
              <th scope="col" class="text-center">
                <b-button variant="primary" @click="addRow">
                  <p class="h5 mb-0">+</p>
                </b-button>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(k, index) in group.detailRuangan" v-bind:key="k.codex">
              <td class="col-4">
                <div @click="tes(index)">
                  <multiselect :disabled="group.selectedRuangan.instalasi[index].id_instalasi.id === '-'" v-model="group.selectedRuangan.instalasi[index].id_instalasi" :show-labels="false" :limit="3" track-by="id" label="deskripsi" placeholder="[ Instalasi ]" :options="group.instalasi" @input="valueInstalasi">
                  </multiselect>
                </div>
              </td>
              <td class="col-4">
                <multiselect :disabled="group.selectedRuangan.instalasi[index].id_instalasi.id === '-'" v-model="group.selectedRuangan.unit[index].id_unit" :show-labels="false" :limit="3" track-by="id" label="deskripsi" placeholder="[ Unit ]" :options="group.comboRuangan.unit[index].data" @input="valueUnit">
                </multiselect>
              </td>
              <td class="col-4">
                <multiselect :disabled="group.selectedRuangan.instalasi[index].id_instalasi.id === '-'" v-model="group.selectedRuangan.ruangan[index].id_ruangan" :show-labels="false" :limit="3" track-by="id" label="deskripsi" placeholder="[ Ruangan ]" :options="group.comboRuangan.ruangan[index].data" @input="valueRuangan($event, index)">
                </multiselect>
              </td>
              <td class="col-1 text-center">
                <b-button variant="white" @click="deleteRow(index, k.id_ruangan, group.selectedRuangan.instalasi[index].id_instalasi.id, 'e')">
                  <p class="mb-0"><i class="fas fa-trash-alt text-danger"></i></p>
                </b-button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="bg-dark px-2 mt-2" v-if="group.message">
        <p class="text-right small text-white mb-0">{{ group.message }}</p>
      </div>
    </b-modal>
    <ModalMsgBox ref="MsgBox" />
  </div>
</template>
<script>
import { xray } from '../../../config/pluginInit'
import Service from '../../../services/fatmahost/index'
import Multiselect from 'vue-multiselect'
import ModalMsgBox from '../../../components/modal/ModalMsgBox.vue'

export default {
  name: 'ModuleList',
  components: {
    ModalMsgBox,
    Multiselect
  },
  computed: {
  },
  data () {
    return {
      group: {
        filter: '',
        id_grp: '',
        isBusy: false,
        fields: [
          { label: 'NO', key: 'index', class: 'text-center col-1' },
          { label: 'NAMA GROUP', key: 'grpname', class: 'text-left' },
          { key: 'action', class: 'text-left col-1' }
        ],
        items: this.listGroup,
        totalRows: null,
        currentPage: null,
        perPage: null,
        classIndex: '',
        selected: [],
        grpname: '',
        combo: [],
        created_at: '',
        created_by: '',
        message: '',
        instalasi: [],
        index: null,
        detailRuangan: [
          {
            id_ruangan: '0'
          }
        ],
        selectedRuangan: {
          instalasi: [
            {
              id_instalasi: ''
            }
          ],
          unit: [
            {
              id_unit: ''
            }
          ],
          ruangan: [
            {
              id_ruangan: ''
            }
          ]
        },
        comboRuangan: {
          unit: [
            {
              data: []
            }
          ],
          ruangan: [
            {
              data: []
            }
          ]
        }
      },
      module: {
        isBusy: false,
        fields: [
          { key: 'name', class: 'text-left' },
          { key: 'bitcontrol1', class: 'text-left' },
          { key: 'bitcontrol2', class: 'text-left' }
        ],
        items: [],
        totalRows: null,
        currentPage: null,
        perPage: 1000,
        selected: [],
        classIndex: ''
      },
      masterData: []
    }
  },
  mounted () {
    xray.index()
    this.selectMenu()
    this.newselectMenu()
    this.instalasi()
  },
  methods: {
    async instalasi () {
      try {
        const response = await Service.master.instalasiAll()
        this.group.instalasi = response.data.data
      } catch (e) {
        this.message = e.message
      }
    },
    async valueInstalasi (e) {
      let idx = this.group.index
      try {
        const response = await Service.master.unitAll(e.id)
        this.group.comboRuangan.unit[idx].data = response.data.data[0] !== null ? response.data.data : []
      } catch (e) {
        this.message = e.message
      }
    },
    async valueUnit (e) {
      let idx = this.group.index
      try {
        const response = await Service.master.ruangan(e.id)
        this.group.comboRuangan.ruangan[idx].data = response.data.data[0] !== null ? response.data.data : []
      } catch (e) {
        this.message = e.message
      }
    },
    valueRuangan ($event, index) {
      this.group.detailRuangan[index].id_ruangan = $event.id
    },
    tes (index) {
      this.group.index = index
    },
    async selectMenu () {
      const selectConfig = {
        currentPage: 0,
        perPage: 1000
      }
      const response = await Service.menu.findAll(selectConfig.currentPage, selectConfig.perPage)
      this.group.combo = response.data.data
    },
    async newselectMenu () {
      try {
        const response = await Service.menu.selectgroup()
        this.group.combo = response.data.data
      } catch (error) {
        this.message = error.message
      }
    },
    onFiltered (filteredItems) {
      this.group.totalRows = filteredItems.length
      this.group.currentPage = 1
    },
    async listGroup (ctx) {
      this.isBusy = true
      const selectConfig = {
        currentPage: 0,
        perPage: 1000
      }
      try {
        const response = await Service.group.findAll(selectConfig.currentPage, selectConfig.perPage, ctx.filter)
        this.group.totalRows = response.data.pagination.total
        this.group.currentPage = response.data.pagination.current
        this.group.perPage = response.data.pagination.perPage
        this.isBusy = false
        return response.data.data.sort((a, b) => a.id_grp - b.id_grp)
      } catch (err) {
        this.isBusy = false
        return []
      }
    },
    async moduleByIdGroup (item) {
      this.module.isBusy = true
      const id = item.id_grp
      this.group.classIndex = item
      try {
        const response = await Service.module.showModuleByIdGroup(id)
        this.module.items = response.data.data
        const aktive = response.data.data.filter(el => el.active_stat === 1 && el.level === 2)
        const checked = aktive.map(el => el.id_modules)
        this.module.selected = checked
        this.masterData = response.data.data
        this.module.isBusy = false
      } catch {
        this.module.selected = []
        this.module.isBusy = false
      }
      this.$refs.tablegroup.refresh()
    },
    rowClassGroup (item) {
      if (item !== null) {
        let a = this.group.classIndex
        if (a !== '') {
          if (item.grpname === a.grpname) return 'table-warning'
        }
      }
    },
    modalShow () {
      this.$refs['modal-tambah'].show()
      this.selectMenu()
    },
    async saveGroup (bvModalEvent) {
      bvModalEvent.preventDefault()
      try {
        const data = {
          object: 'user-group',
          id_smenu: this.group.selected.id_smenu.toString(),
          grpname: this.group.grpname,
          created_by: this.$store.state.Auth.user.id.toString(),
          detail: this.group.detailRuangan
        }
        const response = await Service.group.create(data)
        if (response.data.statcode === 0) {
          this.$refs['modal-tambah'].hide()
          this.$refs.MsgBox.show({
            type: 'success',
            msg: response.data.message
          })
          this.$refs.tablegroup.refresh()
          this.clearForm()
        } else {
          this.group.message = response.data.message
        }
      } catch (error) {
        this.group.message = error.message
      }
    },
    async updateGroup (bvModalEvent) {
      bvModalEvent.preventDefault()
      try {
        const id = this.group.id
        const data = {
          object: 'user-group',
          // created_at: '2023-10-05',
          created_by: this.group.created_by,
          id_smenu: this.group.selected.id_smenu,
          grpname: this.group.grpname,
          updated_by: xray.currentUser().id.toString(),
          detail: this.group.detailRuangan
        }
        const response = await Service.group.update(id, data)
        if (response.data.statcode === 0) {
          this.$refs['modal-update'].hide()
          this.$refs.MsgBox.show({
            type: 'success',
            msg: response.data.message
          })
          this.$refs.tablegroup.refresh()
          this.clearForm()
        } else {
          this.group.message = response.data.message
        }
      } catch (error) {
        this.group.message = error.message
      }
    },
    async deleteGroupDetail (index, ruangan) {
      try {
        const id = this.group.id
        const rgn = ruangan
        const response = await Service.group.destroyX(id, rgn)
        if (response.data.statcode === 0) {
          this.group.detailRuangan.splice(index, 1)
          this.group.selectedRuangan.instalasi.splice(index, 1)
          this.group.selectedRuangan.unit.splice(index, 1)
          this.group.selectedRuangan.ruangan.splice(index, 1)
          this.group.comboRuangan.unit.splice(index, 1)
          this.group.comboRuangan.ruangan.splice(index, 1)
          this.group.message = response.data.message
        } else {
          this.group.message = response.data.message
        }
      } catch (error) {
        this.group.message = error.message
      }
    },
    async saveModule () {
      this.module.isBusy = true
      const data = []
      this.masterData.map((value) => {
        data.push({
          id_grp: value.id_grp,
          id_modules: value.id_modules,
          active_stat: value.id_modules === this.module.selected.find(el => el === value.id_modules) ? 1 : 0,
          bitcontrol1: this.module.items.find(el => el.id_modules === value.id_modules).bitcontrol1,
          bitcontrol2: this.module.items.find(el => el.id_modules === value.id_modules).bitcontrol2,
          created_by: value.created_by,
          created_at: value.created_at,
          updated_by: this.$store.state.Auth.user.id
        })
      })
      const sendData = {
        object: 'module-group',
        data: data
      }
      await Service.module.createModuleByIdGroup(sendData).then((response) => {
        this.$refs.MsgBox.show({
          type: 'success',
          msg: response.data.message
        })
      }).catch(error => {
        this.$refs.MsgBox.show({
          type: 'error',
          msg: error.response
        })
      }).finally(() => {
        this.module.isBusy = false
        this.$refs.tablegroup.refresh()
      })
    },
    async editModal (id) {
      const response = await Service.group.findId(id)
      const data = response.data.data[0]
      this.group.id = id
      this.group.selected = this.group.combo.find(el => el.id_smenu === data.id_smenu)
      this.group.grpname = data.grpname
      this.group.created_by = data.created_by
      // this.group.created_at = data.created_at
      if (response.data.data[0].detail) {
        this.deleteRow(0, '', '', '')
        let detail = response.data.data[0].detail
        detail.map((value, index) => {
          this.group.detailRuangan.push(
            {
              id_ruangan: value.id.toString()
            }
          )
          this.group.selectedRuangan.instalasi.push(
            {
              id_instalasi: { id: '-', deskripsi: value.nama_instalasi }
            }
          )
          this.group.selectedRuangan.unit.push(
            {
              id_unit: { id: '-', deskripsi: value.nama_unit }
            }
          )
          this.group.selectedRuangan.ruangan.push(
            {
              id_ruangan: { id: '-', deskripsi: value.nama_ruangan }
            }
          )
          this.group.comboRuangan.unit.push(
            {
              data: []
            }
          )
          this.group.comboRuangan.ruangan.push(
            {
              data: []
            }
          )
        })
      }
      this.$refs['modal-update'].show()
    },
    addRow () {
      this.group.detailRuangan.push(
        {
          id_ruangan: '0'
        }
      )
      this.group.selectedRuangan.instalasi.push(
        {
          id_instalasi: ''
        }
      )
      this.group.selectedRuangan.unit.push(
        {
          id_unit: ''
        }
      )
      this.group.selectedRuangan.ruangan.push(
        {
          id_ruangan: ''
        }
      )
      this.group.comboRuangan.unit.push(
        {
          data: []
        }
      )
      this.group.comboRuangan.ruangan.push(
        {
          data: []
        }
      )
    },
    deleteRow (index, ruangan, instalasi, param) {
      if (param === 't') {
        this.group.detailRuangan.splice(index, 1)
        this.group.selectedRuangan.instalasi.splice(index, 1)
        this.group.selectedRuangan.unit.splice(index, 1)
        this.group.selectedRuangan.ruangan.splice(index, 1)
        this.group.comboRuangan.unit.splice(index, 1)
        this.group.comboRuangan.ruangan.splice(index, 1)
      } else if (param === 'e') {
        if (instalasi === '-') {
          this.deleteGroupDetail(index, ruangan)
        } else {
          this.group.detailRuangan.splice(index, 1)
          this.group.selectedRuangan.instalasi.splice(index, 1)
          this.group.selectedRuangan.unit.splice(index, 1)
          this.group.selectedRuangan.ruangan.splice(index, 1)
          this.group.comboRuangan.unit.splice(index, 1)
          this.group.comboRuangan.ruangan.splice(index, 1)
        }
      } else {
        this.group.detailRuangan.splice(index, 1)
        this.group.selectedRuangan.instalasi.splice(index, 1)
        this.group.selectedRuangan.unit.splice(index, 1)
        this.group.selectedRuangan.ruangan.splice(index, 1)
        this.group.comboRuangan.unit.splice(index, 1)
        this.group.comboRuangan.ruangan.splice(index, 1)
      }
    },
    clearForm () {
      this.group.message = ''
      //
      this.group.selected = []
      this.group.grpname = ''
      this.group.created_at = ''
      this.group.created_by = ''
      //
      let a = this.group.detailRuangan
      let aP = a.splice(0, a.length)
      let b = this.group.selectedRuangan.instalasi
      let bP = b.splice(0, b.length)
      let c = this.group.selectedRuangan.unit
      let cP = c.splice(0, c.length)
      let d = this.group.selectedRuangan.ruangan
      let dP = d.splice(0, d.length)
      let e = this.group.comboRuangan.unit
      let eP = e.splice(0, e.length)
      let f = this.group.comboRuangan.ruangan
      let fP = f.splice(0, f.length)
      //
      if (aP && bP && cP && dP && eP && fP) {
        this.addRow()
      }
    }
  }
}
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style>
#module-role .iq-card-body {
    padding: 0px;
}
#module-role .iq-card {
    margin-bottom: 10px;
}
#module-role .table td {
    font-size: 12px;
    font-weight: 500;
    padding-top: 5px;
    padding-bottom: 5px;
}
#module-role .table th {
    font-size: 13px;
    font-weight: 400;
}
#module-role tbody tr:focus {
  background: rgba(207, 194, 3, 0.24);
}
#module-role .cstm-cntrl {
  color: black!important;
  border-radius: 5px!important;
  font-size: 12px!important;
  height: 38px!important;
}
#module-role .form-area {
  color: black!important;
  border-radius: 5px!important;
  font-size: 12px!important;
}
#module-role .font-class {
  font-size: 12px;
}

#module-role .multiselect__select {
    height: 35px;
}
#module-role .multiselect__single {
  font-size: 12px!important;
  border-radius: 5px!important;
}
#module-role .multiselect__placeholder {
  border-radius: 5px!important;
  font-size: 12px!important;
  font-weight: normal;
}
#module-role .multiselect__option {
  border-radius: 5px!important;
  font-size: 12px;
}
#module-role .multiselect--disabled .multiselect__tags {
  border: 1px solid rgba(0, 0, 0, 0.171);
  overflow: hidden;
  background: #E9ECEF;
}
#module-role .multiselect--disabled {
  opacity: 1;
}
#module-role .multiselect--disabled .multiselect__tags .multiselect__single {
  overflow: hidden;
  background: transparent;
}
#module-role .multiselect__tags {
  border-radius: 5px!important;
  border: 1px solid rgba(0, 0, 0, 0.171)!important;
  padding: 5px 40px 0 8px!important;
  height: 38px!important;
  min-height: 38px!important;
}

#module-role  .custom-min-height {
  min-height: 100%;
}
#module-role .cstm-slct-role {
  padding-left: 8px;
  padding-right: 8px;
  font-size: 12px;
  height: 38px!important;
  border-radius: 5px;
  color: black;
}
</style>
