<template>
  <div id="halaman-login-v2" class="background-simrs">
    <Loader />
    <!-- <section class="sign-in-page">
      <b-container class="sign-in-page-bg p-0">
        <div class="row no-gutters">
          <div class="col-md-6 text-center position-relative">
            <div class="sign-in-detail text-white">
              <b-row class="text-center mb-5 justify-content-center align-items-center">
                <b-col col lg="2" class="pr-0">
                  <h1 class="text-white font-weight-bold">H</h1>
                </b-col>
                <b-col col lg="2" class="px-0"><img :src="logo" class="img-fluid" alt="logo"></b-col>
                <b-col col lg="2" class="pl-0">
                  <h1 class="text-white font-weight-bold">SPIA</h1>
                </b-col>
              </b-row>
              <Slick :option="slickOptions">
                <div class="item">
                  <img :src="require('../../assets/images/login/1.png')" class="img-fluid mb-4" alt="logo">
                  <h4 class="mb-1 text-white">Manage your orders</h4>
                  <p>It is a long established fact that a reader will be distracted by the readable content.</p>
                </div>
                <div class="item">
                  <img :src="require('../../assets/images/login/2.png')" class="img-fluid mb-4" alt="logo">
                  <h4 class="mb-1 text-white">Manage your orders</h4>
                  <p>It is a long established fact that a reader will be distracted by the readable content.</p>
                </div>
                <div class="item">
                  <img :src="require('../../assets/images/login/3.png')" class="img-fluid mb-4" alt="logo">
                  <h4 class="mb-1 text-white">Manage your orders</h4>
                  <p>It is a long established fact that a reader will be distracted by the readable content.</p>
                </div>
              </Slick>
            </div>
          </div>
          <div class="col-md-6 position-relative">
            <div class="sign-in-from">
              <router-view></router-view>
            </div>
          </div>
        </div>
      </b-container>
    </section> -->
    <!-- <section>
      <b-container>
        <div class="login-center pt-custom">
          <b-row class="justify-content-center align-items-center">
            <b-col cols="12" lg="5" class="p-0">
              <div class="text-white rounded px-5 pb-4 pt-4 shadow-sm backgound-login-left">
                <div class="d-flex flex-row align-items-center text-left mb-3">
                  <div class="mr-3">
                    <img :src="logokmks" class="img-fluid" width="200rem" alt="logo">
                  </div>
                </div>
                <div>
                  <p class="text-white text-left mb-0"><i class="fas fa-street-view"></i> Jl. RS FATMAWATI CILANDAK</p>
                  <p class="text-white text-left mb-0"><i class="fas fa-phone"></i> 7501524 / <i class="fas fa-fax"></i> 7501524</p>
                  <p class="text-white text-left mb-0"><i class="fas fa-envelope"></i> humas@fatmawatihospital.com</p>
                  <p class="text-white text-left mb-0"><i class="fas fa-globe"></i> www.rsupfatmawati.id</p>
                </div>
              </div>
            </b-col>
            <b-col cols="12" lg="4" class="p-0">
              <div class="sign-in-from rounded shadow p-5 ml-lg-n5 mt-n5 mt-lg-n1 px-3">
                <router-view></router-view>
              </div>
            </b-col>
          </b-row>
        </div>
      </b-container>
    </section> -->
    <section>
      <div>
        <b-container fluid>
          <div class="mt-3 mt-lg-0">
            <b-row>
              <b-col cols="12" lg="8" class="mb-4 mb-lg-0 d-none d-lg-block">
                <div class="height-full d-flex flex-row justify-content-lg-center align-items-center">
                  <div >
                    <img :src="logokmks" class="img-fluid width-responsive" alt="logo">
                  </div>
                </div>
              </b-col>
              <b-col cols="12" lg="4">
                <div class="height-full d-flex flex-row justify-content-center align-items-center border-lft">
                  <div class="w-100 px-lg-5 px-2 mx-lg-5 mx-0">
                    <router-view></router-view>
                  </div>
                </div>
              </b-col>
            </b-row>
          </div>
        </b-container>
      </div>
    </section>
  </div>
</template>
<script>
import Loader from '../../components/xray/loader/Loader'
// import logo from '../../assets/images/logo.png'
import kmks from '../../assets/images/kmenkes_rsup_logo.svg'
import logors from '../../assets/images/logo-rsup.png'
import { xray } from '../../config/pluginInit'
import bgImage from '../../assets/images/login/2.png'
export default {
  name: 'AuthLayout1',
  components: {
    Loader
  },
  mounted () {
    xray.index()
  },
  methods: {
    onSlideStart (slide) {
      this.sliding = true
    },
    onSlideEnd (slide) {
      this.sliding = false
    }
  },
  data () {
    return {
      // logo: logo,
      logors: logors,
      logokmks: kmks,
      bgImageURL: bgImage,
      slide: 0,
      sliding: null
    }
  }
}
</script>
<style>
#halaman-login-v2.background-simrs {
  background-color: white;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  min-height: 100vh;
}
#halaman-login-v2 .height-full {
  min-height: auto;
}
#halaman-login-v2 .kemenkes-color {
  color: #047d78 ;
}
#halaman-login-v2 .width-responsive {
  width: 10rem;
}
#halaman-login-v2 .border-lft {
  border-left: none;
}
#halaman-login-v2 .form-border {
  border: 1px solid rgba(0, 0, 0, 0.226);
  border-radius: .5rem;
}
#halaman-login-v2 .form-border-child-radius {
  border-top-left-radius: 0px!important;
  border-bottom-left-radius: 0px!important;
  border-top-right-radius: .5rem!important;
  border-bottom-right-radius: .5rem!important;
}
#halaman-login .pt-custom {
  padding-top: 20px;
  padding-left: 20px;
  padding-right: 20px;
}
#halaman-login.background-simrs {
  background-color: #089bab60;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  min-height: 100vh;
}
#halaman-login #carousel-1 .carousel-indicators {
  position: static;
  margin-top: 20px;
}
#halaman-login .login-center {
  transform: translate(0%, 0%);
}
#halaman-login .sign-in-from {
  position: relative;
  top: 0%!important;
}
#halaman-login .backgound-login-left {
  background-color: rgba(39, 75, 104, 0.5);
}
@media (min-width: 992px) {
  #halaman-login .pt-custom {
    padding-top: 150px;
    padding-left: 0px;
    padding-right: 0px;
  }
  #halaman-login .login-center {
    transform: translate(0%, 10%);
  }
  #halaman-login-v2 .height-full {
    min-height: 100vh;
  }
  #halaman-login-v2 .width-responsive {
    width: 40rem;
  }
  #halaman-login-v2 .border-lft {
    border-left: 2px solid #0000000e;
  }
}
</style>
