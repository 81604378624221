
<template>
  <div id="monitoring-bpjs">
    <b-container fluid>
      <div v-show="isLoading">
        <div id="show-overlay"></div>
        <Loader :style="{ opacity: .5 }" />
      </div>
      <form>
        <b-row>
          <b-col lg="12">
            <iq-card body-class="p-12">
              <template v-slot:body>
              </template>
            </iq-card>
          </b-col>
          <b-col lg="12">
            <div class="iq-edit-list-data">
              <tab-content id="pills-tabContent-2">
                <tab-content-item :active="true" id="kunjunganbpjs">
                  <div class="bg-green border px-3 py-2 d-flex flex-row">
                    <div class="d-flex flex-row align-items-center mr-auto">
                      <h5 class="card-title text-dark font-weight-bold m-0 p-0">Monitoring Status Pengiriman Antrian Ke
                        bpjs</h5>
                    </div>
                    <div class="mr-2">
                      <flat-pickr :config="config" v-model="search.tanggal_awal" value=""
                        @on-change="list()" placeholder="[ Tanggal Mulai ]"
                        class="w-100 calender-small custom-flat-pickr m-0">
                      </flat-pickr>
                    </div>
                    <!-- <div class="mr-2">
                      <flat-pickr :config="config" v-model="search.tanggal_ahkir" value=""
                        @on-change="convertDate($event, 'tanggalahkir')" placeholder="[ Tanggal Ahkir ]"
                        class="w-100 calender-small custom-flat-pickr m-0">
                      </flat-pickr>
                    </div> -->
                    <div>
                      <b-button variant="primary" class="rounded-1" size="sm" @click="cari()">
                        <i class="fas fa-search"></i> Cari
                      </b-button>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-lg-12">
                      <div class="row">
                        <div class="col-md-6 col-lg-3">
                          <div class="iq-card iq-card-block iq-card-stretch iq-card-height"><!---->
                            <div class="card-body iq-card-body iq-bg-primary rounded"><!----><!---->
                              <div class="d-flex align-items-center justify-content-between">
                                <div class="rounded-circle iq-card-icon bg-primary">
                                  <i class="fas fa-ambulance"></i>
                                </div>
                                <div class="text-right">
                                  <h2 class="mb-0"><span class="counter">{{ adakodebooking }}</span></h2>
                                  <h6 class="font-weight-bold">BERHASIL KIRIM ANTRIAN</h6>
                                </div>
                              </div>
                            </div><!---->
                          </div>
                        </div>
                        <div class="col-md-6 col-lg-3">
                          <div class="iq-card iq-card-block iq-card-stretch iq-card-height">
                            <div class="card-body iq-card-body iq-bg-info rounded">
                              <div class="d-flex align-items-center justify-content-between">
                                <div class="rounded-circle iq-card-icon bg-info">
                                  <i class="fas fa-prescription-bottle-alt"></i>
                                </div>
                                <div class="text-right">
                                  <h2 class="mb-0"><span class="counter">{{ sudahDilayani }}</span></h2>
                                  <h5 class="font-weight-bold">SUDAH DILAYANI</h5>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6 col-lg-3">
                          <div class="iq-card iq-card-block iq-card-stretch iq-card-height">
                            <div class="card-body iq-card-body iq-bg-warning rounded">
                              <div class="d-flex align-items-center justify-content-between">
                                <div class="rounded-circle iq-card-icon bg-warning">
                                  <i class="fas fa-x-ray"></i>
                                </div>
                                <div class="text-right">
                                  <h2 class="mb-0"><span class="counter">{{ belumDilayani }}</span></h2>
                                  <h5 class="font-weight-bold">BELUM DILAYANI</h5>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6 col-lg-3">
                          <div class="iq-card iq-card-block iq-card-stretch iq-card-height"><!---->
                            <div class="card-body iq-card-body iq-bg-danger rounded"><!----><!---->
                              <div class="d-flex align-items-center justify-content-between">
                                <div class="rounded-circle iq-card-icon bg-danger">
                                  <i class="fas fa-vial"></i>
                                </div>
                                <div class="text-right">
                                  <h2 class="mb-0"><span class="counter">{{ tanpakodebooking }}</span></h2>
                                  <h6 class="font-weight-bold">GAGAL KIRIM ANTRIAN</h6>
                                </div>
                              </div>
                            </div><!---->
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="table bg-white mb-0">
                    <b-table class="mb-0" ref="table" :busy="table.isBusy" :items="table.items" :fields="table.fields"
                    :current-page="table.currentPage" :per-page="table.perPage" stacked="md"
                    bordered hover striped small show-empty>
                      <template #table-busy>
                        <div class="text-center my-2">
                          <b-spinner class="align-middle text-primary mr-2"></b-spinner>
                          <strong class="text-dark">Memuat Data...</strong>
                        </div>
                      </template>
                      <template #cell(index)="data">
                        <span>{{ (table.currentPage*table.perPage)-table.perPage + data.index + 1 }}</span>
                      </template>
                      <template #thead-top>
                        <tr>
                          <th colspan="7"></th>
                          <th colspan="5">
                            <center>STATUS TASK ID</center>
                          </th>
                          <th colspan="1" class="text-center">
                            <b-button variant="danger" class="me-1" @click="sinc()">
                              <i class="ri-send-plane-fill"></i>SINC
                            </b-button>
                          </th>
                        </tr>
                      </template>
                      <template #cell(task1)="data">
                        <b-button variant="none" class="iq-bg-success mb-1" v-if="data.item.status === 'Selesai dilayani' || data.item.task1 === 1">
                          <i class="fas fa-clock"></i>
                        </b-button>
                        <b-button variant="none" class="iq-bg-danger mb-1" v-if="data.item.status === 'Belum dilayani' && data.item.task1 === ''">
                          <i class="fas fa-stopwatch"></i>
                        </b-button>
                      </template>
                      <template #cell(task2)="data">
                        <b-button variant="none" class="iq-bg-success mb-1" v-if="data.item.status === 'Selesai dilayani' || data.item.task1 === 1">
                          <i class="fas fa-clock"></i>
                        </b-button>
                        <b-button variant="none" class="iq-bg-danger mb-1" v-if="data.item.status === 'Belum dilayani' && data.item.task1 === ''">
                          <i class="fas fa-stopwatch"></i>
                        </b-button>
                      </template>
                      <template #cell(task3)="data">
                        <b-button variant="none" class="iq-bg-success mb-1" v-if="data.item.status === 'Selesai dilayani' || data.item.task1 === 1">
                          <i class="fas fa-clock"></i>
                        </b-button>
                        <b-button variant="none" class="iq-bg-danger mb-1" v-if="data.item.status === 'Belum dilayani' && data.item.task1 === ''">
                          <i class="fas fa-stopwatch"></i>
                        </b-button>
                      </template>
                      <template #cell(task4)="data">
                        <b-button variant="none" class="iq-bg-success mb-1" v-if="data.item.status === 'Selesai dilayani' || data.item.task1 === 1">
                          <i class="fas fa-clock"></i>
                        </b-button>
                        <b-button variant="none" class="iq-bg-danger mb-1" v-if="data.item.status === 'Belum dilayani' && data.item.task1 === ''">
                          <i class="fas fa-stopwatch"></i>
                        </b-button>
                      </template>
                      <template #cell(task5)="data">
                        <b-button variant="none" class="iq-bg-success mb-1" v-if="data.item.status === 'Selesai dilayani' || data.item.task1 === 1">
                          <i class="fas fa-clock"></i>
                        </b-button>
                        <b-button variant="none" class="iq-bg-danger mb-1" v-if="data.item.status === 'Belum dilayani' && data.item.task1 === ''">
                          <i class="fas fa-stopwatch"></i>
                        </b-button>
                      </template>
                      <template #cell(status)="data">
                        <b-button variant="success" class="" v-if="data.item.status === 'Selesai dilayani'">
                          {{ data.item.status }}
                        </b-button>
                        <b-button variant="warning" class="" v-if="data.item.status === 'Belum dilayani'" @click="sendTaskId(data.item.kodebooking)">
                          {{ data.item.status }}
                        </b-button>
                        <b-button v-if="data.item.kodebooking === undifined" variant="danger" class="me-1" @click="showModal(data.item)">
                          <i class="ri-send-plane-fill"></i>Kirim Antrian
                        </b-button>
                      </template>
                    </b-table>
                    <!-- <b-table id="my-table" ref="table" :items="table.items" :per-page="table.perPage" :current-page="table.currentPage" :fields="table.fields"
                      small></b-table> -->
                  </div>
                  <div class="table-pagination bg-white px-3 py-2 d-flex flex-row align-items-center">
                    <div>
                      <!-- <b-pagination v-model="table.currentPage" :total-rows="table.totalRows" :per-page="table.perPage"
                        size="sm" class="my-0"></b-pagination> -->
                      <b-pagination v-model="table.currentPage" :total-rows="rows" :per-page="table.perPage" aria-controls="my-table"></b-pagination>
                    </div>
                    <div @click="refreshTable" class="my-cursor">
                      <i class="fas fa-sync"></i>
                    </div>
                    <div class="ml-auto">
                      <p class="m-0 p-0 text-secondary">Menampilkan {{ table.totalRows }} baris</p>
                    </div>
                  </div>
                </tab-content-item>
              </tab-content>
            </div>
          </b-col>
        </b-row>
      </form>
      <template>
        <div>
          <b-modal v-model="isModalVisible" title="Data Tambah Antrian" @ok="onSend" ok-title="Kirim">
            <b-container fluid>
              <b-row>
                <b-col cols="4"><strong>Kode Booking:</strong></b-col>
                <b-col>
                  <b-form-input id="kodebooking" v-model="form.kodebooking"></b-form-input>
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="4"><strong>No Kartu:</strong></b-col>
                <b-col>
                  <b-form-input id="nokartu" v-model="form.nomorkartu"></b-form-input>
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="4"><strong>No Referensi:</strong></b-col>
                <b-col>
                  <b-form-input id="norujukan" v-model="form.nomorreferensi"></b-form-input>
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="4"><strong>NIK:</strong></b-col>
                <b-col>
                  <b-form-input id="nik" v-model="form.nik"></b-form-input>
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="4"><strong>No Rekam Medis:</strong></b-col>
                <b-col>
                  <b-form-input id="norm" v-model="form.norm"></b-form-input>
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="4"><strong>Pasien Baru:</strong></b-col>
                <b-col>
                  <b-form-input id="norm" v-model="form.pasienbaru"></b-form-input>
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="4"><strong>No HP:</strong></b-col>
                <b-col>
                  <b-form-input id="kodebooking" v-model="form.nohp"></b-form-input>
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="4"><strong>Kode Poli:</strong></b-col>
                <b-col>
                  <b-form-input id="kodepoli" v-model="form.kodepoli"></b-form-input>
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="4"><strong>Nama Poli:</strong></b-col>
                <b-col>
                  <b-form-input id="namapoli" v-model="form.namapoli"></b-form-input>
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="4"><strong>Kode Dokter:</strong></b-col>
                <b-col>
                  <b-form-input id="kodepoli" v-model="form.kodedokter"></b-form-input>
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="4"><strong>Nama Dokter:</strong></b-col>
                <b-col>
                  <b-form-input id="kodepoli" v-model="form.namadokter"></b-form-input>
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="4"><strong>Tanggal Periksa:</strong></b-col>
                <b-col>
                  <b-form-input id="tanggalperiksa" v-model="form.tanggalperiksa"></b-form-input>
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="4"><strong>Estimasi Waktu:</strong></b-col>
                <b-col>
                  <b-form-input id="kodepoli" v-model="form.estimasidilayani"></b-form-input>
                </b-col>
              </b-row>
            </b-container>
          </b-modal>
        </div>
      </template>
    </b-container>
  </div>
</template>

<script>
import { xray } from '../../../../../config/pluginInit.js'
import Loader from '../../../../../components/xray/loader/Loader'
import Services from '../../../../../services/fatmahost/connected/index.js'
import flatPickr from 'vue-flatpickr-component'
import 'flatpickr/dist/flatpickr.css'

export default {
  name: 'BPJS',
  components: {
    Loader,
    flatPickr
  },
  data () {
    return {
      isModalVisible: false,
      isLoading: false,
      table: {
        isBusy: false,
        items: this.list(),
        paginatedItems: [],
        fields: [
          { label: 'NO.', key: 'index', class: 'text-lg-center' },
          { label: 'KODE BOOKING', key: 'kodebooking', class: 'text-lg-center' },
          { label: 'NOMOR KARTU', key: 'noKartu', class: 'text-lg-center' },
          { label: 'NOMOR SEP', key: 'noSep', class: 'text-lg-center' },
          { label: 'NO REFERENSI', key: 'nomorreferensi', class: 'text-lg-center' },
          { label: 'POLI', key: 'poli', class: 'text-lg-center' },
          // { label: 'TANGGAL', key: 'tanggal' },
          // { label: 'ANTRIAN', key: 'noantrean' },
          { label: 'NAMA', key: 'nama' },
          { label: 'TASK ID 1', key: 'task1', class: 'text-lg-center' },
          { label: 'TASK ID 2', key: 'task2', class: 'text-lg-center' },
          { label: 'TASK ID 3', key: 'task3', class: 'text-lg-center' },
          { label: 'TASK ID 4', key: 'task4', class: 'text-lg-center' },
          { label: 'TASK ID 5', key: 'task5', class: 'text-lg-center' },
          // { label: 'TASK ID 6', key: 'task6', class: 'text-lg-center' },
          { label: 'STATUS', key: 'status', class: 'text-lg-center' }
        ],
        currentPage: 1,
        perPage: 10,
        totalRows: 100
      },
      options: {
        jenis_pelayanan: [
          { name: 'Ruang Rawat Inap', value: 1 },
          { name: 'Ruang Rawat Jalan', value: 2 }
        ],
        status: [
          { name: 'Proses Verifikasi', value: 1 },
          { name: 'Pending Verifikasi', value: 2 },
          { name: 'Klaim', value: 3 }
        ]
      },
      selected: {
        jeniskelamin: [],
        stsNikah: []
      },
      config: {
        dateFormat: 'Y-m-d',
        maxDate: 'today',
        disableMobile: true
      },
      search: {
        tanggal_awal: '',
        tanggal_ahkir: ''
      },
      form: {
        kodebooking: '',
        jenispasien: 'JKN',
        nomorkartu: '',
        nik: '',
        nohp: '',
        kodepoli: '',
        namapoli: '',
        kodedokter: '',
        namadokter: '',
        pasienbaru: 0,
        norm: '',
        tanggalperiksa: '',
        jampraktek: '08:00-16:00',
        jeniskunjungan: '2',
        nomorreferensi: '',
        nomorantrean: 'A-12',
        angkaantrean: 12,
        estimasidilayani: '',
        sisakuotajkn: 0,
        kuotajkn: 0,
        sisakuotanonjkn: 0,
        kuotanonjkn: 0,
        keterangan: 'Peserta harap 30 menit lebih awal guna pencatatan administrasi.'
      },
      antrol: [],
      sudahDilayani: '',
      belumDilayani: '',
      adakodebooking: '',
      tanpakodebooking: ''
    }
  },
  computed: {
    rows () {
      return this.table.items.length
    }
  },
  mounted () {
    xray.index()
  },
  methods: {
    async list () {
      this.table.isBusy = true
      try {
        const response = await Services.antreanPertanggal(this.search.tanggal_awal)
        const kunjungan = await this.gabungkanData(response.data.response.kunjungan, response.data.response.antrian)
        const antrian = await this.mergeData(kunjungan, response.data.response.taskid)
        const sortedData = antrian.sort((a, b) => {
          if (!a.kodebooking) return -1
          if (!b.kodebooking) return 1
          if (a.status === 'Belum dilayani') return -1
          if (b.status === 'Belum dilayani') return 1
          return 0
        })
        this.antrol = antrian.filter(item => item.kodebooking === null || item.kodebooking === undefined)
        const persentase = await this.hitungPersentase(antrian)
        const jmladdantrian = await this.hitungKodeBooking(antrian)
        this.adakodebooking = jmladdantrian.denganKodeBooking
        this.tanpakodebooking = jmladdantrian.tanpaKodeBooking
        this.sudahDilayani = persentase.persentaseDilayani
        this.belumDilayani = persentase.persentaseBelumDilayani
        this.table.items = antrian
        this.table.totalRows = this.table.items.length
        this.pagination()
        this.table.isBusy = false
        return sortedData
      } catch (error) {
        this.table.isBusy = false
        this.status = 400
        this.message = error.message
      }
    },
    async sinc () {
      const nokartu = this.antrol.map(item => item.noKartu)
      nokartu.forEach(async (item) => {
        await this.getDataAntrian(item)
        await this.onSend()
      })
    },
    async hitungPersentase (data) {
      let total = data.length
      let dilayani = 0
      let belumDilayani = 0

      data.forEach(item => {
        if (item.status === 'Selesai dilayani') {
          dilayani++
        }
        if (item.status === 'Belum dilayani') {
          belumDilayani++
        }
      })

      // let belumDilayani = total - dilayani

      let persentaseDilayani = (dilayani / total) * 100
      let persentaseBelumDilayani = (belumDilayani / total) * 100

      return {
        persentaseDilayani: persentaseDilayani.toFixed(2) + '%',
        persentaseBelumDilayani: persentaseBelumDilayani.toFixed(2) + '%'
      }
    },
    async hitungKodeBooking (dataArray) {
      let denganKodeBooking = 0
      let tanpaKodeBooking = 0

      dataArray.forEach(item => {
        if (item.kodebooking) {
          denganKodeBooking++
        } else {
          tanpaKodeBooking++
        }
      })

      return {
        denganKodeBooking,
        tanpaKodeBooking
      }
    },
    showModal (data) {
      this.isModalVisible = true
      this.getDataAntrian(data)
    },
    async sendTaskId (kodebooking) {
      try {
        const response = await Services.sendTask(kodebooking)
        if (response.data.metadata.code === 200) {
          this.$bvToast.toast(response.data.metadata.message, {
            title: 'Sukses',
            variant: 'success',
            autoHideDelay: 5000
          })
        } else {
          this.$bvToast.toast(response.data.metadata.message, {
            title: 'Gagal',
            variant: 'danger',
            autoHideDelay: 5000
          })
        }
      } catch (error) {
        this.$bvToast.toast(`error server`, {
          title: 'Gagal',
          variant: 'danger',
          autoHideDelay: 5000
        })
      }
    },
    async gabungkanData (kunjungan, antrian) {
      const kunjunganFiltered = kunjungan.filter(k => !['IGD', 'HDL'].includes(k.poli))
      return kunjunganFiltered.map(k => {
        const dataAntrian = antrian.find(a => a.nokapst === k.noKartu)
        return {
          ...k,
          ...(dataAntrian ? {
            kodebooking: dataAntrian.kodebooking,
            nomorreferensi: dataAntrian.nomorreferensi,
            noantrean: dataAntrian.noantrean,
            kodepoliAntrian: dataAntrian.kodepoli,
            estimasidilayani: dataAntrian.estimasidilayani,
            kodedokter: dataAntrian.kodedokter,
            jampraktek: dataAntrian.jampraktek,
            nohp: dataAntrian.nohp,
            status: dataAntrian.status
          } : {})
        }
      })
    },
    async mergeData (antrian, tasks, maxTasks = 6) {
      return antrian.map(item => {
        const taskIds = tasks.filter(task => task.nomor_reservasi.toUpperCase() === item.kodebooking).map(task => task.task)

        let merged = { ...item }
        for (let i = 1; i <= maxTasks; i++) {
          merged[`task${i}`] = taskIds[i - 1] || ''
        }

        return merged
      })
    },
    pagination () {
      const start = (this.table.currentPage - 1) * this.table.perPage
      const end = start + this.table.perPage
      this.table.paginatedItems = this.table.items.slice(start, end)
    },
    changePage (page) {
      if (page >= 1 && page <= this.totalPage()) {
        this.table.currentPage = page
        this.pagination()
      }
    },
    totalPage () {
      return Math.ceil(this.table.totalRows / this.table.perPage)
    },
    refreshTable () {
      this.cari()
    },
    async getDataAntrian (data) {
      this.table.isBusy = true
      try {
        const response = await Services.getDataAntrian(data.noKartu)
        // this.antrol = response.data[0]
        this.form.kodebooking = response.data[0].kodebooking
        this.form.nomorkartu = response.data[0].nokartu
        this.form.norujukan = response.data[0].norujukan
        this.form.nik = response.data[0].nik
        this.form.nohp = this.form.nohp = (response.data[0].nohp && response.data[0].nohp.length <= 12) ? response.data[0].nohp : '000000000000'
        this.form.kodepoli = data.poli
        this.form.namapoli = response.data[0].namapoli
        this.form.kodedokter = response.data[0].kodedokter
        this.form.namadokter = response.data[0].namadokter
        this.form.norm = response.data[0].norm
        this.form.pasienbaru = response.data[0].pasienbaru
        this.form.tanggalperiksa = response.data[0].tanggalperiksa
        this.form.nomorreferensi = (response.data[0].norujukan.replace(/B/g, 'I').length === 19) ? response.data[0].norujukan.replace(/B/g, 'I') : this.generateRandom19DigitNumber()
        this.form.estimasidilayani = this.convertToMilliseconds(response.data[0].estimasi)
        this.table.isBusy = false
      } catch (error) {
        this.table.isBusy = false
        this.status = 400
        this.message = error.message
      }
    },
    convertToMilliseconds (dateString) {
      return new Date(dateString).getTime()
    },
    async onSend () {
      try {
        const response = await Services.addTambahAntrian(this.form)
        if (response.data.metadata.code === 200) {
          this.$bvToast.toast(response.data.metadata.message, {
            title: 'Sukses',
            variant: 'success',
            autoHideDelay: 5000
          })
        } else {
          this.$bvToast.toast(response.data.metadata.message, {
            title: 'Gagal',
            variant: 'danger',
            autoHideDelay: 5000
          })
        }
        if (response.data.metadata.message === 'Data dokter tidak ditemukan.') {
          this.onSendAgain()
        }
      } catch (error) {
        this.$bvToast.toast(`error server`, {
          title: 'Gagal',
          variant: 'danger',
          autoHideDelay: 5000
        })
      }
    },
    async onSendAgain () {
      const today = new Date()
      const formattedDate = today.toISOString().split('T')[0]
      try {
        const response = await Services.getJadwalDokter(this.form.kodepoli, formattedDate)
        const dataDokter = response.data.response.filter(item => item.kodesubspesialis === this.form.kodepoli)
        this.form.kodedokter = dataDokter[0].kodedokter
        this.form.namadokter = dataDokter[0].namadokter
        const res = await Services.addTambahAntrian(this.form)
        if (res.data.metadata.code === 200) {
          this.$bvToast.toast(res.data.metadata.message, {
            title: 'Sukses',
            variant: 'success',
            autoHideDelay: 5000
          })
        } else {
          this.$bvToast.toast(res.data.metadata.message, {
            title: 'Gagal',
            variant: 'danger',
            autoHideDelay: 5000
          })
        }
      } catch (error) {
        this.message = error.message
      }
    },
    async generateRandom19DigitNumber () {
      let randomNumber = ''
      for (let i = 0; i < 19; i++) {
        randomNumber += Math.floor(Math.random() * 10)
      }
      return randomNumber
    }
  }
}
</script>
<style>
#monitoring-bpjs .page-link {
  background: transparent !important;
  border: none !important;
  color: gray !important;
  margin-right: 15px;
}

#monitoring-bpjs .page-item.active .page-link {
  background-color: transparent;
  border-color: transparent;
  color: #089bab !important;
  font-weight: bold;
}
#monitoring-bpjs .my-cursor {
  cursor: pointer;
}
#monitoring-bpjs .radius-custom {
  border-radius: .3rem;
}
#monitoring-bpjs .form-control-custom {
  height: 33px!important;
  background-color: transparent;
  border-radius: .3rem!important;
  font-size: 13px;
  color: black;
}
#monitoring-bpjs .multiselect__placeholder {
  font-weight: normal;
  font-size: 13px!important;
  color: #495057c9;
}
#monitoring-bpjs .multiselect__option {
  font-weight: normal;
  font-size: 13px;
}
#monitoring-bpjs .multiselect__tags {
  overflow: hidden;
  font-weight: normal;
  padding: 3px 40px 0 8px!important;
  height: 33px!important;
  min-height: 33px!important;
  border: 1px solid rgba(0, 0, 0, 0.212);
}
#monitoring-bpjs .multiselect {
  height: 33px!important;
  min-height: 33px!important;
}
#monitoring-bpjs .multiselect__select {
  height: 33px!important;
  min-height: 33px!important;
}
#monitoring-bpjs .multiselect__single  {
  padding-left: 0px;
}
#monitoring-bpjs .custom-fonts {
  font-size: 13px;
}
#monitoring-bpjs .custom-flat-pickr {
    border: 1px solid rgb(233, 233, 233);
    height: 33px;
    padding-left: 10px;
    padding-top: 5px;
    padding-bottom: 5px;
    border: 1px solid rgb(192, 192, 192)!important;
    border-radius: 5px;
    font-size: 12px;
    color: black;
}
.table-danger {
  background-color: #ea1325 !important;
}
</style>
