<template>
  <div>
    <b-row>
      <b-col cols="12" lg="8">
        <div class="bg-white">
          <div class="bg-primary radius-top px-3 py-2">
            <b-row class="justify-content-between align-items-center">
              <b-col cols="12" lg="3" class="mr-auto">
                <div>
                  <p class="text-white mb-0">Barang Produksi</p>
                </div>
              </b-col>
              <b-col cols="12" lg="5">
                <div>
                  <b-form-group class="mb-0">
                    <multiselect class="shadow-sm border-0 rounded" v-model="vselectJenisPendonor" :show-labels="false"
                      :limit="3" @input="valJenisPendonor" label="name" placeholder="Ruang Transaksi"
                      :options="comboJenisPendonor" :searchable="true">
                    </multiselect>
                  </b-form-group>
                </div>
              </b-col>
              <b-col cols="12" lg="3">
                <div>
                  <b-button variant="primary" class="btn-block shadow-sm w-100"><i class="fas fa-file"></i> Permintaan
                    Baru</b-button>
                </div>
              </b-col>
            </b-row>
          </div>
          <div class="shadow-sm">
            <b-row>
              <b-col>
                <div>
                  <b-form-group class="mb-0">
                    <multiselect class="shadow-sm border-0 rounded" v-model="vselectJenisPendonor" :show-labels="false"
                      :limit="3" @input="valJenisPendonor" label="name" placeholder="Ruang Transaksi"
                      :options="comboJenisPendonor" :searchable="true">
                    </multiselect>
                  </b-form-group>
                </div>
              </b-col>
              <b-col>
                <div>
                  <b-form-group class="mb-0">
                    <multiselect class="shadow-sm border-0 rounded" v-model="vselectJenisPendonor" :show-labels="false"
                      :limit="3" @input="valJenisPendonor" label="name" placeholder="Ruang Transaksi"
                      :options="comboJenisPendonor" :searchable="true">
                    </multiselect>
                  </b-form-group>
                </div>
              </b-col>
              <b-col>
                <div>
                  <b-form-group class="mb-0">
                    <multiselect class="shadow-sm border-0 rounded" v-model="vselectJenisPendonor" :show-labels="false"
                      :limit="3" @input="valJenisPendonor" label="name" placeholder="Ruang Transaksi"
                      :options="comboJenisPendonor" :searchable="true">
                    </multiselect>
                  </b-form-group>
                </div>
              </b-col>
              <b-col>4</b-col>
              <b-col>5</b-col>
            </b-row>
          </div>
        </div>
      </b-col>
      <b-col cols="12" lg="4">2</b-col>
    </b-row>
  </div>
</template>
<script>
import Multiselect from 'vue-multiselect'
export default {
  name: 'BarangProduksi',
  components: {
    Multiselect
  },
  data () {
    return {
      items: [
        { unit_asal: 1, tanggal_permintaan: 'Kategori 1', tanggal_pengiriman: 'Merk 1', status: '1' }
      ],
      items2: [
        { barang_diminta: 1, barang_dikirm: 'Kategori 1', jumlah_diminta: 'Merk 1', jumlah_dikirm: 'Merk 1' }
      ],
      comboJenisPendonor: [
        { name: 'Langsung', id: 1 },
        { name: 'Sukarela', id: 2 },
        { name: 'Pengganti', id: 3 },
        { name: 'Aferesis', id: 4 }
      ],
      vselectJenisPendonor: []
    }
  }
}
</script>
