import axios from 'axios'
import BaseURL from '@/config/constant'

const applikasi = axios.create({
  baseURL: BaseURL.serviceFatmahostAuth,
  withCredentials: true,
  headers: {
    'Accept': 'application/json',
    'Content-Type': 'application/json'
  }
})

const log = axios.create({
  withCredentials: true,
  baseURL: BaseURL.serviceFatmahostLog,
  headers: {
    'Accept': 'application/json',
    'Content-Type': 'application/json'
  }
})

const master = axios.create({
  withCredentials: true,
  baseURL: BaseURL.serviceFatmahostMaster,
  headers: {
    'Accept': 'application/json',
    'Content-Type': 'application/json'
  }
})

const users = axios.create({
  baseURL: BaseURL.serviceFatmahostUsers,
  headers: {
    'Accept': 'application/json',
    'Content-Type': 'application/json'
  }
})

const pegawai = axios.create({
  baseURL: BaseURL.serviceFatmahostPegawai,
  headers: {
    'Accept': 'application/json',
    'Content-Type': 'application/json'
  }
})

const pasien = axios.create({
  baseURL: BaseURL.serviceFatmahostPasien,
  headers: {
    'Accept': 'application/json',
    'Content-Type': 'application/json'
  }
})

const member = axios.create({
  baseURL: BaseURL.serviceFatmahostMember,
  headers: {
    'Accept': 'application/json',
    'Content-Type': 'application/json'
  }
})

const katalog = axios.create({
  withCredentials: true,
  baseURL: BaseURL.serviceFatmahostKatalog,
  headers: {
    'Accept': 'application/json',
    'Content-Type': 'application/json'
  }
})

const dashboard = axios.create({
  baseURL: BaseURL.serviceFatmahostDashboard,
  headers: {
    'Accept': 'application/json',
    'Content-Type': 'application/json'
  }
})

const keuangan = axios.create({
  baseURL: BaseURL.serviceFatmahostKeuangan,
  headers: {
    'Accept': 'application/json',
    'Content-Type': 'application/json'
  }
})

const eksport = axios.create({
  baseURL: BaseURL.serviceFatmahostEksport,
  headers: {
    'Accept': 'application/json',
    'Content-Type': 'application/json'
  }
})

const reservasi = axios.create({
  baseURL: BaseURL.serviceFatmahostReservasi,
  headers: {
    'Accept': 'application/json',
    'Content-Type': 'application/json'
  }
})

const connected = axios.create({
  baseURL: BaseURL.serviceFatmahostConnected,
  headers: {
    'Accept': 'application/json',
    'Content-Type': 'application/json'
  }
})

const middleware = axios.create({
  baseURL: BaseURL.serviceFatmahostMiddleware,
  headers: {
    'Accept': 'application/json',
    'Content-Type': 'application/json'
  }
})

const laravel = axios.create({
  baseURL: `${BaseURL.serviceHimsBackend}/api`,
  headers: {
    'Accept': 'application/json',
    'Content-Type': 'application/json'
  }
})

export default {
  applikasi,
  log,
  master,
  users,
  pegawai,
  pasien,
  member,
  katalog,
  dashboard,
  keuangan,
  eksport,
  reservasi,
  connected,
  middleware,
  laravel
}
