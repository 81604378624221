<template>
  <div class="form-wizard-cstm">
    <b-container fluid>
      <form-wizard @onComplete="onComplete">
        <tab-content title="JENIS PELAYANAN" :selected="true" class="px-3">
          <b-row>
            <b-col md="12">
              <div class="form-group">
                <b-row>
                  <b-col>
                    <b-form-group class="my-radio mb-0">
                      <b-form-radio-group id="radio-group-2" v-model="selected" name="radio-sub-component">
                        <div class="bg-primary px-2 py-2 shadow-sm rounded mr-3" style="display:inline-block;">
                          <b-form-radio value="first">Rawat Inap</b-form-radio>
                        </div>
                        <div class="bg-primary px-2 py-2 shadow-sm rounded" style="display:inline-block;">
                          <b-form-radio value="second">Rawat Jalan</b-form-radio>
                        </div>
                        <div class="ml-3" style="display:inline-block;">
                          <b-input-group prepend="Pendaftaran" class="mt-3">
                            <b-form-input type="search"></b-form-input>
                            <b-input-group-append>
                              <b-button variant="primary"><i class="fas fa-search"></i> Cari</b-button>
                            </b-input-group-append>
                          </b-input-group>
                        </div>
                      </b-form-radio-group>
                    </b-form-group>
                  </b-col>
                </b-row>
              </div>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <div class="form-group">
                <b-row>
                  <b-col md="12">
                    <label for="nama">Nama</label>
                    <input type="text" class="form-control" :class="hasError('nama') ? 'is-invalid' : ''"
                      placeholder="Masukan Nama" v-model="formData.nama">
                  </b-col>
                </b-row>
              </div>
              <div class="form-group">
                <b-row>
                  <b-col md="12">
                    <label for="fullName">Umur</label>
                    <input type="text" class="form-control" :class="hasError('umur') ? 'is-invalid' : ''"
                      placeholder="Masukan Umur" v-model="formData.umur">
                  </b-col>
                </b-row>
              </div>
              <div class="form-group">
                <b-row>
                  <b-col md="12">
                    <label for="namaLengkap">Cara keluar</label>
                    <multiselect v-model="formData.caraKeluar" :show-labels="false"
                      :class="hasError('caraKeluar') ? 'selectize-invalid' : ''" :limit="3" track-by="name"
                      :options="optionsB" label="name" placeholder="Cara Keluar">
                    </multiselect>
                    <div v-if="hasError('caraKeluar')" class="invalid-feedback">
                      <div class="error" v-if="!$v.formData.caraKeluar.required">Pilih Cara Keluar</div>
                    </div>
                  </b-col>
                </b-row>
              </div>
              <div class="form-group">
                <b-row>
                  <b-col md="12">
                    <label for="namaLengkap">DPJP</label>
                    <multiselect v-model="formData.dpjp" :class="hasError('dpjp') ? 'selectize-invalid' : ''"
                      :show-labels="false" :limit="3" track-by="name" :options="optionsB" label="name"
                      placeholder="DPJP">
                    </multiselect>
                    <div v-if="hasError('dpjp')" class="invalid-feedback">
                      <div class="error" v-if="!$v.formData.dpjp.required">Pilih DPJP</div>
                    </div>
                  </b-col>
                </b-row>
              </div>
              <div class="form-group mt-4">
                <b-row>
                  <b-col md="4">
                    <p class="h6 font-weight-light text-dark mb-0">ICU Indikator:</p>
                  </b-col>
                  <b-col>
                    <b-form-radio-group v-model="formData.icuIndikator" :options="optionsC"
                      :class="hasError('icuIndikator') ? 'is-invalid' : ''" name="plain-inline" plain>
                    </b-form-radio-group>
                  </b-col>
                </b-row>
                <div v-if="hasError('icuIndikator')" class="invalid-feedback">
                  <div class="error" v-if="!$v.formData.icuIndikator.required">Pilih Icu Indikator</div>
                </div>
              </div>
              <div class="form-group">
                <b-row>
                  <b-col md="4">
                    <p class="h6 font-weight-light text-dark mb-0">Upgrade KLS:</p>
                  </b-col>
                  <b-col>
                    <b-form-radio-group v-model="selectedC" :options="optionsC" name="plain-inline" plain>
                    </b-form-radio-group>
                  </b-col>
                </b-row>
                <div v-if="hasError('fullName')" class="invalid-feedback">
                  <div class="error" v-if="!$v.formData.fullName.required">Please provide a valid name.</div>
                </div>
              </div>
            </b-col>
            <b-col>
              <div class="form-group">
                <b-row>
                  <b-col md="12">
                    <label for="noSep">No SEP</label>
                    <input type="text" class="form-control" :class="hasError('noSep') ? 'is-invalid' : ''"
                      placeholder="Masukan Nama" v-model="formData.noSep">
                  </b-col>
                </b-row>
              </div>
              <div class="form-group">
                <b-row>
                  <b-col md="12">
                    <label for="fullName">NORM</label>
                    <input type="text" class="form-control" :class="hasError('noRm') ? 'is-invalid' : ''"
                      placeholder="Masukan No.RM" v-model="formData.noRm">
                  </b-col>
                </b-row>
              </div>
              <div class="form-group mt-4">
                <b-row>
                  <b-col md="4">
                    <p class="h6 font-weight-light text-dark mb-0">Jenis Kelamin:</p>
                  </b-col>
                  <b-col>
                    <b-form-radio-group v-model="selectedC" :options="optionsC" name="plain-inline" plain>
                    </b-form-radio-group>
                  </b-col>
                </b-row>
              </div>
              <b-form-group label="Date Input" label-for="exampleInputdate">
                <b-form-input id="exampleInputdate" v-model="formData.dateInput" :class="hasError('dateInput') ? 'is-invalid' : ''" type="date" value="2019-12-18"></b-form-input>
              </b-form-group>
              <div class="form-group">
                <b-row>
                  <b-col>
                    <label for="namaLengkap">Kelas</label>
                    <multiselect v-model="formData.kelas" :class="hasError('kelas') ? 'selectize-invalid' : ''" :show-labels="false" :limit="3" track-by="name"
                      :options="optionsB" label="name" placeholder="DPJP">
                    </multiselect>
                  </b-col>
                </b-row>
              </div>
              <div class="form-group">
                <b-row>
                  <b-col md="4 d-flex align-items-center">
                    <p class="h6 font-weight-light text-dark mb-0">ICU Los / vent:</p>
                  </b-col>
                  <b-col>
                    <b-form-input class="shadow-sm" type="text"></b-form-input>
                  </b-col>
                  <b-col>
                    <b-form-input class="shadow-sm" type="text"></b-form-input>
                  </b-col>
                </b-row>
                <div v-if="hasError('fullName')" class="invalid-feedback">
                  <div class="error" v-if="!$v.formData.fullName.required">Please provide a valid name.</div>
                </div>
              </div>
              <div class="form-group">
                <b-row>
                  <b-col md="4">
                    <p class="h6 font-weight-light text-dark mb-0">Kelas Upgrade:</p>
                  </b-col>
                  <b-col>
                    <b-form-radio-group v-model="selectedC" :options="optionsC" name="plain-inline" plain>
                    </b-form-radio-group>
                  </b-col>
                </b-row>
                <div v-if="hasError('fullName')" class="invalid-feedback">
                  <div class="error" v-if="!$v.formData.fullName.required">Please provide a valid name.</div>
                </div>
              </div>
            </b-col>
            <b-col>
              <div class="form-group">
                <b-row>
                  <b-col md="12">
                    <label for="noKartu">No Kartu</label>
                    <input type="text" class="form-control" :class="hasError('noKartu') ? 'is-invalid' : ''"
                      placeholder="Masukan Nama" v-model="formData.noKartu">
                  </b-col>
                </b-row>
              </div>
              <b-form-group label="Tanggal Lahir" label-for="exampleInputdate">
                <b-form-input id="exampleInputdate" v-model="formData.tanggalLahir" :class="hasError('tanggalLahir') ? 'is-invalid' : ''" type="date" value="2019-12-18"></b-form-input>
              </b-form-group>
              <div class="form-group">
                <b-row>
                  <b-col md="12">
                    <label for="beratBadan">Berat Badan</label>
                    <input type="text" class="form-control" :class="hasError('beratBadan') ? 'is-invalid' : ''"
                      placeholder="Masukan Nama" v-model="formData.beratBadan">
                  </b-col>
                </b-row>
              </div>
              <b-form-group label="Tanggal Keluar" label-for="exampleInputdate">
                <b-form-input id="exampleInputdate" v-model="formData.tanggalKeluar" :class="hasError('tanggalKeluar') ? 'is-invalid' : ''" type="date" value="2019-12-18"></b-form-input>
              </b-form-group>
              <div class="form-group">
                <b-row>
                  <b-col md="12">
                    <label for="tarif">Tarif Eks (Rp)</label>
                    <input type="text" class="form-control float-right text-right"
                      :class="hasError('tarif') ? 'is-invalid' : ''" placeholder="Rp.0"
                      v-model="formData.tarif">
                  </b-col>
                </b-row>
              </div>
              <div class="form-group">
                <b-row>
                  <b-col md="5" class="d-flex align-items-center">
                    <p class="h6 font-weight-light text-dark mb-0">ADL Sub Acute / chronic:</p>
                  </b-col>
                  <b-col>
                    <b-form-input class="shadow-sm" type="text"></b-form-input>
                  </b-col>
                  <b-col>
                    <b-form-input class="shadow-sm" type="text"></b-form-input>
                  </b-col>
                </b-row>
                <div v-if="hasError('fullName')" class="invalid-feedback">
                  <div class="error" v-if="!$v.formData.fullName.required">Please provide a valid name.</div>
                </div>
              </div>
              <div class="form-group">
                <b-row>
                  <b-col md="5" class="d-flex align-items-center">
                    <p class="h6 font-weight-light text-dark mb-0">Lama rawat Upgrade Kelas:</p>
                  </b-col>
                  <b-col>
                    <b-form-input class="shadow-sm" type="text"></b-form-input>
                  </b-col>
                  <b-col>
                    <b-form-input class="shadow-sm" type="text"></b-form-input>
                  </b-col>
                </b-row>
                <div v-if="hasError('fullName')" class="invalid-feedback">
                  <div class="error" v-if="!$v.formData.fullName.required">Please provide a valid name.</div>
                </div>
              </div>
            </b-col>
          </b-row>
        </tab-content>
        <tab-content title="TARIF RUMAH SAKIT" class="px-3">
          <b-row>
            <b-col md="12">
              <div class="form-group">
                <b-row class="justify-content-center">
                  <b-col md="5">
                    <b-form-group class="my-radio mb-0">
                      <label for="">
                        <p class="text-dark font-weight-bold mb-0">Total Tarif Rumah Sakit:</p>
                      </label>
                      <b-input-group prepend="Rp" class="">
                        <b-form-input placeholder="0" v-model="formData.totalTarifRS" :class="hasError('totalTarifRS') ? 'is-invalid' : ''" type="text"></b-form-input>
                        <b-input-group-append>
                          <b-button variant="primary">Release</b-button>
                        </b-input-group-append>
                      </b-input-group>
                    </b-form-group>
                  </b-col>
                </b-row>
              </div>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <b-form-group class="my-radio">
                <label for="">
                  <p class="text-dark mb-0">Prosedur Non Bedah:</p>
                </label>
                <b-input-group prepend="Rp" class="">
                  <b-form-input placeholder="0" v-model="formData.prosedurNonBedah" :class="hasError('prosedurNonBedah') ? 'is-invalid' : ''" type="text"></b-form-input>
                  <b-input-group-append>
                    <b-button variant="primary"><i class="far fa-money-bill-alt"></i></b-button>
                  </b-input-group-append>
                </b-input-group>
              </b-form-group>
              <b-form-group class="my-radio">
                <label for="">
                  <p class="text-dark mb-0">Tenaga Ahli:</p>
                </label>
                <b-input-group prepend="Rp" class="">
                  <b-form-input placeholder="0" v-model="formData.tenagaAhli" :class="hasError('tenagaAhli') ? 'is-invalid' : ''" type="text"></b-form-input>
                  <b-input-group-append>
                    <b-button variant="primary"><i class="far fa-money-bill-alt"></i></b-button>
                  </b-input-group-append>
                </b-input-group>
              </b-form-group>
              <b-form-group class="my-radio">
                <label for="">
                  <p class="text-dark mb-0">Radiologi:</p>
                </label>
                <b-input-group prepend="Rp" class="">
                  <b-form-input placeholder="0" v-model="formData.radiologi" :class="hasError('radiologi') ? 'is-invalid' : ''" type="text"></b-form-input>
                  <b-input-group-append>
                    <b-button variant="primary"><i class="far fa-money-bill-alt"></i></b-button>
                  </b-input-group-append>
                </b-input-group>
              </b-form-group>
              <b-form-group class="my-radio">
                <label for="">
                  <p class="text-dark mb-0">Rehabilitasi:</p>
                </label>
                <b-input-group prepend="Rp" class="">
                  <b-form-input placeholder="0" v-model="formData.rehabilitasi" :class="hasError('rehabilitasi') ? 'is-invalid' : ''" type="text"></b-form-input>
                  <b-input-group-append>
                    <b-button variant="primary"><i class="far fa-money-bill-alt"></i></b-button>
                  </b-input-group-append>
                </b-input-group>
              </b-form-group>
              <b-form-group class="my-radio">
                <label for="">
                  <p class="text-dark mb-0">Obat:</p>
                </label>
                <b-input-group prepend="Rp" class="">
                  <b-form-input placeholder="0" v-model="formData.obat" :class="hasError('obat') ? 'is-invalid' : ''" type="text"></b-form-input>
                  <b-input-group-append>
                    <b-button variant="primary"><i class="far fa-money-bill-alt"></i></b-button>
                  </b-input-group-append>
                </b-input-group>
              </b-form-group>
              <b-form-group class="my-radio">
                <label for="">
                  <p class="text-dark mb-0">Alkes:</p>
                </label>
                <b-input-group prepend="Rp" class="">
                  <b-form-input placeholder="0" v-model="formData.alkes" :class="hasError('alkes') ? 'is-invalid' : ''" type="text"></b-form-input>
                  <b-input-group-append>
                    <b-button variant="primary"><i class="far fa-money-bill-alt"></i></b-button>
                  </b-input-group-append>
                </b-input-group>
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group class="my-radio">
                <label for="">
                  <p class="text-dark mb-0">Prosedur Bedah:</p>
                </label>
                <b-input-group prepend="Rp" class="">
                  <b-form-input placeholder="0" v-model="formData.prosedurBedah" :class="hasError('prosedurBedah') ? 'is-invalid' : ''" type="text"></b-form-input>
                  <b-input-group-append>
                    <b-button variant="primary"><i class="far fa-money-bill-alt"></i></b-button>
                  </b-input-group-append>
                </b-input-group>
              </b-form-group>
              <b-form-group class="my-radio">
                <label for="">
                  <p class="text-dark mb-0">Keperawatan:</p>
                </label>
                <b-input-group prepend="Rp" class="">
                  <b-form-input placeholder="0" v-model="formData.keperawatan" :class="hasError('keperawatan') ? 'is-invalid' : ''" type="text"></b-form-input>
                  <b-input-group-append>
                    <b-button variant="primary"><i class="far fa-money-bill-alt"></i></b-button>
                  </b-input-group-append>
                </b-input-group>
              </b-form-group>
              <b-form-group class="my-radio">
                <label for="">
                  <p class="text-dark mb-0">Laboratorium:</p>
                </label>
                <b-input-group prepend="Rp" class="">
                  <b-form-input placeholder="0" v-model="formData.laboratorium" :class="hasError('laboratorium') ? 'is-invalid' : ''" type="text"></b-form-input>
                  <b-input-group-append>
                    <b-button variant="primary"><i class="far fa-money-bill-alt"></i></b-button>
                  </b-input-group-append>
                </b-input-group>
              </b-form-group>
              <b-form-group class="my-radio">
                <label for="">
                  <p class="text-dark mb-0">Kamar/Akomodasi:</p>
                </label>
                <b-input-group prepend="Rp" class="">
                  <b-form-input placeholder="0" v-model="formData.kamar" :class="hasError('kamar') ? 'is-invalid' : ''" type="text"></b-form-input>
                  <b-input-group-append>
                    <b-button variant="primary"><i class="far fa-money-bill-alt"></i></b-button>
                  </b-input-group-append>
                </b-input-group>
              </b-form-group>
              <b-form-group class="my-radio">
                <label for="">
                  <p class="text-dark mb-0">Obat Kronis:</p>
                </label>
                <b-input-group prepend="Rp" class="">
                  <b-form-input placeholder="0" v-model="formData.obatKronis" :class="hasError('obatKronis') ? 'is-invalid' : ''" type="text"></b-form-input>
                  <b-input-group-append>
                    <b-button variant="primary"><i class="far fa-money-bill-alt"></i></b-button>
                  </b-input-group-append>
                </b-input-group>
              </b-form-group>
              <b-form-group class="my-radio">
                <label for="">
                  <p class="text-dark mb-0">BMHP:</p>
                </label>
                <b-input-group prepend="Rp" class="">
                  <b-form-input placeholder="0" v-model="formData.bmhp" :class="hasError('bmhp') ? 'is-invalid' : ''" type="text"></b-form-input>
                  <b-input-group-append>
                    <b-button variant="primary"><i class="far fa-money-bill-alt"></i></b-button>
                  </b-input-group-append>
                </b-input-group>
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group class="my-radio">
                <label for="">
                  <p class="text-dark mb-0">Konsultasi:</p>
                </label>
                <b-input-group prepend="Rp" class="">
                  <b-form-input placeholder="0" v-model="formData.konsultasi" :class="hasError('konsultasi') ? 'is-invalid' : ''" type="text"></b-form-input>
                  <b-input-group-append>
                    <b-button variant="primary"><i class="far fa-money-bill-alt"></i></b-button>
                  </b-input-group-append>
                </b-input-group>
              </b-form-group>
              <b-form-group class="my-radio">
                <label for="">
                  <p class="text-dark mb-0">Penunjang:</p>
                </label>
                <b-input-group prepend="Rp" class="">
                  <b-form-input placeholder="0" v-model="formData.penunjang" :class="hasError('penunjang') ? 'is-invalid' : ''" type="text"></b-form-input>
                  <b-input-group-append>
                    <b-button variant="primary"><i class="far fa-money-bill-alt"></i></b-button>
                  </b-input-group-append>
                </b-input-group>
              </b-form-group>
              <b-form-group class="my-radio">
                <label for="">
                  <p class="text-dark mb-0">Pelayanan Darah:</p>
                </label>
                <b-input-group prepend="Rp" class="">
                  <b-form-input placeholder="0" v-model="formData.pelayananDarah" :class="hasError('pelayananDarah') ? 'is-invalid' : ''" type="text"></b-form-input>
                  <b-input-group-append>
                    <b-button variant="primary"><i class="far fa-money-bill-alt"></i></b-button>
                  </b-input-group-append>
                </b-input-group>
              </b-form-group>
              <b-form-group class="my-radio">
                <label for="">
                  <p class="text-dark mb-0">Rawat Intensif:</p>
                </label>
                <b-input-group prepend="Rp" class="">
                  <b-form-input placeholder="0" v-model="formData.RawatIntensif" :class="hasError('RawatIntensif') ? 'is-invalid' : ''" type="text"></b-form-input>
                  <b-input-group-append>
                    <b-button variant="primary"><i class="far fa-money-bill-alt"></i></b-button>
                  </b-input-group-append>
                </b-input-group>
              </b-form-group>
              <b-form-group class="my-radio">
                <label for="">
                  <p class="text-dark mb-0">Obat Khemoterapi:</p>
                </label>
                <b-input-group prepend="Rp" class="">
                  <b-form-input placeholder="0" v-model="formData.obatKemoterapi" :class="hasError('obatKemoterapi') ? 'is-invalid' : ''" type="text"></b-form-input>
                  <b-input-group-append>
                    <b-button variant="primary"><i class="far fa-money-bill-alt"></i></b-button>
                  </b-input-group-append>
                </b-input-group>
              </b-form-group>
              <b-form-group class="my-radio">
                <label for="">
                  <p class="text-dark mb-0">Sewa Alat:</p>
                </label>
                <b-input-group prepend="Rp" class="">
                  <b-form-input placeholder="0" v-model="formData.sewaAlat" :class="hasError('sewaAlat') ? 'is-invalid' : ''" type="text"></b-form-input>
                  <b-input-group-append>
                    <b-button variant="primary"><i class="far fa-money-bill-alt"></i></b-button>
                  </b-input-group-append>
                </b-input-group>
              </b-form-group>
            </b-col>
          </b-row>
        </tab-content>
        <tab-content title="Diagnosa Dan Inagrouper" class="px-3">
          <b-row>
            <b-col>
              <div class="form-group">
                <label for="referral" class="font-weight-bold">DIAGNOSA</label>
                <table class="table table-hover table-bordered">
                  <thead>
                    <tr>
                      <th scope="col">Nama</th>
                      <th scope="col">Kode</th>
                      <th scope="col">Jenis</th>
                      <th scope="col">
                        <div class="bg-primary text-center rounded shadow-sm px-4 py-1 text-white" @click="addRow"
                          style="cursor:pointer;"><i class="fas fa-plus"></i></div>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(u, index) in users" v-bind:key="u.id">
                      <td><input v-model="u.name" type="text" placeholder="Nilai..." class="form-control form-sm"></td>
                      <td><input v-model="u.email" type="text" placeholder="Nilai..." class="form-control form-sm"></td>
                      <td><input v-model="u.mobno" type="text" placeholder="Nilai..." class="form-control form-sm"></td>
                      <td>
                        <div class="bg-danger text-center rounded shadow-sm px-4 py-1 text-white" role="button"
                          @click="deleteRow(index)" style="cursor:pointer;"><i class="far fa-trash-alt"></i></div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </b-col>
            <b-col>
              <div class="form-group">
                <label for="referral" class="font-weight-bold">PROCEDURE</label>
                <table class="table table-hover table-bordered">
                  <thead>
                    <tr>
                      <th scope="col">Nama</th>
                      <th scope="col">Kode</th>
                      <th scope="col">
                        <div class="bg-primary text-center rounded shadow-sm px-4 py-1 text-white" @click="addRowB"
                          style="cursor:pointer;"><i class="fas fa-plus"></i></div>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(u, index) in usersB" v-bind:key="u.id">
                      <td><input v-model="u.name" type="text" placeholder="Nilai..." class="form-control form-sm"></td>
                      <td><input v-model="u.email" type="text" placeholder="Nilai..." class="form-control form-sm"></td>
                      <td>
                        <div class="bg-danger text-center rounded shadow-sm px-4 py-1 text-white" role="button"
                          @click="deleteRowB(index)" style="cursor:pointer;"><i class="far fa-trash-alt"></i></div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </b-col>
          </b-row>
          <div><hr class="my-4"></div>
          <b-row>
            <b-col>
              <div class="form-group">
                <label for="referral" class="font-weight-bold">DIAGNOSA INAGROUPER</label>
                <table class="table table-hover table-bordered">
                  <thead>
                    <tr>
                      <th scope="col">Nama</th>
                      <th scope="col">Kode</th>
                      <th scope="col">Jenis</th>
                      <th scope="col">
                        <div class="bg-primary text-center rounded shadow-sm px-4 py-1 text-white" @click="addRowC"
                          style="cursor:pointer;"><i class="fas fa-plus"></i></div>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(u, index) in usersC" v-bind:key="u.id">
                      <td><input v-model="u.name" type="text" placeholder="Nilai..." class="form-control form-sm"></td>
                      <td><input v-model="u.email" type="text" placeholder="Nilai..." class="form-control form-sm"></td>
                      <td><input v-model="u.mobno" type="text" placeholder="Nilai..." class="form-control form-sm"></td>
                      <td>
                        <div class="bg-danger text-center rounded shadow-sm px-4 py-1 text-white" role="button"
                          @click="deleteRowC(index)" style="cursor:pointer;"><i class="far fa-trash-alt"></i></div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </b-col>
            <b-col>
              <div class="form-group">
                <label for="referral" class="font-weight-bold">PROCEDURE INAGROUPER</label>
                <table class="table table-hover table-bordered">
                  <thead>
                    <tr>
                      <th scope="col">Nama</th>
                      <th scope="col">Kode</th>
                      <th scope="col">
                        <div class="bg-primary text-center rounded shadow-sm px-4 py-1 text-white" @click="addRowD"
                          style="cursor:pointer;"><i class="fas fa-plus"></i></div>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(u, index) in usersD" v-bind:key="u.id">
                      <td><input v-model="u.name" type="text" placeholder="Nilai..." class="form-control form-sm"></td>
                      <td><input v-model="u.email" type="text" placeholder="Nilai..." class="form-control form-sm"></td>
                      <td>
                        <div class="bg-danger text-center rounded shadow-sm px-4 py-1 text-white" role="button"
                          @click="deleteRowD(index)" style="cursor:pointer;"><i class="far fa-trash-alt"></i></div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </b-col>
          </b-row>
        </tab-content>
      </form-wizard>
    </b-container>
  </div>
</template>
<script>
import { FormWizard, TabContent, ValidationHelper } from 'vue-step-wizard'
import 'vue-step-wizard/dist/vue-step-wizard.css'
// import { required, numeric } from 'vuelidate/lib/validators'
import Multiselect from 'vue-multiselect'

export default {
  name: 'FormCustom',
  components: {
    FormWizard,
    TabContent,
    Multiselect
  },
  mixins: [ValidationHelper],
  data () {
    return {
      users: [
        { id: '', name: '', email: '', mobno: '', btnClass: 'btn btn-primary', iconClass: 'fas fa-plus' }
      ],
      usersB: [
        { id: '', name: '', email: '', mobno: '', btnClass: 'btn btn-primary', iconClass: 'fas fa-plus' }
      ],
      usersC: [
        { id: '', name: '', email: '', mobno: '', btnClass: 'btn btn-primary', iconClass: 'fas fa-plus' }
      ],
      usersD: [
        { id: '', name: '', email: '', mobno: '', btnClass: 'btn btn-primary', iconClass: 'fas fa-plus' }
      ],
      selected: 'first',
      options: [
        { text: 'Toggle this custom radio', value: 'first' },
        { text: 'Or toggle this other custom radio', value: 'second' }
      ],
      selectedB: 'A',
      valueSelected: null,
      optionsB: [
        { name: 'Atas Persetujuan Dokter', language: 'JavaScript' },
        { name: 'Di Rujuk', language: 'Ruby' }
      ],
      selectedC: 'first',
      optionsC: [
        { text: 'Ya', value: 'first' },
        { text: 'Tidak', value: 'second' },
        { text: 'Mungkin', value: 'third' }
      ],
      formData: {
        nama: '',
        umur: '',
        caraKeluar: '',
        dpjp: '',
        noSep: '',
        noRm: '',
        dateInput: '',
        kelas: '',
        noKartu: '',
        tanggalLahir: '',
        beratBadan: '',
        tanggalKeluar: '',
        tarif: '',
        totalTarifRS: '',
        prosedurNonBedah: '',
        tenagaAhli: '',
        radiologi: '',
        rehabilitasi: '',
        obat: '',
        alkes: '',
        prosedurBedah: '',
        keperawatan: '',
        laboratorium: '',
        kamar: '',
        obatKronis: '',
        bmhp: '',
        konsultasi: '',
        penunjang: '',
        pelayananDarah: '',
        RawatIntensif: '',
        obatKemoterapi: '',
        sewaAlat: ''
      }
      // validationRules: [
      //   { nama: { required }, umur: { required }, caraKeluar: { required }, dpjp: { required }, noSep: { required }, noRm: { required }, kelas: { required }, dateInput: { required }, noKartu: { required }, tanggalLahir: { required }, beratBadan: { required }, tanggalKeluar: { required }, tarif: { required } },
      //   { totalTarifRS: { required, numeric }, prosedurNonBedah: { required, numeric }, tenagaAhli: { required, numeric }, radiologi: { required, numeric }, rehabilitasi: { required, numeric }, obat: { required, numeric }, alkes: { required, numeric }, prosedurBedah: { required, numeric }, keperawatan: { required, numeric }, laboratorium: { required, numeric }, kamar: { required, numeric }, obatKronis: { required, numeric }, bmhp: { required, numeric }, konsultasi: { required, numeric }, penunjang: { required, numeric }, pelayananDarah: { required, numeric }, RawatIntensif: { required, numeric }, obatKemoterapi: { required, numeric }, sewaAlat: { required, numeric } },
      //   { referral: { required }, terms: { required } }
      // ]
    }
  },
  methods: {
    onComplete () {
      alert('Submitting Form ! Rock On')
    },
    addRow () {
      this.users.push(
        { name: '', email: '', mobno: '' }
      )
    },
    deleteRow (index) {
      this.users.splice(index, 1)
    },
    addRowB () {
      this.usersB.push(
        { name: '', email: '', mobno: '' }
      )
    },
    deleteRowB (index) {
      this.usersB.splice(index, 1)
    },
    addRowC () {
      this.usersC.push(
        { name: '', email: '', mobno: '' }
      )
    },
    deleteRowC (index) {
      this.usersC.splice(index, 1)
    },
    addRowD () {
      this.usersD.push(
        { name: '', email: '', mobno: '' }
      )
    },
    deleteRowD (index) {
      this.usersD.splice(index, 1)
    }
  }
}
</script>
<style>
.step-pills .step-item {
    padding: 0.5rem 0.5rem;
}
.vue-step-wizard {
    background-color: transparent;
    width: auto;
}
.step-footer {
    margin-top: 20px!important;
    padding: 10px 20px!important;
    scroll-margin-left: auto;
    margin: 1rem 0;
    text-align: right;
    margin-top: 0;
    padding: 0px;
    background: white;
    box-shadow: 2px 5px 4px 2px #8888883a;
}
.step-button.step-button-previous {
  margin-left: 0px!important;
}
.step-button {
    display: inline-block;
    margin: 0px 10px 0px 0px!important;
    border-radius: 10px!important;
    background: #089bab!important;
    color: white!important;
    font-weight: 400;
    color: #212529;
    text-align: center;
    vertical-align: middle;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    background-color: transparent;
    border: 1px solid transparent;
    padding: 0.375rem 2.75rem;
    font-size: 1rem;
    line-height: 1.5;
    border-radius: 10px;
    -webkit-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
}
.step-progress .bar {
    content: "";
    height: 1rem;
    border-radius: 1rem;
    background-color: #089bab!important;
}
.step-pills .step-item.active {
    border: 1px solid #089bab!important;
    background-color: #089bab!important;
}
.step-pills .step-item.active a {
  color: white!important;
}
.vue-step-wizard {
    padding: 0px;
}
.form-wizard-cstm .custom-control-input:checked ~ .custom-control-label::before {
    border-color: rgb(0, 196, 59)!important;
    background-color: rgb(0, 196, 59)!important;
}
.form-wizard-cstm .my-radio label {
    color: white!important;
}
.step-pills {
    justify-content: flex-start;
    padding-left: 30px!important;
}
.step-pills .step-item {
  margin-right: 20px;
}
.selectize-invalid {
  border: 1px solid red;
  border-radius: 5px;
}
</style>
