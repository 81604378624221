<template>
  <div>
    <b-row>
      <b-col cols="12" lg="5">
        <div class="bg-primary radius-top px-3 py-3">
          <div>
            <p class="text-white mb-1"><i class="fas fa-list"></i> Histori Pengiriman Barang</p>
          </div>
        </div>
        <div class="bg-white shadow-sm">
          <multiselect v-model="vselectJenisPendonor" :show-labels="false" :limit="3" @input="valJenisPendonor" label="name"
            placeholder="Ruangan / Depo" :options="comboJenisPendonor" :searchable="true">
          </multiselect>
        </div>
        <div>
          <b-table class="bg-white shadow-sm" ref="table" responsive striped hover show-empty :items="items2"></b-table>
        </div>
      </b-col>
      <b-col cols="12" lg="7">
        <div>
          <div class="bg-primary radius-top px-3 py-3">
            <div>
              <p class="text-white mb-1"><i class="fas fa-list"></i> Detail Item</p>
            </div>
          </div>
          <div>
            <b-table class="bg-white shadow-sm" ref="table" stacked="md" striped hover show-empty :items="items"></b-table>
          </div>
        </div>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import Multiselect from 'vue-multiselect'
export default {
  name: 'HistoryPengirimanBarang',
  components: {
    Multiselect
  },
  data () {
    return {
      items: [
        { no: 1, barang_diminta: 'Kategori 1', barang_dikirim: 'Merk 1', jumlah_diminta: 'Merk 1', jumlah_dikirim: 'Merk 1' }
      ],
      items2: [
        { no: 1, unit_meminta: 'Kategori 1', unit_tujuan: 'Merk 1', nomor_transaksi: '1', tanggal_pengiriman: 'Merk 1', status: '1', tag: '#' }
      ],
      comboJenisPendonor: [
        { name: 'Langsung', id: 1 },
        { name: 'Sukarela', id: 2 },
        { name: 'Pengganti', id: 3 },
        { name: 'Aferesis', id: 4 }
      ],
      vselectJenisPendonor: []
    }
  }
}
</script>
