<template>
  <div id="rumah-tangga-kemasan">
    <b-container fluid>
      <iq-card>
        <div class="pt-3 pl-3 pr-3 mb-3" id="header-table-filter">
          <div class="px-2 py-2 border d-flex align-items-center justify-content-between">
            <div class="mr-auto"><p class="text-dark font-weight-bold h6 mb-0">Master Kemasan</p>
            </div>
            <div><b-button class="py-1" variant="primary" @click="openModal('addModal', '')"><i class="fas fa-plus-circle "></i>Tambah</b-button></div>
          </div>
          <div class="table mb-0">
            <b-table class="mb-0" ref="table" :busy="table.isBusy" :items="table.items" :fields="table.fields"
              :current-page="table.currentPage" :per-page="table.perPage" :filter="table.filter" @filtered="onFiltered" stacked="md"
              bordered hover show-empty small striped
              :sort-by.sync="table.sortBy" :sort-desc.sync="table.sortDesc">
              <template #cell(index)="data">
                {{ (table.currentPage * table.perPage)-table.perPage + data.index + 1 }}
              </template>
              <template #cell(sts_aktif)="data">
               <span v-if="data.item.sts_aktif === 1"><b-badge pill variant="primary">Aktif</b-badge></span>
               <span v-else><b-badge pill variant="danger">Tidak Aktif</b-badge></span>
              </template>
              <template #cell(tindakan)="data">
                <div class="text-center">
                  <b-button @click="openModal('editModal', data)"
                    variant="mr-1 mb-1" size="sm">
                    <i class="fas fa-edit text-primary"></i>
                  </b-button>
                </div>
              </template>
              <template #cell(sysdate_in)="data">
                  <span>{{ new Date(data.item.sysdate_in) | formatDate }}</span>
                </template>
                <template #cell(sysdate_updt)="data">
                  <span>{{ new Date(data.item.sysdate_updt) | formatDate }}</span>
                </template>
            </b-table>
          </div>
          <div class="pagination-kemasan border shadow-sm p-2 mb-2">
              <b-row class="align-items-center">
                <b-col cols="4" class="d-flex flex-row align-items-center">
                  <div class="w-100">
                    <b-pagination
                      v-model="table.currentPage"
                      :total-rows="table.totalRows"
                      :per-page="table.perPage"
                      size="md"
                      class="my-0"
                    ></b-pagination>
                  </div>
                  <div class="w-100">
                    <div>
                      <span class="border-left border-dark mr-3 py-1"></span>
                      <span @click="tableRefresh" class="katalog-cursor">
                        <i class="fas fa-sync text-secondary"></i>
                      </span>
                      <span class="border-right border-dark ml-3 py-1"></span>
                    </div>
                  </div>
                </b-col>
                <b-col cols="5">
                  <div class="d-flex flex-row justify-content-end align-items-center">
                    <div class="mr-1 w-75">
                      <form class="searchbox mb-0">
                        <b-form-input type="text" class="kemasan-form-input py-1"  v-model="search.searchNama" @input="tableRefresh" placeholder="Cari Nama Kemasan ..."></b-form-input>
                      </form>
                    </div>
                  </div>
                </b-col>
                <b-col cols="3">
                  <div class="d-flex flex-row align-items-center justify-content-end">
                    <div class="mr-3">
                      <b-form-select
                        class="cstm-select-kemasan"
                        id="per-page-select"
                        v-model="table.perPage"
                        :options="table.pageOptions"
                      ></b-form-select>
                    </div>
                    <div>
                      <p class="text-dark mb-0">Menampilkan {{ table.perPage }} dari {{ table.totalRows }} baris</p>
                    </div>
                  </div>
                </b-col>
              </b-row>
            </div>
        </div>
      </iq-card>
      <b-modal id="modal-kemasan" ref="modal-kemasan" size="lg" centered no-close-on-backdrop hide-header-close
      small striped bordered hover  show-empty cancel-title="Batal" @ok="onSave" stacked="md"
      :title="modal.title" :ok-variant="modal.varOk" :ok-title="modal.ok" @cancel="clearData">
      <b-form>
          <b-form-group class="mb-2">
            <b-row class="align-items-center">
              <b-col cols="2">
                <span>
                  <p class="small text-dark mb-0">Kode</p>
                </span>
              </b-col>
              <b-col>
                <b-form-input class="new-cstm-cntrl-kemasan" v-model="modal.form.kode"  placeholder="Kode"></b-form-input>
              </b-col>
            </b-row>
          </b-form-group>
          <b-form-group class="mb-2">
            <b-row class="align-items-center">
              <b-col cols="2">
                <span>
                  <p class="small text-dark mb-0">Nama kemasan</p>
                </span>
              </b-col>
              <b-col>
                <b-form-input class="new-cstm-cntrl-kemasan" v-model="modal.form.nama_kemasan"  placeholder="Nama Kemasan"></b-form-input>
              </b-col>
            </b-row>
          </b-form-group>
          <b-form-group class="mb-2">
            <b-row class="align-items-center">
              <b-col cols="2">
                <span>
                  <p class="small text-dark mb-0">User Update</p>
                </span>
              </b-col>
              <b-col>
                <b-form-input class="new-cstm-cntrl-kemasan" v-model="modal.user_name" placeholder="Nama User" readonly></b-form-input>
              </b-col>
            </b-row>
          </b-form-group>
          <b-form-group class="mb-0">
            <b-row class="align-items-center">
              <b-col cols="2">
                <span>
                  <p class="small text-dark mb-0">Status Aktif</p>
                </span>
              </b-col>
              <b-col>
                <b-form-radio-group class="mb-0" :options="options.sts_aktif" v-model="modal.form.sts_aktif"></b-form-radio-group>
              </b-col>
            </b-row>
            <div class="bg-dark px-2" v-if="preview.message">
            <p class="text-right text-white mb-0">{{ preview.message }}</p>
          </div>
          </b-form-group>
        </b-form>
      </b-modal>
      <b-modal ref="modal-success" size="md" title="Informasi" ok-title="Oke" :ok-only=true :hide-header=true centered>
        <div class="text-center px-lg-4 px-2">
          <lottie :options="preview.lottie.defaultOptions" :autoPlay="true" :width="250" :height="250" />
          <p class="h6 text-success font-weight-bold mb-0"> Keterangan : Data Berhasil Disimpan</p>
        </div>
      </b-modal>
      <b-modal ref="modal-failed" size="md" title="Informasi" ok-title="Kembali" :ok-only=true :hide-header=true centered>
        <div class="text-center px-lg-4 px-2">
          <lottie :options="preview.lottie.defaultOptions2" :autoPlay="true" :width="250" :height="250" />
          <p class="h6 text-danger font-weight-bold mb-0">Data Gagal Disimpan</p>
          <p class="small text-danger font-weight-light mb-0">{{error}}</p>
        </div>
      </b-modal>
    </b-container>
  </div>
</template>
<script>
import { xray } from '../../../../config/pluginInit'
import jwtdecode from 'jwt-decode'
import Services from '../../../../services/fatmahost/index'
import Lottie from 'vue-lottie'
import animationLogo from '../../../../assets/lottie/checked.json'
import animationLogo2 from '../../../../assets/lottie/failed.json'

export default {
  name: 'MasterRumahTanggaKemasan',
  components: {
    Lottie
  },
  mounted () {
    xray.index()
  },
  data () {
    return {
      payload: '',
      error: '',
      search: {
        searchNama: ''
      },
      modal: {
        title: '',
        ok: '',
        varOk: '',
        user_name: xray.currentUser().name,
        id: null,
        form: {
          object: 'kemasan-rt',
          kode: '',
          nama_kemasan: '',
          sts_aktif: '',
          userid_in: xray.currentUser().id
        }
      },
      table: {
        fields: [
          { label: 'No.', key: 'index', class: 'text-center' },
          { label: 'Kode', key: 'kode', sortable: true },
          { label: 'Nama Kemasan', key: 'nama_kemasan', sortable: true },
          { label: 'User In', key: 'desc_userid_in' },
          // { label: 'Status', key: 'sts_aktif', class: 'text-center', sortable: true },
          { label: 'Last In', key: 'sysdate_in' },
          { label: 'Last Update', key: 'sysdate_updt' },
          { label: 'Aksi', key: 'tindakan', class: 'text-center' }
        ],
        sortBy: '',
        sortDesc: false,
        isBusy: false,
        items: this.list,
        filter: null,
        currentPage: null,
        perPage: 10,
        pageOptions: [10, 50, 100, 500],
        totalRows: null
      },
      options: {
        sts_aktif: [
          { value: 1, text: 'Aktif' },
          { value: 2, text: 'Tidak Aktif' }
        ]
      },
      preview: {
        lottie: {
          defaultOptions: { animationData: animationLogo, loop: true },
          defaultOptions2: { animationData: animationLogo2, loop: true }
        },
        user_name: jwtdecode(localStorage.getItem('token')).user.name,
        message: '',
        messageValue: ''
      }
    }
  },
  methods: {
    async list (ctx) {
      let filter = ''
      let sorting = ''
      let sortfield = ''
      if (ctx.sortBy) {
        sortfield = ctx.sortBy
      } else {
        sortfield = ''
      }
      if (ctx.sortDesc === true) {
        sorting = 'ASC'
      } else if (ctx.sortDesc === false) {
        sorting = 'DESC'
      } else {
        sorting = ''
      }
      let vParams4 = `{"xparam0":"${sortfield}","xparam1":"${sorting}"}`
      filter = `{"xparam0":"${this.search.searchNama}","xparam1":"","xparam2":"","xparam3":"","xparam4":"","xparam5":"","xparam6":"","xparam7":""}`
      this.isBusy = true
      try {
        const response = await Services.katalog.getKemasanAll(ctx.currentPage - 1, ctx.perPage, filter, vParams4)
        this.isBusy = false
        this.table.totalRows = response.data.pagination.total
        this.table.perPage = response.data.pagination.perPage
        this.table.currentPage = response.data.pagination.current
        return response.data.data
      } catch (error) {
        this.isBusy = false
        return []
      }
    },
    clearData () {
      this.modal.form.kode = ''
      this.modal.form.nama_kemasan = ''
      this.modal.form.sts_aktif = ''
      this.preview.message = ''
      this.preview.messageValue = ''
    },
    openModal (objModal, params) {
      if (objModal === 'addModal') {
        this.$refs['modal-kemasan'].show()
        this.modal.title = 'Tambah Master Kemasan'
        this.modal.ok = 'Simpan'
        this.modal.varOk = 'primary'
      } else if (objModal === 'editModal') {
        this.$refs['modal-kemasan'].show()
        this.modal.id = params.item.id
        this.modal.form.nama_kemasan = params.item.nama_kemasan
        this.modal.form.kode = params.item.kode
        this.modal.form.sts_aktif = params.item.sts_aktif
        this.modal.title = 'Edit Master Kemasan'
        this.modal.ok = 'Update'
        this.modal.varOk = 'primary'
      }
    },
    async onSave (bvModalEvent) {
      bvModalEvent.preventDefault()
      try {
        if (this.modal.form.kode !== '' && this.modal.form.nama_kemasan !== '' && this.modal.form.sts_aktif !== '') {
          this.payload = this.modal.form
          if (this.modal.ok === 'Simpan') {
            let response = await Services.katalog.saveKemasanRt(this.payload)
            if (response.data.statcode === 0) {
              this.preview.message = response.data.message
              this.preview.messageValue = '1'
              this.$refs.table.refresh()
              this.clearData()
              this.$refs['modal-kemasan'].hide()
              this.$refs['modal-success'].show()
              setTimeout(() => {
                this.$refs['modal-success'].hide()
              }, 3000)
            } else {
              this.$refs['modal-failed'].show()
              this.preview.message = response.data.message
              this.preview.messageValue = '2'
              this.$refs.table.refresh()
              this.clearData()
              setTimeout(() => {
                this.$refs['modal-failed'].hide()
              }, 3000)
            }
          } else if (this.modal.ok === 'Update') {
            this.payload.userid_updt = xray.currentUser().id
            let response = await Services.katalog.updateKemasanRt(this.modal.id, this.payload)
            if (response.data.statcode === 0) {
              this.$refs['modal-kemasan'].hide()
              this.$refs['modal-success'].show()
              this.preview.message = response.data.message
              this.preview.messageValue = '1'
              this.$refs.table.refresh()
              this.clearData()
              setTimeout(() => {
                this.$refs['modal-success'].hide()
              }, 3000)
            } else {
              this.$refs['modal-failed'].show()
              this.preview.message = response.data.message
              this.preview.messageValue = '2'
              this.$refs.table.refresh()
              this.clearData()
              setTimeout(() => {
                this.$refs['modal-failed'].hide()
              }, 3000)
            }
          }
        } else {
          this.preview.message = 'Harap Lengkapi Data !'
          this.preview.messageValue = '2'
        }
      } catch (error) {
        this.error = error
        this.$refs['modal-failed'].show()
        this.preview.messageValue = '2'
        this.$refs.table.refresh()
        this.clearData()
        setTimeout(() => {
          this.$refs['modal-failed'].hide()
        }, 3000)
      }
    },
    convertDate () {
      const date = new Date()
      const day = String(date.getDate()).padStart(2, '0')
      const month = String(date.getMonth() + 1).padStart(2, '0')
      const year = String(date.getFullYear())
      const hours = String(date.getHours()).padStart(2, '0')
      const minutes = String(date.getMinutes()).padStart(2, '0')
      const seconds = String(date.getSeconds()).padStart(2, '0')
      return year + '-' + month + '-' + day + ' ' + hours + ':' + minutes + ':' + seconds
    },
    onFiltered (filteredItems) {
      this.table.totalRows = filteredItems.length
    },
    tableRefresh () {
      this.$refs.table.refresh()
    }
  }
}
</script>
<style>
#modal-kemasan .custom-control-label, #modal-edit-jenisAnggaran .custom-control-label {
    font-size: 12px;
    margin-top: 0;
}
#modal-kemasan .multiselect__single, #modal-edit-jenisAnggaran .multiselect__single {
  font-size: 12px!important;
}
#modal-kemasan .multiselect__placeholder, #modal-edit-jenisAnggaran .multiselect__placeholder {
  font-size: 12px!important;
}
#modal-kemasan .multiselect__option, #modal-edit-jenisAnggaran .multiselect__option {
    font-size: 12px;
}
#modal-kemasan .multiselect__tags, #modal-edit-jenisAnggaran .multiselect__tags {
  padding: 5px 40px 0 8px!important;
  height: 38px!important;
  min-height: 38px!important;
}
#modal-kemasan .modal-body, #modal-edit-jenisAnggaran .modal-body {
  background: #3399331b
}

#modal-kemasan .modal-header, #modal-edit-jenisAnggaran .modal-header {
  background: #3399333a;
}

#modal-kemasan .modal-footer, #modal-edit-jenisAnggaran .modal-footer {
  background: #3399333a;
}
#rumah-tangga-kemasan .page-link {
  font-size: 14px;
  padding-top: 5px;
  padding-bottom: 5px;
  margin-right: 10px!important;
}
#rumah-tangga-kemasan .custom-button-radius {
  border: 1px solid rgba(0, 0, 0, 0.112);
  border-radius: 5px!important;
}
#rumah-tangga-kemasan .table td {
    font-size: 12px;
    font-weight: 500;
    padding-top: 0px;
    padding-bottom: 0px;
}
#rumah-tangga-kemasan .table th {
    font-size: 13px;
    font-weight: 400;
}
#rumah-tangga-kemasan .cstm-select-kemasan .cstm-srch-kemasan {
  font-size: 13px;
  height: 38px!important;
  border-radius: 5px;
}
.new-cstm-cntrl-kemasan {
  background: white!important;
  color: black!important;
  border-radius: 5px!important;
  font-size: 12px!important;
  height: 38px!important;
}
.katalog-form-area {
  color: black!important;
  border-radius: 5px!important;
  font-size: 12px!important;
}
#rumah-tangga-kemasan .pagination-sm .page-link {
  color: gray!important;
}
#rumah-tangga-kemasan .page-item.active .page-link {
  color: white!important;
}
#rumah-tangga-kemasan .page-item.active .page-link {
  color: white!important;
}
.custom-radius-add-katalog {
  border-radius: 5px;
  cursor: pointer;
}
.katalog-select-flex-left {
  width: 90%;
}
.katalog-select-flex-right {
  width: 10%;
}
.katalog-form-satuan-jenisAnggaran-left-right {
  width: 40%;
}
.katalog-form-satuan-jenisAnggaran-center {
  width: 15%;
}
.hr-katalog-custom {
  border-top: 1px solid rgba(0, 0, 0, 0.199)!important;
  margin: 0!important;
}
#rumah-tangga-kemasan .pagination-kemasan .page-link {
    border: none;
    color: gray!important;
    margin-right: 15px;
}
#rumah-tangga-kemasan .pagination-kemasan .page-item.active .page-link {
    background-color: transparent;
    border-color: transparent;
    color: #089bab!important;
    font-weight: bold;
}
</style>
