<template>
  <div id="katalog-view" class="mb-4">
    <b-container fluid>
      <b-row>
        <b-col cols="6" lg="2" class="mb-4">
          <div class="katalog-menu text-center shadow-sm px-2 py-4 rounded crsr" @click="toKatalog">
            <i class="fas fa-table  fa-3x"></i>
            <p class="mb-0 font-weight-bold">Katalog</p>
          </div>
        </b-col>
        <b-col cols="6" lg="2" class="mb-4">
          <div class="katalog-menu text-center shadow-sm px-2 py-4 rounded crsr" @click="toBrand">
            <i class="fab fa-bandcamp fa-3x"></i>
            <p class="mb-0 font-weight-bold">Brand</p>
          </div>
        </b-col>
        <b-col cols="6" lg="2" class="mb-4">
          <div class="katalog-menu text-center shadow-sm px-2 py-4 rounded crsr" @click="toGenerik">
            <i class="fas fa-tablets fa-3x"></i>
            <p class="mb-0 font-weight-bold">Generik</p>
          </div>
        </b-col>
        <b-col cols="6" lg="2" class="mb-4">
          <div class="katalog-menu text-center shadow-sm px-2 py-4 rounded crsr" @click="toKelompok">
            <i class="fas fa-layer-group fa-3x"></i>
            <p class="mb-0 font-weight-bold">Kelompok</p>
          </div>
        </b-col>
        <b-col cols="6" lg="2" class="mb-4">
          <div class="katalog-menu text-center shadow-sm px-2 py-4 rounded crsr" @click="toKemasan">
            <i class="fas fa-prescription-bottle-alt fa-3x"></i>
            <p class="mb-0 font-weight-bold">Kemasan</p>
          </div>
        </b-col>
        <b-col cols="6" lg="2" class="mb-4">
          <div class="katalog-menu text-center shadow-sm px-2 py-4 rounded crsr" @click="toPabrik">
            <i class="fas fa-industry fa-3x"></i>
            <p class="mb-0 font-weight-bold">Pabrik</p>
          </div>
        </b-col>
        <b-col cols="6" lg="2" class="mb-4">
          <div class="katalog-menu text-center shadow-sm px-2 py-4 rounded crsr" @click="toPbf">
            <i class="fas fa-book-medical fa-3x"></i>
            <p class="mb-0 font-weight-bold">PBF</p>
          </div>
        </b-col>
        <b-col cols="6" lg="2">
          <div class="katalog-menu text-center shadow-sm px-2 py-4 rounded crsr" @click="toBuffer">
            <i class="fas fa-warehouse fa-3x"></i>
            <p class="mb-0 font-weight-bold">Buffer Gudang</p>
          </div>
        </b-col>
        <b-col cols="6" lg="2" class="mb-4">
          <div class="katalog-menu text-center shadow-sm px-2 py-4 rounded crsr" @click="toJenisAnggaran">
            <i class="fas fa-window-maximize fa-3x"></i>
            <p class="mb-0 font-weight-bold">Jenis Anggaran</p>
          </div>
        </b-col>
        <b-col cols="6" lg="2">
          <div class="katalog-menu text-center shadow-sm px-2 py-4 rounded crsr" @click="toSubJenisAnggaran">
            <i class="fas fa-window-restore fa-3x"></i>
            <p class="mb-0 font-weight-bold">Sub Jenis Anggaran</p>
          </div>
        </b-col>
        <b-col cols="6" lg="2">
          <div class="katalog-menu text-center shadow-sm px-2 py-4 rounded crsr" @click="toSakti">
            <i class="fas fa-window-maximize fa-3x"></i>
            <p class="mb-0 font-weight-bold">Sakti</p>
          </div>
        </b-col>
        <b-col cols="6" lg="2">
          <div class="katalog-menu text-center shadow-sm px-2 py-4 rounded crsr" @click="toSaktiHdr">
            <i class="fas fa-window-restore fa-3x"></i>
            <p class="mb-0 font-weight-bold">Sakti Hdr</p>
          </div>
        </b-col>
        <b-col cols="6" lg="2">
          <div class="katalog-menu text-center shadow-sm px-2 py-4 rounded crsr" @click="toDosis">
            <i class="fas fa-prescription-bottle fa-3x"></i>
            <p class="mb-0 font-weight-bold">Dosis</p>
          </div>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>
<script>
import { xray } from '../../config/pluginInit'

export default {
  name: 'Index',
  data () {
    return {
      error: '',
      message: ''
    }
  },
  mounted () {
    xray.index()
  },
  methods: {
    toKatalog () {
      this.$router.push({
        name: 'katalog.katalog'
      })
    },
    toPabrik () {
      this.$router.push({
        name: 'katalog.pabrik'
      })
    },
    toKemasan () {
      this.$router.push({
        name: 'katalog.kemasan'
      })
    },
    toPbf () {
      this.$router.push({
        name: 'katalog.pbf'
      })
    },
    toGenerik () {
      this.$router.push({
        name: 'katalog.generik'
      })
    },
    toKelompok () {
      this.$router.push({
        name: 'katalog.kelompok'
      })
    },
    toBrand () {
      this.$router.push({
        name: 'katalog.brand'
      })
    },
    toBuffer () {
      this.$router.push({
        name: 'katalog.buffer'
      })
    },
    toJenisAnggaran () {
      this.$router.push({
        name: 'katalog.jenisanggaran'
      })
    },
    toSubJenisAnggaran () {
      this.$router.push({
        name: 'katalog.subjenisanggaran'
      })
    },
    toSakti () {
      this.$router.push({
        name: 'katalog.sakti'
      })
    },
    toSaktiHdr () {
      this.$router.push({
        name: 'katalog.saktihdr'
      })
    },
    toDosis () {
      this.$router.push({
        name: 'katalog.dosis'
      })
    }
  }
}
</script>

<style>
#katalog-view .crsr {
  cursor: pointer;
}
#katalog-view .katalog-menu {
  background: white;
  color: rgb(8, 155, 171);
  transition: all ease-in-out .3s;
}
#katalog-view .katalog-menu:hover {
  color: white;
  background: rgb(8, 155, 171);
}
#katalog-view .katalog-menu-disactive {
  background: white;
  color: rgb(133, 133, 133);
  transition: all ease-in-out .3s;
}
#katalog-view .katalog-menu-disactive:hover {
  color: white;
  background: rgb(133, 133, 133);
}
#katalog-view .katalog-menu-bug {
  background: white;
  color: rgb(207, 69, 0);
  transition: all ease-in-out .3s;
}
#katalog-view .katalog-menu-bug:hover {
  color: white;
  background: rgb(207, 69, 0);
}
</style>
