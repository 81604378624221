import Api from './fatmahost/axiosInstance'

export default {
  cekkoneksi () {
    return Api.laravel.get('getBpjsKoneksi/rsf')
  },
  nokartu (data) {
    return Api.laravel.post('setBpjsNoPeserta/rsf', data)
  },
  nik (data) {
    return Api.laravel.post('setBpjsNik/rsf', data)
  },
  nokartuadmin (data) {
    return Api.laravel.post('setBpjsNoPeserta', data)
  },
  nikadmin (data) {
    return Api.laravel.post('setBpjsNik', data)
  }
}
