<template>
  <div class="apm h-100">
    <b-container fluid class="h-100 px-0">
      <div class="h-100">
        <div class="box-parent full-height bg-white shadow-sm border">
          <div class="head bg-primary px-3 py-3">
            <p class="h3 text-center font-weight-bold mb-0">ANJUNGAN PENDAFTARAN MANDIRI</p>
          </div>
          <div class="body px-5 pt-4">
            <div class="d-flex flex-row justify-content-center align-items-center mt-4">
              <div>
                <img :src="require('../../assets/images/logo_kemenkes_new.svg')" width="300rem" class="d-inline-block align-top" alt="kemenkes">
              </div>
            </div>
            <div class="mb-5 px-4 py-5">
              <b-row class="justify-content-center align-items-center">
                <b-col cols="12" lg="4">
                  <div class="parent">
                    <div class="text-right mt-3">
                      <lottie :options="defaultOptions" :autoPlay="true" :width="400" />
                    </div>
                  </div>
                </b-col>
                <b-col cols="12" lg="2">
                  <router-link to="/apm-irj">
                    <div class="hover-btn-apm parent bg-primary rounded px-2 py-4">
                      <div class="text-center d-flex flex-row justify-content-center align-items-center">
                        <div>
                          <i class="fas fa-hand-point-up fa-7x mb-2"></i>
                          <p class="h5 text-center mb-0 mt-3">REGULER</p>
                          <p class="small text-center mb-0">( Rawat Jalan )</p>
                        </div>
                      </div>
                    </div>
                  </router-link>
                </b-col>
                <b-col cols="12" lg="2">
                  <router-link to="/apm-igh">
                    <div class="hover-btn-apm parent bg-primary rounded px-2 py-4">
                      <div class="text-center d-flex flex-row justify-content-center align-items-center">
                        <div>
                          <i class="fas fa-hand-point-up fa-7x mb-2"></i>
                          <p class="h5 text-center mb-0 mt-3">EKSEKUTIF</p>
                          <p class="small text-center mb-0">( Griya Husada )</p>
                        </div>
                      </div>
                    </div>
                  </router-link>
                </b-col>
                <!-- <b-col cols="12" lg="2">
                  <router-link to="/apm-irj-sep">
                    <div class="hover-btn-apm parent bg-primary rounded px-2 py-4">
                      <div class="text-center d-flex flex-row justify-content-center align-items-center">
                        <div>
                          <i class="fas fa-hand-point-up fa-7x mb-2"></i>
                          <p class="h5 text-center mb-0 mt-3">APM KHUSUS</p>
                          <p class="small text-center mb-0">( Rawat Jalan )</p>
                        </div>
                      </div>
                    </div>
                  </router-link>
                </b-col> -->
              </b-row>
            </div>
          </div>
        </div>
      </div>
    </b-container>
  </div>
</template>
<script>
import Lottie from 'vue-lottie'
import animationLogo from '../../assets/lottie/registration-animation.json'
export default {
  name: 'AnjunganPendaftaranMandiri',
  components: {
    Lottie
  },
  data () {
    return {
      nik: '',
      defaultOptions: { animationData: animationLogo, loop: true }
    }
  },
  methods: {
    async runAutoLogin () {
      // var pathFinger = 'C:\\Users\\User\\OneDrive\\Desktop\\finger.ahk'
      // const reader = new FileReader();
      // reader.onload = function(e) {
      //   console.log('File content:', e.target.result);
      // };
    }
  }
}
</script>
<style>
.apm .radius-top {
  border-radius: 10px 10px 0px 0px!important;
}
.apm .hover-btn-apm {
  box-shadow: 1px 1px 5 px #888888;
  cursor: pointer;
  transition: all ease-in-out .3s;
}
.apm .hover-btn-apm:hover {
  box-shadow: 5px 5px 10px #888888;
}
.apm .full-height {
  min-height: 100vh!important;
  background-image: url('../../assets/images/wave.svg');
  background-repeat: no-repeat;
  background-position: right bottom;
}
</style>
