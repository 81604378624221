<template>
  <div id="list-pengguna" class="list-pengguna">
    <b-container fluid>
      <b-row>
        <b-col md="12">
          <iq-card>
            <template v-slot:headerTitle>
              <h4 class="card-title">Data Pengguna</h4>
            </template>
            <template v-slot:body>
              <div class="table mb-0">
                <b-table class="mb-0"
                    ref="table"
                    bordered
                    hover
                    striped
                    :busy="isBusy"
                    :items="items"
                    :fields="fields"
                    :current-page="currentPage"
                    :per-page="perPage"
                    :filter="filter"
                    show-empty
                    small
                    @filtered="onFiltered"
                  >
                    <template #cell(index)="data">
                      {{ (currentPage*perPage)-perPage + data.index + 1 }}
                    </template>
                    <template #table-busy>
                      <div class="text-center text-danger my-2">
                        <b-spinner class="align-middle" variant="primary" type="grow" style="width: 3rem; height: 3rem;"></b-spinner>
                        <!-- <strong> Loading...</strong> -->
                      </div>
                    </template>
                    <!-- <template #cell(index)="data">
                      {{ data.index + 1 }}
                    </template> -->
                    <template v-slot:cell(tanggal_lahir)="data">
                      <span>{{ new Date(data.item.tanggal_lahir) | formatDate }}</span>
                    </template>
                    <template v-slot:cell(jenis_kelamin)="data">
                      <span>{{ data.item.jenis_kelamin == 1 ? 'Laki - laki' : 'Perempuan' }}</span>
                    </template>
                    <template v-slot:cell(action)="data">
                      <b-button variant="white" size="md" class="my-1 mx-1" @click="viewModalForgot(data.item.user_id, data.item.nama)"><i class="fas fa-lock-open text-danger"></i></b-button>
                      <!-- <b-button variant="white" size="md" class="my-1 mx-1" v-b-tooltip.hover.top="'Akses Pengguna'" @click="viewPengguna(data.item.pegawai_id)"><i class="fas fa-key text-warning"></i></b-button> -->
                      <b-button variant="white" size="md" class="my-1 mx-1" @click="modalPengguna(data.item.user_id)"><i class="fas fa-key text-secanodary"></i></b-button>
                    </template>
                  </b-table>
              </div>
              <div>
                  <div class="bg-white pb-2 pt-2 pl-3 pr-3 border-bottom border-right border-left">
                  <b-row class="align-items-center justify-content-between">
                    <b-col cols="12" lg="3" class="mr-auto">
                      <div class="d-flex align-items-center">
                        <div>
                          <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage" size="sm" class="my-0">
                          </b-pagination>
                        </div>
                        <div class="border-left border-right px-3">
                          <span class="cursor-point" @click="tableRefresh">
                            <i class="fas fa-sync"></i>
                          </span>
                        </div>
                      </div>
                    </b-col>
                    <b-col cols="12" lg="5">
                      <div class="d-flex flex-row">
                        <div class="mr-2 w-75">
                          <b-input-group>
                            <b-form-input class="cstm-slct-pegawai rounded-0" id="filter-input" v-model="filter" type="search" placeholder="[ Pencarian ]"></b-form-input>
                          </b-input-group>
                        </div>
                        <div>
                          <b-form-select class="cstm-slct-pegawai" v-model="selected.filterType" :options="combo.filterType" @change="filterchange"></b-form-select>
                        </div>
                      </div>
                    </b-col>
                    <b-col cols="12" lg="3">
                      <span class="text-right">
                        <p class="font-weight-normal mb-0">Menampilkan 1 - {{ perPage }} dari {{ totalRows }}</p>
                      </span>
                    </b-col>
                  </b-row>
                </div>
              </div>
            </template>
          </iq-card>
        </b-col>
      </b-row>
    </b-container>
    <b-modal ref="modal-forgot" size="md" title="Lupa Password" ok-title="Oke" :ok-only=true @ok="forgot" @cancel="clear" @close="clear" :no-close-on-backdrop="true" :static="true" centered>
      <div class="mb-2">
        <p class="text-dark mb-0">Pengguna : <span class="font-weight-bold">{{ dump }}</span></p>
      </div>
      <div>
        <b-input-group>
          <b-form-input v-model="dataForgot.password" class="cstm-slct-pegawai rounded-0" id="filter-input" type="text" placeholder="Masukan Password Baru"></b-form-input>
        </b-input-group>
      </div>
    </b-modal>
    <b-modal id="modal-akses-group" ref="modal-pengguna" size="md" title="Akses Pengguna" ok-title="Oke" :ok-only=true @ok="createGroup" @cancel="clear" @close="clear" :no-close-on-backdrop="true" :static="true" centered>
      <div class="mb-2">
        <p class="text-dark mb-0">Group Pengguna: <span class="font-weight-bold"></span></p>
      </div>
      <div>
        <multiselect v-model="selected.selected" :show-labels="false" :limit="3" track-by="grpname" label="grpname" placeholder="[ Masukan Group Pengguna ]" :options="combo.combo" @input="valGoup">
        </multiselect>
      </div>
    </b-modal>
    <ModalMsgBox ref="MsgBox"/>
  </div>
</template>
<script>
import { xray } from '../../../config/pluginInit'
import Service from '../../../services/fatmahost/index'
import ModalMsgBox from '../../../components/modal/ModalMsgBox'
import Multiselect from 'vue-multiselect'

export default {
  name: 'UiDataTable',
  components: {
    ModalMsgBox,
    Multiselect
  },
  mounted () {
    xray.index()
    this.listGroup()
  },
  methods: {
    default () {
      return {
        errors: [],
        id: this.items.length,
        nip: '',
        nama: '',
        tanggal_lahir: '',
        jenis_kelamin: '',
        alamat: ''
      }
    },
    viewModalForgot (param, param1) {
      this.dataForgot.pegawai_id = param
      this.dump = param1
      this.$refs['modal-forgot'].show()
    },
    tableRefresh () {
      this.$refs.table.refresh()
    },
    filterchange () {
      this.filter = ''
    },
    async listPengguna (ctx) {
      let filterType = this.selected.filterType
      let filter = ''
      if (ctx.filter === '') {
        ctx.filter = null
        filter = `{"xparam0":"","xparam1":"","xparam2":""}`
      }
      if (ctx.filter === null) {
        filter = `{"xparam0":"","xparam1":"","xparam2":""}`
      } else {
        if (filterType === '1') {
          filter = `{"xparam0":"${ctx.filter}","xparam1":"","xparam2":""}`
        } else if (filterType === '2') {
          filter = `{"xparam0":"","xparam1":"${ctx.filter}","xparam2":""}`
        } else if (filterType === '3') {
          filter = `{"xparam0":"","xparam1":"","xparam2":"${ctx.filter}"}`
        }
      }
      try {
        const response = await Service.pengguna.findAll(ctx.currentPage - 1, ctx.perPage, filter)
        this.totalRows = response.data.pagination.total
        this.currentPage = response.data.pagination.current
        this.perPage = response.data.pagination.perPage
        this.isBusy = false
        return response.data.data
      } catch (err) {
        this.isBusy = false
        return []
      }
    },
    async forgot () {
      const data = this.dataForgot
      await Service.users.forgot(data).then(response => {
        this.$refs.table.refresh()
        this.$refs.MsgBox.show({
          type: 'success',
          msg: response.data.message
        })
        this.clear()
      }).catch(error => {
        this.$refs.table.refresh()
        this.$refs.MsgBox.show({
          type: 'error',
          msg: error.response.data.errors.message
        })
        this.clear()
      })
    },
    viewPengguna (id) {
      this.$router.push('view/' + id)
    },
    onFiltered (filteredItems) {
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
    async listGroup () {
      const config = {
        currentPage: 0,
        perPage: 1000
      }
      try {
        const response = await Service.group.findAll(config.currentPage, config.perPage, '')
        this.combo.combo = response.data.data
      } catch (err) {
        this.error = err.response.data.errors.message
      }
    },
    async createGroup () {
      let id = this.paramgroup.toString()
      let data = this.dataGroup
      await Service.pengguna.update(id, data).then(response => {
        this.$refs.table.refresh()
        this.$refs.MsgBox.show({
          type: 'success',
          msg: response.data.message
        })
        this.clear()
      }).catch(error => {
        this.$refs.table.refresh()
        this.$refs.MsgBox.show({
          type: 'error',
          msg: error.response.data.errors.message
        })
        this.clear()
      })
    },
    valGoup (param) {
      this.dataGroup.id_grp = param.id_grp.toString()
    },
    modalPengguna (id) {
      this.paramgroup = id
      this.$refs['modal-pengguna'].show()
    },
    clear () {
      this.dataForgot.pegawai_id = ''
      this.dataForgot.password = ''
      this.dataGroup.object = 'pengguna-practitioner'
      this.dataGroup.id_grp = ''
      this.selected.selected = []
    }
  },
  data () {
    return {
      dump: '',
      isBusy: false,
      selected: {
        filterType: '1',
        selected: []
      },
      combo: {
        filterType: [
          { value: '1', text: 'Nama' },
          { value: '2', text: 'Usename' },
          { value: '3', text: 'NIP' }
        ],
        combo: []
      },
      fields: [
        { label: 'NO', key: 'index', class: 'text-center' },
        { label: 'NAMA LENGKAP', key: 'nama', class: 'text-left', thClass: 'text-center' },
        { label: 'NO. INDUK PEGAWAI', key: 'nip', class: 'text-left', thClass: 'text-center' },
        { label: 'USERNAME', key: 'username', class: 'text-center', thClass: 'text-center' },
        { label: 'TANGGAL LAHIR', key: 'tanggal_lahir', class: 'text-center' },
        { label: 'GROUP PENGGUNA', key: 'grpname', class: 'text-left', thClass: 'text-center' },
        { label: 'AKSI', key: 'action', class: 'text-center' }
      ],
      items: this.listPengguna,
      totalRows: null,
      currentPage: null,
      perPage: null,
      filterOn: [],
      filter: null,
      dataForgot: {
        pegawai_id: '',
        password: ''
      },
      paramgroup: '',
      dataGroup: {
        object: 'pengguna-practitioner',
        id_grp: ''
      },
      itemsGroup: [
        { age: 40, first_name: 'Dickerson', last_name: 'Macdonald' },
        { age: 21, first_name: 'Larsen', last_name: 'Shaw' },
        { age: 89, first_name: 'Geneva', last_name: 'Wilson' },
        { age: 38, first_name: 'Jami', last_name: 'Carney' }
      ]
    }
  }
}
</script>
<style>
#list-pengguna .table td {
    font-size: 12px;
    font-weight: 500;
    padding-top: 0px;
    padding-bottom: 0px;
}
#list-pengguna .table th {
    font-size: 13px;
    font-weight: 400;
}
#list-pengguna .page-link {
    border: none;
    color: gray!important;
    margin-right: 15px;
}
#list-pengguna .page-item.active .page-link {
    background-color: transparent;
    border-color: transparent;
    color: #089bab!important;
    font-weight: bold;
}
#list-pengguna .cursor-point {
    cursor: pointer;
}
.cstm-slct-pegawai {
  font-size: 13px!important;
  height: 38px!important;
  border-radius: 5px;
}
.cstm-cntrl-pegawai {
  color: black!important;
  font-weight: bold!important;
  border-radius: 5px!important;
  font-size: 12px!important;
  height: 38px!important;
}
.custom-fontsize {
  font-size: 12px;
}
#modal-akses-group .table td {
  font-size: 12px;
  font-weight: 500;
  padding-top: 10px;
  padding-bottom: 10px;
}
#modal-akses-group .table th {
  font-size: 13px;
  font-weight: 400;
}
</style>
