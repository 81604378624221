<template>
  <div id="bios-jumlah-visite" class="mb-4">
    <b-container fluid>
      <div>
        <iq-card>
          <template v-slot:body>
            <div class="filter-jumlah-visite border p-2">
              <b-row class="align-items-center">
                <b-col>
                  <div>
                    <h5 class="card-title font-weight-bold mb-0">Jumlah Visite Pasien &lt;= 10</h5>
                  </div>
                </b-col>
                <b-col>
                  <div class="text-right">
                    <b-button class="custom-button-radius" size="sm" variant="primary" id="show-btn" @click="showModal"><i
                        class="fas fa-plus"></i>Tambah</b-button>
                  </div>
                </b-col>
              </b-row>
            </div>
            <div class="table mb-0">
              <b-table
                class="mb-0"
                ref="tables"
                :sort-by.sync="tables.sortBy"
                :sort-desc.sync="tables.sortDesc"
                :busy="tables.isBusy"
                :current-page="tables.currentPage"
                :per-page="tables.perPage"
                stacked="md" bordered hover show-empty striped
                :items="tables.itemsRO"
                :fields="tables.fieldsRO">
                <template #table-busy>
                  <div class="text-center my-2">
                    <b-spinner class="align-middle text-primary mr-2"></b-spinner>
                    <strong class="text-dark">Memuat Data...</strong>
                  </div>
                </template>
                <template #cell(index)="data">
                  <div class="py-2">
                    {{ (tables.currentPage*tables.perPage)-tables.perPage + data.index + 1 }}
                  </div>
                </template>
                <template #cell(tgl_transaksi)="data">
                  <div class="py-2">
                    {{ new Date(data.item.tgl_transaksi) | formatDate }}
                  </div>
                </template>
              </b-table>
            </div>
            <div class="pagination-bios-jumlah-visite border shadow-sm p-2 mb-2">
              <b-row class="align-items-center">
                <b-col cols="4" class="d-flex flex-row align-items-center">
                  <div class="w-100">
                    <b-pagination v-model="tables.currentPage" :total-rows="tables.totalRows" :per-page="tables.perPage"
                      size="md" class="my-0"></b-pagination>
                  </div>
                  <div class="w-100">
                    <div>
                      <span class="border-left border-dark mr-3 py-1"></span>
                      <span @click="tableRefresh" class="katalog-cursor">
                        <i class="fas fa-sync text-secondary"></i>
                      </span>
                      <span class="border-right border-dark ml-3 py-1"></span>
                    </div>
                  </div>
                </b-col>
              </b-row>
            </div>
          </template>
        </iq-card>
      </div>
    </b-container>
    <b-modal id="modal-jumlah-visite-pasien" ref="modal-jumlah-visite-pasien" size="lg" centered :no-close-on-backdrop="true" :static="true"
      title="Tambah Data IKT Jumlah Visite Pasien &lt;= 10" ok-title="Simpan Data" @close="clearData" @cancel="clearData" scrollable
      cancel-title="Tutup" @ok="tambahSave">
      <!-- <b-form class="py-2">
        <b-row>
          <b-col>
            <b-form-group label-for="tanggal" class="mb-1">
              <b-row>
                <b-col cols="12" md="2">
                  <p class="custom-label-katalog text-dark mb-0">Tanggal</p>
                </b-col>
                <b-col>
                  <flat-pickr :config="config" @on-change="tglDetail($event, index)" v-model="tanggal" value="" placeholder="[ Pilih Tanggal ]" class="w-100 custom-flatpicker calender-small">
                  </flat-pickr>
                </b-col>
              </b-row>
            </b-form-group>
          </b-col>
          <b-col>
          </b-col>
        </b-row>
      </b-form>
      <hr class="m-0"> -->
      <div>
        <b-row>
          <b-col cols="12">
            <div class="table-responsive-xl">
              <table class="table bg-white table-bordered table-sm m-0 p-0">
                <thead>
                  <tr>
                    <th scope="col" style="vertical-align: center;" class="text-center">
                      <p class="mb-0 font-weight-normal text-center">Nomor</p>
                    </th>
                    <th scope="col" style="vertical-align: center;" class="text-center">
                      <p class="mb-0 font-weight-normal text-center">Tanggal</p>
                    </th>
                    <th scope="col" style="vertical-align: center;" class="text-center">
                      <p class="mb-0 font-weight-normal text-center">Jumlah ( Persentase Capaian )</p>
                    </th>
                    <th scope="col" style="vertical-align: center;" class="text-center">
                      <b-button :disabled="tambahTransaksi.length >= 10 ? true: false" :variant="tambahTransaksi.length >= 10 ? 'secondary': 'primary'" @click="addRow">
                        <p class="h5 mb-0">+</p>
                      </b-button>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(k, index) in tambahTransaksi" v-bind:key="k.index">
                    <td class="col-1 py-3">
                      <div>
                        <p class="text-center font-weight-bold mb-0">{{ index + 1 }}</p>
                      </div>
                    </td>
                    <td class="col-4">
                      <div>
                        <div>
                          <flat-pickr :config="config" @on-change="tglDetail($event, index)" v-model="preview.tanggal[index].date" value="" placeholder="Pilih Tanggal" class="w-100 custom-flatpicker calender-small">
                          </flat-pickr>
                        </div>
                      </div>
                    </td>
                    <td>
                      <div>
                        <b-form-input class="new-cstm-cntrl-inventori" id="jumlah" v-model="tambahTransaksi[index].jumlah"
                         type="text" placeholder="Diisi Dengan Persentase Capaian" @keypress="isNumberKey">
                        </b-form-input>
                      </div>
                    </td>
                    <td class="col-1 text-center">
                      <b-button variant="white" @click="deleteRow(index)" v-if="index !== 0">
                        <p class="mb-0"><i class="fas fa-trash-alt text-danger"></i></p>
                      </b-button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </b-col>
        </b-row>
      </div>
      <!-- <pre>{{tambahTransaksi}}</pre> -->
      <!-- <pre>{{preview}}</pre> -->
    </b-modal>
     <ModalMsgbox ref="MsgBox" />
  </div>
</template>
<script>
import { xray } from '../../../../config/pluginInit'
import ModalMsgbox from '../../../../components/modal/ModalMsgBox'
import BIOS from '../../../../services/fatmahost/bios'
import flatPickr from 'vue-flatpickr-component'
import 'flatpickr/dist/flatpickr.css'
import axios from 'axios'

export default {
  components: {
    ModalMsgbox,
    flatPickr
  },
  data () {
    return {
      hidden: false,
      config: {
        dateFormat: 'd-m-Y',
        disableMobile: true,
        maxDate: 'today'
      },
      // new saldo bios rekening operasional
      tanggal: '',
      currentDate: '',
      tables: {
        fieldsRO: [
          { label: 'No', key: 'rn', class: 'text-center' },
          { label: 'Kode Satker', key: 'kdsatker' },
          { label: 'Nama Satker', key: 'nmsatker' },
          { label: 'Tgl. Transaksi', key: 'tgl_transaksi' },
          { label: 'Persentase Capaian', key: 'jumlah' },
          { label: 'Update At', key: 'updated_at' }
        ],
        itemsRO: this.getRiwayatIkt,
        currentPage: 1,
        perPage: null,
        isBusy: false,
        totalRows: null
      },
      options: {
        akun: [],
        selected: []
      },
      tambahTransaksi: [{
        object: 'ikt-url',
        jenis: 'JVP1',
        method: 'kirim-data',
        tgl_transaksi: '',
        jumlah: ''
      }],
      preview: {
        tanggal: [
          {
            date: ''
          }
        ]
      }
    }
  },
  mounted () {
    xray.index()
  },
  methods: {
    isNumberKey (evt) {
      evt = (evt) || window.event
      var charCode = (evt.which) ? evt.which : evt.keyCode
      if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
        evt.preventDefault()
      } else {
        return true
      }
    },
    async getRiwayatIkt (ctx) {
      this.tables.isBusy = true
      const data = {
        object: 'ikt-url',
        jenis: 'JVP1',
        method: 'tarik-data',
        page: ctx.currentPage.toString() - 1
      }
      try {
        const response = await BIOS.riwayatIkt(data)
        this.tables.isBusy = false
        this.tables.ctx = response.data.data.offset
        this.tables.totalRows = response.data.data.total
        return response.data.data.datas
      } catch (error) {
        this.tables.isBusy = false
        return []
      }
    },
    onFiltered (filteredItems) {
      this.tables.totalRows = filteredItems.length
      this.tables.currentPage = 1
    },
    filterchange () {
      this.tables.filter = ''
    },
    tableRefresh () {
      this.$refs.tables.refresh()
    },
    tabjumlah ($event, index) {
      // this.tambahTransaksi[index].jumlah = $event
      let numberString = $event.replace(/[^,\d]/g, '').toString()
      let split = numberString.split(',')
      let sisa = split[0].length % 3
      let rupiah = split[0].substr(0, sisa)
      let ribuan = split[0].substr(sisa).match(/\d{3}/gi)
      if (ribuan) {
        let separator = sisa ? '.' : ''
        rupiah += separator + ribuan.join('.')
      }
      rupiah = split[1] !== undefined ? rupiah + ',' + split[1] : rupiah
      this.preview.jumlah[index].value = rupiah
      this.tambahTransaksi[index].jumlah = rupiah.replace(/\./g, '')
    },
    clearData () {
      // // form
      this.tanggal = ''
      this.currentDate = ''
      this.tambahTransaksi = []
      this.preview.tanggal = []
      this.addRow()
    },
    tglEvent ($event, param) {
      const date = new Date($event)
      let day = date.getDate()
      let month = (date.getMonth() + 1).toString().padStart(2, 0)
      let year = date.getFullYear()
      if (day < 10) {
        day = '0' + day
      }
      let currentDate = year + '-' + month + '-' + day
      this.currentDate = currentDate
    },
    tglDetail ($event, index) {
      const date = new Date($event)
      let day = date.getDate()
      let month = (date.getMonth() + 1).toString().padStart(2, 0)
      let year = date.getFullYear()
      if (day < 10) {
        day = '0' + day
      }
      let currentDate = year + '-' + month + '-' + day
      this.tambahTransaksi[index].tgl_transaksi = currentDate
    },
    showModal () {
      this.clearData()
      this.$refs['modal-jumlah-visite-pasien'].show()
    },
    async tambahSave (bvModalEvent) {
      bvModalEvent.preventDefault()
      this.sendData()
      // this.tableRefresh()
      // if (this.currentDate) {
      //   if (this.currentDate === 'NaN-NaN-NaN') {
      //     this.$refs.MsgBox.show({
      //       type: 'error',
      //       msg: 'Tanggal Kosong'
      //     })
      //   } else {
      //     this.tambahTransaksi.forEach((item, index) => {
      //       item.tgl_transaksi = this.currentDate
      //     })
      //     this.sendData()
      //     this.tableRefresh()
      //   }
      // } else {
      //   this.$refs.MsgBox.show({
      //     type: 'error',
      //     msg: 'Tanggal Kosong'
      //   })
      // }
      // this.tableRefresh()
    },
    async sendData () {
      const axiosInstances = this.tambahTransaksi.map(data => {
        return BIOS.riwayatIkt(data)
      })
      axios.all(axiosInstances)
        .then(axios.spread((...responses) => {
          let allRequestsSuccessful = true
          const aggregatedResponse = {
            success: [],
            errors: []
          }

          responses.forEach((response, index) => {
            if (response.status === 200) {
              aggregatedResponse.success.push({
                index: index + 1,
                data: response.data
              })
            } else {
              allRequestsSuccessful = false
              aggregatedResponse.errors.push({
                index: index + 1,
                error: response.statusText
              })
            }
          })
          if (allRequestsSuccessful) {
            this.$refs.MsgBox.show({
              type: 'success',
              msg: 'Semua permintaan berhasil dikirim.'
            })
            this.tableRefresh()
            this.$refs['modal-jumlah-visite-pasien'].hide()
          } else {
            this.$refs.MsgBox.show({
              type: 'success',
              msg: 'Beberapa permintaan gagal.'
            })
          }
        }))
        .catch(error => {
          this.$refs.MsgBox.show({
            type: 'error',
            msg: 'Terjadi kesalahan:' + error
          })
        })
    },
    deleteModal (id) {
      this.$refs['modal-konfirm-delete'].show()
    },
    addRow () {
      this.tambahTransaksi.push(
        {
          object: 'ikt-url',
          jenis: 'JVP1',
          method: 'kirim-data',
          tgl_transaksi: '',
          jumlah: ''
        }
      )
      this.preview.tanggal.push(
        {
          date: ''
        }
      )
    },
    deleteRow (index) {
      this.tambahTransaksi.splice(index, 1)
      this.preview.tanggal.splice(index, 1)
    }
  }
}
</script>
<style>
#modal-jumlah-visite-pasien .height-static-pabrik {
  /* max-height: 12em;
 overflow-x: hidden; */
  overflow: auto;
}

#modal-jumlah-visite-pasien .multiselect, #modal-edit-bios-jumlah-visite .multiselect {
  min-height: 33px;
}

#modal-jumlah-visite-pasien .multiselect__single, #modal-edit-bios-jumlah-visite .multiselect__single {
  font-size: 0.9em!important;
  font-weight: normal!important;
  line-height: 26px;
}
#modal-jumlah-visite-pasien .multiselect__placeholder, #modal-edit-bios-jumlah-visite .multiselect__placeholder {
  font-size: 0.9em!important;
  font-weight: normal;
}
#modal-jumlah-visite-pasien .multiselect__option, #modal-edit-bios-jumlah-visite .multiselect__option {
  font-size: 0.9em;
}
#modal-jumlah-visite-pasien .multiselect__tags, #modal-edit-bios-jumlah-visite .multiselect__tags {
  overflow: hidden;
  padding: 1px 40px 0 8px!important;
  height: 33px!important;
  min-height: 33px!important;
}

#modal-jumlah-visite-pasien .multiselect--disabled .multiselect__tags {
  overflow: hidden;
  background: #E9ECEF;
}

#modal-jumlah-visite-pasien .multiselect--disabled {
  opacity: 1;
}

#modal-jumlah-visite-pasien .multiselect--disabled .multiselect__tags .multiselect__single {
  overflow: hidden;
  background: transparent;
}

#modal-jumlah-visite-pasien .multiselect__select {
    height: 30px;
}

.new-cstm-cntrl-inventori {
  background: white!important;
  color: black!important;
  border-radius: 5px!important;
  font-size: 12px!important;
  height: 33px!important;
}

#modal-jumlah-visite-pasien .border-merah {
  border: 2px solid red;
}

#modal-jumlah-visite-pasien .border-hijau {
  border: 2px solid green;
}

#modal-jumlah-visite-pasien .modal-body {
  background: #3399331b;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

#modal-jumlah-visite-pasien .modal-header {
  background: #3399333a;
}

#modal-jumlah-visite-pasien .modal-footer {
  background: #3399333a;
}

#modal-edit-bios-jumlah-visite .border-merah {
  border: 2px solid red;
}

#modal-edit-bios-jumlah-visite .border-hijau {
  border: 2px solid green;
}

#modal-edit-bios-jumlah-visite .modal-body {
  background: #3399331b;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

#modal-edit-bios-jumlah-visite .modal-header {
  background: #3399333a;
}

#modal-edit-bios-jumlah-visite .modal-footer {
  background: #3399333a;
}

#bios-jumlah-visite .custom-button-radius {
  border: 1px solid rgba(0, 0, 0, 0.112);
  border-radius: 5px !important;
}

#bios-jumlah-visite .table td {
    font-size: 12px;
    font-weight: 500;
    padding-top: 0px;
    padding-bottom: 0px;
}
#bios-jumlah-visite .table th {
    font-size: 13px;
    font-weight: 400;
}

#bios-jumlah-visite .table {
  text-align: left;
}

#bios-jumlah-visite .cstm-select-Inventory {
  font-size: 13px;
  height: 38px !important;
  border-radius: 5px;
}

#bios-jumlah-visite .cstm-srch-jumlah-visite {
  font-size: 13px;
  height: 38px !important;
  border-radius: 5px;
}

#bios-jumlah-visite .cstm-slct-jumlah-visite {
  font-size: 13px;
  height: 38px !important;
  border-radius: 5px;
}

#bios-jumlah-visite .pagination-bios-jumlah-visite .page-link {
  border: none;
  color: gray !important;
  margin-right: 15px;
}

#bios-jumlah-visite .pagination-bios-jumlah-visite .page-link {
  border: none;
  color: gray !important;
  margin-right: 15px;
}

#bios-jumlah-visite .page-item.active .page-link {
    background-color: transparent;
    border-color: transparent;
}

#bios-jumlah-visite .pagination-bios-jumlah-visite .page-item.active .page-link {
  background-color: transparent;
  border-color: transparent;
  color: #089bab !important;
  font-weight: bold;
}

#modal-jumlah-visite-pasien .custom-flatpicker, #modal-edit-bios-jumlah-visite .custom-flatpicker {
    border: 1px solid rgb(233, 233, 233);
    height: 33px;
    padding-left: 10px;
    padding-top: 5px;
    padding-bottom: 5px;
    border: 1px solid rgb(209, 208, 208)!important;
    border-radius: 5px;
    border: 1px solid rgb(228, 228, 228);
    font-size: 12px;
    color: black;
}
</style>
