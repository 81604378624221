<template>
  <b-container fluid class="eklaim-monitoring">
    <b-row>
      <b-col md="12">
        <iq-card class="p-4">
          <b-row class="mb-1">
            <b-col cols="12" lg="4">
              <div>
                <b-form-group label="Jenis Klaim">
                  <multiselect v-model="selectedKlaim" :show-labels="false" :limit="3" @input="valJenisKlaim"
                  label="name" placeholder="Jenis Klaim" :options="comboJenisKlaim" :searchable="true">
                </multiselect>
                </b-form-group>
              </div>
            </b-col>
            <b-col cols="12" lg="4">
              <div>
                <b-form-group label="Tanggal Awal">
                  <template>
                    <flat-pickr v-model="formData.tanggal_awal" :config="config" placeholder="Pilih Tanggal" class="w-100 custom-flatpicker calender-small">
                    </flat-pickr>
                  </template>
                </b-form-group>
              </div>
            </b-col>
            <b-col cols="12" lg="4">
              <div>
                <b-form-group label="Tanggal Akhir">
                  <template>
                    <flat-pickr v-model="formData.tanggal_akhir" :config="config" placeholder="Pilih Tanggal" class="w-100 custom-flatpicker calender-small">
                    </flat-pickr>
                  </template>
                </b-form-group>
              </div>
            </b-col>
          </b-row>
          <b-row class="mb-3 justify-content-end">
            <b-col cols="4">
              <div class="text-right">
                <b-button variant="info" @click="filterup" class="mr-3"><i class="fas fa-filter"></i> Filter</b-button>
                <b-button variant="warning" @click="filterclear"><i class="fas fa-sync"></i> Bersihkan Filter</b-button>
              </div>
            </b-col>
          </b-row>
        </iq-card>
        <iq-card class="py-4 px-4">
          <template>
            <div class="pb-4">
              <b-row class="justify-content-between">
                <b-col md="4" class="text-center">
                  <form class="searchbox">
                    <input type="search" v-model="filter" class="form-control" id="filter-input"
                      placeholder="Cari No. Pendaftaran">
                  </form>
                </b-col>
                <b-col md="3" class="text-right d-flex align-items-center justify-content-end">
                  <div :class="hideExcl">
                    <xlsx-workbook>
                      <xlsx-sheet :collection="sheet.data" v-for="sheet in sheets" :key="sheet.name" :sheet-name="sheet.name" />
                      <xlsx-download>
                        <b-button variant="success" class="mr-3"><i class="fas fa-file-excel"></i> Export</b-button>
                      </xlsx-download>
                    </xlsx-workbook>
                  </div>
                </b-col>
              </b-row>
            </div>
          </template>
          <template>
            <b-row>
              <b-col md="12">
                <b-table ref="table" outlined hover :busy="isBusy" :items="listKlaim" :fields="fields" :current-page="currentPage"
                  :per-page="perPage" stacked="md" show-empty @filtered="onFiltered">

                  <template v-slot:cell(action)="data">
                    <b-button variant=" iq-bg-success mr-1 mb-1" size="lg" @click="viewEklaimMonitoring(data.item.NOPEN)"><i class="ri-eye-line m-0"></i></b-button>
                  </template>

                </b-table>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
              </b-col>
              <b-col>
              </b-col>
              <b-col sm="12" md="3" class=" my-1">
                <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage" align="fill" size="sm"
                  class="my-0"></b-pagination>
              </b-col>
            </b-row>
          </template>
        </iq-card>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { xray } from '../../config/pluginInit'
import Eklaim from '../../services/eklaim'
import Multiselect from 'vue-multiselect'
import 'sweetalert2/src/sweetalert2.scss'
import flatPickr from 'vue-flatpickr-component'
import 'flatpickr/dist/flatpickr.css'
import XlsxWorkbook from 'vue-xlsx/dist/components/XlsxWorkbook'
import XlsxSheet from 'vue-xlsx/dist/components/XlsxSheet'
import XlsxDownload from 'vue-xlsx/dist/components/XlsxDownload'

export default {
  name: 'UiDataTable',
  components: {
    Multiselect,
    flatPickr,
    XlsxWorkbook,
    XlsxSheet,
    XlsxDownload
  },
  mounted () {
    xray.index()
    // this.listKlaim()
  },
  data () {
    return {
      isBusy: false,
      formData: {
        jenis_klaim: '',
        tanggal_awal: '',
        tanggal_akhir: ''
      },
      config: {
        dateFormat: 'd-m-Y'
      },
      selectedKlaim: [],
      comboJenisKlaim: [
        { name: 'Rawat Jalan', id: 1 },
        { name: 'Rawat Inap', id: 2 },
        { name: 'IGD', id: 3 }
      ],
      error: [],
      fields: [
        { label: 'No. Pendaftaran', key: 'NOPEN', class: 'text-center' },
        { label: 'Nama Pasien', key: 'NAMA', class: 'text-left' },
        { label: 'No. SEP', key: 'NOSEP', class: 'text-left' },
        { label: 'No. RM', key: 'NORM', class: 'text-left' },
        { label: 'Jenis Pasien', key: 'JENIS_PASIEN', class: 'text-left' },
        { label: 'Ruangan', key: 'DESKRIPSI', class: 'text-left' },
        { label: 'Status', key: 'DESKRIPSI_STATUS', class: 'text-left' },
        { label: 'Action', key: 'action', class: 'text-center' }
      ],
      items: [],
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      filterOn: [],
      sheets: [
        {
          name: 'SheetOne',
          data: []
        }
      ],
      hideExcl: 'd-none'
    }
  },
  methods: {
    default () {
      return {
        errors: [],
        id: this.items.length
      }
    },
    async listKlaim (ctx) {
      let data = ''
      if (ctx.filter === '') {
        ctx.filter = null
      }
      const jenisklaim = this.formData.jenis_klaim
      const tanggalawal = this.formData.tanggal_awal === '' || this.formData.tanggal_awal === null ? '' : this.formData.tanggal_awal.split('-').reverse().join('-')
      const tanggalakhir = this.formData.tanggal_akhir === '' || this.formData.tanggal_akhir === null ? '' : this.formData.tanggal_akhir.split('-').reverse().join('-')
      data = `&jenis_klaim=${jenisklaim}&tanggal_awal=${tanggalawal}&tanggal_akhir=${tanggalakhir}`
      this.isBusy = true
      if (jenisklaim !== '' && tanggalawal !== '' && tanggalakhir !== '') {
        this.hideExcl = 'd-block'
        const exportdata = await Eklaim.exportlist(data)
        if (exportdata.data.data.length === 0) {
          this.hideExcl = 'd-none'
          this.sheets[0].data = null
        } else {
          this.hideExcl = 'd-block'
          this.sheets[0].data = exportdata.data.data
        }
      } else {
        this.hideExcl = 'd-none'
        this.sheets[0].data = null
      }
      try {
        const response = await Eklaim.all(ctx.currentPage, data, ctx.filter)
        this.totalRows = response.data.data.total
        this.isBusy = false
        return response.data.data.data
      } catch (err) {
        this.isBusy = false
        return []
      }
    },
    valJenisKlaim (value) {
      this.formData.jenis_klaim = value.id
    },
    viewEklaimMonitoring (id) {
      this.$router.push('vview/' + id)
    },
    onFiltered (filteredItems) {
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
    filterup () {
      this.listKlaim()
      this.$refs.table.refresh()
      this.hideExcl = 'd-block'
    },
    filterclear () {
      this.selectedKlaim = []
      this.formData.jenis_klaim = ''
      this.formData.tanggal_awal = ''
      this.formData.tanggal_akhir = ''
      this.listKlaim()
      this.$refs.table.refresh()
      this.hideExcl = 'd-none'
    }
  }
}
</script>
<style>
.eklaim-monitoring .multiselect__tags {
  border: 1px solid #d7dbda!important;
  border-radius: 10px!important;
  background: #ceebee;
  height: 45px;
}
.eklaim-monitoring .custom-flatpicker {
  min-height: 45px;
  height: 45px;
  border: 1px solid #d7dbda;
  padding: 10px;
  border-radius: 10px;
  cursor: pointer;
  background: #ceebee;
  font-weight: bold;
}
.eklaim-monitoring .multiselect__single {
  background: #ceebee;
}
.flatpickr-day.selected {
  background: #089bab!important;
}
.flatpickr-day.today {
    background: #089bab!important;
    color: white;
}
</style>
