<template>
  <div id="sisrute" class="mb-4">
    <div v-show="isLoading">
        <div id="show-overlay"></div>
      <Loader :style="{ opacity: .5 }" />
    </div>
    <b-container fluid>
      <div>
        <div class="bg-white border px-3 py-2">
          <h5 class="card-title text-dark font-weight-bold m-0 p-0">SISTEM INFORMASI RUJUKAN TERINTEGRASI</h5>
          <!-- <pre>{{ filter }}</pre> -->
        </div>
        <div class="bg-white border px-2 py-2">
          <b-row class="align-items-center">
            <b-col cols="12" lg="2" class="pr-1">
              <b-form-input class="form-control-custom" v-model="filter.nomor" @input="refreshTable" placeholder="Nomor" size="sm"></b-form-input>
            </b-col>
            <!-- <b-col cols="12" lg="2" class="px-1">
              <flat-pickr :config="config" v-model="date.tanggalList" value="" @on-change="convertDate($event, 'tanggalList')" placeholder="[ Tanggal ]" class="w-100 calender-small custom-flat-pickr m-0">
              </flat-pickr>
            </b-col> -->
            <!-- <b-col cols="12" lg="2" class="px-1">
              <multiselect :options="options.status" v-model="selected.status" @input="valueMultiselect($event, 'status')" :allow-empty="false" label="NAMA" :show-labels="false" placeholder="[ Status ]">
              </multiselect>
            </b-col> -->
            <b-col cols="12" lg="2" class="px-1">
              <multiselect :options="options.create" v-model="selected.create" @input="valueMultiselect($event, 'create')" :allow-empty="false" label="NAMA" :show-labels="false" placeholder="[ Rujukan ]">
              </multiselect>
            </b-col>
            <b-col cols="12" lg="2" class="px-1">
              <b-button variant="primary" class="rounded-1" size="sm" @click="resetFilter">
                <i class="fas fa-sync ml-1"></i>
              </b-button>
            </b-col>
            <b-col cols="12" lg="2" class="ml-auto text-right pl-1">
              <b-button :disabled="filter.create !== '?create=true' ? false : true" :variant="filter.create !== '?create=true' ? 'primary' : 'secondary'" class="rounded-1" size="sm" @click="openModal('create', '', '', '', '', '', '')">
                <i class="fas fa-plus"></i> Rujuk Keluar
              </b-button>
            </b-col>
          </b-row>
        </div>
        <!-- <pre>{{ filter }}</pre> -->
        <div class="table bg-white mb-0">
          <b-table class="mb-0" ref="table" :busy="table.isBusy" :items="table.items" :fields="table.fields" :tbody-tr-class="rowClass" :select-mode="table.selectMode" stacked="md"
              bordered hover small show-empty selectable>
              <template #table-busy>
                <div class="text-center my-2">
                  <b-spinner class="align-middle text-primary mr-2"></b-spinner>
                  <strong class="text-dark">Memuat Data...</strong>
                </div>
              </template>
              <template #cell(index)="data">
                <span>{{ (table.currentPage*table.perPage)-table.perPage + data.index + 1 }}</span>
              </template>
              <template #cell(rujukan_nomor)="data">
                <span>{{ data.item.RUJUKAN.NOMOR }}</span>
              </template>
              <template #cell(norm)="data">
                <span>{{ data.item.PASIEN.NORM.padStart(8, '0') }}</span>
              </template>
              <template #cell(nama)="data">
                <span>{{ data.item.PASIEN.NAMA }}</span>
              </template>
              <template #cell(tanggal)="data">
                <span>{{ data.item.RUJUKAN.TANGGAL }}</span>
              </template>
              <template #cell(rujukan_tujuan)="data">
                <span>{{ data.item.RUJUKAN.FASKES_ASAL.NAMA }} <i class="fas fa-long-arrow-alt-right text-primary"></i> {{ data.item.RUJUKAN.FASKES_TUJUAN.NAMA }}</span>
              </template>
              <template #cell(diagnosa)="data">
                <span>{{ data.item.RUJUKAN.DIAGNOSA ? `[${data.item.RUJUKAN.DIAGNOSA.KODE}] - ${data.item.RUJUKAN.DIAGNOSA.NAMA}` : `` }}</span>
                <!-- <span>[ {{ data.item.RUJUKAN.DIAGNOSA.KODE }} ] - {{ data.item.RUJUKAN.DIAGNOSA.NAMA }}</span> -->
                <!-- formPreview.RUJUKAN.DIAGNOSA ? `${formPreview.RUJUKAN.DIAGNOSA.KODE} - ${formPreview.RUJUKAN.DIAGNOSA.NAMA}` : '' -->
              </template>
              <template #cell(status)="data">
                <span>{{ data.item.RUJUKAN.STATUS.NAMA }}</span>
              </template>
              <template v-slot:cell(aksi)="data">
                <b-button variant="white" size="lg" @click="openModal('lihat', data.item.RUJUKAN.NOMOR, '', '', '', '', '')" v-if="filter.create === ''">
                  <i class="ri-eye-fill ri-lg text-primary"></i>
                </b-button>
                <b-button variant="white" size="lg" @click="openModal('jawab', data.item.RUJUKAN.NOMOR, data.item.PASIEN.NORM, data.item.PASIEN.NAMA, data.item.RUJUKAN.FASKES_ASAL.NAMA, data.item.RUJUKAN.FASKES_TUJUAN.NAMA, data.item.RUJUKAN.DIAGNOSA !== null ? data.item.RUJUKAN.DIAGNOSA.KODE + ' - ' + data.item.RUJUKAN.DIAGNOSA.NAMA : '-')" v-if="filter.create === ''">
                  <i class="ri-git-repository-commits-fill ri-lg text-primary"></i>
                </b-button>
                <b-button variant="white" size="lg" @click="openModal('batal', data.item.RUJUKAN.NOMOR, data.item.PASIEN.NORM, data.item.PASIEN.NAMA, data.item.RUJUKAN.FASKES_ASAL.NAMA, data.item.RUJUKAN.FASKES_TUJUAN.NAMA, data.item.RUJUKAN.DIAGNOSA !== null ? data.item.RUJUKAN.DIAGNOSA.KODE + ' - ' + data.item.RUJUKAN.DIAGNOSA.NAMA : '-')" v-if="filter.create === '?create=true'">
                  <i class="ri-close-circle-fill ri-lg text-danger"></i>
                </b-button>
              </template>
            </b-table>
        </div>
        <div class="bg-white px-3 py-2 border">
          <p class="m-0 p-0">Gunakan Pencarian</p>
        </div>
        <!-- <div class="table-pagination bg-white px-3 py-2 d-flex flex-row align-items-center">
          <div>
            <b-pagination v-model="table.currentPage" :total-rows="table.totalRows" :per-page="table.perPage" size="sm" class="my-0"></b-pagination>
          </div>
          <div @click="refreshTable" class="my-cursor mr-auto">
            <i class="fas fa-sync"></i>
          </div>
          <div :class="['pl-2 pr-2 shadow-sm', messageClass]" v-if="messagetype === '1'">
            <div class="d-flex flex-row justify-content-between">
              <div class="mr-2">
                <p class="text-right m-0 p-0">{{ message }}</p>
              </div>
              <div @click="clearMessage">
                <p class="text-right m-0 p-0"><i class="fas fa-times-circle"></i></p>
              </div>
            </div>
          </div>
          <div class="ml-2">
            <p class="m-0 p-0 text-secondary">Menampilkan {{ table.totalRows }} baris</p>
          </div>
        </div> -->
      </div>
    </b-container>
    <b-modal id="modal-create-sisrute" ref="modal-create-sisrute" header-class="text-uppercase" title="RUJUK BALIK" size="lg" centered scrollable :no-close-on-backdrop="true" :static="true" :no-close-on-esc="true" @ok="onSubmit" cancel-title="Tutup" ok-title="Simpan">
      <div>
        <div class="field-pasien shadow-sm radius-custom border mb-3">
          <div class="p-2">
            <p class="m-0 p-0 text-dark font-weight-bold text-uppercase">Pasien</p>
          </div>
          <hr class="p-0 my-1">
          <div class="p-2">
            <b-row class="mb-1">
              <b-col cols="3">
                <div>
                  <p class="m-0 p-0 text-dark custom-fonts">NORM</p>
                </div>
              </b-col>
              <b-col>
                <b-form-group class="m-0 p-0">
                  <b-form-input class="form-control-custom" v-model="form.PASIEN.NORM" placeholder="No. Rekam Medis..." size="sm"></b-form-input>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row class="mb-1">
              <b-col cols="3">
                <div>
                  <p class="m-0 p-0 text-dark custom-fonts">NIK</p>
                </div>
              </b-col>
              <b-col>
                <b-form-group class="m-0 p-0">
                  <b-form-input class="form-control-custom" v-model="form.PASIEN.NIK" placeholder="No. Induk Kependudukan..." size="sm"></b-form-input>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row class="mb-1">
              <b-col cols="3">
                <div>
                  <p class="m-0 p-0 text-dark custom-fonts">NO. JKN</p>
                </div>
              </b-col>
              <b-col>
                <b-form-group class="m-0 p-0">
                  <b-form-input class="form-control-custom" v-model="form.PASIEN.NO_KARTU_JKN" placeholder="No. Kartu JKN..." size="sm"></b-form-input>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row class="mb-1">
              <b-col cols="3">
                <div>
                  <p class="m-0 p-0 text-dark custom-fonts">NAMA</p>
                </div>
              </b-col>
              <b-col>
                <b-form-group class="m-0 p-0">
                  <b-form-input class="form-control-custom" v-model="form.PASIEN.NAMA" placeholder="Nama..." size="sm"></b-form-input>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row class="mb-1">
              <b-col cols="3">
                <div>
                  <p class="m-0 p-0 text-dark custom-fonts">JENIS KELAMIN</p>
                </div>
              </b-col>
              <b-col>
                <b-form-group class="m-0 p-0">
                  <multiselect :options="options.jeniskelamin" v-model="selected.jeniskelamin" @input="valueMultiselect($event, 'jeniskelamin')" :allow-empty="false" label="NAMA" :show-labels="false" placeholder="[ Jenis Kelamin ]" :searchable="true">
                  </multiselect>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row class="mb-1">
              <b-col cols="3">
                <div>
                  <p class="m-0 p-0 text-dark custom-fonts">TANGGAL LAHIR</p>
                </div>
              </b-col>
              <b-col>
                <b-form-group class="m-0 p-0">
                  <flat-pickr :config="config" v-model="date.tanggallahir" value="" @on-change="convertDate($event, 'tanggallahir')" placeholder="[ Tanggal Lahir ]" class="w-100 calender-small custom-flat-pickr m-0">
                  </flat-pickr>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row class="mb-1">
              <b-col cols="3">
                <div>
                  <p class="m-0 p-0 text-dark custom-fonts">TEMPAT LAHIR</p>
                </div>
              </b-col>
              <b-col>
                <b-form-group class="m-0 p-0">
                  <b-form-input class="form-control-custom" v-model="form.PASIEN.TEMPAT_LAHIR" placeholder="Tempat Lahir..." size="sm"></b-form-input>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row class="mb-1">
              <b-col cols="3">
                <div>
                  <p class="m-0 p-0 text-dark custom-fonts">ALAMAT</p>
                </div>
              </b-col>
              <b-col>
                <b-form-group class="m-0 p-0">
                  <b-form-input class="form-control-custom" v-model="form.PASIEN.ALAMAT" placeholder="Alamat..." size="sm"></b-form-input>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row class="mb-0">
              <b-col cols="3">
                <div>
                  <p class="m-0 p-0 text-dark custom-fonts">KONTAK</p>
                </div>
              </b-col>
              <b-col>
                <b-form-group class="m-0 p-0">
                  <b-form-input class="form-control-custom" v-model="form.PASIEN.KONTAK" placeholder="Kontak..." size="sm"></b-form-input>
                </b-form-group>
              </b-col>
            </b-row>
          </div>
        </div>
        <div class="field-pasien shadow-sm radius-custom border mb-3">
          <div class="p-2">
            <p class="m-0 p-0 text-dark font-weight-bold text-uppercase">Rujukan</p>
          </div>
          <hr class="p-0 my-1">
          <div class="p-2">
            <b-row class="mb-1">
              <b-col cols="3">
                <div>
                  <p class="m-0 p-0 text-dark custom-fonts">JENIS RUJUKAN</p>
                </div>
              </b-col>
              <b-col>
                <b-form-group class="m-0 p-0">
                  <multiselect :options="options.jenisrujukan" v-model="selected.jenisrujukan" @input="valueMultiselect($event, 'jenisrujukan')" :custom-label="nameWithKode" :allow-empty="false" label="NAMA" :show-labels="false" placeholder="[ Jenis Rujukan ]" :searchable="true">
                  </multiselect>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row class="mb-1">
              <b-col cols="3">
                <div>
                  <p class="m-0 p-0 text-dark custom-fonts">PELAYANAN</p>
                </div>
              </b-col>
              <b-col>
                <b-form-group class="m-0 p-0">
                  <!-- <b-form-input class="form-control-custom" v-model="form.RUJUKAN.PELAYANAN" placeholder="Pelayanan..." size="sm"></b-form-input> -->
                  <b-form-textarea
                    class="text-dark rounded-0"
                    v-model="form.RUJUKAN.PELAYANAN"
                    placeholder="Pelayanan"
                    rows="3"
                    max-rows="7"
                  ></b-form-textarea>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row class="mb-1">
              <b-col cols="3">
                <div>
                  <p class="m-0 p-0 text-dark custom-fonts">NOMOR RUJUKAN BPJS</p>
                </div>
              </b-col>
              <b-col>
                <b-form-group class="m-0 p-0">
                  <b-form-input class="form-control-custom" v-model="form.RUJUKAN.NOMOR_RUJUKAN_BPJS" placeholder="No. Rujukan BPJS..." size="sm"></b-form-input>
                </b-form-group>
              </b-col>
            </b-row>
            <div class="mb-1" v-if="form.RUJUKAN.JENIS_RUJUKAN === '1'">
              <hr>
              <b-row class="mb-1">
                <b-col cols="3">
                  <div>
                    <p class="m-0 p-0 text-dark custom-fonts">KRITERIA RUJUKAN</p>
                  </div>
                </b-col>
                <b-col>
                  <b-form-group class="m-0 p-0">
                    <multiselect :options="options.kriteriarujukan" v-model="selected.kriteriarujukan" @input="valueMultiselect($event, 'kriteriarujukan')" @search-change="getRefrenceParams($event, 'kriteriarujukan', 'nomor')" :allow-empty="false" label="DESKRIPSI" :show-labels="false" placeholder="[ Kriteria Rujukan ]" :searchable="true">
                    </multiselect>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row class="mb-1">
                <b-col cols="3">
                  <div>
                    <p class="m-0 p-0 text-dark custom-fonts">KRITERIA KHUSUS</p>
                  </div>
                </b-col>
                <b-col>
                  <b-form-group class="m-0 p-0">
                    <multiselect :options="options.kriteriakhusus" v-model="selected.kriteriakhusus" @input="valueMultiselect($event, 'kriteriakhusus')" :custom-label="descWithKode" :allow-empty="false" label="DESKRIPSI" :show-labels="false" placeholder="[ Kriteria Khusus ]" :searchable="true">
                    </multiselect>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row class="mb-1">
                <b-col cols="3">
                  <div>
                    <p class="m-0 p-0 text-dark custom-fonts">SDM</p>
                  </div>
                </b-col>
                <b-col>
                  <b-form-group class="m-0 p-0">
                    <b-form-input class="form-control-custom" v-model="form.RUJUKAN.KRITERIA.SDM" placeholder="SDM..." size="sm"></b-form-input>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row class="mb-1">
                <b-col cols="3">
                  <div>
                    <p class="m-0 p-0 text-dark custom-fonts">PELAYANAN</p>
                  </div>
                </b-col>
                <b-col>
                  <b-form-group class="m-0 p-0">
                    <b-form-input class="form-control-custom" v-model="form.RUJUKAN.KRITERIA.PELAYANAN" placeholder="Pelayanan..." size="sm"></b-form-input>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row class="mb-1">
                <b-col cols="3">
                  <div>
                    <p class="m-0 p-0 text-dark custom-fonts">KELAS PERAWATAN</p>
                  </div>
                </b-col>
                <b-col>
                  <b-form-group class="m-0 p-0">
                    <b-form-input class="form-control-custom" v-model="form.RUJUKAN.KRITERIA.KELAS_PERAWATAN" placeholder="Kelas Perawatan..." size="sm"></b-form-input>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row class="mb-1">
                <b-col cols="3">
                  <div>
                    <p class="m-0 p-0 text-dark custom-fonts">JENIS PERAWATAN</p>
                  </div>
                </b-col>
                <b-col>
                  <b-form-group class="m-0 p-0">
                    <b-form-input class="form-control-custom" v-model="form.RUJUKAN.KRITERIA.JENIS_PERAWATAN" placeholder="Jenis Perawatan..." size="sm"></b-form-input>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row class="mb-1">
                <b-col cols="3">
                  <div>
                    <p class="m-0 p-0 text-dark custom-fonts">ALAT</p>
                  </div>
                </b-col>
                <b-col>
                  <b-form-group class="m-0 p-0">
                    <b-form-input class="form-control-custom" v-model="form.RUJUKAN.KRITERIA.ALAT" placeholder="Alat..." size="sm"></b-form-input>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row class="mb-1">
                <b-col cols="3">
                  <div>
                    <p class="m-0 p-0 text-dark custom-fonts">SARANA</p>
                  </div>
                </b-col>
                <b-col>
                  <b-form-group class="m-0 p-0">
                    <b-form-input class="form-control-custom" v-model="form.RUJUKAN.KRITERIA.SARANA" placeholder="Sarana..." size="sm"></b-form-input>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row class="mb-0">
                <b-col cols="3">
                  <div>
                    <p class="m-0 p-0 text-dark custom-fonts">KRITERIA MATNEO</p>
                  </div>
                </b-col>
                <b-col>
                  <b-form-group class="m-0 p-0">
                    <b-form-input class="form-control-custom" v-model="form.RUJUKAN.KRITERIA.KRITERIA_MATNEO" placeholder="Kriteria Matneo..." size="sm"></b-form-input>
                  </b-form-group>
                </b-col>
              </b-row>
              <hr>
            </div>
            <b-row class="mb-1">
              <b-col cols="3">
                <div>
                  <p class="m-0 p-0 text-dark custom-fonts">TANGGAL</p>
                </div>
              </b-col>
              <b-col>
                <b-form-group class="m-0 p-0">
                  <flat-pickr :config="config" v-model="date.tanggal" value="" @on-change="convertDate($event, 'tanggal')" placeholder="[ Tanggal ]" class="w-100 calender-small custom-flat-pickr m-0">
                  </flat-pickr>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row class="mb-1">
              <b-col cols="3">
                <div>
                  <p class="m-0 p-0 text-dark custom-fonts">FASKES TUJUAN</p>
                </div>
              </b-col>
              <b-col>
                <b-form-group class="m-0 p-0">
                  <multiselect :options="options.faskes" v-model="selected.faskes" @input="valueMultiselect($event, 'faskes')" :custom-label="nameWithKode" @search-change="getRefrenceParams($event, 'faskes', 'cari')" :allow-empty="false" label="NAMA" :show-labels="false" placeholder="[ Faskes Tujuan ]" :searchable="true">
                  </multiselect>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row class="mb-1">
              <b-col cols="3">
                <div>
                  <p class="m-0 p-0 text-dark custom-fonts">ALASAN</p>
                </div>
              </b-col>
              <b-col>
                <b-form-group class="m-0 p-0">
                  <multiselect :options="options.alasanrujukan" v-model="selected.alasanrujukan" @input="valueMultiselect($event, 'alasanrujukan')" :custom-label="nameWithKode" :allow-empty="false" label="NAMA" :show-labels="false" placeholder="[ Alasan ]" :searchable="true">
                  </multiselect>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row class="mb-1">
              <b-col cols="3">
                <div>
                  <p class="m-0 p-0 text-dark custom-fonts">ALASAN LAINNYA</p>
                </div>
              </b-col>
              <b-col>
                <b-form-group class="m-0 p-0">
                  <b-form-textarea
                    class="text-dark rounded-0"
                    v-model="form.RUJUKAN.ALASAN_LAINNYA"
                    placeholder="Alasan Lain"
                    rows="3"
                    max-rows="7"
                  ></b-form-textarea>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row class="mb-1">
              <b-col cols="3">
                <div>
                  <p class="m-0 p-0 text-dark custom-fonts">DIAGNOSA</p>
                </div>
              </b-col>
              <b-col>
                <b-form-group class="m-0 p-0">
                  <multiselect :options="options.diagnosa" v-model="selected.diagnosa" @input="valueMultiselect($event, 'diagnosa')" :custom-label="nameWithKode" :allow-empty="false" label="NAMA" :show-labels="false" placeholder="[ Diagnosa ]" :searchable="true">
                  </multiselect>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row class="mb-1">
              <b-col cols="3">
                <div>
                  <p class="m-0 p-0 text-dark custom-fonts">DOKTER NIK</p>
                </div>
              </b-col>
              <b-col>
                <b-form-group class="m-0 p-0">
                  <b-form-input class="form-control-custom" v-model="form.RUJUKAN.DOKTER.NIK" placeholder="Dokter NIK..." size="sm"></b-form-input>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row class="mb-1">
              <b-col cols="3">
                <div>
                  <p class="m-0 p-0 text-dark custom-fonts">DOKTER NAMA</p>
                </div>
              </b-col>
              <b-col>
                <b-form-group class="m-0 p-0">
                  <b-form-input class="form-control-custom" v-model="form.RUJUKAN.DOKTER.NAMA" placeholder="Dokter Nama..." size="sm"></b-form-input>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row class="mb-1">
              <b-col cols="3">
                <div>
                  <p class="m-0 p-0 text-dark custom-fonts">PETUGAS NIK</p>
                </div>
              </b-col>
              <b-col>
                <b-form-group class="m-0 p-0">
                  <b-form-input class="form-control-custom" v-model="form.RUJUKAN.PETUGAS.NIK" placeholder="Petugas NIK..." size="sm"></b-form-input>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row class="mb-1">
              <b-col cols="3">
                <div>
                  <p class="m-0 p-0 text-dark custom-fonts">PETUGAS NAMA</p>
                </div>
              </b-col>
              <b-col>
                <b-form-group class="m-0 p-0">
                  <b-form-input class="form-control-custom" v-model="form.RUJUKAN.PETUGAS.NAMA" placeholder="Petugas Nama..." size="sm" readonly></b-form-input>
                </b-form-group>
              </b-col>
            </b-row>
          </div>
        </div>
        <div class="field-pasien shadow-sm radius-custom border mb-3">
          <div class="p-2">
            <p class="m-0 p-0 text-dark font-weight-bold text-uppercase">KONDISI UMUM</p>
          </div>
          <hr class="p-0 my-1">
          <div class="p-2">
            <b-row class="mb-1">
              <b-col cols="3">
                <div>
                  <p class="m-0 p-0 text-dark custom-fonts">ANAMNESIS DAN PEMERIKSAAN FISIK</p>
                </div>
              </b-col>
              <b-col>
                <b-form-group class="m-0 p-0">
                  <b-form-textarea
                    class="text-dark rounded-0"
                    v-model="form.KONDISI_UMUM.ANAMNESIS_DAN_PEMERIKSAAN_FISIK"
                    placeholder="Anamnesis Dan Pemeriksaan Fisik"
                    rows="3"
                    max-rows="7"
                  ></b-form-textarea>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row class="mb-1">
              <b-col cols="3">
                <div>
                  <p class="m-0 p-0 text-dark custom-fonts">KESADARAN</p>
                </div>
              </b-col>
              <b-col>
                <b-form-group class="m-0 p-0">
                  <multiselect :options="options.kesadaran" v-model="selected.kesadaran" @input="valueMultiselect($event, 'kesadaran')" :allow-empty="false" label="NAMA" :show-labels="false" placeholder="[ Kesadaran]" :searchable="true">
                  </multiselect>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row class="mb-1">
              <b-col cols="3">
                <div>
                  <p class="m-0 p-0 text-dark custom-fonts">TEKANAN DARAH</p>
                </div>
              </b-col>
              <b-col>
                <b-form-group class="m-0 p-0">
                  <b-form-input class="form-control-custom" v-model="form.KONDISI_UMUM.TEKANAN_DARAH" placeholder="Tekanan Darah..." size="sm"></b-form-input>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row class="mb-1">
              <b-col cols="3">
                <div>
                  <p class="m-0 p-0 text-dark custom-fonts">FREKUENSI NADI</p>
                </div>
              </b-col>
              <b-col>
                <b-form-group class="m-0 p-0">
                  <b-form-input class="form-control-custom" v-model="form.KONDISI_UMUM.FREKUENSI_NADI" placeholder="Frekuensi Nadi..." size="sm"></b-form-input>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row class="mb-1">
              <b-col cols="3">
                <div>
                  <p class="m-0 p-0 text-dark custom-fonts">SUHU</p>
                </div>
              </b-col>
              <b-col>
                <b-form-group class="m-0 p-0">
                  <b-form-input class="form-control-custom" v-model="form.KONDISI_UMUM.SUHU" placeholder="Suhu..." size="sm"></b-form-input>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row class="mb-1">
              <b-col cols="3">
                <div>
                  <p class="m-0 p-0 text-dark custom-fonts">PERNAPASAN</p>
                </div>
              </b-col>
              <b-col>
                <b-form-group class="m-0 p-0">
                  <b-form-input class="form-control-custom" v-model="form.KONDISI_UMUM.PERNAPASAN" placeholder="Pernapasan..." size="sm"></b-form-input>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row class="mb-1">
              <b-col cols="3">
                <div>
                  <p class="m-0 p-0 text-dark custom-fonts">KEADAAN UMUM</p>
                </div>
              </b-col>
              <b-col>
                <b-form-group class="m-0 p-0">
                  <b-form-textarea
                    class="text-dark rounded-0"
                    v-model="form.KONDISI_UMUM.KEADAAN_UMUM"
                    placeholder="Keadaan Umum"
                    rows="3"
                    max-rows="7"
                  ></b-form-textarea>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row class="mb-1">
              <b-col cols="3">
                <div>
                  <p class="m-0 p-0 text-dark custom-fonts">NYERI</p>
                </div>
              </b-col>
              <b-col>
                <b-form-group class="m-0 p-0">
                  <multiselect :options="options.nyeri" v-model="selected.nyeri" @input="valueMultiselect($event, 'nyeri')" :allow-empty="false" label="NAMA" :show-labels="false" placeholder="[ Nyeri ]" :searchable="true">
                  </multiselect>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row class="mb-0">
              <b-col cols="3">
                <div>
                  <p class="m-0 p-0 text-dark custom-fonts">ALERGI</p>
                </div>
              </b-col>
              <b-col>
                <b-form-group class="m-0 p-0">
                  <b-form-input class="form-control-custom" v-model="form.KONDISI_UMUM.ALERGI" placeholder="Alergi..." size="sm"></b-form-input>
                </b-form-group>
              </b-col>
            </b-row>
          </div>
        </div>
        <div class="field-pasien shadow-sm radius-custom border mb-0">
          <div class="p-2">
            <p class="m-0 p-0 text-dark font-weight-bold text-uppercase">PENUNJANG</p>
          </div>
          <hr class="p-0 my-1">
          <div class="p-2">
            <b-row class="mb-1">
              <b-col cols="3">
                <div>
                  <p class="m-0 p-0 text-dark custom-fonts">LABORATORIUM</p>
                </div>
              </b-col>
              <b-col>
                <b-form-group class="m-0 p-0">
                  <b-form-textarea
                    class="text-dark rounded-0"
                    v-model="form.PENUNJANG.LABORATORIUM"
                    placeholder="Laboratorium..."
                    rows="3"
                    max-rows="7"
                  ></b-form-textarea>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row class="mb-1">
              <b-col cols="3">
                <div>
                  <p class="m-0 p-0 text-dark custom-fonts">RADIOLOGI</p>
                </div>
              </b-col>
              <b-col>
                <b-form-group class="m-0 p-0">
                  <b-form-textarea
                    class="text-dark rounded-0"
                    v-model="form.PENUNJANG.RADIOLOGI"
                    placeholder="Radiologi..."
                    rows="3"
                    max-rows="7"
                  ></b-form-textarea>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row class="mb-1">
              <b-col cols="3">
                <div>
                  <p class="m-0 p-0 text-dark custom-fonts">TERAPI</p>
                </div>
              </b-col>
              <b-col>
                <b-form-group class="m-0 p-0">
                  <b-form-textarea
                    class="text-dark rounded-0"
                    v-model="form.PENUNJANG.TERAPI"
                    placeholder="Terapi..."
                    rows="3"
                    max-rows="7"
                  ></b-form-textarea>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row class="mb-1">
              <b-col cols="3">
                <div>
                  <p class="m-0 p-0 text-dark custom-fonts">TINDAKAN</p>
                </div>
              </b-col>
              <b-col>
                <b-form-group class="m-0 p-0">
                  <b-form-textarea
                    class="text-dark rounded-0"
                    v-model="form.PENUNJANG.TINDAKAN"
                    placeholder="Tindakan..."
                    rows="3"
                    max-rows="7"
                  ></b-form-textarea>
                </b-form-group>
              </b-col>
            </b-row>
          </div>
        </div>
      </div>
      <div :class="['mt-2 px-2', messageClass]" v-if="messagetype === '2'">
        <div class="d-flex flex-row justify-content-between">
          <div>
            <p class="text-right m-0 p-0">{{ message }}</p>
          </div>
          <div @click="clearMessage">
            <p class="text-right m-0 p-0"><i class="fas fa-times-circle"></i></p>
          </div>
        </div>
      </div>
    </b-modal>
    <b-modal id="modal-jawab-sisrute" ref="modal-jawab-sisrute" header-class="text-uppercase" title="JAWAB RUJUKAN" size="md" centered scrollable :no-close-on-backdrop="true" :static="true" :no-close-on-esc="true" @ok="onPutRujukan" cancel-title="Tutup" ok-title="Simpan">
      <div class="mb-3 border shadow-sm p-2">
        <div class="d-flex flex-row justify-content-between mb-0">
          <div>
            <p class="m-0 p-0">Nama</p>
          </div>
          <div>
            <p class="m-0 p-0 text-dark text-right">{{ view.nama }}</p>
          </div>
        </div>
        <hr class="my-1">
        <div class="d-flex flex-row justify-content-between mb-0">
          <div>
            <p class="m-0 p-0">No. Rekam Medis</p>
          </div>
          <div>
            <p class="m-0 p-0 text-dark text-right">{{ view.norm.padStart(8, '0') }}</p>
          </div>
        </div>
        <hr class="my-1">
        <div class="d-flex flex-row justify-content-between mb-0">
          <div>
            <p class="m-0 p-0">Faskes Asal</p>
          </div>
          <div>
            <p class="m-0 p-0 text-dark text-right">{{ view.faskes1 }}</p>
          </div>
        </div>
        <hr class="my-1">
        <div class="d-flex flex-row justify-content-between mb-0">
          <div>
            <p class="m-0 p-0">Faskes Tujuan</p>
          </div>
          <div>
            <p class="m-0 p-0 text-dark text-right">{{ view.faskes2 }}</p>
          </div>
        </div>
        <hr class="my-1">
        <div class="d-flex flex-row justify-content-between mb-0">
          <div>
            <p class="m-0 p-0">Diagnosa</p>
          </div>
          <div>
            <p class="m-0 p-0 text-dark text-right">{{ view.diagnosa ? `${view.diagnosa}` : `` }}</p>
          </div>
        </div>
      </div>
      <div>
        <b-row class="align-items-center mb-1">
          <b-col cols="4">
            <div>
              <p class="m-0 p-0 text-dark custom-fonts">DITERIMA</p>
            </div>
          </b-col>
          <b-col>
            <b-form-group class="m-0 p-0">
              <multiselect :options="options.terima" v-model="selected.terima" @input="valueMultiselect($event, 'terima')" :allow-empty="false" label="NAMA" :show-labels="false" placeholder="[ Diterima ]" :searchable="true">
              </multiselect>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row class="align-items-center mb-1">
          <b-col cols="4">
            <div>
              <p class="m-0 p-0 text-dark custom-fonts">KETERANGAN</p>
            </div>
          </b-col>
          <b-col>
            <b-form-group class="m-0 p-0">
              <b-form-input class="form-control-custom" v-model="formJawabRujukan.KETERANGAN" placeholder="Keterangan..." size="sm"></b-form-input>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row class="align-items-center mb-1">
          <b-col cols="4">
            <div>
              <p class="m-0 p-0 text-dark custom-fonts">PETUGAS NIK</p>
            </div>
          </b-col>
          <b-col>
            <b-form-group class="m-0 p-0">
              <b-form-input class="form-control-custom" v-model="formJawabRujukan.PETUGAS.NIK" placeholder="NIK Petugas..." size="sm"></b-form-input>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row class="align-items-center mb-0">
          <b-col cols="4">
            <div>
              <p class="m-0 p-0 text-dark custom-fonts">PETUGAS NAMA</p>
            </div>
          </b-col>
          <b-col>
            <b-form-group class="m-0 p-0">
              <b-form-input class="form-control-custom" v-model="formJawabRujukan.PETUGAS.NAMA" placeholder="Nama Petugas..." size="sm" readonly></b-form-input>
            </b-form-group>
          </b-col>
        </b-row>
      </div>
      <div :class="['mt-2 px-2', messageClass]" v-if="messagetype === '2'">
        <div class="d-flex flex-row justify-content-between">
          <div>
            <p class="text-right m-0 p-0">{{ message }}</p>
          </div>
          <div @click="clearMessage">
            <p class="text-right m-0 p-0"><i class="fas fa-times-circle"></i></p>
          </div>
        </div>
      </div>
    </b-modal>
    <b-modal id="modal-batal-sisrute" ref="modal-batal-sisrute" header-class="text-uppercase" title="BATAL RUJUKAN" size="md" centered scrollable :no-close-on-backdrop="true" :static="true" :no-close-on-esc="true" @ok="onPutRujukan" cancel-title="Tutup" ok-title="Simpan">
      <div class="mb-3 border shadow-sm p-2">
        <div class="d-flex flex-row justify-content-between mb-0">
          <div>
            <p class="m-0 p-0">Nama</p>
          </div>
          <div>
            <p class="m-0 p-0 text-dark text-right">{{ view.nama }}</p>
          </div>
        </div>
        <hr class="my-1">
        <div class="d-flex flex-row justify-content-between mb-0">
          <div>
            <p class="m-0 p-0">No. Rekam Medis</p>
          </div>
          <div>
            <p class="m-0 p-0 text-dark text-right">{{ view.norm.padStart(8, '0') }}</p>
          </div>
        </div>
        <hr class="my-1">
        <div class="d-flex flex-row justify-content-between mb-0">
          <div>
            <p class="m-0 p-0">Faskes Asal</p>
          </div>
          <div>
            <p class="m-0 p-0 text-dark text-right">{{ view.faskes1 }}</p>
          </div>
        </div>
        <hr class="my-1">
        <div class="d-flex flex-row justify-content-between mb-0">
          <div>
            <p class="m-0 p-0">Faskes Tujuan</p>
          </div>
          <div>
            <p class="m-0 p-0 text-dark text-right">{{ view.faskes2 }}</p>
          </div>
        </div>
        <hr class="my-1">
        <div class="d-flex flex-row justify-content-between mb-0">
          <div>
            <p class="m-0 p-0">Diagnosa</p>
          </div>
          <div>
            <p class="m-0 p-0 text-dark text-right">{{ view.diagnosa }}</p>
          </div>
        </div>
      </div>
      <div>
        <b-row class="align-items-center mb-1">
          <b-col cols="4">
            <div>
              <p class="m-0 p-0 text-dark custom-fonts">PETUGAS NIK</p>
            </div>
          </b-col>
          <b-col>
            <b-form-group class="m-0 p-0">
              <b-form-input class="form-control-custom" v-model="formBatalRujukan.PETUGAS.NIK" placeholder="NIK Petugas..." size="sm"></b-form-input>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row class="align-items-center mb-0">
          <b-col cols="4">
            <div>
              <p class="m-0 p-0 text-dark custom-fonts">PETUGAS NAMA</p>
            </div>
          </b-col>
          <b-col>
            <b-form-group class="m-0 p-0">
              <b-form-input class="form-control-custom" v-model="formBatalRujukan.PETUGAS.NAMA" placeholder="Nama Petugas..." size="sm" readonly></b-form-input>
            </b-form-group>
          </b-col>
        </b-row>
      </div>
      <div :class="['mt-2 px-2', messageClass]" v-if="messagetype === '2'">
        <div class="d-flex flex-row justify-content-between">
          <div>
            <p class="text-right m-0 p-0">{{ message }}</p>
          </div>
          <div @click="clearMessage">
            <p class="text-right m-0 p-0"><i class="fas fa-times-circle"></i></p>
          </div>
        </div>
      </div>
    </b-modal>
    <b-modal id="modal-lihat-sisrute" ref="modal-lihat-sisrute" header-class="text-uppercase" title="DETAIL RUJUKAN" size="lg" centered scrollable :no-close-on-backdrop="true" :static="true" :no-close-on-esc="true" cancel-title="Tutup" ok-title="Tutup" :ok-only="true" ok-variant="secondary">
      <div>
        <div class="pasien border shadow-sm mb-3">
          <div class="bg-primary px-2 py-1">
            <p class="m-0 p-0">PASIEN</p>
          </div>
          <div class="p-2">
            <b-row>
              <b-col>
                <div>
                  <p class="m-0 p-0">NORM</p>
                </div>
                <div>
                  <p class="m-0 p-0 text-dark">{{ formPreview.PASIEN.NORM.padStart(8, '0') }}</p>
                </div>
              </b-col>
            </b-row>
            <hr class="my-1">
            <b-row>
              <b-col>
                <div>
                  <p class="m-0 p-0">NAMA</p>
                </div>
                <div>
                  <p class="m-0 p-0 text-dark">{{ formPreview.PASIEN.NAMA }}</p>
                </div>
              </b-col>
            </b-row>
            <hr class="my-1">
            <b-row>
              <b-col>
                <div>
                  <p class="m-0 p-0">KONTAK</p>
                </div>
                <div>
                  <p class="m-0 p-0 text-dark">{{ formPreview.PASIEN.KONTAK }}</p>
                </div>
              </b-col>
            </b-row>
            <hr class="my-1">
            <b-row>
              <b-col>
                <div>
                  <p class="m-0 p-0">ALAMAT</p>
                </div>
                <div>
                  <p class="m-0 p-0 text-dark">{{ formPreview.PASIEN.ALAMAT }}</p>
                </div>
              </b-col>
            </b-row>
            <hr class="my-1">
            <b-row>
              <b-col>
                <div>
                  <p class="m-0 p-0">TEMPAT LAHIR</p>
                </div>
                <div>
                  <p class="m-0 p-0 text-dark">{{ formPreview.PASIEN.TEMPAT_LAHIR }}</p>
                </div>
              </b-col>
            </b-row>
            <hr class="my-1">
            <b-row>
              <b-col>
                <div>
                  <p class="m-0 p-0">TANGGAL LAHIR</p>
                </div>
                <div>
                  <p class="m-0 p-0 text-dark">{{ formPreview.PASIEN.TANGGAL_LAHIR }}</p>
                </div>
              </b-col>
            </b-row>
            <hr class="my-1">
            <b-row>
              <b-col>
                <div>
                  <p class="m-0 p-0">JENIS KELAMIN.NAMA</p>
                </div>
                <div>
                  <p class="m-0 p-0 text-dark">{{ formPreview.PASIEN.JENIS_KELAMIN.NAMA }}</p>
                </div>
              </b-col>
            </b-row>
            <hr class="my-1">
            <b-row>
              <b-col>
                <div>
                  <p class="m-0 p-0">NO KARTU JKN</p>
                </div>
                <div>
                  <p class="m-0 p-0 text-dark">{{ formPreview.PASIEN.NO_KARTU_JKN }}</p>
                </div>
              </b-col>
            </b-row>
            <hr class="my-1">
            <b-row>
              <b-col>
                <div>
                  <p class="m-0 p-0">NIK</p>
                </div>
                <div>
                  <p class="m-0 p-0 text-dark">{{ formPreview.PASIEN.NIK }}</p>
                </div>
              </b-col>
            </b-row>
          </div>
        </div>
        <div class="rujukan border shadow-sm mb-3">
          <div class="bg-primary px-2 py-1">
            <p class="m-0 p-0">RUJUKAN</p>
          </div>
          <div class="p-2">
            <b-row>
              <b-col>
                <div>
                  <p class="m-0 p-0">NOMOR</p>
                </div>
                <div>
                  <p class="m-0 p-0 text-dark">{{ formPreview.RUJUKAN.NOMOR }}</p>
                </div>
              </b-col>
            </b-row>
            <hr class="my-1">
            <b-row>
              <b-col>
                <div>
                  <p class="m-0 p-0">NOMOR RUJUKAN BPJS</p>
                </div>
                <div>
                  <p class="m-0 p-0 text-dark">{{ formPreview.RUJUKAN.NOMOR_RUJUKAN_BPJ ? `${formPreview.RUJUKAN.NOMOR_RUJUKAN_BPJ} - ${formPreview.RUJUKAN.NOMOR_RUJUKAN_BPJ}` : `-` }}</p>
                </div>
              </b-col>
            </b-row>
            <hr class="my-1">
            <b-row>
              <b-col>
                <div>
                  <p class="m-0 p-0">FASKES ASAL</p>
                </div>
                <div>
                  <p class="m-0 p-0 text-dark">{{ formPreview.RUJUKAN.FASKES_ASAL.NAMA }}</p>
                </div>
              </b-col>
            </b-row>
            <hr class="my-1">
            <b-row>
              <b-col>
                <div>
                  <p class="m-0 p-0">FASKES TUJUAN</p>
                </div>
                <div>
                  <p class="m-0 p-0 text-dark">{{ formPreview.RUJUKAN.FASKES_TUJUAN.NAMA }}</p>
                </div>
              </b-col>
            </b-row>
            <hr class="my-1">
            <b-row>
              <b-col>
                <div>
                  <p class="m-0 p-0">JENIS RUJUKAN</p>
                </div>
                <div>
                  <p class="m-0 p-0 text-dark">{{ formPreview.RUJUKAN.JENIS_RUJUKAN.NAMA }}</p>
                </div>
              </b-col>
            </b-row>
            <hr class="my-1">
            <b-row>
              <b-col>
                <div>
                  <p class="m-0 p-0">PELAYANAN</p>
                </div>
                <div>
                  <p class="m-0 p-0 text-dark">{{ formPreview.RUJUKAN.PELAYANAN ? `${formPreview.RUJUKAN.PELAYANAN} - ${formPreview.RUJUKAN.PELAYANAN}` : `-` }}</p>
                </div>
              </b-col>
            </b-row>
            <hr class="my-1">
            <b-row>
              <b-col>
                <div>
                  <p class="m-0 p-0">ALASAN</p>
                </div>
                <div>
                  <p class="m-0 p-0 text-dark">{{ formPreview.RUJUKAN.ALASAN.NAMA }}</p>
                </div>
              </b-col>
            </b-row>
            <hr class="my-1">
            <b-row>
              <b-col>
                <div>
                  <p class="m-0 p-0">ALASAN LAINNYA</p>
                </div>
                <div>
                  <p class="m-0 p-0 text-dark">{{ formPreview.RUJUKAN.ALASAN_LAINNYA }}</p>
                </div>
              </b-col>
            </b-row>
            <hr class="my-1">
            <b-row>
              <b-col>
                <div>
                  <p class="m-0 p-0">STATUS</p>
                </div>
                <div>
                  <p class="m-0 p-0 text-dark">{{ formPreview.RUJUKAN.ALASAN_LAINNYA }}</p>
                </div>
              </b-col>
            </b-row>
            <hr class="my-1">
            <b-row>
              <b-col>
                <div>
                  <p class="m-0 p-0">STATUS DITERIMA</p>
                </div>
                <div>
                  <p class="m-0 p-0 text-dark" v-if="formPreview.RUJUKAN.STATUS_DITERIMA">{{ formPreview.RUJUKAN.STATUS_DITERIMA.NAMA }}</p>
                  <p class="m-0 p-0 text-dark" v-else>-</p>
                </div>
              </b-col>
            </b-row>
            <hr class="my-1">
            <b-row>
              <b-col>
                <div>
                  <p class="m-0 p-0">TANGGAL</p>
                </div>
                <div>
                  <p class="m-0 p-0 text-dark">{{ formPreview.RUJUKAN.TANGGAL }}</p>
                </div>
              </b-col>
            </b-row>
            <hr class="my-1">
            <b-row>
              <b-col>
                <div>
                  <p class="m-0 p-0">DOKTER</p>
                </div>
                <div>
                  <p class="m-0 p-0 text-dark">{{ formPreview.RUJUKAN.DOKTER.NIK }} - {{ formPreview.RUJUKAN.DOKTER.NAMA }}</p>
                </div>
              </b-col>
            </b-row>
            <hr class="my-1">
            <b-row>
              <b-col>
                <div>
                  <p class="m-0 p-0">PETUGAS</p>
                </div>
                <div>
                  <p class="m-0 p-0 text-dark">{{ formPreview.RUJUKAN.PETUGAS.NIK }} - {{ formPreview.RUJUKAN.PETUGAS.NAMA }}</p>
                </div>
              </b-col>
            </b-row>
            <hr class="my-1">
            <b-row>
              <b-col>
                <div>
                  <p class="m-0 p-0">DIAGNOSA</p>
                </div>
                <div>
                  <p class="m-0 p-0 text-dark">{{ formPreview.RUJUKAN.DIAGNOSA ? `${formPreview.RUJUKAN.DIAGNOSA.KODE} - ${formPreview.RUJUKAN.DIAGNOSA.NAMA}` : `-` }}</p>
                </div>
              </b-col>
            </b-row>
          </div>
        </div>
        <div class="kondisi-umum border shadow-sm mb-3">
          <div class="bg-primary px-2 py-1">
            <p class="m-0 p-0">KONDISI UMUM</p>
          </div>
          <div class="p-2">
            <b-row>
              <b-col>
                <div>
                  <p class="m-0 p-0">ANAMNESIS DAN PEMERIKSAAN FISIK</p>
                </div>
                <div>
                  <b-form-textarea
                    disabled
                    class="text-dark rounded-0"
                    v-model="formPreview.KONDISI_UMUM.ANAMNESIS_DAN_PEMERIKSAAN_FISIK"
                    placeholder="-"
                    rows="3"
                    max-rows="7"
                  ></b-form-textarea>
                  <!-- <p class="m-0 p-0 text-dark">{{ formPreview.KONDISI_UMUM.ANAMNESIS_DAN_PEMERIKSAAN_FISIK }}</p> -->
                </div>
              </b-col>
            </b-row>
            <hr class="my-1">
            <b-row>
              <b-col>
                <div>
                  <p class="m-0 p-0">KRITERIA RUJUKAN</p>
                </div>
                <div>
                  <p class="m-0 p-0 text-dark">{{ formPreview.KONDISI_UMUM.KRITERIA_RUJUKAN ? `${formPreview.KONDISI_UMUM.KRITERIA_RUJUKAN} - ${formPreview.KONDISI_UMUM.KRITERIA_RUJUKAN}` : `-` }}</p>
                </div>
              </b-col>
            </b-row>
            <hr class="my-1">
            <b-row>
              <b-col>
                <div>
                  <p class="m-0 p-0">KESADARAN</p>
                </div>
                <div>
                  <p class="m-0 p-0 text-dark">{{ formPreview.KONDISI_UMUM.KESADARAN.NAMA }}</p>
                </div>
              </b-col>
            </b-row>
            <hr class="my-1">
            <b-row>
              <b-col>
                <div>
                  <p class="m-0 p-0">TEKANAN DARAH</p>
                </div>
                <div>
                  <p class="m-0 p-0 text-dark">{{ formPreview.KONDISI_UMUM.TEKANAN_DARAH }}</p>
                </div>
              </b-col>
            </b-row>
            <hr class="my-1">
            <b-row>
              <b-col>
                <div>
                  <p class="m-0 p-0">FREKUENSI NADI</p>
                </div>
                <div>
                  <p class="m-0 p-0 text-dark">{{ formPreview.KONDISI_UMUM.FREKUENSI_NADI }}</p>
                </div>
              </b-col>
            </b-row>
            <hr class="my-1">
            <b-row>
              <b-col>
                <div>
                  <p class="m-0 p-0">SUHU</p>
                </div>
                <div>
                  <p class="m-0 p-0 text-dark">{{ formPreview.KONDISI_UMUM.SUHU }}</p>
                </div>
              </b-col>
            </b-row>
            <hr class="my-1">
            <b-row>
              <b-col>
                <div>
                  <p class="m-0 p-0">PERNAPASAN</p>
                </div>
                <div>
                  <p class="m-0 p-0 text-dark">{{ formPreview.KONDISI_UMUM.PERNAPASAN }}</p>
                </div>
              </b-col>
            </b-row>
            <hr class="my-1">
            <b-row>
              <b-col>
                <div>
                  <p class="m-0 p-0">NYERI</p>
                </div>
                <div>
                  <p class="m-0 p-0 text-dark">{{ formPreview.KONDISI_UMUM.NYERI.NAMA }}</p>
                </div>
              </b-col>
            </b-row>
            <hr class="my-1">
            <b-row>
              <b-col>
                <div>
                  <p class="m-0 p-0">KEADAAN UMUM</p>
                </div>
                <div>
                  <p class="m-0 p-0 text-dark">{{ formPreview.KONDISI_UMUM.KEADAAN_UMUM ? `${formPreview.KONDISI_UMUM.KEADAAN_UMUM} - ${formPreview.KONDISI_UMUM.KEADAAN_UMUM}` : `-` }}</p>
                </div>
              </b-col>
            </b-row>
            <hr class="my-1">
            <b-row>
              <b-col>
                <div>
                  <p class="m-0 p-0">ALERGI</p>
                </div>
                <div>
                  <p class="m-0 p-0 text-dark">{{ formPreview.KONDISI_UMUM.ALERGI }}</p>
                </div>
              </b-col>
            </b-row>
          </div>
        </div>
        <div class="penunjang border shadow-sm">
          <div class="bg-primary px-2 py-1">
            <p class="m-0 p-0">PENUNJANG</p>
          </div>
          <div class="p-2">
            <div>
              <p class="m-0 p-0">LABORATORIUM</p>
            </div>
            <div class="laboratorium-img">
              <b-row>
                <b-col lg="4" v-for="(data, index) in formImageListLaboratorium" :key="index">
                  <div class="my-cursor p-2" @click="getImgDetail(data, '1')">
                    <b-img :src="require('../../../assets/images/lab-rad.svg')" width="150rem" fluid alt="Responsive image"></b-img>
                    <div>
                      <span class="text-dark">{{ data.NAME_FILE }}</span>
                    </div>
                  </div>
                </b-col>
              </b-row>
            </div>
            <b-row>
              <b-col>
                <div>
                  <b-form-textarea
                    disabled
                    class="text-dark rounded-0"
                    v-model="formPreview.PENUNJANG.LABORATORIUM"
                    placeholder="-"
                    rows="3"
                    max-rows="7"
                  ></b-form-textarea>
                </div>
              </b-col>
            </b-row>
            <hr class="mt-3 mb-1">
            <div>
              <p class="m-0 p-0">RADIOLOGI</p>
            </div>
            <div class="radiologi-img">
              <b-row>
                <b-col lg="4" v-for="(data, index) in formImageListRadiologi" :key="index">
                  <div class="my-cursor p-2" @click="getImgDetail(data, '2')">
                    <b-img :src="require('../../../assets/images/lab-rad.svg')" width="150rem" fluid alt="Responsive image"></b-img>
                    <div>
                      <span class="text-dark">{{ data.fileName }}</span>
                    </div>
                  </div>
                </b-col>
              </b-row>
            </div>
            <b-row>
              <b-col>
                <div>
                  <b-form-textarea
                    disabled
                    class="text-dark rounded-0"
                    v-model="formPreview.PENUNJANG.RADIOLOGI"
                    placeholder="-"
                    rows="3"
                    max-rows="7"
                  ></b-form-textarea>
                </div>
              </b-col>
            </b-row>
            <hr class="mt-3 mb-1">
            <b-row>
              <b-col>
                <div>
                  <p class="m-0 p-0">TERAPI</p>
                </div>
                <div>
                  <b-form-textarea
                    disabled
                    class="text-dark rounded-0"
                    v-model="formPreview.PENUNJANG.TERAPI"
                    placeholder="-"
                    rows="3"
                    max-rows="7"
                  ></b-form-textarea>
                </div>
              </b-col>
            </b-row>
            <hr class="mt-3 mb-1">
            <b-row>
              <b-col>
                <div>
                  <p class="m-0 p-0">TINDAKAN</p>
                </div>
                <div>
                  <b-form-textarea
                    disabled
                    class="text-dark rounded-0"
                    v-model="formPreview.PENUNJANG.TINDAKAN"
                    placeholder="-"
                    rows="3"
                    max-rows="7"
                  ></b-form-textarea>
                </div>
              </b-col>
            </b-row>
          </div>
          </div>
      </div>
    </b-modal>
    <b-modal id="modal-image-sisrute" ref="modal-image-sisrute" header-class="text-uppercase" title="GAMBAR DETAIL" size="xl" centered scrollable :no-close-on-backdrop="true" :static="true" :no-close-on-esc="true" cancel-title="Tutup" :ok-only="true" ok-variant="secondary" ok-title="Tutup">
      <div class="text-center">
        <b-img :src="image" class="border shadow-sm" fluid alt="Responsive image"></b-img>
      </div>
      <div class="mt-2" v-if="messagetype === '2'">
        <p class="text-center text-dark h5 m-0 p-0">{{ message }}</p>
      </div>
    </b-modal>
  </div>
</template>
<script>
import { xray } from '../../../config/pluginInit'
import Loader from '../../../components/xray/loader/Loader'
import Services from '../../../services/fatmahost/connected/index.js'
import Multiselect from 'vue-multiselect'
import flatPickr from 'vue-flatpickr-component'
import 'flatpickr/dist/flatpickr.css'

export default {
  name: 'Sisrute',
  components: {
    Loader,
    Multiselect,
    flatPickr
  },
  data () {
    return {
      isLoading: false,
      api: {
        refrensiGeneral: [
          { url: 'alasanrujukan' },
          { url: 'diagnosa' },
          { url: 'jenispelayanan' },
          { url: 'keadaankeluar' },
          { url: 'carakeluar' },
          { url: 'kriteriakhusus' }
        ]
      },
      table: {
        isBusy: false,
        items: this.list,
        fields: [
          { label: 'NO.', key: 'index', class: 'text-lg-center' },
          { label: 'NOMOR', key: 'rujukan_nomor' },
          { label: 'NORM', key: 'norm' },
          { label: 'NAMA', key: 'nama' },
          { label: 'TANGGAL', key: 'tanggal' },
          { label: 'FASKES TUJUAN', key: 'rujukan_tujuan' },
          { label: 'DIAGNOSA', key: 'diagnosa' },
          { label: 'STATUS', key: 'status' },
          { label: 'AKSI', key: 'aksi', class: 'text-center' }
        ],
        currentPage: 1,
        perPage: null,
        totalRows: null,
        selectMode: 'single',
        condition: ''
      },
      filter: {
        nomor: '',
        tanggal: '',
        create: '',
        status: ''
      },
      options: {
        create: [
          { KODE: '', NAMA: 'Rujukan Masuk' },
          { KODE: '?create=true', NAMA: 'Rujukan Keluar' }
        ],
        status: [
          { KODE: 0, NAMA: 'Belum Respon' },
          { KODE: 1, NAMA: 'Sudah Respon' }
        ],
        jeniskelamin: [
          { KODE: '1', NAMA: 'Laki - laki' },
          { KODE: '2', NAMA: 'Perempuan' }
        ],
        jenisrujukan: [
          { KODE: '1', NAMA: 'Rawat Jalan' },
          { KODE: '2', NAMA: 'Rawat Darurat/Inap' },
          { KODE: '3', NAMA: 'Parsial' },
          { KODE: '6', NAMA: 'Maternal' },
          { KODE: '7', NAMA: 'Neonatal' }
        ],
        kesadaran: [
          { KODE: '1', NAMA: 'Sadar' },
          { KODE: '2', NAMA: 'Tidak Sadar' }
        ],
        nyeri: [
          { KODE: '0', NAMA: 'Tidak Nyeri' },
          { KODE: '1', NAMA: 'Ringan' },
          { KODE: '2', NAMA: 'Sedang' },
          { KODE: '3', NAMA: 'Berat' }
        ],
        terima: [
          { KODE: '1', NAMA: 'Diterima' },
          { KODE: '0', NAMA: 'Tidak Diterima' }
        ],
        faskes: [],
        kriteriarujukan: [],
        alasanrujukan: [],
        diagnosa: [],
        jenispelayanan: [],
        keadaankeluar: [],
        carakeluar: [],
        kriteriakhusus: []
      },
      selected: {
        create: [
          { KODE: '', NAMA: 'Rujukan Masuk' }
        ],
        status: [],
        kesadaran: [],
        nyeri: [],
        terima: [],
        faskes: [],
        kriteriarujukan: [],
        jeniskelamin: [],
        diagnosa: [],
        jenispelayanan: [],
        keadaankeluar: [],
        carakeluar: [],
        kriteriakhusus: []
      },
      date: {
        tanggalList: '',
        tanggallahir: '',
        tanggal: ''
      },
      config: {
        dateFormat: 'd-m-Y',
        maxDate: 'today',
        disableMobile: true
      },
      form: {
        PASIEN: {
          NORM: null,
          NIK: '',
          NO_KARTU_JKN: '',
          NAMA: '',
          JENIS_KELAMIN: '',
          TANGGAL_LAHIR: '',
          TEMPAT_LAHIR: '',
          ALAMAT: '',
          KONTAK: ''
        },
        RUJUKAN: {
          JENIS_RUJUKAN: '',
          PELAYANAN: '',
          NOMOR_RUJUKAN_BPJS: '',
          KRITERIA: {
            KRITERIA_RUJUKAN: '',
            KRITERIA_KHUSUS: '',
            SDM: '',
            PELAYANAN: '',
            KELAS_PERAWATAN: '',
            JENIS_PERAWATAN: '',
            ALAT: '',
            SARANA: '',
            KRITERIA_MATNEO: ''
          },
          TANGGAL: '',
          FASKES_TUJUAN: '',
          ALASAN: '',
          ALASAN_LAINNYA: '',
          DIAGNOSA: '',
          DOKTER: {
            NIK: '',
            NAMA: ''
          },
          PETUGAS: {
            NIK: '',
            NAMA: xray.currentUser().name
          }
        },
        KONDISI_UMUM: {
          ANAMNESIS_DAN_PEMERIKSAAN_FISIK: '',
          KESADARAN: '',
          TEKANAN_DARAH: '',
          FREKUENSI_NADI: '',
          SUHU: '',
          PERNAPASAN: '',
          KEADAAN_UMUM: '',
          NYERI: '',
          ALERGI: ''
        },
        PENUNJANG: {
          LABORATORIUM: '',
          RADIOLOGI: '',
          TERAPI: '',
          TINDAKAN: ''
        }
      },
      formJawabRujukan: {
        DITERIMA: '',
        KETERANGAN: '',
        PETUGAS: {
          NIK: '',
          NAMA: xray.currentUser().name
        }
      },
      formBatalRujukan: {
        PETUGAS: {
          NIK: '',
          NAMA: xray.currentUser().name
        }
      },
      formPreview: {
        PASIEN: {
          NORM: '',
          NAMA: '',
          KONTAK: '',
          ALAMAT: '',
          TEMPAT_LAHIR: '',
          TANGGAL_LAHIR: '',
          JENIS_KELAMIN: {
            KODE: '',
            NAMA: ''
          },
          NO_KARTU_JKN: '',
          NIK: ''
        },
        RUJUKAN: {
          NOMOR: '',
          NOMOR_RUJUKAN_BPJS: null,
          FASKES_ASAL: {
            KODE: '',
            NAMA: ''
          },
          FASKES_TUJUAN: {
            KODE: '',
            NAMA: ''
          },
          JENIS_RUJUKAN: {
            KODE: '',
            NAMA: ''
          },
          PELAYANAN: '',
          ALASAN: {
            KODE: '',
            NAMA: ''
          },
          ALASAN_LAINNYA: '',
          STATUS: {
            KODE: '',
            NAMA: ''
          },
          STATUS_DITERIMA: {
            KODE: '',
            NAMA: '',
            KETERANGAN: ''
          },
          TANGGAL: '',
          DOKTER: {
            NIK: '',
            NAMA: ''
          },
          PETUGAS: {
            NIK: '',
            NAMA: ''
          },
          DIAGNOSA: {
            KODE: '',
            NAMA: ''
          }
        },
        KONDISI_UMUM: {
          ANAMNESIS_DAN_PEMERIKSAAN_FISIK: '',
          KRITERIA_RUJUKAN: null,
          KESADARAN: {
            KODE: '',
            NAMA: ''
          },
          TEKANAN_DARAH: '',
          FREKUENSI_NADI: '',
          SUHU: '',
          PERNAPASAN: '',
          NYERI: {
            KODE: '',
            NAMA: ''
          },
          KEADAAN_UMUM: '',
          ALERGI: '-'
        },
        PENUNJANG: {
          LABORATORIUM: '',
          RADIOLOGI: '',
          TERAPI: '',
          TINDAKAN: ''
        }
      },
      formImageListLaboratorium: [],
      formImageListRadiologi: [],
      image: '',
      view: {
        norm: '',
        nama: '',
        faskes1: '',
        faskes2: '',
        diagnosa: '',
        status: ''
      },
      formRujukanNoRujukan: '',
      formPenunjangLab: false,
      formPenunjangRad: false,
      modalDesc: '',
      status: '',
      messagetype: '0',
      messageClass: '',
      message: ''
    }
  },
  mounted () {
    xray.index()
    this.getRefrence()
  },
  methods: {
    async getRefrence () {
      try {
        for (let index = 0; index < this.api.refrensiGeneral.length; index++) {
          let response = await Services.refrences(this.api.refrensiGeneral[index].url)
          this.options[`${this.api.refrensiGeneral[index].url}`] = response.data.data
        }
      } catch (error) {
        this.message = error.message
      }
    },
    async getRefrenceParams ($event, obj, param) {
      try {
        let response = await Services.refrencesWithParams($event, obj, param)
        if (obj === 'faskes') {
          this.options[`${obj}`] = response.data.data
        } else {
          let arr = []
          arr.push(response.data.data)
          this.options[`${obj}`] = arr
        }
      } catch (error) {
        this.message = error.message
      }
    },
    async list () {
      this.table.isBusy = true
      try {
        let create = this.filter.create
        // let status = this.filter.status !== '' ? `&status=${this.filter.status}` : ``
        let nomor = ''
        if (this.filter.create === '') nomor = this.filter.nomor !== '' ? `?nomor=${this.filter.nomor}` : ``
        if (this.filter.create === '?create=true') nomor = this.filter.nomor !== '' ? `&nomor=${this.filter.nomor}` : ``
        let condition = `${create}${nomor}`
        let response = await Services.findAll(condition)
        if (response.data.status === 200) {
          this.table.isBusy = false
          this.status = response.data.status
          this.table.totalRows = response.data.total
          return response.data.data
        } else {
          return []
        }
      } catch (error) {
        this.table.isBusy = false
        this.status = 400
        this.toast('bg-danger', error.response.data.detail, '1')
        return []
      }
    },
    refreshTable () {
      this.$refs.table.refresh()
    },
    rowClass (item, type) {
      if (!item || type !== 'row') return
      if (item.RUJUKAN.STATUS.KODE !== '1') return 'text-secondary'
    },
    async onSubmit (bvModalEvent) {
      bvModalEvent.preventDefault()
      this.isLoading = true
      try {
        let response = await Services.postRujukan(this.form)
        this.isLoading = false
        this.toast('bg-success', response.data.detail, '1')
        this.$refs.table.refresh()
        this.$refs[`modal-${this.modalDesc}-sisrute`].hide()
      } catch (error) {
        this.isLoading = false
        this.toast('bg-danger', error.response.data.detail || error.response.data.data.detail, '2')
      }
    },
    async onPutRujukan (bvModalEvent) {
      bvModalEvent.preventDefault()
      this.isLoading = true
      try {
        let vObj = this.modalDesc === 'jawab' ? 'jawab-rujukan' : 'batal-rujukan'
        let vForm = this.modalDesc === 'jawab' ? this.formJawabRujukan : this.formBatalRujukan
        let response = await Services.putRujukan(vObj, this.formRujukanNoRujukan, vForm)
        this.isLoading = false
        this.toast('bg-success', response.data.detail, '1')
        this.$refs.table.refresh()
        this.$refs[`modal-${this.modalDesc}-sisrute`].hide()
      } catch (error) {
        this.isLoading = false
        this.toast('bg-danger', error.response.data.detail || error.response.data.data.detail, '2')
      }
    },
    openModal (modal, nomor, norm, nama, faskes1, faskes2, diagnosa) {
      this.clearMessage()
      this.formImageListLaboratorium = []
      this.formImageListRadiologi = []
      this.isLoading = true
      this.modalDesc = modal
      this.formRujukanNoRujukan = nomor
      if (modal !== 'lihat') {
        this.isLoading = false
        this.view.norm = norm
        this.view.nama = nama
        this.view.faskes1 = faskes1
        this.view.faskes2 = faskes2
        this.view.diagnosa = diagnosa
        this.$refs[`modal-${modal}-sisrute`].show()
      } else {
        this.findSpecific(modal, nomor)
      }
    },
    async findSpecific (modal, nomor) {
      try {
        let condition = this.filter.create === '?create=true' ? `?create=true&nomor=${nomor}` : `?nomor=${nomor}`
        let response = await Services.findAll(condition)
        let arr = []
        if (response.data.status === 200) {
          this.formPreview = response.data.data[0]
          if (response.data.data[0].PENUNJANG.LABORATORIUM !== '') arr.push({ name: 'lab', jenis: '1' })
          if (response.data.data[0].PENUNJANG.RADIOLOGI !== '') arr.push({ name: 'rad', jenis: '2' })
          this.getImg(arr, modal)
        }
      } catch (error) {
        this.isLoading = false
        this.toast('bg-danger', error.response.data.detail || error.response.data.data.detail, '2')
      }
    },
    async getImg (arr, modal) {
      this.isLoading = true
      try {
        let vRujukan = this.formPreview.RUJUKAN.NOMOR
        for (let i = 0; i < arr.length; i++) {
          const response = await Services.image_list(vRujukan, arr[i].jenis)
          if (response.data.data !== '') {
            if (arr[i].name === 'lab') this.formImageListLaboratorium = response.data.data.data
            if (arr[i].name === 'rad') this.formImageListRadiologi = response.data.data.data
          }
        }
      } catch (error) {
        this.toast('bg-danger', error.response.data.detail || error.response.data.data.detail || error.message, '2')
      } finally {
        this.isLoading = false
        this.$refs[`modal-${modal}-sisrute`].show()
      }
    },
    async getImgDetail (data, jenis) {
      this.isLoading = true
      try {
        let vRujukan = data.NOMOR_RUJUKAN
        let vFile = data.NAME_FILE
        let vType = data.NAME_FILE.split('.').pop()
        let vJenis = jenis
        let vEncode = 'base64'
        let response = await Services.image_view(vRujukan, vFile, vType, vJenis, vEncode)
        let vImg = `data:image/${vType};${vEncode},` + response.data.data.data.FILE
        this.image = vImg
      } catch (error) {
        this.toast('bg-danger', error.response.data.detail || error.response.data.data.detail || error.message, '2')
      } finally {
        this.isLoading = false
        this.$refs['modal-image-sisrute'].show()
      }
    },
    valueMultiselect ($event, param) {
      // filter
      if (param === 'create') {
        this.filter.create = $event.KODE
        this.$refs.table.refresh()
      }
      if (param === 'status') {
        this.filter.status = $event.KODE
        this.$refs.table.refresh()
      }
      // pasien
      if (param === 'jeniskelamin') this.form.PASIEN.JENIS_KELAMIN = $event.KODE
      // rujukan
      if (param === 'jenisrujukan') this.form.RUJUKAN.JENIS_RUJUKAN = $event.KODE
      if (param === 'kriteriarujukan') this.form.RUJUKAN.KRITERIA_RUJUKANN = $event.KODE
      if (param === 'faskes') this.form.RUJUKAN.FASKES_TUJUAN = $event.KODE
      if (param === 'diagnosa') this.form.RUJUKAN.DIAGNOSA = $event.KODE
      if (param === 'kriteriakhusus') this.form.RUJUKAN.KRITERIA.KRITERIA_KHUSUS = $event.KODE
      if (param === 'alasanrujukan') this.form.RUJUKAN.ALASAN = $event.KODE
      // kondisi umum
      if (param === 'kesadaran') this.form.KONDISI_UMUM.KESADARAN = $event.KODE
      if (param === 'nyeri') this.form.KONDISI_UMUM.NYERI = $event.KODE
      // form jawab rujukan
      if (param === 'terima') this.formJawabRujukan.DITERIMA = $event.KODE
    },
    convertDate (value, param) {
      let date = new Date(value)
      let currentDate = new Date()
      let day = String(date.getDate()).padStart(2, '0')
      let month = String(date.getMonth() + 1).padStart(2, '0')
      let year = String(date.getFullYear())
      let hours = String(currentDate.getHours()).padStart(2, '0')
      let minutes = String(currentDate.getMinutes()).padStart(2, '0')
      let seconds = String(currentDate.getSeconds()).padStart(2, '0')
      if (param === 'tanggallahir') this.form.PASIEN.TANGGAL_LAHIR = year + '-' + month + '-' + day
      if (param === 'tanggal') this.form.RUJUKAN.TANGGAL = year + '-' + month + '-' + day + ' ' + hours + ':' + minutes + ':' + seconds
      if (param === 'tanggalList') {
        this.filter.tanggal = year + '-' + month + '-' + day
        this.$refs.table.refresh()
      }
    },
    toast (variant, message, type) {
      this.messagetype = type
      this.messageClass = variant
      this.message = message
    },
    nameWithKode ({ KODE, NAMA }) {
      return `${KODE} - ${NAMA}`
    },
    descWithKode ({ KODE, DESKRIPSI }) {
      return `${KODE} - ${DESKRIPSI}`
    },
    clearMessage () {
      this.message = ''
      this.messageClass = ''
      this.messagetype = '0'
    },
    resetFilter () {
      this.filter.nomor = ''
      this.filter.tanggal = ''
      this.filter.create = ''
      this.filter.status = ''
      //
      this.date.tanggalList = ''
      this.selected.status = []
      //
      this.$refs.table.refresh()
    }
  }
}
</script>

<style>
#sisrute .table td {
  font-size: 12px;
  font-weight: 500;
  padding-top: 0px;
  padding-bottom: 0px;
}

#sisrute .table th {
  font-size: 13px;
  font-weight: 400;
}

#sisrute .page-link {
  background: transparent !important;
  border: none !important;
  color: gray !important;
  margin-right: 15px;
}

#sisrute .page-item.active .page-link {
  background-color: transparent;
  border-color: transparent;
  color: #089bab !important;
  font-weight: bold;
}
#sisrute .my-cursor {
  cursor: pointer;
}
#sisrute .radius-custom {
  border-radius: .3rem;
}
#sisrute .form-control-custom {
  height: 33px!important;
  background-color: transparent;
  border-radius: .3rem!important;
  font-size: 13px;
  color: black;
}
#sisrute .multiselect__placeholder {
  font-weight: normal;
  font-size: 13px!important;
  color: #495057c9;
}
#sisrute .multiselect__option {
  font-weight: normal;
  font-size: 13px;
}
#sisrute .multiselect__tags {
  overflow: hidden;
  font-weight: normal;
  padding: 3px 40px 0 8px!important;
  height: 33px!important;
  min-height: 33px!important;
  border: 1px solid rgba(0, 0, 0, 0.212);
}
#sisrute .multiselect {
  height: 33px!important;
  min-height: 33px!important;
}
#sisrute .multiselect__select {
  height: 33px!important;
  min-height: 33px!important;
}
#sisrute .multiselect__single  {
  padding-left: 0px;
}
#sisrute .custom-fonts {
  font-size: 13px;
}
#sisrute .custom-flat-pickr {
    border: 1px solid rgb(233, 233, 233);
    height: 33px;
    padding-left: 10px;
    padding-top: 5px;
    padding-bottom: 5px;
    border: 1px solid rgb(192, 192, 192)!important;
    border-radius: 5px;
    font-size: 12px;
    color: black;
}
#sisrute .form-control:disabled, .form-control:disabled {
  background-color: white;
}
</style>
