<template>
  <div class="emr-riwayat-all">
    <b-row class="justify-content-between">
      <b-col cols="12" :md="formOrder" class="pr-1">
        <div class="border w-100 h-100">
          <div class="arrow bg-primary p-2">
            <span class="h6"> &nbsp; <i class="fas fa-sitemap"></i> Riwayat All</span>
          </div>
          <!-- <pre>{{ fields.table }}</pre> -->
          <div class="custom-scroller" :style="{height: heightPercent}">
            <b-row class="align-items-center mr-0">
              <b-col md="12">
                <div class="table mb-0">
                  <b-table class="mb-0"
                  :busy="tableBusy"
                  size="sm"
                  selectable
                  select-mode="single"
                  @row-clicked="getDetailLab"
                  ref="table"
                  hover
                  :items="items.table"
                  :fields="fields.table">
                  <template #table-busy>
                    <div class="text-center my-2">
                      <b-spinner class="align-middle text-primary mr-2"></b-spinner>
                      <strong class="text-dark">Memuat Data...</strong>
                    </div>
                  </template>
                  </b-table>
                </div>
              </b-col>
            </b-row>
          </div>
          <div class="arrow bg-white border p-2 text-left">
            <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage"
              size="sm" class="my-0">
            </b-pagination>
          </div>
        </div>
      </b-col>
      <b-col cols="12" :md="formDetail" class="pl-1">
        <div class="border w-100 h-100">
          <div class="arrow bg-primary p-2">
            <span><span class="h6"> &nbsp;
              &nbsp; <i class="fas fa-info-circle"></i> Riwayat Detil All</span></span>
          </div>
          <div class="custom-scroller" :style="{ height: heightPercent }">
            <div class="d-flex flex-row">
              <div class="w-100">
                <b-row class="align-items-center mr-0">
                  <b-col md="12">
                    <div class="table mb-0">
                      <b-table class="mb-0"
                      ref="table2"
                      hover
                      selectable
                      select-mode="single"
                      :busy="tableBusyB"
                      :items="items.table2"
                      :fields="fields.table2">
                      <template #table-busy>
                        <div class="text-center my-2">
                          <b-spinner class="align-middle text-primary mr-2"></b-spinner>
                          <strong class="text-dark">Memuat Data...</strong>
                        </div>
                      </template>
                      </b-table>
                    </div>
                  </b-col>
                </b-row>
              </div>
            </div>
          </div>
          <div class="arrow bg-white border p-2 text-left">
            <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage"
              size="sm" class="my-0">
            </b-pagination>
          </div>
        </div>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import { xray } from '../../../../config/pluginInit'
import Services from '../../../../services/fatmahost/index.js'

export default {
  name: 'EResepDaftarOrder',
  components: {
  },
  data () {
    return {
      windowHeight: 0,
      heightPercent: 0,
      formOrder: '6',
      formDetail: '6',
      message: '',
      response: {
        resep: [
          { key: 'no_urut', label: 'No.', class: 'text-uppercase text-center' },
          { key: 'kemasan', label: 'Kemasan', class: 'text-uppercase' },
          { key: 'produk_nama', label: 'Nama Obat', class: 'text-uppercase' },
          { key: 'jumlah', label: 'Jumlah', class: 'text-uppercase' },
          { key: 'dosisi', label: 'Dosis', class: 'text-uppercase' },
          { key: 'aturan_pakai', label: 'Aturan Pakai', class: 'text-uppercase' }
        ],
        nonResep: [
          { key: 'no_urut', label: 'No.', class: 'text-uppercase text-center' },
          { key: 'produk_nama', label: 'Tindakan', class: 'text-uppercase' }
        ]
      },
      selected: [],
      tableBusy: false,
      tableBusyB: false,
      fields: {
        table: [
          { key: 'no_urut', label: 'No.', class: 'text-uppercase text-center' },
          // { key: 'order_nomor', label: 'No. Order', class: 'text-uppercase' },
          { key: 'order_asal', label: 'Asal / Unit', class: 'text-uppercase' },
          { key: 'order_tujuan', label: 'Tujuan', class: 'text-uppercase' },
          { key: 'order_tanggal', label: 'Tanggal', class: 'text-uppercase' },
          { key: 'order_dpjp', label: 'Dpjp', class: 'text-uppercase' },
          { key: 'order_kelompoktrx', label: 'Kelompok', class: 'text-uppercase' }
        ],
        table2: []
      },
      items: {
        table: this.getTableLab,
        table2: []
      },
      currentPage: 1,
      totalRows: 100,
      perPage: 10
    }
  },
  mounted () {
    xray.index()
    this.getWindowHeight()
    window.addEventListener('resize', this.getWindowHeight)
  },
  destroyed () {
    window.removeEventListener('resize', this.getWindowHeight)
  },
  methods: {
    async getTableLab () {
      this.tableBusy = true
      try {
        const param1 = ``
        const param2 = `{"object":"SIMRS_EMR_RORDER","xparam0":"${this.$route.query.norm}","xparam1":"","xparam2":"","xparam3":"","xparam4":"","xparam5":"","xparam6":"","xparam7":"","xparam8":"","xparam9":""}`
        const response = await Services.middleware.find(param1, param2)
        this.tableBusy = false
        return response.data.data
      } catch (error) {
        this.tableBusy = false
        this.message = error
      }
    },
    async getDetailLab (item, index, event) {
      this.tableBusyB = true
      try {
        const param1 = ``
        const param2 = `{"object":"SIMRS_EMR_RORDER_RINC","xparam0":"${item.order_id}","xparam1":"","xparam2":"","xparam3":"","xparam4":"","xparam5":"","xparam6":"","xparam7":"","xparam8":"","xparam9":""}`
        const response = await Services.middleware.find(param1, param2)
        if (item.order_kelompoktrx === 'resep') {
          this.fields.table2 = this.response.resep
        } else {
          this.fields.table2 = this.response.nonResep
        }
        this.items.table2 = response.data.data
        this.tableBusyB = false
      } catch (error) {
        this.tableBusyB = false
        this.message = error
      }
    },
    getWindowHeight () {
      this.windowHeight = window.innerHeight
      var calc = (50 / 100) * this.windowHeight
      this.heightPercent = calc + 'px'
    },
    closeRightForm () {
      this.formOrder = '11'
      this.formDetail = '1'
    },
    openRightForm () {
      this.formOrder = '8'
      this.formDetail = '4'
    }
  }
}
</script>

<style>
.emr-riwayat-all .custom-scroller {
  position: relative;
  overflow-y: auto;
}

.emr-riwayat-all .radius-custom-emr {
  border-radius: .5rem;
}

.emr-riwayat-all .radius-custom-emr-head {
  border-top-left-radius: .5rem;
  border-top-right-radius: .5rem;
}

.emr-riwayat-all .radius-custom-form-head-eresep-daftar-order {
  border-top-right-radius: .5rem;
}

.emr-riwayat-all .eresep-daftar-order-fc-black {
  color: black;
  font-weight: bold;
}

.emr-riwayat-all .eresep-daftar-order-form-input {
  font-size: 13px;
  height: 33px!important;
  border-radius: 5px;
}

.emr-riwayat-all .table td {
    font-size: 13px;
    padding-top: 0px;
    padding-bottom: 0px;
}
.emr-riwayat-all .table th {
    font-size: 13px;
    font-weight: bold;
}

.emr-riwayat-all .table td, .emr-riwayat-all .table th {
    padding: 0.20rem!important;
}

.emr-riwayat-all .hover {
  transition: all .3s;
  cursor: pointer;
  background-color: rgb(255, 255, 255);
  color: rgb(107, 107, 107);
}

.emr-riwayat-all .arrow:hover .hover {
  background-color: rgba(0, 0, 0, 0.596);
  color: rgb(255, 255, 255);
}

.emr-riwayat-all .page-link {
  background: transparent !important;
  border: none !important;
  color: gray !important;
  margin-right: 15px;
}

.emr-riwayat-all .page-item.active .page-link {
  background-color: transparent;
  border-color: transparent;
  color: #089bab !important;
  font-weight: bold;
}
</style>
