<template>
  <b-container class="pendaftaran-form" fluid>
    <div v-show="isLoading">
      <div id="show-overlay"></div>
      <Loader :style="{ opacity: .5 }" />
    </div>
    <b-form>
      <iq-card id="data-identitas" class="pendaftaran-form-identitas bg-white shadow-sm border mb-4">
        <div class="py-3 px-3 head-title d-flex align-items-center justify-content-between">
          <div>
            <p class="h6 text-dark font-weight-light mb-0"><i class="fas fa-user-plus"></i> Pendaftaran Kunjungan Pasien <span class="font-weight-bold">NAMA PASIEN</span></p>
          </div>
          <div>
            <p class="small font-weight-bold text-dark font-italic mb-0"> [Nomor Pendaftaran]</p>
          </div>
        </div>
        <div class="body-form p-3">
          <div class="d-flex flex-wrap align-items-center">
            <div class="mr-3">
              <p class="h6 mb-0 text-dark">Tgl. Pendaftaran : </p>
            </div>
            <div class="mr-1">
              <b-form-group label-for="exampleInputdate" class="mb-0">
                <b-form-input id="exampleInputdate" type="date" value="2019-12-18"></b-form-input>
              </b-form-group>
            </div>
            <div class="mr-3">
              <b-form-group label-for="exampleInputtime" class="mb-0">
                <b-form-input id="exampleInputtime" type="time" value="13:45"></b-form-input>
              </b-form-group>
            </div>
            <div>
              <b-button variant="danger"><i class="fas fa-file-pdf"></i> General Consent</b-button>
            </div>
            <div class="ml-auto mr-3">
              <b-form-checkbox id="checkbox-1" v-model="status" name="checkbox-1" value="accepted" unchecked-value="not_accepted">
                Cito ?
              </b-form-checkbox>
            </div>
            <div>
              <b-form-checkbox id="checkbox-2" v-model="status" name="checkbox-2" value="accepted" unchecked-value="not_accepted">
                Resiko Jatuh ?
              </b-form-checkbox>
            </div>
            <div></div>
          </div>
        </div>
      </iq-card>
      <b-row>
        <b-col cols="12" lg="6">
          <iq-card id="data-identitas" class="pasien-form-identitas bg-white shadow-sm border mb-4">
            <div class="py-3 px-3 head-title">
              <p class="h6 text-dark mb-0"><i class="fas fa-home"></i> Tujuan Ke: </p>
            </div>
            <div class="body-form p-4">
              <b-row class="mb-4">
                <b-col cols="12">
                  <multiselect v-model="selected.provinsi" :class="[provinsiIsValid ? 'multiselect__tags_color_valid' : '']" :show-labels="false" :limit="3" @input="getkabupaten" label="deskripsi" placeholder="Pilih Instalasi" :options="combo.provinsi" :searchable="true">
                  </multiselect>
                </b-col>
              </b-row>
              <b-row class="mb-4">
                <b-col cols="12" lg="6">
                  <multiselect v-model="selected.provinsi" :class="[provinsiIsValid ? 'multiselect__tags_color_valid' : '']" :show-labels="false" :limit="3" @input="getkabupaten" label="deskripsi" placeholder="Pilih Unit" :options="combo.provinsi" :searchable="true">
                  </multiselect>
                </b-col>
                <b-col cols="12" lg="6">
                  <multiselect v-model="selected.kabupaten" :class="[kabupatenIsValid ? 'multiselect__tags_color_valid' : '']" :show-labels="false" :limit="3" @input="getkecamatan" label="deskripsi" placeholder="Pilih Ruangan" :options="combo.kabupaten" :searchable="true">
                  </multiselect>
                </b-col>
              </b-row>
              <b-row class="mb-4">
                <b-col cols="12" lg="6">
                  <multiselect v-model="selected.kecamatan" :class="[kecamatanIsValid ? 'multiselect__tags_color_valid' : '']" :show-labels="false" :limit="3" @input="getdesa" label="deskripsi" placeholder="Pilih SMF" :options="combo.kecamatan" :searchable="true">
                  </multiselect>
                </b-col>
                <b-col cols="12" lg="6">
                  <multiselect v-model="selected.desa" :class="[desaIsValid ? 'multiselect__tags_color_valid' : '']" :show-labels="false" :limit="3" @input="valWilayah" label="deskripsi" placeholder="Pilih Dokter" :options="combo.desa" :searchable="true">
                  </multiselect>
                </b-col>
              </b-row>
              <b-row class="mb-0">
                <b-col cols="12">
                  <multiselect v-model="selected.provinsi" :class="[provinsiIsValid ? 'multiselect__tags_color_valid' : '']" :show-labels="false" :limit="3" @input="getkabupaten" label="deskripsi" placeholder="Pilih Paket" :options="combo.provinsi" :searchable="true">
                  </multiselect>
                </b-col>
              </b-row>
            </div>
          </iq-card>
          <iq-card id="data-identitas" class="pasien-form-identitas bg-white shadow-sm border mb-4">
            <div class="d-flex flex-row justify-content-between align-items-center py-3 px-3 head-title">
              <div>
                <p class="h6 text-dark mb-0"><i class="fas fa-user-secret"></i> Penjamin</p>
              </div>
            </div>
            <div class="body-form p-4">
              <b-row class="mb-4">
                <b-col cols="12">
                  <multiselect v-model="selected.provinsi" :class="[provinsiIsValid ? 'multiselect__tags_color_valid' : '']" :show-labels="false" :limit="3" @input="getkabupaten" label="deskripsi" placeholder="Pilih Metode" :options="combo.provinsi" :searchable="true">
                  </multiselect>
                </b-col>
              </b-row>
              <b-row class="mb-4">
                <b-col cols="12">
                  <b-form-input v-model="pasien.identitas[0].alamat" :class="[alamatIdentitasIsValid ? 'form-control-valid' : '']" size="sm" type="text" placeholder="Nomor Kartu / Penjamin"></b-form-input>
                </b-col>
              </b-row>
              <b-row class="mb-4">
                <b-col cols="12" lg="8">
                  <b-form-input v-model="pasien.identitas[0].rt" :class="[rtIdentitasIsValid? 'form-control-valid' : '']" size="sm" type="text" placeholder="Nomor Kartu / Penjamin"></b-form-input>
                </b-col>
                <b-col cols="12" lg="4">
                  <multiselect v-model="selected.provinsi" :class="[provinsiIsValid ? 'multiselect__tags_color_valid' : '']" :show-labels="false" :limit="3" @input="getkabupaten" label="deskripsi" placeholder="Pilih Kelas" :options="combo.provinsi" :searchable="true">
                  </multiselect>
                </b-col>
              </b-row>
              <b-row class="mb-4">
                <b-col cols="12">
                  <b-form-input v-model="pasien.identitas[0].kodepos" :class="[kodeposIdentitasIsValid? 'form-control-valid' : '']" size="sm" type="text" placeholder="Nomor Surat Elegibiltas Peserta (SEP) / Klaim"></b-form-input>
                </b-col>
              </b-row>
              <b-row class="mb-0">
                <b-col cols="12">
                  <b-form-input v-model="pasien.identitas[0].kodepos" :class="[kodeposIdentitasIsValid? 'form-control-valid' : '']" size="sm" type="text" placeholder="Catatan/Keterangan"></b-form-input>
                </b-col>
              </b-row>
            </div>
          </iq-card>
          <iq-card id="data-identitas" class="pasien-form-identitas bg-white shadow-sm border">
            <div class="d-flex flex-row justify-content-between align-items-center py-3 px-3 head-title">
              <div>
                <p class="h6 text-dark mb-0"><i class="fas fa-user-secret"></i> Daftar Tindakan</p>
              </div>
            </div>
            <div class="body-form p-4">
            </div>
          </iq-card>
        </b-col>
        <b-col cols="12" lg="6">
          <iq-card id="data-identitas" class="pasien-form-identitas bg-white shadow-sm border mb-4">
            <div class="py-3 px-3 head-title">
              <p class="h6 text-dark mb-0"><i class="fas fa-envelope"></i> Rujukan Dari: </p>
            </div>
            <div class="body-form p-4">
              <b-row class="mb-4">
                <b-col cols="12" lg="8">
                  <b-form-input v-model="pasien.gelar_depan" :class="['my-input', gelarDepanIsValid ? 'form-control-valid' : '']" size="sm" type="text" placeholder="Masukan PPK"></b-form-input>
                </b-col>
                <b-col cols="12" lg="4" class="d-flex align-items-center">
                  <div class="bg-info text-center text-white radius-cstm shadow-sm py-2 px-4 w-100 hover">
                    <i class="fas fa-arrow-left"></i> Ambil Sebelumnya
                  </div>
                </b-col>
              </b-row>
              <b-row class="mb-4">
                <b-col cols="12" lg="8">
                  <b-form-input v-model="pasien.gelar_depan" :class="['my-input', gelarDepanIsValid ? 'form-control-valid' : '']" size="sm" type="text" placeholder="Nomor"></b-form-input>
                </b-col>
                <b-col cols="12" lg="4" class="d-flex align-items-center">
                  <b-form-group label-for="exampleInputdate" class="mb-0 w-100">
                    <b-form-input id="exampleInputdate" type="date" value="2019-12-18"></b-form-input>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row class="mb-4">
                <b-col cols="12" lg="6">
                  <multiselect v-model="selected.provinsi" :class="[provinsiIsValid ? 'multiselect__tags_color_valid' : '']" :show-labels="false" :limit="3" @input="getkabupaten" label="deskripsi" placeholder="Nama Dokter" :options="combo.provinsi" :searchable="true">
                  </multiselect>
                </b-col>
                <b-col cols="12" lg="6">
                  <multiselect v-model="selected.kabupaten" :class="[kabupatenIsValid ? 'multiselect__tags_color_valid' : '']" :show-labels="false" :limit="3" @input="getkecamatan" label="deskripsi" placeholder="Nama SMF" :options="combo.kabupaten" :searchable="true">
                  </multiselect>
                </b-col>
              </b-row>
              <b-row class="mb-0">
                <b-col cols="12" lg="6">
                  <multiselect v-model="selected.kecamatan" :class="[kecamatanIsValid ? 'multiselect__tags_color_valid' : '']" :show-labels="false" :limit="3" @input="getdesa" label="deskripsi" placeholder="Diagnosa Masuk" :options="combo.kecamatan" :searchable="true">
                  </multiselect>
                </b-col>
                <b-col cols="12" lg="6">
                  <multiselect v-model="selected.desa" :class="[desaIsValid ? 'multiselect__tags_color_valid' : '']" :show-labels="false" :limit="3" @input="valWilayah" label="deskripsi" placeholder="Masukkan / Pilih Kode ICD" :options="combo.desa" :searchable="true">
                  </multiselect>
                </b-col>
              </b-row>
              <b-row>
                <b-col></b-col>
                <b-col></b-col>
              </b-row>
            </div>
          </iq-card>
          <iq-card id="data-identitas" class="pasien-form-identitas bg-white shadow-sm border mb-4">
            <div class="py-3 px-3 head-title d-flex align-items-center justify-content-between">
              <div>
                <p class="h6 text-dark mb-0"><i class="fas fa-ambulance"></i> Kecelakaan</p>
              </div>
              <div>
                <b-form-checkbox id="checkbox-4" v-model="status4" @change="kecelakaan" name="checkbox-4" value="accepted" unchecked-value="not_accepted">
                </b-form-checkbox>
              </div>
            </div>
            <div class="body-form p-4">
              <div :class="kecelakaanClass">
                <b-row class="mb-4">
                  <b-col cols="12" lg="6">
                    <b-form-input v-model="pasien.gelar_depan" :class="['my-input', gelarDepanIsValid ? 'form-control-valid' : '']"
                      size="sm" type="text" placeholder="Keterangan"></b-form-input>
                  </b-col>
                  <b-col cols="12" lg="3" class="d-flex align-items-center">
                    <b-form-group label-for="exampleInputdate" class="mb-0 w-100">
                      <b-form-input id="exampleInputdate" type="date" value="2019-12-18"></b-form-input>
                    </b-form-group>
                  </b-col>
                  <b-col cols="12" lg="3" class="d-flex align-items-center">
                    <multiselect v-model="selected.kabupaten" :class="[kabupatenIsValid ? 'multiselect__tags_color_valid' : '']"
                      :show-labels="false" :limit="3" @input="getkecamatan" label="deskripsi" placeholder="Penjamin"
                      :options="combo.kabupaten" :searchable="true">
                    </multiselect>
                  </b-col>
                </b-row>
                <b-row class="mb-4">
                <b-col cols="12" lg="2" class="d-flex align-items-center">
                  <b-form-checkbox id="checkbox-3" v-model="status" name="checkbox-3" value="accepted" unchecked-value="not_accepted">
                    Suplesi ?
                  </b-form-checkbox>
                </b-col>
                <b-col cols="12" lg="10">
                  <b-form-input v-model="pasien.gelar_depan" :class="['my-input', gelarDepanIsValid ? 'form-control-valid' : '']" size="sm" type="text" placeholder="Masukan No.SEP Suplasi"></b-form-input>
                </b-col>
              </b-row>
              <b-row class="mb-4">
                <b-col cols="12" lg="4">
                  <multiselect v-model="selected.kecamatan" :class="[kecamatanIsValid ? 'multiselect__tags_color_valid' : '']" :show-labels="false" :limit="3" @input="getdesa" label="deskripsi" placeholder="Provinsi" :options="combo.kecamatan" :searchable="true">
                  </multiselect>
                </b-col>
                <b-col cols="12" lg="4">
                  <multiselect v-model="selected.desa" :class="[desaIsValid ? 'multiselect__tags_color_valid' : '']" :show-labels="false" :limit="3" @input="valWilayah" label="deskripsi" placeholder="Kecamatan" :options="combo.desa" :searchable="true">
                  </multiselect>
                </b-col>
                <b-col cols="12" lg="4">
                  <multiselect v-model="selected.desa" :class="[desaIsValid ? 'multiselect__tags_color_valid' : '']" :show-labels="false" :limit="3" @input="valWilayah" label="deskripsi" placeholder="Kabupaten" :options="combo.desa" :searchable="true">
                  </multiselect>
                </b-col>
              </b-row>
              </div>
            </div>
          </iq-card>
          <iq-card id="data-identitas" class="pasien-form-identitas bg-white shadow-sm border mb-4">
            <div class="py-3 px-3 head-title d-flex align-items-center justify-content-between">
              <div>
                <p class="h6 text-dark mb-0"><i class="fas fa-user"></i> Penanggung Jawab</p>
              </div>
            </div>
            <div class="body-form p-4">
              <div>
                <b-row class="mb-4">
                  <b-col cols="12" lg="8">
                    <multiselect v-model="selected.desa" :class="[desaIsValid ? 'multiselect__tags_color_valid' : '']" :show-labels="false" :limit="3" @input="valWilayah" label="deskripsi" placeholder="Hubungan Dengan Pasien" :options="combo.desa" :searchable="true">
                    </multiselect>
                  </b-col>
                  <b-col cols="12" lg="4" class="d-flex align-items-center">
                    <b-form-input v-model="pasien.gelar_depan" :class="['my-input', gelarDepanIsValid ? 'form-control-valid' : '']" size="sm" type="text" placeholder="Nama Tanpa Gelar"></b-form-input>
                  </b-col>
                </b-row>
                <b-row class="mb-4">
                  <b-col cols="12" lg="4">
                    <multiselect v-model="selected.kecamatan" :class="[kecamatanIsValid ? 'multiselect__tags_color_valid' : '']"
                      :show-labels="false" :limit="3" @input="getdesa" label="deskripsi" placeholder="Jenis Kelamin"
                      :options="combo.kecamatan" :searchable="true">
                    </multiselect>
                  </b-col>
                  <b-col cols="12" lg="4">
                    <multiselect v-model="selected.desa" :class="[desaIsValid ? 'multiselect__tags_color_valid' : '']"
                      :show-labels="false" :limit="3" @input="valWilayah" label="deskripsi" placeholder="Pendidikan"
                      :options="combo.desa" :searchable="true">
                    </multiselect>
                  </b-col>
                  <b-col cols="12" lg="4">
                    <multiselect v-model="selected.desa" :class="[desaIsValid ? 'multiselect__tags_color_valid' : '']"
                      :show-labels="false" :limit="3" @input="valWilayah" label="deskripsi" placeholder="Pekerjaan"
                      :options="combo.desa" :searchable="true">
                    </multiselect>
                  </b-col>
                </b-row>
                <b-row class="mb-4">
                  <b-col cols="12">
                    <b-form-input v-model="pasien.gelar_depan" :class="['my-input', gelarDepanIsValid ? 'form-control-valid' : '']" size="sm" type="text" placeholder="Masukan Alamat"></b-form-input>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col cols="12" lg="4">
                    <b-form-input v-model="pasien.gelar_depan" :class="['my-input', gelarDepanIsValid ? 'form-control-valid' : '']" size="sm" type="text" placeholder="Ex.0411-123456 / 0853456789"></b-form-input>
                  </b-col>
                  <b-col cols="12" lg="8">
                    <multiselect v-model="selected.desa" :class="[desaIsValid ? 'multiselect__tags_color_valid' : '']" :show-labels="false" :limit="3" @input="valWilayah" label="deskripsi" placeholder="Jenis Kontak" :options="combo.desa" :searchable="true">
                    </multiselect>
                  </b-col>
                </b-row>
              </div>
            </div>
          </iq-card>
        </b-col>
      </b-row>
      <iq-card>
        <div class="d-flex flex-row align-items-center justify-content-end px-3 py-2">
          <div class="m-1">
            <b-button variant="primary" @click="SimpanData" class=""><i class="fas fa-save"></i> Simpan</b-button>
          </div>
          <div class="m-1">
            <b-button variant="secondary" @click="backtopasien" class=""><i class="fas fa-undo"></i> Kembali</b-button>
          </div>
        </div>
      </iq-card>
    </b-form>
    <b-modal ref="modal-sukses" size="md" title="Informasi" ok-title="Oke" :ok-only=true :hide-header=true
      centered>
      <div class="text-center px-lg-4 px-2">
        <lottie :options="defaultOptions" :autoPlay="true" :width="250" :height="250" />
        <p class="h6 font-weight-bold text-success mb-0">Data Berhasil Disimpan</p>
      </div>
    </b-modal>
    <b-modal ref="modal-gagal" size="md" title="Informasi" ok-title="Kembali" :ok-only=true :hide-header=true
      centered>
      <div class="text-center px-lg-4 px-2">
        <lottie :options="defaultOptions2" :autoPlay="true" :width="250" :height="250" />
        <p class="h6 text-danger font-weight-bold mb-0">Data Gagal Disimpan</p>
      </div>
    </b-modal>
  </b-container>
</template>

<script>
import iqCard from '../../../components/xray/cards/iq-card.vue'
import { xray } from '../../../config/pluginInit'
import Multiselect from 'vue-multiselect'
import combo from '../../../services/combo'
import Pasien from '../../../services/pasien'
import Loader from '../../../components/xray/loader/Loader'
import Lottie from 'vue-lottie'
import animationLogo from '../../../assets/lottie/checked.json'
import animationLogo2 from '../../../assets/lottie/failed.json'

export default {
  name: 'ProfileEdit',
  components: {
    iqCard,
    Lottie,
    Loader,
    Multiselect
  },
  mounted () {
    xray.index()
    this.getjk()
    this.gettempatlahir()
    this.getagama()
    this.getkawin()
    this.getpendidikan()
    this.getpekerjaan()
    this.getgoldarah()
    this.getkewarganegaraan()
    this.getsuku()
    this.getstatuspasien()
    this.getprovinsi()
    this.getkartu()
    this.getkontak()
    this.getshdk()
  },
  data () {
    return {
      defaultOptions: { animationData: animationLogo, loop: true },
      defaultOptions2: { animationData: animationLogo2, loop: true },
      index: null,
      selectedtgllahir: '',
      status4: 'not_accepted',
      kecelakaanClass: 'd-block',
      combo: {
        tempatlahir: [],
        jeniskelamin: [],
        agama: [],
        perkawinan: [],
        pendidikan: [],
        pekerjaan: [],
        golonganDarah: [],
        kewarganegaraan: [],
        suku: [],
        statusPasien: [],
        provinsi: [],
        kabupaten: [],
        kecamatan: [],
        desa: [],
        jenis: [],
        kontak: [],
        shdk: []
      },
      selected: {
        tempat_lahir: [],
        jenis_kelamin: [],
        agama: [],
        status_perkawinan: [],
        pendidikan: [],
        pekerjaan: [],
        golongan_darah: [],
        kewarganegaraan: [],
        suku: [],
        status: [],
        provinsi: [],
        kabupaten: [],
        kecamatan: [],
        desa: [],
        jenis: [],
        identitas: {
          provinsi: [],
          kabupaten: [],
          kecamatan: [],
          desa: []
        },
        kontak: [{
          jenis: []
        }],
        kontakKeluarga: [{
          shdk: [],
          jenis: []
        }]
      },
      pasien: {
        nik: '',
        gelar_depan: '',
        nama: '',
        gelar_belakang: '',
        panggilan: '',
        tanggal_lahir: '',
        alamat: '',
        rt: '',
        rw: '',
        kodepos: '',
        oleh: JSON.parse(localStorage.getItem('user')).id,
        tempat_lahir: null,
        jenis_kelamin: null,
        agama: null,
        status_perkawinan: null,
        pendidikan: null,
        pekerjaan: null,
        golongan_darah: null,
        kewarganegaraan: null,
        suku: null,
        status: null,
        wilayah: null,
        identitas: [{
          jenis: null,
          nomor: null,
          alamat: '',
          rt: null,
          rw: null,
          kodepos: null,
          wilayah: null
        }],
        kontak: [{
          jenis: null,
          nomor: null
        }],
        kontakKeluarga: [{
          shdk: null,
          nama: '',
          alamat: '',
          jenis: null,
          nomor: null
        }]
      },
      isLoading: false,
      error: [],
      statuscheck: ''
    }
  },
  computed: {
    gelarDepanIsValid () {
      return (
        this.pasien.gelar_depan !== ''
      )
    },
    namaIsValid () {
      return (
        this.pasien.nama !== ''
      )
    },
    gelarBelakangIsValid () {
      return (
        this.pasien.gelar_belakang !== ''
      )
    },
    panggilanIsValid () {
      return (
        this.pasien.panggilan !== ''
      )
    },
    ktpIsValid () {
      return (
        this.pasien.nik !== ''
      )
    },
    tempatLahirIsValid () {
      return (
        this.pasien.tempat_lahir != null
      )
    },
    tgllahirIsValid () {
      return (
        this.pasien.tanggal_lahir !== ''
      )
    },
    jenisKelaminIsValid () {
      return (
        this.pasien.jenis_kelamin != null
      )
    },
    agamaIsValid () {
      return (
        this.pasien.agama != null
      )
    },
    perkawinanIsValid () {
      return (
        this.pasien.status_perkawinan != null
      )
    },
    pendidikanIsValid () {
      return (
        this.pasien.pendidikan != null
      )
    },
    pekerjaanIsValid () {
      return (
        this.pasien.pekerjaan != null
      )
    },
    darahIsValid () {
      return (
        this.pasien.golongan_darah != null
      )
    },
    kewarganegaraanIsValid () {
      return (
        this.pasien.kewarganegaraan != null
      )
    },
    sukuIsValid () {
      return (
        this.pasien.suku != null
      )
    },
    statusIsValid () {
      return (
        this.pasien.status != null
      )
    },
    alamatIsValid () {
      return (
        this.pasien.alamat !== ''
      )
    },
    rtIsValid () {
      return (
        this.pasien.rt !== ''
      )
    },
    rwIsValid () {
      return (
        this.pasien.rw !== ''
      )
    },
    kodeposIsValid () {
      return (
        this.pasien.kodepos !== ''
      )
    },
    jenisIsValid () {
      return (
        this.pasien.identitas[0].jenis != null
      )
    },
    nokartuIsValid () {
      return (
        this.pasien.identitas[0].nomor != null && this.pasien.identitas[0].nomor !== ''
      )
    },
    alamatIdentitasIsValid () {
      return (
        this.pasien.identitas[0].alamat !== '' && this.pasien.identitas[0].alamat != null
      )
    },
    rtIdentitasIsValid () {
      return (
        this.pasien.identitas[0].rt != null && this.pasien.identitas[0].rt !== ''
      )
    },
    rwIdentitasIsValid () {
      return (
        this.pasien.identitas[0].rw != null && this.pasien.identitas[0].rw !== ''
      )
    },
    kodeposIdentitasIsValid () {
      return (
        this.pasien.identitas[0].kodepos != null && this.pasien.identitas[0].kodepos !== ''
      )
    },
    jenisKontakIsValid () {
      return (
        this.pasien.kontak[0].jenis != null
      )
    },
    nomorKontakIsValid () {
      return (
        this.pasien.kontak[0].nomor != null && this.pasien.kontak[0].nomor !== ''
      )
    },
    hubunganKeluargaIsValid () {
      return (
        this.pasien.kontakKeluarga[0].shdk != null && this.pasien.kontakKeluarga[0].shdk !== ''
      )
    },
    namaKeluargaIsValid () {
      return (
        this.pasien.kontakKeluarga[0].nama != null && this.pasien.kontakKeluarga[0].nama !== ''
      )
    },
    alamatKeluargaIsValid () {
      return (
        this.pasien.kontakKeluarga[0].alamat != null && this.pasien.kontakKeluarga[0].alamat !== ''
      )
    },
    jenisKeluargaIsValid () {
      return (
        this.pasien.kontakKeluarga[0].jenis != null && this.pasien.kontakKeluarga[0].jenis !== ''
      )
    },
    nomorKeluargaIsValid () {
      return (
        this.pasien.kontakKeluarga[0].nomor != null && this.pasien.kontakKeluarga[0].nomor !== ''
      )
    },
    provinsiIsValid () {
      return (
        this.selected.provinsi.id != null && this.selected.provinsi.id !== ''
      )
    },
    kabupatenIsValid () {
      return (
        this.selected.kabupaten.id != null && this.selected.kabupaten.id !== ''
      )
    },
    kecamatanIsValid () {
      return (
        this.selected.kecamatan.id != null && this.selected.kecamatan.id !== ''
      )
    },
    desaIsValid () {
      return (
        this.selected.desa.id != null && this.selected.desa.id !== ''
      )
    },
    provinsiIdentitasIsValid () {
      return (
        this.selected.identitas.provinsi.id != null && this.selected.provinsi.id !== ''
      )
    },
    kabupatenIdentitasIsValid () {
      return (
        this.selected.identitas.kabupaten.id != null && this.selected.kabupaten.id !== ''
      )
    },
    kecamatanIdentitasIsValid () {
      return (
        this.selected.identitas.kecamatan.id != null && this.selected.kecamatan.id !== ''
      )
    },
    desaIdentitasIsValid () {
      return (
        this.selected.identitas.desa.id != null && this.selected.desa.id !== ''
      )
    }
  },
  methods: {
    kecelakaan () {
      if (this.status4 === 'accepted') {
        this.kecelakaanClass = 'd-none'
      } else {
        this.kecelakaanClass = 'd-block'
      }
    },
    autoFill () {
      if (this.statuscheck === 'accepted') {
        this.selected.identitas.provinsi = this.selected.provinsi
        this.selected.identitas.kabupaten = this.selected.kabupaten
        this.selected.identitas.kecamatan = this.selected.kecamatan
        this.selected.identitas.desa = this.selected.desa
        this.pasien.identitas[0].alamat = this.pasien.alamat
        this.pasien.identitas[0].rt = this.pasien.rt
        this.pasien.identitas[0].rw = this.pasien.rw
        this.pasien.identitas[0].kodepos = this.pasien.kodepos
        this.pasien.identitas[0].wilayah = this.pasien.wilayah
      } else {
        this.selected.identitas.provinsi = ''
        this.selected.identitas.kabupaten = ''
        this.selected.identitas.kecamatan = ''
        this.selected.identitas.desa = ''
        this.pasien.identitas[0].alamat = ''
        this.pasien.identitas[0].rt = ''
        this.pasien.identitas[0].rw = ''
        this.pasien.identitas[0].kodepos = ''
        this.pasien.identitas[0].wilayah = ''
      }
    },
    pad2 (n) {
      return (n < 10 ? '0' : '') + n
    },
    onContextTglLahir (ctx) {
      var date = new Date(ctx.selectedYMD)
      var month = this.pad2(date.getMonth() + 1)
      var day = this.pad2(date.getDate())
      var year = date.getFullYear()

      var formattedDate = day + '-' + month + '-' + year
      this.selectedtgllahir = formattedDate === 'NaN-NaN-NaN' ? ctx.selectedYMD : formattedDate
      this.pasien.tanggal_lahir = ctx.selectedYMD
    },
    addRow () {
      this.pasien.kontak.push(
        {
          jenis: null,
          nomor: ''
        }
      )
      this.selected.kontak.push(
        {
          jenis: []
        }
      )
    },
    deleteRow (index) {
      this.pasien.kontak.splice(index, 1)
      this.selected.kontak.splice(index, 1)
    },
    addRowB () {
      this.pasien.kontakKeluarga.push(
        {
          shdk: null,
          nama: '',
          alamat: '',
          jenis: null,
          nomor: null
        }
      )
      this.selected.kontakKeluarga.push(
        {
          shdk: [],
          jenis: []
        }
      )
    },
    deleteRowB (index) {
      this.pasien.kontakKeluarga.splice(index, 1)
      this.selected.kontakKeluarga.splice(index, 1)
    },
    // valselectoption start
    valTempatLahir (value) {
      this.pasien.tempat_lahir = value.id
    },
    valJenisKelamin (value) {
      this.pasien.jenis_kelamin = value.id_referensi
    },
    valAgama (value) {
      this.pasien.agama = value.id_referensi
    },
    valKawin (value) {
      this.pasien.status_perkawinan = value.id_referensi
    },
    valPendidikan (value) {
      this.pasien.pendidikan = value.id_referensi
    },
    valPekerjaan (value) {
      this.pasien.pekerjaan = value.id_referensi
    },
    valGolDarah (value) {
      this.pasien.golongan_darah = value.id_referensi
    },
    valKewarganegaraan (value) {
      this.pasien.kewarganegaraan = value.id
    },
    valSuku (value) {
      this.pasien.suku = value.id_referensi
    },
    valStatusPasien (value) {
      this.pasien.status = value.id_referensi
    },
    valWilayah (value) {
      this.pasien.wilayah = value.id
    },
    valKartu (value) {
      this.pasien.identitas[0].jenis = value.id_referensi
    },
    valWilayahIdentitas (value) {
      this.pasien.identitas[0].wilayah = value.id
    },
    valKontak (value) {
      const index = this.index
      this.pasien.kontak[index].jenis = value.id_referensi
    },
    // valselectoption end
    valShdk (value) {
      const index = this.index
      this.pasien.kontakKeluarga[index].shdk = value.id_referensi
    },
    valKeluargaKartu (value) {
      const index = this.index
      this.pasien.kontakKeluarga[index].jenis = value.id_referensi
    },
    // index add row select option
    ValIndex (index) {
      this.index = index
    },
    // combo start
    async gettempatlahir () {
      await combo.getalltemlahir().then(response => {
        if (response.status === 200) {
          this.combo.tempatlahir = response.data.tempatLahir
        }
      }).catch(error => {
        this.error = error.response
      })
    },
    async getjk () {
      await combo.getjeniskelamin().then(response => {
        if (response.status === 200) {
          this.combo.jeniskelamin = response.data.jenisKelamin
        }
      }).catch(error => {
        this.error = error.response
      })
    },
    async getagama () {
      await combo.getallagama().then(response => {
        if (response.status === 200) {
          this.combo.agama = response.data.agama
        }
      }).catch(error => {
        this.error = error.response
      })
    },
    async getkawin () {
      await combo.getallperkawinan().then(response => {
        if (response.status === 200) {
          this.combo.perkawinan = response.data.status
        }
      }).catch(error => {
        this.error = error.response
      })
    },
    async getpendidikan () {
      await combo.getallpendidikan().then(response => {
        if (response.status === 200) {
          this.combo.pendidikan = response.data.pendidikan
        }
      }).catch(error => {
        this.error = error.response
      })
    },
    async getpekerjaan () {
      await combo.getallpekerjaan().then(response => {
        if (response.status === 200) {
          this.combo.pekerjaan = response.data.pekerjaan
        }
      }).catch(error => {
        this.error = error.response
      })
    },
    async getgoldarah () {
      await combo.getallgolongandarah().then(response => {
        if (response.status === 200) {
          this.combo.golonganDarah = response.data.golonganDarah
        }
      }).catch(error => {
        this.error = error.response
      })
    },
    async getkewarganegaraan () {
      await combo.getallkewarganegaraan().then(response => {
        if (response.status === 200) {
          this.combo.kewarganegaraan = response.data.kewarganegaraan
        }
      }).catch(error => {
        this.error = error.response
      })
    },
    async getsuku () {
      await combo.getallsuku().then(response => {
        if (response.status === 200) {
          this.combo.suku = response.data.suku
        }
      }).catch(error => {
        this.error = error.response
      })
    },
    async getstatuspasien () {
      await combo.getallstatuspasien().then(response => {
        if (response.status === 200) {
          this.combo.statusPasien = response.data.statusPasien
        }
      }).catch(error => {
        this.error = error.response
      })
    },
    // alamat sekarang
    async getprovinsi () {
      await combo.getallprovinsi().then(response => {
        if (response.status === 200) {
          this.combo.provinsi = response.data.provinsi
        }
      }).catch(error => {
        this.error = error.response
      })
    },
    async getkabupaten (value) {
      const data = {
        id: value.id
      }
      await combo.getallkabupaten(data).then(response => {
        if (response.status === 200) {
          this.combo.kabupaten = response.data.kabupaten
        }
      }).catch(error => {
        this.error = error.response
      })
    },
    async getkecamatan (value) {
      const data = {
        id: value.id
      }
      await combo.getallkecamatan(data).then(response => {
        if (response.status === 200) {
          this.combo.kecamatan = response.data.kecamatan
        }
      }).catch(error => {
        this.error = error.response
      })
    },
    async getdesa (value) {
      const data = {
        id: value.id
      }
      await combo.getalldesa(data).then(response => {
        if (response.status === 200) {
          this.combo.desa = response.data.desa
        }
      }).catch(error => {
        this.error = error.response
      })
    },
    // kartu identitas
    async getkartu () {
      await combo.getallkartu().then(response => {
        if (response.status === 200) {
          this.combo.jenis = response.data.kartu
        }
      }).catch(error => {
        this.error = error.response
      })
    },
    // Kontak
    async getkontak () {
      await combo.getallkontak().then(response => {
        if (response.status === 200) {
          this.combo.kontak = response.data.kontak
        }
      }).catch(error => {
        this.error = error.response
      })
    },
    // Kontak Keluarga
    async getshdk () {
      await combo.getallshdk().then(response => {
        if (response.status === 200) {
          this.combo.shdk = response.data.shdk
        }
      }).catch(error => {
        this.error = error.response
      })
    },
    // combo end
    async SimpanData (e) {
      this.isLoading = true
      const data = this.pasien
      await Pasien.store(data)
        .then((response) => {
          if (response.status === 200) {
            this.isLoading = false
            this.$refs['modal-sukses'].show()
            this.clearForm()
          } else {
            this.isLoading = false
            this.$refs['modal-gagal'].show()
          }
        })
        .catch(() => {
          this.isLoading = false
          this.$refs['modal-gagal'].show()
        })
    },
    clearForm () {
      this.statuscheck = ''
      this.pasien.nik = ''
      this.pasien.gelar_depan = ''
      this.pasien.nama = ''
      this.pasien.gelar_belakang = ''
      this.pasien.tanggal_lahir = ''
      this.pasien.alamat = ''
      this.pasien.rt = ''
      this.pasien.rw = ''
      this.pasien.kodepos = ''
      // this.pasien.oleh: JSON.parse(localStorage.getItem('user')).id,
      this.pasien.tempat_lahir = null
      this.pasien.jenis_kelamin = null
      this.pasien.agama = null
      this.pasien.status_perkawinan = null
      this.pasien.pendidikan = null
      this.pasien.pekerjaan = null
      this.pasien.golongan_darah = null
      this.pasien.kewarganegaraan = null
      this.pasien.suku = null
      this.pasien.status = null
      this.pasien.wilayah = null

      this.pasien.identitas[0].jenis = null
      this.pasien.identitas[0].nomor = null
      this.pasien.identitas[0].alamat = ''
      this.pasien.identitas[0].rt = null
      this.pasien.identitas[0].rw = null
      this.pasien.identitas[0].kodepos = null
      this.pasien.identitas[0].wilayah = null

      this.pasien.kontak = [{
        jenis: null,
        nomor: null
      }]

      this.pasien.kontakKeluarga = [{
        shdk: null,
        nama: '',
        alamat: '',
        jenis: null,
        nomor: null
      }]

      this.selected.tempat_lahir = ''
      this.selected.jenis_kelamin = ''
      this.selected.agama = ''
      this.selected.status_perkawinan = ''
      this.selected.pendidikan = ''
      this.selected.pekerjaan = ''
      this.selected.golongan_darah = ''
      this.selected.kewarganegaraan = ''
      this.selected.suku = ''
      this.selected.status = ''
      this.selected.provinsi = ''
      this.selected.kabupaten = ''
      this.selected.kecamatan = ''
      this.selected.desa = ''
      this.selected.jenis = ''

      this.selected.identitas = {
        provinsi: '',
        kabupaten: '',
        kecamatan: '',
        desa: ''
      }
      this.selected.kontak = [{
        jenis: []
      }]
      this.selected.kontakKeluarga = [{
        shdk: [],
        jenis: []
      }]
    },
    backtopasien () {
      this.$router.replace({ path: `/pasien/list` })
    }
  }
}
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style>
  .pendaftaran-form-identitas .head-title {
    background: #ceebee;
    border-top-left-radius: 25px;
    border-top-right-radius: 25px;
  }
  .pendaftaran-form-identitas .pendaftaran-cstm-slctd{
    height: 45px!important;
    height: 45px!important;
    border-radius: 10px!important;
  }
  .pendaftaran-form .multiselect__tags {
    border-radius: 10px;
    height: 45px!important;
  }
  .pendaftaran-form .multiselect, .pendaftaran-form .multiselect__input, .pendaftaran-form .multiselect__single {
    font-size: 14px!important;
    color: black;
    font-weight: bold!important;
  }
  .pendaftaran-form .multiselect__tags_color_valid {
      border: 2px solid #28a745!important;
      border-radius: 10px;
  }
  .pendaftaran-form .multiselect__tags_color_notvalid {
      border: 2px solid #cf0000!important;
      border-radius: 10px;
  }
  .pendaftaran-form .form-control-valid {
    border: 2px solid!important;
    border-color: #28a745!important;
  }
  .pendaftaran-form .form-control-notvalid {
    border: 2px solid!important;
    border-color: #cf0000!important;
  }
  .pendaftaran-form .form-control {
    color: black!important;
    font-weight: bold!important;
  }
  .pendaftaran-form .radius-cstm {
    border-radius: 10px;
  }
  .pendaftaran-form .hover {
    cursor: pointer;
  }
</style>
