const convertToDMY = (date) => {
  const parsedDate = new Date(date)

  const day = parsedDate.getDate()
  const month = parsedDate.getMonth() + 1
  const year = parsedDate.getFullYear()

  const dayStr = day < 10 ? '0' + day : day
  const monthStr = month < 10 ? '0' + month : month

  return `${dayStr}-${monthStr}-${year}`
}

const convertToRupiah = (number) => {
  const formatter = new Intl.NumberFormat('id-ID', {
    style: 'currency',
    currency: 'IDR',
    minimumFractionDigits: 0
  })

  return formatter.format(number)
}

const convertToAge = (dateOfBirth) => {
  const birthDate = new Date(dateOfBirth)
  const currentDate = new Date()

  let age = currentDate.getFullYear() - birthDate.getFullYear()

  const monthDiff = currentDate.getMonth() - birthDate.getMonth()
  if (monthDiff < 0 || (monthDiff === 0 && currentDate.getDate() < birthDate.getDate())) {
    age--
  }

  return age
}

export default {
  convertToDMY,
  convertToAge,
  convertToRupiah
}
