<template>
  <div>
    <b-container class="mb-5 pt-5">
      <b-row>
        <b-col md="12">
          <div class="batas-wadah">
            <div class="wadah-konten bg-primary-transprnt shadow pt-5 pb-3 pl-3 pr-3 rounded border-solid">
              <b-row class="justify-content-start mb-4">
                <b-col md="6" v-for="i in nakes" :key="i.id">
                  <iq-card class="mb-0">
                    <template v-slot:headerTitle>
                      <h4 class="font-weight-bold">{{ i.title }}</h4>
                    </template>
                    <template v-slot:body>
                      <ApexChart :element="i.type" :chartOption="i.bodyData" />
                    </template>
                  </iq-card>
                  <div class="text-center">
                    <b-button v-b-modal.modal-nakes variant="light" class="btn-white-sdm text-uppercase mt-2"><i
                        class="far fa-eye"></i> Detail Data
                      Nakes</b-button>
                  </div>
                </b-col>
                <b-col md="6">
                  <lottie :options="defaultOptions" :autoPlay="true" :width="350" :height="350" />
                </b-col>
              </b-row>
              <b-row class="justify-content-center">
                <b-col md="6" v-for="i in str" :key="i.id">
                  <iq-card class="mb-0">
                    <template v-slot:headerTitle>
                      <h4 class="font-weight-bold">{{ i.title }}</h4>
                    </template>
                    <template v-slot:body>
                      <ApexChart :element="i.type" :chartOption="i.bodyData" />
                    </template>
                  </iq-card>
                  <div class="text-center">
                    <b-button v-b-modal.modal-str variant="light" class="btn-white-sdm text-uppercase mt-2"><i
                        class="far fa-eye"></i> Detail Surat
                      Tanda Registrasi</b-button>
                  </div>
                </b-col>
                <b-col md="6" v-for="i in sip" :key="i.id">
                  <iq-card class="mb-0">
                    <template v-slot:headerTitle>
                      <h4 class="font-weight-bold">{{ i.title }}</h4>
                    </template>
                    <template v-slot:body>
                      <ApexChart :element="i.type" :chartOption="i.bodyData" />
                    </template>
                  </iq-card>
                  <div class="text-center">
                    <b-button v-b-modal.modal-sip variant="light" class="btn-white-sdm text-uppercase mt-2"><i
                        class="far fa-eye"></i> Detail Surat
                      Izin Praktek</b-button>
                  </div>
                </b-col>
              </b-row>
            </div>
          </div>
        </b-col>
      </b-row>
    </b-container>
    <b-modal id="modal-nakes" centered title="Data Nakes" size="lg ">
      <b-table id="my-table" :items="items" :fields="fields1" :per-page="perPage" :current-page="currentPage" small></b-table>
      <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage"></b-pagination>
    </b-modal>
    <b-modal id="modal-str" centered title="Surat Tanda Izin Registrasi" size="lg ">
      <b-table id="my-table" :items="items" :fields="fields2" :per-page="perPage" :current-page="currentPage" small></b-table>
      <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage" aria-controls="my-table"></b-pagination>
    </b-modal>
    <b-modal id="modal-sip" centered title="Surat Izin Praktek" size="lg ">
      <b-table id="my-table" :items="items" :fields="fields3" :per-page="perPage" :current-page="currentPage" small></b-table>
      <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage" aria-controls="my-table"></b-pagination>
    </b-modal>
  </div>
</template>
<script>

import Lottie from 'vue-lottie'
import animationLogo from '../../assets/lottie/sdm-img.json'
import Dashboard from '../../services/fatmahost/dashboard/index'

export default {
  name: 'DashboardSDM',
  components: {
    Lottie
  },
  data () {
    return {
      defaultOptions: { animationData: animationLogo, loop: true },
      currentPage: 0,
      perPage: 10,
      totalRows: 0,
      items: this.detailstrsip,
      fields1: [
        { label: 'Nama', key: 'NAMA', sortable: true },
        { label: 'Jabatan', key: 'DESKRIPSI', sortable: true }
      ],
      fields2: [
        { label: 'Nama', key: 'NAMA', sortable: true },
        { label: 'Berlaku STR', key: 'BERLAKU_STR', sortable: true }
      ],
      fields3: [
        { label: 'Nama', key: 'NAMA', sortable: true },
        { label: 'Berlaku SIP', key: 'BERLAKU_SIP', sortable: true }
      ],
      nakes: [
        {
          id: 1,
          title: 'DATA NAKES',
          type: 'pie',
          bodyData: {
            chart: {
              width: 380,
              type: 'pie'
            },
            labels: ['Dokter', 'Perawat', 'Radiografer', 'Nutrisionis', 'Bidan'],
            series: [71, 21, 4, 1, 3],
            colors: ['#0084ff', '#00ca00', '#e64141', '#ffd400', '#00d0ff'],
            responsive: [{
              breakpoint: 480,
              options: {
                chart: {
                  width: 200
                },
                legend: {
                  position: 'bottom'
                }
              }
            }]
          }
        }
      ],
      str: [
        {
          title: 'SURAT TANDA REGISTRASI',
          type: 'column',
          bodyData: {
            chart: {
              height: 350,
              type: 'bar'
            },
            plotOptions: {
              bar: {
                horizontal: false,
                columnWidth: '55%',
                endingShape: 'rounded'
              }
            },
            dataLabels: {
              enabled: false
            },
            stroke: {
              show: true,
              width: 2,
              colors: ['transparent']
            },
            colors: ['#0084ff', '#00ca00', '#ffc107'],
            series: [{
              name: 'Jumlah',
              data: [1480, 700]
            }],
            xaxis: {
              categories: ['STR AKTIF', 'STR EXPIRED']
            },
            fill: {
              opacity: 1

            },
            tooltip: {
              y: {
                formatter: function (val) {
                  return val + ' orang'
                }
              }
            }
          }
        }
      ],
      sip: [
        {
          id: 3,
          title: 'SURAT IZIN PRAKTEK',
          type: 'bar',
          bodyData: {
            chart: {
              height: 350,
              type: 'bar'
            },
            plotOptions: {
              bar: {
                horizontal: true
              }
            },
            dataLabels: {
              enabled: false
            },
            series: [{
              data: [1480, 1000],
              name: 'Jumlah'
            }],
            xaxis: {
              categories: ['SIP AKTIF', 'SIP EXPIRED']
            }
          }
        }
      ],
      error: ''
    }
  },
  mounted () {
    this.Stp()
  },
  computed: {
  },
  methods: {
    async detailstrsip (ctx) {
      try {
        const detail = await Dashboard.dash_sdm_detail_strsip(ctx.currentPage - 1, ctx.perPage)
        this.totalRows = detail.data.pagination.total
        this.perPage = detail.data.pagination.perPage
        this.currentPage = detail.data.pagination.current
        return detail.data.data
      } catch (err) {
        this.error = err
      }
    },
    async Stp () {
      try {
        const res = await Dashboard.dash_sdm_strsip()
        this.cobaSip = res.data.rekapSip[0].SIP_NONAKTIF
        this.sip[0].bodyData.series[0].data = [res.data.rekapSip[0].SIP_AKTIF, res.data.rekapSip[0].SIP_NONAKTIF]
        this.str[0].bodyData.series[0].data = [res.data.rekapStr[0].STR_AKTIF, res.data.rekapStr[0].STR_NONAKTIF]
        this.nakes[0].bodyData.series[0] = res.data.rekapNakes[6].TOTAL
        this.nakes[0].bodyData.series[1] = res.data.rekapNakes[14].TOTAL
        this.nakes[0].bodyData.series[2] = res.data.rekapNakes[4].TOTAL
        this.nakes[0].bodyData.series[3] = res.data.rekapNakes[12].TOTAL
        this.nakes[0].bodyData.series[4] = res.data.rekapNakes[13].TOTAL
      } catch (err) {
        this.error = err
      }
    }
  }
}
</script>
<style>
@import '../../assets/css/pages/public/dashboard-sdm.css';
</style>
