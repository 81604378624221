import Api from '../axiosInstance'
const version = '/v1'
export default {
  findAll (currentPage, perPage, filter) {
    return Api.log.get(`${version}/practitioner/trxLogBackend?param0=trx-log&param1=${currentPage}&param2=${perPage}&param3=${filter}`)
  },
  findId (id, key) {
    return Api.log.get(`${version}/practitioner/trxLogBackend/${id}?param0=${key}`)
  },
  create (data) {
    return Api.log.post(`${version}/practitioner/trxLogBackend`, data)
  },
  find (obj) {
    return Api.log.get(`${version}/practitioner/monitDatabase?param0=${obj}`)
  }
}
