<template>
  <div id="mhas" class="mb-4 pb-1">
    <div v-show="isLoading">
        <div id="show-overlay"></div>
      <Loader :style="{ opacity: .5 }" />
    </div>
    <b-container fluid>
      <div class="letter-spacing-custom mb-4">
        <p class="text-uppercase text-dark text-center font-weight-bold h5 mb-0">Hospital Application Solution</p>
      </div>
      <div class="letter-spacing-custom mt-2">
        <b-row>
          <b-col lg="6" class="pr-lg-1">
            <div class="bg-white shadow-sm radius-top-custom radius-bottom-custom h-100">
              <div class="bg-color-mandiri-low radius-top-custom border px-3 py-2">
                <p class="text-dark mb-0">Pencarian</p>
              </div>
              <div class="radius-bottom-custom px-3 py-4">
                <b-form-group>
                  <b-form-radio-group
                  class=""
                    id="radio-group-1"
                    v-model="selected.radio"
                    :options="options.radio"
                    name="radio-options"
                    @change="valueRadio"
                  ></b-form-radio-group>
                </b-form-group>
                <b-form-group>
                  <div v-if="valueLabel === '1'">
                    <b-form-input class="text-dark form-control-custom-mhas" size="sm" v-model="formleftTop.regNo" :placeholder="label" @keyup.enter="normMhas"></b-form-input>
                  </div>
                  <div v-else>
                    <b-row>
                      <b-col class="pr-1">
                        <flat-pickr :config="config" v-model="previewTanggal" value="" @on-change="valueTanggal($event)" placeholder="Range Tanggal" class="w-100 calender-small custom-flat-pickr m-0">
                        </flat-pickr>
                      </b-col>
                      <b-col class="pl-1">
                        <b-form-input class="text-dark form-control-custom-mhas" size="sm" v-model="formleftTop.rmNo" :placeholder="label" @keyup.enter="normMhas"></b-form-input>
                      </b-col>
                    </b-row>
                  </div>
                </b-form-group>
                <b-form-group class="text-right">
                  <b-button variant="light" size="sm" class=" mr-2 border" @click="resetForm"><i class="fas fa-sync-alt"></i> Ulang</b-button>
                  <b-button class=" bg-primary" variant="primary" size="sm" @click="normMhas"><i class="fas fa-search"></i> Cari</b-button>
                </b-form-group>
              </div>
            </div>
          </b-col>
          <b-col lg="6" class="pl-lg-1 mt-2 mt-lg-0">
            <div class="bg-white shadow-sm radius-top-custom radius-bottom-custom h-100">
              <div class="bg-color-mandiri-low radius-top-custom border px-3 py-2 ">
                <p class="text-dark mb-0">Detail</p>
              </div>
              <div class="px-3 py-2 radius-bottom-custom">
                <div class="d-flex flex-row align-items-center mb-4">
                  <div class="mr-3">
                    <b-img :src="require('@/assets/images/mandiri-image.svg')" width="100rem" fluid alt="Responsive image"></b-img>
                  </div>
                  <div>
                    <p class="h5 text-dark mb-0" v-if="formRightTop.pasienName">{{ formRightTop.pasienName }}</p>
                    <p class="h5 text-dark mb-0" v-else>-</p>
                  </div>
                </div>
                <div class="border-bottom mb-3">
                  <p class="small  mb-0">Nomor Rekam Medis</p>
                  <p class="text-dark  mb-0" v-if="formRightTop.rmNo">{{ formRightTop.rmNo }}</p>
                  <p class="text-dark  mb-0" v-else>-</p>
                </div>
                <div class="border-bottom mb-3">
                  <p class="small  mb-0">Tanggal Lahir</p>
                  <p class="text-dark  mb-0" v-if="formRightTop.dob">{{ formRightTop.dob.split('-').reverse().join('-') }}</p>
                  <p class="text-dark  mb-0" v-else>-</p>
                </div>
                <div class="border-bottom mb-4">
                  <p class="small  mb-0">Jenis Kelamin</p>
                  <p class="text-dark  mb-0" v-if="formRightTop.gender">{{ formRightTop.gender === 'L' ? 'Laki-Laki' : formRightTop.gender === 'P' ? 'Perempuan' : '' }}</p>
                  <p class="text-dark  mb-0" v-else>-</p>
                </div>
              </div>
            </div>
          </b-col>
        </b-row>
        <b-row class="mt-2">
          <b-col lg="12">
            <div class="shadow-sm border px-3 py-2 radius-top-custom bg-color-mandiri-low">
              <p class="text-dark  mb-0">Tagihan</p>
            </div>
            <div class="bg-white shadow-sm radius-bottom-custom px-3 py-3">
              <div class="d-flex flex-row justify-content-between mb-2">
                <!-- <div class="mr-2">
                  <b-form-select class="" v-model="selected.selectize" :options="options.selectize" size="sm"></b-form-select>
                </div>
                <div class="mr-2">
                  <b-form-input class=" text-dark form-control-custom-mhas" size="sm" placeholder="Aprroval Code"></b-form-input>
                </div> -->
                <div>
                  <div>
                    <p class="text-dark mb-0"><i class="fas fa-history"></i> History Pembayaran</p>
                  </div>
                </div>
              </div>
              <div class="table-responsive">
                <table class="shadow-sm border table bg-white table-sm m-0 p-0">
                  <thead class="bg-color-mandiri-low">
                    <b-tr>
                      <b-th variant="white" class="py-1 text-uppercase font-weight-normal pl-3">trx no</b-th>
                      <b-th variant="white" class="py-1 text-uppercase font-weight-normal">No. RM</b-th>
                      <b-th variant="white" class="py-1 text-uppercase font-weight-normal">nama dokter</b-th>
                      <b-th variant="white" class="py-1 text-uppercase font-weight-normal">layanan</b-th>
                      <b-th variant="white" class="py-1 text-uppercase font-weight-normal">unit layanan</b-th>
                      <b-th variant="white" class="py-1 text-uppercase font-weight-normal">Tagihan</b-th>
                      <b-th variant="white" class="py-1 text-uppercase font-weight-normal">Terbayar</b-th>
                      <b-th variant="white" class="py-1 text-uppercase font-weight-normal">Kuitansi</b-th>
                      <b-th variant="white" class="py-1 text-uppercase font-weight-normal">Tanggal</b-th>
                      <b-th variant="white" class="py-1 text-uppercase font-weight-normal text-right pr-3">Aksi</b-th>
                    </b-tr>
                  </thead>
                  <tbody>
                    <b-tr v-for="(fa, index) in formArr" v-bind:key="fa.x">
                      <b-td class="pl-3">
                        <div>
                          <p class="text-custpm-tabel mb-0" v-if="fa.trxNo">{{ fa.trxNo }}</p>
                          <p class="text-custpm-tabel mb-0" v-else>-</p>
                        </div>
                      </b-td>
                      <b-td>
                        <div>
                          <p class="text-custpm-tabel mb-0" v-if="fa.rmNo">{{ fa.rmNo }}</p>
                          <p class="text-custpm-tabel mb-0" v-else>-</p>
                        </div>
                      </b-td>
                      <b-td>
                        <div>
                          <p class="text-custpm-tabel mb-0" v-if="fa.namaDokter">{{ fa.namaDokter }}</p>
                          <p class="text-custpm-tabel mb-0" v-else>-</p>
                        </div>
                      </b-td>
                      <b-td>
                        <div>
                          <p class="text-custpm-tabel mb-0" v-if="fa.additional2">{{ fa.additional2 }}</p>
                          <p class="text-custpm-tabel mb-0" v-else>-</p>
                        </div>
                      </b-td>
                      <b-td>
                        <div>
                          <p class="text-custpm-tabel mb-0" v-if="fa.additional1">{{ fa.additional1 }}</p>
                          <p class="text-custpm-tabel mb-0" v-else>-</p>
                        </div>
                      </b-td>
                      <b-td>
                        <div>
                          <span v-if="fa.paymentBill !== ''"><p class="text-custpm-tabel mb-0">Rp. {{ formatRibuan(fa.paymentBill) }}</p></span>
                          <span v-else><p class="text-custpm-tabel mb-0">-</p></span>
                        </div>
                      </b-td>
                      <b-td>
                        <div>
                          <p class="text-custpm-tabel text-primary mb-0" v-if="fa.paidFlag === '1'"><i class="fas fa-check-circle"></i> Terbayar</p>
                          <p class="text-custpm-tabel text-dark mb-0" v-else><i class="fas fa-spinner"></i> Kosong</p>
                        </div>
                      </b-td>
                      <b-td>
                        <div>
                          <p class="text-custpm-tabel mb-0" v-if="fa.noKuitansi">{{ fa.noKuitansi }}</p>
                          <p class="text-custpm-tabel mb-0" v-else>-</p>
                        </div>
                      </b-td>
                      <b-td>
                        <div>
                          <p class="text-custpm-tabel mb-0" v-if="fa.regDate2">{{ fa.regDate2 }}</p>
                          <p class="text-custpm-tabel mb-0" v-else>-</p>
                        </div>
                      </b-td>
                      <b-td class="pr-3">
                        <div class="text-right" v-if="fa.pasienName">
                          <b-button :disabled="fa.paidFlag === '1' ? true : false" class="m-1" :variant="fa.paidFlag === '1' ? 'secondary' : 'primary'" size="sm" @click="progress(index, formArr[index].pasienName, formArr[index].rmNo, formArr[index].regNo, formArr[index].additional1, formArr[index].namaDokter, formArr[index].paymentBill)"><i class="fas fa-sign-in-alt"></i></b-button>
                          <b-button :disabled="fa.cancelFlag === '0' && fa.paidFlag === '1' ? false : true" class="m-1" :variant="fa.cancelFlag === '0' && fa.paidFlag === '1' ? 'danger' : 'secondary'" size="sm" @click="cancelPreview(fa.regNo, fa.rmNo, fa.pasienName, fa.regDate, fa.jenisPelayananId, fa.paymentTp, fa.trxNo, fa.noKuitansi, fa.paidFlag, fa.paymentBill, fa.newPaymentBill, fa.namaDokter, fa.additional1, fa.additional2, fa.additional3)"><i class="fas fa-times-circle"></i></b-button>
                          <b-button :disabled="fa.paidFlag === '1' ? false : true" class="m-1" :variant="fa.paidFlag === '1' ? 'warning' : 'secondary'" size="sm" @click="printPreview(fa.noKuitansi, fa.namaDokter)"><i class="fas fa-print"></i></b-button>
                        </div>
                        <div class="text-right" v-else>
                          -
                        </div>
                      </b-td>
                    </b-tr>
                  </tbody>
                </table>
              </div>
              <div v-if="messageModal === false">
                <div :class="['text-right mt-3 px-1 shadow-sm', messageValue === '1' ? 'bg-primary' : 'bg-danger']">
                  <p class="text-dark text-white mb-0">{{ message }}</p>
                </div>
              </div>
            </div>
          </b-col>
        </b-row>
      </div>
    </b-container>
    <b-modal ref="modal-proses" size="lg" title="PROSES PEMBAYARAN" ok-title="Oke" :ok-only="true" :hide-footer="true" :no-close-on-backdrop="true" :static="true" :no-close-on-esc="true" @close="clearModal" centered>
      <div class="p-2">
        <div class="letter-spacing-custom">
          <div class="shadow-sm radius-custom p-4 mb-4 bg-color-mandiri-low">
            <b-row>
              <b-col lg="6">
                <div class="mb-0">
                  <div class="border-bottom mb-3">
                    <p class="small text-secondary mb-0">Nama</p>
                    <p class="text-dark mb-0">{{ preview.pasienName }}</p>
                  </div>
                  <div class="border-bottom mb-3">
                    <p class="small text-secondary mb-0">Nomor Rekam Medis</p>
                    <p class="text-dark mb-0">{{ preview.rmNo }}</p>
                  </div>
                  <div class="border-bottom">
                    <p class="small text-secondary mb-0">Nomor Registrasi</p>
                    <p class="text-dark mb-0">{{ preview.regNo }}</p>
                  </div>
                </div>
              </b-col>
              <b-col lg="6">
                <div class="mb-0">
                  <div class="border-bottom mb-3">
                    <p class="small text-secondary mb-0">Unit Layanan</p>
                    <p class="text-dark mb-0">{{ preview.additional1 }}</p>
                  </div>
                  <div class="border-bottom mb-3">
                    <p class="small text-secondary mb-0">Dokter</p>
                    <p class="text-dark mb-0">{{ preview.namaDokter }}</p>
                  </div>
                  <div class="border-bottom">
                    <p class="small text-secondary mb-0">Tagihan</p>
                    <p class="text-dark h5 font-weight-bold mb-0">Rp {{ formatRibuan(preview.paymentBill) }}</p>
                  </div>
                </div>
              </b-col>
            </b-row>
          </div>
          <div class="shadow-sm radius-custom p-4 mb-4">
            <b-row>
              <b-col lg="6" class="pr-1 mb-2">
                <b-form-group label="Metode Bayar" class="mb-2">
                  <b-form-select value-field="metodebayarid" text-field="nama" :options="options.caraBayar" v-model="formArr[indexProgress].metodeBayarId" size="sm"></b-form-select>
                </b-form-group>
              </b-col>
              <b-col lg="6" class="pl-1">
                <b-form-group label="Jenis Bayar" class="mb-0">
                  <b-form-select disabled :options="options.caraBayarTp" v-model="formArr[indexProgress].paymentTp" size="sm"></b-form-select>
                </b-form-group>
              </b-col>
              <b-col lg="6" class="pr-1">
                <b-form-group label="Pembayar" class="mb-0">
                  <b-form-input v-model="formArr[indexProgress].pembayar" class="text-dark form-control-custom-mhas" size="sm" placeholder="Pembayar..."></b-form-input>
                </b-form-group>
              </b-col>
            </b-row>
          </div>
          <div class="text-center">
            <b-button size="sm" variant="primary" class="shadow-sm py-2" @click="onSimpan"><i class="fas fa-save"></i> Simpan Pembayaran</b-button>
          </div>
        </div>
        <div v-if="messageModal === true">
          <div :class="['text-right mt-3 px-1 shadow-sm', messageValue === '1' ? 'bg-primary' : 'bg-danger']">
            <p class="text-dark text-white mb-0">{{ message }}</p>
          </div>
        </div>
      </div>
    </b-modal>
    <b-modal ref="modal-preview-print" :size="cetakModalCond === true ? 'lg' : 'md'" title="CETAK PEMBAYARAN" ok-title="Oke" :ok-only="true" :hide-footer="true" :no-close-on-backdrop="true" :static="true" :no-close-on-esc="true" centered>
      <div class="p-2 letter-spacing-custom">
        <div v-if="cetakModalCond === true">
          <b-row>
            <b-col lg="6">
              <div class="mb-0">
                <div class="border-bottom mb-3">
                  <p class="small text-secondary mb-0">Tanggal Registrasi</p>
                  <p class="text-dark mb-0">{{ formCetak.regdate.split('-').reverse().join('-') }}</p>
                </div>
                <div class="border-bottom mb-3">
                  <p class="small text-secondary mb-0">Nomor Registrasi</p>
                  <p class="text-dark mb-0">{{ formCetak.regNo }}</p>
                </div>
                <div class="border-bottom mb-3">
                  <p class="small text-secondary mb-0">Nomor Rekam Medis</p>
                  <p class="text-dark mb-0">{{ formCetak.rmNo }}</p>
                </div>
                <div class="border-bottom mb-0">
                  <p class="small text-secondary mb-0">Nomor Kuitansi</p>
                  <p class="text-dark mb-0">{{ formCetak.nokuitansi }}</p>
                </div>
              </div>
            </b-col>
            <b-col lg="6">
              <div class="mb-0">
                <div class="border-bottom mb-3">
                  <p class="small text-secondary mb-0">Nama</p>
                  <p class="text-dark mb-0">{{ formCetak.pasienName }}</p>
                </div>
                <div class="border-bottom mb-3">
                  <p class="small text-secondary mb-0">Unit Layanan</p>
                  <p class="text-dark mb-0">{{ formCetak.additional1 }}</p>
                </div>
                <div class="border-bottom mb-3">
                  <p class="small text-secondary mb-0">Dokter</p>
                  <p class="text-dark mb-0">{{ formCetak.namaDokter }}</p>
                </div>
                <div class="border-bottom">
                  <p class="small text-secondary mb-0">Tagihan</p>
                  <p class="text-dark h5 font-weight-bold mb-0">Rp {{ formatRibuan(formCetak.paymentbill) }}</p>
                </div>
              </div>
            </b-col>
          </b-row>
          <div class="mt-2 text-center">
            <b-button class="mt-3" @click="print" variant="warning" size="sm"><i class="fas fa-print"></i> Cetak Pembayaran</b-button>
          </div>
        </div>
        <div class="pt-3 pb-4" v-else>
          <div class="text-center">
            <b-img :src="require('@/assets/images/print.svg')" width="200rem" fluid alt="Responsive image"></b-img>
            <p class="h5 mb-0 text-uppercase text-dark mt-5">gagal cetak pembayaran</p>
          </div>
        </div>
      </div>
    </b-modal>
    <b-modal ref="modal-preview-cancel" size="lg" title="PEMBATALAN KASIR" ok-title="Oke" :ok-only="true" :hide-footer="true" :no-close-on-backdrop="true" :static="true" :no-close-on-esc="true" centered @close="clearMessage">
      <div class="p-2 letter-spacing-custom">
        <div class="shadow-sm radius-custom p-4 mb-4 bg-color-mandiri-low">
          <b-row>
            <b-col lg="6">
              <div class="mb-0">
                <div class="border-bottom mb-3">
                  <p class="small text-secondary mb-0">Tanggal Registrasi</p>
                  <p class="text-dark mb-0">
                    <span v-if="formBatal.regDate">{{ formBatal.regDate.split('-').reverse().join('-') }}</span>
                    <span v-else>-</span>
                  </p>
                </div>
                <div class="border-bottom mb-3">
                  <p class="small text-secondary mb-0">Nomor Registrasi</p>
                  <p class="text-dark mb-0">
                    <span v-if="formBatal.regNo">{{ formBatal.regNo }}</span>
                    <span v-else>-</span>
                  </p>
                </div>
                <div class="border-bottom mb-3">
                  <p class="small text-secondary mb-0">Nomor Trx</p>
                  <p class="text-dark mb-0">
                    <span v-if="formBatal.trxNo">{{ formBatal.trxNo }}</span>
                    <span v-else>-</span>
                  </p>
                </div>
                <div class="border-bottom mb-3">
                  <p class="small text-secondary mb-0">Nomor Kuitansi</p>
                  <p class="text-dark mb-0">
                    <span v-if="formBatal.noKuitansi">{{ formBatal.noKuitansi }}</span>
                    <span v-else>-</span>
                  </p>
                </div>
              </div>
            </b-col>
            <b-col lg="6">
              <div class="mb-0">
                <div class="border-bottom mb-3">
                  <p class="small text-secondary mb-0">Layanan</p>
                  <p class="text-dark mb-0">
                    <span v-if="formBatal.additional2">{{ formBatal.additional2 }}</span>
                    <span v-else>-</span>
                  </p>
                </div>
                <div class="border-bottom mb-3">
                  <p class="small text-secondary mb-0">Unit Layanan</p>
                  <p class="text-dark mb-0">
                    <span v-if="formBatal.additional1">{{ formBatal.additional1 }}</span>
                    <span v-else>-</span>
                  </p>
                </div>
                <div class="border-bottom mb-3">
                  <p class="small text-secondary mb-0">Dokter</p>
                  <p class="text-dark mb-0">
                    <span v-if="formBatal.namaDokter">{{ formBatal.namaDokter }}</span>
                    <span v-else>-</span>
                  </p>
                </div>
                <div class="border-bottom">
                  <p class="small text-secondary mb-0">Pembatalan Tagihan</p>
                  <p class="text-dark h5 font-weight-bold mb-0">
                    <span v-if="formBatal.cancelNominal">{{ formatRibuan(formBatal.cancelNominal) }}</span>
                    <span v-else>-</span>
                  </p>
                </div>
              </div>
            </b-col>
          </b-row>
        </div>
        <div class="mt-2 text-center">
          <b-row class="align-items-center">
            <b-col cols="12" lg="8" class="pr-1">
              <div>
                <b-form-input v-model="formBatal.alasan_batal" class="text-dark form-control-custom-mhas" size="sm" placeholder="Alasan Batal..."></b-form-input>
              </div>
            </b-col>
            <b-col class="pl-1">
              <div>
                <b-button class="shadow-sm btn-custom-mhas w-100" @click="onCancel" variant="danger" size="sm">Batal Pembayaran</b-button>
              </div>
            </b-col>
          </b-row>
        </div>
        <div v-if="messageModal === true">
          <div :class="['text-right mt-3 px-1 shadow-sm', messageValue === '1' ? 'bg-primary' : 'bg-danger']">
            <p class="text-dark text-white mb-0">{{ message }}</p>
          </div>
        </div>
        <!-- <pre>{{ formBatal }}</pre> -->
      </div>
    </b-modal>
    <section id="print-area">
      <div id="printCoba" style="display: none;">
        <div class="page" style="border: none; width: 215mm; height: 140mm; margin: 0px;">
          <div class="page-area" style="font-family: Arial, Helvetica, sans-serif; border: none; padding-left: 1rem; padding-right: 1rem; padding-top: 1rem; padding-bottom: 1rem;">
            <table style="width: 100%; margin-bottom: 1.5rem;">
              <tbody>
                <tr>
                  <td style="text-align: center;">
                    <span style="font-size: 1.3rem; font-weight: bold; color: black; text-decoration: underline;">KWITANSI PEMBAYARAN</span>
                  </td>
                </tr>
                <tr>
                  <td style="text-align: center;">
                    <span style="font-size: 1.2rem; font-weight: bold; color: black;">RSUP FATMAWATI</span>
                  </td>
                </tr>
                <tr>
                  <td style="text-align: center;">
                    <span style="font-size: 1rem; font-weight: bold; color: black;">Jl.RS.Fatmawati Raya No.4</span>
                  </td>
                </tr>
              </tbody>
            </table>
            <table style="width: 100%; margin-bottom: 1.5rem;">
              <tbody>
                <tr>
                  <td style="width: 25%;">
                    <span style="font-size: .9rem; font-weight: bold; color: black; text-transform: capitalize;">nama pasien</span>
                  </td>
                  <td style="width: 5%;">
                    <span style="font-size: .9rem; font-weight: bold; color: black;">:</span>
                  </td>
                  <td style="width: 70%; border-bottom: 1px solid black;">
                    <span style="font-size: .9rem; font-weight: bold; color: black; text-transform: uppercase;">{{ formCetak.pasienName }}</span>
                  </td>
                </tr>
                <tr>
                  <td style="width: 25%;">
                    <span style="font-size: .9rem; font-weight: bold; color: black; text-transform: capitalize;">no. RM</span>
                  </td>
                  <td style="width: 5%;">
                    <span style="font-size: .9rem; font-weight: bold; color: black;">:</span>
                  </td>
                  <td style="width: 70%; border-bottom: 1px solid black;">
                    <span style="font-size: .9rem; font-weight: bold; color: black; text-transform: uppercase;">{{ formCetak.rmNo }}</span>
                  </td>
                </tr>
                <tr>
                  <td style="width: 25%;">
                    <span style="font-size: .9rem; font-weight: bold; color: black; text-transform: capitalize;">no. Reg</span>
                  </td>
                  <td style="width: 5%;">
                    <span style="font-size: .9rem; font-weight: bold; color: black;">:</span>
                  </td>
                  <td style="width: 70%; border-bottom: 1px solid black;">
                    <span style="font-size: .9rem; font-weight: bold; color: black; text-transform: uppercase;">{{ formCetak.regNo }}</span>
                  </td>
                </tr>
                <tr>
                  <td style="width: 25%;">
                    <span style="font-size: .9rem; font-weight: bold; color: black; text-transform: capitalize;">layanan / dokter</span>
                  </td>
                  <td style="width: 5%;">
                    <span style="font-size: .9rem; font-weight: bold; color: black;">:</span>
                  </td>
                  <td style="width: 70%; border-bottom: 1px solid black;">
                    <span style="font-size: .9rem; font-weight: bold; color: black; text-transform: uppercase;">{{ formCetak.additional1 }} / {{ formCetak.namaDokter }}</span>
                  </td>
                </tr>
                <tr>
                  <td style="width: 25%;">
                    <span style="font-size: .9rem; font-weight: bold; color: black; text-transform: capitalize;">metode pembayaran</span>
                  </td>
                  <td style="width: 5%;">
                    <span style="font-size: .9rem; font-weight: bold; color: black;">:</span>
                  </td>
                  <td style="width: 70%; border-bottom: 1px solid black;">
                    <span style="font-size: .9rem; font-weight: bold; color: black; text-transform: uppercase;">{{ formCetak.metodebayarid }}</span>
                  </td>
                </tr>
                <tr>
                  <td style="width: 25%;">
                    <span style="font-size: .9rem; font-weight: bold; color: black; text-transform: capitalize;">nominal pembayaran</span>
                  </td>
                  <td style="width: 5%;">
                    <span style="font-size: .9rem; font-weight: bold; color: black;">:</span>
                  </td>
                  <td style="width: 70%; border-bottom: 1px solid black;">
                    <span style="font-size: .9rem; font-weight: bold; color: black;" v-if="formCetak.paymentbill">Rp {{ formatRibuan(formCetak.paymentbill) }}</span>
                  </td>
                </tr>
                <tr>
                  <td style="width: 25%;">
                    <span style="font-size: .9rem; font-weight: bold; color: black; text-transform: capitalize;">terbilang</span>
                  </td>
                  <td style="width: 5%;">
                    <span style="font-size: .9rem; font-weight: bold; color: black;">:</span>
                  </td>
                  <td style="width: 70%; border-bottom: 1px solid black;">
                    <span style="font-size: .9rem; font-weight: bold; color: black; text-transform: uppercase;">{{ formCetak.terbilang }}</span>
                  </td>
                </tr>
                <tr>
                  <td style="width: 25%;">
                    <span style="font-size: .9rem; font-weight: bold; color: black; text-transform: capitalize;">nama pembayar</span>
                  </td>
                  <td style="width: 5%;">
                    <span style="font-size: .9rem; font-weight: bold; color: black;">:</span>
                  </td>
                  <td style="width: 70%; border-bottom: 1px solid black;">
                    <span style="font-size: .9rem; font-weight: bold; color: black; text-transform: uppercase;">{{ formCetak.pembayar }}</span>
                  </td>
                </tr>
              </tbody>
            </table>
            <table style="width: 100%;">
              <tbody>
                <tr>
                  <td style="text-align: left;">
                    <!-- <span style="font-size: 1rem; font-weight: bold; color: black; text-transform: capitalize;">Jakarta, {{ getDateCetak() }} - {{ getTime() }}</span> -->
                  </td>
                  <td style="text-align: center;">
                    <!-- <span style="font-size: 1rem; font-weight: bold; color: black; text-transform: capitalize;">Jakarta, {{ getDateCetak() }} - {{ getTime() }}</span> -->
                  </td>
                  <td style="text-align: center; width: 40%;">
                    <span style="font-size: .9rem; font-weight: bold; color: black; text-transform: capitalize;">Jakarta, {{ getDateCetak() }} - {{ getTime() }}</span>
                  </td>
                </tr>
                <tr>
                  <td style="text-align: left;">
                    <!-- <span style="font-size: .9rem; font-weight: bold; color: black; text-transform: capitalize;"><br><br><br><br></span> -->
                  </td>
                  <td style="text-align: center;">
                    <!-- <span style="font-size: .9rem; font-weight: bold; color: black; text-transform: capitalize;"><br><br><br><br></span> -->
                  </td>
                  <td style="text-align: center;">
                    <span style="font-size: .9rem; font-weight: bold; color: black; text-transform: capitalize;"><br><br><br><br></span>
                  </td>
                </tr>
                <tr>
                  <td style="text-align: left;">
                    <!-- <span style="font-size: .9rem; font-weight: bold; color: black; text-transform: uppercase; ">{{ formCetak.namakasir }}</span> -->
                  </td>
                  <td style="text-align: center;">
                    <!-- <span style="font-size: .9rem; font-weight: bold; color: black; text-transform: uppercase; ">{{ formCetak.namakasir }}</span> -->
                  </td>
                  <td style="text-align: center; width: 40%;">
                    <span style="font-size: .9rem; font-weight: bold; color: black; text-transform: uppercase; ">{{ formCetak.namakasir }}</span>
                  </td>
                </tr>
                <tr>
                  <td style="text-align: left;">
                    <!-- <span style="font-size: .9rem; font-weight: bold; color: black; text-transform: uppercase; border-top: 2px solid black;">Kasir mandiri rsup fatmawati</span> -->
                  </td>
                  <td style="text-align: center;">
                    <!-- <span style="font-size: .9rem; font-weight: bold; color: black; text-transform: uppercase; border-top: 2px solid black;">Kasir mandiri rsup fatmawati</span> -->
                  </td>
                  <td style="text-align: center; width: 40%;">
                    <span style="font-size: .9rem; font-weight: bold; color: black; text-transform: uppercase; border-top: 2px solid black;">Kasir mandiri rsup fatmawati</span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import { xray } from '../../../config/pluginInit'
import Service from '../../../services/fatmahost/mhas/index'
import flatPickr from 'vue-flatpickr-component'
import 'flatpickr/dist/flatpickr.css'

export default {
  name: 'Mhas',
  components: {
    flatPickr
  },
  data () {
    return {
      isLoading: false,
      selected: {
        radio: '1',
        caraBayar: 1,
        caraBayarTp: '1'
      },
      options: {
        radio: [
          { text: 'No. Registrasi', value: '1' },
          { text: 'No. Rekam Medis', value: '2' }
        ],
        caraBayar: [],
        caraBayarTp: [
          { value: '1', text: 'Uang Muka' },
          { value: '2', text: 'Pelunasan' },
          { value: '3', text: 'Cicilan' }
        ]
      },
      formleftTop: {
        regNo: '',
        rmNo: '',
        startDate: '',
        endDate: ''
      },
      formRightTop: {
        pasienName: '',
        rmNo: '',
        dob: '',
        gender: ''
      },
      formArr: [
        {
          user_id: '',
          metodeBayarId: 1,
          pembayar: '',
          regNo: '',
          rmNo: '',
          pasienName: '',
          regDate: '',
          regDate2: '',
          jenisPelayananId: '',
          paymentTp: '2',
          trxNo: '',
          noKuitansi: '',
          paidFlag: '',
          cancelFlag: '',
          isCancel: '',
          paymentBill: '',
          cancelNominal: '',
          newPaymentBill: '',
          additional1: '',
          additional2: '',
          additional3: '',
          timeStamp: '',
          namaDokter: ''
        }
      ],
      formCetak: {
        pasienName: '',
        rmNo: '',
        regNo: '',
        additional1: '',
        additional3: '',
        regdate: '',
        namaDokter: '',
        metodebayarid: '',
        paymentbill: '',
        terbilang: '',
        pembayar: '',
        nokuitansi: '',
        namakasir: ''
      },
      formBatal: {
        user_id: xray.currentUser().id.toString(),
        alasan_batal: '',
        regNo: '',
        rmNo: '',
        pasienName: '',
        regDate: '',
        jenisPelayananId: '',
        paymentTp: '',
        trxNo: '',
        noKuitansi: '',
        oldNoKuitansi: '',
        paidFlag: '',
        cancelFlag: '1',
        isCancel: '1',
        paymentBill: '',
        cancelNominal: '',
        newPaymentBill: '',
        namaDokter: '',
        additional1: '',
        additional2: '',
        additional3: '',
        timeStamp: this.timestamp()
      },
      preview: {
        pasienName: '',
        rmNo: '',
        regNo: '',
        additional1: '',
        namaDokter: '',
        paymentBill: ''
      },
      config: {
        dateFormat: 'd-m-Y',
        disableMobile: true,
        mode: 'range'
      },
      valueLabel: '1',
      label: 'No. Kartu',
      previewTanggal: '',
      message: '',
      messageValue: '',
      messageModal: null,
      kuitansi: '',
      indexProgress: 0,
      cetakModalCond: false
    }
  },
  mounted () {
    xray.index()
    this.getBayar()
  },
  methods: {
    async getBayar () {
      let response = await Service.metode_bayar()
      this.options.caraBayar = response.data.data
    },
    resetForm () {
      this.formleftTop.regNo = ''
      this.formleftTop.rmNo = ''
      this.formleftTop.startDate = ''
      this.formleftTop.endDate = ''
      this.selected.radio = '1'
      this.valueLabel = '1'
      this.previewTanggal = ''
    },
    timestamp () {
      let currentDate = new Date()
      let year = currentDate.getFullYear()
      let month = currentDate.getMonth() + 1
      let day = currentDate.getDate()
      let hours = currentDate.getHours()
      let minutes = currentDate.getMinutes()
      let seconds = currentDate.getSeconds()
      if (day < 10) {
        day = '0' + day
      }
      if (month < 10) {
        month = '0' + month
      }
      if (hours < 10) {
        hours = '0' + hours
      }
      if (minutes < 10) {
        minutes = '0' + minutes
      }
      let final = year + '-' + month + '-' + day + ' ' + hours + ':' + minutes + ':' + seconds
      return final
    },
    getDate () {
      let date = new Date()
      let day = date.getDate()
      let month = (date.getMonth() + 1).toString().padStart(2, 0)
      let year = date.getFullYear()
      if (day < 10) {
        day = '0' + day
      }
      return day + '-' + month + '-' + year
    },
    getDateCetak () {
      let months = new Array(12)
      months[0] = 'Januari'
      months[1] = 'Februari'
      months[2] = 'Maret'
      months[3] = 'April'
      months[4] = 'Mei'
      months[5] = 'Juni'
      months[6] = 'Juli'
      months[7] = 'Agustus'
      months[8] = 'September'
      months[9] = 'Oktober'
      months[10] = 'November'
      months[11] = 'Desember'
      let date = new Date()
      let day = date.getDate()
      let month = (date.getMonth())
      let year = date.getFullYear()
      if (day < 10) {
        day = '0' + day
      }
      return day + ' ' + months[month] + ' ' + year
    },
    getTime () {
      let currentDate = new Date()
      let currentTimeString = currentDate.toLocaleTimeString([], { hour12: false })
      return currentTimeString
    },
    valueTanggal ($event) {
      if ($event[1] !== undefined) {
        let date = new Date($event[0])
        let day = date.getDate()
        let month = (date.getMonth() + 1).toString().padStart(2, 0)
        let year = date.getFullYear()
        if (day < 10) {
          day = '0' + day
        }
        let date2 = new Date($event[1])
        let day2 = date2.getDate()
        let month2 = (date2.getMonth() + 1).toString().padStart(2, 0)
        let year2 = date2.getFullYear()
        if (day2 < 10) {
          day2 = '0' + day2
        }
        this.formleftTop.startDate = year + '-' + month + '-' + day
        this.formleftTop.endDate = year2 + '-' + month2 + '-' + day2
      }
    },
    formatRibuan (value) {
      if (value !== null || value !== '' || value) {
        let amount = parseInt(value)
        return amount.toLocaleString()
      } else {
        return '0'
      }
    },
    valueRadio (e) {
      this.clearFormLeft()
      this.valueLabel = e
      if (e === '1') {
        this.label = 'No. Kartu'
      } else {
        this.label = 'No. Rekam Medis'
      }
    },
    async normMhas () {
      this.isLoading = true
      //
      this.clearMessage()
      this.clearIndex()
      this.resetArr()
      //
      let data = {
        regNo: this.valueLabel === '1' ? this.formleftTop.regNo : '',
        rmNo: this.valueLabel === '2' ? this.formleftTop.rmNo : '',
        startDate: this.valueLabel === '2' ? this.formleftTop.startDate : '',
        endDate: this.valueLabel === '2' ? this.formleftTop.endDate : '',
        timeStamp: this.timestamp()
      }
      let response = await Service.penerimaanInqury(data)
      if (response.data.inquiryResponse.status.statusDescription === 'Sukses') {
        this.formRightTop.pasienName = response.data.inquiryResponse.pasienName !== '' ? response.data.inquiryResponse.pasienName : ''
        this.formRightTop.dob = response.data.inquiryResponse.dob !== '' ? response.data.inquiryResponse.dob : ''
        this.formRightTop.gender = response.data.inquiryResponse.gender !== '' ? response.data.inquiryResponse.gender : ''
        this.formRightTop.rmNo = response.data.inquiryResponse.rmNo !== '' ? response.data.inquiryResponse.rmNo : ''
        this.deleteRow(0)
        let arr = response.data.inquiryResponse.billDetails.billDetail
        arr.map((value, index) => {
          this.formArr.push(
            {
              user_id: xray.currentUser().id.toString(),
              metodeBayarId: 1,
              pembayar: response.data.inquiryResponse.pasienName !== '' ? response.data.inquiryResponse.pasienName : '',
              regNo: value.regNo !== '' ? value.regNo : '',
              rmNo: response.data.inquiryResponse.rmNo !== '' ? response.data.inquiryResponse.rmNo : '',
              pasienName: response.data.inquiryResponse.pasienName !== '' ? response.data.inquiryResponse.pasienName : '',
              regDate: value.regDate !== '' ? value.regDate : '',
              regDate2: value.regDate2 !== '' ? value.regDate2 : '',
              jenisPelayananId: value.jenisPelayananId !== '' ? value.jenisPelayananId : '',
              paymentTp: '2',
              trxNo: value.trxNo !== '' ? value.trxNo : '',
              noKuitansi: value.noKuitansi !== '' ? value.noKuitansi : '',
              paidFlag: value.paidFlag !== '' ? value.paidFlag : '',
              cancelFlag: value.cancelFlag !== '' ? value.cancelFlag : '',
              isCancel: value.isCancel !== '' ? value.isCancel : '',
              paymentBill: value.paymentBill !== '' ? value.paymentBill : '',
              cancelNominal: value.cancelNominal !== '' ? value.cancelNominal : '',
              newPaymentBill: '0',
              additional1: value.additional1 !== '' ? value.additional1 : '',
              additional2: value.additional2 !== '' ? value.additional2 : '',
              additional3: value.additional3 !== '' ? value.additional3 : '',
              timeStamp: this.timestamp(),
              namaDokter: value.namaDokter !== '' ? value.namaDokter : ''
            }
          )
        })
        this.isLoading = false
      } else {
        this.messageModal = false
        this.messageValue = '0'
        this.message = response.data.inquiryResponse.status.statusDescription
        this.isLoading = false
      }
    },
    async progress (index, pasienName, rmNo, regNo, additional1, namaDokter, paymentBill) {
      //
      this.indexProgress = index
      //
      this.preview.pasienName = pasienName
      this.preview.rmNo = rmNo
      this.preview.regNo = regNo
      this.preview.additional1 = additional1
      this.preview.namaDokter = namaDokter
      this.preview.paymentBill = paymentBill
      //
      this.$refs['modal-proses'].show()
    },
    async onSimpan () {
      this.formArr[this.indexProgress].paidFlag = '1'
      let dataSimpan = this.formArr[this.indexProgress]
      let responseSimpan = await Service.flagging(dataSimpan)
      if (responseSimpan.data.flaggingResponse.status.statusCode === '1') {
        this.formArr[this.indexProgress].noKuitansi = responseSimpan.data.flaggingResponse.nokuitansi
        let dataSimpan2 = this.formArr[this.indexProgress]
        let responseSimpan2 = await Service.flagging2(dataSimpan2)
        if (responseSimpan2.data.flaggingResponse.status.statusCode === '1') {
          this.$refs['modal-proses'].hide()
          this.normMhas()
        } else {
          this.messageModal = true
          this.messageValue = responseSimpan2.data.flaggingResponse.status.statusCode
          this.message = responseSimpan2.data.flaggingResponse.nokuitansi
        }
      } else {
        this.messageModal = true
        this.messageValue = responseSimpan.data.flaggingResponse.status.statusCode
        this.message = responseSimpan.data.flaggingResponse.status.statusDescription
      }
    },
    async printPreview (value, valueb) {
      let data = {
        noKuitansi: value
      }
      let response = await Service.cetak_bukti(data)
      if (response.data) {
        this.cetakModalCond = true
        this.formCetak.pasienName = response.data.inquiryResponse.pasienName ? response.data.inquiryResponse.pasienName : '-'
        this.formCetak.rmNo = response.data.inquiryResponse.rmNo ? response.data.inquiryResponse.rmNo : '-'
        this.formCetak.regNo = response.data.inquiryResponse.regNo ? response.data.inquiryResponse.regNo : '-'
        this.formCetak.regdate = response.data.inquiryResponse.regdate ? response.data.inquiryResponse.regdate : '-'
        this.formCetak.additional1 = response.data.inquiryResponse.additional1 ? response.data.inquiryResponse.additional1 : '-'
        this.formCetak.additional3 = response.data.inquiryResponse.additional3 ? response.data.inquiryResponse.additional3 : '-'
        this.formCetak.namaDokter = valueb
        this.formCetak.metodebayarid = response.data.inquiryResponse.metodebayarid ? response.data.inquiryResponse.metodebayarid : '-'
        this.formCetak.paymentbill = response.data.inquiryResponse.paymentbill ? response.data.inquiryResponse.paymentbill : '-'
        this.formCetak.terbilang = response.data.inquiryResponse.terbilang ? response.data.inquiryResponse.terbilang : '-'
        this.formCetak.pembayar = response.data.inquiryResponse.pembayar ? response.data.inquiryResponse.pembayar : '-'
        this.formCetak.nokuitansi = response.data.inquiryResponse.nokuitansi ? response.data.inquiryResponse.nokuitansi : '-'
        this.formCetak.namakasir = response.data.inquiryResponse.namakasir ? response.data.inquiryResponse.namakasir : '-'
      } else {
        this.cetakModalCond = false
      }
      this.$refs['modal-preview-print'].show()
    },
    async cancelPreview (regNo, rmNo, pasienName, regDate, jenisPelayananId, paymentTp, trxNo, noKuitansi, paidFlag, paymentBill, newPaymentBill, namaDokter, additional1, additional2, additional3) {
      this.formBatal.regNo = regNo
      this.formBatal.rmNo = rmNo
      this.formBatal.pasienName = pasienName
      this.formBatal.regDate = regDate
      this.formBatal.jenisPelayananId = jenisPelayananId
      this.formBatal.paymentTp = paymentTp
      this.formBatal.trxNo = trxNo
      this.formBatal.noKuitansi = noKuitansi
      this.formBatal.oldNoKuitansi = ''
      this.formBatal.paidFlag = paidFlag
      this.formBatal.paymentBill = paymentBill
      this.formBatal.cancelNominal = paymentBill
      this.formBatal.newPaymentBill = newPaymentBill
      this.formBatal.namaDokter = namaDokter
      this.formBatal.additional1 = additional1
      this.formBatal.additional2 = additional2
      this.formBatal.additional3 = additional3
      this.$refs['modal-preview-cancel'].show()
    },
    async onCancel () {
      if (this.formBatal.alasan_batal !== '') {
        let response1 = await Service.batal_kasir(this.formBatal)
        if (response1.data.flaggingResponse.status.statusCode === '1') {
          let response2 = await Service.batal_kasir_2(this.formBatal)
          if (response2.data.flaggingResponse.status.statusCode === '1') {
            this.$refs['modal-preview-cancel'].hide()
            this.normMhas()
          } else {
            this.messageModal = true
            this.messageValue = response2.data.flaggingResponse.status.statusCode
            this.message = response2.data.flaggingResponse.status.statusDescription
          }
        } else {
          this.messageModal = true
          this.messageValue = response1.data.flaggingResponse.status.statusCode
          this.message = response1.data.flaggingResponse.status.statusDescription
        }
      } else {
        this.messageModal = true
        this.messageValue = '0'
        this.message = 'Alasan batal, harus diisi'
      }
    },
    print () {
      let prtHtml = document.getElementById('printCoba')
      let Winprint = window.open('', '', 'height=1000,width=1000')
      Winprint.document.write(`<!DOCTYPE html>
      <html lang="en">
      <head>
      </head>
      <body>
      ${prtHtml.innerHTML}
      </body>
      </html>`)
      Winprint.document.close()
      Winprint.focus()
      Winprint.print()
      Winprint.close()
    },
    clearModal () {
      this.normMhas()
    },
    clearMessage () {
      this.messageModal = null
      this.messageValue = ''
      this.message = ''
      //
      this.formBatal.alasan_batal = ''
    },
    clearIndex () {
      this.indexProgress = 0
    },
    clearFormLeft () {
      this.formleftTop.regNo = ''
      this.formleftTop.rmNo = ''
      this.formleftTop.startDate = ''
      this.formleftTop.endDate = ''
      this.previewTanggal = ''
    },
    resetArr () {
      this.formArr = [
        {
          user_id: '',
          metodeBayarId: 1,
          pembayar: '',
          regNo: '',
          rmNo: '',
          pasienName: '',
          regDate: '',
          regDate2: '',
          jenisPelayananId: '',
          paymentTp: '2',
          trxNo: '',
          noKuitansi: '',
          paidFlag: '',
          cancelFlag: '',
          isCancel: '',
          paymentBill: '',
          cancelNominal: '',
          newPaymentBill: '',
          additional1: '',
          additional2: '',
          additional3: '',
          timeStamp: '',
          namaDokter: ''
        }
      ]
    },
    deleteRow (index) {
      this.formArr.splice(index, 1)
    }
  }
}
</script>
<style>
#mhas .text-custpm-tabel {
    font-size: .8rem;
}
#mhas .border-c {
    font-size: .8rem;
}
#mhas .col-form-label {
    font-size: .7rem;
}
#mhas .radius-custom {
  border-radius: 10px;
}
#mhas .bg-gray-custom {
  background: rgba(128, 128, 128, 0.075);
}
#mhas .letter-spacing-custom {
  letter-spacing: 1px
}
#mhas .radius-top-custom {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}
#mhas .radius-custom-2 {
  border-radius: 5px;
}
#mhas .radius-bottom-custom {
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}
#mhas .form-control-custom-mhas {
    height: 33px;
    line-height: 35px;
    border-radius: 5px;
}
#mhas .btn-custom-mhas {
    height: 33px;
}
#mhas .color-mandiri {
  color: #6CA7C7;
}
#mhas .bg-color-mandiri-low {
  background: #F5F9FC;
}
#mhas .bg-color-mandiri {
  background: #6CA7C7;
}
#mhas .bg-color-mandiri-kuning {
  background: #FDC202;
}
#mhas .custom-flat-pickr{
    border: 1px solid rgb(233, 233, 233);
    height: 33px;
    padding-left: 10px;
    padding-top: 5px;
    padding-bottom: 5px;
    border: 1px solid rgb(209, 208, 208)!important;
    border-radius: 5px;
    font-size: 14px;
    color: black;
  }
</style>
