<template>
  <div id="e-kinerja-dashboard" class="mb-4">
    <div v-show="isLoading">
        <div id="show-overlay"></div>
      <Loader :style="{ opacity: .5 }" />
    </div>
    <b-container fluid>
      <div class="bg-white letter-spacing-custom border-radius-custom-ekinerja shadow-sm px-3 py-3">
        <div class="border py-2 px-2">
          <b-row class="justify-content-between">
            <b-col cols="12" lg="3" class="pr-1 mb-2 mb-lg-0">
              <p class="text-center text-lg-left font-weight-bold text-dark h5 m-0 p-0">E-KINERJA DASHBOARD</p>
              <!-- <pre>{{ formTemp }}</pre> -->
            </b-col>
          </b-row>
        </div>
        <div class="table-header border py-2 px-2">
          <b-row class="align-items-center">
            <b-col cols="6" lg="2" class="pr-lg-1 mb-2 mb-lg-0">
              <div>
                <b-form-input v-model="preview.filterTag" type="search" class="e-kinerja-filter" @input="tableRefresh" placeholder="Tag..."></b-form-input>
              </div>
            </b-col>
            <b-col cols="6" lg="3" class="px-lg-1 mb-2 mb-lg-0">
              <div>
                <b-form-input v-model="preview.filterUraian" type="search" class="e-kinerja-filter" @input="tableRefresh" placeholder="Uraian..."></b-form-input>
              </div>
            </b-col>
            <b-col cols="6" lg="2" class="px-lg-1 mb-2 mb-lg-0">
              <div>
                <multiselect :options="options.status" v-model="selected.status" @input="valueStatus" :allow-empty="false" label="uraian" :show-labels="false" placeholder="Tipe" :searchable="true">
                </multiselect>
              </div>
            </b-col>
            <b-col cols="6" lg="2" class="px-lg-1 mb-2 mb-lg-0">
              <div>
                <multiselect :options="options.statusKinerja" v-model="selected.statusKinerja" @input="valueStatusKinerja" :allow-empty="false" label="deskripsi" :show-labels="false" placeholder="Status" :searchable="true">
                </multiselect>
              </div>
            </b-col>
            <b-col cols="12" lg="2" class="px-lg-1 mb-2 mb-lg-0">
              <div>
                <flat-pickr :config="config" v-model="preview.tanggal" value="" @on-change="tglEvent" placeholder="[ Range Tanggal ]" class="w-100 calender-small custom-flat-pickr m-0">
                </flat-pickr>
              </div>
            </b-col>
            <b-col cols="12" lg="1" class="pl-lg-1 mb-2 mb-lg-0 ml-auto">
              <div class="w-100">
                <b-button size="sm" class="w-100 " variant="warning" @click="resetFilter" v-b-popover.hover.top="'Reset Filter'"> <i class="fas fa-undo"></i></b-button>
              </div>
            </b-col>
          </b-row>
        </div>
        <div class="table table-body m-0">
          <b-table ref="table" class="m-0" :sort-by.sync="table.sortBy" :sort-desc.sync="table.sortDesc"
            :filter="table.filter" @filtered="onFiltered" :busy="table.isBusy" :current-page="table.currentPage"
            :per-page="table.perPage" responsive stacked="md" bordered hover show-empty small :items="table.items"
            :fields="table.fields" :select-mode="table.selectMode" selectable>
            <template #table-busy>
              <div class="text-center my-2">
                <b-spinner class="align-middle text-primary mr-2"></b-spinner>
                <strong class="text-dark ">Loading...</strong>
              </div>
            </template>
            <template #row-details="data">
              <b-card class="py-1">
                <div class="my-2 mx-2">
                  <p class="m-0 p-0 font-weight-bold">URAIAN LOG : </p>
                </div>
                <b-form-textarea readonly v-model="data.item.eventlog_uraian" rows="5" class="text-dark font-italic font-weight-bold bg-white border-radius-custom-ekinerja-textarea rounded-0 small" placeholder="Keterangan..." v-if="data.item.eventlog_uraian"></b-form-textarea>
              </b-card>
            </template>
            <template #cell(index)="data">
              <span>{{ (table.currentPage*table.perPage)-table.perPage + data.index + 1 }}</span>
            </template>
            <template #cell(te.mulai)="data">
              <span>{{ timeStamp(tglWithDots(data.item)) }}</span>
            </template>
            <template #cell(te.selesai)="data">
              <span>{{ timeStamp(tglEndWithDots(data.item)) }}</span>
            </template>
            <template #cell(url_referensi)="data">
              <b-link @click.prevent="openUrl(data.item.url_referensi)">{{ data.item.url_referensi }}</b-link>
            </template>
            <template #cell(status)="data">
              <span v-if="data.item.status === 0 ">BELUM</span>
              <span class="text-success font-weight-bold" v-else-if="data.item.status === 1 ">SELESAI</span>
              <span class="text-warning font-weight-bold" v-else-if="data.item.status === 2 ">PROSES</span>
              <span class="text-danger font-weight-bold" v-else-if="data.item.status === 9 ">PENDING</span>
            </template>
            <template v-slot:cell(action)="data">
              <span>
                <b-button size="sm" variant="warning" class="m-1" @click="data.toggleDetails">
                  <i class="fas fa-eye"></i>
                </b-button>
                <b-button variant="primary" size="sm" class="m-1" @click.prevent="openModal(data.item)" >
                  <i class="fas fa-sign-out-alt"></i>
                </b-button>
              </span>
            </template>
          </b-table>
        </div>
        <div class="table-footer border">
          <b-row id="pagination-trx-remun" class="align-items-center px-2">
            <b-col cols="12" lg="3" class="d-flex flex-row align-items-center justify-content-center justify-content-lg-start mr-auto">
              <div>
                <b-pagination
                  v-model="table.currentPage"
                  :total-rows="table.totalRows"
                  :per-page="table.perPage"
                  size="md"
                  class="my-2"
                ></b-pagination>
              </div>
              <div>
                <span class="hover" @click="tableRefresh">
                  <i class="fas fa-sync text-primary"></i>
                </span>
              </div>
            </b-col>
            <b-col cols="12" lg="6" v-if="messageModal === 0">
              <div :class="['px-2 shadow-sm text-right mb-3 mb-lg-0 d-flex flex-row justify-content-end', messageColor]">
                <div class="mr-3">
                  <p class="small text-uppercase text-white m-0">{{ message }}</p>
                </div>
                <div>
                  <p class="small text-uppercase text-white m-0" @click="clearMessage"><i class="fas fa-times-circle"></i></p>
                </div>
              </div>
            </b-col>
            <b-col cols="12" lg="3" class="d-flex flex-row align-items-center justify-content-center justify-content-lg-end mb-3 mb-lg-0">
              <div class="mr-2">
                <b-form-select
                  class="cstm-select-remun-dash"
                  id="per-page-select"
                  v-model="table.perPage"
                  :options="table.pageOptions"
                ></b-form-select>
              </div>
              <div>
                <p class="text-dark text-center text-lg-right mb-0">Menampilkan {{ table.perPage }} dari {{ table.totalRows }} baris</p>
              </div>
            </b-col>
          </b-row>
        </div>
      </div>
    </b-container>
    <b-modal id="modal-ekinerja-dashboard" ref="modal-ekinerja-dashboard" header-class="text-uppercase" title="KONFIRMASI" size="md" centered :no-close-on-backdrop="true" :static="true" :no-close-on-esc="true" cancel-title="Tutup" :hide-footer="true" :ok-title="btnModalTitle">
      <div class="letter-spacing-custom text-uppercase text-center text-dark mb-4">
        <img :src="require('../../../assets/images/work.svg')" width="250rem" class="d-inline-block align-top" alt="kemenkes">
        <p class="m-0 p-0 h6 text-dark mt-2">{{ modalMessageConfirm }}</p>
      </div>
      <div>
        <b-row class="justify-content-center">
          <b-col cols="3" v-if="formTemp.status === 0">
            <b-button variant="primary" size="sm" class="w-100" @click.prevent="prosesEvent(1)">KERJAKAN</b-button>
          </b-col>
          <b-col cols="3" v-if="formTemp.status === 2">
            <b-button variant="warning" size="sm" class="w-100" @click.prevent="prosesEvent(2)">PENDING</b-button>
          </b-col>
          <b-col cols="3" v-if="formTemp.status === 2">
            <b-button variant="success" size="sm" class="w-100" @click.prevent="prosesEvent(3)">SELESAI</b-button>
          </b-col>
          <b-col cols="3" v-if="formTemp.status === 9">
            <b-button variant="primary" size="sm" class="w-100" @click.prevent="prosesEvent(2)">KERJAKAN</b-button>
          </b-col>
          <b-col cols="3" v-if="formTemp.status === 1">
            <b-button variant="danger" size="sm" class="w-100">BATAL</b-button>
          </b-col>
        </b-row>
      </div>
      <div class="letter-spacing-custom text-uppercase text-center text-dark py-1" v-if="message !== ''">
        <div class="bg-danger px-1">
          <span class="text-white small">{{ message }}</span>
        </div>
      </div>
    </b-modal>
  </div>
</template>
<script>
import { xray } from '../../../config/pluginInit'
import newServices from '../../../services/fatmahost/pegawai/index.ts'
import Multiselect from 'vue-multiselect'

export default {
  name: 'EKinerjaDashboard',
  components: {
    Multiselect
  },
  data () {
    return {
      isLoading: false,
      config: {
        dateFormat: 'd-m-Y',
        disableMobile: true,
        mode: 'range'
      },
      table: {
        isBusy: false,
        items: this.list,
        sortBy: '',
        sortDesc: false,
        filter: null,
        currentPage: null,
        perPage: null,
        pageOptions: [10, 25, 50, 100],
        totalRows: null,
        selectMode: 'single',
        selected: [],
        fields: [
          { label: 'NO.', key: 'index', class: 'text-lg-center py-2' },
          { label: 'TGL. MULAI', key: 'te.mulai' },
          { label: 'TGL. SELESAI', key: 'te.selesai' },
          { label: 'ASSIGN', key: 'assign_uraian' },
          { label: 'TAG', key: 'te.tag' },
          { label: 'URL REFERENSI', key: 'url_referensi' },
          // { label: 'ISSUE', key: 'issue_uraian' },
          // { label: 'URAIAN', key: 'uraian' },
          { label: 'PIC', key: 'keterangan' },
          { label: 'STATUS', key: 'status' },
          { label: 'AKSI', key: 'action' }
        ]
      },
      formTemp: {},
      options: {
        status: [],
        statusKinerja: []
      },
      selected: {
        status: [],
        statusKinerja: []
      },
      preview: {
        tanggal: '',
        tanggalAwal: '',
        tanggalAkhir: '',
        //
        filterTag: '',
        filterUraian: '',
        filterStatus: '',
        filterStatusKinerja: ''
      },
      modalMessageConfirm: '',
      btnModalTitle: '',
      message: '',
      messageColor: '',
      messageModal: null
    }
  },
  mounted () {
    xray.index()
    this.getStatus()
    this.getStatusKinerja()
  },
  methods: {
    openUrl (url) {
      if (url) window.open(url, '_blank')
    },
    async list (ctx) {
      let id = xray.currentUser().id.toString()
      let tag = this.preview.filterTag
      let uraian = this.preview.filterUraian
      let status = this.preview.filterStatus
      let statusKin = this.preview.filterStatusKinerja
      let tglAwal = this.preview.tanggalAwal
      let tglAkhir = this.preview.tanggalAkhir
      try {
        let response = await newServices.findDashboardTrxKinerja(ctx.currentPage - 1, ctx.perPage, id, tag, uraian, status, tglAwal, tglAkhir, statusKin)
        if (response.data.data) {
          this.table.isBusy = false
          this.table.totalRows = response.data.pagination.total
          this.table.perPage = response.data.pagination.perPage
          this.table.currentPage = response.data.pagination.current
          return response.data.data
        } else {
          this.table.isBusy = false
          this.table.totalRows = 1
          this.table.perPage = 10
          this.table.currentPage = 1
          return []
        }
      } catch (error) {
        this.table.isBusy = false
        this.message = error.message
        return []
      }
    },
    async getStatus () {
      try {
        const response = await newServices.mworktype()
        if (response.data.statcode === 0) {
          this.options.status = response.data.data
        } else {
          this.options.status = []
        }
      } catch (error) {
        this.message = error.message
      }
    },
    async getStatusKinerja () {
      try {
        const response = await newServices.stskinerja()
        if (response.data.statcode === 0) {
          this.options.statusKinerja = response.data.data
        } else {
          this.options.statusKinerja = []
        }
      } catch (error) {
        this.message = error.message
      }
    },
    tglEvent ($event) {
      if ($event[1] !== undefined) {
        let date = new Date($event[0])
        let day = date.getDate()
        let month = (date.getMonth() + 1).toString().padStart(2, 0)
        let year = date.getFullYear()
        if (day < 10) {
          day = '0' + day
        }
        //
        let date2 = new Date($event[1])
        let day2 = date2.getDate()
        let month2 = (date2.getMonth() + 1).toString().padStart(2, 0)
        let year2 = date2.getFullYear()
        if (day2 < 10) {
          day2 = '0' + day2
        }
        let currentDate = year + '-' + month + '-' + day
        let currentDate2 = year2 + '-' + month2 + '-' + day2
        this.preview.tanggalAwal = currentDate
        this.preview.tanggalAkhir = currentDate2
        this.$refs.table.refresh()
      }
    },
    timeStamp (value) {
      if (value) {
        let currentDate = new Date(value)
        let day = currentDate.getDate()
        let month = (currentDate.getMonth() + 1).toString().padStart(2, 0)
        let year = currentDate.getFullYear()
        let hours = currentDate.getHours()
        let minutes = currentDate.getMinutes()
        if (day < 10) {
          day = '0' + day
        }
        if (hours < 10) {
          hours = '0' + hours
        }
        if (minutes < 10) {
          minutes = '0' + minutes
        }
        let final = day + '-' + month + '-' + year + ' ' + hours + ':' + minutes
        return final
      } else {
        return '00-00-000 00:00'
      }
    },
    openModal (e) {
      this.message = ''
      this.formTemp = e
      if (e.status === 0) {
        this.modalMessageConfirm = 'Ingin Memproses Pekerjaan ?'
        this.btnModalTitle = 'KERJAKAN'
      }
      if (e.status === 1) {
        this.modalMessageConfirm = 'Ingin Membatalkan Proses ?'
        this.btnModalTitle = 'BATAL'
      }
      if (e.status === 2) {
        this.modalMessageConfirm = 'Ingin Menyelesaikan Pekerjaan' + ' ' + e.assign_uraian + ' ' + '?'
        this.btnModalTitle = 'SELESAI'
      }
      if (e.status === 9) {
        this.modalMessageConfirm = 'Ingin Memproses Pekerjaan ?'
        this.btnModalTitle = 'KERJAKAN'
      }
      this.$refs['modal-ekinerja-dashboard'].show()
    },
    async prosesEvent (value) {
      try {
        const bodyCreate = {
          object: 'tkinerja-start',
          id: this.formTemp.id,
          id_event: this.formTemp.id_event,
          pic: this.formTemp.pic
        }
        const bodyPendingOrUpdate = {
          object: value === 2 ? 'tkinerja-pending' : 'tkinerja-selesai'
        }
        const url = value === 0 ? `service/trxKinerja` : `service/trxKinerja/${this.formTemp.id}`
        const response = value === 0 ? await newServices.tkinerjaStart(url, bodyCreate) : await newServices.tkinerjaUpdate(url, bodyPendingOrUpdate)
        if (response.data.statcode === 0) {
          this.$refs['modal-ekinerja-dashboard'].hide()
          this.$refs.table.refresh()
        } else {
          this.message = response.data.message
        }
      } catch (error) {
        this.message = error.message
      }
    },
    tglWithDots (item) {
      return item['te.mulai']
    },
    tglEndWithDots (item) {
      return item['te.selesai']
    },
    onFiltered (filteredItems) {
      this.table.totalRows = filteredItems.length
    },
    tableRefresh () {
      this.$refs.table.refresh()
    },
    valueStatus (value) {
      this.preview.filterStatus = value.id.toString()
      this.$refs.table.refresh()
    },
    valueStatusKinerja (value) {
      this.preview.filterStatusKinerja = value.id.toString()
      this.$refs.table.refresh()
    },
    resetFilter () {
      this.preview.filterTag = ''
      this.preview.filterStatus = ''
      this.preview.filterStatusKinerja = ''
      this.preview.filterUraian = ''
      this.preview.tanggal = ''
      this.preview.tanggalAwal = ''
      this.preview.tanggalAkhir = ''
      //
      this.selected.status = []
      this.selected.statusKinerja = []
      //
      this.$refs.table.refresh()
    },
    clearMessage () {
      this.messageModal = null
      this.message = ''
    }
  }
}
</script>

<style>
#e-kinerja-dashboard .table .card-body {
  padding: 0;
}
#e-kinerja-dashboard .bg-abu {
  background-color: #e9ecef;
}
#e-kinerja-dashboard .hover {
  cursor: pointer;
}
#e-kinerja-dashboard .border-radius-custom-ekinerja {
  border-radius: .5rem;
}
#e-kinerja-dashboard .border-radius-custom-ekinerja-textarea {
  border-radius: .5rem;
  line-height: 25px
}
#e-kinerja-dashboard .table td {
  font-size: .75rem;
  font-weight: 500;
  padding-top: 0px;
  padding-bottom: 0px;
}
#e-kinerja-dashboard .table th {
  font-size: .8rem;
  font-weight: 400;
}
#e-kinerja-dashboard .rounded-custom {
  border-radius: 5px;
}
#e-kinerja-dashboard .page-link {
  border: none;
  color: gray!important;
  margin-right: 15px;
}
#e-kinerja-dashboard .page-item.active .page-link {
  background-color: transparent;
  border-color: transparent;
  color: #089bab!important;
  font-weight: bold;
}
#e-kinerja-dashboard .cstm-select-remun-dash {
  font-size: 13px;
  height: 33px!important;
  border-radius: 5px;
}
#e-kinerja-dashboard .e-kinerja-filter {
  font-size: 13px;
  height: 33px!important;
  border-radius: 5px;
}
#e-kinerja-dashboard .custom-flat-pickr {
    border: 1px solid rgb(233, 233, 233);
    height: 33px;
    padding-left: 10px;
    padding-top: 5px;
    padding-bottom: 5px;
    border: 1px solid rgb(209, 208, 208)!important;
    border-radius: 5px;
    font-size: 12px;
    color: black;
}
#e-kinerja-dashboard .letter-spacing-custom {
  letter-spacing: .5px
}
#e-kinerja-dashboard .multiselect__placeholder {
  font-weight: normal;
  font-size: 13px!important;
}
#e-kinerja-dashboard .multiselect__option {
  font-weight: normal;
  font-size: 13px;
}
#e-kinerja-dashboard .multiselect__tags {
  font-weight: normal;
  padding: 3px 40px 0 8px!important;
  height: 33px!important;
  min-height: 33px!important;
  border: 1px solid rgba(0, 0, 0, 0.212);
}
#e-kinerja-dashboard .multiselect {
  height: 33px!important;
  min-height: 33px!important;
}
#e-kinerja-dashboard .multiselect__select {
  height: 33px!important;
  min-height: 33px!important;
}
#modal-ekinerja-action .modal-dialog {
    min-width: 95%;
}
#modal-ekinerja-action .modal-body, #modal-ekinerja-edit-log .modal-body, #modal-ekinerja-create-issue-event .modal-body {
  padding: 0
}
#modal-ekinerja-action .bg-info-custom {
  background: rgb(0, 187, 230);
}
#modal-ekinerja-tambah-log .modal-body {
  padding: 0
}
#modal-ekinerja-create-issue .modal-body {
  padding: .5rem
}
#modal-ekinerja-action .btn-height-auto {
  height: -webkit-fill-available;
}
#e-kinerja-dashboard .tl-color {
  color: rgb(255, 196, 0);
}
#modal-ekinerja-edit-log .text-area-radius, #modal-ekinerja-tambah-log .text-area-radius {
  border-radius: 0px;
  line-height: 25px
}
</style>
