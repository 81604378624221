<template>
  <b-modal ref="modal-msg-bok" :size="sizeModal" centered title="Notifikasi" ok-title="Tutup" :hide-footer="true" :hide-header="true">
    <div class="pb-3 px-4">
      <div v-if="typeModal === 'success'">
        <lottie :options="animationSuccess" :autoPlay="true" :width="100" :height="100" />
      </div>
      <div v-else>
        <lottie :options="animationError" :autoPlay="true" :width="100" :height="100" />
      </div>
      <p class="h6 text-dark text-center mb-0 mt-2">{{ msgModal }}</p>
    </div>
  </b-modal>
</template>
<script>
import Lottie from 'vue-lottie'
import animationSuccess from '../../assets/lottie/checked.json'
import animationError from '../../assets/lottie/failed.json'
export default {
  name: 'ModalMsgBox',
  components: {
    Lottie
  },
  data () {
    return {
      animationError: { animationData: animationError, loop: true },
      animationSuccess: { animationData: animationSuccess, loop: true },
      typeModal: '',
      msgModal: '',
      sizeModal: ''
    }
  },
  props: {
  },
  mounted () {
  },
  computed: {
  },
  methods: {
    show (config) {
      this.typeModal = config.type
      this.msgModal = config.msg
      this.sizeModal = config.size
      this.$refs['modal-msg-bok'].show()
      const fx = () => this.$refs['modal-msg-bok'].hide()
      setTimeout(fx, 3000)
    },
    hide () {
      this.$refs['modal-msg-bok'].hide()
    }
  }
}
</script>
