<template>
  <div class="pegawai-edit-form">
    <b-container fluid>
      <div v-show="isLoading">
        <div id="show-overlay"></div>
        <Loader :style="{ opacity: .5 }" />
      </div>
      <form>
          <b-row>
            <b-col lg="8">
              <iq-card>
                <template v-slot:headerTitle>
                  <h6 class="card-title"><i class="fas fa-user-check"></i> Identitas</h6>
                </template>
                <template v-slot:body>
                  <div class="new-user-info">
                    <b-row class="mb-2">
                      <b-form-group class="col-md-3 pr-1 mb-0">
                        <b-form-input class="cstm-cntrl-pegawai" v-model="pegawainew.pegawai.nip" type="text" placeholder="[ Masukkan N I P ]"></b-form-input>
                      </b-form-group>
                      <b-form-group class="col-md-2 pr-1 pl-1 mb-0">
                          <b-form-input class="cstm-cntrl-pegawai" v-model="pegawainew.pegawai.gelar_depan" type="text" placeholder="[ Gelar Depan ]"></b-form-input>
                      </b-form-group>
                      <b-form-group class="col-md-3 pr-1 pl-1 mb-0">
                        <b-form-input class="cstm-cntrl-pegawai" v-model="pegawainew.pegawai.nama" type="text" placeholder="[ Masukkan Nama Tanpa Gelar ]"></b-form-input>
                      </b-form-group>
                      <b-form-group class="col-md-2 pr-1 pl-1 mb-0">
                        <b-form-input class="cstm-cntrl-pegawai" v-model="pegawainew.pegawai.panggilan" type="text" placeholder="[ Masukkan Nama Tanpa Gelar ]"></b-form-input>
                      </b-form-group>
                      <b-form-group class="col-md-2 pl-1 mb-0">
                          <b-form-input class="cstm-cntrl-pegawai" v-model="pegawainew.pegawai.gelar_belakang" type="text" placeholder="[ Gelar Belakang ]"></b-form-input>
                      </b-form-group>
                    </b-row>
                    <b-row>
                      <b-col cols="12">
                        <b-row class="mb-2">
                          <b-form-group class="col-md-6 mb-0 pr-1">
                            <multiselect v-model="vselected.id_tempat_lahir" :show-labels="false" :limit="3" @input="valTempatLahir" track-by="deskripsi" label="deskripsi" placeholder="[ Tempat Lahir ]" :options="combo.temlahir">
                            </multiselect>
                          </b-form-group>
                          <b-form-group class="col-md-6 mb-0 pl-1">
                            <!-- <b-form-datepicker v-model="pegawainew.pegawai.tanggal_lahir" :class="['my-input', tglIsValid ? 'form-control-isvalid' : '']" id="tgllahir" type="date" placeholder="Tanggal Lahir"></b-form-datepicker> -->
                            <flat-pickr v-model="pegawainew.pegawai.tanggal_lahir" :config="config"
                              class="w-100 calender-small flatpickr-cstm-lab" placeholder="[ Tanggal Lahir ]">
                            </flat-pickr>
                          </b-form-group>
                        </b-row>
                        <b-row class="mb-2">
                          <b-form-group class="col-md-6 mb-0 pr-1">
                            <multiselect v-model="vselected.id_jenis_kelamin" :show-labels="false" :limit="3" @input="valJenisKelamin" label="deskripsi" placeholder="[ Jenis Kelamin ]" :options="combo.jk">
                            </multiselect>
                          </b-form-group>
                          <b-form-group class="col-md-6 mb-0 pl-1">
                            <multiselect v-model="vselected.id_agama" :show-labels="false" :limit="3" @input="valAgama" label="deskripsi" placeholder="[ Agama ]" :options="combo.agama">
                            </multiselect>
                          </b-form-group>
                        </b-row>
                        <b-row  class="mb-2">
                          <b-form-group class="col-md-6 mb-2 pr-1">
                            <multiselect v-model="vselected.id_profesi" :show-labels="false" :limit="3" @input="valProfesi" label="deskripsi" placeholder="[ Profesi ]" :options="combo.profesi">
                            </multiselect>
                          </b-form-group>
                          <b-form-group class="col-md-6 mb-2 pl-1">
                            <multiselect v-model="vselected.id_smf" :show-labels="false" :limit="3" @input="valSmf" label="deskripsi" placeholder="[ S M F ]" :options="combo.smf">
                            </multiselect>
                          </b-form-group>
                          <b-form-group class="col-md-6 mb-0 pr-1">
                            <multiselect v-model="vselected.id_status" :show-labels="false" :limit="3" @input="valStatus" label="deskripsi" placeholder="[ Status ]" :options="combo.status">
                            </multiselect>
                          </b-form-group>
                          <b-form-group class="col-md-6 mb-0 pl-1">
                            <multiselect v-model="vselected.id_bios" :show-labels="false" :limit="3" @input="valBios" label="deskripsi" placeholder="[ Profesi ]" :options="combo.bios">
                            </multiselect>
                          </b-form-group>
                        </b-row>
                      </b-col>
                      <b-col cols="12">
                        <b-row class="mb-2">
                          <b-form-group class="col-sm-6 mb-2 pr-1" label-for="provinsi">
                            <multiselect v-model="vselected.id_provinsi" :show-labels="false" @input="getkabupatenidentitas" :limit="3" track-by="deskripsi" :options="combo.identitas.provinsi" label="deskripsi" placeholder="[ Provinsi ]">
                            </multiselect>
                          </b-form-group>
                          <b-form-group class="col-sm-6 mb-2 pl-1" label-for="kabupatenkota">
                            <multiselect v-model="vselected.id_kabupaten" :show-labels="false" @input="getkecamatanidentitas" :limit="3" track-by="deskripsi" :options="combo.identitas.kabupaten" label="deskripsi" placeholder="[ Kabupaten ]">
                            </multiselect>
                          </b-form-group>
                          <b-form-group class="col-sm-6 mb-0 pr-1" label-for="kecamatan">
                            <multiselect v-model="vselected.id_kecamatan" :show-labels="false" @input="getdesaidentitas" :limit="3" track-by="deskripsi" :options="combo.identitas.kecamatan" label="deskripsi" placeholder="[ Kecamatan ]">
                            </multiselect>
                          </b-form-group>
                          <b-form-group class="col-sm-6 mb-0 pl-1" label-for="kelurahandesa">
                            <multiselect v-model="vselected.id_desa" :show-labels="false" @input="valWilayah" :limit="3" track-by="deskripsi" :options="combo.identitas.desa" label="deskripsi" placeholder="[ Desa ]">
                            </multiselect>
                          </b-form-group>
                        </b-row>
                        <b-row class="mb-2">
                          <b-form-group class="col-md-4 mb-0 pr-1" label-for="rt">
                            <b-form-input class="cstm-cntrl-pegawai" v-model="pegawainew.pegawai.rt" type="number" placeholder="[ RT ]"></b-form-input>
                          </b-form-group>
                          <b-form-group class="col-md-4 mb-0 pr-1 pl-1" label-for="rw">
                            <b-form-input class="cstm-cntrl-pegawai" v-model="pegawainew.pegawai.rw" type="number" placeholder="[ RW ]"></b-form-input>
                          </b-form-group>
                          <b-form-group class="col-md-4 mb-0 pl-1" label-for="kodepos">
                            <b-form-input class="cstm-cntrl-pegawai" v-model="pegawainew.pegawai.kodepos" type="number" placeholder="[ Kode Pos ]"></b-form-input>
                          </b-form-group>
                        </b-row>
                        <b-row>
                          <b-form-group class="col-md-12 mb-0">
                            <b-form-textarea class="cstm-cntrl-pegawai-alamat" v-model="pegawainew.pegawai.alamat" id="alamattextarea" rows="1" placeholder="[ Alamat ]"></b-form-textarea>
                          </b-form-group>
                        </b-row>
                      </b-col>
                    </b-row>
                  <div>
                    <br>
                  </div>
                </div>
                </template>
              </iq-card>
              <iq-card class="py-3 px-4">
                <b-button variant="secondary"  class="mr-3" @click="backtolistpegawai"><i class="fas fa-undo-alt"></i> Kembali</b-button>
                <b-button variant="primary" @click="onSubmit"><i class="far fa-arrow-alt-circle-up"></i> Perbarui Data</b-button>
              </iq-card>
            </b-col>
            <b-col lg="4">
              <iq-card>
                <template v-slot:headerTitle>
                  <h6 class="card-title"><i class="fas fa-address-card"></i> Kartu Identitas</h6>
                </template>
                <template v-slot:body>
                  <b-form-group class="mb-2">
                    <multiselect v-model="vselected.id_kartu" :show-labels="false" :limit="3" @input="valKartu" label="deskripsi" placeholder="[ Jenis Kartu ]" :options="combo.kartu">
                    </multiselect>
                  </b-form-group>
                  <b-form-group class="mb-2">
                    <b-form-input class="cstm-cntrl-pegawai" v-model="pegawainew.identitas.nomor" placeholder="[ Nomor Kartu Identitas ]"></b-form-input>
                  </b-form-group>
                  <b-form-group class="mb-2">
                    <b-form-input class="cstm-cntrl-pegawai" v-model="pegawainew.identitas.nip" placeholder="[ N I P ]"></b-form-input>
                  </b-form-group>
                  <b-form-group class="mb-2" label-for="alamattextarea">
                    <b-form-textarea class="cstm-cntrl-pegawai-alamat" v-model="pegawainew.identitas.alamat" id="alamattextarea" rows="1" placeholder="[ Alamat ]"></b-form-textarea>
                  </b-form-group>
                  <b-row class="mb-2">
                    <b-form-group class="col-md-4 mb-0 pr-1" label-for="rt">
                      <b-form-input class="cstm-cntrl-pegawai" v-model="pegawainew.identitas.rt" type="number" placeholder="[ RT ]"></b-form-input>
                    </b-form-group>
                    <b-form-group class="col-md-4 mb-0 pr-1 pl-1" label-for="rw">
                      <b-form-input class="cstm-cntrl-pegawai" v-model="pegawainew.identitas.rw" type="number" placeholder="[ RW ]"></b-form-input>
                    </b-form-group>
                    <b-form-group class="col-md-4 mb-0 pl-1" label-for="kodepos">
                      <b-form-input class="cstm-cntrl-pegawai" v-model="pegawainew.identitas.kodepos" type="number" placeholder="[ Kode Pos ]"></b-form-input>
                    </b-form-group>
                  </b-row>
                    <b-form-group class="mb-2">
                      <multiselect v-model="vselected.vkartuidentitas.id_provinsi" :show-labels="false" @input="getkabupatenkartuidentitas" :limit="3" track-by="deskripsi" :options="combo.kartuidentitas.provinsi" label="deskripsi" placeholder="[ Provinsi ]">
                      </multiselect>
                    </b-form-group>
                    <b-form-group class="mb-2">
                      <multiselect v-model="vselected.vkartuidentitas.id_kabupaten" :show-labels="false" @input="getkecamatankartuidentitas" :limit="3" track-by="deskripsi" :options="combo.kartuidentitas.kabupaten" label="deskripsi" placeholder="[ Kabupaten ]">
                      </multiselect>
                    </b-form-group>
                    <b-form-group class="mb-2">
                      <multiselect v-model="vselected.vkartuidentitas.id_kecamatan" :show-labels="false" @input="getdesakartuidentitas" :limit="3" track-by="deskripsi" :options="combo.kartuidentitas.kecamatan" label="deskripsi" placeholder="[ Kecamatan ]">
                      </multiselect>
                    </b-form-group>
                    <b-form-group class="mb-0">
                      <multiselect v-model="vselected.vkartuidentitas.id_desa" :show-labels="false" @input="valWilayahKartu" :limit="3" track-by="deskripsi" :options="combo.kartuidentitas.desa" label="deskripsi" placeholder="[ Desa ]">
                      </multiselect>
                    </b-form-group>
                </template>
                <!-- <div class="px-4">
                  <pre>
                    {{ pegawainew }}
                  </pre>
                </div> -->
              </iq-card>
            </b-col>
          </b-row>
      </form>
      <ModalMsgBox ref="MsgBox" />
    </b-container>
  </div>
</template>

<script>
import { xray } from '../../../config/pluginInit'
import Multiselect from 'vue-multiselect'
import Service from '../../../services/fatmahost/index'
import Master from '../../../services/fatmahost/master/index'
import ModalMsgBox from '../../../components/modal/ModalMsgBox'
import FlatPickr from 'vue-flatpickr-component'
import 'flatpickr/dist/flatpickr.css'

export default {
  name: 'ProfileEdit',
  components: {
    Multiselect,
    ModalMsgBox,
    FlatPickr
  },
  mounted () {
    xray.index()
    // this.viewPegawai(this.$route.params.id)
    this.newViewPegawai(this.$route.params.id)
    this.getagama()
    this.getkartu()
    this.getprofesi()
    this.getsmf()
    this.getjk()
    this.gettempatlahir()
    this.getprovinsiidentitas()
    this.getprovinsikartuidentitas()
    this.getabios()
  },
  data () {
    return {
      vselected: {
        id_tempat_lahir: [],
        id_jenis_kelamin: [],
        id_kartu: [],
        id_agama: [],
        id_profesi: [],
        id_smf: [],
        id_provinsi: [],
        id_kabupaten: [],
        id_kecamatan: [],
        id_desa: [],
        id_status: [],
        id_bios: [],
        vkartuidentitas: {
          id_provinsi: [],
          id_kabupaten: [],
          id_kecamatan: [],
          id_desa: []
        }
      },
      combo: {
        agama: [],
        kartu: [],
        profesi: [],
        smf: [],
        jk: [],
        temlahir: [],
        bios: [],
        status: [
          { deskripsi: 'Aktif', id: '1' },
          { deskripsi: 'Tidak Aktif', id: '0' }
        ],
        identitas: {
          provinsi: [],
          kabupaten: [],
          kecamatan: [],
          desa: []
        },
        kartuidentitas: {
          provinsi: [],
          kabupaten: [],
          kecamatan: [],
          desa: []
        }
      },
      config: {
        disableMobile: true,
        maxDate: 'today'
      },
      pegawai: {
        nip: '',
        nama: '',
        panggilan: '',
        gelar_depan: '',
        gelar_belakang: '',
        tempat_lahir: '',
        tanggal_lahir: '',
        agama: '',
        jenis_kelamin: '',
        profesi: '',
        smf: '',
        alamat: '',
        rt: '',
        rw: '',
        kodepos: '',
        wilayah: '',
        status: '1',
        jenisIdentitas: '',
        nipIdentitas: '',
        nomorIdentitas: '',
        alamatIdentitas: '',
        rtIdentitas: '',
        rwIdentitas: '',
        kodeposIdentitas: '',
        wilayahIdentitas: ''
      },
      pegawainew: {
        pegawai: {
          nip: '',
          nama: '',
          panggilan: '',
          gelar_depan: '',
          gelar_belakang: '',
          tempat_lahir: '',
          tanggal_lahir: '',
          agama: '',
          jenis_kelamin: '',
          profesi: '',
          smf: '',
          alamat: '',
          rt: '',
          rw: '',
          kodepos: '',
          wilayah: '',
          status: '',
          flagbios: ''
        },
        identitas: {
          jenis: '',
          nip: '',
          nomor: '',
          alamat: '',
          rt: '',
          rw: '',
          kodepos: '',
          wilayah: ''
        }
      },
      isLoading: false
    }
  },
  computed: {
    nikIsValid () {
      return (
        this.pegawainew.pegawai.nip !== '' && this.pegawainew.pegawai.nip != null
      )
    },
    gelardepanIsValid () {
      return (
        this.pegawainew.pegawai.gelar_depan !== '' && this.pegawainew.pegawai.gelar_depan != null
      )
    },
    namaIsValid () {
      return (
        this.pegawainew.pegawai.nama !== '' && this.pegawainew.pegawai.nama != null
      )
    },
    panggilanIsValid () {
      return (
        this.pegawainew.pegawai.panggilan !== '' && this.pegawainew.pegawai.panggilan != null
      )
    },
    gelarbelakangIsValid () {
      return (
        this.pegawainew.pegawai.gelar_belakang !== '' && this.pegawainew.pegawai.gelar_belakang != null
      )
    },
    tlIsValid () {
      return (
        this.pegawainew.pegawai.tempat_lahir !== '' && this.pegawainew.pegawai.tempat_lahir != null
      )
    },
    tglIsValid () {
      return (
        this.pegawainew.pegawai.tanggal_lahir !== '' && this.pegawainew.pegawai.tanggal_lahir != null
      )
    },
    jkIsValid () {
      return (
        this.pegawainew.pegawai.jenis_kelamin !== '' && this.pegawainew.pegawai.jenis_kelamin != null
      )
    },
    agamaIsValid () {
      return (
        this.pegawainew.pegawai.agama !== '' && this.pegawainew.pegawai.agama != null
      )
    },
    profesiIsValid () {
      return (
        this.pegawainew.pegawai.profesi !== '' && this.pegawainew.pegawai.profesi != null
      )
    },
    smfIsValid () {
      return (
        this.pegawainew.pegawai.smf !== '' && this.pegawainew.pegawai.smf != null
      )
    },
    statusIsValid () {
      return (
        this.pegawainew.pegawai.status !== '' && this.pegawainew.pegawai.status != null
      )
    },
    alamatIsValid () {
      return (
        this.pegawainew.pegawai.alamat !== '' && this.pegawainew.pegawai.alamat != null
      )
    },
    rtIsValid () {
      return (
        this.pegawainew.pegawai.rt !== '' && this.pegawainew.pegawai.rt != null
      )
    },
    rwIsValid () {
      return (
        this.pegawainew.pegawai.rw !== '' && this.pegawainew.pegawai.rw != null
      )
    },
    kodeposIsValid () {
      return (
        this.pegawainew.pegawai.kodepos !== '' && this.pegawainew.pegawai.kodepos != null
      )
    },
    provinsiIsValid () {
      return (
        this.vselected.id_provinsi.id !== '' && this.vselected.id_provinsi.id != null
      )
    },
    kabupatenIsValid () {
      return (
        this.vselected.id_kabupaten.id !== '' && this.vselected.id_kabupaten.id != null
      )
    },
    kecamatanIsValid () {
      return (
        this.vselected.id_kecamatan.id !== '' && this.vselected.id_kecamatan.id != null
      )
    },
    desaIsValid () {
      return (
        this.vselected.id_desa.id !== '' && this.vselected.id_desa.id != null
      )
    },
    kartuIsValid () {
      return (
        this.vselected.id_kartu.id_referensi !== '' && this.vselected.id_kartu.id_referensi != null
      )
    },
    nipIsValid () {
      return (
        this.vselected.id_kartu.id_referensi !== '' && this.vselected.id_kartu.id_referensi != null
      )
    },
    nomorIsValid () {
      return (
        this.pegawainew.identitas.nomor !== '' && this.pegawainew.identitas.nomor != null
      )
    },
    alamatIdentitasIsValid () {
      return (
        this.pegawainew.identitas.alamat !== '' && this.pegawainew.identitas.alamat != null
      )
    },
    rtIdentitasIsValid () {
      return (
        this.pegawainew.identitas.rt !== '' && this.pegawainew.identitas.rt != null
      )
    },
    rwIdentitasIsValid () {
      return (
        this.pegawainew.identitas.rw !== '' && this.pegawainew.identitas.rw != null
      )
    },
    kodeposIdentitasIsValid () {
      return (
        this.pegawainew.identitas.kodepos !== '' && this.pegawainew.identitas.kodepos != null
      )
    },
    identitasProvinsi () {
      return (
        this.vselected.vkartuidentitas.id_provinsi.id !== '' && this.vselected.vkartuidentitas.id_provinsi.id != null
      )
    },
    identitasKabupaten () {
      return (
        this.vselected.vkartuidentitas.id_kabupaten.id !== '' && this.vselected.vkartuidentitas.id_kabupaten.id != null
      )
    },
    identitasKecamatan () {
      return (
        this.vselected.vkartuidentitas.id_kecamatan.id !== '' && this.vselected.vkartuidentitas.id_kecamatan.id != null
      )
    },
    identitasDesa () {
      return (
        this.vselected.vkartuidentitas.id_desa.id !== '' && this.vselected.vkartuidentitas.id_desa.id != null
      )
    }
  },
  methods: {
    async newViewPegawai (id) {
      await Service.pegawai.findId(id).then(response => {
        this.pegawainew.pegawai.nip = response.data.data[0].NIP
        this.pegawainew.pegawai.gelar_depan = response.data.data[0].GELAR_DEPAN
        this.pegawainew.pegawai.nama = response.data.data[0].NAMA
        this.pegawainew.pegawai.tanggal_lahir = response.data.data[0].TANGGAL_LAHIR
        this.pegawainew.pegawai.tempat_lahir = response.data.data[0].TEMPAT_LAHIR
        this.vselected.id_tempat_lahir = { deskripsi: response.data.data[0].TEMPAT_LAHIR, id_referensi: '0' }
        this.pegawainew.pegawai.panggilan = response.data.data[0].PANGGILAN
        this.pegawainew.pegawai.gelar_belakang = response.data.data[0].GELAR_BELAKANG
        this.vselected.id_jenis_kelamin = response.data.data[0].JENIS_KELAMIN === null ? [] : { deskripsi: response.data.data[0].NAMA_JENKEL, id_referensi: response.data.data[0].JENIS_KELAMIN }
        this.pegawainew.pegawai.jenis_kelamin = response.data.data[0].JENIS_KELAMIN
        this.vselected.id_agama = response.data.data[0].AGAMA === null ? [] : { deskripsi: response.data.data[0].NAMA_AGAMA, id: response.data.data[0].AGAMA }
        this.pegawainew.pegawai.agama = response.data.data[0].AGAMA.toString()
        this.vselected.id_profesi = response.data.data[0].PROFESI === null ? [] : { deskripsi: response.data.data[0].NAMA_PROFESI, id_referensi: response.data.data[0].PROFESI }
        this.pegawainew.pegawai.profesi = response.data.data[0].PROFESI.toString()
        this.vselected.id_smf = response.data.data[0].SMF === null ? [] : { deskripsi: response.data.data[0].NAMA_SMF, id_referensi: response.data.data[0].SMF }
        this.pegawainew.pegawai.smf = response.data.data[0].SMF.toString()
        this.pegawainew.pegawai.alamat = response.data.data[0].ALAMAT
        this.pegawainew.pegawai.rt = response.data.data[0].RT
        this.pegawainew.pegawai.rw = response.data.data[0].RW
        this.pegawainew.pegawai.kodepos = response.data.data[0].KODEPOS
        this.vselected.id_provinsi = response.data.data[0].ID_PROVINSI === null ? [] : { deskripsi: response.data.data[0].NAMA_PROVINSI, id_referensi: response.data.data[0].ID_PROVINSI }
        this.vselected.id_kabupaten = response.data.data[0].ID_KABUPATEN === null ? [] : { deskripsi: response.data.data[0].NAMA_KABUPATEN, id_referensi: response.data.data[0].ID_KABUPATEN }
        this.vselected.id_kecamatan = response.data.data[0].ID_KECAMATAN === null ? [] : { deskripsi: response.data.data[0].NAMA_KECAMATAN, id_referensi: response.data.data[0].ID_KECAMATAN }
        this.vselected.id_desa = response.data.data[0].ID_KELURAHAN === null ? [] : { deskripsi: response.data.data[0].NAMA_KELURAHAN, id_referensi: response.data.data[0].ID_KELURAHAN }
        this.pegawainew.pegawai.wilayah = response.data.data[0].WILAYAH
        this.vselected.id_status = response.data.data[0].status === '1' ? { deskripsi: 'Aktif', id_referensi: '1' } : { deskripsi: 'Tidak Aktif', id_referensi: '0' }
        this.pegawainew.pegawai.status = response.data.data[0].STATUS.toString()
        this.vselected.id_bios = response.data.data[0].FLAGBIOS === null ? [] : { deskripsi: response.data.data[0].NAMA_BIOS, id_referensi: response.data.data[0].FLAGBIOS }
        this.pegawainew.pegawai.flagbios = response.data.data[0].FLAGBIOS.toString()
        this.vselected.id_kartu = response.data.data[0].JENIS === null ? [] : { deskripsi: response.data.data[0].NAMA_JENIS, id_referensi: response.data.data[0].JENIS }
        this.pegawainew.identitas.jenis = response.data.data[0].JENIS.toString()
        this.pegawainew.identitas.nomor = response.data.data[0].NOMOR
        this.pegawainew.identitas.nip = response.data.data[0].NIP
        this.pegawainew.identitas.alamat = response.data.data[0].ALAMAT
        this.pegawainew.identitas.rt = response.data.data[0].RT
        this.pegawainew.identitas.rw = response.data.data[0].RW
        this.pegawainew.identitas.kodepos = response.data.data[0].KODEPOS
        this.vselected.vkartuidentitas.id_provinsi = response.data.data[0].ID_PROVINSI_IDENTITAS === null ? [] : { deskripsi: response.data.data[0].NAMA_PROVINSI_IDENTITAS, id_referensi: response.data.data[0].ID_PROVINSI_IDENTITAS }
        this.vselected.vkartuidentitas.id_kabupaten = response.data.data[0].ID_KABUPATEN_IDENTITAS === null ? [] : { deskripsi: response.data.data[0].NAMA_KABUPATEN_IDENTITAS, id_referensi: response.data.data[0].ID_KABUPATEN_IDENTITAS }
        this.vselected.vkartuidentitas.id_kecamatan = response.data.data[0].ID_KECAMATAN_IDENTITAS === null ? [] : { deskripsi: response.data.data[0].NAMA_KECAMATAN_IDENTITAS, id_referensi: response.data.data[0].ID_KECAMATAN_IDENTITAS }
        this.vselected.vkartuidentitas.id_desa = response.data.data[0].ID_KELURAHAN_IDENTITAS === null ? [] : { deskripsi: response.data.data[0].NAMA_KELURAHAN_IDENTITAS, id_referensi: response.data.data[0].ID_KELURAHAN_IDENTITAS }
        this.pegawainew.identitas.wilayah = response.data.data[0].WILAYAH_IDENTITAS
      }).catch(error => {
        this.error = error.response || error.response
      })
    },
    valTempatLahir (value) {
      this.pegawainew.pegawai.tempat_lahir = value.deskripsi
    },
    valJenisKelamin (value) {
      this.pegawainew.pegawai.jenis_kelamin = value.id.toString()
    },
    valAgama (value) {
      this.pegawainew.pegawai.agama = value.id.toString()
    },
    valProfesi (value) {
      this.pegawainew.pegawai.profesi = value.id.toString()
    },
    valSmf (value) {
      this.pegawainew.pegawai.smf = value.id.toString()
    },
    valWilayah (value) {
      this.pegawainew.pegawai.wilayah = value.id.toString()
    },
    valKartu (value) {
      this.pegawainew.identitas.jenis = value.id.toString()
    },
    valWilayahKartu (value) {
      this.pegawainew.identitas.wilayah = value.id.toString()
    },
    valStatus (value) {
      this.pegawainew.pegawai.status = value.id.toString()
    },
    valBios (value) {
      this.pegawainew.pegawai.flagbios = value.id.toString()
    },
    async getabios () {
      await Master.referensi('177').then(response => {
        if (response.status === 200) {
          this.combo.bios = response.data.data
        }
      }).catch(error => {
        this.error = error.response
      })
    },
    async getagama () {
      await Master.referensi('1').then(response => {
        if (response.status === 200) {
          this.combo.agama = response.data.data
        }
      }).catch(error => {
        this.error = error.response
      })
    },
    async getkartu () {
      await Master.referensi('9').then(response => {
        if (response.status === 200) {
          this.combo.kartu = response.data.data
        }
      }).catch(error => {
        this.error = error.response
      })
    },
    async getprofesi () {
      await Master.referensi('36').then(response => {
        if (response.status === 200) {
          this.combo.profesi = response.data.data
        }
      }).catch(error => {
        this.error = error.response
      })
    },
    async getsmf () {
      await Master.referensi('26').then(response => {
        if (response.status === 200) {
          this.combo.smf = response.data.data
        }
      }).catch(error => {
        this.error = error.response
      })
    },
    async getjk () {
      await Master.referensi('2').then(response => {
        if (response.status === 200) {
          this.combo.jk = response.data.data
        }
      }).catch(error => {
        this.error = error.response
      })
    },
    async gettempatlahir () {
      await Master.tempat_lahir().then(response => {
        if (response.status === 200) {
          this.combo.temlahir = response.data.data
        }
      }).catch(error => {
        this.error = error.response
      })
    },
    async getprovinsiidentitas () {
      await Master.provinsi('1').then(response => {
        if (response.status === 200) {
          this.combo.identitas.provinsi = response.data.data
        }
      }).catch(error => {
        this.error = error.response
      })
    },
    async getprovinsikartuidentitas () {
      await Master.provinsi('1').then(response => {
        if (response.status === 200) {
          this.combo.kartuidentitas.provinsi = response.data.data
        }
      }).catch(error => {
        this.error = error.response
      })
    },
    async getkabupatenidentitas (value) {
      const data = value.id
      await Master.kabupaten(data).then(response => {
        if (response.status === 200) {
          this.combo.identitas.kabupaten = response.data.data
        }
      }).catch(error => {
        this.error = error.response
      })
    },
    async getkabupatenkartuidentitas (value) {
      const data = value.id
      await Master.kabupaten(data).then(response => {
        if (response.status === 200) {
          this.combo.kartuidentitas.kabupaten = response.data.data
        }
      }).catch(error => {
        this.error = error.response
      })
    },
    async getkecamatanidentitas (value) {
      const data = value.id
      await Master.kecamatan(data).then(response => {
        if (response.status === 200) {
          this.combo.identitas.kecamatan = response.data.data
        }
      }).catch(error => {
        this.error = error.response
      })
    },
    async getkecamatankartuidentitas (value) {
      const data = value.id
      await Master.kecamatan(data).then(response => {
        if (response.status === 200) {
          this.combo.kartuidentitas.kecamatan = response.data.data
        }
      }).catch(error => {
        this.error = error.response
      })
    },
    async getdesaidentitas (value) {
      const data = value.id
      await Master.desa(data).then(response => {
        if (response.status === 200) {
          this.combo.identitas.desa = response.data.data
        }
      }).catch(error => {
        this.error = error.response
      })
    },
    async getdesakartuidentitas (value) {
      const data = value.id
      await Master.desa(data).then(response => {
        if (response.status === 200) {
          this.combo.kartuidentitas.desa = response.data.data
        }
      }).catch(error => {
        this.error = error.response
      })
    },
    async onSubmit () {
      this.isLoading = true
      const data = this.pegawainew
      await Service.pegawai.store(data).then(response => {
        this.isLoading = false
        this.$refs.MsgBox.show({
          type: 'success',
          msg: response.data.message
        })
        this.clearForm()
      }).catch(error => {
        this.isLoading = false
        this.$refs.MsgBox.show({
          type: 'error',
          msg: error.response.data.errors.serverMessage
        })
      })
    },
    backtolistpegawai () {
      this.$router.push({ name: 'pegawai.list' })
    }
  }
}
</script>
<style>
.pegawai-edit-form .iq-card .iq-card-header {
    background: #ceebee!important;
}
.pegawai-edit-form .form-control {
    color: black!important;
    font-weight: bold!important;
    border: 1px solid rgba(0, 0, 0, 0.301)!important;
}
.pegawai-edit-form .multiselect__placeholder {
    font-weight: bold!important;
}
.pegawai-edit-form .multiselect__input, .multiselect__single {
  color: black!important;
  font-weight: bold!important;
  font-size: 14px!important;
}
.pegawai-edit-form .form-control-isvalid {
  border: 1px solid green!important;
}
.pegawai-edit-form .form-control-isnotvalid {
  border: 1px solid #cf0000!important;
}
.pegawai-edit-form .multiselect__tags {
  border-radius: 10px!important;
  border: 1px solid rgba(0, 0, 0, 0.301);
}
.pegawai-edit-form .multiselect__tags-isvalid {
  border: 1px solid green!important;
  border-radius: 10px!important;
}
.pegawai-edit-form .multiselect__tags-isnotvalid {
  border: 1px solid #cf0000!important;
  border-radius: 10px!important;
}
.pegawai-edit-form .flatpickr-cstm-lab {
  min-height: 38px!important;
  height: 38px!important;
  line-height: 38px!important;
  border: 1px solid rgba(0, 0, 0, 0.301);
  border-radius: 5px!important;
  padding-left: 10px!important;
  padding-right: 10px!important;
  color: #000000!important;
  font-weight: bold!important;
  font-size: 12px;
}
.pegawai-edit-form .cstm-cntrl-pegawai {
  color: black!important;
  font-weight: bold!important;
  border-radius: 5px!important;
  font-size: 12px!important;
  height: 38px!important;
}
.pegawai-edit-form .cstm-cntrl-pegawai-alamat {
  color: black!important;
  font-weight: bold!important;
  border-radius: 5px!important;
  font-size: 12px!important;
}
.pegawai-edit-form .custom-control-label {
    font-size: 12px;
    margin-top: 0;
}
.pegawai-edit-form .multiselect__single {
  font-size: 12px!important;
  border-radius: 5px!important;
}
.pegawai-edit-form .multiselect__placeholder {
  border-radius: 5px!important;
  font-size: 12px!important;
}
.pegawai-edit-form .multiselect__option {
  border-radius: 5px!important;
    font-size: 12px;
}
.pegawai-edit-form .multiselect__tags {
  border-radius: 5px!important;
  padding: 5px 40px 0 8px!important;
  height: 38px!important;
  min-height: 38px!important;
}
</style>
