<template>
  <div id="laporan-instalasi">
    <b-container fluid>
      <b-row class="mb-4">
        <b-col>
          <div>
            <b-card no-body>
              <b-tabs pills card>
                <b-tab title="KASIR MANDIRI" v-if="bitcontrol.bitcontrol2.charAt(15) === '1' || bitcontrol.bitcontrol2.charAt(15) === '0'">
                  <b-card-text>
                    <div>
                      <ComponentMhas />
                    </div>
                  </b-card-text>
                </b-tab>
                <b-tab title="RESERVASI GRIYA HUSADA" v-if="bitcontrol.bitcontrol2.charAt(15) === '2' || bitcontrol.bitcontrol2.charAt(15) === '0'">
                  <b-card-text>
                    <ComponentIgh />
                  </b-card-text>
                </b-tab>
                <b-tab title="RESERVASI RAWAT JALAN" v-if="bitcontrol.bitcontrol2.charAt(15) === '3' || bitcontrol.bitcontrol2.charAt(15) === '0'">
                  <b-card-text>
                    <ComponentIrj />
                  </b-card-text>
                </b-tab>
              </b-tabs>
            </b-card>
          </div>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import { xray } from '../../config/pluginInit'
import ComponentMhas from './ComponentMhas.vue'
import ComponentIgh from './ComponentIgh.vue'
import ComponentIrj from './ComponentIrj.vue'

export default {
  name: 'EmbeddedPage',
  components: {
    ComponentMhas,
    ComponentIgh,
    ComponentIrj
  },
  data () {
    return {
      bitcontrol: xray.bitcontrol(this.$route.name)
    }
  },
  mounted () {
    xray.index()
  }
}
</script>

<style>
  #laporan-instalasi .card-header {
    background: white;
  }
  #laporan-instalasi .card-body {
    padding: 0px;
  }
</style>
