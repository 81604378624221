<template>
  <div class="emr-eresep-daftar-order">
    <!-- <div class="arrow bg-primary p-2">
      <span class="h6"><i class="fas fa-prescription-bottle-alt"></i> &nbsp; Form Resep</span>
    </div> -->
    <div class="overflow-emr" :style="{height: heightPercent}">
      <b-row>
        <b-col cols="12">
          <div>
            <b-card no-body>
              <b-tabs pills card>
                <b-tab title="ORDER" active>
                  <b-row>
                    <b-col cols="12">
                      <div class="shadow-sm border m-2">
                        <div class="eresep-head p-2">
                          <p class="m-0 p-0 font-weight-bold text-dark">FORM ORDER</p>
                        </div>
                        <hr class="m-0 p-0">
                        <div class="eresep-body p-2">
                          <b-row>
                            <b-col cols="12" lg="3" class="pr-1 mb-2">
                              <b-form-input
                                class="form-control-emr"
                                type="text"
                                placeholder="Tanggal"
                                required
                              ></b-form-input>
                            </b-col>
                            <b-col cols="12" lg="3" class="px-1 mb-2">
                              <multiselect :options="options.depo"
                                label="deskripsi" placeholder="Pilih Depo Layanan">
                              </multiselect>
                            </b-col>
                            <b-col cols="12" lg="3" class="px-1 mb-2">
                              <multiselect :options="options.pemberi_resep"
                                label="deskripsi" placeholder="Dokter Pemberi Resep">
                              </multiselect>
                            </b-col>
                            <b-col cols="12" lg="3" class="pl-1 mb-2">
                              <b-form-input
                                class="form-control-emr"
                                type="text"
                                placeholder="Pemberi Resep"
                                required
                              ></b-form-input>
                            </b-col>
                            <b-col cols="12" lg="2" class="pr-1">
                              <b-form-input
                                class="form-control-emr"
                                type="text"
                                placeholder="Berat Badan"
                                required
                              ></b-form-input>
                            </b-col>
                            <b-col cols="12" lg="2" class="px-1">
                              <b-form-input
                                class="form-control-emr"
                                type="text"
                                placeholder="Tinggi Badan"
                                required
                              ></b-form-input>
                            </b-col>
                            <b-col cols="12" lg="3" class="px-1">
                              <b-form-input
                                class="form-control-emr"
                                type="text"
                                placeholder="Alergi Obat"
                                required
                              ></b-form-input>
                            </b-col>
                            <b-col cols="12" lg="5" class="pl-1">
                              <b-form-input
                                class="form-control-emr"
                                type="text"
                                placeholder="Diagnosa"
                                required
                              ></b-form-input>
                            </b-col>
                          </b-row>
                        </div>
                        <hr class="m-0 p-0">
                        <div class="eresep-footer p-2">
                          <b-row>
                            <b-col cols="2">
                              <div class="d-flex flex-row">
                                <div class="mr-2">
                                  <p class="m-0 p-0 text-dark">Gangguan Ginjal ?</p>
                                </div>
                                <div>
                                  <b-form-checkbox
                                    v-model="status"
                                    value="accepted"
                                    unchecked-value="not_accepted"
                                  >
                                  </b-form-checkbox>
                                </div>
                              </div>
                            </b-col>
                            <b-col cols="2">
                              <div class="d-flex flex-row">
                                <div class="mr-2">
                                  <p class="m-0 p-0 text-dark">Hamil ?</p>
                                </div>
                                <div>
                                  <b-form-checkbox
                                    v-model="status"
                                    value="accepted"
                                    unchecked-value="not_accepted"
                                  >
                                  </b-form-checkbox>
                                </div>
                              </div>
                            </b-col>
                            <b-col cols="2">
                              <div class="d-flex flex-row">
                                <div class="mr-2">
                                  <p class="m-0 p-0 text-dark">Menyusui ?</p>
                                </div>
                                <div>
                                  <b-form-checkbox
                                    v-model="status"
                                    value="accepted"
                                    unchecked-value="not_accepted"
                                  >
                                  </b-form-checkbox>
                                </div>
                              </div>
                            </b-col>
                            <b-col cols="2">
                              <div class="d-flex flex-row">
                                <div class="mr-2">
                                  <p class="m-0 p-0 text-dark">Resep Pasien Pulang ?</p>
                                </div>
                                <div>
                                  <b-form-checkbox
                                    v-model="status"
                                    value="accepted"
                                    unchecked-value="not_accepted"
                                  >
                                  </b-form-checkbox>
                                </div>
                              </div>
                            </b-col>
                            <b-col cols="2">
                              <div class="d-flex flex-row">
                                <div class="mr-2">
                                  <p class="m-0 p-0 text-dark">Cito ?</p>
                                </div>
                                <div>
                                  <b-form-checkbox
                                    v-model="status"
                                    value="accepted"
                                    unchecked-value="not_accepted"
                                  >
                                  </b-form-checkbox>
                                </div>
                              </div>
                            </b-col>
                          </b-row>
                        </div>
                      </div>
                    </b-col>
                    <b-col cols="4" class="pr-1">
                      <div class="shadow-sm border m-2">
                        <b-card no-body>
                          <b-tabs pills card>
                            <b-tab title="NON RACIK" active>
                              <div class="p-2">
                                <b-row class="align-items-center">
                                  <b-col cols="8" class="pr-1">
                                    <multiselect :options="options.depo"
                                      label="deskripsi" placeholder="Nama Obat">
                                    </multiselect>
                                  </b-col>
                                  <b-col cols="4" class="pl-1">
                                    <b-form-input
                                      class="form-control-emr"
                                      type="text"
                                      placeholder="Jumlah"
                                      required
                                    ></b-form-input>
                                  </b-col>
                                  <b-col cols="5" class="pr-1">
                                    <b-form-input
                                      class="form-control-emr"
                                      type="text"
                                      placeholder="Signa 1"
                                      required
                                    ></b-form-input>
                                  </b-col>
                                  <b-col cols="2" class="text-center px-1">
                                    <p class="h4 m-0 p-0 text-dark">X</p>
                                  </b-col>
                                  <b-col cols="5" class="mt-2 pl-1">
                                    <b-form-input
                                      class="form-control-emr"
                                      type="text"
                                      placeholder="Signa 2"
                                      required
                                    ></b-form-input>
                                  </b-col>
                                  <b-col cols="12" class="mt-2">
                                    <b-form-input
                                      class="form-control-emr"
                                      type="text"
                                      placeholder="Aturan Pakai"
                                      required
                                    ></b-form-input>
                                  </b-col>
                                  <b-col cols="12" class="mt-2">
                                    <b-form-input
                                      class="form-control-emr"
                                      type="text"
                                      placeholder="Keterangan"
                                      required
                                    ></b-form-input>
                                  </b-col>
                                </b-row>
                              </div>
                            </b-tab>
                            <b-tab title="RACIK">
                              <div class="p-2">
                                <b-row class="align-items-center">
                                  <b-col cols="6" class="pr-1">
                                    <b-form-input
                                      class="form-control-emr"
                                      type="text"
                                      placeholder="Nama Obat"
                                      required
                                    ></b-form-input>
                                  </b-col>
                                  <b-col cols="4" class="px-1">
                                    <b-form-input
                                      class="form-control-emr"
                                      type="text"
                                      placeholder="Dosis"
                                      required
                                    ></b-form-input>
                                  </b-col>
                                  <b-col cols="2" class="pl-1">
                                    <b-button variant="primary" size="sm" class="w-100 py-1">
                                      <p class="m-0 p-0 h5">+</p>
                                    </b-button>
                                  </b-col>
                                  <b-col cols="5" class="mt-2 pr-1">
                                    <b-form-input
                                      class="form-control-emr"
                                      type="text"
                                      placeholder="Signa 1"
                                      required
                                    ></b-form-input>
                                  </b-col>
                                  <b-col cols="2" class="text-center mt-2 px-1">
                                    <p class="h4 m-0 p-0 text-dark">X</p>
                                  </b-col>
                                  <b-col cols="5" class="mt-2 pl-1">
                                    <b-form-input
                                      class="form-control-emr"
                                      type="text"
                                      placeholder="Signa 2"
                                      required
                                    ></b-form-input>
                                  </b-col>
                                  <b-col cols="12" class="mt-2">
                                    <b-form-input
                                      class="form-control-emr"
                                      type="text"
                                      placeholder="Aturan Pakai"
                                      required
                                    ></b-form-input>
                                  </b-col>
                                  <b-col cols="6" class="mt-2 pr-1">
                                    <b-form-input
                                      class="form-control-emr"
                                      type="text"
                                      placeholder="Petunjuk Racikan"
                                      required
                                    ></b-form-input>
                                  </b-col>
                                  <b-col cols="6" class="mt-2 pl-1">
                                    <b-form-input
                                      class="form-control-emr"
                                      type="text"
                                      placeholder="Jumlah Racikan"
                                      required
                                    ></b-form-input>
                                  </b-col>
                                  <b-col cols="12" class="mt-2">
                                    <b-form-input
                                      class="form-control-emr"
                                      type="text"
                                      placeholder="Keterangan"
                                      required
                                    ></b-form-input>
                                  </b-col>
                                </b-row>
                              </div>
                            </b-tab>
                          </b-tabs>
                        </b-card>
                        <hr class="m-0 p-0">
                        <div class="p-2 text-right">
                          <b-button variant="primary" size=sm>BUAT RESEP</b-button>
                        </div>
                      </div>
                    </b-col>
                    <b-col cols="8" class="pl-1">
                      <div class="shadow-sm border m-2">
                        <div class="eresep-head p-2">
                          <p class="m-0 p-0 font-weight-bold text-dark">DETAIL ORDER</p>
                        </div>
                        <hr class="m-0 p-0">
                        <div class="eresep-body p-0">
                          <b-table class="mb-0"
                            ref="table"
                            select-mode="single"
                            hover small show-empty selectable
                            :items="items.table">
                            <template #table-busy>
                              <div class="text-center my-2">
                                <b-spinner class="align-middle text-primary mr-2"></b-spinner>
                                <strong class="text-dark">Memuat Data...</strong>
                              </div>
                            </template>
                          </b-table>
                        </div>
                      </div>
                    </b-col>
                  </b-row>
                </b-tab>
                <b-tab title="DAFTAR ORDER">
                  <div class="p-2">
                    <b-row>
                      <b-col>
                        <b-table class="mb-0"
                          ref="table"
                          select-mode="single"
                          hover small show-empty selectable
                          :items="items.table">
                          <template #table-busy>
                            <div class="text-center my-2">
                              <b-spinner class="align-middle text-primary mr-2"></b-spinner>
                              <strong class="text-dark">Memuat Data...</strong>
                            </div>
                          </template>
                        </b-table>
                      </b-col>
                      <b-col>
                        <b-table class="mb-0"
                          ref="table"
                          select-mode="single"
                          hover small show-empty selectable
                          :items="items.table">
                          <template #table-busy>
                            <div class="text-center my-2">
                              <b-spinner class="align-middle text-primary mr-2"></b-spinner>
                              <strong class="text-dark">Memuat Data...</strong>
                            </div>
                          </template>
                        </b-table>
                      </b-col>
                    </b-row>
                  </div>
                </b-tab>
              </b-tabs>
            </b-card>
          </div>
        </b-col>
      </b-row>
    </div>
    <div class="arrow bg-white border p-2 text-right">
      <b-button variant="primary" size="sm" class="px-4">FINAL</b-button>
    </div>
  </div>
</template>
<script>
import { xray } from '../../../../config/pluginInit.js'
import Services from '../../../../services/fatmahost/index.js'
import Multiselect from 'vue-multiselect'

export default {
  name: 'EResepDaftarOrder',
  components: {
    Multiselect
  },
  data () {
    return {
      windowHeight: 0,
      heightPercent: 0,
      formOrder: '6',
      formDetail: '6',
      message: '',
      selected: [],
      fields: {
        table: [
          { key: 'no_urut', label: 'No.', class: 'text-uppercase text-center' },
          { key: 'no_resep', label: 'No. Resep', class: 'text-uppercase' },
          { key: 'asal', label: 'Asal / Unit', class: 'text-uppercase' },
          { key: 'tujuan', label: 'Tujuan', class: 'text-uppercase' },
          { key: 'tanggal', label: 'Tgl. Resep', class: 'text-uppercase' },
          { key: 'dpjp', label: 'Dpjp', class: 'text-uppercase' }
        ],
        table2: [
          { key: 'no_urut', label: 'No.', class: 'text-uppercase text-center' },
          { key: 'kemasan', label: 'Kemasan', class: 'text-uppercase' },
          { key: 'nama_obat', label: 'Nama Obat', class: 'text-uppercase' },
          { key: 'jumlah', label: 'Jumlah', class: 'text-uppercase' },
          { key: 'dosisi', label: 'Dosis', class: 'text-uppercase' },
          { key: 'aturan_pakai', label: 'Aturan Pakai', class: 'text-uppercase' }
        ]
      },
      items: {
        table: [],
        table2: []
      },
      options: {
        depo: [],
        pemberi_resep: []
      }
    }
  },
  mounted () {
    xray.index()
    this.getWindowHeight()
    window.addEventListener('resize', this.getWindowHeight)
  },
  destroyed () {
    window.removeEventListener('resize', this.getWindowHeight)
  },
  methods: {
    async getTableOrder () {
      try {
        const param1 = ``
        const param2 = `{"object":"SIMRS_EMR_RORDERRESEP","xparam0":"${this.$route.params.id}","xparam1":"","xparam2":"","xparam3":"","xparam4":"","xparam5":"","xparam6":"","xparam7":"","xparam8":"","xparam9":""}`
        const response = await Services.middleware.find(param1, param2)
        return response.data.data
      } catch (error) {
        this.message = error
      }
    },
    async getDetailOrder (item, index, event) {
      try {
        const param1 = ``
        const param2 = `{"object":"SIMRS_EMR_RORDERRESEP_RINC","xparam0":"${item.id}","xparam1":"","xparam2":"","xparam3":"","xparam4":"","xparam5":"","xparam6":"","xparam7":"","xparam8":"","xparam9":""}`
        const response = await Services.middleware.find(param1, param2)
        this.items.table2 = response.data.data
      } catch (error) {
        this.message = error
      }
    },
    getWindowHeight () {
      this.windowHeight = window.innerHeight
      var calc = (50 / 100) * this.windowHeight
      this.heightPercent = calc + 'px'
    },
    closeRightForm () {
      this.formOrder = '11'
      this.formDetail = '1'
    },
    openRightForm () {
      this.formOrder = '8'
      this.formDetail = '4'
    }
  }
}
</script>

<style>

.form-control-emr {
  height: 33px!important;
  line-height: 33px!important;
  border-radius: 5px!important;
  font-size: 12px!important;
}

.emr-eresep-daftar-order .overflow-emr {
  overflow-y: scroll;
  overflow-x: hidden;
}

.emr-eresep-daftar-order .card-header {
    padding: 0.5rem 1rem!important;
}

.emr-eresep-daftar-order .radius-custom-emr {
  border-radius: .5rem;
}

.emr-eresep-daftar-order .radius-custom-emr-head {
  border-top-left-radius: .5rem;
  border-top-right-radius: .5rem;
}

.emr-eresep-daftar-order .radius-custom-form-head-eresep-daftar-order {
  border-top-right-radius: .5rem;
}

.emr-eresep-daftar-order .eresep-daftar-order-fc-black {
  color: black;
  font-weight: bold;
}

.emr-eresep-daftar-order .eresep-daftar-order-form-input {
  font-size: 13px;
  height: 33px!important;
  border-radius: 5px;
}

.emr-eresep-daftar-order .table td {
    font-size: 13px;
    padding-top: 0px;
    padding-bottom: 0px;
}
.emr-eresep-daftar-order .table th {
    font-size: 13px;
    font-weight: bold;
}

.emr-eresep-daftar-order .table td, .emr-eresep-daftar-order .table th {
    padding: 0.20rem!important;
}

.emr-eresep-daftar-order .hover {
  transition: all .3s;
  cursor: pointer;
  background-color: rgb(255, 255, 255);
  color: rgb(107, 107, 107);
}

.emr-eresep-daftar-order .arrow:hover .hover {
  background-color: rgba(0, 0, 0, 0.596);
  color: rgb(255, 255, 255);
}

.emr-eresep-daftar-order .multiselect__single {
  font-size: 13px !important;
}
.emr-eresep-daftar-order .multiselect__placeholder {
  font-weight: normal !important;
  font-size: 13px !important;
  padding-top: 0px!important;
}
.emr-eresep-daftar-order .multiselect__option {
  font-size: 13px;
}
.emr-eresep-daftar-order .multiselect__tags {
  padding: 5px 40px 0 8px !important;
  height: 33px !important;
  min-height: 33px !important;
  border: 1px solid rgb(214, 214, 214);
}
.emr-eresep-daftar-order .multiselect__content-wrapper {
  font-size: 12px;
  font-weight: normal !important;
}
</style>
