<template>
    <div id="master-libur-exclude" class="mb-4">
      <b-container fluid>
        <div>
          <iq-card>
            <template v-slot:body>
              <div class="filter-hari-libur-dokter border p-2">
                <b-row class="align-items-center">
                  <b-col cols="12" lg="3" class="d-flex flex-row w-100 mr-auto">
                    <div>
                      <h5 class="card-title font-weight-bold mb-0">Master Libur Nasional Exclude</h5>
                    </div>
                  </b-col>
                  <b-col cols="12" lg="9">
                    <div class="text-right">
                      <b-button class="custom-button-radius" size="sm" variant="primary" @click="tambahModal"><i
                        class="fas fa-plus"></i> Tambah</b-button>
                    </div>
                  </b-col>
                </b-row>
              </div>
              <div class="table mb-0">
                <b-table class="mb-0" ref="table" :sort-by.sync="tablehariexclude.sortBy"
                  :sort-desc.sync="tablehariexclude.sortDesc" :busy="tablehariexclude.isBusy" :items="tablehariexclude.items"
                  :fields="tablehariexclude.fields" :current-page="tablehariexclude.currentPage"
                  :per-page="tablehariexclude.perPage" :filter="tablehariexclude.filter" stacked="md" @filtered="onFiltered" bordered hover
                  show-empty small striped>
                  <template #cell(index)="data">
                    {{ (tablehariexclude.currentPage * tablehariexclude.perPage) - tablehariexclude.perPage + data.index + 1 }}
                  </template>
                  <template #cell(TANGGAL_LIBUR)="data">
                    {{ new Date(data.item.TANGGAL_LIBUR) | formatDate }}
                  </template>
                  <template #cell(NAMA_RUANGAN)="data">
                  <span class="text-secondary" v-if="data.item.ID_RUANGAN === 1010101 || data.item.ID_RUANGAN === '1010101'"><font color="black">Instalasi Rawat Jalan</font></span>
                  <span class="text-secondary" v-if="data.item.ID_RUANGAN === 1011001 || data.item.ID_RUANGAN === '1011001'"><font color="black">Instalasi Rehab medik</font></span>
                  <span class="text-secondary" v-if="data.item.ID_RUANGAN === 1012701 || data.item.ID_RUANGAN === '1012701'"><font color="black">Instalasi Griya Husada</font></span>
                </template>
                  <template #cell(action)="data">
                    <b-button @click="editModal(data.item.ID, data.item.ID_DOKTER, data.item.NAMA_DOKTER, data.item.ID_RUANGAN, data.item.NAMA_RUANGAN)" variant=" mr-1 mb-1" size="lg">
                      <i class="fas fa-edit text-primary"></i>
                    </b-button>
                    <b-button @click="deleteModal(data.item.ID)" variant=" mr-1 mb-1" size="lg">
                      <i class="fas fa-trash-alt text-danger"></i>
                    </b-button>
                  </template>
                </b-table>
              </div>
              <div class="pagination-dokter border shadow-sm p-2 mb-2">
                <b-row class="align-items-center">
                  <b-col cols="4" class="d-flex flex-row align-items-center">
                    <div class="w-100">
                      <b-pagination v-model="tablehariexclude.currentPage" :total-rows="tablehariexclude.totalRows"
                        :per-page="tablehariexclude.perPage" size="md" class="my-0"></b-pagination>
                    </div>
                    <div class="w-100">
                      <div>
                        <span class="border-left border-dark mr-3 py-1"></span>
                        <span @click="tableRefresh" class="katalog-cursor">
                          <i class="fas fa-sync text-secondary"></i>
                        </span>
                        <span class="border-right border-dark ml-3 py-1"></span>
                      </div>
                    </div>
                  </b-col>
                  <b-col cols="5">
                    <div class="d-flex flex-row justify-content-end align-items-center">
                      <div class="mr-1 w-75">
                        <form class="searchbox mb-0">
                          <input type="search" v-model="tablehariexclude.filter" class="form-control cstm-srch-dokter"
                            id="filter-input" placeholder="[Cari]">
                        </form>
                      </div>
                      <div>
                        <b-form-select class="cstm-slct-dokter" v-model="selected.filter" :options="options.optionsFilter"
                          @change="filterchange"></b-form-select>
                      </div>
                    </div>
                  </b-col>
                  <b-col cols="3">
                    <div class="d-flex flex-row align-items-center justify-content-end">
                      <div class="mr-3">
                        <b-form-select
                          class="cstm-slct-dokter"
                          id="per-page-select"
                          v-model="tablehariexclude.perPage"
                          :options="tablehariexclude.pageOptions"
                        ></b-form-select>
                      </div>
                      <div>
                        <p class="text-dark mb-0">Menampilkan {{ tablehariexclude.perPage }} dari {{ tablehariexclude.totalRows }} baris</p>
                      </div>
                    </div>
                  </b-col>
                </b-row>
              </div>
            </template>
          </iq-card>
        </div>
      </b-container>
      <div id="modal-component-libur-exclude">
        <b-modal id="modal-tambah-libur-exclude" ref="modal-tambah-libur-exclude" size="lg" centered
          title="Tambah Libur Nasional Exclude" ok-title="Simpan Data" @ok="tambahSave" cancel-title="Tutup"  @close="clearData" @cancel="clearData">
          <b-form-group class="mb-2">
            <b-row class="align-items-center">
              <b-col cols="2">
                <span>
                  <p class="text-dark mb-0">Instalasi</p>
                </span>
              </b-col>
              <b-col>
                <multiselect id="instalasi" :show-labels="false" label="name" track-by="id" placeholder="[ Instalasi ]" :searchable="true" :allow-empty="false" @input="valueruangan" v-model="selectedruangan"  :options="options.comboruangan">
                </multiselect>
              </b-col>
            </b-row>
          </b-form-group>
          <b-form-group class="mb-2">
            <b-row class="align-items-center">
              <b-col cols="2">
                <span>
                  <p class="text-dark mb-0">Dokter</p>
                </span>
              </b-col>
              <b-col>
                <multiselect :show-labels="false" label="nama" placeholder="[ Dokter ]" v-model="selected.dokterrajal"
                    :searchable="true" :options="options.dokterrajal" @input="valueliburdokter" :allow-empty="false">
                </multiselect>
              </b-col>
            </b-row>
          </b-form-group>
          <b-form-group class="mb-2">
            <b-row class="align-items-center">
              <b-col cols="2">
                <span>
                  <p class="text-dark mb-0">Tanggal Libur</p>
                </span>
              </b-col>
              <b-col>
                <flat-pickr :config="config" @on-change="tglEventExecutive($event, 't')"  v-model="preview.tanggal_libur" value="" id="tanggal_libur" placeholder="[ Pilih Tanggal ]"
                  class="w-100 custom-flatpicker calender-small">
                </flat-pickr>
              </b-col>
            </b-row>
          </b-form-group>
          <div class="bg-dark px-2" v-if="preview.message">
            <p class="text-right text-white mb-0">{{ preview.message }}</p>
          </div>
        </b-modal>
        <b-modal id="modal-edit-libur-exclude" ref="modal-edit-libur-exclude" size="lg" centered title="Edit Hari Libur Nasional Exclude" ok-title="Perbarui Data" cancel-title="Tutup" @ok="editSave">
          <b-form>
            <b-form-group class="mb-2">
              <b-row class="align-items-center">
                <b-col cols="2">
                  <span>
                    <p class="text-dark mb-0">Ruangan</p>
                  </span>
                </b-col>
                <b-col>
                  <multiselect id="instalasi" :show-labels="false" label="name" track-by="id" placeholder="[ Instalasi ]" :searchable="true" :allow-empty="false" @input="valueruangan" v-model="selected.Editruangan"  :options="options.comboruangan"></multiselect>
                </b-col>
              </b-row>
            </b-form-group>
            <b-form-group class="mb-2">
              <b-row class="align-items-center">
                <b-col cols="2">
                  <span>
                    <p class="text-dark mb-0">Dokter</p>
                  </span>
                </b-col>
                <b-col>
                  <multiselect :show-labels="false" label="nama" placeholder="[ Dokter ]" v-model="selected.Editdokterajal"
                      :searchable="true"  :options="options.doktereditrajal" @input="valueEditliburexecutive" :allow-empty="false">
                  </multiselect>
                </b-col>
              </b-row>
            </b-form-group>
            <b-form-group class="mb-2">
              <b-row class="align-items-center">
                <b-col cols="2">
                  <span>
                    <p class="text-dark mb-0">Tanggal Libur</p>
                  </span>
                </b-col>
                <b-col>
                  <flat-pickr :config="config" @on-change="tglEventExecutive($event, 'e')" v-model="preview.tanggal_libur"  id="tanggal_libur" placeholder="[ Pilih Tanggal ]" class="w-100 custom-flatpicker calender-small">
                  </flat-pickr>
                </b-col>
              </b-row>
            </b-form-group>
          </b-form>
        </b-modal>
      <b-modal ref="modal-delete-libur-exclude" size="md" centered title="Konfirmasi" ok-title="Hapus Data" ok-variant="danger" cancel-title="Tutup" @ok="deleteSave">
          <div class="py-3">
            <p class="h6 text-center text-dark mb-0">Yakin Ingin Menghapus Data ?</p>
          </div>
      </b-modal>
      <b-modal ref="modal-notif-libur-exclude" size="md" centered title="Notifikasi" ok-title="Tutup" :hide-footer="true"
        :hide-header="true">
        <div class="pb-3 px-4">
          <div v-if="preview.messageValue === '1'">
            <lottie :options="preview.lottie.defaultOptions" :autoPlay="true" :width="150" :height="150" />
          </div>
          <div v-else>
            <lottie :options="preview.lottie.defaultOptions2" :autoPlay="true" :width="150" :height="150" />
          </div>
          <p class="h6 text-dark text-center mb-0 mt-2">{{ preview.message }}</p>
        </div>
      </b-modal>
      </div>
    </div>
  </template>
<script>
import { xray } from '../../../config/pluginInit'
import jwtdecode from 'jwt-decode'
import Multiselect from 'vue-multiselect'
import Lottie from 'vue-lottie'
import Services from '../../../services/fatmahost/master/index'
import animationLogo from '../../../assets/lottie/checked.json'
import animationLogo2 from '../../../assets/lottie/failed.json'
import flatPickr from 'vue-flatpickr-component'
import 'flatpickr/dist/flatpickr.css'

export default {
  name: 'Liburnasionalexclude',
  components: {
    Multiselect,
    flatPickr,
    Lottie
  },
  data () {
    return {
      tablehariexclude: {
        isBusy: false,
        items: this.list,
        sortBy: '',
        sortDesc: false,
        fields: [
          { label: 'No', key: 'index', class: 'text-center' },
          { label: 'Nama Dokter', key: 'NAMA_DOKTER', sortable: true },
          { label: 'Ruangan', key: 'NAMA_RUANGAN' },
          { label: 'Tanggal Libur', key: 'TANGGAL_LIBUR', sortable: true },
          { label: 'Aksi', key: 'action', class: 'text-center' }
        ],
        filter: '',
        currentPage: null,
        perPage: null,
        pageOptions: [10, 25, 50, 100],
        totalRows: null
      },
      tambahLiburExclude: {
        object: '',
        ruangan: '',
        id_dokter: '',
        tanggal_libur: ''
      },
      editLiburExclude: {
        object: '',
        ruangan: '',
        id_dokter: '',
        tanggal_libur: ''
      },
      config: {
        dateFormat: 'd-m-Y',
        disableMobile: true
      },
      options: {
        dokterrajal: [],
        doktereditrajal: [],
        ruangan: [],
        optionsFilter: [
          { value: '1', text: 'Nama Dokter' }
        ],
        comboruangan: [
          { name: 'INSTALASI GRIYA HUSADA', id: 1012701 },
          { name: 'INSTALASI RAWAT JALAN', id: 1010101 },
          { name: 'INSTALASI REHAB MEDIK', id: 1011001 }
        ]
      },
      selectedruangan: [],
      selecteditedruangan: [],
      selected: {
        filter: '1',
        dokterrajal: [],
        Editdokterajal: [],
        Editruangan: []
      },
      preview: {
        lottie: {
          defaultOptions: { animationData: animationLogo, loop: true },
          defaultOptions2: { animationData: animationLogo2, loop: true }
        },
        user_name: jwtdecode(localStorage.getItem('token')).user.name,
        edit_id: '',
        delete_id: '',
        message: '',
        messageValue: ''
      }
    }
  },
  mounted () {
    xray.index()
  },
  methods: {
    clearData () {
      this.preview.tanggal_libur = ''
      this.selected.dokterrajal = []
      this.selected.Editruangan = []
      this.selectedruangan = []
      this.tambahLiburExclude.tanggal_libur = ''
      this.preview.message = ''
    },
    tglEventExecutive ($event, param) {
      const date = new Date($event)
      let day = date.getDate()
      let month = (date.getMonth() + 1).toString().padStart(2, 0)
      let year = date.getFullYear()

      let currentDate = year + '-' + month + '-' + day
      if (param === 't') {
        this.tambahLiburExclude.tanggal_libur = currentDate
      } else if (param === 'e') {
        this.editLiburExclude.tanggal_libur = currentDate
      }
    },
    tglResExecutive ($event, param) {
      const date = new Date($event)
      let day = date.getDate()
      let month = (date.getMonth() + 1).toString().padStart(2, 0)
      let year = date.getFullYear()

      this.preview.tanggal_libur = day + '-' + month + '-' + year
      let currentDate = year + '-' + month + '-' + day
      if (param === 'a') {
        this.editLiburExclude.tanggal_libur = currentDate
      }
    },
    async editSave () {
      try {
        let id = this.preview.edit_id
        let data = this.editLiburExclude
        const response = await Services.edit_libur_nasional_exclude(id, data)
        if (response.status === 200) {
          this.preview.message = response.data.message
          this.preview.messageValue = '1'
          this.$refs['modal-notif-libur-exclude'].hide()
          this.$refs['modal-notif-libur-exclude'].show()
          this.$refs.table.refresh()
        } else {
          this.preview.message = response.data.message
          this.preview.messageValue = '2'
          this.$refs.table.refresh()
        }
      } catch (error) {
        this.preview.message = error.message
        this.preview.messageValue = '2'
        this.$refs['modal-notif-libur-exclude'].show()
      }
    },
    valueEditliburexecutive (param) {
      this.editLiburExclude.id_dokter = param.id_dokter.toString()
    },
    async editModal (id, a, b, c, d) {
      let ids = id
      let ruangan = ''
      if (c === '1010101') ruangan = 'Instalasi Rawat Jalan'
      if (c === '1011001') ruangan = 'Instalasi Rehab medik'
      if (c === '1012701') ruangan = 'Instalasi Griya Husada'
      this.preview.edit_id = ids
      try {
        const response = await Services.show_libur_exclude(ids)
        this.editLiburExclude.ruangan = response.data.data.ruangan
        this.selected.Editruangan = { id: c, name: ruangan }
        this.selected.Editdokterajal = { id: a, nama: b }
        this.editLiburExclude.tanggal_libur = response.data.data.tanggal_libur
        this.preview.tanggal_libur = response.data.data.tanggal_libur
        this.editLiburExclude.id_dokter = response.data.data.id_dokter
      } catch (error) {
        this.preview.message = error.message
        this.preview.messageValue = '2'
      }
      this.$refs['modal-edit-libur-exclude'].show()
    },
    async deleteSave () {
      let id = this.preview.delete_id
      try {
        const response = await Services.delete_Libur_exclude(id)
        if (response.status === 200) {
          this.preview.message = response.data.message
          this.preview.messageValue = '1'
          this.$refs['modal-notif-libur-exclude'].hide()
          this.$refs['modal-notif-libur-exclude'].show()
          this.$refs.table.refresh()
        } else {
          this.preview.message = response.data.message
          this.preview.messageValue = '2'
          this.$refs.table.refresh()
        }
      } catch (error) {
        this.preview.message = error.message
        this.preview.messageValue = '2'
        this.$refs['modal-notif-libur-exclude'].show()
      }
    },
    async tambahSave (bvModalEvent) {
      bvModalEvent.preventDefault()
      try {
        if (this.tambahLiburExclude.tanggal_libur !== '' && this.tambahLiburExclude.ruangan !== '' && this.tambahLiburExclude.id_dokter !== '') {
          let data = this.tambahLiburExclude
          let response = await Services.createliburecxlude(data)
          if (response.status === 200) {
            this.preview.message = response.data.message
            this.preview.messageValue = '1'
            this.$refs['modal-tambah-libur-exclude'].hide()
            this.$refs['modal-notif-libur-exclude'].show()
          } else {
            this.preview.message = response.data.message
            this.preview.messageValue = '2'
          }
          this.$refs.table.refresh()
        } else {
          this.preview.message = 'Data tidak boleh kosong'
          this.preview.messageValue = '2'
        }
        this.$refs.table.refresh()
      } catch (error) {
        this.preview.message = error.message
        this.preview.messageValue = '2'
        this.$refs.table.refresh()
      }
    },
    onFiltered (filteredItems) {
      this.tablehariexclude.totalRows = filteredItems.length
      this.tablehariexclude.currentPage = 1
    },
    filterchange () {
      this.tablehariexclude.filter = ''
    },
    valueliburdokter (param) {
      this.tambahLiburExclude.id_dokter = param.id_dokter.toString()
    },
    async valueruangan (param) {
      let response = []
      if (param.id === 1012701) response = await Services.getdoktergriya()
      if (param.id === 1010101) response = await Services.getdokterrjl()
      if (param.id === 1011001) response = await Services.getdokterirm()
      this.options.dokterrajal = response.data.data
      this.options.doktereditrajal = response.data.data
      this.tambahLiburExclude.ruangan = param.id.toString()
      this.editLiburExclude.ruangan = param.id.toString()
    },
    tableRefresh () {
      this.$refs.table.refresh()
    },
    tambahModal () {
      this.clearData()
      this.$refs['modal-tambah-libur-exclude'].show()
    },
    deleteModal (id) {
      this.preview.delete_id = id
      this.$refs['modal-delete-libur-exclude'].show()
    },
    async list (ctx) {
      let filter = ``
      let sorting = ``
      if (ctx.sortBy === 'NAMA_DOKTER') {
        if (ctx.sortDesc === true) {
          sorting = `{"nama":"asc"}`
        } else if (ctx.sortDesc === false) {
          sorting = `{"nama":"desc"}`
        }
      } else if (ctx.sortBy === 'TANGGAL_LIBUR') {
        if (ctx.sortDesc === true) {
          sorting = `{"tanggal_libur":"asc"}`
        } else if (ctx.sortDesc === false) {
          sorting = `{"tanggal_libur":"desc"}`
        }
      } else {
        sorting = '{}'
      }
      if (ctx.filter) {
        filter = `{"nama":"${this.tablehariexclude.filter}"}`
      } else {
        filter = '{}'
      }

      try {
        let response = await Services.list_libur_nasional_exclude(ctx.currentPage - 1, ctx.perPage, filter, sorting)
        this.isBusy = false
        this.tablehariexclude.totalRows = response.data.pagination.total
        this.tablehariexclude.perPage = response.data.pagination.perPage
        this.tablehariexclude.currentPage = response.data.pagination.current
        return response.data.data
      } catch (erorr) {
        this.isBusy = false
        return []
      }
    }
  }
}
</script>
  <style>
  #master-libur-exclude input[type="text"][disabled],
  #modal-tambah-libur-exclude input[type="text"][disabled],
  #modal-edit-libur-exclude input[type="text"][disabled] {
     background: #e9ecef;
  }

  #modal-tambah-libur-exclude .height-static-pabrik {
    /* max-height: 12em;
   overflow-x: hidden; */
    overflow: auto;
  }

  #modal-tambah-libur-exclude .multiselect, #modal-edit-libur-exclude .multiselect {
    min-height: 33px;
  }

  #modal-tambah-libur-exclude .multiselect__single, #modal-edit-libur-exclude .multiselect__single {
    font-size: 0.9em!important;
    font-weight: normal!important;
    line-height: 26px;
  }
  #modal-tambah-libur-exclude .multiselect__placeholder, #modal-edit-libur-exclude .multiselect__placeholder {
    font-size: 0.9em!important;
    font-weight: normal;
  }
  #modal-tambah-libur-exclude .multiselect__option, #modal-edit-libur-exclude .multiselect__option {
    font-size: 0.9em;
  }
  #modal-tambah-libur-exclude .multiselect__tags, #modal-edit-libur-exclude .multiselect__tags {
    overflow: hidden;
    padding: 1px 40px 0 8px!important;
    height: 33px!important;
    min-height: 33px!important;
  }

  #modal-edit-libur-exclude .multiselect--disabled .multiselect__tags {
    overflow: hidden;
    background: #E9ECEF;
  }

  #modal-edit-libur-exclude .multiselect--disabled {
    opacity: 1;
  }

  #modal-edit-libur-exclude .multiselect--disabled .multiselect__tags .multiselect__single {
    overflow: hidden;
    background: transparent;
  }

  #modal-edit-libur-exclude .multiselect__select {
      height: 30px;
  }

  .new-cstm-cntrl-inventori {
    background: white!important;
    color: black!important;
    border-radius: 5px!important;
    font-size: 12px!important;
    height: 33px!important;
  }

  #modal-tambah-libur-exclude .border-merah {
    border: 2px solid red;
  }

  #modal-tambah-libur-exclude .border-hijau {
    border: 2px solid green;
  }

  #modal-tambah-libur-exclude .modal-body {
    background: #3399331b;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }

  #modal-tambah-libur-exclude .modal-header {
    background: #3399333a;
  }

  #modal-tambah-libur-exclude .modal-footer {
    background: #3399333a;
  }

  #modal-edit-libur-exclude .border-merah {
    border: 2px solid red;
  }

  #modal-edit-libur-exclude.border-hijau {
    border: 2px solid green;
  }

  #modal-edit-libur-exclude .modal-body {
    background: #3399331b;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }

  #modal-edit-libur-exclude .modal-header {
    background: #3399333a;
  }

  #modal-edit-libur-exclude .modal-footer {
    background: #3399333a;
  }

  #master-libur-exclude .custom-button-radius {
    border: 1px solid rgba(0, 0, 0, 0.112);
    border-radius: 5px !important;
  }

  #master-libur-exclude .table td {
      font-size: 12px;
      font-weight: 500;
      padding-top: 0px;
      padding-bottom: 0px;
  }
  #master-libur-exclude .table th {
      font-size: 13px;
      font-weight: 400;
  }

  #master-libur-exclude .table {
    text-align: left;
  }

  #master-libur-exclude .cstm-select-Inventory {
    font-size: 13px;
    height: 38px !important;
    border-radius: 5px;
  }

  #master-libur-exclude .cstm-srch-exclude {
    font-size: 13px;
    height: 38px !important;
    border-radius: 5px;
  }

  #master-libur-exclude .cstm-slct-exclude {
    font-size: 13px;
    height: 38px !important;
    border-radius: 5px;
  }

  #master-libur-exclude .pagination-dokter .page-link {
      border: none;
      color: gray!important;
      margin-right: 15px;
  }
  #master-libur-exclude .pagination-dokter .page-item.active .page-link {
      background-color: transparent;
      border-color: transparent;
      color: #089bab!important;
      font-weight: bold;
  }

  #modal-tambah-libur-exclude .custom-flatpicker, #modal-edit-libur-exclude .custom-flatpicker {
      border: 1px solid rgb(233, 233, 233);
      height: 33px;
      padding-left: 10px;
      padding-top: 5px;
      padding-bottom: 5px;
      border: 1px solid rgb(209, 208, 208)!important;
      border-radius: 5px;
      border: 1px solid rgb(228, 228, 228);
      font-size: 12px;
      color: black;
  }

  #modal-tambah-libur-exclude .custom-dsbld, #modal-edit-libur-exclude .custom-dsbld {
    background: #e9ecef;
  }
  </style>
