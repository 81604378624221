<template>
  <div id="remun-kegiatan-klp" class="mb-4">
    <b-container fluid>
      <div>
        <iq-card>
          <template v-slot:body>
            <div class="filter-tarif border p-2">
              <b-row class="align-items-center">
                <b-col cols="12" lg="4" class="d-flex flex-row w-100 mr-auto">
                  <div>
                    <h5 class="card-title font-weight-bold text-uppercase mb-0">Master Remun Kegiatan Kelompok</h5>
                  </div>
                </b-col>
                <b-col cols="12" lg="8">
                  <div class="select-tindakan d-flex flex-row justify-content-end align-items-center">
                    <div>
                      <b-button class="custom-button-radius py-2" size="md" variant="primary" @click="tambahModal"><i class="fas fa-plus"></i> Tambah</b-button>
                    </div>
                  </div>
                </b-col>
              </b-row>
            </div>
            <div class="table mb-0">
              <b-table class="mb-0" ref="table" :sort-by.sync="table.sortBy" :sort-desc.sync="table.sortDesc" :busy="table.isBusy" :items="table.items" :fields="table.fields"
                :current-page="table.currentPage" :per-page="table.perPage" :filter="table.filter" @filtered="onFiltered" stacked="md"
                bordered hover show-empty small striped>
                <template #cell(index)="data">
                  {{ (table.currentPage*table.perPage)-table.perPage + data.index + 1 }}
                </template>
                <template v-slot:cell(proses_sysdate)="data">
                  <span class="text-dark">{{ new Date(data.item.proses_sysdate) | formatDate }}</span>
                </template>
                <template v-slot:cell(mkk.kegiatan_qty)="data">
                  <span class="text-dark">{{ formatRibuan(qtyWithDots(data.item)) }}</span>
                </template>
                <template #cell(action)="data">
                  <b-button disabled
                    variant=" mr-1 mb-1" size="lg">
                    <i class="fas fa-edit text-secondary"></i>
                  </b-button>
                  <b-button
                    @click="deleteModal(data.item.kegiatan_id)"
                    variant=" mr-1 mb-1" size="lg">
                    <i class="fas fa-trash-alt text-danger"></i>
                  </b-button>
                </template>
              </b-table>
            </div>
            <div class="pagination-pbf border shadow-sm p-2 mb-2">
              <b-row class="align-items-center">
                <b-col cols="4" class="d-flex flex-row align-items-center">
                  <div class="w-100">
                    <b-pagination
                      v-model="table.currentPage"
                      :total-rows="table.totalRows"
                      :per-page="table.perPage"
                      size="md"
                      class="my-0"
                    ></b-pagination>
                  </div>
                  <div class="w-100">
                    <div>
                      <span class="border-left border-dark mr-3 py-1"></span>
                      <span @click="tableRefresh" class="tarif-cursor">
                        <i class="fas fa-sync text-primary"></i>
                      </span>
                      <span class="border-right border-dark ml-3 py-1"></span>
                    </div>
                  </div>
                </b-col>
                <b-col cols="5">
                  <div class="d-flex flex-row justify-content-end align-items-center">
                    <div class="mr-1 w-75">
                      <form class="searchbox mb-0">
                        <input v-model="table.filter" type="search" class="form-control cstm-srch-kgtn-klp" id="filter-input"
                          placeholder="[Cari Nama Kegiatan]">
                      </form>
                    </div>
                    <div>
                      <b-form-select class="cstm-slct-kgtn-klp" v-model="selected.filter" :options="options.optionsFilter" @change="filterchange"></b-form-select>
                    </div>
                  </div>
                </b-col>
                <b-col cols="3">
                  <div class="d-flex flex-row align-items-center justify-content-end">
                    <div class="mr-3">
                      <b-form-select
                        class="cstm-select-pbf"
                        id="per-page-select"
                        v-model="table.perPage"
                        :options="table.pageOptions"
                      ></b-form-select>
                    </div>
                    <div>
                      <p class="text-dark mb-0">Menampilkan {{ table.perPage }} dari {{ table.totalRows }} baris</p>
                    </div>
                  </div>
                </b-col>
              </b-row>
            </div>
          </template>
        </iq-card>
      </div>
    </b-container>
    <div id="modal-component-tarif">
      <b-modal id="modal-tambah-kegiatan-klp" ref="modal-tambah-kegiatan-klp" size="lg" centered title="Tambah Kegiatan Kelompok" ok-title="Simpan Kegiatan" cancel-title="Tutup" :no-close-on-backdrop="true" :static="true" @ok="tambahSave">
        <b-form>
          <b-row class="mb-custom align-items-center">
            <b-col cols="3">
              <div>
                <p class="font-custom text-dark mb-0">ID Kegiatan</p>
              </div>
            </b-col>
            <b-col>
              <div>
                <multiselect :allow-empty="false" v-model="selected.tindakan" label="nama" :show-labels="false" placeholder="[ Masukkan Kegiatan ]" :options="options.tindakan" @input="valueTindakan" :searchable="true">
                </multiselect>
              </div>
            </b-col>
          </b-row>
          <b-row class="mb-custom align-items-center">
            <b-col cols="3">
              <div>
                <p class="font-custom text-dark mb-0">DTO Kegiatan Kelompok</p>
              </div>
            </b-col>
            <b-col>
              <div>
                <multiselect :allow-empty="false" v-model="selected.dto_kelompok" label="nama" :show-labels="false" placeholder="[ Masukkan DTO Kegiatan Kelompok ]" :options="options.dto_kelompok" @input="valueDto" :searchable="true">
                </multiselect>
              </div>
            </b-col>
          </b-row>
          <b-row class="mb-custom align-items-center">
            <b-col cols="3">
              <div>
                <p class="font-custom text-dark mb-0">Nama Kegiatan</p>
              </div>
            </b-col>
            <b-col>
              <div>
                <b-form-input class="form-control cstm-srch-kgtn-klp text-dark bg-white" v-model="form.kegiatan_nama" placeholder="Masukkan Nama Kegiatan"></b-form-input>
              </div>
            </b-col>
          </b-row>
        </b-form>
        <div class="bg-dark mt-2">
          <p class="small text-right text-white px-2 mb-0" v-if="message">{{ message[0].message }}</p>
        </div>
      </b-modal>
      <b-modal id="modal-edit-kegiatan-klp" ref="modal-edit-kegiatan-klp" size="lg" centered title="Edit Kegiatan Kelompok" hide-footer :no-close-on-backdrop="true" :static="true">
        <b-form>
          <b-row class="mb-custom align-items-center">
            <b-col cols="2">
              <div>
                <p class="font-custom text-dark mb-0">ID Kegiatan</p>
              </div>
            </b-col>
            <b-col>
              <div>
                <multiselect disabled :allow-empty="false" v-model="selected.tindakan" label="nama" :show-labels="false" placeholder="[ Masukkan Kegiatan ID ]" :options="options.tindakan" @input="valueTindakan" :searchable="true">
                </multiselect>
              </div>
            </b-col>
          </b-row>
          <b-row class="mb-custom align-items-center">
            <b-col cols="2">
              <div>
                <p class="font-custom text-dark mb-0">Nama Kegiatan</p>
              </div>
            </b-col>
            <b-col>
              <div>
                <b-form-input disabled class="form-control bg-white" v-model="form.kegiatan_nama" placeholder="Masukkan Nama Kegiatan"></b-form-input>
              </div>
            </b-col>
          </b-row>
          <b-row class="mb-custom align-items-center">
            <b-col cols="2">
              <div>
                <p class="font-custom text-dark mb-0">DTO Kegiatan Kelompok</p>
              </div>
            </b-col>
            <b-col>
              <div>
                <b-form-input disabled class="form-control bg-white" v-model="form.dto_kegiatan_klp" placeholder="Masukkan Dto Kegiatan"></b-form-input>
              </div>
            </b-col>
          </b-row>
        </b-form>
        <div class="bg-dark mt-2">
          <p class="small text-right text-white px-2 mb-0" v-if="message">{{ message[0].message }}</p>
        </div>
      </b-modal>
      <b-modal ref="modal-delete-tarif" size="md" centered title="Konfirmasi" ok-title="Hapus Data" ok-variant="danger" cancel-title="Tutup" @ok="deleteSave">
        <div class="py-3">
          <p class="h6 text-center text-dark mb-0">Yakin Ingin Menghapus Data ?</p>
        </div>
      </b-modal>
      <ModalMsgbox ref="MsgBox" />
    </div>
  </div>
</template>
<script>
import { xray } from '../../../config/pluginInit'
import ModalMsgbox from '../../../components/modal/ModalMsgBox'
import Service from '../../../services/fatmahost/index'
import Multiselect from 'vue-multiselect'

export default {
  name: 'RemunKegiatanKlp',
  components: {
    ModalMsgbox,
    Multiselect
  },
  data () {
    return {
      table: {
        isBusy: false,
        items: this.list,
        sortBy: '',
        sortDesc: false,
        fields: [
          { label: 'No', key: 'index', class: 'text-center' },
          { label: 'ID', key: 'kegiatan_id' },
          { label: 'KELOMPOK', key: 'mkk.dto_kegiatan_klp', class: 'text-left', sortable: true },
          { label: 'NAMA', key: 'mkk.kegiatan_nama', sortable: true },
          { label: 'TANGGAL', key: 'proses_sysdate', sortable: true },
          { label: 'QTY', key: 'mkk.kegiatan_qty', sortable: true },
          { label: 'Aksi', key: 'action', class: 'text-center' }
        ],
        filter: null,
        currentPage: 1,
        perPage: 10,
        pageOptions: [10, 25, 50, 100],
        totalRows: null
      },
      options: {
        optionsFilter: [
          { text: 'Nama Kegiatan', value: '1' }
        ],
        tindakan: [],
        dto_kelompok: [
          { id: 'visite', nama: 'visite' },
          { id: 'rawatjalan', nama: 'rawatjalan' },
          { id: 'penunjang', nama: 'penunjang' },
          { id: 'tindakan', nama: 'tindakan' }
        ]
      },
      selected: {
        filter: '1',
        tindakan: [],
        dto_kelompok: []
      },
      form: {
        object: 'kegiatan-klp',
        kegiatan_id: '',
        kegiatan_nama: '',
        dto_kegiatan_klp: '',
        created_by: xray.currentUser().id
      },
      message: ''
    }
  },
  mounted () {
    xray.index()
    this.getTindakan()
  },
  methods: {
    tableRefresh () {
      this.$refs.table.refresh()
    },
    formatRibuan (value) {
      if (value !== null || value !== '') {
        const amount = value
        return amount.toLocaleString()
      } else {
        return '0'
      }
    },
    isNumberKey (evt) {
      evt = (evt) || window.event
      var charCode = (evt.which) ? evt.which : evt.keyCode
      if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
        evt.preventDefault()
      } else {
        return true
      }
    },
    async getTindakan (value) {
      const response = await Service.master.gettindakan()
      this.options.tindakan = response.data.data
    },
    valueTindakan (value) {
      this.form.kegiatan_id = value.id
    },
    valueDto (value) {
      this.form.dto_kegiatan_klp = value.id
    },
    async list (ctx) {
      let sortfield = ''
      let sorting = ''
      let filter = ''
      if (ctx.sortBy) {
        sortfield = ctx.sortBy
      } else {
        sortfield = ''
      }
      if (ctx.sortDesc === true) {
        sorting = 'DESC'
      } else if (ctx.sortDesc === false) {
        sorting = 'ASC'
      } else {
        sorting = ''
      }
      if (ctx.filter !== null) {
        filter = ctx.filter
      } else {
        filter = ''
      }
      this.table.isBusy = true
      try {
        const response = await Service.pegawai.remunKegKlp(ctx.currentPage - 1, ctx.perPage, filter, sortfield, sorting)
        this.table.isBusy = false
        this.table.totalRows = response.data.pagination.total
        this.table.perPage = response.data.pagination.perPage
        this.table.currentPage = response.data.pagination.current
        return response.data.data
      } catch (error) {
        this.table.isBusy = false
        return []
      }
    },
    qtyWithDots (item) {
      return item['mkk.kegiatan_qty']
    },
    onFiltered (filteredItems) {
      this.table.totalRows = filteredItems.length
    },
    filterchange () {
      this.table.filter = ''
    },
    tambahModal () {
      this.formClear()
      this.$refs['modal-tambah-kegiatan-klp'].show()
    },
    async editModal (id) {
      this.formClear()
      try {
        const response = await Service.pegawai.remunKegKlpShow(id)
        if (response.data.statcode === 0) {
          this.selected.tindakan = response.data.data[0].kegiatan_id ? { id: response.data.data[0].kegiatan_id, nama: response.data.data[0].kegiatan_nama } : []
          this.form.kegiatan_id = response.data.data[0].kegiatan_id
          this.form.kegiatan_nama = response.data.data[0].kegiatan_nama
          this.form.dto_kegiatan_klp = response.data.data[0].dto_kegiatan_klp
          this.$refs['modal-edit-kegiatan-klp'].show()
        }
      } catch (error) {
        this.$refs.MsgBox.show({
          type: 'error',
          msg: error.message
        })
      }
      this.$refs['modal-edit-kegiatan-klp'].show()
    },
    async tambahSave (bvModalEvent) {
      bvModalEvent.preventDefault()
      try {
        const data = this.form
        const response = await Service.pegawai.remunKegKlpCreate(data)
        if (response.data.statcode === 0) {
          this.$refs.MsgBox.show({
            type: 'success',
            msg: response.data.message
          })
          this.$refs['modal-tambah-kegiatan-klp'].hide()
          this.$refs.table.refresh()
          this.formClear()
        } else {
          this.message = response.data.message
        }
      } catch (error) {
        this.message = error.message
      }
    },
    deleteModal (id) {
      this.selected.id = id
      this.$refs['modal-delete-tarif'].show()
    },
    async deleteSave () {
      let id = this.selected.id
      try {
        const response = await Service.pegawai.remunKegKlpDelete(id)
        if (response.data.statcode === 0) {
          this.$refs.MsgBox.show({
            type: 'success',
            msg: response.data.message
          })
        } else {
          this.$refs.MsgBox.show({
            type: 'error',
            msg: response.data.message
          })
        }
        this.$refs.table.refresh()
      } catch (error) {
        this.$refs.MsgBox.show({
          type: 'error',
          msg: error.message
        })
      }
    },
    formClear () {
      this.form.object = 'kegiatan-klp'
      this.form.kegiatan_id = ''
      this.form.kegiatan_nama = ''
      this.form.dto_kegiatan_klp = ''
      //
      this.selected.tindakan = []
      this.selected.dto_kelompok = []
      this.message = ''
    }
  }
}
</script>
<style>
#modal-tambah-kegiatan-klp .custom-control-label, #modal-edit-kegiatan-klp .custom-control-label {
    margin-top: 0;
}
#modal-tambah-kegiatan-klp .multiselect__single, #modal-edit-kegiatan-klp .multiselect__single {
  font-size: 13px!important;
}
#modal-tambah-kegiatan-klp .multiselect__placeholder, #modal-edit-kegiatan-klp .multiselect__placeholder {
  font-weight: normal!important;
  font-size: 13px!important;
}
#modal-tambah-kegiatan-klp .multiselect__option, #modal-edit-kegiatan-klp .multiselect__option {
    font-size: 13px;
}
#modal-tambah-kegiatan-klp .multiselect__tags, #modal-edit-kegiatan-klp .multiselect__tags {
  padding: 5px 40px 0 8px!important;
  height: 38px!important;
  min-height: 38px!important;
}
#modal-tambah-kegiatan-klp .multiselect__content-wrapper, #modal-edit-kegiatan-klp .multiselect__content-wrapper {
  font-size: 12px;
  font-weight: normal!important;
}
#remun-kegiatan-klp .select-tindakan .multiselect__input, .multiselect__single {
    line-height: 25px;
    font-weight: normal!important;
}
#remun-kegiatan-klp .select-tindakan .custom-control-label {
    margin-top: 0;
}
#remun-kegiatan-klp .select-tindakan .multiselect__single {
  font-size: 12px!important;
  font-weight: normal!important;
}
#remun-kegiatan-klp .select-tindakan .multiselect__placeholder {
  font-size: 12px!important;
  font-weight: normal!important;
}
#remun-kegiatan-klp .select-tindakan .multiselect__option {
  font-size: 12px;
  font-weight: normal!important;
}
#remun-kegiatan-klp .select-tindakan .multiselect__tags {
  padding: 5px 40px 0 8px!important;
  height: 40px!important;
  min-height: 40px!important;
}
#remun-kegiatan-klp .select-tindakan .multiselect__content-wrapper {
  font-size: 12px;
  font-weight: normal!important;
}
#modal-tambah-kegiatan-klp  .modal-body, #modal-edit-kegiatan-klp  .modal-body {
  background: #3399331b
}

#modal-tambah-kegiatan-klp  .modal-header, #modal-edit-kegiatan-klp  .modal-header {
  background: #3399333a;
}

#modal-tambah-kegiatan-klp  .modal-footer, #modal-edit-kegiatan-klp  .modal-footer {
  background: #3399333a;
}
#remun-kegiatan-klp .page-link {
  font-size: 14px;
  padding-top: 5px;
  padding-bottom: 5px;
  margin-right: 10px!important;
}
#remun-kegiatan-klp .custom-button-radius {
  border: 1px solid rgba(0, 0, 0, 0.112);
  border-radius: 5px!important;
}
#remun-kegiatan-klp .table td {
    font-size: 12px;
    font-weight: 500;
    padding-top: 0px;
    padding-bottom: 0px;
}
#remun-kegiatan-klp .table th {
    font-size: 13px;
    font-weight: 400;
}
#remun-kegiatan-klp .cstm-select-pbf {
  font-size: 13px;
  height: 38px!important;
  border-radius: 5px;
}
#remun-kegiatan-klp .cstm-srch-kgtn-klp {
  font-size: 13px;
  height: 38px!important;
  border-radius: 5px;
}
#remun-kegiatan-klp .cstm-slct-kgtn-klp {
  font-size: 13px;
  height: 38px!important;
  border-radius: 5px;
}
.cstm-cntrl-tarif {
  background: white!important;
  color: black!important;
  border-radius: 5px!important;
  font-size: 24px!important;
  height: 38px!important;
  text-align: right!important;
}
#remun-kegiatan-klp .pagination-sm .page-link {
  color: gray!important;
}
#remun-kegiatan-klp .page-item.active .page-link {
  color: white!important;
}
#remun-kegiatan-klp .page-item.active .page-link {
  color: white!important;
}
.custom-radius-add-tarif {
  border-radius: 5px;
  cursor: pointer;
}
.tarif-select-flex-left {
  width: 90%;
}
.tarif-select-flex-right {
  width: 10%;
}
.tarif-form-satuan-pbf-left-right {
  width: 40%;
}
.tarif-form-satuan-pbf-center {
  width: 15%;
}
.hr-tarif-custom {
  border-top: 1px solid rgba(0, 0, 0, 0.199)!important;
  margin: 0!important;
}
#remun-kegiatan-klp .pagination-pbf .page-link {
    border: none;
    color: gray!important;
    margin-right: 15px;
}
#remun-kegiatan-klp .pagination-pbf .page-item.active .page-link {
    background-color: transparent;
    border-color: transparent;
    color: #089bab!important;
    font-weight: bold;
}
#remun-kegiatan-klp .mb-custom {
  margin-bottom: 6px;
}
#remun-kegiatan-klp .font-custom {
  font-size: 12px;
}
#remun-kegiatan-klp .tarif-cursor {
  cursor: pointer;
}
</style>
