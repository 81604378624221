<template>
  <b-container fluid>
    <b-row class="justify-content-center">
      <b-col lg="4">
        <iq-card body-class="iq-card-body pl-0 pr-0 pt-0">
          <template v-slot:body>
            <div class="docter-details-block">
              <div class="doc-profile-bg bg-primary mb-3" style="height:50px;">
              </div>
              <div class="docter-profile text-center">
                <b-img :src="user.jenis_kelamin === 1 ? imgProfile.pria : imgProfile.wanita" alt="profile-img"
                  class="avatar-130 img-fluid" />
              </div>
              <div class="text-center mt-3 pl-3 pr-3">
                <h4><b>
                  <!-- {{ pegawai.gelar_depan == null ? pegawai.gelar_depan +'.' : pegawai.gelar_depan }}  -->
                  {{ user.name }}</b></h4>
                <p>{{ user.nip }}</p>
                <!-- <p class="mb-0">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Delectus repudiandae eveniet harum.</p> -->
              </div>
              <hr>
              <ul class="doctoe-sedual d-flex align-items-center justify-content-between p-0 m-0">
                <li class="text-center">
                  <h4 class="counter text-danger">{{ profile.terlambat }}</h4>
                  <span>Terlambat </span>
                </li>
                <li class="text-center">
                  <h4 class="counter text-warning">{{ profile.tidakmasuk }}</h4>
                  <span>Tidak Masuk</span>
                </li>
                <li class="text-center">
                  <h4 class="counter text-primary">{{ profile.jamkerja }}</h4>
                  <span>Jam Kerja</span>
                </li>
              </ul>
            </div>
          </template>
        </iq-card>
        <div>
          <div v-if="profile.keluar === ''">
          <iq-card body-class="iq-card-body bg-primary rounded">
            <template v-slot:body>
              <div class="iq-card-body py-0">
                <div class="text-center" variant="primary">
                  <h5 class="text-white">Masuk : <span class="font-weight-bold">{{ profile.masuk }} <i class="fas fa-check-circle"></i></span></h5>
                </div>
              </div>
            </template>
          </iq-card>
        </div>
        <div v-else-if="profile.keluar !== '' && profile.masuk !== ''">
          <iq-card body-class="iq-card-body bg-danger rounded">
            <template v-slot:body>
              <div class="iq-card-body py-0">
                <div class="text-center" variant="primary">
                  <h5 class="text-white">Keluar : <span class="font-weight-bold">{{ profile.keluar }} <i class="fas fa-check-circle"></i></span></h5>
                </div>
              </div>
            </template>
          </iq-card>
        </div>
        <div v-else>
          <iq-card body-class="iq-card-body">
            <template v-slot:body>
              <div class="iq-card-body py-0">
                <div class="row" variant="primary">
                  <h4 class="text-white"></h4>
                </div>
              </div>
            </template>
          </iq-card>
        </div>
        </div>
        <iq-card>
          <template v-slot:headerTitle>
            <h4 class="card-title">Personal Information</h4>
          </template>
          <template v-slot:body>
            <div class="iq-card-body">
              <div class="about-info m-0 p-0">
                <div class="row">
                  <div class="col-2 col-lg-3 text-dark">NIP</div>
                  <div class="col-1 col-lg-1 text-dark">:</div>
                  <div class="col-7 col-lg-8 text-dark font-weight-bold">{{ user.nip }}</div>
                </div>
                <div class="row">
                  <div class="col-2 col-lg-3 text-dark">Nama</div>
                  <div class="col-1 col-lg-1 text-dark">:</div>
                  <div class="col-7 col-lg-8 text-dark font-weight-bold">{{ user.name }}</div>
                </div>
                <!-- <div class="row">
                  <div class="col-2 col-lg-3 text-dark">Umur</div>
                  <div class="col-1 col-lg-1 text-dark">:</div>
                  <div class="col-7 col-lg-8 text-dark font-weight-bold">{{ getAge(pegawai.TANGGAL_LAHIR) }}</div>
                </div> -->
                <div class="row">
                  <div class="col-2 col-lg-3 text-dark">Profesi</div>
                  <div class="col-1 col-lg-1 text-dark">:</div>
                  <div class="col-7 col-lg-8 text-dark font-weight-bold">{{ user.profesi }}</div>
                </div>
              </div>
            </div>
          </template>
        </iq-card>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { xray } from '../../config/pluginInit'
import Service from '../../services/fatmahost/index'
import profile from '../../services/profile'
// import jwtdecode from 'jwt-decode'
export default {
  name: 'viewProfile',
  mounted () {
    xray.index()
    this.viewPegawai()
    this.viewProfileSummery()
    this.viewProfileReport()
  },
  data () {
    return {
      error: [],
      imgProfile: {
        pria: require('../../assets/images/user/14.jpg'),
        wanita: require('../../assets/images/user/15.jpg')
      },
      doctor: {
        profile: require('../../assets/images/user/1.jpg')
      },
      profileSummery: [],
      profileReport: [],
      profile: {
        terlambat: 0,
        tidakmasuk: 0,
        jamkerja: 0,
        masuk: '',
        keluar: ''

      },
      user: xray.currentUser()
    }
  },
  methods: {
    async viewPegawai () {
      const id = xray.currentUser().pegawai_id
      await Service.pegawai.findId(id).then(response => {
        if (response.status === 200) {
          this.pegawai = response.data.data[0]
        }
      }).catch(error => {
        this.error = error.response || error.response.status
      })
    },
    getAge (dateString) {
      var today = new Date()
      var birthDate = new Date(dateString)
      var age = today.getFullYear() - birthDate.getFullYear()
      var m = today.getMonth() - birthDate.getMonth()
      if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
        age--
      }
      return age
    },
    async viewProfileSummery () {
      const data = xray.currentUser().username
      await profile.profilesummery(data).then(response => {
        if (response.status === 200) {
          const summeryprofile = response.data.data
          this.profileSummery = summeryprofile
          const workingtimeduration = parseInt(response.data.data.reduce((a, b) => a + b.workingtimeduration, 0))
          const overtimeduration = parseInt(response.data.data.reduce((a, b) => a + b.overtimeduration, 0))
          const h = Math.floor((workingtimeduration + overtimeduration) / 3600)
          const m = Math.floor((workingtimeduration + overtimeduration) % 3600 / 60)
          const s = Math.floor((workingtimeduration + overtimeduration) % 3600 % 60)
          const todaysummery = summeryprofile[summeryprofile.length - 1]

          this.profile.terlambat = response.data.data.filter(el => el.checkindiff < 0).length
          this.profile.tidakmasuk = response.data.data.filter(el => el.shouldworking === 'y' && el.attendance === 't').length
          this.profile.jamkerja = h + ':' + m + ':' + s
          this.profile.masuk = todaysummery.checkin.substring(10)
          this.profile.keluar = todaysummery.checkout.substring(10)
        }
      }).catch(error => {
        this.error = error.response
      })
    },
    async viewProfileReport () {
      const data = xray.currentUser().username
      await profile.profilereport(data).then(response => {
        if (response.status === 200) {
          this.profileReport = response.data.data
        }
      }).catch(error => {
        this.error = error.response
      })
    }
  }
}
</script>
