<template>
  <div id="table-trx-remun" class="mb-4">
    <div v-show="isLoading">
        <div id="show-overlay"></div>
      <Loader :style="{ opacity: .5 }" />
    </div>
    <b-container fluid>
      <div class="bg-white p-2 rounded-custom shadow-sm mb-0">
        <div class="border p-2">
          <p class="h6 m-0 p-0 text-dark text-uppercase font-weight-bold">Transaksi Remunerasi V2</p>
        </div>
        <div class="border py-2 px-2">
          <div>
            <b-row class="mb-2">
              <b-col lg="2" class="pr-1">
                <div>
                  <multiselect v-model="selected.caraBayarFilterDummy" :allow-empty="false" label="name" :show-labels="false" placeholder="DTO CARA BAYAR" :options="options.caraBayarFilter" @input="valueMultiSelect($event, 'filterCaraBayar')" :searchable="true">
                  </multiselect>
                </div>
              </b-col>
              <b-col class="pr-1 pl-1">
                <flat-pickr :config="config" v-model="preview.tanggalDatang" value="" @on-change="tglEvent($event, '1')" placeholder="[ TANGGAL DATANG ]" class="w-100 calender-small custom-flat-pickr m-0">
                </flat-pickr>
              </b-col>
              <b-col class="pr-1 pl-1">
                <flat-pickr :config="config" v-model="preview.tanggalPulang" value="" @on-change="tglEvent($event, '2')" placeholder="[ TANGGAL PULANG ]" class="w-100 calender-small custom-flat-pickr m-0">
                </flat-pickr>
              </b-col>
              <b-col class="pr-1 pl-1">
                <flat-pickr :config="config" v-model="preview.tanggalKegiatan" value="" @on-change="tglEvent($event, '3')" placeholder="[ TANGGAL KEGIATAN ]" class="w-100 calender-small custom-flat-pickr m-0">
                </flat-pickr>
              </b-col>
              <b-col class="pr-1 pl-1">
                <div>
                  <b-form-input v-model="preview.tindakanRuangan" @input="tableRefresh('2')" type="search" class="custom-form-control-trx" placeholder="TINDAKAN..."></b-form-input>
                </div>
              </b-col>
              <b-col lg="2" class="pl-1">
                <b-form-input class="custom-form-control-trx" type="search" size="sm"  @input="tableRefresh('2')" v-model="preview.nomor" placeholder="NOMOR REKAM MEDIS..."></b-form-input>
              </b-col>
            </b-row>
          </div>
          <div>
            <b-row>
              <b-col cols="12" lg="2" class="pr-1">
                <div>
                  <multiselect v-model="selected.kegiatanFilterDummy" :allow-empty="false" label="text" :show-labels="false" placeholder="DTO KEGIATAN" :options="options.kegiatanDummy" @input="valueMultiSelect($event, 'kegiatan')" :searchable="true">
                  </multiselect>
                </div>
              </b-col>
              <!-- <b-col cols="12" lg="3" class="pr-1 pl-1">
                <div>
                  <multiselect :allow-empty="false" v-model="selected.ksmDummy" label="ksm_fatmawati" :show-labels="false" placeholder="KELOMPOK STAFF MEDIS" :options="options.ksm" :searchable="true" @input="valueMultiSelect($event, 'ksm')">
                  </multiselect>
                </div>
              </b-col> -->
              <b-col cols="12" lg="3" class="pr-1 pl-1">
                <div>
                  <multiselect :allow-empty="false" v-model="selected.ruanganDummy" label="deskripsi" :show-labels="false" placeholder="RUANGAN" :options="options.ruangan" :searchable="true" @input="valueMultiSelect($event, 'ruangan')">
                  </multiselect>
                </div>
              </b-col>
              <b-col cols="12" lg="3" class="pr-1 pl-1">
                <div class="d-flex flex-row align-items-center">
                  <div v-b-tooltip.hover.top="'Dokter yang dipilih akan ditampilkan saat aksi'">
                    <b-form-checkbox
                      :disabled="selected.dokter == '' ? true : false"
                      id="checkbox-dokter"
                      v-model="selected.showWithDokter"
                      name="checkbox-dokter"
                      value="1"
                      unchecked-value="0"
                      @change="valueShowWithDokter"
                    >
                    </b-form-checkbox>
                  </div>
                  <div class="w-100">
                    <multiselect :allow-empty="false" v-model="selected.dokterDummy" label="nama" :show-labels="false" placeholder="DOKTER" :options="options.dokter" :searchable="true" @search-change="getDokter" @input="valueDokter">
                    </multiselect>
                  </div>
                </div>
              </b-col>
              <b-col class="pl-1">
                <b-button class="custom-btn-height" size="sm" variant="warning" @click="tableRefresh('1')"><i class="fas fa-sync-alt"></i> Reset</b-button>
              </b-col>
            </b-row>
          </div>
        </div>
        <div class="m-0">
          <b-table ref="table" class="m-0" :sort-by.sync="table.sortBy" :sort-desc.sync="table.sortDesc"
          :filter="table.filter" @filtered="onFiltered" :busy="table.isBusy" :current-page="table.currentPage"
          :per-page="table.perPage" responsive stacked="md" bordered hover show-empty small :items="table.items"
          :fields="table.fields" :select-mode="table.selectMode" selectable>
            <template #table-busy>
              <div class="text-center my-2">
                <b-spinner class="align-middle text-primary mr-2"></b-spinner>
                <strong class="text-dark ">Loading...</strong>
              </div>
            </template>
            <template #cell(index)="data">
              {{ (table.currentPage*table.perPage)-table.perPage + data.index + 1 }}
            </template>
            <template #cell(tr.daftar_tanggal)="data">
              <span>{{ timeStamp(columnDotsConvert(data.item, 'tr.daftar_tanggal')) }}</span>
            </template>
            <template #cell(tr.daftar_tanggal_pulang)="data">
              <span>{{ timeStamp(columnDotsConvert(data.item, 'tr.daftar_tanggal_pulang')) }}</span>
            </template>
            <template #cell(kegiatan_tanggal)="data">
              <span>{{ timeStamp(data.item.kegiatan_tanggal) }}</span>
            </template>
            <template #cell(dto_kegiatan_crbyr)="data">
              <b-form-select
                class="cstm-select-trx-remun"
                :id="'per-page-select' + data.index"
                :options="options.kegiatanDummy"
                v-model="data.item.dto_kegiatan_crbyr"
                @change="updateNopen($event, data.item.daftar_nomor)"
              ></b-form-select>
            </template>
            <template #cell(kegiatan_tarif)="data">
              <span>{{ formatRibuan(data.item.kegiatan_tarif) }}</span>
            </template>
            <template #cell(kegiatan_tarif_hitung)="data">
              <span>{{ formatRibuan(data.item.kegiatan_tarif_hitung) }}</span>
            </template>
            <template #cell(jasa_diskon)="data">
              <span>{{ formatRibuan(data.item.jasa_diskon) }}</span>
            </template>
            <template #cell(action)="data">
              <span class="text-center">
                <b-button :id="'btn-aksi-' + data.index" size="sm" variant="primary" class="text-center" @click="showModalNoKunjungan(data.item.daftar_nomor)"><i class="fas fa-edit"></i></b-button>
              </span>
            </template>
          </b-table>
        </div>
        <div id="pagination-trx-remun" class="d-flex flex-row align-items-center border px-3">
          <div>
            <b-pagination
              v-model="table.currentPage"
              :total-rows="table.totalRows"
              :per-page="table.perPage"
              size="md"
              class="my-2"
            ></b-pagination>
          </div>
          <div>
            <span @click="tableRefresh('1')" class="hover">
              <i class="fas fa-sync text-primary"></i>
            </span>
          </div>
          <div class="ml-auto mr-3">
            <div :class="['d-flex flex-row shadow-sm px-2 py-1 text-white rounded-custom', preview.messageValue === 0 ? 'bg-success-custom' : 'bg-danger']" v-if="preview.messageModal === '1'">
              <div class="mr-3">
                <p class="mb-0 small">{{ preview.message }}</p>
              </div>
              <div @click="clearMessage">
                <p class="mb-0 small"><i class="fas fa-times-circle"></i></p>
              </div>
            </div>
          </div>
          <div class="mr-3">
            <b-form-select
              class="cstm-select-trx-remun"
              id="per-page-select"
              v-model="table.perPage"
              :options="table.pageOptions"
            ></b-form-select>
          </div>
          <div>
            <p class="text-dark mb-0">Menampilkan {{ table.perPage }} dari {{ formatRibuan(table.totalRows) }} baris</p>
          </div>
        </div>
      </div>
    </b-container>
    <div>
      <b-modal id="modal-trx-remun" ref="modal-trx-remun" :title="'Rinci Remunerasi' + ' : ' + preview.desc" size="xl" centered :no-close-on-backdrop="true" :static="true" :no-close-on-esc="true" :ok-only="true" ok-title="Tutup" @cancel="clearModal" @close="clearModal" @ok="clearModal">
        <div class="px-2">
          <b-tabs v-model="preview.activeTabIndex" @activate-tab="valueTab" align="start" class="bg-secondary-custom shadow-sm border">
            <b-tab title="Update Kunjungan" active>
              <div class="mb-0">
                <table class="table bg-white table-bordered table-sm m-0 p-0">
                  <thead>
                    <b-tr>
                      <b-th variant="white" class="py-2 text-center text-uppercase">No.</b-th>
                      <b-th variant="white" class="py-2 text-center text-uppercase">Tanggal</b-th>
                      <b-th variant="white" class="py-2 text-center text-uppercase">Nomor</b-th>
                      <b-th variant="white" class="py-2 text-center text-uppercase">Ruang</b-th>
                      <b-th variant="white" class="py-2 text-center text-uppercase">Keg. Kelompok</b-th>
                      <b-th variant="white" class="py-2 text-center text-uppercase">Cara Bayar</b-th>
                      <b-th variant="white" class="py-2 text-center text-uppercase">Keg. Tarif</b-th>
                      <b-th variant="white" class="py-2 text-center text-uppercase">Jasa</b-th>
                      <b-th variant="white" class="py-2 text-center text-uppercase">Aksi</b-th>
                    </b-tr>
                  </thead>
                  <tbody>
                    <b-tr v-for="(p, index) in nopen.detail" v-bind:key="p.cdx">
                      <b-td>
                        <div class="text-center">
                          <p class="mb-0">{{ index + 1 }}</p>
                        </div>
                      </b-td>
                      <b-td>
                        <div>
                          <p class="mb-0">{{ timeStamp(p.kunjungan_tanggal) }}</p>
                        </div>
                      </b-td>
                      <b-td>
                        <div>
                          <p class="mb-0 text-primary text-underline hover" @click="kunjunganRinci(p.kunjungan_nomor)"><u>{{ p.kunjungan_nomor }}</u></p>
                        </div>
                      </b-td>
                      <b-td>
                        <div>
                          <p class="mb-0">{{ p.kegiatan_ruang }}</p>
                        </div>
                      </b-td>
                      <b-td class="col-2">
                        <div>
                          <multiselect class="multiselect-trx-remun" label="kegiatan_kelompok" :allow-empty="false" v-model="selected.kegiatan[index].detail" :show-labels="false" @input="valueKegiatan($event, index)" placeholder="Kegiatan" :options="options.kegiatan" :searchable="true">
                          </multiselect>
                        </div>
                      </b-td>
                      <b-td class="col-1">
                        <div>
                          <p class="mb-0">{{ p.dto_kegiatan_crbyr }}</p>
                        </div>
                      </b-td>
                      <b-td>
                        <div>
                          <p class="mb-0 text-right">{{ formatRibuan(p.kegiatan_tarif) }}</p>
                        </div>
                      </b-td>
                      <b-td>
                        <div>
                          <p class="mb-0 text-right">{{ formatRibuan(p.kegiatan_tarif_hitung) }}</p>
                        </div>
                      </b-td>
                      <b-td class="col-1">
                        <div class=" text-center">
                          <b-button size="sm" variant="primary" @click="updateKunjungan(selected.kegiatan[index].detail, p.kunjungan_nomor)"><i class="fas fa-save"></i> Simpan</b-button>
                        </div>
                      </b-td>
                    </b-tr>
                  </tbody>
                </table>
              </div>
            </b-tab>
            <b-tab title="Update Detail">
              <div class="mb-0">
                <table class="table bg-white table-bordered table-sm m-0 p-0">
                  <thead>
                    <b-tr>
                      <b-th variant="white" class="py-2 text-center text-uppercase">No.</b-th>
                      <b-th variant="white" class="py-2 text-center text-uppercase">Tgl. Kunjungan</b-th>
                      <b-th variant="white" class="py-2 text-center text-uppercase">Tgl. Transaksi</b-th>
                      <b-th variant="white" class="py-2 text-center text-uppercase">Tgl. Hasil</b-th>
                      <b-th variant="white" class="py-2 text-center text-uppercase">ID</b-th>
                      <b-th variant="white" class="py-2 text-center text-uppercase">Kegiatan Tindakan</b-th>
                      <b-th variant="white" class="py-2 text-center text-uppercase">Ruang</b-th>
                      <b-th variant="white" class="py-2 text-center text-uppercase">Cara Bayar</b-th>
                      <b-th variant="white" class="py-2 text-center text-uppercase">Dokter</b-th>
                      <b-th variant="white" class="py-2 text-center text-uppercase">Posisi</b-th>
                      <b-th variant="white" class="py-2 text-center text-uppercase">Keg. Tarif</b-th>
                      <b-th variant="white" class="py-2 text-center text-uppercase">Jasa</b-th>
                      <b-th variant="white" class="py-2 text-center text-uppercase">Jasa Diskon</b-th>
                      <b-th variant="white" class="py-2 text-center text-uppercase">Verifikasi</b-th>
                      <b-th variant="white" class="py-2 text-center text-uppercase">Aksi</b-th>
                    </b-tr>
                  </thead>
                  <tbody>
                    <b-tr v-for="(p, index) in nopen.detail" v-bind:key="p.cdx">
                      <b-td>
                        <div class="text-center">
                          <p class="mb-0">{{ index + 1 }}</p>
                        </div>
                      </b-td>
                      <b-td>
                        <div>
                          <p class="mb-0">{{ timeStamp(p.kunjungan_tanggal) }}</p>
                        </div>
                      </b-td>
                      <b-td>
                        <div>
                          <p class="mb-0">{{ timeStamp(p.tgl_transaksi) }}</p>
                        </div>
                      </b-td>
                      <b-td>
                        <div>
                          <p class="mb-0">{{ timeStamp(p.tgl_hasil) }}</p>
                        </div>
                      </b-td>
                      <b-td>
                        <div>
                          <p class="mb-0">{{ p.kegiatan_id }}</p>
                        </div>
                      </b-td>
                      <b-td>
                        <div>
                          <p class="mb-0">{{ p.kegiatan_nama }}</p>
                        </div>
                      </b-td>
                      <b-td>
                        <div>
                          <p class="mb-0">{{ p.kegiatan_ruang }}</p>
                        </div>
                      </b-td>
                      <b-td class="col-1">
                        <div>
                          <p class="mb-0">{{ p.dto_kegiatan_crbyr }}</p>
                        </div>
                      </b-td>
                      <b-td>
                        <div>
                          <p class="mb-0">{{ p.dokter_nama }}</p>
                        </div>
                      </b-td>
                      <b-td class="col-1">
                        <div>
                          <multiselect :disabled="bitcontrol2Group.akses_posisi === true ? false : true" class="multiselect-trx-remun" label="label" :allow-empty="false" v-model="selected.kegiatanPosisi[index].detail" :show-labels="false" placeholder="Kegiatan" :options="options.kegiatanPosisi" @input="valueKegPosisi($event, index, 'a')" :searchable="true">
                          </multiselect>
                        </div>
                      </b-td>
                      <b-td>
                        <div>
                          <p class="mb-0 text-right">{{ formatRibuan(p.kegiatan_tarif) }}</p>
                        </div>
                      </b-td>
                      <b-td>
                        <div>
                          <p class="mb-0 text-right">{{ formatRibuan(p.kegiatan_tarif_hitung) }}</p>
                        </div>
                      </b-td>
                      <b-td class="col-1">
                        <div>
                          <b-input-group size="sm">
                            <b-form-input placeholder="Diskon Jasa" class="text-right text-dark" v-model="p.jasa_diskon" @input="convertRibuan($event, index, 'a')" @keypress="isNumberKey($event)"></b-form-input>
                          </b-input-group>
                        </div>
                      </b-td>
                      <b-td>
                        <div class=" text-center ">
                          <b-form-checkbox
                              :id="'checkbox-' + index"
                              :name="'checkbox-' + index"
                              v-model="p.sts_verifikasi1"
                              value="1"
                              unchecked-value="0"
                            >
                          </b-form-checkbox>
                        </div>
                      </b-td>
                      <b-td>
                        <div class=" text-center">
                          <b-button size="sm" variant="primary" @click="updateDetail(p.id_transaksi.toString(), p.id_transaksi_detail.toString(), p.jasa_diskon, p.sts_verifikasi1, p.kegiatan_posisi)"><i class="fas fa-save"></i> Simpan</b-button>
                        </div>
                      </b-td>
                    </b-tr>
                  </tbody>
                </table>
              </div>
            </b-tab>
            <b-tab class="ml-auto" :title="preview.tabsNoKunjungan">
              <div class="mb-0">
                <table class="table bg-white table-bordered table-sm m-0 p-0">
                  <thead>
                    <b-tr>
                      <b-th variant="white" class="py-2 text-center text-uppercase">No.</b-th>
                      <b-th variant="white" class="py-2 text-center text-uppercase">Tgl. Kunjungan</b-th>
                      <b-th variant="white" class="py-2 text-center text-uppercase">Tgl. Transaksi</b-th>
                      <b-th variant="white" class="py-2 text-center text-uppercase">Tgl. Hasil</b-th>
                      <b-th variant="white" class="py-2 text-center text-uppercase">ID</b-th>
                      <b-th variant="white" class="py-2 text-center text-uppercase">Kegiatan Tindakan</b-th>
                      <b-th variant="white" class="py-2 text-center text-uppercase">Ruang</b-th>
                      <b-th variant="white" class="py-2 text-center text-uppercase">Cara Bayar</b-th>
                      <b-th variant="white" class="py-2 text-center text-uppercase">Dokter</b-th>
                      <b-th variant="white" class="py-2 text-center text-uppercase">Posisi</b-th>
                      <b-th variant="white" class="py-2 text-center text-uppercase">Keg. Tarif</b-th>
                      <b-th variant="white" class="py-2 text-center text-uppercase">Jasa</b-th>
                      <b-th variant="white" class="py-2 text-center text-uppercase">Jasa Diskon</b-th>
                      <b-th variant="white" class="py-2 text-center text-uppercase">Verifikasi</b-th>
                      <b-th variant="white" class="py-2 text-center text-uppercase">Aksi</b-th>
                    </b-tr>
                  </thead>
                  <tbody>
                    <b-tr v-for="(p, index) in nokunjungan.detail" v-bind:key="p.cdx">
                      <b-td>
                        <div class="text-center">
                          <p class="mb-0">{{ index + 1 }}</p>
                        </div>
                      </b-td>
                      <b-td>
                        <div>
                          <p class="mb-0">{{ timeStamp(p.kunjungan_tanggal) }}</p>
                        </div>
                      </b-td>
                      <b-td>
                        <div>
                          <p class="mb-0">{{ timeStamp(p.tgl_transaksi) }}</p>
                        </div>
                      </b-td>
                      <b-td>
                        <div>
                          <p class="mb-0">{{ timeStamp(p.tgl_hasil) }}</p>
                        </div>
                      </b-td>
                      <b-td>
                        <div>
                          <p class="mb-0">{{ p.kegiatan_id }}</p>
                        </div>
                      </b-td>
                      <b-td>
                        <div>
                          <p class="mb-0">{{ p.kegiatan_nama }}</p>
                        </div>
                      </b-td>
                      <b-td>
                        <div>
                          <p class="mb-0">{{ p.kegiatan_ruang }}</p>
                        </div>
                      </b-td>
                      <b-td class="col-1">
                        <div>
                          <p class="mb-0">{{ p.dto_kegiatan_crbyr }}</p>
                        </div>
                      </b-td>
                      <b-td>
                        <div>
                          <p class="mb-0">{{ p.dokter_nama }}</p>
                        </div>
                      </b-td>
                      <b-td class="col-1">
                        <div>
                          <multiselect :disabled="bitcontrol2Group.akses_posisi === true ? false : true" class="multiselect-trx-remun" label="label" :allow-empty="false" v-model="selected.kegiatanPosisiTabs[index].detail" :show-labels="false" placeholder="Kegiatan" :options="options.kegiatanPosisi" @input="valueKegPosisi($event, index, 'b')" :searchable="true">
                          </multiselect>
                        </div>
                      </b-td>
                      <b-td>
                        <div>
                          <p class="mb-0 text-right">{{ formatRibuan(p.kegiatan_tarif) }}</p>
                        </div>
                      </b-td>
                      <b-td>
                        <div>
                          <p class="mb-0 text-right">{{ formatRibuan(p.kegiatan_tarif_hitung) }}</p>
                        </div>
                      </b-td>
                      <b-td class="col-1">
                        <div>
                          <b-input-group size="sm">
                            <b-form-input placeholder="Diskon Jasa" class="text-right text-dark" v-model="p.jasa_diskon" @input="convertRibuan($event, index, 'b')" @keypress="isNumberKey($event)"></b-form-input>
                          </b-input-group>
                        </div>
                      </b-td>
                      <b-td>
                        <div class=" text-center ">
                          <b-form-checkbox
                              :id="'checkbox-kunjungan-' + index"
                              :name="'checkbox-kunjungan-' + index"
                              v-model="p.sts_verifikasi1"
                              value="1"
                              unchecked-value="0"
                            >
                          </b-form-checkbox>
                        </div>
                      </b-td>
                      <b-td>
                        <div class="text-center">
                          <b-button size="sm" variant="primary" @click="updateDetail(p.id_transaksi.toString(), p.id_transaksi_detail.toString(), p.jasa_diskon, p.sts_verifikasi1, p.kegiatan_posisi)"><i class="fas fa-save"></i> Simpan</b-button>
                        </div>
                      </b-td>
                    </b-tr>
                  </tbody>
                </table>
              </div>
            </b-tab>
          </b-tabs>
          <!-- <pre>{{ nopen }}</pre> -->
          <div :class="['d-flex flex-row justify-content-end shadow-sm px-2 py-1 text-white rounded-custom mt-2', preview.messageValue === 0 ? 'bg-success-custom' : 'bg-danger']" v-if="preview.messageModal === '2'">
            <div class="mr-3">
              <p class="mb-0 small">{{ preview.message }}</p>
            </div>
            <div @click="clearMessage">
              <p class="mb-0 small"><i class="fas fa-times-circle"></i></p>
            </div>
          </div>
        </div>
      </b-modal>
    </div>
  </div>
</template>
<script>
import { xray } from '../../../config/pluginInit'
import newServices from '../../../services/fatmahost/pegawai/index.ts'
import flatPickr from 'vue-flatpickr-component'
import 'flatpickr/dist/flatpickr.css'
import Multiselect from 'vue-multiselect'

export default {
  name: 'RemunTrans',
  components: {
    flatPickr,
    Multiselect
  },
  data () {
    return {
      isLoading: false,
      table: {
        isBusy: false,
        items: this.list,
        sortBy: '',
        sortDesc: false,
        fields: [
          { label: 'NO.', key: 'index', class: 'text-lg-center' },
          { label: 'Tgl. Daftar', key: 'tr.daftar_tanggal', class: 'text-uppercase', sortable: true },
          { label: 'Tgl. Pulang', key: 'tr.daftar_tanggal_pulang', class: 'text-uppercase', sortable: true },
          { label: 'Tgl. Kegiatan', key: 'kegiatan_tanggal', class: 'text-uppercase', sortable: true },
          { label: 'NO. RM', key: 'pasien_nomor', class: 'text-uppercase', sortable: true },
          // { label: 'Instalasi', key: 'tr.kunj_instalasi', class: 'text-uppercase', sortable: true },
          { label: 'Ruangan', key: 'tr.kunj_ruang', class: 'text-uppercase', sortable: true },
          { label: 'Dokter', key: 'namadokter', class: 'text-uppercase', sortable: true },
          { label: 'TINDAKAN', key: 'kegiatan_nama', class: 'text-uppercase' },
          { label: 'Dto Kegiatan', key: 'dto_kegiatan_crbyr', class: 'text-uppercase' },
          { label: 'Cara Bayar', key: 'daftar_crbyr', class: 'text-uppercase' },
          // { label: 'KSM', key: 'kelompokstafmedis_grp', class: 'text-uppercase text-right' },
          { label: 'Aksi', key: 'action', class: 'text-uppercase text-lg-center' }
        ],
        filter: null,
        currentPage: null,
        perPage: null,
        pageOptions: [10, 25, 50, 100],
        totalRows: null,
        selectMode: 'single',
        selected: []
      },
      nopen: {
        detail: [
          {
            id_transaksi: '',
            id_transaksi_detail: '',
            kegiatan_id: '',
            kunjungan_tanggal: '',
            tgl_hasil: '',
            tgl_transaksi: '',
            dokter_nama: '',
            kegiatan_posisi: '',
            pasien_nama: '',
            dto_kegiatan_crbyr: '',
            kegiatan_nama: '',
            daftar_nomor: '',
            kegiatan_ruang: '',
            kegiatan_tarif: '',
            kegiatan_tarif_hitung: '',
            kunjungan_nomor: '',
            sts_verifikasi1: '0',
            jasa_diskon: ''
          }
        ]
      },
      nokunjungan: {
        detail: [
          {
            id_transaksi: '',
            id_transaksi_detail: '',
            kegiatan_id: '',
            kunjungan_tanggal: '',
            tgl_hasil: '',
            tgl_transaksi: '',
            dokter_nama: '',
            kegiatan_posisi: '',
            pasien_nama: '',
            dto_kegiatan_crbyr: '',
            kegiatan_nama: '',
            daftar_nomor: '',
            kegiatan_ruang: '',
            kegiatan_tarif: '',
            kegiatan_tarif_hitung: '',
            kunjungan_nomor: '',
            sts_verifikasi1: '0',
            jasa_diskon: ''
          }
        ]
      },
      preview: {
        objName: 'trx-remun-nopen-trans',
        objName2: 'trx-remun-nopen-rinci-trans',
        activeTabIndex: 0,
        nopen: '',
        tanggalDatang: '',
        tanggalPulang: '',
        tanggalKegiatan: '',
        tanggalDatangAwal: '',
        tanggalDatangAkhir: '',
        tanggalPulangAwal: '',
        tanggalPulangAkhir: '',
        tanggalKegiatanAwal: '',
        tanggalKegiatanAkhir: '',
        nomor: '',
        tindakanRuangan: '',
        message: '',
        messageValue: '',
        messageModal: null,
        desc: '',
        tabsNoKunjungan: ''
      },
      options: {
        kegiatan: [],
        kegiatanDummy: [],
        ksm: [],
        ruangan: [],
        dokter: [],
        caraBayarFilter: [
          { value: 'JKN', name: 'JKN' },
          { value: 'REGULER', name: 'REGULER' },
          { value: 'EXECUTIVE', name: 'EXECUTIVE' }
        ],
        kegiatanPosisi: []
      },
      selected: {
        kegiatan: [
          {
            detail: []
          }
        ],
        kegiatanPosisi: [
          {
            detail: []
          }
        ],
        kegiatanPosisiTabs: [
          {
            detail: []
          }
        ],
        caraBayarDummy: [],
        caraBayarFilterDummy: [],
        caraBayarFilter: '',
        kegiatanFilterDummy: [],
        kegiatanFilter: '',
        ksmDummy: [],
        ksm: [],
        ruanganDummy: [],
        ruangan: [],
        dokterDummy: [],
        dokter: [],
        showWithDokter: ''
      },
      config: {
        dateFormat: 'd-m-Y',
        disableMobile: true,
        mode: 'range'
      },
      configB: {
        dateFormat: 'd-m-Y',
        disableMobile: true
      },
      bitcontrol2Group: {
        akses_posisi: false
      },
      api: {
        version: 'v2'
      }
    }
  },
  mounted () {
    xray.index()
    this.getKegiatan()
    this.getPegawaiKsm()
    this.getKegPosisi()
    this.getRuanganRemun()
    this.getBitControl()
  },
  methods: {
    // TRANSAKSI START
    async list (ctx) {
      this.table.isBusy = true
      let object = 'trx-remun-trans'
      let sortfield = ''
      let sorting = ''
      let tanggalA = this.preview.tanggalDatangAwal
      let tanggalB = this.preview.tanggalDatangAkhir
      let tanggalC = this.preview.tanggalPulangAwal
      let tanggalD = this.preview.tanggalPulangAkhir
      let tanggalE = this.preview.tanggalKegiatanAwal
      let tanggalF = this.preview.tanggalKegiatanAkhir
      let nomor = this.preview.nomor
      let dokter = this.selected.dokter
      let ruangan = this.selected.ruangan
      let tindakan = this.preview.tindakanRuangan
      let kegiatan = this.selected.kegiatanFilter
      let carabayar = this.selected.caraBayarFilter
      let ksm = this.selected.ksm
      if (ctx.sortBy) {
        sortfield = ctx.sortBy
      } else {
        sortfield = ''
      }
      if (ctx.sortDesc === true) {
        sorting = 'DESC'
      } else if (ctx.sortDesc === false) {
        sorting = 'ASC'
      } else {
        sorting = ''
      }
      try {
        let response = await newServices.findOrAllTrxRemun(this.api.version, ctx.currentPage - 1, ctx.perPage, object, tanggalA, tanggalB, tanggalC, tanggalD, tanggalE, tanggalF, nomor, dokter, ruangan, tindakan, kegiatan, carabayar, ksm, sortfield, sorting)
        if (response.status === 200) {
          this.table.isBusy = false
          this.table.totalRows = response.data.pagination.total
          this.table.perPage = response.data.pagination.perPage
          this.table.currentPage = response.data.pagination.current
          return response.data.data
        } else {
          this.table.isBusy = false
          return []
        }
      } catch (error) {
        this.table.isBusy = false
        return []
      }
    },
    async showModalNoKunjungan (nopendafataran) {
      this.clearTabsNoKunjungan()
      this.clearMessage()
      this.clearArr('1')
      this.preview.nopen = nopendafataran
      this.isLoading = true
      let dokter = this.selected.showWithDokter === '1' ? this.selected.dokter : ''
      try {
        let response = await newServices.show_nopendafataran(this.api.version, nopendafataran, this.preview.objName, dokter)
        if (response.data.statcode === 0) {
          this.isLoading = false
          this.preview.desc = response.data.data[0].pasien_nomor.toString() + ' - ' + response.data.data[0].pasien_nama
          this.deleteRow(0, 'a')
          let data = response.data.data
          data.map((value, index) => {
            this.nopen.detail.push({
              id_transaksi: value.id_transaksi !== null ? value.id_transaksi : '',
              kegiatan_id: value.kegiatan_id !== null ? value.kegiatan_id : '',
              kunjungan_tanggal: value.kunjungan_tanggal !== null ? value.kunjungan_tanggal : '',
              tgl_transaksi: value.tgl_transaksi !== null ? value.tgl_transaksi : '',
              tgl_hasil: value.tgl_hasil !== null ? value.tgl_hasil : '',
              id_transaksi_detail: value.id_transaksi_detail !== null ? value.id_transaksi_detail : '',
              dokter_nama: value.dokter_nama !== null ? value.dokter_nama : '',
              kegiatan_posisi: value.kegiatan_posisi !== null ? value.kegiatan_posisi : '',
              pasien_nama: value.pasien_nama !== null ? value.pasien_nama : '',
              dto_kegiatan_crbyr: value.dto_kegiatan_crbyr !== null ? value.dto_kegiatan_crbyr : '',
              kegiatan_nama: value.kegiatan_nama !== null ? value.kegiatan_nama : '',
              daftar_nomor: value.daftar_nomor !== null ? value.daftar_nomor : '',
              kegiatan_ruang: value.kegiatan_ruang !== null ? value.kegiatan_ruang : '',
              kegiatan_tarif: value.kegiatan_tarif !== null ? value.kegiatan_tarif : null,
              kegiatan_tarif_hitung: value.kegiatan_tarif_hitung !== null ? value.kegiatan_tarif_hitung : null,
              kunjungan_nomor: value.kunjungan_nomor !== null ? value.kunjungan_nomor : '',
              sts_verifikasi1: value.sts_verifikasi1 !== '' ? value.sts_verifikasi1.toString() : '0',
              jasa_diskon: value.jasa_diskon !== null ? value.jasa_diskon.toString() : ''
            })
            this.selected.kegiatan.push(
              {
                detail: [
                  { kegiatan_kelompok: value.kegiatan_kelompok, dto_kegiatan_crbyr: value.dto_kegiatan_crbyr }
                ]
              }
            )
            this.selected.kegiatanPosisi.push(
              {
                detail: [
                  { label: value.kegiatan_posisi, value: value.kegiatan_posisi }
                ]
              }
            )
            this.selected.caraBayarDummy.push(
              { value: value.dto_kegiatan_crbyr }
            )
            this.convertRibuan(value.jasa_diskon.toString(), index, 'a')
          })
        } else {
          this.isLoading = false
          this.preview.messageModal = '2'
          this.preview.messageValue = response.data.statcode
          this.preview.message = response.data.message
        }
      } catch (error) {
        this.isLoading = false
        this.preview.messageModal = '2'
        this.preview.messageValue = 20001
        this.preview.message = error.message
      }
      this.$refs['modal-trx-remun'].show()
    },
    async updateNopen ($event, nopen) {
      this.isLoading = true
      let data = {
        object: 'trx-remun-nopen-trans',
        kegiatan_kelompok: $event
      }
      try {
        let response = await newServices.update_nopen(this.api.version, nopen, data)
        if (response.data.statcode === 0) {
          this.isLoading = false
          this.preview.messageModal = '1'
          this.preview.messageValue = response.data.statcode
          this.preview.message = response.data.message
          this.options.kegiatanDummy = []
          this.getKegiatan()
          this.table.currentPage = 1
          this.$refs.table.refresh()
        } else {
          this.isLoading = false
          this.preview.messageModal = '1'
          this.preview.messageValue = response.data.statcode
          this.preview.message = response.data.message
        }
      } catch (error) {
        this.isLoading = false
        this.preview.messageModal = '1'
        this.preview.messageValue = 20001
        this.preview.message = error.message
      }
    },
    async updateKunjungan (a, b) {
      this.isLoading = true
      let data = {
        object: 'trx-remun-kunjungan-trans',
        kegiatan_kelompok: a[0] ? a[0].kegiatan_kelompok : a.kegiatan_kelompok
      }
      try {
        let response = await newServices.update_kunjungan(this.api.version, b, data)
        if (response.data.statcode === 0) {
          this.isLoading = false
          this.preview.messageModal = '2'
          this.preview.messageValue = response.data.statcode
          this.preview.message = response.data.message
        } else {
          this.isLoading = false
          this.preview.messageModal = '2'
          this.preview.messageValue = response.data.statcode
          this.preview.message = response.data.message
        }
      } catch (error) {
        this.isLoading = false
        this.preview.messageModal = '2'
        this.preview.messageValue = 20001
        this.preview.message = error.message
      }
    },
    async updateDetail (idtransaksi, iddetail, diskon, verifikasi, posisi) {
      this.isLoading = true
      let data = {
        object: 'trx-remun-detil-trans',
        sts_verifikasi1: verifikasi,
        jasa_diskon: diskon.replace(/\./g, ''),
        kegiatan_posisi: posisi
      }
      try {
        let response = await newServices.update_detil_v2(this.api.version, idtransaksi, iddetail, data)
        if (response.data.statcode === 0) {
          this.isLoading = false
          this.preview.messageModal = '2'
          this.preview.messageValue = response.data.statcode
          this.preview.message = response.data.message
        } else {
          this.isLoading = false
          this.preview.messageModal = '2'
          this.preview.messageValue = response.data.statcode
          this.preview.message = response.data.message
        }
      } catch (error) {
        this.isLoading = false
        this.preview.messageModal = '2'
        this.preview.messageValue = 20001
        this.preview.message = error.message
      }
    },
    async kunjunganRinci (value) {
      this.clearMessage()
      this.clearArr('2')
      this.preview.tabsNoKunjungan = value
      this.preview.activeTabIndex = 2
      let object = 'trx-remun-kunjungan-trans'
      let dokter = this.selected.showWithDokter === '1' ? this.selected.dokter : ''
      try {
        let response = await newServices.show_nokunjungan_rinci(this.api.version, object, value, dokter)
        if (response.data.statcode === 0) {
          this.isLoading = false
          this.preview.desc = response.data.data[0].pasien_nomor.toString() + ' - ' + response.data.data[0].pasien_nama
          this.deleteRow(0, 'b')
          let data = response.data.data
          data.map((value, index) => {
            this.nokunjungan.detail.push({
              id_transaksi: value.id_transaksi !== null ? value.id_transaksi : '',
              kegiatan_id: value.kegiatan_id !== null ? value.kegiatan_id : '',
              kunjungan_tanggal: value.kunjungan_tanggal !== null ? value.kunjungan_tanggal : '',
              tgl_transaksi: value.tgl_transaksi !== null ? value.tgl_transaksi : '',
              tgl_hasil: value.tgl_hasil !== null ? value.tgl_hasil : '',
              id_transaksi_detail: value.id_transaksi_detail !== null ? value.id_transaksi_detail : '',
              dokter_nama: value.dokter_nama !== null ? value.dokter_nama : '',
              kegiatan_posisi: value.kegiatan_posisi !== null ? value.kegiatan_posisi : '',
              pasien_nama: value.pasien_nama !== null ? value.pasien_nama : '',
              dto_kegiatan_crbyr: value.dto_kegiatan_crbyr !== null ? value.dto_kegiatan_crbyr : '',
              kegiatan_nama: value.kegiatan_nama !== null ? value.kegiatan_nama : '',
              daftar_nomor: value.daftar_nomor !== null ? value.daftar_nomor : '',
              kegiatan_ruang: value.kegiatan_ruang !== null ? value.kegiatan_ruang : '',
              kegiatan_tarif: value.kegiatan_tarif !== null ? value.kegiatan_tarif : null,
              kegiatan_tarif_hitung: value.kegiatan_tarif_hitung !== null ? value.kegiatan_tarif_hitung : null,
              kunjungan_nomor: value.kunjungan_nomor !== null ? value.kunjungan_nomor : '',
              sts_verifikasi1: value.sts_verifikasi1 !== '' ? value.sts_verifikasi1.toString() : '0',
              jasa_diskon: ''
            })
            this.selected.kegiatanPosisiTabs.push(
              {
                detail: [
                  { label: value.kegiatan_posisi, value: value.kegiatan_posisi }
                ]
              }
            )
            this.convertRibuan(value.jasa_diskon.toString(), index, 'b')
          })
        } else {
          this.isLoading = false
          this.preview.messageModal = '2'
          this.preview.messageValue = response.data.statcode
          this.preview.message = response.data.message
        }
      } catch (error) {
        this.isLoading = false
        this.preview.messageModal = '2'
        this.preview.messageValue = 20001
        this.preview.message = error.message
      }
    },
    async reloadShowModalNoKunjungan (value) {
      this.isLoading = true
      let objName = ''
      let dokter = this.selected.showWithDokter === '1' ? this.selected.dokter : ''
      if (value === 0) {
        objName = this.preview.objName
      } else if (value === 1) {
        objName = this.preview.objName2
      }
      try {
        let response = await newServices.show_nopendafataran(this.api.version, this.preview.nopen, objName, dokter)
        if (response.data.statcode === 0) {
          this.isLoading = false
          this.preview.desc = response.data.data[0].pasien_nomor.toString() + ' - ' + response.data.data[0].pasien_nama
          this.deleteRow(0, 'a')
          let data = response.data.data
          data.map((value, index) => {
            this.nopen.detail.push({
              id_transaksi: value.id_transaksi !== null ? value.id_transaksi : '',
              kegiatan_id: value.kegiatan_id !== null ? value.kegiatan_id : '',
              kunjungan_tanggal: value.kunjungan_tanggal !== null ? value.kunjungan_tanggal : '',
              tgl_transaksi: value.tgl_transaksi !== null ? value.tgl_transaksi : '',
              tgl_hasil: value.tgl_hasil !== null ? value.tgl_hasil : '',
              id_transaksi_detail: value.id_transaksi_detail !== null ? value.id_transaksi_detail : '',
              dokter_nama: value.dokter_nama !== null ? value.dokter_nama : '',
              kegiatan_posisi: value.kegiatan_posisi !== null ? value.kegiatan_posisi : '',
              pasien_nama: value.pasien_nama !== null ? value.pasien_nama : '',
              dto_kegiatan_crbyr: value.dto_kegiatan_crbyr !== null ? value.dto_kegiatan_crbyr : '',
              kegiatan_nama: value.kegiatan_nama !== null ? value.kegiatan_nama : '',
              daftar_nomor: value.daftar_nomor !== null ? value.daftar_nomor : '',
              kegiatan_ruang: value.kegiatan_ruang !== null ? value.kegiatan_ruang : '',
              kegiatan_tarif: value.kegiatan_tarif !== null ? value.kegiatan_tarif : null,
              kegiatan_tarif_hitung: value.kegiatan_tarif_hitung !== null ? value.kegiatan_tarif_hitung : null,
              kunjungan_nomor: value.kunjungan_nomor !== null ? value.kunjungan_nomor : '',
              sts_verifikasi1: value.sts_verifikasi1 !== '' ? value.sts_verifikasi1.toString() : '0',
              jasa_diskon: ''
            })
            this.selected.kegiatan.push(
              {
                detail: [
                  { kegiatan_kelompok: value.kegiatan_kelompok, dto_kegiatan_crbyr: value.dto_kegiatan_crbyr }
                ]
              }
            )
            this.selected.kegiatanPosisi.push(
              {
                detail: [
                  { label: value.kegiatan_posisi, value: value.kegiatan_posisi }
                ]
              }
            )
            this.selected.caraBayarDummy.push(
              { value: value.dto_kegiatan_crbyr }
            )
            this.convertRibuan(value.jasa_diskon.toString(), index, 'a')
          })
        } else {
          this.isLoading = false
          this.preview.messageModal = '2'
          this.preview.messageValue = response.data.statcode
          this.preview.message = response.data.message
        }
      } catch (error) {
        this.isLoading = false
        this.preview.messageModal = '2'
        this.preview.messageValue = 20001
        this.preview.message = error.message
      }
    },
    // TRANSAKSI END
    // -----------------------------------------------------------------------------------------------------
    // GET SELECTIZE START
    async getKegPosisi () {
      let response = await newServices.getKegiatanPosisi()
      if (response.data.statcode === 0) {
        this.options.kegiatanPosisi = response.data.data
      }
    },
    async getPegawaiKsm () {
      let response = await newServices.selectizePegawaiKsmGrup()
      if (response.data.statcode === 0) {
        this.options.ksm = response.data.data
      }
    },
    async getRuanganRemun () {
      let response = await newServices.getRuangRemun()
      if (response.data.statcode === 0) {
        this.options.ruangan = response.data.data
      }
    },
    async getDokter (value) {
      let response = await newServices.trx_pegawai(value)
      if (response.data.statcode === 0) {
        this.options.dokter = response.data.data
      }
    },
    async getKegiatan () {
      let response = await newServices.tindakan_remun()
      this.options.kegiatan = response.data.data
      response.data.data.map((value, index) => {
        this.options.kegiatanDummy.push(
          { value: value.kegiatan_kelompok, text: value.kegiatan_kelompok, disabled: false }
        )
      })
      this.options.kegiatanDummy.push(
        { value: '', text: '-', disabled: false }
      )
    },
    // GET SELECTIZE END
    // -----------------------------------------------------------------------------------------------------
    // MULTISELECT START
    valueMultiSelect ($event, param) {
      if (param === 'kegiatan') this.selected.kegiatanFilter = $event.value
      if (param === 'ksm') this.selected.ksm = $event.id.toString()
      if (param === 'ruangan') this.selected.ruangan = $event.id
      if (param === 'filterCaraBayar') this.selected.caraBayarFilter = $event.value
      this.table.currentPage = 1
      this.tableRefresh('2')
    },
    valueKegPosisi ($event, index, param) {
      if (param === 'a') {
        this.nopen.detail[index].kegiatan_posisi = $event.value
      } else if (param === 'b') {
        this.nokunjungan.detail[index].kegiatan_posisi = $event.value
      }
    },
    valueDokter (value) {
      this.selected.dokter = value.nip.toString()
      this.tableRefresh('2')
    },
    valueKegiatan ($event, index) {
      if ($event.keyid === 'EXE') {
        this.nopen.detail[index].dto_kegiatan_crbyr = 'EXECUTIVE'
      } else {
        this.nopen.detail[index].dto_kegiatan_crbyr = this.selected.caraBayarDummy[index].value
      }
    },
    valueShowWithDokter (e) {
      if (e === '1') {
        this.preview.objName = 'trx-remun-nopen-detil-trans'
        this.preview.objName2 = 'trx-remun-nopen-rinci-detil-trans'
      } else {
        this.preview.objName = 'trx-remun-nopen-trans'
        this.preview.objName2 = 'trx-remun-nopen-rinci-trans'
      }
    },
    // MULTISELECT END
    // -----------------------------------------------------------------------------------------------------
    // TABLE START
    onFiltered (filteredItems) {
      this.table.totalRows = filteredItems.length
    },
    onRowSelected (items) {
      this.selected = items
    },
    tableRefresh (param) {
      if (param === '1') {
        this.preview.tanggalDatang = ''
        this.preview.tanggalDatangAwal = ''
        this.preview.tanggalDatangAkhir = ''
        this.preview.tanggalPulang = ''
        this.preview.tanggalPulangAwal = ''
        this.preview.tanggalPulangAkhir = ''
        this.preview.tanggalKegiatan = ''
        this.preview.tanggalKegiatanAwal = ''
        this.preview.tanggalKegiatanAkhir = ''
        this.preview.tindakanRuangan = ''
        //
        this.selected.kegiatanFilterDummy = []
        this.selected.kegiatanFilter = ''
        this.selected.ksmDummy = []
        this.selected.ksm = ''
        this.selected.dokterDummy = []
        this.selected.dokter = ''
        this.selected.caraBayarFilterDummy = []
        this.selected.caraBayarFilter = ''
        this.selected.ruanganDummy = []
        this.selected.ruangan = ''
        //
        this.table.filter = ''
        this.preview.nomor = ''
        this.table.currentPage = 1
        this.$refs.table.refresh()
      } else {
        this.table.currentPage = 1
        this.$refs.table.refresh()
      }
    },
    // TABLE END
    // -----------------------------------------------------------------------------------------------------
    isNumberKey ($event) {
      $event = ($event) || window.event
      let charCode = ($event.which) ? $event.which : $event.keyCode
      if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
        $event.preventDefault()
      } else {
        return true
      }
    },
    getBitControl () {
      let access = xray.bitcontrol(this.$route.name)
      if (access.bitcontrol2.charAt(0) === '1') {
        this.bitcontrol2Group.akses_posisi = true
      } else {
        this.bitcontrol2Group.akses_posisi = false
      }
    },
    columnDotsConvert (column, desc) {
      return column[desc]
    },
    formatRibuan (value) {
      if (value !== null) {
        let amount = parseInt(value)
        return amount.toLocaleString()
      } else {
        return '0'
      }
    },
    tglEvent ($event, param) {
      if ($event[1] !== undefined) {
        let date = new Date($event[0])
        let day = String(date.getDate()).padStart(2, 0)
        let month = String(date.getMonth() + 1).padStart(2, 0)
        let year = String(date.getFullYear())
        //
        let date2 = new Date($event[1])
        let day2 = String(date2.getDate()).padStart(2, 0)
        let month2 = String(date2.getMonth() + 1).padStart(2, 0)
        let year2 = String(date2.getFullYear())
        let currentDate = year + '-' + month + '-' + day
        let currentDate2 = year2 + '-' + month2 + '-' + day2
        if (param === '1') {
          this.preview.tanggalDatangAwal = currentDate
          this.preview.tanggalDatangAkhir = currentDate2
        }
        if (param === '2') {
          this.preview.tanggalPulangAwal = currentDate
          this.preview.tanggalPulangAkhir = currentDate2
        }
        if (param === '3') {
          this.preview.tanggalKegiatanAwal = currentDate
          this.preview.tanggalKegiatanAkhir = currentDate2
        }
        this.table.currentPage = 1
        this.$refs.table.refresh()
      }
    },
    timeStamp (value) {
      if (value) {
        let currentDate = new Date(value)
        let hours = String(currentDate.getHours()).padStart(2, 0)
        let minutes = String(currentDate.getMinutes()).padStart(2, 0)
        let day = String(currentDate.getDate()).padStart(2, 0)
        let month = String(currentDate.getMonth() + 1).padStart(2, 0)
        let year = String(currentDate.getFullYear())
        let final = day + '-' + month + '-' + year + ' ' + hours + ':' + minutes
        return final
      } else {
        return '00-00-000 00:00'
      }
    },
    convertRibuan ($event, index, value) {
      let numberString = $event.replace(/[^,\d]/g, '').toString()
      let split = numberString.split(',')
      let sisa = split[0].length % 3
      let rupiah = split[0].substr(0, sisa)
      let ribuan = split[0].substr(sisa).match(/\d{3}/gi)
      if (ribuan) {
        let separator = sisa ? '.' : ''
        rupiah += separator + ribuan.join('.')
      }
      rupiah = split[1] !== undefined ? rupiah + ',' + split[1] : rupiah
      if (value === 'a') {
        this.nopen.detail[index].jasa_diskon = rupiah
      } else if (value === 'b') {
        this.nokunjungan.detail[index].jasa_diskon = rupiah
      }
    },
    clearArr (value) {
      if (value === '1') {
        this.nopen.detail = [
          {
            id_transaksi: '',
            id_transaksi_detail: '',
            kegiatan_id: '',
            kunjungan_tanggal: '',
            tgl_hasil: '',
            tgl_transaksi: '',
            dokter_nama: '',
            kegiatan_posisi: '',
            pasien_nama: '',
            dto_kegiatan_crbyr: '',
            kegiatan_nama: '',
            daftar_nomor: '',
            kegiatan_ruang: '',
            kegiatan_tarif: '',
            kegiatan_tarif_hitung: '',
            kunjungan_nomor: '',
            sts_verifikasi1: '0',
            jasa_diskon: ''
          }
        ]
        this.selected.caraBayarDummy = []
        this.selected.kegiatan = [
          {
            detail: []
          }
        ]
        this.selected.kegiatanPosisi = [
          {
            detail: []
          }
        ]
      } else if (value === '2') {
        this.nokunjungan.detail = [
          {
            id_transaksi: '',
            id_transaksi_detail: '',
            kegiatan_id: '',
            kunjungan_tanggal: '',
            tgl_hasil: '',
            tgl_transaksi: '',
            dokter_nama: '',
            kegiatan_posisi: '',
            pasien_nama: '',
            dto_kegiatan_crbyr: '',
            kegiatan_nama: '',
            daftar_nomor: '',
            kegiatan_ruang: '',
            kegiatan_tarif: '',
            kegiatan_tarif_hitung: '',
            kunjungan_nomor: '',
            sts_verifikasi1: '0',
            jasa_diskon: ''
          }
        ]
        this.selected.kegiatanPosisiTabs = [
          {
            detail: []
          }
        ]
      }
    },
    deleteRow (index, value) {
      if (value === 'a') {
        this.nopen.detail.splice(index, 1)
        this.selected.kegiatan.splice(index, 1)
        this.selected.kegiatanPosisi.splice(index, 1)
      } else if (value === 'b') {
        this.nokunjungan.detail.splice(index, 1)
        this.selected.kegiatanPosisiTabs.splice(index, 1)
      }
    },
    clearMessage () {
      this.preview.messageModal = null
      this.preview.messageValue = ''
    },
    clearModal () {
      this.preview.activeTabIndex = 0
      this.preview.objName = 'trx-remun-nopen-trans'
      this.clearMessage()
      this.clearArr('1')
    },
    clearTabsNoKunjungan () {
      this.preview.tabsNoKunjungan = ''
    },
    valueTab (value) {
      if (value !== 2) {
        this.clearMessage()
        this.clearArr('1')
        this.reloadShowModalNoKunjungan(value)
      }
    }
  }
}
</script>
<style>
  #table-trx-remun .letter-spacing-custom {
    letter-spacing: 1px
  }
  #table-trx-remun .table thead th {
    font-size: .8rem;
    font-weight: normal;
    text-align: center;
  }
  #table-trx-remun .table td {
    font-size: .8rem;
  }
  #table-trx-remun .rounded-custom {
    border-radius: 5px;
  }
  #table-trx-remun .page-link {
    border: none;
    color: gray!important;
    margin-right: 15px;
  }
  #table-trx-remun .page-item.active .page-link {
    background-color: transparent;
    border-color: transparent;
    color: #089bab!important;
    font-weight: bold;
  }
  #table-trx-remun .cstm-select-trx-remun {
    font-size: .8rem;
    height: 35px;
  }
  #table-trx-remun .hover {
    cursor: pointer;
  }
  #modal-trx-remun .modal-dialog {
    min-width: 98%;
    height: auto;
  }
  #modal-trx-remun .modal-body {
    overflow-y: scroll;
    height: 75vh!important;
    padding: 0;
  }
  #modal-trx-remun .modal-header {
    background: #3399333a;
  }
  #modal-trx-remun .modal-footer {
    background: #3399333a;
  }
  #modal-trx-remun .modal-body {
    background: #3399331b;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }
  #table-trx-remun .tgl-width-custom {
    width: 12%;
  }
  #table-trx-remun .custom-flat-pickr{
    border: 1px solid rgb(233, 233, 233);
    height: 35px;
    padding-left: 10px;
    padding-top: 5px;
    padding-bottom: 5px;
    border: 1px solid rgb(209, 208, 208)!important;
    border-radius: 5px;
    font-size: 12px;
    color: black;
  }
  #table-trx-remun .custom-form-control-trx {
    height: 35px;
    line-height: 35px;
    font-size: 12px;
    border-radius: 5px;
    border: 1px solid rgb(209, 208, 208)!important;
  }
  #table-trx-remun .custom-btn-height {
    height: 35px;
  }
  #table-trx-remun .bg-success-custom {
    background-color: green;
  }
  #modal-trx-remun .multiselect__single {
    font-weight: normal;
    font-size: 13px!important;
  }
  #modal-trx-remun .nav-tabs {
    margin-bottom: 0px;
  }
  #table-trx-remun .multiselect__placeholder, #modal-trx-remun .multiselect__placeholder {
    font-weight: normal;
    font-size: 13px!important;
  }
  #table-trx-remun .multiselect__option, #modal-trx-remun .multiselect__option {
    font-weight: normal;
    font-size: 13px;
  }
  #table-trx-remun .multiselect__tags, #modal-trx-remun .multiselect__tags {
    font-weight: normal;
    padding: 5px 40px 0 8px!important;
    height: 35px!important;
    min-height: 35px!important;
    border: 1px solid rgba(0, 0, 0, 0.212);
  }
  #table-trx-remun .multiselect, #modal-trx-remun .multiselect {
    height: 33px!important;
    min-height: 33px!important;
  }
  #table-trx-remun .multiselect__select , #modal-trx-remun .multiselect__select  {
    height: 33px!important;
    min-height: 33px!important;
  }
  #modal-trx-remun .multiselect--disabled .multiselect__tags {
    height: 35px!important;
    min-height: 35px!important;
    overflow: hidden;
    background: #E9ECEF;
  }
  #modal-trx-remun .multiselect--disabled {
    opacity: 1;
  }
  #modal-trx-remun .multiselect--disabled .multiselect__tags .multiselect__single {
    overflow: hidden;
    background: transparent;
  }
  #modal-trx-remun .bg-secondary-custom {
    background-color: #089bab1a;
  }
</style>
