<template>
  <b-container fluid class="eklaim-monitoring">
    <div v-show="isLoading">
      <div id="show-overlay"></div>
      <Loader :style="{ opacity: .5 }" />
    </div>
    <b-row>
      <b-col md="4">
        <iq-card class="px-4 pt-4 pb-2">
          <b-row class="mb-3">
            <b-col cols="6" lg="12">
              <div>
                <template>
                  <b-form-group label="Jenis Klaim">
                    <multiselect v-model="selectedRuang" :show-labels="false" :limit="3" @input="valRuang"
                    label="name" placeholder="Jenis Klaim" :options="comboruang" :searchable="true">
                  </multiselect>
                  </b-form-group>
                </template>
              </div>
            </b-col>
            <b-col cols="6" lg="12">
              <div>
                <b-form-group label="Periode">
                  <template>
                    <flat-pickr v-model="dataProses.tgl" :config="config.monthSelect" placeholder="Pilih Periode" class="w-100 custom-flatpicker calender-small">
                    </flat-pickr>
                  </template>
                </b-form-group>
              </div>
            </b-col>
          </b-row>
          <b-row class="justify-content-center">
            <b-col cols="12" lg="6">
              <div :class="['text-center', tglProses]">
                <div>
                  <b-button variant="warning" @click="prosesData" class="btn-block shadow-sm mb-4"><i class="fas fa-sync"></i> Proses</b-button>
                </div>
              </div>
              <div :class="['text-center', resetProses]">
                <div>
                  <b-button variant="light" @click="prosesClear" class="btn-block shadow-sm mb-4"><i class="fas fa-sync"></i> Reset</b-button>
                </div>
              </div>
            </b-col>
            <b-col cols="12" lg="6" v-if="sheets[0].data.length !== 0">
              <div class="text-center">
                <div>
                  <xlsx-workbook>
                    <xlsx-sheet :collection="sheet.data" v-for="sheet in sheets" :key="sheet.name" :sheet-name="sheet.name" filename="cobaaaa.xlsx" />
                    <xlsx-download>
                      <b-button variant="success" class="btn-block shadow-sm mb-4"><i class="fas fa-file-excel"></i> Export</b-button>
                    </xlsx-download>
                  </xlsx-workbook>
                </div>
              </div>
            </b-col>
          </b-row>
        </iq-card>
      </b-col>
      <b-col md="8">
        <iq-card class="p-4 bg-white">
          <div class="mb-3">
            <p class="h6 font-weight-light mb-0">Filter Data</p>
          </div>
          <b-row class="mb-1 align-items-center">
            <b-col cols="12" lg="3">
              <b-form-group class="mb-0">
                <template>
                  <flat-pickr v-model="tgl" :config="config.monthSelect" placeholder="Pilih Periode"
                    class="w-100 custom-flatpicke-2 calender-small">
                  </flat-pickr>
                </template>
              </b-form-group>
            </b-col>
            <b-col cols="12" lg="4">
              <b-form-group class="select-status mb-0">
                <multiselect v-model="selectedStatus" :show-labels="false" :limit="3" @input="valStatus" label="name"
                  placeholder="Pilih Status" :options="comboStatus" :searchable="true">
                </multiselect>
              </b-form-group>
            </b-col>
            <b-col cols="12" lg="2">
              <b-button variant="dark" @click="filterup" class="btn-block"><i class="fas fa-filter"></i> Filter</b-button>
            </b-col>
            <b-col cols="12" lg="3">
              <b-button variant="danger" @click="filterclear" class="btn-block"><i class="fas fa-sync"></i> Bersihkan Filter
              </b-button>
            </b-col>
          </b-row>
        </iq-card>
        <b-row>
          <b-col>
            <iq-card class="p-4">
              <div class="mb-3">
                <p class="h6 font-weight-light mb-0">Rekonsiliasi Tahap I</p>
              </div>
              <b-row class="mb-1">
                <b-col cols="12" lg="8">
                  <div>
                    <b-form-group class="mb-0">
                      <template>
                        <div>
                          <b-form-file v-model="file1" :state="Boolean(file1)" placeholder="Upload File..."></b-form-file>
                        </div>
                      </template>
                    </b-form-group>
                  </div>
                </b-col>
                <b-col cols="12" lg="4">
                  <div class="">
                    <b-button variant="primary" @click="importUpload1" class="btn-block"> Import</b-button>
                  </div>
                </b-col>
              </b-row>
            </iq-card>
          </b-col>
          <b-col>
            <iq-card class="p-4 bg-white">
              <template>
                <div class="mb-1">
                  <div class="mb-3">
                    <p class="h6 font-weight-light mb-0">Cari SIP</p>
                  </div>
                  <b-row class="justify-content-between">
                    <b-col class="text-center">
                      <form class="searchbox">
                        <input type="search" v-model="filter" class="form-control bg-white" id="filter-input"
                          placeholder="Cari No. SIP">
                      </form>
                    </b-col>
                  </b-row>
                </div>
              </template>
            </iq-card>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <b-row>
      <b-col md="12">
        <iq-card class="py-4 px-4">
          <template>
            <b-row>
              <b-col md="12">
                <b-table ref="table" outlined hover :busy="isBusy" :items="listImport" :fields="fields" :current-page="currentPage"
                  :per-page="perPage" :filter="filter" stacked="md" show-empty @filtered="onFiltered">

                  <template v-slot:cell(action)="data">
                    <b-button variant=" iq-bg-success mr-1 mb-1" size="lg" @click="viewEklaimMonitoring(data.item.NOPEN)"><i class="ri-eye-line m-0"></i></b-button>
                  </template>

                </b-table>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
              </b-col>
              <b-col>
              </b-col>
              <b-col sm="12" md="3" class=" my-1">
                <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage" align="fill" size="sm"
                  class="my-0"></b-pagination>
              </b-col>
            </b-row>
          </template>
        </iq-card>
      </b-col>
    </b-row>
    <b-modal ref="modal-import-ok" size="md" title="Informasi" ok-title="Oke" :ok-only=true :hide-header=true centered>
      <div class="text-center py-4">
        <div v-if="okRespon !== ''">
          <lottie :options="defaultOptions" :autoPlay="true" :width="250" :height="250" />
        </div>
        <p class="h5 text-dark font-weight-bold mb-0" v-if="okRespon !== ''">{{okRespon}}</p>
      </div>
    </b-modal>
    <b-modal ref="modal-import-gagal" size="md" title="Informasi" ok-title="Oke" :ok-only=true :hide-header=true centered>
      <div class="text-center py-4">
        <div v-if="errRespon !== ''">
          <lottie :options="defaultOptions2" :autoPlay="true" :width="250" :height="250" />
        </div>
        <p class="h5 text-dark font-weight-bold mb-0" v-if="errRespon !== ''">{{errRespon}}</p>
      </div>
    </b-modal>
  </b-container>
</template>
<script>
import { xray } from '../../config/pluginInit'
import Eklaim from '../../services/eklaim'
import Loader from '../../components/xray/loader/Loader'
import flatPickr from 'vue-flatpickr-component'
import MonthSelectPlugin from 'flatpickr/dist/plugins/monthSelect/index.js'
import 'flatpickr/dist/plugins/monthSelect/style.css'
import Multiselect from 'vue-multiselect'
import XlsxWorkbook from 'vue-xlsx/dist/components/XlsxWorkbook'
import XlsxSheet from 'vue-xlsx/dist/components/XlsxSheet'
import XlsxDownload from 'vue-xlsx/dist/components/XlsxDownload'
import Lottie from 'vue-lottie'
import animationLogo from '../../assets/lottie/checked.json'
import animationLogo2 from '../../assets/lottie/failed.json'

export default {
  name: 'UiDataTable',
  mounted () {
    xray.index()
  },
  components: {
    Loader,
    Multiselect,
    flatPickr,
    XlsxWorkbook,
    XlsxSheet,
    XlsxDownload,
    Lottie
  },
  data () {
    return {
      isLoading: false,
      isBusy: false,
      error: [],
      selectedRuang: [],
      comboruang: [
        { name: 'Rawat Jalan', id: 10101 },
        { name: 'Rawat Inap', id: 10102 }
      ],
      selectedStatus: [],
      comboStatus: [
        { name: 'Layak', id: 1 },
        { name: 'Proses Kedua', id: 2 },
        { name: 'Tidak Layak', id: 3 }
      ],
      file1: null,
      file2: null,
      file3: null,
      dataProses: {
        tgl: '',
        ruang: ''
      },
      tgl: '',
      status: '',
      config: {
        monthSelect: {
          plugins: [
            new MonthSelectPlugin({
              shorthand: true,
              dateFormat: 'm-Y',
              altFormat: 'F Y',
              theme: 'dark'
            })
          ]
        }
      },
      fields: [
        { label: 'No. SEP', key: 'sep', class: 'text-center' },
        { label: 'Instalasi', key: 'deskripsi', class: 'text-left' },
        { label: 'Tgl. Verifikasi', key: 'tglverifikasi', class: 'text-left' },
        { label: 'Riilrs', key: 'riilrs', class: 'text-left' },
        { label: 'Diajukan', key: 'diajukan', class: 'text-left' },
        { label: 'Disetujui', key: 'disetujui', class: 'text-left' },
        { label: 'Status', key: 'status', class: 'text-left' }
        // { label: 'Action', key: 'action', class: 'text-center' }
      ],
      items: [],
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      filterOn: [],
      filter: null,
      sheets: [
        {
          name: 'SheetOne',
          data: []
        }
      ],
      defaultOptions: { animationData: animationLogo, loop: true },
      defaultOptions2: { animationData: animationLogo2, loop: true },
      okRespon: '',
      errRespon: '',
      resetProses: 'd-none',
      tglProses: 'd-block'
    }
  },
  methods: {
    default () {
      return {
        errors: [],
        id: this.items.length
      }
    },
    async listImport (ctx) {
      let data = ''
      if (ctx.filter === '') {
        ctx.filter = null
      }
      data = this.tgl === '' ? '' : `&periode=${this.tgl}&status=${this.status}`
      this.isBusy = true
      try {
        const response = await Eklaim.allImport(ctx.currentPage, data, ctx.filter)
        this.totalRows = response.data.data.total
        this.isBusy = false
        return response.data.data.data
      } catch (err) {
        this.isBusy = false
        return []
      }
    },
    onFiltered (filteredItems) {
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
    filterup () {
      this.listImport()
      this.$refs.table.refresh()
    },
    valRuang (value) {
      this.dataProses.ruang = value.id
    },
    valStatus (value) {
      this.status = value.id
    },
    async prosesData () {
      this.isLoading = true
      const tgl = this.dataProses.tgl
      const ruang = this.dataProses.ruang
      if (tgl === '' && ruang === '') {
        this.errRespon = 'Jenis Klaim Dan Periode Tidak Boleh Kosong'
        this.$refs['modal-import-gagal'].show()
      } else {
        try {
          const response = await Eklaim.getRekon(tgl, ruang)
          if (response.status === 200) {
            this.sheets[0].data = response.data.data
            this.isLoading = false
            this.okRespon = 'Data Berhasil Di Dapatkan'
            this.tglProses = 'd-none'
            this.resetProses = 'd-block'
            this.$refs['modal-import-ok'].show()
          }
        } catch (err) {
          if (err.response.status === 400 || err.response.status === 500) {
            this.isLoading = false
            this.errRespon = 'Data Tidak Ditemukan'
            this.$refs['modal-import-gagal'].show()
          }
        }
      }
      this.isLoading = false
    },
    async importUpload1 () {
      this.isLoading = true
      let formData = new FormData()
      formData.append('file', this.file1)
      if (this.file1 === null) {
        this.isLoading = false
        this.errRespon = 'File Tidak Boleh Kosong'
        this.$refs['modal-import-gagal'].show()
      } else {
        const response = await Eklaim.storeImport1(formData)
        if (response.data.status === 200) {
          this.okRespon = response.data.message
          this.$refs['modal-import-ok'].show()
        } else {
          this.errRespon = response.data.message
          this.$refs['modal-import-gagal'].show()
        }
      }
      this.isLoading = false
      this.$refs.table.refresh()
    },
    filterclear () {
      this.tgl = ''
      this.status = ''
      this.selectedStatus = []
    },
    prosesClear () {
      this.sheets[0].data = []
      this.dataProses.tgl = ''
      this.dataProses.ruang = ''
      this.selectedRuang = []
      this.tglProses = 'd-block'
      this.resetProses = 'd-none'
    }
    // async importUpload2 () {
    //   this.isLoading = true
    //   let formData = new FormData()
    //   formData.append('file', this.file2)
    //   if (this.file2 === null) {
    //     this.isLoading = false
    //     this.errRespon = 'File Tidak Boleh Kosong'
    //     this.$refs['modal-import-gagal'].show()
    //   }
    //   const response = await Eklaim.storeImport2(formData)
    //   if (response.data.status === 200) {
    //     this.okRespon = response.data.message
    //     this.$refs['modal-import-ok'].show()
    //   } else {
    //     this.errRespon = response.data.message
    //     this.$refs['modal-import-gagal'].show()
    //   }
    //   this.isLoading = false
    //   this.$refs.table.refresh()
    // },
    // async importUpload3 () {
    //   this.isLoading = true
    //   let formData = new FormData()
    //   formData.append('file', this.file3)
    //   if (this.file3 === null) {
    //     this.isLoading = false
    //     this.errRespon = 'File Tidak Boleh Kosong'
    //     this.$refs['modal-import-gagal'].show()
    //   }
    //   const response = await Eklaim.storeImport3(formData)
    //   if (response.data.status === 200) {
    //     this.okRespon = response.data.message
    //     this.$refs['modal-import-ok'].show()
    //   } else {
    //     this.errRespon = response.data.message
    //     this.$refs['modal-import-gagal'].show()
    //   }
    //   this.isLoading = false
    //   this.$refs.table.refresh()
    // },
  }
}
</script>
<style>
.eklaim-monitoring .multiselect__tags {
  border: 1px solid #d7dbda!important;
  border-radius: 10px!important;
  background: #ceebee;
  height: 45px;
}
.eklaim-monitoring .custom-flatpicker {
  min-height: 45px;
  height: 45px;
  border: 1px solid #d7dbda;
  padding: 10px;
  border-radius: 10px;
  cursor: pointer;
  background: #ceebee;
  font-weight: bold;
}
.eklaim-monitoring .custom-flatpicke-2 {
  min-height: 45px;
  height: 45px;
  border: 1px solid #d7dbda;
  padding: 10px;
  border-radius: 10px;
  cursor: pointer;
  background: #ceebee;
  font-weight: bold;
}
.eklaim-monitoring .multiselect__single {
  background: #ceebee;
}
.flatpickr-day.selected {
  background: #089bab!important;
}
.flatpickr-day.today {
    background: #089bab!important;
    color: white;
}
.eklaim-monitoring .custom-file-input.is-invalid ~ .custom-file-label, .eklaim-monitoring .was-validated .custom-file-input:invalid ~ .custom-file-label {
    background-color: #ceebee;
}
.eklaim-monitoring .select-status .multiselect__tags {
    background: #ceebee!important;
    border-radius: 10px!important;
    background: #ffffff;
    height: 45px;
}
.eklaim-monitoring .searchbox .form-control {
    background: #ceebee!important;
    height: 35px!important;
    line-height: 45px!important;
}
</style>
