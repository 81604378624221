<template>
  <div>
    <b-row>
      <b-col cols="12" lg="5">
        <div class="bg-primary radius-top px-3 py-2">
          <b-row class="justify-content-between align-items-center">
            <b-col cols="12" lg="6">
              <div>
                <p class="text-white mb-0">List Permintaan Yang Sudah Dikirim</p>
              </div>
            </b-col>
            <b-col cols="12" lg="6">
              <div>
                <b-form-group class="mb-0">
                  <multiselect class="shadow-sm border-0 rounded" v-model="vselectJenisPendonor" :show-labels="false" :limit="3"
                    @input="valJenisPendonor" label="name" placeholder="Ruang Akses" :options="comboJenisPendonor" :searchable="true">
                  </multiselect>
                </b-form-group>
              </div>
            </b-col>
          </b-row>
        </div>
        <div>
          <b-table class="bg-white shadow-sm" ref="table" stacked="md" striped hover show-empty :items="items"></b-table>
        </div>
      </b-col>
      <b-col cols="12" lg="7">
        <div class="bg-white shadow-sm">
          <div class="bg-primary radius-top px-3 py-3">
            <div>
              <p class="text-white mb-1"><i class="fas fa-list"></i> Detail Item</p>
            </div>
          </div>
          <div class="shadow-sm">
            <b-table class="bg-white shadow-sm" ref="table" stacked="md" striped hover show-empty :items="items2"></b-table>
          </div>
          <div class="pb-3 px-3 text-right">
              <b-button variant="success" class="shadow-sm border px-4">Terima</b-button>
          </div>
        </div>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import Multiselect from 'vue-multiselect'
export default {
  name: 'PenerimaanBarangBaru',
  components: {
    Multiselect
  },
  data () {
    return {
      items: [
        { unit_asal: 1, tanggal_permintaan: 'Kategori 1', tanggal_pengiriman: 'Merk 1', status: '1' }
      ],
      items2: [
        { barang_diminta: 1, barang_dikirm: 'Kategori 1', jumlah_diminta: 'Merk 1', jumlah_dikirm: 'Merk 1' }
      ],
      comboJenisPendonor: [
        { name: 'Langsung', id: 1 },
        { name: 'Sukarela', id: 2 },
        { name: 'Pengganti', id: 3 },
        { name: 'Aferesis', id: 4 }
      ],
      vselectJenisPendonor: []
    }
  }
}
</script>
