import auth from './auth/index.ts'
import group from './group/index.ts'
import menu from './menu/index.ts'
import module from './module/index.ts'
import log from './log/index.ts'
import pegawai from './pegawai/index.ts'
import pengguna from './pengguna/index.ts'
import users from './users/index.ts'
import pasien from './pasien/index.ts'
import member from './member/index.js'
import sakti from './sakti/index.ts'
import ruangan from './ruangan/index.ts'
import bios from './bios'
import master from './master'
import dashboard from './dashboard'
import katalog from './katalog'
import middleware from './middleware'

export default {
  auth,
  group,
  menu,
  module,
  log,
  bios,
  sakti,
  master,
  ruangan,
  dashboard,
  katalog,
  pegawai,
  users,
  pengguna,
  pasien,
  member,
  middleware
}
