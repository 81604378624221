export default {
  async createCache ({ dispatch }, data) {
    // console.log(data)
    dispatch('attempt', data)
  },
  async attempt ({ commit }, data) {
    // commit('SET_ACTIVE_TAB_EMR', data)
    commit('SET_FHIR_EMR', data.fhir)
    commit('SET_NORM_EMR', data.norm)
  }
}
