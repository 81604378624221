<template>
  <div id="sdm-penugasan" class="mb-4">
    <b-container fluid>
      <div>
        <iq-card>
          <template v-slot:body>
            <div class="filter-pbf border p-2">
              <b-row class="align-items-center">
                <b-col cols="12" lg="3" class="d-flex flex-row w-100 mr-auto">
                  <div>
                    <h5 class="card-title font-weight-bold text-uppercase mb-0">PENUGASAN PEGAWAI</h5>
                  </div>
                </b-col>
                <b-col cols="12" lg="9">
                  <div class="text-right">
                    <b-button class="custom-button-radius" size="sm" variant="primary" @click="tambahModal"><i class="fas fa-plus"></i> Tambah</b-button>
                  </div>
                </b-col>
              </b-row>
            </div>
            <div class="table mb-0">
              <b-table class="mb-0" ref="table" :busy="table.isBusy" :items="table.items" :fields="table.fields"
                :current-page="table.currentPage" :per-page="table.perPage" :filter="table.filter" @filtered="onFiltered" stacked="md"
                bordered hover show-empty small striped>
                <template #cell(index)="data">
                  {{ (table.currentPage*table.perPage)-table.perPage + data.index + 1 }}
                </template>
                <template #cell(sts_aktif)="data">
                  <span v-if="data.item.sts_aktif === 1"> Aktif </span>
                  <span v-else> Tidak Aktif </span>
                </template>
                <template #cell(tanggal)="data">
                  <span v-if="data.item.tanggal"> {{ data.item.tanggal.split('-').reverse().join('-') }} </span>
                </template>
                <template #cell(action)="data">
                  <b-button variant="white" size="md" @click="data.toggleDetails">
                    <i class="ri-eye-fill ri-lg text-warning"></i>
                  </b-button>
                  <b-button
                    @click="editModal(data.item.id)"
                    variant=" mr-1 mb-1" size="lg">
                    <i class="fas fa-edit text-primary"></i>
                  </b-button>
                  <b-button
                    @click="deleteModal()"
                    variant=" mr-1 mb-1" size="lg">
                    <i class="fas fa-trash-alt text-danger"></i>
                  </b-button>
                </template>
                <template #row-details="data">
                  <b-card>
                    <div class="d-flex">
                      <b-col cols="12" md="6" lg="4">
                        <div>
                          <b-row class="mb-1">
                            <b-col cols="4">
                              <p class="mb-0">NIK</p>
                            </b-col>
                            <b-col cols="1">
                              <p class="mb-0">:</p>
                            </b-col>
                            <b-col>
                              <p class="font-size-responsive text-dark mb-0">{{ data.item.nik }}</p>
                            </b-col>
                          </b-row>
                          <b-row class="mb-1">
                            <b-col cols="4">
                              <p class="mb-0">Tanggal Lahir</p>
                            </b-col>
                            <b-col cols="1">
                              <p class="mb-0">:</p>
                            </b-col>
                            <b-col>
                              <p class="font-size-responsive text-dark mb-0" v-if="data.item.tgl_lahir">{{ data.item.tgl_lahir.split('-').reverse().join('-') }}</p>
                            </b-col>
                          </b-row>
                          <b-row class="mb-1">
                            <b-col cols="4">
                              <p class="mb-0">Jenis Kelamin</p>
                            </b-col>
                            <b-col cols="1">
                              <p class="mb-0">:</p>
                            </b-col>
                            <b-col>
                              <p class="font-size-responsive text-dark mb-0">{{ data.item.jenis_kelamin }}</p>
                            </b-col>
                          </b-row>
                          <b-row class="mb-1">
                            <b-col cols="4">
                              <p class="mb-0">Nama Faskes</p>
                            </b-col>
                            <b-col cols="1">
                              <p class="mb-0">:</p>
                            </b-col>
                            <b-col>
                              <p class="font-size-responsive text-dark mb-0">{{ data.item.namafaskes }}</p>
                            </b-col>
                          </b-row>
                          <b-row class="mb-0">
                            <b-col cols="4">
                              <p class="mb-0">Jenis Tenaga</p>
                            </b-col>
                            <b-col cols="1">
                              <p class="mb-0">:</p>
                            </b-col>
                            <b-col>
                              <p class="font-size-responsive text-dark mb-0">{{ data.item.jenis_tenaga }}</p>
                            </b-col>
                          </b-row>
                        </div>
                      </b-col>
                      <b-col cols="12" md="6" lg="4">
                        <div>
                          <b-row class="mb-1">
                            <b-col cols="4">
                              <p class="mb-0">Tanggal STR</p>
                            </b-col>
                            <b-col cols="1">
                              <p class="mb-0">:</p>
                            </b-col>
                            <b-col>
                              <p class="font-size-responsive text-dark mb-0" v-if="data.item.str_tanggals">{{ data.item.str_tanggals.split('-').reverse().join('-') }}</p>
                            </b-col>
                          </b-row>
                          <b-row class="mb-1">
                            <b-col cols="4">
                              <p class="mb-0">Tanggal STR Awal</p>
                            </b-col>
                            <b-col cols="1">
                              <p class="mb-0">:</p>
                            </b-col>
                            <b-col>
                              <p class="font-size-responsive text-dark mb-0" v-if="data.item.str_tgl_awal">{{ data.item.str_tgl_awal.split('-').reverse().join('-') }}</p>
                            </b-col>
                          </b-row>
                          <b-row class="mb-1">
                            <b-col cols="4">
                              <p class="mb-0">Tanggal STR Akhir</p>
                            </b-col>
                            <b-col cols="1">
                              <p class="mb-0">:</p>
                            </b-col>
                            <b-col>
                              <p class="font-size-responsive text-dark mb-0" v-if="data.item.str_tgl_akhir">{{ data.item.str_tgl_akhir.split('-').reverse().join('-') }}</p>
                            </b-col>
                          </b-row>
                          <b-row class="mb-1">
                            <b-col cols="4">
                              <p class="mb-0">Tanggals SIP </p>
                            </b-col>
                            <b-col cols="1">
                              <p class="mb-0">:</p>
                            </b-col>
                            <b-col>
                              <p class="font-size-responsive text-dark mb-0" v-if="data.item.sip_tanggals">{{ data.item.sip_tanggals.split('-').reverse().join('-') }}</p>
                            </b-col>
                          </b-row>
                          <b-row class="mb-0">
                            <b-col cols="4">
                              <p class="mb-0">Tanggal SIP</p>
                            </b-col>
                            <b-col cols="1">
                              <p class="mb-0">:</p>
                            </b-col>
                            <b-col>
                              <p class="font-size-responsive text-dark mb-0" v-if="data.item.sip_tanggal">{{ data.item.sip_tanggal.split('-').reverse().join('-') }}</p>
                            </b-col>
                          </b-row>
                        </div>
                      </b-col>
                      <b-col cols="12" md="6" lg="4">
                        <div>
                          <b-row class="mb-1">
                            <b-col cols="4">
                              <p class="mb-0">KSM Template</p>
                            </b-col>
                            <b-col cols="1">
                              <p class="mb-0">:</p>
                            </b-col>
                            <b-col>
                              <p class="font-size-responsive text-dark mb-0">{{ data.item.ksm_template }}</p>
                            </b-col>
                          </b-row>
                          <b-row class="mb-1">
                            <b-col cols="4">
                              <p class="mb-0">KSM Fatmawati</p>
                            </b-col>
                            <b-col cols="1">
                              <p class="mb-0">:</p>
                            </b-col>
                            <b-col>
                              <p class="font-size-responsive text-dark mb-0">{{ data.item.ksm_fatmawati }}</p>
                            </b-col>
                          </b-row>
                        </div>
                      </b-col>
                    </div>
                  </b-card>
                </template>
              </b-table>
            </div>
            <div class="pagination-pbf border shadow-sm p-2 mb-2">
              <b-row class="align-items-center">
                <b-col cols="4" class="d-flex flex-row align-items-center">
                  <div class="w-100">
                    <b-pagination
                      v-model="table.currentPage"
                      :total-rows="table.totalRows"
                      :per-page="table.perPage"
                      size="md"
                      class="my-0"
                    ></b-pagination>
                  </div>
                  <div class="w-100">
                    <div>
                      <span class="border-left border-dark mr-3 py-1"></span>
                      <span @click="tableRefresh" class="tarif-cursor">
                        <i class="fas fa-sync text-primary"></i>
                      </span>
                      <span class="border-right border-dark ml-3 py-1"></span>
                    </div>
                  </div>
                </b-col>
                <b-col cols="5">
                  <div class="d-flex flex-row justify-content-end align-items-center">
                    <div class="mr-1 w-75">
                      <form class="searchbox mb-0">
                        <input v-model="table.filter" type="search" class="form-control cstm-srch-pbf" id="filter-input"
                          placeholder="[Cari Nama Tindakan]">
                      </form>
                    </div>
                    <div>
                      <b-form-select class="cstm-slct-pbf" v-model="selected.filter" :options="options.optionsFilter" @change="filterchange"></b-form-select>
                    </div>
                  </div>
                </b-col>
                <b-col cols="3">
                  <div class="d-flex flex-row align-items-center justify-content-end">
                    <div class="mr-3">
                      <b-form-select
                        class="cstm-select-pbf"
                        id="per-page-select"
                        v-model="table.perPage"
                        :options="table.pageOptions"
                      ></b-form-select>
                    </div>
                    <div>
                      <p class="text-dark mb-0">Menampilkan {{ table.perPage }} dari {{ table.totalRows }} baris</p>
                    </div>
                  </div>
                </b-col>
              </b-row>
            </div>
          </template>
        </iq-card>
      </div>
    </b-container>
    <div id="modal-component">
      <b-modal id="modal-tambah" ref="modal-tambah" size="lg" centered title="Tambah Penugasan" ok-title="Simpan Data" cancel-title="Tutup" @ok="tambahSave" :no-close-on-backdrop="true" :static="true" @close="clear" @cancel="clear">
        <b-form>
          <b-row class="mb-custom align-items-center">
            <b-col cols="1">
              <div>
                <p class="font-custom text-dark mb-0">Pegawai</p>
              </div>
            </b-col>
            <b-col>
              <div>
                <multiselect :allow-empty="false" :loading="preview.isLoading" v-model="selected.pegawai" label="nama" :show-labels="false" placeholder="[ Pegawai ]" @input="valuePegawai($event, 'a')" :options="options.pegawai" :searchable="true">
                </multiselect>
              </div>
            </b-col>
            <b-col cols="1">
              <div>
                <p class="font-custom text-dark mb-0">Tanggal</p>
              </div>
            </b-col>
            <b-col>
              <div>
                <flat-pickr :config="config" @on-change="tglEvt($event, 'a')" v-model="preview.tanggal" value="" placeholder="Pilih Tanggal " class="w-100 custom-flatpicker calender-small">
                </flat-pickr>
              </div>
            </b-col>
          </b-row>
        </b-form>
        <div class="bg-danger">
          <p class="text-right px-2 mt-2 mb-0" v-if="preview.messageValue === '2'">{{ preview.message }}</p>
        </div>
      </b-modal>
      <b-modal id="modal-edit" ref="modal-edit" size="lg" centered title="Edit Penugasan" ok-title="Perbarui Data" cancel-title="Tutup" :no-close-on-backdrop="true" :static="true" @ok="editSave"  @close="clear" @cancel="clear">
        <b-form>
          <b-row class="mb-custom align-items-center">
            <b-col cols="1">
              <div>
                <p class="font-custom text-dark mb-0">Pegawai</p>
              </div>
            </b-col>
            <b-col>
              <div>
                <multiselect :allow-empty="false" disabled :loading="preview.isLoading" v-model="selected.pegawai" label="nama" :show-labels="false" placeholder="[ Pegawai ]" @input="valuePegawai($event, 'b')" :options="options.pegawai" :searchable="true">
                </multiselect>
              </div>
            </b-col>
            <b-col cols="1">
              <div>
                <p class="font-custom text-dark mb-0">Tanggal</p>
              </div>
            </b-col>
            <b-col>
              <div>
                <flat-pickr :config="config" @on-change="tglEvt($event, 'b')" v-model="preview.tanggal" value="" placeholder="Pilih Tanggal " class="w-100 custom-flatpicker calender-small">
                </flat-pickr>
              </div>
            </b-col>
          </b-row>
        </b-form>
        <div class="bg-danger">
          <p class="text-right px-2 mt-2 mb-0" v-if="preview.messageValue === '2'">{{ preview.message }}</p>
        </div>
      </b-modal>
      <b-modal ref="modal-delete-pbf" size="md" centered title="Konfirmasi" ok-title="Hapus Data" ok-variant="danger" cancel-title="Tutup" @ok="deleteSave">
        <div class="py-3">
          <p class="h6 text-center text-dark mb-0">Yakin Ingin Menghapus Data ?</p>
        </div>
      </b-modal>
      <b-modal ref="modal-notif" size="md" centered title="Notifikasi" ok-title="Tutup" :hide-footer="true" :hide-header="true">
        <div class="pb-3 px-4">
          <div v-if="preview.messageValue === '1'">
            <lottie :options="preview.lottie.defaultOptions" :autoPlay="true" :width="150" :height="150" />
          </div>
          <div v-else>
            <lottie :options="preview.lottie.defaultOptions2" :autoPlay="true" :width="150" :height="150" />
          </div>
          <p class="h6 text-dark text-center mb-0 mt-2">{{ preview.message }}</p>
        </div>
      </b-modal>
    </div>
  </div>
</template>
<script>
import { xray } from '../../../config/pluginInit'
import jwtdecode from 'jwt-decode'
import newServices from '../../../services/fatmahost/pegawai/index.ts'
import Lottie from 'vue-lottie'
import animationLogo from '../../../assets/lottie/checked.json'
import animationLogo2 from '../../../assets/lottie/failed.json'
import Multiselect from 'vue-multiselect'
import flatPickr from 'vue-flatpickr-component'
import 'flatpickr/dist/flatpickr.css'

export default {
  name: 'Pbf',
  components: {
    Multiselect,
    Lottie,
    flatPickr
  },
  data () {
    return {
      table: {
        isBusy: false,
        items: this.list,
        fields: [
          { label: 'NO.', key: 'index', class: 'text-center' },
          { label: 'NOMOR', key: 'nomor' },
          { label: 'NAMA', key: 'nama' },
          { label: 'KSM', key: 'ksm_fatmawati' },
          { label: 'Nomor Identitas Pegawai', key: 'nip' },
          { label: 'PENDIDIKAN', key: 'pendidikan' },
          { label: 'STATUS', key: 'status' },
          { label: 'JABATAN', key: 'jabatan' },
          { label: 'NOMOR STR', key: 'str_nomor' },
          { label: 'NOMOR SIP', key: 'sip_nomor' },
          { label: 'Tanggal', key: 'tanggal' },
          { label: 'STATUS AKTIF', key: 'sts_aktif', class: 'text-center' },
          { label: 'AKSI', key: 'action', class: 'text-center' }
        ],
        filter: null,
        currentPage: 1,
        perPage: 10,
        pageOptions: [10, 25, 50, 100],
        totalRows: null
      },
      tambahPenugasan: {
        object: 'pegawai-dokter',
        tanggal: '',
        id_pegawai: '',
        created_by: jwtdecode(localStorage.getItem('token')).user.id.toString()
      },
      editPenugasan: {
        object: 'pegawai-dokter',
        tanggal: '',
        id_pegawai: '',
        updated_by: jwtdecode(localStorage.getItem('token')).user.id.toString()
      },
      options: {
        optionsFilter: [
          { text: 'Nama', value: '1' },
          { text: 'NIP', value: '2' },
          { text: 'KSM', value: '3' }
        ],
        pegawai: []
      },
      selected: {
        filter: '1',
        pegawai: []
      },
      config: {
        dateFormat: 'd-m-Y',
        disableMobile: true
      },
      preview: {
        isLoading: false,
        tanggal: '',
        lottie: {
          defaultOptions: { animationData: animationLogo, loop: true },
          defaultOptions2: { animationData: animationLogo2, loop: true }
        },
        user_name: jwtdecode(localStorage.getItem('token')).user.name,
        edit_id: '',
        delete_id: '',
        message: '',
        messageValue: '',
        modal_title: ''
      }
    }
  },
  mounted () {
    xray.index()
    this.getPegawai()
  },
  methods: {
    tableRefresh () {
      this.$refs.table.refresh()
    },
    async list (ctx) {
      let filterType = this.selected.filter
      let filter = ''
      if (ctx.filter === '') {
        ctx.filter = null
      }
      if (ctx.filter === null) {
        filter = `{"xparam0":"","xparam1":"","xparam2":""}`
      } else {
        if (filterType === '1') {
          filter = `{"xparam0":"${ctx.filter}","xparam1":"","xparam2":""}`
        } else if (filterType === '2') {
          filter = `{"xparam0":"","xparam1":"${ctx.filter}","xparam2":""}`
        } else if (filterType === '3') {
          filter = `{"xparam0":"","xparam1":"","xparam2":"${ctx.filter}"}`
        }
      }
      this.isBusy = true
      try {
        const response = await newServices.findPegawaiDokter(ctx.currentPage - 1, ctx.perPage, filter)
        this.isBusy = false
        this.table.totalRows = response.data.pagination.total
        this.table.perPage = response.data.pagination.perPage
        this.table.currentPage = response.data.pagination.current
        return response.data.data
      } catch (error) {
        this.isBusy = false
        return []
      }
    },
    onFiltered (filteredItems) {
      this.table.totalRows = filteredItems.length
      // this.table.currentPage = 1
    },
    filterchange () {
      this.table.filter = ''
    },
    tambahModal () {
      this.preview.message = ''
      this.$refs['modal-tambah'].show()
    },
    async tambahSave (bvModalEvent) {
      bvModalEvent.preventDefault()
      try {
        let data = this.tambahPenugasan
        const response = await newServices.createPegawaiDokter(data)
        if (response.data.rowcount === 1 && response.data.statcode === 0) {
          this.preview.message = response.data.message
          this.preview.messageValue = '1'
          this.$refs['modal-tambah'].hide()
          this.$refs['modal-notif'].show()
          this.$refs.table.refresh()
        } else if (response.data.rowcount === 0 && response.data.statcode === 20001) {
          this.preview.message = response.data.message
          this.preview.messageValue = '2'
        }
      } catch (error) {
        this.preview.message = error.message
        this.preview.messageValue = '2'
      }
    },
    async editModal (id) {
      let ids = id
      this.preview.edit_id = ids
      this.preview.message = ''
      try {
        const response = await newServices.showPegawaiDokter(ids)
        this.preview.tanggal = response.data.data[0].tanggal.split('-').reverse().join('-')
        this.editPenugasan.id_pegawai = response.data.data[0].id_penugasan.toString()
        this.selected.pegawai = response.data.data[0].id ? { id: response.data.data[0].id, nama: response.data.data[0].nama } : []
      } catch (error) {
        this.preview.message = error.message
        this.preview.messageValue = '2'
        this.$refs['modal-notif'].show()
      }
      this.$refs['modal-edit'].show()
    },
    async editSave (bvModalEvent) {
      bvModalEvent.preventDefault()
      let id = this.preview.edit_id
      let data = this.editPenugasan
      try {
        const response = await newServices.updatePegawaiDokter(id, data)
        if (response.data.statcode === 0) {
          this.preview.message = response.data.message
          this.preview.messageValue = '1'
          this.$refs['modal-edit'].hide()
          this.$refs['modal-notif'].show()
          this.$refs.table.refresh()
        } else {
          this.preview.message = response.data.message
          this.preview.messageValue = '2'
        }
      } catch (error) {
        this.preview.message = error.message
        this.preview.messageValue = '2'
      }
    },
    deleteModal (id) {
      this.preview.delete_id = id
      this.$refs['modal-delete-pbf'].show()
    },
    async deleteSave () {
      let id = this.preview.delete_id
      try {
        const response = await newServices.deletePegawaiDokter(id)
        if (response.data.statcode === 0) {
          this.preview.message = response.data.message
          this.preview.messageValue = '1'
        } else {
          this.preview.message = response.data.message
          this.preview.messageValue = '2'
        }
        this.$refs['modal-notif'].show()
        this.$refs.table.refresh()
      } catch (error) {
        this.preview.message = error.message
        this.preview.messageValue = '2'
        this.$refs['modal-notif'].show()
      }
    },
    async getPegawai () {
      try {
        const response = await newServices.selectizePegawai()
        this.options.pegawai = response.data.data
      } catch (error) {
        this.preview.message = error.message
        this.preview.messageValue = '2'
      }
    },
    tglEvt (value, param) {
      let date = new Date(value)
      let year = date.getFullYear()
      let month = date.getMonth() + 1
      let day = date.getDate()
      if (month < 10) {
        month = '0' + month
      }
      if (day < 10) {
        day = '0' + day
      }
      let joinDate = year + '-' + month + '-' + day
      if (param === 'a') {
        this.tambahPenugasan.tanggal = joinDate.toString()
      } else {
        this.editPenugasan.tanggal = joinDate.toString()
      }
    },
    valuePegawai (value, param) {
      if (param === 'a') {
        this.tambahPenugasan.id_pegawai = value.id.toString()
      } else {
        this.editPenugasan.id_pegawai = value.id.toString()
      }
    },
    defaultTgl () {
      let date = new Date()
      let year = date.getFullYear()
      let month = date.getMonth() + 1
      let day = date.getDate()
      if (month < 10) {
        month = '0' + month
      }
      if (day < 10) {
        day = '0' + day
      }
      let joinDate = day + '-' + month + '-' + year
      return joinDate.toString()
    },
    clear () {
      this.selected.pegawai = []
      this.preview.tanggal = ''
      this.tambahPenugasan.tanggal = ''
      this.tambahPenugasan.id_pegawai = ''
    }
  }
}
</script>
<style>
#modal-tambah .custom-control-label, #modal-edit .custom-control-label {
    font-size: 12px;
    margin-top: 0;
}
#modal-tambah .multiselect__single, #modal-edit .multiselect__single {
  font-size: 12px!important;
}
#modal-tambah .multiselect__placeholder, #modal-edit .multiselect__placeholder {
  font-size: 12px!important;
}
#modal-tambah .multiselect__option, #modal-edit .multiselect__option {
    font-size: 12px;
}
#modal-tambah .multiselect__tags, #modal-edit .multiselect__tags {
  padding: 5px 40px 0 8px!important;
  height: 38px!important;
  min-height: 38px!important;
}
#modal-tambah  .modal-body, #modal-edit  .modal-body {
  background: #3399331b
}

#modal-tambah  .modal-header, #modal-edit  .modal-header {
  background: #3399333a;
}

#modal-tambah  .modal-footer, #modal-edit  .modal-footer {
  background: #3399333a;
}
#sdm-penugasan .page-link {
  font-size: 14px;
  padding-top: 5px;
  padding-bottom: 5px;
  margin-right: 10px!important;
}
#sdm-penugasan .custom-button-radius {
  border: 1px solid rgba(0, 0, 0, 0.112);
  border-radius: 5px!important;
}
#sdm-penugasan .table td {
    font-size: 12px;
    font-weight: 500;
    padding-top: 0px;
    padding-bottom: 0px;
}
#sdm-penugasan .table th {
    font-size: 13px;
    font-weight: 400;
}
#sdm-penugasan .cstm-select-pbf {
  font-size: 13px;
  height: 38px!important;
  border-radius: 5px;
}
#sdm-penugasan .cstm-srch-pbf {
  font-size: 13px;
  height: 38px!important;
  border-radius: 5px;
}
#sdm-penugasan .cstm-slct-pbf {
  font-size: 13px;
  height: 38px!important;
  border-radius: 5px;
}
.cstm-cntrl-tarif {
  background: white!important;
  color: black!important;
  border-radius: 5px!important;
  font-size: 12px!important;
  height: 38px!important;
}
.tarif-form-area {
  color: black!important;
  font-weight: bold!important;
  border-radius: 5px!important;
  font-size: 12px!important;
}
#sdm-penugasan .pagination-sm .page-link {
  color: gray!important;
}
#sdm-penugasan .page-item.active .page-link {
  color: white!important;
}
#sdm-penugasan .page-item.active .page-link {
  color: white!important;
}
.custom-radius-add-tarif {
  border-radius: 5px;
  cursor: pointer;
}
.tarif-select-flex-left {
  width: 90%;
}
.tarif-select-flex-right {
  width: 10%;
}
.tarif-form-satuan-pbf-left-right {
  width: 40%;
}
.tarif-form-satuan-pbf-center {
  width: 15%;
}
.hr-tarif-custom {
  border-top: 1px solid rgba(0, 0, 0, 0.199)!important;
  margin: 0!important;
}
#sdm-penugasan .pagination-pbf .page-link {
    border: none;
    color: gray!important;
    margin-right: 15px;
}
#sdm-penugasan .pagination-pbf .page-item.active .page-link {
    background-color: transparent;
    border-color: transparent;
    color: #089bab!important;
    font-weight: bold;
}
#sdm-penugasan .mb-custom {
  margin-bottom: 6px;
}
#sdm-penugasan .font-custom {
  font-size: 12px;
}
#sdm-penugasan .multiselect__input, .multiselect__single {
    font-weight: normal!important;
}
#sdm-penugasan .multiselect__input, .multiselect__single {
    line-height: 25px;
}
#sdm-penugasan .tarif-cursor {
  cursor: pointer;
}
#sdm-penugasan  .multiselect__input, .multiselect {
  font-weight: normal!important;
}
#sdm-penugasan .flatpickr-input[readonly], #modal-tambah .flatpickr-input[readonly], #modal-edit .flatpickr-input[readonly] {
    border: 1px solid rgb(233, 233, 233);
    padding-left: 10px;
    padding-top: 5px;
    padding-bottom: 5px;
    border-radius: 5px;
}
</style>
