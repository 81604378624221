<template>
  <div id="dashboard-antrian">
    <b-container fluid>
      <div class="mt-2">
        <div class="bg-primary radius-cstm p-3 mb-4">
          <div class="bg-white radius-cstm p-3">
            <p class="h3 text-dark text-center text-uppercase font-weight-bold mb-0">ANTRIAN POLIKLINIK, {{ tanggal }}</p>
          </div>
        </div>
        <b-row>
          <b-col class="mb-4" cols="12" md="3" v-for="p in poli" :key="p.id">
            <div class="radius-cstm poli bg-primary shadow-sm border px-2 py-2">
              <p class="h4 font-weight-bold text-center mb-2 mt-1">NOMOR ANTRIAN</p>
              <div class="bg-white radius-cstm py-4">
                <p class="display-4 font-weight-bold text-center text-dark mb-0">{{ p.nomor }}</p>
              </div>
              <p class="h4 text-center text-warning font-weight-bold mb-0 mt-2">POLI {{ p.ruangan }}</p>
            </div>
          </b-col>
        </b-row>
      </div>
    </b-container>
  </div>
</template>
<script>

export default {
  name: 'Dashboard-Antrian',
  data () {
    return {
      tanggal: '',
      poli: [
        {
          id: 1,
          nomor: '001',
          ruangan: 1
        },
        {
          id: 2,
          nomor: '002',
          ruangan: 2
        },
        {
          id: 3,
          nomor: '003',
          ruangan: 3
        },
        {
          id: 4,
          nomor: '004',
          ruangan: 4
        },
        {
          id: 5,
          nomor: '005',
          ruangan: 5
        },
        {
          id: 6,
          nomor: '006',
          ruangan: 6
        },
        {
          id: 7,
          nomor: '007',
          ruangan: 7
        },
        {
          id: 8,
          nomor: '008',
          ruangan: 8
        }
      ]
    }
  },
  mounted () {
    this.getTgl()
  },
  methods: {
    getTgl () {
      var months = new Array(12)
      months[0] = 'Januari'
      months[1] = 'Februari'
      months[2] = 'Maret'
      months[3] = 'April'
      months[4] = 'Mei'
      months[5] = 'Juni'
      months[6] = 'Juli'
      months[7] = 'Agustus'
      months[8] = 'September'
      months[9] = 'Oktober'
      months[10] = 'November'
      months[11] = 'Desember'
      var date = new Date()
      var year = date.getFullYear()
      var month = date.getMonth()
      var day = date.getDate()
      var tglFinal = day + ' ' + months[month] + ' ' + year
      this.tanggal = tglFinal
    }
  }
}
</script>
<style>
#dashboard-antrian .radius-cstm {
  border-radius: 5px;
}
</style>
