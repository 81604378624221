<template>
  <div id="bios-view" class="mb-4">
    <b-container fluid>
      <b-row class="mr-2 ml-2">
        <b-col cols="12" lg="12">
          <div>
            <b-button variant="primary" class="btn-block text-left rounded-custom" size="lg" v-b-toggle="'collapse-a'">
              <p class="mb-0 text-uppercase font-weight-bold h5"><i class="fas fa-braille"></i> KEUANGAN</p>
            </b-button>
            <b-collapse id="collapse-a" visible>
              <b-card class="border shadow-sm">
                <b-row>
                  <b-col cols="6" lg="2" class="mb-2 mt-2">
                    <div
                      class="bios-menu text-center border shadow-sm px-4 py-4 rounded crsr"
                      @click="toMasterAkun"
                    >
                      <i class="fas fa-gear fa-3x"></i>
                      <p class="small mb-0 font-weight-bold mt-1">Master Akun</p>
                    </div>
                  </b-col>
                  <b-col cols="6" lg="2" class="mb-2 mt-2 ">
                    <div
                      class="bios-menu text-center border shadow-sm px-4 py-4 rounded crsr"
                      @click="toMasterBank"
                    >
                      <i class="fas fa-gear fa-3x"></i>
                      <p class="small mb-0 font-weight-bold mt-1">Master Bank</p>
                    </div>
                  </b-col>
                  <b-col cols="6" lg="2" class="mb-2 mt-2 ">
                    <div
                      class="bios-menu text-center border shadow-sm px-4 py-4 rounded crsr"
                      @click="toMasterRekening"
                    >
                      <i class="fas fa-gear fa-3x"></i>
                      <p class="small mb-0 font-weight-bold mt-1">Master Rekening</p>
                    </div>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col cols="6" lg="2" class="mb-2 mt-2">
                    <div
                      class="bios-menu text-center border shadow-sm px-4 py-4 rounded crsr h-100"
                      g-variant="primary"
                      @click="toSaldoOperasional"
                    >
                      <i class="fas fa-dollar-sign fa-3x"></i>
                      <p class="small mb-0 font-weight-bold mt-2">Saldo Operasional</p>
                    </div>
                  </b-col>
                  <b-col cols="6" lg="2" class="mb-2 mt-2 ">
                    <div
                      class="bios-menu text-center border shadow-sm px-4 py-4 rounded crsr h-100"
                      g-variant="primary"
                      @click="toSaldoPengelolaanKas"
                    >
                      <i class="fas fa-dollar-sign fa-3x"></i>
                      <p class="small mb-0 font-weight-bold mt-2">Saldo Pengelolaan Kas</p>
                    </div>
                  </b-col>
                  <b-col cols="6" lg="2" class="mb-2 mt-2 ">
                    <div
                      class="bios-menu text-center border shadow-sm px-4 py-4 rounded crsr h-100"
                      g-variant="primary"
                      @click="toSaldoDanaKelolaan"
                    >
                      <i class="fas fa-dollar-sign fa-3x"></i>
                      <p class="small mb-0 font-weight-bold mt-2">Saldo Dana Kelolaan</p>
                    </div>
                  </b-col>
                  <b-col cols="6" lg="2" class="mb-2 mt-2 ">
                    <div
                      class="bios-menu text-center border shadow-sm px-4 py-4 rounded crsr h-100"
                      @click="toPenerimaan"
                    >
                    <i class="fas fa-dollar-sign fa-3x"></i>
                      <p class="small mb-0 font-weight-bold mt-2">Penerimaan</p>
                    </div>
                  </b-col>
                  <b-col cols="6" lg="2" class="mb-2 mt-2 ">
                    <div
                      class="bios-menu text-center border shadow-sm px-4 py-4 rounded crsr h-100"
                      @click="toPengeluaran"
                    >
                      <i class="fas fa-dollar-sign fa-3x"></i>
                      <p class="small mb-0 font-weight-bold mt-2">Pengeluaran</p>
                    </div>
                  </b-col>
                </b-row>
              </b-card>
            </b-collapse>
          </div>
          <hr>
          <div>
            <b-button variant="primary" class="btn-block text-left rounded-custom" size="lg" v-b-toggle="'collapse-b'">
              <p class="mb-0 text-uppercase font-weight-bold h5"><i class="fas fa-braille"></i> Indikator Kinerja Tambahan</p>
            </b-button>
            <b-collapse id="collapse-b" visible>
              <b-card class="border shadow-sm">
                <b-row>
                  <!-- <b-col cols="6" lg="2" class="mb-2 mt-2">
                    <div
                      class="bios-menu text-center border shadow-sm px-4 py-4 rounded crsr h-100"
                      g-variant="primary"
                      @click="toVisiteDokter"
                    >
                      <i class="fas fa-folder-plus fa-3x"></i>
                      <p class="small mb-0 font-weight-bold mt-2">Kepatuhan Waktu Visite Dokter</p>
                    </div>
                  </b-col> -->
                  <b-col cols="6" lg="2" class="mb-2 mt-2 ">
                    <div
                      class="bios-menu text-center border shadow-sm px-4 py-4 rounded crsr h-100"
                      g-variant="primary"
                      @click="toVisitePasien"
                    >
                      <i class="fas fa-folder-plus fa-3x"></i>
                      <p class="small mb-0 font-weight-bold mt-2">Jumlah Visite Pasien > 10.00 s.d. 12.00</p>
                    </div>
                  </b-col>
                  <b-col cols="6" lg="2" class="mb-2 mt-2 ">
                    <div
                      class="bios-menu text-center border shadow-sm px-4 py-4 rounded crsr h-100"
                      g-variant="primary"
                      @click="toVisitePasien12"
                    >
                      <i class="fas fa-folder-plus fa-3x"></i>
                      <p class="small mb-0 font-weight-bold mt-2">Jumlah Visite Pasien > 12.00</p>
                    </div>
                  </b-col>
                  <b-col cols="6" lg="2" class="mb-2 mt-2 ">
                    <div
                      class="bios-menu text-center border shadow-sm px-4 py-4 rounded crsr h-100"
                      @click="toVisiteDpjp"
                    >
                    <i class="fas fa-folder-plus fa-3x"></i>
                      <p class="small mb-0 font-weight-bold mt-2">Jumlah DPJP Tidak Visite</p>
                    </div>
                  </b-col>
                  <b-col cols="6" lg="2" class="mb-2 mt-2 ">
                    <div
                      class="bios-menu text-center border shadow-sm px-4 py-4 rounded crsr h-100"
                      @click="toRasioPobo"
                    >
                    <i class="fas fa-folder-plus fa-3x"></i>
                      <p class="small mb-0 font-weight-bold mt-2">Rasio Pobo</p>
                    </div>
                  </b-col>
                  <b-col cols="6" lg="2" class="mb-2 mt-2">
                    <div
                      class="bios-menu text-center border shadow-sm px-4 py-4 rounded crsr h-100"
                      g-variant="primary"
                      @click="toVisitePasienPertama"
                    >
                      <i class="fas fa-folder-plus fa-3x"></i>
                      <p class="small mb-0 font-weight-bold mt-2">Jumlah Kegiatan Visite Pasien Pertama</p>
                    </div>
                  </b-col>
                  <b-col cols="6" lg="2" class="mb-2 mt-2 ">
                    <div
                      class="bios-menu text-center border shadow-sm px-4 py-4 rounded crsr h-100"
                      g-variant="primary"
                      @click="toKepuasanPasien"
                    >
                      <i class="fas fa-folder-plus fa-3x"></i>
                      <p class="small mb-0 font-weight-bold mt-2">Kepuasan Pasien</p>
                    </div>
                  </b-col>
                  <b-col cols="6" lg="2" class="mb-2 mt-2 ">
                    <div
                      class="bios-menu text-center border shadow-sm px-4 py-4 rounded crsr h-100"
                      g-variant="primary"
                      @click="toWaktuTungguRJ"
                    >
                      <i class="fas fa-folder-plus fa-3x"></i>
                      <p class="small mb-0 font-weight-bold mt-2">Waktu Tunggu Rawat Jalan</p>
                    </div>
                  </b-col>
                  <b-col cols="6" lg="2" class="mb-2 mt-2 ">
                    <div
                      class="bios-menu text-center border shadow-sm px-4 py-4 rounded crsr h-100"
                      @click="toPenyelenggaraRme"
                    >
                    <i class="fas fa-folder-plus fa-3x"></i>
                      <p class="small mb-0 font-weight-bold mt-2">Penyelenggaraan Rekam Medis Elektronik</p>
                    </div>
                  </b-col>
                  <b-col cols="6" lg="2" class="mb-2 mt-2 ">
                    <div
                      class="bios-menu text-center border shadow-sm px-4 py-4 rounded crsr h-100"
                      @click="toPenggunaanAlat"
                    >
                    <i class="fas fa-folder-plus fa-3x"></i>
                      <p class="small mb-0 font-weight-bold mt-2">Kepatuhan Penggunaan Alat Pelindung Diri</p>
                    </div>
                  </b-col>
                  <b-col cols="6" lg="2" class="mb-2 mt-2">
                    <div
                      class="bios-menu text-center border shadow-sm px-4 py-4 rounded crsr h-100"
                      g-variant="primary"
                      @click="toPenundaanOperasi"
                    >
                      <i class="fas fa-folder-plus fa-3x"></i>
                      <p class="small mb-0 font-weight-bold mt-2">Penundaan Operasi Elektif</p>
                    </div>
                  </b-col>
                  <b-col cols="6" lg="2" class="mb-2 mt-2 ">
                    <div
                      class="bios-menu text-center border shadow-sm px-4 py-4 rounded crsr h-100"
                      g-variant="primary"
                      @click="toKepatuhanTerhadapAlur"
                    >
                      <i class="fas fa-folder-plus fa-3x"></i>
                      <p class="small mb-0 font-weight-bold mt-2">Kepatuhan Terhadap Alur Klinis</p>
                    </div>
                  </b-col>
                  <b-col cols="6" lg="2" class="mb-2 mt-2 ">
                    <div
                      class="bios-menu text-center border shadow-sm px-4 py-4 rounded crsr h-100"
                      g-variant="primary"
                      @click="toKepatuhanPenggunaanFormularium"
                    >
                      <i class="fas fa-folder-plus fa-3x"></i>
                      <p class="small mb-0 font-weight-bold mt-2">Kepatuhan Penggunaan Formularium Nasional </p>
                    </div>
                  </b-col>
                  <b-col cols="6" lg="2" class="mb-2 mt-2 ">
                    <div
                      class="bios-menu text-center border shadow-sm px-4 py-4 rounded crsr h-100"
                      @click="toKepatuhanWaktuVisiteDPJP"
                    >
                    <i class="fas fa-folder-plus fa-3x"></i>
                      <p class="small mb-0 font-weight-bold mt-2">Kepatuhan Waktu Visite DPJP</p>
                    </div>
                  </b-col>
                  <b-col cols="6" lg="2" class="mb-2 mt-2 ">
                    <div
                      class="bios-menu text-center border shadow-sm px-4 py-4 rounded crsr h-100"
                      @click="toKepatuhanPelaksanaanProtokolKesehatan"
                    >
                    <i class="fas fa-folder-plus fa-3x"></i>
                      <p class="small mb-0 font-weight-bold mt-2">kepatuhan pelaksanaan protokol kesehatan</p>
                    </div>
                  </b-col>
                  <b-col cols="6" lg="2" class="mb-2 mt-2">
                    <div
                      class="bios-menu text-center border shadow-sm px-4 py-4 rounded crsr h-100"
                      g-variant="primary"
                      @click="toPembelianAlkes"
                    >
                      <i class="fas fa-folder-plus fa-3x"></i>
                      <p class="small mb-0 font-weight-bold mt-2">Persentase Pembelian Alat Kesehatan Dalam Negeri</p>
                    </div>
                  </b-col>
                  <b-col cols="6" lg="2" class="mb-2 mt-2 ">
                    <div
                      class="bios-menu text-center border shadow-sm px-4 py-4 rounded crsr h-100"
                      g-variant="primary"
                      @click="toKepatuhanIdentifikasiPsn"
                    >
                      <i class="fas fa-folder-plus fa-3x"></i>
                      <p class="small mb-0 font-weight-bold mt-2">Kepatuhan Identifikasi Pasien</p>
                    </div>
                  </b-col>
                  <b-col cols="6" lg="2" class="mb-2 mt-2 ">
                    <div
                      class="bios-menu text-center border shadow-sm px-4 py-4 rounded crsr h-100"
                      g-variant="primary"
                      @click="toKecepatanWktTgpKomplain"
                    >
                      <i class="fas fa-folder-plus fa-3x"></i>
                      <p class="small mb-0 font-weight-bold mt-2">Kecepatan Waktu Tanggap Komplain</p>
                    </div>
                  </b-col>
                  <b-col cols="6" lg="2" class="mb-2 mt-2 ">
                    <div
                      class="bios-menu text-center border shadow-sm px-4 py-4 rounded crsr h-100"
                      @click="toLaporanHasilKritis"
                    >
                    <i class="fas fa-folder-plus fa-3x"></i>
                      <p class="small mb-0 font-weight-bold mt-2">Pelaporan Hasil Kritis Laboratorium</p>
                    </div>
                  </b-col>
                  <b-col cols="6" lg="2" class="mb-2 mt-2 ">
                    <div
                      class="bios-menu text-center border shadow-sm px-4 py-4 rounded crsr h-100"
                      @click="toOperasiSeksio"
                    >
                    <i class="fas fa-folder-plus fa-3x"></i>
                      <p class="small mb-0 font-weight-bold mt-2">Waktu Tanggap Operasi Seksio Sasarea Emergensi</p>
                    </div>
                  </b-col>
                  <b-col cols="6" lg="2" class="mb-2 mt-2">
                    <div
                      class="bios-menu text-center border shadow-sm px-4 py-4 rounded crsr h-100"
                      g-variant="primary"
                      @click="toResikoJatuh"
                    >
                      <i class="fas fa-folder-plus fa-3x"></i>
                      <p class="small mb-0 font-weight-bold mt-2">Kepatuhan Upaya Pencegahan Resiko Pasien Jatuh</p>
                    </div>
                  </b-col>
                  <b-col cols="6" lg="2" class="mb-2 mt-2 ">
                    <div
                      class="bios-menu text-center border shadow-sm px-4 py-4 rounded crsr h-100"
                      g-variant="primary"
                      @click="toPendapatanBlu"
                    >
                      <i class="fas fa-folder-plus fa-3x"></i>
                      <p class="small mb-0 font-weight-bold mt-2">Pertumbuhan Realisasi Pendapatan Dari Pengelolaan Aset BLU</p>
                    </div>
                  </b-col>
                  <b-col cols="6" lg="2" class="mb-2 mt-2 ">
                    <div
                      class="bios-menu text-center border shadow-sm px-4 py-4 rounded crsr h-100"
                      g-variant="primary"
                      @click="toJumlahVisite10"
                    >
                      <i class="fas fa-folder-plus fa-3x"></i>
                      <p class="small mb-0 font-weight-bold mt-2">Jumlah Visite Pasien &lt;= 10</p>
                    </div>
                  </b-col>
                  <b-col cols="6" lg="2" class="mb-2 mt-2 ">
                    <div
                      class="bios-menu text-center border shadow-sm px-4 py-4 rounded crsr h-100"
                      g-variant="primary"
                      @click="toKepatuhanKebersihanTangan"
                    >
                      <i class="fas fa-folder-plus fa-3x"></i>
                      <p class="small mb-0 font-weight-bold mt-2">Kepatuhan Kebersihan Tangan</p>
                    </div>
                  </b-col>
                </b-row>
              </b-card>
            </b-collapse>
          </div>
          <hr>
          <div>
            <b-button variant="primary" class="btn-block text-left rounded-custom" size="lg" v-b-toggle="'collapse-c'">
              <p class="mb-0 text-uppercase font-weight-bold h5"><i class="fas fa-braille"></i> Layanan</p>
            </b-button>
            <b-collapse id="collapse-c" visible>
              <b-card class="border shadow-sm">
                <b-row>
                  <b-col cols="6" lg="2" class="mb-2 mt-2">
                    <div
                      class="bios-menu text-center border shadow-sm px-4 py-4 rounded crsr h-100"
                      g-variant="primary"
                      @click="toBor"
                    >
                      <i class="fas fa-window-restore fa-3x"></i>
                      <p class="small mb-0 font-weight-bold mt-2">BOR</p>
                      <p class="small mb-0 font-weight-bold">(Bed Occupancy Ratio)</p>
                    </div>
                  </b-col>
                  <b-col cols="6" lg="2" class="mb-2 mt-2 ">
                    <div
                      class="bios-menu text-center border shadow-sm px-4 py-4 rounded crsr h-100"
                      g-variant="primary"
                      @click="toToi"
                    >
                      <i class="fas fa-window-restore fa-3x"></i>
                      <p class="small mb-0 font-weight-bold mt-2">TOI</p>
                      <p class="small mb-0 font-weight-bold">(Turn Over Interval)</p>
                    </div>
                  </b-col>
                  <b-col cols="6" lg="2" class="mb-2 mt-2 ">
                    <div
                      class="bios-menu text-center border shadow-sm px-4 py-4 rounded crsr h-100"
                      g-variant="primary"
                      @click="toAlos"
                    >
                      <i class="fas fa-window-restore fa-3x"></i>
                      <p class="small mb-0 font-weight-bold mt-2">ALOS</p>
                      <p class="small mb-0 font-weight-bold">(Average Length of Stay)</p>
                    </div>
                  </b-col>
                  <b-col cols="6" lg="2" class="mb-2 mt-2 ">
                    <div
                      class="bios-menu text-center border shadow-sm px-4 py-4 rounded crsr h-100"
                      @click="toBto"
                    >
                      <i class="fas fa-window-restore fa-3x"></i>
                      <p class="small mb-0 font-weight-bold mt-2">BTO</p>
                      <p class="small mb-0 font-weight-bold">(Bed Turn Over)</p>
                    </div>
                  </b-col>
                </b-row>
              </b-card>
            </b-collapse>
          </div>
        </b-col>
        <!-- <b-col  cols="6" lg="4">
          <div>
            <p class="h6 font-weight-bold text-center mb-2 mt-1">MASTER</p>
            <b-row>
              <b-col cols="6" lg="4" class="mb-2 mt-2">
                <div
                  class="bios-menu text-center border shadow-sm px-4 py-4 rounded crsr"
                  @click="toMasterAkun"
                >
                  <i class="fas fa-gear fa-3x"></i>
                  <p class="small mb-0 font-weight-bold mt-1">Master Akun</p>
                </div>
              </b-col>
              <b-col cols="6" lg="4" class="mb-2 mt-2">
                <div
                  class="bios-menu text-center border shadow-sm px-4 py-4 rounded crsr"
                  @click="toMasterBank"
                >
                  <i class="fas fa-gear fa-3x"></i>
                  <p class="small mb-0 font-weight-bold mt-1">Master Bank</p>
                </div>
              </b-col>
              <b-col cols="6" lg="4" class="mb-2 mt-2">
                <div
                  class="bios-menu text-center border shadow-sm px-4 py-4 rounded crsr"
                  @click="toMasterRekening"
                >
                  <i class="fas fa-gear fa-3x"></i>
                  <p class="small mb-0 font-weight-bold mt-1">Master Rekening</p>
                </div>
              </b-col>
            </b-row>
          </div>
        </b-col> -->
      </b-row>
    </b-container>
  </div>
</template>
<script>
import { xray } from '../../../config/pluginInit'

export default {
  name: 'Bios',
  mounted () {
    xray.index()
  },
  methods: {
    toSaldoOperasional () {
      this.$router.push({
        name: 'bios.saldo-operasional'
      })
    },
    toSaldoPengelolaanKas () {
      this.$router.push({
        name: 'bios.saldo-pengelolaan-kas'
      })
    },
    toSaldoDanaKelolaan () {
      this.$router.push({
        name: 'bios.saldo-dana-kelolaan'
      })
    },
    toPengeluaran () {
      this.$router.push({
        name: 'bios.pengeluaran'
      })
    },
    toPenerimaan () {
      this.$router.push({
        name: 'bios.penerimaan'
      })
    },
    toVisiteDokter () {
      this.$router.push({
        name: 'bios.visite-dokter'
      })
    },
    toVisitePasien () {
      this.$router.push({
        name: 'bios.visite-pasien'
      })
    },
    toVisitePasien12 () {
      this.$router.push({
        name: 'bios.visite-pasien-12'
      })
    },
    toVisiteDpjp () {
      this.$router.push({
        name: 'bios.dpjp-tidak-visite'
      })
    },
    toRasioPobo () {
      this.$router.push({
        name: 'bios.rasio-pobo'
      })
    },
    toVisitePasienPertama () {
      this.$router.push({
        name: 'bios.visite-pasien-pertama'
      })
    },
    toKepuasanPasien () {
      this.$router.push({
        name: 'bios.kepuasaan-pasien'
      })
    },
    toWaktuTungguRJ () {
      this.$router.push({
        name: 'bios.waktu-tunggu-irj'
      })
    },
    toPenyelenggaraRme () {
      this.$router.push({
        name: 'bios.penyelenggara-rme'
      })
    },
    toPenggunaanAlat () {
      this.$router.push({
        name: 'bios.penggunaan-alat'
      })
    },
    toPenundaanOperasi () {
      this.$router.push({
        name: 'bios.penundaan-operasi'
      })
    },
    toKepatuhanTerhadapAlur () {
      this.$router.push({
        name: 'bios.kepatuhan-terhadap-alur'
      })
    },
    toKepatuhanPenggunaanFormularium () {
      this.$router.push({
        name: 'bios.kepatuhan-penggunaan-formularium'
      })
    },
    toKepatuhanWaktuVisiteDPJP () {
      this.$router.push({
        name: 'bios.kepatuhan-waktu-visite-DPJP'
      })
    },
    toKepatuhanPelaksanaanProtokolKesehatan () {
      this.$router.push({
        name: 'bios.kepatuhan-pelaksanaan-protokol-kesehatan'
      })
    },
    toPembelianAlkes () {
      this.$router.push({
        name: 'bios.pembelian-alkes'
      })
    },
    toKepatuhanIdentifikasiPsn () {
      this.$router.push({
        name: 'bios.kepatuhan-identifikasi-pasien'
      })
    },
    toKecepatanWktTgpKomplain () {
      this.$router.push({
        name: 'bios.kecepatan-waktu-tanggap'
      })
    },
    toLaporanHasilKritis () {
      this.$router.push({
        name: 'bios.laporan-hasil-kritis'
      })
    },
    toOperasiSeksio () {
      this.$router.push({
        name: 'bios.operasi-seksio'
      })
    },
    toResikoJatuh () {
      this.$router.push({
        name: 'bios.resiko-jatuh'
      })
    },
    toPendapatanBlu () {
      this.$router.push({
        name: 'bios.realisasi-pendapatan-blu'
      })
    },
    toJumlahVisite10 () {
      this.$router.push({
        name: 'bios.jumlah-visite-10'
      })
    },
    toKepatuhanKebersihanTangan () {
      this.$router.push({
        name: 'bios.kepatuhan-kebersihan-tangan'
      })
    },
    toBor () {
      this.$router.push({
        name: 'bios.bor'
      })
    },
    toToi () {
      this.$router.push({
        name: 'bios.toi'
      })
    },
    toAlos () {
      this.$router.push({
        name: 'bios.alos'
      })
    },
    toBto () {
      this.$router.push({
        name: 'bios.bto'
      })
    },
    // master
    toMasterAkun () {
      this.$router.push({
        name: 'bios.master-akun'
      })
    },
    toMasterBank () {
      this.$router.push({
        name: 'bios.master-bank'
      })
    },
    toMasterRekening () {
      this.$router.push({
        name: 'bios.master-rekening'
      })
    }
  }
}
</script>

<style>
#bios-view .bios-section {
  padding: 10px;
  flex-direction: row;
  justify-content: space-between;
  background: rgb(243, 254, 255);
}
#bios-view .crsr {
  cursor: pointer;
}
#bios-view .bios-menu {
  background: white;
  color: rgb(8, 155, 171);
  transition: all ease-in-out 0.3s;
}
#bios-view .bios-menu:hover {
  color: white;
  background: rgb(8, 155, 171);
}
#bios-view .bios-menu-disactive {
  background: white;
  color: rgb(133, 133, 133);
  transition: all ease-in-out 0.3s;
}
#bios-view .bios-menu-disactive:hover {
  color: white;
  background: rgb(133, 133, 133);
}
#bios-view .bios-menu-bug {
  background: white;
  color: rgb(207, 69, 0);
  transition: all ease-in-out 0.3s;
}
#bios-view .bios-menu-bug:hover {
  color: white;
  background: rgb(207, 69, 0);
}
#bios-view .rounded-custom {
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
}
</style>
