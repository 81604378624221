<template>
  <div id="master-tarif-remun" class="mb-4">
    <b-container fluid>
      <div>
        <iq-card>
          <template v-slot:body>
            <div class="filter-tarif border p-2">
              <b-row class="align-items-center">
                <b-col cols="12" lg="3" class="d-flex flex-row w-100 mr-auto">
                  <div>
                    <h5 class="card-title font-weight-bold text-uppercase mb-0">Master Tarif Remun</h5>
                  </div>
                </b-col>
                <b-col cols="12" lg="9">
                  <div class="select-tindakan d-flex flex-row justify-content-end align-items-center">
                    <div class="w-50 mr-2">
                      <multiselect :allow-empty="false" :loading="preview.isLoading" v-model="selected.tindakanGroup" @input="valueTindakanGroup" label="nama" :show-labels="false" placeholder="Grup Tindakan" :options="options.tindakanGroup" :searchable="true">
                      </multiselect>
                    </div>
                    <div>
                      <b-button disabled class="custom-button-radius py-2" size="md" variant="secondary" @click="tambahModal"><i class="fas fa-plus"></i> Tambah</b-button>
                    </div>
                  </div>
                </b-col>
              </b-row>
            </div>
            <div class="table mb-0">
              <b-table class="mb-0" ref="table" :sort-by.sync="table.sortBy" :sort-desc.sync="table.sortDesc" :busy="table.isBusy" :items="table.items" :fields="table.fields"
                :current-page="table.currentPage" :per-page="table.perPage" :filter="table.filter" @filtered="onFiltered" stacked="md"
                bordered hover show-empty small striped>
                <template #cell(index)="data">
                  {{ (table.currentPage*table.perPage)-table.perPage + data.index + 1 }}
                </template>
                <template v-slot:cell(jasa_ba)="data">
                  <span class="text-dark">{{ data.item.jasa_ba }}</span>
                </template>
                <template v-slot:cell(jasa_rs)="data">
                  <span class="text-dark">{{ data.item.jasa_rs }}</span>
                </template>
                <template v-slot:cell(jasa_rs2)="data">
                  <span class="text-dark">{{ data.item.jasa_rs2 }}</span>
                </template>
                <template v-slot:cell(jasa_dr)="data">
                  <span class="text-dark">{{ data.item.jasa_dr }}</span>
                </template>
                <template v-slot:cell(jasa_umum)="data">
                  <span class="text-dark">{{ data.item.jasa_umum }}</span>
                </template>
                <template v-slot:cell(jasa_anes_dr)="data">
                  <span class="text-dark">{{ data.item.jasa_anes_dr }}</span>
                </template>
                <template v-slot:cell(jasa_anes_umum)="data">
                  <span class="text-dark">{{ data.item.jasa_anes_umum }}</span>
                </template>
                <template v-slot:cell(total_tarif)="data">
                  <span class="text-dark">{{ data.item.total_tarif }}</span>
                </template>
                <template #cell(action)="data">
                  <b-button variant="white" size="md" @click="data.toggleDetails">
                    <i class="ri-eye-fill ri-lg text-warning"></i>
                  </b-button>
                  <b-button
                    @click="editModal(data.item.id, data.item.tindakan_id)"
                    variant=" mr-1 mb-1" size="lg">
                    <i class="fas fa-edit text-primary"></i>
                  </b-button>
                  <b-button
                    @click="deleteModal(data.item.id)"
                    variant=" mr-1 mb-1" size="lg">
                    <i class="fas fa-trash-alt text-danger"></i>
                  </b-button>
                </template>
                <template #row-details="data">
                  <div class="bg-white mx-1 my-2 p-2 border">
                    <b-row class="w-50">
                      <b-col cols="3">
                        <p class="text-dark mb-0">Created Date</p>
                      </b-col>
                      <b-col cols="1">
                        <p class="text-dark mb-0">:</p>
                      </b-col>
                      <b-col>
                        <p class="text-dark mb-0" v-if="data.item.created_at">{{ new Date(data.item.created_at) | formatDate }}</p>
                      </b-col>
                    </b-row>
                    <b-row class="w-50 mb-1">
                      <b-col cols="3">
                        <p class="text-dark mb-0">User Created</p>
                      </b-col>
                      <b-col cols="1">
                        <p class="text-dark mb-0">:</p>
                      </b-col>
                      <b-col>
                        <p class="text-dark mb-0">{{ data.item.name_created }}</p>
                      </b-col>
                    </b-row>
                    <b-row class="w-50">
                      <b-col cols="3">
                        <p class="text-dark mb-0">Updated Date</p>
                      </b-col>
                      <b-col cols="1">
                        <p class="text-dark mb-0">:</p>
                      </b-col>
                      <b-col>
                        <p class="text-dark mb-0" v-if="data.item.updated_at">{{ new Date(data.item.updated_at) | formatDate }}</p>
                      </b-col>
                    </b-row>
                    <b-row class="w-50">
                      <b-col cols="3">
                        <p class="text-dark mb-0">User Updated</p>
                      </b-col>
                      <b-col cols="1">
                        <p class="text-dark mb-0">:</p>
                      </b-col>
                      <b-col>
                        <p class="text-dark mb-0">{{ data.item.name_updated }}</p>
                      </b-col>
                    </b-row>
                  </div>
                </template>
              </b-table>
            </div>
            <div class="pagination-pbf border shadow-sm p-2 mb-2">
              <b-row class="align-items-center">
                <b-col cols="4" class="d-flex flex-row align-items-center">
                  <div class="w-100">
                    <b-pagination
                      v-model="table.currentPage"
                      :total-rows="table.totalRows"
                      :per-page="table.perPage"
                      size="md"
                      class="my-0"
                    ></b-pagination>
                  </div>
                  <div class="w-100">
                    <div>
                      <span class="border-left border-dark mr-3 py-1"></span>
                      <span @click="tableRefresh" class="tarif-cursor">
                        <i class="fas fa-sync text-primary"></i>
                      </span>
                      <span class="border-right border-dark ml-3 py-1"></span>
                    </div>
                  </div>
                </b-col>
                <b-col cols="5">
                  <div class="d-flex flex-row justify-content-end align-items-center">
                    <div class="mr-1 w-75">
                      <form class="searchbox mb-0">
                        <input v-model="table.filter" type="search" class="form-control cstm-srch-pbf" id="filter-input"
                          placeholder="[Cari Nama Tindakan]">
                      </form>
                    </div>
                    <div>
                      <b-form-select class="cstm-slct-pbf" v-model="selected.filter" :options="options.optionsFilter" @change="filterchange"></b-form-select>
                    </div>
                  </div>
                </b-col>
                <b-col cols="3">
                  <div class="d-flex flex-row align-items-center justify-content-end">
                    <div class="mr-3">
                      <b-form-select
                        class="cstm-select-pbf"
                        id="per-page-select"
                        v-model="table.perPage"
                        :options="table.pageOptions"
                      ></b-form-select>
                    </div>
                    <div>
                      <p class="text-dark mb-0">Menampilkan {{ table.perPage }} dari {{ table.totalRows }} baris</p>
                    </div>
                  </div>
                </b-col>
              </b-row>
            </div>
          </template>
        </iq-card>
      </div>
    </b-container>
    <div id="modal-component-tarif">
      <!-- <b-modal id="modal-tambah-tarif-remun" ref="modal-tambah-tarif-remun" size="xl" centered title="Tambah Tindakan" ok-title="Simpan Tindakan" cancel-title="Tutup" :no-close-on-backdrop="true" :static="true" @ok="tambahSave">
        <b-form>
          <b-form-group class="mb-1">
            <b-row class="align-items-center">
              <b-col cols="2">
                <span>
                  <p class="font-custom text-dark mb-0">Tindakan</p>
                </span>
              </b-col>
              <b-col>
                <multiselect :allow-empty="false" :loading="preview.isLoading" @search-change="getTindakan" v-model="selected.tindakan" label="nama" :show-labels="false" placeholder="[ Tindakan ]" :options="options.tindakan" @input="valueTindakan" :searchable="true">
                </multiselect>
              </b-col>
            </b-row>
          </b-form-group>
          <b-row class="mb-custom align-items-center">
            <b-col cols="2">
              <div>
                <p class="font-custom text-dark mb-0">Jasa BA</p>
              </div>
            </b-col>
            <b-col>
              <div>
                <b-form-input class="cstm-cntrl-tarif" v-model="preview.tarif.jasa_ba" @keypress="isNumberKey" @input="convertMoneyFormated($event, 'a')" placeholder="Jasa BA"></b-form-input>
              </div>
            </b-col>
            <b-col cols="2">
              <div>
                <p class="font-custom text-dark mb-0">Jasa Umum</p>
              </div>
            </b-col>
            <b-col>
              <div>
                <b-form-input class="cstm-cntrl-tarif" v-model="preview.tarif.jasa_umum" @keypress="isNumberKey" @input="convertMoneyFormated($event, 'e')" placeholder="Jasa Umum"></b-form-input>
              </div>
            </b-col>
          </b-row>
          <b-row class="mb-custom align-items-center">
            <b-col cols="2">
              <div>
                <p class="font-custom text-dark mb-0">Jasa RS</p>
              </div>
            </b-col>
            <b-col>
              <div>
                <b-form-input class="cstm-cntrl-tarif" v-model="preview.tarif.jasa_rs" @keypress="isNumberKey" @input="convertMoneyFormated($event, 'c')" placeholder="Jasa RS"></b-form-input>
              </div>
            </b-col>
            <b-col cols="2">
              <div>
                <p class="font-custom text-dark mb-0">Jasa Anes dr</p>
              </div>
            </b-col>
            <b-col>
              <div>
                <b-form-input class="cstm-cntrl-tarif" v-model="preview.tarif.jasa_anes_dr" @keypress="isNumberKey" @input="convertMoneyFormated($event, 'f')" placeholder="jasa Anes dr"></b-form-input>
              </div>
            </b-col>
          </b-row>
          <b-row class="mb-custom align-items-center">
            <b-col cols="2">
              <div>
                <p class="font-custom text-dark mb-0">Jasa RS 2</p>
              </div>
            </b-col>
            <b-col>
              <div>
                <b-form-input class="cstm-cntrl-tarif" v-model="preview.tarif.jasa_rs2" @keypress="isNumberKey" @input="convertMoneyFormated($event, 'd')" placeholder="Jasa RS2"></b-form-input>
              </div>
            </b-col>
            <b-col cols="2">
              <div>
                <p class="font-custom text-dark mb-0">Jasa Anes Umum</p>
              </div>
            </b-col>
            <b-col>
              <div>
                <b-form-input class="cstm-cntrl-tarif" v-model="preview.tarif.jasa_anes_umum" @keypress="isNumberKey" @input="convertMoneyFormated($event, 'g')" placeholder="Jasa Anes Umum"></b-form-input>
              </div>
            </b-col>
          </b-row>
          <b-row class="mb-custom align-items-center">
            <b-col cols="2">
              <div>
                <p class="font-custom text-dark mb-0">Jasa dr</p>
              </div>
            </b-col>
            <b-col>
              <div>
                <b-form-input class="cstm-cntrl-tarif" v-model="preview.tarif.jasa_dr" @keypress="isNumberKey" @input="convertMoneyFormated($event, 'b')" placeholder="Jasa dr"></b-form-input>
              </div>
            </b-col>
            <b-col cols="2">
              <div>
                <p class="font-custom text-dark mb-0">Total Tarif</p>
              </div>
            </b-col>
            <b-col>
              <div>
                <b-form-input class="cstm-cntrl-tarif" v-model="preview.tarif.total_tarif" @keypress="isNumberKey" @input="convertMoneyFormated($event, 'h')" placeholder="Total Tarif"></b-form-input>
              </div>
            </b-col>
          </b-row>
          <b-row class="mb-custom align-items-center">
            <b-col cols="2">
              <div>
                <p class="font-custom text-dark mb-0">Total Tarif JKN</p>
              </div>
            </b-col>
            <b-col>
              <div>
                <b-form-input class="cstm-cntrl-tarif" v-model="preview.tarif.total_tarif_jkn" @keypress="isNumberKey" @input="convertMoneyFormated($event, 'i')" placeholder="Total Tarif JKN"></b-form-input>
              </div>
            </b-col>
            <b-col cols="2">
              <div>
                <p class="font-custom text-dark mb-0">Total Tarif Reguler</p>
              </div>
            </b-col>
            <b-col>
              <div>
                <b-form-input class="cstm-cntrl-tarif" v-model="preview.tarif.total_tarif_reguler" @keypress="isNumberKey" @input="convertMoneyFormated($event, 'j')" placeholder="Total Tarif Reguler"></b-form-input>
              </div>
            </b-col>
          </b-row>
          <b-row class="align-items-center">
            <b-col cols="2">
              <div>
                <p class="font-custom text-dark mb-0">Total Tarif Eksekutif</p>
              </div>
            </b-col>
            <b-col cols="4">
              <div>
                <b-form-input class="cstm-cntrl-tarif" v-model="preview.tarif.total_tarif_executive" @keypress="isNumberKey" @input="convertMoneyFormated($event, 'k')" placeholder="Total Tarif Eksekutif"></b-form-input>
              </div>
            </b-col>
          </b-row>
        </b-form>
        <div class="bg-danger">
          <p class="text-right px-2 mt-2 mb-0" v-if="preview.messageValue === '2'">{{ preview.message }}</p>
        </div>
      </b-modal> -->
      <b-modal id="modal-edit-tarif-remun" ref="modal-edit-tarif-remun" size="xl" centered :title="preview.modal_title" ok-title="Perbarui TIndakan" cancel-title="Tutup" :no-close-on-backdrop="true" :static="true" @ok="editSave">
        <b-form>
          <b-form-group class="mb-1">
            <b-row class="align-items-center">
              <b-col cols="2">
                <span>
                  <p class="h6 text-dark mb-0">Tindakan</p>
                </span>
              </b-col>
              <b-col>
                <multiselect disabled :allow-empty="false" :loading="preview.isLoading" @search-change="getTindakan" v-model="selected.tindakan" label="nama" :show-labels="false" placeholder="[ Tindakan ]" :options="options.tindakan" @input="valueTindakan" :searchable="true">
                </multiselect>
              </b-col>
            </b-row>
          </b-form-group>
          <hr>
          <b-form-group>
            <b-row>
              <b-col cols="12" md="6">
                <b-row class="align-items-center mb-1">
                  <b-col cols="5">
                    <div>
                      <p class="h6 text-dark mb-0">Jasa BA</p>
                    </div>
                  </b-col>
                  <b-col>
                    <div>
                      <b-form-input class="cstm-cntrl-tarif" v-model="preview.tarif.jasa_ba" @keypress="isNumberKey" @input="convertMoneyFormated($event, 'a')" placeholder="Jasa BA"></b-form-input>
                    </div>
                  </b-col>
                </b-row>
                <b-row class="align-items-center mb-1">
                  <b-col cols="5">
                    <div>
                      <p class="h6 text-dark mb-0">Jasa RS</p>
                    </div>
                  </b-col>
                  <b-col>
                    <div>
                      <b-form-input class="cstm-cntrl-tarif" v-model="preview.tarif.jasa_rs" @keypress="isNumberKey" @input="convertMoneyFormated($event, 'c')" placeholder="Jasa RS"></b-form-input>
                    </div>
                  </b-col>
                </b-row>
                <b-row class="align-items-center mb-1">
                  <b-col cols="5">
                    <div>
                      <p class="h6 text-dark mb-0">Jasa RS 2</p>
                    </div>
                  </b-col>
                  <b-col>
                    <div>
                      <b-form-input class="cstm-cntrl-tarif" v-model="preview.tarif.jasa_rs2" @keypress="isNumberKey" @input="convertMoneyFormated($event, 'd')" placeholder="Jasa RS2"></b-form-input>
                    </div>
                  </b-col>
                </b-row>
                <b-row class="align-items-center mb-1">
                  <b-col cols="5">
                    <div>
                      <p class="h6 text-dark mb-0">Jasa dr</p>
                    </div>
                  </b-col>
                  <b-col>
                    <div>
                      <b-form-input class="cstm-cntrl-tarif" v-model="preview.tarif.jasa_dr" @keypress="isNumberKey" @input="convertMoneyFormated($event, 'b')" placeholder="Jasa dr"></b-form-input>
                    </div>
                  </b-col>
                </b-row>
                <b-row class="align-items-center mb-1">
                  <b-col cols="5">
                    <div>
                      <p class="h6 text-dark mb-0">Jasa Umum</p>
                    </div>
                  </b-col>
                  <b-col>
                    <div>
                      <b-form-input class="cstm-cntrl-tarif" v-model="preview.tarif.jasa_umum" @keypress="isNumberKey" @input="convertMoneyFormated($event, 'e')" placeholder="Jasa Umum"></b-form-input>
                    </div>
                  </b-col>
                </b-row>
                <b-row class="align-items-center mb-1">
                  <b-col cols="5">
                    <div>
                      <p class="h6 text-dark mb-0">Jasa Anes dr</p>
                    </div>
                  </b-col>
                  <b-col>
                    <div>
                      <b-form-input class="cstm-cntrl-tarif" v-model="preview.tarif.jasa_anes_dr" @keypress="isNumberKey" @input="convertMoneyFormated($event, 'f')" placeholder="jasa Anes dr"></b-form-input>
                    </div>
                  </b-col>
                </b-row>
                <b-row class="align-items-center mb-1">
                  <b-col cols="5">
                    <div>
                      <p class="h6 text-dark mb-0">Jasa Anes Umum</p>
                    </div>
                  </b-col>
                  <b-col>
                    <div>
                      <b-form-input class="cstm-cntrl-tarif" v-model="preview.tarif.jasa_anes_umum" @keypress="isNumberKey" @input="convertMoneyFormated($event, 'g')" placeholder="Jasa Anes Umum"></b-form-input>
                    </div>
                  </b-col>
                </b-row>
                <b-row class="align-items-center mb-1">
                  <b-col cols="5">
                    <div>
                      <p class="h6 text-dark mb-0">Jasa Perawat</p>
                    </div>
                  </b-col>
                  <b-col>
                    <div>
                      <b-form-input class="cstm-cntrl-tarif" v-model="preview.tarif.jasa_perawat" @keypress="isNumberKey" @input="convertMoneyFormated($event, 'l')" placeholder="Jasa Anes Umum"></b-form-input>
                    </div>
                  </b-col>
                </b-row>
                <b-row class="align-items-center mb-1">
                  <b-col cols="5">
                    <div>
                      <p class="h6 text-dark mb-0">Jasa Penata Anestesi</p>
                    </div>
                  </b-col>
                  <b-col>
                    <div>
                      <b-form-input class="cstm-cntrl-tarif" v-model="preview.tarif.jasa_penataanestesi" @keypress="isNumberKey" @input="convertMoneyFormated($event, 'm')" placeholder="Jasa Anes Umum"></b-form-input>
                    </div>
                  </b-col>
                </b-row>
                <b-row class="align-items-center mb-1">
                  <b-col cols="5">
                    <div>
                      <p class="h6 text-dark mb-0">Jasa Manajemen Bp</p>
                    </div>
                  </b-col>
                  <b-col>
                    <div>
                      <b-form-input class="cstm-cntrl-tarif" v-model="preview.tarif.jasa_manajemenbp" @keypress="isNumberKey" @input="convertMoneyFormated($event, 'n')" placeholder="Jasa Anes Umum"></b-form-input>
                    </div>
                  </b-col>
                </b-row>
                <b-row class="align-items-center mb-0">
                  <b-col cols="5">
                    <div>
                      <p class="h6 text-dark mb-0">Jasa Radiologi</p>
                    </div>
                  </b-col>
                  <b-col>
                    <div>
                      <b-form-input class="cstm-cntrl-tarif" v-model="preview.tarif.jasa_radiologi" @keypress="isNumberKey" @input="convertMoneyFormated($event, 'o')" placeholder="Jasa Anes Umum"></b-form-input>
                    </div>
                  </b-col>
                </b-row>
              </b-col>
              <b-col cols="12" md="6">
                <div class="bg-white py-3 px-3 border shadow-sm">
                  <b-row class="align-items-center mb-0">
                    <b-col cols="5">
                      <div>
                        <p class="h6 text-dark mb-0">Total Tarif RS</p>
                      </div>
                    </b-col>
                    <b-col>
                      <div>
                        <b-form-input readonly class="cstm-cntrl-tarif shadow-sm" v-model="preview.tarif.total_tarif" @keypress="isNumberKey" @input="convertMoneyFormated($event, 'h')" placeholder="Total Tarif"></b-form-input>
                      </div>
                    </b-col>
                  </b-row>
                  <div v-if="this.show_virtual === 1">
                    <hr>
                    <b-row class="align-items-center mb-2">
                      <b-col cols="5">
                        <div>
                          <p class="h6 text-dark mb-0">Total Tarif Reguler</p>
                        </div>
                        <div>
                          <b-form-checkbox
                            v-model="selected.selectedReguler"
                            value="1"
                            unchecked-value="0"
                            size="sm"
                            @change="equalityTarif($event, 'reg')"
                            >
                            Sama dengan tarif RS
                          </b-form-checkbox>
                        </div>
                      </b-col>
                      <b-col>
                        <div>
                          <b-form-input class="cstm-cntrl-tarif shadow-sm" :readonly="preview.disableFieldReg" v-model="preview.tarif.total_tarif_reguler" @keypress="isNumberKey" @input="convertMoneyFormated($event, 'j')" placeholder="Total Tarif Reguler"></b-form-input>
                        </div>
                      </b-col>
                    </b-row>
                    <b-row class="align-items-center mb-2">
                      <b-col cols="5">
                        <div>
                          <p class="h6 text-dark mb-0">Total Tarif JKN</p>
                        </div>
                        <div>
                          <b-form-checkbox
                            v-model="selected.selectedJkn"
                            value="1"
                            unchecked-value="0"
                            size="sm"
                            @change="equalityTarif($event, 'jkn')"
                            >
                            Sama dengan tarif RS
                          </b-form-checkbox>
                        </div>
                      </b-col>
                      <b-col>
                        <div>
                          <b-form-input class="cstm-cntrl-tarif shadow-sm" :readonly="preview.disableFieldJkn" v-model="preview.tarif.total_tarif_jkn" @keypress="isNumberKey" @input="convertMoneyFormated($event, 'i')" placeholder="Total Tarif JKN"></b-form-input>
                        </div>
                      </b-col>
                    </b-row>
                    <b-row class="align-items-center">
                      <b-col cols="5">
                        <div>
                          <p class="h6 text-dark mb-0">Total Tarif Eksekutif</p>
                        </div>
                        <div>
                          <b-form-checkbox
                            v-model="selected.selectedExecutive"
                            value="1"
                            unchecked-value="0"
                            size="sm"
                            @change="equalityTarif($event, 'exc')"
                            >
                            Sama dengan tarif RS
                          </b-form-checkbox>
                        </div>
                      </b-col>
                      <b-col>
                        <div>
                          <b-form-input class="cstm-cntrl-tarif shadow-sm" :readonly="preview.disableFieldExc" v-model="preview.tarif.total_tarif_executive" @keypress="isNumberKey" @input="convertMoneyFormated($event, 'k')" placeholder="Total Tarif Eksekutif"></b-form-input>
                        </div>
                      </b-col>
                    </b-row>
                  </div>
                  <!-- <div class="mt-3">
                    <b-button variant="warning" class="shadow-sm border custom-button-radius" @click="countTarif"><i class="fas fa-calculator"></i> Hitung Tarif RS</b-button>
                  </div> -->
                </div>
              </b-col>
            </b-row>
          </b-form-group>
        </b-form>
        <div class="bg-dark">
          <p class="text-right text-white px-2 mb-0" v-if="preview.messageValue === '2'">{{ preview.message }}</p>
        </div>
        <!-- <pre>{{ formTarif }}</pre> -->
        <!-- <pre>{{ preview.tarif }}</pre> -->
      </b-modal>
      <b-modal ref="modal-delete-tarif" size="md" centered title="Konfirmasi" ok-title="Hapus Data" ok-variant="danger" cancel-title="Tutup" @ok="deleteSave">
        <div class="py-3">
          <p class="h6 text-center text-dark mb-0">Yakin Ingin Menghapus Data ?</p>
        </div>
      </b-modal>
      <b-modal ref="modal-notif-tarif" size="md" centered title="Notifikasi" ok-title="Tutup" :hide-footer="true" :hide-header="true">
        <div class="pb-3 px-4">
          <div v-if="preview.messageValue === '1'">
            <lottie :options="preview.lottie.defaultOptions" :autoPlay="true" :width="150" :height="150" />
          </div>
          <div v-else>
            <lottie :options="preview.lottie.defaultOptions2" :autoPlay="true" :width="150" :height="150" />
          </div>
          <p class="h6 text-dark text-center mb-0 mt-2">{{ preview.message }}</p>
        </div>
      </b-modal>
    </div>
  </div>
</template>
<script>
import { xray } from '../../../config/pluginInit'
import jwtdecode from 'jwt-decode'
import Master from '../../../services/fatmahost/master/index'
import Lottie from 'vue-lottie'
import animationLogo from '../../../assets/lottie/checked.json'
import animationLogo2 from '../../../assets/lottie/failed.json'
import Multiselect from 'vue-multiselect'

export default {
  name: 'Pbf',
  components: {
    Multiselect,
    Lottie
  },
  data () {
    return {
      table: {
        isBusy: false,
        items: this.list,
        sortBy: '',
        sortDesc: false,
        fields: [
          // { label: 'NAMA', key: 'NAMA' },
          { label: 'No', key: 'index', class: 'text-center' },
          { label: 'ID TINDAKAN', key: 'tindakan_id', sortable: true },
          { label: 'NAMA TINDAKAN', key: 'nama', sortable: true },
          // { label: 'JASA BA', key: 'jasa_ba', class: 'text-right', sortable: true },
          // { label: 'JASA RS', key: 'jasa_rs', class: 'text-right', sortable: true },
          // { label: 'JASA RS2', key: 'jasa_rs2', class: 'text-right', sortable: true },
          { label: 'JASA DOKTER', key: 'jasa_dr', class: 'text-right', sortable: true },
          // { label: 'JASA UMUM', key: 'jasa_umum', class: 'text-right', sortable: true },
          // { label: 'JASA DOKTER ANES', key: 'jasa_anes_dr', class: 'text-right', sortable: true },
          // { label: 'JASA ANES UMUM', key: 'jasa_anes_umum', class: 'text-right', sortable: true },
          { label: 'TOTAL TARIF RS', key: 'total_tarif', class: 'text-right', sortable: true },
          // { label: 'TOTAL TARIF EKSEKUTIF', key: 'total_tarif_executive', class: 'text-right', sortable: true },
          // { label: 'TOTAL TARIF JKN', key: 'total_tarif_jkn', class: 'text-right', sortable: true },
          // { label: 'TOTAL TARIF REGULER', key: 'total_tarif_reguler', class: 'text-right', sortable: true },
          { label: 'Aksi', key: 'action', class: 'text-center' }
        ],
        filter: null,
        currentPage: 1,
        perPage: 10,
        pageOptions: [10, 25, 50, 100],
        totalRows: null
      },
      formTarif: {
        object: 'tarif',
        tindakan_id: '',
        tindakan_nama: '',
        jasa_ba: '',
        jasa_rs: '',
        jasa_rs2: '',
        jasa_dr: '',
        jasa_umum: '',
        jasa_anes_dr: '',
        jasa_anes_umum: '',
        jasa_perawat: '', //
        jasa_penataanestesi: '', //
        jasa_manajemenbp: '', //
        jasa_radiologi: '', //
        total_tarif: '',
        total_tarif_jkn: '',
        total_tarif_reguler: '',
        total_tarif_executive: '',
        created_by: jwtdecode(localStorage.getItem('token')).user.id.toString(),
        updated_by: jwtdecode(localStorage.getItem('token')).user.id.toString()
      },
      options: {
        optionsFilter: [
          { text: 'Nama Tindakan', value: '1' }
        ],
        tindakan: [],
        tindakanGroup: []
      },
      selected: {
        filter: '1',
        tindakan: [],
        tindakanGroup: [],
        selectedReguler: '0',
        selectedJkn: '0',
        selectedExecutive: '0'
      },
      show_virtual: 0,
      preview: {
        isLoading: false,
        tarif: {
          jasa_ba: '',
          jasa_rs: '',
          jasa_rs2: '',
          jasa_dr: '',
          jasa_umum: '',
          jasa_anes_dr: '',
          jasa_anes_umum: '',
          jasa_perawat: '', //
          jasa_penataanestesi: '', //
          jasa_manajemenbp: '', //
          jasa_radiologi: '', //
          total_tarif: '',
          total_tarif_jkn: '',
          total_tarif_reguler: '',
          total_tarif_executive: ''
        },
        lottie: {
          defaultOptions: { animationData: animationLogo, loop: true },
          defaultOptions2: { animationData: animationLogo2, loop: true }
        },
        user_name: jwtdecode(localStorage.getItem('token')).user.name,
        edit_id: '',
        delete_id: '',
        message: '',
        messageValue: '',
        modal_title: '',
        disableFieldReg: false,
        disableFieldJkn: false,
        disableFieldExc: false
      }
    }
  },
  mounted () {
    xray.index()
    this.getTindakanGroup()
  },
  methods: {
    tableRefresh () {
      this.selected.filter = '1'
      this.table.filter = ''
      if (this.selected.tindakanGroup.id) {
        this.selected.tindakanGroup.id = ''
      }
      this.selected.tindakanGroup = []
      this.$refs.table.refresh()
    },
    isNumberKey (evt) {
      evt = (evt) || window.event
      var charCode = (evt.which) ? evt.which : evt.keyCode
      if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
        evt.preventDefault()
      } else {
        return true
      }
    },
    async list (ctx) {
      let filterType = this.selected.filter
      let tndkn = this.selected.tindakanGroup.id ? this.selected.tindakanGroup.id.toString() : ''
      let sortfield = ''
      let sorting = ''
      let filter = ''
      if (ctx.filter === '') {
        ctx.filter = null
      }
      if (ctx.sortBy) {
        sortfield = ctx.sortBy
      } else {
        sortfield = ''
      }
      if (ctx.sortDesc === true) {
        sorting = 'DESC'
      } else if (ctx.sortDesc === false) {
        sorting = 'ASC'
      } else {
        sorting = ''
      }
      if (ctx.filter === null) {
        filter = `param3={"xparam0":"", "xparam1":"${tndkn}"}&param4={"xparam0":"${sortfield}","xparam1":"${sorting}"}`
      } else {
        if (filterType === '1') {
          filter = `param3={"xparam0":"${ctx.filter}", "xparam1":"${tndkn}"}&param4={"xparam0":"${sortfield}","xparam1":"${sorting}"}`
        } else if (filterType === '2') {
          filter = `param3={"xparam0":"${ctx.filter}", "xparam1":"${tndkn}"}&param4={"xparam0":"${sortfield}","xparam1":"${sorting}"}`
        }
      }
      this.isBusy = true
      try {
        const response = await Master.list_tarif_remun(ctx.currentPage - 1, ctx.perPage, filter)
        this.isBusy = false
        this.table.totalRows = response.data.pagination.total
        this.table.perPage = response.data.pagination.perPage
        this.table.currentPage = response.data.pagination.current
        return response.data.data
      } catch (error) {
        this.isBusy = false
        return []
      }
    },
    onFiltered (filteredItems) {
      this.table.totalRows = filteredItems.length
      // this.table.currentPage = 1
    },
    filterchange () {
      this.table.filter = ''
    },
    tambahModal () {
      this.formClear()
      this.$refs['modal-tambah-tarif-remun'].show()
    },
    async tambahSave (bvModalEvent) {
      bvModalEvent.preventDefault()
      try {
        let data = this.formTarif
        const response = await Master.create_tarif(data)
        if (response.data.statcode === 0) {
          this.preview.message = response.data.message
          this.preview.messageValue = '1'
          this.$refs['modal-tambah-tarif-remun'].hide()
          this.$refs['modal-notif-tarif'].show()
          this.$refs.table.refresh()
        } else {
          this.preview.message = response.data.message
          this.preview.messageValue = '2'
        }
      } catch (error) {
        this.preview.message = error.message
        this.preview.messageValue = '2'
      }
    },
    async editModal (id, tindakan) {
      this.formClear()
      let ids = id
      this.preview.edit_id = ids
      this.preview.modal_title = 'Kode :' + ' ' + tindakan.toString()
      try {
        const response = await Master.show_tarif_remun(ids)
        if (response.data.statcode === 0) {
          this.show_virtual = response.data.data[0].show_virtual
          this.formTarif.tindakan_id = response.data.data[0].tindakan_id ? response.data.data[0].tindakan_id.toString() : ''
          this.formTarif.tindakan_nama = response.data.data[0].tindakan_nama ? response.data.data[0].tindakan_nama.toString() : ''
          this.selected.tindakan = response.data.data[0].tindakan_id ? { id: response.data.data[0].tindakan_id, nama: response.data.data[0].tindakan_nama } : []
          if (response.data.data[0].jasa_ba || response.data.data[0].jasa_ba !== null) {
            this.convertMoneyFormated(response.data.data[0].jasa_ba.toString(), 'a')
          } else {
            this.formTarif.jasa_ba = '0'
            this.preview.tarif.jasa_ba = '0'
          }
          //
          if (response.data.data[0].jasa_dr || response.data.data[0].jasa_dr !== null) {
            this.convertMoneyFormated(response.data.data[0].jasa_dr.toString(), 'b')
          } else {
            this.formTarif.jasa_dr = '0'
            this.preview.tarif.jasa_dr = '0'
          }
          //
          if (response.data.data[0].jasa_rs || response.data.data[0].jasa_rs !== null) {
            this.convertMoneyFormated(response.data.data[0].jasa_rs.toString(), 'c')
          } else {
            this.formTarif.jasa_rs = '0'
            this.preview.tarif.jasa_rs = '0'
          }
          //
          if (response.data.data[0].jasa_rs2 || response.data.data[0].jasa_rs2 !== null) {
            this.convertMoneyFormated(response.data.data[0].jasa_rs2.toString(), 'd')
          } else {
            this.formTarif.jasa_rs2 = '0'
            this.preview.tarif.jasa_rs2 = '0'
          }
          //
          if (response.data.data[0].jasa_umum || response.data.data[0].jasa_umum !== null) {
            this.convertMoneyFormated(response.data.data[0].jasa_umum.toString(), 'e')
          } else {
            this.formTarif.jasa_umum = '0'
            this.preview.tarif.jasa_umum = '0'
          }
          //
          if (response.data.data[0].jasa_anes_dr || response.data.data[0].jasa_anes_dr !== null) {
            this.convertMoneyFormated(response.data.data[0].jasa_anes_dr.toString(), 'f')
          } else {
            this.formTarif.jasa_anes_dr = '0'
            this.preview.tarif.jasa_anes_dr = '0'
          }
          //
          if (response.data.data[0].jasa_anes_umum || response.data.data[0].jasa_anes_umum !== null) {
            this.convertMoneyFormated(response.data.data[0].jasa_anes_umum.toString(), 'g')
          } else {
            this.formTarif.jasa_anes_umum = '0'
            this.preview.tarif.jasa_anes_umum = '0'
          }
          //
          if (response.data.data[0].jasa_perawat || response.data.data[0].jasa_perawat !== null) {
            this.convertMoneyFormated(response.data.data[0].jasa_perawat.toString(), 'l')
          } else {
            this.formTarif.jasa_perawat = '0'
            this.preview.tarif.jasa_perawat = '0'
          }
          //
          if (response.data.data[0].jasa_penataanestesi || response.data.data[0].jasa_penataanestesi !== null) {
            this.convertMoneyFormated(response.data.data[0].jasa_penataanestesi.toString(), 'm')
          } else {
            this.formTarif.jasa_penataanestesi = '0'
            this.preview.tarif.jasa_penataanestesi = '0'
          }
          //
          if (response.data.data[0].jasa_manajemenbp || response.data.data[0].jasa_manajemenbp !== null) {
            this.convertMoneyFormated(response.data.data[0].jasa_manajemenbp.toString(), 'n')
          } else {
            this.formTarif.jasa_manajemenbp = '0'
            this.preview.tarif.jasa_manajemenbp = '0'
          }
          //
          if (response.data.data[0].jasa_radiologi || response.data.data[0].jasa_radiologi !== null) {
            this.convertMoneyFormated(response.data.data[0].jasa_radiologi.toString(), 'o')
          } else {
            this.formTarif.jasa_radiologi = '0'
            this.preview.tarif.jasa_radiologi = '0'
          }
          //
          if (response.data.data[0].total_tarif || response.data.data[0].total_tarif !== null) {
            this.convertMoneyFormated(response.data.data[0].total_tarif.toString(), 'h')
          } else {
            this.formTarif.total_tarif = '0'
            this.preview.tarif.total_tarif = '0'
          }
          //
          if (response.data.data[0].total_tarif_jkn !== null) {
            if (response.data.data[0].total_tarif_jkn === response.data.data[0].total_tarif) {
              this.selected.selectedJkn = '1'
              this.preview.disableFieldJkn = true
            }
            this.convertMoneyFormated(response.data.data[0].total_tarif_jkn.toString(), 'i')
          } else if (response.data.data[0].total_tarif_jkn === null || response.data.data[0].total_tarif_jkn === 0) {
            if (response.data.data[0].total_tarif !== null) {
              this.convertMoneyFormated(response.data.data[0].total_tarif.toString(), 'i')
              this.selected.selectedJkn = '1'
              this.preview.disableFieldJkn = true
            }
          }
          //
          if (response.data.data[0].total_tarif_reguler !== null) {
            if (response.data.data[0].total_tarif_reguler === response.data.data[0].total_tarif) {
              this.selected.selectedReguler = '1'
              this.preview.disableFieldReg = true
            }
            this.convertMoneyFormated(response.data.data[0].total_tarif_reguler.toString(), 'j')
          } else if (response.data.data[0].total_tarif_reguler === null || response.data.data[0].total_tarif_reguler === 0) {
            if (response.data.data[0].total_tarif !== null) {
              this.convertMoneyFormated(response.data.data[0].total_tarif.toString(), 'j')
              this.selected.selectedReguler = '1'
              this.preview.disableFieldReg = true
            }
          }
          //
          if (response.data.data[0].total_tarif_executive !== null) {
            if (response.data.data[0].total_tarif_executive === response.data.data[0].total_tarif) {
              this.selected.selectedExecutive = '1'
              this.preview.disableFieldExc = true
            }
            this.convertMoneyFormated(response.data.data[0].total_tarif_executive.toString(), 'k')
          } else if (response.data.data[0].total_tarif_executive === null || response.data.data[0].total_tarif_executive === 0) {
            if (response.data.data[0].total_tarif !== null) {
              this.convertMoneyFormated(response.data.data[0].total_tarif.toString(), 'k')
              this.selected.selectedExecutive = '1'
              this.preview.disableFieldExc = true
            }
          }
        } else {
          this.preview.message = response.data.message
          this.preview.messageValue = '2'
        }
      } catch (error) {
        this.preview.message = error.message
        this.preview.messageValue = '2'
      }
      this.$refs['modal-edit-tarif-remun'].show()
    },
    async editSave (bvModalEvent) {
      bvModalEvent.preventDefault()
      let id = this.preview.edit_id
      let data = this.formTarif
      try {
        const response = await Master.update_tarif(id, data)
        if (response.data.statcode === 0) {
          this.preview.message = response.data.message
          this.preview.messageValue = '1'
          this.$refs['modal-edit-tarif-remun'].hide()
          this.$refs['modal-notif-tarif'].show()
          this.$refs.table.refresh()
        } else {
          this.preview.message = response.data.message
          this.preview.messageValue = '2'
        }
      } catch (error) {
        this.preview.message = error.message
        this.preview.messageValue = '2'
      }
    },
    deleteModal (id) {
      this.preview.delete_id = id
      this.$refs['modal-delete-tarif'].show()
    },
    async deleteSave () {
      let id = this.preview.delete_id
      try {
        const response = await Master.delete_tarif(id)
        if (response.data.statcode === 0) {
          this.preview.message = response.data.message
          this.preview.messageValue = '1'
        } else {
          this.preview.message = response.data.message
          this.preview.messageValue = '2'
        }
        this.$refs['modal-notif-tarif'].show()
        this.$refs.table.refresh()
      } catch (error) {
        this.preview.message = error.message
        this.preview.messageValue = '2'
        this.$refs['modal-notif-tarif'].show()
      }
    },
    async getTindakan (value) {
      if (value.length === 0) {
        this.options.tindakan = []
      } else if (value.length === 2) {
        try {
          const response = await Master.select_tindakan()
          this.options.tindakan = response.data.data
        } catch (error) {
          this.preview.message = error.message
          this.preview.messageValue = '2'
        }
      }
    },
    async getTindakanGroup () {
      try {
        const response = await Master.select_tindakan_group()
        this.options.tindakanGroup = response.data.data
      } catch (error) {
        this.preview.message = error.message
        this.preview.messageValue = '2'
      }
    },
    valueTindakan (value) {
      this.formTarif.tindakan_id = value.id.toString()
      this.formTarif.tindakan_nama = value.nama
    },
    valueTindakanGroup () {
      this.$refs.table.refresh()
    },
    convertMoneyFormated (value, param) {
      let numberString = value.replace(/[^,\d]/g, '').toString()
      let split = numberString.split(',')
      let sisa = split[0].length % 3
      let rupiah = split[0].substr(0, sisa)
      let ribuan = split[0].substr(sisa).match(/\d{3}/gi)
      if (ribuan) {
        let separator = sisa ? '.' : ''
        rupiah += separator + ribuan.join('.')
      }
      rupiah = split[1] !== undefined ? rupiah + ',' + split[1] : rupiah
      if (param === 'a') {
        this.preview.tarif.jasa_ba = rupiah
        this.formTarif.jasa_ba = value ? value.replace(/[.,\s]/g, '') : '0'
      } else if (param === 'b') {
        this.preview.tarif.jasa_dr = rupiah
        this.formTarif.jasa_dr = value.replace(/[.,\s]/g, '')
      } else if (param === 'c') {
        this.preview.tarif.jasa_rs = rupiah
        this.formTarif.jasa_rs = value.replace(/[.,\s]/g, '')
      } else if (param === 'd') {
        this.preview.tarif.jasa_rs2 = rupiah
        this.formTarif.jasa_rs2 = value.replace(/[.,\s]/g, '')
      } else if (param === 'e') {
        this.preview.tarif.jasa_umum = rupiah
        this.formTarif.jasa_umum = value.replace(/[.,\s]/g, '')
      } else if (param === 'f') {
        this.preview.tarif.jasa_anes_dr = rupiah
        this.formTarif.jasa_anes_dr = value.replace(/[.,\s]/g, '')
      } else if (param === 'g') {
        this.preview.tarif.jasa_anes_umum = rupiah
        this.formTarif.jasa_anes_umum = value.replace(/[.,\s]/g, '')
      } else if (param === 'h') {
        this.preview.tarif.total_tarif = rupiah
        this.formTarif.total_tarif = value.replace(/[.,\s]/g, '')
      } else if (param === 'i') {
        this.preview.tarif.total_tarif_jkn = rupiah
        this.formTarif.total_tarif_jkn = value.replace(/[.,\s]/g, '')
      } else if (param === 'j') {
        this.preview.tarif.total_tarif_reguler = rupiah
        this.formTarif.total_tarif_reguler = value.replace(/[.,\s]/g, '')
      } else if (param === 'k') {
        this.preview.tarif.total_tarif_executive = rupiah
        this.formTarif.total_tarif_executive = value.replace(/[.,\s]/g, '')
      } else if (param === 'l') {
        this.preview.tarif.jasa_perawat = rupiah
        this.formTarif.jasa_perawat = value.replace(/[.,\s]/g, '')
      } else if (param === 'm') {
        this.preview.tarif.jasa_penataanestesi = rupiah
        this.formTarif.jasa_penataanestesi = value.replace(/[.,\s]/g, '')
      } else if (param === 'n') {
        this.preview.tarif.jasa_manajemenbp = rupiah
        this.formTarif.jasa_manajemenbp = value.replace(/[.,\s]/g, '')
      } else if (param === 'o') {
        this.preview.tarif.jasa_radiologi = rupiah
        this.formTarif.jasa_radiologi = value.replace(/[.,\s]/g, '')
      }
      this.countTarif()
    },
    countTarif () {
      const a = parseInt(this.formTarif.jasa_ba)
      const b = parseInt(this.formTarif.jasa_rs)
      const c = parseInt(this.formTarif.jasa_rs2)
      const d = parseInt(this.formTarif.jasa_dr)
      const e = parseInt(this.formTarif.jasa_umum)
      const f = parseInt(this.formTarif.jasa_anes_dr)
      const g = parseInt(this.formTarif.jasa_anes_umum)
      const h = parseInt(this.formTarif.jasa_perawat)
      const i = parseInt(this.formTarif.jasa_penataanestesi)
      const j = parseInt(this.formTarif.jasa_manajemenbp)
      const k = parseInt(this.formTarif.jasa_radiologi)
      const hasil = a + b + c + d + e + f + g + h + i + j + k
      const hasilStr = hasil.toString()
      //
      let numberString = hasilStr.replace(/[^,\d]/g, '').toString()
      let split = numberString.split(',')
      let sisa = split[0].length % 3
      let rupiah = split[0].substr(0, sisa)
      let ribuan = split[0].substr(sisa).match(/\d{3}/gi)
      if (ribuan) {
        let separator = sisa ? '.' : ''
        rupiah += separator + ribuan.join('.')
      }
      rupiah = split[1] !== undefined ? rupiah + ',' + split[1] : rupiah
      //
      this.preview.tarif.total_tarif = rupiah
      this.formTarif.total_tarif = hasilStr
      //
      if (this.selected.selectedReguler === '1') {
        this.preview.tarif.total_tarif_reguler = rupiah
        this.formTarif.total_tarif_reguler = hasilStr
      }
      if (this.selected.selectedJkn === '1') {
        this.preview.tarif.total_tarif_jkn = rupiah
        this.formTarif.total_tarif_jkn = hasilStr
      }
      if (this.selected.selectedExecutive === '1') {
        this.preview.tarif.total_tarif_executive = rupiah
        this.formTarif.total_tarif_executive = hasilStr
      }
    },
    equalityTarif ($event, param) {
      if (param === 'reg' && $event === '1') {
        this.formTarif.total_tarif_reguler = this.formTarif.total_tarif
        this.preview.tarif.total_tarif_reguler = this.preview.tarif.total_tarif
        this.preview.disableFieldReg = true
      } else if (param === 'reg' && $event === '0') {
        this.preview.disableFieldReg = false
      }
      //
      if (param === 'jkn' && $event === '1') {
        this.formTarif.total_tarif_jkn = this.formTarif.total_tarif
        this.preview.tarif.total_tarif_jkn = this.preview.tarif.total_tarif
        this.preview.disableFieldJkn = true
      } else if (param === 'jkn' && $event === '0') {
        this.preview.disableFieldJkn = false
      }
      //
      if (param === 'exc' && $event === '1') {
        this.formTarif.total_tarif_executive = this.formTarif.total_tarif
        this.preview.tarif.total_tarif_executive = this.preview.tarif.total_tarif
        this.preview.disableFieldExc = true
      } else if (param === 'exc' && $event === '0') {
        this.preview.disableFieldExc = false
      }
    },
    formClear () {
      this.formTarif.object = 'tarif'
      this.formTarif.tindakan_id = ''
      this.formTarif.tindakan_nama = ''
      this.formTarif.jasa_ba = ''
      this.formTarif.jasa_rs = ''
      this.formTarif.jasa_rs2 = ''
      this.formTarif.jasa_dr = ''
      this.formTarif.jasa_umum = ''
      this.formTarif.jasa_anes_dr = ''
      this.formTarif.jasa_anes_umum = ''
      this.formTarif.total_tarif = ''
      this.formTarif.total_tarif_jkn = ''
      this.formTarif.total_tarif_reguler = ''
      this.formTarif.total_tarif_executive = ''
      this.formTarif.created_by = jwtdecode(localStorage.getItem('token')).user.id.toString()
      this.formTarif.updated_by = jwtdecode(localStorage.getItem('token')).user.id.toString()
      //
      this.preview.tarif.jasa_ba = ''
      this.preview.tarif.jasa_rs = ''
      this.preview.tarif.jasa_rs2 = ''
      this.preview.tarif.jasa_dr = ''
      this.preview.tarif.jasa_umum = ''
      this.preview.tarif.jasa_anes_dr = ''
      this.preview.tarif.jasa_anes_umum = ''
      this.preview.tarif.total_tarif = ''
      this.preview.tarif.total_tarif_jkn = ''
      this.preview.tarif.total_tarif_reguler = ''
      this.preview.tarif.total_tarif_executive = ''
      this.selected.tindakan = []
      this.preview.modal_title = ''
      this.preview.message = ''
      this.preview.disableFieldReg = false
      this.preview.disableFieldJkn = false
      this.preview.disableFieldExc = false
      //
      this.selected.selectedReguler = '0'
      this.selected.selectedJkn = '0'
      this.selected.selectedExecutive = '0'
    }
  }
}
</script>
<style>
#modal-tambah-tarif-remun .custom-control-label, #modal-edit-tarif-remun .custom-control-label {
    margin-top: 0;
}
#modal-tambah-tarif-remun .multiselect__single, #modal-edit-tarif-remun .multiselect__single {
  font-size: 16px!important;
  font-weight: bold!important;
}
#modal-tambah-tarif-remun .multiselect__placeholder, #modal-edit-tarif-remun .multiselect__placeholder {
  font-size: 16px!important;
  font-weight: bold!important;
}
#modal-tambah-tarif-remun .multiselect__option, #modal-edit-tarif-remun .multiselect__option {
    font-size: 16px;
    font-weight: bold!important;
}
#modal-tambah-tarif-remun .multiselect__tags, #modal-edit-tarif-remun .multiselect__tags {
  padding: 5px 40px 0 8px!important;
  height: 38px!important;
  min-height: 38px!important;
}
#master-tarif-remun .select-tindakan .multiselect__input, .multiselect__single {
    line-height: 25px;
    font-weight: normal!important;
}
#master-tarif-remun .select-tindakan .custom-control-label {
    margin-top: 0;
}
#master-tarif-remun .select-tindakan .multiselect__single {
  font-size: 12px!important;
  font-weight: normal!important;
}
#master-tarif-remun .select-tindakan .multiselect__placeholder {
  font-size: 12px!important;
  font-weight: normal!important;
}
#master-tarif-remun .select-tindakan .multiselect__option {
  font-size: 12px;
  font-weight: normal!important;
}
#master-tarif-remun .select-tindakan .multiselect__tags {
  padding: 5px 40px 0 8px!important;
  height: 40px!important;
  min-height: 40px!important;
}
#master-tarif-remun .select-tindakan .multiselect__content-wrapper {
  font-size: 12px;
  font-weight: normal!important;
}
#modal-tambah-tarif-remun  .modal-body, #modal-edit-tarif-remun  .modal-body {
  background: #3399331b
}

#modal-tambah-tarif-remun  .modal-header, #modal-edit-tarif-remun  .modal-header {
  background: #3399333a;
}

#modal-tambah-tarif-remun  .modal-footer, #modal-edit-tarif-remun  .modal-footer {
  background: #3399333a;
}
#master-tarif-remun .page-link {
  font-size: 14px;
  padding-top: 5px;
  padding-bottom: 5px;
  margin-right: 10px!important;
}
#master-tarif-remun .custom-button-radius {
  border: 1px solid rgba(0, 0, 0, 0.112);
  border-radius: 5px!important;
}
#master-tarif-remun .table td {
    font-size: 12px;
    font-weight: 500;
    padding-top: 0px;
    padding-bottom: 0px;
}
#master-tarif-remun .table th {
    font-size: 13px;
    font-weight: 400;
}
#master-tarif-remun .cstm-select-pbf {
  font-size: 13px;
  height: 38px!important;
  border-radius: 5px;
}
#master-tarif-remun .cstm-srch-pbf {
  font-size: 13px;
  height: 38px!important;
  border-radius: 5px;
}
#master-tarif-remun .cstm-slct-pbf {
  font-size: 13px;
  height: 38px!important;
  border-radius: 5px;
}
.cstm-cntrl-tarif {
  background: white!important;
  color: black!important;
  border-radius: 5px!important;
  font-size: 24px!important;
  height: 38px!important;
  text-align: right!important;
}
#master-tarif-remun .pagination-sm .page-link {
  color: gray!important;
}
#master-tarif-remun .page-item.active .page-link {
  color: white!important;
}
#master-tarif-remun .page-item.active .page-link {
  color: white!important;
}
.custom-radius-add-tarif {
  border-radius: 5px;
  cursor: pointer;
}
.tarif-select-flex-left {
  width: 90%;
}
.tarif-select-flex-right {
  width: 10%;
}
.tarif-form-satuan-pbf-left-right {
  width: 40%;
}
.tarif-form-satuan-pbf-center {
  width: 15%;
}
.hr-tarif-custom {
  border-top: 1px solid rgba(0, 0, 0, 0.199)!important;
  margin: 0!important;
}
#master-tarif-remun .pagination-pbf .page-link {
    border: none;
    color: gray!important;
    margin-right: 15px;
}
#master-tarif-remun .pagination-pbf .page-item.active .page-link {
    background-color: transparent;
    border-color: transparent;
    color: #089bab!important;
    font-weight: bold;
}
#master-tarif-remun .mb-custom {
  margin-bottom: 6px;
}
#master-tarif-remun .font-custom {
  font-size: 12px;
}
#master-tarif-remun .tarif-cursor {
  cursor: pointer;
}
</style>
