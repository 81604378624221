import Api from '../axiosInstance'
const version = '/v1'
export default {
  findAll (condition) {
    return Api.connected.get(`${version}/sisrute/rujukan${condition}`)
  },
  refrences (param) {
    return Api.connected.get(`${version}/sisrute/referensi-${param}`)
  },
  refrencesWithParams ($event, obj, param) {
    return Api.connected.get(`${version}/sisrute/referensi-${obj}?${param}=${$event}`)
  },
  image_list (rujukan, jenis) {
    return Api.connected.get(`${version}/sisrute/images-list?NOMOR_RUJUKAN=${rujukan}&JENIS_LAMPIRAN_GAMBAR=${jenis}`)
  },
  image_view (rujukan, file, type, jenis, encode) {
    return Api.connected.get(`${version}/sisrute/images-view?NOMOR_RUJUKAN=${rujukan}&NAME_FILE=${file}&JENIS_LAMPIRAN_GAMBAR=${jenis}&TYPE=${encode}`)
  },
  postRujukan (data) {
    return Api.connected.post(`${version}/sisrute/rujukan`, data)
  },
  putRujukan (obj, nomor, data) {
    return Api.connected.put(`${version}/sisrute/${obj}?nomor=${nomor}`, data)
  },
  getDataAllSihepi (condition) {
    return Api.connected.get(`${version}/sihepi/get-data-tes${condition}`)
  },
  transferDataAllSihepi () {
    return Api.connected.post(`${version}/sihepi/transferdata`)
  },
  kirimDataAllSihepi () {
    return Api.connected.post(`${version}/sihepi/post-hasil-tes`)
  },
  dashboardTerkirim () {
    return Api.connected.get(`${version}/sihepi/get-dashboard-terkirim`)
  },
  cekFinger (nokartu, tanggal) {
    return Api.connected.get(`${version}/bpjs/vclaim/sep/finger?nokartu=${nokartu}&tanggal=${tanggal}`)
  },
  antreanPertanggal (tanggal) {
    return Api.connected.get(`${version}/bpjs/antrean/antrianpertanggal?tanggal=${tanggal}`)
  },
  getDataAntrian (nokartu) {
    return Api.connected.get(`${version}/transmedic/antrol/getdata?nokartu=${nokartu}`)
  },
  addTambahAntrian (data) {
    return Api.connected.post(`${version}/bpjs/antrean/tambahantrean2`, data)
  },
  sendTask (kodebooking) {
    return Api.connected.post(`${version}/bpjs/antrean/sendtask?kodebooking=${kodebooking}`)
  },
  getJadwalDokter (kdpoli, tanggal) {
    return Api.connected.get(`${version}/bpjs/antrean/jadwaldokter?kodepoli=${kdpoli}&tanggal=${tanggal}`)
  }
}
