<template>
  <div class="form-wizard-irj-offline pb-4">
    <b-container fluid>
      <div v-show="isLoading">
        <div id="show-overlay"></div>
        <Loader :style="{ opacity: .5 }" />
      </div>
      <b-row class="justify-content-center">
        <!-- <b-col cols="12" md="6" lg="4" xl="3" class="mt-3 mb-4 mb-lg-0">
          <div>
            <div class="wadah py-4 px-3 rounded p-lg-3 p-2 bg-review position-relative">
              <div :class="kuotaClass">
                <div class="position-absolute wadah-child py-4 d-flex flex-column justify-content-between align-items-center">
                <div>
                  <p class="h4 text-white text-center font-weight-bold txt-shdw-reservasi mb-0">Informasi Kuota</p>
                </div>
                <div class="px-3 w-100">
                  <div class="bg-white shadow-sm rounded mb-4" v-if="kuotaSeeGriya">
                    <div class="px-4 pt-3">
                      <p class="text-black font-weight-bold mb-0">{{ kuotaSeeDokter }}</p>
                    </div>
                    <hr>
                    <div class="px-4 pb-3">
                      <p class="text-black mb-1">Tanggal : <span class="font-weight-bold text-dark">{{viewTgl}}</span></p>
                      <p class="text-black mb-1">Total Kuota : <span class="font-weight-bold text-success">{{kuotaSeeGriya.jumlah_off}}</span></p>
                      <p class="text-black mb-1">Kuota Tersedia : <span class="font-weight-bold text-danger">{{kuotaSeeGriya.sisa}}</span></p>
                      <p class="text-black mb-0">Jam : <span class="font-weight-bold">{{kuotaSeeGriya.jam_mulai}} - {{kuotaSeeGriya.jam_akhir}}</span></p>
                    </div>
                  </div>
                  <div v-else>
                    <p class="h6 text-white text-center font-weight-bold txt-shdw-reservasi mb-2">Kuota Tidak Tersedia</p>
                  </div>
                </div>
                <div>
                  <b-button variant="danger" class="font-weight-bold" @click="kuotaClose"><i class="fas fa-times-circle"></i> Tutup</b-button>
                </div>
              </div>
              </div>
              <div class="head-calendar shadow bg-white rounded-custom text-center p-3 mb-4">
                <p class="text-dark mb-0"><i class="fas fa-calendar-check"></i> CEK KUOTA RESERVASI OFFLINE</p>
                <p class="small mb-0">Reservasi Hanya Untuk Hari Ini</p>
              </div>
              <div class="body-calendar-griya">
                <div class="shadow-sm mb-2">
                  <div class="d-flex flex-row">
                    <div class="w-50 mr-1">
                      <b-button variant="light" :class="['btn-block btn-lihat-kuota-a py-2', buttonCekIrj]" @click="valUnitKuota(1010101)"><span class="h6">UMUM <span v-if="iconCekKuota === 1010101"><i class="fas fa-check-circle text-success"></i></span></span></b-button>
                    </div>
                    <div class="w-50 ml-1">
                      <b-button variant="light" :class="['btn-block btn-lihat-kuota-a py-2', buttonCekGriya]" @click="valUnitKuota(1012701)"><span class="h6">EKSEKUTIF <span v-if="iconCekKuota === 1012701"><i class="fas fa-check-circle text-success"></i></span></span></b-button>
                    </div>
                  </div>
                </div>
                <div class="shadow mb-2">
                  <b-form-group class="mb-0">
                    <multiselect :show-labels="false" v-model="selectedRuanganKuota" :options="comboRuanganKuota" @input="valRuanganpoliKuota"
                      :limit="3" label="deskripsi" placeholder="Pilih Ruangan" :searchable="true">
                    </multiselect>
                  </b-form-group>
                </div>
                <div class="d-flex justify-content-center mb-2">
                  <template>
                    <flat-pickr :config="config" @on-change="lihatkuotaTest" v-model="flatPickrTglKuotaValue" placeholder="Pilih Tanggal " class="w-100 custom-flatpicker calender-small" />
                  </template>
                </div>
                <div class="shadow mb-4">
                  <b-form-group class="mb-0">
                    <multiselect :show-labels="false" v-model="selectedDokterKuota" :options="comboDokterKuota" @input="valDokterKuota" :limit="3"
                      label="deskripsi" placeholder="Pilih Dokter" :searchable="true">
                    </multiselect>
                  </b-form-group>
                </div>
              </div>
              <div class="bottom-calendar shadow bg-white rounded-custom p-3">
                <p class="small text-dark font-weight-bold mb-1">Cara Melihat Kuota :</p>
                <p class="small text-dark mb-0">Kilk Umum Atau Eksekutif <i class="fas fa-arrow-right"></i> Pilih Ruangan <i class="fas fa-arrow-right"></i> Pilih Tanggal <i class="fas fa-arrow-right"></i> Pilih Dokter</p>
              </div>
            </div>
          </div>
        </b-col> -->
        <b-col cols="12">
          <form-wizard @onComplete="onComplete">
            <tab-content title="TUJUAN" :selected="true" class="px-1">
              <div class="tujuan-wizard py-4 px-1 px-lg-3 my-4">
                <b-row class="align-items-center mb-4">
                  <b-col cols="12" lg="4" class="mb-4">
                    <b-form-group label="Instalasi :" class="mb-0">
                      <multiselect  :show-labels="false" v-model="selectedInstalasi" :options="comboInstalasi" @input="valInstalasi" :limit="3" label="deskripsi"
                        placeholder="Pilih Gedung" :searchable="true" :allow-empty="false">
                      </multiselect>
                    </b-form-group>
                  </b-col>
                  <b-col cols="12" lg="4" class="mb-4">
                    <b-form-group label="Poli :" class="mb-0">
                      <multiselect :show-labels="false" v-model="selectedUnit" :options="comboUnit" @input="valUnit" :limit="3" label="keterangan"
                        placeholder="Pilih Gedung" :searchable="true" :allow-empty="false">
                      </multiselect>
                    </b-form-group>
                  </b-col>
                  <b-col cols="12" lg="4" class="mb-4">
                    <b-form-group label="Sub Spesialis :" class="mb-0">
                      <multiselect :show-labels="false" v-model="selectedRuangan" :options="comboRuangan" @input="valRuanganpoli" :limit="3" label="keterangan"
                        placeholder="Pilih Ruangan Poli" :searchable="true" :allow-empty="false"
                        :class="hasError('ruangan') ? 'multiselect__tags-error' : ''">
                      </multiselect>
                    </b-form-group>
                  </b-col>
                  <b-col cols="12" lg="4" class="mb-4">
                    <b-form-group label="Dokter :" class="mb-0">
                      <multiselect :show-labels="false" v-model="selectedDokter" :options="comboDokter" @input="valDokter" :limit="3" label="nama"
                        placeholder="Pilih Dokter" :searchable="true" :allow-empty="false"
                        :class="hasError('dokter') ? 'multiselect__tags-error' : ''">
                      </multiselect>
                    </b-form-group>
                  </b-col>
                  <b-col cols="12" lg="4" class="mb-4">
                    <!-- <b-form-group label="Tanggal :" label-for="tanggal" class="mb-0">
                      <multiselect :show-labels="false" v-model="selectedTanggal" :options="comboTanggal" @input="valTanggal" :limit="3" label="tanggal"
                        placeholder="Pilih Tanggal" :searchable="true" :custom-label="tanggalWithHari" :allow-empty="false"
                        :class="hasError('tanggal_reservasi') ? 'multiselect__tags-error' : ''">
                      </multiselect>
                    </b-form-group> -->
                    <b-form-group label="Tanggal Kedatangan :" label-for="tanggal" class="mb-0">
                      <flat-pickr id="tglDtg" v-model="flatPickrTglReservasiValue" :config="configTglDatang" @on-change="lihatkuotaTanggalDatang" placeholder="Pilih Tanggal Kedatangan"
                      class="w-100 custom-flatpicker flatpickr-input calender-small" :class="hasError('tanggal_reservasi') ? 'flatpickr-input-error' : 'flatpickr-input-valid '">
                      </flat-pickr>
                    </b-form-group>
                  </b-col>
                  <b-col cols="12" lg="4">
                    <div class="shadow-sm text-center px-3 py-2 mt-2" :class="responselected.kuota >= '10' ? 'bg-kuota-bnyk' : 'bg-kuota-dkt'" v-if="responselected.kuota">
                      <p class="font-weight-bold text-uppercase mb-0">Tersisa {{ responselected.kuota }} Kuota</p>
                    </div>
                  </b-col>
                  <!-- <b-col cols="12" lg="6">
                    <b-form-group label="Jam Pelayanan:" class="mb-0">
                      <multiselect :show-labels="false" :disabled="multiSelectDisable" v-model="selectedShift" :options="comboShift" @input="valShift" :limit="3" label="deskripsi"
                        placeholder="Pilih Shift" :searchable="true"
                        :class="hasError('shift') ? 'multiselect__tags-error' : ''">
                      </multiselect>
                      <small :class="responselected.sisa !== 0 ? 'text-success font-weight-normal' : 'text-danger font-weight-normal'" v-if="responselected.jumlah_off">Tersisa {{ responselected.sisa }} kuota dari total {{ responselected.jumlah_off }} kuota tersedia </small>
                      <small class="text-danger font-weight-normal" v-else-if="responselected.sisa === 0">Tersisa {{ responselected.sisa }} kuota dari total {{ responselected.jumlah_off }} kuota tersedia  </small>
                    </b-form-group>
                  </b-col> -->
                </b-row>
                <div class="shadow-sm border bg-white rounded-custom p-3">
                  <p class="small text-dark font-weight-bold mb-1">Cara Memilih Kuota :</p>
                  <p class="small text-dark mb-0">Pilih Instalasi <i class="fas fa-arrow-right"></i> Pilih Unit <i class="fas fa-arrow-right"></i> Pilih Poli Tujuan <i class="fas fa-arrow-right"></i> Pilih Tanggal Kedatangan <i class="fas fa-arrow-right"></i> Pilih Dokter Tujuan</p>
                </div>
              </div>
            </tab-content>
            <tab-content title="DATA DIRI" class="px-3">
              <div class="datadiri-wizard mb-4 pb-3">
                <div class="mb-4 mt-4">
                  <div class="text-center">
                    <b-form-group class="mt-3">
                      <b-form-radio-group id="radio-group-1" v-model="formData.jenis_pasien" @change="onChangePasien" :options="jenisPasien"
                        name="radio-options"></b-form-radio-group>
                    </b-form-group>
                  </div>
                  <div class="d-flex flex-row justify-content-center" v-if="formData.jenis_pasien === '1'">
                    <div>
                      <b-form-group class="mb-0">
                        <b-form-input v-model="norm" v-on:keyup.enter="getNorm"  :state="nameState" class="bg-white border shadow-sm form-control-utd-cek utd-form-input-cstm"
                          placeholder="Nomor Rekam Medis..." id="cekData" type="search"></b-form-input>
                      </b-form-group>
                    </div>
                    <div>
                      <b-button variant="primary" class="utd-btn-cari" @click="getNorm">Cek</b-button>
                    </div>
                  </div>
                </div>
                <div>
                  <b-row class="justify-content-center mt-3">
                    <b-col cols="12" lg="6">
                      <b-form-group label="Jenis Identitas :" class="mb-4">
                        <multiselect v-model="selectedJenisIdentitas" :show-labels="false" :limit="3" @input="valJenisIdentitas"
                          label="name" placeholder="Jenis Identitas" :options="comboJenisIdentitas" :searchable="true"
                          :class="hasError('jenis') ? 'multiselect__tags-error' : ''">
                        </multiselect>
                      </b-form-group>
                      <b-form-group label="Nomor Identitas :" class="mb-4">
                        <b-form-input class="bg-white" id="nomor" type="text" v-model="formData.nomor" @input="testBpjs" placeholder="Nomor NIK/SIM/Passport"
                          :class="hasError('nomor') ? 'is-invalid' : ''"></b-form-input>
                      </b-form-group>
                      <b-form-group label="Nama Lengkap :" class="mb-4">
                        <b-form-input class="bg-white" id="nama" type="text" v-model="formData.nama" placeholder="Nama"
                          :class="hasError('nama') ? 'is-invalid' : ''"></b-form-input>
                      </b-form-group>
                      <b-form-group label="Jenis Kelamin :" class="mb-4">
                        <multiselect v-model="selectedJenisKelamin" :show-labels="false" :limit="3" @input="valJenisKelamin"
                          label="name" placeholder="Jenis Kelamin" :options="comboJenisKelamin" :searchable="true"
                          :class="hasError('jenis_kelamin') ? 'multiselect__tags-error' : ''">
                        </multiselect>
                      </b-form-group>
                      <b-form-group label="Tempat Lahir :" class="mb-4">
                        <multiselect v-model="selectedTempatLahir" :show-labels="false" :limit="3" @input="valTempatLahir"
                          label="deskripsi" placeholder="Tempat Lahir" :options="comboTempatLahir" :searchable="true"
                          :class="hasError('tempat_lahir') ? 'multiselect__tags-error' : ''">
                        </multiselect>
                      </b-form-group>
                      <b-form-group label="Tanggal Lahir">
                        <template>
                          <flat-pickr v-model="tglLahir" @on-change="valTglLahir" :config="configTglLahir"
                            placeholder="Pilih Tanggal " class="w-100 custom-flatpicker calender-small"
                            :class="hasError('tanggal_lahir') ? 'flatpickr-input-error' : ''">
                          </flat-pickr>
                        </template>
                      </b-form-group>
                      <b-form-group label="Usia:" label-for="tanggal" class="mb-4">
                        <b-form-input readonly id="usia" type="text" v-model="formData.usia" placeholder="Usia Saat Ini"
                          :class="hasError('usia') ? 'is-invalid' : ''"></b-form-input>
                      </b-form-group>
                      <b-form-group label="Agama :" class="mb-4">
                        <multiselect v-model="selectedAgama" :show-labels="false" :limit="3" @input="valAgama" label="deskripsi"
                          placeholder="Agama" :options="comboAgama" :searchable="true"
                          :class="hasError('agama') ? 'multiselect__tags-error' : ''">
                        </multiselect>
                      </b-form-group>
                      <b-form-group label="Pendidikan :" class="mb-4">
                        <multiselect v-model="selectedPendidikan" :show-labels="false" :limit="3" @input="valPendidikan"
                          label="deskripsi" placeholder="Pendidikan" :options="comboPendidikan" :searchable="true"
                          :class="hasError('pendidikan') ? 'multiselect__tags-error' : ''">
                        </multiselect>
                      </b-form-group>
                      <b-form-group label="Pekerjaan:" label-for="tanggal" class="mb-4">
                        <multiselect v-model="selectedPekerjaan" :show-labels="false" :limit="3" @input="valPekerjaan"
                          label="deskripsi" placeholder="Pekerjaan" :options="comboPekerjaan" :searchable="true"
                          :class="hasError('pekerjaan') ? 'multiselect__tags-error' : ''">
                        </multiselect>
                      </b-form-group>
                    </b-col>
                    <b-col cols="12" lg="6">
                      <b-form-group label="Warga Negara:" label-for="tanggal" class="mb-4">
                        <multiselect v-model="selectedNegara" :show-labels="false" :limit="3" @input="valNegara" label="deskripsi"
                          placeholder="Kewarganegaraan (Country)" :options="comboNegara" :searchable="true"
                          :class="hasError('kewarganegaraan') ? 'multiselect__tags-error' : ''">
                        </multiselect>
                      </b-form-group>
                      <b-form-group label="Provinsi :" class="mb-4">
                        <multiselect v-model="selectedProvinsi" :show-labels="false" :limit="3" @input="getkabupaten"
                          label="deskripsi" placeholder="Provinsi" :options="comboProvinsi" :searchable="true"
                          :class="hasError('wilayah') ? 'multiselect__tags-error' : ''">
                        </multiselect>
                      </b-form-group>
                      <b-form-group label="Kota:" label-for="tanggal" class="mb-4">
                        <multiselect v-model="selectedKabupaten" :show-labels="false" :limit="3" @input="getkecamatan"
                          label="deskripsi" placeholder="Kabupaten/Kota" :options="comboKabupaten" :searchable="true"
                          :class="hasError('wilayah') ? 'multiselect__tags-error' : ''">
                        </multiselect>
                      </b-form-group>
                      <b-form-group label="Kecamatan :" class="mb-4">
                        <multiselect v-model="selectedKecamatan" :show-labels="false" :limit="3" @input="getkelurahan"
                          label="deskripsi" placeholder="Kecamatan" :options="comboKecamatan" :searchable="true"
                          :class="hasError('wilayah') ? 'multiselect__tags-error' : ''">
                        </multiselect>
                      </b-form-group>
                      <b-form-group label="Kelurahan:" label-for="tanggal" class="mb-4">
                        <multiselect v-model="selectedKelurahan" :show-labels="false" :limit="3" @input="valWilayah"
                          label="deskripsi" placeholder="Kabupaten/Kota" :options="comboKelurahan" :searchable="true"
                          :class="hasError('wilayah') ? 'multiselect__tags-error' : ''">
                        </multiselect>
                      </b-form-group>
                      <div class="mb-4">
                        <b-row>
                          <b-col cols="12" lg="4">
                            <b-form-group label="RT :" class="mb-4">
                              <b-form-input class="bg-white" id="nama" type="text" v-model="formData.rt" placeholder="RT"
                                :class="hasError('rt') ? 'is-invalid' : ''"></b-form-input>
                            </b-form-group>
                          </b-col>
                          <b-col cols="12" lg="4">
                            <b-form-group label="RW :" class="mb-4">
                              <b-form-input class="bg-white" id="nama" type="text" v-model="formData.rw" placeholder="RW"
                                :class="hasError('rw') ? 'is-invalid' : ''"></b-form-input>
                            </b-form-group>
                          </b-col>
                          <b-col cols="12" lg="4">
                            <b-form-group label="Kodepos :" class="mb-0">
                              <b-form-input class="bg-white" id="nama" type="text" v-model="formData.kodepos" placeholder="Kodepos"
                                :class="hasError('kodepos') ? 'is-invalid' : ''"></b-form-input>
                            </b-form-group>
                          </b-col>
                        </b-row>
                      </div>
                      <b-form-group label="Alamat Tinggal :" class="mb-4">
                        <b-form-textarea class="bg-white" id="textarea" v-model="formData.alamat" placeholder="Alamat" rows="3"
                          :class="hasError('alamat') ? 'is-invalid' : ''"></b-form-textarea>
                      </b-form-group>
                      <b-form-group label="Nomor Whatsapp :" class="mb-4">
                        <b-form-input class="bg-white" id="whatsapp" v-model="formData.nomor_kontak" type="text" placeholder="Handpone"
                          :class="hasError('nomor_kontak') ? 'is-invalid' : ''"></b-form-input>
                      </b-form-group>
                      <b-form-group label="Email :" class="mb-4">
                        <b-form-input class="bg-white" id="email" type="email" v-model="formData.email" placeholder="Email"
                          :class="hasError('email') ? 'is-invalid' : ''"></b-form-input>
                      </b-form-group>
                    </b-col>
                  </b-row>
                </div>
              </div>
            </tab-content>
            <tab-content title="CARA BAYAR" class="px-3">
              <div class="cara-bayar-wizard py-2 my-4">
                <div class="shadow-sm bg-white border rounded-custom p-3 mb-4">
                  <div class="post-ranap-description mb-3">
                    <p class="text-dark font-weight-bold text-center mb-0">* Jika anda merupakan pasien yang melanjutkan kontrol dari Rawat Inap, maka pilih opsi ke-2</p>
                  </div>
                  <b-row class="justify-content-center">
                    <b-col cols="12" lg="8">
                      <b-form-group class="justify-content-center text-center mb-0" v-slot="{ ariaDescribedby }">
                        <b-form-radio-group
                          id="radio-group-post-ranap"
                          v-model="selectedRanap"
                          :options="optionsRanap"
                          :aria-describedby="ariaDescribedby"
                          name="radio-options"
                          @change="onrawatcheck"
                        ></b-form-radio-group>
                      </b-form-group>
                    </b-col>
                  </b-row>
                </div>
                <b-row class="align-items-end">
                  <b-col cols="12" lg="5" class="mb-4 mb-lg-0">
                    <b-form-group label="Pilih Jenis Bayar" class="mb-0">
                      <multiselect v-model="selectedCaraBayar" :show-labels="false" :limit="3" label="deskripsi"
                        placeholder="Cara Bayar" :options="comboCaraBayar" @input="valCaraBayar" :searchable="true"
                        :class="hasError('carabayar') ? 'multiselect__tags-error' : ''">
                      </multiselect>
                    </b-form-group>
                  </b-col>
                  <b-col class="mb-4 mb-lg-0" cols="12" lg="5" v-if="formData.carabayar === '2' || formData.carabayar === 2">
                    <b-form-group label="Nomor KTP/NIK" class="mb-0" v-if="validasiKartuBPJS === 1">
                      <b-form-input id="nonik" type="search" v-model="nomorNIK" placeholder="Masukan Nomor KTP/NIK"></b-form-input>
                    </b-form-group>
                    <b-form-group label="Masukan Nomor Kartu BPJS" class="mb-0" v-else-if="validasiKartuBPJS === 2">
                      <b-form-input id="nobpjs" type="search" v-model="nomorBPJS" placeholder="Masukan Nomor Kartu BPJS"></b-form-input>
                    </b-form-group>
                  </b-col>
                  <b-col cols="12" lg="2" v-if="selectedRanap !== '2'">
                    <div v-if="formData.carabayar === '2' || formData.carabayar === 2">
                      <b-button variant="primary" class="btn-block w-100 btn-validasi-bpjs shadow-sm border" @click="getnoBpjs">Validasi</b-button>
                    </div>
                  </b-col>
                </b-row>
                <div class="mt-4">
                  <div class="shadow-sm border bg-white rounded-custom p-3">
                    <p class="small text-dark font-weight-bold mb-1">Informasi Untuk Pembayaran  BPJS :</p>
                    <p class="small text-dark mb-1"> 1. Jika Anda Sudah Berumur 17 Tahun Dan Mempunyai KTP, Maka Harus Memasukan Nomor NIK Sebagai Validasi BPJS.</p>
                    <p class="small text-dark mb-3"> 2. Jika Anda Berumur Dibawah 17 Tahun Dan Belum Mempunyai KTP, Maka Masukan Nomor BPJS Anda.</p>
                    <p class="small text-dark font-weight-bold mb-1">Cara Validasi :</p>
                    <p class="small text-dark mb-0">Pastikan Nomor KTP Atau Nomor BPJS Sudah Terisi <i class="fas fa-arrow-right"></i> Klik Validasi <i class="fas fa-arrow-right"></i> Pilih Rujukan</p>
                  </div>
                </div>
              </div>
            </tab-content>
            <tab-content title="REVIEW" class="px-lg-3">
              <div class="rounded">
                <div class="d-none d-lg-block">
                  <div class="d-flex justify-content-center mb-3">
                  <div class="title bg-white rounded shadow-sm">
                    <p class="h6 text-dark px-4 py-2 mb-0">Review Data Pasien</p>
                  </div>
                </div>
                </div>
                <div class="bg-white rounded shadow-sm p-4" v-if="formData.nik !== '' && formData.nama !== ''">
                  <b-row>
                    <b-col cols="12" lg="6">
                      <b-row class="mb-2">
                        <b-col cols="3" lg="4">
                          <p class="text-dark mb-0">Nomor Identitas</p>
                        </b-col>
                        <b-col cols="1" lg="1">
                          <p class="text-dark mb-0">:</p>
                        </b-col>
                        <b-col>
                          <p class="text-dark mb-0">{{formData.nomor}}</p>
                        </b-col>
                      </b-row>
                      <b-row class="mb-2">
                        <b-col cols="3" lg="4">
                          <p class="text-dark mb-0">Nama Lengkap</p>
                        </b-col>
                        <b-col cols="1" lg="1">
                          <p class="text-dark mb-0">:</p>
                        </b-col>
                        <b-col>
                          <p class="text-dark mb-0">{{formData.nama}}</p>
                        </b-col>
                      </b-row>
                       <b-row class="mb-2">
                        <b-col cols="3" lg="4">
                          <p class="text-dark mb-0">Jenis Kelamin</p>
                        </b-col>
                        <b-col cols="1" lg="1">
                          <p class="text-dark mb-0">:</p>
                        </b-col>
                        <b-col>
                          <p class="text-dark mb-0">{{selectedJenisKelamin.name}}</p>
                        </b-col>
                      </b-row>
                      <b-row class="mb-2">
                        <b-col cols="3" lg="4">
                          <p class="text-dark mb-0">Tanggal Lahir</p>
                        </b-col>
                        <b-col cols="1" lg="1">
                          <p class="text-dark mb-0">:</p>
                        </b-col>
                        <b-col>
                          <p class="text-dark mb-0">{{tglLahirCard}}</p>
                        </b-col>
                      </b-row>
                      <b-row class="mb-2">
                        <b-col cols="3" lg="4">
                          <p class="text-dark mb-0">Alamat Tinggal</p>
                        </b-col>
                        <b-col cols="1" lg="1">
                          <p class="text-dark mb-0">:</p>
                        </b-col>
                        <b-col>
                          <p class="text-dark mb-0">{{formData.alamat}}</p>
                        </b-col>
                      </b-row>
                      <b-row>
                        <b-col cols="3" lg="4">
                          <p class="text-dark mb-0">Tempat Lahir</p>
                        </b-col>
                        <b-col cols="1" lg="1">
                          <p class="text-dark mb-0">:</p>
                        </b-col>
                        <b-col>
                          <p class="text-dark mb-0">{{selectedTempatLahir.deskripsi}}</p>
                        </b-col>
                      </b-row>
                    </b-col>
                    <b-col cols="12" lg="6">
                      <b-row class="mb-2">
                        <b-col cols="3" lg="4">
                          <p class="text-dark mb-0">Nomor Handpone</p>
                        </b-col>
                        <b-col cols="1" lg="1">
                          <p class="text-dark mb-0">:</p>
                        </b-col>
                        <b-col>
                          <p class="text-dark mb-0">{{formData.nomor_kontak}}</p>
                        </b-col>
                      </b-row>
                      <b-row class="mb-2">
                        <b-col cols="3" lg="4">
                          <p class="text-dark mb-0">Email</p>
                        </b-col>
                        <b-col cols="1" lg="1">
                          <p class="text-dark mb-0">:</p>
                        </b-col>
                        <b-col>
                          <p class="text-dark mb-0" v-if="formData.email !== ''">{{formData.email}}</p>
                          <p class="text-dark mb-0" v-else>-</p>
                        </b-col>
                      </b-row>
                      <b-row class="mb-2">
                        <b-col cols="3" lg="4">
                          <p class="text-dark mb-0">Gedung Instalasi</p>
                        </b-col>
                        <b-col cols="1" lg="1">
                          <p class="text-dark mb-0">:</p>
                        </b-col>
                        <b-col>
                          <p class="text-dark mb-0">{{ selectedInstalasi.deskripsi }}</p>
                        </b-col>
                      </b-row>
                      <b-row class="mb-2">
                        <b-col cols="3" lg="4">
                          <p class="text-dark mb-0">Ruangan</p>
                        </b-col>
                        <b-col cols="1" lg="1">
                          <p class="text-dark mb-0">:</p>
                        </b-col>
                        <b-col>
                          <p class="text-dark mb-0">{{ reviewRuang.name }}</p>
                        </b-col>
                      </b-row>
                      <b-row class="mb-2">
                        <b-col cols="3" lg="4">
                          <p class="text-dark mb-0">Tanggal Reservasi</p>
                        </b-col>
                        <b-col cols="1" lg="1">
                          <p class="text-dark mb-0">:</p>
                        </b-col>
                        <b-col>
                          <p class="text-dark mb-0">{{formData.tanggal_reservasi}}</p>
                        </b-col>
                      </b-row>
                      <b-row>
                        <b-col cols="3" lg="4">
                          <p class="text-dark mb-0">Jam Kedatangan</p>
                        </b-col>
                        <b-col cols="1" lg="1">
                          <p class="text-dark mb-0">:</p>
                        </b-col>
                        <b-col>
                          <p class="text-dark mb-0">{{formData.shift}}</p>
                        </b-col>
                      </b-row>
                    </b-col>
                  </b-row>
                </div>
                <div v-else>
                  <p class="text-white-50 text-center mb-0">Data Belum Lengkap</p>
                </div>
              </div>
            </tab-content>
          </form-wizard>
        </b-col>
      </b-row>
      <!-- <pre>{{ formData }}</pre> -->
      <!-- <pre>{{ configTglDatang.enable }}</pre> -->
      <!-- <pre>{{ arrComboTgl }}</pre> -->
    </b-container>
    <!-- <b-modal ref="modal-sukses-norm" size="md" title="Informasi" ok-title="Oke" :ok-only="true"
      :hide-header=true centered>
      <div class="text-center px-lg-4 px-2">
        <lottie :options="defaultOptions" :autoPlay="true" :width="250" :height="250" />
        <p class="h6 text-success font-weight-bold text-uppercase mb-0">{{respon}}</p>
      </div>
    </b-modal> -->
    <b-modal ref="modal-sukses-qrcode" size="md" title="Informasi" ok-title="Oke" cancel-title="Cetak" cancel-variant="warning" @cancel="print" @ok="refreshpage" :no-close-on-backdrop="true" :static="true" :hide-header="true" centered>
      <div class="text-center px-2 py-5">
        <div class="mb-2">
          <p class="h4 text-success font-weight-bold mb-1"><i class="fas fa-check-circle"></i> Berhasil</p>
          <p class="text-secondary text-capitalize mb-0"> {{respon}}</p>
        </div>
        <!-- <div class="mb-2">
          <p class="h3 text-dark font-weight-bold mb-0">Nomor Reservasi</p>
        </div> -->
        <div ref="captureMe">
          <template>
            <qrcode-vue :value="qrcode" :size="size" level="H" />
          </template>
        </div>
        <div class="mt-1">
          <p class="h3 text-dark font-weight-bold mb-0" v-if="resKet">{{resKet[0].nomor_reservasi}}</p>
        </div>
         <div class="mt-3">
          <p class="text-dark font-weight-normal text-capitalize mb-0" v-if="resKet">Estimasi : {{resKet[0].estimasi}}</p>
          <p class="text-dark font-weight-bold text-capitalize mb-0" v-if="resKet">Nomor Antrian : {{resKet[0].antrian}}</p>
        </div>
        <!-- <div class="mt-1">
          <p class="h4 text-dark font-weight-bold mb-0">{{qrcode}}</p>
        </div>
         <div class="mt-3">
          <p class="h6 text-dark font-weight-normal mb-0">{{resKet}}</p>
        </div> -->
      </div>
      <div id="printCoba" class="cetak-bukti" style="display: none;">
        <div class="page" style="border: none; width: 110mm; height: 20mm; margin: 0px;">
          <div class="page-area" style="font-family: Arial, Helvetica, sans-serif; border: none;">
            <table class="padding-md font-arial text-general" style="padding-top: 10px; padding-bottom: 20px; border-top: none; font-size: 3mm; width: 100%;">
              <tbody style="padding-top: 10px; text-align: center; color: black; text-transform: capitalize;">
                <tr>
                  <td width="100%"><span style="font-size: 1.5rem; font-weight: bold; color: green;">Berhasil</span></td>
                </tr>
                <tr>
                  <td width="100%"><span style="font-size: 1rem;">{{respon}}</span></td>
                </tr>
                <tr>
                  <td width="100%">
                    <div>
                      <b-img :src="output" fluid alt="Responsive image"></b-img>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td width="100%"><span style="font-size: 1.5rem; font-weight: bold;" v-if="resKet">{{resKet[0].nomor_reservasi}}</span></td>
                </tr>
                <tr>
                  <td width="100%"><span style="font-size: 1rem;" v-if="resKet">Estimasi : {{resKet[0].estimasi}}</span></td>
                </tr>
                <tr>
                  <td width="100%"><span style="font-size: 1rem; font-weight: bold;" v-if="resKet">Nomor Antrian : {{resKet[0].antrian}}</span></td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </b-modal>
    <b-modal ref="modal-gagal-norm" size="md" title="Informasi" ok-title="Saya Mengerti" :ok-only=true
      :hide-header=true centered>
      <div class="text-center">
        <lottie :options="defaultOptions2" :autoPlay="true" :width="250" :height="250" />
        <p class="h6 font-weight-bold text-capitalize text-danger">{{error}}</p>
      </div>
    </b-modal>
    <b-modal ref="modal-gagal-normValid" size="md" title="Informasi" ok-title="Saya Mengerti" :ok-only=true
      :hide-header=true centered>
      <div class="text-center">
        <lottie :options="defaultOptions2" :autoPlay="true" :width="250" :height="250" />
        <p class="h6 font-weight-bold text-capitalize text-danger">{{error}}</p>
      </div>
    </b-modal>
    <b-modal ref="modal-gagal-kirim-2" size="md" title="Informasi" ok-title="Saya Mengerti" :ok-only=true
      :hide-header=true centered>
      <div class="text-center">
        <lottie :options="defaultOptions2" :autoPlay="true" :width="250" :height="250" />
        <p class="h6 text-danger">{{error}}</p>
      </div>
    </b-modal>
    <b-modal ref="modal-bpjs" size="lg" title="Riwayat Pasien BPJS" ok-title="Saya Mengerti" :ok-only=true
      :hide-header=true centered>
      <div class="shadow-sm border mb-4">
        <div class="mb-4">
          <div class="bg-primary px-3 py-2">
            <p class="mb-0">Detail Peserta</p>
          </div>
          <div class="p-3">
            <b-row>
              <b-col>
                <div>
                  <b-row class="mb-2">
                    <b-col cols="4">
                      <p class="text-dark mb-0">Nama Peserta</p>
                    </b-col>
                    <b-col cols="1">
                      <p class="text-dark mb-0">:</p>
                    </b-col>
                    <b-col>
                      <p class="text-dark font-weight-bold mb-0">{{ pesertaBPJS.nama }}</p>
                    </b-col>
                  </b-row>
                  <b-row class="mb-2">
                    <b-col cols="4">
                      <p class="text-dark mb-0">Nomor Induk Kependudukan</p>
                    </b-col>
                    <b-col cols="1">
                      <p class="text-dark mb-0">:</p>
                    </b-col>
                    <b-col>
                      <p class="text-dark font-weight-bold mb-0">{{ pesertaBPJS.nik }}</p>
                    </b-col>
                  </b-row>
                  <b-row class="mb-0">
                    <b-col cols="4">
                      <p class="text-dark mb-0">Nomor Kartu</p>
                    </b-col>
                    <b-col cols="1">
                      <p class="text-dark mb-0">:</p>
                    </b-col>
                    <b-col>
                      <p class="text-dark font-weight-bold mb-0">{{ pesertaBPJS.noKartu }}</p>
                    </b-col>
                  </b-row>
                </div>
              </b-col>
            </b-row>
          </div>
        </div>
      </div>
      <div class="shadow-sm border">
        <div class="mb-4">
          <div class="bg-primary px-3 py-2">
            <p class="mb-0">Riwayat Rujukan</p>
          </div>
          <div v-for="(r, index) in rujukanBPJS.rujukan" :key="r.noKunjungan">
            <b-row>
              <b-col>
                <div class="p-3">
                  <b-row class="mb-2">
                    <b-col cols="4">
                      <p class="text-dark mb-0">Nomor Kunjungan</p>
                    </b-col>
                    <b-col cols="1">
                      <p class="text-dark mb-0">:</p>
                    </b-col>
                    <b-col>
                      <p class="text-dark font-weight-bold mb-0">{{ r.noKunjungan }}</p>
                    </b-col>
                  </b-row>
                  <b-row class="mb-2">
                    <b-col cols="4">
                      <p class="text-dark mb-0">Prov Perujuk</p>
                    </b-col>
                    <b-col cols="1">
                      <p class="text-dark mb-0">:</p>
                    </b-col>
                    <b-col>
                      <p class="text-dark font-weight-bold mb-0">{{ r.provPerujuk.nama }}</p>
                    </b-col>
                  </b-row>
                  <b-row class="mb-2">
                    <b-col cols="4">
                      <p class="text-dark mb-0">Pelayanan</p>
                    </b-col>
                    <b-col cols="1">
                      <p class="text-dark mb-0">:</p>
                    </b-col>
                    <b-col>
                      <p class="text-dark font-weight-bold mb-0">{{ r.pelayanan.nama }}</p>
                    </b-col>
                  </b-row>
                  <b-row class="mb-2">
                    <b-col cols="4">
                      <p class="text-dark mb-0">Poli Rujukan</p>
                    </b-col>
                    <b-col cols="1">
                      <p class="text-dark mb-0">:</p>
                    </b-col>
                    <b-col>
                      <p class="text-dark font-weight-bold mb-0">{{ r.poliRujukan.nama }}</p>
                    </b-col>
                  </b-row>
                  <b-row class="mb-2">
                    <b-col cols="4">
                      <p class="text-dark mb-0">Diagnosa</p>
                    </b-col>
                    <b-col cols="1">
                      <p class="text-dark mb-0">:</p>
                    </b-col>
                    <b-col>
                      <p class="text-dark font-weight-bold mb-0">{{ r.diagnosa.kode }}</p>
                    </b-col>
                  </b-row>
                  <div class="text-right">
                    <b-button variant="primary" @click="testbtn(index)">Pilih Rujukan</b-button>
                  </div>
                </div>
              </b-col>
            </b-row>
            <hr>
          </div>
        </div>
      </div>
    </b-modal>
  </div>
</template>
<script>
import { xray } from '../../config/pluginInit'
import { FormWizard, TabContent, ValidationHelper } from 'vue-step-wizard'
import 'vue-step-wizard/dist/vue-step-wizard.css'
import Loader from '../../components/xray/loader/Loader'
import Online from '../../services/reservasi_offline'
import Service from '../../services/fatmahost/index'
import Reservasi from '../../services/fatmahost/reservasi/index.ts'
import Multiselect from 'vue-multiselect'
import Lottie from 'vue-lottie'
import animationLogo from '../../assets/lottie/checked.json'
import animationLogo2 from '../../assets/lottie/failed.json'
import QrcodeVue from 'qrcode.vue'
import flatPickr from 'vue-flatpickr-component'
import { Indonesian as IndonesianLocale } from 'flatpickr/dist/l10n/id.js'
import 'flatpickr/dist/flatpickr.css'
import { required, numeric } from 'vuelidate/lib/validators'
import moment from 'moment'
// import Token from '../../services/token.js'
import Vue from 'vue'
import VueHtml2Canvas from 'vue-html2canvas'
Vue.use(VueHtml2Canvas)

export default {
  name: 'ReservasiOffline',
  components: {
    FormWizard,
    TabContent,
    Loader,
    Multiselect,
    QrcodeVue,
    Lottie,
    flatPickr
  },
  data () {
    return {
      iconCekKuota: null,
      buttonCekGriya: '',
      buttonCekIrj: '',
      isLoading: false,
      jenispasien: 1,
      viewTgl: '',
      formData: {
        object: 'rjgh',
        jenis: '',
        nomor: '',
        norm: 0,
        nama: '',
        tempat_lahir: null,
        tanggal_lahir: '',
        usia: '',
        jenis_kelamin: '',
        jenis_pasien: '1',
        agama: null,
        nomor_kontak: '',
        email: '',
        alamat: '',
        rt: '',
        rw: '',
        kodepos: '',
        kewarganegaraan: null,
        pendidikan: null,
        pekerjaan: null,
        jenis_reservasi: 2,
        tanggal_reservasi: '',
        ruangan: null,
        dokter: '',
        shift: '',
        menit_layanan: '',
        carabayar: '',
        kode_dokter_bpjs: null,
        wilayah: null,
        status: '1',
        created_in: xray.currentUser().id,
        peserta: {
          no_kartu: null,
          response: {
            nik: null,
            nama: null,
            pisa: null,
            sex: null,
            mr: {
              noMR: null,
              noTelepon: null
            },
            tglLahir: null,
            tglCetakKartu: null,
            tglTAT: null,
            tglTMT: null,
            statusPeserta: {
              kode: null,
              keterangan: null
            },
            provUmum: {
              kdProvider: null,
              nmProvider: null
            },
            jenisPeserta: {
              kode: null,
              keterangan: null
            },
            hakKelas: {
              kode: null,
              keterangan: null
            },
            umur: {
              umurSekarang: null,
              umurSaatPelayanan: null
            },
            informasi: {
              dinsos: null,
              prolanisPRB: null,
              noSKTM: null
            },
            cob: {
              noAsuransi: null,
              nmAsuransi: null,
              tglTMT: null,
              tglTAT: null
            }
          }
        },
        rujukan: {
          no_rujukan: '',
          response: {
            asalFaskes: null,
            rujukan: [
              {
                noKunjungan: null,
                tglKunjungan: null,
                provPerujuk: {
                  kode: null,
                  nama: null
                },
                peserta: {
                  noKartu: null,
                  nik: null,
                  nama: null,
                  pisa: null,
                  sex: null,
                  mr: {
                    noMR: null,
                    noTelepon: null
                  },
                  tglLahir: null,
                  tglCetakKartu: null,
                  tglTAT: null,
                  tglTMT: null,
                  statusPeserta: {
                    kode: null,
                    keterangan: null
                  },
                  provUmum: {
                    kdProvider: null,
                    nmProvider: null
                  },
                  jenisPeserta: {
                    kode: null,
                    keterangan: null
                  },
                  hakKelas: {
                    kode: null,
                    keterangan: null
                  },
                  umur: {
                    umurSekarang: null,
                    umurSaatPelayanan: null
                  },
                  informasi: {
                    dinsos: null,
                    prolanisPRB: null,
                    noSKTM: null
                  },
                  cob: {
                    noAsuransi: null,
                    nmAsuransi: null,
                    tglTMT: null,
                    tglTAT: null
                  }
                },
                diagnosa: {
                  kode: null,
                  nama: null
                },
                keluhan: null,
                poliRujukan: {
                  kode: null,
                  nama: null
                },
                pelayanan: {
                  kode: null,
                  nama: null
                }
              }
            ]
          }
        }
      },
      validasiKartuBPJS: '',
      statusIdentitas: 0,
      statusUmur: 0,
      tglpelayanan: '',
      tglLahir: '',
      selectedRanap: '1',
      optionsRanap: [
        { text: 'Bukan Lanjutan Dari Rawat Inap', value: '1' },
        { text: 'Lanjutan Kontrol Dari Rawat Inap', value: '2' }
      ],
      validationRules: [
        { tanggal_reservasi: { required }, ruangan: { required }, dokter: { required }, shift: { required } },
        { jenis: { required }, nomor: { required, numeric }, nama: { required }, tempat_lahir: { required }, tanggal_lahir: { required }, wilayah: { required }, usia: { required }, agama: { required }, pekerjaan: { required }, kewarganegaraan: { required }, pendidikan: { required }, jenis_kelamin: { required }, alamat: { required }, rt: { required }, rw: { required }, nomor_kontak: { required } },
        { carabayar: { required } }
      ],
      selectedTempatLahir: [],
      selectedAgama: [],
      selectedPendidikan: [],
      selectedPekerjaan: [],
      selectedNegara: [],
      selectedProvinsi: [],
      selectedKabupaten: [],
      selectedKecamatan: [],
      selectedKelurahan: [],
      selectedJenisKelamin: [],
      selectedJenisIdentitas: [],
      selectedInstalasiKuota: [],
      selectedUnitKuota: [],
      selectedRuanganKuota: [],
      selectedDokterKuota: [],
      selectedInstalasi: [],
      selectedUnit: [],
      selectedRuangan: [],
      selectedTanggal: [],
      selectedDokter: [],
      selectedShift: [],
      selectedSkrining: [],
      selectedCaraBayar: [],
      selectedMember: [],
      comboTempatLahir: [],
      comboAgama: [],
      comboPendidikan: [],
      comboPekerjaan: [],
      comboNegara: [],
      comboProvinsi: [],
      comboKabupaten: [],
      comboKecamatan: [],
      comboKelurahan: [],
      comboUnitKuota: [],
      comboRuanganKuota: [],
      comboDokterKuota: [],
      comboInstalasi: [
        {
          id: '1010101',
          deskripsi: 'Instalasi Rawat Jalan'
        },
        {
          id: '1011001',
          deskripsi: 'Instalasi Rehabilitasi Medik'
        },
        {
          id: '1012701',
          deskripsi: 'Instalasi Griya Husada '
        }
      ],
      comboUnit: [],
      comboRuangan: [],
      comboTanggal: [],
      comboDokter: [],
      comboCaraBayar: [],
      comboMember: [],
      comboInstalasiKuota: [
        { deskripsi: 'Instalasi Rawat Jalan (Umum)', id: 10101 },
        { deskripsi: 'Instalasi Griya Husada (Eksekutif)', id: 10127 }
      ],
      comboJenisKelamin: [
        { name: 'Laki - laki', id: 1 },
        { name: 'Perempuan', id: 2 }
      ],
      comboJenisIdentitas: [
        { name: 'KTP (NIK)', id: 1 },
        { name: 'SIM', id: 2 },
        { name: 'Passport', id: 4 }
      ],
      comboSkrining: [
        { name: 'Skrining 1', id: 1 },
        { name: 'Skrining 2', id: 2 },
        { name: 'Skrining 3', id: 3 },
        { name: 'Skrining 4', id: 4 },
        { name: 'Skrining 5', id: 5 }
      ],
      jenisPasien: [
        { text: 'Pasien Lama', value: '1' },
        { text: 'Pasien Baru', value: '2' }
      ],
      comboShift: [],
      tabIndex: 1,
      norm: '',
      value: '',
      qrcode: '',
      resKet: '',
      noantrian: '',
      size: 250,
      error: '',
      errorTglKedatangan: '',
      respon: '',
      classHide: 'd-none',
      defaultOptions: { animationData: animationLogo, loop: true },
      defaultOptions2: { animationData: animationLogo2, loop: true },
      config: {
        locale: IndonesianLocale,
        minDate: 'today',
        disableMobile: true,
        dateFormat: 'd-m-Y'
      },
      configTglDatang: {
        locale: IndonesianLocale,
        dateFormat: 'd-m-Y',
        enable: [],
        disableMobile: true
      },
      configTglLahir: {
        locale: IndonesianLocale,
        maxDate: 'today',
        dateFormat: 'd-m-Y',
        disableMobile: true
      },
      kuotaSee: [],
      kuotaSeeGriya: {},
      kuotaClass: 'd-none',
      kuotaSeeDokter: '',
      selected: 'first',
      options: [
        { text: 'Toggle this custom radio', value: 'first' },
        { text: 'Or toggle this other custom radio', value: 'second' },
        { text: 'This one is Disabled', value: 'third', disabled: true },
        { text: 'This is the 4th radio', value: { fourth: 4 } }
      ],
      tglResponNorm: '',
      ruangan: null,
      caraBayar: null,
      nomorNIK: '',
      nomorBPJS: '',
      pesertaBPJS: {},
      rujukanBPJS: [],
      validasiBPJS: false,
      multiSelectDisable: true,
      formLihatKuota: {
        ruangan: null,
        dokter: ''
      },
      responselected: '',
      reviewRuang: {
        id: '',
        name: ''
      },
      menuLihatKuota: '',
      flatPickrTglKuotaValue: null,
      flatPickrTglReservasiValue: null,
      arrComboTgl: [],
      //
      output: null
    }
  },
  mounted () {
    xray.index()
    // this.getinstalasi()
    this.wilayah()
    this.agama()
    this.pendidikan()
    this.pekerjaan()
    this.Negara()
    this.getprovinsi()
    this.getCaraBayar()
    // this.refreshtoken()
  },
  computed: {
    nameState () {
      return this.norm.length === 8
    },
    tglLahirCard () {
      var months = new Array(12)
      months[0] = 'January'
      months[1] = 'February'
      months[2] = 'March'
      months[3] = 'April'
      months[4] = 'May'
      months[5] = 'June'
      months[6] = 'July'
      months[7] = 'August'
      months[8] = 'September'
      months[9] = 'October'
      months[10] = 'November'
      months[11] = 'December'
      var date = new Date(this.formData.tanggal_lahir)
      var year = date.getFullYear()
      var month = date.getMonth()
      var day = date.getDate()
      var tglFinal
      tglFinal = day + ' ' + months[month] + ', ' + year
      return tglFinal
    }
  },
  mixins: [ValidationHelper],
  methods: {
    onChangePasien () {
      this.norm = ''
      this.formData.jenis = ''
      this.selectedJenisIdentitas = []
      this.formData.nomor = ''
      this.formData.norm = 0
      this.formData.nama = ''
      this.formData.tempat_lahir = null
      this.selectedTempatLahir = []
      this.formData.tanggal_lahir = ''
      this.tglLahir = ''
      this.formData.usia = ''
      this.formData.jenis_kelamin = ''
      this.selectedJenisKelamin = []
      this.formData.agama = null
      this.selectedAgama = []
      this.formData.nomor_kontak = ''
      this.formData.alamat = ''
      this.formData.rt = ''
      this.formData.rw = ''
      this.formData.kodepos = ''
      this.formData.kewarganegaraan = null
      this.selectedNegara = []
      this.formData.pendidikan = null
      this.selectedPendidikan = []
      this.formData.pekerjaan = null
      this.selectedPekerjaan = []
      this.formData.email = ''
      this.formData.wilayah = null
      this.selectedProvinsi = []
      this.selectedKabupaten = []
      this.selectedKecamatan = []
      this.selectedKelurahan = []
    },
    async getNorm () {
      this.isLoading = true
      const data = this.norm
      if (data.length === 8) {
        await Online.showByRm(data).then(response => {
          this.isLoading = false
          this.formData.norm = response.data.data[0].NORM
          this.formData.jenis = response.data.data[0].JENIS_NIK
          this.selectedJenisIdentitas = response.data.data[0].JENIS_NIK === 1 ? { name: 'KTP (NIK)', id: response.data.data[0].JENIS_NIK } : response.data.data[0].JENIS_NIK === 2 ? { name: 'SIM', id: response.data.data[0].JENIS_NIK } : response.data.data[0].JENIS_NIK === 4 ? { name: 'Passport', id: response.data.data[0].JENIS_NIK } : []
          this.statusIdentitas = response.data.data[0].JENIS_NIK === 1 ? 1 : 2
          this.formData.nomor = response.data.data[0].JENIS_NIK === 1 ? response.data.data[0].NIK : response.data.data[0].JENIS_NIK === 2 ? response.data.data[0].SIM : response.data.data[0].PASSPORT
          this.nomorNIK = response.data.data[0].JENIS_NIK === 1 ? response.data.data[0].NIK : response.data.data[0].JENIS_NIK === 2 ? response.data.data[0].SIM : response.data.data[0].PASSPORT
          this.formData.nama = response.data.data[0].NAMA
          this.formData.jenis_kelamin = response.data.data[0].JENIS_KELAMIN
          this.selectedJenisKelamin = response.data.data[0].JENIS_KELAMIN === null ? [] : { name: response.data.data[0].NAMA_JENIS_KELAMIN, id: response.data.data[0].JENIS_KELAMIN }
          this.formData.agama = response.data.data[0].AGAMA
          this.selectedAgama = response.data.data[0].AGAMA === null ? [] : { deskripsi: response.data.data[0].DESKRIPSI_AGAMA, id: response.data.data[0].AGAMA }
          this.formData.pendidikan = response.data.data[0].PENDIDIKAN
          this.selectedPendidikan = response.data.data[0].PENDIDIKAN === null ? [] : { deskripsi: response.data.data[0].DESKRIPSI_PENDIDIKAN, id: response.data.data[0].PENDIDIKAN }
          this.formData.pekerjaan = response.data.data[0].PEKERJAAN
          this.selectedPekerjaan = response.data.data[0].PEKERJAAN === null ? [] : { deskripsi: response.data.data[0].DESKRIPSI_PEKERJAAN, id: response.data.data[0].PEKERJAAN }
          this.formData.kewarganegaraan = response.data.data[0].KEWARGANEGARAAN
          this.selectedNegara = response.data.data[0].KEWARGANEGARAAN === null ? [] : { deskripsi: response.data.data[0].DESKRIPSI_KEWARGANEGARAAN, id: response.data.data[0].KEWARGANEGARAAN }
          this.formData.alamat = response.data.data[0].ALAMAT
          this.selectedProvinsi = response.data.data[0].KELURAHAN === null ? [] : { deskripsi: response.data.data[0].NAMA_PROVINSI, id: response.data.data[0].PROVINSI }
          this.selectedKabupaten = response.data.data[0].KELURAHAN === null ? [] : { deskripsi: response.data.data[0].NAMA_KABUPATEN, id: response.data.data[0].KABUPATEN }
          this.selectedKecamatan = response.data.data[0].KELURAHAN === null ? [] : { deskripsi: response.data.data[0].NAMA_KECAMATAN, id: response.data.data[0].KECAMATAN }
          this.selectedKelurahan = response.data.data[0].KELURAHAN === null ? [] : { deskripsi: response.data.data[0].NAMA_KELURAHAN, id: response.data.data[0].KELURAHAN }
          this.formData.wilayah = response.data.data[0].KELURAHAN === null ? '' : response.data.data[0].KELURAHAN
          this.formData.rt = response.data.data[0].RT !== null ? response.data.data[0].RT.replace(/[^a-zA-Z0-9\s]/g, '') : ''
          this.formData.rw = response.data.data[0].RW !== null ? response.data.data[0].RW.replace(/[^a-zA-Z0-9\s]/g, '') : ''
          this.formData.kodepos = response.data.data[0].KODEPOS !== null ? response.data.data[0].KODEPOS.replace(/[^a-zA-Z0-9\s]/g, '') : ''
          this.formData.nomor_kontak = response.data.data[0].NOMOR
          this.tglResponNorm = response.data.data[0].TANGGAL_LAHIR
          this.formData.tempat_lahir = response.data.data[0].TEMPAT_LAHIR
          this.selectedTempatLahir = { deskripsi: response.data.data[0].nmTempatLahir, id: response.data.data[0].TEMPAT_LAHIR }
          this.respon = response.data.message
          this.tglLahir = response.data.data[0].TANGGAL_LAHIR
          this.getAge(response.data.data[0].TANGGAL_LAHIR)
          this.clearBpjsForm()
          this.validasiBPJS = false
        }).catch(error => {
          this.isLoading = false
          this.error = error.response
          this.formData.jenis = ''
          this.formData.nomor = ''
          this.formData.norm = 0
          this.formData.nama = ''
          this.formData.tempat_lahir = null
          this.selectedTempatLahir = []
          this.formData.tanggal_lahir = ''
          this.tglLahir = ''
          this.formData.usia = ''
          this.formData.jenis_kelamin = ''
          this.selectedJenisKelamin = []
          this.formData.agama = null
          this.selectedAgama = []
          this.formData.nomor_kontak = ''
          this.formData.alamat = ''
          this.formData.rt = ''
          this.formData.rw = ''
          this.formData.kodepos = ''
          this.formData.kewarganegaraan = null
          this.selectedNegara = []
          this.formData.pendidikan = null
          this.selectedPendidikan = []
          this.formData.pekerjaan = null
          this.selectedPekerjaan = []
          this.formData.email = ''
          this.formData.wilayah = null
          this.selectedProvinsi = []
          this.selectedKabupaten = []
          this.selectedKecamatan = []
          this.selectedKelurahan = []
          this.error = error.response.data.message
          this.$refs['modal-gagal-norm'].show()
        })
      } else {
        this.isLoading = false
        this.formData.jenis = ''
        this.formData.nomor = ''
        this.formData.norm = 0
        this.formData.nama = ''
        this.formData.tempat_lahir = null
        this.selectedTempatLahir = []
        this.formData.tanggal_lahir = ''
        this.tglLahir = ''
        this.formData.usia = ''
        this.formData.jenis_kelamin = ''
        this.selectedJenisKelamin = []
        this.formData.agama = null
        this.selectedAgama = []
        this.formData.nomor_kontak = ''
        this.formData.alamat = ''
        this.formData.rt = ''
        this.formData.rw = ''
        this.formData.kodepos = ''
        this.formData.kewarganegaraan = null
        this.selectedNegara = []
        this.formData.pendidikan = null
        this.selectedPendidikan = []
        this.formData.pekerjaan = null
        this.selectedPekerjaan = []
        this.formData.email = ''
        this.formData.wilayah = null
        this.selectedProvinsi = []
        this.selectedKabupaten = []
        this.selectedKecamatan = []
        this.selectedKelurahan = []
        this.error = 'nomor rekam medis tidak 8 digit dan tidak mengandung huruf'
        this.$refs['modal-gagal-normValid'].show()
      }
    },
    onrawatcheck (value) {
      if (value === '2') {
        this.clearBpjsForm()
        this.validasiBPJS = false
      }
    },
    valTglLahir () {
      const tglStr = this.tglLahir
      const tgl = tglStr.split('-').reverse().join('-')
      this.formData.tanggal_lahir = tgl
      this.getAge(tglStr)
    },
    async wilayah () {
      try {
        const res = await Service.master.tempat_lahir()
        this.comboTempatLahir = res.data.data
      } catch (err) {
        this.error = err
      }
    },
    async agama () {
      try {
        const res = await Service.master.referensi(1)
        this.comboAgama = res.data.data
      } catch (err) {
        this.error = err
      }
    },
    async pendidikan () {
      try {
        const res = await Service.master.referensi(3)
        this.comboPendidikan = res.data.data
      } catch (err) {
        this.error = err
      }
    },
    async pekerjaan () {
      try {
        const res = await Service.master.referensi(4)
        this.comboPekerjaan = res.data.data
      } catch (err) {
        this.error = err
      }
    },
    async Negara () {
      try {
        const res = await Service.master.negara()
        this.comboNegara = res.data.data
      } catch (err) {
        this.error = err
      }
    },
    async getprovinsi () {
      await Service.master.provinsi(1).then(response => {
        if (response.status === 200) {
          this.comboProvinsi = response.data.data
        }
      }).catch(error => {
        this.error = error.response
      })
    },
    async getkabupaten (value) {
      const data = value.id
      await Service.master.kabupaten(data).then(response => {
        if (response.status === 200) {
          this.comboKabupaten = response.data.data
        }
      }).catch(error => {
        this.error = error.response
      })
    },
    async getkecamatan (value) {
      const data = value.id
      await Service.master.kecamatan(data).then(response => {
        if (response.status === 200) {
          this.comboKecamatan = response.data.data
        }
      }).catch(error => {
        this.error = error.response
      })
    },
    async getkelurahan (value) {
      const data = value.id
      await Service.master.desa(data).then(response => {
        if (response.status === 200) {
          this.comboKelurahan = response.data.data
        }
      }).catch(error => {
        this.error = error.response
      })
    },
    async getCaraBayar () {
      await Service.master.referensicarabayar(10).then(response => {
        if (response.status === 200) {
          this.comboCaraBayar = response.data.data
        }
      }).catch(error => {
        this.error = error.response
      })
    },
    // async getinstalasi () {
    //   await Service.master.instalasireservasi().then(response => {
    //     if (response.status === 200) {
    //       this.comboInstalasi = response.data.data
    //     }
    //   }).catch(error => {
    //     this.error = error.response
    //   })
    // },
    async getunit () {
      const ruangan = this.reviewRuang.id
      await Service.pegawai.poli(ruangan).then(response => {
        if (response.data.statcode === 0) {
          this.comboUnit = response.data.data
        }
      }).catch(error => {
        this.error = error.message
      })
    },
    // async getunitkuota (value) {
    //   const data = value.id
    //   await Service.master.unitreservasi(data).then(response => {
    //     if (response.status === 200) {
    //       this.comboUnitKuota = response.data.data
    //     }
    //   }).catch(error => {
    //     this.error = error.response
    //   })
    // },
    async getruangan (value) {
      const id = value.id
      const ruangan = this.reviewRuang.id
      await Service.pegawai.subspesialis(id, ruangan).then(response => {
        if (response.data.statcode === 0) {
          this.comboRuangan = response.data.data
        } else {
          this.comboRuangan = [{ id: null, keterangan: 'Kuota Poli Tidak Tersedia', $isDisabled: true }]
        }
      }).catch(error => {
        this.error = error.response
        this.comboRuangan = [{ id: null, keterangan: 'FrontEnd Error', $isDisabled: true }]
      })
    },
    async getruangankuota (value) {
      const data = value
      await Service.master.ruangancekkuota(data).then(response => {
        if (response.data.statcode === 0) {
          this.comboRuanganKuota = response.data.data
        }
      }).catch(error => {
        this.error = error.response
      })
    },
    async gettanggal (value) {
      const dokter = value.id.toString()
      const ruangan = this.reviewRuang.id
      await Service.master.tanggalcekkuota_v2(dokter, ruangan).then(response => {
        if (response.data.statcode === 0) {
          this.comboTanggal = response.data.data
          this.loopTgl()
        } else {
          this.comboTanggal = [{ id: null, tanggal: 'Tanggal Tidak Tersedia', $isDisabled: true }]
        }
      }).catch(error => {
        this.error = error.response
        this.comboTanggal = [{ id: null, tanggal: 'FrontEnd Error', $isDisabled: true }]
      })
    },
    loopTgl () {
      let a = this.comboTanggal
      let b = this.arrComboTgl
      let c = this.configTglDatang.enable
      for (let i = 0; i < a.length; i++) {
        b.push(a[i])
        c.push(a[i].tanggal.split('-').reverse().join('-'))
      }
    },
    async getdokter (value) {
      const id = value.id
      const ruangan = this.reviewRuang.id
      await Service.pegawai.dokter(id, ruangan).then(response => {
        if (response.data.statcode === 0) {
          this.comboDokter = response.data.data
        } else {
          this.comboDokter = [{ id: null, nama: 'Dokter Tidak Tersedia', $isDisabled: true }]
        }
      }).catch(error => {
        this.error = error.response
        this.comboDokter = [{ id: null, nama: 'FrontEnd Error', $isDisabled: true }]
      })
    },
    testBpjs (value) {
      this.nomorNIK = value
    },
    async getnoBpjs () {
      this.isLoading = true
      if (this.validasiKartuBPJS === 1) {
        if (this.nomorNIK !== '') {
          if (this.formData.jenis === 1 && this.formData.nomor === this.nomorNIK) {
            const nik = this.nomorNIK
            await Online.cekKBpjsNik(nik).then(response => {
              if (response.status === 200) {
                this.pesertaBPJS = response.data.peserta
                this.rujukanBPJS = response.data.rujukan
                this.$refs['modal-bpjs'].show()
                this.isLoading = false
              } else {
                this.error = response.data.message
                this.$refs['modal-gagal-kirim-2'].show()
                this.validasiBPJS = false
                this.clearBpjsForm()
                this.isLoading = false
              }
            }).catch(error => {
              this.error = error.response
              this.$refs['modal-gagal-kirim-2'].show()
              this.validasiBPJS = false
              this.clearBpjsForm()
              this.isLoading = false
            })
          } else {
            this.error = 'Nomor Identitas Tidak Sesuai dengan NIK Pada Formulir DATA DIRI'
            this.$refs['modal-gagal-kirim-2'].show()
            this.validasiBPJS = false
            this.clearBpjsForm()
            this.isLoading = false
          }
        } else {
          this.error = 'Nomor NIK Harus Di Isi'
          this.$refs['modal-gagal-kirim-2'].show()
          this.validasiBPJS = false
          this.clearBpjsForm()
          this.isLoading = false
        }
      } else if (this.validasiKartuBPJS === 2) {
        if (this.nomorBPJS !== '') {
          const noka = this.nomorBPJS
          await Online.cekKBpjsNoKa(noka).then(response => {
            if (response.status === 200) {
              this.pesertaBPJS = response.data.peserta
              this.rujukanBPJS = response.data.rujukan
              this.$refs['modal-bpjs'].show()
              this.isLoading = false
            } else {
              this.error = response.data.message
              this.$refs['modal-gagal-kirim-2'].show()
              this.validasiBPJS = false
              this.clearBpjsForm()
              this.isLoading = false
            }
          }).catch(error => {
            this.error = error.response
            this.$refs['modal-gagal-kirim-2'].show()
            this.validasiBPJS = false
            this.clearBpjsForm()
            this.isLoading = false
          })
        } else {
          this.error = 'Nomor BPJS Harus Di Isi'
          this.$refs['modal-gagal-kirim-2'].show()
          this.validasiBPJS = false
          this.clearBpjsForm()
          this.isLoading = false
        }
      }
      this.isLoading = false
    },
    testbtn (index) {
      let idx = index
      let dataPeserta = this.pesertaBPJS
      let dataRujukan = this.rujukanBPJS.rujukan
      this.formData.peserta.no_kartu = dataPeserta.noKartu
      this.formData.peserta.response.nik = dataPeserta.nik
      this.formData.peserta.response.nama = dataPeserta.nama
      this.formData.peserta.response.pisa = dataPeserta.pisa
      this.formData.peserta.response.sex = dataPeserta.sex
      this.formData.peserta.response.mr.noMR = dataPeserta.mr.noMR
      this.formData.peserta.response.mr.noTelepon = dataPeserta.mr.noTelepon
      this.formData.peserta.response.tglLahir = dataPeserta.tglLahir
      this.formData.peserta.response.tglCetakKartu = dataPeserta.tglCetakKartu
      this.formData.peserta.response.tglTAT = dataPeserta.tglTAT
      this.formData.peserta.response.tglTMT = dataPeserta.tglTMT
      this.formData.peserta.response.statusPeserta.kode = dataPeserta.statusPeserta.kode
      this.formData.peserta.response.statusPeserta.keterangan = dataPeserta.statusPeserta.keterangan
      this.formData.peserta.response.provUmum.kdProvider = dataPeserta.provUmum.kdProvider
      this.formData.peserta.response.provUmum.nmProvider = dataPeserta.provUmum.nmProvider
      this.formData.peserta.response.jenisPeserta.kode = dataPeserta.jenisPeserta.kode
      this.formData.peserta.response.jenisPeserta.keterangan = dataPeserta.jenisPeserta.keterangan
      this.formData.peserta.response.hakKelas.kode = dataPeserta.hakKelas.kode
      this.formData.peserta.response.hakKelas.keterangan = dataPeserta.hakKelas.keterangan
      this.formData.peserta.response.umur.umurSekarang = dataPeserta.umur.umurSekarang
      this.formData.peserta.response.umur.umurSaatPelayanan = dataPeserta.umur.umurSaatPelayanan
      this.formData.peserta.response.informasi.dinsos = dataPeserta.informasi.dinsos
      this.formData.peserta.response.informasi.prolanisPRB = dataPeserta.informasi.prolanisPRB
      this.formData.peserta.response.informasi.noSKTM = dataPeserta.informasi.noSKTM
      this.formData.peserta.response.cob.noAsuransi = dataPeserta.cob.noAsuransi
      this.formData.peserta.response.cob.nmAsuransi = dataPeserta.cob.nmAsuransi
      this.formData.peserta.response.cob.tglTMT = dataPeserta.cob.tglTMT
      this.formData.peserta.response.cob.noSKTM = dataPeserta.cob.noSKTM
      this.formData.rujukan.no_rujukan = this.rujukanBPJS.rujukan[idx].noKunjungan
      this.formData.rujukan.response.asalFaskes = this.rujukanBPJS.asalFaskes
      this.formData.rujukan.response.rujukan[0].noKunjungan = dataRujukan[idx].noKunjungan
      this.formData.rujukan.response.rujukan[0].tglKunjungan = dataRujukan[idx].tglKunjungan
      this.formData.rujukan.response.rujukan[0].provPerujuk.kode = dataRujukan[idx].provPerujuk.kode
      this.formData.rujukan.response.rujukan[0].provPerujuk.nama = dataRujukan[idx].provPerujuk.nama
      this.formData.rujukan.response.rujukan[0].peserta.noKartu = dataRujukan[idx].peserta.noKartu
      this.formData.rujukan.response.rujukan[0].peserta.nik = dataRujukan[idx].peserta.nik
      this.formData.rujukan.response.rujukan[0].peserta.nama = dataRujukan[idx].peserta.nama
      this.formData.rujukan.response.rujukan[0].peserta.pisa = dataRujukan[idx].peserta.pisa
      this.formData.rujukan.response.rujukan[0].peserta.sex = dataRujukan[idx].peserta.sex
      this.formData.rujukan.response.rujukan[0].peserta.mr.noMR = dataRujukan[idx].peserta.mr.noMR
      this.formData.rujukan.response.rujukan[0].peserta.mr.noTelepon = dataRujukan[idx].peserta.mr.noTelepon
      this.formData.rujukan.response.rujukan[0].peserta.tglLahir = dataRujukan[idx].peserta.tglLahir
      this.formData.rujukan.response.rujukan[0].peserta.tglCetakKartu = dataRujukan[idx].peserta.tglCetakKartu
      this.formData.rujukan.response.rujukan[0].peserta.tglTAT = dataRujukan[idx].peserta.tglTAT
      this.formData.rujukan.response.rujukan[0].peserta.tglTMT = dataRujukan[idx].peserta.tglTMT
      this.formData.rujukan.response.rujukan[0].peserta.statusPeserta.kode = dataRujukan[idx].peserta.statusPeserta.kode
      this.formData.rujukan.response.rujukan[0].peserta.statusPeserta.keterangan = dataRujukan[idx].peserta.statusPeserta.keterangan
      this.formData.rujukan.response.rujukan[0].peserta.provUmum.kdProvider = dataRujukan[idx].peserta.provUmum.kdProvider
      this.formData.rujukan.response.rujukan[0].peserta.provUmum.nmProvider = dataRujukan[idx].peserta.provUmum.nmProvider
      this.formData.rujukan.response.rujukan[0].peserta.jenisPeserta.kode = dataRujukan[idx].peserta.jenisPeserta.kode
      this.formData.rujukan.response.rujukan[0].peserta.jenisPeserta.keterangan = dataRujukan[idx].peserta.jenisPeserta.keterangan
      this.formData.rujukan.response.rujukan[0].peserta.hakKelas.kode = dataRujukan[idx].peserta.hakKelas.kode
      this.formData.rujukan.response.rujukan[0].peserta.hakKelas.keterangan = dataRujukan[idx].peserta.hakKelas.keterangan
      this.formData.rujukan.response.rujukan[0].peserta.umur.umurSekarang = dataRujukan[idx].peserta.umur.umurSekarang
      this.formData.rujukan.response.rujukan[0].peserta.umur.umurSaatPelayanan = dataRujukan[idx].peserta.umur.umurSaatPelayanan
      this.formData.rujukan.response.rujukan[0].peserta.informasi.dinsos = dataRujukan[idx].peserta.informasi.dinsos
      this.formData.rujukan.response.rujukan[0].peserta.informasi.prolanisPRB = dataRujukan[idx].peserta.informasi.prolanisPRB
      this.formData.rujukan.response.rujukan[0].peserta.informasi.noSKTM = dataRujukan[idx].peserta.informasi.noSKTM
      this.formData.rujukan.response.rujukan[0].peserta.cob.noAsuransi = dataRujukan[idx].peserta.cob.noAsuransi
      this.formData.rujukan.response.rujukan[0].peserta.cob.nmAsuransi = dataRujukan[idx].peserta.cob.nmAsuransi
      this.formData.rujukan.response.rujukan[0].peserta.cob.tglTMT = dataRujukan[idx].peserta.cob.tglTMT
      this.formData.rujukan.response.rujukan[0].peserta.cob.tglTAT = dataRujukan[idx].peserta.cob.tglTAT
      this.formData.rujukan.response.rujukan[0].diagnosa.kode = dataRujukan[idx].diagnosa.kode
      this.formData.rujukan.response.rujukan[0].diagnosa.nama = dataRujukan[idx].diagnosa.nama
      this.formData.rujukan.response.rujukan[0].keluhan = dataRujukan[idx].keluhan
      this.formData.rujukan.response.rujukan[0].poliRujukan.kode = dataRujukan[idx].poliRujukan.kode
      this.formData.rujukan.response.rujukan[0].poliRujukan.nama = dataRujukan[idx].poliRujukan.nama
      this.formData.rujukan.response.rujukan[0].pelayanan.kode = dataRujukan[idx].pelayanan.kode
      this.formData.rujukan.response.rujukan[0].pelayanan.nama = dataRujukan[idx].pelayanan.nama
      this.validasiBPJS = true
      this.$refs['modal-bpjs'].hide()
    },
    nameWithNorm ({ nama, norm, deskripsi }) {
      return `${nama} — ${norm.toString().padStart(8, '0')} — ${deskripsi}`
    },
    tanggalWithHari ({ tanggal, hari }) {
      return `${hari} (${tanggal.split('-').reverse().join('-')})`
    },
    clearBpjsForm () {
      this.formData.peserta.no_kartu = null
      this.formData.peserta.response.nik = null
      this.formData.peserta.response.nama = null
      this.formData.peserta.response.pisa = null
      this.formData.peserta.response.sex = null
      this.formData.peserta.response.mr.noMR = null
      this.formData.peserta.response.mr.noTelepon = null
      this.formData.peserta.response.tglLahir = null
      this.formData.peserta.response.tglCetakKartu = null
      this.formData.peserta.response.tglTAT = null
      this.formData.peserta.response.tglTMT = null
      this.formData.peserta.response.statusPeserta.kode = null
      this.formData.peserta.response.statusPeserta.keterangan = null
      this.formData.peserta.response.provUmum.kdProvider = null
      this.formData.peserta.response.provUmum.nmProvider = null
      this.formData.peserta.response.jenisPeserta.kode = null
      this.formData.peserta.response.jenisPeserta.keterangan = null
      this.formData.peserta.response.hakKelas.kode = null
      this.formData.peserta.response.hakKelas.keterangan = null
      this.formData.peserta.response.umur.umurSekarang = null
      this.formData.peserta.response.umur.umurSaatPelayanan = null
      this.formData.peserta.response.informasi.dinsos = null
      this.formData.peserta.response.informasi.prolanisPRB = null
      this.formData.peserta.response.informasi.noSKTM = null
      this.formData.peserta.response.cob.noAsuransi = null
      this.formData.peserta.response.cob.nmAsuransi = null
      this.formData.peserta.response.cob.tglTMT = null
      this.formData.peserta.response.cob.noSKTM = null
      this.formData.rujukan.no_rujukan = null
      this.formData.rujukan.response.asalFaskes = null
      this.formData.rujukan.response.rujukan[0].noKunjungan = null
      this.formData.rujukan.response.rujukan[0].tglKunjungan = null
      this.formData.rujukan.response.rujukan[0].provPerujuk.kode = null
      this.formData.rujukan.response.rujukan[0].provPerujuk.nama = null
      this.formData.rujukan.response.rujukan[0].peserta.noKartu = null
      this.formData.rujukan.response.rujukan[0].peserta.nik = null
      this.formData.rujukan.response.rujukan[0].peserta.nama = null
      this.formData.rujukan.response.rujukan[0].peserta.pisa = null
      this.formData.rujukan.response.rujukan[0].peserta.sex = null
      this.formData.rujukan.response.rujukan[0].peserta.mr.noMR = null
      this.formData.rujukan.response.rujukan[0].peserta.mr.noTelepon = null
      this.formData.rujukan.response.rujukan[0].peserta.tglLahir = null
      this.formData.rujukan.response.rujukan[0].peserta.tglCetakKartu = null
      this.formData.rujukan.response.rujukan[0].peserta.tglTAT = null
      this.formData.rujukan.response.rujukan[0].peserta.tglTMT = null
      this.formData.rujukan.response.rujukan[0].peserta.statusPeserta.kode = null
      this.formData.rujukan.response.rujukan[0].peserta.statusPeserta.keterangan = null
      this.formData.rujukan.response.rujukan[0].peserta.provUmum.kdProvider = null
      this.formData.rujukan.response.rujukan[0].peserta.provUmum.nmProvider = null
      this.formData.rujukan.response.rujukan[0].peserta.jenisPeserta.kode = null
      this.formData.rujukan.response.rujukan[0].peserta.jenisPeserta.keterangan = null
      this.formData.rujukan.response.rujukan[0].peserta.hakKelas.kode = null
      this.formData.rujukan.response.rujukan[0].peserta.hakKelas.keterangan = null
      this.formData.rujukan.response.rujukan[0].peserta.umur.umurSekarang = null
      this.formData.rujukan.response.rujukan[0].peserta.umur.umurSaatPelayanan = null
      this.formData.rujukan.response.rujukan[0].peserta.informasi.dinsos = null
      this.formData.rujukan.response.rujukan[0].peserta.informasi.prolanisPRB = null
      this.formData.rujukan.response.rujukan[0].peserta.informasi.noSKTM = null
      this.formData.rujukan.response.rujukan[0].peserta.cob.noAsuransi = null
      this.formData.rujukan.response.rujukan[0].peserta.cob.nmAsuransi = null
      this.formData.rujukan.response.rujukan[0].peserta.cob.tglTMT = null
      this.formData.rujukan.response.rujukan[0].peserta.cob.tglTAT = null
      this.formData.rujukan.response.rujukan[0].diagnosa.kode = null
      this.formData.rujukan.response.rujukan[0].diagnosa.nama = null
      this.formData.rujukan.response.rujukan[0].keluhan = null
      this.formData.rujukan.response.rujukan[0].poliRujukan.kode = null
      this.formData.rujukan.response.rujukan[0].poliRujukan.nama = null
      this.formData.rujukan.response.rujukan[0].pelayanan.kode = null
      this.formData.rujukan.response.rujukan[0].pelayanan.nama = null
      this.validasiBPJS = false
    },
    valMember (value) {
      this.normMember = value.norm
      this.valMemberAutoDigit()
    },
    valMemberAutoDigit () {
      const temp = this.normMember?.toString() || ''
      const generate = temp.padStart(8, '0')
      this.getNorm(generate)
    },
    valTempatLahir (value) {
      this.formData.tempat_lahir = value.id
    },
    valJenisKelamin (value) {
      this.formData.jenis_kelamin = value.id
    },
    valJenisIdentitas (value) {
      this.formData.jenis = value.id
      if (this.statusUmur === 1) {
        if (this.formData.jenis === 1) {
          this.nomorBPJS = this.formData.nomor
          this.statusIdentitas = 1
          this.validasiKartuBPJS = 1
        } else {
          this.nomorBPJS = ''
          this.statusIdentitas = 2
          this.validasiKartuBPJS = 2
        }
      } else if (this.statusUmur === 2) {
        if (this.formData.jenis === 1) {
          this.nomorBPJS = this.formData.nomor
          this.statusIdentitas = 1
          this.validasiKartuBPJS = 2
        } else {
          this.nomorBPJS = ''
          this.statusIdentitas = 2
          this.validasiKartuBPJS = 2
        }
      } else if (this.statusUmur === 0) {
        if (this.formData.jenis === 1) {
          this.nomorBPJS = this.formData.nomor
          this.statusIdentitas = 1
          this.validasiKartuBPJS = 2
        } else {
          this.nomorBPJS = ''
          this.statusIdentitas = 2
          this.validasiKartuBPJS = 2
        }
      }
    },
    valAgama (value) {
      this.formData.agama = value.id
    },
    valPendidikan (value) {
      this.formData.pendidikan = value.id
    },
    valPekerjaan (value) {
      this.formData.pekerjaan = value.id
    },
    valNegara (value) {
      this.formData.kewarganegaraan = value.id
    },
    valWilayah (value) {
      this.formData.wilayah = value.id
    },
    valCaraBayar (value) {
      this.formData.carabayar = value.id.toString()
      if (value.id !== 2 || value.id !== '2') {
        this.clearBpjsForm()
        this.validasiBPJS = false
      }
    },
    valInstalasi (value) {
      this.selectedUnit = []
      this.comboUnit = []
      this.selectedRuangan = []
      this.comboRuangan = []
      this.formData.ruangan = null
      this.flatPickrTglReservasiValue = null
      this.selectedTanggal = []
      this.comboTanggal = []
      this.arrComboTgl = []
      this.configTglDatang.enable = []
      this.formData.tanggal_reservasi = ''
      this.selectedDokter = []
      this.comboDokter = []
      this.formData.dokter = ''
      this.selectedShift = []
      this.comboShift = []
      this.formData.shift = ''
      this.formData.menit_layanan = ''
      this.responselected = ''
      this.formData.kode_dokter_bpjs = null
      this.reviewRuang.id = value.id
      this.getunit()
    },
    valUnit (value) {
      this.selectedRuangan = []
      this.comboRuangan = []
      this.formData.ruangan = null
      this.flatPickrTglReservasiValue = null
      this.selectedTanggal = []
      this.comboTanggal = []
      this.arrComboTgl = []
      this.configTglDatang.enable = []
      this.formData.tanggal_reservasi = ''
      this.selectedDokter = []
      this.comboDokter = []
      this.formData.dokter = ''
      this.selectedShift = []
      this.comboShift = []
      this.formData.menit_layanan = ''
      this.formData.shift = ''
      this.responselected = ''
      this.formData.kode_dokter_bpjs = null
      this.getruangan(value)
    },
    valRuanganpoli (value) {
      this.formData.ruangan = ''
      this.flatPickrTglReservasiValue = null
      this.selectedTanggal = []
      this.comboTanggal = []
      this.arrComboTgl = []
      this.configTglDatang.enable = []
      this.formData.tanggal_reservasi = ''
      this.selectedDokter = []
      this.comboDokter = []
      this.formData.dokter = ''
      this.selectedShift = []
      this.comboShift = []
      this.formData.shift = ''
      this.formData.menit_layanan = ''
      this.formData.kode_dokter_bpjs = null
      this.responselected = ''
      this.getdokter(value)
    },
    valDokter (value) {
      this.flatPickrTglReservasiValue = null
      this.selectedTanggal = []
      this.comboTanggal = []
      this.configTglDatang.enable = []
      this.arrComboTgl = []
      this.formData.tanggal_reservasi = ''
      this.selectedShift = []
      this.comboShift = []
      this.formData.shift = ''
      this.responselected = ''
      this.formData.ruangan = value.ruangan
      this.formData.menit_layanan = ''
      this.formData.dokter = value.id.toString()
      this.formData.kode_dokter_bpjs = value.kode_dokter_bpjs
      this.reviewRuang.name = value.deskripsi
      this.gettanggal(value)
    },
    // valTanggal (value) {
    //   this.formData.tanggal_reservasi = value.tanggal
    //   this.selectedShift = []
    //   this.comboShift = []
    //   this.arrComboTgl = []
    //   this.configTglDatang.enable = []
    //   this.formData.shift = ''
    //   this.formData.menit_layanan = ''
    //   this.responselected = value
    //   this.formData.shift = value.shift
    //   this.getdokter(value)
    // },
    lihatkuotaTanggalDatang (selectedDates, dateStr, instance) {
      for (let i = 0; i < this.arrComboTgl.length; i++) {
        if (dateStr.split('-').reverse().join('-') === this.arrComboTgl[i].tanggal) {
          this.formData.tanggal_reservasi = this.arrComboTgl[i].tanggal
          this.selectedShift = []
          this.comboShift = []
          this.formData.shift = ''
          this.responselected = this.arrComboTgl[i]
          this.formData.shift = this.arrComboTgl[i].shift
          this.formData.menit_layanan = this.arrComboTgl[i].menit_layanan.toString()
        }
      }
    },
    // valShift (value) {
    //   this.formData.shift = value.deskripsi
    // },
    // valUnitKuota (value) {
    //   this.iconCekKuota = value
    //   this.reviewRuang = value
    //   if (value === 1010101) {
    //     this.buttonCekIrj = 'btn-lihat-kuota'
    //     this.buttonCekGriya = ''
    //   } else if (value === 1012701) {
    //     this.buttonCekGriya = 'btn-lihat-kuota'
    //     this.buttonCekIrj = ''
    //   }
    //   this.comboRuanganKuota = []
    //   this.selectedRuanganKuota = []
    //   this.formLihatKuota.ruangan = null
    //   this.flatPickrTglKuotaValue = null
    //   this.comboDokterKuota = []
    //   this.selectedDokterKuota = []
    //   this.formLihatKuota.dokter = ''
    //   this.getruangankuota(value)
    // },
    // valRuanganpoliKuota (value) {
    //   this.formLihatKuota.ruangan = value.id
    //   this.flatPickrTglKuotaValue = null
    //   this.comboDokterKuota = []
    //   this.selectedDokterKuota = []
    //   this.formLihatKuota.dokter = ''
    // },
    // lihatkuotaTest (selectedDates, dateStr, instance) {
    //   this.comboDokterKuota = []
    //   this.selectedDokterKuota = []
    //   this.formLihatKuota.dokter = ''
    //   let tgl = dateStr.split('-').reverse().join('-')
    //   let rgn = this.formLihatKuota.ruangan
    //   let id = '1'
    //   this.menuLihatKuota = tgl
    //   this.viewTgl = tgl.split('-').reverse().join('-')
    //   this.valMenuCekDokter(tgl, rgn, id)
    // },
    // async valMenuCekDokter (tgl, rgn, id) {
    //   let ruangan = rgn
    //   let tanggal = tgl
    //   let identifier = id
    //   if (identifier === '1') {
    //     await Service.master.doktercek(ruangan, tanggal).then(response => {
    //       this.comboDokterKuota = response.data.data
    //     }).catch(error => {
    //       this.error = error.response
    //       this.comboDokterKuota.push({ deskripsi: 'Dokter Tidak Tersedia', id: null, $isDisabled: true })
    //     })
    //   } else if (identifier === '2') {
    //     await Service.master.doktercek(ruangan, tanggal).then(response => {
    //       this.comboDokter = response.data.data
    //     }).catch(error => {
    //       this.error = error.response
    //       this.comboDokter.push({ deskripsi: 'Dokter Tidak Tersedia', id: null, $isDisabled: true })
    //     })
    //   }
    // },
    // valDokterKuota (value) {
    //   let dktr = value.id
    //   let id = '1'
    //   this.kuotaSeeDokter = value.deskripsi
    //   this.formLihatKuota.dokter = value.id
    //   this.menuCekKuota(dktr, id)
    // },
    // async menuCekKuota (dktr, id) {
    //   let identifier = id
    //   if (identifier === '1') {
    //     let ruangan = this.formLihatKuota.ruangan
    //     let tgls = this.menuLihatKuota
    //     let dokter = dktr
    //     let jnsrsv = this.formData.jenis_reservasi
    //     await Service.master.kuotadokter(ruangan, tgls, jnsrsv, dokter).then(response => {
    //       this.kuotaSeeGriya = response.data.data
    //       this.kuotaClass = 'd-block'
    //     }).catch(error => {
    //       this.error = error.response
    //       this.kuotaSeeGriya = false
    //       this.kuotaClass = 'd-block'
    //     })
    //   } else if (identifier === '2') {
    //     let ruangan = this.formData.ruangan
    //     let tgls = this.formData.tanggal_reservasi
    //     let dokter = dktr
    //     let jnsrsv = this.formData.jenis_reservasi
    //     await Service.master.kuotadokter(ruangan, tgls, jnsrsv, dokter).then(response => {
    //       if (response.status === 200) {
    //         this.responselected = response.data.data
    //         this.comboShift = this.responselected.sisa !== 0 ? [{ deskripsi: this.responselected.jam_mulai + ' - ' + this.responselected.jam_akhir, id: null }] : []
    //         this.multiSelectDisable = false
    //       } else {
    //         this.responselected = 'Kuota Tidak Tersedia'
    //         this.selectedShift = []
    //         this.formData.shift = ''
    //         this.multiSelectDisable = true
    //       }
    //     }).catch(error => {
    //       this.responselected = 'Kuota Tidak Tersedia'
    //       this.error = error.response
    //       this.selectedShift = []
    //       this.formData.shift = ''
    //       this.multiSelectDisable = true
    //     })
    //   }
    // },
    async onComplete () {
      this.isLoading = true
      const data = this.formData
      if (this.selectedRanap === '1') {
        if (data.carabayar === '2' || data.carabayar === 2) {
          if (this.validasiBPJS === true) {
            await Reservasi.createRsv(data).then(response => {
              if (response.data[0].statcode === 0) {
                this.respon = response.data[0].message
                this.resKet = JSON.parse(response.data[0].data)
                this.$refs['modal-sukses-qrcode'].show()
                setTimeout(() => {
                  this.isLoading = false
                  this.captureQr()
                }, 3000)
              } else {
                this.error = response.data[0].message
                this.$refs['modal-gagal-kirim-2'].show()
                this.isLoading = false
              }
            }).catch(error => {
              this.error = error.message
              this.$refs['modal-gagal-kirim-2'].show()
              this.isLoading = false
            })
          } else if (this.validasiBPJS === false) {
            this.error = 'Anda Belum Melakukan Validasi BPJS Atau Memilih Rujukan'
            this.$refs['modal-gagal-kirim-2'].show()
            this.isLoading = false
          }
        } else {
          await Reservasi.createRsv(data).then(response => {
            if (response.data[0].statcode === 0) {
              this.respon = response.data[0].message
              this.resKet = JSON.parse(response.data[0].data)
              this.$refs['modal-sukses-qrcode'].show()
              setTimeout(() => {
                this.isLoading = false
                this.captureQr()
              }, 3000)
            } else {
              this.error = response.data[0].message
              this.$refs['modal-gagal-kirim-2'].show()
              this.isLoading = false
            }
          }).catch(error => {
            this.error = error.message
            this.$refs['modal-gagal-kirim-2'].show()
            this.isLoading = false
          })
        }
      } else if (this.selectedRanap === '2') {
        await Reservasi.createRsv(data).then(response => {
          if (response.data[0].statcode === 0) {
            this.respon = response.data[0].message
            this.resKet = JSON.parse(response.data[0].data)
            this.$refs['modal-sukses-qrcode'].show()
            setTimeout(() => {
              this.isLoading = false
              this.captureQr()
            }, 3000)
          } else {
            this.error = response.data[0].message
            this.$refs['modal-gagal-kirim-2'].show()
            this.isLoading = false
          }
        }).catch(error => {
          this.error = error.message
          this.$refs['modal-gagal-kirim-2'].show()
          this.isLoading = false
        })
      }
    },
    kuotaClose () {
      this.kuotaClass = 'd-none'
    },
    refreshpage () {
      location.reload()
    },
    getAge (date) {
      if (date) {
        const tgl = date.split('-').reverse().join('-')
        const today = moment(new Date())
        const dob = moment(new Date(tgl))
        var duration = moment.duration(today.diff(dob))
        var age = duration.years().toString().padStart(2, '0') + ' Tahun ' + duration.months().toString().padStart(2, '0') + ' Bulan ' + duration.days().toString().padStart(2, '0') + ' Hari'
        var cekUmurbpjs = duration.years().toString().padStart(2, '0')
        if (this.statusIdentitas === 1) {
          if (cekUmurbpjs >= 17) {
            this.validasiKartuBPJS = 1
            this.statusUmur = 1
          } else if (cekUmurbpjs < 17) {
            this.validasiKartuBPJS = 2
            this.statusUmur = 2
          }
        } else if (this.statusIdentitas === 2) {
          if (cekUmurbpjs >= 17) {
            this.validasiKartuBPJS = 2
            this.statusUmur = 1
          } else if (cekUmurbpjs < 17) {
            this.validasiKartuBPJS = 2
            this.statusUmur = 2
          }
        } else if (this.statusIdentitas === 0) {
          if (cekUmurbpjs >= 17) {
            this.validasiKartuBPJS = 2
            this.statusUmur = 1
          } else if (cekUmurbpjs < 17) {
            this.validasiKartuBPJS = 2
            this.statusUmur = 2
          }
        }
      }
      this.formData.usia = age
    },
    print (bvModalEvent) {
      bvModalEvent.preventDefault()
      const prtHtml = document.getElementById('printCoba')
      const Winprint = window.open('', '', 'height=1000,width=1000')
      Winprint.document.write(`<!DOCTYPE html>
      <html lang="en">
      <head>
      </head>
      <body>
      ${prtHtml.innerHTML}
      </body>
      </html>`)
      Winprint.document.close()
      Winprint.focus()
      Winprint.print()
      Winprint.close()
      // this.$refs['modal-sukses-qrcode'].hide()
      this.input = ''
      this.reload()
      // setTimeout(() => {
      //   this.autofocus()
      // }, 1000)
    },
    async captureQr () {
      const el = this.$refs.captureMe
      const options = {
        type: 'dataURL'
      }
      this.output = await this.$html2canvas(el, options)
    }
  }
}
</script>
<style>
@import '../../assets/css/pages/public/reservasi-irj-griya-offline.css';
</style>
