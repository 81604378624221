<template>
  <div id="master-libur-dokter" class="mb-4">
    <b-container fluid>
      <div>
        <iq-card>
          <template v-slot:body>
            <div class="filter-hari-libur-dokter border p-2">
              <b-row class="align-items-center">
                <b-col cols="12" lg="3" class="d-flex flex-row w-100 mr-auto">
                  <div>
                    <h5 class="card-title font-weight-bold mb-0">Master Libur Dokter Rawat Jalan</h5>
                  </div>
                </b-col>
                <b-col cols="12" lg="9">
                  <div class="text-right">
                    <b-button class="custom-button-radius" size="sm" variant="primary" @click="tambahModal"><i
                      class="fas fa-plus"></i> Tambah</b-button>
                  </div>
                </b-col>
              </b-row>
            </div>
            <div class="table mb-0">
              <b-table class="mb-0" ref="table" :sort-by.sync="tablehariexecutive.sortBy"
                :sort-desc.sync="tablehariexecutive.sortDesc" :busy="tablehariexecutive.isBusy" :items="tablehariexecutive.items"
                :fields="tablehariexecutive.fields" :current-page="tablehariexecutive.currentPage"
                :per-page="tablehariexecutive.perPage" :filter="tablehariexecutive.filter" stacked="md" @filtered="onFiltered" bordered hover
                show-empty small striped>
                <template #cell(index)="data">
                  {{ (tablehariexecutive.currentPage * tablehariexecutive.perPage) - tablehariexecutive.perPage + data.index + 1 }}
                </template>
                <template #cell(action)="data">
                  <b-button @click="editModal(data.item.id)" variant=" mr-1 mb-1" size="lg">
                    <i class="fas fa-edit text-primary"></i>
                  </b-button>
                  <b-button @click="deleteModal(data.item.id)" variant=" mr-1 mb-1" size="lg">
                    <i class="fas fa-trash-alt text-danger"></i>
                  </b-button>
                </template>
              </b-table>
            </div>
            <div class="pagination-dokter border shadow-sm p-2 mb-2">
              <b-row class="align-items-center">
                <b-col cols="4" class="d-flex flex-row align-items-center">
                  <div class="w-100">
                    <b-pagination v-model="tablehariexecutive.currentPage" :total-rows="tablehariexecutive.totalRows"
                      :per-page="tablehariexecutive.perPage" size="md" class="my-0"></b-pagination>
                  </div>
                  <div class="w-100">
                    <div>
                      <span class="border-left border-dark mr-3 py-1"></span>
                      <span @click="tableRefresh" class="katalog-cursor">
                        <i class="fas fa-sync text-secondary"></i>
                      </span>
                      <span class="border-right border-dark ml-3 py-1"></span>
                    </div>
                  </div>
                </b-col>
                <b-col cols="5">
                  <div class="d-flex flex-row justify-content-end align-items-center">
                    <div class="mr-1 w-75">
                      <form class="searchbox mb-0">
                        <input type="search" v-model="tablehariexecutive.filter" class="form-control cstm-srch-dokter"
                          id="filter-input" placeholder="[Cari]">
                      </form>
                    </div>
                    <div>
                      <b-form-select class="cstm-slct-dokter" v-model="selected.filter" :options="options.optionsFilter"
                        @change="filterchange"></b-form-select>
                    </div>
                  </div>
                </b-col>
                <b-col cols="3">
                  <div class="d-flex flex-row align-items-center justify-content-end">
                    <div class="mr-3">
                      <b-form-select
                        class="cstm-slct-dokter"
                        id="per-page-select"
                        v-model="tablehariexecutive.perPage"
                        :options="tablehariexecutive.pageOptions"
                      ></b-form-select>
                    </div>
                    <div>
                      <p class="text-dark mb-0">Menampilkan {{ tablehariexecutive.perPage }} dari {{ tablehariexecutive.totalRows }} baris</p>
                    </div>
                  </div>
                </b-col>
              </b-row>
            </div>
          </template>
        </iq-card>
      </div>
    </b-container>
    <div id="modal-component-libur-dokter">
      <b-modal id="modal-tambah-libur-dokter" ref="modal-tambah-libur-dokter" size="lg" centered
        title="Tambah Libur Dokter Rawat Jalan" ok-title="Simpan Data" @ok="tambahSave" cancel-title="Tutup"  @close="clearData" @cancel="clearData">
        <b-form-group class="mb-2">
          <b-row class="align-items-center">
            <b-col cols="2">
              <span>
                <p class="text-dark mb-0">Ruangan</p>
              </span>
            </b-col>
            <b-col>
              <div class="custom-dsbld py-1 px-2 shadow-sm">
                <p class="text-dark font-weight-bold mb-0">Instalasi Rawat Jalan</p>
              </div>
            </b-col>
          </b-row>
        </b-form-group>
        <b-form-group class="mb-2">
          <b-row class="align-items-center">
            <b-col cols="2">
              <span>
                <p class="text-dark mb-0">Dokter</p>
              </span>
            </b-col>
            <b-col>
              <multiselect :show-labels="false" label="nama" placeholder="[ Dokter ]" v-model="selected.dokterrajal"
                  :searchable="true" :options="options.dokterrajal" @input="valueliburdokter" :allow-empty="false">
              </multiselect>
            </b-col>
          </b-row>
        </b-form-group>
        <b-form-group class="mb-2">
          <b-row class="align-items-center">
            <b-col cols="2">
              <span>
                <p class="text-dark mb-0">Tanggal Libur</p>
              </span>
            </b-col>
            <b-col>
              <flat-pickr :config="config" @on-change="tglEventExecutive($event, 't')"  v-model="preview.tanggal_libur" value="" id="tanggal_libur" placeholder="[ Pilih Tanggal ]"
                class="w-100 custom-flatpicker calender-small">
              </flat-pickr>
            </b-col>
          </b-row>
        </b-form-group>
        <b-form-group class="mb-2">
          <b-row class="align-items-center">
            <b-col cols="2">
              <span>
                <p class="text-dark mb-0">Keterangan</p>
              </span>
            </b-col>
            <b-col>
              <b-form-textarea class="katalog-form-area-2" v-model="tambahLiburExecutive.keterangan" placeholder="[ Keterangan ]"></b-form-textarea>
            </b-col>
          </b-row>
        </b-form-group>
        <div class="bg-dark px-2" v-if="preview.message">
          <p class="text-right text-white mb-0">{{ preview.message }}</p>
        </div>
      </b-modal>
      <b-modal id="modal-edit-libur-dokter" ref="modal-edit-libur-dokter" size="lg" centered title="Edit Hari Libur Dokter Rawat Jalan" ok-title="Perbarui Data" cancel-title="Tutup" @ok="editSave">
        <b-form>
          <b-form-group class="mb-2">
            <b-row class="align-items-center">
              <b-col cols="2">
                <span>
                  <p class="text-dark mb-0">Ruangan</p>
                </span>
              </b-col>
              <b-col>
                <div class="custom-dsbld py-1 px-2 shadow-sm">
                  <p class="text-dark font-weight-bold mb-0">Instalasi Rawat Jalan</p>
                </div>
              </b-col>
            </b-row>
          </b-form-group>
          <b-form-group class="mb-2">
            <b-row class="align-items-center">
              <b-col cols="2">
                <span>
                  <p class="text-dark mb-0">Dokter</p>
                </span>
              </b-col>
              <b-col>
                <multiselect :show-labels="false" label="nama" placeholder="[ Dokter ]" v-model="selected.Editdokterajal"
                    :searchable="true"  :options="options.doktereditrajal" @input="valueEditliburexecutive" :allow-empty="false" disabled>
                </multiselect>
              </b-col>
            </b-row>
          </b-form-group>
          <b-form-group class="mb-2">
            <b-row class="align-items-center">
              <b-col cols="2">
                <span>
                  <p class="text-dark mb-0">Tanggal Libur</p>
                </span>
              </b-col>
              <b-col>
                <flat-pickr :config="config" value="" @on-change="tglEventExecutive($event, 'e')" v-model="preview.tanggal_libur"  id="tanggal_libur" placeholder="[ Pilih Tanggal ]" class="w-100 custom-flatpicker calender-small" disabled>
                </flat-pickr>
              </b-col>
            </b-row>
          </b-form-group>
          <b-form-group class="mb-2">
            <b-row class="align-items-center">
              <b-col cols="2">
                <span>
                  <p class="text-dark mb-0">Keterangan</p>
                </span>
              </b-col>
              <b-col>
                <b-form-textarea class="katalog-form-area-2" v-model="editLiburExecutive.keterangan" placeholder="[ Keterangan ]"></b-form-textarea>
              </b-col>
            </b-row>
          </b-form-group>
        </b-form>
      </b-modal>
    <b-modal ref="modal-delete-libur-executive" size="md" centered title="Konfirmasi" ok-title="Hapus Data" ok-variant="danger" cancel-title="Tutup" @ok="deleteSave">
        <div class="py-3">
          <p class="h6 text-center text-dark mb-0">Yakin Ingin Menghapus Data ?</p>
        </div>
    </b-modal>
    <b-modal ref="modal-notif-libur-executive" size="md" centered title="Notifikasi" ok-title="Tutup" :hide-footer="true"
      :hide-header="true">
      <div class="pb-3 px-4">
        <div v-if="preview.messageValue === '1'">
          <lottie :options="preview.lottie.defaultOptions" :autoPlay="true" :width="150" :height="150" />
        </div>
        <div v-else>
          <lottie :options="preview.lottie.defaultOptions2" :autoPlay="true" :width="150" :height="150" />
        </div>
        <p class="h6 text-dark text-center mb-0 mt-2">{{ preview.message }}</p>
      </div>
    </b-modal>
    </div>
  </div>
</template>
<script>
import { xray } from '../../../config/pluginInit'
import jwtdecode from 'jwt-decode'
import Multiselect from 'vue-multiselect'
import Lottie from 'vue-lottie'
import Services from '../../../services/fatmahost/master/index'
import animationLogo from '../../../assets/lottie/checked.json'
import animationLogo2 from '../../../assets/lottie/failed.json'
import flatPickr from 'vue-flatpickr-component'
import 'flatpickr/dist/flatpickr.css'

export default {
  name: 'Liburexecutive',
  components: {
    Multiselect,
    flatPickr,
    Lottie
  },
  data () {
    return {
      tablehariexecutive: {
        isBusy: false,
        items: this.list,
        sortBy: '',
        sortDesc: true,
        fields: [
          { label: 'No', key: 'index', class: 'text-center' },
          { label: 'Nama Dokter', key: 't.nama', sortable: true },
          { label: 'Ruangan', key: 'mr.deskripsi' },
          { label: 'Tanggal Libur', key: 'ml.tanggal_libur' },
          { label: 'Keterangan', key: 'ml.keterangan' },
          { label: 'Aksi', key: 'action', class: 'text-center' }
        ],
        filter: null,
        currentPage: null,
        perPage: null,
        pageOptions: [10, 25, 50, 100],
        totalRows: null
      },
      tambahLiburExecutive: {
        object: 'libur-dokter',
        ruangan: '10101',
        dokter: '',
        tanggal_libur: '',
        keterangan: ''
      },
      editLiburExecutive: {
        object: 'libur-dokter',
        dokter: '',
        tanggal_libur: '',
        keterangan: ''
      },
      config: {
        dateFormat: 'd-m-Y',
        disableMobile: true
      },
      options: {
        dokterrajal: [],
        doktereditrajal: [],
        ruangeditrajal: [],
        optionsFilter: [
          { value: '1', text: 'Nama Dokter' }
        ]
      },
      selected: {
        filter: '1',
        dokterrajal: [],
        ruanganrajal: [],
        Editdokterajal: [],
        Editruanganrajal: []
      },
      preview: {
        lottie: {
          defaultOptions: { animationData: animationLogo, loop: true },
          defaultOptions2: { animationData: animationLogo2, loop: true }
        },
        user_name: jwtdecode(localStorage.getItem('token')).user.name,
        edit_id: '',
        delete_id: '',
        message: '',
        messageValue: ''
      }
    }
  },
  mounted () {
    xray.index()
    this.dokter_irj_id()
    this.dokter_edit_rajal_id()
  },
  methods: {
    clearData () {
      this.preview.tanggal_libur = ''
      this.selected.dokterrajal = []
      this.selected.ruanganrajal = []
      this.tambahLiburExecutive.tanggal_libur = ''
      this.tambahLiburExecutive.keterangan = ''
      this.preview.message = ''
    },
    tglEventExecutive ($event, param) {
      const date = new Date($event)
      let day = date.getDate()
      let month = (date.getMonth() + 1).toString().padStart(2, 0)
      let year = date.getFullYear()

      let currentDate = year + '-' + month + '-' + day
      if (param === 't') {
        this.tambahLiburExecutive.tanggal_libur = currentDate
      } else if (param === 'e') {
        this.editLiburExecutive.tanggal_libur = currentDate
      }
    },
    tglResExecutive ($event, param) {
      const date = new Date($event)
      let day = date.getDate()
      let month = (date.getMonth() + 1).toString().padStart(2, 0)
      let year = date.getFullYear()

      this.preview.tanggal_libur = day + '-' + month + '-' + year
      let currentDate = year + '-' + month + '-' + day
      if (param === 'a') {
        this.editLiburExecutive.tanggal_libur = currentDate
      }
    },
    async editSave () {
      try {
        let id = this.preview.edit_id
        let data = this.editLiburExecutive
        const response = await Services.edit_libur_executive(id, data)
        if (response.data.rowcount === 1 && response.data.statcode === 0) {
          this.preview.message = response.data.message
          this.preview.messageValue = '1'
        } else if (response.data.rowcount === 0 && response.data.statcode === 20001) {
          this.preview.message = response.data.message
          this.preview.messageValue = '2'
        }
        this.$refs['modal-notif-libur-executive'].show()
        this.$refs.table.refresh()
      } catch (error) {
        this.preview.message = error.message
        this.preview.messageValue = '2'
        this.$refs['modal-notif-libur-executive'].show()
      }
    },
    valueEditliburexecutive (param) {
      this.editLiburExecutive.dokter = param.id_dokter.toString()
    },
    valueEditliburruanganexecutive (param) {
      this.editLiburExecutive.ruangan = param.poli.toString()
    },
    async editModal (id) {
      let ids = id
      this.preview.edit_id = ids
      try {
        const response = await Services.show_libur_executive(ids)
        this.selected.Editdokterajal = response.data.data[0].dokter ? { id: response.data.data[0].dokter.toString(), nama: response.data.data[0].nama_dokter } : []
        this.editLiburExecutive.dokter = response.data.data[0].dokter
        this.selected.Editruanganrajal = response.data.data[0].ruangan ? { id: response.data.data[0].ruangan.toString(), nama_poli: response.data.data[0].deskripsi } : []
        this.editLiburExecutive.keterangan = response.data.data[0].keterangan
        this.tglResExecutive(response.data.data[0].tanggal_libur, 'a')
      } catch (error) {
        this.preview.message = error.message
        this.preview.messageValue = '2'
      }
      this.$refs['modal-edit-libur-dokter'].show()
    },
    async deleteSave () {
      let id = this.preview.delete_id
      try {
        const response = await Services.delete_Libur_rjl(id)
        if (response.data.rowcount === 1 && response.data.statcode === 0) {
          this.preview.message = response.data.message
          this.preview.messageValue = '1'
        } else if (response.data.rowcount === 0 && response.data.statcode === 20001) {
          this.preview.message = response.data.message
          this.preview.messageValue = '2'
        }
        this.$refs['modal-notif-libur-executive'].show()
        this.$refs.table.refresh()
      } catch (error) {
        this.preview.message = error.message
        this.preview.messageValue = '2'
        this.$refs['modal-notif-libur-executive'].show()
      }
    },
    async tambahSave (bvModalEvent) {
      bvModalEvent.preventDefault()
      try {
        let data = this.tambahLiburExecutive
        const response = await Services.createliburrajal(data)
        if (response.data.statcode === 0) {
          this.preview.message = response.data.message
          this.preview.messageValue = '1'
          this.$refs['modal-tambah-libur-dokter'].hide()
          this.$refs['modal-notif-libur-executive'].show()
        } else {
          this.preview.message = response.data.message
          this.preview.messageValue = '2'
        }
        this.$refs.table.refresh()
      } catch (error) {
        this.preview.message = error.message
        this.preview.messageValue = '2'
        this.$refs.table.refresh()
      }
    },
    onFiltered (filteredItems) {
      this.tablehariexecutive.totalRows = filteredItems.length
      this.tablehariexecutive.currentPage = 1
    },
    filterchange () {
      this.filter = ''
    },
    async dokter_irj_id () {
      const response = await Services.getdokterrjl()
      this.options.dokterrajal = response.data.data
      this.options.ruanganrajal = response.data.data
    },
    async dokter_edit_rajal_id () {
      const response = await Services.getdokterrjl()
      this.options.doktereditrajal = response.data.data
      this.options.ruangeditrajal = response.data.data
    },
    valueliburdokter (param) {
      this.tambahLiburExecutive.dokter = param.id_dokter.toString()
    },
    valueliburruangan (param) {
      this.tambahLiburExecutive.ruangan = param.poli.toString()
    },
    tableRefresh () {
      this.$refs.table.refresh()
    },
    tambahModal () {
      this.clearData()
      this.$refs['modal-tambah-libur-dokter'].show()
    },
    deleteModal (id) {
      this.preview.delete_id = id
      this.$refs['modal-delete-libur-executive'].show()
    },
    async list (ctx) {
      let filterType = this.selected.filter
      let sortfield = ''
      let sorting = ''
      if (ctx.filter === '') {
        ctx.filter = null
      }
      if (ctx.sortBy) {
        sortfield = ctx.sortBy
      } else {
        sortfield = ''
      }
      if (ctx.sortDesc === true) {
        sorting = 'DESC'
      } else if (ctx.sortDesc === false) {
        sorting = 'ASC'
      } else {
        sorting = ''
      }
      try {
        let response = await Services.list_libur_dokter_rajal(ctx.currentPage - 1, ctx.perPage, ctx.filter, filterType, sortfield, sorting)
        this.isBusy = false
        this.tablehariexecutive.totalRows = response.data.pagination.total
        this.tablehariexecutive.perPage = response.data.pagination.perPage
        this.tablehariexecutive.currentPage = response.data.pagination.current
        return response.data.data
      } catch (erorr) {
        this.isBusy = false
        return []
      }
    }
  }
}
</script>
<style>
#master-libur-dokter input[type="text"][disabled],
#modal-tambah-libur-dokter input[type="text"][disabled],
#modal-edit-libur-dokter input[type="text"][disabled] {
   background: #e9ecef;
}

#modal-tambah-libur-dokter .height-static-pabrik {
  /* max-height: 12em;
 overflow-x: hidden; */
  overflow: auto;
}

#modal-tambah-libur-dokter .multiselect, #modal-edit-libur-dokter .multiselect {
  min-height: 33px;
}

#modal-tambah-libur-dokter .multiselect__single, #modal-edit-libur-dokter .multiselect__single {
  font-size: 0.9em!important;
  font-weight: normal!important;
  line-height: 26px;
}
#modal-tambah-libur-dokter .multiselect__placeholder, #modal-edit-libur-dokter .multiselect__placeholder {
  font-size: 0.9em!important;
  font-weight: normal;
}
#modal-tambah-libur-dokter .multiselect__option, #modal-edit-libur-dokter .multiselect__option {
  font-size: 0.9em;
}
#modal-tambah-libur-dokter .multiselect__tags, #modal-edit-libur-dokter .multiselect__tags {
  overflow: hidden;
  padding: 1px 40px 0 8px!important;
  height: 33px!important;
  min-height: 33px!important;
}

#modal-edit-libur-dokter .multiselect--disabled .multiselect__tags {
  overflow: hidden;
  background: #E9ECEF;
}

#modal-edit-libur-dokter .multiselect--disabled {
  opacity: 1;
}

#modal-edit-libur-dokter .multiselect--disabled .multiselect__tags .multiselect__single {
  overflow: hidden;
  background: transparent;
}

#modal-edit-libur-dokter .multiselect__select {
    height: 30px;
}

.new-cstm-cntrl-inventori {
  background: white!important;
  color: black!important;
  border-radius: 5px!important;
  font-size: 12px!important;
  height: 33px!important;
}

#modal-tambah-libur-dokter .border-merah {
  border: 2px solid red;
}

#modal-tambah-libur-dokter .border-hijau {
  border: 2px solid green;
}

#modal-tambah-libur-dokter .modal-body {
  background: #3399331b;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

#modal-tambah-libur-dokter .modal-header {
  background: #3399333a;
}

#modal-tambah-libur-dokter .modal-footer {
  background: #3399333a;
}

#modal-edit-libur-dokter .border-merah {
  border: 2px solid red;
}

#modal-edit-libur-dokter.border-hijau {
  border: 2px solid green;
}

#modal-edit-libur-dokter .modal-body {
  background: #3399331b;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

#modal-edit-libur-dokter .modal-header {
  background: #3399333a;
}

#modal-edit-libur-dokter .modal-footer {
  background: #3399333a;
}

#master-libur-dokter .custom-button-radius {
  border: 1px solid rgba(0, 0, 0, 0.112);
  border-radius: 5px !important;
}

#master-libur-dokter .table td {
    font-size: 12px;
    font-weight: 500;
    padding-top: 0px;
    padding-bottom: 0px;
}
#master-libur-dokter .table th {
    font-size: 13px;
    font-weight: 400;
}

#master-libur-dokter .table {
  text-align: left;
}

#master-libur-dokter .cstm-select-Inventory {
  font-size: 13px;
  height: 38px !important;
  border-radius: 5px;
}

#master-libur-dokter .cstm-srch-dokter {
  font-size: 13px;
  height: 38px !important;
  border-radius: 5px;
}

#master-libur-dokter .cstm-slct-dokter {
  font-size: 13px;
  height: 38px !important;
  border-radius: 5px;
}

#master-libur-dokter .pagination-dokter .page-link {
    border: none;
    color: gray!important;
    margin-right: 15px;
}
#master-libur-dokter .pagination-dokter .page-item.active .page-link {
    background-color: transparent;
    border-color: transparent;
    color: #089bab!important;
    font-weight: bold;
}

#modal-tambah-libur-dokter .custom-flatpicker, #modal-edit-libur-dokter .custom-flatpicker {
    border: 1px solid rgb(233, 233, 233);
    height: 33px;
    padding-left: 10px;
    padding-top: 5px;
    padding-bottom: 5px;
    border: 1px solid rgb(209, 208, 208)!important;
    border-radius: 5px;
    border: 1px solid rgb(228, 228, 228);
    font-size: 12px;
    color: black;
}

#modal-tambah-libur-dokter .custom-dsbld, #modal-edit-libur-dokter .custom-dsbld {
  background: #e9ecef;
}
</style>
