<template>
  <div>
    <div class="bg-white p-3 radius shadow-sm mb-3">
      <b-row class="justify-content-between">
        <b-col cols="12" lg="2">
          <b-form-group class="mb-0">
            <b-form-input id="exampleInputText1" type="date" placeholder="Enter Name" value="Mark Jhon"></b-form-input>
          </b-form-group>
        </b-col>
        <b-col cols="12" lg="2">
          <b-form-group class="mb-0">
            <b-form-input id="exampleInputText1" type="time" placeholder="Enter Name" value="Mark Jhon"></b-form-input>
          </b-form-group>
        </b-col>
        <b-col cols="12" lg="3">
          <b-form-group class="mb-0">
            <div class="border radius">
              <multiselect v-model="vselectJenisPendonor" :show-labels="false" :limit="3" @input="valJenisPendonor" label="name"
                placeholder="Ruang Asal" :options="comboJenisPendonor" :searchable="true">
              </multiselect>
            </div>
          </b-form-group>
        </b-col>
        <b-col cols="12" lg="3">
          <b-form-group class="mb-0">
            <div class="border radius">
              <multiselect v-model="vselectJenisPendonor" :show-labels="false" :limit="3" @input="valJenisPendonor" label="name"
                placeholder="Tujuan Permintaan" :options="comboJenisPendonor" :searchable="true">
              </multiselect>
            </div>
          </b-form-group>
        </b-col>
        <b-col cols="12" lg="2">
          <b-form-group class="mb-0">
            <b-button variant="primary" class="shadow-sm border w-100"><i class="fas fa-file"></i> Permintaan
              Baru</b-button>
          </b-form-group>
        </b-col>
      </b-row>
    </div>
    <div class="bg-white p-3 radius shadow-sm mb-3">
      <b-row class="justify-content-between">
        <b-col cols="12" lg="3">
          <b-form-group class="mb-0">
            <div class="border radius">
              <multiselect v-model="vselectJenisPendonor" :show-labels="false" :limit="3" @input="valJenisPendonor" label="name"
                placeholder="Kategori" :options="comboJenisPendonor" :searchable="true">
              </multiselect>
            </div>
          </b-form-group>
        </b-col>
        <b-col cols="12" lg="6">
          <b-form-group class="mb-0">
            <div class="border radius">
              <multiselect v-model="vselectJenisPendonor" :show-labels="false" :limit="3" @input="valJenisPendonor" label="name"
                placeholder="Item Barang" :options="comboJenisPendonor" :searchable="true">
              </multiselect>
            </div>
          </b-form-group>
        </b-col>
        <b-col cols="12" lg="2">
          <b-form-group class="mb-0">
            <b-form-input id="exampleInputText1" type="number" placeholder="0" value="Mark Jhon"></b-form-input>
          </b-form-group>
        </b-col>
        <b-col cols="12" lg="1">
          <b-form-group class="mb-0">
            <b-button variant="primary" class="shadow-sm border w-100">Simpan</b-button>
          </b-form-group>
        </b-col>
      </b-row>
    </div>
    <div>
      <div class="bg-primary radius-top px-3 py-3">
        <p class="mb-0"><i class="fas fa-list"></i> Detail Item</p>
      </div>
      <div class="shadow-sm">
        <b-table class="bg-white" ref="table" stacked="md" striped hover show-empty :items="items"></b-table>
      </div>
      <div class="text-right">
        <b-button variant="warning" class="shadow-sm border mr-2" @click="historyPermintaanBarang"><i class="fas fa-undo"></i>History</b-button>
        <b-button variant="success" class="shadow-sm border"><i class="fas fa-file"></i>Simpan Dan Kirim</b-button>
      </div>
    </div>
    <b-modal ref="modal-history" size="lg" title="Histori Permintaan Barang" ok-title="Tutup" :ok-only="true"
      :no-close-on-backdrop="true" :static="true" :hide-header-close="true" centered>
      <div class="border shadow-sm mb-3 radius p-2">
        <multiselect v-model="vselectJenisPendonor" :show-labels="false" :limit="3" @input="valJenisPendonor" label="name"
          placeholder="Ruangan / Depo" :options="comboJenisPendonor" :searchable="true">
        </multiselect>
      </div>
      <div>
        <div class="bg-primary radius-top px-3 py-3">
          <p class="h6 mb-0"><i class="fas fa-list"></i> List Permintaan</p>
        </div>
        <div>
          <b-table class="bg-white shadow-sm border" ref="table" stacked="md" striped hover show-empty :items="items2"></b-table>
        </div>
      </div>
    </b-modal>
  </div>
</template>
<script>
import Multiselect from 'vue-multiselect'
export default {
  name: 'Pengiriman',
  components: {
    Multiselect
  },
  data () {
    return {
      items: [
        { nama_barang: 1, jumlah: 'Kategori 1', tag: 'Merk 1' }
      ],
      items2: [
        { nomor: 1, unit_tujuan: 'Kategori 1', no_transaksi: 'Merk 1', tanggal_permintaan: 'Merk 1', status: 'Merk 1', tag: 'Merk 1' }
      ],
      comboJenisPendonor: [
        { name: 'Langsung', id: 1 },
        { name: 'Sukarela', id: 2 },
        { name: 'Pengganti', id: 3 },
        { name: 'Aferesis', id: 4 }
      ],
      vselectJenisPendonor: []
    }
  },
  methods: {
    historyPermintaanBarang () {
      this.$refs['modal-history'].show()
    }
  }
}
</script>
