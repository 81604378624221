<template>
  <div id="dashboard-remun-rekap" class="mb-4">
    <b-container fluid>
      <div class="card-remun-dash">
        <!-- <b-row>
          <b-col cols="6" lg="3" class="pr-1 mb-2 mb-lg-0">
            <iq-card class="rounded-custom card-child-remun shadow-sm m-0 h-100">
              <div class="px-4 py-3" @click="_active('1')">
                <p :class="['h6 text-primary mb-1', classActive.all === '1' ? 'text-primary' : 'text-secondary']">TOTAL <i :class="classActive.all === '1' ? 'fas fa-check-circle text-success' : ''"></i></p>
                <p :class="['h6 font-weight-bold letter-spacing-custom mb-0', classActive.all === '1' ? 'text-dark' : 'text-secondary']">
                  <span v-if="preview.saldo === '1'">{{ data.TOTAL !== null ? _formatRibuan(data.TOTAL) : '0' }}</span>
                  <span v-else>************</span>
                </p>
              </div>
            </iq-card>
          </b-col>
          <b-col cols="6" lg="3" class="pr-lg-1 pl-1 mb-2 mb-lg-0">
            <iq-card class="rounded-custom card-child-remun shadow-sm m-0 h-100">
              <div class="px-4 py-3" @click="_active('4')">
                <p :class="['h6 text-primary mb-1', classActive.jkn === '1' ? 'text-primary' : 'text-secondary']">JKN <i :class="classActive.jkn === '1' ? 'fas fa-check-circle text-success' : ''"></i></p>
                <p :class="['h6 font-weight-bold letter-spacing-custom mb-0', classActive.jkn === '1' ? 'text-dark' : 'text-secondary']">
                  <span v-if="preview.saldo === '1'">{{ data.JKN !== null ? _formatRibuan(data.JKN) : '0' }}</span>
                  <span v-else>************</span>
                </p>
              </div>
            </iq-card>
          </b-col>
          <b-col cols="6" lg="3" class="pl-1 mb-2 mb-lg-0">
            <iq-card class="rounded-custom card-child-remun shadow-sm m-0 h-100">
              <div class="px-4 py-3" @click="_active('2')">
                <p :class="['h6 text-primary mb-1', classActive.exc === '1' ? 'text-primary' : 'text-secondary']">EXECUTIVE <i :class="classActive.exc === '1' ? 'fas fa-check-circle text-success' : ''"></i></p>
                <p :class="['h6 font-weight-bold letter-spacing-custom mb-0', classActive.exc === '1' ? 'text-dark' : 'text-secondary']">
                  <span v-if="preview.saldo === '1'">{{ data.EXECUTIVE !== null ? _formatRibuan(data.EXECUTIVE) : '0' }}</span>
                  <span v-else>************</span>
                </p>
              </div>
            </iq-card>
          </b-col>
          <b-col cols="6" lg="3" class="pr-1 pl-lg-1 mb-2 mb-lg-0">
            <iq-card class="rounded-custom card-child-remun shadow-sm m-0 h-100">
              <div class="px-4 py-3"  @click="_active('3')">
                <p :class="['h6 text-primary mb-1', classActive.reg === '1' ? 'text-primary' : 'text-secondary']">REGULER <i :class="classActive.reg === '1' ? 'fas fa-check-circle text-success' : ''"></i></p>
                <p :class="['h6 letter-spacing-custom font-weight-bold mb-0', classActive.reg === '1' ? 'text-dark' : 'text-secondary']">
                  <span v-if="preview.saldo === '1'">{{ data.REGULER !== null ? _formatRibuan(data.REGULER) : '0' }}</span>
                  <span v-else>************</span>
                </p>
              </div>
            </iq-card>
          </b-col>
        </b-row> -->
      </div>
      <div class="list-remun-dash mt-2">
        <iq-card class="rounded-custom shadow-sm">
          <template v-slot:body>
            <div class="head-tb-remun border py-2 px-2">
              <b-row class="align-items-center">
                <b-col cols="12" md="6">
                  <div>
                    <h5 class="card-title font-weight-bold text-center text-lg-left text-uppercase mb-0">REKAP {{ preview.title }} : {{ preview.tanggal_tabel }}</h5>
                  </div>
                </b-col>
                <b-col cols="12" md="6" class="d-flex flex-row justify-content-center justify-content-lg-end align-items-center">
                  <div>
                    <b-form-checkbox
                      id="checkbox-1"
                      v-model="preview.saldo"
                      name="checkbox-1"
                      value="1"
                      unchecked-value="0"
                    >
                      <span class="text-uppercase font-weight-bold">Tampilkan Nilai</span>
                    </b-form-checkbox>
                  </div>
                  <div class="mx-2">
                    <p class="text-dark mb-0">|</p>
                  </div>
                  <div>
                    <!-- <b-button :disabled="data.historyRemun === 0 ? true : false" :variant="data.historyRemun === 0 ? 'secondary' : 'primary'" size="sm" @click="_toRekap"><i class="fas fa-chart-bar"></i> RIWAYAT REMUN</b-button> -->
                    <b-button variant="primary" size="sm" @click="_toRekap"><i class="fas fa-chart-bar"></i> RINCI</b-button>
                  </div>
                </b-col>
              </b-row>
            </div>
            <div class="head-tb-remun-filter border py-2 px-2">
              <b-row class="align-items-center">
                <b-col cols="12" md="2" class="mb-3 mb-lg-0 pr-1">
                  <div>
                    <flat-pickr :config="configTgl" v-model="preview.tanggal" value="" @on-change="_valueTgl" placeholder="Range Tanggal"
                    class="w-100 custom-flatpicker calender-small bg-kuning-input" />
                  </div>
                </b-col>
                <!-- <b-col cols="12" md="2" class="mb-3 mb-lg-0 px-1">
                  <div>
                    <form class="searchbox mb-0">
                      <input v-model="preview.norm" @input="_tableRefresh" type="search" class="form-control cstm-srch-remun-dash" id="filter-input"
                        placeholder="No. Rekam Medis...">
                    </form>
                  </div>
                </b-col>
                <b-col cols="12" md="2" class="mb-3 mb-lg-0 px-1">
                  <div>
                    <form class="searchbox mb-0">
                      <input v-model="preview.namaPasien" @input="_tableRefresh" type="search" class="form-control cstm-srch-remun-dash" id="filter-input"
                        placeholder="Nama Pasien...">
                    </form>
                  </div>
                </b-col>
                <b-col cols="12" md="2" class="mb-3 mb-lg-0 px-1">
                  <div>
                    <form class="searchbox mb-0">
                      <input v-model="preview.kegiatanKode" @input="_tableRefresh" type="search" class="form-control cstm-srch-remun-dash" id="filter-input"
                        placeholder="Kegiatan Kode...">
                    </form>
                  </div>
                </b-col>
                <b-col cols="12" md="2" class="mb-3 mb-lg-0 pl-1">
                  <div>
                    <form class="searchbox mb-0">
                      <input v-model="preview.kegiatanNama" @input="_tableRefresh" type="search" class="form-control cstm-srch-remun-dash" id="filter-input"
                        placeholder="Kegiatan Nama...">
                    </form>
                  </div>
                </b-col> -->
              </b-row>
            </div>
            <div class="table-responsive mb-0">
              <b-table class="mb-0" ref="table" :sort-by.sync="table.sortBy" :sort-desc.sync="table.sortDesc"
                :filter="table.filter" @filtered="_onFiltered" :busy="table.isBusy" :current-page="table.currentPage"
                :per-page="table.perPage" responsive stacked="md" bordered hover show-empty small striped :items="table.items"
                :fields="table.fields">
                <template #table-busy>
                  <div class="text-center my-2">
                    <b-spinner class="align-middle text-primary mr-2"></b-spinner>
                    <strong class="text-dark">Memuat Data...</strong>
                  </div>
                </template>
                <template #cell(index)="data">
                  {{ (table.currentPage*table.perPage)-table.perPage + data.index + 1 }}
                </template>
                <template #cell(tr.kegiatan_tanggal_remun)="data">
                  <span>{{ new Date(data.item['tr.kegiatan_tanggal_remun']) | formatDate }}</span>
                </template>
                <template #cell(tr.remun_nilai)="data">
                  <span class="letter-spacing-custom" v-if="preview.saldo === '1'">{{ _formatRibuan(data.item['tr.remun_nilai']) }}</span>
                  <span v-else>************</span>
                </template>
              </b-table>
            </div>
            <div class="pagination-remun-dash border shadow-sm p-2 mb-0">
              <b-row class="align-items-center justify-content-center justify-content-lg-between">
                <b-col lg="3" class="d-flex flex-row align-items-center justify-content-center justify-content-lg-between mb-3 mb-lg-0">
                  <div class="">
                    <b-pagination
                      v-model="table.currentPage"
                      :total-rows="table.totalRows"
                      :per-page="table.perPage"
                      size="md"
                      class="my-0"
                    ></b-pagination>
                  </div>
                  <div class="">
                    <div>
                      <span class="border-left border-dark mr-3 py-1"></span>
                      <span @click="_tableRefresh" class="katalog-cursor">
                        <i class="fas fa-sync text-primary"></i>
                      </span>
                      <span class="border-right border-dark ml-3 py-1"></span>
                    </div>
                  </div>
                </b-col>
                <b-col lg="3">
                  <div class="d-flex flex-row align-items-center justify-content-center justify-content-lg-end">
                    <div class="mr-3">
                      <b-form-select
                        class="cstm-select-remun-dash"
                        id="per-page-select"
                        v-model="table.perPage"
                        :options="table.pageOptions"
                      ></b-form-select>
                    </div>
                    <div>
                      <!-- <p class="text-dark mb-0">Menampilkan {{ table.perPage }} dari {{ table.totalRows }} baris</p> -->
                      <p class="text-dark mb-0">Menampilkan {{ table.totalRows }} Tindakan</p>
                    </div>
                  </div>
                </b-col>
              </b-row>
            </div>
          </template>
        </iq-card>
        <!-- <pre>{{ table }}</pre> -->
      </div>
    </b-container>
  </div>
</template>
<script>
import { xray } from '../../../config/pluginInit'
// import newServices from '../../../services/fatmahost/pegawai/index.ts'
import Services from '../../../services/fatmahost/index.js'
import flatPickr from 'vue-flatpickr-component'
import { Indonesian as IndonesianLocale } from 'flatpickr/dist/l10n/id.js'
import 'flatpickr/dist/flatpickr.css'
// import jwtdecode from 'jwt-decode'

export default {
  name: 'Remun',
  components: {
    flatPickr
  },
  data () {
    return {
      data: {},
      table: {
        isBusy: false,
        items: this._list,
        sortBy: '',
        sortDesc: false,
        fields: [
          { label: 'NO.', key: 'index', class: 'text-lg-center py-2 col-1' },
          { label: 'NAMA KEGIATAN', key: 'tr.kegiatan_nama' },
          { label: 'KODE KEGIATAN', key: 'tr.kegiatan_kode' },
          { label: 'JASA', key: 'tr.remun_nilai', class: 'text-lg-right' }
        ],
        filter: null,
        currentPage: 1,
        perPage: 10,
        pageOptions: [10, 25, 50, 100],
        totalRows: null,
        type: ''
      },
      classActive: {
        all: '1',
        exc: '0',
        reg: '0',
        jkn: '0'
      },
      options: {
        optionsFilter: [
          { text: 'Nama Kegiatan', value: '1' },
          { text: 'Kegiatan Kelompok', value: '2' }
        ],
        optionsKegKelompok: [
          { text: 'Rawat Jalan', value: 'rawatjalan' },
          { text: 'Tindakan', value: 'tindakan' },
          { text: 'Visite', value: 'visite' },
          { text: 'Penunjang', value: 'penunjang' }
        ]
      },
      selected: {
        filter: '1',
        kegiatan_kelompok: 'rawatjalan'
      },
      configTgl: {
        locale: IndonesianLocale,
        disableMobile: true,
        altFormat: 'j M, Y',
        dateFormat: 'Y-m-d',
        maxDate: 'today',
        mode: 'range'
      },
      preview: {
        id: xray.currentUser().pegawai_id.toString(),
        periode: '000000',
        userJwt: xray.currentUser(),
        title: 'TOTAL',
        message: '',
        valMessage: '',
        saldo: '0',
        tanggal: null,
        tanggal_awal: null,
        tanggal_akhir: null,
        tanggal_tabel: '',
        norm: '',
        namaPasien: '',
        kegiatanKode: '',
        kegiatanNama: ''
      }
    }
  },
  mounted () {
    xray.index()
    this._getTodayLastFirstDate()
  },
  methods: {
    _toRekap () {
      this.$router.push({
        name: 'sdm.remun-v2'
      })
    },
    async _list (ctx) {
      this.isBusy = true
      try {
        const body = `{
            "param1": "",
            "param2": {
              "object": "EREMUNERASI_find_rekap1",
              "xparam0": "${this.preview.tanggal_awal}",
              "xparam1": "${this.preview.tanggal_akhir}",
              "xparam2": "${xray.currentUser().pegawai_id}",
              "xpage": "${ctx.currentPage - 1}",
              "xlimit": "${ctx.perPage}",
              "xfilter1": "${this.table.type}",
              "xfilter2": "${this.preview.norm}",
              "xfilter3": "${this.preview.namaPasien}",
              "xfilter4": "${this.preview.kegiatanKode}",
              "xfilter5": "${this.preview.kegiatanNama}"
          }
        }`
        const response = await Services.middleware.find_v2(body)
        if (response.data.statcode === 0) {
          this.isBusy = false
          this.table.totalRows = response.data.pagination.total
          this.table.perPage = response.data.pagination.perPage
          this.table.currentPage = response.data.pagination.current + 1
          return response.data.data
        } else {
          this.isBusy = false
          return []
        }
      } catch (e) {
        this.isBusy = false
        this.preview.message = e.message
        return []
      }
    },
    _onFiltered (filteredItems) {
      this.table.totalRows = filteredItems.length
    },
    _filterchange () {
      this.table.filter = ''
      this.$refs.table.refresh()
    },
    _tableRefresh () {
      this.table.currentPage = 1
      this.$refs.table.refresh()
    },
    _active (value) {
      if (value === '1') {
        this.classActive.all = '1'
        this.classActive.exc = '0'
        this.classActive.reg = '0'
        this.classActive.jkn = '0'
        this.table.type = ''
        this.selected.filter = '1'
        this.selected.kegiatan_kelompok = 'rawatjalan'
        this.preview.title = 'TOTAL'
      } else if (value === '2') {
        this.classActive.all = '0'
        this.classActive.exc = '1'
        this.classActive.reg = '0'
        this.classActive.jkn = '0'
        this.table.type = 'EXECUTIVE'
        this.selected.filter = '1'
        this.selected.kegiatan_kelompok = 'rawatjalan'
        this.preview.title = 'EXECUTIVE'
      } else if (value === '3') {
        this.classActive.all = '0'
        this.classActive.exc = '0'
        this.classActive.reg = '1'
        this.classActive.jkn = '0'
        this.table.type = 'REGULER'
        this.selected.filter = '1'
        this.selected.kegiatan_kelompok = 'rawatjalan'
        this.preview.title = 'REGULER'
      } else if (value === '4') {
        this.classActive.all = '0'
        this.classActive.exc = '0'
        this.classActive.reg = '0'
        this.classActive.jkn = '1'
        this.table.type = 'JKN'
        this.selected.filter = '1'
        this.selected.kegiatan_kelompok = 'rawatjalan'
        this.preview.title = 'JKN'
      }
      this.table.currentPage = 1
      this.$refs.table.refresh()
    },
    _formatRibuan (value) {
      if (value !== null || value !== '' || value) {
        const amount = parseInt(value)
        return amount.toLocaleString()
      } else {
        return '0'
      }
    },
    _getTodayLastFirstDate () {
      const today = new Date()
      const startOfMonth = new Date(today.getFullYear(), today.getMonth(), 1)
      const endOfMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0)
      function padZero (value) {
        return value < 10 ? '0' + value : value
      }
      const startFormatted = `${startOfMonth.getFullYear()}-${padZero(startOfMonth.getMonth() + 1)}-${padZero(startOfMonth.getDate())}`
      const endFormatted = `${endOfMonth.getFullYear()}-${padZero(endOfMonth.getMonth() + 1)}-${padZero(endOfMonth.getDate())}`
      this.preview.tanggal_awal = String(startFormatted)
      this.preview.tanggal_akhir = String(endFormatted)
      //
      this.preview.tanggal_tabel = this._convertTanggal(String(startFormatted.split('-').reverse().join('-'))) + ' - ' + this._convertTanggal(String(endFormatted.split('-').reverse().join('-')))
    },
    _convertTanggal (value) {
      const [day, month, year] = value.split('-')
      const date = new Date(`${year}-${month}-${day}`)
      const options = { year: 'numeric', month: 'long', day: 'numeric' }
      const tanggalDalamBahasaIndonesia = date.toLocaleDateString('id-ID', options)
      return tanggalDalamBahasaIndonesia
    },
    _valueTgl (e) {
      this.preview.tanggal_awal = formated(new Date(e[0]))
      this.preview.tanggal_akhir = formated(new Date(e[1]))
      function formated (value) {
        const day = String(value.getDate()).padStart(2, '0')
        const month = String(value.getMonth() + 1).padStart(2, '0')
        const year = String(value.getFullYear())
        return year + '-' + month + '-' + day
      }
      this.preview.tanggal_tabel = this._convertTanggal(String(this.preview.tanggal_awal.split('-').reverse().join('-'))) + ' - ' + this._convertTanggal(String(this.preview.tanggal_akhir.split('-').reverse().join('-')))
      this.table.currentPage = 1
      this.$refs.table.refresh()
    }
  }
}
</script>
<style>
#dashboard-remun-rekap .card-child-remun {
  cursor: pointer;
}
#dashboard-remun-rekap .table td {
  font-size: .75rem;
  font-weight: 500;
  padding-top: 0px;
  padding-bottom: 0px;
}
#dashboard-remun-rekap .table th {
  font-size: .8rem;
  font-weight: 400;
}
#dashboard-remun-rekap .rounded-custom {
  border-radius: 5px;
}
#dashboard-remun-rekap .page-link {
  border: none;
  color: gray!important;
  margin-right: 15px;
}
#dashboard-remun-rekap .page-item.active .page-link {
  background-color: transparent;
  border-color: transparent;
  color: #089bab!important;
  font-weight: bold;
}
#dashboard-remun-rekap .cstm-srch-remun-dash {
  font-size: 13px;
  height: 33px!important;
  border-radius: 2px;
}
#dashboard-remun-rekap .cstm-slct-remun-dash {
  font-size: 13px;
  height: 38px!important;
  border-radius: 5px;
}
#dashboard-remun-rekap .cstm-select-remun-dash {
  font-size: 13px;
  height: 38px!important;
  border-radius: 5px;
}
#dashboard-remun-rekap .custom-flatpicker {
  height: 33px;
  padding-left: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
  border-radius: 3px;
  border: 1px solid rgb(228, 228, 228);
  font-size: 0.8rem;
  color: black;
}
#dashboard-remun-rekap .letter-spacing-custom {
  letter-spacing: .8px
}
</style>
