<template>
  <b-container fluid>
    <b-row>
      <b-col cols="12" md="6" class="mb-4">
        <iq-card class="h-100">
          <div class="head-klaim-view px-4 py-4">
            <p class="h5 text-primary mb-0"><i class="fas fa-user-check"></i> INFORMASI DIRI</p>
          </div>
          <hr class="m-0 p-0">
          <hr class="mt-1 mb-0 mx-0 p-0">
          <div class="body-klaim-view px-4 py-4">
            <b-row class="mb-3">
              <b-col md="3">
                <div>
                  <p class="h6 text-dark mb-0">NAMA</p>
                </div>
              </b-col>
              <b-col md="1">
                <p class="h6 text-dark mb-0">:</p>
              </b-col>
              <b-col md="8">
                <p class="h6 text-dark font-weight-bold mb-0">{{data.NAMA}}</p>
              </b-col>
            </b-row>
            <b-row class="mb-3">
              <b-col md="3">
                <div>
                  <p class="h6 text-dark mb-0">NORM</p>
                </div>
              </b-col>
              <b-col md="1">
                <p class="h6 text-dark mb-0">:</p>
              </b-col>
              <b-col md="8">
                <p class="h6 text-dark font-weight-bold mb-0">{{data.NORM}}</p>
              </b-col>
            </b-row>
            <b-row class="mb-3">
              <b-col md="3">
                <div>
                  <p class="h6 text-dark mb-0">NOPEN</p>
                </div>
              </b-col>
              <b-col md="1">
                <p class="h6 text-dark mb-0">:</p>
              </b-col>
              <b-col md="8">
                <p class="h6 text-dark font-weight-bold mb-0">{{data.NOPEN}}</p>
              </b-col>
            </b-row>
            <b-row class="mb-3">
              <b-col md="3">
                <div>
                  <p class="h6 text-dark mb-0">NOSEP</p>
                </div>
              </b-col>
              <b-col md="1">
                <p class="h6 text-dark mb-0">:</p>
              </b-col>
              <b-col md="8">
                <p class="h6 text-dark font-weight-bold mb-0">{{data.NOSEP}}</p>
              </b-col>
            </b-row>
            <b-row class="mb-3">
              <b-col md="3">
                <div>
                  <p class="h6 text-dark mb-0">MASUK</p>
                </div>
              </b-col>
              <b-col md="1">
                <p class="h6 text-dark mb-0">:</p>
              </b-col>
              <b-col md="8">
                <p class="h6 text-dark font-weight-bold mb-0">{{data.MASUK}}</p>
              </b-col>
            </b-row>
            <b-row class="mb-3">
              <b-col md="3">
                <div>
                  <p class="h6 text-dark mb-0">KELUAR</p>
                </div>
              </b-col>
              <b-col md="1">
                <p class="h6 text-dark mb-0">:</p>
              </b-col>
              <b-col md="8">
                <p class="h6 text-dark font-weight-bold mb-0">{{data.KELUAR}}</p>
              </b-col>
            </b-row>
            <b-row class="mb-3">
              <b-col md="3">
                <div>
                  <p class="h6 text-dark mb-0">RUANGAN</p>
                </div>
              </b-col>
              <b-col md="1">
                <p class="h6 text-dark mb-0">:</p>
              </b-col>
              <b-col md="8">
                <p class="h6 text-dark font-weight-bold mb-0">{{data.DESKRIPSI}}</p>
              </b-col>
            </b-row>
            <b-row class="mb-0">
              <b-col md="3">
                <div>
                  <p class="h6 text-dark mb-0">DPJP</p>
                </div>
              </b-col>
              <b-col md="1">
                <p class="h6 text-dark mb-0">:</p>
              </b-col>
              <b-col md="8">
                <p class="h6 text-dark font-weight-bold mb-0">{{data.DPJP}}</p>
              </b-col>
            </b-row>
          </div>
        </iq-card>
      </b-col>
      <b-col cols="12" md="6" class="mb-4">
        <iq-card class="h-100">
          <div class="head-klaim-view px-4 py-4">
            <p class="h5 text-primary mb-0"><i class="fas fa-book"></i> INFORMASI KLAIM</p>
          </div>
          <hr class="m-0 p-0">
          <hr class="mt-1 mb-0 mx-0 p-0">
          <div class="body-klaim-view px-4 py-4">
            <b-row class="mb-3">
              <b-col md="3">
                <div>
                  <p class="h6 text-dark mb-0">JENIS PASIEN</p>
                </div>
              </b-col>
              <b-col md="1">
                <p class="h6 text-dark mb-0">:</p>
              </b-col>
              <b-col md="8">
                <p class="h6 text-dark font-weight-bold mb-0">{{data.JENIS_PASIEN}}</p>
              </b-col>
            </b-row>
            <b-row class="mb-3">
              <b-col md="3">
                <div>
                  <p class="h6 text-dark mb-0">DC BPJS</p>
                </div>
              </b-col>
              <b-col md="1">
                <p class="h6 text-dark mb-0">:</p>
              </b-col>
              <b-col md="8">
                <p class="h6 text-dark font-weight-bold mb-0">{{data.DESKRIPSI_DC_BPJS}}</p>
              </b-col>
            </b-row>
            <b-row class="mb-3">
              <b-col md="3">
                <div>
                  <p class="h6 text-dark mb-0">DC KEMKES</p>
                </div>
              </b-col>
              <b-col md="1">
                <p class="h6 text-dark mb-0">:</p>
              </b-col>
              <b-col md="8">
                <p class="h6 text-dark font-weight-bold mb-0">{{data.DESKRIPSI_DC_KEMKES}}</p>
              </b-col>
            </b-row>
            <b-row class="mb-3">
              <b-col md="3">
                <div>
                  <p class="h6 text-dark mb-0">DESKRIPSI STATUS</p>
                </div>
              </b-col>
              <b-col md="1">
                <p class="h6 text-dark mb-0">:</p>
              </b-col>
              <b-col md="8">
                <p class="h6 text-dark font-weight-bold mb-0">{{data.DESKRIPSI_STATUS}}</p>
              </b-col>
            </b-row>
            <b-row class="mb-3">
              <b-col md="3">
                <div>
                  <p class="h6 text-dark mb-0">TANGGAL</p>
                </div>
              </b-col>
              <b-col md="1">
                <p class="h6 text-dark mb-0">:</p>
              </b-col>
              <b-col md="8">
                <p class="h6 text-dark font-weight-bold mb-0">{{new Date(data.TANGGAL) | formatDate}}</p>
              </b-col>
            </b-row>
            <b-row class="mb-0">
              <b-col md="3">
                <div>
                  <p class="h6 text-dark mb-0">USER</p>
                </div>
              </b-col>
              <b-col md="1">
                <p class="h6 text-dark mb-0">:</p>
              </b-col>
              <b-col md="8">
                <p class="h6 text-dark font-weight-bold mb-0">{{data.USER}}</p>
              </b-col>
            </b-row>
          </div>
        </iq-card>
      </b-col>
      <b-col cols="12" md="6">
        <iq-card class="h-100">
          <div class="head-klaim-view px-4 py-4">
            <p class="h5 text-primary mb-0"><i class="fas fa-money-bill-alt"></i> INFORMASI TARIF</p>
          </div>
          <hr class="m-0 p-0">
          <hr class="mt-1 mb-0 mx-0 p-0">
          <div class="body-klaim-view px-4 py-4">
            <b-row class="mb-3">
              <b-col md="3">
                <div>
                  <p class="h6 text-dark mb-0">CODE CBG</p>
                </div>
              </b-col>
              <b-col md="1">
                <p class="h6 text-dark mb-0">:</p>
              </b-col>
              <b-col md="8">
                <p class="h6 text-dark font-weight-bold mb-0">{{data.CODECBG}}</p>
              </b-col>
            </b-row>
             <b-row class="mb-3">
              <b-col md="3">
                <div>
                  <p class="h6 text-dark mb-0">TARIF CBG</p>
                </div>
              </b-col>
              <b-col md="1">
                <p class="h6 text-dark mb-0">:</p>
              </b-col>
              <b-col md="8">
                <p class="h6 text-dark font-weight-bold text-right mb-0">{{data.TARIFCBG}}</p>
              </b-col>
            </b-row>
            <b-row class="mb-3">
              <b-col md="3">
                <div>
                  <p class="h6 text-dark mb-0">TARIF RS</p>
                </div>
              </b-col>
              <b-col md="1">
                <p class="h6 text-dark mb-0">:</p>
              </b-col>
              <b-col md="8">
                <p class="h6 text-dark font-weight-bold text-right mb-0">{{data.TARIFRS}}</p>
              </b-col>
            </b-row>
            <hr>
            <b-row class="mb-3">
              <b-col md="3">
                <div>
                  <p class="h6 text-dark mb-0">SELISIH</p>
                </div>
              </b-col>
              <b-col md="1">
                <p class="h6 text-dark mb-0">:</p>
              </b-col>
              <b-col md="8">
                <p class="h6 text-danger font-weight-bold text-right mb-0">{{data.SELISIH}}</p>
              </b-col>
            </b-row>
          </div>
        </iq-card>
      </b-col>
    </b-row>
    <b-row class="justify-content-center mt-4">
      <b-col md="2">
        <iq-card>
          <button type="button" @click="EklaimMonitoringList" class="btn btn-primary btn-block">
            <i class="fas fa-undo"></i>  Kembali
          </button>
        </iq-card>
      </b-col>
    </b-row>
    <b-modal ref="modal-sukses" size="md" title="Informasi" ok-title="Oke" :ok-only=true :hide-header=true
        centered>
        <div class="text-center px-lg-4 px-2">
          <lottie :options="defaultOptions" :autoPlay="true" :width="250" :height="250" />
          <p class="h6 font-weight-bold text-success mb-0">Data Berhasil Disimpan</p>
        </div>
      </b-modal>
      <b-modal ref="modal-gagal" size="md" title="Informasi" ok-title="Kembali" :ok-only=true :hide-header=true
        centered>
        <div class="text-center px-lg-4 px-2">
          <p class="h6 text-danger font-weight-bold mb-0">Data Gagal Disimpan</p>
        </div>
      </b-modal>
  </b-container>
</template>
<script>
import { xray } from '../../config/pluginInit'
import Klaim from '../../services/eklaim'
import Lottie from 'vue-lottie'
import animationLogo from '../../assets/lottie/checked.json'
import animationLogo2 from '../../assets/lottie/failed.json'
export default {
  name: 'MeningitisView',
  components: {
    Lottie
  },
  mounted () {
    xray.index()
    this.id = this.$route.params.id
    this.viewEklaimMonitoring(this.id)
  },
  methods: {
    EklaimMonitoringList () {
      this.$router.push({ name: 'eklaim.vlist' })
    },
    async viewEklaimMonitoring (id) {
      await Klaim.show(id).then(response => {
        if (response.status === 200) {
          // console.log(response.data.data)
          this.data = response.data.data
        }
      }).catch(error => {
        this.error = error.response || error.response.status
      })
    }
  },
  data () {
    return {
      defaultOptions: { animationData: animationLogo, loop: true },
      defaultOptions2: { animationData: animationLogo2, loop: true },
      error: '',
      pasien: [],
      data: []
    }
  }
}
</script>
