<template>
  <div>
    <div v-show="isLoading">
        <div id="show-overlay"></div>
      <Loader :style="{ opacity: .5 }" />
    </div>
    <div class="apm-dtl-new">
      <b-container fluid class="h-100 px-0">
        <div class="h-100">
          <div class="box-parent full-height bg-white shadow-sm border">
            <!-- <div class="head bg-primary px-3 py-3">
              <p class="h3 text-center font-weight-bold mb-0">ANJUNGAN PENDAFTARAN MANDIRI RAWAT JALAN KHUSUS</p>
            </div> -->
            <div class="body px-5 pt-4">
              <div class="d-flex flex-row align-items-center mt-4 mb-5 justify-content-between">
                <div>
                  <img :src="require('../../assets/images/logo_kemenkes_new.svg')" width="200rem" class="d-inline-block align-top" alt="kemenkes">
                </div>
                <div>
                  <img :src="require('../../assets/images/myrsf1.svg')" width="100rem" class="d-inline-block align-top" alt="kemenkes">
                </div>
              </div>
              <div class="mb-5 px-4 py-4">
                <b-row class="justify-content-center align-items-center">
                  <b-col class="d-flex flex-row align-items-center" cols="12" lg="4">
                      <div class="bounce">
                        <img :src="require('../../assets/images/myrsf.svg')" width="250rem" class="d-inline-block align-top" alt="kemenkes">
                      </div>
                    <div class="px-2">
                      <p class="h3 text-primary font-weight-bold mb-3">MYRSF <span class="text-dark">APP</span></p>
                      <p class="h6 text-secondary mb-1">Available for</p>
                      <p class="h4 text-dark font-weight-bold mb-1">Download</p>
                      <div class="mb-4">
                        <img :src="require('../../assets/images/playstore.png')" width="150rem" class="d-inline-block align-top" alt="kemenkes">
                      </div>
                      <div>
                        <img :src="require('../../assets/images/myrsf-barcode.jpeg')" width="150rem" class="d-inline-block align-top border shadow-sm" alt="kemenkes">
                      </div>
                    </div>
                  </b-col>
                  <b-col cols="12" lg="8">
                    <div class="d-flex flex-row align-items-center w-100">
                      <div class="w-75 mr-2">
                        <input id="formqr" :value="input" @keyup.enter="cekReservasi()" class="input shadow custom-input-style" type="search" @input="onInputChange"
                        placeholder="Scan Nomor Booking..." autocomplete="off">
                      </div>
                      <div class="text-center w-25">
                        <template>
                          <div class="shadow rounded">
                            <b-button variant="primary" class="btn-block btn-proses-padding" @click="cekReservasi()"><span
                                class="font-weight-bold"><i class="fas fa-sign-in-alt"></i> PROSES</span></b-button>
                          </div>
                        </template>
                      </div>
                    </div>
                    <div class="mt-5">
                      <div class="text-center shadow">
                        <template>
                          <SimpleKeyboard @onChange="onChange" @onKeyPress="onKeyPress" :input="input"/>
                        </template>
                      </div>
                    </div>
                  </b-col>
                </b-row>
                <!-- <b-row class="justify-content-center align-items-center mb-4 mt-5">
                  <b-col cols="12" lg="10">
                    <div class="parent">
                      <div class="text-center shadow mt-3">
                        <template>
                          <SimpleKeyboard @onChange="onChange" @onKeyPress="onKeyPress" :input="input"/>
                        </template>
                      </div>
                    </div>
                  </b-col>
                </b-row> -->
              </div>
            </div>
          </div>
        </div>
      </b-container>
      <b-modal ref="modal-previeww" size="lg" :hide-footer="true" :hide-header="true" :no-close-on-backdrop="true" :static="true" centered>
        <div>
          <div class="message-respon">
            <div v-if="okresponse !== ''">
              <div>
                <div id="printMe">
                  <div class="datenow">
                    <p class="h6 font-weight-light text-dark text-capitalize mb-0">Tanggal Reservasi : <span
                        class="font-weight-bold">{{ dateBoard }}</span></p>
                  </div>
                  <hr>
                  <b-row class="py-3">
                    <b-col cols="12" lg="6" class="mb-3">
                      <div class="mb-2">
                        <p class="h6 font-weight-normal text-capitalize mb-0">Nama Pasien</p>
                        <p class="h6 text-capitalize font-weight-bold text-dark mb-0">{{ okresponse.NAMA }}</p>
                      </div>
                      <div class="mb-2">
                        <p class="h6 font-weight-normal text-capitalize mb-0">Ruangan</p>
                        <p class="h6 text-capitalize font-weight-bold text-dark mb-0">{{ okresponse.RUANGAN }}</p>
                      </div>
                      <div class="mb-2">
                        <p class="h6 font-weight-normal text-capitalize mb-0">Dokter</p>
                        <p class="h6 text-capitalize font-weight-bold text-dark mb-0">{{ okresponse.DOKTER }}</p>
                      </div>
                      <div class="mb-2">
                        <p class="h6 font-weight-normal text-capitalize mb-0">Tanggal Reservasi</p>
                        <p class="h6 text-capitalize font-weight-bold text-dark mb-0">{{ new Date(okresponse.TANGGAL_RESERVASI) | formatDate }}</p>
                      </div>
                      <div class="mb-2">
                        <p class="h6 font-weight-normal text-capitalize mb-0">Estimasi</p>
                        <p class="h6 text-capitalize font-weight-bold text-dark mb-0">{{ okresponse.ESTIMASI }}</p>
                      </div>
                      <div class="mb-0">
                        <p class="h6 font-weight-normal text-capitalize mb-0">Checkin</p>
                        <p class="h6 text-capitalize font-weight-bold text-dark mb-0">{{ okresponse.CHECK_IN }}</p>
                      </div>
                    </b-col>
                    <b-col cols="12" lg="6">
                      <div class="mb-2">
                        <p class="h6 font-weight-normal text-capitalize mb-0">Nomor Rekam Medis</p>
                        <p class="h6 text-capitalize font-weight-bold text-dark mb-0">{{ okresponse.NORM }}</p>
                      </div>
                      <div class="mb-2">
                        <p class="h6 font-weight-normal text-capitalize mb-0">Nomor Ref</p>
                        <p class="h6 text-capitalize font-weight-bold text-dark mb-0">{{ okresponse.NOMOR }}</p>
                      </div>
                      <div class="mb-2">
                        <p class="h6 font-weight-normal text-capitalize mb-0">Nomor Antrian</p>
                        <p class="h6 text-capitalize font-weight-bold text-dark mb-0">{{ okresponse.ANTRIAN }}</p>
                      </div>
                      <div class="mb-2">
                        <p class="h6 font-weight-normal text-capitalize mb-0">Cara Bayar</p>
                        <p class="h6 text-capitalize font-weight-bold text-dark mb-0">{{ okresponse.CARABAYAR }}</p>
                      </div>
                      <div class="mb-2">
                        <p class="h6 font-weight-normal text-capitalize mb-0">Nomor Kartu BPJS</p>
                        <p class="h6 text-capitalize font-weight-bold text-dark mb-0" v-if="okresponse.NOMOR_KARTU">{{ okresponse.NOMOR_KARTU }}</p>
                        <p class="h6 text-capitalize font-weight-bold text-dark mb-0" v-else>-</p>
                      </div>
                      <div class="mb-0">
                        <p class="h6 font-weight-normal text-capitalize mb-0">Nomor Rujukan BPJS</p>
                        <p class="h6 text-capitalize font-weight-bold text-dark mb-0" v-if="okresponse.NOMOR_RUJUKAN">{{ okresponse.NOMOR_RUJUKAN }}</p>
                        <p class="h6 text-capitalize font-weight-bold text-dark mb-0" v-else>-</p>
                      </div>
                    </b-col>
                  </b-row>
                </div>
                <hr>
                <div class="text-center">
                  <b-button variant="primary" @click="print"><i class="fas fa-print"></i> Print Bukti</b-button>
                </div>
              </div>
            </div>
            <div v-else-if="errRespon !== ''">
              <div>
                <lottie :options="defaultOptions2" :autoPlay="true" :width="100" :height="100" />
              </div>
              <p class="h6 font-weight-normal text-center text-capitalize text-dark mb-0 mt-2">{{ errRespon }}</p>
              <div class="mt-4 text-center">
                <b-button class="px-4" variant="danger" @click="autoClose">Tutup</b-button>
              </div>
            </div>
          </div>
        </div>
      </b-modal>
      <b-modal ref="modal-preview" size="lg" :hide-footer="true" :hide-header="true" :no-close-on-backdrop="true" :static="true" centered>
        <div class="ticket">
          <!-- <div class="ticket-header text-center">
            <h5>Hospital Reservation Ticket</h5>
          </div> -->
          <div v-if="showReservasi !== ''">
            <div class="ticket-body">
              <div class="ticket-row d-flex justify-content-between text-dark font-weight-bold">
                <strong>NO BOOKING:</strong>
                <span>{{ showReservasi.NOMOR_RESERVASI }}</span>
              </div>
              <div class="ticket-row d-flex justify-content-between text-dark font-weight-bold">
                <strong>NIK:</strong>
                <span>{{ showReservasi.NIK }}</span>
              </div>
              <div class="ticket-row d-flex justify-content-between text-dark font-weight-bold">
                <strong>NAMA:</strong>
                <span>{{ showReservasi.NAMA }}</span>
              </div>
              <div class="ticket-row d-flex justify-content-between text-dark font-weight-bold">
                <strong>TANGGAL LAHIR:</strong>
                <span>{{ showReservasi.TANGGAL_LAHIR }}</span>
              </div>
              <div class="ticket-row d-flex justify-content-between text-dark font-weight-bold">
                <strong>POLI TUJUAN:</strong>
                <span>{{ showReservasi.RUANGAN }}</span>
              </div>
              <div class="ticket-row d-flex justify-content-between text-dark font-weight-bold">
                <strong>DOKTER:</strong>
                <span>{{ showReservasi.DOKTER }}</span>
              </div>
              <div class="ticket-row d-flex justify-content-between text-dark font-weight-bold">
                <strong>CARA BAYAR:</strong>
                <span>{{ showReservasi.CARABAYAR }}</span>
              </div>
            </div>
          </div>
        </div>
        <div class="ticket-footer text-center mt-4">
            <b-button @click="postQr" variant="primary" class="mx-2">PROSES & CETAK</b-button>
          </div>
      </b-modal>
      <b-modal ref="modal-finger" size="lg" :hide-footer="true" :hide-header="true" :no-close-on-backdrop="true" hide-backdrop :static="true" centered>
        <div>
          <div class="message-respon">
            <div>
              <!-- <div>
                <lottie :options="defaultOptions2" :autoPlay="true" :width="100" :height="100" />
              </div> -->
              <p class="h6 font-weight-normal text-center text-capitalize text-dark mb-0 mt-2">{{ messageFinger }}</p>
              <div class="mt-4 text-center">
                <b-button class="px-4 mr-3" variant="warning" @click="openFinger()">Open Fingerprint</b-button>
                <b-button class="px-4" variant="primary" @click="cekReservasi()">Lanjutkan Proses</b-button>
              </div>
            </div>
          </div>
        </div>
      </b-modal>
    </div>
    <template>
      <div>
        <b-sidebar v-model="isSidebarVisible" id="sidebar-right" width="500px" bg-variant="white" no-header right shadow>
          <div>
            <div class="message-respon">
              <div class="align-item-center">
                <!-- <div class="m-5">
                  <img :src="require('../../assets/images/fingerbpjs.png')" width="100%" class="center" alt="finger">
                </div> -->
                <div class="mt-5"></div>
                <div>
                  <lottie :options="fingerprintDevice" :autoPlay="true" :width="200" :height="200" />
                </div>
                <p class="h6 font-weight-normal text-center text-capitalize text-dark mb-0 mt-2 m-5">
                  Silahkan lakukan fingerprint terlebih dahulu untuk melanjutkan proses.
                </p>
                <div class="mt-4 text-center">
                  <b-button class="btn-lg px-5 py-3 mr-3" variant="warning" @click="openFinger()">Open Fingerprint</b-button>
                  <b-button class="btn-lg px-5 py-3" variant="primary" @click="cekReservasi()">Lanjutkan Proses</b-button>
                </div>
              </div>
            </div>
          </div>
        </b-sidebar>
      </div>
    </template>

    <div id="printCoba" style="display: none;">
      <div class="page" style="border: none; width: 110mm; height: 20mm; margin: 0px;">
        <div class="page-area" style="font-family: Arial, Helvetica, sans-serif; border: none;">
          <table class="header font-arial" style="width: 100%; padding-bottom: 10px; border-bottom: 1px solid black;">
            <tbody>
              <tr>
                <!-- <td><img src="<?= base_url('/assets/img/logo2.png') ?>" alt="logo-fatmawati"></td> -->
                <td style="width: 50%;">
                  <strong>
                    <span style="color: rgb(53, 53, 53); font-size: 1.3rem;">RS FATMAWATI</span><br>
                    <span style="font-size: 1rem; color: black;" v-if="okresponse.TANGGAL_RESERVASI">Tanggal : {{ new Date(okresponse.TANGGAL_RESERVASI) | formatDate }}</span><br>
                    <!-- <span style="font-size: 9pt; color: gray; font-weight: lighter;">TANGGAL CETAK : 22-12-2022</span> -->
                  </strong>
                </td>
                <td style="width: 50%; padding-left: 100px;">
                  <table>
                    <tbody>
                      <tr>
                        <td style="text-align: center; padding: 0; border-spacing: 0; margin: 0;">
                          <span style="color: rgb(53, 53, 53); font-size: 2rem; font-weight: bold;">{{ okresponse.ANTRIAN }}</span>
                        </td>
                      </tr>
                      <tr>
                        <td style="text-align: center; padding: 0; border-spacing: 0; margin: 0;">
                          <span style="font-size: 1rem; color: rgb(53, 53, 53); font-weight: bold;">NO.ANTRIAN</span>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
            </tbody>
          </table>
          <table class="padding-md font-arial text-general" style="padding-top: 10px; padding-bottom: 20px; border-top: none; font-size: 3mm; width: 100%;">
            <tbody style="padding-top: 10px">
              <tr>
                <td width="20%"><span style="font-size: 1rem;">Nama</span></td>
                <td width="1%"><span style="font-size: 1rem;">:</span></td>
                <td width="29%">
                  <span style="font-size: 1rem; font-weight: bold; color: black;">{{ okresponse.NAMA }}</span>
                </td>
              </tr>
              <tr>
                <td width="20%"><span style="font-size: 1rem;">No. Rekam Medis</span></td>
                <td width="1%"><span style="font-size: 1rem;">:</span></td>
                <td width="29%">
                  <span style="font-size: 1rem; font-weight: bold; color: black;">{{ okresponse.NORM }}</span>
                </td>
              </tr>
              <tr>
                <td width="20%"><span style="font-size: 1rem;">No. Reservasi</span></td>
                <td width="1%"><span style="font-size: 1rem;">:</span></td>
                <td width="29%">
                  <span style="font-size: 1rem; font-weight: bold; color: black;">{{ okresponse.NOMOR_RESERVASI }}</span>
                </td>
              </tr>
              <tr>
                <td width="20%"><span style="font-size: 1rem;">Poli Ruangan</span></td>
                <td width="1%"><span style="font-size: 1rem;">:</span></td>
                <td width="29%">
                  <span style="font-size: 1rem; font-weight: bold; color: black;">{{ okresponse.RUANGAN }}</span>
                </td>
              </tr>
              <tr>
                <td width="20%"><span style="font-size: 1rem;">Dokter</span></td>
                <td width="1%"><span style="font-size: 1rem;">:</span></td>
                <td width="29%">
                  <span style="font-size: 1rem; font-weight: bold; color: black;">{{ okresponse.DOKTER }}</span>
                </td>
              </tr>
              <tr>
                <td width="20%"><span style="font-size: 1rem;">Cara Bayar</span></td>
                <td width="1%"><span style="font-size: 1rem;">:</span></td>
                <td width="29%">
                  <span style="font-size: 1rem; font-weight: bold; color: black;">{{ okresponse.CARABAYAR }}</span>
                </td>
              </tr>
              <tr>
                <td width="20%"><span style="font-size: 1rem;">Nomor BPJS</span></td>
                <td width="1%"><span style="font-size: 1rem;">:</span></td>
                <td width="29%">
                  <span style="font-size: 1rem; font-weight: bold; color: black;" v-if="okresponse.NOMOR_KARTU">{{ okresponse.NOMOR_KARTU }}</span>
                  <span style="font-size: 1rem; font-weight: bold; color: black;" v-else>-</span>
                </td>
              </tr>
              <tr>
                <td width="20%"><span style="font-size: 1rem;">Nomor Rujukan</span></td>
                <td width="1%"><span style="font-size: 1rem;">:</span></td>
                <td width="29%">
                  <span style="font-size: 1rem; font-weight: bold; color: black;" v-if="okresponse.NOMOR_RUJUKAN">{{ okresponse.NOMOR_RUJUKAN }}</span>
                  <span style="font-size: 1rem; font-weight: bold; color: black;" v-else>-</span>
                </td>
              </tr>
              <tr>
                <td width="20%"><span style="font-size: 1rem;">Nomor SEP</span></td>
                <td width="1%"><span style="font-size: 1rem;">:</span></td>
                <td width="29%">
                  <!-- 0902R0010824V012464 -->
                  <span style="font-size: 1rem; font-weight: bold; color: black;" v-if="nosep">{{ nosep == 'NULL' ? '-' : nosep }}</span>
                  <span style="font-size: 1rem; font-weight: bold; color: black;" v-else>-</span>
                </td>
              </tr>
              <tr>
                <td width="20%"><span style="font-size: 1rem;">Estimasi</span></td>
                <td width="1%"><span style="font-size: 1rem;">:</span></td>
                <td width="29%">
                  <span style="font-size: 1rem; font-weight: bold; color: black;">{{ okresponse.ESTIMASI }}</span>
                </td>
              </tr>
              <tr>
                <td width="20%"><span style="font-size: 1rem;">Check In</span></td>
                <td width="1%"><span style="font-size: 1rem;">:</span></td>
                <td width="29%">
                  <span style="font-size: 1rem; font-weight: bold; color: black;">{{ okresponse.CHECK_IN }}</span>
                </td>
              </tr>
              <!-- <tr>
                <td width="20%"><span style="font-size: 1rem;">Tanggal Reservasi</span></td>
                <td width="1%"><span style="font-size: 1rem;">:</span></td>
                <td width="29%">
                  <span style="font-size: 1rem; font-weight: bold; color: black;" v-if="okresponse.TANGGAL_RESERVASI">{{ new Date(okresponse.TANGGAL_RESERVASI) | formatDate }}</span>
                </td>
              </tr> -->
            </tbody>
          </table>
          <table>
            <tbody>
              <tr>
                <div style="padding-top: 10px;">
                  <label style="font-size: 1rem;">
                    <input type="checkbox" id="radiologi" name="radiologi"> Radiologi
                  </label>
                  <label style="font-size: 1rem; margin-left: 15px;">
                    <input type="checkbox" id="laboratorium" name="laboratorium"> Laboratorium
                  </label>
                  <label style="font-size: 1rem; margin-left: 15px;">
                    <input type="checkbox" id="resep" name="resep"> Resep
                  </label>
                </div>
              </tr>
            </tbody>
          </table>
          <!-- <table class="font-arial" style="padding-top: 40px padding-bottom: 20px; width: 100%;">
            <tbody>
              <tr>
                <td style="width: 50%; font-size: 1rem; font-weight: bold; padding-bottom: 20px;">
                  <span style="border: 3px solid black; padding: 5px;">NOMOR ANTRIAN : {{ okresponse.NOMOR }}</span>
                </td>
              </tr>
            </tbody>
          </table> -->
          <table class="font-arial" style=" width: 100%;">
            <tbody>
              <tr>
                <td style="width: 50%; font-size: 1rem; padding-top: 10px;">
                  <span style="font-size: 1rem; color: gray; font-weight: lighter; font-style: italic;">Nomor Registrasi <span style="font-weight: bold;">{{ okresponse.NOMOR }}</span></span><br>
                </td>
              </tr>
              <tr v-if="enroll == '1'">
                <td style="width: 50%; font-size: 1rem; padding-top: 10px;">
                  <span style="font-size: 1rem; color: gray; font-weight: lighter;">Peserta Sudah Melakukan Enrollment Validasi Biometrik</span><br>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
// import axios from 'axios'
import Services from '../../services/fatmahost/reservasi/index.ts'
import Bpjs from '../../services/fatmahost/connected'
import Lottie from 'vue-lottie'
import animationLogo from '../../assets/lottie/checked.json'
import animationLogo2 from '../../assets/lottie/failed.json'
import fingerprintDevice from '../../assets/lottie/fingerprint-device.json'
import SimpleKeyboard from '../../components/hospia/SimpleKeyboard'
import logo from '../../assets/images/logo-rs-black.png'

export default {
  name: 'AnjunganPendaftaranMandiriDetail',
  components: {
    Lottie,
    SimpleKeyboard
  },
  data () {
    return {
      isLoading: false,
      input: '',
      output: null,
      defaultOptions: { animationData: animationLogo, loop: true },
      defaultOptions2: { animationData: animationLogo2, loop: true },
      fingerprintDevice: { animationData: fingerprintDevice, loop: true },
      qrcode: '',
      okresponse: '',
      okresponse2: '',
      errRespon: '',
      dateBoard: '',
      showReservasi: '',
      messageFinger: '',
      nobpjs: '',
      nosep: '',
      imageLogo: logo,
      counter: 0,
      isSidebarVisible: false,
      enroll: '0'
    }
  },
  mounted () {
    this.getDate()
    this.autofocus()
  },
  methods: {
    onChange (input) {
      this.input = input.toUpperCase()
    },
    onKeyPress (button) {
      // console.log('button', button)
    },
    onInputChange (input) {
      this.input = input.target.value.toString()
    },
    async openFinger () {
      window.location.href = `fingerbpjs://${this.nobpjs}`
    },
    async cekReservasi () {
      this.isLoading = true
      try {
        if (this.input !== '') {
          // const response = await axios.get(`http://localhost:4018/v1/practitioner/apmnew?nomor_reservasi=${this.input}`)
          const response = await Services.cekFinger(this.input)
          this.nobpjs = response.data.response.NOMOR_KARTU
          if (response.data.metadata.code === 2001) { // umur > 17 tahun wajib finger
            this.counter += 1
            const today = new Date()
            const formattedDate = today.toISOString().split('T')[0]
            this.nobpjs = response.data.response.NOMOR_KARTU
            const cekfinger = await Bpjs.cekFinger(response.data.response.NOMOR_KARTU, formattedDate)
            if (cekfinger && cekfinger.data && cekfinger.data.metadata && cekfinger.data.metadata.code === '200') {
              if (cekfinger && cekfinger.data && cekfinger.data.response.kode === '0') {
                this.enroll = cekfinger.data.response.kode
                if (this.counter < 2) {
                  this.showReservasi = response.data.response
                  this.$refs['modal-preview'].show()
                  this.openFinger()
                } else {
                  this.showReservasi = response.data.response
                  this.$refs['modal-preview'].show()
                }
              } else {
                this.enroll = cekfinger.data.response.kode
                this.showReservasi = response.data.response
                // this.isSidebarVisible = false
                // this.$refs['modal-finger'].hide()
                this.$refs['modal-preview'].show()
              }
            } else {
              this.showReservasi = response.data.response
              // this.isSidebarVisible = false
              // this.$refs['modal-finger'].hide()
              this.$refs['modal-preview'].show()
            }
          } else if (response.data.metadata.code === 201) {
            this.errRespon = response.data.metadata.message
            this.$refs['modal-previeww'].show()
          } else {
            if (response.data.metadata.code === 200) {
              this.showReservasi = response.data.response
              this.isSidebarVisible = false
              // this.$refs['modal-finger'].hide()
              this.$refs['modal-preview'].show()
            } else {
              this.errRespon = response.data.metadata.message
              this.$refs['modal-previeww'].show()
            }
          }
        } else {
          this.errRespon = 'Masukkan nomor reservasi anda'
          this.$refs['modal-previeww'].show()
        }
      } catch (error) {
        this.errRespon = 'Tidak dapat terhubung ke server'
        this.$refs['modal-previeww'].show()
      } finally {
        this.isLoading = false
      }
    },
    async postQr () {
      const today = new Date()
      const formattedDate = today.toISOString().split('T')[0]
      const cekfinger = await Bpjs.cekFinger(this.nobpjs, formattedDate)
      this.enroll = cekfinger?.data?.response?.kode
      this.isLoading = true
      if (this.input !== '') {
        const data = {
          object: 'apm-rj',
          nomor_reservasi: this.input
        }
        await Services.printapm(data).then(response => {
          if (response.data.statcode === 0) {
            this.isLoading = false
            this.okresponse = response.data.data[0]
            this.nosep = response?.data?.nosep
            if (this.okresponse) {
              this.print()
            }
          } else {
            this.isLoading = false
            this.errRespon = response.data.message
            this.$refs['modal-previeww'].show()
          }
        }).catch(error => {
          this.isLoading = false
          this.errRespon = error.message
          this.$refs['modal-previeww'].show()
        })
      } else {
        this.isLoading = false
        this.errRespon = 'Mohon Masukan Nomor QR Code Anda'
        this.$refs['modal-previeww'].show()
      }
      this.isLoading = false
    },
    getDate () {
      let date = new Date()
      let y = date.getFullYear()
      let m = date.getMonth() + 1
      let d = date.getDate()
      let joinDate = d + '-' + m + '-' + y
      this.dateBoard = joinDate
    },
    print () {
      const prtHtml = document.getElementById('printCoba')
      if (prtHtml) {
        setTimeout(() => {
          const Winprint = window.open('', '', 'height=1000,width=1000')
          Winprint.document.write(`<!DOCTYPE html>
          <html lang="en">
          <head>
          </head>
          <body>
          ${prtHtml.innerHTML}
          </body>
          </html>`)
          Winprint.document.close()
          Winprint.focus()
          Winprint.print()
          Winprint.close()
          this.$refs['modal-preview'].hide()
          this.input = ''
          this.reload()
        }, 100) // Adjust the delay time if necessary
      } else {
        // console.error('Element with id printCoba not found')
      }
    },
    autofocus () {
      document.getElementById('formqr').focus()
    },
    reload () {
      location.reload()
    },
    autoClose () {
      this.$refs['modal-preview'].hide()
      this.input = ''
      this.reload()
      // setTimeout(() => {
      //   this.autofocus()
      // }, 1000)
    }
  }
}
</script>
<style>
.ticket {
  background-color: #dce6f0;
  border: 2px dashed #099FB0;
  padding: 20px;
  border-radius: 15px;
  font-family: 'Courier New', Courier, monospace;
}

.ticket-header {
  margin-bottom: 20px;
}

.ticket-body .ticket-row {
  margin-bottom: 5px;
}

.ticket-footer .btn {
  margin: 0 10px;
}
/* batas */
.apm-dtl-new .radius-top {
  border-radius: 10px 10px 0px 0px!important;
}
.apm-dtl-new .btn-proses-padding {
  padding-top: 1.3em!important;
  padding-bottom: 1.3em!important;
}
.apm-dtl-new .hover-btn-apm {
  -webkit-box-shadow: 1px 1px 5 px #888888;
          box-shadow: 1px 1px 5 px #888888;
  cursor: pointer;
  -webkit-transition: all ease-in-out .3s;
  -o-transition: all ease-in-out .3s;
  transition: all ease-in-out .3s;
}
.apm-dtl-new .hover-btn-apm:hover {
  -webkit-box-shadow: 5px 5px 10px #888888;
          box-shadow: 5px 5px 10px #888888;
}
.apm-dtl-new .full-height {
  min-height: 100vh!important;
  background-image: url('../../assets/images/bg-pattern.png');
  background-repeat: no-repeat;
  -webkit-animation-name: bg-animatiom;
          animation-name: bg-animatiom;
  -webkit-animation-duration: 50s;
          animation-duration: 50s;
  -webkit-animation-timing-function: linear;
          animation-timing-function: linear;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
  -webkit-animation-direction: alternate;
          animation-direction: alternate;
  /* background-position: right bottom; */
}
@-webkit-keyframes bg-animatiom {
  0%   {background-size: 100%;}
  50%   {background-size: 150%;}
  100% {background-size: 100%;}
}
@keyframes bg-animatiom {
  0%   {background-size: 100%;}
  50%   {background-size: 150%;}
  100% {background-size: 100%;}
}
.apm-dtl-new .custom-input-style {
  border: 1px solid rgb(235, 235, 235);
  border-radius: 10px;
  width: 100%!important;
  height: 60px!important;
  padding: 10px 10px 10px 10px;
  font-size: 17px;
  font-weight: bold;
}
.apm-dtl-new .hg-theme-default .hg-button {
    color: black;
    font-weight: bolder;
    font-size: 17px;
}
.apm-dtl-new .modal-content {
  border-radius: 1.5rem !important;
}
@media print {
  .hidden-print {
    display: none !important;
  }
}
</style>
<style>
.bounce {
  animation: bounce 50s infinite;
}
@keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-30px);
  }
  60% {
    transform: translateY(-15px);
  }
}
</style>
