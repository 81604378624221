<template>
  <b-container id="pendaftaran-show" fluid>
    <div v-show="isLoading">
      <div id="show-overlay"></div>
      <Loader :style="{ opacity: .5 }" />
    </div>
    <b-row>
      <b-col cols="12" lg="6">
        <iq-card id="pasien-info" class="shadow-hover mb-4">
          <div class="d-flex flex-row justify-content-between align-items-center p-2 p-lg-3">
            <div>
              <p class="h6 text-dark mb-0 font-weight-bold"><i class="fas fa-male mr-2"></i>Pasien Info</p>
            </div>
            <div>
              <b-button pill class="m-1" @click="editPasien" variant="primary"><i class="fas fa-sync"></i></b-button>
              <b-button class="m-1" variant="info" v-b-modal.pendaftaran-show><i class="fas fa-list"></i> Detail</b-button>
              <b-button class="m-1" @click="editPasien" variant="warning"><i class="fas fa-edit"></i> Edit</b-button>
            </div>
          </div>
          <hr class="m-0 p-0">
          <hr class="mx-0 px-0 my-1 py-0">
          <div class="d-flex flex-row justify-content-between text-center p-2 p-lg-3">
            <div>
              <b-img :src="pasien.jenis_kelamin === 1 ? imgProfile.pria : imgProfile.wanita" alt="profile-img" class="avatar-130 img-fluid mb-3" />
            </div>
            <div>
              <p v-if="pasien.noihs !== null" class="h3 text-primary font-weight-bold mb-2">{{ pasien.noihs }}</p>
              <p class="h3 text-dark text-right font-weight-bold mb-0">{{pasien.norm | norm}}</p>
            </div>
          </div>
          <hr class="m-0 p-0">
          <hr class="mx-0 px-0 my-1 py-0">
          <b-row>
            <b-col>
              <div class="p-2 p-lg-3">
                <p class="h5 text-dark font-weight-light text-uppercase"><span
                    class="font-weight-bold">{{pasien.nama}}</span></p>
                <p class="h6 text-dark">{{pasien.nm_tempat_lahir}}, {{ pasien.tanggal_lahir | formatTgl }} / {{ pasien.tanggal_lahir | umurymd }}
                </p>
                <p class="h6 text-dark font-weight-light">{{pasien.nm_jenis_kelamin}} / Status. {{pasien.nm_status}}</p>
                <p class="small font-weight-light mb-0"><i class="fas fa-address-card"></i> {{ pasien.nik }}</p>
              </div>
            </b-col>
          </b-row>
        </iq-card>
        <iq-card id="tagihan" class="shadow-hover mb-4">
          <div class="d-flex flex-row justify-content-between align-items-center p-2 p-lg-3">
            <div>
              <p class="h6 text-dark font-weight-light mb-0"><i class="fas fa-credit-card"></i> Tagihan / Pembayaran</p>
            </div>
            <div>
              <b-button class="m-1" variant="success"><i class="fas fa-history"></i> History</b-button>
            </div>
          </div>
          <hr class="m-0 p-0">
          <hr class="mx-0 px-0 my-1 py-0">
          <div class="d-flex flex-row justify-content-between align-items-center p-2 p-lg-3">
            <div>
              <p class="h6 text-dark font-weight-bold mb-1">2208240003 - 24-08-2022 16:12:33 | TUNAI</p>
              <p class="h6 text-dark font-weight-light mb-0">Prof.Soelarto - Lt.3 / 2207270003 - 27-07-2022 17:27:07</p>
            </div>
            <div class="d-flex flex-row align-items-center">
              <div class="mr-2">
                <p class="h4 text-dark font-weight-bold mb-0">0</p>
              </div>
              <div>
                <b-button class="m-1" variant="info"> View</b-button>
              </div>
            </div>
          </div>
        </iq-card>
        <div id="piutang">
          <b-row>
            <b-col cols="12" lg="6">
              <iq-card class="shadow-hover">
                <template v-slot:headerTitle>
                  <h6 class="card-title"><i class="fas fa-money-check-alt"></i> Piutang Pasien</h6>
                </template>
                <template v-slot:body>
                  <div>
                    <p class="display-1 mb-0 text-dark font-weight-bold text-center">0</p>
                  </div>
                  <b-button class="m-1 btn-block" variant="danger"> Pembayaran</b-button>
                </template>
              </iq-card>
            </b-col>
            <b-col cols="12" lg="6">
              <iq-card class="shadow-hover">
                <template v-slot:headerTitle>
                  <h6 class="card-title"><i class="fas fa-money-check-alt"></i> Piutang Perusahaan</h6>
                </template>
                <template v-slot:body>
                  <div>
                    <p class="display-1 mb-0 text-dark font-weight-bold text-center">0</p>
                  </div>
                  <b-button class="m-1 btn-block" variant="danger"> Pembayaran</b-button>
                </template>
              </iq-card>
            </b-col>
          </b-row>
        </div>
      </b-col>
      <b-col>
        <iq-card id="tagihan" class="shadow-hover mb-4">
          <div class="d-flex flex-row justify-content-between align-items-center p-2 p-lg-3">
            <div>
              <p class="h6 text-dark font-weight-bold mb-0"><i class="fas fa-user-plus  mr-2"></i> Pendaftaran Kunjungan</p>
            </div>
            <div>
              <b-button v-b-tooltip.top="'Pendaftaran Kunjungan Baru'" class="m-1" variant="dark"><i class="fas fa-file"></i> Daftar</b-button>
              <b-button class="m-1" variant="success"><i class="fas fa-history"></i> History</b-button>
              <b-button class="m-1" variant="primary"><i class="fas fa-list"></i> Detail</b-button>
            </div>
          </div>
          <hr class="m-0 p-0">
          <hr class="mx-0 px-0 my-1 py-0">
          <div class="d-flex flex-row justify-content-between align-items-center p-2 p-lg-3">
            <!-- <div>
              <p class="h5 text-dark font-weight-bold mb-1">2208240003</p>
              <p class="h6 text-dark">Rabu, 24 Agustus 2022 Pukul 16:12:31</p>
              <p class="h6 text-dark mb-0">Aktif</p>
            </div>
            <div class="text-right">
              <p class="h6"><span class="font-weight-bold">Prof.Soelarto - Lt.3</span>, dr. Fachrisal, Sp.OT, KSpine</p>
              <p class="small mb-0">Pasien belum diterima</p>
            </div> -->
          </div>
          <hr class="m-0 p-0">
          <hr class="mx-0 px-0 my-1 py-0">
          <div class="p-3">
            <p class="h6 text-right mb-0" :class="[pasien.status === 1 ? 'text-success' : 'text-danger']">{{ pasien.nm_status }}</p>
          </div>
        </iq-card>
        <iq-card id="tagihan" class="shadow-hover mb-4">
          <div class="d-flex flex-row justify-content-between align-items-center p-2 p-lg-3">
            <div>
              <p class="h6 text-dark font-weight-bold mb-0"><i class="fas fa-users mr-2"></i> Kunjungan</p>
            </div>
            <div>
              <b-button class="m-1" variant="success"><i class="fas fa-history"></i> History</b-button>
              <b-button class="m-1" variant="primary"><i class="fas fa-list"></i> Detail</b-button>
              <b-button class="m-1" variant="danger"><i class="fas fa-minus-circle"></i> Batalkan Kunjungan</b-button>
            </div>
          </div>
          <hr class="m-0 p-0">
          <hr class="mx-0 px-0 my-1 py-0">
          <div class="d-flex flex-row justify-content-between align-items-center p-2 p-lg-3">
            <!-- <div>
              <p class="h5 text-dark font-weight-bold mb-1">2208240003</p>
              <p class="h6 text-dark">Rabu, 24 Agustus 2022 Pukul 16:12:31</p>
              <p class="h6 text-dark mb-0">Aktif</p>
            </div>
            <div class="text-right">
              <p class="h5">Depo IRJ LT 1</p>
              <p class="h6">Masuk: Senin, 29 Agustus 2022 Pukul 09:32:02</p>
              <p class="small text-danger mb-0">Status. Pasien Berada di ruangan ini / Sedang dilayani</p>
            </div> -->
          </div>
          <hr class="m-0 p-0">
          <hr class="mx-0 px-0 my-1 py-0">
          <div class="p-3">
            <!-- <p class="h5 text-dark font-weight-bold mb-0">TUNAI</p> -->
          </div>
        </iq-card>
        <iq-card id="tagihan" class="shadow-hover mb-0">
          <div class="p-2 p-lg-3">
            <p class="h6 text-dark font-weight-light mb-0"><i class="fas fa-calendar-alt"></i> Jadwal Kontrol</p>
          </div>
          <hr class="m-0 p-0">
          <hr class="mx-0 px-0 my-1 py-0">
          <div class="p-2 p-lg-3">
            <p class="small text-right font-weight-light mb-0">Data Tidak Ditemukan</p>
          </div>
        </iq-card>
      </b-col>
    </b-row>
    <b-modal id="pendaftaran-show" :ok-only="true" title="Detail Pasien" class="p-0">
      <div>
        <div class="head-card">
          <b-row class="justify-content-start">
            <b-col cols="12" lg="4">
              <iq-card class="cstm-card bg-dark shadow p-4">
                <div class="d-flex flex-column justify-content-between h-100 w-100">
                  <div class="mb-4">
                    <b-row>
                      <b-col class="d-flex align-items-center">
                        <div>
                          <p class="h6 text-white txt-shdw mb-0">Direktorat Jendral Pelayanan Kesehatan</p>
                          <p class="h5 text-white txt-shdw font-weight-bold mb-0">RSUP Fatmawati</p>
                        </div>
                      </b-col>
                      <b-col cols="3">
                        <div class="tex-right">
                          <b-img :src="imgProfile.kemkes" alt="profile-img" width="125rem" class="img-fluid mb-3" />
                        </div>
                      </b-col>
                    </b-row>
                  </div>
                  <div class="mb-4">
                    <b-row>
                      <b-col>
                        <div class="d-flex align-items-center w-100">
                          <div>
                            <b-img :src="imgProfile.kartu" alt="profile-img" width="150rem" class="img-fluid mb-3" />
                          </div>
                          <div class="text-right w-100">
                            <p class="h1 text-white txt-shdw font-weight-bold mb-1">{{pasien.norm | norm}}</p>
                            <p class="h3 text-white txt-shdw mb-0">{{pasien.nama}}</p>
                          </div>
                        </div>
                      </b-col>
                    </b-row>
                  </div>
                  <div>
                    <b-row>
                      <b-col>
                        <div class="d-flex">
                          <div class="p-2">
                            <p class="h5 font-weight-bold txt-shdw text-white mb-1">Protection For Your Hospital</p>
                          </div>
                        </div>
                      </b-col>
                    </b-row>
                  </div>
                </div>
              </iq-card>
            </b-col>
            <b-col cols="12" lg="8">
              <iq-card class="p-4 shadow border border-primary">
                <b-row>
                  <b-col cols="12" lg="2" class="bg-foto text-center">
                    <div>
                      <b-img :src="pasien.jenis_kelamin === 1 ? imgProfile.priatrns : imgProfile.wanitatrns" alt="profile-img"
                        class="avatar-130 img-fluid mb-3" />
                    </div>
                    <p class="h4 font-weight-bold text-primary mb-0 txt-shdw2">{{pasien.norm | norm}}</p>
                    <p class="h6 font-weight-bold text-dark mb-0">{{pasien.nama}}</p>
                  </b-col>
                  <b-col cols="12" lg="5">
                    <b-row class="mb-2">
                      <b-col cols="5">
                        <p class="small text-primary mb-0">TEMPAT LAHIR</p>
                      </b-col>
                      <b-col cols="1">
                        <p class="small text-dark mb-0">:</p>
                      </b-col>
                      <b-col cols="6">
                        <p class="small font-weight-bold text-uppercase text-dark mb-0" v-if="pasien.nm_tempat_lahir === null">-
                        </p>
                        <p class="small font-weight-bold text-uppercase text-dark mb-0" v-else>{{pasien.nm_tempat_lahir}}</p>
                      </b-col>
                    </b-row>
                    <b-row class="mb-2">
                      <b-col cols="5">
                        <p class="small text-primary mb-0">TANGGAL LAHIR</p>
                      </b-col>
                      <b-col cols="1">
                        <p class="small text-dark mb-0">:</p>
                      </b-col>
                      <b-col cols="6">
                        <p class="small font-weight-bold text-uppercase text-dark mb-0">{{ new Date(pasien.tanggal_lahir) | formatDate }}</p>
                      </b-col>
                    </b-row>
                    <b-row class="mb-2">
                      <b-col cols="5">
                        <p class="small text-primary mb-0">UMUR</p>
                      </b-col>
                      <b-col cols="1">
                        <p class="small text-dark mb-0">:</p>
                      </b-col>
                      <b-col cols="6">
                        <p class="small font-weight-bold text-uppercase text-dark mb-0">{{ pasien.tanggal_lahir | umurymd }}</p>
                      </b-col>
                    </b-row>
                    <b-row class="mb-2">
                      <b-col cols="5">
                        <p class="small text-primary mb-0">AGAMA</p>
                      </b-col>
                      <b-col cols="1">
                        <p class="small text-dark mb-0">:</p>
                      </b-col>
                      <b-col cols="6">
                        <p class="small font-weight-bold text-uppercase text-dark mb-0" v-if="pasien.nm_agama === null">-</p>
                        <p class="small font-weight-bold text-uppercase text-dark mb-0" v-else>{{pasien.nm_agama}}</p>
                      </b-col>
                    </b-row>
                    <b-row class="mb-2">
                      <b-col cols="5">
                        <p class="small text-primary mb-0">JENIS KELAMIN</p>
                      </b-col>
                      <b-col cols="1">
                        <p class="small text-dark mb-0">:</p>
                      </b-col>
                      <b-col cols="6">
                        <p class="small font-weight-bold text-uppercase text-dark mb-0">{{pasien.nm_jenis_kelamin}}</p>
                      </b-col>
                    </b-row>
                    <b-row class="mb-2">
                      <b-col cols="5">
                        <p class="small text-primary mb-0">PENDIDIKAN</p>
                      </b-col>
                      <b-col cols="1">
                        <p class="small text-dark mb-0">:</p>
                      </b-col>
                      <b-col cols="6">
                        <p class="small font-weight-bold text-uppercase text-dark mb-0" v-if="pasien.nm_pendidikan === null">-</p>
                        <p class="small font-weight-bold text-uppercase text-dark mb-0" v-else>{{pasien.nm_pendidikan}}</p>
                      </b-col>
                    </b-row>
                    <b-row class="mb-2">
                      <b-col cols="5">
                        <p class="small text-primary mb-0">PEKERJAAN</p>
                      </b-col>
                      <b-col cols="1">
                        <p class="small text-dark mb-0">:</p>
                      </b-col>
                      <b-col cols="6">
                        <p class="small font-weight-bold text-dark mb-0"></p>
                      </b-col>
                    </b-row>
                    <b-row class="mb-2">
                      <b-col cols="5">
                        <p class="small text-primary mb-0">GOLONGAN DARAH</p>
                      </b-col>
                      <b-col cols="1">
                        <p class="small text-dark mb-0">:</p>
                      </b-col>
                      <b-col cols="6">
                        <p class="small font-weight-bold text-uppercase text-dark mb-0" v-if="pasien.nm_golongan_darah === null">-</p>
                        <p class="small font-weight-bold text-uppercase text-dark mb-0" v-else>{{pasien.nm_golongan_darah}}</p>
                      </b-col>
                    </b-row>
                    <b-row class="mb-2">
                      <b-col cols="5">
                        <p class="small text-primary mb-0">STATUS PERKAWINAN</p>
                      </b-col>
                      <b-col cols="1">
                        <p class="small text-dark mb-0">:</p>
                      </b-col>
                      <b-col cols="6">
                        <p class="small font-weight-bold text-uppercase text-dark mb-0" v-if="pasien.nm_status_perkawinan === null">-</p>
                        <p class="small font-weight-bold text-uppercase text-dark mb-0" v-else>{{pasien.nm_status_perkawinan}}</p>
                      </b-col>
                    </b-row>
                  </b-col>
                  <b-col cols="12" lg="5">
                    <b-row class="mb-2">
                      <b-col cols="5">
                        <p class="small text-primary mb-0">KEWARGANEGARAAN</p>
                      </b-col>
                      <b-col cols="1">
                        <p class="small text-dark mb-0">:</p>
                      </b-col>
                      <b-col cols="6">
                        <p class="small font-weight-bold text-uppercase text-dark mb-0" v-if="pasien.nm_kewarganegaraan === null">-</p>
                        <p class="small font-weight-bold text-uppercase text-dark mb-0" v-else>{{pasien.nm_kewarganegaraan}}</p>
                      </b-col>
                    </b-row>
                    <b-row class="mb-2">
                      <b-col cols="5">
                        <p class="small text-primary mb-0">ALAMAT</p>
                      </b-col>
                      <b-col cols="1">
                        <p class="small text-dark mb-0">:</p>
                      </b-col>
                      <b-col cols="6">
                        <p class="small font-weight-bold text-uppercase text-dark mb-0" v-if="identitas.alamat === null">-</p>
                        <p class="small font-weight-bold text-uppercase text-dark mb-0" v-else>{{identitas.alamat}}</p>
                      </b-col>
                    </b-row>
                    <b-row class="mb-2">
                      <b-col cols="5">
                        <p class="small text-primary mb-0">RT</p>
                      </b-col>
                      <b-col cols="1">
                        <p class="small text-dark mb-0">:</p>
                      </b-col>
                      <b-col cols="6">
                        <p class="small font-weight-bold text-uppercase text-dark mb-0" v-if="pasien.rt === null">-</p>
                        <p class="small font-weight-bold text-uppercase text-dark mb-0" v-else>{{pasien.rt}}</p>
                      </b-col>
                    </b-row>
                    <b-row class="mb-2">
                      <b-col cols="5">
                        <p class="small text-primary mb-0">RW</p>
                      </b-col>
                      <b-col cols="1">
                        <p class="small text-dark mb-0">:</p>
                      </b-col>
                      <b-col cols="6">
                        <p class="small font-weight-bold text-uppercase text-dark mb-0" v-if="pasien.rw === null">-</p>
                        <p class="small font-weight-bold text-uppercase text-dark mb-0" v-else>{{pasien.rw}}</p>
                      </b-col>
                    </b-row>
                    <b-row class="mb-2">
                      <b-col cols="5">
                        <p class="small text-primary mb-0">KODE POS</p>
                      </b-col>
                      <b-col cols="1">
                        <p class="small text-dark mb-0">:</p>
                      </b-col>
                      <b-col cols="6">
                        <p class="small font-weight-bold text-uppercase text-dark mb-0" v-if="pasien.kodepos === null">-</p>
                        <p class="small font-weight-bold text-uppercase text-dark mb-0" v-else>{{pasien.kodepos}}</p>
                      </b-col>
                    </b-row>
                    <b-row class="mb-2">
                      <b-col cols="5">
                        <p class="small text-primary mb-0">KELURAHAN</p>
                      </b-col>
                      <b-col cols="1">
                        <p class="small text-dark mb-0">:</p>
                      </b-col>
                      <b-col cols="6">
                        <p class="small font-weight-bold text-uppercase text-dark mb-0" v-if="pasien.nm_wilayah_kelurahan === null">-</p>
                        <p class="small font-weight-bold text-uppercase text-dark mb-0" v-else>{{pasien.nm_wilayah_kelurahan}}</p>
                      </b-col>
                    </b-row>
                    <b-row class="mb-2">
                      <b-col cols="5">
                        <p class="small text-primary mb-0">KECAMATAN</p>
                      </b-col>
                      <b-col cols="1">
                        <p class="small text-dark mb-0">:</p>
                      </b-col>
                      <b-col cols="6">
                        <p class="small font-weight-bold text-uppercase text-dark mb-0" v-if="pasien.nm_wilayah_kecamatan === null">-</p>
                        <p class="small font-weight-bold text-uppercase text-dark mb-0" v-else>{{pasien.nm_wilayah_kecamatan}}</p>
                      </b-col>
                    </b-row>
                    <b-row class="mb-2">
                      <b-col cols="5">
                        <p class="small text-primary mb-0">KABUPATEN / KOTA</p>
                      </b-col>
                      <b-col cols="1">
                        <p class="small text-dark mb-0">:</p>
                      </b-col>
                      <b-col cols="6">
                        <p class="small font-weight-bold text-uppercase text-dark mb-0" v-if="pasien.nm_wilayah_kabupaten === null">-</p>
                        <p class="small font-weight-bold text-uppercase text-dark mb-0" v-else>{{pasien.nm_wilayah_kabupaten}}</p>
                      </b-col>
                    </b-row>
                    <b-row class="mb-2">
                      <b-col cols="5">
                        <p class="small text-primary mb-0">PROVINSI</p>
                      </b-col>
                      <b-col cols="1">
                        <p class="small text-dark mb-0">:</p>
                      </b-col>
                      <b-col cols="6">
                        <p class="small font-weight-bold text-uppercase text-dark mb-0" v-if="pasien.nm_wilayah_provinsi === null">-</p>
                        <p class="small font-weight-bold text-uppercase text-dark mb-0" v-else>{{pasien.nm_wilayah_provinsi}}</p>
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row>
              </iq-card>
            </b-col>
          </b-row>
          <div class="mt-5">
            <tab-nav :tabs="true" id="myTab-two" class="justify-content-center justify-content-lg-start">
              <tab-nav-items :active="true" id="home-tab-two" ariaControls="home" href="#home-two" role="tab"
                :ariaSelected="true">
                <template v-slot:title>
                  <div>
                    <i class="fas fa-address-card"></i> Kartu Identitas
                  </div>
                </template>
              </tab-nav-items>
              <tab-nav-items :active="false" id="profile-tab-two" ariaControls="profile" href="#profile-two" role="tab"
                :ariaSelected="false">
                <template v-slot:title>
                  <div>
                    <i class="fas fa-address-card"></i> Kartu Jaminan Asuransi
                  </div>
                </template>
              </tab-nav-items>
              <tab-nav-items :active="false" id="contact-tab-two" ariaControls="contact" href="#contact-two" role="tab"
                :ariaSelected="false">
                <template v-slot:title>
                  <div>
                    <i class="fas fa-users"></i> Keluarga Pasien
                  </div>
                </template>
              </tab-nav-items>
              <tab-nav-items :active="false" id="contact-tab-three" ariaControls="contactB" href="#contact-three" role="tab"
                :ariaSelected="false">
                <template v-slot:title>
                  <div>
                    <i class="fas fa-phone-alt"></i> Kontak
                  </div>
                </template>
              </tab-nav-items>
            </tab-nav>
            <tab-content id="myTabContent-1">
              <tab-content-item :active="true" id="home-two" aria-labelled-by="home-tab-two">
                <template>
                  <div>
                    <b-table striped hover bordered :items="tableIdentitas" :fields="fieldsIdentitas">
                      <template #cell(index)="data">
                        {{ data.index + 1 }}
                      </template>
                    </b-table>
                  </div>
                </template>
              </tab-content-item>
              <tab-content-item :active="false" id="profile-two" aria-labelled-by="profile-tab-two">
                <template>
                  <div>
                    <b-table striped hover bordered :items="itemsTabelB"></b-table>
                  </div>
                </template>
              </tab-content-item>
              <tab-content-item :active="false" id="contact-two" aria-labelled-by="contact-tab-two">
                <template>
                  <div>
                    <b-table striped hover bordered :items="tableKeluarga" :fields="fieldsKeluarga"></b-table>
                  </div>
                </template>
              </tab-content-item>
              <tab-content-item :active="false" id="contact-three" aria-labelled-by="contact-tab-three">
                <template>
                  <div>
                    <b-table striped hover bordered :items="tableKontak" :fields="fieldsKontak">
                      <template #cell(index)="data">
                        {{ data.index + 1 }}
                      </template>
                    </b-table>
                  </div>
                </template>
              </tab-content-item>
            </tab-content>
          </div>
        </div>
      </div>
    </b-modal>
  </b-container>
</template>
<script>
import { xray } from '../../../config/pluginInit'
import Loader from '../../../components/xray/loader/Loader'
import Pasien from '../../../services/pasien'

export default {
  components: {
    Loader
  },
  mounted () {
    xray.index()
  },
  watch: {
    now () {}
  },
  computed: {
    now () {
      return this.viewPasien(this.$route.params.id)
    }
  },
  data () {
    return {
      isLoading: false,
      itemsTabelB: [
        { No: 1, Nomor: '-', Jenis: 'TUNAI' }
      ],
      error: [],
      imgProfile: {
        pria: require('../../../assets/images/user/14.jpg'),
        priatrns: require('../../../assets/images/14.png'),
        wanitatrns: require('../../../assets/images/14.png'),
        kartu: require('../../../assets/images/logo-rs.png'),
        kemkes: require('../../../assets/images/logo-kemkes-putih.png'),
        wanita: require('../../../assets/images/user/15.jpg')
      },
      doctor: {
        profile: require('../../../assets/images/user/1.jpg')
      },
      pasien: [],
      identitas: [],
      tableIdentitas: [],
      fieldsIdentitas: [
        { label: 'No', key: 'index', class: 'text-center' },
        { label: 'Nomor', key: 'nomor', class: 'text-left' },
        { label: 'Jenis', key: 'nm_jenis', class: 'text-left' },
        { label: 'Alamat', key: 'alamat', class: 'text-left' },
        { label: 'Kode Pos', key: 'kodepos', class: 'text-left' }
      ],
      tableKontak: [],
      fieldsKontak: [
        { label: 'No', key: 'index', class: 'text-center' },
        { label: 'Jenis Kontak', key: 'nm_jenis', class: 'text-left' },
        { label: 'Nomor', key: 'nomor', class: 'text-left' }
      ],
      tableKeluarga: [],
      fieldsKeluarga: [
        { label: 'No', key: 'index', class: 'text-center' },
        { label: 'Nama', key: 'nama', class: 'text-left' },
        { label: 'Hubungan', key: 'nm_shdk', class: 'text-left' },
        { label: 'Jenis Kontak', key: 'nm_jenis', class: 'text-left' },
        { label: 'Nomor', key: 'nomor', class: 'text-left' }
      ]
    }
  },
  methods: {
    async viewPasien (id) {
      this.isLoading = true
      await Pasien.show(id).then(response => {
        if (response.status === 200) {
          this.pasien = response.data.pasien[0]
          this.identitas = response.data.identitas[0]
          this.tableIdentitas = response.data.identitas
          this.tableKontak = response.data.kontak
        }
        this.isLoading = false
      }).catch(error => {
        this.error = error.response
        this.isLoading = false
      })
    },
    editPasien () {
      this.$router.push({ path: '/pasien/edit/' + this.$route.params.id })
    }
  }
}
</script>
<style>
#pendaftaran-show .modal-dialog {
    min-width: 100%!important;
    margin: 0px!important;
}
#pendaftaran-show .modal-dialog {
    width: 100%!important;
    height: 100%!important;
}
#pendaftaran-show .modal-content {
    min-height: 100%;
}
#pendaftaran-show .cstm-card {
    background-image: url('../../../assets/images/batik-bg.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center right;
}
#pendaftaran-show .bg-foto {
    background-image: url('../../../assets/images/bg-photo.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center right;
}
#pendaftaran-show .txt-color {
  color: #396033;
}
#pendaftaran-show .txt-shdw {
  text-shadow: 2px 2px #616161;
}
#pendaftaran-show .bg-detail {
  background: #89c8d725;
}
#pendaftaran-show .bg-detail2 {
  background: #fff8e3;
}
#pendaftaran-show .shadow-hover {
  box-shadow: 10px 10px 15px 1px rgba(0, 0, 0, 0);
  transition: all ease-in-out .3s;
}
#pendaftaran-show .shadow-hover:hover {
  box-shadow: 10px 10px 15px 1px rgba(0, 0, 0, 0.167);
  transition: all ease-in-out .3s;
}
#pendaftaran-show .txt-shdw2 {
  text-shadow: rgb(255, 255, 255) 1px 0 10px;
}
#pendaftaran-show .nav-tabs {
    margin-bottom: 0px;
}
</style>
