<template>
  <b-container fluid>
    <iq-card>
      <template v-slot:headerTitle>
        <h4 class="card-title">KALENDER OPERASI</h4>
      </template>
      <!-- <template v-slot:headerAction>
        <a href="#" class="btn btn-primary"><i class="ri-add-line mr-2"></i>KALENDER OPERASI</a>
      </template> -->
      <template v-slot:body>
        <FullCalendar
          class='demo-app-calendar'
          ref="fullCalendar"
          defaultView="dayGridMonth"
          :header="{
            left: 'prev,next today',
            center: 'title',
            right: 'dayGridMonth,timeGridWeek,timeGridDay,list'
          }"
          :plugins="calendarPlugins"
          :weekends="calendarWeekends"
          :events="calendarEvents"
          @dateClick="handleDateClick"
          />
      </template>
    </iq-card>
  </b-container>
</template>
<script>
import { xray } from '../../../config/pluginInit'
import FullCalendar from '@fullcalendar/vue'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import interactionPlugin from '@fullcalendar/interaction'
export default {
  name: 'GoogleCalendar',
  components: { FullCalendar },
  data () {
    return {
      config: {
        dateFormat: 'Y-m-d',
        inline: true
      },
      calendarPlugins: [
        dayGridPlugin,
        timeGridPlugin,
        interactionPlugin
      ],
      calendarWeekends: true,
      calendarEvents: [
        {
          title: 'All Day Event',
          start: '2024-09-01',
          color: '#fc9919'
        },
        {
          title: 'Long Event',
          start: '2024-09-07',
          end: '2024-09-10',
          color: '#ffc107' // override!
        },
        {
          groupId: '999',
          title: 'Repeating Event',
          start: '2024-09-09T16:00:00',
          color: '#17a2b8'
        },
        {
          groupId: '999',
          title: 'Repeating Event',
          start: '2024-09-16T16:00:00',
          color: '#17a2b8'
        },
        {
          title: 'Conference',
          start: '2024-09-11',
          end: '2024-09-13',
          color: '#27e3f4' // override!
        },
        {
          title: 'Meeting',
          start: '2024-09-12T10:30:00',
          end: '2024-09-12T12:30:00',
          color: '#0084ff'
        },
        {
          title: 'Lunch',
          start: '2024-09-12T12:00:00',
          color: '#777D74'
        },
        {
          title: 'Meeting',
          start: '2024-09-12T14:30:00',
          color: '#0084ff'
        },
        {
          title: 'Birthday Party',
          start: '2024-09-28T07:00:00',
          color: '#28a745'
        },
        {
          title: 'Meeting',
          start: '2020-01-12T14:30:00',
          color: '#0084ff'
        },
        {
          title: 'Birthday Party',
          start: '2020-01-02T07:00:00',
          color: '#28a745'
        },
        {
          title: 'Birthday Party',
          start: '2020-01-13T07:00:00',
          color: '#28a745'
        },
        {
          title: 'Meeting',
          start: '2020-01-12T14:30:00',
          color: '#0084ff'
        },
        {
          title: 'Birthday Party',
          start: '2020-01-13T07:00:00',
          color: '#28a745'
        },
        {
          title: 'Click for Google',
          url: 'http://google.com/',
          start: '2020-01-28'
        },
        {
          title: 'All Day Event',
          start: '2020-02-01',
          color: '#fc9919'
        },
        {
          title: 'Long Event',
          start: '2020-02-07',
          end: '2020-02-10',
          color: '#ffc107' // override!
        },
        {
          groupId: '999',
          title: 'Repeating Event',
          start: '2020-02-09T16:00:00',
          color: '#17a2b8'
        },
        {
          groupId: '999',
          title: 'Repeating Event',
          start: '2020-02-16T16:00:00',
          color: '#17a2b8'
        }
      ]
    }
  },
  mounted () {
    xray.index()
  },
  computed: {
  },
  methods: {
    handleDateClick (arg) {
      // console.log(arg)
    }
  }
}
</script>

<style lang='scss'>

@import '~@fullcalendar/core/main.css';
@import '~@fullcalendar/daygrid/main.css';

</style>
