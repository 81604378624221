<template>
  <div id="kuota-mcu" class="kuota-mcu">
    <b-container fluid class="mb-5">
      <iq-card class="mb-2">
        <div class="iq-email-to-list pt-3 pl-3 pr-3" id="header-table-filter">
          <div class="px-2 mb-3">
            <p class="text-dark h5 mb-0">KUOTA UMUM DAN PEGAWAI</p>
          </div>
          <div class="d-flex flex-row align-items-center mb-0 py-1 px-1 bg-filter flex-wrap">
            <!-- <div class="mr-1">
              <b-form-select class="cstm-cntrl-kuota" style="font-size: 13px;" plain v-model="selected.filterHari" :options="combo.hariFil" @input="actionrefresh" size="lg">
              </b-form-select>
            </div>
            <div cols="2" class="mr-1">
              <b-input-group>
                <b-form-input class="cstm-cntrl-kuota" style="font-size: 13px;" id="filter-input" v-model="table.filter" @keypress="isAlphabetKey" type="search" placeholder="[ Pencarian ]"></b-form-input>
              </b-input-group>
            </div> -->
            <div class="mr-lg-auto">
            </div>
            <div class="mt-1 mt-lg-0 ml-1 ml-lg-0">
              <b-button variant="primary" class="py-2" style="border-radius: 5px;" @click="TambahKoutaMcu"><i class="fas fa-plus-circle "></i>Tambah</b-button>
            </div>
          </div>
        </div>
        <div class="iq-email-listbox pl-3 pr-3 pb-3">
          <div class="table mb-0">
            <b-table class="mb-0 pointer-table" ref="table" :busy="table.isBusy" :items="table.items"
              :fields="table.fields" :current-page="table.currentPage" :per-page="table.perPage" :filter="table.filter"
              @filtered="onFiltered" :sort-by.sync="table.sortBy" :sort-desc.sync="table.sortDesc" stacked="md" bordered
              hover show-empty small striped>
              <template #table-busy>
                <div class="text-center my-2">
                  <b-spinner class="align-middle text-primary mr-2"></b-spinner>
                  <strong class="text-dark">Memuat Data...</strong>
                </div>
              </template>
              <template #cell(index)="data">
                {{ (table.currentPage*table.perPage)-table.perPage + data.index + 1 }}
              </template>
              <template #cell(action)="data">
                <b-button @click="handleShowDtl(data.item.id_dokter)" variant=" mr-1 mb-1" size="lg">
                  <i class="fas fa-edit text-primary"></i>
                </b-button>
                <b-button @click="deleteModal(data.item.id_dokter, data.item.ruangan)" variant=" mr-1 mb-1" size="lg">
                  <i class="fas fa-trash-alt text-danger"></i>
                </b-button>
              </template>
            </b-table>
          </div>
          <div class="bg-white border pb-2 pt-2 pl-3 pr-3">
            <b-row class="align-items-center justify-content-between">
              <b-col cols="12" lg="3" class="mr-auto">
                <div class="d-flex align-items-center">
                  <div>
                    <b-pagination v-model="table.currentPage" :total-rows="table.totalRows" :per-page="table.perPage"
                      size="sm" class="my-0">
                    </b-pagination>
                  </div>
                  <div class="border-left border-right px-3">
                    <span class="cursor-point" @click="tableRefresh">
                      <i class="fas fa-sync text-primary"></i>
                    </span>
                  </div>
                </div>
              </b-col>
              <b-col cols="12" lg="4" class="d-flex justify-content-end align-items-center">
                <div class="mr-3">
                  <b-form-select id="per-page-select" v-model="table.perPage" :options="table.pageOptions"
                    size="sm"></b-form-select>
                </div>
                <div>
                  <p class="font-weight-normal mb-0 text-dark" v-if="table.totalRows">Menampilkan 1 - {{ table.perPage
                    }} dari {{ table.totalRows }}</p>
                </div>
              </b-col>
            </b-row>
          </div>
        </div>
      </iq-card>
    </b-container>
    <b-modal id="modal-new-kuota-mcu" ref="modal-new-kuota-mcu" size="sm" centered title="Tambah Kouta Umum dan Pegawai"
      ok-title="Simpan Data" cancel-title="Tutup" :no-close-on-backdrop="true" :static="true" @cancel="clearData">
      <b-form>
        <b-form-group>
          <b-row class="align-items-center">
            <b-col cols="2">
              <span>
                <p class="small text-dark mb-0">Hari</p>
              </span>
            </b-col>
            <b-col>
              <multiselect v-model="value" placeholder="Hari" label="name" track-by="value" :options="options"
                :multiple="true" @tag="addHari" :deselect-label="''" :select-label="''" :selected-label="''">
              </multiselect>
            </b-col>
          </b-row>
        </b-form-group>
        <b-form-group>
          <b-row class="align-items-center">
            <b-col cols="2">
              <span>
                <p class="small text-dark mb-0">Total Kouta</p>
              </span>
            </b-col>
            <b-col>
              <b-form-input class="new-cstm-cntrl-katalog" type="number" placeholder="Total Kouta"></b-form-input>
            </b-col>
          </b-row>
        </b-form-group>
        <b-form-group>
          <b-row class="align-items-center">
            <b-col cols="2">
              <span>
                <p class="small text-dark mb-0">Kouta Online</p>
              </span>
            </b-col>
            <b-col>
              <b-form-input class="new-cstm-cntrl-katalog" type="number" placeholder="Kouta Online"></b-form-input>
            </b-col>
          </b-row>
        </b-form-group>
        <b-form-group>
          <b-row class="align-items-center">
            <b-col cols="2">
              <span>
                <p class="small text-dark mb-0">Kouta Onsite</p>
              </span>
            </b-col>
            <b-col>
              <b-form-input class="new-cstm-cntrl-katalog" type="number" placeholder="Kouta Onsite"></b-form-input>
            </b-col>
          </b-row>
        </b-form-group>
        <b-form-group>
          <b-row class="align-items-center">
            <b-col cols="2">
              <span>
                <p class="small text-dark mb-0">Jam Mulai</p>
              </span>
            </b-col>
            <b-col>
              <b-form-input class="new-cstm-cntrl-katalog" maxlength="5" type="text" placeholder="__:__" v-model="jam_mulai" @input="formatTime"></b-form-input>
            </b-col>
          </b-row>
        </b-form-group>
        <b-form-group>
          <b-row class="align-items-center">
            <b-col cols="2">
              <span>
                <p class="small text-dark mb-0">Jam Ahkir</p>
              </span>
            </b-col>
            <b-col>
              <b-form-input class="new-cstm-cntrl-katalog" maxlength="5" type="text" placeholder="__:__" v-model="jam_ahkir" @input="formatTime1"></b-form-input>
            </b-col>
          </b-row>
        </b-form-group>
      </b-form>
    </b-modal>
    <b-modal ref="modal-konfirm-delete-hard" size="md" title="KONFIRMASI HAPUS DOKTER" ok-variant="danger"
      ok-title="Hapus" @ok="handleDelete" @close="clearErr" @cancel="clearErr" :no-close-on-backdrop="true"
      :static="true" cancel-title="Batal" centered>
      <div class="px-1 py-2">
        <div class="text-center ">
          <i class="fas fa-exclamation-circle text-danger fa-5x"></i>
          <p class="h6 text-danger mb-0 mt-3">Anda Yakin Akan Menghapus Kuota ?</p>
        </div>
        <div class="bg-dark px-2 mt-2" v-if="error">
          <p class="small text-white mb-0">{{ error }}</p>
        </div>
      </div>
    </b-modal>
    <b-modal ref="modal-sukses" size="md" title="Informasi" ok-title="Oke" :ok-only="true" :hide-header="true" centered>
      <div class="text-center px-lg-4 px-2">
        <lottie :options="defaultOptions" :autoPlay="true" :width="250" :height="250" />
        <p class="h6 text-success font-weight-bold mb-0">{{ message }}</p>
      </div>
    </b-modal>
    <b-modal ref="modal-gagal" size="md" title="Informasi" ok-title="Kembali" :ok-only="true" :hide-header="true"
      centered>
      <div class="text-center px-lg-4 px-2">
        <lottie :options="defaultOptions2" :autoPlay="true" :width="250" :height="250" />
        <p class="h6 text-danger font-weight-bold mb-2">Data Gagal Disimpan</p>
        <p class="small text-danger font-weight-light mb-0">{{error}}</p>
      </div>
    </b-modal>
    <ModalMsgbox ref="MsgBox" />
  </div>
</template>
<script>
import { xray } from '../../../config/pluginInit'
import Lottie from 'vue-lottie'
import animationLogo from '../../../assets/lottie/checked.json'
import animationLogo2 from '../../../assets/lottie/failed.json'
import Service from '../../../services/fatmahost/index'
import Multiselect from 'vue-multiselect'
// import flatPickr from 'vue-flatpickr-component'
// import { Indonesian as IndonesianLocale } from 'flatpickr/dist/l10n/id.js'
// import 'flatpickr/dist/flatpickr.css'
import ModalMsgbox from '../../../components/modal/ModalMsgBox'
// import kuota from '../../../services/kuota'
export default {
  name: 'koutaMcu',
  components: {
    ModalMsgbox,
    Multiselect,
    Lottie
    // flatPickr
  },
  data () {
    return {
      jam_mulai: '',
      jam_ahkir: '',
      table: {
        isBusy: false,
        items: this.list,
        sortBy: '',
        sortDesc: false,
        fields: [
          { label: 'No', key: 'index', class: 'text-center' },
          { label: 'Hari', key: 'hari', class: 'text-center', sortable: true },
          { label: 'Kouta', key: 'kouta', class: 'text-center', sortable: true },
          { label: 'Aksi', key: 'action', class: 'text-center' }
        ],
        filter: null,
        currentPage: null,
        perPage: 10,
        pageOptions: [10, 50, 100, 500],
        totalRows: null
      },
      value: [
        { name: 'Senin', value: '1' }
      ],
      options: [
        { name: 'Senin', value: '1' },
        { name: 'Selasa', value: '2' },
        { name: 'Rabu', value: '3' },
        { name: 'Kamis', value: '4' },
        { name: 'Jumat', value: '5' },
        { name: 'Sabtu', value: '6' }
      ],
      newTag: '',
      combo: {
        ruangan: [],
        hariFil: [
          { value: '1', text: 'Senin' },
          { value: '2', text: 'Selasa' },
          { value: '3', text: 'Rabu' },
          { value: '4', text: 'Kamis' },
          { value: '5', text: 'Jumat' },
          { value: '6', text: 'Sabtu' }
        ],
        filterRuang: [
          { value: '', text: 'Rawat Jalan' }
        ]
      },
      selected: {
        unit: [],
        ruangan: [],
        filterHari: ''
      },
      id: null,
      filtering: {
        valTglAwal: '',
        valTglAkhir: ''
      },
      error: '',
      message: '',
      messageTgl: '',
      defaultOptions: { animationData: animationLogo, loop: true },
      defaultOptions2: { animationData: animationLogo2, loop: true }
    }
  },
  mounted () {
    xray.index()
  },
  methods: {
    formatTime () {
      let value = this.jam_mulai.replace(/[^0-9]/g, '')

      if (value.length > 2) {
        value = value.substring(0, 2) + ':' + value.substring(2, 4)
      }

      this.jam_mulai = value
    },
    formatTime1 () {
      let value1 = this.jam_ahkir.replace(/[^0-9]/g, '')

      if (value1.length > 2) {
        value1 = value1.substring(0, 2) + ':' + value1.substring(2, 4)
      }
      this.jam_ahkir = value1
    },
    clearData () {
      this.value = []
    },
    clearErr () {
    },
    filterchange () {
      this.table.filter = ''
    },
    addHari (newTag) {
      const tag = {
        name: newTag,
        value: newTag.substring(0, 2) + Math.floor((Math.random() * 10000000))
      }
      this.options.push(tag)
      this.value.push(tag)
    },
    async list (ctx) {
      // let filter = ''
      // let sortfield = ''
      // let sorting = ''
      // let filterType = this.selected.filterType ? this.selected.filterType : ''
      // let filterHari = this.selected.filterHari ? this.selected.filterHari : ''
      // if (ctx.filter === '') {
      //   ctx.filter = null
      // }
      // if (ctx.sortBy) {
      //   sortfield = ctx.sortBy
      // } else {
      //   sortfield = ''
      // }
      // if (ctx.sortDesc === true) {
      //   sorting = 'DESC'
      // } else if (ctx.sortDesc === false) {
      //   sorting = 'ASC'
      // } else {
      //   sorting = ''
      // }
      // if (ctx.filter === null) {
      //   filter = `param3={"xparam0":"","xparam1":"","xparam2":"${filterHari}","xparam3":"","xparam4":""}&param4={"xparam0":"${sortfield}","xparam1":"${sorting}"}`
      // } else {
      //   if (filterType === '1') {
      //     filter = `param3={"xparam0":"","xparam1":"${ctx.filter}", "xparam2":"${filterHari}", "xparam3":"", "xparam4":""}&param4={"xparam0":"${sortfield}","xparam1":"${sorting}"}`
      //   } else if (filterType === '2') {
      //     filter = `param3={"xparam0":"${ctx.filter}","xparam1":"", "xparam2":"${filterHari}", "xparam3":"", "xparam4":""}&param4={"xparam0":"${sortfield}","xparam1":"${sorting}"}`
      //   }
      // }
      // this.table.isBusy = true
      // try {
      //   const response = await Service.master.kuota_dokter_findall_v2(ctx.currentPage - 1, ctx.perPage, filter)
      //   this.table.isBusy = false
      //   this.table.totalRows = response.data.pagination.total
      //   this.table.perPage = response.data.pagination.perPage
      //   this.table.currentPage = response.data.pagination.current
      //   return response.data.data
      // } catch (error) {
      //   this.table.isBusy = false
      //   return []
      // }
    },
    TambahKoutaMcu () {
      this.$refs['modal-new-kuota-mcu'].show()
    },
    async handleSubmit (bvModalEvent) {
      bvModalEvent.preventDefault()
      try {
        const data = this.kuota
        const response = await Service.master(data)
        if (response.data.statcode === 0) {
          this.message = response.data.message
          this.$refs['modal-new-kuota-mcu'].hide()
          this.$refs.MsgBox.show({
            type: 'success',
            msg: response.data.message
          })
          this.clearAll()
        } else {
          this.error = response.data.message
        }
        this.$refs.table.refresh()
      } catch (error) {
        this.error = error.message
        this.$refs.table.refresh()
      }
    },
    async handleUpdate (bvModalEvent) {
      bvModalEvent.preventDefault()
      try {
        const id = this.id
        const data = this.editKuota
        const response = await Service.master(id, data)
        if (response.data.statcode === 0) {
          this.message = response.data.message
          this.$refs['modal-edit-kuota'].hide()
          this.$refs.MsgBox.show({
            type: 'success',
            msg: response.data.message
          })
          this.clearAll()
        } else {
          this.error = response.data.message
        }
        this.$refs.table.refresh()
      } catch (error) {
        this.error = error.message
        this.$refs.table.refresh()
      }
    },
    async handleDelete (bvModalEvent) {
      bvModalEvent.preventDefault()
      try {
        const id = this.id
        const ruangan = this.ruangan
        const response = await Service.master.kuota_dokter_delete_v2_hard(id, ruangan)
        if (response.data.statcode === 0) {
          this.$refs['modal-konfirm-delete-hard'].hide()
          this.$refs.MsgBox.show({
            type: 'success',
            msg: response.data.message
          })
          this.$refs.table.refresh()
        } else {
          this.error = response.data.message
          this.$refs.table.refresh()
        }
      } catch (error) {
        this.error = error.message
        this.$refs.table.refresh()
      }
    },
    valHari ($event, index, param) {
      if (param === 't') {
        this.kuota.detail[index].hari = $event.id.toString()
      } else if (param === 'e') {
        this.editKuota.detail[index].hari = $event.id.toString()
      }
    },
    onFiltered (filteredItems) {
      this.table.totalRows = filteredItems.length
    },
    tableRefresh () {
      this.$refs.table.refresh()
    }
  }
}
</script>

<style>
#kuota-mcu .bg-filter {
  border: 1px solid rgba(0, 0, 0, 0.13);
  background-color: #089bab1e;
}
#header-table-filter .flatpickr-input[readonly] {
  height: 38px;
  font-size: 12px;
  border-radius: 5px;
  border: 1px solid rgba(17, 17, 17, 0.292);
  padding-left: 10px;
}
#kuota-mcu legend {
  color: darkslategrey;
}
#kuota-mcu .form-control:disabled, #kuota-mcu .form-control[readonly] {
  background-color: #e9ecef;
  opacity: 0.5;
}
#kuota-mcu .form-control {
  color: black!important;
}
#kuota-mcu .fc-unthemed .fc-toolbar .fc-button {
  margin: 3px;
}
#kuota-mcu tr:first-child > td > .fc-day-grid-event {
  cursor: pointer;
}
#kuota-mcu .multiselect__input, .multiselect {
  font-weight: bold!important;
}
td.fc-day.fc-past {
  background-color: #EEEEEE;
}
#modal-kuota-edit .multiselect--disabled {
  opacity: 1;
}
#kuota-mcu .flex-half-width-left {
  width: 76%;
}
#kuota-mcu .flex-half-width-right {
  width: 23%;
}
#kuota-mcu .flex-full-width-left {
  width: 96%;
}
#kuota-mcu .flex-full-width-right {
  width: 3%;
}
#kuota-mcu .txt-farmasi-base {
  font-size: 12px;
}
#kuota-mcu .txt-farmasi-base-head {
  font-size: 14px;
}
#kuota-mcu .table td {
  font-size: 12px;
  font-weight: 500;
  padding-top: 0px;
  padding-bottom: 0px;
}
#modal-new-kuota-mcu .modal-dialog {
  max-width: 700px;
}
#kuota-mcu .table th {
  font-size: 13px;
  font-weight: 400;
}
#kuota-mcu .form-control {
  border: 1px solid rgb(194, 194, 194);
  background: white;
  height: 40px;
}
#kuota-mcu .multiselect__tags {
  border-radius: 3px;
  border: 1px solid rgb(194, 194, 194);
  background: white;
  font-size: 11px;
  font-weight: 550;
  height: 10px;
}
#kuota-mcu .btn-height-custom {
  height: 39px;
}
#kuota-mcu .cursor-point {
  cursor: pointer;
}
#kuota-mcu .scroll-y {
  overflow-y: scroll;
  height: 550px;
}
#kuota-mcu .bg-abu {
  background: rgba(236, 236, 236, 0.541);
}
#kuota-mcu .form-control {
  font-size: 11px;
}
#kuota-mcu .b-table-sticky-header, #kuota-mcu .table-responsive {
  margin-bottom: 0px;
}
#kuota-mcu .table-barang .btn {
  font-size: 11px;
}
#kuota-mcu .page-link {
  border: none;
  color: gray!important;
  margin-right: 15px;
}
#kuota-mcu .page-item.active .page-link {
  background-color: transparent;
  border-color: transparent;
  color: #089bab!important;
  font-weight: bold;
}
#kuota-mcu .vertical-txt {
  writing-mode: vertical-rl;
  letter-spacing: 10px;
}
#kuota-mcu .transition-cstm {
  transition: .3s linear;
}
.cstm-slct-kuota {
  font-size: 13px!important;
  height: 38px!important;
  border-radius: 5px;
}
.alert-radius-kuota {
  border-radius: 5px;
}

.font-size-responsive {
  font-size: 7px;
}

@media (min-width: 576px) {
  .font-size-responsive {
    font-size: 8px;
  }
 }

@media (min-width: 768px) {
  .font-size-responsive {
    font-size: 9px;
  }
 }

@media (min-width: 992px) {
  .font-size-responsive {
    font-size: 10px;
  }
 }

@media (min-width: 1200px) {
  .font-size-responsive {
    font-size: 11px;
  }
 }
 .pointer-table tbody tr {
  cursor: pointer;
}
</style>
