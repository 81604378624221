<template>
  <div id="katalog-saktiHdr" class="mb-4">
    <b-container fluid>
      <div>
        <iq-card>
          <template v-slot:body>
            <div class="filter-saktiHdr border p-2">
              <b-row class="align-items-center">
                <b-col cols="12" lg="3" class="d-flex flex-row w-100 mr-auto">
                  <div>
                    <h5 class="card-title font-weight-bold mb-0">Master Sakti Hdr</h5>
                  </div>
                </b-col>
                <b-col cols="12" lg="9">
                  <div class="text-right">
                    <b-button class="custom-button-radius" size="sm" variant="primary" @click="tambahModal"><i class="fas fa-plus"></i> Tambah</b-button>
                  </div>
                </b-col>
              </b-row>
            </div>
            <div class="table mb-0">
              <b-table class="mb-0" ref="table" :sort-by.sync="table.sortBy" :sort-desc.sync="table.sortDesc" :busy="table.isBusy" :items="table.items" :fields="table.fields"
                :current-page="table.currentPage" :per-page="table.perPage" :filter="table.filter" @filtered="onFiltered"
                stacked="md" bordered hover show-empty small striped>
                <template #cell(index)="data">
                {{ (table.currentPage*table.perPage)-table.perPage + data.index + 1 }}
                </template>
                <template #cell(action)="data">
                  <b-button @click="editModal(data.item.id)" variant=" mr-1 mb-1"
                    size="lg">
                    <i class="fas fa-edit text-primary"></i>
                  </b-button>
                  <b-button @click="deleteModal(data.item.id)" variant=" mr-1 mb-1"
                    size="lg">
                    <i class="fas fa-trash-alt text-danger"></i>
                  </b-button>
                </template>
                <template #cell(sysdate_updt)="data">
                  <span>{{ new Date(data.item.sysdate_updt) | formatDate }}</span>
                </template>
              </b-table>
            </div>
            <div class="pagination-saktiHdr border shadow-sm p-2 mb-2">
              <b-row class="align-items-center">
                <b-col cols="4" class="d-flex flex-row align-items-center">
                  <div class="w-100">
                    <b-pagination
                      v-model="table.currentPage"
                      :total-rows="table.totalRows"
                      :per-page="table.perPage"
                      size="md"
                      class="my-0"
                    ></b-pagination>
                  </div>
                  <div class="w-100">
                    <div>
                      <span class="border-left border-dark mr-3 py-1"></span>
                      <span @click="tableRefresh" class="katalog-cursor">
                        <i class="fas fa-sync text-secondary"></i>
                      </span>
                      <span class="border-right border-dark ml-3 py-1"></span>
                    </div>
                  </div>
                </b-col>
                <b-col cols="5">
                  <div class="d-flex flex-row justify-content-end align-items-center">
                    <div class="mr-1 w-75">
                      <form class="searchbox mb-0">
                        <input v-model="table.filter" type="search" class="form-control cstm-srch-saktiHdr" id="filter-input"
                          placeholder="[Cari]">
                      </form>
                    </div>
                    <div>
                      <b-form-select class="cstm-slct-saktiHdr" v-model="selected.filter" :options="options.optionsFilter" @change="filterchange"></b-form-select>
                    </div>
                  </div>
                </b-col>
                <b-col cols="3">
                  <div class="d-flex flex-row align-items-center justify-content-end">
                    <div class="mr-3">
                      <b-form-select
                        class="cstm-select-saktiHdr"
                        id="per-page-select"
                        v-model="table.perPage"
                        :options="table.pageOptions"
                      ></b-form-select>
                    </div>
                    <div>
                      <p class="text-dark mb-0">Menampilkan {{ table.perPage }} dari {{ table.totalRows }} baris</p>
                    </div>
                  </div>
                </b-col>
              </b-row>
            </div>
          </template>
        </iq-card>
      </div>
    </b-container>
    <div id="modal-component-saktiHdr">
      <b-modal id="modal-tambah-saktiHdr" ref="modal-tambah-saktiHdr" size="lg" centered title="Tambah SaktiHdr" ok-title="Simpan Data"
        cancel-title="Tutup" @close="clear" @cancel="clear" :no-close-on-backdrop="true" :static="true" @ok="tambahSave">
        <b-form>
          <b-form-group class="mb-1">
            <b-row class="align-items-center">
              <b-col cols="2">
                <span>
                  <p class="small text-dark mb-0">Kode</p>
                </span>
              </b-col>
              <b-col>
                <div>
                  <b-form-input class="new-cstm-cntrl-katalog" v-model="tambahSaktiHdr.kode" placeholder="[ Kode ]"></b-form-input>
                </div>
              </b-col>
            </b-row>
          </b-form-group>
          <b-form-group class="mb-1">
            <b-row class="align-items-center">
              <b-col cols="2">
                <span>
                  <p class="small text-dark mb-0">Uraian</p>
                </span>
              </b-col>
              <b-col>
                <div>
                  <b-form-input class="new-cstm-cntrl-katalog" v-model="tambahSaktiHdr.uraian" placeholder="[ Uraian ]"></b-form-input>
                </div>
              </b-col>
            </b-row>
          </b-form-group>
          <b-form-group class="mb-0">
            <b-row class="align-items-center">
              <b-col cols="2">
                <span>
                  <p class="small text-dark mb-0">User In</p>
                </span>
              </b-col>
              <b-col>
                <b-form-input class="new-cstm-cntrl-katalog" v-model="preview.user_name" placeholder="[ Nama User ]" readonly></b-form-input>
              </b-col>
            </b-row>
          </b-form-group>
        </b-form>
        <div class="bg-dark px-2 mt-2" v-if="preview.messageValue === '2'">
          <p class="text-right small text-white mb-0">{{ preview.message }}</p>
        </div>
        <!-- <pre>{{ tambahSaktiHdr }}</pre> -->
      </b-modal>
      <b-modal id="modal-edit-saktiHdr" ref="modal-edit-saktiHdr" size="lg" centered title="Edit SaktiHdr" ok-title="Perbarui Data" cancel-title="Tutup"
      @close="clear" @cancel="clear" :no-close-on-backdrop="true" :static="true" @ok="editSave">
        <b-form>
          <b-form-group class="mb-1">
            <b-row class="align-items-center">
              <b-col cols="2">
                <span>
                  <p class="small text-dark mb-0">Kode</p>
                </span>
              </b-col>
              <b-col>
                <div>
                  <b-form-input class="new-cstm-cntrl-katalog" v-model="editSaktiHdr.kode" placeholder="[ Kode ]"></b-form-input>
                </div>
              </b-col>
            </b-row>
          </b-form-group>
          <b-form-group class="mb-1">
            <b-row class="align-items-center">
              <b-col cols="2">
                <span>
                  <p class="small text-dark mb-0">Uraian</p>
                </span>
              </b-col>
              <b-col>
                <div>
                  <b-form-input class="new-cstm-cntrl-katalog" v-model="editSaktiHdr.uraian" placeholder="[ Uraian ]"></b-form-input>
                </div>
              </b-col>
            </b-row>
          </b-form-group>
          <b-form-group class="mb-0">
            <b-row class="align-items-center">
              <b-col cols="2">
                <span>
                  <p class="small text-dark mb-0">User Update</p>
                </span>
              </b-col>
              <b-col>
                <b-form-input class="new-cstm-cntrl-katalog" v-model="preview.user_name" placeholder="[ Nama User ]" readonly></b-form-input>
              </b-col>
            </b-row>
          </b-form-group>
        </b-form>
        <div class="bg-dark px-2 mt-2" v-if="preview.messageValue === '2'">
          <p class="text-right small text-white mb-0">{{ preview.message }}</p>
        </div>
        <!-- <pre>{{ editSaktiHdr }}</pre> -->
      </b-modal>
      <b-modal ref="modal-delete-saktiHdr" size="md" centered title="Konfirmasi" ok-title="Hapus Data" ok-variant="danger"
        cancel-title="Tutup" @ok="deleteSave">
        <div class="py-3">
          <p class="h6 text-center text-dark mb-0">Yakin Ingin Menghapus Data ?</p>
        </div>
      </b-modal>
      <b-modal ref="modal-notif-saktiHdr" size="md" centered title="Notifikasi" ok-title="Tutup" :hide-footer="true"
        :hide-header="true">
        <div class="pb-3 px-4">
          <div v-if="preview.messageValue === '1'">
            <lottie :options="preview.lottie.defaultOptions" :autoPlay="true" :width="150" :height="150" />
          </div>
          <div v-else>
            <lottie :options="preview.lottie.defaultOptions2" :autoPlay="true" :width="150" :height="150" />
          </div>
          <p class="h6 text-dark text-center mb-0 mt-2">{{ preview.message }}</p>
        </div>
      </b-modal>
    </div>
  </div>
</template>
<script>
import { xray } from '../../../config/pluginInit'
import jwtdecode from 'jwt-decode'
import Services from '../../../services/fatmahost/katalog/index'
import Lottie from 'vue-lottie'
import animationLogo from '../../../assets/lottie/checked.json'
import animationLogo2 from '../../../assets/lottie/failed.json'

export default {
  name: 'SaktiHdr',
  components: {
    Lottie
  },
  data () {
    return {
      table: {
        isBusy: false,
        items: this.list,
        sortBy: '',
        sortDesc: true,
        fields: [
          { label: 'No', key: 'index', class: 'text-center' },
          { label: 'KODE', key: 'msh.kode', sortable: true },
          { label: 'URAIAN', key: 'msh.uraian', sortable: true },
          { label: 'USER IN', key: 'up.name', sortable: true },
          { label: 'USER UPDATE', key: 'up1.name', sortable: true },
          { label: 'LAST UPDATE', key: 'msh.sysdate_updt', sortable: true },
          { label: 'AKSI', key: 'action', class: 'text-center' }
        ],
        filter: null,
        currentPage: null,
        perPage: null,
        pageOptions: [10, 25, 50, 100],
        totalRows: null
      },
      tambahSaktiHdr: {
        object: 'sakti-hdr',
        kode: '',
        uraian: '',
        userid_in: jwtdecode(localStorage.getItem('token')).user.id.toString()
      },
      editSaktiHdr: {
        object: 'sakti-hdr',
        kode: '',
        uraian: '',
        userid_in: '',
        sysdate_in: '',
        userid_updt: jwtdecode(localStorage.getItem('token')).user.id.toString()
      },
      options: {
        optionsStsAktif: [
          { text: 'Aktif', value: '1' },
          { text: 'Tidak Aktif', value: '2' }
        ],
        optionsFilter: [
          { text: 'Kode', value: '1' },
          { text: 'Uraian', value: '2' }
        ],
        saktiHdr: [],
        editSaktiHdr: []
      },
      selected: {
        filter: '1',
        saktiHdr: [],
        editSaktiHdr: []
      },
      preview: {
        lottie: {
          defaultOptions: { animationData: animationLogo, loop: true },
          defaultOptions2: { animationData: animationLogo2, loop: true }
        },
        user_name: jwtdecode(localStorage.getItem('token')).user.name,
        edit_id: '',
        delete_id: '',
        message: '',
        messageValue: ''
      }
    }
  },
  mounted () {
    xray.index()
  },
  methods: {
    tableRefresh () {
      this.$refs.table.refresh()
    },
    async list (ctx) {
      let filterType = this.selected.filter
      let sortfield = ''
      let sorting = ''
      if (ctx.filter === '') {
        ctx.filter = null
      }
      if (ctx.sortBy) {
        sortfield = ctx.sortBy
      } else {
        sortfield = ''
      }
      if (ctx.sortDesc === true) {
        sorting = 'DESC'
      } else if (ctx.sortDesc === false) {
        sorting = 'ASC'
      } else {
        sorting = ''
      }
      this.isBusy = true
      try {
        const response = await Services.listSaktiHdr(ctx.currentPage - 1, ctx.perPage, ctx.filter, filterType, sortfield, sorting)
        this.isBusy = false
        this.table.totalRows = response.data.pagination.total
        this.table.perPage = response.data.pagination.perPage
        this.table.currentPage = response.data.pagination.current
        return response.data.data
      } catch (error) {
        this.isBusy = false
        return []
      }
    },
    onFiltered (filteredItems) {
      this.table.totalRows = filteredItems.length
      this.table.currentPage = 1
    },
    filterchange () {
      this.table.filter = ''
    },
    tambahModal () {
      this.$refs['modal-tambah-saktiHdr'].show()
    },
    async tambahSave (bvModalEvent) {
      bvModalEvent.preventDefault()
      try {
        let data = this.tambahSaktiHdr
        const response = await Services.createSaktiHdr(data)
        if (response.data.statcode === 0) {
          this.preview.message = response.data.message
          this.preview.messageValue = '1'
          this.$refs['modal-tambah-saktiHdr'].hide()
          this.$refs['modal-notif-saktiHdr'].show()
          this.$refs.table.refresh()
          this.clear()
        } else {
          this.preview.message = response.data.message
          this.preview.messageValue = '2'
        }
      } catch (error) {
        this.preview.message = error.message
        this.preview.messageValue = '2'
      }
    },
    async editModal (id) {
      let ids = id
      this.preview.edit_id = ids
      try {
        const response = await Services.showSaktiHdr(ids)
        this.editSaktiHdr.kode = response.data.data[0].kode
        this.editSaktiHdr.uraian = response.data.data[0].uraian
        this.editSaktiHdr.sysdate_in = response.data.data[0].sysdate_in
        this.editSaktiHdr.userid_in = response.data.data[0].userid_in.toString()
        this.selected.editSaktiHdr = response.data.data[0] ? { kode: response.data.data[0].kode, uraian: response.data.data[0].uraian } : []
      } catch (error) {
        this.preview.message = error.message
        this.preview.messageValue = '2'
        // this.$refs['modal-notif-saktiHdr'].show()
      }
      this.$refs['modal-edit-saktiHdr'].show()
    },
    async editSave (bvModalEvent) {
      bvModalEvent.preventDefault()
      try {
        let id = this.preview.edit_id
        let data = this.editSaktiHdr
        const response = await Services.editSaktiHdr(id, data)
        if (response.data.statcode === 0) {
          this.preview.message = response.data.message
          this.preview.messageValue = '1'
          this.$refs['modal-edit-saktiHdr'].hide()
          this.$refs['modal-notif-saktiHdr'].show()
          this.$refs.table.refresh()
          this.clear()
        } else {
          this.preview.message = response.data.message
          this.preview.messageValue = '2'
        }
      } catch (error) {
        this.preview.message = error.message
        this.preview.messageValue = '2'
      }
    },
    deleteModal (id) {
      this.preview.delete_id = id
      this.$refs['modal-delete-saktiHdr'].show()
    },
    async deleteSave () {
      let id = this.preview.delete_id
      try {
        const response = await Services.deleteSaktiHdr(id)
        if (response.data.statcode === 0) {
          this.preview.message = response.data.message
          this.preview.messageValue = '1'
        } else {
          this.preview.message = response.data.message
          this.preview.messageValue = '2'
        }
        this.$refs['modal-notif-saktiHdr'].show()
        this.$refs.table.refresh()
      } catch (error) {
        this.preview.message = error.message
        this.preview.messageValue = '2'
        this.$refs['modal-notif-saktiHdr'].show()
      }
    },
    clear () {
      this.tambahSaktiHdr.object = 'sakti-hdr'
      this.tambahSaktiHdr.kode = ''
      this.tambahSaktiHdr.uraian = ''
      this.tambahSaktiHdr.userid_in = jwtdecode(localStorage.getItem('token')).user.id.toString()
    },
    clearMessage () {
      this.preview.messageValue = ''
      this.preview.message = ''
    }
  }
}
</script>
<style>
#modal-tambah-saktiHdr .custom-control-label, #modal-edit-saktiHdr .custom-control-label {
    font-size: 12px;
    margin-top: 0;
}
#modal-tambah-saktiHdr .multiselect__single, #modal-edit-saktiHdr .multiselect__single {
  font-size: 12px!important;
}
#modal-tambah-saktiHdr .multiselect__placeholder, #modal-edit-saktiHdr .multiselect__placeholder {
  font-size: 12px!important;
}
#modal-tambah-saktiHdr .multiselect__option, #modal-edit-saktiHdr .multiselect__option {
    font-size: 12px;
}
#modal-tambah-saktiHdr .multiselect__tags, #modal-edit-saktiHdr .multiselect__tags {
  padding: 5px 40px 0 8px!important;
  height: 38px!important;
  min-height: 38px!important;
}
#modal-tambah-saktiHdr  .modal-body, #modal-edit-saktiHdr .modal-body {
  background: #3399331b
}

#modal-tambah-saktiHdr  .modal-header, #modal-edit-saktiHdr .modal-header {
  background: #3399333a;
}

#modal-tambah-saktiHdr  .modal-footer, #modal-edit-saktiHdr .modal-footer {
  background: #3399333a;
}
#katalog-saktiHdr .page-link {
  font-size: 14px;
  padding-top: 5px;
  padding-bottom: 5px;
  margin-right: 10px!important;
}
#katalog-saktiHdr .custom-button-radius {
  border: 1px solid rgba(0, 0, 0, 0.112);
  border-radius: 5px!important;
}
#katalog-saktiHdr .table td {
    font-size: 12px;
    font-weight: 500;
    padding-top: 0px;
    padding-bottom: 0px;
}
#katalog-saktiHdr .table th {
    font-size: 13px;
    font-weight: 400;
}
#katalog-saktiHdr .cstm-select-saktiHdr {
  font-size: 13px;
  height: 38px!important;
  border-radius: 5px;
}
#katalog-saktiHdr .cstm-srch-saktiHdr {
  font-size: 13px;
  height: 38px!important;
  border-radius: 5px;
}
#katalog-saktiHdr .cstm-slct-saktiHdr {
  font-size: 13px;
  height: 38px!important;
  border-radius: 5px;
}
.new-cstm-cntrl-katalog {
  background: white!important;
  color: black!important;
  border-radius: 5px!important;
  font-size: 12px!important;
  height: 38px!important;
}
.katalog-form-area {
  color: black!important;
  border-radius: 5px!important;
  font-size: 12px!important;
}
#katalog-saktiHdr .pagination-sm .page-link {
  color: gray!important;
}
#katalog-saktiHdr .page-item.active .page-link {
  color: white!important;
}
#katalog-saktiHdr .page-item.active .page-link {
  color: white!important;
}
.custom-radius-add-katalog {
  border-radius: 5px;
  cursor: pointer;
}
.katalog-select-flex-left {
  width: 90%;
}
.katalog-select-flex-right {
  width: 10%;
}
.katalog-form-satuan-kemasan-left-right {
  width: 40%;
}
.katalog-form-satuan-kemasan-center {
  width: 15%;
}
.hr-katalog-custom {
  border-top: 1px solid rgba(0, 0, 0, 0.199)!important;
  margin: 0!important;
}
#katalog-saktiHdr .pagination-saktiHdr .page-link {
    border: none;
    color: gray!important;
    margin-right: 15px;
}
#katalog-saktiHdr .pagination-saktiHdr .page-item.active .page-link {
    background-color: transparent;
    border-color: transparent;
    color: #089bab!important;
    font-weight: bold;
}
</style>
