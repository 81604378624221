export default {
  SET_ACTIVE_TAB_EMR (state, data) {
    state.tabActiveEmr = data
    localStorage.setItem('active_tab_emr', data)
  },
  SET_FHIR_EMR (state, data) {
    state.fhir = data
    localStorage.setItem('fhir_emr', data)
  },
  SET_NORM_EMR (state, data) {
    state.norm = data
    localStorage.setItem('norm_emr', data)
  }
}
