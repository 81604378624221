<template>
  <div id="utd-formulir">
    <div class="d-block d-lg-none">
      <div class="fixed-top bg-white py-3 shadow-sm border cursor-reservasi">
        <b-container>
          <div class="d-flex flex-row align-items-center" @click="backToBeranda">
            <div class="mr-2">
              <i class="ri-arrow-left-line text-dark font-weight-bold fa-lg "></i>
            </div>
            <div>
              <p class="h6 text-dark">Donor Darah</p>
            </div>
          </div>
        </b-container>
      </div>
    </div>
    <b-container fluid class="px-lg-5 my-lg-5 pt-4 pt-lg-0">
      <b-row class="justify-content-center shadow rounded">
        <b-col xl="2" lg="3" sm="12" class="px-0">
          <div
            class="img-side-left bg-utd-side-left d-flex flex-column justify-content-end align-items-center utd-border-left px-4 py-5 h-100">
            <div class="mb-auto">
              <p class="h5 font-weight-bold text-danger text-center">Unit Transfusi Darah</p>
              <p class="small font-weight-bold text-dark text-center">RSUP Fatmawati</p>
              <div>
                <p class="small text-dark">1. Masukan NIK Anda Di Form <span class="font-weight-bold">Cek NIK</span>,
                  Untuk Pengecekan Jika Sudah Pernah
                  Mendaftar Diwaktu Sebelumnya</p>
                <p class="small text-dark">2. Jika Belum Pernah Mendaftar, Harap Mengisi Form <span
                    class="font-weight-bold">Data Diri, Reservasi, Dan Riwayat Medis</span></p>
                <p class="small text-dark">3. Jika Sudah Pernah Mendaftar, Harap Mengisi Kembali <span
                    class="font-weight-bold">Form Reservasi Dan Form Riwayat Medis</span></p>
              </div>
            </div>
            <!-- <div>
              <pre>{{ pasien }}</pre>
            </div> -->
            <div class="position-relative" style="z-index: 100;">
              <b-img :src="require('../../assets/images/utd_img.svg')" class="" width="400rem" fluid
                alt="Responsive image"></b-img>
            </div>
          </div>
        </b-col>
        <b-col xl="8" lg="6" sm="12" class="px-0">
          <div class="form-content bg-white p-4 p-lg-5">
            <div class="border rounded shadow-sm bg-cek-utd-pendaftaran mb-4 pb-5 pt-4 px-3 px-lg-4">
              <b-row class="justify-content-center mb-4">
                <b-col>
                  <div class="d-flex flex-row justify-content-center">
                    <div class="bg-dark shadow-sm px-4 py-2 rounded ">
                      <p class="h6 text-white font-weight-bold mb-0"><i class="fas fa-address-card"></i> CEK PENDAFTARAN
                      </p>
                    </div>
                  </div>
                </b-col>
              </b-row>
              <b-row class="justify-content-center align-items-end">
                <b-col cols="12" lg="5" class="mb-3 mb-lg-0">
                  <b-form-group label="Nomor Identitas" label-for="noIdentitas" class="mb-0">
                    <b-form-input v-model="cekNik" class="bg-white"
                      placeholder="Nomor NIK, SIM, Passport" id="cekData" type="search"></b-form-input>
                  </b-form-group>
                </b-col>
                <b-col cols="12" lg="5" class="mb-4 mb-lg-0">
                  <b-form-group label="Jenis Donor" label-for="jnsdonor" class="mb-0">
                    <b-form-select class="selected-cstm-utd" v-model="pasien.jenis_donor"
                      :options="optionsJenisDonor">
                    </b-form-select>
                  </b-form-group>
                </b-col>
                <b-col cols="12" lg="2">
                  <b-button variant="primary" class="btn-block btn-utd-nik shadow-sm" @click="utdnik">Cek</b-button>
                </b-col>
              </b-row>
            </div>
            <div class="d-flex flex-row justify-content-center mb-3 mt-4">
              <div class="bg-dark shadow-sm px-4 py-2 rounded ">
                <p class="h6 text-white font-weight-bold mb-0"><i class="fas fa-book-open"></i> FORM DATA DIRI
                </p>
              </div>
            </div>
            <b-form>
              <b-row>
                <b-col cols="12" lg="6">
                  <b-form-group label="Nama Lengkap:" label-for="nama" class="mb-3">
                    <b-form-input id="nama" :state="validationNama" v-model="pasien.nama" type="text" size="sm"
                      placeholder="[Nama Pendonor]" autocomplete="off" required>
                    </b-form-input>
                  </b-form-group>
                  <b-form-group label="Jenis Kelamin:" label-for="jenisKelamin" class="mb-3">
                    <b-form-select :state="validationJK" class="selected-cstm-utd" v-model="pasien.jenis_kelamin"
                      :options="options">
                    </b-form-select>
                  </b-form-group>
                  <b-form-group label="Tempat Lahir" label-for="tmptLahir" class="mb-3">
                    <multiselect id="tmptLahir" v-model="vselecttempatLahir" :options="tempatLahirMap" @input="valTempatLahir" :class="[pasien.tempat_lahir === '' ? 'multiselect__tags_color_empty' : 'multiselect__tags_color_valid']" :show-labels="false" label="deskripsi" placeholder="Pilih Tempat Lahir" :searchable="true">
                    </multiselect>
                  </b-form-group>
                  <b-form-group label="Tanggal Lahir" label-for="tglLahir" class="mb-3">
                    <template>
                      <flat-pickr id="tglLahir" v-model="tglLahir" @on-change="valTglLahir" :config="configTglLahir" placeholder="Pilih Tanggal "
                        :class="['w-100 custom-flatpicker calender-small', tglLahirIsValid ? 'flatpickr-input-valid' : 'flatpickr-input-notvalid']">
                      </flat-pickr>
                    </template>
                  </b-form-group>
                  <b-row class="mb-3">
                    <b-col cols="12" lg="5">
                      <b-form-group label="Jenis Identitas:" label-for="nik" class="mb-0">
                        <b-form-select :state="validationJenisIdentitas" class="selected-cstm-utd"
                        v-model="pasien.jenis" :options="optionsJenisIdentitas">
                      </b-form-select>
                      </b-form-group>
                    </b-col>
                    <b-col cols="12" lg="7">
                      <b-form-group label="Nomor Identitas:" label-for="nomor" class="mb-0">
                        <b-form-input id="nomor" :state="validationNomor" v-model="pasien.nomor" type="text"
                          placeholder="[Nomor Identitas]" autocomplete="off" required>
                        </b-form-input>
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <b-form-group label="Pekerjaan:" label-for="wilayah" class="mb-3">
                    <multiselect :class="[pasien.pekerjaan === '' ? 'multiselect__tags_color_empty' : 'multiselect__tags_color_valid']" v-model="vselectpekerjaan" :show-labels="false"
                      label="deskripsi" @input="valPekerjaan" placeholder="Pilih Pekerjaan" :options="pekerjaanCombo"
                      :searchable="true">
                    </multiselect>
                  </b-form-group>
                  <b-form-group label="Wilayah Domisili:" label-for="wilayah">
                    <multiselect :class="[pasien.wilayah === null ? 'multiselect__tags_color_empty' : 'multiselect__tags_color_valid']" v-model="vselectwilayah" :show-labels="false"
                      label="deskripsi" @input="valWilayah" placeholder="Pilih Wilayah" :options="wilayahMap"
                      :searchable="true">
                    </multiselect>
                  </b-form-group>
                </b-col>
                <b-col cols="12" lg="6">
                  <b-form-group label="Alamat:" label-for="exampleFormControlTextarea1" class="mb-3">
                    <b-form-textarea :state="validationAlamat" v-model="pasien.alamat" id="exampleFormControlTextarea1"
                      placeholder="[Alamat Sesuai KTP]" rows="2" autocomplete="off" required></b-form-textarea>
                  </b-form-group>
                  <b-form-group label="No. Handpone:" label-for="noHandpone" class="mb-3">
                    <b-form-input :state="validationHP" v-model="pasien.no_handphone" id="noHandpone"
                      placeholder="[Nomor Handpone Pendonor]" type="text" autocomplete="off">
                    </b-form-input>
                  </b-form-group>
                  <b-form-group label="No. Telepon Rumah:" label-for="noTelepon" class="mb-3">
                    <b-form-input :state="validationTelepon" v-model="pasien.telepon" id="noTelepon"
                      placeholder="[Nomor Telepon Rumah Pendonor]" type="text" autocomplete="off">
                    </b-form-input>
                  </b-form-group>
                  <b-form-group class="mb-3" label="Email:" label-for="email" description="Jika Tidak Ada Dikosongkan">
                    <b-form-input v-model="pasien.email" id="email"
                      placeholder="[Email Pendonor]" type="email" autocomplete="off">
                    </b-form-input>
                  </b-form-group>
                  <b-form-group class="mb-3" label="Fax:" label-for="fax" description="Jika Tidak Ada Beri Tanda Strip (-)">
                    <b-form-input v-model="pasien.fax" id="fax" placeholder="[Fax Pendonor]"
                      type="text" autocomplete="off">
                    </b-form-input>
                  </b-form-group>
                  <b-form-group label="Penghargaan:" label-for="penghargaan">
                    <b-form-select class="selected-cstm-utd" v-model="pasien.penghargaan" :options="optionsPenghargaan">
                    </b-form-select>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row class="mt-3">
                <b-col cols="12" class="mb-2">
                  <div class="mb-1">
                    <p class="h6 text-dark font-weight-normal mb-0">Bersediakah saudara donor pada waktu bulan puasa ?</p>
                  </div>
                  <div>
                    <b-form-group v-slot="{ ariaDescribedby }">
                      <b-form-radio-group id="radio-group-puasa" v-model="pasien.param41" :options="optionsPuasa" :aria-describedby="ariaDescribedby" name="radio-puasa"></b-form-radio-group>
                    </b-form-group>
                  </div>
                </b-col>
                <b-col cols="12">
                  <div class="mb-0">
                    <p class="h6 text-dark font-weight-normal mb-0">Bersediakah saudara donor saat dibutuhkan untuk keperluan tertentu (di luar donor rutin) ?</p>
                  </div>
                  <div>
                    <b-form-group v-slot="{ ariaDescribedby }">
                      <b-form-radio-group id="radio-group-sedia" v-model="pasien.param42" :options="optionsSedia" :aria-describedby="ariaDescribedby" name="radio-sedia"></b-form-radio-group>
                    </b-form-group>
                  </div>
                </b-col>
              </b-row>
              <div class="border rounded shadow-sm bg-cek-utd-pendaftaran pt-3 px-4 mt-3 mb-5">
                <div class="d-flex flex-row justify-content-center mb-3 mt-3">
                  <div class="bg-dark shadow-sm px-4 py-2 rounded ">
                    <p class="h6 text-white font-weight-bold mb-0"><i class="fas fa-hospital"></i> RESERVASI
                    </p>
                  </div>
                </div>
                <b-row class="justify-content-center">
                  <b-col cols="12" lg="5">
                    <b-form-group label="Tempat Pelayanan:" label-for="pelayanan" description="Tempat Pelayanan Yang Disediakan">
                      <b-form-select :state="validationPelayanan" class="selected-cstm-utd"
                        v-model="pasien.jenis_layanan" :options="optionsB">
                      </b-form-select>
                    </b-form-group>
                  </b-col>
                  <b-col cols="12" lg="5">
                    <!-- tgl reservasi -->
                    <b-form-group label="Tanggal Kedatangan:" label-for="tanggal"
                      description="Pendaftaran Berlaku Untuk Hari Ini Atau Besok">
                      <b-input-group>
                        <b-form-input class="bg-white" :state="validationTgl" id="example-input" v-model="formattedtglreservasi"
                          type="text" placeholder="DD-MM-YYYY" autocomplete="on"></b-form-input>
                        <b-input-group-append>
                          <b-form-datepicker id="example-datepicker2" :state="validationTgl" v-model="pasien.tanggal"
                            nav-button-variant="dark" :date-disabled-fn="dateDisabled" button-only right locale="id"
                            aria-controls="example-input" @context="onContextTglReservasi"></b-form-datepicker>
                        </b-input-group-append>
                      </b-input-group>
                    </b-form-group>
                    <!-- tgl reservasi -->
                    <!-- <b-form-group label="Tanggal Kedatangan (Reservasi):" label-for="tanggal"
                    description="Pendaftaran Berlaku Untuk Hari Ini Atau Besok">
                    <b-form-datepicker id="example-datepicker2" :state="validationTgl" v-model="pasien.tanggal"
                      :date-disabled-fn="dateDisabled" class="mb-2" required></b-form-datepicker>
                  </b-form-group> -->
                  </b-col>
                  <b-col cols="12" lg="2">
                    <b-form-group label="Cek Umur:" label-for="pelayanan" description="Minimal 17 Tahun">
                      <b-button @click="kalkulasiUmur" variant="primary" class="btn-cek-utd shadow-sm border w-100">Cek!</b-button>
                    </b-form-group>
                  </b-col>
                </b-row>
              </div>
            </b-form>
          </div>
        </b-col>
        <b-col xl="2" lg="3" sm="12" class="p-0">
          <div
            class="bg-utd-side-right d-flex flex-column justify-content-center align-items-center h-100 p-4 utd-border-right">
            <div class="text-center">
              <b-img :src="require('../../assets/images/logo-rsup.png')" width="120rem" fluid alt="Responsive image">
              </b-img>
              <p class="text-dark font-weight-bold"></p>
            </div>
          </div>
        </b-col>
      </b-row>
      <b-row class="justify-content-center mt-n4">
        <b-col cols="12" xl="7" class="p-0">
          <div class="utd-form-radio bg-cek-utd-pendaftaran utd-border-radius shadow-sm p-4">
            <div class="d-flex flex-row justify-content-center mb-3">
              <div class="bg-dark shadow-sm px-4 py-2 rounded ">
                <p class="h6 text-white font-weight-bold mb-0"><i class="fas fa-book-medical"></i> FORM RIWAYAT MEDIS
                </p>
              </div>
            </div>
            <p class="h6 text-danger font-weight-light text-center mb-4 mt-2" v-if="historyLabel === true">Riwayat Medis Wajib Diisi</p>
            <div class="utd-scroll">
              <div class="task-1 bg-white rounded p-4 shadow-sm mb-3">
                <p class="h6 font-weight-bold text-dark mb-3">Apakah Anda:</p>
                <b-form-group label="Merasa Sehat Pada Hari Ini ?" v-slot="{ ariaDescribedby }">
                  <b-form-radio-group :state="validationParam1" id="radio-group-1" v-model="pasien.param1"
                    :options="optionsRadio" :aria-describedby="ariaDescribedby" name="radio-options">
                  </b-form-radio-group>
                </b-form-group>
                <b-form-group label="Sedang Minum Antibiotik Atau Obat Lain Untuk Infeksi ?"
                  v-slot="{ ariaDescribedby }">
                  <b-form-radio-group :state="validationParam2" id="radio-group-2" v-model="pasien.param2"
                    :options="optionsRadio2" :aria-describedby="ariaDescribedby" name="radio-options2">
                  </b-form-radio-group>
                </b-form-group>
                <b-form-group label="Sedang Minum Jamu ?" v-slot="{ ariaDescribedby }">
                  <b-form-radio-group :state="validationParam3" id="radio-group-3" v-model="pasien.param3"
                    :options="optionsRadio3" :aria-describedby="ariaDescribedby" name="radio-options3">
                  </b-form-radio-group>
                </b-form-group>
              </div>
              <div class="task-2 bg-white rounded p-4 shadow-sm mb-3">
                <p class="h6 font-weight-bold text-dark mb-3">Dalam Waktu 48 Jam Terakhir</p>
                <b-form-group label="Apakah Anda Sedang Minum Aspirin Atau Obat Yang Mengandung Aspirin"
                  v-slot="{ ariaDescribedby }">
                  <b-form-radio-group :state="validationParam4" id="radio-group-4" v-model="pasien.param4"
                    :options="optionsRadio4" :aria-describedby="ariaDescribedby" name="radio-options4">
                  </b-form-radio-group>
                </b-form-group>
              </div>
              <div class="task-3 bg-white rounded p-4 shadow-sm mb-3">
                <p class="h6 font-weight-bold text-dark mb-3">Dalam Waktu 1 Minggu Terakhir</p>
                <b-form-group label="Apakah Anda Mengalami Sakit Kepala Dan Demam Bersamaan Atau Tindakan Cabut Gigi"
                  v-slot="{ ariaDescribedby }">
                  <b-form-radio-group :state="validationParam5" id="radio-group-5" v-model="pasien.param5"
                    :options="optionsRadio5" :aria-describedby="ariaDescribedby" name="radio-options5">
                  </b-form-radio-group>
                </b-form-group>
              </div>
              <div class="task-4 bg-white rounded p-4 shadow-sm mb-3">
                <p class="h6 font-weight-bold text-dark mb-3">Dalam Waktu 6 Minggu Terakhir</p>
                <b-form-group
                  label="Untuk Donor Darah Wanita : Apakah Anda Saat Ini Sedang Hamil ? Jika Ya, Kehamilan Berapa ?"
                  v-slot="{ ariaDescribedby }">
                  <b-form-radio-group :state="validationParam6" id="radio-group-6" v-model="pasien.param6"
                  @change="autoIsi" :options="optionsRadio6" :aria-describedby="ariaDescribedby" name="radio-options6">
                  </b-form-radio-group>
                </b-form-group>
                <b-form-group v-if="pasien.param6 === 1">
                  <b-form-input id="input-41" :state="validationParamKet6" v-model="pasien.ket_param6" type="text"
                    placeholder="Jika Ya : harap mengisi usia kandungan"
                    required>
                  </b-form-input>
                  <b-form-invalid-feedback>
                    Usia kandungan harus diisi
                  </b-form-invalid-feedback>
                </b-form-group>
                <b-form-group class="utd-form-hide" v-else-if="pasien.param6 === 2 || pasien.param6 === 3">
                  <b-form-input id="input-41" v-model="pasien.ket_param6" class="d-none"
                    placeholder="Jika : 'Tidak atau Tidak Tahu' beri tanda strip">
                  </b-form-input>
                </b-form-group>
                <b-form-group class="utd-form-hide" v-else>
                  <b-form-input id="input-41" v-model="pasien.ket_param6" class="d-none"
                    placeholder="Jika : 'Tidak atau Tidak Tahu' beri tanda strip">
                  </b-form-input>
                </b-form-group>
              </div>
              <div class="task-5 bg-white rounded p-4 shadow-sm mb-3">
                <p class="h6 font-weight-bold text-dark mb-3">Dalam Waktu 8 Minggu Terakhir ( 2 Bulan )</p>
                <b-form-group label="Apakah Anda Mendonorkan Darah, Trombosit, Atau Plasma"
                  v-slot="{ ariaDescribedby }">
                  <b-form-radio-group id="radio-group-7" :state="validationParam7" v-model="pasien.param7"
                    :options="optionsRadio7" :aria-describedby="ariaDescribedby" name="radio-options7">
                  </b-form-radio-group>
                </b-form-group>
                <b-form-group label="Apakah Anda Menerima Vaksinasi Atau Suntik Lainnya" v-slot="{ ariaDescribedby }">
                  <b-form-radio-group id="radio-group-8" :state="validationParam8" v-model="pasien.param8"
                    :options="optionsRadio8" :aria-describedby="ariaDescribedby" name="radio-options8">
                  </b-form-radio-group>
                </b-form-group>
                <b-form-group label="Apakah Anda Pernah Kontak Dengan Orang Yang Menerima Vaksinasi Smallpox"
                  v-slot="{ ariaDescribedby }">
                  <b-form-radio-group id="radio-group-9" :state="validationParam9" v-model="pasien.param9"
                    :options="optionsRadio9" :aria-describedby="ariaDescribedby" name="radio-options9">
                  </b-form-radio-group>
                </b-form-group>
              </div>
              <div class="task-6 bg-white rounded p-4 shadow-sm mb-3">
                <p class="h6 font-weight-bold text-dark mb-3">Dalam Waktu 16 Minggu Terakhir ( 4 Bulan )</p>
                <b-form-group label="Apakah Anda Mendonorkan 2 kantong Sel Darah Merah Melalui Proses Apheresis"
                  v-slot="{ ariaDescribedby }">
                  <b-form-radio-group id="radio-group-10" :state="validationParam10" v-model="pasien.param10"
                    :options="optionsRadio7" :aria-describedby="ariaDescribedby" name="radio-options10">
                  </b-form-radio-group>
                </b-form-group>
              </div>
              <div class="task-7 bg-white rounded p-4 shadow-sm mb-3">
                <p class="h6 font-weight-bold text-dark mb-3">Dalam Waktu 12 Bulan Terakhir (1 Tahun)</p>
                <b-form-group label="Apakah Anda Pernah Menerima Transfusi Darah" v-slot="{ ariaDescribedby }">
                  <b-form-radio-group id="radio-group-11" :state="validationParam11" v-model="pasien.param11"
                    :options="optionsRadio7" :aria-describedby="ariaDescribedby" name="radio-options11">
                  </b-form-radio-group>
                </b-form-group>
                <b-form-group
                  label="Apakah Anda Pernah Mendapat Transplantasi Organ, Jaringan, Sumsum Tulang Atau Cangkok Tulang Untuk Kulit"
                  v-slot="{ ariaDescribedby }">
                  <b-form-radio-group id="radio-group-12" :state="validationParam12" v-model="pasien.param12"
                    :options="optionsRadio7" :aria-describedby="ariaDescribedby" name="radio-options12">
                  </b-form-radio-group>
                </b-form-group>
                <b-form-group label="Apakah Anda Pernah Mengalami Tindakan Operasi" v-slot="{ ariaDescribedby }">
                  <b-form-radio-group id="radio-group-13" :state="validationParam13" v-model="pasien.param13"
                    :options="optionsRadio7" :aria-describedby="ariaDescribedby" name="radio-options13">
                  </b-form-radio-group>
                </b-form-group>
                <b-form-group label="Apakah Anda Pernah Tertusuk Jarum Medis, Akupuntur Atau Bekam"
                  v-slot="{ ariaDescribedby }">
                  <b-form-radio-group id="radio-group-14" :state="validationParam14" v-model="pasien.param14"
                    :options="optionsRadio7" :aria-describedby="ariaDescribedby" name="radio-options14">
                  </b-form-radio-group>
                </b-form-group>
                <b-form-group label="Apakah Anda Pernah Berhubungan Seksual Dengan Orang HIV/AIDS"
                  v-slot="{ ariaDescribedby }">
                  <b-form-radio-group id="radio-group-15" :state="validationParam15" v-model="pasien.param15"
                    :options="optionsRadio7" :aria-describedby="ariaDescribedby" name="radio-options15">
                  </b-form-radio-group>
                </b-form-group>
                <b-form-group label="Apakah Anda Pernah Berhubungan Seksual Dengan Pekerja Seks Komersial"
                  v-slot="{ ariaDescribedby }">
                  <b-form-radio-group id="radio-group-16" :state="validationParam16" v-model="pasien.param16"
                    :options="optionsRadio7" :aria-describedby="ariaDescribedby" name="radio-options16">
                  </b-form-radio-group>
                </b-form-group>
                <b-form-group label="Apakah Anda Pernah Berhubungan Seksual Dengan Pengguna Narkoba Jarum Suntik"
                  v-slot="{ ariaDescribedby }">
                  <b-form-radio-group id="radio-group-17" :state="validationParam17" v-model="pasien.param17"
                    :options="optionsRadio7" :aria-describedby="ariaDescribedby" name="radio-options17">
                  </b-form-radio-group>
                </b-form-group>
                <b-form-group label="Apakah Anda Pernah Berhubungan Seksual Dengan Pengguna Konsetrat Faktor Pembekuan"
                  v-slot="{ ariaDescribedby }">
                  <b-form-radio-group id="radio-group-18" :state="validationParam18" v-model="pasien.param18"
                    :options="optionsRadio7" :aria-describedby="ariaDescribedby" name="radio-options18">
                  </b-form-radio-group>
                </b-form-group>
                <b-form-group label="Apakah Anda Pernah Berhubungan Seksual Dengan Yang Laki - Laki Biseksual"
                  v-slot="{ ariaDescribedby }">
                  <b-form-radio-group id="radio-group-19" :state="validationParam19" v-model="pasien.param19"
                    :options="optionsRadio7" :aria-describedby="ariaDescribedby" name="radio-options19">
                  </b-form-radio-group>
                </b-form-group>
                <b-form-group label="Apakah Anda Pernah Berhubungan Seksual Dengan Penderita Hepatitis"
                  v-slot="{ ariaDescribedby }">
                  <b-form-radio-group id="radio-group-20" :state="validationParam20" v-model="pasien.param20"
                    :options="optionsRadio20" :aria-describedby="ariaDescribedby" name="radio-options20">
                  </b-form-radio-group>
                </b-form-group>
                <b-form-group label="Apakah Anda Tinggal Bersama Penderita Hepatitis" v-slot="{ ariaDescribedby }">
                  <b-form-radio-group id="radio-group-21" :state="validationParam21" v-model="pasien.param21"
                    :options="optionsRadio7" :aria-describedby="ariaDescribedby" name="radio-options21">
                  </b-form-radio-group>
                </b-form-group>
                <b-form-group label="Apakah Anda Memiliki Tato" v-slot="{ ariaDescribedby }">
                  <b-form-radio-group id="radio-group-22" :state="validationParam22" v-model="pasien.param22"
                    :options="optionsRadio7" :aria-describedby="ariaDescribedby" name="radio-options22">
                  </b-form-radio-group>
                </b-form-group>
                <b-form-group label="Apakah Anda Memiliki Tindik Telingan Atau Dibagian Tubuh Lainnya"
                  v-slot="{ ariaDescribedby }">
                  <b-form-radio-group id="radio-group-23" :state="validationParam23" v-model="pasien.param23"
                    :options="optionsRadio7" :aria-describedby="ariaDescribedby" name="radio-options23">
                  </b-form-radio-group>
                </b-form-group>
                <b-form-group label="Apakah Anda Sedang Atau Pernah Mendapat Pengobatan Sifilis Atau GO(Kencing Nanah)"
                  v-slot="{ ariaDescribedby }">
                  <b-form-radio-group id="radio-group-24" :state="validationParam24" v-model="pasien.param24"
                    :options="optionsRadio7" :aria-describedby="ariaDescribedby" name="radio-options24">
                  </b-form-radio-group>
                </b-form-group>
                <b-form-group label="Apakah Anda Pernah Ditahan Dipenjara Untuk Waktu Lebih Dari 72 Jam"
                  v-slot="{ ariaDescribedby }">
                  <b-form-radio-group id="radio-group-25" :state="validationParam25" v-model="pasien.param25"
                    :options="optionsRadio7" :aria-describedby="ariaDescribedby" name="radio-options25">
                  </b-form-radio-group>
                </b-form-group>
              </div>
              <div class="task-8 bg-white rounded p-4 shadow-sm mb-3">
                <p class="h6 font-weight-bold text-dark mb-3">Tahun 1977 Hingga Sekarang</p>
                <b-form-group label="Apakah Anda Menerima Uang, Obat, Atau Pembiayaan Lainnya Untuk Seks"
                  v-slot="{ ariaDescribedby }">
                  <b-form-radio-group id="radio-group-26" :state="validationParam26" v-model="pasien.param26"
                    :options="optionsRadio26" :aria-describedby="ariaDescribedby" name="radio-options26">
                  </b-form-radio-group>
                </b-form-group>
                <b-form-group
                  label="Untuk Laki-laki: Apakah Anda Pernah Berhubungan Seksual Dengan Sesama Laki-laki, Walaupun Hanya Sekali"
                  v-slot="{ ariaDescribedby }">
                  <b-form-radio-group id="radio-group-27" :state="validationParam27" v-model="pasien.param27"
                    :options="optionsRadio27" :aria-describedby="ariaDescribedby" name="radio-options27">
                  </b-form-radio-group>
                </b-form-group>
              </div>
              <div class="task-9 bg-white rounded p-4 shadow-sm mb-3">
                <p class="h6 font-weight-bold text-dark mb-3">Tahun 1980 Hingga Sekarang</p>
                <b-form-group label="Apakah Anda Tingal Selama 5 Tahun Atau Lebih Di Eropa ?"
                  v-slot="{ ariaDescribedby }">
                  <b-form-radio-group id="radio-group-28" :state="validationParam28" v-model="pasien.param28"
                    :options="optionsRadio28" :aria-describedby="ariaDescribedby" name="radio-options28">
                  </b-form-radio-group>
                </b-form-group>
                <b-form-group label="Apakah Anda Menerima Transfusi Darah Di Inggris" v-slot="{ ariaDescribedby }">
                  <b-form-radio-group id="radio-group-29" :state="validationParam29" v-model="pasien.param29"
                    :options="optionsRadio29" :aria-describedby="ariaDescribedby" name="radio-options29">
                  </b-form-radio-group>
                </b-form-group>
              </div>
              <div class="task-10 bg-white rounded p-4 shadow-sm mb-3">
                <p class="h6 font-weight-bold text-dark mb-3">Tahun 1980 Hingga 1996</p>
                <b-form-group label="Apakah Anda Tingal Selama 3 Bulan Atau Lebih Di Inggris"
                  v-slot="{ ariaDescribedby }">
                  <b-form-radio-group id="radio-group-30" :state="validationParam30" v-model="pasien.param30"
                    :options="optionsRadio30" :aria-describedby="ariaDescribedby" name="radio-options30">
                  </b-form-radio-group>
                </b-form-group>
              </div>
              <div class="task-11 bg-white rounded p-4 shadow-sm mb-3">
                <p class="h6 font-weight-bold text-dark mb-3">Apakah Anda Pernah</p>
                <b-form-group label="Mendapat Hasil Positif Untuk Tes HIV/AIDS" v-slot="{ ariaDescribedby }">
                  <b-form-radio-group id="radio-group-31" :state="validationParam31" v-model="pasien.param31"
                    :options="optionsRadio31" :aria-describedby="ariaDescribedby" name="radio-options31">
                  </b-form-radio-group>
                </b-form-group>
                <b-form-group label="Menggunakan Jarum Suntik Untuk Obat - Obatan, Steroid Yang Tidak Diresepkan Dokter"
                  v-slot="{ ariaDescribedby }">
                  <b-form-radio-group id="radio-group-32" :state="validationParam32" v-model="pasien.param32"
                    :options="optionsRadio32" :aria-describedby="ariaDescribedby" name="radio-options32">
                  </b-form-radio-group>
                </b-form-group>
                <b-form-group label="Menggunakan Konsetrat Faktor Pembekuan (AHF/FFP)" v-slot="{ ariaDescribedby }">
                  <b-form-radio-group id="radio-group-33" :state="validationParam33" v-model="pasien.param33"
                    :options="optionsRadio33" :aria-describedby="ariaDescribedby" name="radio-options33">
                  </b-form-radio-group>
                </b-form-group>
                <b-form-group label="Menderita Hepatitis" v-slot="{ ariaDescribedby }">
                  <b-form-radio-group id="radio-group-34" :state="validationParam34" v-model="pasien.param34"
                    :options="optionsRadio34" :aria-describedby="ariaDescribedby" name="radio-options34">
                  </b-form-radio-group>
                </b-form-group>
                <b-form-group label="Menderita Malaria" v-slot="{ ariaDescribedby }">
                  <b-form-radio-group id="radio-group-35" :state="validationParam35" v-model="pasien.param35"
                    :options="optionsRadio35" :aria-describedby="ariaDescribedby" name="radio-options35">
                  </b-form-radio-group>
                </b-form-group>
                <b-form-group label="Menderita kanker Termasuk Leukemia" v-slot="{ ariaDescribedby }">
                  <b-form-radio-group id="radio-group-36" :state="validationParam36" v-model="pasien.param36"
                    :options="optionsRadio36" :aria-describedby="ariaDescribedby" name="radio-options36">
                  </b-form-radio-group>
                </b-form-group>
                <b-form-group label="Bersmasalah Dengan Jantung Atau Paru-Paru Termasuk TBC"
                  v-slot="{ ariaDescribedby }">
                  <b-form-radio-group id="radio-group-37" :state="validationParam37" v-model="pasien.param37"
                    :options="optionsRadio37" :aria-describedby="ariaDescribedby" name="radio-options37">
                  </b-form-radio-group>
                </b-form-group>
                <b-form-group label="Menderita Pendarahan Atau Penyakit Berhubungan Dengan Darah Seperti Hemofilia"
                  v-slot="{ ariaDescribedby }">
                  <b-form-radio-group id="radio-group-38" :state="validationParam38" v-model="pasien.param38"
                    :options="optionsRadio38" :aria-describedby="ariaDescribedby" name="radio-options38">
                  </b-form-radio-group>
                </b-form-group>
                <b-form-group label="Berhubungan Seksual Dengan Orang Yang Tinggal Di Afrika"
                  v-slot="{ ariaDescribedby }">
                  <b-form-radio-group id="radio-group-39" :state="validationParam39" v-model="pasien.param39"
                    :options="optionsRadio39" :aria-describedby="ariaDescribedby" name="radio-options39">
                  </b-form-radio-group>
                </b-form-group>
                <b-form-group label="Tinggal Di Afrika" v-slot="{ ariaDescribedby }">
                  <b-form-radio-group id="radio-group-40" :state="validationParam40" v-model="pasien.param40"
                    :options="optionsRadio40" :aria-describedby="ariaDescribedby" name="radio-options40">
                  </b-form-radio-group>
                </b-form-group>
              </div>
            </div>
          </div>
        </b-col>
      </b-row>
      <b-row class="justify-content-center mt-4 mb-5">
        <b-col cols="12" xl="7" class="px-0">
          <div class="text-center py-3 px-3 px-lg-4 px-2">
            <div class="mb-3" v-if="pasien.nama != ''">
              <!-- <b-form-checkbox id="checkbox-1" v-model="pasien.pernyataan" name="checkbox-1" value="1" unchecked-value="0">
                <small>Saya <span class="text-primary font-weight-bold">{{pasien.nama}}</span>, Siap
                Bertanggung Jawab Dan Menerima Sanksi Yang Berlaku, Jika Ditemukan Ketidak Jujuran Dalam Pengisian Data
                Formulir Ini.</small>
              </b-form-checkbox> -->
              <template>
                <div>
                  <b-form-checkbox id="checkbox-1" v-model="pasien.pernyataan" name="checkbox-1" :value="1"
                    :unchecked-value="0">
                    <small>Saya <span class="text-primary font-weight-bold">{{pasien.nama}}</span>, Siap
                      Bertanggung Jawab Dan Menerima Sanksi Yang Berlaku, Jika Ditemukan Ketidak Jujuran Dalam Pengisian
                      Data
                      Formulir Ini.</small>
                  </b-form-checkbox>
                </div>
              </template>
              <!-- <b-form-checkbox-group>
                <b-form-checkbox id="checkbox" v-model="pasien.pernyataan"
                name="pernyataan" :value="1"><small>Saya <span class="text-primary font-weight-bold">{{pasien.nama}}</span>, Siap
                Bertanggung Jawab Dan Menerima Sanksi Yang Berlaku, Jika Ditemukan Ketidak Jujuran Dalam Pengisian Data
                Formulir Ini.</small></b-form-checkbox>
              </b-form-checkbox-group> -->
            </div>
            <b-button variant="danger" size="lg" @click="onSubmit" :disabled="validationUmurBtn">Kirim Data Anda!</b-button>
            <div class="text-center" v-if="validationUmurBtn === true">
              <small class="text-danger">Anda Belum Melakukan Cek Umur</small>
            </div>
          </div>
        </b-col>
      </b-row>
    </b-container>
    <b-modal ref="modal-sukses" size="md" title="Informasi" ok-title="Saya Mengerti" :ok-only=true :hide-header=true
      centered>
      <div class="text-center px-lg-4 px-2">
        <lottie :options="defaultOptions" :autoPlay="true" :width="250" :height="250" />
        <p class="h6 font-weight-bold text-success">{{okResponse}}</p>
        <p class="small text-dark font-weight-light">Harap Mengisi <span class="font-weight-bold">Tanggal Reservasi,
            Jenis Pelayanan, Form Riwayat Medis</span> Untuk Memperbaharui Data Anda</p>
      </div>
    </b-modal>
    <b-modal ref="modal-gagal" size="md" title="Informasi" ok-title="Saya Mengerti" :ok-only=true :hide-header=true
      centered>
      <div class="text-center px-lg-4 px-2">
        <lottie :options="defaultOptions2" :autoPlay="true" :width="250" :height="250" />
        <p class="h5 font-weight-bold text-danger">GAGAL</p>
        <p class="small font-weight-light text-danger">{{error}}</p>
      </div>
    </b-modal>
    <b-modal ref="modal-sukses-kirim" size="md" title="Reservasi Unit Transfusi Darah" @ok="clearData" :no-close-on-backdrop="true" :static="true" ok-title="Tutup" :ok-only=true
      centered>
      <div class="text-center px-lg-4 px-2 py-4">
        <p class="h6 text-danger font-weight-bold text-uppercase mb-4">Mohon Di <i class="fas fa-camera"></i> Screenshoot <br> <span class="font-weight-bold"></span> sebagai bukti reservasi Anda</p>
        <div class="mb-2">
          <template>
            <qrcode-vue :value="qrcode" size="250" level="H" />
          </template>
        </div>
        <p class="h4 font-weight-bold text-dark mb-1">{{qrcode}}</p>
        <p class="h6 font-weight-normal text-uppercase text-success mb-4">{{notif}}</p>
        <p class="small font-weight-normal text-uppercase text-dark mb-0">Mohon Datang Pada Tanggal <span class="font-weight-bold text-danger">{{tglBarcode}}</span></p>
        <p class="small font-weight-normal text-uppercase text-dark mb-0">Jam Pelayanan Dimulai Pukul <span class="font-weight-bold text-danger">8.00 - 19.00</span></p>
      </div>
    </b-modal>
    <b-modal ref="modal-gagal-kirim" size="md" title="Informasi" ok-title="Saya Mengerti" :ok-only=true
      :hide-header=true centered>
      <div class="text-center">
        <lottie :options="defaultOptions2" :autoPlay="true" :width="250" :height="250" />
        <p class="h5 text-danger">Data Gagal Disimpan</p>
        <p class="small text-danger mb-0" v-if="error !== ''">{{error}}</p>
      </div>
    </b-modal>
    <b-modal ref="modal-awal" size="lg" title="Petunjuk Daftar" ok-title="Saya Mengerti" :ok-only=true :hide-header=true
      centered scrollable>
      <b-row class="mb-3">
        <b-col>
          <div>
            <p class="h6 font-weight-normal text-dark">1.Masukan <span class="font-weight-bold">NIK</span> Anda, Untuk
              Pengecekan Jika Anda Sudah pernah Mendaftar Atau Belum
            </p>
            <b-img :src="require('../../assets/images/utd-step1.svg')" fluid alt="Responsive image">
            </b-img>
          </div>
        </b-col>
      </b-row>
      <hr>
      <b-row class="mb-3">
        <b-col>
          <div>
            <p class="h6 font-weight-normal text-dark">2.Jika Belum Pernah Mendaftar Sebelumnya, Maka Anda Harus Mengisi
              <span class="font-weight-bold">Form Data Diri, Reservasi </span> Dan Dilanjutkan Dengan Mengisi <span
                class="font-weight-bold">Form Riwayat Medis</span>
            </p>
            <b-img :src="require('../../assets/images/utd-step2.svg')" fluid alt="Responsive image"></b-img>
          </div>
        </b-col>
      </b-row>
      <hr>
      <b-row class="mb-3">
        <b-col>
          <div>
            <p class="h6 font-weight-normal text-dark">3.Jika Sudah Pernah Mendaftar, Isi Kembali <span
                class="font-weight-bold">Form Reservasi</span>, Lanjut Untuk Mengisi <span class="font-weight-bold">Form
                Riwayat Medis</span> Jika Ada Perubahan
            </p>
            <b-img :src="require('../../assets/images/utd-step3.svg')" fluid alt="Responsive image">
            </b-img>
          </div>
        </b-col>
      </b-row>
    </b-modal>
    <b-modal ref="modal-umur" size="md" title="Informasi" ok-title="Saya Mengerti" :ok-only=true :hide-header=true
      centered>
      <div class="text-center px-lg-4 px-2">
        <lottie :options="defaultOptions2" :autoPlay="true" :width="250" :height="250" />
        <p class="h6 font-weight-bold text-danger">{{notif}}</p>
      </div>
    </b-modal>
    <b-modal ref="modal-umur-ok" size="md" title="Informasi" ok-title="Saya Mengerti" :ok-only=true :hide-header=true
      centered>
      <div class="text-center px-lg-4 px-2">
        <lottie :options="defaultOptions" :autoPlay="true" :width="250" :height="250" />
        <p class="h6 font-weight-bold text-success">{{notif}}</p>
      </div>
    </b-modal>
  </div>
</template>
<script>
import UTD from '../../services/utd.js'
import Online from '../../services/reservasi_online'
import Multiselect from 'vue-multiselect'
import Lottie from 'vue-lottie'
import animationLogo from '../../assets/lottie/checked.json'
import animationLogo2 from '../../assets/lottie/failed.json'
import QrcodeVue from 'qrcode.vue'
import flatPickr from 'vue-flatpickr-component'
import 'flatpickr/dist/flatpickr.css'
// import Token from '../../services/token.js'
import moment from 'moment'
export default {
  name: 'ReservasiUTD',
  components: {
    Lottie,
    Multiselect,
    QrcodeVue,
    flatPickr
  },
  data () {
    return {
      formattedtglreservasi: '',
      formattedtgllahir: '',
      configTglLahir: {
        dateFormat: 'd-m-Y',
        disableMobile: 'true'
      },
      defaultOptions: { animationData: animationLogo, loop: true },
      defaultOptions2: { animationData: animationLogo2, loop: true },
      optionsSedia: [
        { value: 1, text: 'Ya' },
        { value: 0, text: 'Tidak' }
      ],
      optionsPuasa: [
        { value: 1, text: 'Ya' },
        { value: 0, text: 'Tidak' }
      ],
      optionsPenghargaan: [
        { value: 'default', text: 'Pilih Penghargaan', disabled: true },
        { value: '', text: 'Tidak Tahu / Tidak Ada' },
        { value: '10x', text: '10X' },
        { value: '25x', text: '25X' },
        { value: '50x', text: '50X' },
        { value: '75x', text: '75X' },
        { value: '100x', text: '100X' }
      ],
      optionsJenisDonor: [
        { value: 1, text: 'Biasa' },
        { value: 2, text: 'Aferesis' }
      ],
      options: [
        { value: 0, text: 'Pilih Jenis Kelamin', disabled: true },
        { value: 1, text: 'Laki - Laki' },
        { value: 2, text: 'Perempuan' }
      ],
      optionsJenisIdentitas: [
        { value: 0, text: 'Pilih Jenis Identitas', disabled: true },
        { value: 1, text: 'KTP (NIK)' },
        { value: 2, text: 'SIM' },
        { value: 4, text: 'Passport' }
      ],
      optionsB: [
        { value: 1, text: 'Gedung UTD Rsup Fatmawati' },
        { value: 2, text: 'Mobil Unit' }
      ],
      selectedRadio: 'first',
      optionsRadio: [
        { text: 'Ya', value: 1 },
        { text: 'Tidak', value: 2 },
        { text: 'Tidak Tahu', value: 3 }
      ],
      selectedRadio2: 'first',
      optionsRadio2: [
        { text: 'Ya', value: 1 },
        { text: 'Tidak', value: 2 },
        { text: 'Tidak Tahu', value: 3 }
      ],
      selectedRadio3: 'first',
      optionsRadio3: [
        { text: 'Ya', value: 1 },
        { text: 'Tidak', value: 2 },
        { text: 'Tidak Tahu', value: 3 }
      ],
      selectedRadio4: 'first',
      optionsRadio4: [
        { text: 'Ya', value: 1 },
        { text: 'Tidak', value: 2 },
        { text: 'Tidak Tahu', value: 3 }
      ],
      selectedRadio5: 'first',
      optionsRadio5: [
        { text: 'Ya', value: 1 },
        { text: 'Tidak', value: 2 },
        { text: 'Tidak Tahu', value: 3 }
      ],
      selectedRadio6: 'first',
      optionsRadio6: [
        { text: 'Ya', value: 1 },
        { text: 'Tidak', value: 2 },
        { text: 'Tidak Tahu', value: 3 }
      ],
      selectedRadio7: 'first',
      optionsRadio7: [
        { text: 'Ya', value: 1 },
        { text: 'Tidak', value: 2 },
        { text: 'Tidak Tahu', value: 3 }
      ],
      selectedRadio8: 'first',
      optionsRadio8: [
        { text: 'Ya', value: 1 },
        { text: 'Tidak', value: 2 },
        { text: 'Tidak Tahu', value: 3 }
      ],
      selectedRadio9: 'first',
      optionsRadio9: [
        { text: 'Ya', value: 1 },
        { text: 'Tidak', value: 2 },
        { text: 'Tidak Tahu', value: 3 }
      ],
      selectedRadio10: 'first',
      optionsRadio10: [
        { text: 'Ya', value: 1 },
        { text: 'Tidak', value: 2 },
        { text: 'Tidak Tahu', value: 3 }
      ],
      selectedRadio11: 'first',
      optionsRadio11: [
        { text: 'Ya', value: 1 },
        { text: 'Tidak', value: 2 },
        { text: 'Tidak Tahu', value: 3 }
      ],
      selectedRadio12: 'first',
      optionsRadio12: [
        { text: 'Ya', value: 1 },
        { text: 'Tidak', value: 2 },
        { text: 'Tidak Tahu', value: 3 }
      ],
      selectedRadio13: 'first',
      optionsRadio13: [
        { text: 'Ya', value: 1 },
        { text: 'Tidak', value: 2 },
        { text: 'Tidak Tahu', value: 3 }
      ],
      selectedRadio14: 'first',
      optionsRadio14: [
        { text: 'Ya', value: 1 },
        { text: 'Tidak', value: 2 },
        { text: 'Tidak Tahu', value: 3 }
      ],
      selectedRadio15: 'first',
      optionsRadio15: [
        { text: 'Ya', value: 1 },
        { text: 'Tidak', value: 2 },
        { text: 'Tidak Tahu', value: 3 }
      ],
      selectedRadio16: 'first',
      optionsRadio16: [
        { text: 'Ya', value: 1 },
        { text: 'Tidak', value: 2 },
        { text: 'Tidak Tahu', value: 3 }
      ],
      selectedRadio17: 'first',
      optionsRadio17: [
        { text: 'Ya', value: 1 },
        { text: 'Tidak', value: 2 },
        { text: 'Tidak Tahu', value: 3 }
      ],
      selectedRadio18: 'first',
      optionsRadio18: [
        { text: 'Ya', value: 1 },
        { text: 'Tidak', value: 2 },
        { text: 'Tidak Tahu', value: 3 }
      ],
      selectedRadio19: 'first',
      optionsRadio19: [
        { text: 'Ya', value: 1 },
        { text: 'Tidak', value: 2 },
        { text: 'Tidak Tahu', value: 3 }
      ],
      selectedRadio20: 'first',
      optionsRadio20: [
        { text: 'Ya', value: 1 },
        { text: 'Tidak', value: 2 },
        { text: 'Tidak Tahu', value: 3 }
      ],
      selectedRadio21: 'first',
      optionsRadio21: [
        { text: 'Ya', value: 1 },
        { text: 'Tidak', value: 2 },
        { text: 'Tidak Tahu', value: 3 }
      ],
      selectedRadio22: 'first',
      optionsRadio22: [
        { text: 'Ya', value: 1 },
        { text: 'Tidak', value: 2 },
        { text: 'Tidak Tahu', value: 3 }
      ],
      selectedRadio23: 'first',
      optionsRadio23: [
        { text: 'Ya', value: 1 },
        { text: 'Tidak', value: 2 },
        { text: 'Tidak Tahu', value: 3 }
      ],
      selectedRadio24: 'first',
      optionsRadio24: [
        { text: 'Ya', value: 1 },
        { text: 'Tidak', value: 2 },
        { text: 'Tidak Tahu', value: 3 }
      ],
      selectedRadio25: 'first',
      optionsRadio25: [
        { text: 'Ya', value: 1 },
        { text: 'Tidak', value: 2 },
        { text: 'Tidak Tahu', value: 3 }
      ],
      selectedRadio26: 'first',
      optionsRadio26: [
        { text: 'Ya', value: 1 },
        { text: 'Tidak', value: 2 },
        { text: 'Tidak Tahu', value: 3 }
      ],
      selectedRadio27: 'first',
      optionsRadio27: [
        { text: 'Ya', value: 1 },
        { text: 'Tidak', value: 2 },
        { text: 'Tidak Tahu', value: 3 }
      ],
      selectedRadio28: 'first',
      optionsRadio28: [
        { text: 'Ya', value: 1 },
        { text: 'Tidak', value: 2 },
        { text: 'Tidak Tahu', value: 3 }
      ],
      selectedRadio29: 'first',
      optionsRadio29: [
        { text: 'Ya', value: 1 },
        { text: 'Tidak', value: 2 },
        { text: 'Tidak Tahu', value: 3 }
      ],
      selectedRadio30: 'first',
      optionsRadio30: [
        { text: 'Ya', value: 1 },
        { text: 'Tidak', value: 2 },
        { text: 'Tidak Tahu', value: 3 }
      ],
      selectedRadio31: 'first',
      optionsRadio31: [
        { text: 'Ya', value: 1 },
        { text: 'Tidak', value: 2 },
        { text: 'Tidak Tahu', value: 3 }
      ],
      selectedRadio32: 'first',
      optionsRadio32: [
        { text: 'Ya', value: 1 },
        { text: 'Tidak', value: 2 },
        { text: 'Tidak Tahu', value: 3 }
      ],
      selectedRadio33: 'first',
      optionsRadio33: [
        { text: 'Ya', value: 1 },
        { text: 'Tidak', value: 2 },
        { text: 'Tidak Tahu', value: 3 }
      ],
      selectedRadio34: 'first',
      optionsRadio34: [
        { text: 'Ya', value: 1 },
        { text: 'Tidak', value: 2 },
        { text: 'Tidak Tahu', value: 3 }
      ],
      selectedRadio35: 'first',
      optionsRadio35: [
        { text: 'Ya', value: 1 },
        { text: 'Tidak', value: 2 },
        { text: 'Tidak Tahu', value: 3 }
      ],
      selectedRadio36: 'first',
      optionsRadio36: [
        { text: 'Ya', value: 1 },
        { text: 'Tidak', value: 2 },
        { text: 'Tidak Tahu', value: 3 }
      ],
      selectedRadio37: 'first',
      optionsRadio37: [
        { text: 'Ya', value: 1 },
        { text: 'Tidak', value: 2 },
        { text: 'Tidak Tahu', value: 3 }
      ],
      selectedRadio38: 'first',
      optionsRadio38: [
        { text: 'Ya', value: 1 },
        { text: 'Tidak', value: 2 },
        { text: 'Tidak Tahu', value: 3 }
      ],
      selectedRadio39: 'first',
      optionsRadio39: [
        { text: 'Ya', value: 1 },
        { text: 'Tidak', value: 2 },
        { text: 'Tidak Tahu', value: 3 }
      ],
      selectedRadio40: 'first',
      optionsRadio40: [
        { text: 'Ya', value: 1 },
        { text: 'Tidak', value: 2 },
        { text: 'Tidak Tahu', value: 3 }
      ],
      form: {
        checked: []
      },
      vselectpekerjaan: [],
      pekerjaanCombo: [],
      vselectwilayah: [],
      wilayahMap: [],
      vselecttempatLahir: [],
      tempatLahirMap: [],
      cekNik: '',
      pasien: {
        jenis: 0,
        nomor: '',
        jenis_donor: '1',
        nama: '',
        jenis_kelamin: 0,
        no_handphone: '',
        alamat: '',
        tanggal_lahir: '',
        tempat_lahir: '',
        telepon: '',
        email: '',
        fax: '',
        wilayah: null,
        tanggal: '',
        pekerjaan: '',
        penghargaan: 'default',
        jenis_layanan: 1,
        pernyataan: 0,
        param1: null,
        param2: null,
        param3: null,
        param4: null,
        param5: null,
        param6: null,
        ket_param6: '-',
        param7: null,
        param8: null,
        param9: null,
        param10: null,
        param11: null,
        param12: null,
        param13: null,
        param14: null,
        param15: null,
        param16: null,
        param17: null,
        param18: null,
        param19: null,
        param20: null,
        param21: null,
        param22: null,
        param23: null,
        param24: null,
        param25: null,
        param26: null,
        param27: null,
        param28: null,
        param29: null,
        param30: null,
        param31: null,
        param32: null,
        param33: null,
        param34: null,
        param35: null,
        param36: null,
        param37: null,
        param38: null,
        param39: null,
        param40: null,
        param41: null,
        param42: null
      },
      tglLahir: '',
      tglRespon: '',
      tglBarcode: '',
      valueDate: new Date(),
      error: '',
      historyLabel: false,
      notif: '',
      okResponse: '',
      qrcode: '',
      validationUmurBtn: true
    }
  },
  mounted () {
    this.showModal()
    this.wilayah()
    this.refreshtoken()
    this.pekerjaan()
    this.getTmptLahir()
  },
  computed: {
    validationNama () {
      return this.pasien.nama !== '' && this.pasien.nama !== null
    },
    validationNomor () {
      return this.pasien.nomor !== '' && this.pasien.nomor !== null
    },
    validationJenisIdentitas () {
      return this.pasien.jenis !== 0 && this.pasien.jenis !== null
    },
    validationEmail () {
      return this.pasien.email !== '' && this.pasien.email !== null
    },
    validationFax () {
      return this.pasien.fax !== '' && this.pasien.fax !== null
    },
    validationTglLahir () {
      return this.pasien.tanggal_lahir !== '' && this.pasien.tanggal_lahir !== null
    },
    validationAlamat () {
      return this.pasien.alamat !== '' && this.pasien.alamat !== null
    },
    validationWilayah () {
      return this.pasien.wilayah !== '' && this.pasien.wilayah !== null
    },
    validationPelayanan () {
      return this.pasien.jenis_layanan !== 0 && this.pasien.jenis_layanan !== ''
    },
    validationJK () {
      return this.pasien.jenis_kelamin !== 0 && this.pasien.jenis_kelamin !== ''
    },
    validationTempatLahir () {
      return this.pasien.tempat_lahir !== null && this.pasien.tempat_lahir !== ''
    },
    validationHP () {
      return this.pasien.no_handphone !== '' && this.pasien.no_handphone !== null
    },
    validationTelepon () {
      return this.pasien.telepon !== '' && this.pasien.telepon !== null
    },
    validationTgl () {
      return this.pasien.tanggal !== '' && this.pasien.tanggal !== null
    },
    validationParam1 () {
      return this.pasien.param1 !== null && this.pasien.param1 !== ''
    },
    validationParam2 () {
      return this.pasien.param2 !== null && this.pasien.param2 !== ''
    },
    validationParam3 () {
      return this.pasien.param3 !== null && this.pasien.param3 !== ''
    },
    validationParam4 () {
      return this.pasien.param4 !== null && this.pasien.param4 !== ''
    },
    validationParam5 () {
      return this.pasien.param5 !== null && this.pasien.param5 !== ''
    },
    validationParam6 () {
      return this.pasien.param6 !== null && this.pasien.param6 !== ''
    },
    validationParamKet6 () {
      return this.pasien.ket_param6 !== '' && this.pasien.ket_param6 !== '-' && this.pasien.ket_param6 !== null
    },
    validationParam7 () {
      return this.pasien.param7 !== null && this.pasien.param7 !== ''
    },
    validationParam8 () {
      return this.pasien.param8 !== null && this.pasien.param8 !== ''
    },
    validationParam9 () {
      return this.pasien.param9 !== null && this.pasien.param9 !== ''
    },
    validationParam10 () {
      return this.pasien.param10 !== null && this.pasien.param10 !== ''
    },
    validationParam11 () {
      return this.pasien.param11 !== null && this.pasien.param11 !== ''
    },
    validationParam12 () {
      return this.pasien.param12 !== null && this.pasien.param12 !== ''
    },
    validationParam13 () {
      return this.pasien.param13 !== null && this.pasien.param13 !== ''
    },
    validationParam14 () {
      return this.pasien.param14 !== null && this.pasien.param14 !== ''
    },
    validationParam15 () {
      return this.pasien.param15 !== null && this.pasien.param15 !== ''
    },
    validationParam16 () {
      return this.pasien.param16 !== null && this.pasien.param16 !== ''
    },
    validationParam17 () {
      return this.pasien.param17 !== null && this.pasien.param17 !== ''
    },
    validationParam18 () {
      return this.pasien.param18 !== null && this.pasien.param18 !== ''
    },
    validationParam19 () {
      return this.pasien.param19 !== null && this.pasien.param19 !== ''
    },
    validationParam20 () {
      return this.pasien.param20 !== null && this.pasien.param20 !== ''
    },
    validationParam21 () {
      return this.pasien.param21 !== null && this.pasien.param21 !== ''
    },
    validationParam22 () {
      return this.pasien.param22 !== null && this.pasien.param22 !== ''
    },
    validationParam23 () {
      return this.pasien.param23 !== null && this.pasien.param23 !== ''
    },
    validationParam24 () {
      return this.pasien.param24 !== null && this.pasien.param24 !== ''
    },
    validationParam25 () {
      return this.pasien.param25 !== null && this.pasien.param25 !== ''
    },
    validationParam26 () {
      return this.pasien.param26 !== null && this.pasien.param26 !== ''
    },
    validationParam27 () {
      return this.pasien.param27 !== null && this.pasien.param27 !== ''
    },
    validationParam28 () {
      return this.pasien.param28 !== null && this.pasien.param28 !== ''
    },
    validationParam29 () {
      return this.pasien.param29 !== null && this.pasien.param29 !== ''
    },
    validationParam30 () {
      return this.pasien.param30 !== null && this.pasien.param30 !== ''
    },
    validationParam31 () {
      return this.pasien.param31 !== null && this.pasien.param31 !== ''
    },
    validationParam32 () {
      return this.pasien.param32 !== null && this.pasien.param32 !== ''
    },
    validationParam33 () {
      return this.pasien.param33 !== null && this.pasien.param33 !== ''
    },
    validationParam34 () {
      return this.pasien.param34 !== null && this.pasien.param34 !== ''
    },
    validationParam35 () {
      return this.pasien.param35 !== null && this.pasien.param35 !== ''
    },
    validationParam36 () {
      return this.pasien.param36 !== null && this.pasien.param36 !== ''
    },
    validationParam37 () {
      return this.pasien.param37 !== null && this.pasien.param37 !== ''
    },
    validationParam38 () {
      return this.pasien.param38 !== null && this.pasien.param38 !== ''
    },
    validationParam39 () {
      return this.pasien.param39 !== null && this.pasien.param39 !== ''
    },
    validationParam40 () {
      return this.pasien.param40 !== null && this.pasien.param40 !== ''
    },
    validationCheck () {
      return this.pasien.pernyataan !== null && this.pasien.pernyataan !== ''
    },
    tglLahirIsValid () {
      return (
        this.pasien.tanggal_lahir !== ''
      )
    }
  },
  methods: {
    backToBeranda () {
      this.$router.push({ name: 'dashboard-patient' })
    },
    async utdnik () {
      const nomor = this.cekNik
      const jenis = this.pasien.jenis_donor
      try {
        const res = await UTD.show(nomor, jenis)
        this.pasien.jenis = res.data.dataReservasi.jenis
        this.pasien.nomor = res.data.dataReservasi.nomor
        this.pasien.nama = res.data.dataReservasi.nama
        // this.pasien.tanggal_lahir = res.data.dataReservasi.tanggal_lahir
        this.tglRespon = res.data.dataReservasi.tanggal_lahir
        this.pasien.tanggal_kembali = res.data.dataReservasi.tanggal_kembali
        this.pasien.alamat = res.data.dataReservasi.alamat
        this.vselectwilayah = { id: res.data.dataReservasi.wilayah, deskripsi: res.data.dataReservasi.deskripsi }
        this.pasien.wilayah = res.data.dataReservasi.wilayah
        // this.vselecttempatLahir = { id: res.data.dataReservasi.tempat_lahir, deskripsi: res.data.dataReservasi.deskripsi }
        // this.pasien.tempat_lahir = res.data.dataReservasi.tempat_lahir
        // this.vselectpekerjaan = { id: res.data.dataReservasi.pekerjaan, deskripsi: res.data.dataReservasi.deskripsi }
        // this.pasien.pekerjaan = res.data.dataReservasi.tempat_lahir
        this.pasien.jenis_kelamin = res.data.dataReservasi.jenis_kelamin
        this.pasien.no_handphone = res.data.dataReservasi.no_handphone
        this.pasien.telepon = res.data.dataReservasi.telepon
        this.pasien.email = res.data.dataReservasi.email
        this.pasien.fax = res.data.dataReservasi.fax
        this.historyLabel = true
        this.okResponse = res.data.message
        this.valTglRespon()
        this.$refs['modal-sukses'].show()
      } catch (err) {
        this.pasien.jenis = 0
        this.pasien.nomor = ''
        this.pasien.nama = ''
        this.pasien.tanggal_lahir = ''
        this.tglLahir = ''
        this.tglRespon = ''
        this.pasien.alamat = ''
        this.pasien.wilayah = null
        this.vselectwilayah = []
        this.pasien.jenis_kelamin = 0
        this.pasien.no_handphone = ''
        this.pasien.telepon = ''
        this.pasien.email = ''
        this.pasien.fax = ''
        this.pasien.tanggal = ''
        this.pasien.jenis_layanan = 0
        this.historyLabel = false
        this.error = err.response.data.message
        this.$refs['modal-gagal'].show()
      }
    },
    async onSubmit (e) {
      const pasienUtd = this.pasien
      try {
        const pasienUtdResponse = await UTD.store(pasienUtd)
        if (pasienUtdResponse.statusText === 'OK') {
          this.tglnotif()
          this.historyLabel = false
          this.notif = pasienUtdResponse.data.message
          this.qrcode = pasienUtdResponse.data.data
          this.$refs['modal-sukses-kirim'].show()
        }
      } catch (err) {
        this.error = err.response.data.message
        this.$refs['modal-gagal-kirim'].show()
      }
    },
    async wilayah () {
      try {
        const res = await UTD.getWilayah()
        this.wilayahMap = res.data.tempatLahir
      } catch (err) {
        this.error = err
      }
    },
    async pekerjaan () {
      try {
        const res = await Online.pekerjaan()
        this.pekerjaanCombo = res.data.pekerjaan
      } catch (err) {
        this.error = err
      }
    },
    async getTmptLahir () {
      try {
        const res = await UTD.getWilayah()
        this.tempatLahirMap = res.data.tempatLahir
      } catch (err) {
        this.error = err
      }
    },
    showModal () {
      this.$refs['modal-awal'].show()
    },
    dateDisabled (ymd, date) {
      const today = new Date()
      today.setHours(0, 0, 0, 0)
      return date < today || date > new Date(today.getTime() + 1 * 24 * 3600 * 1000)
    },
    onContextTglReservasi (ctx) {
      this.validationUmurBtn = true
      this.formattedtglreservasi = ctx.selectedFormatted
    },
    onContextTglLahir (ctx) {
      this.validationUmurBtn = true
      this.formattedtgllahir = ctx.selectedFormatted
    },
    valWilayah (value) {
      this.pasien.wilayah = value.id.toString()
    },
    valPekerjaan (value) {
      this.pasien.pekerjaan = value.id_referensi.toString()
    },
    valTempatLahir (value) {
      this.pasien.tempat_lahir = value.id.toString()
    },
    autoIsi () {
      if (this.pasien.param6 === 2 || this.pasien.param6 === 3) {
        this.pasien.ket_param6 = '-'
      }
    },
    clearData () {
      this.pasien.jenis = 0
      this.pasien.nomor = ''
      this.pasien.nama = ''
      this.pasien.tanggal_lahir = ''
      this.tglLahir = ''
      this.tglRespon = ''
      this.pasien.alamat = ''
      this.pasien.wilayah = null
      this.vselectwilayah = []
      this.pasien.jenis_kelamin = 0
      this.pasien.no_handphone = ''
      this.pasien.telepon = ''
      this.pasien.email = ''
      this.pasien.fax = ''
      this.pasien.tanggal = ''
      this.pasien.jenis_layanan = 0
      this.pasien.param1 = null
      this.pasien.param2 = null
      this.pasien.param3 = null
      this.pasien.param4 = null
      this.pasien.param5 = null
      this.pasien.param6 = null
      this.pasien.ket_param6 = '-'
      this.pasien.param7 = null
      this.pasien.param8 = null
      this.pasien.param9 = null
      this.pasien.param10 = null
      this.pasien.param11 = null
      this.pasien.param12 = null
      this.pasien.param13 = null
      this.pasien.param14 = null
      this.pasien.param15 = null
      this.pasien.param16 = null
      this.pasien.param17 = null
      this.pasien.param18 = null
      this.pasien.param19 = null
      this.pasien.param20 = null
      this.pasien.param21 = null
      this.pasien.param22 = null
      this.pasien.param23 = null
      this.pasien.param24 = null
      this.pasien.param25 = null
      this.pasien.param26 = null
      this.pasien.param27 = null
      this.pasien.param28 = null
      this.pasien.param29 = null
      this.pasien.param30 = null
      this.pasien.param31 = null
      this.pasien.param32 = null
      this.pasien.param33 = null
      this.pasien.param34 = null
      this.pasien.param35 = null
      this.pasien.param36 = null
      this.pasien.param37 = null
      this.pasien.param38 = null
      this.pasien.param39 = null
      this.pasien.param40 = null
      this.validationUmurBtn = false
      this.$router.push({ name: 'dashboard-patient' })
      setTimeout(() => {
        location.reload()
      }, 1250)
    },
    valTglLahir () {
      const tglStr = this.tglLahir
      const tgl = tglStr.split('-').reverse().join('-')
      this.pasien.tanggal_lahir = tgl
      this.validationUmurBtn = true
    },
    valTglRespon () {
      const tglStr = this.tglRespon
      const tgl = tglStr.split('-').reverse().join('-')
      this.tglLahir = tgl
    },
    tglnotif () {
      var tglStr = this.pasien.tanggal
      var tgl = tglStr.split('-').reverse().join('-')
      this.tglBarcode = tgl
    },
    kalkulasiUmur () {
      if (this.pasien.tanggal_lahir !== '') {
        if (this.pasien.tanggal !== '') {
          let date = moment(new Date())
          let tgllahir = moment(new Date(this.pasien.tanggal_lahir))
          let tglReservasi = moment(new Date(this.pasien.tanggal))
          let selisihLahir = date - tgllahir
          let selisihreservasi = tglReservasi - date
          let jumlahTgl = selisihLahir + selisihreservasi
          let jmlhtgl2 = moment.duration(jumlahTgl)
          if (jmlhtgl2.years() < 17) {
            this.notif = 'Umur Kurang Dari 17 Tahun'
            this.$refs['modal-umur'].show()
            this.validationUmurBtn = true
          } else {
            this.notif = 'Umur Memenuhi Syarat'
            this.$refs['modal-umur-ok'].show()
            this.validationUmurBtn = false
          }
        } else {
          this.notif = 'Tanggal Kedatangan Tidak Boleh Kosong'
          this.$refs['modal-umur'].show()
          this.validationUmurBtn = true
        }
      } else {
        this.notif = 'Tanggal Lahir Tidak Boleh Kosong'
        this.$refs['modal-umur'].show()
        this.validationUmurBtn = true
      }
    }
  }
}
</script>
<style>
@import '../../assets/css/pages/public/reservasi-utd.css';
</style>
