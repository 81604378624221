import axiosInstance from './axiosInstance'
import axios from 'axios'
import router from '../../router'
import BaseURL from '@/config/constant'

const webBaseURL = BaseURL.serviceFatmahostAuth
const setup = (store) => {
  axiosInstance.log.interceptors.request.use(
    (config) => {
      const token = localStorage.getItem('token')
      if (token) {
        config.headers['Authorization'] = 'Bearer ' + token
      }
      return config
    },
    (error) => {
      return Promise.reject(error)
    }
  )

  axiosInstance.log.interceptors.response.use(
    (res) => {
      return res
    },
    async (err) => {
      const originalConfig = err.config

      if (originalConfig.url !== '/auth/signin' && err.response) {
        // Access Token was expired
        if (err.response.status === 401 && !originalConfig._retry) {
          originalConfig._retry = true

          try {
            // store.dispatch('Auth/refreshtoken')
            await axios.get(`${webBaseURL}/auth/practitioner/token`, {
              withCredentials: true
            }).then((res) => {
              localStorage.setItem('token', res.data.accessToken)
            }).catch(() => {
              router.push({ name: 'welcome' })
            })

            return axiosInstance.log(originalConfig)
          } catch (_error) {
            return Promise.reject(_error)
          }
        }
      }

      return Promise.reject(err)
    }
  )

  axiosInstance.master.interceptors.request.use(
    (config) => {
      const token = localStorage.getItem('token')
      if (token) {
        config.headers['Authorization'] = 'Bearer ' + token
      }
      return config
    },
    (error) => {
      return Promise.reject(error)
    }
  )

  axiosInstance.master.interceptors.response.use(
    (res) => {
      return res
    },
    async (err) => {
      const originalConfig = err.config

      if (originalConfig.url !== '/auth/signin' && err.response) {
        // Access Token was expired
        if (err.response.status === 401 && !originalConfig._retry) {
          originalConfig._retry = true

          try {
            // store.dispatch('Auth/refreshtoken')
            await axios.get(`${webBaseURL}/auth/practitioner/token`, {
              withCredentials: true
            }).then((res) => {
              localStorage.setItem('token', res.data.accessToken)
            }).catch(() => {
              router.push({ name: 'welcome' })
            })

            return axiosInstance.master(originalConfig)
          } catch (_error) {
            return Promise.reject(_error)
          }
        }
      }

      return Promise.reject(err)
    }
  )

  axiosInstance.users.interceptors.request.use(
    (config) => {
      const token = localStorage.getItem('token')
      if (token) {
        config.headers['Authorization'] = 'Bearer ' + token
      }
      return config
    },
    (error) => {
      return Promise.reject(error)
    }
  )

  axiosInstance.users.interceptors.response.use(
    (res) => {
      return res
    },
    async (err) => {
      const originalConfig = err.config

      if (originalConfig.url !== '/auth/signin' && err.response) {
        // Access Token was expired
        if (err.response.status === 401 && !originalConfig._retry) {
          originalConfig._retry = true

          try {
            // store.dispatch('Auth/refreshtoken')
            await axios.get(`${webBaseURL}/auth/practitioner/token`, {
              withCredentials: true
            }).then((res) => {
              localStorage.setItem('token', res.data.accessToken)
            }).catch(() => {
              router.push({ name: 'welcome' })
            })

            return axiosInstance.users(originalConfig)
          } catch (_error) {
            return Promise.reject(_error)
          }
        }
      }

      return Promise.reject(err)
    }
  )

  axiosInstance.pegawai.interceptors.request.use(
    (config) => {
      const token = localStorage.getItem('token')
      if (token) {
        config.headers['Authorization'] = 'Bearer ' + token
      }
      return config
    },
    (error) => {
      return Promise.reject(error)
    }
  )

  axiosInstance.pegawai.interceptors.response.use(
    (res) => {
      return res
    },
    async (err) => {
      const originalConfig = err.config

      if (originalConfig.url !== '/auth/signin' && err.response) {
        // Access Token was expired
        if (err.response.status === 401 && !originalConfig._retry) {
          originalConfig._retry = true

          try {
            // store.dispatch('Auth/refreshtoken')
            await axios.get(`${webBaseURL}/auth/practitioner/token`, {
              withCredentials: true
            }).then((res) => {
              localStorage.setItem('token', res.data.accessToken)
            }).catch(() => {
              router.push({ name: 'welcome' })
            })

            return axiosInstance.pegawai(originalConfig)
          } catch (_error) {
            return Promise.reject(_error)
          }
        }
      }

      return Promise.reject(err)
    }
  )

  axiosInstance.pasien.interceptors.request.use(
    (config) => {
      const token = localStorage.getItem('token')
      if (token) {
        config.headers['Authorization'] = 'Bearer ' + token
      }
      return config
    },
    (error) => {
      return Promise.reject(error)
    }
  )

  axiosInstance.pasien.interceptors.response.use(
    (res) => {
      return res
    },
    async (err) => {
      const originalConfig = err.config

      if (originalConfig.url !== '/auth/signin' && err.response) {
        // Access Token was expired
        if (err.response.status === 401 && !originalConfig._retry) {
          originalConfig._retry = true

          try {
            // store.dispatch('Auth/refreshtoken')
            await axios.get(`${webBaseURL}/auth/practitioner/token`, {
              withCredentials: true
            }).then((res) => {
              localStorage.setItem('token', res.data.accessToken)
            }).catch(() => {
              router.push({ name: 'welcome' })
            })

            return axiosInstance.pasien(originalConfig)
          } catch (_error) {
            return Promise.reject(_error)
          }
        }
      }

      return Promise.reject(err)
    }
  )

  axiosInstance.member.interceptors.request.use(
    (config) => {
      const token = localStorage.getItem('token')
      if (token) {
        config.headers['Authorization'] = 'Bearer ' + token
      }
      return config
    },
    (error) => {
      return Promise.reject(error)
    }
  )

  axiosInstance.member.interceptors.response.use(
    (res) => {
      return res
    },
    async (err) => {
      const originalConfig = err.config

      if (originalConfig.url !== '/auth/signin' && err.response) {
        // Access Token was expired
        if (err.response.status === 401 && !originalConfig._retry) {
          originalConfig._retry = true

          try {
            // store.dispatch('Auth/refreshtoken')
            await axios.get(`${webBaseURL}/auth/practitioner/token`, {
              withCredentials: true
            }).then((res) => {
              localStorage.setItem('token', res.data.accessToken)
            }).catch(() => {
              router.push({ name: 'welcome' })
            })

            return axiosInstance.member(originalConfig)
          } catch (_error) {
            return Promise.reject(_error)
          }
        }
      }

      return Promise.reject(err)
    }
  )

  axiosInstance.katalog.interceptors.request.use(
    (config) => {
      const token = localStorage.getItem('token')
      if (token) {
        config.headers['Authorization'] = 'Bearer ' + token
      }
      return config
    },
    (error) => {
      return Promise.reject(error)
    }
  )

  axiosInstance.katalog.interceptors.response.use(
    (res) => {
      return res
    },
    async (err) => {
      const originalConfig = err.config

      if (originalConfig.url !== '/auth/signin' && err.response) {
        // Access Token was expired
        if (err.response.status === 401 && !originalConfig._retry) {
          originalConfig._retry = true

          try {
            // store.dispatch('Auth/refreshtoken')
            await axios.get(`${webBaseURL}/auth/practitioner/token`, {
              withCredentials: true
            }).then((res) => {
              localStorage.setItem('token', res.data.accessToken)
            }).catch(() => {
              router.push({ name: 'welcome' })
            })

            return axiosInstance.katalog(originalConfig)
          } catch (_error) {
            return Promise.reject(_error)
          }
        }
      }

      return Promise.reject(err)
    }
  )

  axiosInstance.dashboard.interceptors.request.use(
    (config) => {
      const token = localStorage.getItem('token')
      if (token) {
        config.headers['Authorization'] = 'Bearer ' + token
      }
      return config
    },
    (error) => {
      return Promise.reject(error)
    }
  )

  axiosInstance.dashboard.interceptors.response.use(
    (res) => {
      return res
    },
    async (err) => {
      const originalConfig = err.config

      if (originalConfig.url !== '/auth/signin' && err.response) {
        // Access Token was expired
        if (err.response.status === 401 && !originalConfig._retry) {
          originalConfig._retry = true

          try {
            // store.dispatch('Auth/refreshtoken')
            await axios.get(`${webBaseURL}/auth/practitioner/token`, {
              withCredentials: true
            }).then((res) => {
              localStorage.setItem('token', res.data.accessToken)
            }).catch(() => {
              router.push({ name: 'welcome' })
            })

            return axiosInstance.dashboard(originalConfig)
          } catch (_error) {
            return Promise.reject(_error)
          }
        }
      }

      return Promise.reject(err)
    }
  )

  axiosInstance.keuangan.interceptors.request.use(
    (config) => {
      const token = localStorage.getItem('token')
      if (token) {
        config.headers['Authorization'] = 'Bearer ' + token
      }
      return config
    },
    (error) => {
      return Promise.reject(error)
    }
  )

  axiosInstance.keuangan.interceptors.response.use(
    (res) => {
      return res
    },
    async (err) => {
      const originalConfig = err.config

      if (originalConfig.url !== '/auth/signin' && err.response) {
        // Access Token was expired
        if (err.response.status === 401 && !originalConfig._retry) {
          originalConfig._retry = true

          try {
            // store.dispatch('Auth/refreshtoken')
            await axios.get(`${webBaseURL}/auth/practitioner/token`, {
              withCredentials: true
            }).then((res) => {
              localStorage.setItem('token', res.data.accessToken)
            }).catch(() => {
              router.push({ name: 'welcome' })
            })

            return axiosInstance.keuangan(originalConfig)
          } catch (_error) {
            return Promise.reject(_error)
          }
        }
      }

      return Promise.reject(err)
    }
  )

  axiosInstance.eksport.interceptors.request.use(
    (config) => {
      const token = localStorage.getItem('token')
      if (token) {
        config.headers['Authorization'] = 'Bearer ' + token
      }
      return config
    },
    (error) => {
      return Promise.reject(error)
    }
  )

  axiosInstance.eksport.interceptors.response.use(
    (res) => {
      return res
    },
    async (err) => {
      const originalConfig = err.config

      if (originalConfig.url !== '/auth/signin' && err.response) {
        // Access Token was expired
        if (err.response.status === 401 && !originalConfig._retry) {
          originalConfig._retry = true

          try {
            // store.dispatch('Auth/refreshtoken')
            await axios.get(`${webBaseURL}/auth/practitioner/token`, {
              withCredentials: true
            }).then((res) => {
              localStorage.setItem('token', res.data.accessToken)
            }).catch(() => {
              router.push({ name: 'welcome' })
            })

            return axiosInstance.eksport(originalConfig)
          } catch (_error) {
            return Promise.reject(_error)
          }
        }
      }

      return Promise.reject(err)
    }
  )

  axiosInstance.reservasi.interceptors.request.use(
    (config) => {
      const token = localStorage.getItem('token')
      if (token) {
        config.headers['Authorization'] = 'Bearer ' + token
      }
      return config
    },
    (error) => {
      return Promise.reject(error)
    }
  )

  axiosInstance.reservasi.interceptors.response.use(
    (res) => {
      return res
    },
    async (err) => {
      const originalConfig = err.config

      if (originalConfig.url !== '/auth/signin' && err.response) {
        // Access Token was expired
        if (err.response.status === 401 && !originalConfig._retry) {
          originalConfig._retry = true

          try {
            // store.dispatch('Auth/refreshtoken')
            await axios.get(`${webBaseURL}/auth/practitioner/token`, {
              withCredentials: true
            }).then((res) => {
              localStorage.setItem('token', res.data.accessToken)
            }).catch(() => {
              router.push({ name: 'welcome' })
            })

            return axiosInstance.reservasi(originalConfig)
          } catch (_error) {
            return Promise.reject(_error)
          }
        }
      }

      return Promise.reject(err)
    }
  )

  axiosInstance.middleware.interceptors.request.use(
    (config) => {
      const token = localStorage.getItem('token')
      if (token) {
        config.headers['Authorization'] = 'Bearer ' + token
      }
      return config
    },
    (error) => {
      return Promise.reject(error)
    }
  )

  axiosInstance.middleware.interceptors.response.use(
    (res) => {
      return res
    },
    async (err) => {
      const originalConfig = err.config

      if (originalConfig.url !== '/auth/signin' && err.response) {
        // Access Token was expired
        if (err.response.status === 401 && !originalConfig._retry) {
          originalConfig._retry = true

          try {
            // store.dispatch('Auth/refreshtoken')
            await axios.get(`${webBaseURL}/auth/practitioner/token`, {
              withCredentials: true
            }).then((res) => {
              localStorage.setItem('token', res.data.accessToken)
            }).catch(() => {
              router.push({ name: 'welcome' })
            })

            return axiosInstance.middleware(originalConfig)
          } catch (_error) {
            return Promise.reject(_error)
          }
        }
      }

      return Promise.reject(err)
    }
  )

  axiosInstance.laravel.interceptors.request.use(
    (config) => {
      let token = localStorage.getItem('token')
      if (token == null) {
        token = localStorage.getItem('patient')
      }
      if (token) {
        config.headers['Authorization'] = 'Bearer ' + token
      }
      return config
    },
    (error) => {
      return Promise.reject(error)
    }
  )

  axiosInstance.laravel.interceptors.response.use(
    (res) => {
      return res
    },
    async (err) => {
      const originalConfig = err.config

      let token = localStorage.getItem('token')
      if (token == null) {
        if (originalConfig.url !== '/login-patient' && err.response) {
          if (err.response.status === 401 && !originalConfig._retry) {
            originalConfig._retry = true

            try {
              await axios.get(`${webBaseURL}/auth/patient/token`, {
                withCredentials: true
              }).then((res) => {
                localStorage.setItem('patient', res.data.accessToken)
              }).catch(() => {
                router.push({ name: 'welcome' })
              })

              return axiosInstance.laravel(originalConfig)
            } catch (_error) {
              return Promise.reject(_error)
            }
          }
        }
      } else {
        if (originalConfig.url !== '/auth/signin' && err.response) {
          if (err.response.status === 401 && !originalConfig._retry) {
            originalConfig._retry = true

            try {
              await axios.get(`${webBaseURL}/auth/practitioner/token`, {
                withCredentials: true
              }).then((res) => {
                localStorage.setItem('token', res.data.accessToken)
              }).catch(() => {
                router.push({ name: 'auth1.signin' })
              })

              return axiosInstance.laravel(originalConfig)
            } catch (_error) {
              return Promise.reject(_error)
            }
          }
        }
      }

      return Promise.reject(err)
    }
  )
}

export default setup
