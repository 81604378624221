<template>
  <b-container fluid>
    <b-row>
      <b-col md="12">
        <iq-card  class="py-4 px-4">
          <template>
            <div class="pb-4">
              <b-row>
                <b-col md="3" class="d-flex align-items-center text-left">
                  <h4 class="card-title mb-0">Data Pasien</h4>
                </b-col>
                <b-col md="6" class="text-center">
                  <form class="searchbox">
                    <input type="search" v-model="filter" class="form-control" id="filter-input"
                      placeholder="Cari Nama Pasien">
                  </form>
                </b-col>
                <b-col md="3" class="text-right d-flex align-items-center justify-content-end">
                  <div>
                    <b-button variant="primary" @click="tambah"><i class="fas fa-plus"></i>Tambah data</b-button>
                  </div>
                </b-col>
              </b-row>
            </div>
          </template>
          <template>
            <b-row>
              <b-col md="12">
                <b-table
                  ref="table"
                  outlined
                  hover
                  :busy="isBusy"
                  :items="items"
                  :fields="fields"
                  :current-page="currentPage"
                  :per-page="perPage"
                  :filter="filter"
                  stacked="sm"
                  small
                  show-empty
                  @filtered="onFiltered"
                >
                  <template #cell(index)="data">
                    {{ data.index + 1 }}
                  </template>
                  <template v-slot:cell(tanggal_lahir)="data">
                    <span>{{ new Date(data.item.tanggal_lahir) | formatDate }}</span>
                  </template>

                  <template v-slot:cell(norm)="data">
                    <span>{{ data.item.norm | norm }}</span>
                  </template>

                  <template v-slot:cell(jenis_kelamin)="data">
                    <span>{{ data.item.jenis_kelamin == 1 ? 'Laki - laki' : 'Perempuan' }}</span>
                  </template>

                  <template v-slot:cell(action)="data">
                    <b-button variant=" iq-bg-info mr-1 mb-1" size="lg" @click="viewPasien(data.item.norm)"><i class="ri-eye-line m-0"></i></b-button>
                    <b-button variant=" iq-bg-success mr-1 mb-1" size="lg" @click="editPasien(data.item.norm)"><i class="ri-ball-pen-fill m-0"></i></b-button>
                  </template>
                </b-table>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
              </b-col>
              <b-col>
              </b-col>
              <b-col sm="12" md="3" class=" my-1">
                <b-pagination
                  v-model="currentPage"
                  :total-rows="totalRows"
                  :per-page="perPage"
                  align="fill"
                  size="sm"
                  class="my-0"
                ></b-pagination>
              </b-col>
            </b-row>
          </template>
        </iq-card>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { xray } from '../../../config/pluginInit'
import Pasien from '../../../services/pasien'

export default {
  name: 'UiDataTable',
  mounted () {
    xray.index()
  },
  methods: {
    default () {
      return {
        errors: [],
        id: this.items.length,
        nip: '',
        nama: '',
        tempat_lahir: '',
        tanggal_lahir: '',
        jenis_kelamin: '',
        alamat: ''
      }
    },
    async listPasien (ctx) {
      if (ctx.filter === '') {
        ctx.filter = null
      }
      this.isBusy = true
      try {
        const response = await Pasien.all(ctx.currentPage, ctx.filter)
        this.totalRows = response.data.total
        this.isBusy = false
        return response.data.data
      } catch (err) {
        this.isBusy = false
        return []
      }
    },
    viewPasien (id) {
      this.$router.push('view/' + id)
    },
    editPendaftaran (id) {
      this.$router.push('edit/' + id)
    },
    tambah () {
      this.$router.push({ name: 'pendaftaran.add' })
    },
    onFiltered (filteredItems) {
      this.totalRows = filteredItems.length
      this.currentPage = 1
    }
  },
  data () {
    return {
      usernameState: null,
      passwordState: null,
      isBusy: false,
      error: [],
      fields: [
        { label: 'NORM', key: 'norm', class: 'text-center' },
        { label: 'NIK', key: 'nik', class: 'text-center' },
        { label: 'Nama Lengkap', key: 'nama', class: 'text-left' },
        { label: 'Jenis Kelamin', key: 'jenis_kelamin', class: 'text-left' },
        { label: 'Tanggal Lahir', key: 'tanggal_lahir', class: 'text-left' },
        { label: 'Action', key: 'action', class: 'text-center' }
      ],
      items: this.listPasien,
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      filterOn: [],
      filter: null
    }
  }
}
</script>
