<template>
  <div>
    <div class="bg-primary radius-top px-3 py-2">
      <b-row class="justify-content-end">
        <b-col cols="12" lg="6">
          <b-form-group class="mb-0">
            <form class="searchbox">
              <input type="search" class="form-control bg-white search-class-farm border-0" id="filter-input"
                placeholder="Cari Nama Obat">
            </form>
          </b-form-group>
        </b-col>
        <b-col cols="12" lg="6">
          <b-form-group class="mb-0">
            <multiselect class="shadow-sm border-0 rounded" v-model="vselectJenisPendonor" :show-labels="false" :limit="3"
              @input="valJenisPendonor" label="name" placeholder="Ruangan" :options="comboJenisPendonor"
              :searchable="true">
            </multiselect>
          </b-form-group>
        </b-col>
      </b-row>
    </div>
    <div class="shadow-sm">
      <b-table class="bg-white" ref="table" stacked="md" striped hover show-empty :items="items"></b-table>
    </div>
  </div>
</template>
<script>
import Multiselect from 'vue-multiselect'
export default {
  name: 'DaftarBarang',
  components: {
    Multiselect
  },
  data () {
    return {
      items: [
        { No: 1, Barang: 'Barang 1', Kategori: 'Kategori 1', Merk: 'Merk 1', penyedia: 'Penyedia 1', Stok: 'Stok 1', Status: 'Status 1', Nomor_Seri: 'No. Seri 1' }
      ],
      comboJenisPendonor: [
        { name: 'Langsung', id: 1 },
        { name: 'Sukarela', id: 2 },
        { name: 'Pengganti', id: 3 },
        { name: 'Aferesis', id: 4 }
      ],
      vselectJenisPendonor: []
    }
  }
}
</script>
