import Api from '../axiosInstance'
const version = '/v1'
export default {
  getAllRiwayatOut (data) {
    return Api.keuangan.post(`${version}/bios/satker`, data)
  },
  getAllMasterOut (data) {
    return Api.master.get(
      `${version}/master/practitioner/mBios?param0=${data}`
    )
  },
  kirimDataOut (data) {
    return Api.keuangan.post(`${version}/bios/satker`, data)
  },
  getAllRiwayatIn (data) {
    return Api.keuangan.post(`${version}/bios/satker`, data)
  },
  getAllMasterIn (data) {
    return Api.master.get(
      `${version}/master/practitioner/mBios?param0=${data}`
    )
  },
  kirimDataIn (data) {
    return Api.keuangan.post(`${version}/bios/satker`, data)
  },
  getAllMasterPengelolaan (data) {
    return Api.master.get(
      `${version}/master/practitioner/mBios?param0=${data}`
    )
  },
  getAllMasterOperasional (data) {
    return Api.master.get(
      `${version}/master/practitioner/mBios?param0=${data}`
    )
  },
  getAllMasterRekening (data) {
    return Api.master.get(
      `${version}/master/practitioner/mBios?param0=${data}`
    )
  },
  getAllRiwayatRPK (data) {
    return Api.keuangan.post(`${version}/bios/satker`, data)
  },
  getAllRiwayatRO (data) {
    return Api.keuangan.post(`${version}/bios/satker`, data)
  },
  getAllRiwayatRDK (data) {
    return Api.keuangan.post(`${version}/bios/satker`, data)
  },
  kirimPengelolaan (data) {
    return Api.keuangan.post(`${version}/bios/satker`, data)
  },
  kirimOperasional (data) {
    return Api.keuangan.post(`${version}/bios/satker`, data)
  },
  kirimDanaKelolaan (data) {
    return Api.keuangan.post(`${version}/bios/satker`, data)
  },
  // new endpoint
  // akun
  akunFindAll (currentPage, perPage, filters, sorting) {
    return Api.keuangan.get(`${version}/bios/mkeuangan?param0=akun&param1=${currentPage}&param2=${perPage}&param3=${filters}&param4=${sorting}`)
  },
  akunUpdate (data) {
    return Api.keuangan.put(`${version}/bios/mkeuangan/1`, data)
  },
  // bank
  bankFindAll (currentPage, perPage, filters, sorting) {
    return Api.keuangan.get(`${version}/bios/mkeuangan?param0=bank&param1=${currentPage}&param2=${perPage}&param3=${filters}&param4=${sorting}`)
  },
  bankUpdate (data) {
    return Api.keuangan.put(`${version}/bios/mkeuangan/1`, data)
  },
  // rekening
  rekeningFindAll (currentPage, perPage, filters, sortfield, sorting) {
    return Api.keuangan.get(`${version}/bios/mkeuangan?param0=rekening&param1=${currentPage}&param2=${perPage}&param3=${filters}&param4={"xparam0":"${sortfield}","xparam1":"${sorting}"}`)
  },
  rekeningCreate (data) {
    return Api.keuangan.post(`${version}/bios/mkeuangan/`, data)
  },
  rekeningUpdate (id, data) {
    return Api.keuangan.put(`${version}/bios/mkeuangan/${id}`, data)
  },
  rekeningShow (id) {
    return Api.keuangan.get(`${version}/bios/mkeuangan/${id}?param0=rekening`)
  },
  // ikt
  riwayatIkt (data) {
    return Api.keuangan.post(`${version}/bios/ikt`, data)
  },
  riwayatLayanan (data) {
    return Api.keuangan.post(`${version}/bios/layanan`, data)
  },
  // selectize
  selectizeAkun () {
    return Api.keuangan.get(`${version}/selectize?param0=akun-all&param1={"xparam0":""}`)
  },
  selectizePenerimaan () {
    return Api.keuangan.get(`${version}/selectize?param0=penerimaan&param1={"xparam0":""}`)
  },
  selectizePengeluaran () {
    return Api.keuangan.get(`${version}/selectize?param0=pengeluaran&param1={"xparam0":""}`)
  },
  selectizeBank () {
    return Api.keuangan.get(`${version}/selectize?param0=bank-all&param1={"xparam0":""}`)
  },
  selectizeRekening () {
    return Api.keuangan.get(`${version}/selectize?param0=rekening&param1={"xparam0":""}`)
  },
  selectizeSaldo () {
    return Api.keuangan.get(`${version}/selectize?param0=saldo&param1={"xparam0":""}`)
  },
  selectizeSaldoROP () {
    return Api.keuangan.get(`${version}/selectize?param0=saldo-rekening-operasional&param1={"xparam0":""}`)
  },
  selectizeSaldoRPK () {
    return Api.keuangan.get(`${version}/selectize?param0=saldo-rekening-pengelolaan-kas&param1={"xparam0":""}`)
  },
  selectizeSaldoRDK () {
    return Api.keuangan.get(`${version}/selectize?param0=saldo-rekening-dana-kelola&param1={"xparam0":""}`)
  }
}
