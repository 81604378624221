<template>
  <div id="bpjs-integrasi-vclaim">
    <b-card no-body class="p-0 m-0">
      <b-tabs pills card vertical>
        <b-tab>
          <template #title>
            <i class="fas fa-stethoscope py-2"></i> &nbsp; Dashboard Antrian Online
          </template>
          <div class="p-0">
            <Pemeriksaan />
          </div>
        </b-tab>
        <b-tab active>
          <template #title>
            <i class="fas fa-notes-medical py-2"></i> &nbsp; Monitoring Status Antrian
          </template>
          <div class="p-0">
            <Monitoring />
          </div>
        </b-tab>
        <b-tab active>
          <template #title>
            <i class="fas fa-notes-medical py-2"></i> &nbsp; Jadwal Dokter HFIS
          </template>
          <div class="p-0">
          </div>
        </b-tab>
        <b-tab active>
          <template #title>
            <i class="fas fa-notes-medical py-2"></i> &nbsp; Waktu Tunggu
          </template>
          <div class="p-0">
          </div>
        </b-tab>
      </b-tabs>
    </b-card>
  </div>
</template>
<script>
import { xray } from '../../../../config/pluginInit'
import Monitoring from '../ComponentSub/Monitoring/monitoringbpjs.vue'

export default {
  name: 'BPJS',
  mounted () {
    xray.index()
  },
  components: {
    Monitoring
  }
}
</script>

<style>
.card-body {
    -ms-flex: 1 1 auto;
    -webkit-box-flex: 1;
    flex: 1 1 auto;
    min-height: 1px;
    padding: 0;
}
</style>
