<template>
  <div class="emr-operasi">
    <b-row class="justify-content-between">
      <b-col cols="12" md="12">
        <div class="border w-100 h-100">
          <div class="d-flex flex-row arrow bg-primary justify-content-between align-items-center p-2">
            <div>
              <span class="h6"><i class="fas fa-prescription-bottle-alt"></i> &nbsp; DAFTAR OPERASI</span>
            </div>
            <div>
              <b-button variant="light" size="sm"><i class="fas fa-plus"></i> OPERASI</b-button>
            </div>
          </div>
          <!-- <pre>{{ fields.table }}</pre> -->
          <div class="custom-scroller" :style="{height: heightPercent}">
            <b-table class="mb-0"
              size="sm"
              selectable
              select-mode="single"
              @row-clicked="getDetailLab"
              ref="table"
              hover
              :items="items.table"
              :fields="fields.table">
              </b-table>
          </div>
        </div>
      </b-col>
      <!-- <b-col cols="12" :md="formDetail" class="pl-1">
        <div class="border w-100 h-100">
          <div class="arrow bg-primary p-2">
            <span><span class="h6"> &nbsp; <i class="fas fa-capsules"></i>
              &nbsp; Riwayat Detil Lab</span></span>
          </div>
          <div class="custom-scroller" :style="{ height: heightPercent }">
            <div class="d-flex flex-row">
              <div class="w-100">
                <b-row class="align-items-center mr-0">
                  <b-col md="12">
                    <div class="table mb-0">
                      <b-table class="mb-0"
                      ref="table2"
                      hover
                      selectable
                      select-mode="single"
                      :items="items.table2"
                      :fields="fields.table2">
                      </b-table>
                    </div>
                  </b-col>
                </b-row>
              </div>
            </div>
          </div>
        </div>
      </b-col> -->
    </b-row>
  </div>
</template>
<script>
import { xray } from '../../../../config/pluginInit'
import Services from '../../../../services/fatmahost/index.js'

export default {
  name: 'EResepDaftarOrder',
  components: {
  },
  data () {
    return {
      windowHeight: 0,
      heightPercent: 0,
      formOrder: '6',
      formDetail: '6',
      message: '',
      selected: [],
      fields: {
        table: [
          { key: 'no_urut', label: 'No.', class: 'text-uppercase text-center' },
          { key: 'no_resep', label: 'Nomor', class: 'text-uppercase' },
          { key: 'asal', label: 'Tgl. Laporan', class: 'text-uppercase' },
          { key: 'tujuan', label: 'Nama Operasi', class: 'text-uppercase col-6' },
          { key: 'tanggal', label: 'Tgl. Operasi', class: 'text-uppercase' },
          { key: 'dpjp', label: 'Aksi', class: 'text-uppercase' }
        ],
        table2: [
          { key: 'no_urut', label: 'No.', class: 'text-uppercase text-center' },
          { key: 'nama_pemeriksaan', label: 'Pemeriksaan', class: 'text-uppercase' }
        ]
      },
      items: {
        table: [],
        table2: []
      }
    }
  },
  mounted () {
    xray.index()
    this.getWindowHeight()
    window.addEventListener('resize', this.getWindowHeight)
  },
  destroyed () {
    window.removeEventListener('resize', this.getWindowHeight)
  },
  methods: {
    async getTableLab () {
      try {
        const param1 = ``
        const param2 = `{"object":"SIMRS_EMR_RORDERLAB","xparam0":"${this.$route.params.id}","xparam1":"","xparam2":"","xparam3":"","xparam4":"","xparam5":"","xparam6":"","xparam7":"","xparam8":"","xparam9":""}`
        const response = await Services.middleware.find(param1, param2)
        return response.data.data
      } catch (error) {
        this.message = error
      }
    },
    async getDetailLab (item, index, event) {
      try {
        const param1 = ``
        const param2 = `{"object":"SIMRS_EMR_RORDERLAB_RINC","xparam0":"${item.id}","xparam1":"","xparam2":"","xparam3":"","xparam4":"","xparam5":"","xparam6":"","xparam7":"","xparam8":"","xparam9":""}`
        const response = await Services.middleware.find(param1, param2)
        this.items.table2 = response.data.data
      } catch (error) {
        this.message = error
      }
    },
    getWindowHeight () {
      this.windowHeight = window.innerHeight
      var calc = (50 / 100) * this.windowHeight
      this.heightPercent = calc + 'px'
    },
    closeRightForm () {
      this.formOrder = '11'
      this.formDetail = '1'
    },
    openRightForm () {
      this.formOrder = '8'
      this.formDetail = '4'
    }
  }
}
</script>

<style>
.emr-operasi .custom-scroller {
  position: relative;
  overflow-y: auto;
}

.emr-operasi .radius-custom-emr {
  border-radius: .5rem;
}

.emr-operasi .radius-custom-emr-head {
  border-top-left-radius: .5rem;
  border-top-right-radius: .5rem;
}

.emr-operasi .radius-custom-form-head-eresep-daftar-order {
  border-top-right-radius: .5rem;
}

.emr-operasi .eresep-daftar-order-fc-black {
  color: black;
  font-weight: bold;
}

.emr-operasi .eresep-daftar-order-form-input {
  font-size: 13px;
  height: 33px!important;
  border-radius: 5px;
}

.emr-operasi .table td {
    font-size: 13px;
    padding-top: 0px;
    padding-bottom: 0px;
}
.emr-operasi .table th {
    font-size: 13px;
    font-weight: bold;
}

.emr-operasi .table td, .emr-operasi .table th {
    padding: 0.20rem!important;
}

.emr-operasi .hover {
  transition: all .3s;
  cursor: pointer;
  background-color: rgb(255, 255, 255);
  color: rgb(107, 107, 107);
}

.emr-operasi .arrow:hover .hover {
  background-color: rgba(0, 0, 0, 0.596);
  color: rgb(255, 255, 255);
}
</style>
