import Api from '../axiosInstance'
const version = '/v1'
export default {
  referensi (id) {
    return Api.master.get(`${version}/master/practitioner/referensi/${id}?param0=all`)
  },
  referensicarabayar (id) {
    return Api.master.get(`${version}/master/practitioner/referensi/${id}?param0=reservasi-carabayar`)
  },
  provinsi (id) {
    return Api.master.get(`${version}/master/practitioner/wilayah/provinsi/${id}`)
  },
  kabupaten (id) {
    return Api.master.get(`${version}/master/practitioner/wilayah/kabupaten/${id}`)
  },
  kecamatan (id) {
    return Api.master.get(`${version}/master/practitioner/wilayah/kecamatan/${id}`)
  },
  desa (id) {
    return Api.master.get(`${version}/master/practitioner/wilayah/kelurahan/${id}`)
  },
  tempat_lahir () {
    return Api.master.get(`${version}/master/practitioner/tempatLahir`)
  },
  negara () {
    return Api.master.get(`${version}/master/practitioner/negara`)
  },
  instalasi () {
    return Api.master.get(`${version}/master/practitioner/instalasi`)
  },
  instalasiAll () {
    return Api.master.get(`${version}/master/practitioner/instalasi?param0=instalasi`)
  },
  instalasireservasi () {
    return Api.master.get(`${version}/master/practitioner/instalasi?param0=instalasi-reservasi`)
  },
  instalasimeningitis () {
    return Api.master.get(`${version}/master/practitioner/instalasi?param0=instalasi-reservasi-meningitis`)
  },
  unit (id) {
    return Api.master.get(`${version}/master/practitioner/unit/${id}`)
  },
  unitAll (id) {
    return Api.master.get(`${version}/master/practitioner/unit/${id}?param0=unit`)
  },
  unitreservasi (id) {
    return Api.master.get(`${version}/master/practitioner/unit/${id}?param0=unit-reservasi`)
  },
  unitmeningitis (id) {
    return Api.master.get(`${version}/master/practitioner/unit/${id}?param0=unit-reservasi-meningitis`)
  },
  ruangan (id) {
    return Api.master.get(`${version}/master/practitioner/ruangan?param0=ruangan&param1=${id}`)
  },
  ruangankuota (id) {
    return Api.master.get(`${version}/master/practitioner/ruangan?param0=ruangan-kuota&param1=${id}`)
  },
  ruangancekkuota (id) {
    return Api.master.get(`${version}/master/practitioner/ruangan?param0=ruangan-cek-kuota-off&param1=${id}`)
  },
  ruangancekkuota_v2 (id) {
    return Api.master.get(`${version}/master/practitioner/ruangan?param0=ruangan-reservasi&param1=${id}`)
  },
  ruangancekkuotameningitis (id) {
    return Api.master.get(`${version}/master/practitioner/ruangan?param0=ruangan-cek-kuota-meningitis-off&param1=${id}`)
  },
  tanggalcekkuota (id) {
    return Api.master.get(`${version}/master/practitioner/cekKuota?param0=tanggal-kuota-off&param1=${id}`)
  },
  tanggalcekkuota_v2 (dokter, ruangan) {
    return Api.master.get(`${version}/master/practitioner/cekKuota?param0=jadwal-off&param1=${ruangan}&param2=${dokter}`)
  },
  tanggalcekkuotameningitis (id) {
    return Api.master.get(`${version}/master/practitioner/cekKuota?param0=tanggal-kuota-meningitis-off`)
  },
  doktercekkuota (id, tgl) {
    return Api.master.get(`${version}/master/practitioner/cekKuota?param0=dokter-kuota-off&param1=${id}&param2=${tgl}`)
  },
  doktercekkuota_v2 (ruangan) {
    return Api.master.get(`${version}/master/practitioner/cekKuota?param0=dokter-reservasi&param1=${ruangan}`)
  },
  dokter (id) {
    return Api.master.get(`${version}/master/practitioner/dokterRuangan/${id}`)
  },
  doktercek (ruangan, tanggal) {
    return Api.master.get(`${version}/master/practitioner/dokterReservasi?param0={"xparam0":"${ruangan}","xparam1":"${tanggal}"}`)
  },
  kuotadokter (ruangan, tgl, jnsrsv, dokter) {
    return Api.master.get(`${version}/master/practitioner/kuotaReservasi?param0=dokter&param1={"xparam0":"${dokter}","xparam1":"${ruangan}","xparam2":"${tgl}","xparam3":"${jnsrsv}"}`)
  },
  kuota_dokter_findall (currentPage, perPage, filter) {
    return Api.master.get(`${version}/master/practitioner/kuota?param0=dokter&param1=${currentPage}&param2=${perPage}&param3=${filter}`)
  },
  kuota_dokter_findall_v2 (currentPage, perPage, filter) {
    return Api.master.get(`${version}/master/practitioner/mreservasi?param0=kuota-dokter-rajal&param1=${currentPage}&param2=${perPage}&${filter}`)
  },
  kuota_dokter_findall_v2_igh (currentPage, perPage, filter) {
    return Api.master.get(`${version}/master/practitioner/mreservasi?param0=kuota-dokter-griya&param1=${currentPage}&param2=${perPage}&${filter}`)
  },
  kuota_dokter_findall_v2_irm (currentPage, perPage, filter) {
    return Api.master.get(`${version}/master/practitioner/mreservasi?param0=kuota-dokter-irm&param1=${currentPage}&param2=${perPage}&${filter}`)
  },
  kuota_dokter_findid (id) {
    return Api.master.get(`${version}/master/practitioner/kuota/${id}?param0=dokter`)
  },
  kuota_dokter_findid_v2 (id) {
    return Api.master.get(`${version}/master/practitioner/mreservasi/${id}?param0=kuota-dokter-rajal`)
  },
  kuota_dokter_findid_detail_v2 (id) {
    return Api.master.get(`${version}/master/practitioner/mreservasi/${id}?param0=kuota-dokter-dtl-rajal`)
  },
  kuota_dokter_findid_detail_v2_igh (id) {
    return Api.master.get(`${version}/master/practitioner/mreservasi/${id}?param0=kuota-dokter-dtl-griya`)
  },
  kuota_dokter_findid_detail_v2_irm (id) {
    return Api.master.get(`${version}/master/practitioner/mreservasi/${id}?param0=kuota-dokter-dtl-irm`)
  },
  kuota_dokter_create (data) {
    return Api.master.post(`${version}/master/practitioner/kuota`, data)
  },
  kuota_dokter_create_v2 (data) {
    return Api.master.post(`${version}/master/practitioner/mreservasi`, data)
  },
  kuota_dokter_update (id, data) {
    return Api.master.put(`${version}/master/practitioner/kuota/${id}`, data)
  },
  kuota_dokter_update_v2 (id, data) {
    return Api.master.put(`${version}/master/practitioner/mreservasi/${id}`, data)
  },
  kuota_dokter_delete (id) {
    return Api.master.delete(`${version}/master/practitioner/kuota/${id}?param0=dokter`)
  },
  kuota_dokter_delete_v2_soft (ids) {
    return Api.master.delete(`${version}/master/practitioner/mreservasi/${ids}?param0=kuota-dokter`)
  },
  kuota_dokter_delete_v2_hard (id, ruangan) {
    return Api.master.delete(`${version}/master/practitioner/mreservasi/${id}/${ruangan}?param0=kuota-dokter-hd`)
  },
  kuota_poli_findall (currentPage, perPage, filter) {
    return Api.master.get(`${version}/master/practitioner/kuota?param0=poli&param1=${currentPage}&param2=${perPage}&param3=${filter}`)
  },
  kuota_poli_findid (id) {
    return Api.master.get(`${version}/master/practitioner/kuota/${id}?param0=poli`)
  },
  kuota_poli_create (data) {
    return Api.master.post(`${version}/master/practitioner/kuota`, data)
  },
  kuota_poli_update (id, data) {
    return Api.master.put(`${version}/master/practitioner/kuota/${id}`, data)
  },
  kuota_poli_delete (id) {
    return Api.master.delete(`${version}/master/practitioner/kuota/${id}?param0=poli`)
  },
  dokterIrj (id) {
    return Api.master.get(`${version}/master/practitioner/selectize?param0=dokter-irj-v1&param1={"xparam0":"","xparam1":"${id}","xparam2":""}`)
  },
  dokterIrjByName (id) {
    return Api.master.get(`${version}/master/practitioner/selectize?param0=dokter-irj-v1&param1={"xparam0":"${id}","xparam1":"","xparam2":""}`)
  },
  dokterIgh (id) {
    return Api.master.get(`${version}/master/practitioner/selectize?param0=dokter-igh-v1&param1={"xparam0":"","xparam1":"${id}","xparam2":""}`)
  },
  dokterIrm (id) {
    return Api.master.get(`${version}/master/practitioner/selectize?param0=dokter-irm-v1&param1={"xparam0":"","xparam1":"${id}","xparam2":""}`)
  },
  list_tarif (currentPage, perPage, filter) {
    return Api.master.get(`${version}/master/practitioner/tarif?param0=tarif&param1=${currentPage}&param2=${perPage}&${filter}`)
  },
  show_tarif (id) {
    return Api.master.get(`${version}/master/practitioner/tarif/${id}?param0=tarif`)
  },
  list_tarif_remun (currentPage, perPage, filter) {
    return Api.master.get(`${version}/master/practitioner/tarif?param0=tarif-remun&param1=${currentPage}&param2=${perPage}&${filter}`)
  },
  show_tarif_remun (id) {
    return Api.master.get(`${version}/master/practitioner/tarif/${id}?param0=tarif-remun`)
  },
  create_tarif (data) {
    return Api.master.post(`${version}/master/practitioner/tarif`, data)
  },
  update_tarif (id, data) {
    return Api.master.put(`${version}/master/practitioner/tarif/${id}`, data)
  },
  delete_tarif (id) {
    return Api.master.delete(`${version}/master/practitioner/tarif/${id}?param0=tarif`)
  },
  select_tindakan () {
    return Api.master.get(`${version}/master/practitioner/selectize?param0=tindakan&param1={"xparam0":"","xparam1":"","xparam2":""}`)
  },
  select_tindakan_group () {
    return Api.master.get(`${version}/master/practitioner/selectize?param0=group-tindakan&param1={"xparam0":"","xparam1":"","xparam2":""}`)
  },
  // hari libur nasional-----------------------------------------
  list_libur_nasional (currentPage, perPage, filter, filterType, sortfield, sorting) {
    if (filter) {
      if (filterType === '1') {
        return Api.master.get(`${version}/master/practitioner/mreservasi?param0=libur-nasional&param1=${currentPage}&param2=${perPage}&param3={"xparam0":"${filter}","xparam1":""}&param4={"xparam0":"${sortfield}","xparam1":"${sorting}"}`)
      } else if (filterType === '2') {
        return Api.master.get(`${version}/master/practitioner/mreservasi?param0=libur-nasional&param1=${currentPage}&param2=${perPage}&param3={"xparam0":"","xparam1":"${filter}"}&param4={"xparam0":"${sortfield}","xparam1":"${sorting}"}`)
      }
    } else {
      return Api.master.get(`${version}/master/practitioner/mreservasi?param0=libur-nasional&param1=${currentPage}&param2=${perPage}&param3={"xparam0":"","xparam1":""}&param4={"xparam0":"${sortfield}","xparam1":"${sorting}"}`)
    }
  },
  createLiburPinjam (data) {
    return Api.master.post(`${version}/master/practitioner/mreservasi`, data)
  },
  delete_Libur_nasional (id) {
    return Api.master.delete(`${version}/master/practitioner/mreservasi/${id}?param0=libur-nasional`)
  },
  show_libur_nasional (id) {
    return Api.master.get(`${version}/master/practitioner/mreservasi/${id}?param0=libur-nasional`)
  },
  edit_libur_nasional (id, data) {
    return Api.master.put(`${version}/master/practitioner/mreservasi/${id}`, data)
  },
  // hari libur executive griya husada------------------------------------
  list_libur_executive (currentPage, perPage, filter, filterType, sortfield, sorting) {
    if (filter) {
      if (filterType === '1') {
        return Api.master.get(`${version}/master/practitioner/mreservasi?param0=libur-dokter-griya&param1=${currentPage}&param2=${perPage}&param3={"xparam0":"${filter}","xparam1":""}&param4={"xparam0":"${sortfield}","xparam1":"${sorting}"}`)
      } else if (filterType === '2') {
        return Api.master.get(`${version}/master/practitioner/mreservasi?param0=libur-dokter-griya&param1=${currentPage}&param2=${perPage}&param3={"xparam0":"","xparam1":"${filter}"}&param4={"xparam0":"${sortfield}","xparam1":"${sorting}"}`)
      }
    } else {
      return Api.master.get(`${version}/master/practitioner/mreservasi?param0=libur-dokter-griya&param1=${currentPage}&param2=${perPage}&param3={"xparam0":"","xparam1":""}&param4={"xparam0":"${sortfield}","xparam1":"${sorting}"}`)
    }
  },
  getdoktergriya () {
    return Api.master.get(`${version}/master/practitioner/selectize?param0=dokter-igh&param1={"xparam0":"","xparam1":"","xparam2":""}`)
  },
  delete_Libur_griya (id) {
    return Api.master.delete(`${version}/master/practitioner/mreservasi/${id}?param0=libur-dokter`)
  },
  createliburgriya (data) {
    return Api.master.post(`${version}/master/practitioner/mreservasi`, data)
  },
  show_libur_griya (id) {
    return Api.master.get(`${version}/master/practitioner/mreservasi/${id}?param0=libur-dokter`)
  },
  edit_libur_griya (id, data) {
    return Api.master.put(`${version}/master/practitioner/mreservasi/${id}`, data)
  },
  // hari libur dokter rawat jalan------------------------------------
  list_libur_dokter_rajal (currentPage, perPage, filter, filterType, sortfield, sorting) {
    if (filter) {
      if (filterType === '1') {
        return Api.master.get(`${version}/master/practitioner/mreservasi?param0=libur-dokter-rajal&param1=${currentPage}&param2=${perPage}&param3={"xparam0":"${filter}","xparam1":""}&param4={"xparam0":"${sortfield}","xparam1":"${sorting}"}`)
      } else if (filterType === '2') {
        return Api.master.get(`${version}/master/practitioner/mreservasi?param0=libur-dokter-rajal&param1=${currentPage}&param2=${perPage}&param3={"xparam0":"","xparam1":"${filter}"}&param4={"xparam0":"${sortfield}","xparam1":"${sorting}"}`)
      }
    } else {
      return Api.master.get(`${version}/master/practitioner/mreservasi?param0=libur-dokter-rajal&param1=${currentPage}&param2=${perPage}&param3={"xparam0":"","xparam1":""}&param4={"xparam0":"${sortfield}","xparam1":"${sorting}"}`)
    }
  },
  getdokterrjl () {
    return Api.master.get(`${version}/master/practitioner/selectize?param0=dokter-irj&param1={"xparam0":"","xparam1":"","xparam2":""}`)
  },
  createliburrajal (data) {
    return Api.master.post(`${version}/master/practitioner/mreservasi`, data)
  },
  delete_Libur_rjl (id) {
    return Api.master.delete(`${version}/master/practitioner/mreservasi/${id}?param0=libur-dokter`)
  },
  show_libur_executive (id) {
    return Api.master.get(`${version}/master/practitioner/mreservasi/${id}?param0=libur-dokter`)
  },
  edit_libur_executive (id, data) {
    return Api.master.put(`${version}/master/practitioner/mreservasi/${id}`, data)
  },
  // hari libur irm------------------------------------
  list_libur_irm (currentPage, perPage, filter, filterType, sortfield, sorting) {
    if (filter) {
      if (filterType === '1') {
        return Api.master.get(`${version}/master/practitioner/mreservasi?param0=libur-dokter-irm&param1=${currentPage}&param2=${perPage}&param3={"xparam0":"${filter}","xparam1":""}&param4={"xparam0":"${sortfield}","xparam1":"${sorting}"}`)
      } else if (filterType === '2') {
        return Api.master.get(`${version}/master/practitioner/mreservasi?param0=libur-dokter-irm&param1=${currentPage}&param2=${perPage}&param3={"xparam0":"","xparam1":"${filter}"}&param4={"xparam0":"${sortfield}","xparam1":"${sorting}"}`)
      }
    } else {
      return Api.master.get(`${version}/master/practitioner/mreservasi?param0=libur-dokter-irm&param1=${currentPage}&param2=${perPage}&param3={"xparam0":"","xparam1":""}&param4={"xparam0":"${sortfield}","xparam1":"${sorting}"}`)
    }
  },
  getdokterirm () {
    return Api.master.get(`${version}/master/practitioner/selectize?param0=dokter-irm&param1={"xparam0":"","xparam1":"","xparam2":""}`)
  },
  createliburirm (data) {
    return Api.master.post(`${version}/master/practitioner/mreservasi`, data)
  },
  delete_Libur_irm (id) {
    return Api.master.delete(`${version}/master/practitioner/mreservasi/${id}?param0=libur-dokter`)
  },
  show_libur_irm (id) {
    return Api.master.get(`${version}/master/practitioner/mreservasi/${id}?param0=libur-dokter`)
  },
  edit_libur_irm (id, data) {
    return Api.master.put(`${version}/master/practitioner/mreservasi/${id}`, data)
  },
  // laporan selectize------------------------------------
  getcarabayar () {
    return Api.master.get(`${version}/master/practitioner/referensi/10?param0=reservasi-carabayar&param1={"xparam0":""}`)
  },
  getruangan () {
    return Api.master.get(`${version}/master/practitioner/referensi/15?param0=all&param1={"xparam0":""}`)
  },
  gettindakan () {
    return Api.master.get(`${version}/master/practitioner/selectize?param0=tindakan&param1={"xparam0":"","xparam1":"","xparam2":""}`)
  },
  // hari libur exclude------------------------------------
  list_libur_nasional_exclude (currentPage, perPage, filter, sorting) {
    return Api.master.get(`${version}/master/practitioner/dokter_libur_ex?param1=${currentPage}&param2=${perPage}&param3=${filter}&param4=${sorting}`)
  },
  createliburecxlude (data) {
    return Api.master.post(`${version}/master/practitioner/dokter_libur_ex`, data)
  },
  delete_Libur_exclude (id) {
    return Api.master.delete(`${version}/master/practitioner/dokter_libur_ex/${id}`)
  },
  edit_libur_nasional_exclude (id, data) {
    return Api.master.put(`${version}/master/practitioner/dokter_libur_ex/${id}`, data)
  },
  show_libur_exclude (id) {
    return Api.master.get(`${version}/master/practitioner/dokter_libur_ex/${id}`)
  }
}
