import Vue from 'vue'
import Vuex from 'vuex'
import Setting from './Setting/index'
import Chat from './Chat/index'
import Auth from './Auth/index'
import Emr from './Emr/index'
Vue.use(Vuex)

// const debug = process.env.NODE_ENV !== 'development'

export default new Vuex.Store({
  modules: {
    Emr,
    Auth,
    Setting,
    Chat
  },
  state: {},
  mutations: {},
  actions: {},
  getters: {}
  // strict: debug
})
