import Api from '../axiosInstance'
const version = 'v1'
export default {
  kuitansigenerate (data) {
    return Api.keuangan.post(`/api/${version}/generator/kuitansi`, data)
  },
  penerimaanInqury (data) {
    return Api.keuangan.post(`/api/${version}/penerimaan/inquirypenerimaan`, data)
  },
  flagging (data) {
    return Api.keuangan.post(`/api/${version}/penerimaan/flaggingpenerimaan`, data)
  },
  flagging2 (data) {
    return Api.keuangan.post(`/api/${version}/penerimaan/flaggingpenerimaan/mhas`, data)
  },
  metode_bayar () {
    return Api.keuangan.get(`${version}/selectize?param0=metode-bayar&param1={"xparam0":""}`)
  },
  cetak_bukti (data) {
    return Api.keuangan.post(`/api/${version}/penerimaan/inquirypenerimaan/find`, data)
  },
  preview_batal_kasir (data) {
    return Api.keuangan.post(`/api/${version}/pembatalan/inquirypembatalan`, data)
  },
  batal_kasir (data) {
    return Api.keuangan.post(`/api/${version}/pembatalan/flaggingpembatalan`, data)
  },
  batal_kasir_2 (data) {
    return Api.keuangan.post(`/api/${version}/pembatalan/flaggingpembatalan/mhas`, data)
  }
}
