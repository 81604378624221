import Api from '../axiosInstance'
const version = '/v1'
export default {
  findAll (currentPage, perPage, filter) {
    return Api.users.get(`${version}/practitioner/groupUser?param0=user-group&param1=${currentPage}&param2=${perPage}&param3={"xparam0":"${filter}"}`)
  },
  findId (id) {
    return Api.users.get(`${version}/practitioner/groupUser/${id}?param0=user-group`)
  },
  create (data) {
    return Api.users.post(`${version}/practitioner/groupUser`, data)
  },
  update (id, data) {
    return Api.users.put(`${version}/practitioner/groupUser/${id}`, data)
  },
  destroyX (id, ruangan) {
    return Api.users.delete(`${version}/practitioner/groupUser/${id}/${ruangan}?param0=user-group-ruangan`)
  },
  delete (id) {
    return Api.users.delete(`${version}/practitioner/groupUser/${id}?param0=user-group`)
  }
}
