<template>
  <div>
    <div v-show="isLoading">
        <div id="show-overlay"></div>
      <Loader :style="{ opacity: .5 }" />
    </div>
    <div class="apm-dtl">
      <b-container fluid class="h-100 px-0">
        <div class="h-100">
          <div class="box-parent full-height bg-white shadow-sm border">
            <div class="head bg-primary px-3 py-3">
              <p class="h3 text-center font-weight-bold mb-0">ANJUNGAN PENDAFTARAN MANDIRI RAWAT JALAN (REGULER)</p>
            </div>
            <div class="body px-5 pt-4">
              <div class="d-flex flex-row justify-content-center align-items-center mt-4">
                <div>
                  <img :src="require('../../assets/images/logo_kemenkes_new.svg')" width="300rem" class="d-inline-block align-top" alt="kemenkes">
                </div>
              </div>
              <div class="mb-5 px-4 py-4">
                <b-row class="justify-content-center align-items-center mb-4">
                  <b-col cols="12" lg="6">
                    <div class="parent">
                      <div class="text-center mt-3">
                        <template>
                          <div>
                            <input id="formqr" :value="input" @keyup.enter="postQr" class="input shadow custom-input-style" type="search" @input="onInputChange"
                              placeholder="Scan Nomor Booking..." autocomplete="off">
                            <!-- <b-form-input v-model="qrcode" :value="input" @input="onInputChange" size="lg" class="shadow input" type="search"
                                                      placeholder="Masukan Nomor QR Code..."></b-form-input> -->
                          </div>
                        </template>
                      </div>
                    </div>
                  </b-col>
                </b-row>
                <b-row class="justify-content-center align-items-center">
                  <b-col cols="12" lg="3">
                    <div class="parent">
                      <router-link to="/apm">
                        <div class="text-center mt-3">
                          <template>
                            <div class="shadow rounded">
                              <b-button variant="danger" class="btn-block py-2"><span class="font-weight-bold"><i
                                    class="fas fa-undo-alt"></i> KEMBALI</span></b-button>
                            </div>
                          </template>
                        </div>
                      </router-link>
                    </div>
                  </b-col>
                  <b-col cols="12" lg="3">
                    <div class="parent">
                      <div class="text-center mt-3">
                        <template>
                          <div class="shadow rounded">
                            <b-button variant="primary" class="btn-block py-2" @click="postQr"><span
                                class="font-weight-bold"><i class="fas fa-sign-in-alt"></i> PROSES</span></b-button>
                          </div>
                        </template>
                      </div>
                    </div>
                  </b-col>
                </b-row>
                <b-row class="justify-content-center align-items-center mb-4 mt-5">
                  <b-col cols="12" lg="10">
                    <div class="parent">
                      <div class="text-center shadow mt-3">
                        <template>
                          <SimpleKeyboard @onChange="onChange" @onKeyPress="onKeyPress" :input="input"/>
                        </template>
                      </div>
                    </div>
                  </b-col>
                </b-row>
              </div>
            </div>
          </div>
        </div>
      </b-container>
      <b-modal ref="modal-preview" size="lg" :hide-footer="true" :hide-header="true" :no-close-on-backdrop="true" :static="true" centered>
        <div>
          <div class="message-respon">
            <div v-if="okresponse !== ''">
              <div>
                <div id="printMe">
                  <div class="datenow">
                    <p class="h6 font-weight-light text-dark text-capitalize mb-0">Tanggal Reservasi : <span
                        class="font-weight-bold">{{ dateBoard }}</span></p>
                  </div>
                  <hr>
                  <b-row class="py-3">
                    <b-col cols="12" lg="6" class="mb-3">
                      <div class="mb-2">
                        <p class="h6 font-weight-normal text-capitalize mb-0">Nama Pasien</p>
                        <p class="h6 text-capitalize font-weight-bold text-dark mb-0">{{ okresponse.NAMA }}</p>
                      </div>
                      <div class="mb-2">
                        <p class="h6 font-weight-normal text-capitalize mb-0">Ruangan</p>
                        <p class="h6 text-capitalize font-weight-bold text-dark mb-0">{{ okresponse.RUANGAN }}</p>
                      </div>
                      <div class="mb-2">
                        <p class="h6 font-weight-normal text-capitalize mb-0">Dokter</p>
                        <p class="h6 text-capitalize font-weight-bold text-dark mb-0">{{ okresponse.DOKTER }}</p>
                      </div>
                      <div class="mb-2">
                        <p class="h6 font-weight-normal text-capitalize mb-0">Tanggal Reservasi</p>
                        <p class="h6 text-capitalize font-weight-bold text-dark mb-0">{{ new Date(okresponse.TANGGAL_RESERVASI) | formatDate }}</p>
                      </div>
                      <div class="mb-2">
                        <p class="h6 font-weight-normal text-capitalize mb-0">Estimasi</p>
                        <p class="h6 text-capitalize font-weight-bold text-dark mb-0">{{ okresponse.ESTIMASI }}</p>
                      </div>
                      <div class="mb-0">
                        <p class="h6 font-weight-normal text-capitalize mb-0">Checkin</p>
                        <p class="h6 text-capitalize font-weight-bold text-dark mb-0">{{ okresponse.CHECK_IN }}</p>
                      </div>
                    </b-col>
                    <b-col cols="12" lg="6">
                      <div class="mb-2">
                        <p class="h6 font-weight-normal text-capitalize mb-0">Nomor Rekam Medis</p>
                        <p class="h6 text-capitalize font-weight-bold text-dark mb-0">{{ okresponse.NORM }}</p>
                      </div>
                      <div class="mb-2">
                        <p class="h6 font-weight-normal text-capitalize mb-0">Nomor Ref</p>
                        <p class="h6 text-capitalize font-weight-bold text-dark mb-0">{{ okresponse.NOMOR }}</p>
                      </div>
                      <div class="mb-2">
                        <p class="h6 font-weight-normal text-capitalize mb-0">Nomor Antrian</p>
                        <p class="h6 text-capitalize font-weight-bold text-dark mb-0">{{ okresponse.ANTRIAN }}</p>
                      </div>
                      <div class="mb-2">
                        <p class="h6 font-weight-normal text-capitalize mb-0">Cara Bayar</p>
                        <p class="h6 text-capitalize font-weight-bold text-dark mb-0">{{ okresponse.CARABAYAR }}</p>
                      </div>
                      <div class="mb-2">
                        <p class="h6 font-weight-normal text-capitalize mb-0">Nomor Kartu BPJS</p>
                        <p class="h6 text-capitalize font-weight-bold text-dark mb-0" v-if="okresponse.NOMOR_KARTU">{{ okresponse.NOMOR_KARTU }}</p>
                        <p class="h6 text-capitalize font-weight-bold text-dark mb-0" v-else>-</p>
                      </div>
                      <div class="mb-0">
                        <p class="h6 font-weight-normal text-capitalize mb-0">Nomor Rujukan BPJS</p>
                        <p class="h6 text-capitalize font-weight-bold text-dark mb-0" v-if="okresponse.NOMOR_RUJUKAN">{{ okresponse.NOMOR_RUJUKAN }}</p>
                        <p class="h6 text-capitalize font-weight-bold text-dark mb-0" v-else>-</p>
                      </div>
                    </b-col>
                  </b-row>
                </div>
                <hr>
                <div class="text-center">
                  <b-button variant="primary" @click="print"><i class="fas fa-print"></i> Print Bukti</b-button>
                </div>
              </div>
            </div>
            <div v-else-if="errRespon !== ''">
              <div>
                <lottie :options="defaultOptions2" :autoPlay="true" :width="100" :height="100" />
              </div>
              <p class="h6 font-weight-normal text-center text-capitalize text-dark mb-0 mt-2">{{ errRespon }}</p>
              <div class="mt-4 text-center">
                <b-button class="px-4" variant="danger" @click="autoClose">Tutup</b-button>
              </div>
            </div>
          </div>
        </div>
      </b-modal>
    </div>
    <div id="printCoba" style="display: none;">
      <div class="page" style="border: none; width: 110mm; height: 20mm; margin: 0px;">
        <div class="page-area" style="font-family: Arial, Helvetica, sans-serif; border: none;">
          <table class="header font-arial" style="width: 100%; padding-bottom: 10px; border-bottom: 1px solid black;">
            <tbody>
              <tr>
                <!-- <td><img src="<?= base_url('/assets/img/logo2.png') ?>" alt="logo-fatmawati"></td> -->
                <td style="width: 50%;">
                  <strong>
                    <span style="color: rgb(53, 53, 53); font-size: 1.3rem;">RS FATMAWATI</span><br>
                    <span style="font-size: 1rem; color: black;" v-if="okresponse.TANGGAL_RESERVASI">Tanggal : {{ new Date(okresponse.TANGGAL_RESERVASI) | formatDate }}</span><br>
                    <!-- <span style="font-size: 9pt; color: gray; font-weight: lighter;">TANGGAL CETAK : 22-12-2022</span> -->
                  </strong>
                </td>
                <td style="width: 50%; padding-left: 100px;">
                  <table>
                    <tbody>
                      <tr>
                        <td style="text-align: center; padding: 0; border-spacing: 0; margin: 0;">
                          <span style="color: rgb(53, 53, 53); font-size: 2rem; font-weight: bold;">{{ okresponse.ANTRIAN }}</span>
                        </td>
                      </tr>
                      <tr>
                        <td style="text-align: center; padding: 0; border-spacing: 0; margin: 0;">
                          <span style="font-size: 1rem; color: rgb(53, 53, 53); font-weight: bold;">NO.ANTRIAN</span>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
            </tbody>
          </table>
          <table class="padding-md font-arial text-general" style="padding-top: 10px; padding-bottom: 20px; border-top: none; font-size: 3mm; width: 100%;">
            <tbody style="padding-top: 10px">
              <tr>
                <td width="20%"><span style="font-size: 1rem;">Nama</span></td>
                <td width="1%"><span style="font-size: 1rem;">:</span></td>
                <td width="29%">
                  <span style="font-size: 1rem; font-weight: bold; color: black;">{{ okresponse.NAMA }}</span>
                </td>
              </tr>
              <tr>
                <td width="20%"><span style="font-size: 1rem;">No. Rekam Medis</span></td>
                <td width="1%"><span style="font-size: 1rem;">:</span></td>
                <td width="29%">
                  <span style="font-size: 1rem; font-weight: bold; color: black;">{{ okresponse.NORM }}</span>
                </td>
              </tr>
              <tr>
                <td width="20%"><span style="font-size: 1rem;">No. Reservasi</span></td>
                <td width="1%"><span style="font-size: 1rem;">:</span></td>
                <td width="29%">
                  <span style="font-size: 1rem; font-weight: bold; color: black;">{{ okresponse.NOMOR_RESERVASI }}</span>
                </td>
              </tr>
              <tr>
                <td width="20%"><span style="font-size: 1rem;">Poli Ruangan</span></td>
                <td width="1%"><span style="font-size: 1rem;">:</span></td>
                <td width="29%">
                  <span style="font-size: 1rem; font-weight: bold; color: black;">{{ okresponse.RUANGAN }}</span>
                </td>
              </tr>
              <tr>
                <td width="20%"><span style="font-size: 1rem;">Dokter</span></td>
                <td width="1%"><span style="font-size: 1rem;">:</span></td>
                <td width="29%">
                  <span style="font-size: 1rem; font-weight: bold; color: black;">{{ okresponse.DOKTER }}</span>
                </td>
              </tr>
              <tr>
                <td width="20%"><span style="font-size: 1rem;">Cara Bayar</span></td>
                <td width="1%"><span style="font-size: 1rem;">:</span></td>
                <td width="29%">
                  <span style="font-size: 1rem; font-weight: bold; color: black;">{{ okresponse.CARABAYAR }}</span>
                </td>
              </tr>
              <tr>
                <td width="20%"><span style="font-size: 1rem;">Nomor BPJS</span></td>
                <td width="1%"><span style="font-size: 1rem;">:</span></td>
                <td width="29%">
                  <span style="font-size: 1rem; font-weight: bold; color: black;" v-if="okresponse.NOMOR_KARTU">{{ okresponse.NOMOR_KARTU }}</span>
                  <span style="font-size: 1rem; font-weight: bold; color: black;" v-else>-</span>
                </td>
              </tr>
              <tr>
                <td width="20%"><span style="font-size: 1rem;">Nomor Rujukan</span></td>
                <td width="1%"><span style="font-size: 1rem;">:</span></td>
                <td width="29%">
                  <span style="font-size: 1rem; font-weight: bold; color: black;" v-if="okresponse.NOMOR_RUJUKAN">{{ okresponse.NOMOR_RUJUKAN }}</span>
                  <span style="font-size: 1rem; font-weight: bold; color: black;" v-else>-</span>
                </td>
              </tr>
              <tr>
                <td width="20%"><span style="font-size: 1rem;">Nomor SEP</span></td>
                <td width="1%"><span style="font-size: 1rem;">:</span></td>
                <td width="29%">
                  <!-- 0902R0010824V012464 -->
                  <span style="font-size: 1rem; font-weight: bold; color: black;" v-if="nosep">{{ nosep == 'NULL' ? '-' : nosep }}</span>
                  <span style="font-size: 1rem; font-weight: bold; color: black;" v-else>-</span>
                </td>
              </tr>
              <tr>
                <td width="20%"><span style="font-size: 1rem;">Estimasi</span></td>
                <td width="1%"><span style="font-size: 1rem;">:</span></td>
                <td width="29%">
                  <span style="font-size: 1rem; font-weight: bold; color: black;">{{ okresponse.ESTIMASI }}</span>
                </td>
              </tr>
              <tr>
                <td width="20%"><span style="font-size: 1rem;">Check In</span></td>
                <td width="1%"><span style="font-size: 1rem;">:</span></td>
                <td width="29%">
                  <span style="font-size: 1rem; font-weight: bold; color: black;">{{ okresponse.CHECK_IN }}</span>
                </td>
              </tr>
              <!-- <tr>
                <td width="20%"><span style="font-size: 1rem;">Tanggal Reservasi</span></td>
                <td width="1%"><span style="font-size: 1rem;">:</span></td>
                <td width="29%">
                  <span style="font-size: 1rem; font-weight: bold; color: black;" v-if="okresponse.TANGGAL_RESERVASI">{{ new Date(okresponse.TANGGAL_RESERVASI) | formatDate }}</span>
                </td>
              </tr> -->
            </tbody>
          </table>
          <table>
            <tbody>
              <tr>
                <div style="padding-top: 10px;">
                  <label style="font-size: 1rem;">
                    <input type="checkbox" id="radiologi" name="radiologi"> Radiologi
                  </label>
                  <label style="font-size: 1rem; margin-left: 15px;">
                    <input type="checkbox" id="laboratorium" name="laboratorium"> Laboratorium
                  </label>
                  <label style="font-size: 1rem; margin-left: 15px;">
                    <input type="checkbox" id="resep" name="resep"> Resep
                  </label>
                </div>
              </tr>
            </tbody>
          </table>
          <!-- <table class="font-arial" style="padding-top: 40px padding-bottom: 20px; width: 100%;">
            <tbody>
              <tr>
                <td style="width: 50%; font-size: 1rem; font-weight: bold; padding-bottom: 20px;">
                  <span style="border: 3px solid black; padding: 5px;">NOMOR ANTRIAN : {{ okresponse.NOMOR }}</span>
                </td>
              </tr>
            </tbody>
          </table> -->
          <table class="font-arial" style=" width: 100%;">
            <tbody>
              <tr>
                <td style="width: 50%; font-size: 1rem; padding-top: 10px;">
                  <span style="font-size: 1rem; color: gray; font-weight: lighter; font-style: italic;">Nomor Registrasi <span style="font-weight: bold;">{{ okresponse.NOMOR }}</span></span><br>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Services from '../../services/fatmahost/reservasi/index.ts'
import Lottie from 'vue-lottie'
import animationLogo from '../../assets/lottie/checked.json'
import animationLogo2 from '../../assets/lottie/failed.json'
import SimpleKeyboard from '../../components/hospia/SimpleKeyboard'
import logo from '../../assets/images/logo-rs-black.png'

export default {
  name: 'AnjunganPendaftaranMandiriDetail',
  components: {
    Lottie,
    SimpleKeyboard
  },
  data () {
    return {
      isLoading: false,
      input: '',
      output: null,
      defaultOptions: { animationData: animationLogo, loop: true },
      defaultOptions2: { animationData: animationLogo2, loop: true },
      qrcode: '',
      okresponse: '',
      okresponse2: '',
      errRespon: '',
      dateBoard: '',
      nosep: '',
      imageLogo: logo
    }
  },
  mounted () {
    this.getDate()
    this.autofocus()
  },
  methods: {
    onChange (input) {
      this.input = input.toUpperCase()
    },
    onKeyPress (button) {
      // console.log('button', button)
    },
    onInputChange (input) {
      this.input = input.target.value.toString()
    },
    async postQr () {
      this.isLoading = true
      if (this.input !== '') {
        const data = {
          object: 'apm-rj',
          nomor_reservasi: this.input
        }
        await Services.printapm(data).then(response => {
          if (response.data.statcode === 0) {
            this.isLoading = false
            this.okresponse = response.data.data[0]
            this.nosep = response?.data?.nosep
            // this.okresponse2 = response.data.res
            this.$refs['modal-preview'].show()
          } else {
            this.isLoading = false
            this.errRespon = response.data.message
            this.$refs['modal-preview'].show()
          }
        }).catch(error => {
          this.isLoading = false
          this.errRespon = error.message
          this.$refs['modal-preview'].show()
        })
      } else {
        this.isLoading = false
        this.errRespon = 'Mohon Masukan Nomor QR Code Anda'
        this.$refs['modal-preview'].show()
      }
    },
    getDate () {
      let date = new Date()
      let y = date.getFullYear()
      let m = date.getMonth() + 1
      let d = date.getDate()
      let joinDate = d + '-' + m + '-' + y
      this.dateBoard = joinDate
    },
    print () {
      const prtHtml = document.getElementById('printCoba')
      const Winprint = window.open('', '', 'height=1000,width=1000')
      Winprint.document.write(`<!DOCTYPE html>
      <html lang="en">
      <head>
      </head>
      <body>
      ${prtHtml.innerHTML}
      </body>
      </html>`)
      Winprint.document.close()
      Winprint.focus()
      Winprint.print()
      Winprint.close()
      this.$refs['modal-preview'].hide()
      this.input = ''
      this.reload()
      // setTimeout(() => {
      //   this.autofocus()
      // }, 1000)
    },
    autofocus () {
      document.getElementById('formqr').focus()
    },
    reload () {
      location.reload()
    },
    autoClose () {
      this.$refs['modal-preview'].hide()
      this.input = ''
      this.reload()
      // setTimeout(() => {
      //   this.autofocus()
      // }, 1000)
    }
  }
}
</script>
<style>
.apm-dtl .radius-top {
  border-radius: 10px 10px 0px 0px!important;
}
.apm-dtl .hover-btn-apm {
  box-shadow: 1px 1px 5 px #888888;
  cursor: pointer;
  transition: all ease-in-out .3s;
}
.apm-dtl .hover-btn-apm:hover {
  box-shadow: 5px 5px 10px #888888;
}
.apm-dtl .full-height {
  min-height: 100vh!important;
  background-image: url('../../assets/images/wave.svg');
  background-repeat: no-repeat;
  background-position: right bottom;
}
.apm-dtl .custom-input-style {
  border: 1px solid rgb(235, 235, 235);
  border-radius: 10px;
  width: 100%!important;
  height: 60px!important;
  padding: 10px 10px 10px 10px;
  font-size: 17px;
  font-weight: bold;
}
.apm-dtl .hg-theme-default .hg-button {
    color: black;
    font-weight: bolder;
    font-size: 17px;
}
@media print {
  .hidden-print {
    display: none !important;
  }
}
</style>
