<template>
  <div class="jadwal-cathlab-add mb-4">
    <div v-show="isLoading">
      <div id="show-overlay"></div>
      <Loader :style="{ opacity: .5 }" />
    </div>
    <b-container fluid>
      <div class="bg-white px-4 py-3 rounded shadow-sm">
        <div class="search-section mb-4">
          <div class="bg-transparent">
            <div class="px-4 py-3">
              <b-row class="justify-content-center">
                <b-col cols="12" lg="6">
                  <b-form>
                    <b-form-group class="mb-0">
                      <div class="d-flex flex-row">
                        <div class="w-75">
                          <b-form-input v-model="cekNorm" class="custom-search form-control-cathlab" type="search"
                            size="lg" id="colFormLabelLg" placeholder="Nomor Rekam Medis"></b-form-input>
                        </div>
                        <div class="w-25">
                          <button @click="getNorm" type="button" class="btn btn-primary btn-height-custom"
                            data-toggle="button" aria-pressed="false"><i class="fas fa-search"></i> Cari Pasien</button>
                        </div>
                      </div>
                    </b-form-group>
                  </b-form>
                </b-col>
              </b-row>
            </div>
          </div>
        </div>
        <div class="body-section">
          <b-row>
            <b-col>
              <iq-card class="shadow">
                <div class="head-card bg-primary px-4 py-3 card-top-radius">
                  <p class="h6 text-white font-weight-bold text-uppercase mb-0"><i class="fas fa-id-card"></i> data diri
                  </p>
                </div>
                <hr class="m-0">
                <div class="px-4 pt-3">
                  <b-row class="mb-3 align-items-center">
                    <b-col cols="12" lg="3">
                      <p class="text-dark text-uppercase mb-0">Nama</p>
                    </b-col>
                    <b-col cols="12" lg="1">
                      <p class="text-dark text-uppercase mb-0">:</p>
                    </b-col>
                    <b-col>
                      <b-form-input class="form-control-cathlab" v-model="nama" type="text" size="sm" id="colFormLabelSm" readonly>
                      </b-form-input>
                    </b-col>
                  </b-row>
                </div>
                <hr class="m-0">
                <div class="body-card px-4 py-4">
                  <b-row class="mb-3 align-items-center">
                    <b-col cols="12" lg="3">
                      <p class="text-dark text-uppercase mb-0">Norm</p>
                    </b-col>
                    <b-col cols="12" lg="1">
                      <p class="text-dark text-uppercase mb-0">:</p>
                    </b-col>
                    <b-col>
                      <b-form-input :state="validationNorm" class="form-control-cathlab" v-model="form.norm" type="text" size="sm"
                        id="colFormLabelSm" readonly></b-form-input>
                    </b-col>
                  </b-row>
                  <b-row class="mb-3 align-items-center">
                    <b-col cols="12" lg="3">
                      <p class="text-dark text-uppercase mb-0">Nomor Kontak</p>
                    </b-col>
                    <b-col cols="12" lg="1">
                      <p class="text-dark text-uppercase mb-0">:</p>
                    </b-col>
                    <b-col>
                      <b-form-input class="form-control-cathlab" v-model="form.nomor" type="text" size="sm"
                        id="colFormLabelSm"></b-form-input>
                    </b-col>
                  </b-row>
                  <b-row class="mb-3 align-items-center">
                    <b-col cols="12" lg="3">
                      <p class="text-dark text-uppercase mb-0">Email</p>
                    </b-col>
                    <b-col cols="12" lg="1">
                      <p class="text-dark text-uppercase mb-0">:</p>
                    </b-col>
                    <b-col>
                      <b-form-input class="form-control-cathlab" v-model="form.email" type="text" size="sm"
                        id="colFormLabelSm"></b-form-input>
                    </b-col>
                  </b-row>
                </div>
                <hr class="m-0">
                <div class="body-section-2 px-4 py-4">
                  <b-row class="mb-0 align-items-center">
                    <b-col cols="12" lg="3">
                      <p class="text-dark text-uppercase mb-0">Status</p>
                    </b-col>
                    <b-col cols="12" lg="1">
                      <p class="text-dark text-uppercase mb-0">:</p>
                    </b-col>
                    <b-col>
                      <b-form-group class="mb-0">
                        <b-form-radio-group id="radio-group-1" v-model="form.status" :options="options"
                          name="radio-options"></b-form-radio-group>
                      </b-form-group>
                    </b-col>
                  </b-row>
                </div>
              </iq-card>
              <iq-card class="shadow mb-4">
                <div class="head-card bg-primary px-4 py-3 card-top-radius">
                  <p class="h6 text-white font-weight-bold text-uppercase mb-0"><i class="fas fa-user-md"></i> pilih
                    tanggal</p>
                </div>
                <hr class="m-0">
                <div class="body-card px-4 py-4">
                  <template>
                    <flat-pickr @on-change="valTgl" v-model="tanggal" :config="config"
                      class="w-100 calender-small flatpickr-cstm-lab" placeholder="PILIH TANGGAL">
                    </flat-pickr>
                  </template>
                </div>
              </iq-card>
            </b-col>
            <b-col>
              <iq-card class="mb-4 shadow">
                <div class="head-card bg-primary px-4 py-3 card-top-radius">
                  <p class="h6 text-white font-weight-bold text-uppercase mb-0"><i class="fas fa-user-md"></i> pilih
                    dokter</p>
                </div>
                <hr class="m-0">
                <div class="body-card px-4 py-4">
                  <multiselect v-model="selected.dokter" :show-labels="false" :limit="3" @input="valDokter"
                    label="deskripsi" placeholder="DOKTER" :options="combo.dokter" :searchable="true">
                  </multiselect>
                </div>
              </iq-card>
              <iq-card class="shadow mb-4">
                <div
                  class="head-card bg-primary px-4 py-3 card-top-radius d-flex flex-row justify-content-between align-items-center">
                  <div>
                    <p class="h6 text-white font-weight-bold text-uppercase mb-0"><i
                        class="fas fa-hand-holding-medical"></i> pilih tindakan</p>
                  </div>
                  <div class="cursor-effect">
                    <p class="h5 mb-0" @click="addRow">
                      <i class="fas fa-plus-circle"></i>
                    </p>
                  </div>
                </div>
                <hr class="m-0">
                <div class="body-card px-4 py-4">
                  <div>
                    <b-row class="align-items-center mb-3" v-for="(k, index) in form.detail" v-bind:key="k.id">
                      <b-col cols="12" lg="11">
                        <multiselect v-model="selected.tindakan[index].detail" :show-labels="false" :limit="3"
                          @input="valTindakan" @select="valIndex(index)" label="deskripsi" placeholder="TINDAKAN"
                          :options="combo.tindakan" :searchable="true">
                        </multiselect>
                      </b-col>
                      <b-col cols="12" lg="1">
                        <div class="cursor-effect text-right">
                          <p class="h5 mb-0" @click="deleteRow(index)"><i class="fas text-danger fa-minus-circle"></i></p>
                        </div>
                      </b-col>
                    </b-row>
                  </div>
                </div>
              </iq-card>
            </b-col>
          </b-row>
        </div>
        <div class="footer-section">
          <iq-card class="bg-transparent shadow-none">
            <div class="d-flex flex-row align-items-center justify-content-start px-3 py-2">
              <div class="m-1">
                <b-button variant="secondary" @click="backtojadwal" class=""><i class="fas fa-undo"></i> Kembali
                </b-button>
              </div>
              <div class="m-1">
                <b-button variant="primary" @click="onSimpan" class=""><i class="fas fa-save"></i> Simpan</b-button>
              </div>
            </div>
          </iq-card>
        </div>
        <b-modal ref="modal" size="md" title="Informasi" ok-title="Oke" @ok="backtojadwal" :ok-only=true :hide-header=true centered>
          <div class="text-center px-lg-4 px-2">
            <lottie :options="defaultOptions" :autoPlay="true" :width="250" :height="250" />
            <p class="h6 text-success font-weight-bold text-uppercase mb-0">{{notif}}</p>
          </div>
        </b-modal>
        <b-modal ref="modal-gagal" size="md" title="Informasi" ok-title="Oke" @ok="backtojadwal" :ok-only=true :hide-header=true centered>
          <div class="text-center px-lg-4 px-2">
            <lottie :options="defaultOptions2" :autoPlay="true" :width="250" :height="250" />
            <p class="h6 text-danger font-weight-bold text-uppercase mb-0">{{notif}}</p>
          </div>
        </b-modal>
      </div>
    </b-container>
  </div>
</template>

<script>
import { xray } from '../../../config/pluginInit'
import Lab from '../../../services/laboratorium'
import iqCard from '../../../components/xray/cards/iq-card.vue'
import Loader from '../../../components/xray/loader/Loader'
import Multiselect from 'vue-multiselect'
import FlatPickr from 'vue-flatpickr-component'
import 'flatpickr/dist/flatpickr.css'
import Lottie from 'vue-lottie'
import animationLogo from '../../../assets/lottie/checked.json'
import animationLogo2 from '../../../assets/lottie/failed.json'

export default {
  name: 'ProfileEdit',
  components: {
    iqCard,
    Loader,
    Multiselect,
    FlatPickr,
    Lottie
  },
  mounted () {
    xray.index()
    this.getDokter()
    this.getTindakan()
  },
  computed: {
    validationNorm () {
      return this.form.norm.length === 8
    }
  },
  methods: {
    addRow () {
      this.form.detail.push(
        {
          kode: null
        }
      )
      this.selected.tindakan.push(
        {
          detail: []
        }
      )
    },
    valTgl () {
      const tglStr = this.tanggal
      const tgl = tglStr.split('-').reverse().join('-')
      this.form.tanggal = tgl
    },
    deleteRow (index) {
      this.form.detail.splice(index, 1)
      this.selected.tindakan.splice(index, 1)
    },
    async getNorm () {
      this.isLoading = true
      const data = this.cekNorm
      await Lab.getNorm(data).then(response => {
        this.resData = response.data.data[0]
        this.nama = this.resData.NAMA
        this.form.norm = this.resData.NORM
        this.form.nomor = this.resData.NOMOR
        this.autoNorm()
        this.isLoading = false
      }).catch(error => {
        this.isLoading = false
        this.error = error.response.data.message
      })
      this.isLoading = false
    },
    async getDokter () {
      const data = {
        ruangan: 101170101
      }
      await Lab.showDokter(data).then(response => {
        if (response.status === 200) {
          this.combo.dokter = response.data.data
        }
      })
      // .catch(error => {
      //   console.log(error.response)
      // })
    },
    async getTindakan () {
      const data = {
        ruangan: 101170101
      }
      await Lab.showTindakan(data).then(response => {
        if (response.status === 200) {
          this.combo.tindakan = response.data.data
        }
      })
      // .catch(error => {
      //   console.log(error.response)
      // })
    },
    async onSimpan () {
      const data = this.form
      if (data.norm.length === 8) {
        try {
          const res = await Lab.store(data)
          this.notif = res.data.message
          this.$refs['modal'].show()
        } catch (err) {
          this.notif = err.response.data.message
          this.$refs['modal-gagal'].show()
        }
      } else {
        this.notif = 'Nomor Rekam Medis Harus 8 Digit'
        this.$refs['modal-gagal'].show()
      }
    },
    backtojadwal () {
      this.$router.push({ name: 'laboratorium.list' })
    },
    valDokter (value) {
      this.form.dokter = value.id
    },
    valTindakan (value) {
      const index = this.index
      this.form.detail[index].kode = value.id
    },
    valIndex (index) {
      this.index = index
    },
    refreshpage () {
      location.reload()
    },
    autoNorm () {
      const norm = this.form.norm?.toString() || ''
      this.form.norm = norm.padStart(8, '0')
    }
  },
  data () {
    return {
      isLoading: false,
      index: null,
      error: '',
      combo: {
        dokter: [],
        tindakan: []
      },
      selected: {
        dokter: [],
        tindakan: [{
          detail: []
        }]
      },
      selectedStatus: null,
      config: {
        dateFormat: 'd-m-Y',
        minDate: new Date()
      },
      options: [
        { text: 'Cito', value: 1 },
        { text: 'Elektif', value: 2 }
      ],
      resData: {},
      cekNorm: '',
      tanggal: '',
      nama: '',
      form: {
        norm: '',
        nomor: '',
        email: '',
        ruangan: 101170101,
        dokter: '',
        tanggal: '',
        status: '',
        detail: [{
          kode: null
        }]
      },
      notif: '',
      defaultOptions: { animationData: animationLogo, loop: true },
      defaultOptions2: { animationData: animationLogo2, loop: true }
    }
  }
}
</script>
<style>
.jadwal-cathlab-add .btn-height-custom {
  min-height: 45px!important;
  height: 45px!important;
  border-radius: 0px 10px 10px 0px!important;
}
.jadwal-cathlab-add .card-top-radius {
  border-radius: 25px 25px 0px 0px;
}
.jadwal-cathlab-add .custom-search {
  background: #ceebee!important;
  border-radius: 10px 0px 0px 10px!important;
}
.jadwal-cathlab-add .cursor-effect {
  cursor: pointer;
}
.jadwal-cathlab-add .flatpickr-cstm-lab {
  min-height: 45px!important;
  height: 45px!important;
  line-height: 45px!important;
  border: 1px solid #d7dbda!important;
  border-radius: 10px!important;
  padding-left: 10px!important;
  padding-right: 10px!important;
  color: #000000!important;
  font-weight: bold!important;
}
.jadwal-cathlab-add .form-control-cathlab {
  color: #000000!important;
}
</style>
