<template>
  <div id="pasien-form" class="pasien-form mb-4">
    <b-container fluid>
      <div>
        <iq-card class="card-radius-custom border mb-2">
          <template v-slot:headerTitle>
            <h6 class="card-title mb-0"><i class="fas fa-user"></i> Data Identitas</h6>
          </template>
          <template v-slot:headerAction>
            <h6 class="card-title mb-0">[ No. Rekam Medis ]</h6>
          </template>
          <template v-slot:body>
            <b-row class="mb-2">
              <b-col class="pr-1">
                <b-form-input class="custom-control-pasien" placeholder="[ Masukan NORM Manual ]"></b-form-input>
              </b-col>
              <b-col class="pl-1 pr-1">
                <b-form-input class="custom-control-pasien" placeholder="[ Gelar Depan ]"></b-form-input>
              </b-col>
              <b-col class="pl-1 pr-1">
                <b-form-input class="custom-control-pasien" placeholder="[ Masukan Nama Tanpa Gelar ]"></b-form-input>
              </b-col>
              <b-col class="pl-1 pr-1">
                <b-form-input class="custom-control-pasien" placeholder="[ Gelar Belakang ]"></b-form-input>
              </b-col>
              <b-col class="pl-1">
                <b-form-input class="custom-control-pasien" placeholder="[ Masukan Nama Panggilan ]"></b-form-input>
              </b-col>
            </b-row>
            <b-row class="mb-2">
              <b-col class="pr-1">
                <multiselect v-model="selected.tempatlahir" :show-labels="false" :limit="3" track-by="deskripsi" label="deskripsi" placeholder="[ Tempat Lahir ]" :options="combo.tempatlahir">
                </multiselect>
              </b-col>
              <b-col class="pl-1 pr-1">
                <flat-pickr v-model="form.data_identitas.tanggal_lahir" :config="config"
                    class="w-100 calender-small flatpickr-cstm-lab" placeholder="[ Tanggal Lahir ]">
                </flat-pickr>
              </b-col>
              <b-col cols="1" class="pl-1 pr-1">
                <b-form-input class="custom-control-pasien" placeholder="Tahun" readonly></b-form-input>
              </b-col>
              <b-col cols="1" class="pl-1 pr-1">
                <b-form-input class="custom-control-pasien" placeholder="Bulan" readonly></b-form-input>
              </b-col>
              <b-col cols="1" class="pl-1 pr-1">
                <b-form-input class="custom-control-pasien" placeholder="Hari" readonly></b-form-input>
              </b-col>
              <b-col class="pl-1">
                <multiselect v-model="selected.jeniskelamin" :show-labels="false" :limit="3" track-by="deskripsi" label="deskripsi" placeholder="[ Jenis Kelamin ]" :options="combo.jeniskelamin">
                </multiselect>
              </b-col>
            </b-row>
            <b-row class="mb-2">
              <b-col class="pr-1">
                <multiselect v-model="selected.agama" :show-labels="false" :limit="3" track-by="deskripsi" label="deskripsi" placeholder="[ Agama ]" :options="combo.agama">
                </multiselect>
              </b-col>
              <b-col class="pl-1 pr-1">
                <multiselect v-model="selected.statusperkawinan" :show-labels="false" :limit="3" track-by="deskripsi" label="deskripsi" placeholder="[ Status Perkawinan ]" :options="combo.statusperkawinan">
                </multiselect>
              </b-col>
              <b-col class="pl-1 pr-1">
                <multiselect v-model="selected.pendidikan" :show-labels="false" :limit="3" track-by="deskripsi" label="deskripsi" placeholder="[ Pendidikan ]" :options="combo.pendidikan">
                </multiselect>
              </b-col>
              <b-col class="pl-1">
                <multiselect v-model="selected.pekerjaan" :show-labels="false" :limit="3" track-by="deskripsi" label="deskripsi" placeholder="[ Pekerjaan ]" :options="combo.pekerjaan">
                </multiselect>
              </b-col>
            </b-row>
            <b-row>
              <b-col class="pr-1">
                <multiselect v-model="selected.golongandarah" :show-labels="false" :limit="3" track-by="deskripsi" label="deskripsi" placeholder="[ Golongan Darah ]" :options="combo.golongandarah">
                </multiselect>
              </b-col>
              <b-col class="pl-1 pr-1">
                <multiselect v-model="selected.negara" :show-labels="false" :limit="3" track-by="deskripsi" label="deskripsi" placeholder="[ Kewarganegaraan ]" :options="combo.negara">
                </multiselect>
              </b-col>
              <b-col class="pl-1 pr-1">
                <multiselect v-model="selected.suku" :show-labels="false" :limit="3" track-by="deskripsi" label="deskripsi" placeholder="[ Suku ]" :options="combo.suku">
                </multiselect>
              </b-col>
              <b-col class="pl-1">
                <multiselect v-model="selected.status" :show-labels="false" :limit="3" track-by="deskripsi" label="deskripsi" placeholder="[ Hidup/Aktif ]" :options="combo.status">
                </multiselect>
              </b-col>
            </b-row>
          </template>
        </iq-card>
      </div>
      <div>
        <b-row>
          <b-col class="pr-1">
            <iq-card class="card-radius-custom border mb-2">
              <template v-slot:headerTitle>
                <h6 class="card-title mb-0"><i class="fas fa-location-arrow"></i> Alamat Sekarang</h6>
              </template>
              <template v-slot:body>
                <b-row class="mb-2">
                  <b-col>
                    <b-form-input class="custom-control-pasien" placeholder="[ Alamat ]"></b-form-input>
                  </b-col>
                </b-row>
                <b-row class="mb-2">
                  <b-col class="pr-1">
                    <b-form-input cols="4" class="custom-control-pasien" placeholder="[ RT ]"></b-form-input>
                  </b-col>
                  <b-col class="pr-1 pl-1">
                    <b-form-input cols="4" class="custom-control-pasien" placeholder="[ RW ]"></b-form-input>
                  </b-col>
                  <b-col class="pl-1">
                    <b-form-input cols="4" class="custom-control-pasien" placeholder="[ Kodepos ]"></b-form-input>
                  </b-col>
                </b-row>
                <b-row class="mb-2">
                  <b-col class="pr-1">
                    <multiselect v-model="selected.provinsi" :show-labels="false" :limit="3" track-by="deskripsi" label="deskripsi" placeholder="[ Provinsi ]" :options="combo.provinsi" @input="getvalkabupaten">
                    </multiselect>
                  </b-col>
                  <b-col class="pl-1">
                    <multiselect v-model="selected.kabupaten" :show-labels="false" :limit="3" track-by="deskripsi" label="deskripsi" placeholder="[ Kabupaten ]" :options="combo.kabupaten" @input="getvalkecamatan">
                    </multiselect>
                  </b-col>
                </b-row>
                <b-row class="mb-0">
                  <b-col class="pr-1">
                    <multiselect v-model="selected.kecamatan" :show-labels="false" :limit="3" track-by="deskripsi" label="deskripsi" placeholder="[ Kabupaten ]" :options="combo.kecamatan" @input="getvalkelurahan">
                    </multiselect>
                  </b-col>
                  <b-col class="pl-1">
                    <multiselect v-model="selected.kelurahan" :show-labels="false" :limit="3" track-by="deskripsi" label="deskripsi" placeholder="[ Desa ]" :options="combo.kelurahan">
                    </multiselect>
                  </b-col>
                </b-row>
              </template>
            </iq-card>
            <iq-card class="card-radius-custom border mb-0">
              <template v-slot:headerTitle>
                <h6 class="card-title mb-0"><i class="fas fa-id-card"></i> Kartu Identitas</h6>
              </template>
              <template v-slot:headerAction>
                <b-form-checkbox
                  id="checkbox-1"
                  v-model="checked.status"
                  name="checkbox-1"
                  value="1"
                  unchecked-value="0"
                >
                  <p class="mb-0">Sama dengan alamat sekarang</p>
                </b-form-checkbox>
              </template>
              <template v-slot:body>
                <b-row class="mb-2">
                  <b-col class="pr-1">
                    <multiselect v-model="selected.jeniskartu" :show-labels="false" :limit="3" track-by="deskripsi" label="deskripsi" placeholder="[ Jenis Kartu ]" :options="combo.jeniskartu">
                    </multiselect>
                  </b-col>
                  <b-col class="pl-1">
                    <b-form-input class="custom-control-pasien" placeholder="[ Nomor Kartu ]"></b-form-input>
                  </b-col>
                </b-row>
                <b-row class="mb-2">
                  <b-col>
                    <b-form-input class="custom-control-pasien" placeholder="[ Alamat ]"></b-form-input>
                  </b-col>
                </b-row>
                <b-row class="mb-2">
                  <b-col cols="4" class="pr-1">
                    <b-form-input class="custom-control-pasien" placeholder="[ RT ]"></b-form-input>
                  </b-col>
                  <b-col cols="4" class="pr-1 pl-1">
                    <b-form-input class="custom-control-pasien" placeholder="[ RW ]"></b-form-input>
                  </b-col>
                  <b-col cols="4" class="pl-1">
                    <b-form-input class="custom-control-pasien" placeholder="[ Kodepos ]"></b-form-input>
                  </b-col>
                </b-row>
                <b-row class="mb-2">
                  <b-col class="pr-1">
                    <multiselect v-model="selected.provinsi" :show-labels="false" :limit="3" track-by="deskripsi" label="deskripsi" placeholder="[ Provinsi ]" :options="combo.provinsi" @input="getvalkabupaten">
                    </multiselect>
                  </b-col>
                  <b-col class="pl-1">
                    <multiselect v-model="selected.kabupaten" :show-labels="false" :limit="3" track-by="deskripsi" label="deskripsi" placeholder="[ Kabupaten ]" :options="combo.kabupaten" @input="getvalkecamatan">
                    </multiselect>
                  </b-col>
                </b-row>
                <b-row class="mb-0">
                  <b-col class="pr-1">
                    <multiselect v-model="selected.kecamatan" :show-labels="false" :limit="3" track-by="deskripsi" label="deskripsi" placeholder="[ Kabupaten ]" :options="combo.kecamatan" @input="getvalkelurahan">
                    </multiselect>
                  </b-col>
                  <b-col class="pl-1">
                    <multiselect v-model="selected.kelurahan" :show-labels="false" :limit="3" track-by="deskripsi" label="deskripsi" placeholder="[ Desa ]" :options="combo.kelurahan">
                    </multiselect>
                  </b-col>
                </b-row>
              </template>
            </iq-card>
          </b-col>
          <b-col class="pl-1">
            <iq-card class="card-radius-custom border mb-2">
              <template v-slot:headerTitle>
                <h6 class="card-title mb-0"><i class="fas fa-phone-alt"></i> Kontak</h6>
              </template>
              <template v-slot:body>
                <b-row class="mb-2">
                  <b-col class="pr-1">
                    <multiselect v-model="selected.jeniskontak" :show-labels="false" :limit="3" track-by="deskripsi" label="deskripsi" placeholder="[ Jenis Kontak ]" :options="combo.jeniskontak">
                    </multiselect>
                  </b-col>
                  <b-col class="pl-1">
                    <b-form-input class="custom-control-pasien" placeholder="[ Nomor Telepon / Email / Faks ]"></b-form-input>
                  </b-col>
                </b-row>
              </template>
            </iq-card>
            <iq-card class="card-radius-custom border">
              <template v-slot:headerTitle>
                <h6 class="card-title mb-0"><i class="fas fa-users"></i> Keluarga</h6>
              </template>
              <template v-slot:body>
                <b-row class="mb-2">
                  <b-col cols="4" class="pr-1">
                    <multiselect v-model="selected.hubungankeluarga" :show-labels="false" :limit="3" track-by="deskripsi" label="deskripsi" placeholder="[ Hubungan Dengan Pasien ]" :options="combo.hubungankeluarga">
                    </multiselect>
                  </b-col>
                  <b-col class="pl-1">
                    <b-form-input class="custom-control-pasien" placeholder="[ Nama Keluarga Tanpa Gelar ]"></b-form-input>
                  </b-col>
                </b-row>
                <b-row class="mb-2">
                  <b-col cols="4" class="pr-1">
                    <multiselect v-model="selected.jeniskelamin" :show-labels="false" :limit="3" track-by="deskripsi" label="deskripsi" placeholder="[ Jenis Kelamin ]" :options="combo.jeniskelamin">
                    </multiselect>
                  </b-col>
                  <b-col cols="4" class="pl-1 pr-1">
                    <multiselect v-model="selected.pendidikan" :show-labels="false" :limit="3" track-by="deskripsi" label="deskripsi" placeholder="[ Pendidikan ]" :options="combo.pendidikan">
                    </multiselect>
                  </b-col>
                  <b-col cols="4" class="pl-1">
                    <multiselect v-model="selected.pekerjaan" :show-labels="false" :limit="3" track-by="deskripsi" label="deskripsi" placeholder="[ Pekerjaan ]" :options="combo.pekerjaan">
                    </multiselect>
                  </b-col>
                </b-row>
                <b-row class="mb-2">
                  <b-col>
                    <b-form-input class="custom-control-pasien" placeholder="[ Alamat ]"></b-form-input>
                  </b-col>
                </b-row>
                <b-row class="mb-0">
                  <b-col class="pr-1">
                    <multiselect v-model="selected.jeniskontak" :show-labels="false" :limit="3" track-by="deskripsi" label="deskripsi" placeholder="[ Jenis Kontak ]" :options="combo.jeniskontak">
                    </multiselect>
                  </b-col>
                  <b-col class="pl-1">
                    <b-form-input class="custom-control-pasien" placeholder="[ Nomor Telepon / Email / Faks ]"></b-form-input>
                  </b-col>
                </b-row>
              </template>
            </iq-card>
          </b-col>
        </b-row>
      </div>
    </b-container>
  </div>
</template>

<script>
import { xray } from '../../../config/pluginInit'
import Master from '../../../services/fatmahost/master/index'
import Multiselect from 'vue-multiselect'
import FlatPickr from 'vue-flatpickr-component'
import 'flatpickr/dist/flatpickr.css'

export default {
  name: 'ProfileEdit',
  components: {
    Multiselect,
    FlatPickr
  },
  data () {
    return {
      form: {
        data_identitas: {
          tempat_lahir: '',
          tanggal_lahir: ''
        }
      },
      message: {
        status: '',
        success: '',
        error: ''
      },
      combo: {
        tempatlahir: [],
        jeniskelamin: [],
        agama: [],
        pendidikan: [],
        pekerjaan: [],
        golongandarah: [],
        statusperkawinan: [],
        suku: [],
        status: [],
        negara: [],
        provinsi: [],
        kabupaten: [],
        kecamatan: [],
        kelurahan: [],
        jeniskartu: [],
        jeniskontak: [],
        hubungankeluarga: []
      },
      selected: {
        tempatlahir: [],
        jeniskelamin: [],
        agama: [],
        pendidikan: [],
        pekerjaan: [],
        golongandarah: [],
        statusperkawinan: [],
        suku: [],
        status: [],
        negara: [],
        provinsi: [],
        kabupaten: [],
        kecamatan: [],
        kelurahan: [],
        jeniskartu: [],
        jeniskontak: [],
        hubungankeluarga: []
      },
      checked: {
        status: ''
      },
      config: {
        disableMobile: true,
        maxDate: 'today'
      }
    }
  },
  mounted () {
    xray.index()
    this.gettempatlahir()
    this.getjk()
    this.getagama()
    this.getpendidikan()
    this.getpekerjaan()
    this.getgoldar()
    this.getstatuskawin()
    this.getjeniskartu()
    this.getkontak()
    this.getsuku()
    this.getstatus()
    this.gethubungan()
    this.getnegara()
    this.getprovinsi()
  },
  methods: {
    async gettempatlahir () {
      await Master.tempat_lahir().then(response => {
        this.combo.tempatlahir = response.data.data
        this.message.status = '1'
      }).catch(error => {
        this.message.error = error.response
        this.message.status = '0'
      })
    },
    async getjk () {
      await Master.referensi('2').then(response => {
        this.combo.jeniskelamin = response.data.data
        this.message.status = '1'
      }).catch(error => {
        this.message.error = error.response
        this.message.status = '0'
      })
    },
    async getagama () {
      await Master.referensi('1').then(response => {
        this.combo.agama = response.data.data
        this.message.status = '1'
      }).catch(error => {
        this.message.error = error.response
        this.message.status = '0'
      })
    },
    async getpendidikan () {
      await Master.referensi('3').then(response => {
        this.combo.pendidikan = response.data.data
        this.message.status = '1'
      }).catch(error => {
        this.message.error = error.response
        this.message.status = '0'
      })
    },
    async getpekerjaan () {
      await Master.referensi('4').then(response => {
        this.combo.pekerjaan = response.data.data
        this.message.status = '1'
      }).catch(error => {
        this.message.error = error.response
        this.message.status = '0'
      })
    },
    async getstatuskawin () {
      await Master.referensi('5').then(response => {
        this.combo.statusperkawinan = response.data.data
        this.message.status = '1'
      }).catch(error => {
        this.message.error = error.response
        this.message.status = '0'
      })
    },
    async getgoldar () {
      await Master.referensi('6').then(response => {
        this.combo.golongandarah = response.data.data
        this.message.status = '1'
      }).catch(error => {
        this.message.error = error.response
        this.message.status = '0'
      })
    },
    async getsuku () {
      await Master.referensi('140').then(response => {
        this.combo.suku = response.data.data
        this.message.status = '1'
      }).catch(error => {
        this.message.error = error.response
        this.message.status = '0'
      })
    },
    async getstatus () {
      await Master.referensi('13').then(response => {
        this.combo.status = response.data.data
        this.message.status = '1'
      }).catch(error => {
        this.message.error = error.response
        this.message.status = '0'
      })
    },
    async getnegara () {
      await Master.negara('').then(response => {
        this.combo.negara = response.data.data
        this.message.status = '1'
      }).catch(error => {
        this.message.error = error.response
        this.message.status = '0'
      })
    },
    async getjeniskartu () {
      await Master.referensi('9').then(response => {
        this.combo.jeniskartu = response.data.data
        this.message.status = '1'
      }).catch(error => {
        this.message.error = error.response
        this.message.status = '0'
      })
    },
    async getkontak () {
      await Master.referensi('8').then(response => {
        this.combo.jeniskontak = response.data.data
        this.message.status = '1'
      }).catch(error => {
        this.message.error = error.response
        this.message.status = '0'
      })
    },
    async gethubungan () {
      await Master.referensi('7').then(response => {
        this.combo.hubungankeluarga = response.data.data
        this.message.status = '1'
      }).catch(error => {
        this.message.error = error.response
        this.message.status = '0'
      })
    },
    async getprovinsi () {
      await Master.provinsi('1').then(response => {
        this.combo.provinsi = response.data.data
        this.message.status = '1'
      }).catch(error => {
        this.message.error = error.response
        this.message.status = '0'
      })
    },
    async getkabupaten (value) {
      const data = value.id
      await Master.kabupaten(data).then(response => {
        this.combo.kabupaten = response.data.data
        this.message.status = '1'
      }).catch(error => {
        this.message.error = error.response
        this.message.status = '0'
      })
    },
    async getkecamatan (value) {
      const data = value.id
      await Master.kecamatan(data).then(response => {
        this.combo.kecamatan = response.data.data
        this.message.status = '1'
      }).catch(error => {
        this.message.error = error.response
        this.message.status = '0'
      })
    },
    async getdesa (value) {
      const data = value.id
      await Master.desa(data).then(response => {
        this.combo.kelurahan = response.data.data
        this.message.status = '1'
      }).catch(error => {
        this.message.error = error.response
        this.message.status = '0'
      })
    },
    getvalkabupaten (param) {
      this.getkabupaten(param)
    },
    getvalkecamatan (param) {
      this.getkecamatan(param)
    },
    getvalkelurahan (param) {
      this.getdesa(param)
    }
  }
}
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style>
#pasien-form .custom-control-pasien {
  color: rgb(31, 31, 31)!important;
  font-weight: normal!important;
  border-radius: 5px!important;
  font-size: 12px!important;
  height: 38px!important;
}
#pasien-form .card-radius-custom {
  border-radius: 10px;
}
#pasien-form .multiselect__single {
  font-size: 12px!important;
  border-radius: 5px!important;
}
#pasien-form .multiselect__placeholder {
  border-radius: 5px!important;
  font-size: 12px!important;
  padding-left: 5px;
  font-weight: normal
}
#pasien-form .multiselect__option {
  border-radius: 5px!important;
  font-size: 12px;
}
#pasien-form .multiselect__tags {
  border-radius: 5px!important;
  padding: 5px 40px 0 8px!important;
  height: 38px!important;
  min-height: 38px!important;
}
#pasien-form .flatpickr-cstm-lab {
  min-height: 38px!important;
  height: 38px!important;
  line-height: 38px!important;
  border: 1px solid rgba(145, 145, 145, 0.301);
  border-radius: 5px!important;
  padding-left: 10px!important;
  padding-right: 10px!important;
  color: #000000!important;
  font-weight: normal!important;
  font-size: 12px;
}
#pasien-form .iq-card .iq-card-header {
    min-height: 45px;
}
#pasien-form .iq-card-body {
    padding: 10px;
}
</style>
