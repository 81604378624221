<template>
  <div id="master-ruangan">
    <b-container fluid class="mb-2">
      <iq-card>
        <div class="px-4 py-2 d-flex align-items-center justify-content-between">
          <div>
            <h5 class="font-weight-bold mb-0">Master Ruangan</h5>
          </div>
          <div>
            <b-button variant="primary" @click="showDataItem('create')"><i class="fas fa-plus"></i> Tambah Data</b-button>
          </div>
        </div>
        <hr class="m-0 p-0">
        <div class="body p-4">
          <div class="table mb-0">
              <b-table
                class="mb-0"
                ref="table"
                :busy="table.isBusy"
                :items="table.items"
                :fields="table.fields"
                :current-page="table.currentPage"
                :per-page="table.perPage"
                :filter="table.filter"
                @filtered="onFiltered"
                :sort-by.sync="table.sortBy"
                :sort-desc.sync="table.sortDesc"
                stacked="md"
                bordered hover show-empty small striped>
                <template #cell(index)="data">
                  {{ (table.currentPage*table.perPage)-table.perPage + data.index + 1 }}
                </template>
                <template #cell(updated_at)="data">
                  {{ data.item.updated_at | convertToDMY }}
                </template>
                <template #cell(ksm_fatmawati)="data">
                  <span v-if="data.item.ksm_fatmawati">{{ data.item.ksm_fatmawati }}</span>
                  <span v-else>-</span>
                </template>
                <template #cell(action)="data">
                  <b-button variant="white" class="my-1 mx-1" size="md" @click="showDataItem('update', data.item.id)">
                    <i class="fas fa-edit text-primary"></i>
                  </b-button>
                  <b-button variant="white" class="my-1 mx-1" size="md" @click="showDataItem('delete', data.item.id)">
                    <i class="fas fa-trash-alt text-danger"></i>
                  </b-button>
                </template>
              </b-table>
            </div>
            <div class="bg-white pb-2 pt-2 pl-3 pr-3 border-bottom border-right border-left">
              <b-row class="align-items-center justify-content-between">
                <b-col cols="12" lg="3" class="mr-auto">
                  <div class="d-flex align-items-center">
                    <div>
                      <b-pagination v-model="table.currentPage" :total-rows="table.totalRows" :per-page="table.perPage" size="sm" class="my-0">
                      </b-pagination>
                    </div>
                    <div class="border-left border-right px-3">
                      <span class="cursor-point" @click="tableRefresh">
                        <i class="fas fa-sync"></i>
                      </span>
                    </div>
                  </div>
                </b-col>
                <b-col cols="12" lg="5">
                  <div class="d-flex flex-row">
                    <div class="mr-2 w-75">
                      <b-input-group>
                        <b-form-input class="cstm-slct-mst-ruang rounded-0" id="filter-input" v-model="table.filter" type="search" placeholder="[ Pencarian ]"></b-form-input>
                      </b-input-group>
                    </div>
                    <div>
                      <b-form-select class="cstm-slct-mst-ruang" v-model="table.selected.filterType" :options="table.combo.filterType" @change="filterchange"></b-form-select>
                    </div>
                  </div>
                </b-col>
                <b-col cols="12" lg="">
                  <div class="d-flex flex-row align-items-center justify-content-end">
                    <div class="mr-3">
                      <b-form-select id="per-page-select" class="custom-perpage" v-model="table.perPage" :options="table.pageOptions" size="sm"
                      ></b-form-select>
                    </div>
                    <div>
                      <p class="font-weight-normal text-right mb-0">Menampilkan 1 - {{ table.perPage }} dari {{ table.totalRows }}</p>
                    </div>
                  </div>
                </b-col>
              </b-row>
            </div>
        </div>
      </iq-card>
    </b-container>
    <div id="modal-component">
      <b-modal ref="modal-data" id="modal-data" size="lg" :title="modalConfig.title" ok-title="Simpan" @ok="submitData" @cancel="clearForm" @close="clearForm" scrollable :no-close-on-backdrop="true" centered>
        <b-form>
          <b-row>
            <b-col cols="12">
              <div class="mb-2">
                <b-row class="align-items-center">
                  <b-col cols="4">
                    <p class="txt-module-base text-dark mb-0">ID :</p>
                  </b-col>
                  <b-col>
                    <b-form-input :disabled="other.visible_id === '1' ? true : false" v-model="modelRuangan.id" :class="['cstm-cntrl-module', other.visible_id === '1' ? 'bg-disabled-form' : 'bg-white']" size="sm" placeholder="[ Masukan Data ]"></b-form-input>
                  </b-col>
                </b-row>
              </div>
              <div class="mb-2">
                <b-row class="align-items-center">
                  <b-col cols="4">
                    <p class="txt-module-base text-dark mb-0">Deskripsi :</p>
                  </b-col>
                  <b-col>
                    <b-form-input v-model="modelRuangan.deskripsi" class="cstm-cntrl-module" size="sm" placeholder="[ Masukan Data ]"></b-form-input>
                  </b-col>
                </b-row>
              </div>
              <div class="mb-2">
                <b-row class="align-items-center">
                  <b-col cols="4">
                    <p class="txt-module-base text-dark mb-0">Jenis :</p>
                  </b-col>
                  <b-col>
                    <b-form-input v-model="modelRuangan.jenis" class="cstm-cntrl-module" size="sm" placeholder="[ Masukan Data ]"></b-form-input>
                  </b-col>
                </b-row>
              </div>
              <div class="mb-2">
                <b-row class="align-items-center">
                  <b-col cols="4">
                    <p class="txt-module-base text-dark mb-0">Jenis Kunjungan :</p>
                  </b-col>
                  <b-col>
                    <b-form-input v-model="modelRuangan.jenis_kunjungan" class="cstm-cntrl-module" size="sm" placeholder="[ Masukan Data ]"></b-form-input>
                  </b-col>
                </b-row>
              </div>
              <div class="mb-0">
                <b-row class="align-items-center">
                  <b-col cols="4">
                    <p class="txt-module-base text-dark mb-0">Ref ID :</p>
                  </b-col>
                  <b-col>
                    <b-form-input v-model="modelRuangan.ref_id" class="cstm-cntrl-module" size="sm" placeholder="[ Masukan Data ]"></b-form-input>
                  </b-col>
                </b-row>
              </div>
            </b-col>
            <b-col lg="12">
              <hr>
            </b-col>
            <b-col lg="12">
              <div class="mb-2">
                <b-row class="align-items-center">
                  <b-col cols="4">
                    <p class="txt-module-base text-dark mb-0">Setting KSM :</p>
                  </b-col>
                  <b-col>
                    <div id="select-ksm">
                      <multiselect :allow-empty="false" v-model="selected.pegawai" label="ksm_fatmawati" :show-labels="false" placeholder="[ KSM ]" :options="combo.pegawai" @input="valueKsm" :searchable="true">
                      </multiselect>
                    </div>
                  </b-col>
                </b-row>
              </div>
              <div class="mb-2">
                <b-row class="align-items-center">
                  <b-col cols="4">
                    <p class="txt-module-base text-dark mb-0">Setting inv :</p>
                  </b-col>
                  <b-col>
                    <!-- <b-form-input v-model="modelRuangan.setting_inv" class="cstm-cntrl-module" size="sm" placeholder="[ Masukan Data ]"></b-form-input> -->
                    <b-form-checkbox id="checkbox-1" v-model="modelRuangan.setting_inv" name="checkbox-1" value="1" unchecked-value="0">
                      <span v-if="modelRuangan.setting_inv === '1'">Ya</span>
                      <span v-else>Tidak</span>
                    </b-form-checkbox>
                  </b-col>
                </b-row>
              </div>
              <div class="mb-2">
                <b-row class="align-items-center">
                  <b-col cols="4">
                    <p class="txt-module-base text-dark mb-0">Setting inv24jam :</p>
                  </b-col>
                  <b-col>
                    <!-- <b-form-input v-model="modelRuangan.setting_inv24jam" class="cstm-cntrl-module" size="sm" placeholder="[ Masukan Data ]"></b-form-input> -->
                    <b-form-checkbox id="checkbox-2" v-model="modelRuangan.setting_inv24jam" name="checkbox-2" value="1" unchecked-value="0">
                      <span v-if="modelRuangan.setting_inv24jam === '1'">Ya</span>
                      <span v-else>Tidak</span>
                    </b-form-checkbox>
                  </b-col>
                </b-row>
              </div>
              <div class="mb-2">
                <b-row class="align-items-center">
                  <b-col cols="4">
                    <p class="txt-module-base text-dark mb-0">Setting invjual :</p>
                  </b-col>
                  <b-col>
                    <!-- <b-form-input v-model="modelRuangan.setting_invjual" class="cstm-cntrl-module" size="sm" placeholder="[ Masukan Data ]"></b-form-input> -->
                    <b-form-checkbox id="checkbox-3" v-model="modelRuangan.setting_invjual" name="checkbox-3" value="1" unchecked-value="0">
                      <span v-if="modelRuangan.setting_invjual === '1'">Ya</span>
                      <span v-else>Tidak</span>
                    </b-form-checkbox>
                  </b-col>
                </b-row>
              </div>
              <div class="mb-2">
                <b-row class="align-items-center">
                  <b-col cols="4">
                    <p class="txt-module-base text-dark mb-0">Setting invminus :</p>
                  </b-col>
                  <b-col>
                    <!-- <b-form-input v-model="modelRuangan.setting_invminus" class="cstm-cntrl-module" size="sm" placeholder="[ Masukan Data ]"></b-form-input> -->
                    <b-form-checkbox id="checkbox-4" v-model="modelRuangan.setting_invminus" name="checkbox-4" value="1" unchecked-value="0">
                      <span v-if="modelRuangan.setting_invminus === '1'">Ya</span>
                      <span v-else>Tidak</span>
                    </b-form-checkbox>
                  </b-col>
                </b-row>
              </div>
              <div class="mb-2">
                <b-row class="align-items-center">
                  <b-col cols="4">
                    <p class="txt-module-base text-dark mb-0">Setting reservasi :</p>
                  </b-col>
                  <b-col>
                    <!-- <b-form-input v-model="modelRuangan.setting_reservasi" class="cstm-cntrl-module" size="sm" placeholder="[ Masukan Data ]"></b-form-input> -->
                    <b-form-checkbox id="checkbox-5" v-model="modelRuangan.setting_reservasi" name="checkbox-5" value="1" unchecked-value="0">
                      <span v-if="modelRuangan.setting_reservasi === '1'">Ya</span>
                      <span v-else>Tidak</span>
                    </b-form-checkbox>
                  </b-col>
                </b-row>
              </div>
              <div class="mb-2">
                <b-row class="align-items-center">
                  <b-col cols="4">
                    <p class="txt-module-base text-dark mb-0">Setting reservasi meningitis :</p>
                  </b-col>
                  <b-col>
                    <!-- <b-form-input v-model="modelRuangan.setting_reservasi_meningitis" class="cstm-cntrl-module" size="sm" placeholder="[ Masukan Data ]"></b-form-input> -->
                    <b-form-checkbox id="checkbox-6" v-model="modelRuangan.setting_reservasi_meningitis" name="checkbox-6" value="1" unchecked-value="0">
                      <span v-if="modelRuangan.setting_reservasi_meningitis === '1'">Ya</span>
                      <span v-else>Tidak</span>
                    </b-form-checkbox>
                  </b-col>
                </b-row>
              </div>
              <div class="mb-2">
                <b-row class="align-items-center">
                  <b-col cols="4">
                    <p class="txt-module-base text-dark mb-0">Status :</p>
                  </b-col>
                  <b-col>
                    <!-- <b-form-input v-model="modelRuangan.status" class="cstm-cntrl-module" size="sm" placeholder="[ Masukan Data ]"></b-form-input> -->
                    <b-form-checkbox id="checkbox-8" v-model="modelRuangan.status" name="checkbox-8" value="1" unchecked-value="0">
                      <span v-if="modelRuangan.status === '1'">Aktif</span>
                      <span v-else>Tidak Aktif</span>
                    </b-form-checkbox>
                  </b-col>
                </b-row>
              </div>
              <div class="mb-0">
                <b-row class="align-items-center">
                  <b-col cols="4">
                    <p class="txt-module-base text-dark mb-0">Jenis Counter :</p>
                  </b-col>
                  <b-col>
                    <div id="select-counter">
                      <multiselect :allow-empty="false" v-model="selected.counter" label="text" :show-labels="false" placeholder="[ Jenis Counter ]" :options="combo.counter" @input="valueCounter" :searchable="true">
                      </multiselect>
                    </div>
                  </b-col>
                </b-row>
              </div>
            </b-col>
          </b-row>
        </b-form>
        <!-- <pre>{{ modelRuangan }}</pre> -->
        <div class="bg-dark">
          <p class="small text-right text-white px-2 mt-2 mb-0" v-if="other.message !== ''">{{ other.message }}</p>
        </div>
      </b-modal>
      <ModalMsgbox ref="MsgBox" />
    </div>
  </div>
</template>
<script>
import { xray } from '../../../config/pluginInit'
import Service from '../../../services/fatmahost'
import ModalMsgbox from '../../../components/modal/ModalMsgBox'
import Multiselect from 'vue-multiselect'

export default {
  name: 'ModuleList',
  components: {
    Multiselect,
    ModalMsgbox
  },
  mounted () {
    xray.index()
    this.getPegawaiKsm()
  },
  data () {
    return {
      user: xray.currentUser(),
      table: {
        isBusy: false,
        items: this.list,
        sortBy: '',
        sortDesc: true,
        fields: [
          { label: 'No', key: 'index', class: 'text-center' },
          { label: 'ID', key: 'id' },
          { label: 'NAMA', key: 'mr.deskripsi', sortable: true },
          { label: 'KSM Setting', key: 'ksm_fatmawati', sortable: true },
          { label: 'JENIS', key: 'jenis', sortable: true },
          { label: 'JENIS KUNJUNGAN', key: 'jenis_kunjungan', sortable: true },
          { label: 'REF ID', key: 'ref_id', sortable: true },
          { label: 'Aksi', key: 'action', class: 'text-center' }
        ],
        currentPage: 1,
        perPage: 10,
        totalRows: null,
        pageOptions: [10, 25, 50, 100],
        filter: '',
        selected: {
          filterType: '1'
        },
        combo: {
          filterType: [
            { value: '1', text: 'Nama' }
          ]
        }
      },
      modalConfig: {
        type: '',
        title: ''
      },
      modelRuangan: {
        object: 'ruangan',
        id: '',
        jenis: '',
        jenis_kunjungan: '',
        ref_id: '',
        deskripsi: '',
        status: '0',
        setting_inv: '0',
        setting_invminus: '0',
        setting_inv24jam: '0',
        setting_invjual: '0',
        setting_reservasi: '0',
        setting_reservasi_meningitis: '0',
        setting_ksm: '0',
        setting_counter: ''
      },
      combo: {
        pegawai: [],
        counter: [
          { id: '1', text: 'Poli' },
          { id: '2', text: 'Dokter' }
        ]
      },
      selected: {
        pegawai: [],
        counter: []
      },
      other: {
        visible_id: '0',
        message: ''
      }
    }
  },
  methods: {
    tableRefresh () {
      this.$refs.table.refresh()
    },
    async list (ctx) {
      this.isBusy = true
      let sortfield = ''
      let sorting = ''
      let filterType = this.table.selected.filterType
      let filters = ''
      if (ctx.filter === '') {
        ctx.filter = null
      }
      if (ctx.sortBy) {
        sortfield = ctx.sortBy
      } else {
        sortfield = ''
      }
      if (ctx.sortDesc === true) {
        sorting = 'DESC'
      } else if (ctx.sortDesc === false) {
        sorting = 'ASC'
      } else {
        sorting = ''
      }
      if (ctx.filter === null) {
        filters = `param3={"xparam0":"","xparam1":"","xparam2":"","xparam3":"","xparam4":""}&param4={"xparam0":"${sortfield}","xparam1":"${sorting}"}`
      } else {
        if (filterType === '1') {
          filters = `param3={"xparam0":"${ctx.filter}","xparam1":"","xparam2":"","xparam3":"","xparam4":""}&param4={"xparam0":"${sortfield}","xparam1":"${sorting}"}`
        }
      }
      try {
        const response = await Service.ruangan.findAll(ctx.currentPage - 1, ctx.perPage, filters)
        this.table.currentPage = response.data.pagination.current
        this.table.perPage = response.data.pagination.perPage
        this.table.totalRows = response.data.pagination.total
        this.isBusy = false
        return response.data.data
      } catch (error) {
        this.isBusy = false
        return []
      }
    },
    onFiltered (filteredItems) {
      this.table.totalRows = filteredItems.length
      // this.table.currentPage = 1
    },
    filterchange () {
      this.table.filter = ''
    },
    async getPegawaiKsm () {
      try {
        const response = await Service.pegawai.selectizePegawaiKsmGrup()
        this.combo.pegawai = response.data.data
      } catch (error) {
        this.other.message = error.message
      }
    },
    valueKsm (value) {
      this.modelRuangan.setting_ksm = value.id.toString()
    },
    valueCounter (value) {
      this.modelRuangan.setting_counter = value.id.toString()
    },
    showDataItem (event, param) {
      this.clearForm()
      if (event === 'create') {
        this.other.visible_id = '0'
        this.modalConfig.type = event
        this.modalConfig.title = 'Tambah Ruangan'
        this.$refs['modal-data'].show()
      } else if (event === 'update') {
        this.other.visible_id = '1'
        this.modalConfig.type = event
        this.modalConfig.title = 'Edit Ruangan'
        this.showData(param)
        this.$refs['modal-data'].show()
      } else if (event === 'delete') {
        this.other.visible_id = '0'
        this.$bvModal.msgBoxConfirm('Apakah Anda Yakin Ingin Menghapus Data?', {
          title: 'Konfirmasi Hapus Ruangan',
          size: 'md',
          buttonSize: 'md',
          okVariant: 'danger',
          okTitle: 'Ya',
          cancelTitle: 'Batal',
          footerClass: 'p-2',
          hideHeaderClose: true,
          centered: true
        }).then((value) => {
          if (value === true) {
            this.deleteData(param)
          }
        })
      }
    },
    submitData (bvModalEvent) {
      bvModalEvent.preventDefault()
      if (this.modalConfig.type === 'create') {
        this.createData()
      } else if (this.modalConfig.type === 'update') {
        this.updateData()
      }
    },
    async createData () {
      await Service.ruangan.create(this.modelRuangan).then(response => {
        if (response.data.statcode === 0) {
          this.$refs['modal-data'].hide()
          this.$refs.MsgBox.show({
            type: 'success',
            msg: response.data.message
          })
          this.clearForm()
        } else {
          this.other.message = response.data.message
        }
      }).catch(error => {
        this.other.message = error.message
      }).finally(() => {
        this.$refs.table.refresh()
      })
    },
    async updateData () {
      await Service.ruangan.update(this.modelRuangan.id, this.modelRuangan).then(response => {
        if (response.data.statcode === 0) {
          this.$refs['modal-data'].hide()
          this.$refs.MsgBox.show({
            type: 'success',
            msg: response.data.message
          })
          this.clearForm()
        } else {
          this.other.message = response.data.message
        }
      }).catch(error => {
        this.other.message = error.message
      }).finally(() => {
        this.$refs.table.refresh()
      })
    },
    async deleteData (id) {
      await Service.ruangan.delete(id).then(response => {
        if (response.data.statcode === 0) {
          this.$refs.MsgBox.show({
            type: 'success',
            msg: response.data.message
          })
        } else {
          this.$refs.MsgBox.show({
            type: 'error',
            msg: response.data.message
          })
        }
      }).catch(error => {
        this.$refs.MsgBox.show({
          type: 'error',
          msg: error.message
        })
      }).finally(() => {
        this.$refs.table.refresh()
      })
    },
    async showData (id) {
      await Service.ruangan.findId(id).then(response => {
        if (response.data.statcode === 0) {
          this.modelRuangan.id = response.data.data[0].id
          this.modelRuangan.jenis = response.data.data[0].jenis !== null ? response.data.data[0].jenis.toString() : ''
          this.modelRuangan.jenis_kunjungan = response.data.data[0].jenis_kunjungan !== null ? response.data.data[0].jenis_kunjungan.toString() : ''
          this.modelRuangan.ref_id = response.data.data[0].ref_id !== null ? response.data.data[0].ref_id.toString() : ''
          this.modelRuangan.deskripsi = response.data.data[0].deskripsi
          this.modelRuangan.status = response.data.data[0].status !== null ? response.data.data[0].status.toString() : ''
          this.modelRuangan.setting_inv = response.data.data[0].setting_inv !== null ? response.data.data[0].setting_inv.toString() : ''
          this.modelRuangan.setting_invminus = response.data.data[0].setting_invminus !== null ? response.data.data[0].setting_invminus.toString() : ''
          this.modelRuangan.setting_inv24jam = response.data.data[0].setting_inv24jam !== null ? response.data.data[0].setting_inv24jam.toString() : ''
          this.modelRuangan.setting_invjual = response.data.data[0].setting_invjual !== null ? response.data.data[0].setting_invjual.toString() : ''
          this.modelRuangan.setting_reservasi = response.data.data[0].setting_reservasi !== null ? response.data.data[0].setting_reservasi.toString() : ''
          this.modelRuangan.setting_reservasi_meningitis = response.data.data[0].setting_reservasi_meningitis !== null ? response.data.data[0].setting_reservasi_meningitis.toString() : ''
          this.modelRuangan.setting_ksm = response.data.data[0].setting_ksm !== null ? response.data.data[0].setting_ksm.toString() : ''
          this.modelRuangan.setting_counter = response.data.data[0].setting_counter !== null ? response.data.data[0].setting_counter.toString() : ''
          this.selected.pegawai = response.data.data[0].setting_ksm !== null ? { id: response.data.data[0].setting_ksm, ksm_fatmawati: response.data.data[0].ksm_fatmawati } : []
          this.selected.counter = response.data.data[0].setting_counter !== null ? { id: response.data.data[0].setting_counter.toString(), text: this.responseCounter(response.data.data[0].setting_counter) } : []
        } else {
          this.other.message = response.data.message
        }
        this.$refs.table.refresh()
      }).catch((error) => {
        this.other.message = error.message
        this.$refs.table.refresh()
      })
    },
    responseCounter (value) {
      if (value === 1) {
        return 'Poli'
      } else if (value === 2) {
        return 'Dokter'
      } else {
        return '[ Jenis Counter ]'
      }
    },
    clearForm () {
      this.modelRuangan.id = ''
      this.modelRuangan.jenis = ''
      this.modelRuangan.jenis_kunjungan = ''
      this.modelRuangan.ref_id = ''
      this.modelRuangan.deskripsi = ''
      this.modelRuangan.status = '0'
      this.modelRuangan.setting_inv = '0'
      this.modelRuangan.setting_invminus = '0'
      this.modelRuangan.setting_inv24jam = '0'
      this.modelRuangan.setting_invjual = '0'
      this.modelRuangan.setting_reservasi = '0'
      this.modelRuangan.setting_reservasi_meningitis = '0'
      this.modelRuangan.setting_ksm = '0'
      this.modelRuangan.setting_counter = ''
      this.selected.pegawai = []
      this.selected.counter = []
      this.other.message = ''
    }
  }
}
</script>
<style>
  #modal-data .modal-body {
    background: #3399331b
  }

  #modal-data .modal-header {
    background: #3399333a;
  }

  #modal-data .modal-footer {
    background: #3399333a;
  }
  #master-ruangan .cstm-cntrl-module, #modal-data .cstm-cntrl-module {
    color: black;
    background: white;
    font-weight: normal!important;
  }
  #modal-data .bg-disabled-form {
    background: #e9ecef;
  }
  #master-ruangan .fc-unthemed .fc-toolbar .fc-button {
      margin: 3px;
  }
  #master-ruangan tr:first-child > td > .fc-day-grid-event {
      cursor: pointer;
  }
  td.fc-day.fc-past {
    background-color: #EEEEEE;
  }
  #master-ruangan .multiselect__single {
    font-size: 12px!important;
  }
  #master-ruangan .multiselect__placeholder {
    font-size: 12px!important;
  }
  #master-ruangan .multiselect__option {
    font-size: 12px;
  }
  #master-ruangan .multiselect__tags {
    padding: 5px 40px 0 8px!important;
    height: 38px!important;
    min-height: 38px!important;
  }
  #master-ruangan .flex-half-width-left {
    width: 76%;
  }
  #master-ruangan .flex-half-width-right {
    width: 23%;
  }
  #master-ruangan .flex-full-width-left {
    width: 96%;
  }
  #master-ruangan .flex-full-width-right {
    width: 3%;
  }
  #master-ruangan .txt-module-base, #modal-data .txt-module-base {
    font-size: 12px;
  }
  #master-ruangan .multiselect__placeholder, #modal-data .multiselect__placeholder {
    margin-bottom: 5px;
  }
  #master-ruangan .multiselect__tags, #modal-data .multiselect__tags {
    font-size: 12px;
    font-weight: normal!important;
  }
  #master-ruangan .multiselect__content-wrapper, #modal-data .multiselect__content-wrapper {
    font-weight: normal!important;
    font-size: 12px;
  }
  #select-ksm .multiselect__input, .multiselect__single, #modal-data .multiselect__input, .multiselect__single {
    font-size: 12px!important;
    font-weight: normal!important;
  }
  #master-ruangan .txt-module-base-head {
    font-size: 14px;
  }
  #master-ruangan .table td {
      font-size: 12px;
      font-weight: 500;
      padding-top: 0px;
      padding-bottom: 0px;
  }
  #master-ruangan .table th {
      font-size: 13px;
      font-weight: 400;
  }
  #master-ruangan .form-control {
      border: 1px solid rgb(224, 224, 224);
      background: white;
      height: 40px;
  }
  #master-ruangan .multiselect__tags {
      border-radius: 0px;
      border: 1px solid rgb(224, 224, 224);
      background: white;
      font-size: 11px;
      font-weight: 550;
      height: 10px;
  }
  #master-ruangan .btn-height-custom {
      height: 39px;
  }
  #master-ruangan .cursor-point {
      cursor: pointer;
  }
  #master-ruangan .scroll-y {
      overflow-y: scroll;
      height: 550px;
  }
  #master-ruangan .bg-abu {
    background: rgba(236, 236, 236, 0.541);
  }
  #master-ruangan .form-control {
      font-size: 11px;
      font-weight: 550;
  }
  #master-ruangan .b-table-sticky-header, #master-ruangan .table-responsive {
      margin-bottom: 0px;
  }
  #master-ruangan .table-barang .btn {
      font-size: 11px;
  }
  #master-ruangan .page-link {
      border: none;
      color: gray!important;
      margin-right: 10px!important;
  }
  #master-ruangan .page-item.active .page-link {
      background-color: transparent;
      border-color: transparent;
      color: #089bab!important;
      font-weight: bold;
  }
  #master-ruangan .vertical-txt {
    writing-mode: vertical-rl;
    letter-spacing: 10px;
  }
  #master-ruangan .transition-cstm {
    transition: .3s linear;
  }
  .cstm-cntrl-module {
    font-weight: bold!important;
    border-radius: 5px!important;
    font-size: 13px!important;
    height: 38px!important;
    color: black!important;
  }
  #master-ruangan .modal-dialog {
    min-width: 65%!important;
  }
  #master-ruangan .cstm-slct-mst-ruang {
    font-size: 13px!important;
    font-weight: normal!important;
    height: 38px!important;
    border-radius: 5px;
  }
  #master-ruangan .custom-perpage {
    height: 35px;
  }
</style>
