<template>
  <div id="bpjs-integrasi-vclaim">
    <b-card no-body class="p-0 m-0">
      <b-tabs pills card>
        <b-tab active>
          <template #title>
            <span>Data Kunjungan</span>
          </template>
          <div class="p-2">
            <Kunjunganbpjs />
          </div>
        </b-tab>
        <b-tab>
          <template #title>
            <span>Data Klaim</span>
          </template>
          <div class="p-2">
            <Klaimbpjs />
          </div>
        </b-tab>
        <b-tab>
          <template #title>
            <span>Data History Pelayanan Peserta</span>
          </template>
          <div class="p-2">
          </div>
        </b-tab>
        <b-tab>
          <template #title>
            <span>Data Klaim Jaminan Jasa Rahaja</span>
          </template>
          <div class="p-2">
          </div>
        </b-tab>
      </b-tabs>
    </b-card>
  </div>
</template>
<script>
import { xray } from '../../../../config/pluginInit'
import Kunjunganbpjs from '../ComponentSub/Monitoring/kunjunganbpjs.vue'
import Klaimbpjs from '../ComponentSub/Monitoring/klaimbpjs.vue'

export default {
  name: 'Anamnesis',
  components: {
    Kunjunganbpjs,
    Klaimbpjs
  },
  mounted () {
    xray.index()
  }
}
</script>
