<template>
  <div id="bios-rekening" class="mb-4">
    <b-container fluid>
      <div>
        <iq-card>
          <template v-slot:body>
            <div class="filter-brand border p-2">
              <b-row class="align-items-center">
                <b-col>
                  <div>
                    <h5 class="card-title font-weight-bold mb-0">Master Rekening</h5>
                  </div>
                </b-col>
                <b-col>
                  <div class="text-right">
                    <b-button class="custom-button-radius" size="sm" variant="primary" id="show-btn" @click="showModal"><i
                        class="fas fa-plus"></i>Tambah</b-button>
                  </div>
                </b-col>
              </b-row>
            </div>
            <div class="table mb-0">
              <b-table
                class="mb-0 pointer-table"
                ref="table"
                :sort-by.sync="table.sortBy"
                :filter="table.filter"
                @filtered="onFiltered"
                @row-clicked="openDetail"
                :sort-desc.sync="table.sortDesc"
                :busy="table.isBusy"
                :current-page="table.currentPage"
                :per-page="table.perPage"
                stacked="md" bordered hover show-empty striped
                :items="table.items"
                :fields="table.fields">
                <template #cell(index)="data">
                  <div class="py-2">
                    {{ (table.currentPage*table.perPage)-table.perPage + data.index + 1 }}
                  </div>
                </template>
                <template #cell(status)="data">
                  <span class="text-success" v-if="data.item.status === 1 || data.item.status === '1'">Aktif</span>
                  <span class="text-danger" v-else >Tidak Aktif </span>
                </template>
                <template #cell(action)="data">
                  <b-button variant="white" class="my-1 mx-1" size="md" @click="editModal(data.item.id)">
                    <i class="fas fa-edit text-primary"></i>
                  </b-button>
                </template>
              </b-table>
            </div>
            <div class="pagination-rekening-bios border shadow-sm p-2 mb-2">
              <b-row class="align-items-center">
                <b-col cols="4" class="d-flex flex-row align-items-center">
                  <div class="w-100">
                    <b-pagination v-model="table.currentPage" :total-rows="table.totalRows" :per-page="table.perPage"
                      size="md" class="my-0"></b-pagination>
                  </div>
                  <div class="w-100">
                    <div>
                      <span class="border-left border-dark mr-3 py-1"></span>
                      <span @click="tableRefresh" class="katalog-cursor">
                        <i class="fas fa-sync text-secondary"></i>
                      </span>
                      <span class="border-right border-dark ml-3 py-1"></span>
                    </div>
                  </div>
                </b-col>
                <b-col cols="5">
                  <div class="d-flex flex-row justify-content-end align-items-center">
                    <div class="mr-1 w-75">
                      <form class="searchbox mb-0">
                        <input v-model="table.filter" type="search" class="form-control cstm-srch-brand" id="filter-input"
                          placeholder="[Cari]">
                      </form>
                    </div>
                    <div>
                      <b-form-select class="cstm-slct-brand" v-model="selected.filter" :options="options.optionsFilter"
                        @change="filterchange"></b-form-select>
                    </div>
                  </div>
                </b-col>
                <b-col cols="3">
                  <div class="d-flex flex-row align-items-center justify-content-end">
                    <div class="mr-3">
                      <b-form-select
                        class="cstm-select-rekening"
                        id="per-page-select"
                        v-model="table.perPage"
                        :options="table.pageOptions"
                      ></b-form-select>
                    </div>
                    <div>
                      <p class="text-dark mb-0">Menampilkan {{ table.perPage }} dari {{ table.totalRows }} baris</p>
                    </div>
                  </div>
                </b-col>
              </b-row>
            </div>
          </template>
        </iq-card>
      </div>
    </b-container>
    <b-modal id="modal-tambah-rekening-bios" ref="modal-tambah-rekening-bios" size="xl" centered :no-close-on-backdrop="true" :static="true"
      title="Tambah Data Master Rekening" ok-title="Simpan Data" @close="clearData" @cancel="clearData"
      cancel-title="Tutup" @ok="tambahSave">
      <hr class="m-0">
      <div>
        <b-row>
          <b-col cols="12">
            <div class="table-responsive-xl">
              <table class="table bg-white table-bordered table-sm m-0 p-0">
                <thead>
                  <tr>
                    <th scope="col" style="vertical-align: center;" class="text-center">
                      <p class="mb-0 font-weight-normal text-center">Nomor</p>
                    </th>
                    <th scope="col" style="vertical-align: center;" class="text-center">
                      <p class="mb-0 font-weight-normal text-center">Nama Bank</p>
                    </th>
                    <th scope="col" style="vertical-align: center;" class="text-center">
                      <p class="mb-0 font-weight-normal text-center">Jenis Rekening</p>
                    </th>
                     <th scope="col" style="vertical-align: center;" class="text-center">
                      <p class="mb-0 font-weight-normal text-center">Nomor Rekening</p>
                    </th>
                     <th scope="col" style="vertical-align: center;" class="text-center">
                      <p class="mb-0 font-weight-normal text-center">Nama Rekening</p>
                    </th>
                     <th scope="col" style="vertical-align: center;" class="text-center">
                      <p class="mb-0 font-weight-normal text-center">Nomor Surat Izin</p>
                    </th>
                    <th scope="col" style="vertical-align: center;" class="text-center">
                      <b-button variant="primary" @click="addRow">
                        <p class="h5 mb-0">+</p>
                      </b-button>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(k, index) in tambahTransaksi.detail" v-bind:key="k.index">
                    <td class="py-3">
                      <div>
                        <p class="text-center font-weight-bold mb-0">{{ index + 1 }}</p>
                      </div>
                    </td>
                    <td class="col-3">
                      <div>
                        <div class="w-100">
                          <multiselect :show-labels="false" :custom-label="selectLabelBank" placeholder="[ nama bank ]"
                            :options="options.bank" v-model="selected.bank[index]" @input="tabbank($event, index)"
                            :searchable="true" :allow-empty="false">
                          </multiselect>
                        </div>
                      </div>
                    </td>
                     <td class="col-3">
                      <div>
                        <div class="w-100">
                          <multiselect :show-labels="false" :custom-label="selectLabelRekening" placeholder="[ jenis rekening ]"
                            :options="options.rekening" v-model="selected.rekening[index]" @input="tabrekening($event, index)"
                            :searchable="true" :allow-empty="false">
                          </multiselect>
                        </div>
                      </div>
                    </td>
                    <td class="">
                      <div>
                        <div class="w-100">
                          <b-form-input class="new-cstm-cntrl-inventori" id="jumlah" v-model="tambahTransaksi.detail[index].norek"
                            @input="tabnorek($event, index)" type="text" placeholder="[ nomor rekening ]" @keypress="isNumberKey">
                          </b-form-input>
                        </div>
                      </div>
                    </td>
                    <td class="">
                      <div>
                        <div class="w-100">
                          <b-form-input class="new-cstm-cntrl-inventori" id="jumlah" v-model="tambahTransaksi.detail[index].nama_rekening"
                            @input="tabnama_rekening($event, index)" type="text" placeholder="[ nama rekening ]">
                          </b-form-input>
                        </div>
                      </div>
                    </td>
                    <td class="">
                      <div>
                        <div class="w-100">
                          <b-form-input class="new-cstm-cntrl-inventori" id="jumlah" v-model="tambahTransaksi.detail[index].no_surat_izin"
                            @input="tabno_surat_izin($event, index)" type="text" placeholder="[ nomor surat izin ]">
                          </b-form-input>
                        </div>
                      </div>
                    </td>
                    <td class="text-center">
                      <b-button variant="white" @click="deleteRow(index)" v-if="index !== 0">
                        <p class="mb-0"><i class="fas fa-trash-alt text-danger"></i></p>
                      </b-button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </b-col>
        </b-row>
      </div>
    </b-modal>
    <b-modal id="modal-edit-rekening-bios" ref="modal-edit-rekening-bios" size="lg" centered :no-close-on-backdrop="true" :static="true"
      title="Edit Master Rekening" ok-title="Update Data" @close="clearData" @cancel="clearData"
      cancel-title="Tutup" @ok="updateSave">
      <b-form class="py-2">
        <b-form-group label-for="tanggal" class="mb-1">
          <b-row class="mb-1">
            <b-col cols="3">
              <p class="custom-label-katalog text-dark mb-0">Bank</p>
            </b-col>
            <b-col>
              <multiselect :show-labels="false" :custom-label="selectLabelBank" placeholder="[ Nama Bank ]"
                :options="options.bank" v-model="selected.bank" @input="tabbankupdate($event)"
                :searchable="true" :allow-empty="false">
              </multiselect>
            </b-col>
          </b-row>
          <b-row class="mb-1">
            <b-col cols="3">
              <p class="custom-label-katalog text-dark mb-0">Jenis Rekening</p>
            </b-col>
            <b-col>
              <multiselect :show-labels="false" :custom-label="selectLabelRekening" placeholder="[ jenis rekening ]"
                :options="options.rekening" v-model="selected.rekening" @input="tabrekeningupdate($event)"
                :searchable="true" :allow-empty="false">
              </multiselect>
            </b-col>
          </b-row>
          <b-row class="mb-1">
            <b-col cols="3">
              <p class="custom-label-katalog text-dark mb-0">Nomor Rekening</p>
            </b-col>
            <b-col>
              <b-form-input class="new-cstm-cntrl-inventori" id="jumlah" v-model="updateTransaksi.norek"
                type="text" placeholder="[ nomor rekening ]">
            </b-form-input>
            </b-col>
          </b-row>
          <b-row class="mb-1">
            <b-col cols="3">
              <p class="custom-label-katalog text-dark mb-0">Nama Rekening</p>
            </b-col>
            <b-col>
              <b-form-input class="new-cstm-cntrl-inventori" id="jumlah" v-model="updateTransaksi.nama_rekening"
                type="text" placeholder="[ nama rekening ]">
            </b-form-input>
            </b-col>
          </b-row>
          <b-row class="mb-1">
            <b-col cols="3">
              <p class="custom-label-katalog text-dark mb-0">Nomor Surat Izin</p>
            </b-col>
            <b-col>
              <b-form-input class="new-cstm-cntrl-inventori" id="jumlah" v-model="updateTransaksi.no_surat_izin"
                type="text" placeholder="[ nama rekening ]">
            </b-form-input>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="3">
              <p class="custom-label-katalog text-dark mb-0">Status</p>
            </b-col>
            <b-col>
              <multiselect :show-labels="false" :custom-label="selectLabelStatus" placeholder="[ status ]"
                :options="options.status" v-model="selected.status" @input="tabstatusupdate($event)"
                :searchable="true" :allow-empty="false">
              </multiselect>
            </b-col>
          </b-row>
        </b-form-group>
      </b-form>
    </b-modal>
    <ModalMsgbox ref="MsgBox" />
  </div>
</template>
<script>
import { xray } from '../../../../config/pluginInit'
import Multiselect from 'vue-multiselect'
import BIOS from '../../../../services/fatmahost/bios'
import ModalMsgbox from '../../../../components/modal/ModalMsgBox'

export default {
  components: {
    Multiselect,
    ModalMsgbox
  },
  mounted () {
    xray.index()
  },
  data () {
    return {
      hidden: false,
      // new saldo bios rekening operasional
      currentDate: '',
      table: {
        filter: '',
        fields: [
          { label: 'No', key: 'index', class: 'text-center' },
          { label: 'Kode bank', key: 'kd_bank', class: 'col-1' },
          { label: 'Nama Bank', key: 'mb.nama_bank' },
          { label: 'Nomor Rekening', key: 'tr.norek' },
          { label: 'Nama Rekening', key: 'tr.nama_rekening' },
          { label: 'Jenis Rekening', key: 'mr.nama_rekening' },
          { label: 'Status', key: 'status', class: 'col-1 text-center' },
          { label: 'Aksi', key: 'action', class: 'col-1 text-center' }
        ],
        items: this.list,
        currentPage: null,
        pageOptions: [10, 25, 50, 100],
        perPage: null,
        isBusy: false,
        totalRows: null
      },
      selected: {
        bank: [],
        rekening: [],
        status: [],
        filter: '1'
      },
      options: {
        bank: [],
        rekening: [],
        status: [
          { text: 'Aktif', value: '1' },
          { text: 'Tidak Aktif', value: '0' }
        ],
        optionsFilter: [
          { text: 'Nomor Rekening', value: '1' },
          { text: 'Nama Rekening', value: '2' }
        ]
      },
      tambahTransaksi: {
        object: 'rekening',
        detail: []
      },
      updateTransaksi: {
        object: 'rekening',
        kd_bank: '',
        kd_rek: '',
        norek: '',
        no_surat_izin: '',
        tanggal: this.tglKirim(),
        nama_rekening: '',
        status: '',
        updated_in: xray.currentUser().id
      },
      idu: ''
    }
  },
  methods: {
    isNumberKey (evt) {
      evt = (evt) || window.event
      var charCode = (evt.which) ? evt.which : evt.keyCode
      if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
        evt.preventDefault()
      } else {
        return true
      }
    },
    // eslint-disable-next-line camelcase
    selectLabelBank ({ kd_bank, nama_bank }) {
      // eslint-disable-next-line camelcase
      return `${kd_bank} - ${nama_bank}`
    },
    // eslint-disable-next-line camelcase
    selectLabelRekening ({ nama_rekening }) {
      // eslint-disable-next-line camelcase
      return `${nama_rekening}`
    },
    selectLabelStatus ({ text }) {
      // eslint-disable-next-line camelcase
      return `${text}`
    },
    tabbank ($event, index) {
      this.tambahTransaksi.detail[index].kd_bank = $event.kd_bank
    },
    tabrekening ($event, index) {
      this.tambahTransaksi.detail[index].kd_rek = $event.kd_rekening
    },
    tabnorek ($event, index) {
      this.tambahTransaksi.detail[index].norek = $event
    },
    tabnama_rekening ($event, index) {
      this.tambahTransaksi.detail[index].nama_rekening = $event
    },
    tabno_surat_izin ($event, index) {
      this.tambahTransaksi.detail[index].no_surat_izin = $event
    },
    tabbankupdate ($event) {
      this.updateTransaksi.kd_bank = $event.kd_bank
    },
    tabrekeningupdate ($event) {
      this.updateTransaksi.kd_rek = $event.kd_rekening
    },
    tabstatusupdate ($event) {
      this.updateTransaksi.status = $event.value
    },
    async list (ctx) {
      this.isBusy = true
      let sortfield = ''
      let sorting = ''
      let filters = ''
      let filterType = this.selected.filter
      if (ctx.filter === '') {
        ctx.filter = null
        filters = `{"xparam0":"","xparam1":"","xparam2":"","xparam3":"","xparam4":""}`
      }
      if (ctx.filter === null) {
        filters = `{"xparam0":"","xparam1":"","xparam2":"","xparam3":"","xparam4":""}`
      } else {
        if (filterType === '1') {
          filters = `{"xparam0":"${ctx.filter}","xparam1":"","xparam2":"","xparam3":"","xparam4":""}`
        } else if (filterType === '2') {
          filters = `{"xparam0":"","xparam1":"${ctx.filter}","xparam2":"","xparam3":"","xparam4":""}`
        }
      }
      if (ctx.sortBy) {
        sortfield = ctx.sortBy
      } else {
        sortfield = ''
      }
      if (ctx.sortDesc === true) {
        sorting = 'DESC'
      } else if (ctx.sortDesc === false) {
        sorting = 'ASC'
      } else {
        sorting = ''
      }
      try {
        const response = await BIOS.rekeningFindAll(ctx.currentPage - 1, ctx.perPage, filters, sortfield, sorting)
        this.isBusy = false
        if (response.data.statcode === 20001) {
          return []
        }
        this.isBusy = false
        this.table.totalRows = response.data.pagination.total
        this.table.currentPage = response.data.pagination.current
        this.table.perPage = response.data.pagination.perPage
        return response.data.data
      } catch (error) {
        this.isBusy = false
        return []
      }
    },
    onFiltered (filteredItems) {
      this.table.totalRows = filteredItems.length
    },
    filterchange () {
      this.table.filter = ''
    },
    tableRefresh () {
      this.$refs.table.refresh()
    },
    openDetail (items) {
      this.showModal()
    },
    clearData () {
      this.tambahTransaksi.detail = []
      this.selected.bank = []
      this.selected.rekening = []
      this.selected.status = []
      this.kd_bank = ''
      this.kd_rek = ''
      this.norek = ''
      this.no_surat_izin = ''
      this.nama_rekening = ''
      this.status = ''
    },
    tglKirim () {
      const date = new Date()
      let day = date.getDate()
      let month = (date.getMonth() + 1).toString().padStart(2, 0)
      let year = date.getFullYear()
      if (day < 10) {
        day = '0' + day
      }
      let currentDate = year + '-' + month + '-' + day
      return currentDate
    },
    async selectizeRekening () {
      try {
        const response = await BIOS.selectizeRekening()
        this.options.rekening = response.data.data
      } catch (error) {
        this.options.rekening = []
      }
    },
    async selectizeBank () {
      try {
        const response = await BIOS.selectizeBank()
        this.options.bank = response.data.data
      } catch (error) {
        this.options.bank = []
      }
    },
    showModal () {
      this.selectizeBank()
      this.selectizeRekening()
      this.clearData()
      this.addRow()
      this.$refs['modal-tambah-rekening-bios'].show()
    },
    editModal (id) {
      this.selectizeBank()
      this.selectizeRekening()
      this.showData(id)
      this.$refs['modal-edit-rekening-bios'].show()
    },
    async showData (id) {
      this.idu = id
      const res = await BIOS.rekeningShow(id)
      const data = res.data.data[0]
      this.selected.bank.push({
        kd_bank: data.kd_bank, nama_bank: data.nama_bank
      })
      this.selected.rekening.push({
        kd_rek: data.kd_bank, nama_rekening: data.mnama_rekening
      })
      this.selected.status.push({
        text: (data.status === '1' || data.status === 1 ? 'Aktif' : 'Tidak Aktif'), value: data.status
      })
      this.updateTransaksi.kd_bank = data.kd_bank
      this.updateTransaksi.kd_rek = data.kd_rek
      this.updateTransaksi.norek = data.norek
      this.updateTransaksi.nama_rekening = data.tnama_rekening
      this.updateTransaksi.no_surat_izin = data.no_surat_izin
      this.updateTransaksi.status = data.status
    },
    async tambahSave (bvModalEvent) {
      bvModalEvent.preventDefault()
      try {
        const response = await BIOS.rekeningCreate(this.tambahTransaksi)
        if (response.data.statcode === 0) {
          this.$refs['modal-tambah-rekening-bios'].hide()
          this.$refs.MsgBox.show({
            type: 'success',
            msg: response.data.message
          })
        } else {
          this.$refs['modal-tambah-rekening-bios'].hide()
          this.$refs.MsgBox.show({
            type: 'error',
            msg: response.data.message
          })
        }
      } catch (error) {
        this.$refs['modal-tambah-rekening-bios'].hide()
        this.$refs.MsgBox.show({
          type: 'error',
          msg: 'Data gagal diubah.'
        })
      }
      this.$refs.table.refresh()
    },
    async updateSave (bvModalEvent) {
      bvModalEvent.preventDefault()
      this.updateTransaksi.tanggal = this.tglKirim()
      try {
        const response = await BIOS.rekeningUpdate(this.idu, this.updateTransaksi)
        if (response.data.statcode === 0) {
          this.$refs['modal-edit-rekening-bios'].hide()
          this.$refs.MsgBox.show({
            type: 'success',
            msg: response.data.message
          })
        } else {
          this.$refs['modal-edit-rekening-bios'].hide()
          this.$refs.MsgBox.show({
            type: 'error',
            msg: response.data.message
          })
        }
      } catch (error) {
        this.$refs['modal-edit-rekening-bios'].hide()
        this.$refs.MsgBox.show({
          type: 'error',
          msg: 'Data gagal diubah.'
        })
        this.$refs.table.refresh()
      }
    },
    deleteModal (id) {
      this.$refs['modal-konfirm-delete'].show()
    },
    addRow () {
      this.tambahTransaksi.detail.push(
        {
          kd_bank: '',
          kd_rek: '',
          norek: '',
          no_surat_izin: '',
          tanggal: this.tglKirim(),
          nama_rekening: '',
          created_in: xray.currentUser().id
        }
      )
    },
    deleteRow (index) {
      this.tambahTransaksi.detail.splice(index, 1)
    }
  }
}
</script>
<style>
#modal-tambah-rekening-bios .height-static-pabrik {
  /* max-height: 12em;
 overflow-x: hidden; */
  overflow: auto;
}

#modal-tambah-rekening-bios .multiselect, #modal-edit-rekening-bios .multiselect {
  min-height: 33px;
}

#modal-tambah-rekening-bios .multiselect__single, #modal-edit-rekening-bios .multiselect__single {
  font-size: 0.9em!important;
  font-weight: normal!important;
  line-height: 26px;
}
#modal-tambah-rekening-bios .multiselect__placeholder, #modal-edit-rekening-bios .multiselect__placeholder {
  font-size: 0.9em!important;
  font-weight: normal;
}
#modal-tambah-rekening-bios .multiselect__option, #modal-edit-rekening-bios .multiselect__option {
  font-size: 0.9em;
}
#modal-tambah-rekening-bios .multiselect__tags, #modal-edit-rekening-bios .multiselect__tags {
  overflow: hidden;
  padding: 1px 40px 0 8px!important;
  height: 33px!important;
  min-height: 33px!important;
}

#modal-edit-rekening-bios .multiselect--disabled .multiselect__tags {
  overflow: hidden;
  background: #E9ECEF;
}

#modal-edit-rekening-bios .multiselect--disabled {
  opacity: 1;
}

#modal-edit-rekening-bios .multiselect--disabled .multiselect__tags .multiselect__single {
  overflow: hidden;
  background: transparent;
}

#modal-edit-rekening-bios .multiselect__select {
    height: 30px;
}

.new-cstm-cntrl-inventori {
  background: white!important;
  color: black!important;
  border-radius: 5px!important;
  font-size: 12px!important;
  height: 33px!important;
}

#modal-tambah-rekening-bios .border-merah {
  border: 2px solid red;
}

#modal-tambah-rekening-bios .border-hijau {
  border: 2px solid green;
}

#modal-tambah-rekening-bios .modal-body {
  background: #3399331b;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

#modal-tambah-rekening-bios .modal-header {
  background: #3399333a;
}

#modal-tambah-rekening-bios .modal-footer {
  background: #3399333a;
}

#modal-edit-rekening-bios .border-merah {
  border: 2px solid red;
}

#modal-edit-rekening-bios.border-hijau {
  border: 2px solid green;
}

#modal-edit-rekening-bios .modal-body {
  background: #3399331b;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

#modal-edit-rekening-bios .modal-header {
  background: #3399333a;
}

#modal-edit-rekening-bios .modal-footer {
  background: #3399333a;
}

#bios-rekening .custom-button-radius {
  border: 1px solid rgba(0, 0, 0, 0.112);
  border-radius: 5px !important;
}

#bios-rekening .table td {
    font-size: 12px;
    font-weight: 500;
    padding-top: 0px;
    padding-bottom: 0px;
}
#bios-rekening .table th {
    font-size: 13px;
    font-weight: 400;
}

#bios-rekening .table {
  text-align: left;
}

#bios-rekening .cstm-select-rekening {
  font-size: 13px;
  height: 38px !important;
  border-radius: 5px;
}

#bios-rekening .cstm-srch-brand {
  font-size: 13px;
  height: 38px !important;
  border-radius: 5px;
}

#bios-rekening .cstm-slct-brand {
  font-size: 13px;
  height: 38px !important;
  border-radius: 5px;
}

#bios-rekening .pagination-rekening-bios .page-link {
  border: none;
  color: gray !important;
  margin-right: 15px;
}

#bios-rekening .pagination-rekening-bios .page-link {
  border: none;
  color: gray !important;
  margin-right: 15px;
}

#bios-rekening .page-item.active .page-link {
    background-color: transparent;
    border-color: transparent;
}

#bios-rekening .pagination-rekening-bios .page-item.active .page-link {
  background-color: transparent;
  border-color: transparent;
  color: #089bab !important;
  font-weight: bold;
}

#modal-tambah-rekening-bios .custom-flatpicker, #modal-edit-rekening-bios .custom-flatpicker {
    border: 1px solid rgb(233, 233, 233);
    height: 33px;
    padding-left: 10px;
    padding-top: 5px;
    padding-bottom: 5px;
    border: 1px solid rgb(209, 208, 208)!important;
    border-radius: 5px;
    border: 1px solid rgb(228, 228, 228);
    font-size: 12px;
    color: black;
}

.pointer-table tbody tr {
  cursor: pointer;
}
</style>
