<template>
  <div class="dashboardKlaim">
    <div v-show="isLoading">
      <div id="show-overlay"></div>
      <Loader :style="{ opacity: .5 }" />
    </div>
    <div id="cstm-nav" class="cstm-nav">
      <b-navbar class="bg-white fixed-top shadow-sm" variant="faded" type="light">
        <b-navbar-brand href="#" class="text-center d-flex flex-row justify-content-center align-items-center mx-auto">
          <div class="mr-3 mr-lg-4">
            <img :src="require('../../assets/images/logo1.png')" width="40rem" class="d-inline-block align-top"
              alt="Kitten">
          </div>
          <div class="mr-3 mr-lg-4">
            <img :src="require('../../assets/images/logo-rsup.png')" width="45rem" class="d-inline-block align-top"
              alt="Kitten">
          </div>
          <div class="mr-3 mr-lg-4">
            <img :src="require('../../assets/images/logo-kemkes.png')" width="85rem" class="d-inline-block align-top"
              alt="Kitten">
          </div>
          <div>
            <img :src="require('../../assets/images/germas.png')" width="85rem" class="d-inline-block align-top"
              alt="Kitten">
          </div>
        </b-navbar-brand>
      </b-navbar>
    </div>
    <b-container class="mt-5 pt-5">
      <div class="px-lg-5">
        <b-row>
          <b-col cols="12" class="mb-4">
            <div class="bg-white rounded shadow-sm border py-4 px-4 card-klaim">
              <div class="mb-3">
                <p class="h5 text-dark font-weight-bold mb-0"><i class="fas fa-check-circle text-success"></i> LAYAK</p>
              </div>
              <b-table stacked="sm" class="border" striped hover id="my-table" :items="dataLayak" :filter="filter" :fields="fields" :per-page="perPage" :current-page="currentPage">
                <template v-slot:cell(periode)="data">
                  <span>{{ data.item.periode == null ? '-' : data.item.periode }}</span>
                </template>
                <template v-slot:cell(action)="data">
                  <b-button variant="primary" @click="showById(1, data.item.periode)">Lihat Rinci</b-button>
                </template>
              </b-table>
              <b-pagination size="md" v-model="currentPage" :total-rows="rows" :per-page="perPage" aria-controls="my-table"></b-pagination>
            </div>
          </b-col>
          <b-col cols="12" class="mb-4">
            <div class="bg-white rounded shadow-sm border py-4 px-3 card-klaim">
              <div class="mb-3">
                <p class="h5 text-dark font-weight-bold mb-0"><i class="fas fa-spinner text-warning"></i> PROSES</p>
              </div>
              <b-table stacked="sm" class="border" striped hover id="my-table" :items="dataProses" :filter="filter" :fields="fields" :per-page="perPage" :current-page="currentPage">
                <template v-slot:cell(periode)="data">
                  <span>{{ data.item.periode == null ? '-' : data.item.periode }}</span>
                </template>
                <template v-slot:cell(action)="data">
                  <b-button variant="primary" @click="showById(2, data.item.periode)">Lihat Rinci</b-button>
                </template>
              </b-table>
              <b-pagination size="md" v-model="currentPage" :total-rows="rows" :per-page="perPage" aria-controls="my-table"></b-pagination>
            </div>
          </b-col>
          <b-col cols="12" class="mb-4">
            <div class="bg-white rounded shadow-sm border py-4 px-3 card-klaim">
              <div class="mb-3">
                <p class="h5 text-dark font-weight-bold mb-0"><i class="fas fa-times-circle text-danger"></i> TIDAK LAYAK</p>
              </div>
              <b-table stacked="sm" class="border" striped hover id="my-table" :items="dataTidakLayak" :filter="filter" :fields="fields" :per-page="perPage" :current-page="currentPage">
                <template v-slot:cell(periode)="data">
                  <span>{{ data.item.periode == null ? '-' : data.item.periode }}</span>
                </template>
                <template v-slot:cell(action)="data">
                  <b-button variant="primary" @click="showById(3, data.item.periode)">Lihat Rinci</b-button>
                </template>
              </b-table>
              <b-pagination size="md" v-model="currentPage" :total-rows="rows" :per-page="perPage" aria-controls="my-table"></b-pagination>
            </div>
          </b-col>
        </b-row>
      </div>
      <b-modal ref="modal-dashboard-klaim" size="xl" title="Informasi" ok-title="Tutup" :ok-only=true @ok="clearData" @close="clearData" @cancel="clearData" centered>
        <div class="py-4 px-3 dashboard-klaim-scroll">
          <p class="h4 font-weight-bold text-center  text-dark text-uppercase mb-4">{{respon}}</p>
          <p class="h6 text-dark text-uppercase mb-4">{{error}}</p>
          <div v-for="d in dataAll" :key="d.TGLMASUK">
            <div class="shadow rounded px-4 py-5 mb-5">
              <b-row class="mb-3">
                <b-col cols="2">
                  <p class="h6 text-dark mb-0">Tanggal Masuk</p>
                </b-col>
                <b-col cols="1">
                  <p class="h6 text-dark mb-0">:</p>
                </b-col>
                <b-col cols="9">
                  <p class="h6 text-dark mb-0">{{ new Date(d.TGLMASUK) | formatDate }}</p>
                </b-col>
              </b-row>
              <b-row class="mb-3">
                <b-col cols="2">
                  <p class="h6 text-dark mb-0">Tanggal Keluar</p>
                </b-col>
                <b-col cols="1">
                  <p class="h6 text-dark mb-0">:</p>
                </b-col>
                <b-col cols="9">
                  <p class="h6 text-dark mb-0">{{ new Date(d.TGLKELUAR) | formatDate }}</p>
                </b-col>
              </b-row>
              <b-row class="mb-3">
                <b-col cols="2">
                  <p class="h6 text-dark mb-0">Tanggal Pulang</p>
                </b-col>
                <b-col cols="1">
                  <p class="h6 text-dark mb-0">:</p>
                </b-col>
                <b-col cols="9">
                  <p class="h6 text-dark mb-0">{{ new Date(d.TGLPULANG) | formatDate }}</p>
                </b-col>
              </b-row>
              <b-row class="mb-3">
                <b-col cols="2">
                  <p class="h6 text-dark mb-0">Nomor</p>
                </b-col>
                <b-col cols="1">
                  <p class="h6 text-dark mb-0">:</p>
                </b-col>
                <b-col cols="9">
                  <p class="h6 text-dark mb-0">{{d.NOMOR}}</p>
                </b-col>
              </b-row>
              <b-row class="mb-3">
                <b-col cols="2">
                  <p class="h6 text-dark mb-0">NORM</p>
                </b-col>
                <b-col cols="1">
                  <p class="h6 text-dark mb-0">:</p>
                </b-col>
                <b-col cols="9">
                  <p class="h6 text-dark mb-0">{{d.NORM}}</p>
                </b-col>
              </b-row>
              <b-row class="mb-3">
                <b-col cols="2">
                  <p class="h6 text-dark mb-0">Nomor Kartu</p>
                </b-col>
                <b-col cols="1">
                  <p class="h6 text-dark mb-0">:</p>
                </b-col>
                <b-col cols="9">
                  <p class="h6 text-dark mb-0">{{d.NOMORKARTU}}</p>
                </b-col>
              </b-row>
              <b-row class="mb-3">
                <b-col cols="2">
                  <p class="h6 text-dark mb-0">No. SEP</p>
                </b-col>
                <b-col cols="1">
                  <p class="h6 text-dark mb-0">:</p>
                </b-col>
                <b-col cols="9">
                  <p class="h6 text-dark mb-0">{{d.NOSEP}}</p>
                </b-col>
              </b-row>
              <b-row class="mb-3">
                <b-col cols="2">
                  <p class="h6 text-dark mb-0">Nama Pasien</p>
                </b-col>
                <b-col cols="1">
                  <p class="h6 text-dark mb-0">:</p>
                </b-col>
                <b-col cols="9">
                  <p class="h6 text-dark mb-0">{{d.NAMAPASIEN}}</p>
                </b-col>
              </b-row>
              <b-row class="mb-3">
                <b-col cols="2">
                  <p class="h6 text-dark mb-0">Code CBG</p>
                </b-col>
                <b-col cols="1">
                  <p class="h6 text-dark mb-0">:</p>
                </b-col>
                <b-col cols="9">
                  <p class="h6 text-dark mb-0" v-if="d.CODECBG === null">-</p>
                  <p class="h6 text-dark mb-0" v-else>{{d.CODECBG}}</p>
                </b-col>
              </b-row>
              <b-row class="mb-3">
                <b-col cols="2">
                  <p class="h6 text-dark mb-0">Keterangan</p>
                </b-col>
                <b-col cols="1">
                  <p class="h6 text-dark mb-0">:</p>
                </b-col>
                <b-col cols="9">
                  <p class="h6 text-dark mb-0" v-if="d.KETERANGAN === null">-</p>
                  <p class="h6 text-dark mb-0" v-else>{{d.KETERANGAN}}</p>
                </b-col>
              </b-row>
              <b-row class="mb-3">
                <b-col cols="2">
                  <p class="h6 text-dark mb-0">Status Grouping</p>
                </b-col>
                <b-col cols="1">
                  <p class="h6 text-dark mb-0">:</p>
                </b-col>
                <b-col cols="9">
                  <p class="h6 text-dark mb-0">{{d.STATUSGROUPING}}</p>
                </b-col>
              </b-row>
              <b-row class="mb-3">
                <b-col cols="2">
                  <p class="h6 text-dark mb-0">Ruangan</p>
                </b-col>
                <b-col cols="1">
                  <p class="h6 text-dark mb-0">:</p>
                </b-col>
                <b-col cols="9">
                  <p class="h6 text-dark mb-0" v-if="d.RUANGAN === null">-</p>
                  <p class="h6 text-dark mb-0" v-else>{{d.RUANGAN}}</p>
                </b-col>
              </b-row>
              <b-row class="mb-4">
                <b-col cols="2">
                  <p class="h6 text-dark mb-0">DPJP</p>
                </b-col>
                <b-col cols="1">
                  <p class="h6 text-dark mb-0">:</p>
                </b-col>
                <b-col cols="9">
                  <p class="h6 text-dark mb-0" v-if="d.DPJP === null">-</p>
                  <p class="h6 text-dark mb-0" v-else>{{d.DPJP}}</p>
                </b-col>
              </b-row>
              <div class="shadow-sm border rounded border p-4">
                <b-row class="mb-3">
                  <b-col cols="2">
                    <p class="h6 text-dark font-weight-bold mb-0">Tarif CBG</p>
                  </b-col>
                  <b-col cols="1">
                    <p class="h6 text-dark mb-0">:</p>
                  </b-col>
                  <b-col cols="9">
                    <p class="h6 text-primary text-right font-weight-bold mb-0">{{d.TARIFCBG}}</p>
                  </b-col>
                </b-row>
                <b-row class="mb-3">
                  <b-col cols="2">
                    <p class="h6 text-dark font-weight-bold mb-0">Tarif RS</p>
                  </b-col>
                  <b-col cols="1">
                    <p class="h6 text-dark mb-0">:</p>
                  </b-col>
                  <b-col cols="9">
                    <p class="h6 text-primary text-right font-weight-bold mb-0">{{d.TARIFRS}}</p>
                  </b-col>
                </b-row>
                <hr>
                <b-row>
                  <b-col cols="2">
                    <p class="h6 text-dark font-weight-bold mb-0">Selisih</p>
                  </b-col>
                  <b-col cols="1">
                    <p class="h6 text-dark mb-0">:</p>
                  </b-col>
                  <b-col cols="9">
                    <p class="h6 text-danger text-right font-weight-bold mb-0">{{d.SELISIH}}</p>
                  </b-col>
                </b-row>
              </div>
            </div>
          </div>
        </div>
      </b-modal>
    </b-container>
  </div>
</template>
<script>
import Eklaim from '../../services/eklaim'
import Loader from '../../components/xray/loader/Loader'
// import Token from '../../services/token.js'
export default {
  name: 'DashboardKlaim',
  components: {
    Loader
  },
  data () {
    return {
      isLoading: false,
      dataAll: [],
      dataLayak: [],
      dataProses: [],
      dataTidakLayak: [],
      respon: '',
      error: '',
      perPage: 5,
      currentPage: 1,
      rows: '',
      filter: '',
      fields: [
        { label: 'PERIODE', key: 'periode' },
        { label: 'KASUS', key: 'kasus' },
        { label: 'RIIL RS', key: 'riilrs' },
        { label: 'DIAJUKAN', key: 'diajukan' },
        { label: 'DISETUJUI', key: 'disetujui' },
        { label: 'DETAIL', key: 'action', class: 'text-right' }
      ]
    }
  },
  mounted () {
    // this.cekToken()
    this.showAll()
  },
  methods: {
    // async cekToken () {
    //   this.isLoading = true
    //   const origin = {
    //     url: location.origin
    //   }
    //   let one = localStorage.getItem('ref')
    //   const res = await Token.myToken(origin)
    //   localStorage.setItem('token', res.data.token)
    //   localStorage.setItem('ref', one = one + 1)
    //   this.isLoading = false
    //   // const tk = localStorage.getItem('token')
    //   const rf = localStorage.getItem('ref')
    //   if (rf === '1' || rf === '1111') {
    //     location.reload()
    //   }
    // },
    async showAll () {
      try {
        const res = await Eklaim.getShow()
        if (res.status === 200) {
          this.dataLayak = res.data.data.layak
          this.dataProses = res.data.data.proses
          this.dataTidakLayak = res.data.data.tidakLayak
        }
      } catch (err) {
        alert(err.response.message)
      }
    },
    async showById (sts, tgl) {
      this.isLoading = true
      const status = sts
      const periode = tgl
      try {
        const res = await Eklaim.getShowDetail(status, periode)
        if (res.status === 200) {
          this.dataAll = res.data.data
          this.respon = res.data.message
          this.$refs['modal-dashboard-klaim'].show()
          this.isLoading = false
        }
      } catch (err) {
        this.error = err.response.data.message
        this.$refs['modal-dashboard-klaim'].show()
        this.isLoading = false
      }
    },
    clearData () {
      this.dataAll = []
    }
  }
}
</script>
<style>
@import '../../assets/css/pages/public/dashboard-klaim.css';
</style>
