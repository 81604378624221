import Api from './fatmahost/axiosInstance'

export default {
  // public
  showByRm (data) {
    return Api.laravel.get(`rm/reservasi/rsf?norm=${data}`)
  },
  getMember (id) {
    return Api.laravel.get(`profil/member?patient_id=${id}`)
  },
  createReservasi (data) {
    return Api.laravel.post('create/reservasi/rsf', data)
  },
  cekKuota (ruangan, tgl, jnsrsv) {
    return Api.laravel.get(`kuota/poli/rsf?jenis_reservasi=${jnsrsv}&tanggal=${tgl}&ruangan=${ruangan}`)
  },
  agama () {
    return Api.laravel.get('getAgama/rsf')
  },
  pendidikan () {
    return Api.laravel.get('getPendidikan/rsf')
  },
  pekerjaan () {
    return Api.laravel.get('getPekerjaan/rsf')
  },
  kewarganegaraan () {
    return Api.laravel.get('getKewarganegaraan/rsf')
  },
  provinsi () {
    return Api.laravel.get('provinsi/rsf')
  },
  kabupaten (data) {
    return Api.laravel.post('kabupaten/rsf', data)
  },
  kecamatan (data) {
    return Api.laravel.post('kecamatan/rsf', data)
  },
  kelurahan (data) {
    return Api.laravel.post('kelurahan/rsf', data)
  },
  // Public IRJ--------------------------------------------------------------------------------------------------------------
  caraBayar () {
    return Api.laravel.get('getbyCarabayar/rsf')
  },
  instalasi () {
    return Api.laravel.get('getbyInstalasi/rsf')
  },
  unit (data) {
    return Api.laravel.post('getbyUnit/rsf', data)
  },
  ruangan (data) {
    return Api.laravel.post('getbyRuangan/rsf', data)
  },
  cekKuotaIrj (ruangan, tgl, jnsrsv) {
    return Api.laravel.get(`kuota/reservasi/irj?ruangan=${ruangan}&tanggal=${tgl}&jenis_reservasi=${jnsrsv}`)
  },
  cekKBpjsNik (nik) {
    return Api.laravel.get(`bpjs/nik?nik=${nik}`)
  },
  cekKBpjsNoKa (noka) {
    return Api.laravel.get(`bpjs/nokartu?noKartu=${noka}`)
  },
  createReservasiIRJ (data) {
    return Api.laravel.post('create/reservasi/irj', data)
  },
  cekRanap (norm) {
    return Api.laravel.get(`getPostRanap/rsf?jenis_reservasi=1&nomor=${norm}`)
  },
  // private IRJ----------------------------------------------------------------------------------------------------------
  getListIrj (page, params) {
    if (params !== null) {
      return Api.laravel.get(`reservasi/RJ/index/?params=${params}`)
    } else {
      return Api.laravel.get(`reservasi/RJ/index/?page=${page}`)
    }
  },
  irjBatal (data) {
    return Api.laravel.put('reservasi/RJ/batal', data)
  },
  irjCetakUlang (data) {
    return Api.laravel.get(`reservasi/RJ/cetakUlang?id=${data}`)
  },
  // Public Griya --------------------------------------------------------------------------------------------------------------
  getDokterRuangan (data) {
    return Api.laravel.post('setDokterRuangan/rsf', data)
  },
  menuGetDokter (ruangan, tanggal) {
    return Api.laravel.get(`dokter/reservasi/rsf?ruangan=${ruangan}&tanggal=${tanggal}`)
  },
  menuCekKuotaGriya (ruangan, tgl, jnsrsv, dokter) {
    return Api.laravel.get(`kuota/reservasi/rsf?dokter=${dokter}&ruangan=${ruangan}&tanggal=${tgl}&jenis_reservasi=${jnsrsv}`)
  },
  cekKuotaGriya (ruangan, tgl, jnsrsv, dokter) {
    return Api.laravel.get(`kuota/reservasi/igh?dokter=${dokter}&ruangan=${ruangan}&tanggal=${tgl}&jenis_reservasi=${jnsrsv}`)
  },
  createReservasiGriya (data) {
    return Api.laravel.post('create/reservasi/irj', data)
  },
  // private Griya----------------------------------------------------------------------------------------------------------
  getListGriya (page, params) {
    if (params !== null) {
      return Api.laravel.get(`reservasi/IGH/index/?params=${params}`)
    } else {
      return Api.laravel.get(`reservasi/IGH/index/?page=${page}`)
    }
  },
  ighBatal (data) {
    return Api.laravel.put('reservasi/IGH/batal', data)
  },
  ighCetakUlang (data) {
    return Api.laravel.get(`reservasi/IGH/cetakUlang?id=${data}`)
  }
}
