<template>
  <div id="penerimaan-rumah-tangga" class="mb-4">
    <div v-show="isLoading">
      <div id="show-overlay"></div>
      <Loader :style="{ opacity: .5 }" />
    </div>
    <b-container fluid>
      <div class="bg-white rounded-sm shadow-sm p-3">
        <div class="bg-white border">
          <b-row class="align-items-center p-2">
            <b-col class="pr-1">
              <div>
                <p class="h6 font-weight-bold text-dark m-0 p-0">PENERIMAAN RUMAH TANGGA</p>
              </div>
            </b-col>
            <b-col cols="2" class="px-1">
              <div>
                <flat-pickr :config="configTgl" v-model="tanggal.tgl_doc" value=""
                @on-change="valueTgl($event, 'tgl_list')" placeholder="Tanggal"
                class="custom-flatpicker calender-small w-100" />
              </div>
            </b-col>
            <b-col cols="1" class="px-1">
              <div>
                <b-button class="w-100" variant="warning" size="sm" @click="resetFilter">
                  <i class="fas fa-sync-alt"></i> RESET
                </b-button>
              </div>
            </b-col>
            <b-col cols="2" class="pl-1">
              <div>
                <!-- <b-button variant="primary" size="sm" @click="openModal('tambah')"><i class="fas fa-plus"></i> TAMBAH
                  PENERIMAAN
                </b-button> -->
                <b-button class="w-100" variant="primary" size="sm" @click="toPenerimaanDetail('', 'tambah')"><i class="fas fa-plus"></i> TAMBAH
                  PENERIMAAN
                </b-button>
              </div>
            </b-col>
        </b-row>
        </div>
        <div class="table m-0">
          <b-table class="bg-white mb-0" ref="table" :busy="table.isBusy" :items="table.items" :fields="table.fields"
            :sort-by.sync="table.sortBy" :sort-desc.sync="table.sortDesc" :current-page="table.currentPage"
            :per-page="table.perPage" :filter="table.filter" @filtered="onFiltered" :tbody-tr-class="rowClass"
            :select-mode="table.selectMode" stacked="md" bordered hover small show-empty selectable>
            <template #table-busy>
              <div class="text-center my-2">
                <b-spinner class="align-middle text-primary mr-2"></b-spinner>
                <strong class="text-dark">Memuat Data...</strong>
              </div>
            </template>
            <template #cell(index)="data">
              <span>{{ (table.currentPage*table.perPage)-table.perPage + data.index + 1 }}</span>
            </template>
            <template #cell(tgl_doc)="data">
              <span>{{ convertDate(data.item.tgl_doc, 'use') }}</span>
            </template>
            <template #cell(tgl_faktur)="data">
              <span>{{ convertDate(data.item.tgl_faktur, 'use') }}</span>
            </template>
            <template #cell(tgl_tukar_faktur)="data">
              <span>{{ convertDate(data.item.tgl_tukar_faktur, 'use') }}</span>
            </template>
            <template #cell(action)="data">
              <b-button variant="warning" size="sm" class="m-1" @click="cetak(data.item.id_terima)"><i
                class="fas fa-print"></i>
              </b-button>
              <!-- <b-button variant="primary" size="sm" class="m-1"
                @click="show(data.item.id_terima, 'penerimaan-rumah-tangga')"><i class="fas fa-edit"></i>
              </b-button> -->
              <!-- <b-button variant="danger" size="sm" class="m-1"
                @click="softDelete(data.item.id_terima)"><i class="fas fa-trash-alt"></i>
              </b-button> -->
              <b-button variant="primary" size="sm" class="m-1"
                @click="toPenerimaanDetail(data.item.kode, 'edit')"><i class="fas fa-sign-in-alt"></i>
              </b-button>
            </template>
          </b-table>
        </div>
        <div class="bg-white border pb-2 pt-2 pl-3 pr-3">
          <b-row class="align-items-center justify-content-between">
            <b-col cols="12" lg="3" class="mr-auto">
              <div class="d-flex align-items-center">
                <div>
                  <b-pagination v-model="table.currentPage" :total-rows="table.totalRows" :per-page="table.perPage"
                    size="sm" class="my-0">
                  </b-pagination>
                </div>
                <div class="border-left border-right px-3">
                  <span class="cursor-point my-cursor" @click="refreshTable">
                    <i class="fas fa-sync"></i>
                  </span>
                </div>
              </div>
            </b-col>
            <b-col cols="12" lg="3">
              <div v-if="message.code === 'list'"
                :class="['rounded-sm shadow-sm border px-2 py-1 d-flex flex-row justify-content-between', 'bg-' + message.variant]">
                <div>
                  <p class="small m-0 p-0">{{ message.desc }}</p>
                </div>
                <div>
                  <p class="small m-0 p-0 my-cursor" @click="clearMessage"><i class="fas fa-times fa-lg"></i></p>
                </div>
              </div>
            </b-col>
            <b-col cols="12" lg="3" class="d-flex justify-content-end align-items-center">
              <div class="mr-3">
                <b-form-select id="per-page-select" v-model="table.perPage" :options="table.pageOptions"
                  size="sm"></b-form-select>
              </div>
              <div>
                <p class="font-weight-normal mb-0 text-dark" v-if="table.totalRows">Menampilkan 1 - {{ table.perPage }}
                  dari {{ table.totalRows }}</p>
              </div>
            </b-col>
          </b-row>
        </div>
      </div>
    </b-container>
    <b-modal id="modal-create-update-penerimaan" ref="modal-create-update-penerimaan" size="xl" :title="modal_title"
      ok-title="Simpan" cancel-title="Tutup" :no-close-on-backdrop="true" :static="true" @ok="createOrUpdate"
      scrollable centered>
      <div>
        <b-row class="justify-content-between">
          <b-col cols="12" lg="5" xl="5">
            <div>
              <b-row class="align-items-center mb-1">
                <b-col cols="12" xl="4">
                  <div>
                    <p class="text-dark custom-font text-uppercase m-0 p-0">Rekanan</p>
                  </div>
                </b-col>
                <b-col cols="12" xl="8">
                  <div class="d-flex">
                    <div class="w-100">
                      <multiselect :show-labels="false" :allow-empty="false" label="deskripsi" placeholder="Ruangan"
                        :options="options.rekanan_rt" v-model="selected.rekanan_rt" :searchable="true"
                        @input="valueSelectize($event, 'id_rekanan', '')">
                      </multiselect>
                    </div>
                    <!-- <div class="w-custom-30">
                      <b-form-input type="text" class="bg-white custom-form" placeholder="Ke..."></b-form-input>
                    </div> -->
                  </div>
                </b-col>
              </b-row>
              <b-row class="align-items-center mb-1">
                <b-col cols="12" xl="4">
                  <div>
                    <p class="text-dark custom-font text-uppercase m-0 p-0">Jenis Anggaran</p>
                  </div>
                </b-col>
                <b-col cols="12" xl="8">
                  <div class="d-flex">
                    <div class="w-100">
                      <multiselect :show-labels="false" :allow-empty="false" label="deskripsi"
                        placeholder="Jenis Anggaran" :options="options.jenis_anggaran_rt"
                        v-model="selected.jenis_anggaran_rt" :searchable="true"
                        @input="valueSelectize($event, 'id_jenisanggaran', '')">
                      </multiselect>
                    </div>
                    <!-- <div class="w-custom-30">
                      <b-form-input type="text" class="bg-white custom-form" placeholder="Ke..."></b-form-input>
                    </div> -->
                  </div>
                </b-col>
              </b-row>
              <b-row class="align-items-center mb-1">
                <b-col cols="12" xl="4">
                  <div>
                    <p class="text-dark custom-font text-uppercase m-0 p-0">Keterangan</p>
                  </div>
                </b-col>
                <b-col cols="12" xl="8">
                  <div class="d-flex">
                    <div class="w-100">
                      <b-form-input v-model="form.header.keterangan" type="text" class="bg-white text-dark custom-form"
                        placeholder="Keterangan..."></b-form-input>
                    </div>
                    <!-- <div class="w-custom-30">
                      <b-form-input type="text" class="bg-white custom-form" placeholder="Ke..."></b-form-input>
                    </div> -->
                  </div>
                </b-col>
              </b-row>
              <!-- <b-row class="align-items-center mb-1">
                <b-col cols="12" xl="4">
                  <div>
                    <p class="text-dark custom-font m-0 p-0">No. Penerimaan</p>
                  </div>
                </b-col>
                <b-col cols="12" xl="8">
                  <div>
                    <b-form-input type="text" class="bg-white custom-form" placeholder="No. Penerimaan..."></b-form-input>
                  </div>
                </b-col>
              </b-row> -->
              <b-row class="align-items-center mb-1">
                <b-col cols="12" xl="4">
                  <div>
                    <p class="text-dark custom-font text-uppercase m-0 p-0">Tanggal / Tipe Doc</p>
                  </div>
                </b-col>
                <b-col cols="12" xl="8">
                  <div class="d-flex">
                    <div class="w-50 mr-1">
                      <flat-pickr :config="configTgl" v-model="tanggal.tgl_doc" value=""
                        @on-change="valueTgl($event, 'tgl_doc')" placeholder="Tanggal"
                        class="w-100 custom-flatpicker calender-small" />
                    </div>
                    <div class="w-50">
                      <multiselect :show-labels="false" :allow-empty="false" label="deskripsi" placeholder="Tipe Doc"
                        :options="options.tipe_doc" v-model="selected.tipe_doc" :searchable="true"
                        @input="valueSelectize($event, 'tipe_doc', '')">
                      </multiselect>
                    </div>
                  </div>
                </b-col>
              </b-row>
              <b-row class="align-items-center mb-1">
                <b-col cols="12" xl="4">
                  <div>
                    <p class="text-dark custom-font text-uppercase m-0 p-0">Nomor Faktur</p>
                  </div>
                </b-col>
                <b-col cols="12" xl="8">
                  <div>
                    <b-form-input v-model="form.header.no_faktur" type="text" class="bg-white text-dark custom-form"
                      placeholder="No. Faktur..."></b-form-input>
                  </div>
                </b-col>
              </b-row>
              <b-row class="align-items-center mb-1">
                <b-col cols="12" xl="4">
                  <div>
                    <p class="text-dark custom-font text-uppercase m-0 p-0">Nomor Surat Jalan</p>
                  </div>
                </b-col>
                <b-col cols="12" xl="8">
                  <div>
                    <b-form-input v-model="form.header.no_suratjalan" type="text" class="bg-white text-dark custom-form"
                      placeholder="No. Surat Jalan..."></b-form-input>
                  </div>
                </b-col>
              </b-row>
              <!-- <b-row class="align-items-center mb-1">
                <b-col cols="12" xl="4">
                  <div>
                    <p class="text-dark custom-font m-0 p-0">Distributor</p>
                  </div>
                </b-col>
                <b-col cols="12" xl="8">
                  <div>
                    <b-form-input type="text" class="bg-white custom-form" placeholder="Distributor..."></b-form-input>
                  </div>
                </b-col>
              </b-row> -->
              <b-row class="align-items-center mb-0">
                <b-col cols="12" xl="4">
                  <div>
                    <p class="text-dark custom-font text-uppercase m-0 p-0">Tanggal Faktur</p>
                  </div>
                </b-col>
                <b-col cols="12" xl="8">
                  <div>
                    <flat-pickr :config="configTgl" v-model="tanggal.tgl_faktur" value=""
                      @on-change="valueTgl($event, 'tgl_faktur')" placeholder="Tanggal"
                      class="w-100 custom-flatpicker calender-small" />
                  </div>
                </b-col>
              </b-row>
            </div>
          </b-col>
          <b-col cols="12" lg="3" xl="3">
            <div>
              <b-row class="align-items-center mb-1">
                <b-col cols="12" xl="5">
                  <div>
                    <p class="text-dark custom-font m-0 p-0">Nilai Total</p>
                  </div>
                </b-col>
                <b-col cols="12" xl="7">
                  <div>
                    <b-form-input disabled v-model="nilai.nilai_total" @input="convertRibuan($event, 'nilai_total', '')"
                      @keypress="isNumberKey" type="text" class="bg-white text-dark custom-form text-right custom-spacing"
                      placeholder="Nilai Total..."></b-form-input>
                  </div>
                </b-col>
              </b-row>
              <b-row class="align-items-center mb-1">
                <b-col cols="12" xl="5">
                  <div>
                    <p class="text-dark custom-font m-0 p-0">Nilai Diskon</p>
                  </div>
                </b-col>
                <b-col cols="12" xl="7">
                  <div>
                    <b-form-input disabled v-model="nilai.nilai_diskon" @input="convertRibuan($event, 'nilai_diskon', '')"
                      @keypress="isNumberKey" type="text" class="bg-white text-dark custom-form text-right custom-spacing"
                      placeholder="Nilai Diskon..."></b-form-input>
                  </div>
                </b-col>
              </b-row>
              <b-row class="align-items-center mb-1">
                <b-col cols="12" xl="5">
                  <div>
                    <p class="text-dark custom-font m-0 p-0">PPN</p>
                  </div>
                </b-col>
                <b-col cols="12" xl="7">
                  <div>
                      <b-form-checkbox
                        v-model="form.header.ppn"
                        value="1"
                        unchecked-value="0"
                        @change="countPpn"
                      >
                        PPN 11%
                      </b-form-checkbox>
                  </div>
                </b-col>
              </b-row>
              <b-row class="align-items-center mb-1">
                <b-col cols="12" xl="5">
                  <div>
                    <p class="text-dark custom-font text-uppercase m-0 p-0">Nilai PPN</p>
                  </div>
                </b-col>
                <b-col cols="12" xl="7">
                  <div>
                    <b-form-input disabled v-model="nilai.nilai_ppn" @input="convertRibuan($event, 'nilai_ppn', '')"
                      @keypress="isNumberKey" type="text" class="bg-white text-dark custom-form text-right custom-spacing"
                      placeholder="Nilai PPN..."></b-form-input>
                  </div>
                </b-col>
              </b-row>
              <b-row class="align-items-center mb-1">
                <b-col cols="12" xl="5">
                  <div>
                    <p class="text-dark custom-font text-uppercase m-0 p-0">Nilai Pembulatan</p>
                  </div>
                </b-col>
                <b-col cols="12" xl="7">
                  <div>
                    <b-form-input disabled v-model="nilai.nilai_pembulatan"
                      @input="convertRibuan($event, 'nilai_pembulatan', '')" @keypress="isNumberKey" type="text"
                      class="bg-white text-dark custom-form text-right custom-spacing" placeholder="Nilai Pembuatan..."></b-form-input>
                  </div>
                </b-col>
              </b-row>
              <b-row class="align-items-center mb-1">
                <b-col cols="12" xl="5">
                  <div>
                    <p class="text-dark custom-font text-uppercase m-0 p-0">Nilai Akhir</p>
                  </div>
                </b-col>
                <b-col cols="12" xl="7">
                  <div>
                    <b-form-input disabled v-model="nilai.nilai_akhir" @input="convertRibuan($event, 'nilai_akhir', '')"
                      @keypress="isNumberKey" type="text" class="bg-white text-dark custom-form text-right custom-spacing"
                      placeholder="Nilai Akhir..."></b-form-input>
                  </div>
                </b-col>
              </b-row>
            </div>
          </b-col>
          <b-col cols="12" lg="4">
            <table class="bg-white table table-bordered table-sm">
              <thead>
                <tr>
                  <th class="text-center col-1">
                    NO
                  </th>
                  <th class="text-center col-1">
                    VER
                  </th>
                  <th class="text-center col-3">
                    OTORISASI
                  </th>
                  <th class="text-center col-4">
                    USER
                  </th>
                  <th class="text-center col-3">
                    TANGGAL
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td class="text-center p-1">1</td>
                  <td class="text-center">
                    <b-form-checkbox id="ver_terima" name="ver_terima" value="1" v-model="verifikasi.ver_terima"
                      @change="verifikasiType($event, 'ver_terima')" unchecked-value="0">
                    </b-form-checkbox>
                  </td>
                  <td class="text-center">
                    <span v-if="verifikasi.ver_terima === '1'">Tim Terima</span>
                  </td>
                  <td class="text-center">
                    <span
                    v-if="verifikasi.ver_terima === '1'"> {{ verifikasi_name.ver_terima }}
                    </span>
                  </td>
                  <td class="text-center">
                    <span
                        v-if="verifikasi.ver_terima === '1'">{{
                        form.header.ver_tglterima.split('-').reverse().join('-') }}
                      </span>
                  </td>
                </tr>
                <tr>
                  <td class="text-center p-1">2</td>
                  <td class="text-center">
                    <b-form-checkbox id="ver_gudang" name="ver_gudang" value="1" v-model="verifikasi.ver_gudang"
                      @change="verifikasiType($event, 'ver_gudang')" unchecked-value="0">
                    </b-form-checkbox>
                  </td>
                  <td class="text-center">
                    <span v-if="verifikasi.ver_gudang === '1'">Gudang</span>
                  </td>
                  <td class="text-center">
                    <span
                    v-if="verifikasi.ver_gudang === '1'"> {{ verifikasi_name.ver_gudang }}
                    </span>
                  </td>
                  <td class="text-center">
                    <span
                        v-if="verifikasi.ver_gudang === '1'">{{
                        form.header.ver_tglgudang.split('-').reverse().join('-') }}
                      </span>
                  </td>
                </tr>
                <tr>
                  <td class="text-center p-1">3</td>
                  <td class="text-center">
                    <b-form-checkbox id="ver_akuntansi" name="ver_akuntansi" value="1"
                      v-model="verifikasi.ver_akuntansi" @change="verifikasiType($event, 'ver_akuntansi')"
                      unchecked-value="0">
                    </b-form-checkbox>
                  </td>
                  <td class="text-center">
                    <span v-if="verifikasi.ver_akuntansi === '1'">Akutansi</span>
                  </td>
                  <td class="text-center">
                    <span
                    v-if="verifikasi.ver_akuntansi === '1'"> {{ verifikasi_name.ver_akuntansi }}
                    </span>
                  </td>
                  <td class="text-center">
                    <span
                        v-if="verifikasi.ver_akuntansi === '1'">{{
                        form.header.ver_tglakuntansi.split('-').reverse().join('-') }}
                      </span>
                  </td>
                </tr>
              </tbody>
            </table>
          </b-col>
        </b-row>
        <hr>
        <div>
          <div class="d-flex flex-row justify-content-between align-items-center mb-2">
            <div>
              <p class="m-0 p-0 text-dark font-weight-bold">TABEL DETAIL</p>
            </div>
          </div>
          <table class="bg-white table table-bordered table-sm">
            <thead>
              <tr>
                <th colspan="3"></th>
                <!-- <th colspan="2" class="text-center">KEMASAN</th> -->
                <!-- <th colspan="1" class="text-center"></th> -->
                <th colspan="2" class="text-center">JUMLAH</th>
                <th colspan="2" class="text-center">HARGA</th>
                <th colspan="2" class="text-center">DISKON</th>
                <th colspan="1" class="text-center">
                  <div class="bg-primary py-1 m-0 text-center shadow-sm border rounded-sm my-cursor" @click="addRow">
                    <span>
                      <i class="fas fa-plus"></i>
                    </span>
                  </div>
                </th>
              </tr>
              <tr>
                <td class="font-weight-normal text-center">
                  <span>NO</span>
                </td>
                <td class="col-2 font-weight-normal text-center">
                  <span>BARANG</span>
                </td>
                <!-- <td class=" col-1 font-weight-normal text-center">
                  <span>JENIS</span>
                </td> -->
                <!-- <td class="font-weight-normal text-center">
                  <span>ISI</span>
                </td> -->
                <td class="col-2 font-weight-normal text-center">
                  <span>KETERANGAN</span>
                </td>
                <td class="font-weight-normal text-center">
                  <span>ITEM</span>
                </td>
                <td class="font-weight-normal text-center">
                  <span>KEMASAN</span>
                </td>
                <!-- <td class="font-weight-normal text-center">
                  <span>ITEM BONUS</span>
                </td> -->
                <td class="font-weight-normal text-center">
                  <span>ITEM</span>
                </td>
                <td class="font-weight-normal text-center">
                  <span>KEMASAN</span>
                </td>
                <td class="font-weight-normal text-center">
                  <span>PERSEN</span>
                </td>
                <td class="font-weight-normal text-center">
                  <span>HARGA</span>
                </td>
                <td class="font-weight-normal text-center">
                  <span>ACTION</span>
                </td>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(d, index) in form.detail" :key="d.index">
                <td class="text-center">{{ index + 1 }}</td>
                <td>
                  <multiselect :show-labels="false" :allow-empty="false" label="deskripsi" placeholder="Barang"
                    :options="options.barang_rt" :searchable="true" v-model="selected.id_katalog[index].detail"
                    @input="valueSelectize($event, 'id_katalog', index)">
                  </multiselect>
                </td>
                <!-- <td>
                  <multiselect :show-labels="false" :allow-empty="false" label="deskripsi" placeholder="Jenis"
                    :options="options.kemasan_rt" :searchable="true" v-model="selected.id_kemasan[index].detail"
                    @input="valueSelectize($event, 'id_kemasan', index)">
                  </multiselect>
                </td> -->
                <!-- <td>
                  <span>
                    <b-form-input v-model="nilai.isi_kemasan[index].value"
                      @input="convertRibuan($event, 'isi_kemasan', index)" @keypress="isNumberKey" type="text"
                      class="bg-white text-dark custom-form" placeholder="Kemasan..."></b-form-input>
                  </span>
                </td> -->
                <td class="py-1">
                  <span>
                    <b-form-input v-model="d.keterangan" type="text" class="bg-white text-dark custom-form"
                      placeholder="Keterangan..."></b-form-input>
                  </span>
                </td>
                <td>
                  <span>
                    <b-form-input v-model="nilai.jumlah_item[index].value"
                      @input="count($event, 'jumlah_item', index)" @keypress="isNumberKey" type="text"
                      class="bg-white text-dark custom-form custom-spacing" placeholder="Item..."></b-form-input>
                  </span>
                </td>
                <td>
                  <span>
                    <b-form-input v-model="nilai.jumlah_kemasan[index].value"
                      @input="count($event, 'jumlah_kemasan', index)" @keypress="isNumberKey" type="text"
                      class="bg-white text-dark custom-form custom-spacing" placeholder="Kemasan..."></b-form-input>
                  </span>
                </td>
                <!-- <td>
                  <span>
                    <b-form-input v-model="nilai.jumlah_itembonus[index].value"
                      @input="count($event, 'jumlah_itembonus', index)" @keypress="isNumberKey" type="text"
                      class="bg-white text-dark custom-form custom-spacing" placeholder="Item Bonus..."></b-form-input>
                  </span>
                </td> -->
                <td>
                  <span>
                    <b-form-input v-model="nilai.harga_item[index].value"
                      @input="count($event, 'harga_item', index)" @keypress="isNumberKey" type="text"
                      class="bg-white text-dark custom-form custom-spacing" placeholder="Item..."></b-form-input>
                  </span>
                </td>
                <td>
                  <span>
                    <b-form-input v-model="nilai.harga_kemasan[index].value"
                      @input="count($event, 'harga_kemasan', index)" @keypress="isNumberKey" type="text"
                      class="bg-white text-dark custom-form custom-spacing" placeholder="Kemasan..."></b-form-input>
                  </span>
                </td>
                <td>
                  <span>
                    <b-form-input v-model="nilai.diskon_item[index].value"
                      @input="count($event, 'diskon_item', index)" @keypress="isNumberKey" type="text"
                      class="bg-white text-dark custom-form custom-spacing" placeholder="Item..."></b-form-input>
                  </span>
                </td>
                <td>
                  <span>
                    <b-form-input v-model="nilai.diskon_harga[index].value"
                      @input="count($event, 'diskon_harga', index)" @keypress="isNumberKey" type="text"
                      class="bg-white text-dark custom-form custom-spacing" placeholder="Harga..."></b-form-input>
                  </span>
                </td>
                <td class="text-center">
                  <span>
                    <b-button :disabled="index !== 0 ? false : true" variant="white" size="sm"
                      @click="deleteRow(index)"><i
                        :class="['fas fa-trash-alt', index !== 0 ? 'text-danger' : 'text-secondary']"></i></b-button>
                  </span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div v-if="message.code === 'modal'"
        :class="['shadow-sm border px-2 py-1 d-flex flex-row justify-content-between', 'bg-' + message.variant]">
        <div>
          <p class="small m-0 p-0">{{ message.desc }}</p>
        </div>
        <div>
          <p class="small m-0 p-0 my-cursor" @click="clearMessage"><i class="fas fa-times fa-lg"></i></p>
        </div>
      </div>
      <!-- <pre>{{ form }}</pre>
      <pre>{{ selected }}</pre> -->
    </b-modal>
    <b-modal id="modal-cetak-penerimaan" ref="modal-cetak-penerimaan" size="xl" title="CETAK" ok-title="CETAK"
      cancel-title="Tutup" :no-close-on-backdrop="true" :static="true" @ok="print" scrollable centered>
      <section id="print-area">
        <div id="printCoba">
          <table style="width: 100%; margin-bottom: 1.5rem;">
            <tbody>
              <tr>
                <td>
                  <span style="font-size: 1rem; font-weight: bold; color: black;">RS FATMAWATI</span>
                </td>
              </tr>
              <tr>
                <td>
                  <span style="font-size: 1rem; color: black;">GUDANG : GUDANG INDUK FARMASI</span>
                </td>
                <td style="text-align: right;">
                  <span style="font-size: 1rem; color: black;">Tanggal: 31-05-2024</span>
                </td>
              </tr>
            </tbody>
          </table>
          <table style="width: 100%; margin-bottom: 1.5rem;">
            <tbody>
              <tr>
                <td style="width: 35%;">
                  <span style="font-size: .9rem; color: black; text-transform: capitalize;"></span>
                </td>
                <!-- !-->
                <td style="width: 15%;">
                  <span style="font-size: .9rem; color: black; text-transform: capitalize;">No. Terima </span>
                </td>
                <td style="width: 5%;">
                  <span style="font-size: .9rem; color: black;">:</span>
                </td>
                <td style="width: 10%;">
                  <span style="font-size: .9rem; color: black; text-transform: uppercase;">{{ formCetak.header.no_terima
                    }}</span>
                </td>
                <!-- !-->
                <td style="width: 35%;">
                  <span style="font-size: .9rem; color: black; text-transform: capitalize;"></span>
                </td>
              </tr>
              <tr>
                <td style="width: 35%;">
                  <span style="font-size: .9rem; color: black; text-transform: capitalize;"></span>
                </td>
                <!-- !-->
                <td style="width: 15%;">
                  <span style="font-size: .9rem; color: black; text-transform: capitalize;">No. BTB </span>
                </td>
                <td style="width: 5%;">
                  <span style="font-size: .9rem; color: black;">:</span>
                </td>
                <td style="width: 10%;">
                  <span style="font-size: .9rem; color: black; text-transform: uppercase;">{{ formCetak.header.no_btb
                    }}</span>
                </td>
                <!-- !-->
                <td style="width: 35%;">
                  <span style="font-size: .9rem; color: black; text-transform: capitalize;"></span>
                </td>
              </tr>
              <tr>
                <td style="width: 35%;">
                  <span style="font-size: .9rem; color: black; text-transform: capitalize;"></span>
                </td>
                <!-- !-->
                <td style="width: 15%;">
                  <span style="font-size: .9rem; color: black; text-transform: capitalize;">No. Faktur </span>
                </td>
                <td style="width: 5%;">
                  <span style="font-size: .9rem; color: black;">:</span>
                </td>
                <td style="width: 10%;">
                  <span style="font-size: .9rem; color: black; text-transform: uppercase;">{{ formCetak.header.no_faktur
                    }}</span>
                </td>
                <!-- !-->
                <td style="width: 35%;">
                  <span style="font-size: .9rem; color: black; text-transform: capitalize;"></span>
                </td>
              </tr>
              <tr>
                <td style="width: 35%;">
                  <span style="font-size: .9rem; color: black; text-transform: capitalize;"></span>
                </td>
                <!-- !-->
                <td style="width: 15%;">
                  <span style="font-size: .9rem; color: black; text-transform: capitalize;">No. SJ </span>
                </td>
                <td style="width: 5%;">
                  <span style="font-size: .9rem; color: black;">:</span>
                </td>
                <td style="width: 10%;">
                  <span style="font-size: .9rem; color: black; text-transform: uppercase;">{{
                    formCetak.header.no_suratjalan }}</span>
                </td>
                <!-- !-->
                <td style="width: 35%;">
                  <span style="font-size: .9rem; color: black; text-transform: capitalize;"></span>
                </td>
              </tr>
              <tr>
                <td style="width: 35%;">
                  <span style="font-size: .9rem; color: black; text-transform: capitalize;"></span>
                </td>
                <!-- !-->
                <td style="width: 15%;">
                  <span style="font-size: .9rem; color: black; text-transform: capitalize;">No. SP/SPK </span>
                </td>
                <td style="width: 5%;">
                  <span style="font-size: .9rem; color: black;">:</span>
                </td>
                <td style="width: 10%;">
                  <span style="font-size: .9rem; color: black; text-transform: uppercase;">{{ formCetak.header.no_sp_spk
                    }}</span>
                </td>
                <!-- !-->
                <td style="width: 35%;">
                  <span style="font-size: .9rem; color: black; text-transform: capitalize;"></span>
                </td>
              </tr>
              <tr>
                <td style="width: 35%;">
                  <span style="font-size: .9rem; color: black; text-transform: capitalize;"></span>
                </td>
                <!-- !-->
                <td style="width: 15%;">
                  <span style="font-size: .9rem; color: black; text-transform: capitalize;">No. SPB </span>
                </td>
                <td style="width: 5%;">
                  <span style="font-size: .9rem; color: black;">:</span>
                </td>
                <td style="width: 10%;">
                  <span style="font-size: .9rem; color: black; text-transform: uppercase;">{{ formCetak.header.no_spb
                    }}</span>
                </td>
                <!-- !-->
                <td style="width: 35%;">
                  <span style="font-size: .9rem; color: black; text-transform: capitalize;"></span>
                </td>
              </tr>
            </tbody>
          </table>
          <table style="width: 100%; margin-bottom: 1.5rem;">
            <tbody>
              <tr>
                <td style="text-align: center;">
                  <span style="font-size: 1.2rem; font-weight: bold; color: black;">BUKTI PENYERAHAN BARANG</span>
                </td>
              </tr>
            </tbody>
          </table>
          <table style="width:100%; color: black;">
            <tr style="border: 1px solid black; background: #00000050;">
              <th style="border: 1px solid black; text-align: center">NO</th>
              <th style="border: 1px solid black; text-align: center">NAMA BARANG</th>
              <th style="border: 1px solid black; text-align: center">PABRIK</th>
              <th style="border: 1px solid black; text-align: center">KUANTITAS</th>
              <th style="border: 1px solid black; text-align: center">SATUAN</th>
              <th style="border: 1px solid black; text-align: center">HARGA</th>
              <th style="border: 1px solid black; text-align: center">DISKON</th>
              <th style="border: 1px solid black; text-align: center">JUMLAH</th>
            </tr>
            <tr style="border: 1px solid black;" v-for="(f, index) in formCetak.details" :key="f.index">
              <td style="border: 1px solid black; text-align: center;">{{ index + 1 }}</td>
              <td style="border: 1px solid black; padding: 3px;">{{ f.nama_barang }}</td>
              <td style="border: 1px solid black; padding: 3px;">{{ formCetak.header.pabrik }}</td>
              <td style="border: 1px solid black; padding: 3px;">{{ formatRibuan(f.kuantitas) }}</td>
              <td style="border: 1px solid black; padding: 3px;">{{ f.satuan }}</td>
              <td style="border: 1px solid black; padding: 3px;">{{ formatRibuan(f.harga) }}</td>
              <td style="border: 1px solid black; padding: 3px;">{{ f.diskon }}</td>
              <td style="border: 1px solid black; padding: 3px; text-align: right;">{{ formatRibuan(f.jumlah) }}</td>
            </tr>
            <tr style="border: 1px solid black;">
              <td rowspan="4" style="border: 1px solid black;"></td>
              <td colspan="6" style="border: 1px solid black; padding: 3px;">JUMLAH</td>
              <td style="border: 1px solid black; text-align: right; padding: 3px;">{{
                formatRibuan(formCetak.header.jumlah) }}</td>
            </tr>
            <tr style="border: 1px solid black;">
              <td colspan="6" style="border: 1px solid black; padding: 3px;">PPN</td>
              <td style="border: 1px solid black; text-align: right; padding: 3px;">{{ formCetak.header.ppn }}</td>
            </tr>
            <tr style="border: 1px solid black;">
              <td colspan="6" style="border: 1px solid black; padding: 3px;">PEMBULATAN</td>
              <td style="border: 1px solid black; text-align: right; padding: 3px;">{{ formCetak.header.nilai_pembulatan
                }}</td>
            </tr>
            <tr style="border: 1px solid black;">
              <td colspan="6" style="border: 1px solid black; padding: 3px;">TOTAL</td>
              <td style="border: 1px solid black; text-align: right; padding: 3px;">{{
                formatRibuan(formCetak.header.nilai_akhir) }}</td>
            </tr>
            <tr style="border: 1px solid black;">
              <td colspan="8" style="padding: 3px; border: 1px solid black;">TERBILANG : SERATUS RIBU RUPIAH</td>
            </tr>
          </table>
          <table style="width:70%; margin-top: 20px; color: black;">
            <tr style="border: 1px solid black; background: #00000050;">
              <th style="border: 1px solid black; text-align: center">NO</th>
              <th style="border: 1px solid black; text-align: center">VER</th>
              <th style="border: 1px solid black; text-align: center">OTORISASI</th>
              <th style="border: 1px solid black; text-align: center">USER</th>
              <th style="border: 1px solid black; text-align: center">TANGGAL</th>
            </tr>
            <tr style="border: 1px solid black;">
              <td style="border: 1px solid black; text-align: center; padding: 3px;">1</td>
              <td style="border: 1px solid black; text-align: center; padding: 3px;">{{ formCetak.header.ver_terima }}
              </td>
              <td style="border: 1px solid black; text-align: left; padding: 3px;">Tim Terima</td>
              <td style="border: 1px solid black; text-align: left; padding: 3px;">{{
                formCetak.header.desc_ver_usrterima }}2</td>
              <td style="border: 1px solid black; text-align: left; padding: 3px;">{{
                convertDate(formCetak.header.ver_tglterima, 'use') }}</td>
            </tr>
            <tr style="border: 1px solid black;">
              <td style="border: 1px solid black; text-align: center; padding: 3px;">2</td>
              <td style="border: 1px solid black; text-align: center; padding: 3px;">{{ formCetak.header.ver_gudang }}
              </td>
              <td style="border: 1px solid black; text-align: left; padding: 3px;">Gudang</td>
              <td style="border: 1px solid black; text-align: left; padding: 3px;">{{
                formCetak.header.desc_ver_usrgudang }}2</td>
              <td style="border: 1px solid black; text-align: left; padding: 3px;">{{
                convertDate(formCetak.header.ver_tglgudang, 'use') }}</td>
            </tr>
            <tr style="border: 1px solid black;">
              <td style="border: 1px solid black; text-align: center; padding: 3px;">3</td>
              <td style="border: 1px solid black; text-align: center; padding: 3px;">{{ formCetak.header.ver_akuntansi
                }}</td>
              <td style="border: 1px solid black; text-align: left; padding: 3px;">Akutansi</td>
              <td style="border: 1px solid black; text-align: left; padding: 3px;">{{
                formCetak.header.desc_ver_usrakuntansi }}2</td>
              <td style="border: 1px solid black; text-align: left; padding: 3px;">{{
                convertDate(formCetak.header.ver_tglakuntansi, 'use') }}</td>
            </tr>
          </table>
        </div>
      </section>
      <div v-if="message.code === 'modal'"
        :class="['shadow-sm border px-2 py-1 d-flex flex-row justify-content-between', 'bg-' + message.variant]">
        <div>
          <p class="small m-0 p-0">{{ message.desc }}</p>
        </div>
        <div>
          <p class="small m-0 p-0 my-cursor" @click="clearMessage"><i class="fas fa-times fa-lg"></i></p>
        </div>
      </div>
    </b-modal>
  </div>
</template>
<script>
import Loader from '../../../components/xray/loader/Loader'
import { xray } from '../../../config/pluginInit'
import Service from '../../../services/fatmahost/index'
import Multiselect from 'vue-multiselect'
import flatPickr from 'vue-flatpickr-component'
import { Indonesian as IndonesianLocale } from 'flatpickr/dist/l10n/id.js'
import 'flatpickr/dist/flatpickr.css'

export default {
  name: 'Penerimaan',
  components: {
    Multiselect,
    flatPickr,
    Loader
  },
  data () {
    return {
      isLoading: false,
      modal_status: '',
      modal_title: '',
      id_show: '',
      message: {
        desc: '',
        code: '',
        variant: ''
      },
      table: {
        isBusy: false,
        items: this.list,
        fields: [
          { label: 'NO.', key: 'index', class: 'text-lg-center text-dark' },
          { label: 'TGL. TERIMA', key: 'tgl_doc', class: 'text-dark' },
          { label: 'NO. TERIMA', key: 'no_doc', class: 'text-dark' },
          { label: 'FAKTUR / SURAT JALAN', key: 'faktur_sj', class: 'text-dark' },
          { label: 'DO PEMESANAN', key: 'no_po', class: 'text-dark' },
          { label: 'PL PEMBELIAN', key: 'no_spk', class: 'text-dark' },
          { label: 'SUPPLIER', key: 'nama_pbf', class: 'text-dark' },
          { label: 'MATA ANGGARAN', key: 'kode_jenis', class: 'text-dark' },
          { label: 'ANGGARAN', key: 'bln_anggaran', class: 'text-dark' },
          // { label: 'MATA ANGGARAN', key: 'kode_jenis', class: 'text-dark' },
          // { label: 'MATA ANGGARAN', key: 'kode_jenis', class: 'text-dark' },
          { label: 'AKSI', key: 'action', class: 'text-dark text-center' }
        ],
        filter: null,
        filterOn: [],
        pageOptions: [10, 50, 100, 500],
        currentPage: 1,
        perPage: 10,
        totalRows: null,
        sortBy: '',
        sortDesc: false,
        selectMode: 'single'
      },
      form: {
        object: 'penerimaan-rumah-tangga',
        header: {
          id_rekanan: '',
          id_jenisanggaran: '',
          keterangan: '',
          tgl_doc: '',
          tgl_faktur: '',
          tipe_doc: '',
          no_faktur: '',
          no_suratjalan: '',
          ppn: '0',
          nilai_total: '',
          nilai_diskon: '',
          nilai_ppn: '',
          nilai_pembulatan: '',
          nilai_akhir: '',
          ver_terima: '0',
          ver_tglterima: '',
          ver_usrterima: '0',
          // update gudang
          ver_gudang: '',
          ver_tglgudang: '',
          ver_usrgudang: '',
          // update akutansi
          ver_akuntansi: '',
          ver_tglakuntansi: '',
          ver_usrakuntansi: '',
          //
          userid_in: xray.currentUser().id.toString(),
          userid_updt: xray.currentUser().id.toString()
        },
        detail: [
          {
            id: 0,
            id_katalog: '',
            id_kemasan: '',
            isi_kemasan: '',
            jumlah_item: '',
            jumlah_kemasan: '',
            jumlah_itembonus: '0',
            harga_item: '',
            harga_kemasan: '',
            diskon_item: '',
            diskon_harga: '',
            keterangan: '',
            userid_updt: xray.currentUser().id.toString()
          }
        ]
      },
      formCetak: {
        header: {
          no_terima: '',
          no_btb: '',
          no_faktur: '',
          no_suratjalan: '',
          no_sp_spk: '',
          no_spb: '',
          pabrik: '',
          jumlah: '',
          ppn: '0',
          nilai_pembulatan: '0',
          nilai_akhir: '',
          ver_terima: '0',
          ver_tglterima: '',
          desc_ver_usrterima: '',
          ver_gudang: '0',
          ver_tglgudang: '',
          desc_ver_usrgudang: '',
          ver_akuntansi: '0',
          ver_tglakuntansi: '',
          desc_ver_usrakuntansi: ''
        },
        details: [
          {
            nama_barang: '',
            kuantitas: '',
            satuan: '',
            harga: '',
            diskon: '',
            jumlah: ''
          }
        ]
      },
      filtering: {
        kode: '',
        nama: '',
        tglAwal: '',
        tglAkhir: ''
      },
      paramSelectize: [
        { param0: 'rekanan-rt' },
        { param0: 'kemasan-rt' },
        { param0: 'kelompok-rt' },
        { param0: 'jenis-anggaran-rt' },
        { param0: 'tipe-doc' }
      ],
      options: {
        rekanan_rt: [],
        kemasan_rt: [],
        kelompok_rt: [],
        jenis_anggaran_rt: [],
        tipe_doc: [],
        barang_rt: []
      },
      selected: {
        rekanan_rt: [],
        kemasan_rt: [],
        kelompok_rt: [],
        jenis_anggaran_rt: [],
        tipe_doc: [],
        barang_rt: [],
        id_katalog: [{ detail: [] }],
        id_kemasan: [{ detail: [] }]
      },
      configTgl: {
        locale: IndonesianLocale,
        disableMobile: true,
        dateFormat: 'd-m-Y',
        maxDate: 'today',
        mode: 'range'
      },
      tanggal: {
        tgl_doc: '',
        tgl_faktur: ''
      },
      verifikasi: {
        ver_terima: '',
        ver_gudang: '',
        ver_akuntansi: ''
      },
      verifikasi_name: {
        ver_terima: xray.currentUser().name,
        ver_gudang: xray.currentUser().name,
        ver_akuntansi: xray.currentUser().name
      },
      rowData: {},
      nilai: {
        nilai_total: '',
        nilai_diskon: '',
        nilai_ppn: '',
        nilai_pembulatan: '0',
        nilai_akhir: '',
        // detail
        isi_kemasan: [{ value: '' }],
        jumlah_item: [{ value: '' }],
        jumlah_kemasan: [{ value: '' }],
        jumlah_itembonus: [{ value: '0' }],
        harga_item: [{ value: '' }],
        harga_kemasan: [{ value: '' }],
        diskon_item: [{ value: '' }],
        diskon_harga: [{ value: '' }]
      }
    }
  },
  mounted () {
    xray.index()
    this.getSelectize()
  },
  methods: {
    toPenerimaanDetail (idterima, action) {
      if (action === 'tambah') {
        this.$router.push({
          name: 'rumahtangga.penerimaan-detail'
        })
      }
      if (action === 'edit') {
        this.$router.push({
          name: 'rumahtangga.penerimaan-detail', query: { idterima }
        })
      }
    },
    convertDate (value, param) {
      const date = param === 'funct' ? new Date() : new Date(value)
      const day = String(date.getDate()).padStart(2, '0')
      const month = String(date.getMonth() + 1).padStart(2, '0')
      const year = String(date.getFullYear())
      const hours = String(date.getHours()).padStart(2, '0')
      const minutes = String(date.getMinutes()).padStart(2, '0')
      const seconds = String(date.getSeconds()).padStart(2, '0')
      if (param === 'use') return day + '-' + month + '-' + year + ' ' + hours + ':' + minutes + ':' + seconds
      if (param === 'func' || param === 'funct') return year + '-' + month + '-' + day
    },
    count ($event, param, index) {
      if (param === 'jumlah_item') {
        const param2 = 'jumlah_kemasan'
        const a = parseFloat($event)
        const x = parseFloat(this.form.detail[index].isi_kemasan)
        const result = String(a * x)
        this.convertRibuan($event, param, index)
        this.convertRibuan(result, param2, index)
      }
      if (param === 'jumlah_kemasan') {
        const param2 = 'jumlah_item'
        const b = parseFloat($event)
        const x = parseFloat(this.form.detail[index].isi_kemasan)
        const result = String(b / x)
        this.convertRibuan($event, param, index)
        this.convertRibuan(result, param2, index)
      }
      //
      if (param === 'harga_item') {
        const param2 = 'harga_kemasan'
        const c = parseFloat($event)
        const x = parseFloat(this.form.detail[index].isi_kemasan)
        const result = String(c * x)
        this.convertRibuan($event, param, index)
        this.convertRibuan(result, param2, index)
      }
      if (param === 'harga_kemasan') {
        const param2 = 'harga_item'
        const d = parseFloat($event)
        const x = parseFloat(this.form.detail[index].isi_kemasan)
        const result = String(d / x)
        this.convertRibuan($event, param, index)
        this.convertRibuan(result, param2, index)
      }
      //
      if (param === 'diskon_item') {
        const param2 = 'diskon_harga'
        const e = parseFloat($event)
        const c = parseFloat(this.form.detail[index].harga_item)
        const result = String((e * c) / 100)
        this.convertRibuan($event, param, index)
        this.convertRibuan(result, param2, index)
      }
      if (param === 'diskon_harga') {
        const param2 = 'diskon_item'
        const f = parseFloat($event)
        const c = parseFloat(this.form.detail[index].harga_item)
        const result = String((f * 100) / c)
        this.convertRibuan($event, param, index)
        this.convertRibuan(result, param2, index)
      }
      this.countNilaiTotal()
      this.countDiskon()
      this.countPpn()
      this.countPembulatan()
      this.countAkhir()
    },
    countNilaiTotal () {
      let jumlahItem = null
      let hargaItem = null
      let result = null
      for (let i = 0; i < this.form.detail.length; i++) {
        jumlahItem += parseFloat(this.form.detail[i].jumlah_item)
        hargaItem += parseInt(this.form.detail[i].harga_item)
      }
      result = String(jumlahItem * hargaItem)
      let verifResult = isNaN(result) === true ? '' : result
      this.nilai.nilai_total = verifResult
      this.form.header.nilai_total = verifResult
    },
    countDiskon () {
      let jumlahItem = null
      let diskonHarga = null
      let result = null
      for (let i = 0; i < this.form.detail.length; i++) {
        jumlahItem += parseFloat(this.form.detail[i].jumlah_item)
        diskonHarga += parseInt(this.form.detail[i].diskon_harga)
      }
      result = String(jumlahItem * diskonHarga)
      let verifResult = isNaN(result) === true ? '' : result
      this.nilai.nilai_diskon = verifResult
      this.form.header.nilai_diskon = verifResult
    },
    countPpn () {
      let ppn = this.form.header.ppn === '1' ? 111 : 100
      let nilaiTotal = parseFloat(this.form.header.nilai_total)
      let diskon = parseFloat(this.form.header.nilai_diskon)
      let result = String(ppn * (nilaiTotal - diskon) / 100)
      let verifResult = isNaN(result) === true ? '' : result
      this.nilai.nilai_ppn = verifResult
      this.form.header.nilai_ppn = verifResult
    },
    countPembulatan () {
      let nilaiTotal = parseFloat(this.form.header.nilai_total)
      let diskon = parseFloat(this.form.header.nilai_diskon)
      let ppn = parseFloat(this.form.header.nilai_ppn)
      let result = nilaiTotal - diskon + ppn
      let resultB = parseFloat(result).toFixed(2)
      let a = parseInt(resultB) + 1
      let b = parseFloat(a - resultB).toFixed(2)
      let c = b === '1.00' || b === '1' ? '0' : b
      this.nilai.nilai_pembulatan = c
      this.form.header.nilai_pembulatan = c
    },
    countAkhir () {
      let nilaiTotal = parseFloat(this.form.header.nilai_total)
      let diskon = parseFloat(this.form.header.nilai_diskon)
      let ppn = parseFloat(this.form.header.nilai_ppn)
      let result = String(nilaiTotal - diskon + ppn)
      let verifResult = isNaN(result) === true ? '' : result
      this.nilai.nilai_akhir = verifResult
      this.form.header.nilai_akhir = verifResult
    },
    convertRibuan ($event, param, index) {
      let value = isNaN($event) === true ? '' : $event
      if (param === 'jumlah_item') {
        this.nilai.jumlah_item[index].value = value
        this.form.detail[index].jumlah_item = value
      }
      if (param === 'jumlah_kemasan') {
        this.nilai.jumlah_kemasan[index].value = value
        this.form.detail[index].jumlah_kemasan = value
      }
      if (param === 'harga_item') {
        this.nilai.harga_item[index].value = value
        this.form.detail[index].harga_item = value
      }
      if (param === 'harga_kemasan') {
        this.nilai.harga_kemasan[index].value = value
        this.form.detail[index].harga_kemasan = value
      }
      if (param === 'diskon_item') {
        this.nilai.diskon_item[index].value = value
        this.form.detail[index].diskon_item = value
      }
      if (param === 'diskon_harga') {
        this.nilai.diskon_harga[index].value = value
        this.form.detail[index].diskon_harga = value
      }
    },
    formatRibuan (value) {
      if (value !== '') {
        let amount = parseInt(value)
        return amount.toLocaleString()
      } else {
        return '0'
      }
    },
    isNumberKey (evt) {
      evt = (evt) || window.event
      const charCode = (evt.which) ? evt.which : evt.keyCode
      if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
        evt.preventDefault()
      } else {
        return true
      }
    },
    async getSelectize () {
      try {
        for (let i = 0; i < this.paramSelectize.length; i++) {
          const response = await Service.katalog.selectizePenerimaan(this.paramSelectize[i].param0)
          this.options[`${this.paramSelectize[i].param0.replace(/-/g, '_')}`] = response.data.data
        }
      } catch (error) {
        this.message.code = error.status
        this.message.desc = error.message
        this.message.variant = 'danger'
      }
    },
    async getSelectizeTable (data) {
      try {
        const response = await Service.katalog.selectizePenerimaanTable(data)
        this.options.barang_rt = response.data.data
      } catch (error) {
        this.message.code = error.status
        this.message.desc = error.message
        this.message.variant = 'danger'
      }
    },
    async list (ctx) {
      try {
        this.table.isBusy = true
        const body = `{
            "param1": "",
            "param2": {
              "object": "TERIMA_find",
              "xparam0": "${this.filtering.tglAwal}",
              "xparam1": "${this.filtering.tglAkhir}",
              "xparam2": "",
              "xparam3": "",
              "xparam4": "",
              "xparam5": "",
              "xparam6": "",
              "xparam7": "",
              "xparam8": "",
              "xparam9": "",
              "xpage": "${ctx.currentPage - 1}",
              "xlimit": "${ctx.perPage}"
          }
        }`
        const response = await Service.middleware.find_v2(body)
        // console.log(response)
        if (response.data.statcode === 0) {
          this.table.isBusy = false
          this.table.perPage = response.data.pagination.perPage
          this.table.currentPage = response.data.pagination.current + 1
          this.table.totalRows = response.data.pagination.total
          return response.data.data
        } else {
          this.table.isBusy = false
          return []
        }
      } catch (error) {
        this.table.isBusy = false
        this.message.code = error.status
        this.message.desc = error.message
        this.message.variant = 'danger'
        return []
      }
    },
    async show (id, param) {
      try {
        this.isLoading = true
        this.clearMessage()
        this.clearForm()
        this.deleteRow(0)
        this.form.object = param
        this.id_show = id.toString()
        this.modal_status = 'edit'
        this.modal_title = 'Edit Penerimaan Berdasarkan SP/SPK/KONTRAK'
        const response = await Service.katalog.showPenerimaan(id)
        if (response.data.statcode === 0) {
          this.isLoading = false
          this.form.header.id_rekanan = response.data.data.header.id_rekanan
          this.form.header.id_jenisanggaran = response.data.data.header.id_jenisanggaran
          this.form.header.keterangan = response.data.data.header.keterangan
          this.form.header.tgl_doc = response.data.data.header.tgl_doc
          this.form.header.tgl_faktur = response.data.data.header.tgl_faktur
          this.form.header.tipe_doc = response.data.data.header.tipe_doc
          this.form.header.no_faktur = response.data.data.header.no_faktur
          this.form.header.no_suratjalan = response.data.data.header.no_suratjalan
          this.form.header.ppn = response.data.data.header.ppn
          //
          this.form.header.nilai_akhir = response.data.data.header.nilai_akhir
          this.form.header.nilai_diskon = response.data.data.header.nilai_diskon
          this.form.header.nilai_pembulatan = response.data.data.header.nilai_pembulatan
          this.form.header.nilai_ppn = response.data.data.header.nilai_ppn
          this.form.header.nilai_total = response.data.data.header.nilai_total
          this.nilai.nilai_pembulatan = response.data.data.header.nilai_pembulatan
          this.nilai.nilai_akhir = response.data.data.header.nilai_akhir
          this.nilai.nilai_diskon = response.data.data.header.nilai_diskon
          this.nilai.nilai_pembulatan = response.data.data.header.nilai_pembulatan
          this.nilai.nilai_ppn = response.data.data.header.nilai_ppn
          this.nilai.nilai_total = response.data.data.header.nilai_total
          this.nilai.nilai_pembulatan = response.data.data.header.nilai_pembulatan
          // verifikasi
          this.verifikasi.ver_terima = response.data.data.header.ver_terima
          this.verifikasi.ver_gudang = response.data.data.header.ver_gudang
          this.verifikasi.ver_akuntansi = response.data.data.header.ver_akuntansi
          //
          this.verifikasi_name.ver_terima = response.data.data.header.desc_ver_usrterima !== '' ? response.data.data.header.desc_ver_usrterima : xray.currentUser().name
          this.verifikasi_name.ver_gudang = response.data.data.header.desc_ver_usrgudang !== '' ? response.data.data.header.desc_ver_usrgudang : xray.currentUser().name
          this.verifikasi_name.ver_akuntansi = response.data.data.header.desc_ver_usrakuntansi !== '' ? response.data.data.header.desc_ver_usrakuntansi : xray.currentUser().name
          //
          this.form.header.ver_terima = response.data.data.header.ver_terima
          this.form.header.ver_gudang = response.data.data.header.ver_gudang
          this.form.header.ver_akuntansi = response.data.data.header.ver_akuntansi
          //
          this.form.header.ver_tglterima = response.data.data.header.ver_tglterima !== '' ? this.convertDate(response.data.data.header.ver_tglterima, 'func') : ''
          this.form.header.ver_tglgudang = response.data.data.header.ver_tglgudang !== '' ? this.convertDate(response.data.data.header.ver_tglgudang, 'func') : ''
          this.form.header.ver_tglakuntansi = response.data.data.header.ver_tglakuntansi !== '' ? this.convertDate(response.data.data.header.ver_tglakuntansi, 'func') : ''
          //
          this.form.header.ver_usrterima = response.data.data.header.ver_usrterima
          this.form.header.ver_usrgudang = response.data.data.header.ver_usrgudang
          this.form.header.ver_usrakuntansi = response.data.data.header.ver_usrakuntansi
          // selected
          this.selected.rekanan_rt = { id: response.data.data.header.id_rekanan, deskripsi: response.data.data.header.nama_rekanan }
          this.selected.jenis_anggaran_rt = { id: response.data.data.header.id_jenisanggaran, deskripsi: response.data.data.header.desc_id_jenisanggaran }
          this.selected.tipe_doc = { id: response.data.data.header.tipe_doc, deskripsi: response.data.data.header.desc_tipe_doc }
          // date
          this.tanggal.tgl_doc = response.data.data.header.tgl_doc
          this.tanggal.tgl_faktur = response.data.data.header.tgl_faktur
          // func
          this.getSelectizeTable(response.data.data.header.id_jenisanggaran)
          // detail
          response.data.data.details.map((value, index) => {
            this.form.detail.push(
              {
                id: value.id,
                id_katalog: value.id_katalog,
                id_kemasan: value.id_kemasan,
                isi_kemasan: value.isi_kemasan,
                jumlah_item: value.jumlah_item,
                jumlah_kemasan: value.jumlah_kemasan,
                jumlah_itembonus: value.jumlah_itembonus,
                harga_item: value.harga_item,
                harga_kemasan: value.harga_kemasan,
                diskon_item: value.diskon_item,
                diskon_harga: value.diskon_harga,
                keterangan: value.keterangan,
                userid_updt: value.userid_updt
                //
              }
            )
            this.nilai.isi_kemasan.push({ value: value.isi_kemasan })
            this.nilai.jumlah_item.push({ value: value.jumlah_item })
            this.nilai.jumlah_kemasan.push({ value: value.jumlah_kemasan })
            this.nilai.jumlah_itembonus.push({ value: value.jumlah_itembonus })
            this.nilai.harga_item.push({ value: value.harga_item })
            this.nilai.harga_kemasan.push({ value: value.harga_kemasan })
            this.nilai.diskon_item.push({ value: value.diskon_item })
            this.nilai.diskon_harga.push({ value: value.diskon_harga })
            //
            this.selected.id_kemasan.push({ detail: [{ id: value.id_kemasan, deskripsi: value.desc_id_kemasan }] })
            this.selected.id_katalog.push({ detail: [{ id: value.id_katalog, deskripsi: value.deskripsi }] })
            //
          })
        } else {
          this.isLoading = false
          this.message.code = 'modal'
          this.message.desc = response.data.message
          this.message.variant = 'danger'
        }
        this.$refs['modal-create-update-penerimaan'].show()
      } catch (error) {
        this.isLoading = false
        this.message.code = 'modal'
        this.message.desc = error.message
        this.message.variant = 'danger'
        this.$refs['modal-create-update-penerimaan'].show()
      }
    },
    async createOrUpdate (bvModalEvent) {
      bvModalEvent.preventDefault()
      try {
        this.isLoading = true
        const response = this.modal_status === 'tambah' ? await Service.katalog.createPenerimaan(this.form) : await Service.katalog.updatePenerimaan(this.id_show, this.form)
        if (response.data.statcode === 0) {
          this.isLoading = false
          this.message.code = 'list'
          this.message.desc = response.data.message
          this.message.variant = 'success'
          this.$refs['modal-create-update-penerimaan'].hide()
        } else {
          this.isLoading = false
          this.message.code = 'modal'
          this.message.desc = response.data.message
          this.message.variant = 'danger'
        }
      } catch (error) {
        this.isLoading = false
        this.message.code = 'modal'
        this.message.desc = error.message
        this.message.variant = 'danger'
      } finally {
        this.$refs.table.refresh()
      }
    },
    async softDelete (id) {
      try {
        const data = {
          object: 'penerimaan-rumah-tangga',
          header: {
            del_status: '1',
            del_user: xray.currentUser().id.toString()
          },
          detail: [
            {}
          ]
        }
        const response = await Service.katalog.softDeletePenerimaan(id, data)
        if (response.data.statcode === 0) {
          this.isLoading = false
          this.message.code = 'list'
          this.message.desc = response.data.message
          this.message.variant = 'success'
          this.$refs['modal-create-update-penerimaan'].hide()
        } else {
          this.isLoading = false
          this.message.code = 'list'
          this.message.desc = response.data.message
          this.message.variant = 'danger'
        }
      } catch (error) {
        this.isLoading = false
        this.message.code = 'list'
        this.message.desc = error.message
        this.message.variant = 'danger'
      } finally {
        this.$refs.table.refresh()
      }
    },
    async cetak (id) {
      try {
        // this.clearForm()
        const response = await Service.katalog.cetakPenerimaan(id)
        if (response.data.statcode === 0) {
          this.formCetak = response.data.data
        } else {
          this.message.code = 'modal'
          this.message.desc = response.data.message
          this.message.variant = 'danger'
        }
      } catch (error) {
        this.message.code = 'modal'
        this.message.desc = error.message
        this.message.variant = 'danger'
      } finally {
        this.$refs['modal-cetak-penerimaan'].show()
      }
    },
    print () {
      const prtHtml = document.getElementById('printCoba')
      const Winprint = window.open('', '', 'height=1000,width=1000')
      Winprint.document.write(`<!DOCTYPE html>
      <html lang="en">
      <head>
      </head>
      <body>
      ${prtHtml.innerHTML}
      </body>
      </html>`)
      Winprint.document.close()
      Winprint.focus()
      Winprint.print()
      Winprint.close()
    },
    refreshTable () {
      this.$refs.table.refresh()
    },
    onFiltered (filteredItems) {
      this.table.currentPage = 1
      this.table.totalRows = filteredItems.length
    },
    rowClass (item, type) {
      if (!item || type !== 'row') return
      if (item.del_status === 1) return 'text-secondary'
    },
    openModal (param) {
      this.clearMessage()
      this.clearForm()
      this.form.object = 'penerimaan-rumah-tangga'
      this.modal_title = 'Tambah Penerimaan Berdasarkan SP/SPK/KONTRAK'
      this.modal_status = param
      this.$refs['modal-create-update-penerimaan'].show()
    },
    valueSelectize ($event, param, index) {
      if (param === 'id_rekanan') this.form.header.id_rekanan = $event.id.toString()
      if (param === 'tipe_doc') this.form.header.tipe_doc = $event.id.toString()
      if (param === 'id_kemasan') this.form.detail[index].id_kemasan = $event.id.toString()
      if (param === 'id_katalog') {
        this.form.detail[index].id_katalog = $event.id_katalog.toString()
        this.form.detail[index].id_kemasan = $event.id_kemasan.toString()
        this.form.detail[index].isi_kemasan = $event.isi_kemasan.toString()
        this.clearCountIdKatalog()
      }
      if (param === 'id_jenisanggaran') {
        this.form.header.id_jenisanggaran = $event.id.toString()
        this.getSelectizeTable(this.form.header.id_jenisanggaran)
        this.clearCountJenisAnggaran()
      }
    },
    valueTgl ($event, param) {
      if (param === 'tgl_doc') this.form.header.tgl_doc = this.convertDate($event, 'func')
      if (param === 'tgl_faktur') this.form.header.tgl_faktur = this.convertDate($event, 'func')
      if (param === 'tgl_list') {
        this.filtering.tglAwal = this.convertDate($event[0], 'func')
        this.filtering.tglAkhir = this.convertDate($event[1], 'func')
        this.$refs.table.refresh()
      }
    },
    verifikasiType ($event, param) {
      // tim terima
      if ($event === '1' && param === 'ver_terima') {
        this.form.header.ver_terima = $event
        this.form.header.ver_tglterima = this.convertDate(new Date(), 'funct')
        this.form.header.ver_usrterima = xray.currentUser().id.toString()
      }
      if ($event === '0' && param === 'ver_terima') {
        this.form.header.ver_terima = $event
        this.form.header.ver_tglterima = ''
        this.form.header.ver_usrterima = ''
      }
      // gudang
      if ($event === '1' && param === 'ver_gudang') {
        this.form.header.ver_gudang = $event
        this.form.header.ver_tglgudang = this.convertDate(new Date(), 'funct')
        this.form.header.ver_usrgudang = xray.currentUser().id.toString()
      }
      if ($event === '0' && param === 'ver_gudang') {
        this.form.header.ver_gudang = $event
        this.form.header.ver_tglgudang = ''
        this.form.header.ver_usrgudang = ''
      }
      // akutansi
      if ($event === '1' && param === 'ver_akuntansi') {
        this.form.header.ver_akuntansi = $event
        this.form.header.ver_tglakuntansi = this.convertDate(new Date(), 'funct')
        this.form.header.ver_usrakuntansi = xray.currentUser().id.toString()
      }
      if ($event === '0' && param === 'ver_akuntansi') {
        this.form.header.ver_akuntansi = $event
        this.form.header.ver_tglakuntansi = ''
        this.form.header.ver_usrakuntansi = ''
      }
    },
    addRow () {
      this.form.detail.push(
        {
          id: 0,
          id_katalog: '',
          id_kemasan: '',
          isi_kemasan: '',
          jumlah_item: '',
          jumlah_kemasan: '',
          jumlah_itembonus: '0',
          harga_item: '',
          harga_kemasan: '',
          diskon_item: '',
          diskon_harga: '',
          keterangan: '',
          userid_updt: xray.currentUser().id.toString()
        }
      )
      this.selected.id_kemasan.push({ detail: [] })
      this.selected.id_katalog.push({ detail: [] })
      //
      this.nilai.isi_kemasan.push({ value: '' })
      this.nilai.jumlah_item.push({ value: '' })
      this.nilai.jumlah_kemasan.push({ value: '' })
      this.nilai.jumlah_itembonus.push({ value: '0' })
      this.nilai.harga_item.push({ value: '' })
      this.nilai.harga_kemasan.push({ value: '' })
      this.nilai.diskon_item.push({ value: '' })
      this.nilai.diskon_harga.push({ value: '' })
      //
      this.countNilaiTotal()
      this.countDiskon()
      this.countPpn()
      this.countPembulatan()
      this.countAkhir()
    },
    deleteRow (index) {
      this.form.detail.splice(index, 1)
      //
      this.selected.id_kemasan.splice(index, 1)
      this.selected.id_katalog.splice(index, 1)
      //
      this.nilai.isi_kemasan.splice(index, 1)
      this.nilai.jumlah_item.splice(index, 1)
      this.nilai.jumlah_kemasan.splice(index, 1)
      this.nilai.jumlah_itembonus.splice(index, 1)
      this.nilai.harga_item.splice(index, 1)
      this.nilai.harga_kemasan.splice(index, 1)
      this.nilai.diskon_item.splice(index, 1)
      this.nilai.diskon_harga.splice(index, 1)
      //
      this.countNilaiTotal()
      this.countDiskon()
      this.countPpn()
      this.countPembulatan()
      this.countAkhir()
    },
    clearMessage () {
      this.message.code = ''
      this.message.desc = ''
      this.message.variant = ''
    },
    resetFilter () {
      this.filtering.tglAwal = ''
      this.filtering.tglAkhir = ''
      this.tanggal.tgl_doc = ''
      this.table.perPage = 10
      this.table.total = null
      this.table.currentPage = 1
      this.$refs.table.refresh()
    },
    clearForm () {
      this.form = {
        object: 'penerimaan-rumah-tangga',
        header: {
          id_rekanan: '',
          id_jenisanggaran: '',
          keterangan: '',
          tgl_doc: '',
          tgl_faktur: '',
          tipe_doc: '',
          no_faktur: '',
          no_suratjalan: '',
          ppn: '0',
          nilai_total: '',
          nilai_diskon: '',
          nilai_ppn: '',
          nilai_pembulatan: '',
          nilai_akhir: '',
          ver_terima: '0',
          ver_tglterima: '',
          ver_usrterima: '',
          // update gudang
          ver_gudang: '0',
          ver_tglgudang: '',
          ver_usrgudang: '',
          // update akutansi
          ver_akuntansi: '0',
          ver_tglakuntansi: '',
          ver_usrakuntansi: '',
          //
          userid_in: xray.currentUser().id.toString(),
          userid_updt: xray.currentUser().id.toString()
        },
        detail: [
          {
            id: 0,
            id_katalog: '',
            id_kemasan: '',
            isi_kemasan: '',
            jumlah_item: '',
            jumlah_kemasan: '',
            jumlah_itembonus: '0',
            harga_item: '',
            harga_kemasan: '',
            diskon_item: '',
            diskon_harga: '',
            keterangan: '',
            userid_updt: xray.currentUser().id.toString()
          }
        ]
      }
      this.selected = {
        rekanan_rt: [],
        kemasan_rt: [],
        kelompok_rt: [],
        jenis_anggaran_rt: [],
        tipe_doc: [],
        barang_rt: [],
        id_katalog: [{ detail: [] }],
        id_kemasan: [{ detail: [] }]
      }
      this.options.barang_rt = []
      this.tanggal = {
        tgl_doc: '',
        tgl_faktur: ''
      }
      this.nilai = {
        nilai_total: '',
        nilai_diskon: '',
        nilai_ppn: '',
        nilai_pembulatan: '0',
        nilai_akhir: '',
        isi_kemasan: [{ value: '' }],
        jumlah_item: [{ value: '' }],
        jumlah_kemasan: [{ value: '' }],
        jumlah_itembonus: [{ value: '0' }],
        harga_item: [{ value: '' }],
        harga_kemasan: [{ value: '' }],
        diskon_item: [{ value: '' }],
        diskon_harga: [{ value: '' }]
      }
      this.verifikasi = {
        ver_terima: '',
        ver_gudang: '',
        ver_akuntansi: ''
      }
      this.verifikasi_name = {
        ver_terima: xray.currentUser().name,
        ver_gudang: xray.currentUser().name,
        ver_akuntansi: xray.currentUser().name
      }
      this.formCetak = {
        header: {
          no_terima: '',
          no_btb: '',
          no_faktur: '',
          no_suratjalan: '',
          no_sp_spk: '',
          no_spb: '',
          pabrik: '',
          jumlah: '',
          ppn: '0',
          nilai_pembulatan: '0',
          nilai_akhir: '',
          ver_terima: '',
          ver_tglterima: '',
          desc_ver_usrterima: '',
          ver_gudang: '',
          ver_tglgudang: '',
          desc_ver_usrgudang: '',
          ver_akuntansi: '',
          ver_tglakuntansi: '',
          desc_ver_usrakuntansi: ''
        },
        details: [
          {
            nama_barang: '',
            kuantitas: '',
            satuan: '',
            harga: '',
            diskon: '',
            jumlah: ''
          }
        ]
      }
    },
    clearCountJenisAnggaran () {
      this.form.header.ppn = '0'
      this.form.header.nilai_total = ''
      this.form.header.nilai_diskon = ''
      this.form.header.nilai_ppn = ''
      this.form.header.nilai_pembulatan = ''
      this.form.header.nilai_akhir = ''
      //
      this.nilai.nilai_total = ''
      this.nilai.nilai_diskon = ''
      this.nilai.nilai_ppn = ''
      this.nilai.nilai_pembulatan = '0'
      this.nilai.nilai_akhir = ''
      //
      this.form.detail.forEach((element, index) => {
        this.form.detail[index].id_katalog = ''
        this.form.detail[index].id_kemasan = ''
        this.form.detail[index].isi_kemasan = ''
        this.form.detail[index].jumlah_item = ''
        this.form.detail[index].jumlah_kemasan = ''
        this.form.detail[index].jumlah_itembonus = '0'
        this.form.detail[index].harga_item = ''
        this.form.detail[index].harga_kemasan = ''
        this.form.detail[index].diskon_item = ''
        this.form.detail[index].diskon_harga = ''
        this.form.detail[index].keterangan = ''
        //
        this.nilai.isi_kemasan[index].value = ''
        this.nilai.jumlah_item[index].value = ''
        this.nilai.jumlah_kemasan[index].value = ''
        this.nilai.jumlah_itembonus[index].value = '0'
        this.nilai.harga_item[index].value = ''
        this.nilai.harga_kemasan[index].value = ''
        this.nilai.diskon_item[index].value = ''
        this.nilai.diskon_harga[index].value = ''
        //
        this.selected.id_katalog[index] = { detail: [] }
      })
    },
    clearCountIdKatalog () {
      this.form.header.ppn = '0'
      this.form.header.nilai_total = ''
      this.form.header.nilai_diskon = ''
      this.form.header.nilai_ppn = ''
      this.form.header.nilai_pembulatan = ''
      this.form.header.nilai_akhir = ''
      //
      this.nilai.nilai_total = ''
      this.nilai.nilai_diskon = ''
      this.nilai.nilai_ppn = ''
      this.nilai.nilai_pembulatan = '0'
      this.nilai.nilai_akhir = ''
      //
      this.form.detail.forEach((element, index) => {
        // this.form.detail[index].id_katalog = ''
        // this.form.detail[index].id_kemasan = ''
        // this.form.detail[index].isi_kemasan = ''
        this.form.detail[index].jumlah_item = ''
        this.form.detail[index].jumlah_kemasan = ''
        this.form.detail[index].jumlah_itembonus = '0'
        this.form.detail[index].harga_item = ''
        this.form.detail[index].harga_kemasan = ''
        this.form.detail[index].diskon_item = ''
        this.form.detail[index].diskon_harga = ''
        this.form.detail[index].keterangan = ''
        //
        this.nilai.isi_kemasan[index].value = ''
        this.nilai.jumlah_item[index].value = ''
        this.nilai.jumlah_kemasan[index].value = ''
        this.nilai.jumlah_itembonus[index].value = '0'
        this.nilai.harga_item[index].value = ''
        this.nilai.harga_kemasan[index].value = ''
        this.nilai.diskon_item[index].value = ''
        this.nilai.diskon_harga[index].value = ''
        //
        // this.selected.id_katalog[index] = { detail: [] }
      })
    }
  }
}
</script>

<style>
#modal-create-update-penerimaan .modal-dialog {
  min-width: 98% !important;
}

#modal-create-update-penerimaan .modal-body {
  background: #3399331b;
}

#modal-create-update-penerimaan .modal-header {
  background: #3399333a;
}

#modal-create-update-penerimaan .modal-footer {
  background: #3399333a;
}

#modal-create-update-penerimaan .custom-font {
  font-size: 13px;
}

#modal-create-update-penerimaan .custom-form {
  font-size: 13px;
  height: 33px !important;
  border-radius: 5px;
}

#penerimaan-rumah-tangga .custom-form {
  font-size: 13px;
  height: 33px !important;
  border-radius: 5px;
}

#modal-create-update-penerimaan .w-custom-70 {
  width: 70%;
}

#modal-create-update-penerimaan .w-custom-30 {
  width: 30%;
}

#modal-create-update-penerimaan .bg-secondary-custom {
  background-color: #455f451b;
}

#modal-create-update-penerimaan .multiselect,
#modal-stokopname-edit .multiselect {
  min-height: 33px;
}

#modal-create-update-penerimaan .multiselect__single {
  font-size: 0.9em !important;
  font-weight: normal !important;
  line-height: 26px;
}

#modal-create-update-penerimaan .multiselect__placeholder {
  font-size: 0.9em !important;
  font-weight: normal;
}

#modal-create-update-penerimaan .multiselect__option {
  font-size: .8em !important;
}

#modal-create-update-penerimaan .multiselect__tags {
  padding: 1px 40px 0 8px !important;
  height: 33px !important;
  min-height: 33px !important;
  font-size: 0.9em !important;
}

#modal-create-update-penerimaan .multiselect--disabled .multiselect__tags {
  border: 1px solid rgba(0, 0, 0, 0.171);
  background: #E9ECEF;
}

#modal-create-update-penerimaan .multiselect--disabled {
  opacity: 1;
}

#modal-create-update-penerimaan .multiselect--disabled .multiselect__tags .multiselect__single {
  background: transparent;
}

#modal-create-update-penerimaan .multiselect__select {
  height: 30px;
}

#penerimaan-rumah-tangga .custom-flatpicker, #modal-create-update-penerimaan .custom-flatpicker {
  height: 33px;
  padding-left: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
  border-radius: 5px;
  border: 1px solid rgb(228, 228, 228);
  font-size: 13px;
  color: black;
}

#penerimaan-rumah-tangga .table td {
  font-size: 12px;
  font-weight: 500;
  padding-top: 0px;
  padding-bottom: 0px;
}

#penerimaan-rumah-tangga .table th {
  font-size: 13px;
  font-weight: 400;
}

#modal-create-update-penerimaan .table thead th {
  vertical-align: baseline;
}

#penerimaan-rumah-tangga .page-link {
  background: transparent !important;
  border: none !important;
  color: gray !important;
  margin-right: 15px;
}

#penerimaan-rumah-tangga .page-item.active .page-link {
  background-color: transparent;
  border-color: transparent;
  color: #089bab !important;
  font-weight: bold;
}

#penerimaan-rumah-tangga .my-cursor,
#modal-create-update-penerimaan .my-cursor {
  cursor: pointer;
}

#penerimaan-rumah-tangga .form-control:disabled, .form-control[readonly] {
    background-color: #E9ECEF!important;
}

#modal-create-update-penerimaan .custom-spacing {
  letter-spacing: .1rem;
}
</style>
