import actions from './actions'
import mutations from './mutations'

const state = {
  tabActiveEmr: null,
  fhir: null,
  norm: null
}

export default {
  namespaced: true,
  state,
  actions,
  mutations
}
